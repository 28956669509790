import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
// UI
import Table from '@coopeuch-components/web/molecules/Table';
// Componentes
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import CardError from '../../../atoms/Error/CardError';

import TableWrapper from './styles';

const RemanenteCalculo = ({ remanenteCalculo }) => {
  const { tituloTabla, detalleTabla } = remanenteCalculo || {};
  const { contenidoError } = useSelector((state) => state.entities.remanente, shallowEqual);

  return (
    <CardBox id="remanente-calculo">
      <CardBox.Body>
        {contenidoError ? (
          <CardError />
        ) : (
          <>
            <div className="row text-left bmb-3">
              <div className="col-xs-12">
                <p>
                  <b>{tituloTabla}</b>
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                {detalleTabla ? (
                  <TableWrapper>
                    <Table
                      borderColor="trasparent"
                      fullWidth
                      alignContent="left"
                      stripeColor="#F6F3F5"
                      stripeReverse
                    >
                      <Table.Header
                        outterClassName="d-none d-md-table-row"
                        innerClassName={'text-bold bpl-3'}
                        data={['Descripción', '%', 'Valor']}
                      />
                      <Table.Body
                        data={detalleTabla}
                        renderItems={(item, TR, TD) => {
                          return (
                            <Fragment key={item.titulo}>
                              <TR className="d-none d-md-table-row">
                                <TD className="bpl-3">{item.titulo}</TD>
                                <TD className="bpl-3">{item.porcentaje}</TD>
                                <TD className="bpl-3">
                                  <div dangerouslySetInnerHTML={{ __html: item.descripcion }} />
                                </TD>
                              </TR>
                              <TR className="stripped" />
                              <TR className="d-table-row d-md-none ">
                                <TD className="bpl-3">
                                  {item.titulo} {item.porcentaje}
                                  <br />
                                  <div dangerouslySetInnerHTML={{ __html: item.descripcion }} />
                                </TD>
                              </TR>
                            </Fragment>
                          );
                        }}
                      />
                    </Table>
                  </TableWrapper>
                ) : (
                  <Skeleton count={6} />
                )}
              </div>
            </div>
          </>
        )}
      </CardBox.Body>
    </CardBox>
  );
};

RemanenteCalculo.propTypes = {
  remanenteCalculo: PropTypes.shape({
    tituloTabla: PropTypes.string,
    detalleTabla: PropTypes.arrayOf(PropTypes.object),
  }),
};

RemanenteCalculo.defaultProps = {
  remanenteCalculo: {
    tituloTabla: '',
    detalleTabla: [],
  },
};

export default RemanenteCalculo;
