import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CuotasParticipacionTemplate from '../../../components/templates/Dashboard/CuotasParticipacion';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';

import { registerGA } from '../../../utils/metrics';
import { cuotaParticipacionTexts } from '../texts';

class CuotasParticipacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCard: false,
      cuentasChecked: false,
      aporte: null,
      cuotasPagadas: null,
      cuotasPorMes: null,
      aporteMensual: null,
      cuotasPendientes: true,
    };
  }

  componentDidMount = () => {
    const { fetchCuotasParticipacion, fetchModificacionCuotasParticipacion } = this.props;
    fetchCuotasParticipacion();
    fetchModificacionCuotasParticipacion();
  };

  componentDidUpdate = (prevProps) => {
    const { cuotas, persona } = this.props;
    const { showCard, cuentasChecked, aporte } = this.state;

    if (cuotas !== prevProps.cuotas) {
      if (cuotas.success) {
        this.setDataCuotas(cuotas.data);
      }
    }
    if (persona.rol === 'SOCIO' && !!cuentasChecked && aporte !== null && !showCard) {
      this.setState({ showCard: true });
    }
  };

  handleClickTarjeta = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': cuotaParticipacionTexts.TITLE_CUOTAS,
    });

    this.props.history.push('/ecd/productos/cuota-de-participacion');
  };

  setDataCuotas = (cuotas) => {
    this.setState({
      pagoAutomatico: cuotas.pactadas.codMedioPago !== 'CA',
      aporte: cuotas.pagadas.montoTotalCuotasPagadas,
      cuotasPagadas: cuotas.pagadas.totalCuotasPagadas,
      cuotasPorMes: cuotas.pactadas.cuotasPactadas,
      aporteMensual: cuotas.pactadas.cuotasPactadas * cuotas.valorCuota,
      cuotasPendientes: cuotas.pendientes.cuotasPendientes,
    });
  };

  render() {
    const { isLoading, cuotas } = this.props;
    const { aporte, cuotasPagadas, cuotasPorMes, aporteMensual } = this.state;

    return (
      <React.Fragment>
        <div className="col-md-6 col-xs-12">
          <CuotasParticipacionTemplate
            {...{
              aporte,
              cuotasPagadas,
              cuotasPorMes,
              aporteMensual,
              isLoading,
            }}
            errorCuotas={cuotas?.error}
            handleClickTarjeta={this.handleClickTarjeta}
          />
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  cuotas: entities.cuotaParticipacion.cuotas,
  isLoading: ui.loaders.isLoadingCuotaParticipacion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchModificacionCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchModificacionCuotasParticipacion());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CuotasParticipacion));
