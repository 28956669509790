import styled from 'styled-components';

const Wrapper = styled.div`
  .conoce-desc-text {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .conoce-icon-container {
    width: 50px;
    margin-top: -4px;
  }
  .conoce-content-container {
    width: 100%;
  }

  .contrato-box-container {
    margin-left: 50px;
    display: inline-flex;
  }

  .contrato-box-container span {
    margin-left: 8px;
  }

  .conoce-container {
    display: inline-flex;
  }

  .contrato-title {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 32px;
  }

  .contrato-desc {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .contrato-download {
    color: #ec1b2b;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .contrato-line {
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #e9e9e9;
  }
  .contrato-icon-container {
    display: inline-flex;
  }

  .contrato-box-text {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .envio-title-text {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .envio-input-text {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .envio-input {
    border-radius: 2px;
    box-sizing: border-box;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 12px;
    padding-left: 12px;
    background-color: #ffffff;
    width: max-content;
    min-width: 296px;
    min-height: 48px;
  }

  .firma-text {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .firma-input-label {
    color: #383638;
    font-family: 'Ubuntu-Regular';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .clave-bloqueada {
    margin-left: 15%;
    margin-right: 15%;
  }
  .boton-bloqueo {
    width: 296px;
  }
  .firma-error-largo {
    margin-bottom: 20px;
  }
  @media screen and (max-width: 1024px) {
    .clave-bloqueada {
      margin-left: initial;
      margin-right: initial;
    }
    .boton-bloqueo {
      width: 100%;
    }
  }
`;

export default Wrapper;
