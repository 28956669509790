import React, { useMemo } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../../../atoms/PageTitle';
import Stepper from '../../../../../organism/Stepper';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const WrapperTemplate = (props) => {
  const { children, position } = props;

  const steps = useMemo(() => {
    return ['Características', 'Contratos y firma', 'Comprobante'];
  }, []);

  return (
    <Wrapper>
      <div className="solicitud-container">
        <Stepper position={position} steps={steps} progressOutContext />
        <div className="solicitud-contenedor">{children}</div>
      </div>
    </Wrapper>
  );
};

export default WrapperTemplate;
