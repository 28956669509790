import styled from 'styled-components';

export const Wrapper = styled.div`
  .cv-container {
    min-height: 178px;

    padding: 16px 32px 16px 32px !important;

    ${({ loading }) => loading && 'padding: 24px 32px 36px 32px !important;'}
  }

  .cv-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }
  .cv-icon-container {
    align-self: center;
  }
  .cv-value-fecha {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    font-family: 'Ubuntu-Medium';
  }
  .cv-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .cv-margin-text {
    margin-bottom: 13px;
  }

  .cv-card-container {
    box-shadow: none;
  }
  .cv-card-container section {
    box-shadow: none;
  }

  .cv-swiper {
    background-color: #ffffff;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.18) !important;
  }

  .swiper-button-next {
    margin-top: 12px;
    height: 5px;
    width: 5px;
    border: solid #007db7;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .swiper-button-prev {
    margin-top: 12px;
    height: 5px;
    width: 5px;
    border: solid #007db7;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .swiper-pagination-bullet-active {
    background-color: #007db7;
    height: 6px;
    width: 6px;
  }
  .swiper-pagination-bullet {
    height: 6px;
    width: 6px;
  }

  .cv-icon svg {
    height: 32px;
    width: 54px;
  }

  .cv-icon-monedero svg {
    height: 45px;
    width: 54px;
  }

  .swiper-pagination-bullet {
    margin: 0px 2px !important;
  }

  .cv-text-right {
    text-align: right;
  }

  @media screen and (max-width: 766px) {
    .cv-icon svg {
      height: 40px;
    }
    .cv-text-right {
      text-align: left;
    }
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  /* line-height: 48px; */
`;
export const CardWrapper = styled.div`
  .dashboard-card-header {
    height: unset;
  }
`;

export const CardMisProductos = styled.div`
  .dashboard--currency-wrapper {
    align-items: center;
    /* margin-bottom: 14px;
    margin-top: 14px; */
    display: flex;
    color: #383638;

    & .dashboard--currency-title {
      font-size: 14px;
      /* margin-top: 6px;
      margin-bottom: 12px; */
    }

    & .dashboard--currency {
      font-family: 'Ubuntu-Bold';
      /* margin-right: 14px; */
      font-size: 28px;
      color: #333;
      float: left;
    }
  }
`;

export default Wrapper;
