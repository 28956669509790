import styled from 'styled-components';

const Card = styled.div`
  border: 1px solid ${(props) => (props.borderColor ? `${props.borderColor}px` : '#e7e7e7')};
  border-radius: ${(props) => (props.rounded ? `${props.rounded}px` : '5px')};
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(31, 30, 30, 0.1);
  width: 100%;
  .header {
    border-bottom: 1px solid #e7e7e7;
    &__title {
      font-family: 'Ubuntu-Bold';
      font-size: 24px;
      color: #383838;
      @media (max-width: 991px) {
        font-size: 18px;
      }
      padding: 20px 32px 20px 32px;
      @media (max-width: 991px) {
        padding: 16px;
      }
    }
  }
  .body {
    padding: ${(props) => (!props.padding ? `${props.padding}px` : '31px')};
    @media (max-width: 991px) {
      padding: ${(props) => (!props.padding ? `${props.padding}px` : '16px')};
    }
  }
`;

export default Card;
