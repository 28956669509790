import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Template
import SolicitaCuentaAhorroTemplate from '../../../components/templates/CuentaAhorro/SolicitaCuenta';

// Actions
import { listaNegraActions } from '../../../../application/actions/listaNegra';

// Utils
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

const Index = ({ rut, solicitudCAFlujo, handleSolicitudCAFlujo, onHandleClickBanner }) => {

  const [mounted, setMounted] = useState(false);
  const [isBlackListed, setIsBlackListed] = useState(null);
  // console.log(solicitudCAFlujo);

  // Redux
  const dispatch = useDispatch();

  const storageBlackListed = useSelector((store) => store.entities.listaNegra, shallowEqual);
  const persona = useSelector((store) => store.entities.persona, shallowEqual);

  useEffect(() => {
    if (!mounted) {
      dispatch(listaNegraActions.fetchListaNegraConsultar());
      setMounted(true);
    }
  }, []);

  useEffect(() => {
    if (storageBlackListed.success) {
      setIsBlackListed(storageBlackListed.enListaNegra);
    }
    if (storageBlackListed.error) {
      setIsBlackListed(true);
    }
  }, [storageBlackListed]);

  useEffect(() => {
    if (rut) {
      const baseRut = String((rut || '').replace('-', ''));
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CA-SIN PRODUCTO',
        informacion_adicional: {},
      });
    }
  }, [rut]);

  return (
    <>
      <div className="w-100 text-left">
        <SolicitaCuentaAhorroTemplate {...{ handleSolicitudCAFlujo, onHandleClickBanner, isBlackListed, persona }} />
      </div>
    </>
  );
};

Index.propTypes = {
  rut: PropTypes.string,
};
Index.defaultProps = {
  rut: '',
};

Index.displayName = 'SolicitaCuentaAhorro_Container';
export default Index;
