import React, { Component } from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../components/atoms/PageTitle';
import SolicitaCuotaParticipacion from '../../../components/templates/CuotaParticipacion/Solicita';
import { registerGA } from '../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

class Index extends Component {
  componentDidMount = () => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/solicita/cuota-participacion',
    });
  };

  bannerClick = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicita/cuota-participacion',
      'eve-cat': 'Click',
      'eve-lab': 'Hazte socio',
    });
    window.open('https://www.coopeuch.cl/personas/hazte-socio-digital.html', '_blank').focus();
  };

  render() {
    return (
      <>
        <PageTitle
          title="Hazte Socio"
          size={24}
          icon={<LoadIcon src={`${iconPath}user.svg`} color="black" />}
        />
        <div className="w-100 margin-after-title text-left">
          <SolicitaCuotaParticipacion bannerClick={this.bannerClick} />
        </div>
      </>
    );
  }
}

Index.displayName="Solicita_CP_Container"
export default Index;
