import React from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DescargaHistorial = ({ handleDownloadPDF }) => {
  return (
    <Button
      color="red"
      className="text-size-m underline middle-items w-100"
      asTextLink
      onClick={handleDownloadPDF}
    >
      <div className="row middle-xs">
        <LoadIcon
          src={`${iconPath}download_arrow.svg`}
          size={22}
          color="#E81E2B"
          className="mr-8"
        />
        <span>Descargar Movimientos</span>
      </div>
    </Button>
  );
};

export default DescargaHistorial;
