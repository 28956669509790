import React from 'react';
import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';

import ContratacionBloqueoClave from '../ContratacionBloqueoClave';
import PasswordInput from '../../atoms/PasswordInput';
import Loading from '../../atoms/Loading/Loading';

import { Paragraph } from '../../../assets/styles/styled_components';

function FirmaElectronica({
  password,
  handlePassword,
  passwordInputValidation,
  handleValidationError,
  validationError,
  showPassword,
  onTogglePassword,
  loading,
  cardDescription,
}) {
  let content = (
    <div id="input">
      <Paragraph className="mb-16">
        <p>{cardDescription}</p>
      </Paragraph>
      <div className="row">
        <div className="col-xs-12 col-md-5">
          <PasswordInput
            password={password}
            showPassword={showPassword}
            passwordError={passwordInputValidation}
            onTogglePassword={onTogglePassword}
            onHandlePasswordInput={handlePassword}
          />
        </div>
      </div>
    </div>
  );
  if (validationError) {
    content = (
      <ContratacionBloqueoClave
        id="pass-error"
        serverPasswordError={validationError}
        onClick={handleValidationError}
      />
    );
  }
  if (loading) {
    content = <Loading id="loading" />;
  }

  return (
    <>
      <CardBox.Header>
        <Typography tag="p" fontSize={22} fontWeight="bold">Firma electrónica</Typography>
      </CardBox.Header>
      <CardBox.Body>{content}</CardBox.Body>
    </>
  );
}

FirmaElectronica.propTypes = {
  password: PropTypes.string,
  handlePassword: PropTypes.func.isRequired,
  passwordInputValidation: PropTypes.string,
  handleValidationError: PropTypes.func.isRequired,
  validationError: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
  showPassword: PropTypes.bool.isRequired,
  onTogglePassword: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

FirmaElectronica.defaultProps = {
  password: '',
  passwordInputValidation: '',
  validationError: null,
  loading: false,
};

export default FirmaElectronica;
