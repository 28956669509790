
import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';

// styles
import Wrapper, { LabelWrapper, SelectInput, IconWrapper, OptionsContainer } from './styles';
import ArrowIcon from './ArrowIcon';

const Select = (props) => {
  const {
    borderColor,
    labelClass,
    className,
    onChange,
    textSize,
    options,
    placeholder,
    dataSet,
    title,
    value,
    id,
    disabled, // Nueva prop para controlar el estado de deshabilitado
  } = props;

  const styleDisabled = {
      disabled: {
        color: '#888888',
        cursor: 'not-allowed',
        pointerEvents:'none',
        backgroundColor:'#edeff3',
        borderColor: '#edeff3'
      }
  }

  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleToggle = useCallback(() => {
    setOpen(!open);
    setFocus(false);
  }, [open, focus]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setFocus(false);
  }, []);

  const handlePressOption = useCallback(
    (itemValue) => {
      onChange({
        target: {
          dataset: {
            id: dataSet,
          },
          value: itemValue,
        },
      });
      handleToggle();
      setFocus(true);
    },
    [dataSet, onChange, handleToggle]
  );

  const currentValue = useMemo(
    () => options?.find((item) => item?.value === value),
    [options, value]
  );

  const containerStyles = disabled ? styleDisabled.disabled : {};

  return (
    <>
      {title ? <LabelWrapper className={labelClass}>{title}</LabelWrapper> : null}

      <OutsideClickHandler onOutsideClick={handleClose}>
        <Wrapper>
          <SelectInput
            open={open}
            focus={focus}
            onClick={handleToggle}
            borderColor={borderColor}
            className={className}
            onChange={onChange}
            textSize={textSize}
            placeholder={placeholder}
            data-id={dataSet}
            value={value}
            id={id}
            disabled={disabled} style={containerStyles}
          >
            <div className="select-container-all">
              <div className="select-container-value">
                <label>{currentValue?.label || placeholder}</label>
              </div>
              <div className="p-0">
                <IconWrapper>
                  <div className="icon-container-select">
                    <ArrowIcon />
                  </div>
                </IconWrapper>
              </div>
            </div>
          </SelectInput>
          <OptionsContainer open={open} numberOfOptions={options?.length}>
            {options.map((item, index) => (
              <div
                key={index}
                value={item.value}
                onClick={() => handlePressOption(item.value)}
              >
                {item.label}
              </div>
            ))}
          </OptionsContainer>
        </Wrapper>
      </OutsideClickHandler>
    </>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  borderColor: PropTypes.string,
  labelClass: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  textSize: PropTypes.number,
  dataSet: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool, // Nueva prop para controlar el estado de deshabilitado
};

Select.defaultProps = {
  onChange() {},
  placeholder: '',
  borderColor: '',
  labelClass: '',
  className: '',
  textSize: 16,
  dataSet: '',
  options: [],
  title: '',
  value: '',
  id: '',
  disabled: false, // Valor predeterminado para la prop de deshabilitado
};

export default Select;