import styled from 'styled-components';

export const BannerWrapper = styled.div`
  height: 150px;
`;

export const LeftColBannerRemanente = styled.div`
  /* min-width:100%; */
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  .button-ver-mas-remanente {
    align-items: center;
  }
  a {
    text-decoration: none;
  }
  svg {
    fill: white;
  }
  .banner-wrapper {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
`;