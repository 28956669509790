import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/certificadosSII';

function fetchPDFCertificadoSIISuccess(state, action) {
  const { codigo, data, key } = action.payload;

  state[codigo][key] = { data, error: null };
}

function fetchPDFCertificadoSIIError(state, action) {
  const { codigo, error, key } = action.payload;

  state[codigo][key] = { data: null, error };
}

const fetchPDFCertificadoSII = createAction('fetchPDFCertificadoSII');

// ------------- connection ------------- //

const certificadosSIIReducer = createSlice({
  name: 'certificadosSII',
  initialState,
  reducers: {
    fetchPDFCertificadoSIISuccess,
    fetchPDFCertificadoSIIError,
  },
});

export const certificadosSIIActions = {
  ...certificadosSIIReducer.actions,
  fetchPDFCertificadoSII,
};

export default certificadosSIIReducer.reducer;
