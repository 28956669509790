import React from 'react';
// Atoms
import AlertModal from '../../../atoms/AlertModal';
// Styles
import Wrapper from './styled';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ModalError = ({ isVisibleModal, setIsVisibleModal, handleClickEntendido }) => {
  return (
    <Wrapper>
      <AlertModal showModal={isVisibleModal} width={482} onClose={() => setIsVisibleModal(false)}>
        <div className="center-xs">
          <div className="row center-xs">
            <img src={`${iconPath}ilustracion_error.svg`} alt="error" />
          </div>
          <p className="title">
            ¡Lo sentimos! La información
            <br />
            no está disponible
          </p>
          <p className="description">
            En estos momentos no podrás visualizar esta información,
            <br />
            por favor intenta más tarde.
          </p>
          <button className="btn" onClick={() => handleClickEntendido()}>
            Entendido
          </button>
        </div>
      </AlertModal>
    </Wrapper>
  );
};

export default ModalError;
