import Styled from 'styled-components';

export const Paragraph = Styled.div`
 @media (min-width: 64em) {
    margin-left: ${({ marginLeft }) => `${marginLeft ? marginLeft : '0'}px`};
  }
  font-size: ${({ size }) => `${size ? size : '16'}px`};
  letter-spacing: 0;
  line-height: 26px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  & .login--wrapper-checkbox {
      width: 100%;
      display: flex;
      margin-left: -6px;
      margin-top: -12px;
      margin-bottom: 12px;
    }
`;

export const HRow = Styled.hr`
  border: 1px solid #E9E9E9;
`;
export const CustomList = Styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 50%;
      position: absolute;
      content: '';
      display: inline-block;
      margin: auto 16px auto 0;
      height: 6px;
      bottom: 0;
      width: 6px;
      left: -16px;
      top: 0;
    }
  }
`;
export const MessageError = Styled.div`
  width:100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;
