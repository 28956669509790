const defaultRequest = {
    id: 500,
    title: '¡Lo sentimos!',
    message:
      'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
    showInput: false,
  };
  export const errTarjetaCoordenadasFactor = {
    1: {
      id: 1,
      title: '¡Se agotó el tiempo!',
      message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
      showInput: false,
    },
    2: {
      id: 2,
      title: '¡Lo sentimos!',
      message: 'Las coordenadas no corresponden. Por favor, intenta nuevamente.',
      showInput: false,
    },
    3: {
      id: 3,
      title: '¡Lo sentimos!',
      message:
        'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
      showInput: false,
    },
    4: {
      id: 4,
      title: '¡Operación rechazada!',
      message:
        'Por su seguridad, su tarjeta de coordenadas ha sido bloqueada, ha superado la cantidad de intentos',
      showInput: false,
    },
    5: {
      id: 5,
      title: '¡Lo sentimos!',
      message: 'Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente en App Coopeuch',
      showInput: false,
    },
  };
  export default defaultRequest;
  