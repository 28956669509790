import React from 'react';

// Ui
import Button from '@coopeuch-components/web/atoms/Button';
import Link from '@coopeuch-components/web/atoms/Link';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Table from '@coopeuch-components/web/molecules/Table';
// import StepsActions from '../../../../../organism/Stepper/StepsActions';

// Utils
import { capitalizeStrFormat } from '../../../../../../utils/formatters';
import { dateWithSlash } from '../../../../../../utils/dates';

// Styles
import { Paragraph, Wrapper, ActionButtons, StepsCol, StepsRow, CardBoxWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Index = ({
  respuestaDetalle,
  despachoDomicilio,
  region,
  comuna,
  calle,
  numeroCasa,
  otrosCasa,
  nextAction,
  handleDownloadComprobante,
  onRedirectToSchedule,
}) => {
  const comprobante = `${staticPath}sello-comprobante.png`;

  const contractData = [
    {
      id: 0,
      name: 'Nombre',
      value: capitalizeStrFormat(respuestaDetalle?.nombre),
    },

    {
      id: 1,
      name: 'Producto',
      value: 'Cuenta Coopeuch',
    },
    {
      id: 2,
      name: 'Nº de tarjeta',
      value: `Nº ${respuestaDetalle?.numeroTarjeta}`,
    },
    {
      id: 3,
      name: 'Tipo de despacho',
      value: despachoDomicilio ? 'Despacho a domicilio' : 'Retiro en Sucursal',
    },
    despachoDomicilio && {
      id: 4,
      name: 'Región',
      value: `${capitalizeStrFormat(region.label)}`,
    },

    despachoDomicilio && {
      id: 5,
      name: 'Comuna',
      value: `${capitalizeStrFormat(comuna.label)}`,
    },
    despachoDomicilio && {
      id: 6,
      name: 'Calle',
      value: `${capitalizeStrFormat(calle)}`,
    },
    despachoDomicilio && {
      id: 6,
      name: 'Número',
      value: `${numeroCasa}`,
    },
    despachoDomicilio && {
      id: 7,
      name: 'Depto / Villa / Otros',
      value: `${otrosCasa}`,
    },

    {
      id: despachoDomicilio ? 8 : 4,
      name: 'Fecha de contratación',
      value: `${dateWithSlash(respuestaDetalle.fechaContratacion)}`,
    },
  ];

  const contractDataFilter = contractData.filter((item) => Boolean(item));
  // console.log(contractDataFilter);

  return (
    <Wrapper>
      <Alert
        className="alert-comprobante-contratacion-monedero bp-3 bmb-3 bmb-md-4"
        bgType="success"
        id="alert-felicitaciones-contratacion-monedero"
        show
      >
        <>¡Felicitaciones! La contratación de Cuenta Coopeuch ha sido realizada con éxito.</>
      </Alert>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>
          <div className="row">
            <div className="col-xs-6">Comprobante</div>
            <div className="col-xs-6 text-right">
              <ActionButtons className="middle-items">
                <div className="comprobante-action bmr-3" onClick={handleDownloadComprobante}>
                  <LoadIcon
                    className="mr-2"
                    src={`${iconPath}download_arrow.svg`}
                    color="#F42534"
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text">Descargar</div>
                </div>
              </ActionButtons>
            </div>
          </div>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12 col-md-8 bmb-md-3 comprobante-card-text">
              <div className="text-wrapper">
                <Paragraph className="mb-2" size={14}>
                  <b>Cuenta y Contratos</b>
                </Paragraph>
                <Paragraph className="bmb-3" size={14}>
                  A partir de estos momentos puedes utilizar tu cuenta para pagar productos de
                  Coopeuch, transferir y recibir depósitos.
                </Paragraph>
                <Paragraph className="mb-2" size={14}>
                  Tu número de Cuenta Coopeuch es:
                </Paragraph>
                <Paragraph className="mb-2" size={24}>
                  <b>{`N° ${respuestaDetalle.numeroCuenta}`}</b>
                </Paragraph>
                <Paragraph className="bmt-3" size={14}>
                  Te hemos enviado un email con la copia de todos los contratos que están asociados
                  a la solicitud de tu Cuenta Coopeuch, revísalo en tu email:{' '}
                  <b>{respuestaDetalle?.correo}.</b>
                </Paragraph>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className="bm-auto bmr-md-0" src={comprobante} alt="comprobante" />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Datos de la contratación</CardBox.Header>
        <CardBox.Body>
          <Table
            borderColor="trasparent"
            fullWidth
            alignContent="left"
            stripeColor="#F6F3F5"
            stripeReverse
          >
            <Table.Body
              data={contractDataFilter}
              renderItems={(item, TR, TD) => {
                return (
                  <TR key={`detalle-contrato-monedero-${item.id}`}>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.name}
                    </TD>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.value}
                    </TD>
                  </TR>
                );
              }}
            />
          </Table>
        </CardBox.Body>
      </CardBox>

      <CardBoxWrapper>
        <CardBox className="steps-card-box bmb-3 bmb-md-4">
          <CardBox.Header>Próximos pasos a seguir</CardBox.Header>
          <CardBox.Body>
            <StepsRow className="row bmx-0" first>
              <StepsCol className="bmr-3">
                {' '}
                <div className="container_number_bkg">
                  <span className="number">1</span>
                </div>
              </StepsCol>
              <div className="col-xs-8 col-md-10">
                <Paragraph size={16}>
                  Recibirás un email de confirmación <b>¡Estás aquí!</b>
                </Paragraph>
                <Paragraph className="bmt-2" size={14}>
                  Se envió una copia de tus contratos firmados al correo{' '}
                  <b>{respuestaDetalle?.correo}</b>
                </Paragraph>
              </div>
              <StepsCol className="middle-items bml-5">
                {' '}
                <LoadIcon
                  className="right-icon"
                  src={`${iconPath}icono_send.svg`}
                  color="#F42534"
                  size={40}
                />
              </StepsCol>
            </StepsRow>
            <StepsRow className="row bmx-0">
                <StepsCol className="bmr-3">
                  {' '}
                  <div className="container_number">
                    <span className="number">2</span>
                  </div>
                </StepsCol>
                <div className="col-xs-8 col-md-10">
                  <Paragraph size={16}>
                    Prepararemos tu tarjeta
                  </Paragraph>
                  <Paragraph className="bmt-2" size={14}>
                    Te enviaremos un sobre que contendrá tu tarjeta de debito.
                  </Paragraph>
                </div>
                <StepsCol className="middle-items bml-5">
                  {' '}
                  <LoadIcon
                    className="right-icon"
                    src={`${iconPath}icon_credit_card.svg`}
                    color="#F42534"
                    size={40}
                  />
                </StepsCol>
            </StepsRow>
            <StepsRow className="row bmx-0">
                <StepsCol className="bmr-3">
                  {' '}
                  <div className="container_number">
                    <span className="number">3</span>
                  </div>
                </StepsCol>
                <div className="col-xs-8 col-md-10">
                  <Paragraph size={16}>Activa tu tarjeta una vez la recibas</Paragraph>
                  <Paragraph className="bmt-2" size={14}>
                    - Si solicitaste despacho a domicilio ingresa a tu Sitio Privado Coopeuch, haz click en el banner de 
                    activación y sigue las instrucciones.
                  </Paragraph>
                </div>
                <StepsCol className="middle-items bml-5">
                  {' '}
                  <LoadIcon
                    className="right-icon"
                    src={`${iconPath}icon_click.svg`}
                    color="#F42534"
                    size={40}
                  />
                </StepsCol>
              </StepsRow>

            <StepsRow className="row bmx-0">
              <StepsCol className="bmr-3">
                {' '}
                <div className="container_number">
                  <span className="number">4</span>
                </div>
              </StepsCol>
              <div className="col-xs-8 col-md-10">
                <Paragraph size={16}>Luego de activarla, crea la clave en un cajero</Paragraph>
                <Paragraph className="bmt-2" size={14}>
                  - 24 horas hábiles posterior a la activación de tu tarjeta dirígete a cualquier cajero automático de 
                  tu elección.
                </Paragraph>
                <Paragraph size={14}>
                  - Ingresa al cajero utilizando como clave los primeros 4 dígitos de tu RUT.
                </Paragraph>
                <Paragraph size={14}>
                  - Finalmente, ingresa la opción "Cambio de clave" y crea tu clave de 4 dígitos.
                </Paragraph>
              </div>
              <StepsCol className="middle-items bml-5">
                {' '}
                <LoadIcon
                  className="right-icon"
                  src={`${iconPath}icon_password alt.svg`}
                  color="#F42534"
                  size={40}
                />
              </StepsCol>
            </StepsRow>
          </CardBox.Body>
        </CardBox>
      </CardBoxWrapper>
      <div className="container_next">
        <Button
          fontFamily="Ubuntu-Medium"
          bgColor="#E81E2B"
          color="#fff"
          onClick={nextAction}
          width={200}
          className="bmb-3 bmb-md-4"
        >
          Ir a home
        </Button>
      </div>
    </Wrapper>
  );
};

export default Index;
