const initState = {
  depositosContratados: [],
  depositosContratadosError: false,
  isFetchedDepositosContratados: false,
  reglasSimulacion: [],
  reglasSimulacionError: false,
  simulaciones: [],
  simulacionesError: false,
  liquidarConfirmado: false,
  liquidarError: {},
  habilitadoOtpSms: false,
  habilitadoOtpSmsError: {},
  liquidarComprobante: {},
  reglasLiquidacion: [],
  reglasLiquidacionError: false,
  liquidarComprobanteError: false,
  seleccionarSimulacion: false,
  seleccionarSimulacionError: false,
  invertirDAP: false,
  invertirDAPError: false,
  invertirDAPErrorCode: 0,
  comprobanteInvertir: {},
  comprobanteInvertirError: false,
};

export default initState;
