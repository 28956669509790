import React, { useEffect } from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Skeleton from 'react-loading-skeleton';
import SinRemanenteAlert from './SinRemanenteAlert';
import { Link } from 'react-router-dom';
// Utils
import { formatCurrency, hiddeNumber } from '../../../../utils/functions';
import { PAYMENT_METHOD } from '../../../../utils/constants';

// styles
import InfoContent, { BankAccount } from './styles';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import { registerGA } from '../../../../utils/metrics';

import Icon_pencil from '../../../../assets/svg/Icon-pencil.svg'

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SIMPLE_GROUP = {
  11: 'Socio fallecido requiere posesión efectiva',
  1: 'Para obtener mayor información de su Remanente, lo invitamos a consultar en www.coopeuch.cl ingresando a tu asistente virtual a partir del 13 de Abril 2022.',
  0: 'Socio no posee remanente.',
};

const MiRemanenteCard = ({
  estado,
  destino,
  grupo,
  fechaDePago,
  tituloAno,
  monto,
  sinRemanente,
  loading,
  switchOnOff,
}) => {
  const mostrarModificarMedioPagoLink = () => {
    return switchOnOff && switchOnOff.data && switchOnOff.data.some(
      item => item.nombre === 'PODER_ESPECIAL_MODIFICAR_MEDIO_PAGO'
    );
  };

  useEffect(() => {
    if (!loading) {
      if (sinRemanente) {
        registerGA({
          event: 'vpv-privado',
          page: '/tef/remanente/miremanente',
          producto: 'No posee remanente',
          formaPago: 'No aplica',
        });
      } else {
        if (grupo === 11) {
          //socio fallecido
          registerGA({
            event: 'vpv-privado',
            page: '/tef/remanente/miremanente',
            producto: 'Fallecido posesion efectiva',
            formaPago: 'No aplica',
          });
        } else if (grupo === 1) {
          // grupo con mora
          registerGA({
            event: 'vpv-privado',
            page: '/tef/remanente/miremanente',
            producto: 'Socio con Mora',
            formaPago: 'No aplica',
          });
        } else {
          registerGA({
            event: 'vpv-privado',
            page: '/tef/remanente/miremanente',
            producto: estado === 'N' ? 'nopagado' : 'pagado',
            formaPago: `Medio de pago: ${PAYMENT_METHOD[grupo]}`,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sinRemanente, loading]);

  return (
    <CardBox className="h-100" id="mi-remanente-card">
      <CardBox.Body>
        {sinRemanente && !loading ? (
          <SinRemanenteAlert />
        ) : (
          <React.Fragment>
            <p className="text-left bmb-3">{loading ? <Skeleton /> : tituloAno}</p>
            <div className="remanente--currency-wrapper bmy-3">
              <p className="remanente--currency text-size-big pl-0 text-bold">
                {grupo !== 0 && (loading ? <Skeleton width={150} /> : formatCurrency(monto))}
              </p>
            </div>
            {grupo === 11 || grupo === 0 || (grupo === 1 && estado === 'N') ? (
              <div className="mt-16 mb-16">
                <InfoContent>
                  <div className="mr-16">
                    <LoadIcon src={`${iconPath}info.svg`} size={32} color="#005D8F" />
                  </div>
                  <div>
                    <Typography tag="p" color="#007DB7">
                      {SIMPLE_GROUP[grupo]}
                    </Typography>
                  </div>
                </InfoContent>
              </div>
            ) : (
              <>
                {loading ? (
                  <Skeleton count={3} />
                ) : (
                  <>
                    <div className="mb-16">
                      Estado: <strong>{estado === 'N' ? 'Pendiente' : 'Pagado'}</strong>
                    </div>
                    <div className="mt-16 mb-16">
                      {`Fecha ${[30, 8, 5].includes(grupo) ? 'estimada ' : ''}de pago${
                        estado === 'N' && ![2, 5, 8, 30].includes(grupo) ? ' desde' : ''
                      }:`}{' '}
                      <strong>{fechaDePago}</strong>
                    </div>
                    <div className="mt-16 mb-16">
                      Medio de pago: <strong>{PAYMENT_METHOD[grupo]}</strong>
                    </div>
                    {/* se añade el false para evitar mostrar campo "destino" mientras 
                    no se arregle el paso a prod de la data fuse. Remover cuando ya no 
                    sea necesario esconder el campo "destino"
                    */}
                    {false && (grupo === 8 || grupo === 5 || grupo === 30) && (
                      <div className="mt-16 mb-16">
                        Destino:{' '}
                        <BankAccount>
                          <strong>
                            {destino?.banco ? `${destino?.banco} - ` : ''}
                            {hiddeNumber(destino?.numeroCuenta)}
                          </strong>
                        </BankAccount>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
            {loading ? <Skeleton count={3} /> : mostrarModificarMedioPagoLink() && (
              <div>
                <Link to="/ecd/poder-especial/datos-bancarios">
                  <div style={{ display: 'flex'}}>
                    <img src={Icon_pencil} />
                    <span style={{marginLeft: 5}}>Modificar medio de pago</span>
                  </div>
                </Link>
              </div>
            )}
          </React.Fragment>
        )}
      </CardBox.Body>
    </CardBox>
  );
};

export default MiRemanenteCard;
