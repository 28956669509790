const tCoordenadas = {
  descripValidar:
    'Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.',
  descriptDesafio: 'Ingresa los valores indicados en las coordenadas de tu Tarjeta Nº',
  buttonValidar: 'Autorizar',
  buttonDesafio: 'para autorizar',
  buttonError:'Reintentar'
};

export const errores = {
  0: {
    title: '!Lo sentimos!',
    descrip:
      'En este momento nuestro servicio no se encuentra disponible. Agradecemos tu comprensión.',
  },
  3: {
    title: '¡Se agotó el tiempo!',
    descrip: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
  },
  2: {
    title: '¡Lo sentimos!',
    descrip: 'Las coordenadas no corresponden. Por favor, intenta nuevamente.',
  },
  4: {
    title: '¡Lo sentimos!',
    descrip: 'Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente',
  },
  5: {
    title: '¡Lo sentimos!',
    descrip: 'Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente',
  },
};

export default tCoordenadas;
