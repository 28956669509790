import React from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import Wrapper from './styles';
import CashIcon from './cashIcon';
import DashboardCard from '../DashboardCard';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';



function BannerIframe({ handleShowIframe, title, subtitle, buttonName }) {
    const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
    return (
        <div className="bmt-3 bmt-md-4">
            <Wrapper>
                <DashboardCard>
                    <div className="row d-flex">
                        <div className="col-xs-2 col-md-1">
                            <div className="icono_card_banner float-right">
                                <CashIcon />
                            </div>
                        </div>
                        <div className="col-xs-8 col-md-9">
                            <p className="text-card-banner">
                                <span className="mensaje_semi_bold">{title}</span>{' '}<span>{subtitle}</span>
                            </p>
                        </div>
                        <div className="col-xs-2 col-md-2 ">
                            <Button
                                iconRight={<LoadIcon src={`${iconPath}arrow_right.svg`} size={22} margin="0" className="arrow-red" />}
                                variant="text"
                                onClick={handleShowIframe}
                                suffix="old-arrow-right"
                                className="override-red-link-btn link-right">
                                <span className='hide-on-mobile'>{buttonName}</span>
                            </Button>
                        </div>
                    </div>
                </DashboardCard>
            </Wrapper>
        </div>

    );
}



export default BannerIframe;
