import React from 'react';
import { Component } from 'react';
import { produce } from 'immer';

import MetodoTemplate from '../../../components/templates/RecuperarOCrearClave/Metodo';
import { metodoTexts } from '../texts';
import { registerGA } from '../../../utils/metrics';
import { metodoFactores } from '../../../utils/constants';

class Metodo extends Component {
  state = {
    telefono: '',
    warningTelefono: false,
    warningBloqueo: false,
  };

  componentDidMount() {
    const {
      fetchFactoresSeguridadCrearClave,
      trackId,
      rut,
      fetchReglasFactores,
      fetchDatosPersonalesCrearClave,
      type,
    } = this.props;

    const params = { track: `${trackId}-${type === 'crear' ? '2' : '12'}`, rut };

    fetchReglasFactores();
    fetchFactoresSeguridadCrearClave(params);
    fetchDatosPersonalesCrearClave(params);

    registerGA({
      event: 'vpv',
      page: '/crea-rec-clave/paso2',
      cuotas: type === 'crear' ? 'creación' : 'recuperación',
    });
  }

  componentDidUpdate(prevProps) {
    const {
      factoresSeguridad,
      envioDesafioOTP,
      onStepsHandle,
      datosPersonales,
      successSelectMetodo,
      errorDatosSinTelefonoIBS,
    } = this.props;
    if (prevProps.factoresSeguridad !== factoresSeguridad) {
      if (!this.existeFactor(2.1)) {
        const { onChangeFactorSeleccionado } = this.props;
        onChangeFactorSeleccionado('3.1');
      }
    }
    if (prevProps.envioDesafioOTP !== envioDesafioOTP) {
      if (envioDesafioOTP) {
        onStepsHandle(3);
      } else {
        this.setState(
          produce((draft) => {
            draft.warningBloqueo = true;
          })
        );
      }
    }
    if (prevProps.datosPersonales !== datosPersonales) {
      if (datosPersonales) {
        this.setState(
          produce((draft) => {
            draft.telefono = datosPersonales;
          })
        );
      }
    }
    if (prevProps.errorDatosSinTelefonoIBS !== errorDatosSinTelefonoIBS) {
      if (errorDatosSinTelefonoIBS) {
        this.setState(
          produce((draft) => {
            draft.warningTelefono = true;
          })
        );
      }
    }
    if (prevProps.successSelectMetodo !== successSelectMetodo) {
      if (successSelectMetodo) {
        const { factorSelected, fetchDesafioOTP, trackId, rut, type } = this.props;
        if (factorSelected === '2.1' || factorSelected === '2.2') {
          onStepsHandle(3);
        }
        if (factorSelected === '3.1') {
          const params = { track: trackId + (type === 'crear' ? '-8' : '-18'), rut: rut };
          fetchDesafioOTP(params);
        }
      }
    }
  }

  handleClickContinue = () => {
    const { factorSelected, trackId, rut, selectMetodo, type, validarUsingFactorFailed } =
      this.props;

    const params = {
      params: { metodo: factorSelected === '3.1' ? '3' : factorSelected, rut: rut },
      track: trackId + (type === 'crear' ? '-3' : '-13'),
    };
    validarUsingFactorFailed({
      error: {},
      type: 'smsErrors',
    });
    validarUsingFactorFailed({
      error: {},
      type: 'tarjetaCoordenadasErrors',
    });
    validarUsingFactorFailed({
      error: {},
      type: 'passCoopeuchErrors',
    });
    selectMetodo(params);
    registerGA({
      event: 'trigger_event',
      producto: metodoFactores[factorSelected],
      cuotas: type === 'crear' ? 'creación' : 'recuperación',
      'event-config': {
        'eve-acc': '/crea-rec-clave/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Continuar',
      },
    });
  };

  handleClickRetry = () => {
    const {
      fetchFactoresSeguridadCrearClave,
      trackId,
      rut,
      fetchReglasFactores,
      fetchDatosPersonalesCrearClave,
      type,
    } = this.props;
    const params = { track: trackId + (type === 'crear' ? '-2' : '-12'), rut: rut };
    fetchFactoresSeguridadCrearClave(params);
    fetchReglasFactores();
    fetchDatosPersonalesCrearClave(params);
  };

  onChangeFactor = (event) => {
    const { telefono } = this.state;
    const { onChangeFactorSeleccionado } = this.props;
    onChangeFactorSeleccionado(event.target.value);
    if (!telefono && event.target.value === '3.1') {
      this.setState(
        produce((draft) => {
          draft.warningTelefono = true;
        })
      );
      registerGA({
        event: 'trigger_event_visto',
        'event-config': {
          'eve-acc': '/crea-rec-clave/paso2',
          'eve-cat': 'Error',
          'eve-lab': metodoTexts.WARNING_TELEFONO + ' ' + metodoTexts.PHONE_CALL_CENTER,
        },
      });
    } else {
      this.setState(
        produce((draft) => {
          draft.warningTelefono = false;
        })
      );
    }
  };

  existeFactor = (factorSeguridad) => {
    const { factoresSeguridad } = this.props;
    return Boolean(factoresSeguridad.find((factor) => factor.codigo === factorSeguridad));
  };

  render() {
    const {
      factorSelected,
      isLoadingFactoresSeguridad,
      errorFactoresSeguridad,
      isLoadingDesafioSMS,
      errorDatosPersonales,
      isLoadingDatosPersonales,
      isLoadingSelectMetodo,
      type,
    } = this.props;
    const { warningTelefono, warningBloqueo } = this.state;

    return (
      <MetodoTemplate
        optionSelected={factorSelected}
        isLoadingFactoresSeguridad={isLoadingFactoresSeguridad}
        isLoadingDesafioSMS={isLoadingDesafioSMS}
        errorFactoresSeguridad={errorFactoresSeguridad}
        errorDatosPersonales={errorDatosPersonales}
        isLoadingDatosPersonales={isLoadingDatosPersonales}
        isLoadingSelectMetodo={isLoadingSelectMetodo}
        warningTelefono={warningTelefono}
        warningBloqueo={warningBloqueo}
        type={type}
        handleChangeOpcions={this.onChangeFactor}
        handleClickContinue={this.handleClickContinue}
        existeFactor={this.existeFactor}
        handleClickRetry={this.handleClickRetry}
      />
    );
  }
}

export default Metodo;
