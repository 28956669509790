import React, { useEffect } from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SliderPage } from './styles';
import { registerGA } from '../../../../../utils/metrics';
import logger from '../../../../../utils/logger';

const Index = () => {
  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
  const pathImage = process.env.REACT_APP_CLOUDFRONT_BANNER
  useEffect(() => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/home',
      'eve-cat': 'Mensaje',
      'eve-lab': 'Banner Remanente Acreencias',
      'ambiente': 'sitio'
    });
  }, []);

  const handleClick = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/home',
      'eve-cat' : 'Click',
      'eve-lab' : 'Banner Remanente Acreencias - confirmar datos',
      'ambiente': 'sitio'
    })
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'PODER_ESPECIAL_BANNER',
      informacion_adicional: {}
    })
  }

  return (
    <SliderPage src={`${pathImage}web/foto_banner_desk.png`} srcMobile={`${pathImage}responsive/foto_banner_mob.png`}>
      <div className="poder-especial-container row">
        <div className="col-xs-12 col-sm-8 col-md-7">
          <div>
            <label className="poder-especial-title">¿Dónde quieres recibir tus acreencias y remanente?</label>
          </div>
          <div className="bmb-2">
            <label className="poder-especial-subtitle">Confirma tus datos y autoriza el abono a tu cuenta de preferencia</label>
          </div>
          <Link
            to={'ecd/poder-especial/datos-bancarios'}
            onClick={() => handleClick()}
          >
            <Button bgColor="red" color="white" className="btn_red">
              Confirmar datos
            </Button>
          </Link>
        </div>
      </div>
    </SliderPage>
  );
};

Index.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  banner: PropTypes.object,
};

Index.defaultProps = {
  banner: {},
};

export default Index;
