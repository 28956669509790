import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';

import { transferenciaEntreProductosActions } from '../../../application/actions/transferenciaEntreProductos';
import { loadersActions } from '../../../application/actions/ui_loaders';

import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';
import { flujoFactoresTefInternas } from '../../../ui/utils/constants';

import api from '../../api';

const apiEntreProductos = api(process.env.REACT_APP_ENTRE_PRODUCTOS);
const apiEntreProductosComprobante = api(process.env.REACT_APP_TEF_COMPROBANTE_ENTRE_PRODUCTOS);
const isReqFactores = process.env.REACT_APP_REQ_FACTORES_TEFSINTERNAS;

export function* selectCuentaTef(action) {
  try {
    const { params, history, pathname } = action.payload;

    yield put(loadersActions.fetchLoadingSelectTefEntreProducto({ isLoading: true }));

    const { data, error } = yield call(apiEntreProductos.post, 'v1/cuenta/seleccionar', params);

    if (data) {
      yield put(transferenciaEntreProductosActions.selectCuentaSuccess({ ...data }));
      yield put(loadersActions.fetchLoadingSelectTefEntreProducto({ isLoading: false }));

      if (history) {
        history.push(pathname);
      }
    } else {
      yield put(
        transferenciaEntreProductosActions.selectCuentaFailed({ errorSeleccionarLog: error })
      );
      yield put(loadersActions.fetchLoadingSelectTefEntreProducto({ isLoading: false }));
    }
  } catch (err) {
    yield put(loadersActions.fetchLoadingSelectTefEntreProducto({ isLoading: false }));
  }
}

export function* fetchReglas(action) {
  try {
    const { params, codigo } = action.payload || {};

    yield put(loadersActions.fetchLoadingReglasEntreProductos({ isLoading: true }));

    const { data, error } = yield call(
      apiEntreProductos.post,
      `reglas/v2/obtener/${codigo}`,
      params
    );

    if (data) {
      yield put(
        transferenciaEntreProductosActions.fetchReglasSuccess({
          reglasEntreProductos: data,
        })
      );
      yield put(loadersActions.fetchLoadingReglasEntreProductos({ isLoading: false }));
    } else {
      yield put(transferenciaEntreProductosActions.fetchReglasFailed({ errorReglasLog: error }));
      yield put(loadersActions.fetchLoadingReglasEntreProductos({ isLoading: false }));
    }
  } catch (err) {
    yield all([
      yield put(transferenciaEntreProductosActions.fetchReglasFailed({ errorReglasLog: err })),
      yield put(loadersActions.fetchLoadingReglasEntreProductos({ isLoading: false })),
    ]);
  }
}

export function* validacionTefEntreProductos(action) {
  try {
    const { params, track } = action.payload || {};

    yield put(loadersActions.fetchLoadingValidacionTefEntreProductos({ isLoading: true }));
    const numero = (isReqFactores && isReqFactores.toLowerCase() === "false")
      ? flujoFactoresTefInternas.sinFactores.idValidaNuevaTef
      : flujoFactoresTefInternas.conFactores.idValidaNuevaTef;
    const { data, error } = yield call(apiEntreProductos.post, 'v2/validar', params, {
      track: `${track}-${numero}`,
    });

    yield all([
      yield put(
        data
          ? transferenciaEntreProductosActions.fetchValidacionTefSuccess()
          : transferenciaEntreProductosActions.fetchValidacionTefFailed({
              errorValidacionLog: error,
            })
      ),
      yield put(loadersActions.fetchLoadingValidacionTefEntreProductos({ isLoading: false })),
    ]);
  } catch (err) {
    yield all([
      yield put(
        transferenciaEntreProductosActions.fetchValidacionTefFailed({ errorValidacionLog: err })
      ),
      yield put(loadersActions.fetchLoadingValidacionTefEntreProductos({ isLoading: false })),
    ]);
  }
}

export function* ejecucionTefEntreProductos(action) {
  try {
    const { idTransferencia, track, history, pathname } = action.payload || {};

    const pathEjecutar = `v1/ejecutar/${idTransferencia}`;
    const pathDetalles = `v1/detalle/obtener/${idTransferencia}`;

    yield put(loadersActions.fetchLoadingEjecucionTefEntreProductos({ isLoading: true }));

    const getTrack = (secuencia) => {
      let numero = null;
      if (secuencia === 'ValidacionTef') {
        numero = (isReqFactores && isReqFactores.toLowerCase() === "false")
          ? flujoFactoresTefInternas.sinFactores.idValidacionTef
          : flujoFactoresTefInternas.conFactores.idValidacionTef;
      } else if (secuencia === 'Comprobante') {
        numero = (isReqFactores && isReqFactores.toLowerCase() === "false")
          ? flujoFactoresTefInternas.sinFactores.idComprobante
          : flujoFactoresTefInternas.conFactores.idComprobante;
      }
      return { track: `${track}-${numero}` };
    };

    const { data, error } = yield call(
      apiEntreProductos.post,
      pathEjecutar,
      null,
      getTrack('ValidacionTef')
    );

    if (history) {
      history.push(pathname);
    }

    if (data) {
      const { data: successData, error: errorData } = yield call(
        apiEntreProductos.get,
        pathDetalles,
        null,
        getTrack('Comprobante')
      );

      if (successData) {
        yield put(
          transferenciaEntreProductosActions.fetchDetallesTefSuccess({
            ...successData,
          })
        );
      } else {
        yield put(
          transferenciaEntreProductosActions.fetchDetallesTefFailed({
            errorTransferenciaLog: errorData,
          })
        );
      }
    } else {
      yield put(
        transferenciaEntreProductosActions.fetchDetallesTefFailed({ errorTransferenciaLog: error })
      );
    }

    yield put(loadersActions.fetchLoadingEjecucionTefEntreProductos({ isLoading: false }));
  } catch (error) {
    yield all([
      yield put(
        transferenciaEntreProductosActions.fetchDetallesTefFailed({ errorTransferenciaLog: error })
      ),
      yield put(loadersActions.fetchLoadingEjecucionTefEntreProductos({ isLoading: false })),
    ]);
  }
}

export function* descargarComprobante(action) {
  try {
    const { idTransferencia } = action.payload;

    const { data } = yield call(apiEntreProductosComprobante.get, `v1/obtener/${idTransferencia}`);

    if (data) {
      downloadPDFBase64OtherTab(data, 'comprobante');
    }
  } catch {}
}

export function* watchSelectCuentaTef() {
  yield takeLatest(transferenciaEntreProductosActions.selectCuentaTef.type, selectCuentaTef);
}

export function* watchFetchReglas() {
  yield takeLatest(transferenciaEntreProductosActions.fetchReglas.type, fetchReglas);
}

export function* watchValidacionTefEntreProductos() {
  yield takeLatest(
    transferenciaEntreProductosActions.validacionTefEntreProductos,
    validacionTefEntreProductos
  );
}

export function* watchEjecucionTefEntreProductos() {
  yield takeLatest(
    transferenciaEntreProductosActions.ejecucionTefEntreProductos.type,
    ejecucionTefEntreProductos
  );
}

export function* watchDescargarComprobante() {
  yield takeLatest(
    transferenciaEntreProductosActions.descargarComprobante.type,
    descargarComprobante
  );
}

export default function* rootSaga() {
  yield spawn(watchFetchReglas);
  yield spawn(watchValidacionTefEntreProductos);
  yield spawn(watchEjecucionTefEntreProductos);
  yield spawn(watchSelectCuentaTef);
  yield spawn(watchDescargarComprobante);
}
