import styled from 'styled-components';
import CardBoxOriginal from '@coopeuch-components/web/molecules/CardBox';

export const CardBox = styled(CardBoxOriginal)`
  color: #383638;
`;

export const Paragraph = styled.div`
  @media (min-width: 64em) {
    margin-left: ${({ marginLeft }) => `${marginLeft ? marginLeft : '0'}px`};
  }
  font-size: ${({ size }) => `${size ? size : '16'}px`};
  letter-spacing: 0;
  line-height: 26px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  & .login--wrapper-checkbox {
    width: 100%;
    display: flex;
    margin-left: -6px;
    margin-top: -12px;
    margin-bottom: 12px;
  }
  .label-checkbox {
    line-height: 20px;
  }
`;

export const HRow = styled.hr`
  border: 1px solid #e9e9e9;
`;
export const CustomList = styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 50%;
      position: absolute;
      content: '';
      display: inline-block;
      margin: auto 16px auto 0;
      height: 6px;
      bottom: 0;
      width: 6px;
      left: -16px;
      top: 0;
    }
  }
`;

export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;
