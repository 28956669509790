const initState = {
  errorReglas: false,
  reglasEntreProductos: {
    reglas: [],
    monto: 0,
    cantidad: 0,
    isNuevo: false,
    permiteTransferir: true,
  },
  idTransferencia: '',
  trackId: '',
  errorSelectCuentaTef: false,
  validacionTefIsSuccess: false,
  validacionTefIsFailed: false,
  detallesTef: {},
  errorDetallesTef: false,
  errorReglasLog: '',
  errorIndicadorLog: '',
  errorSeleccionarLog: '',
  errorValidacionLog: '',
  errorTransferenciaLog: '',
  contadorProTef: 0,
};

export default initState;
