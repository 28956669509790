const initState = {
  creditos: { success: false, error: false, errorCode: null, data: null, date: null },
  cuentas: { success: false, error: false, data: null, date: null },
  pago: { success: false, error: false, errorCode: '', data: null, date: null },
  detallePago: { success: false, error: false, data: null, date: null },
  historialPagado: { success: false, error: false, data: null, date: null },
  reprogramacionCuota: { success: false, error: false, data: null},
};

export default initState;
