import React, { useRef } from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import ButtonBack from '../../../../organism/ActivacionTarjetasKit/ButtonBack';
import TimerButton from '../TimerButton';
import Countdown from '../CountDown';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetasKit/texts';
import { phoneNumbers } from '../../../../../utils/functions';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const FormSMS = ({
  counterActive,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  errorTimeOut,
  retryError,
  handleSetSmsCode,
  authorizeBtn,
  handleClickValidateSMS,
  validateSMS,
  smsCodeOne,
  smsCodeTwo,
  smsCodeThree,
  smsCodeFour,
  smsCodeFive,
  smsCodeSix,
  persona,
  timerValue,
  backToActivacion,
}) => {
  const refCoor1 = useRef();
  const refCoor2 = useRef();
  const refCoor3 = useRef();
  const refCoor4 = useRef();
  const refCoor5 = useRef();
  const refCoor6 = useRef();

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'cor1') {
      handleSetSmsCode(id, value);
      if (value.length === 1) {
        refCoor2.current.focus();
      }
    }

    if (id === 'cor2') {
      handleSetSmsCode(id, value);
      if (value.length === 1) {
        refCoor3.current.focus();
      } else {
        refCoor1.current.focus();
      }
    }
    if (id === 'cor3') {
      handleSetSmsCode(id, value);
      if (value.length === 1) {
        refCoor4.current.focus();
      } else {
        refCoor2.current.focus();
      }
    }
    if (id === 'cor4') {
      handleSetSmsCode(id, value);
      if (value.length === 1) {
        refCoor5.current.focus();
      } else {
        refCoor3.current.focus();
      }
    }
    if (id === 'cor5') {
      handleSetSmsCode(id, value);
      if (value.length === 1) {
        refCoor6.current.focus();
      } else {
        refCoor4.current.focus();
      }
    }
    if (id === 'cor6') {
      handleSetSmsCode(id, value);
      if (value.length === 0) {
        refCoor5.current.focus();
      }
    }
  };
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Autorización con Clave Dinámica SMS
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="contentSMS">
            <Countdown
              ilustration="sms"
              withCountdownAnimation={true}
              timer={timerValue}
              errorTimeOut={errorTimeOut}
              validateSMS={validateSMS}
            />
            {errorTimeOut === true && (
              <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
            )}
            {validateSMS.data?.valido === false && errorTimeOut === false && (
              <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
            )}
            {errorTimeOut === true && <p className="contentSMS__title">¡Se agotó el tiempo!</p>}
            {validateSMS.data?.valido === false && errorTimeOut === false && (
              <p className="contentSMS__title">¡Lo sentimos!</p>
            )}
            <p className="contentSMS__text">
              {errorTimeOut === false &&
                validateSMS.data === null &&
                `Te enviamos un SMS con el código a tu celular ${phoneNumbers(
                  persona.telefono
                )}. Si este no es tu número, por favor comunícate con nuestro Contact Center al 600 200 1200.`}
              {errorTimeOut === true &&
                'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.'}
              {validateSMS.data?.valido === false &&
                errorTimeOut === false &&
                'El código SMS no corresponde. Recuerda que al tercer intento erróneo, la clave será bloqueada por seguridad.'}
            </p>
            {errorTimeOut === false && (
              <div className="contentSMS__strong">
                <p className="contentSMS__strong-text">Clave dinámica SMS</p>
              </div>
            )}

            {errorTimeOut === false && (
              <div className="contentSMS__form">
                <input
                  type="number"
                  maxLength={1}
                  className={`contentSMS__form-input ${
                    errorTimeOut === true || (validateSMS.data?.valido === false && 'errorSMS')
                  }`}
                  id="cor1"
                  onChange={handleChange}
                  ref={refCoor1}
                  value={smsCodeOne}
                />
                <input
                  type="number"
                  maxLength={1}
                  className={`contentSMS__form-input ${
                    errorTimeOut === true || (validateSMS.data?.valido === false && 'errorSMS')
                  }`}
                  id="cor2"
                  onChange={handleChange}
                  ref={refCoor2}
                  value={smsCodeTwo}
                />
                <input
                  type="number"
                  maxLength={1}
                  className={`contentSMS__form-input ${
                    errorTimeOut === true || (validateSMS.data?.valido === false && 'errorSMS')
                  }`}
                  id="cor3"
                  onChange={handleChange}
                  ref={refCoor3}
                  value={smsCodeThree}
                />
                <input
                  type="number"
                  maxLength={1}
                  className={`contentSMS__form-input ${
                    errorTimeOut === true || (validateSMS.data?.valido === false && 'errorSMS')
                  }`}
                  id="cor4"
                  onChange={handleChange}
                  ref={refCoor4}
                  value={smsCodeFour}
                />
                <input
                  type="number"
                  maxLength={1}
                  className={`contentSMS__form-input ${
                    errorTimeOut === true || (validateSMS.data?.valido === false && 'errorSMS')
                  }`}
                  id="cor5"
                  onChange={handleChange}
                  ref={refCoor5}
                  value={smsCodeFive}
                />
                <input
                  type="number"
                  maxLength={1}
                  className={`contentSMS__form-input ${
                    errorTimeOut === true || (validateSMS.data?.valido === false && 'errorSMS')
                  }`}
                  id="cor6"
                  onChange={handleChange}
                  ref={refCoor6}
                  value={smsCodeSix}
                />
              </div>
            )}
            <div className="contentSMS__controls">
              {errorTimeOut === true && (
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  className="contentSMS__controls-btn"
                  onClick={() => retryError('errorTime')}
                >
                  Reintentar
                </Button>
              )}

              {validateSMS.data?.valido === false && errorTimeOut === false && (
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  className="contentSMS__controls-btn"
                  onClick={() => retryError()}
                >
                  Reintentar
                </Button>
              )}
              {authorizeBtn === true && validateSMS.data === null && errorTimeOut === false && (
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  className="contentSMS__controls-btn"
                  onClick={() => handleClickValidateSMS()}
                >
                  Autorizar
                </Button>
              )}
              {errorTimeOut === false && (
                <TimerButton
                  {...{
                    counterActive,
                    setErrorTimeOut,
                    setFactorErrorTimeOut,
                    authorizeBtn,
                    errorTimeOut,
                    validateSMS,
                    timerValue,
                  }}
                />
              )}
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <div className="row">
        <div className="col-md-12">
          <ButtonBack
            onClick={() => backToActivacion(errorTimeOut ? activacionTexts.BUTTON_CANCEL : activacionTexts.BUTTON_BACK, 'sms')}
            link="/ecd/seguridad/activacion-tarjetas-kit"
            text={errorTimeOut ? activacionTexts.BUTTON_CANCEL : activacionTexts.BUTTON_BACK}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default FormSMS;
