import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import CheckBox from '../../../../atoms/CheckBox';
import InputLabel from '../../../../atoms/InputLabel';
import FactoresDeSeguridad from '../../../../organism/FactoresDeSeguridad';

import { migracionCuentaAhorroContratacionTexts } from '../../../../../containers/CuentaAhorro/texts';

import Stepper from '../../../../organism/Stepper';
import StepsActions from '../../../../organism/Stepper/StepsActions';

import Wrapper from './styles';
import { SkeletonContratacion } from './skeleton';
import ErrorCard from '../../../../organism/ErrorCard';

const steps = ['Datos Cuenta', 'Contratación', 'Comprobante'];
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const codigosError = {
  '01': 'La contraseña es incorrecta',
  '02': 'Contraseña incorrecta. Recuerda que al tercer intento se bloqueará la contraseña.',
};

const MigracionCuentaAhorroContratacionTemplate = ({
  isLoadingContratosCuentaAhorro,
  contratosCuentaAhorro,
  handleDescargarContrato,
  errorContratosCuentaAhorro,
  errorBorradorCuentaAhorro,
  checkTerminos,
  handleCheckTerminos,
  email,
  validMail,
  changeCorreo,
  password,
  changePassword,
  showPassword,
  onTogglePassword,
  errorPassword,
  handleActualizar,
  isLoadingMigrarCuentaAhorro,
  handleReturn,
  errorServiceMigrar,
  handleReturnRecuperar,
}) => {
  return (
    <Wrapper className="bpx-3 bpx-md-5 bmt-md-0 bmt-3">
      <div>
        <Stepper className="start-xs bmt-md-4 bmt-3" position={2} steps={steps} />
        {isLoadingContratosCuentaAhorro ? (
          <SkeletonContratacion />
        ) : (
          <div>
            {errorContratosCuentaAhorro || errorBorradorCuentaAhorro ? (
              <div className="bmt-md-4 bmt-3">
                <ErrorCard
                  titleText="¡Lo sentimos!"
                  descText="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
                />
              </div>
            ) : (
              <div>
                <CardBox className="bmt-4">
                  <CardBox.Header>
                    {migracionCuentaAhorroContratacionTexts.CARD_CONOCE_TITLE}
                  </CardBox.Header>
                  <CardBox.Body className="bpt-3 bpb-3">
                    <div>
                      <label className="conoce-desc-text">
                        {migracionCuentaAhorroContratacionTexts.CARD_CONOCE_DESC}
                      </label>
                    </div>
                    {contratosCuentaAhorro?.map((contrato) => (
                      <div className="bmt-4">
                        <div className="conoce-container">
                          <div className="conoce-icon-container">
                            <div>
                              <LoadIcon
                                color="#1F1E1E"
                                src={`${iconPath}note.svg`}
                                size="24"
                                className="bmt-2"
                              />
                            </div>
                          </div>
                          <div className="conoce-content-container">
                            <div>
                              <label className="contrato-title">{contrato?.titulo}</label>
                            </div>
                            <div className="bmt-2">
                              <label className="contrato-desc">{contrato?.descripcion}</label>
                            </div>
                            <div className="bmt-4 bmb-4">
                              <div className="contrato-icon-container">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => handleDescargarContrato(contrato?.nombreDocumento)}
                                >
                                  <LoadIcon
                                    src={`${iconPath}download_arrow.svg`}
                                    size="24"
                                    className="cursor-pointer"
                                  />
                                </div>
                                <label
                                  className="contrato-download bml-2 cursor-pointer"
                                  onClick={() => handleDescargarContrato(contrato?.nombreDocumento)}
                                >
                                  {migracionCuentaAhorroContratacionTexts.DESCARGAR}
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="contrato-line" />
                      </div>
                    ))}
                    <div className="contrato-box-container bmt-4">
                      <CheckBox checked={checkTerminos} onChange={handleCheckTerminos} />
                      <label className="contrato-box-text">
                        {migracionCuentaAhorroContratacionTexts.ACEPTAR_TERMINOS}
                      </label>
                    </div>
                  </CardBox.Body>
                </CardBox>
                <CardBox className="bmt-4">
                  <CardBox.Header>
                    {migracionCuentaAhorroContratacionTexts.CARD_ENVIO_TITLE}
                  </CardBox.Header>
                  <CardBox.Body>
                    <div className="bmb-4">
                      <label className="envio-title-text">
                        {migracionCuentaAhorroContratacionTexts.CARD_EMAIL_DESC}
                      </label>
                    </div>
                    <div className="bmb-3">
                      <label className="envio-input-text">
                        {migracionCuentaAhorroContratacionTexts.CARD_EMAIL_DESC_2}
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <InputLabel
                          inputClass="envio-input"
                          borderColor={validMail ? '#E81E2B' : '#646569'}
                          value={email}
                          onChange={changeCorreo}
                        />
                        {validMail && (
                          <div className="help-text dark-red text-size-s back--icon">
                            <div className="row ml-0 pt-2">
                              <LoadIcon
                                src={`${iconPath}circle_close.svg`}
                                size={14}
                                className="mr-4 dark-red"
                              />
                              <p>{validMail}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </CardBox.Body>
                </CardBox>
                <CardBox className="bmt-4">
                  <CardBox.Header>
                    {migracionCuentaAhorroContratacionTexts.CARD_FIRMA_TITLE}
                  </CardBox.Header>
                  <CardBox.Body>
                    {errorServiceMigrar?.code === '03' ? (
                      <ErrorCard
                        titleText="¡Clave bloqueada!"
                        descText='<div class="clave-bloqueada">
                                Por tu seguridad, hemos bloqueado tu clave. Si lo deseas puedes
                                volver a cambiar tu clave.
                              </div>'
                        isCard={false}
                        showButton={true}
                        buttonText="Cambiar clave"
                        buttonClass="boton-bloqueo"
                        onClickButton={handleReturnRecuperar}
                      />
                    ) : (
                      <div>
                        <div className="bmb-4">
                          <label className="firma-text">
                            {migracionCuentaAhorroContratacionTexts.CARD_FIRMA_DESC}
                            <b>{migracionCuentaAhorroContratacionTexts.CARD_FIRMA_LEY}</b>
                          </label>
                        </div>
                        <div className="bmb-3">
                          <label className="firma-input-label">
                            {migracionCuentaAhorroContratacionTexts.CARD_FIRMA_INGRESA}
                          </label>
                        </div>
                        <div className="row">
                          <div
                            className={
                              errorServiceMigrar?.code === '02'
                                ? 'col-md-4 firma-error-largo'
                                : 'col-md-4'
                            }
                          >
                            <FactoresDeSeguridad
                              tipo={1.1}
                              value={password}
                              onHandlePasswordInput={changePassword}
                              showClave={showPassword}
                              onTogglePassword={onTogglePassword}
                              error={
                                codigosError[errorServiceMigrar?.code] ||
                                errorPassword ||
                                errorServiceMigrar?.message
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </CardBox.Body>
                </CardBox>
                <div className="bmt-4 bmb-4">
                  <StepsActions
                    nextText={migracionCuentaAhorroContratacionTexts.BOTON_AUTORIZAR}
                    disableNextAction={validMail || !checkTerminos || !(password.length > 5)}
                    nextAction={handleActualizar}
                    loading={isLoadingMigrarCuentaAhorro}
                    backAction={handleReturn}
                    hideNextAction={errorServiceMigrar?.code === '03'}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default MigracionCuentaAhorroContratacionTemplate;
