import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/ftu';
// ------- SAGA ------ //
function fetchFtuObtenerSuccess(state, action) {
  state.ftuObtener.success = true;
  state.ftuObtener.error = false;
  state.ftuObtener.data = action.payload;
}
function fetchFtuObtenerError(state) {
  state.ftuObtener.success = false;
  state.ftuObtener.error = true;
  state.ftuObtener.data = null;
}
const fetchFtuEnviar = createAction('fetchFtuEnviar');
const fetchFtuObtener = createAction('fetchFtuObtener');
const ftuReducer = createSlice({
  name: 'ftu',
  initialState,
  reducers: {
    fetchFtuObtenerSuccess,
    fetchFtuObtenerError,
  },
});
export const ftuActions = {
  ...ftuReducer.actions,
  fetchFtuEnviar,
  fetchFtuObtener,
};
export default ftuReducer.reducer;