import React, { useMemo, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Input from '@coopeuch-components/web/atoms/Input';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';

import SkeletonPagarTarjetaPago from './Skeleton';
import StepsActions from '../../../organism/Stepper/StepsActions';
import Stepper from '../../../organism/Stepper';
import DashboardCard from '../../../organism/DashboardCard';
import PageTitle from '../../../atoms/PageTitle';
import Select from '../../../atoms/Select';
import ErrorCard from '../../../organism/ErrorCard';

import { capitalize, formatCurrency, hiddeNumber } from '../../../../utils/functions';
import { hasValuesOnObject, isEmailValid } from '../../../../utils/validators';

import Wrapper from './styles';
import { registerGA } from '../../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const errMessages = {
  4: {
    titleText: 'Clave Bloqueada.',
    descText: `<span class='black'>Por tu seguridad, hemos bloqueado tu clave. Si lo deseas <br /> puedes volver a cambiar tu clave.</span>`,
    buttonText: 'Cambiar clave',
    showButton: true,
  },
  3: {
    titleText: 'Datos incorrectos',
    descText: `<span class='black'>La constraseña ingresada es incorrecta, por favor vuelve a intentar. Recuerda que <b>al tercer intento erróneo</b>, tu clave será bloqueada</span>`,
    buttonText: 'Reintentar',
    showButton: true,
  },
  2: {
    descText: `<span class='black'>La constraseña ingresada es incorrecta. <br /> Por favor vuelve a intentar.</span>`,
    buttonText: 'Reintentar',
    showButton: true,
  },
};

function IconoOjo({ passwordInputIcon }) {
  return (
    <LoadIcon
      src={`${iconPath}${passwordInputIcon}.svg`}
      size={20}
      color="black"
      className="eye-icon"
    />
  );
}

const PagarTarjetaPagoTemplate = (props) => {
  const {
    loading,
    error,
    monto,
    numeroTarjeta,
    datosTarjeta,
    cuentas,
    cuentaActual,
    email,
    password,
    showPassword,
    handlePassword,
    handlePasswordVisibility,
    handleCuenta,
    handleManualEmail,
    handlePrevStep,
    handleSubmit,
    clearPasswordStates,
  } = props;

  const steps = useMemo(() => ['Monto a pagar', 'Pago', 'Comprobante'], []);

  const saldoDisponible = useMemo(() => {
    return cuentas?.find((cuenta) => cuenta?.value === cuentaActual)?.saldoDisponible;
  }, [cuentaActual, cuentas]);

  const emailError = useMemo(() => !isEmailValid(email) || email?.length <= 0, [email]);
  const passwordError = useMemo(() => password?.length < 6 && password?.length >= 1, [password]);

  const passwordInputIcon = useMemo(() => `eye_${showPassword ? 'open' : 'close'}`, [showPassword]);

  const thereIsEnoughMoney = useMemo(
    () => Number(saldoDisponible) > Number(monto),
    [monto, saldoDisponible]
  );

  useEffect(() => {
    if (error?.id === 4) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/tarjeta-de-credito/pagar/paso-2',
        'eve-cat': 'Error',
        'eve-lab': 'Clave bloqueada',
      });
    }
  }, [error?.id]);

  return (
    <Wrapper className="w-100">
      <PageTitle
        id="dap-title-page"
        title="Tarjeta de Crédito"
        icon={<LoadIcon src={`${iconPath}mastercard.svg`} color="#383638" />}
      />

      <div className="w-100 margin-after-title content">
        {error?.id === 500 ? (
          <div className="err-component modulos-container">
            <ErrorCard
              largeMessage
              descText="<span class='black'>No se ha podido realizar el pago de tu Tarjeta de Crédito Coopeuch.<br />Vuelve a intentarlo más tarde."
            />
          </div>
        ) : (
          <Fragment>
            <Stepper className="start-xs" position={2} steps={steps} />

            {loading ? (
              <SkeletonPagarTarjetaPago>
                <SkeletonPagarTarjetaPago.Cuenta />
                <SkeletonPagarTarjetaPago.Summary />
                <SkeletonPagarTarjetaPago.Email />
                <SkeletonPagarTarjetaPago.Password />
              </SkeletonPagarTarjetaPago>
            ) : (
              <Fragment>
                <div className="row modulos-container">
                  <div className="col-xs-12">
                    <DashboardCard classes="modulo">
                      <DashboardCard.Head title="Cuenta de origen" borderBottom="complete" />
                      <DashboardCard.Body classes="modulo-content">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="black text-bold pb-12">Número de cuenta</p>
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                {cuentas?.length >= 2 ? (
                                  <Select
                                    borderColor="#646569"
                                    placeholder="Selecciona Cuenta"
                                    onChange={handleCuenta}
                                    options={cuentas}
                                    value={cuentaActual}
                                    size={48}
                                  />
                                ) : (
                                  <div className="text-size-s black cv--resumen--seleccion">
                                    {cuentaActual && (
                                      <Fragment>Cuenta Vista Coopeuch: N° {cuentaActual}</Fragment>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="black pb-12 modulo-second-text">Saldo disponible:</p>
                            <p className="black text-bold text-size-big saldo-disponible">
                              {formatCurrency(saldoDisponible)}
                            </p>
                          </div>

                          {!thereIsEnoughMoney && (
                            <div className="col-xs-12">
                              <Alert
                                bgType="warning"
                                iconType="warning"
                                show
                                className="pr-0 modulos-container alert--wrapper"
                              >
                                No tienes saldo suficiente. Te invitamos a abonar saldo a tu cuenta.
                              </Alert>
                            </div>
                          )}
                        </div>
                      </DashboardCard.Body>
                    </DashboardCard>
                  </div>

                  <div className="col-xs-12">
                    <DashboardCard classes="modulo">
                      <DashboardCard.Head title="Resumen de pago" borderBottom="complete" />
                      <DashboardCard.Body classes="modulo-content">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="black pb-12">Monto a pagar</p>
                            <p className="black text-bold text-size-big">{formatCurrency(monto)}</p>
                          </div>

                          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                            <p className="black pb-12 modulo-second-text">
                              {capitalize(datosTarjeta?.descripcionProducto || '')}
                            </p>
                            <p className="black">{hiddeNumber(numeroTarjeta)}</p>
                          </div>
                        </div>
                      </DashboardCard.Body>
                    </DashboardCard>
                  </div>

                  {thereIsEnoughMoney && (
                    <Fragment>
                      <div className="col-xs-12">
                        <DashboardCard classes="modulo">
                          <DashboardCard.Head title="Datos de Contacto" borderBottom="complete" />
                          <DashboardCard.Body classes="modulo-content pb-12">
                            <div className="row">
                              <div className="col-xs-12">
                                <Alert
                                  bgType="info"
                                  iconType="info"
                                  show
                                  className="pr-0 alert--wrapper"
                                >
                                  Estos datos no reemplazan tus datos actuales en nuestros
                                  registros.
                                </Alert>
                              </div>
                              <div className="col-xs-12 start-xs">
                                <p className="ml-4 mb-24 mt-24 black">
                                  Para finalizar, confirma tu información de contacto. En caso
                                  contrario ingresa un email.
                                </p>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs black">
                                <div className="row">
                                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                    <div className="ml-4 mr-4 with--help-text">
                                      <p className="text-bold mb-14">Email</p>
                                      <div className="input-correo-or-password-wrapper">
                                        <Input
                                          textSize={16}
                                          size={48}
                                          borderColor={emailError ? '#cc284e' : '#646569'}
                                          onChange={handleManualEmail}
                                          value={email}
                                          type="text"
                                          inputClass="pl-28"
                                        />

                                        {emailError && (
                                          <div className="error-warning-password row pl-8 mt-2">
                                            <LoadIcon
                                              src={`${iconPath}circle_close.svg`}
                                              size={14}
                                              className="err-color"
                                            />
                                            <span className="text-size-s err-color pl-2">
                                              {email?.length <= 0
                                                ? 'Campo obligatorio'
                                                : 'Debes ingresar un email válido'}
                                            </span>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </DashboardCard.Body>
                        </DashboardCard>
                      </div>

                      <div className="col-xs-12">
                        <DashboardCard classes="modulo">
                          <DashboardCard.Head title="Firma electrónica" borderBottom="complete" />
                          <DashboardCard.Body classes="modulo-content">
                            {hasValuesOnObject(error) ? (
                              <div className="row center-xs err-component">
                                <div className="col-xs-8">
                                  <ErrorCard
                                    buttonClass="button-error-class"
                                    isCard={false}
                                    onClickButton={clearPasswordStates}
                                    {...errMessages?.[error?.id]}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs pl-4 black">
                                  <div className="row">
                                    <div className="col-xs-12">
                                      <p className="ml-4 mb-24 black">
                                        Valida la transacción con tu clave de internet Coopeuch
                                      </p>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                      <div className="ml-4 mr-4 with--help-text ">
                                        <p className="text-bold mb-14 black">
                                          Ingresa tu clave de Internet
                                        </p>
                                        <div className="input-correo-or-password-wrapper">
                                          <LoadIcon
                                            src={`${iconPath}lock.svg`}
                                            size={20}
                                            color="#1F1E1E"
                                            className="lock-icon"
                                          />
                                          <InputLabel
                                            textSize={16}
                                            size={48}
                                            borderColor={passwordError ? '#cc284e' : '#646569'}
                                            onChange={handlePassword}
                                            value={password}
                                            type={showPassword ? 'text' : 'password'}
                                            inputClass="pl-28"
                                            placeholder="•••••••"
                                            iconFunc={handlePasswordVisibility}
                                            iconRight={
                                              <IconoOjo passwordInputIcon={passwordInputIcon} />
                                            }
                                            maxLength={8}
                                          />
                                          {passwordError ? (
                                            <div className="error-warning-password row pl-8">
                                              <LoadIcon
                                                src={`${iconPath}circle_close.svg`}
                                                size={14}
                                                className="err-color"
                                              />
                                              <span className="text-size-s err-color pl-2">
                                                La clave debe tener entre 6 y 8 caract.
                                              </span>
                                            </div>
                                          ) : (
                                            <span className="text-size-s help-text pl-2">
                                              Cantidad de caracteres
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </DashboardCard.Body>
                        </DashboardCard>
                      </div>
                    </Fragment>
                  )}

                  <div className="col-xs-12">
                    <StepsActions
                      backAction={handlePrevStep}
                      nextAction={() => handleSubmit(thereIsEnoughMoney)}
                      nextText={thereIsEnoughMoney ? 'Pagar' : 'Continuar'}
                      hideNextAction={hasValuesOnObject(error)}
                      disableNextAction={emailError || passwordError || password?.length === 0}
                      id="pago-tarjeta-credito"
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
};

PagarTarjetaPagoTemplate.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  monto: PropTypes.number,
  numeroTarjeta: PropTypes.number,
  datosTarjeta: PropTypes.object,
  cuentas: PropTypes.array,
  cuentaActual: PropTypes.object,
  email: PropTypes.string,
  password: PropTypes.string,
  showPassword: PropTypes.bool,
  handlePassword: PropTypes.func,
  handlePasswordVisibility: PropTypes.func,
  handleCuenta: PropTypes.func,
  handleManualEmail: PropTypes.func,
  handlePrevStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  clearPasswordStates: PropTypes.func,
};

export default PagarTarjetaPagoTemplate;
