import React, { Fragment, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import DashboardCard from '../../../DashboardCard';
import FactoresDeSeguridad from '../../../FactoresDeSeguridad';

import { hasValuesOnObject } from '../../../../../utils/validators';
import { phoneNumbers } from '../../../../../utils/functions';

function ConfirmacionFactor(props) {
  const {
    desafios,
    smsErrors,
    timer,
    emailError,
    stepFactor,
    telefono,
    handleChangeDesafios,
    handleSubmit,
    clearSmsStates,
  } = props;

  const onClick = useCallback(() => {
    if (hasValuesOnObject(smsErrors)) {
      return clearSmsStates();
    }

    return handleSubmit();
  }, [handleSubmit, clearSmsStates, smsErrors]);

  const message = useMemo(
    () =>
      `<div class='black'>Te enviamos un SMS con el código a tu celular <b>${phoneNumbers(
        telefono || ''
      )}</b> Si este no es tu número por favor contáctate al Call Center al <b>600 200 1200</b>.</div>`,
    [telefono]
  );

  const desafiosValues = useMemo(
    () => ({
      valorDesafio1: desafios[1],
      valorDesafio2: desafios[2],
      valorDesafio3: desafios[3],
      valorDesafio4: desafios[4],
      valorDesafio5: desafios[5],
      valorDesafio6: desafios[6],
    }),
    [desafios]
  );

  return (
    <Fragment>
      <DashboardCard classes="dap--card">
        <DashboardCard.Head title="Código SMS enviado a tu celular" borderBottom="complete" />
        <DashboardCard.Body classes="pt-24 pl-32 pr-32 dap--card--content">
          <div className="row center-xs pb-12">
            <FactoresDeSeguridad
              tipo={3.1}
              buttonText="Autorizar"
              titleClass="text-size-xxl pt-12 pb-24 black"
              messageClass="pb-24 lh-1_5 black"
              className="col-md-8"
              showInputInError={true}
              message={message}
              isButtonDisabled={emailError}
              onChangeInput={handleChangeDesafios}
              withCountdownAnimation
              onClickButton={onClick}
              {...desafiosValues}
              {...(hasValuesOnObject(smsErrors) ? smsErrors : {})}
              timer={timer}
              stepFactor={stepFactor}
              activeTimer={stepFactor === 1 || stepFactor === 2}
            />
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </Fragment>
  );
}

ConfirmacionFactor.propTypes = {
  desafios: PropTypes.object,
  smsErrors: PropTypes.object,
  timer: PropTypes.number,
  stepFactor: PropTypes.number,
  telefono: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleChangeDesafios: PropTypes.func,
};

ConfirmacionFactor.defaultProps = {
  desafios: {},
  smsErrors: {},
  timer: null,
  stepFactor: null,
  telefono: '',
  handleChangeDesafios: null,
};

export default ConfirmacionFactor;
