import {call, put, spawn, takeLatest} from 'redux-saga/effects';
import api from '../../api';
import {desbloqueoTdActions} from "../../../application/actions/desbloqueoTd";
import {loadersActions} from "../../../application/actions/ui_loaders";

const apiDesbloqueoTd = api(process.env.REACT_APP_API_DESBLOQUEOTD);

export function* fetchValidarBloqueoTd() {
console.log(">> log desde fetch desbloqueo");
  try {
    const token = localStorage.getItem('token');
    const response = yield call(apiDesbloqueoTd.post, '/tarjetas/listar', {token});
    const {data} = response;
    if (data) {
      console.log(">> response desde fetch", response )
      yield put(desbloqueoTdActions.fetchValidarBloqueoTdSuccess({data}));
    } else {
      yield put(desbloqueoTdActions.fetchValidarBloqueoTdError({error: response}));
    }

    yield put(loadersActions.fetchLoadingValidarBloqueoTd({isLoading: false}));

  } catch (error) {
    yield put(desbloqueoTdActions.fetchValidarBloqueoTdError({error}));
  }
}

export function* watchValidarBloqueoTd() {
  yield takeLatest(desbloqueoTdActions.fetchValidarBloqueoTd.type, fetchValidarBloqueoTd);
}

export default function* rootSaga() {
  yield spawn(watchValidarBloqueoTd);
}
