import React, { useRef } from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import ButtonBack from '../../../ActivacionTarjetas/ButtonBack';
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
import imagen from '../../../../../assets/tarjetaDebitoH.svg';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardTarjetaDebito = ({
  error,
  handleSetCardSerie,
  handleClickContinue,
  disabledBtn,
  retryButton,
  cardSerieOne,
  cardSerieTwo,
  cardSerieThree,
  cardSerieFour,
  handleClickBack,
}) => {
  const refSerie1 = useRef();
  const refSerie2 = useRef();
  const refSerie3 = useRef();
  const refSerie4 = useRef();

  const handleChange = (e) => {
    const { id, value } = e.target;
    console.log("handleChage" , id)
    if (id === 'serie1') {
      if (value.length === 1 && value.charCodeAt()>47 && value.charCodeAt()<58) {
        handleSetCardSerie(id, value);
        refSerie2.current.focus();
      }else if (value.length > 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value.substr(-1));
        refSerie2.current.focus();
      }else{
        handleSetCardSerie(id, '');
        e.target.value="";
      }
    }
  
    if (id === 'serie2') {
      if(value.length === 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value);
        refSerie3.current.focus();
      }else if (value.length > 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value.substr(-1));
        refSerie3.current.focus();
      }else{
        handleSetCardSerie(id, '');
        e.target.value="";
      }
    }
    if (id === 'serie3') {
      if(value.length === 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value);
        refSerie4.current.focus();
      }else if (value.length > 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value.substr(-1));
        refSerie4.current.focus();
      }else{
        handleSetCardSerie(id, '');
        e.target.value="";
      }
    }
    if (id === 'serie4') {
      if(value.length === 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value);
      }else if (value.length > 1 && value.charCodeAt()>47 && value.charCodeAt()<58){
        handleSetCardSerie(id, value.substr(-1));
        refSerie4.current.focus();
      }else{
        handleSetCardSerie(id, '');
        e.target.value="";
      }
    }
    
  };
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Activa tu Tarjeta de Débito
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="cardCoordenadas">
            {error === 'TC-01' || error === 'TC-04' ? (
              <LoadIcon
                src={`${iconPath}ilustracion_error.svg`}
                className="cardCoordenadas__image"
                size={180}
              />
            ) : (
              <LoadIcon
                src={
                  //`${iconPath}kit/tarjeta-coopeuch-mano.svg`
                  imagen
                }
                className="cardCoordenadas__image"
                size={180}
              />
            )}
            {error === 'TC-01' && (
              <Typography align="center" tag="p" fontSize={18} className="cardCoordenadas__bold">
                ¡Lo sentimos!
              </Typography>
            )}
            {error === 'TC-04' && (
              <Typography align="center" tag="p" fontSize={18} className="cardCoordenadas__bold">
                ¡Lo sentimos!
              </Typography>
            )}
            {error === 'TC-01' || error === 'TC-04' ? (
              <Typography align="center" tag="p" fontSize={14} className="cardCoordenadas__title">
                Los números ingresados no coinciden con la numeración de su tarjeta, por favor, intente nuevamente
              </Typography>
            ) : (
              <Typography align="center" tag="p" fontSize={14} className="cardCoordenadas__title">
                Ingresa los últimos (4) dígitos de la numeración que está en la <br />
                parte frontal de tu tarjeta.
              </Typography>
            )}

            <div className="cardCoordenadas__box">
              <Typography
                tag="p"
                fontSize={14}
                fontWeight="bold"
                className="cardCoordenadas__box-description"
              >
                Nº de tarjeta
              </Typography>
              <div className="cardCoordenadas__form">
                <input
                  id="serie1"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'carCoordenadas__form-input-error'
                  }`}
                  type="text"
                  min={0}
                  max={1}
                  maxLength={1}
                  onChange={handleChange}
                  ref={refSerie1}
                  value={cardSerieOne}
                />
                <input
                  id="serie2"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'carCoordenadas__form-input-error'
                  }`}
                  type="text"
                  min={0}
                  max={1}
                  maxLength={1}
                  onChange={handleChange}
                  ref={refSerie2}
                  value={cardSerieTwo}
                />
                <input
                  id="serie3"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'carCoordenadas__form-input-error'
                  }`}
                  type="text"
                  min={0}
                  max={1}
                  maxLength={1}
                  onChange={handleChange}
                  ref={refSerie3}
                  value={cardSerieThree}
                />
                <input
                  id="serie4"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'carCoordenadas__form-input-error'
                  }`}
                  type="text"
                  min={0}
                  max={1}
                  maxLength={1}
                  onChange={handleChange}
                  ref={refSerie4}
                  value={cardSerieFour}
                />
              </div>
              {error === 'TC-01' || error === 'TC-04' ? (
                <Button
                  bgColor="#e81e2b"
                  color="white"
                  fontSize={14}
                  disabled={false}
                  onClick={retryButton}
                  className="cardCoordenadas__btn"
                >
                  {activacionTexts.BUTTON_RETRY}
                </Button>
              ) : (
                <Button
                  bgColor="#e81e2b"
                  color="white"
                  fontSize={14}
                  disabled={
                    disabledBtn
                  }
                  onClick={handleClickContinue}
                  className="cardCoordenadas__btn"
                >
                  {activacionTexts.BUTTON_CONTINUE}
                </Button>
              )}
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <div className="row">
        <div className="col-md-12">
          <ButtonBack
            link={`/ecd/seguridad/activacion-tarjetas`}
            text={activacionTexts.BUTTON_RETURN}
            onClick={() => handleClickBack(activacionTexts.BUTTON_RETURN)}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CardTarjetaDebito;
