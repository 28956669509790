import styled from 'styled-components';

export const Wrapper = styled.div`
  .bloqueo-definitivo-title {
    padding: 24px 32px;
  }
  .owner-tarjeta-text {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }
  .number-tarjeta-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .product-tarjeta-text {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }
  .cv-icon svg {
    height: 24px;
    width: 40px;
  }
  .button-style-bloqueo {
    width: 168px;
  }

  .tarjeta-mobile-container-icon {
    text-align: left;
  }

  @media (max-width: 768px) {
    .bloqueo-definitivo-title {
      padding: 16px;
    }
    .button-style-bloqueo {
      width: 100%;
    }
    .return-bloqueo-center {
      margin-left: 34%;
    }
    .product-tarjeta-text {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }
    .tarjeta-mobile-text-container {
      padding: 0;
    }
  }
`;

export default Wrapper;
