import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { creditoActions } from '../../../application/actions/credito';

// Utils
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';

import { loadersActions } from '../../../application/actions/ui_loaders';
// api
import api from '../../api';
import getOS from '../../../ui/utils/getOS';

const apiCredito = api(process.env.REACT_APP_CREDITOS);
const apiReprogramacionBff = api(process.env.REACT_APP_REPROGRAMACION_BFF);

export function* fetchCreditos() {
  try {
    const { data, error } = yield call(apiCredito.get, 'credito/v1/obtener');
    if (data) {
      yield put(creditoActions.fetchCreditosSuccess({ data }));
    } else {
      yield put(creditoActions.fetchCreditosError({ error: error.code }));
    }
  } catch (err) {
    console.error(err.message, 'fetchCreditos');
    yield put(creditoActions.fetchCreditosError({ error: '' }));
  }
}

export function* fetchPagoCredito(action) {
  const { clave, numeroCuenta, numeroCredito } = action.payload;
  try {
    const { data, error } = yield call(apiCredito.post, `credito/v1/pagar`, {
      clave,
      numeroCredito,
      numeroCuenta,
      cuotas: 1,
    });
    if (data) {
      yield put(creditoActions.fetchPagoCreditoSuccess({ data }));
    } else {
      yield put(creditoActions.fetchPagoCreditoError({ error: error.code }));
    }
  } catch (err) {
    console.error(err.message, 'fetchPagoCredito');
    yield put(creditoActions.fetchPagoCreditoError({ error: '' }));
  }
}

export function* fetchDetallePagoCredito(action) {
  try {
    const { nroTransaccion } = action.payload;
    const { data } = yield call(
      apiCredito.get,
      `credito/pago/detalle/v1/obtener/${nroTransaccion}`
    );
    if (data) {
      yield put(creditoActions.fetchDetallePagoCreditoSuccess({ data }));
    } else {
      yield put(creditoActions.fetchDetallePagoCreditoError());
    }
  } catch (err) {
    console.error(err.message, 'fetchDetallePagoCredito');
    yield put(creditoActions.fetchDetallePagoCreditoError());
  }
}

export function* fetchCConsumoHistorialPagado(action) {
  // console.log(action.payload);
  const { accountNumber, yearSelected } = action.payload;
  try {
    const { data, error } = yield call(apiCredito.post, 'credito/historial/v1/obtener', {
      numeroCredito: accountNumber,
      anio: yearSelected,
    });
    // const { data } = creditoConsumoHistorial;
    // const error = null;

    if (data) {
      yield put(creditoActions.fetchCConsumoHistorialPagadoSuccess({ data }));
      // console.log(data);
    } else {
      yield put(creditoActions.fetchCConsumoHistorialPagadoError({ error: error.code }));
    }
  } catch (err) {
    console.error(err.message, 'fetchCConsumoHistorialPagado');
    yield put(creditoActions.fetchCConsumoHistorialPagadoError({ error: '' }));
  }
}

export function* fetchPDFCConsumoHistorialPagado(action) {
  const { accountNumber, yearSelected } = action.payload;
  try {
    // yield put(creditoActions.resetDataPDFMovimientos());

    const { data } = yield call(apiCredito.post, 'credito/historial/pdf/v1/obtener', {
      numeroCredito: accountNumber,
      anio: yearSelected,
    });

    if (data) {
      downloadPDFBase64OtherTab(data);
      // yield put(
      //   creditoActions.fetchPDFCConsumoHistorialPagadoSuccess({
      //     data,
      //   })
      // );
    }
    // else if (error) {
    //   yield put(creditoActions.fetchPDFCConsumoHistorialPagadoError());
    // }
  } catch (err) {
    console.log(err.message, 'fetchPDFCConsumoHistorialPagado');
    // yield put(creditoActions.fetchPDFCConsumoHistorialPagadoError());
  }
}


export function* fetchReprogramacionCuota() {
  try {

    const optionalsHeaders = {
      os: getOS().sistemaOperativo,
      os_v: getOS().versionSo,
    };

    yield put(loadersActions.fetchLoadingReprogramacionCuotas({ isLoading: true }));
    const { data, error } = yield call(apiReprogramacionBff.get, 'reprogramaciones_validas', null , optionalsHeaders);
    if (data) {
      yield put(creditoActions.fetchReprogramacionCuotaSuccess({ data }));
      yield put(loadersActions.fetchLoadingReprogramacionCuotas({ isLoading: false }));
    } else {
      yield put(creditoActions.fetchReprogramacionCuotaError({ error: error.code }));
      yield put(loadersActions.fetchLoadingReprogramacionCuotas({ isLoading: false }));
    }
  } catch (err) {
    console.error(err.message, 'fetchReprogramacionCuota');
    yield put(creditoActions.fetchReprogramacionCuotaError({ error: '' }));
    yield put(loadersActions.fetchLoadingReprogramacionCuotas({ isLoading: false }));
  }
}



export function* watchFetchCreditos() {
  yield takeLatest(creditoActions.fetchCreditos.type, fetchCreditos);
}

export function* watchFetchPagoCredito() {
  yield takeLatest(creditoActions.fetchPagoCredito.type, fetchPagoCredito);
}

export function* watchFetchDetallePagoCredito() {
  yield takeLatest(creditoActions.fetchDetallePagoCredito.type, fetchDetallePagoCredito);
}

export function* watchFetchCConsumoHistorialPagado() {
  yield takeLatest(creditoActions.fetchCConsumoHistorialPagado.type, fetchCConsumoHistorialPagado);
}

export function* watchFetchPDFCConsumoHistorialPagado() {
  yield takeLatest(
    creditoActions.fetchPDFCConsumoHistorialPagado.type,
    fetchPDFCConsumoHistorialPagado
  );
}

export function* watchFetchReprogramacionCuota() {
  yield takeLatest(creditoActions.fetchCreditos.type, fetchReprogramacionCuota);
}

export default function* rootSaga() {
  yield spawn(watchFetchCreditos);
  yield spawn(watchFetchPagoCredito);
  yield spawn(watchFetchDetallePagoCredito);
  yield spawn(watchFetchCConsumoHistorialPagado);
  yield spawn(watchFetchReprogramacionCuota);
}
