import { put, takeLatest, call, spawn } from 'redux-saga/effects';
import { puntosActions } from '../../../application/actions/puntos';
import api from '../../api';

const apiPuntos = api(process.env.REACT_APP_PUNTOS_COOPEUCH);

export function* fetchGetPuntosCoopeuch() {
  try {
    const { data, error } = yield call(apiPuntos.get, 'v1/obtener');

    if (data) {
      yield put(puntosActions.fetchGetPuntosCoopeuchSuccess(data));
    } else {
      yield put(puntosActions.fetchGetPuntosCoopeuchError(error));
    }
  } catch (err) {
    yield put(puntosActions.fetchGetPuntosCoopeuchError(err));
  }
}

export function* fetchOpenSesionPuntos() {
  try {
    const { data, error } = yield call(apiPuntos.get, 'token/v1/generar');

    if (data) {
      window
        .open(`${process.env.REACT_APP_L360}?route=api/callback&token=${data.token}`, '_blank')
        .focus();
    } else {
      yield put(puntosActions.fetchGetTokenError(error));
    }
  } catch (err) {
    yield put(puntosActions.fetchGetTokenError(err));
  }
}

export function* watchFetchGetPuntosCoopeuch() {
  yield takeLatest(puntosActions.fetchGetPuntosCoopeuch.type, fetchGetPuntosCoopeuch);
}

export function* watchFetchOpenSesionPuntos() {
  yield takeLatest(puntosActions.fetchOpenSesionPuntos.type, fetchOpenSesionPuntos);
}

export default function* rootSaga() {
  yield spawn(watchFetchGetPuntosCoopeuch);
  yield spawn(watchFetchOpenSesionPuntos);
}
