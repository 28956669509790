import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import CreacionClaveTemplate from '../../../components/templates/RecuperarOCrearClave/CreacionClave';

import { findByKey } from '../../../utils/functions';
import { registerGA } from '../../../utils/metrics';
import { metodoFactores } from '../../../utils/constants';

const isValidWithRegExp = (reg, value) => {
  return new RegExp(reg, 'g').test(value);
};

const CreacionClave = (props) => {
  const {
    fetchReglasCrearClave,
    crearClave,
    recuperarClave,
    type,
    rut,
    trackId,
    reglasCrearClave,
    errorReglaClaves,
    isLoadingValidarOCrear,
    isLoadingReglasCrearClave,
    successCrearClave,
    errorCrearClave,
    errorClaveMessage,
    factorSelected,
    redirectFromDiffOrigins,
    redirectOrigin,
  } = props;

  const [clave, setClave] = useState();
  const [repeat, setRepeat] = useState();
  const [showClave, setShowClave] = useState(false);
  const [showRepeat, setShowRepeat] = useState(false);
  const [repeatDisabled, setRepeatDisabled] = useState(true);
  
  const [claveError, setClaveError] = useState(false);
  const [repeatError, setRepeatError] = useState(false);

  const onFetchReglasHandle = useCallback(() => {
    fetchReglasCrearClave();
  }, [fetchReglasCrearClave]);

  const regs = useMemo(
    () => ({
      min: findByKey(reglasCrearClave, 'codigo', 'CHAR_MIN')?.valor,
      max: findByKey(reglasCrearClave, 'codigo', 'CHAR_MAX')?.valor,
      mayus: findByKey(reglasCrearClave, 'codigo', 'MAYUS')?.valor,
      numeric: findByKey(reglasCrearClave, 'codigo', 'HAS_NUM')?.valor,
      alpha: findByKey(reglasCrearClave, 'codigo', 'ALPHA')?.valor,
      whspaces: findByKey(reglasCrearClave, 'codigo', 'WHSPACES')?.valor,
      regex: findByKey(reglasCrearClave, 'codigo', 'REGEX')?.valor,
    }),
    [reglasCrearClave]
  );

  function isValidClave(clave) {
    switch (true) {
      case clave?.length < Number(regs?.min):
        setClaveError('La clave debe tener entre 6 y 8 caracteres');
        return false;
      case clave?.length > Number(regs?.max?.valor):
        setClaveError('La clave debe tener entre 6 y 8 caracteres');
        return false;
      case isValidWithRegExp(regs.whspaces, clave):
        setClaveError('Tu clave no tiene que tener espacios');
        return false;
      case !isValidWithRegExp(regs.alpha, clave):
        setClaveError('Tu clave debe ser alfanumérica');
        return false;
      case !isValidWithRegExp(regs.mayus, clave):
        setClaveError('Tu clave debe tener al menos una mayúscula');
        return false;
      case !isValidWithRegExp(regs.numeric, clave):
        setClaveError('Tu clave debe tener al menos un número');
        return false;
      case !isValidWithRegExp(regs.regex, clave):
        setClaveError('Tu clave no cumple los requerimientos');
        return false;
      default:
        setClaveError('');
        return true;
    }
  }

  function onChangeHandle(event) {
    const { dataset, value } = event?.target;

    if (dataset?.id === 'clave') {

      if(clave && clave.length > value.length) setRepeat();

      setClave(value);
      if (repeat?.length > 0) {
        setRepeatError(repeat !== value);
      }
      isValidClave(value);
    } else {
      setRepeatError(clave !== value);
      setRepeat(value);
    }
  }

  function onShowHiddeHandle(type) {
    if (type === 'clave') {
      setShowClave(!showClave);
    } else {
      setShowRepeat(!showRepeat);
    }
  }

  function onSubmitHandle() {
    if (isValidClave(clave)) {
      if (type === 'crear') {
        crearClave({ clave, rut, track: `${trackId}-10` });
      } else {
        recuperarClave({ clave, rut, track: `${trackId}-20` });
        redirectFromDiffOrigins({track: `${trackId}-20`})
      }
      registerGA({
        event: 'trigger_event',
        producto: metodoFactores[factorSelected],
        'event-config': {
          'eve-acc': '/crea-rec-clave/paso4',
          'eve-cat': 'Click',
          'eve-lab': 'Crear Clave',
        },
      });
    }
  }

  useEffect(() => {
    if (errorClaveMessage?.length >= 1) {
      setClaveError(errorClaveMessage);
    }
  }, [setClaveError, errorClaveMessage]);

  useEffect(() => {
    onFetchReglasHandle();
  }, [onFetchReglasHandle]);


  useEffect(() => {
    if((clave?.length == 0 || clave == undefined || (claveError && claveError.length > 0))) {
      setRepeatDisabled(true);
      setRepeat();
      setRepeatError(false);
    }else{
      setRepeatDisabled(false);
    }
  }, [clave]);

  useEffect(() => {
    registerGA({
      producto: metodoFactores[factorSelected],
      event: 'vpv',
      page: '/crea-rec-clave/paso4',
    });
  }, [factorSelected]);

  useEffect(() => {
    registerGA({ event: 'vpv', page: '/crea-rec-clave/paso4', producto: type === 'recuperar' ? 'Olvidaste tu clave' : 'Crear tu clave' });
  }, []);

  useEffect(() => {
    if (successCrearClave) {
      registerGA({
        event: 'vpv',
        page: '/crea-rec-clave/exito',
        valoracion: metodoFactores[factorSelected],
        producto: (type === 'recuperar' ? 'Olvidaste tu clave' : 'Crear tu clave')
      });
    }
  }, [successCrearClave]);

  return (
    <CreacionClaveTemplate
      onChangeHandle={onChangeHandle}
      onShowHiddeHandle={onShowHiddeHandle}
      onSubmitHandle={onSubmitHandle}
      onFetchReglasHandle={onFetchReglasHandle}
      isLoadingValidarOCrear={isLoadingValidarOCrear}
      isLoadingReglasCrearClave={isLoadingReglasCrearClave}
      errorReglaClaves={errorReglaClaves}
      successCrearClave={successCrearClave}
      errorCrearClave={errorCrearClave}
      errorClaveMessage={errorClaveMessage}
      clave={clave}
      repeat={repeat}
      showClave={showClave}
      showRepeat={showRepeat}
      claveError={claveError}
      repeatError={repeatError}
      redirectOrigin={redirectOrigin}
      repeatDisabled={repeatDisabled}
    />
  );
};

CreacionClave.propTypes = {
  fetchReglasCrearClave: PropTypes.func,
  crearClave: PropTypes.func,
  rut: PropTypes.string,
  trackId: PropTypes.string,
  reglasCrearClave: PropTypes.array,
  errorReglaClaves: PropTypes.string,
  isLoadingValidarOCrear: PropTypes.bool,
  isLoadingReglasCrearClave: PropTypes.bool,
  successCrearClave: PropTypes.bool,
  errorCrearClave: PropTypes.bool,
  errorClaveMessage: PropTypes.string,
};

CreacionClave.defaultProps = {
  fetchReglasCrearClave: null,
  crearClave: null,
  rut: '',
  trackId: '',
  reglasCrearClave: [],
  errorReglaClaves: '',
  isLoadingValidarOCrear: false,
  isLoadingReglasCrearClave: false,
  successCrearClave: false,
  errorCrearClave: false,
  errorClaveMessage: '',
};

export default CreacionClave;
