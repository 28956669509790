import React from 'react';
import Stepper from '../../../../components/organism/Stepper';
import CardCoordenadas from '../../../../components/organism/ActivacionTarjetas/Activacion/CardCoordenadas';
import CardBlock from '../../../organism/ActivacionTarjetas/Error/CardBlock';
import CardBlockButton from '../../../organism/ActivacionTarjetas/Error/CardBlockButton';
// Styles
import Wrapper from './styles';
import CardTarjetaDebito from '../../../organism/ActivacionTarjetas/Activacion/CardDebito';

const ActivacionTemplate = ({
  steps,
  handleSetCardSerie,
  disabledBtn,
  handleClickContinue,
  error,
  retryButton,
  kitData,
  cardCoorOne,
  cardCoorTwo,
  cardCoorThree,
  cardCoorFour,
  handleClickBack,
  validateTC
}) => {
  return (
    <Wrapper>
      <Stepper className="start-xs" position={1} steps={steps} />
      {error !== 'TC-02' &&
        error !== 'TC-03' &&
        error !== '500 INTERNAL_SERVER_ERROR' &&
        error !== 'KIT-01' && 
        validateTC?.error?.statusCode !== 500 &&
        kitData?.error?.statusCode !== 500 && (
          <CardTarjetaDebito
            {...{
              handleSetCardSerie,
              disabledBtn,
              handleClickContinue,
              error,
              retryButton,
              kitData,
              cardCoorOne,
              cardCoorTwo,
              cardCoorThree,
              cardCoorFour,
              handleClickBack,
            }}
          />
        )}

      {error === 'TC-02' && (
        <CardBlock
          section="Activa tu Tarjeta de Coordenadas"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
        />
      )}
      {error === 'KIT-01' && (
        <CardBlock
          section="Activa tu Tarjeta de Coordenadas"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
        />
      )}
      {error === 'TC-03' && (
        <CardBlock
          section="Activa tu Tarjeta de Coordenadas"
          title="¡Lo sentimos!"
          message="Tu Tarjeta de Coordenadas ha sido bloqueada, ya que has superado la cantidad de
              intentos permitidos. Para activar tu Kit Cuenta Vista debes dirigirte a la sucursal
              Coopeuch más cercana."
          link="/ecd/inicio"
        />
      )}
      {validateTC?.error?.statusCode === 500 && (
        <CardBlockButton
          section="Activa tu Tarjeta de Débito"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
        />
      )}
      {kitData?.error?.statusCode === 500 && (
        <CardBlockButton
          section="Activa tu Tarjeta de Débito"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
        />
      )}
    </Wrapper>
  );
};

export default ActivacionTemplate;
