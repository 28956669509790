import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Wrapper from './styles';
import rectangue from '../../assets/svg/pagoqr/rectangue.svg';
import appCoopeuch from '../../assets/svg/pagoqr/appCoopeuch.svg';
import celular from '../../assets/svg/pagoqr/celular.svg';
import iconM from '../../assets/svg/pagoqr/iconM.svg';
import pConQR from '../../assets/svg/pagoqr/pConQR.svg';
import realizaPago from '../../assets/svg/pagoqr/realizaPago.svg';
import imageV3 from '../../assets/svg/pagoqr/imageV3.svg';
import imageV2 from '../../assets/svg/pagoqr/imageV2.svg';
import imageV1 from '../../assets/svg/pagoqr/imageV1.svg';
import imageV4 from '../../assets/svg/pagoqr/imageV4.svg';
import logoCoopeuch from '../../assets/svg/pagoqr/logoCoopeuch.svg';
import appCoopeuch1 from '../../assets/svg/pagoqr/appCoopeuch1.svg';
import descarga from '../../assets/svg/pagoqr/descarga.svg';
import botonDescarga from '../../assets/svg/pagoqr/botonDescarga.svg';

const PagoQr = () => {
  const location = useLocation();
  const [_uuid, setUuid] = useState(null);

  const deeplink = process.env.REACT_APP_PAGO_QR_DEEPLINK;
  const ios = process.env.REACT_APP_PAGO_QR_IOS;
  const android = process.env.REACT_APP_PAGO_QR_ANDROID;
  const huawei = process.env.REACT_APP_PAGO_QR_HUAWEI;



  useEffect(() => {
    const { uuid } = queryString.parse(location.search);

    if (uuid) {
      setUuid(uuid);
      const appUrl = `${deeplink}${uuid}`;
      window.location.href = appUrl;

    }
  }, [location]);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Detectar iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }
    // Detectar Android
    if (/android/i.test(userAgent)) {
        if (/huawei/i.test(userAgent) || /honor/i.test(userAgent)) {
            return 'Android-Huawei';
        }
        return 'Android';
    }
    // Detectar Windows Phone
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }
    // Si no se detecta un SO móvil
    return 'Otro';
  }

  function redirectToStore() {
    var os = getMobileOperatingSystem();
    if (os === 'iOS') {
        window.location.href = ios;
    } else if (os === 'Android-Huawei') {
        window.location.href = huawei;
    } else if (os === 'Android') {
        window.location.href = android;
    } else {
        console.log('Sistema operativo no soportado o no móvil.');
    }
  }

  return (
    <Wrapper>
    <div className='pantalla'>
      <div className='rectangue'>
        <img src={rectangue} />
      </div>
      <div className='appCoopeuch'>
        <img src={appCoopeuch} />
      </div>
      <div className='celular'>
        <img src={celular} />
      </div>
      <div className='iconM'>
        <img src={iconM} /> 
      </div>
      <div className='pConQR'>
        <img src={pConQR} /> 
      </div>
      <div className='realizaPago'>
        <img src={realizaPago} />
      </div>     
      <div className='imageV3'>
        <img src={imageV3} />
      </div>
      <div className='imageV2'>
        <img src={imageV2} /> 
      </div>
      <div className='imageV1'>
        <img src={imageV1} /> 
      </div>
      <div className='imageV4'>
        <img src={imageV4} />
      </div>
      <div className='logoCoopeuch'>
        <img src={logoCoopeuch} /> 
      </div>
      <div className='appCoopeuch1'>
        <img src={appCoopeuch1} />
      </div>
      <div className='descarga'>
        <img src={descarga} /> 
      </div>
      <div className='botonDescarga'>
        <img src={botonDescarga}  onClick={redirectToStore}/> 
      </div>
    </div> 

    </Wrapper>
  );
};

export default PagoQr;
