export default function currencyFormatter({ value, symbol = '$', thousandsSeparator = '.' }) {
  try {
    if (value === undefined || value === null) {
      return null;
    }

    const formattedValue = value
      .toString()
      .replace(/\./g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

    const result = `${symbol}${formattedValue}`;

    return result;
  } catch (_) {
    return null;
  }
}
