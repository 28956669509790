import React from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

// styles
import { colors } from '../../../assets/styles';
import BaseMenu from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const MenuMobile = ({ onClick, height, id }) => (
  <BaseMenu height={height} onClick={onClick} id={id}>
    <LoadIcon src={`${iconPath}menu_burger.svg`} size={24} color={colors.white} />
  </BaseMenu>
);

MenuMobile.propTypes = {
  onClick: PropTypes.func,
  height: PropTypes.number,
  id: PropTypes.string,
};

MenuMobile.defaultProps = {
  onClick: null,
  height: 0,
  id: '',
};

export default MenuMobile;
