/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/puntos';

// ------- SAGA ------ //
function fetchGetPuntosCoopeuchSuccess(state, action) {
  state.puntosData.data = action.payload;
  state.puntosData.error = null;
}

function fetchGetPuntosCoopeuchError(state, action) {
  state.puntosData.data = null;
  state.puntosData.error = action.payload;
}

function fetchGetTokenError(state, action) {
  state.tokenData.data = false;
  state.tokenData.error = action.payload;
}

const fetchGetPuntosCoopeuch = createAction('fetchGetPuntosCoopeuch');
const fetchOpenSesionPuntos = createAction('fetchOpenSesionPuntos');

const puntosReducer = createSlice({
  name: 'puntos',
  initialState,
  reducers: {
    fetchGetPuntosCoopeuchSuccess,
    fetchGetPuntosCoopeuchError,
    fetchGetTokenError,
  },
});

export const puntosActions = {
  ...puntosReducer.actions,
  fetchGetPuntosCoopeuch,
  fetchOpenSesionPuntos,
};

export default puntosReducer.reducer;
