import styled from 'styled-components';

const Wrapper = styled.div`
  .dashboard-custom {
    padding: 0px;
    padding: 16px 30px 16px 33px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: 465px) {
    .dashboard-custom {
      padding: 10px;
    }
  }
  .dashboard-puntos-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @media screen and (max-width: 465px) {
    .dashboard-puntos-body {
      flex-direction: column;
      justify-content: center;
    }
  }

  /* Icon Puntos Coopeuch */
  .dashboard-puntos__icon {
    display: flex;
    align-items: center;
    padding-right: 20px;
  }
  @media screen and (max-width: 465px) {
    .dashboard-puntos__icon {
      padding-right: 0px;
    }
  }

  .dashboard-puntos__text {
    font-size: 16px;
    padding-left: 8px;
  }

  @media screen and (max-width: 465px) {
    .dashboard-puntos__text {
      font-size: 15px;
    }
  }

  .dashboard-puntos__btn button {
    background-color: transparent;
    border: none;
    color: #ec1b2b;
    border-bottom: 1px solid #ec1b2b;
    font-size: 14px;
    padding: 0px;
    font-weight: bold;
  }
`;

export default Wrapper;
