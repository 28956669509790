import React from 'react';
import { Text, Img } from "./styles";
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const CardError = () => {
  return (
    <div className="mt-24 mb-24 middle-xs">
        <Img src={`${staticPath}login_error.png`} alt="login-error" />
        <Text textSize={16} size={24} weight="normal">
          El servicio no está disponible por el momento
        </Text>
    </div>
  );
};

export default CardError;