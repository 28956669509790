import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: ${({ index }) => (index % 2 === 0 ? '#F6F3F5' : '#FFFFFF')};
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  width: 100%;
  align-items: center;

  ${({ highlightedRow }) =>
    highlightedRow &&
    `
      background-color: #d9f1fa;
      border: 1px solid #03a1df;
  `};

  .table-crud-row-container {
    width: 90%;
    margin-left: 26px;
    margin-right: 26px;
  }

  .table-crud-icons-container {
    width: 10%;
    align-self: center;
    display: inline-flex;
    align-items: center;
  }

  .table-crud-col {
    align-self: center;
  }

  .table-crud-col-wordBreak {
    word-break: break-word;
  }

  .icon-edit {
    cursor: pointer;
    margin-right: 24px;
  }

  .icon-edit:hover {
    & path {
      fill: red;
    }
  }

  .icon-delete:hover {
    & path {
      fill: red;
    }
  }

  .icon-delete {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .table-crud-row-container {
      width: 77%;
      margin-left: 16px;
      margin-right: 16px;
    }

    .table-crud-icons-container {
      width: 23%;
      align-self: center;
      display: inline-flex;
      align-items: center;
    }

    .table-crud-col {
      padding-left: 0px;
      padding-right: 0px;
    }

    .icon-edit {
      margin-right: 16px;
    }
  }
`;

export const WrapperLoader = styled.div`
  text-align: center;
`;

export default Wrapper;
