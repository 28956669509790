import styled from 'styled-components';

export default styled.div`
  width: 100%;

  .content-dap {
    padding-left: 48px;
    padding-right: 48px;
  }

  @media screen and (max-width: 766px) {
    .content-dap {
      padding-left: 0px;
      padding-right: 0px;
    }

    #dap-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }
  }
`;
