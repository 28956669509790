import { put, takeLatest, call, spawn } from 'redux-saga/effects';
import { perfilComercialActions } from '../../../application/actions/perfilComercial';
import api from '../../api';
import getOS from '../../../ui/utils/getOS';
import cookies from '../../../ui/utils/cookies';

const apiPerfilar = api(process.env.REACT_APP_PERFILAR_API);

// obtener la cookie
// el backend ya esta listo para recibir este dato o un null en caso tal no haya data
const _gid = cookies('_gid');

// obtener os y os version
const { sistemaOperativo, versionSo } = getOS();

export function* fetchPerfilar(userId) {
  try {
    const optionalsHeaders = {
      ga_gid: _gid,
      ga_int_u: userId.payload,
      addr_code: '',
      os: sistemaOperativo,
      os_v: versionSo,
    };
    const response = yield call(
      apiPerfilar.get,
      '/api/msperfilcomercial/perfil/v1/perfilar',
      null,
      optionalsHeaders
    );
    if (response) {
      yield put(perfilComercialActions.getPerfilarSuccess(response));
    }
    yield put(perfilComercialActions.setUserIdDataLayer(userId.payload));
  } catch (error) {
    throw new Error(error);
  }
}

export function* watchFetchPerfilar() {
  yield takeLatest(perfilComercialActions.fetchPerfilCliente.type, fetchPerfilar);
}

export default function* rootSaga() {
  yield spawn(watchFetchPerfilar);
}
