import React, { useState } from 'react';
// Redux
import { useSelector } from 'react-redux';
// Stepper
import StepperRouter from './StepperRouter';
// Atoms
import PageTitle from '../../components/atoms/PageTitle';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const BonoEscolar = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);
  return (
    <>
      <PageTitle
        title={`${currentStep === 0 ? 'Bonos de estudio' : dataBono.typeBono}`}
        icon={<LoadIcon src={`${iconPath}cheque.svg`} color="black" />}
      />
      {/* // TO DO: Validar que tipo de usuario es, en base a eso mostrar el contenido */}
      <div className="w-100  margin-after-title text-left">
        <StepperRouter {...{ currentStep, setCurrentStep }} />
      </div>
    </>
  );
};

export default BonoEscolar;
