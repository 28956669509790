import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import CuentasTemplate from '../../../components/templates/Dashboard/Cuentas';
import { findByKey } from '../../../utils/functions';
import { transferenciasActions } from '../../../../application/actions/transferencias';
import { productActions } from '../../../../application/actions/products';

import { registerGA } from '../../../utils/metrics';

const cardText = (codigoProducto) => {
  switch (codigoProducto) {
    case 'VI06':
      return { title: 'Monedero Digital Coopeuch', productName: 'Monedero Digital Coopeuch' };
    default:
      return { title: 'Cuenta Vista Coopeuch', productName: 'Cuenta Vista' };
  }
};

class CuentasVista extends Component {
  componentDidMount() {
    this.props.fetchCuentasVista({
      codigoProducto: ['VI01', 'VI02', 'VI03', 'VI04', 'VI06'],
      estadoCuenta: ['A', 'T'],
      tipoCliente: ['R'],
    });
  }

  handleClickTransferir = (event) => {
    const { dataset } = event.target;
    const { history, cuentas } = this.props;
    const cuentaOrigen = findByKey(cuentas, 'numeroCuenta', Number(dataset.id));
    this.props.changeOrigenSeleccionado(cuentaOrigen);
    history.push({
      pathname: '/ecd/transferir/fondos',
      state: {
        isFromDashboard: true,
      },
    });
  };

  handleChangeSlide = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Visualizacion de estado de cuenta'
    });
  }

  handleClickTarjeta = ({ numeroCuenta, codigoProducto }) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': cardText(codigoProducto).title,
    });

    this.props.history.push({
      pathname: '/ecd/productos/cuenta-coopeuch',
      state: {
        numeroCuenta,
      },
    });
  };

  render() {
    const { cuentas, isLoading, errorCuentasVistas } = this.props;

    return (
      <div className="col-md-6 col-xs-12">
        <CuentasTemplate
          {...{
            cuentas,
            isLoading,
            codigos: cardText,
            handleClickTransferir: this.handleClickTransferir,
            errorCuentasVistas,
            handleClickTarjeta: this.handleClickTarjeta,
            handleChangeSlide: this.handleChangeSlide
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  cuentas: entities.products.cuentaVista.balance,
  isLoading: ui.loaders.isLoadingCuentasVista,
  errorCuentasVistas: entities.products.errorCuentasVistas,
});

const mapDispatchToProps = (dispatch) => ({
  changeOrigenSeleccionado(props) {
    dispatch(transferenciasActions.changeOrigenSeleccionado(props));
  },
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CuentasVista));
