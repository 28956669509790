import styled from 'styled-components';

const Wrapper = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  border-radius: 8px;
  height: 200px;
  padding: 32px;

  .second-paragraph {
    width: 270px;
    margin-top: 8px;
    margin-bottom: 8px;
    line-height: 24px;
  }

  @media screen and (max-width: 768px) {
    background-image: url(${({ srcMobile }) => srcMobile});
    border-radius: 0px;
    margin-top: -18px;
    height: 320px;

    .second-paragraph {
      width: 200px;
    }

    #btn-dap {
      margin-top: 100px;
    }
  }
`;

export const WrapperSkeleton = styled.div`
  margin-top: 24px;

  @media screen and (max-width: 768px) {
    margin-top: 0px !important;
  }
`;

export default Wrapper;
