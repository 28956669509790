import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 64px 3rem 24px 3rem;

  @media (max-width: 768px) {
    .textExitoso {
      font-size: 14px;
      line-height: 24px;
    }

    padding: 64px 16px 24px 16px;
  }
`;

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  padding-block: 24px;
  margin-bottom: 24px;
  width: 100%;
  text-align: left;
`;

export const CardHeader = styled.div`
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 24px;

  .cardTitleContainer {
    padding-inline: 24px;
  }

  .cardTitle {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0;
  }

  .downloadText {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
    color: #ec1b2b;
    font-weight: bold;
    text-decoration: none;
    align-items: center;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .cardTitleContainer {
      padding-inline: 16px;
    }

    .cardTitle {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }
  }
`;

export const CardBody = styled.div`
  padding: 24px 0 0 0;

  .cardBackgrounds {
    background-color: #f4f4f4;
    padding: 16px 32px;
    align-items: center;
    text-align: left;
  }

  .cardNoBackground {
    padding: 16px 32px;
    align-items: center;
    text-align: left;
  }

  .cardBodyTitleContainer {
    margin-block: 24px;
    padding-inline: 32px;
  }

  .cardBodyTitle {
    font-size: 22px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0 0 12px 0;
  }

  .cardBodySubTitles {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
  }

  .cardBodyProducts {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
  }

  .cardContentsBackgrounds {
    background-color: #f4f4f4;
    padding-block: 12px;
  }

  @media (max-width: 768px) {
    padding: 0 0 0 0;

    .cardBackgrounds {
      background-color: #f4f4f4;
      padding: 16px 16px;
      align-items: center;
      text-align: left;
    }

    .cardNoBackground {
      padding: 16px 16px;
      align-items: center;
      text-align: left;
    }

    .cardBodyTitleContainer {
      padding-inline: 24px;
    }

    .cardTitle {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    .cardBodyTitle {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    .cardBodySubTitles {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    .cardBodyProducts {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }
  }
`;

export const CardFooter = styled.div`
  border-top: 1px solid #e6e6e6;
  padding-top: 24px;
  padding-inline: 32px;
`;

export const PageTitleWrapper = styled.div`
  @media screen and (min-width: 768px) {
    #header-cuota-participacion {
      width: 100vmax;
    }
  }
`;

export const DownloadLink = styled.div`
  & > a {
    color: #ec1b2b;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    font-weight: bold;
  }
`;
