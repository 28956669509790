import React, { Component } from 'react';

import CoopeuchPassTemplate from '../../../components/templates/Dashboard/CoopeuchPass';

class CoopeuchPass extends Component {
  render() {
    return (
      <div className="col-md-6 col-xs-12">
        <CoopeuchPassTemplate />
      </div>
    );
  }
}

export default CoopeuchPass;
