/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/activacionTarjetasKit';

// ------- SAGA ------ //

function fetchGetKitSuccessKit(state, action) {
  const { data } = action.payload;

  state.kitDataKit.data = data;
  state.kitDataKit.error = null;
}

function fetchGetKitErrorKit(state, action) {
  const { error } = action.payload;

  state.kitDataKit.data = null;
  state.kitDataKit.error = error;
}

function fetchGetKitResetSuccessKit(state) {
  state.kitDataKit.data = null;
  state.kitDataKit.error = null;
}

function fetchfetchValidarTarjetaCoordenadasSuccessKit(state, action) {
  const { data } = action.payload;

  state.validateTCKit.data = data;
  state.validateTCKit.error = null;
}

function fetchfetchValidarTarjetaCoordenadasErrorKit(state, action) {
  const { error } = action.payload;

  state.validateTCKit.data = null;
  state.validateTCKit.error = error;
}

function fetchValidarTarjetaCoordenadasResetSuccessKit(state) {
  state.validateTCKit.data = null;
  state.validateTCKit.error = null;
}

function fetchSendSMSCodeSuccessKit(state, action) {
  const { data } = action.payload;

  state.smsCodeKit.data = data;
  state.smsCodeKit.error = null;
}

function fetchSendSMSCodeErrorKit(state, action) {
  const { error } = action.payload;

  state.smsCodeKit.data = null;
  state.smsCodeKit.error = error;
}

function fetchSendSMSCodeResetSuccessKit(state) {
  state.smsCodeKit.data = null;
  state.smsCodeKit.error = null;
}

function fetchValidateSMSCodeSuccessKit(state, action) {
  const { data } = action.payload;

  state.validateSMSKit.data = data;
  state.validateSMSKit.error = null;
}

function fetchValidateSMSCodeErrorKit(state, action) {
  const { error } = action.payload;

  state.validateSMSKit.data = null;
  state.validateSMSKit.error = error;
}

function fetchValidateSMSCodeResetSuccessKit(state) {
  state.validateSMSKit.data = null;
  state.validateSMSKit.error = null;
}

function fetchDownloadComprobanteSuccessKit(state) {
  state.downloadPDFKit.error = null;
}

function fetchDownloadComprobanteErrorKit(state, action) {
  const { error } = action.payload;
  state.downloadPDFKit.error = error;
}

function fetchGetKitBannerSuccessKit(state, action) {
  state.kitDataBannerKit.data = action.payload;
  state.kitDataBannerKit.error = null;
}

function fetchGetKitBannerErrorKit(state, action) {
  const { error } = action.payload;

  state.kitDataBannerKit.data = null;
  state.kitDataBannerKit.error = error;
}

function fetchIsEnabledPassCoopeuchSuccessKit(state, action) {
  state.availablePassCoopeuchKit.data = action.payload;
  state.availablePassCoopeuchKit.error = null;
}

function fetchIsEnabledPassCoopeuchErrorKit(state, action) {
  state.availablePassCoopeuchKit.data = null;
  state.availablePassCoopeuchKit.error = action.payload;
}

function fetchIsEnabledPassCoopeuchResetSuccessKit(state) {
  state.availablePassCoopeuchKit.data = null;
  state.availablePassCoopeuchKit.error = null;
}

function fetchSolicitarPassCoopeuchSuccessKit(state, action) {
  state.solicitarPassCoopeuchKit.data = action.payload;
  state.solicitarPassCoopeuchKit.error = null;
}

function fetchSolicitarPassCoopeuchErrorKit(state) {
  state.solicitarPassCoopeuchKit.data = null;
  state.solicitarPassCoopeuchKit.error = true;
}

function fetchSolicitarPassCoopeuchResetSuccessKit(state) {
  state.solicitarPassCoopeuchKit.data = null;
  state.solicitarPassCoopeuchKit.error = null;
}

function fetchValidarPassCoopeuchSuccessKit(state, action) {
  state.validarPassCoopeuchKit.data = action.payload;
  state.validarPassCoopeuchKit.error = null;
}

function fetchValidarPassCoopeuchErrorKit(state, action) {
  state.validarPassCoopeuchKit.data = null;
  state.validarPassCoopeuchKit.error = action.payload;
}

function fetchValidarPassCoopeuchResetSuccessKit(state) {
  state.validarPassCoopeuchKit.data = null;
  state.validarPassCoopeuchKit.error = null;
}

function fetchActivateKitCVSuccessKit(state, action) {
  state.activateKitKit.data = action.payload;
  state.activateKitKit.error = null;
}

function fetchActivateKitCVErrorKit(state, action) {
  state.activateKitKit.data = null;
  state.activateKitKit.error = action.payload;
}

function fetchActivateKitCVResetSuccessKit(state) {
  state.activateKitKit.data = null;
  state.activateKitKit.error = null;
}

const fetchGetKitKit = createAction('fetchGetKitKit');
const fetchValidarTarjetaCoordenadasKit = createAction('fetchValidarTarjetaCoordenadasKit');
const fetchSendSMSCodeKit = createAction('fetchSendSMSCodeKit');
const fetchValidateSMSCodeKit = createAction('fetchValidateSMSCodeKit');
const fetchGetKitResetKit = createAction('fetchGetKitResetKit');
const fetchValidarTarjetaCoordenadasResetKit = createAction('fetchValidarTarjetaCoordenadasResetKit');
const fetchSendSMSCodeResetKit = createAction('fetchSendSMSCodeResetKit');
const fetchValidateSMSCodeResetKit = createAction('fetchValidateSMSCodeResetKit');
const fetchDownloadComprobanteKit = createAction('fetchDownloadComprobanteKit');
const fetchGetKitBannerKit = createAction('fetchGetKitBannerKit');
const fetchIsEnabledPassCoopeuchKit = createAction('fetchIsEnabledPassCoopeuchKit');
const fetchSolicitarPassCoopeuchKit = createAction('fetchSolicitarPassCoopeuchKit');
const fetchIsEnabledPassCoopeuchResetKit = createAction('fetchIsEnabledPassCoopeuchResetKit');
const fetchSolicitarPassCoopeuchResetKit = createAction('fetchSolicitarPassCoopeuchResetKit');
const fetchValidarPassCoopeuchKit = createAction('fetchValidarPassCoopeuchKit');
const fetchValidarPassCoopeuchResetKit = createAction('fetchValidarPassCoopeuchResetKit');
const fetchActivateKitCVKit = createAction('fetchActivateKitCVKit');
const fetchActivateKitCVResetKit = createAction('fetchActivateKitCVResetKit');

// ------------- connection ------------- //

const activacionTarjetasKitReducer = createSlice({
  name: 'activacionTarjetasKit',
  initialState,
  reducers: {
    fetchGetKitSuccessKit,
    fetchGetKitErrorKit,
    fetchfetchValidarTarjetaCoordenadasSuccessKit,
    fetchfetchValidarTarjetaCoordenadasErrorKit,
    fetchSendSMSCodeSuccessKit,
    fetchSendSMSCodeErrorKit,
    fetchValidateSMSCodeSuccessKit,
    fetchValidateSMSCodeErrorKit,
    fetchGetKitResetSuccessKit,
    fetchValidarTarjetaCoordenadasResetSuccessKit,
    fetchSendSMSCodeResetSuccessKit,
    fetchValidateSMSCodeResetSuccessKit,
    fetchDownloadComprobanteSuccessKit,
    fetchDownloadComprobanteErrorKit,
    fetchGetKitBannerSuccessKit,
    fetchGetKitBannerErrorKit,
    fetchIsEnabledPassCoopeuchSuccessKit,
    fetchIsEnabledPassCoopeuchErrorKit,
    fetchSolicitarPassCoopeuchSuccessKit,
    fetchSolicitarPassCoopeuchErrorKit,
    fetchIsEnabledPassCoopeuchResetSuccessKit,
    fetchSolicitarPassCoopeuchResetSuccessKit,
    fetchValidarPassCoopeuchSuccessKit,
    fetchValidarPassCoopeuchErrorKit,
    fetchValidarPassCoopeuchResetSuccessKit,
    fetchActivateKitCVSuccessKit,
    fetchActivateKitCVErrorKit,
    fetchActivateKitCVResetSuccessKit
  },
});

export const activacionTarjetasKitActions = {
  ...activacionTarjetasKitReducer.actions,
  fetchGetKitKit,
  fetchValidarTarjetaCoordenadasKit,
  fetchSendSMSCodeKit,
  fetchValidateSMSCodeKit,
  fetchValidateSMSCodeResetKit,
  fetchGetKitResetKit,
  fetchValidarTarjetaCoordenadasResetKit,
  fetchSendSMSCodeResetKit,
  fetchDownloadComprobanteKit,
  fetchGetKitBannerKit,
  fetchIsEnabledPassCoopeuchKit,
  fetchSolicitarPassCoopeuchKit,
  fetchIsEnabledPassCoopeuchResetKit,
  fetchSolicitarPassCoopeuchResetKit,
  fetchValidarPassCoopeuchKit,
  fetchValidarPassCoopeuchResetKit,
  fetchActivateKitCVKit,
  fetchActivateKitCVResetKit
};

export default activacionTarjetasKitReducer.reducer;



