import React, { useEffect } from 'react';
import { registerGA } from '../../../utils/metrics';

// components
import SinCredito from '../../../components/templates/CreditoHipotecario/SinCredito';

const Index = () => {
  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/mis-productos/credito-hipotecario/beneficios',
    });
  }, []);
  return (
    <>
      <SinCredito />
    </>
  );
};

export default Index;
