import styled from 'styled-components';

const Wrapper = styled.div`
  .cardBox__footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: -10px;
    @media (max-width: 768px) {
      margin-left: 0px;
      justify-content: center;
    }
    &-btnBack {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 16px;
      font-family: 'Ubuntu-Medium';
      & path {
        fill: #e81e2b;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
`;
export default Wrapper;
