import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SocioTemplate from '../../../components/templates/Dashboard/Socio';

import { registerGA } from '../../../utils/metrics';

class Socio extends Component {
  handleClickSocio = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Hazte Socio Coopeuch',
    });

    window.open('https://www.coopeuch.cl/personas/hazte-socio-digital.html', '_blank');
  };

  render() {
    return (
      <div className="col-md-6 col-xs-12">
        <SocioTemplate handleClickSocio={this.handleClickSocio} />
      </div>
    );
  }
}

export default withRouter(Socio);
