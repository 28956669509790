import React from 'react';
// Components
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
// Organism
import InfoBonos from '../../../organism/BonoEscolar/Home/InfoBonos';
import TableBonos from '../../../organism/BonoEscolar/Home/TableBonos';
import CardBlockBonos from '../../../organism/BonoEscolar/Error';
// Styles
import Wrapper, { WrapperError } from './styles';
import LoadingSkeleton from './LoadingSkeleton';
import CardShadow from '../../../atoms/CardShadow';
import ModalConsentimiento from '../../../organism/BonoEscolar/ModalConsentimiento';

const HomeTemplate = ({
  handleClickBtn,
  userType,
  handleClickCuentaVista,
  isLoading,
  handleClickRetry,
  visibleModal,
  handleClickCloseModalCerrar,
  handleClickModalContinuar,
  checked,
  handleCheckBox,
  dataSolicitudes,
  toHome,
  handleClickBonusTab,
  handleClickApplicationsTab,
}) => {
  return isLoading ? (
    <WrapperError>
      <CardShadow padding={0} content={<LoadingSkeleton />} />
    </WrapperError>
  ) : userType?.error === true ? (
    <WrapperError>
      <CardBlockBonos
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        handleClickRetry={handleClickRetry}
      />
    </WrapperError>
  ) : (
    <Wrapper>
      <Tabs bgColor="#fff" fontSize={14} activeColor="#F42534" className="bonoSolicitar__content">
        <Tabs.Header>
          <Tab callback={handleClickBonusTab}>Bonos</Tab>
          <Tab callback={handleClickApplicationsTab}>Mis Solicitudes</Tab>
        </Tabs.Header>
        <Tabs.Body>
          <InfoBonos {...{ handleClickBtn, userType, handleClickCuentaVista }} />
          <TableBonos {...{ dataSolicitudes, toHome}} />
        </Tabs.Body>
      </Tabs>
      <ModalConsentimiento
        visibleModal={visibleModal}
        handleClickCloseModalCerrar={handleClickCloseModalCerrar}
        handleClickModalContinuar={handleClickModalContinuar}
        checked={checked}
        handleCheckBox={handleCheckBox}
      />
    </Wrapper>
  );
};

export default HomeTemplate;
