import logger from "./logger";

export function loguearRutUsuario(tag, infoAdicional) {
  loguear(tag, infoAdicional);
}

export function loguearErrorFlujo(error, tag, infoAdicional) {
  if (!error) {
    console.error('Parámetros inválidos para loguearErrorFlujo');
    return;
  }
  loguear(tag, infoAdicional, error ? error : null);
}

function obtenerRutUsuario() {
  const rutCompleto = localStorage.getItem('user-rut');
  const rut = rutCompleto.replace(/[\.\-]/g, '');
  if (rut && rut.length > 1) {
    return {
      rut: rut.slice(0, -1),
      digito_verificador: rut.slice(-1),
    };
  }
  return null;
}

function loguear(tag, infoAdicional, error) {
  const rutInfo = obtenerRutUsuario();
  if (rutInfo) {
    logger(error, {
      ...rutInfo,
      accion: tag,
      informacion_adicional: infoAdicional,
    });
  }
}

