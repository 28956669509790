import styled from 'styled-components';

export const SinSolicitudes = styled.div`
  border: 1px dashed #979797;
  width: 144px;
  height: 144px;
  @media screen and (max-width: 500px) {
      width: 112px;
      height: 112px;
    }
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 100%;
`;

export const Text = styled.p`
  white-space: break-spaces;
  color: #383638;
  letter-spacing: 0;
  text-align: center;
  font-size: ${({ textSize }) => `${textSize}px`};
  line-height: ${({ size }) => `${size}px`};
  font-weight: ${({ weight }) => weight};
`;