import React from 'react';
import { Link } from 'react-router-dom';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import ButtonBack from '../../../../organism/ActivacionTarjetas/ButtonBack';
import logo from '../../../../../assets/tarjetaDebito.svg';

import FactorTarjetaDeCoordenadas from '../../../../organism/ActivacionTarjetas/Autorizacion/TarjetaCoodenadas';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
import { registerGA } from '../../../../../utils/metrics';

// Styled
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const CardKitPass = ({ kitData, handleClickActivate, handleReturnInicio }) => {
  return (
    <Wrapper>
      <CardBox>
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Tu tarjeta está lista para ser activada
          </Typography>
        </CardBox.Header>
        <CardBox.Body className="homeKitBox">
          <div className="homeKitBox__containerImg">
            <LoadIcon
              src={
                //`${iconPath}activacionTdPassCoopeuch/tarjetaDebito.svg`
                logo
              }
            />
          </div>
          <p className="homeKitBox__title">Tarjeta Débito Coopeuch</p>
          <p className="homeKitBox__content">
            Para comenzar, debes activar tu tarjeta de débito. Tenla a mano para realizar la
            activación
          </p>
          <Link
            to="/ecd/seguridad/activacion-tarjetas/activacion"
            className="homeKitBox__btn"
            onClick={() => handleClickActivate()}
          >
            Activar
          </Link>
        </CardBox.Body>
      </CardBox>
      <ButtonBack
        link="/ecd/inicio"
        text={activacionTexts.BUTTON_RETURN}
        onClick={() => handleReturnInicio()}
      />
    </Wrapper>
  );
};

export default CardKitPass;
