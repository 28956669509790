import React, { useMemo } from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../atoms/PageTitle';
import Stepper from '../../../../components/organism/Stepper';
import Alert from '@coopeuch-components/web/atoms/Alert';
import { capitalize } from '../../../../utils/functions';
import { agregarformatoPesos, agregarFormatoDecimal } from '../../../../utils/formatters';
import { dateFormat } from '../../../../utils/dates';

import { detalleTexts, stepperTexts } from '../../../../containers/DepositoAPlazo/texts';

import Wrapper, { TitleWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DetallesSimulacionTemplate = ({
  simulaciones,
  handleReturn,
  handleInvertir,
  handleSolicitar,
  tarjetaActiva,
  cuentasVista,
  handleChange,
}) => {
  const steps = useMemo(
    () => [
      stepperTexts.STEPPER_PASO_1,
      stepperTexts.STEPPER_PASO_2,
      stepperTexts.STEPPER_PASO_3,
      stepperTexts.STEPPER_PASO_4,
      stepperTexts.STEPPER_PASO_5,
    ],
    []
  );

  return (
    <React.Fragment>
      <TitleWrapper>
        <PageTitle
          title={detalleTexts.PAGE_TITLE}
          icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" className="simulador-icon" />}
        />
      </TitleWrapper>
      <Wrapper className="margin-after-title">
        <Stepper className="bmb-md-4 bmb-3 start-xs" position={3} steps={steps} />

        {cuentasVista.length === 0 && (
          <div className="bmt-3 bmt-md-0 bmb-3 bmb-md-4">
            <Alert show bgType="info" className="icon-alert">
              {detalleTexts.INFO_MESSAGE_TOAST}
            </Alert>
          </div>
        )}

        <div className="bmt-3 bmt-md-0">
          <DashboardCard classes="dashboard-card-shadow">
            <DashboardCard.Head
              title={detalleTexts.PAGE_CARD_TITLE}
              borderBottom="complete"
              classes="simulacion-title-card-head"
            />
            <DashboardCard.Body classes="detalle-simulacion-body-card">
              <div className="bmb-md-4 bmb-3">
                <label className="detalle-simulacion-text-title">
                  Plazo {simulaciones[tarjetaActiva - 1]?.plazoInversion} días -{' '}
                  {capitalize(simulaciones[tarjetaActiva - 1]?.tipoDepositoPlazo)}
                </label>
              </div>
              <div className="detalle-card-header row m-0">
                <div className="col-md-6 col-xs-6">
                  <div>
                    <label className="detalle-card-header-title display-desktop">
                      {detalleTexts.MONTO_VENCIMIENTO}
                    </label>
                    <label className="detalle-card-header-title display-mobile">
                      {detalleTexts.MONTO_VENCIMIENTO_MOBILE}
                    </label>
                  </div>
                  <div>
                    <label className="detalle-card-header-value">
                      {agregarformatoPesos(simulaciones[tarjetaActiva - 1]?.montoVencimiento)}
                    </label>
                  </div>
                </div>
                <div className="col-md-6 col-xs-6">
                  <div>
                    <label className="detalle-card-header-title">{detalleTexts.GANANCIA}</label>
                  </div>
                  <div>
                    <label className="detalle-card-header-value">
                      {agregarformatoPesos(simulaciones[tarjetaActiva - 1]?.montoInteres)}
                    </label>
                  </div>
                </div>
              </div>
              <div className="bmt-md-4 bmt-3">
                <div className="row m-0 detalle-card-list card-impar">
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">{detalleTexts.MONTO_INVERTIR}</label>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">
                      {agregarformatoPesos(simulaciones[tarjetaActiva - 1]?.montoInversion)}
                    </label>
                  </div>
                </div>
                <div className="row m-0 detalle-card-list card-par">
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">{detalleTexts.TIPO_DEPOSITO}</label>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">
                      {capitalize(simulaciones[tarjetaActiva - 1]?.tipoDepositoPlazo)}
                    </label>
                  </div>
                </div>
                <div className="row m-0 detalle-card-list card-impar">
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">{detalleTexts.FECHA_INICIO}</label>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">
                      {capitalize(dateFormat(simulaciones[tarjetaActiva - 1]?.fechaApertura))}
                    </label>
                  </div>
                </div>
                <div className="row m-0 detalle-card-list card-par">
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">{detalleTexts.FECHA_VENCIMIENTO}</label>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">
                      {capitalize(dateFormat(simulaciones[tarjetaActiva - 1]?.fechaVencimiento))}
                    </label>
                  </div>
                </div>
                <div className="row m-0 detalle-card-list card-impar">
                  <div className="col-md-6 col-xs-12">
                    <div>
                      <label className="detalle-card-text title-bold">
                        {detalleTexts.TITLE_TASA_BASE}
                      </label>
                    </div>
                    <div>
                      <label className="detalle-card-text">{detalleTexts.DESC_TASA_BASE}</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">
                      {agregarFormatoDecimal(simulaciones[tarjetaActiva - 1]?.tasaBase)}%
                    </label>
                  </div>
                </div>
                <div className="row m-0 detalle-card-list card-par">
                  <div className="col-md-6 col-xs-12">
                    <div>
                      <label className="detalle-card-text title-bold">
                        {detalleTexts.TITLE_TASA_PERIODO}
                      </label>
                    </div>
                    <div>
                      <label className="detalle-card-text">{detalleTexts.DESC_TASA_PERIODO}</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <label className="detalle-card-text">
                      {agregarFormatoDecimal(simulaciones[tarjetaActiva - 1]?.tasaPeriodo)}%
                    </label>
                  </div>
                </div>
              </div>
            </DashboardCard.Body>
          </DashboardCard>
        </div>
        <div className="bmt-md-4 bmt-3">
          <div className="row">
            <div className="col-md-6 display-desktop">
              <Button
                asTextLink
                color="#E81E2B"
                iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                onClick={handleReturn}
                className="button-return p-0"
              >
                {detalleTexts.BOTON_RETURN}
              </Button>
            </div>
            <div className="col-md-6 col-xs-12 btext-right">
              <Button
                bgColor="#E81E2B"
                color="white"
                onClick={cuentasVista.length > 0 ? handleInvertir : handleSolicitar}
                className="button-continue"
              >
                {cuentasVista.length > 0
                  ? detalleTexts.BOTON_INVERTIR
                  : detalleTexts.BOTON_SOLICITAR}
              </Button>
            </div>
            <div className="col-md-6 col-xs-12 display-mobile">
              <Button
                asTextLink
                color="#E81E2B"
                iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                onClick={handleReturn}
                className="button-return p-0"
              >
                {detalleTexts.BOTON_RETURN}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
    </React.Fragment>
  );
};

DetallesSimulacionTemplate.propTypes = {};
DetallesSimulacionTemplate.defaultProps = {};

export default DetallesSimulacionTemplate;
