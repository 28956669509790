import React, { useEffect, useState } from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Select from '../../../../../atoms/Select';
import InputLabel from '../../../../../atoms/InputLabel';
import Skeleton from 'react-loading-skeleton';
import Typography from '@coopeuch-components/web/atoms/Typography';
import StepsActions from '../../../../../organism/Stepper/StepsActions';
import Alert from '@coopeuch-components/web/atoms/Alert';
import { agregarformatoPesos } from '../../../../../../utils/formatters';
import { registerGA } from '../../../../../../utils/metrics';
import { anioActual } from '../../../../../../utils/constants';

import Wrapper, { AccountWrapper, AlertWrapper } from './styles';

const Index = ({
  accounts,
  selectedAccount,
  valorCuota,
  handleChangeSelect,
  backButton,
  nextButton,
}) => {
  const [saldoInsuficienteError, setSaldoInsuficienteError] = useState(false);
  useEffect(() => {
    if (!!selectedAccount && !!valorCuota) {
      if (parseInt(selectedAccount.saldo, 10) < valorCuota) {
        setSaldoInsuficienteError(true);
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/capitalizar/paso1A',
          'eve-cat': 'Error',
          'eve-lab': 'Saldo insuficiente',
        });
      } else setSaldoInsuficienteError(false);
    }
  }, [selectedAccount, valorCuota]);

  return (
    <Wrapper>
      <CardBox className="bmb-3 bmb-md-4" id="caracteristicas">
        <CardBox.Header>¿Qué es Capitalizar Remanente?</CardBox.Header>
        <CardBox.Body>
          <Typography tag="p" id="descripcion-caracteristicas" color="#383638">
            Es una forma de <strong>adquirir Cuotas de Participación</strong>, utilizando todo o
            parte de tu Remanente correspondiente al ejercicio {`${anioActual - 1}`} pagado en el
            año {`${anioActual}`}. Puedes Capitalizar el monto total o parcial,{' '}
            <strong>una única vez hasta el 30 de diciembre {`${anioActual}`}.</strong>
          </Typography>
        </CardBox.Body>
      </CardBox>
      <CardBox id="cuenta-origen" className="bmb-3 bmb-md-4">
        <CardBox.Header>Capitaliza desde tu Cuenta de Origen</CardBox.Header>
        <CardBox.Body>
          <div className="row bmt-3">
            <div className="col-xs-12 col-md-6">
              <div className="row">
                <AccountWrapper className="col-xs-12 col-md-8 bmb-3 bmb-md-0">
                  {accounts.length && selectedAccount ? (
                    <React.Fragment>
                      {accounts.length > 1 ? (
                        <Select
                          title="Cuenta de Origen"
                          borderColor="#333"
                          labelClass="text-bold bmb-3"
                          rounded={1}
                          onChange={handleChangeSelect}
                          options={accounts}
                          value={selectedAccount ? selectedAccount.value : ''}
                          size={48}
                        />
                      ) : (
                        <InputLabel
                          onChange={() => {}}
                          size={48}
                          title="Cuenta de Origen"
                          labelClass="bmb-3 text-bold"
                          value={selectedAccount.label}
                        />
                      )}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Skeleton className="bmb-3" />
                      <Skeleton className="select-skeleton" />
                    </React.Fragment>
                  )}
                </AccountWrapper>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <Typography className="bmb-3" tag="p" fontSize="16">
                Saldo disponible
              </Typography>
              <Typography tag="p" fontSize="28" fontWeight="bold">
                {selectedAccount ? agregarformatoPesos(selectedAccount.saldo) : <Skeleton />}
              </Typography>
            </div>
          </div>
          {saldoInsuficienteError && (
            <div className="row bmt-3 bmt-md-4">
              <AlertWrapper className="col-xs-12 ">
                <Alert
                  className="alert-warning bp-3 "
                  bgType="warning"
                  id="warning-saldo-insuficiente"
                  show
                >
                  No tienes saldo suficiente para capitalizar. <b>Abona en tu cuenta de origen.</b>
                </Alert>
              </AlertWrapper>
            </div>
          )}
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmb-3 bmb-md-4"
        backAction={backButton}
        backText={'No por ahora'}
        nextAction={nextButton}
        disableNextAction={!selectedAccount || selectedAccount.saldo === 0}
        id="contratacion-paso-1"
      />
    </Wrapper>
  );
};

export default Index;
