import React, { useState } from 'react';

import Skeleton from 'react-loading-skeleton';

import Expand from '../../../../atoms/Expand';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Wrapper, { ExpandContent } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

function ExpandDetailed() {
  const [togglePosition, setTogglePosition] = useState(1);

  function toggleCard() {
    setTogglePosition(togglePosition === 1 ? 2 : 1);
  }

  return (
    <Wrapper>
      <Expand elevation={1} defaultSelectedItem={1} iconColor="#00A1DF" rounded>
        <Expand.Item position={togglePosition} toggleCard={toggleCard}>
          <Expand.Header color="white" className="header-expanded-saldo">
            <LoadIcon
              src={`${iconPath}book.svg`}
              size={32}
              color="black"
              className="float-left mr-16"
            />
            <span className="text-bold text-size-l">¿Depósito a Plazo Fijo o Renovable?</span>
          </Expand.Header>
          <Expand.Body>
            <ExpandContent>
              <ul>
                <li className="bullet-red">
                  <span className="text-bold">Depósito a Plazo Renovable</span>

                  <div>
                    Desde el día de vencimiento, el titular dispondrá de 3 días hábiles, para
                    solicitar el giro. Cumpliendo dicho plazo y no manifestando el titular su
                    intención de renovar o girar, el depósito será renovado automáticamente a la
                    tasa de pizarra vigente a la fecha de vencimiento y por el plazo pactado
                    originalmente y así sucesivamente, en cada nuevo vencimiento.
                  </div>
                </li>
                <li className="bullet-red">
                  <span className="text-bold">Depósito a Plazo Fijo</span>

                  <div>Sólo devengará intereses en pesos hasta el momento de su vencimiento.</div>
                </li>
              </ul>
            </ExpandContent>
          </Expand.Body>
        </Expand.Item>
      </Expand>
    </Wrapper>
  );
}

function SkeletonLoading() {
  return (
    <div className="row bmb-md-4 bmb-3">
      <div className="col-xs-12">
        <CardBox>
          <CardBox.Body>
            <div className="row">
              <div className="col-xs-1">
                <Skeleton circle height={30} width={30} />
              </div>
              <div className="col-xs d-flex middle-items">
                <Skeleton width={100} />
              </div>
              <div className="col-xs text-right">
                <Skeleton circle height={30} width={30} className="bml-auto" />
              </div>
            </div>
          </CardBox.Body>
        </CardBox>
      </div>
    </div>
  );
}

ExpandDetailed.Skeleton = SkeletonLoading;

export default ExpandDetailed;
