import React from 'react';
import AlertModal from '../../../atoms/AlertModal';
import StepsActions from '../../../organism/Stepper/StepsActions';
import { anioActual } from '../../../../utils/constants';
import { Text } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({ show, backButton, nextButton, closeAction }) => {
  return (
    <AlertModal showModal={show} width="424" onClose={closeAction}>
      <div className="center-xs">
        <div className="row center-xs">
          <img src={`${iconPath}info-desktop.svg`} alt="info-desktop" />
        </div>
        <Text className="bmb-3" textSize={18} size={24} weight="bold">
          ¿No deseas cobrar tu Remanente?
        </Text>
        <Text textSize={14} size={20} weight="normal">
          {`Recuerda que podrás cobrar tu remanente hasta el 30 de diciembre de ${anioActual}.`}
        </Text>
      </div>
      <StepsActions
        className="bmt-3"
        backAction={backButton}
        backText={'Cobrar después'}
        nextText={'Cobrar ahora'}
        nextAction={nextButton}
        id="contratacion-cobrar-despues-modal"
      />
    </AlertModal>
  );
};

export default Index;
