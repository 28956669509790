import React, { Component } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import StepperRouter from './StepperRouter';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

class ActivacionTarjetas extends Component {
  render() {
    return (
      <>
        <PageTitle
          title="Activación de tarjetas"
          icon={<LoadIcon src={`${iconPath}file.svg`} color="black" />}
        />

        <div className="w-100  margin-after-title text-left">
          <StepperRouter
            {...this.state}
            handleChangeProductoSeleccionado={this.handleChangeProductoSeleccionado}
          />
        </div>
      </>
    );
  }
}

export default ActivacionTarjetas;
