import React from 'react';
import { Link } from 'react-router-dom';
// UI
import Card from '@coopeuch-components/web/atoms/Card';
import { bannerKitCVTexts } from '../../../../../containers/Dashboard/texts';

import Container from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const BannerKitCV = ({ handleClickBannerKitCV }) => {
  return (
    <div className="col-md-6 col-xs-12">
      <Card padding={0} className="dashboard-card-shadow">
        <Container>
          <div className="encuestaBox">
            <p className="encuestaBox__title">{bannerKitCVTexts.TITLE}</p>
            <div className="encuestaBox__description">
              <p className="encuestaBox__description-text">
                ¡Ya puedes activar <br /> tus tarjetas!
                <br className="encuestaBox__description-text-break" />
                <Link
                  className="encuestaBox__description-text-link"
                  to="seguridad/activacion-tarjetas"
                  onClick={() => handleClickBannerKitCV()}
                >
                  {bannerKitCVTexts.TEXT_LINK}
                </Link>
              </p>
            </div>
          </div>
          <div className="imageBannerCV">
            <img src={`${iconPath}banner_activacion_cv.svg`} alt="" srcset="" />
          </div>
        </Container>
      </Card>
    </div>
  );
};

export default BannerKitCV;
