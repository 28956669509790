import React from 'react';

import Skeleton from 'react-loading-skeleton';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Button from '@coopeuch-components/web/atoms/Button';

import { Wrapper, CustomList, Banner } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const SolicitaTarjetaDeCredito = (props) => {
  const { loading } = props;

  return (
    <Wrapper className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12 col-md-12 w-100">
          {loading ? (
            <Skeleton height={200} />
          ) : (
            <Banner
              src={`${staticPath}banner_solicita_tarjeta_de_credito.jpg`}
              mobileSrc={`${staticPath}banner_solicita_tarjeta_de_credito_mobile.jpg`}
            >
              <div className="row h-100 banner-actions">
                <div className="col-xs-6 col-sm-6 bpl-5 banner-text">
                  <Typography className="mb-8" color={'white'} tag="p" fontSize={18}>
                    <b>Pronto </b> podrás solicitar tu tarjeta<br />Mastercard Coopeuch de forma<br /> <b>100% online.</b>
                  </Typography>
                </div>
              </div>
            </Banner>
          )}
        </div>
      </div>

      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <Typography className="mb-8 text-bold black" tag="p" fontSize={18}>
            ¿Cuáles son los beneficios?
          </Typography>
        </div>
      </div>

      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1 black">
            <CardBox.Header>
              {loading ? (
                <div className="d-flex middle-items">
                  <Skeleton circle height={30} width={30} className="bmr-2" />{' '}
                  <Skeleton width={130} />{' '}
                </div>
              ) : (
                <div className="d-flex middle-items">
                  <LoadIcon
                    src={`${iconPath}mastercard.svg`}
                    size={30}
                    color="#383638"
                    className="float-left bmr-3 black"
                  />{' '}
                  <span>Mastercard</span>
                </div>
              )}
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Permite acceder a la red de aceptación global de Mastercard, con exclusividad de beneficios en Chile y en el extranjero.'
                  )}
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0 black">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header>
              {loading ? (
                <div className="d-flex middle-items">
                  <Skeleton circle height={30} width={30} className="bmr-2" />{' '}
                  <Skeleton width={130} />{' '}
                </div>
              ) : (
                <div className="d-flex middle-items">
                  <LoadIcon
                    src={`${iconPath}shield.svg`}
                    size={30}
                    color="#383638"
                    className="float-left bmr-3"
                  />{' '}
                  <span>Más conveniente</span>
                </div>
              )}
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'La Tarjeta de Crédito cuenta con un seguro de desgravamen gratuito y no tiene impuesto al crédito.'
                  )}
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>

      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1 black">
            <CardBox.Header>
              {loading ? (
                <div className="d-flex middle-items">
                  <Skeleton circle height={30} width={30} className="bmr-2" />{' '}
                  <Skeleton width={130} />{' '}
                </div>
              ) : (
                <React.Fragment>
                  <LoadIcon
                    src={`${iconPath}coins.svg`}
                    size={30}
                    color="#383638"
                    className="float-left bmr-3"
                  />{' '}
                  Avance en efectivo
                </React.Fragment>
              )}
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Pide tu avance hasta en 24 cuotas en cajeros automáticos o en oficinas Coopeuch.'
                  )}
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0 black">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header>
              {loading ? (
                <div className="d-flex middle-items">
                  <Skeleton circle height={30} width={30} className="bmr-2" />{' '}
                  <Skeleton width={130} />{' '}
                </div>
              ) : (
                <React.Fragment>
                  <LoadIcon
                    src={`${iconPath}shopping_cart.svg`}
                    size={30}
                    color="#383638"
                    className="float-left bmr-3"
                  />{' '}
                  Programa Puntos
                </React.Fragment>
              )}
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Acumula Puntos Coopeuch y accede a todos los beneficios y descuentos diseñados especialmente para ti.'
                  )}
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </Wrapper>
  );
};

export default SolicitaTarjetaDeCredito;
