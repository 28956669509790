import styled from 'styled-components';

const Wrapper = styled.div`
  .title {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    font-family: Ubuntu;
  }
  .description {
    font-size: 14px;
    margin-bottom: 30px;
  }
  .btn {
    background-color: #007db7;
    border: none;
    border-radius: 4px;
    padding: 14px 24px 14px 24px;
    color: #ffffff;
    font-size: 14px;
    width: 100%;
    max-width: 168px;
  }
`;

export default Wrapper;
