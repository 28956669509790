import Styled from 'styled-components';

export const Paragraph = Styled.div`
.container_icon_title{
  display: flex;
}
  @media (min-width: 64em) {
    margin-left: ${({ marginLeft }) => `${marginLeft ? marginLeft : '0'}px`};
  }
  font-size: ${({ size }) => `${size ? size : '16'}px`};
  letter-spacing: 0;
  line-height: 26px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  & .login--wrapper-checkbox {
      width: 100%;
      display: flex;
      margin-left: -6px;
      margin-top: -12px;
      margin-bottom: 12px;
    }
`;

export const HRow = Styled.hr`
  border: 1px solid #E9E9E9;
`;
export const CustomList = Styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 50%;
      position: absolute;
      content: '';
      display: inline-block;
      margin: auto 16px auto 0;
      height: 6px;
      bottom: 0;
      width: 6px;
      left: -16px;
      top: 0;
    }
  }
`;
export const MessageError = Styled.div`
  width:100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;
export const CardFooter = Styled.div`
  border-top: 2px solid #f4f4f4;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
  button {
    color: #f42534;
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
    border-bottom: 1px solid #f42534;
  }
`;

export const Blocker  = Styled.div`
.blocker {
  position: relative;
  z-index: 1;
}
`;

export const Overlay  = Styled.div`
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
}
`;

const Wrapper = Styled.div`
  .contentSMS1 {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    &__title {
      font-size: 18px;
      font-family: 'Ubuntu-Bold';
    }
    &__text {
      width: 100%;
      max-width: 470px;
      text-align: center !important;
      font-size: 18px;
      line-height: 30px;
      font-family: 'Ubuntu-Regular';
      margin-top: 21px;
      margin-bottom: 24px;
    }
    &__btn {
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
      font-size: 14px;
      font-family: 'Ubuntu-Medium';
    }
  }
`;
export default Wrapper;