import React from 'react';
// Components

import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { CardDropdown } from '@design-system-coopeuch/web'



const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const InfoBonosIngreso = () => {
    return (
        <>
            <p className="bonoSolicitar__content__info">
                Se le otorgará un bono al beneficiario e hijos por haberse titulado en una institución
                de educación superior reconocida por el ministerio de educación.
            </p>

            <div className="bonoSolicitar__content__monto">
                <Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__monto__montoText"
                >
                    Monto del beneficio:
                </Typography>
                <Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__monto__montoNumber"
                >
                    $60.000
                </Typography>
            </div>

            <div className="bonoSolicitar__content__requisitos">
                <CardDropdown title={<Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__requisitos__title"
                >
                    Requisitos para postular:
                </Typography>}>
                    <div className="bonoSolicitar__content__requisitos__info">
                        <ul>
                            <li>
                                <p>
                                    Debes tener más de 1 año de antigüedad como socio Coopeuch y estar al día en tus compromisos (deudas directas o indirectas).
                                </p>
                            </li>
                            <li>
                                <p>
                                    Haberte titulado de una carrera, cuya malla curricular comprenda más de 2 años de duración.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Las carreras deben ser presenciales, semipresenciales u online, impartidas por Centros de Formación Técnica, Institutos profesionales, Universidades y escuelas de Formación de las Fuerzas Armadas y PDI.
                                </p>
                            </li>
                            <li>
                                <p>
                                    El plazo máximo para postular al bono es 180 días corridos desde la fecha de obtención del título.
                                </p>
                            </li>
                            <li>
                                <p>Debes tener tu Cuenta Vista activa.</p>
                            </li>
                        </ul>
                    </div>
                </CardDropdown>
            </div>
            <div className="bonoSolicitar__content__documentos">
                <CardDropdown title={<Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__documentos__title"
                >
                    Documentos requeridos:
                </Typography>}>
                    <div className="bonoSolicitar__content__documentos__docsRequeridos">
                        <div>
                            <LoadIcon src={`${iconPath}identification_card.svg`} />
                            <p>Cédula de identidad del socio por ambos lados.</p>
                        </div>
                        <div>
                            <LoadIcon src={`${iconPath}identification_card.svg`} />
                            <p>Cédula de identidad del hijo por ambos lados.</p>
                        </div>
                        <div>
                            <LoadIcon src={`${iconPath}certificatev2.svg`} />
                            <p>Certificado de título obtenido.</p>
                        </div>
                    </div>
                </CardDropdown>
            </div>
        </>
    );
};

export default InfoBonosIngreso;
