/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/configuracion';

function fetchObtenerMantencionSuccess(state, action) {
  state.mantencion = { success: true, error: false, data: action.payload };
}

function fetchConsultarContextoSuccess(state, action) {
  state.contexto = { success: true, error: false, data: action.payload };
}

function fetchObtenerMantencionError(state, action) {
  state.mantencion = { success: false, error: true, data: null };
}

function fetchConsultarContextoError(state, action) {
  state.contexto = { success: false, error: true, data: null };
}

function cleanContextoReducer(state, action) {
  state.contexto = { success: false, error: false, data: null };
}

const fetchObtenerMantencion = createAction('fetchObtenerMantencion');

const fetchConsultarContexto = createAction('fetchConsultarContexto');

const cleanContexto = createAction('cleanContextoReducer');

// ------------- connection ------------- //

const configuracionReducer = createSlice({
  name: 'configuracion',
  initialState,
  reducers: {
    fetchObtenerMantencionSuccess,
    fetchObtenerMantencionError,
    fetchConsultarContextoSuccess,
    fetchConsultarContextoError,
    cleanContextoReducer,
  },
});

export const configuracionActions = {
  ...configuracionReducer.actions,
  fetchObtenerMantencion,
  fetchConsultarContexto,
  cleanContexto,
};

export default configuracionReducer.reducer;
