import { normalizeString } from './formatters';

export function isEmailValid(email) {
  return /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
    email
  );
}

export const isRunValid = (value) => {
  const text = value
    .toLowerCase()
    .trim()
    .replace(/[^\dk]/g, '');

  let counter = 2;
  let total = 0;
  let size = text.length - 2;

  for (; size >= 0; size--, counter += 1) {
    if (counter > 7) {
      counter = 2;
    }

    total += Number(text[size]) * counter;
  }

  total = Number(11 - (total - 11 * Math.floor(total / 11)));

  let digit = String(total);

  if (total === 11) {
    digit = '0';
  } else if (total === 10) {
    digit = 'k';
  }

  return digit === text.slice(-1);
};

export const allRequiredFields = (data, keyFields = []) => {
  let areValid = false;

  if (data && keyFields.length) {
    areValid = keyFields.every((key) => data[key]);
  } else if (Array.isArray(data)) {
    areValid = data.every((d) => d);
  }

  return areValid;
};

export const isStringsNormalizedEquals = (string1, string2) => {
  return normalizeString(string1.toLowerCase())?.includes(normalizeString(string2));
};

export const hasValuesOnObject = (object = {}) => {
  return Object.keys(object).length >= 1;
};

export const hasValuesFulledOnObject = (object = {}) => {
  return Object.values(object).join('').length >= 1;
};

export const isSameWord = (comparator, compared) => {
  return String(comparator)?.toLowerCase().includes(String(compared)?.toLowerCase());
};

export const validateExtensionFiles = (file) => {
  const filename = file.name;
  const extension = filename.split('.').pop();
  const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];
  return allowedExtensions.includes(extension);
};

export const onlyWords = (string) => {
  const rule = /^([0-9])*$/;
  if (rule.test(string)) {
    console.log('hola 1');
  } else {
    console.log('hola 2');
  }
};
