/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';

import initialState from '../../../domain/entities/abonoAcreenciasRemanente';

function fetchBannerPoderEspecialSucces(state, action) {
  const { mostrarBanner } = action.payload || {}; // Add fallback to empty object
  state.bannerPoderEspecial = mostrarBanner;
  state.bannerPoderEspecialError = false;
}

function fetchBannerPoderEspecialError(state) {
  state.bannerPoderEspecial = [];
  state.bannerPoderEspecialError = true;
}

function clearValidar(state) {
  state.poderEspecialValidar.success = false;
  state.poderEspecialValidar.error = { server: false, cuenta: false };
  state.poderEspecialValidar.loading = false;
}

function fetchPoderEspecialValidarLoading(state) {
  state.poderEspecialValidar.loading = true;
}

function fetchPoderEspecialValidarSuccess(state) {
  state.poderEspecialValidar.success = true;
  state.poderEspecialValidar.error = { server: false, cuenta: false };
  state.poderEspecialValidar.loading = false;
}

function fetchPoderEspecialValidarServerError(state) {
  state.poderEspecialValidar.success = false;
  state.poderEspecialValidar.error = { server: true, cuenta: false };
  state.poderEspecialValidar.loading = false;
}

function fetchPoderEspecialValidarError(state, action) {
  const { code } = action.payload;
  switch (code) {
    case 'CDPE-02':
      state.poderEspecialValidar.error = { server: false, cuenta: true };
      break;
    case 'CDPE-03':
      state.poderEspecialValidar.error = { server: false, cuenta: true };
      break;
    case 'CDPE-04':
      state.poderEspecialValidar.error = { server: false, cuenta: true };
      break;
    case 'CDPE-05':
      state.poderEspecialValidar.error = { server: false, cuenta: true };
      break;
    case 'CDPE-09':
      state.poderEspecialValidar.error = { server: false, cuenta: true };
      break;
    default:
      state.poderEspecialValidar.error = { server: true, cuenta: false };
      break;
  }
  state.poderEspecialValidar.loading = false;
  state.poderEspecialValidar.success = false;
}

function clearFirmar(state) {
  state.poderEspecialFirmar.success = false;
  state.poderEspecialFirmar.data = {};
  state.poderEspecialFirmar.error = { server: false, claveInvalida: false, claveBloqueada: false };
  state.poderEspecialFirmar.loading = false;
}

function fetchPoderEspecialFirmarLoading(state) {
  state.poderEspecialFirmar.loading = true;
}

function fetchPoderEspecialFirmarSuccess(state) {
  state.poderEspecialFirmar.success = true;
  state.poderEspecialFirmar.data = {};
  state.poderEspecialFirmar.error = { server: false, claveInvalida: false, claveBloqueada: false };
  state.poderEspecialFirmar.loading = false;
}

function fetchPoderEspecialFirmarServerError(state) {
  state.poderEspecialFirmar.success = false;
  state.poderEspecialFirmar.data = {};
  state.poderEspecialFirmar.error = { server: true, claveInvalida: false, claveBloqueada: false };
  state.poderEspecialFirmar.loading = false;
}

function fetchPoderEspecialFirmarError(state, action) {
  const { code, message, statusCode, uri } = action.payload;
  switch (code) {
    case '01':
      state.poderEspecialFirmar.error = {
        server: false,
        claveInvalida: true,
        claveBloqueada: false,
        code,
      };
      state.poderEspecialFirmar.data = {
        title: 'Clave incorrecta',
        message: 'Los datos ingresados no corresponden. Intenta nuevamente.',
        buttonTitle: 'Reintentar',
      };
      state.poderEspecialFirmar.logErr = {
        code: statusCode, 
        message: message, 
        statusCode: statusCode, 
        uri: uri,
      }
      break;
    case '02':
      state.poderEspecialFirmar.error = {
        server: false,
        claveInvalida: true,
        claveBloqueada: false,
        code,
      };
      state.poderEspecialFirmar.data = {
        title: 'Clave incorrecta',
        message: 'Recuerda que al tercer intento se bloqueará.',
        buttonTitle: 'Reintentar',
      };
      state.poderEspecialFirmar.logErr = {
        code: statusCode, 
        message: message, 
        statusCode: statusCode, 
        uri: uri,
      }
      break;
    case '03':
      state.poderEspecialFirmar.error = {
        server: false,
        claveInvalida: false,
        claveBloqueada: true,
        code,
      };
      state.poderEspecialFirmar.data = {
        title: 'Clave Bloqueada',
        message: 'Por seguridad sugerimos cambiarla.',
        buttonTitle: 'Cambiar clave',
      };
      state.poderEspecialFirmar.logErr = {
        code: statusCode, 
        message: message, 
        statusCode: statusCode, 
        uri: uri,
      }
      break;
    default:
      state.poderEspecialFirmar.error = {
        server: true,
        code: statusCode,
        statusCode: "500",
        claveInvalida: false,
        claveBloqueada: false,
      };
      state.poderEspecialFirmar.logErr = {
        code: statusCode, 
        message: message, 
        statusCode: statusCode, 
        uri: uri,
      }
      state.poderEspecialFirmar.data = {};
      break;
  }
  state.poderEspecialFirmar.loading = false;
  state.poderEspecialFirmar.success = false;
}

function fetchDescargarComprobantePoderEspecialLoading(state) {
  state.descargarComprobantePoderEspecial.loading = true;
}

function fetchDescargarComprobantePoderEspecialSuccess(state) {
  state.descargarComprobantePoderEspecial.success = true;
  state.descargarComprobantePoderEspecial.loading = false;
  state.descargarComprobantePoderEspecial.error = { server: false };
}

function fetchDescargarComprobantePoderEspecialServerError(state, action) {
  const {code, message, statusCode, uri} = action?.payload
  state.descargarComprobantePoderEspecial.success = false;
  state.descargarComprobantePoderEspecial.loading = false;
  state.descargarComprobantePoderEspecial.error = { 
    server: true, 
    code: statusCode, 
    message: message, 
    statusCode: statusCode, 
    uri: uri
  };
}

const fetchBannerPoderEspecial = createAction('fetchBannerPoderEspecial');
const fetchPoderEspecialValidar = createAction('fetchPoderEspecialValidar');
const fetchPoderEspecialFirmar = createAction('fetchPoderEspecialFirmar');
const fetchDescargarComprobantePoderEspecial = createAction('fetchDescargarComprobantePoderEspecial');

const abonoAcreenciasRemanenteReducer = createSlice({
  name: 'abonoAcreenciasRemanente',
  initialState,
  reducers: {
    fetchBannerPoderEspecialSucces,
    fetchBannerPoderEspecialError,
    clearValidar,
    fetchPoderEspecialValidarLoading,
    fetchPoderEspecialValidarSuccess,
    fetchPoderEspecialValidarServerError,
    fetchPoderEspecialValidarError,
    clearFirmar,
    fetchPoderEspecialFirmarLoading,
    fetchPoderEspecialFirmarSuccess,
    fetchPoderEspecialFirmarServerError,
    fetchPoderEspecialFirmarError,
    fetchDescargarComprobantePoderEspecialLoading,
    fetchDescargarComprobantePoderEspecialSuccess,
    fetchDescargarComprobantePoderEspecialServerError,
  },
});

export const abonoAcreenciasRemanenteActions = {
  ...abonoAcreenciasRemanenteReducer.actions,
  fetchBannerPoderEspecial,
  fetchPoderEspecialValidar,
  fetchPoderEspecialFirmar,
  fetchDescargarComprobantePoderEspecial,
};

export default abonoAcreenciasRemanenteReducer.reducer;
