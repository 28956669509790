import React, { useMemo } from 'react';

import { addClass } from '@coopeuch-components/web';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../atoms/PageTitle';

import Factores from '../../../organism/DatosDestinatarios/Factores';
import Formulario from '../../../organism/DatosDestinatarios/Formulario';
import { colors } from '../../../../assets/styles';
import { allRequiredFields } from '../../../../utils/validators';

import Content, { WarningMessage } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const editFields = ['alias', 'clave'];

const createFields = ['rut', 'nombre', 'banco', 'tipoCuenta', 'numeroCuenta'];

const DatosDestinatarioTemplate = ({
  onChangeHandle,
  onSubmitHandle,
  onGoBackHandle,
  onTogglePassword,
  onSwitchDifferentFactor,
  onHandleDesafioFactor,
  onChangeInputFactorCoordsHandle,
  onHandleValidarFactor,
  onResetFactorHandle,
  texts,
  numeroCuentaRut,
  destinatario,
  tipoCuentas,
  bancos,
  errors,
  factors,
  reqError,
  destinatarioMessageError,
  isLoading,
  isLoadingFactoresSeguridad,
  isLoadingDesafioOrValidate,
  isEditDestinatario,
  isTransferirFondos,
  origenSeleccionado,
  cuentasDelUsuario,
  currentUserRut,
  factoresDisponibles,
  errorFactoresMessage,
  tarjetaCoordenadasErrors,
  passCoopeuchErrors,
  desafiosTarjetaCoordenadas,
}) => {
  const hasOnlyMonederoDigital = useMemo(() => {
    return (
      (isTransferirFondos && origenSeleccionado?.codigoProducto === 'VI06') ||
      (cuentasDelUsuario.length ===
        cuentasDelUsuario?.filter((cuenta) => cuenta.codigoProducto === 'VI06')?.length &&
        cuentasDelUsuario.length >= 1)
    );
  }, [cuentasDelUsuario, origenSeleccionado, isTransferirFondos]);

  const { fields, fieldsValids } = useMemo(
    () => ({
      fields: isEditDestinatario
        ? { email: destinatario?.email, alias: destinatario?.alias, clave: destinatario?.clave }
        : {
            ...destinatario,
            rut: hasOnlyMonederoDigital ? currentUserRut : destinatario?.rut,
          },
      fieldsValids: isEditDestinatario ? editFields : createFields,
    }),
    [isEditDestinatario, destinatario, hasOnlyMonederoDigital, currentUserRut]
  );

  return (
    <div className="col-md-12">
      <PageTitle
        title={isEditDestinatario ? texts.editPageTitle : texts.pageTitle}
        icon={<LoadIcon src={`${iconPath}money_exchange.svg`} color="#333" />}
      />

      <Content>
        {hasOnlyMonederoDigital && !isEditDestinatario && (
          <WarningMessage className="bmt-sm-4 bmt-xs-3">
            <div className="row">
              <div className="box">
                <LoadIcon src={`${iconPath}warning_triangle.svg`} size={20} />
              </div>
              <div className="col-xs">
                <p className="ml-16">{texts.warningMonedero}</p>
              </div>
            </div>
          </WarningMessage>
        )}
        {reqError && (
          <WarningMessage className="bmt-sm-4 bmt-xs-3">
            <div className="row">
              <LoadIcon src={`${iconPath}warning_triangle.svg`} size={20} />
              <p className="ml-16">{reqError}</p>
            </div>
          </WarningMessage>
        )}

        <Formulario
          onChangeHandle={onChangeHandle}
          texts={texts}
          numeroCuentaRut={numeroCuentaRut}
          destinatario={destinatario}
          tipoCuentas={tipoCuentas}
          bancos={bancos}
          errors={errors}
          isLoading={isLoading}
          isEditDestinatario={isEditDestinatario}
          isTransferirFondos={isTransferirFondos}
          origenSeleccionado={origenSeleccionado}
          cuentasDelUsuario={cuentasDelUsuario}
          currentUserRut={currentUserRut}
        />

        {!isTransferirFondos && (
          <Factores
            onChangeHandle={onChangeHandle}
            onTogglePassword={onTogglePassword}
            onSwitchDifferentFactor={onSwitchDifferentFactor}
            onHandleDesafioFactor={onHandleDesafioFactor}
            onChangeInputFactorCoordsHandle={onChangeInputFactorCoordsHandle}
            onHandleValidarFactor={onHandleValidarFactor}
            onResetFactorHandle={onResetFactorHandle}
            texts={texts}
            destinatario={destinatario}
            factors={factors}
            isLoading={isLoading || isLoadingFactoresSeguridad}
            isLoadingDesafioOrValidate={isLoadingDesafioOrValidate}
            fields={fields}
            fieldsValids={fieldsValids}
            isEditDestinatario={isEditDestinatario}
            factoresDisponibles={factoresDisponibles}
            errorFactoresMessage={errorFactoresMessage}
            tarjetaCoordenadasErrors={tarjetaCoordenadasErrors}
            passCoopeuchErrors={passCoopeuchErrors}
            errors={errors}
            destinatarioMessageError={destinatarioMessageError}
            desafiosTarjetaCoordenadas={desafiosTarjetaCoordenadas}
          />
        )}

        <div className="row middle-xs center-xs mt-32">
          <div
            className={addClass([
              (isEditDestinatario && destinatarioMessageError?.id !== 500) || isTransferirFondos
                ? 'col-md-6'
                : 'col-md-12',
              'col-xs-12 col-sm-12 row center-xs start-md',
            ])}
          >
            <Button
              to="/ecd/transferir/fondos"
              color="red"
              className="text-size-m none-underline"
              asTextLink
              onClick={onGoBackHandle}
              id="btn-transferir-fondos"
            >
              <div className="row middle-xs">
                <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} className="mr-8 back--icon" />
                <span className="text-bold">{texts.goBack}</span>
              </div>
            </Button>
          </div>
          {((isEditDestinatario && destinatarioMessageError?.id !== 500) || isTransferirFondos) && (
            <div className="col-xs-12 col-sm-12 col-md-6 row center-xs end-md">
              <Button
                color="white"
                bgColor={colors.red}
                className="text-size-m"
                width={178}
                onClick={onSubmitHandle}
                disabled={
                  isLoading ||
                  !allRequiredFields(fields, fieldsValids) ||
                  (!isTransferirFondos ? destinatario?.clave?.length < 6 : false) ||
                  errors?.email ||
                  errors?.rut
                }
                id="btn-save-editar-destinatario"
              >
                <span className="text-bold">{isTransferirFondos ? texts.next : texts.edit}</span>
              </Button>
            </div>
          )}
        </div>
      </Content>
    </div>
  );
};

DatosDestinatarioTemplate.propTypes = {
  ...Formulario.propTypes,
  ...Factores.propTypes,
};

DatosDestinatarioTemplate.defaultProps = {
  ...Formulario.defaultProps,
  ...Factores.defaultProps,
};

export default DatosDestinatarioTemplate;
