import React from 'react';
import PropTypes from 'prop-types';
import Spinner from './Spinner';
// styles
import BaseButton, { IconRight, IconLeft, SpinnerWrapper } from './styles';

const _formatDataset = (datasets) => {
  let dataset = datasets;

  if (datasets && Object.keys(datasets).length) {
    dataset = Object.entries(datasets).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [`data-${key}`]: String(value),
      }),
      {}
    );
  }

  return dataset;
};

const Button = (props) => {
  function onClickHandle(event) {
    const { onClick, disabled } = props;

    if (!disabled && onClick) {
      onClick(event);
    }
  }

  const {
    asTextLink,
    className,
    fullWidth,
    iconRight,
    disabled,
    children,
    iconLeft,
    inverse,
    loading,
    bgColor,
    dataset,
    width,
    color,
    id,
    ...rest
  } = props;

  return (
    <BaseButton
      {..._formatDataset(dataset)}
      asTextLink={asTextLink}
      fullWidth={fullWidth}
      className={className}
      disabled={disabled}
      onClick={onClickHandle}
      inverse={inverse}
      bgColor={bgColor}
      width={width}
      color={color}
      type="button"
      id={id}
      {...rest}
    >
      <>
        {iconLeft && <IconLeft color={color}>{iconLeft}</IconLeft>}
        {!!loading && (
          <SpinnerWrapper>
            <Spinner fill="white" />
          </SpinnerWrapper>
        )}
        {children}
        {iconRight && <IconRight color={color}>{iconRight}</IconRight>}
      </>
    </BaseButton>
  );
};

Button.propTypes = {
  /**
   La fuente a asingar
   */
  fontFamily: PropTypes.string,
  /**
   Convierte el componente en un link pero sin cambiar la el tag
   */
  asTextLink: PropTypes.bool,
  /**
   El ancho del componente usará el 100% de su padre
   */
  fullWidth: PropTypes.bool,
  /**
   Objeto que permite agregar data sets como `data-${key}: value`
   */
  dataset: PropTypes.objectOf(PropTypes.object),
  /**
   Cualquier clase css
   */
  className: PropTypes.string,
  /**
   Icono a mostrar en el lado derecho
   */
  iconRight: PropTypes.node,
  /**
   Icono a mostrar en el lado izquiredo
   */
  iconLeft: PropTypes.node,
  /**
   Contenido del componente
   */
  children: PropTypes.node.isRequired,
  /**
   Desactiva el evento onClick y cambia de color el botón
   */
  disabled: PropTypes.bool,
  /**
   Evento onClick
   */
  onClick: PropTypes.func.isRequired,
  /**
   Asigna el color del background 
   */
  bgColor: PropTypes.string,
  /**
   Invirete los colores. El color del background por el color del texto
   y el color del texto por el del background
   */
  inverse: PropTypes.bool,
  /**
   Color del contendio del botón
   */
  color: PropTypes.string,
  /**
   Ancho del botón
   */
  width: PropTypes.number,
  /*+
  Identificador único
   */
  id: PropTypes.string,
};

Button.defaultProps = {
  fontFamily: '',
  asTextLink: false,
  fullWidth: false,
  className: '',
  iconRight: null,
  disabled: false,
  iconLeft: null,
  dataset: {},
  bgColor: '',
  inverse: false,
  width: 0,
  color: '',
  id: '',
};

export default Button;
