import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';

import { indicadorEconomicoActions } from '../../../application/actions/indicadorEconomico';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';
import { logReqError } from '../../../ui/utils/functions';

const apiIndicadorEconomico = api(process.env.REACT_APP_INDICADOR_ECONOMICO);

export function* fetchIndicadorEconomicoUf() {
  try {
    yield put(loadersActions.fetchLoadingIndicadorEconomico({ isLoading: true }));

    const { data, error } = yield call(apiIndicadorEconomico.get, `v1/uf/ultimo/obtener`);

    if (data) {
      const { valor } = data;

      yield put(indicadorEconomicoActions.fetchIndicadorEconomicoUfSuccess({ monto: valor }));
    } else {
      yield put(indicadorEconomicoActions.fetchIndicadorEconomicoUfFailed());
    }

    yield logReqError(error);
    yield put(loadersActions.fetchLoadingIndicadorEconomico({ isLoading: false }));
  } catch (err) {
    yield all([
      yield put(loadersActions.fetchLoadingIndicadorEconomico({ isLoading: false })),
      yield put(indicadorEconomicoActions.fetchIndicadorEconomicoUfFailed()),
    ]);
  }
}

export function* watchFetchIndicadorEconomicoUf() {
  yield takeLatest(
    indicadorEconomicoActions.fetchIndicadorEconomicoUf.type,
    fetchIndicadorEconomicoUf
  );
}

export default function* rootSaga() {
  yield spawn(watchFetchIndicadorEconomicoUf);
}
