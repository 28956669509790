import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { bonoEscolarActions } from '../../../../../application/actions/bonoEscolar';
import DocumentacionTemplate from '../../../../components/templates/BonoEscolar/Documentacion';
import { registerGA } from '../../../../utils/metrics';

const Documentacion = () => {
  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);
  const saveBono = useSelector((store) => store.entities.bonoEscolar.saveBono);
  const [btnContinueDisabled, setbtnContinueDisabled] = useState(true);
  const [isErrorGeneric, setIsErrorGeneric] = useState(false);
  const [loadFile, setLoadFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/beneficios/bono-estudios/solicitudes/carga-documentos',
    });
  }, []);

  // Validate Btn continue
  useEffect(() => {
    if (dataBono.typeBono === 'Bono de Ingreso' && dataBono.typeBeneficiario === 'Para Mí') {
      if (dataBono.documentos?.CEDULA_IDENTIDAD_SOCIO && dataBono.documentos?.DOCUMENTO_ADICIONAL) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }

    if (dataBono.typeBono === 'Bono de Ingreso' && dataBono.typeBeneficiario === 'Para mi hijo') {
      if (
        dataBono.documentos?.CEDULA_IDENTIDAD_SOCIO &&
        dataBono.documentos?.CEDULA_IDENTIDAD_HIJO &&
        dataBono.documentos?.DOCUMENTO_ADICIONAL
      ) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }

    if (dataBono.typeBono === 'Bono de Titulación' && dataBono.typeBeneficiario === 'Para Mí') {
      if (dataBono.documentos?.CEDULA_IDENTIDAD_SOCIO && dataBono.documentos?.CERTIFICADO_TITULO) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }

    if (
      dataBono.typeBono === 'Bono de Titulación' &&
      dataBono.typeBeneficiario === 'Para mi hijo'
    ) {
      if (
        dataBono.documentos.CEDULA_IDENTIDAD_SOCIO &&
        dataBono.documentos.CEDULA_IDENTIDAD_HIJO &&
        dataBono.documentos.CERTIFICADO_TITULO
      ) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }
    setLoadFile(false);
  }, [dataBono.documentos]);

  // Control error generic
  useEffect(() => {
    setIsLoading(false);
    if (dataBono?.documentos.error === true) {
      setIsErrorGeneric(true);
      registerGA({
        event: 'eve',
        'eve-acc': 'paso3',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    } else {
      setIsErrorGeneric(false);
    }
  }, [dataBono.documentos]);

  // Response save data
  useEffect(() => {
    if (saveBono.data != null) {
      history.push('/ecd/bono-escolar/documentos-objetados/comprobante');
    }
  }, [saveBono]);

  if (!dataBono.documentos.isRejected) {
    return <Redirect to="/ecd/bono-escolar" />;
  }
  return (
    <DocumentacionTemplate
      dataBono={dataBono}
      handleUploadFileToServer={(payload) => {
        setLoadFile(true);
        setbtnContinueDisabled(true);
        dispatch(bonoEscolarActions.fetchUploadFileToServer(payload));
      }}
      loadFile={loadFile}
      setbtnContinueDisabled={setbtnContinueDisabled}
      btnContinueDisabled={btnContinueDisabled}
      handleClickContinue={() => {
        setIsLoading(true);
        dispatch(
          bonoEscolarActions.fetchSaveBono({
            isUpdate: true,
            idPostulacion: dataBono.id,
            documentos: [
              dataBono.documentos.CEDULA_IDENTIDAD_SOCIO,
              dataBono.documentos.CEDULA_IDENTIDAD_HIJO,
              dataBono.documentos.DOCUMENTO_ADICIONAL,
              dataBono.documentos.CERTIFICADO_TITULO,
            ].filter(Boolean),
          })
        );
        setbtnContinueDisabled(true);
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios/solicitudes/carga-documentos',
          'eve-cat': 'Click',
          'eve-lab': 'Continuar',
        });
      }}
      isErrorGeneric={isErrorGeneric}
      isLoading={isLoading}
      handleClickBack={() => {
        history.push('/ecd/bono-escolar');
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios/solicitudes/carga-documentos',
          'eve-cat': 'Click',
          'eve-lab': 'Volver',
        });
      }}
      handleClickRetry={() => {
        history.push('/ecd/inicio');
      }}
    />
  );
};

export default Documentacion;
