import styled from 'styled-components';

const Wrapper = styled.div`
  .hidden_line {
    overflow: hidden;
  }
  .card-escoge-cuenta-title {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }

  .card-escoge-cuenta-desc {
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .title-expand {
    color: #383638;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    font-family: 'Ubuntu-Medium';
    margin-left: 16px;
  }

  .metodo-radio-group-container {
    display: inline-flex;
    align-items: center;
  }

  .expand-content {
    ul {
      list-style: none;
    }
  }

  .expand-list {
    align-items: center;
    display: inline-flex;
  }
  .list-desc {
    margin-left: 8px;
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .radio-button-style {
    margin-left: -5px;
  }

  .container_buttons {
    flex-direction: row;
  }

  .button-continue {
    width: 187px;
  }

  .button-return {
    font-weight: bold;
    width: 187px;
    text-align: left;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 640px) {
    .container_buttons {
      flex-direction: column-reverse;
    }
    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }

    .button-continue {
      width: 100%;
    }

    .button-return {
      margin-left: 24%;
    }
  }
`;

export const CustomList = styled.ul`
  padding: 0px 10px;

  & > li {
    font-size: 16px;
    line-height: 26px;
    position: relative;
    margin-left: 16px;
    margin-bottom: 6px;

    &::before {
      background-color: rgba(0, 93, 143, 0.85);
      border-radius: 100%;
      position: absolute;
      content: '';
      height: 6px;
      width: 6px;
      display: inline-block;
      left: -16px;
      top: 10px;
    }
  }
`;

export default Wrapper;
