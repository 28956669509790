/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';
import initialState from '../../../domain/ui/login';

// ---------------- Saga ---------------- //

function fetchUserSuccess(state) {
  state.authenticated = true;
  state.error = {
    message: '',
    id: null,
    link: {},
  };
  state.logout = { message: '' };
}

function fetchUserFailed(state, action) {
  state.error = action.payload;
  state.authenticated = false;
}

function automaticLogout(state, action) {
  state.logout = { message: action.payload };
}

function fetchLogoutSuccess() {
  localStorage.removeItem('token');
  localStorage.removeItem('user-rut');
  localStorage.removeItem('login-timedate');
  // state.authenticated = false;
}

/**
 * Acá deberían de ir las acciones que no van a interactuar con el store
 * directamente. De preferencia son las acciones que se comunicaran con la saga
 */
const fetchUserAuth = createAction('fetchUserAuth');
const fetchLogoutUser = createAction('fetchLogoutUser');

// ------------- connection -------------
const loginReducer = createSlice({
  name: 'login',
  initialState,
  reducers: {
    fetchUserSuccess,
    fetchUserFailed,
    automaticLogout,
    fetchLogoutSuccess,
  },
});

export const loginActions = {
  ...loginReducer.actions,
  fetchUserAuth,
  fetchLogoutUser,
};

export default loginReducer.reducer;
