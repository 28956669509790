import React from 'react';

import Skeleton from 'react-loading-skeleton';

import Button from '@coopeuch-components/web/atoms/Button';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import Badge from '@coopeuch-components/web/atoms/Badge';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Select from '@coopeuch-components/web/atoms/Select';
import Typography from '@coopeuch-components/web/atoms/Typography';

import { dateFormat } from '../../../../../utils/dates';
import { agregarformatoPesos, capitalizeStrFormat } from '../../../../../utils/formatters';

import { BadgeWrapper, ButtonWrapper, ContainerTipoCredito } from './styles';
import InfoAlert from '../InfoAlert';

const Index = ({
  skeleton,
  creditos,
  ventanaHoraria,
  creditoSeleccionado,
  loadingCreditoSeleccionado,
  handleChangeSelect,
  handleClickPago,
  handleClickPagoOnline,
  referToOnlinePayment = false,
  showButton = false,
}) => {
  const cierreVentana = ventanaHoraria?.cierrePago;
  const horaInicio = ventanaHoraria?.horaInicio;
  const horaFin = ventanaHoraria?.horaFin;
  const diasVencimiento = creditoSeleccionado?.cuota?.proximaCuota?.diasVencimiento || 0;
  return (
    <>
      <div className="col-md-6 col-xs-12">
        <div className="w-100 h-100 bpr-md-1">
          <CardBox className="h-100 bmr-md-1">
            <CardBox.Body>
              <ContainerTipoCredito>
                <div className="row">
                  <div className="col-xs-12 bmb-3">
                    {skeleton ? (
                      <Skeleton height={68} />
                    ) : creditos.length === 1 ? (
                      <InputLabel
                        inputTextSize={14}
                        value={`${capitalizeStrFormat(creditos[0].nombreTipoCredito)} Nº ${creditos[0].numeroReferencia
                          }`}
                        size={48}
                        inputClass="border mb-20"
                        rounded={4}
                      />
                    ) : (
                      <Select
                        textSize={14}
                        rounded={4}
                        value={creditoSeleccionado.value}
                        options={creditos}
                        onChange={handleChangeSelect}
                      />
                    )}
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      {skeleton ? <Skeleton width={'70%'} /> : 'Monto Otorgado'}
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={20}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      {loadingCreditoSeleccionado ? (
                        <Skeleton width={'60%'} />
                      ) : (
                        agregarformatoPesos(creditoSeleccionado?.montoOtorgado || '')
                      )}
                    </Typography>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      {skeleton ? <Skeleton width={'70%'} /> : 'Valor Dividendo'}
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={20}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      {loadingCreditoSeleccionado ? (
                        <Skeleton width={'60%'} />
                      ) : (
                        ` UF ${creditoSeleccionado?.cuota?.proximaCuota?.montoPagoUf || ''}`
                      )}
                    </Typography>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      {skeleton ? <Skeleton width={'70%'} /> : 'Fecha de otorgamiento'}
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={16}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      {loadingCreditoSeleccionado ? (
                        <Skeleton width={'60%'} />
                      ) : (
                        dateFormat(creditoSeleccionado?.fechaOtorgamiento || '')
                      )}
                    </Typography>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      {skeleton ? <Skeleton width={'70%'} /> : 'Dividendos pagados'}
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={16}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      {loadingCreditoSeleccionado ? (
                        <Skeleton width={'60%'} />
                      ) : (
                        <b className="boldCredito">
                          {creditoSeleccionado.dividendosPagados} de{' '}
                          {creditoSeleccionado?.dividendosTotales || ''}
                        </b>
                      )}
                    </Typography>
                  </div>
                </div>
              </ContainerTipoCredito>
            </CardBox.Body>
          </CardBox>
        </div>{' '}
      </div>
      <div className="col-md-6 col-xs-12">
        <div className="w-100 h-100 bpr-md-1">
          <CardBox className="h-100 bmr-md-1">
            <CardBox.Body>
              <div className="row align-middle">
                <div className="col-xs-12">
                  <Typography className="mb-20" tag="p" color="#333333" fontSize={16}>
                    {loadingCreditoSeleccionado ? (
                      <Skeleton width={'80%'} />
                    ) : (
                      <>
                        Número de dividendo a pagar:{' '}
                        <b className="boldCredito">
                          {creditoSeleccionado?.cuota?.proximaCuota?.nroDividendo || ''} {`de`}{' '}
                          {creditoSeleccionado?.dividendosTotales || ''}
                        </b>
                      </>
                    )}
                  </Typography>
                  <Typography className="mb-20" tag="p" color="#333333" fontSize={16}>
                    {loadingCreditoSeleccionado ? (
                      <Skeleton width={'60%'} />
                    ) : (
                      <>
                        Monto a pagar (en pesos):{' '}
                        <b className="boldCredito">
                          {agregarformatoPesos(
                            creditoSeleccionado?.cuota?.proximaCuota?.montoPagar || ''
                          )}
                        </b>
                      </>
                    )}
                  </Typography>

                  <Typography className="mb-20" tag="p" color="#333333" fontSize={16}>
                    {loadingCreditoSeleccionado ? (
                      <Skeleton width={'90%'} />
                    ) : (
                      <>
                        Fecha de vencimiento:{' '}
                        <b className="boldCredito">
                          {dateFormat(creditoSeleccionado?.cuota?.proximaCuota?.fechaVencimiento)}
                        </b>
                        {diasVencimiento > 0 && diasVencimiento <= 5 && (
                          <BadgeWrapper>
                            <Badge
                              bgColor="red"
                              className="badge"
                              color="#fff"
                              text={`Vence en ${diasVencimiento} día${diasVencimiento > 1 ? 's' : ''
                                }`}
                            />
                          </BadgeWrapper>
                        )}
                      </>
                    )}
                  </Typography>
                  <Typography tag="p" color="#333333" fontSize={16}>
                    {loadingCreditoSeleccionado ? (
                      <Skeleton width={'60%'} />
                    ) : (
                      <>
                        Modo de pago:{' '}
                        <b className="boldCredito">
                          {capitalizeStrFormat(
                            creditoSeleccionado?.cuota?.proximaCuota?.descMedioPago || ''
                          )}
                        </b>
                      </>
                    )}
                  </Typography>
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      {cierreVentana ? (
        <InfoAlert>
          No podrás realizar pagos de tu crédito hipotecario <b className="boldCredito">entre las {horaInicio} y las {horaFin} hrs. </b>Te invitamos a realizarlo fuera de este horario.
        </InfoAlert>) : (
        <>
          {showButton && (
            <div className="col-xs-12">
              <ButtonWrapper>
                {skeleton ? (
                  <Skeleton width={'25%'} height={48} />
                ) : (
                  <>
                    <Button
                      bgColor="red"
                      color="white"
                      className="btn_red"
                      onClick={referToOnlinePayment ? handleClickPagoOnline : handleClickPago}
                    >
                      {referToOnlinePayment ? 'Ir a Pago Online' : 'Paga tu Dividendo'}
                    </Button>
                  </>
                )}
              </ButtonWrapper>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Index;
