import React from 'react';

import Stepper, { RenderStep } from '../../../organism/Stepper';
import Caracteristicas from '../../../organism/Remanente/Capitalizar/Capitalizacion/Caracteristicas';
import Monto from '../../../organism/Remanente/Capitalizar/Capitalizacion/Monto';
import Comprobante from '../../../organism/Remanente/Capitalizar/Capitalizacion/Comprobante';
import ModalEnviarComprobante from '../../../organism/Remanente/Capitalizar/Capitalizacion/ModalEnviarComprobante';
import ModalComprobanteEnviado from '../../../organism/Remanente/Capitalizar/Capitalizacion/ModalComprobanteEnviado';
import ModalEnviarComprobanteError from '../../../organism/Remanente/Capitalizar/Capitalizacion/ModalEnviarComprobanteError';

const Index = ({
  steps = [],
  currentStep = 1,
  errorCuentasDisponibles,
  accountsAvailable,
  selectedAccount,
  valorCuota,
  minimoCuotas,
  maximoCuotas,
  remanentePagado,
  cantidadCuotas,
  checked,
  loadingCapitalizar,
  generalError,
  detalle,
  secondEmail,
  secondEmailError,
  handleChangeSelect,
  handleChangeInput,
  handleChangeEmail,
  openSendEmailModal,
  handleSendComprobanteEmail,
  onHandleCheckBox,
  onClickDownloadComprobante,
  backStep1,
  continueStep1,
  backStep2,
  continueStep2,
  backStep3,
  continueStep3,
  showEnviarComprobanteModal,
  showComprobanteEnviadoModal,
  showEnviarComprobanteErrorModal,
  closeModal,
}) => {
  return (
    <React.Fragment>
      {!generalError && (
        <div className="row bmy-3 bmy-md-4">
          <div className="col-xs-12">
            <Stepper className="" position={currentStep} {...{ steps }} />
          </div>
        </div>
      )}
      <RenderStep step={currentStep - 1} error={generalError}>
        <Caracteristicas
          {...{ valorCuota }}
          error={errorCuentasDisponibles}
          accounts={accountsAvailable}
          handleChangeSelect={handleChangeSelect}
          selectedAccount={selectedAccount}
          backButton={backStep1}
          nextButton={continueStep1}
        />
        <Monto
          {...{
            valorCuota,
            minimoCuotas,
            maximoCuotas,
            cantidadCuotas,
            selectedAccount,
            remanentePagado,
            checked,
            loading: loadingCapitalizar,
            handleChangeInput,
            onHandleCheckBox,
          }}
          backButton={backStep2}
          nextButton={continueStep2}
        />
        <Comprobante
          {...{ detalle, onClickDownloadComprobante, openSendEmailModal }}
          backButton={backStep3}
          nextButton={continueStep3}
        />
      </RenderStep>
      <ModalEnviarComprobante
        show={showEnviarComprobanteModal}
        email={secondEmail}
        emailError={secondEmailError}
        onHandleChangeEmail={handleChangeEmail}
        closeAction={closeModal}
        nextButton={handleSendComprobanteEmail}
      />
      <ModalComprobanteEnviado
        show={showComprobanteEnviadoModal}
        closeAction={closeModal}
        backButton={closeModal}
        nextButton={closeModal}
      />
      <ModalEnviarComprobanteError
        show={showEnviarComprobanteErrorModal}
        closeAction={closeModal}
      />
    </React.Fragment>
  );
};

export default Index;
