import styled from 'styled-components';

export const Wrapper = styled.div`
  .cuenta-swiper {
    background-color: #ffffff;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.18) !important;
  }
  .cuenta-container {
    min-height: 178px;
    padding: 16px 32px 16px 32px !important;
  }
  .cuenta-card-container {
    box-shadow: none;
  }
  .cuenta-card-container section {
    box-shadow: none;
  }

  .cuenta-icon-container {
    align-self: center;
  }

  .cuenta-icon svg {
    height: 32px;
    width: 54px;
  }

  .cuenta-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  .cuenta-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .cuenta-value {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    font-family: 'Ubuntu-Medium';
  }

  .cuenta-margin-text {
    margin-bottom: 13px;
  }

  .cuenta-text-right {
    text-align: right;
  }

  .swiper-button-next {
    margin-top: 12px;
    height: 5px;
    width: 5px;
    border: solid #007db7;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
  .swiper-button-prev {
    margin-top: 12px;
    height: 5px;
    width: 5px;
    border: solid #007db7;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }
  .swiper-pagination-bullet-active {
    background-color: #007db7;
    height: 6px;
    width: 6px;
  }
  .swiper-pagination-bullet {
    height: 6px;
    width: 6px;
  }

  @media screen and (max-width: 766px) {
    .cuenta-icon svg {
      height: 40px;
    }
    .cuenta-text-right {
      text-align: left;
    }
  }
`;

export default Wrapper;
