import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import Error from '../../../../atoms/Error';
import Select from '../../../../atoms/Select';
import Skeleton from 'react-loading-skeleton';

import Wrapper, { ActionsWrapper, CheckBoxWrapper } from './styles';

const Index = ({
  selectedAccount,
  accountsAvailable,
  accountsType,
  onChangeAccount,
  checked,
  onHandleCheckBox,
  backAction,
  nextAction,
  loading,
  error,
}) => {
  const firstText = () => {
    if (accountsType === 'CV') {
      return accountsAvailable.length === 1
        ? `Tienes activa tu Cuenta Vista Coopeuch Nº ${accountsAvailable[0].value}`
        : 'Para cobrar tu remanente debes seleccionar la cuenta de destino y luego autoriza el pago.';
    } else if (accountsType === 'MONEDERO') {
      return `Tienes activo tu Monedero Digital Coopeuch.`;
    }
  };
  const accountTypeText = accountsType === 'CV' ? 'Cuenta Vista' : 'Monedero Digital';
  return (
    <React.Fragment>
      {error ? (
        <Error />
      ) : (
        <Wrapper>
          <CardBox id="auth-cobro-remanente">
            <CardBox.Header>Cobra tu Remanente</CardBox.Header>
            <CardBox.Body>
              <p className="bmb-3">
                <b>{accountsAvailable ? firstText() : <Skeleton width={'30%'} />}</b>
              </p>
              {!!accountsAvailable && accountsAvailable.length > 1 && accountsType === 'CV' && (
                <div className="row bmb-3">
                  <div className="col-xs-12 col-md-4">
                    <Select
                      title={<b>Cuenta de Destino</b>}
                      borderColor="#333"
                      placeholder="Seleccionar una cuenta..."
                      labelClass="mb-10 mt-8 mb-8"
                      onChange={onChangeAccount}
                      options={accountsAvailable}
                      rounded={1}
                      value={selectedAccount ? selectedAccount.value : ''}
                      size={40}
                      id="select-product"
                    />
                  </div>
                </div>
              )}
              <p className="bmb-3">
                {accountsAvailable ? (
                  <React.Fragment>
                    ¿Deseas que el pago de tu Remanente se deposite en tu {accountTypeText}?
                  </React.Fragment>
                ) : (
                  <Skeleton width={'50%'} />
                )}
              </p>
              {accountsAvailable ? (
                <CheckBoxWrapper className="bmt-3">
                  <CheckBox
                    name="contratacionChecked"
                    className="checkbox-element "
                    text={`Sí, autorizo que el
                    Remanente sea depositado
                    en mi ${accountTypeText} Coopeuch`}
                    borderColor="#646569"
                    id="input-auth-contratacion-monedero"
                    fontSize={16}
                    isChecked={checked}
                    onChange={onHandleCheckBox}
                  />
                </CheckBoxWrapper>
              ) : (
                <React.Fragment>
                  <Skeleton width={'10%'} /> <Skeleton width={'50%'} />
                </React.Fragment>
              )}
            </CardBox.Body>
          </CardBox>
        </Wrapper>
      )}
      {!error && (
        <ActionsWrapper className="bmy-3 bmy-md-4">
          <StepsActions
            backText={'No por ahora'}
            nextText={'Aceptar'}
            {...{ backAction, nextAction, loading }}
            disableNextAction={!checked || error || !selectedAccount}
          />
        </ActionsWrapper>
      )}
    </React.Fragment>
  );
};

export default Index;
