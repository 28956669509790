import React, { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../components/atoms/PageTitle';
import StepperRouter from './StepperRouter';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const MigracionCuentaAhorro = (props) => {
  const [cuenta, setCuenta] = useState({});

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.cuenta) {
      setCuenta(location?.state?.cuenta);
    }
  }, [location?.state?.cuenta]);

  return (
    <>
      <div className="hide-on-mobile">
        <PageTitle
          title="Actualización de Cuenta de Ahorro"
          icon={<LoadIcon src={`${iconPath}deal.svg`} color="black" />}
        />
      </div>
      <div className="hide-on-desktop">
        <PageTitle
          title="Actualización de Cuenta"
          icon={<LoadIcon src={`${iconPath}deal.svg`} color="black" />}
        />
      </div>

      <div className="w-100 margin-after-title text-left">
        <StepperRouter {...props} cuenta={cuenta} />
      </div>
    </>
  );
};

export default MigracionCuentaAhorro;
