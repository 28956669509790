import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import ButtonBack from '../../../../organism/ActivacionTarjetas/ButtonBack';
// Util
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
import Wrapper, { SinHistorialAlert, SinHistorialIconWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const NoKit = ({ icon, destacado, text }) => {
  return (
    <Wrapper>
      <CardBox>
        <CardBox.Header>
          <Typography tag="p" className="titleKitCoopeuch">
            Activa tus productos Coopeuch
          </Typography>
          <Typography tag="p" className="kitDescription">
            Selecciona tus tarjetas que necesitas activar
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <SinHistorialAlert>
            <SinHistorialIconWrapper className="mr-16">
              <LoadIcon src={`${iconPath}${icon}`} size={20} className="w-100" color="#979797" />
            </SinHistorialIconWrapper>

            <div className="boxNoneKit">
              <span className="boxNoneKit__text">
                {destacado} <span className="boxNoneKit__text-bold">{text}</span>
              </span>
            </div>
          </SinHistorialAlert>
        </CardBox.Body>
      </CardBox>
      <ButtonBack link="/ecd/inicio" text={activacionTexts.BUTTON_RETURN} />
    </Wrapper>
  );
};

export default NoKit;
