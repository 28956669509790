import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { tarjetaCreditoActions } from '../../../application/actions/tarjetaCredito';
import { loadersActions } from '../../../application/actions/ui_loaders';

import defaultRequest, { errClaveInternet } from './errors';

import { logReqError } from '../../../ui/utils/functions';

// api
import api from '../../api';

const apiTarjetaCredito = api(process.env.REACT_APP_TARJETA_CREDITO);
const apiMovimientos = api(process.env.REACT_APP_MOVIMIENTO_TARJETA_CREDITO);

export function* fetchTarjetasCredito() {
  try {
    const { data, error } = yield call(apiTarjetaCredito.get, 'v1/obtener');
    if (data) {
      yield put(tarjetaCreditoActions.fetchTarjetasCreditoSuccess(data));
    }
    if (error) {
      yield put(tarjetaCreditoActions.fetchTarjetasCreditoError());
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchTarjetasCreditoError());
  }
}

export function* fetchTarjetasCreditoTC(action) {
  try {
    const { params } = action.payload;
    yield put(loadersActions.fetchLoadingTarjetasCredito({ isLoading: true }));
    const { data } = yield call(apiTarjetaCredito.post, 'obtener', params);
    if (data) {
      yield put(tarjetaCreditoActions.fetchTarjetasCreditoTCSuccess({ tarjetasCredito: data }));
      yield put(loadersActions.fetchLoadingTarjetasCredito({ isLoading: false }));
    } else {
      yield put(tarjetaCreditoActions.fetchTarjetasCreditoTCFailed());
      yield put(loadersActions.fetchLoadingTarjetasCredito({ isLoading: false }));
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchTarjetasCreditoTCFailed());
    yield put(loadersActions.fetchLoadingTarjetasCredito({ isLoading: false }));
  }
}

export function* fetchFacturacionTarjetaCredito(action) {
  try {
    const { numeroTarjeta } = action.payload;
    yield put(loadersActions.fetchLoadingFacturacionTarjetaCredito({ isLoading: true }));
    const { data, error } = yield call(
      apiTarjetaCredito.post,
      'facturacion/obtener/' + numeroTarjeta,
      null
    );
    if (data) {
      yield put(
        tarjetaCreditoActions.fetchFacturacionTarjetaCreditoSuccess({
          facturacionTarjetaCredito: data,
        })
      );
      yield put(loadersActions.fetchLoadingFacturacionTarjetaCredito({ isLoading: false }));
    } else {
      yield logReqError(error);
      yield put(tarjetaCreditoActions.fetchFacturacionTarjetaCreditoFailed());
      yield put(loadersActions.fetchLoadingFacturacionTarjetaCredito({ isLoading: false }));
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchFacturacionTarjetaCreditoFailed());
    yield put(loadersActions.fetchLoadingFacturacionTarjetaCredito({ isLoading: false }));
  }
}

export function* fetchCupoTarjetaCredito(action) {
  try {
    const { numeroTarjeta } = action.payload;
    yield put(loadersActions.fetchLoadingCupoTarjetaCredito({ isLoading: true }));
    const { data } = yield call(apiTarjetaCredito.get, 'cupo/obtener/' + numeroTarjeta);
    if (data) {
      yield put(tarjetaCreditoActions.fetchCupoTarjetaCreditoSuccess({ cupoTarjetaCredito: data }));
      yield put(loadersActions.fetchLoadingCupoTarjetaCredito({ isLoading: false }));
    } else {
      yield put(tarjetaCreditoActions.fetchCupoTarjetaCreditoFailed());
      yield put(loadersActions.fetchLoadingCupoTarjetaCredito({ isLoading: false }));
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchCupoTarjetaCreditoFailed());
    yield put(loadersActions.fetchLoadingCupoTarjetaCredito({ isLoading: false }));
  }
}

export function* fetchPatPassTarjetaCredito(action) {
  try {
    const { numeroTarjeta } = action.payload;
    yield put(loadersActions.fetchPatPassTarjetaCredito({ isLoading: true }));
    const { data } = yield call(
      apiTarjetaCredito.get,
      'patpass/configuracion/obtener/' + numeroTarjeta
    );
    if (data) {
      yield put(tarjetaCreditoActions.fetchPatPassTarjetaCreditoSuccess({ patPass: data }));
      yield put(loadersActions.fetchPatPassTarjetaCredito({ isLoading: false }));
    } else {
      yield put(tarjetaCreditoActions.fetchPatPassTarjetaCreditoFailed());
      yield put(loadersActions.fetchPatPassTarjetaCredito({ isLoading: false }));
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchPatPassTarjetaCreditoFailed());
    yield put(loadersActions.fetchPatPassTarjetaCredito({ isLoading: false }));
  }
}

export function* fetchCupoOfTarjetaAdicionales(action) {
  try {
    const { tarjetas } = action.payload;

    for (const index in tarjetas) {
      const numeroTarjeta = tarjetas[index]?.nroTarjeta;

      yield put(loadersActions.fetchLoadingCupoTarjetaAdicionales({ isLoading: true }));
      const { data, error } = yield call(apiTarjetaCredito.get, `cupo/obtener/${numeroTarjeta}`);

      if (data) {
        yield put(
          tarjetaCreditoActions.fetchCupoTarjetaAdicionalesSuccess({ data, numeroTarjeta })
        );
        yield put(loadersActions.fetchLoadingCupoTarjetaAdicionales({ isLoading: false }));
      } else {
        yield logReqError(error, 'GET');
        yield put(tarjetaCreditoActions.fetchCupoTarjetaAdicionalesFailed());
        yield put(loadersActions.fetchLoadingCupoTarjetaAdicionales({ isLoading: false }));
      }
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchCupoTarjetaAdicionalesFailed());
    yield put(loadersActions.fetchLoadingCupoTarjetaAdicionales({ isLoading: false }));
  }
}

export function* fetchMovimientosNoFacturados(action) {
  try {
    const { numeroCuenta } = action.payload;

    yield put(loadersActions.fetchMovimientosTarjetaCredito({ isLoading: true }));
    const { data, error } = yield call(apiMovimientos.post, `obtener`, { numeroCuenta });

    if (data) {
      yield put(tarjetaCreditoActions.fetchMovimientosNoFacturadosSuccess({ data }));
    } else {
      yield logReqError(error);
      yield put(tarjetaCreditoActions.fetchMovimientosNoFacturadosFailed());
    }

    yield put(loadersActions.fetchMovimientosTarjetaCredito({ isLoading: false }));
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchMovimientosNoFacturadosFailed());
    yield put(loadersActions.fetchMovimientosTarjetaCredito({ isLoading: false }));
  }
}

export function* fetchPagarTarjetaCredito(action) {
  try {
    const { intentos, ...body } = action.payload;

    yield put(loadersActions.fetchLoadingPagarTarjetaCredito({ isLoading: true }));
    const { data, error } = yield call(apiTarjetaCredito.post, 'pagar', body);

    if (data) {
      yield put(tarjetaCreditoActions.pagarTarjetaCreditoSuccess({ data }));
      yield put(loadersActions.fetchLoadingPagarTarjetaCredito({ isLoading: false }));
    } else {
      const claveErrors =
        error?.code === '1'
          ? errClaveInternet[4]
          : error?.code === '3' && intentos === 3
          ? errClaveInternet[2]
          : errClaveInternet[error?.code];

      yield put(
        tarjetaCreditoActions.pagarTarjetaCreditoFailed({
          error: claveErrors || defaultRequest,
        })
      );
      yield put(loadersActions.fetchLoadingPagarTarjetaCredito({ isLoading: false }));
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.pagarTarjetaCreditoFailed({ error: defaultRequest }));
    yield put(loadersActions.fetchLoadingPagarTarjetaCredito({ isLoading: false }));
  }
}

export function* pagarTarjetaCreditoComprobante(action) {
  try {
    const { pagoId } = action.payload;

    yield put(loadersActions.fetchLoadingPagarTarjetaCreditoComprobante({ isLoading: true }));

    const { data, error } = yield call(apiTarjetaCredito.get, `pago/detalle/${pagoId}`);

    if (data) {
      yield put(tarjetaCreditoActions.pagarTarjetaCreditoComprobanteSuccess({ data }));
      yield put(loadersActions.fetchLoadingPagarTarjetaCreditoComprobante({ isLoading: false }));
    } else {
      yield logReqError(error, 'GET');

      yield put(tarjetaCreditoActions.pagarTarjetaCreditoComprobanteFailed());
      yield put(loadersActions.fetchLoadingPagarTarjetaCreditoComprobante({ isLoading: false }));
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.pagarTarjetaCreditoComprobanteFailed());
    yield put(loadersActions.fetchLoadingPagarTarjetaCreditoComprobante({ isLoading: false }));
  }
}

export function* fetchPDFTarjetaCredito(action) {
  try {
    const { params } = action.payload;
    yield put(loadersActions.fetchLoadingPDFTarjetaCredito({ isLoading: true }));

    const { data, error } = yield call(apiTarjetaCredito.post, 'estadocuenta/pdf/obtener', params);

    if (data) {
      yield put(tarjetaCreditoActions.fetchPDFTarjetaCreditoSuccess({ pdfTarjetaCredito: data }));
    } else if (error?.code === 'TC-29') {
      yield put(tarjetaCreditoActions.fetchErrorNoEstadoDeCuenta());
    } else {
      yield put(tarjetaCreditoActions.fetchPDFTarjetaCreditoFailed());
    }
  } catch (err) {
    yield put(tarjetaCreditoActions.fetchPDFTarjetaCreditoFailed());
  }

  yield put(loadersActions.fetchLoadingPDFTarjetaCredito({ isLoading: false }));
}

export function* watchFetchTarjetasCredito() {
  yield takeLatest(tarjetaCreditoActions.fetchTarjetasCredito.type, fetchTarjetasCredito);
}

export function* watchFetchTarjetasCreditoTC() {
  yield takeLatest(tarjetaCreditoActions.fetchTarjetasCreditoTC.type, fetchTarjetasCreditoTC);
}

export function* watchFetchFacturacionTarjetaCredito() {
  yield takeLatest(
    tarjetaCreditoActions.fetchFacturacionTarjetaCredito.type,
    fetchFacturacionTarjetaCredito
  );
}

export function* watchFetchPatPassTarjetaCredito() {
  yield takeLatest(
    tarjetaCreditoActions.fetchPatPassTarjetaCredito.type,
    fetchPatPassTarjetaCredito
  );
}

export function* watchFetchCupoTarjetaCredito() {
  yield takeLatest(tarjetaCreditoActions.fetchCupoTarjetaCredito.type, fetchCupoTarjetaCredito);
}

export function* watchFetchPagarTarjetaCredito() {
  yield takeLatest(tarjetaCreditoActions.fetchPagarTarjetaCredito.type, fetchPagarTarjetaCredito);
}

export function* watchPagarTarjetaCreditoComprobante() {
  yield takeLatest(
    tarjetaCreditoActions.pagarTarjetaCreditoComprobante.type,
    pagarTarjetaCreditoComprobante
  );
}

export function* watchFetchCupoOfTarjetaAdicionales() {
  yield takeLatest(
    tarjetaCreditoActions.fetchCupoOfTarjetaAdicionales.type,
    fetchCupoOfTarjetaAdicionales
  );
}

export function* watchFetchMovimientosNoFacturados() {
  yield takeLatest(
    tarjetaCreditoActions.fetchMovimientosNoFacturados.type,
    fetchMovimientosNoFacturados
  );
}

export function* watchFetchPDFTarjetaCredito() {
  yield takeLatest(tarjetaCreditoActions.fetchPDFTarjetaCredito.type, fetchPDFTarjetaCredito);
}

export default function* rootSaga() {
  yield spawn(watchFetchTarjetasCredito);
  yield spawn(watchFetchTarjetasCreditoTC);
  yield spawn(watchFetchFacturacionTarjetaCredito);
  yield spawn(watchFetchCupoTarjetaCredito);
  yield spawn(watchFetchPatPassTarjetaCredito);
  yield spawn(watchFetchPagarTarjetaCredito);
  yield spawn(watchPagarTarjetaCreditoComprobante);
  yield spawn(watchFetchCupoOfTarjetaAdicionales);
  yield spawn(watchFetchMovimientosNoFacturados);
  yield spawn(watchFetchPDFTarjetaCredito);
}
