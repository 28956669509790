import { createSlice, createAction } from '@reduxjs/toolkit';

import initialState from '../../../domain/entities/creditoHipotecario';

import currencyFormatter from '../../core/formatters/currencyFormatter';
import dateFormatter from '../../core/formatters/dateFormatter';

function fetchCreditoSuccess(state, action) {
  state.credito = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchCreditoError(state) {
  state.credito = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchCuotaSuccess(state, action) {
  state.cuota = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchCuotaError(state) {
  state.cuota = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchHistorialPagoSuccess(state, action) {
  state.historialPago = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchHistorialPagoError(state) {
  state.historialPago = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchHistorialPdfPagoSuccess(state, action) {
  state.pdfHistorialPagado = {
    success: true,
    error: false,
    data: action.payload.data,
  };
}

function fetchHistorialPdfPagoError(state) {
  state.pdfHistorialPagado = {
    success: false,
    error: true,
    data: null,
  };
}

function resetDataPDFMovimientos(state) {
  state.pdfMovements = { success: false, error: false, data: null };
}

function clearPay(state) {
  state.pay.success = false;
  state.pay.error = { server: false, wrongPassword: false, blockedPassword: false };
  state.pay.data = {};
  state.pay.loading = false;
}

function fetchPayLoading(state) {
  state.pay.loading = true;
}

function fetchPaySuccess(state, action) {
  state.pay.success = true;
  state.pay.error = { server: false, wrongPassword: false, blockedPassword: false };
  state.pay.data = {
    amount: action.payload.montoPagado,
    referenceNumber: action.payload.numeroReferencia,
    paymentDate: action.payload.fechaPago,
    paymentHour: action.payload.horaPago,
    paymentMethod: action.payload.metodoPago,
    accountNumber: action.payload.numeroCuenta,
  };
  state.pay.loading = false;
}

function fetchPayServerError(state) {
  state.pay.success = false;
  state.pay.error = { server: true, wrongPassword: false, blockedPassword: false };
  state.pay.data = {};
  state.pay.loading = false;
}

function fetchPayPasswordError(state, action) {
  const { code } = action.payload;

  switch (code) {
    case '01':
      state.pay.error = { server: false, wrongPassword: true, blockedPassword: false };
      state.pay.data = {
        title: 'Clave incorrecta',
        message: 'Los datos ingresados son incorrectos. Favor intente nuevamente.',
        buttonTitle: 'Reintentar',
      };
      break;
    case '02':
      state.pay.error = { server: false, wrongPassword: true, blockedPassword: false };
      state.pay.data = {
        title: 'Clave incorrecta',
        message: 'Recuerda que al tercer intento se bloqueará la contraseña.',
        buttonTitle: 'Reintentar',
      };
      break;
    case '03':
      state.pay.error = { server: false, wrongPassword: false, blockedPassword: true };
      state.pay.data = {
        title: 'Clave Bloqueada',
        message: 'Clave Bloqueada. Por seguridad, hemos bloqueado tu clave.',
        buttonTitle: 'Cambiar clave',
      };
      break;
    default:
      state.pay.error = { server: true, wrongPassword: false, blockedPassword: false };
      state.pay.data = {};
      break;
  }

  state.pay.success = false;
  state.pay.loading = false;
}

function fetchAvailableAccountsLoading(state) {
  state.availableAccounts.success = false;
  state.availableAccounts.error = { server: false, noData: false };
  state.availableAccounts.data = [];
  state.availableAccounts.loading = true;
}

function fetchAvailableAccountsSuccess(state, action) {
  const accounts = action.payload.map(({ numeroCuenta, saldoDisponible }) => ({
    label: `Vista: Nº ${numeroCuenta}`,
    value: numeroCuenta,
    balance: {
      value: saldoDisponible,
      label: currencyFormatter({ value: saldoDisponible }),
    },
  }));

  state.availableAccounts.success = true;
  state.availableAccounts.error = { server: false, noData: false };
  state.availableAccounts.data = accounts;
  state.availableAccounts.loading = false;
}

function fetchAvailableAccountsNoData(state) {
  state.availableAccounts.success = true;
  state.availableAccounts.error = { server: false, noData: true };
  state.availableAccounts.data = [];
  state.availableAccounts.loading = false;
}

function fetchAvailableAccountsError(state) {
  state.availableAccounts.success = false;
  state.availableAccounts.error = { server: true, noData: false };
  state.availableAccounts.data = [];
  state.availableAccounts.loading = false;
}

function changeSelectedLoanToPay(state, action) {
  if (!action.payload.cuota?.proximaCuota) {
    state.pay.loanToPay = {
      paymentAllowed: false,
      automaticPaymentMethod: null,
    };
    return;
  }

  const { codEstado } = action.payload;
  const { montoPagar, montoPagoUf, nroDividendo, fechaVencimiento, codMedioPago } =
    action.payload.cuota.proximaCuota;

  const expirationDate = dateFormatter({
    date: fechaVencimiento,
    splitter: '-',
    yearPosition: 0,
    monthPosition: 1,
    dayPosition: 2,
  });

  // Si el socio tiene asociado un medio de pago automático, no se le permite el pago
  // a través del sitio
  let automaticPaymentMethod = null;

  // Reglas de negocio:
  // - Sólo permitir pagar créditos con próxima cuota
  // - Sólo permitir pagar créditos en estado Vigente o Vencido (códigos 1 y 2)
  // - Sólo permitir pagar créditos con medios de pago (caja = " " y no programados = "NP")
  let paymentAllowed = false;

  const statusOk = codEstado === '1' || codEstado === '2';
  const paymentMethodOk = codMedioPago === ' ' || codMedioPago === 'NP';

  if (!paymentMethodOk) {
    automaticPaymentMethod =
      codMedioPago === '2' ? 'PAT (cargo de Tarjeta de crédito)' : 'PAC Multibanco';
  }

  paymentAllowed = statusOk && paymentMethodOk;

  state.pay.loanToPay = {
    feeNumber: nroDividendo,
    expirationDate,
    feeUFAmount: {
      value: montoPagoUf,
      label: `UF ${montoPagoUf.toString().replace('.', ',')}`,
    },
    feeAmount: {
      value: montoPagar,
      label: currencyFormatter({ value: montoPagar }),
    },
    referenceNumber: action.payload.numeroReferencia,
    paymentAllowed,
    automaticPaymentMethod,
  };
}
function fetchVentanaHorariaSuccess(state, action) {
  state.ventanaHoraria = {
    success: true,
    error: false,
    data: { 
      horaInicio: (action.payload.horaInicio).slice(0, 5), 
      horaFin:( action.payload.horaFin).slice(0, 5), 
      cierrePago: action.payload.cierrePago 
    }
  };
}

function fetchVentanaHorariaError(state) {
  state.ventanaHoraria = {
    success: false,
    error: true,
    data: null
  };
}

function fetchcomprobantePagoSuccess(state, action) {
  const paymentDate = dateFormatter({
    date: action.payload.fechaPago.split(" ")[0],
    splitter: '-',
    dayPosition: 0,
    monthPosition: 1,
    yearPosition: 2,
  });
  state.comprobantePago = {
    success: true,
    error: false,
    data: {
      mail: action.payload.correo,
      paymentDate,
      paymentMethod: action.payload.metodoPago,
      amount: action.payload.montoPagado,
      creditNumber: action.payload.numeroCredito,
      referenceNumber: action.payload.numeroReferencia,
      accountNumber: action.payload.numeroCuenta,
      paidDividendNumber: action.payload.numeroDividendoPagado,
    },
  };
}

function fetchcomprobantePagoError(state) {
  state.comprobantePago = {
    success: false,
    error: true,
    data: null,
  };
}

function fetchComprobantePdfPagoSuccess(state, action) {
  state.comprobantePdfPago = {
    success: true,
    error: false,
    data: action.payload.data,
  };
}

function fetchComprobantePdfPagoError(state) {
  state.comprobantePdfPago = {
    success: false,
    error: true,
    data: null,
  };
}


const fetchCredito = createAction('fetchCredito');
const fetchCuota = createAction('fetchCuota');
const fetchHistorialPago = createAction('fetchHistorialPago');
const fetchHistorialPdfPago = createAction('fetchHistorialPdfPago');
const fetchVentanaHoraria = createAction('fetchVentanaHoraria');
const fetchPay = createAction('fetchPay');
const fetchAvailableAccounts = createAction('fetchAvailableAccounts');
const fetchComprobantePago = createAction('fetchComprobantePago');
const fetchComprobantePdfPago = createAction('fetchComprobantePdfPago');

const creditoHipotecarioReducer = createSlice({
  name: 'creditoHipotecario',
  initialState,
  reducers: {
    fetchCreditoSuccess,
    fetchCreditoError,
    fetchCuotaSuccess,
    fetchCuotaError,
    fetchHistorialPagoSuccess,
    fetchHistorialPagoError,
    fetchHistorialPdfPagoSuccess,
    fetchHistorialPdfPagoError,
    resetDataPDFMovimientos,
    fetchPayLoading,
    fetchPaySuccess,
    fetchPayServerError,
    fetchPayPasswordError,
    changeSelectedLoanToPay,
    fetchAvailableAccountsSuccess,
    fetchAvailableAccountsNoData,
    fetchAvailableAccountsError,
    fetchAvailableAccountsLoading,
    clearPay,
    fetchcomprobantePagoSuccess,
    fetchcomprobantePagoError,
    fetchComprobantePdfPagoSuccess,
    fetchComprobantePdfPagoError,
    fetchVentanaHorariaSuccess,
    fetchVentanaHorariaError,
  },
});

export const creditoHipotecarioActions = {
  ...creditoHipotecarioReducer.actions,
  fetchCredito,
  fetchCuota,
  fetchHistorialPago,
  fetchHistorialPdfPago,
  fetchPay,
  fetchAvailableAccounts,
  fetchComprobantePago,
  fetchComprobantePdfPago,
  fetchVentanaHoraria,
};

export default creditoHipotecarioReducer.reducer;
