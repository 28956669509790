import React from 'react';
import PropTypes from 'prop-types';
// styles
import Card from './styles';

const CardShadow = ({ borderColor, className, content, rounded, title, padding, customHeader }) => (
  <Card borderColor={borderColor} className={className} rounded={rounded} padding={padding}>
    {customHeader ? (
      customHeader
    ) : (
      <div className="header">{title && <h1 className="header__title">{title}</h1>}</div>
    )}

    <div className="body">{content}</div>
  </Card>
);

CardShadow.propTypes = {
  borderColor: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.node,
  rounded: PropTypes.number,
  title: PropTypes.string,
  padding: PropTypes.number,
  customHeader: PropTypes.node,
};

CardShadow.defaultProps = {
  borderColor: '',
  className: '',
  content: null,
  rounded: 5,
  title: '',
  padding: 32,
  customHeader: null,
};

export default CardShadow;
