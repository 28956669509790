import React from 'react';
// Organism
import SocioSinPuntos from '../../../organism/PuntosCoopeuch/SocioSinPuntos';
import SocioPuntos from '../../../organism/PuntosCoopeuch/SocioPuntos';
import ErrorPoints from '../../../organism/PuntosCoopeuch/ErrorPoints';
import ModalError from '../../../organism/PuntosCoopeuch/ModalError';

const PuntosCoopeuchTemplate = ({
  puntosObtener,
  handleClickCanjear,
  handleClickRetry,
  isVisibleModal,
  setIsVisibleModal,
  handleClickAcumular,
  handleClickEntendido,
  isError,
}) => {
  return (
    <React.Fragment>
      {isError === true && <ErrorPoints handleClickRetry={handleClickRetry} />}
      {parseInt(puntosObtener?.data?.puntosDisponibles) > 0 && (
        <SocioPuntos puntosObtener={puntosObtener} handleClickCanjear={handleClickCanjear} />
      )}
      {parseInt(puntosObtener?.data?.puntosDisponibles) === 0 && (
        <SocioSinPuntos handleClickAcumular={handleClickAcumular} />
      )}
      {puntosObtener?.error?.statusCode === 400 && (
        <SocioSinPuntos handleClickAcumular={handleClickAcumular} />
      )}
      <ModalError
        isVisibleModal={isVisibleModal}
        setIsVisibleModal={setIsVisibleModal}
        handleClickEntendido={handleClickEntendido}
      />
    </React.Fragment>
  );
};

export default PuntosCoopeuchTemplate;
