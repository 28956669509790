import styled from 'styled-components';

export const LabelWrapper = styled.label`
  float: left;
  font-size: ${({ main }) => (main ? '16px' : '14px')};
  color: ${({ main }) => (main ? '#383638' : '#6d6d6d')};
  font-weight: ${({ main }) => (main ? 'bold' : 'normal')};
`;

export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }
  div {
    div {
      float: left;
    }
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;
