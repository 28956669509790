import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { beneficiosActions } from '../../../application/actions/beneficios';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiBeneficios = api(process.env.REACT_APP_BENEFICIOS);
const apiRegion = api(process.env.REACT_APP_REGION);

export function* fetchBeneficiosObtener(action) {
  try {

    yield put(loadersActions.fetchLoadingBeneficios({ isLoading: true }));
    const { data, error } = yield call(apiBeneficios.post, 'beneficio/v1/obtener', action.payload);

    if (data) {
      yield put(beneficiosActions.fetchBeneficiosObtenerSuccess({ data }));
    } else {
      yield put(beneficiosActions.fetchBeneficiosObtenerError({ error: error }));
    }
    yield put(loadersActions.fetchLoadingBeneficios({ isLoading: false }));
  } catch (err) {
    yield put(beneficiosActions.fetchBeneficiosObtenerError({ error: err }));
    yield put(loadersActions.fetchLoadingBeneficios({ isLoading: false }));
  }
}

export function* fetchRubroObtener() {
  try {
    yield put(loadersActions.fetchLoadingRubro({ isLoading: true }));
    const { data, error } = yield call(apiBeneficios.get, 'rubro/v1/obtener');
    if (data) {
      yield put(beneficiosActions.fetchRubroObtenerSuccess({ data }));
    } else {
      yield put(beneficiosActions.fetchRubroObtenerError({ error: error }));
    }
    yield put(loadersActions.fetchLoadingRubro({ isLoading: false }));
  } catch (error) {
    yield put(beneficiosActions.fetchRubroObtenerError({ error: error }));
    yield put(loadersActions.fetchLoadingRubro({ isLoading: false }));
  }
}

export function* fetchRegionObtener() {
  try {
    yield put(loadersActions.fetchLoadingRegion({ isLoading: true }));
    const { data, error } = yield call(apiRegion.get, 'v1/obtener');
    if (data) {
      yield put(beneficiosActions.fetchRegionObtenerSuccess({ data }));
    } else {
      yield put(beneficiosActions.fetchRegionObtenerError({ error: error }));
    }
    yield put(loadersActions.fetchLoadingRegion({ isLoading: false }));
  } catch (error) {
    yield put(beneficiosActions.fetchRegionObtenerError({ error: error }));
    yield put(loadersActions.fetchLoadingRegion({ isLoading: false }));
  }
}

export function* watchFetchBeneficiosObtener() {
  yield takeLatest(beneficiosActions.fetchBeneficiosObtener.type, fetchBeneficiosObtener);
}
export function* watchFetchRubroObtener() {
  yield takeLatest(beneficiosActions.fetchRubroObtener.type, fetchRubroObtener);
}
export function* watchFetchRegionObtener() {
  yield takeLatest(beneficiosActions.fetchRegionObtener.type, fetchRegionObtener);
}

export default function* rootSaga() {
  yield spawn(watchFetchBeneficiosObtener);
  yield spawn(watchFetchRubroObtener);
  yield spawn(watchFetchRegionObtener);
}
