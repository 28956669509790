import React from 'react';

const EditarIcon = () => (
  <svg width="22px" height="22px" viewBox="0 0 22 22">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="post">
        <rect id="Rectangle" x="0" y="0" width="22" height="22"></rect>
        <path
          d="M18.5625,11 L18.5625,19.25 C18.5625,20.3890873 17.6390873,21.3125 16.5,21.3125 L2.75,21.3125 C1.6109127,21.3125 0.6875,20.3890873 0.6875,19.25 L0.6875,5.5 C0.6875,4.3609127 1.6109127,3.4375 2.75,3.4375 L11,3.4375 C11.3796958,3.4375 11.6875,3.74530423 11.6875,4.125 C11.6875,4.50469577 11.3796958,4.8125 11,4.8125 L2.75,4.8125 C2.37030423,4.8125 2.0625,5.12030423 2.0625,5.5 L2.0625,19.25 C2.0625,19.6296958 2.37030423,19.9375 2.75,19.9375 L16.5,19.9375 C16.8796958,19.9375 17.1875,19.6296958 17.1875,19.25 L17.1875,11 C17.1875,10.6203042 17.4953042,10.3125 17.875,10.3125 C18.2546958,10.3125 18.5625,10.6203042 18.5625,11 Z M21.1110625,4.6110625 L12.8610625,12.8610625 C12.7321612,12.9900027 12.5573214,13.0625 12.375,13.0625 L9.625,13.0625 C9.24530423,13.0625 8.9375,12.7546958 8.9375,12.375 L8.9375,9.625 C8.9375,9.44267857 9.0099973,9.26783875 9.1389375,9.1389375 L17.3889375,0.8889375 C17.6574061,0.620549917 18.0925939,0.620549917 18.3610625,0.8889375 L21.1110625,3.6389375 C21.3794501,3.90740615 21.3794501,4.34259385 21.1110625,4.6110625 Z M19.652875,4.125 L17.875,2.347125 L10.3125,9.909625 L10.3125,11.6875 L12.090375,11.6875 L19.652875,4.125 Z"
          id="Shape"
          fill="#383638"
          fillRule="nonzero"
        ></path>
      </g>
    </g>
  </svg>
);

export default EditarIcon;
