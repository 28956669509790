import styled from 'styled-components';

export const Text = styled.p`
  color: #383638;
  letter-spacing: 0;
  text-align: center;
  font-size: ${({ textSize }) => `${textSize}px`};
  line-height: ${({ size }) => `${size}px`};
  font-weight: ${({ weight }) => weight};
`;
export const Img = styled.img`
  height: 100px;
  width: auto;
  margin: 0 auto;
`;
export const Wrapper = styled.div`
  .label-email-comprobante {
    font-weight: bold;
    margin-bottom: 15px;
  }
`;
export const MessageError = styled.div`
  width: 100%;
  float: left;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;
