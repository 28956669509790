/* eslint-disable import/prefer-default-export */

import Styled from 'styled-components';

export const Paragraph = Styled.div`
.container_icon_title{
  display: flex;
}
  @media (min-width: 64em) {
    margin-left: ${({ marginLeft }) => `${marginLeft || '0'}px`};
  }
  font-size: ${({ size }) => `${size || '16'}px`};
  letter-spacing: 0;
  line-height: 26px;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  & .login--wrapper-checkbox {
      width: 100%;
      display: flex;
      margin-left: -6px;
      margin-top: -12px;
      margin-bottom: 12px;
    }
`;
