import React, { useState, useEffect } from 'react';
import produce from 'immer';
import Helmet from 'react-helmet';

import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// actions
import { loginActions } from '../../../application/actions/ui_login';
// componetns
import LoginTemplate from '../../components/templates/Login';

import { isRunValid } from '../../utils/validators';
import { registerGA } from '../../utils/metrics';
import { encrypt } from '../../utils/functions';

const forge = require('node-forge');

const recaptchaPath = process.env.REACT_APP_RECAPTCHA;
const recaptchaPathInterno = process.env.REACT_APP_RECAPTCHA_INTERNAL;
const tokenAuth =  process.env.REACT_APP_TOKEN_KMS;

const LoginContainer = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    rut: '',
    password: '',
  });
  const [remember, setRemember] = useState(false);

  const [state, setState] = useState({
    rut: '',
    password: '',
  });
  const [keySite, setKeySite] = useState("");
  const [versionCaptcha, setVersionCaptcha] = useState("E");

  const {isInternal = false} = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const storeData = useSelector((store) => store.ui.login, shallowEqual);
  const autoLogout = useSelector((store) => store.ui.login.logout);


  useEffect(() => {
    if (isInternal) {
      setKeySite(recaptchaPathInterno);
      setVersionCaptcha("I"); // Internal
    } else {
      setKeySite(recaptchaPath);
      setVersionCaptcha("E"); // External
    }
  }, [isInternal])

  function onChangeHandle(event) {
    const { name, value } = event.target;
    setError(
      produce((draft) => {
        if (name === 'rut') {
          draft.rut = !isRunValid(value) ? 'El RUT no es válido' : '';
        } else {
          draft.password =
            value.length < 6 || value.length > 8
              ? 'La clave debe tener entre 6 y 8 caracteres.'
              : '';
        }
      })
    );

    setState(
      produce((draft) => {
        if (name === 'rut') {
          let formattedRut = value.toLocaleLowerCase().replace(/[^\dk]/g, '');

          if (formattedRut.length > 11) {
            formattedRut = formattedRut.substring(0, 11);
          }

          if (formattedRut.length > 1) {
            formattedRut = `${formattedRut.slice(0, -1)}-${formattedRut.slice(-1)}`;
          }

          draft.rut = formattedRut;
        } else {
          draft[name] = value;
        }
      })
    );
  }
  function RSAOAEPPadding(pwd) {
    const publicKeyPem = tokenAuth;
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem)
    const encryptedBytes = publicKey.encrypt(pwd, 'RSA-OAEP', {
      md: forge.md.sha256.create()
    })
    return forge.util.encode64(encryptedBytes);
  }
  function onSubmitHandle(captcha) {
    const { password, rut } = state;
    const pdwHash = RSAOAEPPadding(password)
    setLoading(true);
    dispatch(loginActions.fetchUserAuth({ password: pdwHash, passwordPlain: password, rut, captcha, remember, versionCaptcha }));
    registerGA({ event: 'eve', 'eve-acc': '/login', 'eve-cat': 'Click', 'eve-lab': 'Ingresar' });
    window.grecaptcha && window.grecaptcha.reset();
  }

  function onToggleHandle() {
    setShow(!show);
  }

  function onRememberHandle() {
    setRemember(!remember);
  }

  function onClickErrorLink(code) {
    if (code === 11) {
      registerGA({
        event: 'eve',
        'eve-acc': '/login',
        'eve-cat': 'Click',
        'eve-lab': 'Actualizala aqui',
      });
    }
    if (code === 12) {
      registerGA({
        event: 'eve',
        'eve-acc': '/login',
        'eve-cat': 'Click',
        'eve-lab': 'Cambiar clave',
      });
    }
  }

  useEffect(() => {
    let isUnmounted = false;

    localStorage.setItem('consultarContexto', props.match.params.id || null);

    if (storeData.authenticated && !isUnmounted) {
      registerGA({
        event: 'eve',
        'eve-acc': '/login',
        'eve-cat': 'Click',
        'eve-lab': 'Ingreso exitoso',
      });

      encrypt(state.rut)
        .then((result) => {
          registerGA({ event: 'vpv-privado', page: '/tef/home', userid: result });
        })
        .catch((err) => {
          console.log(err);
        });
      const rutsConRemanente = process.env.REACT_APP_RUT_CON_REMANENTE
        ? process.env.REACT_APP_RUT_CON_REMANENTE.split(' ')
        : [];

      if (rutsConRemanente.includes(state.rut)) {
        history.push('/ecd/productos/remanente');
      } else {
        history.push(process.env.REACT_APP_LOGIN_NEXT_PAGE || '/ecd/inicio');
      }
    } else {
      setLoading(false);
    }
    return () => {
      isUnmounted = true;
    };
  }, [history, storeData]);

  useEffect(() => {
    let isUnmounted = false;
    const storageRut = localStorage.getItem('remember-rut');
    if (storageRut && !isUnmounted) {
      setRemember(Boolean(storageRut));
      setState(
        produce((draft) => {
          draft.rut = storageRut;
        })
      );
    }

    registerGA({ event: 'vpv-privado', page: '/login' });

    return () => {
      isUnmounted = true;
    };
  }, []);

  useEffect(() => {
    window.onSubmitHandle = onSubmitHandle;

    return () => {
      delete window.onSubmitHandle;
    };
  }, [state, remember]);

  // se crea bandera para el captcha
  useEffect(() => {
    if (
      localStorage.getItem('RefreshTokenCaptcha') === 'false' ||
      localStorage.getItem('RefreshTokenCaptcha') === null
    ) {
      window.location.reload();
      localStorage.setItem('RefreshTokenCaptcha', 'true');
    } else if (localStorage.getItem('pathLogin') == null || (`${window.location.hash == '#/' ? 'login' : window.location.hash}` != localStorage.getItem('pathLogin'))) {
      window.location.reload();
      localStorage.setItem('pathLogin', `${window.location.hash == '#/' ? 'login' : window.location.hash}`);
    }
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script src="https://www.google.com/recaptcha/api.js"></script>
      </Helmet>
      <LoginTemplate
        error={error}
        autoLogout={autoLogout}
        serviceError={storeData.error}
        disableSubmit={
          !(
            state.rut.length >= 1 &&
            state.password.length >= 1 &&
            error.rut.length === 0 &&
            error.password.length === 0
          )
        }
        rut={state.rut}
        password={state.password}
        remember={remember}
        onChangeHandle={onChangeHandle}
        onSubmitHandle={onSubmitHandle}
        onRememberHandle={onRememberHandle}
        onToggleHandle={onToggleHandle}
        onClickErrorLink={onClickErrorLink}
        show={show}
        loading={loading}
        recaptchaPath={keySite}
      />
    </React.Fragment>
  );
};

export default LoginContainer;
