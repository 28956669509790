/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react';

// UI
// eslint-disable-next-line import/no-extraneous-dependencies
import { Input } from '@design-system-coopeuch/web';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import CardHeader from '@coopeuch-components/web/molecules/CardBox/CardHeader';
import CardContrato from '../../../../organism/CuentaAhorro/Contratacion/CardContrato';
import InputLabel from '../../../../atoms/InputLabel';
import CardPassCoopeuch from '../../../../organism/FactoresDeSeguridad/PassCoopeuch';
import TarjetaDeCoordenadas from '../../../../organism/FactoresDeSeguridad/TarjetaDeCoordenadas';
import ErrorCard from '../../../../organism/ErrorCard';
import SpinnerLoader from '../../../../atoms/SpinnerLoader';
import NotPassCoopeuch from './NotPassCoopeuch';

// assets
import radioOff from './RadioIcons/rb1.svg';
import radioOn from './RadioIcons/rb2.svg';
import checkOk from '../../../../../assets/svg/check-verde.svg';
// eslint-disable-next-line no-unused-vars
import checkFail from '../../../../../assets/svg/check-rojo.svg';
import { contratacionCATexts } from '../../../../../containers/CuentaAhorro/texts';

// Styles
import Wrapper, { ContainerCheck, LoaderWrapper, MessageError, CardFooter } from './styles';

// Utils
import { isValidEmail } from '../../../../../utils/formatters';
import { capitalize } from '../../../../../utils/functions';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CuentaTemplate = ({
  ischangeWidth,
  contratosPDF,
  downloadDocumentPDF,
  showScreenError,
  isCheckedCheckBox,
  setIsCheckedCheckBox,
  mailSocio,
  onChangeEmail,
  mailSeleccion,
  setMailSeleccion,
  newMail,
  handleClickReturn,
  isLoadingContratGenerada,

  // codigo ejecutivo

  codeExec,
  executiveData,
  onChangeCodeExec,
  handleOnClickCodeExec,

  // Mapeo factores

  authorizationMethod,

  // Pass Coopeuch
  hasCpass,
  timeValue,
  isLoadingGetFactor,
  isLoadingAuthorizationInitialize,
  handleClickPassCoopeuch,
  handleClickErrorButton,

  // TCOO
  hasTcoo,
  switchActiveFactor,
  handleClickTcooButton,
  handleInputChange,

  // validacion
  buttonLock,
}) => {
  const isCpass = authorizationMethod.passCoopeuch.isActive;
  const errorCard = isCpass
    ? authorizationMethod.passCoopeuch.errorCard
    : authorizationMethod.tarjetaCoordenadas.errorCard;

  const errorMessage = (message) => (
    <MessageError className="input-error-simulacion-cdc text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${iconPath}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        <span id="input-error-message">{message}</span>
      </div>
    </MessageError>
  );

  return (
    <Wrapper>
      {showScreenError ? (
        <div className="bmt-md-4 bmt-3">
          <ErrorCard
            titleText="¡Lo sentimos!"
            descText="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          />
        </div>
      ) : (
        <div>
          <div className="bmb-4">
            <CardBox>
              <CardBox.Header>
                <label className="card-escoge-cuenta-title">{contratacionCATexts.CARD_TITLE}</label>
              </CardBox.Header>
              <CardBox.Body>
                <label className="card-escoge-cuenta-desc">{contratacionCATexts.CARD_DESC}</label>
                {contratosPDF &&
                  contratosPDF.map((document, index) => (
                    <CardContrato
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      nombreDocumento={document.nombreDocumento}
                      titulo={document.titulo}
                      descripcion={document.descripcion}
                      downloadDocumentPDF={downloadDocumentPDF}
                    />
                  ))}
                <ContainerCheck>
                  <CheckBox
                    onChange={() => setIsCheckedCheckBox(!isCheckedCheckBox)}
                    isChecked={isCheckedCheckBox}
                  />
                  <p className="textCheck">
                    Acepto los términos y condiciones establecidos en los contratos y declaro
                    haberlos leido.
                  </p>
                </ContainerCheck>
              </CardBox.Body>
            </CardBox>
          </div>
          <div className="bmb-4">
            <CardBox>
              <CardBox.Header>Envío de documentos y contratos</CardBox.Header>
              <CardBox.Body>
                <Typography
                  tag="p"
                  color="#383638"
                  fontSize={16}
                  className="card-escoge-cuenta-desc"
                >
                  Al firmar electrónicamente, te enviaremos tus contratos a tu email:
                </Typography>

                <div className="containerCheck mt-14">
                  {mailSocio && (
                    <div className="metodo-radio-group-container">
                      <img
                        src={mailSeleccion === 'socio' ? radioOn : radioOff}
                        onClick={() => setMailSeleccion('socio')}
                        className="radio-button-style"
                        alt="option"
                        aria-hidden="true"
                      />
                      <p className="title-expand">
                        Email registrado
                        {mailSocio}
                      </p>
                    </div>
                  )}

                  <div className="metodo-radio-group-container mt-14">
                    <img
                      src={mailSeleccion === 'otro' ? radioOn : radioOff}
                      onClick={() => setMailSeleccion('otro')}
                      className="radio-button-style"
                      alt="option"
                      aria-hidden="true"
                    />
                    <p className="title-expand">Enviar a otra cuenta de email.</p>
                  </div>
                  {mailSeleccion === 'otro' && (
                    <div className="metodo-input-mail-container mt-14 ml-34">
                      <InputLabel
                        borderColor="#007DB7"
                        rounded={2}
                        name="correo"
                        id="correo"
                        size={48}
                        inputClass="widthInput"
                        onChange={onChangeEmail}
                        value={newMail}
                        errorMessage={
                          !!newMail.length && !isValidEmail(newMail)
                            ? errorMessage('El email ingresado no es válido')
                            : null
                        }
                      />
                    </div>
                  )}
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
          <div className="bmb-4">
            <CardBox>
              <CardBox.Header>
                ¿En este proceso fuiste o estás siendo atendido por un ejecutivo?
              </CardBox.Header>
              <CardBox.Body>
                {/* TODO: ver como integrar los states con los radio y menjar el input change */}
                <div className="containerCheck mt-14">
                  <div className="metodo-radio-group-container mb-14">
                    <img
                      src={codeExec.value === 'yes' ? radioOn : radioOff}
                      // eslint-disable-next-line react/prop-types
                      onClick={() => handleOnClickCodeExec('yes')}
                      className="radio-button-style"
                      alt="option"
                      aria-hidden="true"
                    />
                    <p className="title-expand">Sí</p>
                  </div>
                  <div className="metodo-radio-group-container">
                    <img
                      src={codeExec.value === 'no' ? radioOn : radioOff}
                      // eslint-disable-next-line react/prop-types
                      onClick={() => handleOnClickCodeExec('no')}
                      className="radio-button-style"
                      alt="option"
                      aria-hidden="true"
                    />
                    <p className="title-expand">No</p>
                  </div>

                  <div className="metodo-input-cod-ejecutivo-container mt-14">
                    {codeExec.showInputCode && (
                      <Typography
                        tag="p"
                        color="#383638"
                        fontSize={16}
                        fontWeight={600}
                        className="text-center pb-4"
                      >
                        Ingresa el código (opcional)
                      </Typography>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      {codeExec.showInputCode && (
                        <>
                          <Input value={codeExec.code} onChange={onChangeCodeExec} maxLength={4} />
                          <div className="row d-flex flex-row pt-2 ml-2">
                            {/* TODO: condicion para check codigo ok o not ok */}
                            {executiveData?.success && (
                              <>
                                <img src={checkOk} alt="check-ok" />
                                <Typography tag="p" color="#238805" fontSize={16} className="pl-1">
                                  Código correcto
                                </Typography>
                              </>
                            )}
                            {executiveData?.error && (
                              <>
                                <img src={checkFail} alt="check-ok" />
                                <Typography tag="p" color="#E81E2B" fontSize={16} className="pl-1">
                                  Código Incorrecto
                                </Typography>
                              </>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                    {executiveData?.success && codeExec.showInputCode && (
                      <div className="col-12 col-md-8 d-flex flex-row pt-1">
                        <LoadIcon src={`${iconPath}customer_service.svg`} size={45} color="#000" />
                        <Typography
                          tag="p"
                          color="#383638"
                          fontSize={16}
                          fontWeight={600}
                          className="pt-3 pl-3"
                        >
                          {capitalize(executiveData?.executiveName)}
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>

          <CardBox>
            <CardHeader>
              {isCpass
                ? 'Autorización con PassCoopeuch'
                : 'Autorización con Tarjeta de Coordenadas'}
            </CardHeader>
            <CardBox.Body>
              {/* eslint-disable-next-line no-nested-ternary */}
              {errorCard.show ? (
                <ErrorCard
                  titleText={errorCard.title}
                  showButton={errorCard.showButton}
                  descText={errorCard.message}
                  buttonText={errorCard.buttonText}
                  onClickButton={handleClickErrorButton}
                  isCard={false}
                />
              ) : isLoadingGetFactor ||
                isLoadingAuthorizationInitialize ||
                isLoadingContratGenerada ? (
                // eslint-disable-next-line react/jsx-indent
                <LoaderWrapper className="d-flex align-items-center justify-content-center">
                  <SpinnerLoader />
                </LoaderWrapper>
              ) : (
                <div className="col d-flex justify-content-center">
                  {
                    // eslint-disable-next-line no-nested-ternary
                    authorizationMethod.passCoopeuch.isActive ? (
                      hasCpass ? (
                        <CardPassCoopeuch
                          buttonText="Autorizar con PassCoopeuch"
                          message="Autoriza la operación a través de tu aplicación PassCoopeuch."
                          stepFactor={authorizationMethod.passCoopeuch.stepFactor}
                          activeTimer={authorizationMethod.passCoopeuch.activeTimer}
                          timer={timeValue}
                          withCountdownAnimation={
                            authorizationMethod.passCoopeuch.withCountdownAnimation
                          }
                          isButtonDisabled={buttonLock}
                          onClickButton={handleClickPassCoopeuch}
                        />
                      ) : (
                        <NotPassCoopeuch />
                      )
                    ) : (
                      <TarjetaDeCoordenadas
                        buttonText={authorizationMethod.tarjetaCoordenadas.buttonText}
                        message={authorizationMethod.tarjetaCoordenadas.message}
                        title={authorizationMethod.tarjetaCoordenadas.title}
                        stepFactor={authorizationMethod.tarjetaCoordenadas.stepFactor}
                        activeTimer={authorizationMethod.tarjetaCoordenadas.activeTimer}
                        timer={timeValue}
                        showInputInError={authorizationMethod.tarjetaCoordenadas.showInputInError}
                        withCountdownAnimation={
                          authorizationMethod.tarjetaCoordenadas.withCountdownAnimation
                        }
                        desafio1={authorizationMethod.tarjetaCoordenadas.desafios[1]}
                        desafio2={authorizationMethod.tarjetaCoordenadas.desafios[2]}
                        desafio3={authorizationMethod.tarjetaCoordenadas.desafios[3]}
                        valorDesafio1={authorizationMethod.tarjetaCoordenadas.valoresDesafios[1]}
                        valorDesafio2={authorizationMethod.tarjetaCoordenadas.valoresDesafios[2]}
                        valorDesafio3={authorizationMethod.tarjetaCoordenadas.valoresDesafios[3]}
                        onChangeInput={handleInputChange}
                        isButtonDisabled={buttonLock}
                        onClickButton={handleClickTcooButton}
                        hasError={authorizationMethod.tarjetaCoordenadas.hasError}
                      />
                    )
                  }
                </div>
              )}
            </CardBox.Body>
            {hasTcoo && (
              <CardFooter>
                <button type="button" className="px-0" onClick={() => switchActiveFactor()}>
                  {isCpass
                    ? 'Quiero autorizar con Tarjeta de Coordenadas'
                    : 'Quiero autorizar con PassCoopeuch'}
                </button>
              </CardFooter>
            )}
          </CardBox>

          <div className="bmt-4">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <Button
                  asTextLink
                  color="#E81E2B"
                  iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                  onClick={handleClickReturn}
                  className="button-return p-0 "
                  fullWidth={ischangeWidth}
                >
                  <b>Cancelar</b>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

CuentaTemplate.displayName = 'Contratacion_Template';
export default CuentaTemplate;
