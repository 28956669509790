import styled from 'styled-components';

const Wrapper = styled.div`
  ._3sTov {
    padding-top: 31px;
    padding-bottom: 31px;
    background-color: #fff;
    @media (max-width: 600px) {
      padding: 16px;
    }
  }
  .containerBonos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 31px;
    &__image {
      margin: 0 auto;
    }

    &__content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      &_title {
        font-size: 18px;
        font-family: 'Ubuntu-Bold';
        @media (max-width: 500px) {
          font-size: 16px;
        }
      }

      &_message {
        width: 100%;
        max-width: 426px;
        margin: 0 auto;
        line-height: 20px;
        padding-top: 12px;
        font-size: 14px;
      }
    }

    &__btnReset {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      margin-top: 45px;
      @media (max-width: 600px) {
        max-width: 100%;
      }
      span {
        font-size: 14px;
        font-family: 'Ubuntu-Medium';
      }
    }
  }

  .btnReturn {
    margin-top: 24px;
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
  }
`;

export default Wrapper;
