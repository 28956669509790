import styled from 'styled-components';

export const ContainerTipoCredito = styled.div`
  .border {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 0px 10px;
    height: 48px;
  }
`;

