import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { loadersActions } from '../../../application/actions/ui_loaders';
import { certProdVigentesActions } from '../../../application/actions/certificadosProdVigentes';

// api
import api from '../../api';

const apiCertificadoProdVigentes = api(process.env.REACT_APP_CERTIFICADO_PROD_VIGENTES);

export function* fetchPDFCertProdVigentes(action) {
  try {
    yield put(loadersActions.fetchLoadingCertificadoProdVigentes({ isLoading: true }));

    const { data } = yield call(
      apiCertificadoProdVigentes.get,
      '/obtener',
      action.payload
    );

    if (data?.code === 0) {
      // La propiedad 'code' está presente en 'data' y es igual a 0, es un éxito
      yield put(
        certProdVigentesActions.fetchPDFCertProdVigentesSuccess({
          ...action.payload,
          data: data || '',
        })
      );
      yield put(loadersActions.fetchLoadingCertificadoProdVigentes({ isLoading: false }));
    } else if (data?.code != 0) {
      // La propiedad 'code' está presente en 'data' pero no es igual a 0, es un error
      yield put(
        certProdVigentesActions.fetchPDFCertProdVigentesError({
          ...action.payload,
          data: data || '',
        })
      );
      yield put(loadersActions.fetchLoadingCertificadoProdVigentes({ isLoading: false }));
    } else {
      yield put(loadersActions.fetchLoadingCertificadoProdVigentes({ isLoading: false }));
    }
  } catch (error) {
    // Manejar errores de la llamada a la API
    yield put(certProdVigentesActions.fetchPDFCertProdVigentesError({ ...action.payload, error }));
  }
}

export function* watchfetchPDFCertProdVigentes() {
  yield takeLatest(certProdVigentesActions.fetchPDFCertProdVigentes.type, fetchPDFCertProdVigentes);
}

/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */
export default function* rootSaga() {
  yield spawn(watchfetchPDFCertProdVigentes);
}
