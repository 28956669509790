import React, { Fragment, useMemo } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Input from '@coopeuch-components/web/atoms/Input';
import RadioGroup, { Radio } from '@coopeuch-components/web/atoms/RadioGroup';

import Skeleton from './Skeleton';
import StepsActions from '../../../organism/Stepper/StepsActions';
import Stepper from '../../../organism/Stepper';
import DashboardCard from '../../../organism/DashboardCard';
import PageTitle from '../../../atoms/PageTitle';
import ErrorCard from '../../../organism/ErrorCard';

import { capitalize, formatCurrency, hiddeNumber } from '../../../../utils/functions';
import { dateFormat } from '../../../../utils/dates';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const errMessages = {
  min: 'El monto es inferior al mínimo',
  max: 'Excede total facturado.',
};

const PagarTarjetaMontoAPagarTemplate = (props) => {
  const {
    loading,
    error,
    numeroTarjeta,
    datosTarjeta,
    facturacion,
    tipoMonto,
    monto,
    montoErr,
    handleNextStep,
    handleTypeOfMonto,
    handleManualMonto,
  } = props;

  const steps = useMemo(() => ['Monto a pagar', 'Pago', 'Comprobante'], []);

  const { facturacionNacional } = facturacion || {};

  const disabled = useMemo(
    () => montoErr?.length >= 1 || monto === 0 || tipoMonto?.length <= 0,
    [montoErr, monto, tipoMonto]
  );

  return (
    <Wrapper className="w-100">
      <PageTitle
        id="pago-tarjeta-credito-title-page"
        title="Tarjeta de Crédito"
        icon={<LoadIcon src={`${iconPath}mastercard.svg`} color="#383638" />}
      />

      <div className="w-100 margin-after-title content">
        {error ? (
          <div className="err-component">
            <ErrorCard />
          </div>
        ) : (
          <Fragment>
            <Stepper className="start-xs" position={1} steps={steps} />

            {loading ? (
              <Skeleton />
            ) : (
              <div className="row modulos-container">
                <div className="col-xs-12">
                  <DashboardCard classes="modulo">
                    <DashboardCard.Head
                      title={`${capitalize(datosTarjeta?.descripcionProducto || '')} ${hiddeNumber(
                        numeroTarjeta || ''
                      )}`}
                      borderBottom="complete"
                    />
                    <DashboardCard.Body classes="modulo-content">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <p className="black pb-12">Fecha de vencimiento</p>
                          <p className="black">
                            {capitalize(dateFormat(facturacionNacional?.fechaVencimiento))}
                          </p>
                        </div>

                        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                          <p className="black pb-12 modulo-second-text">Última facturación</p>
                          <p className="black">
                            {capitalize(dateFormat(facturacionNacional?.fechaUltimaFacturacion))}
                          </p>
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>

                <div className="col-xs-12">
                  <DashboardCard classes="modulo">
                    <DashboardCard.Head title="Selecciona monto" borderBottom="complete" />
                    <DashboardCard.Body classes="modulo-content">
                      <div className="row monto-radio-container">
                        <div className="col-xs-12">
                          <RadioGroup
                            checkedValue={tipoMonto}
                            activedColor="#007DB7"
                            onChange={handleTypeOfMonto}
                          >
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="black modulo">
                                  <span>Estimado socio, recuerda que el pago realizado </span>
                                  <span className="text-bold">
                                    se verá reflejado el día hábil siguiente.
                                  </span>
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <Radio value="facturado" className="monto-radio">
                                  <p className="text-size-s black">Monto total facturado</p>
                                  <p className="text-size-s text-bold black">
                                    {formatCurrency(facturacionNacional?.montoTotal)}
                                  </p>
                                </Radio>
                              </div>

                              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <Radio value="minimo" className="monto-radio black">
                                  <p className="text-size-s black">Monto mínimo</p>
                                  <p className="text-size-s text-bold black">
                                    {formatCurrency(facturacionNacional?.montoMinimo)}
                                  </p>
                                </Radio>
                              </div>

                              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                                <Radio value="otro" className="monto-radio">
                                  <p className="text-size-s black">Otro monto</p>
                                  <div className="monto-radio-input input-otro-monto-wrapper">
                                    <Input
                                      textSize={16}
                                      size={48}
                                      borderColor={montoErr ? '#cc284e' : '#646569'}
                                      onChange={handleManualMonto}
                                      value={tipoMonto === 'otro' ? formatCurrency(monto) : ''}
                                      placeholder="$ Ingresa monto"
                                      type="text"
                                      inputClass="pl-16"
                                      disabled={tipoMonto !== 'otro'}
                                    />
                                    {montoErr && (
                                      <div className="error-warning-password row">
                                        <LoadIcon
                                          src={`${iconPath}circle_close.svg`}
                                          size={14}
                                          className="err-color"
                                        />
                                        <span className="text-size-s err-color pl-2">
                                          {errMessages[montoErr]}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </Radio>
                              </div>
                            </div>
                          </RadioGroup>
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>

                  <div className="col-xs-12">
                    <StepsActions
                      hideBackAction
                      nextAction={handleNextStep}
                      disableNextAction={disabled}
                      nextText="Pagar"
                      id="pago-tarjeta-credito"
                    />
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
};

export default PagarTarjetaMontoAPagarTemplate;
