import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Home from '../Home';
import Simulacion from '../Simulacion';
import Resultado from '../Resultado';
import DetallesSimulacion from '../DetallesSimulacion';
import Confirmacion from '../Confirmacion';
import Comprobante from '../Comprobante';
import DepositosContratado from '../DepositosContratado';
import LiquidacionConfirmar from '../LiquidacionConfirmar';
import LiquidacionComprobante from '../LiquidacionComprobante';

const StepperRouter = (props) => {
  const { match } = props || {};

  return (
    <Switch>
      <Route exact path={`${match?.url}/`} render={() => <Home {...props} />} />
      <Route exact path={`${match?.url}/simulacion`} render={() => <Simulacion {...props} />} />
      <Route exact path={`${match?.url}/resultado`} render={() => <Resultado {...props} />} />
      <Route
        exact
        path={`${match?.url}/detalles-simulacion`}
        render={() => <DetallesSimulacion {...props} />}
      />
      <Route exact path={`${match?.url}/confirmacion`} render={() => <Confirmacion {...props} />} />
      <Route exact path={`${match?.url}/comprobante`} render={() => <Comprobante {...props} />} />

      <Route
        exact
        path={`${match?.url}/deposito-contratado`}
        render={() => <DepositosContratado {...props} />}
      />
      <Route
        exact
        path={`${match?.url}/deposito-contratado/confirmar`}
        render={() => <LiquidacionConfirmar {...props} />}
      />
      <Route
        exact
        path={`${match?.url}/deposito-contratado/comprobante`}
        render={() => <LiquidacionComprobante {...props} />}
      />
      <Redirect path={`${match?.path}/`} to={`${match?.path}/`} />
    </Switch>
  );
};

StepperRouter.propTypes = {
  ...Home.propTypes,
  ...Simulacion.propTypes,
  ...Resultado.propTypes,
  ...DetallesSimulacion.propTypes,
  ...Confirmacion.propTypes,
  ...Comprobante.propTypes,
};

StepperRouter.defaultProps = {
  ...Home.defaultProps,
  ...Simulacion.defaultProps,
  ...Resultado.defaultProps,
  ...DetallesSimulacion.defaultProps,
  ...Confirmacion.defaultProps,
  ...Comprobante.defaultProps,
};

export default StepperRouter;
