import styled from 'styled-components';

const Wrapper = styled.div`
  padding: ${({ padding }) => padding};

  @media screen and (max-width: 766px) {
    padding: 8px 16px;
  }
`;

export const Content = styled.div`
  font-family: 'Ubuntu-Regular';
`;

export const Title = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: #383638;
`;

export const BtnWrapper = styled.div`
  height: 48px;

  button {
    height: 100%;
  }

  @media screen and (max-width: 769px) {
  }
`;

export default Wrapper;
