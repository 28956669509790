import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { monederoActions } from '../../../application/actions/monedero';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiMonedero = api(process.env.REACT_APP_MONEDERO);

export function* fetchContratacionMonedero(action) {
  try {
    const { clave, correo } = action.payload;
    yield put(loadersActions.fetchLoadingContratacionMonedero({ isLoading: true }));
    const { data, error } = yield call(apiMonedero.post, 'v1/contratar', { clave, correo });
    if (error) {
      yield put(
        monederoActions.fetchContratacionMonederoError({ error: error.code, errorLog: error })
      );
    }
    if (data) {
      yield put(monederoActions.fetchContratacionMonederoSuccess({ data }));
    }
    yield put(loadersActions.fetchLoadingContratacionMonedero({ isLoading: false }));
  } catch (err) {
    console.error(err.message, 'fetchContratacionMonedero');
    yield put(monederoActions.fetchContratacionMonederoError({ error: '', errorLog: err }));
    yield put(loadersActions.fetchLoadingContratacionMonedero({ isLoading: false }));
  }
}

export function* fetchComprobanteMonedero(action) {
  try {
    const { data, error } = yield call(apiMonedero.get, 'documento/comprobante/v1/obtener');
    if (error) {
      // TODO: controlar error y dar feedback en UI. Podría no haber contrato
      console.error(error, 'fetchComprobanteMonedero');
    }
    if (data) {
      yield put(monederoActions.fetchComprobanteContratacionSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchComprobanteMonedero');
  }
}

export function* fetchContratoCanales(action) {
  try {
    const { data, error } = yield call(apiMonedero.get, 'documento/contrato/canales/v1/obtener');
    if (error) {
      console.error(error, 'fetchContratoCanales');
    }
    if (data) {
      yield put(monederoActions.fetchContratoCanalesSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchContratoCanales');
  }
}

export function* fetchContratoMonedero(action) {
  try {
    const { data, error } = yield call(apiMonedero.get, 'documento/contrato/monedero/v1/obtener');
    if (error) {
      console.error(error, 'fetchContratoMonedero');
    }
    if (data) {
      yield put(monederoActions.fetchContratoMonederoSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchContratoMonedero');
  }
}

export function* watchFetchContratacionMonedero() {
  yield takeLatest(monederoActions.fetchContratacionMonedero.type, fetchContratacionMonedero);
}

export function* watchFetchComprobanteMonedero() {
  yield takeLatest(monederoActions.fetchComprobanteContratacion.type, fetchComprobanteMonedero);
}

export function* watchFetchContratoCanales() {
  yield takeLatest(monederoActions.fetchContratoCanales.type, fetchContratoCanales);
}

export function* watchFetchContratoMonedero() {
  yield takeLatest(monederoActions.fetchContratoMonedero.type, fetchContratoMonedero);
}

/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */
export default function* rootSaga() {
  yield spawn(watchFetchContratacionMonedero);
  yield spawn(watchFetchComprobanteMonedero);
  yield spawn(watchFetchContratoCanales);
  yield spawn(watchFetchContratoMonedero);
}
