import React, { useEffect, useState } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import PageTitle from '../../components/atoms/PageTitle';
import HomeTemplate from '../../components/templates/CertificadosLiquidacion/Home';
import Wrapper from './styles';
import { registerGA } from '../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CertificadosLiquidacion = () => {
  const [showIframe, setShowIframe] = useState(false);

  const baseURL = process.env.REACT_APP_IFRAME_CERT_LIQUIDACION;
  const tokenLegacy = localStorage.getItem('tokenLegado');
  const urlCertificado = `${baseURL}?token=${tokenLegacy}`;

  // Debido a las limitaciones de implementación, se han debido mantener los estilos CSS in-line
  // en lugar de usar clases o componentes JSX
  const iframeCertificado = `
    <div style="position: relative; overflow: hidden; padding-bottom: 65%;">
      <iframe style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;" frameBorder="0" src=${urlCertificado}></iframe>
    </div>
  `;
  const mostrarIframeCertificado =() => {
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/certificados/certificados-liquidacion',
      'eve-cat' : 'Click',
      'eve-lab' : 'Solicitar'
      });
    setShowIframe(true);
  }

  let content = <HomeTemplate mostrarIframeCertificado = {mostrarIframeCertificado} />;

  // localStorage.getItem() podría retornar "null", por lo que se controla este error acá
  if (showIframe && !tokenLegacy) {
    content = <HomeTemplate isError />;
  }

  if (showIframe && tokenLegacy) {
    // eslint-disable-next-line react/no-danger
    content = (
      <Wrapper className="bpx-3 bpx-md-5">
        <div dangerouslySetInnerHTML={{ __html: iframeCertificado }} />
      </Wrapper>
    );
  }
  
  useEffect(() => {
    registerGA({
      'event': 'vpv-privado',
      'page' : '/tef/certificados/certificados-liquidacion'
    });  
  }, []);
  
  return (
    <>
      <PageTitle
        title="Certificados Liquidacion"
        icon={<LoadIcon src={`${iconPath}deal.svg`} color="black" />}
      />
      <div className="w-100  margin-after-title text-left">
        <div className="bmt-3 bmt-md-4 bmb-3 bmb-md-4">{content}</div>
      </div>
    </>
  );
};

export default CertificadosLiquidacion;
