/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CardFooter } from '@design-system-coopeuch/web';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Tooltip from '@coopeuch-components/web/atoms/Tooltip';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Input from '@coopeuch-components/web/atoms/Input';
import Alert from '@coopeuch-components/web/atoms/Alert';
import DashboardCard from "../../DashboardCard";
import { Paragraph } from './styles';

import Select from '../../../atoms/Select';
import { MessageError } from '../../../atoms/PasswordInput/styles';

const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS || 'default/path/to/icons/';

function DatosBancarios({
  onChangeHandle,
  memoBancoOptions,
  banco,
  memoCuentaOptions,
  tipoCuenta,
  errorNumeroCuenta,
  errorCuenta,
  numeroCuenta,
}) {

  return (
    <DashboardCard>
      <DashboardCard.Head
        title={<span className='txtTitle-CP'>¿Dónde quieres recibir tus acreencias y remanente?</span>}
        borderBottom="complete"
        classes="btext-xs-left bmt-xs-3 bmt-sm-4"
      />
      <CardBox.Body>
        <div className="row pb-28 pl-8">
          <Typography tag="p" fontSize={14} className="textDatosBancarios">
            <span className="pb-12 black">
              Confirmando estos datos, autorizas a Coopeuch para abonar tus acreencias
            </span>
            <Tooltip
              rounded={4}
              bgColor="white"
              tooltipclassName="tooltip-acreencias"
              elevation={2}
              content={
                <Typography tag="p" fontSize={12} lineHeight={false}>
                  Las acreencias son cualquier saldo a favor
                  {' '}
                  <br />
                  que los socios mantengan en la
                  <br />
                  cooperativa a su nombre.
                </Typography>
              }
            >
              <LoadIcon
                src={`${ICON_PATH}ask-icon.svg`}
                size={15}
                color="#00A1DF"
              />
            </Tooltip>
            <span className="pb-12 black">
              {' '}
              y/o remanente a la cuenta bancaria indicada.
              {' '}
            </span>
            <p className="mt-24 black">
              Los campos marcados con (*) son obligatorios.
            </p>
          </Typography>
        </div>
        <div className="row">
          <div className="col-md-4 mb-32 col-sm-12 w-100">
            <div className="ml-4 mr-4">
              <Typography className="mb-1" tag="p" fontSize={14} fontWeight={700}>
                Institución financiera (*)
              </Typography>
              <Select
                borderColor="#333"
                placeholder="Elige institución"
                labelClass="mb-10 mt-8 mb-8"
                onChange={onChangeHandle}
                options={memoBancoOptions}
                value={banco}
                id="banco-abono"
                size={48}
                textSize={14}
                dataSet="banco"
                rounded={4}
              />
            </div>
          </div>
          <div className="col-md-4 mb-32 col-sm-12 w-100">
            <div className="ml-4 mr-4">
              <Typography className="mb-1" tag="p" fontSize={14} fontWeight={700}>
                Tipo de cuenta (*)
              </Typography>
              <Select
                borderColor="#333"
                placeholder="Elige tipo"
                labelClass="mb-10 mt-8 mb-8"
                onChange={onChangeHandle}
                options={memoCuentaOptions}
                dataSet="tipoCuenta"
                id="tipo-abono"
                value={tipoCuenta}
                size={48}
                textSize={14}
                rounded={4}
              />
            </div>
          </div>
          <div className="col-md-4 mb-32 col-sm-12 w-100">
            <div className="ml-4 mr-4">
              <Typography className="mb-1" tag="p" fontSize={14} fontWeight={700}>
                Número de cuenta (*)
              </Typography>
              <Input
                borderColor={errorNumeroCuenta || errorCuenta ? '#e81e2b' : '#646569'}
                size={48}
                textSize={14}
                onChange={onChangeHandle}
                id="numeroCuenta-abono"
                dataSet="numeroCuenta"
                value={numeroCuenta}
                type="text"
                maxLength={15}
                rounded={4}
                placeholder="Ingresa n°"
              />
              {errorNumeroCuenta && (
                <MessageError className="password-error text-size-s dark-red mt-8">
                  <div>
                    <LoadIcon
                      src={`${ICON_PATH}circle_close.svg`}
                      size={14}
                      color="#CC284E"
                      className="mr-8 dark-red"
                    />
                    <Typography tag="p" fontSize={12} lineHeight={false}>
                      Requiere un min. de 5 caracteres
                    </Typography>
                  </div>
                </MessageError>
              )}
            </div>
          </div>
        </div>
        <Alert
          className="bp-3 bmb-3"
          bgType="info"
          show
        >
          <Paragraph>
            Antes de continuar, verifica que la cuenta ingresada esté asociada a tu RUT.
          </Paragraph>
        </Alert>
        {errorCuenta && (
          <Alert bgType="error" show className='alertCuentaNoValida'>
            La cuenta ingresada no es válida para recibir el abono de tus acreencias y/o remanente.
          </Alert>
        )}
      </CardBox.Body>
      <CardFooter>
        <Typography tag="p" fontSize={16} fontWeight="bold" className="mb-12">
          Sobre el Remanente
        </Typography>
        <Typography tag="p" className="textLegal" fontSize={14}>
          Es el resultado positivo de la gestión de la Cooperativa en el ejercicio del año anterior. En caso que la gestión de Coopeuch sea favorable, el Remanente se
          distribuye entre los socios de la forma señalada en los estatutos y la normativa vigente. Los productos que aumentan el Remanente son Cuotas de
          Participación, Ahorro y Productos Crediticios Coopeuch.
        </Typography>
      </CardFooter>
    </DashboardCard>
  );
}

DatosBancarios.propTypes = {
  onChangeHandle: PropTypes.func.isRequired,
  memoBancoOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  banco: PropTypes.object,
  memoCuentaOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })).isRequired,
  tipoCuenta: PropTypes.object,
  errorNumeroCuenta: PropTypes.bool,
  errorCuenta: PropTypes.bool,
  numeroCuenta: PropTypes.string,
};

DatosBancarios.defaultProps = {
  banco: null,
  tipoCuenta: null,
  errorNumeroCuenta: false,
  errorCuenta: false,
  numeroCuenta: '',
};

export default DatosBancarios;
