export const dashboardTexts = {
  TITLE_PRODUCTOS: 'Productos',
};

export const cuotaParticipacionTexts = {
  TITLE_CUOTAS: 'Cuotas de Participación',
  TOTAL_CUOTAS_PAGADAS: 'Total de cuotas pagadas',
  APORTE_FECHA: 'Aporte hasta la fecha:',
  CUOTA_PACTADA_MES: 'Cuotas pactadas mes:',
  APORTE_MENSUAL: 'Aporte mensual:',
  ERROR_DESC: 'Actualmente tu Cuota de Participación no está disponible',
};

export const cuentasTexts = {
  NUMERICO: 'Nº',
  SALDO_DISPONIBLE: 'Saldo disponible:',
  SALDO_CONTABLE: 'Saldo contable:',
  RETENCIONES: 'Retenciones:',
  ERROR_DESC: 'Actualmente tu Cuenta Vista no está disponible',
};

export const cuentasAhorroTexts = {
  NUMERICO: 'Nº',
  AHORRADO_FECHA: 'Ahorrado hasta la fecha: ',
  FECHA_APERTURA: 'Fecha apertura: ',
  PREFIJO: 'Cuenta de Ahorro',
  ERROR_DESC: 'Actualmente tu Cuenta de Ahorro no está disponible',
};

export const tarjetasCreditoTexts = {
  NUMERICO: 'Nº',
  PREFIJO_NUMERO_TARJETA: '**** **** ****',
  DISPONIBLE_NACIONAL: 'Disponible nacional:',
  DISPONIBLE_INTERNACIONAL: 'Disponible Internacional:',
  PREFIJO_USD: 'USD $',
  ERROR_DESC: 'Actualmente tu Tarjeta de Crédito no está disponible',
};

export const accesosRapidosTexts = {
  TITLE: 'Accesos Rápidos',
  TRANSFERIR_BUTTON: 'Transferir Ahora',
  DAP_BUTTON: 'Depósito a Plazo',
  BLOQUEAR_BUTTON: 'Bloquear tarjetas',
  ENCUESTA_TITLE: 'Simula tu Crédito',
  ENCUESTA_DESC: ' Analiza la opción que más te acomode. ',
  ENCUESTA_BUTTON: 'Simular ahora',
  SOLICITUD_CV_TITLE: '¿Aún no tienes Cuenta Vista?',
  SOLICITUD_CV_DESC: 'Con Coopeuch es muy fácil obtenerla.',
  ENCUESTA_LINK: 'Solicítala ahora',
  NORMA_TITLE: 'Infórmate sobre las rebajas',
  NORMA_DESC: ' en las comisiones de tu tarjeta de crédito. ',
  NORMA_BUTTON: 'Revisar ahora',
};

export const coopeuchPassTexts = {
  TITLE: '¿Ya tienes tu PASSCoopeuch?',
  DESC: 'Realiza transacciones de forma fácil, rápida y segura. Disponible en:',
};

export const puntosCoopeuchTexts = {
  TITLE: 'Tienes',
  TITLE_PTS: 'Puntos Coopeuch',
  PTSCLP: '1 punto = CLP $1',
  ACUMULAR_PTS: '¿Cómo acumular puntos?',
  CANJEAR: 'Canjear',
};

export const bannerKitCVTexts = {
  TITLE: 'Cuenta Vista Coopeuch',
  DESC: '¡Ya puedes activar \n tus tarjetas! ',
  TEXT_LINK: 'Comenzar',
};
