export const tarjetaTexts = {
  TITLE_BLOQUEO: 'Bloqueo de Tarjetas Definitivo',
  DESC_BLOQUEO: 'Selecciona la tarjeta que necesitas bloquear definitivamente:',
  PRE_NUMERO: 'Nº ',
  RETURN_BUTTON: 'Volver',
  CONTINUE_BUTTON: 'Continuar',
};

export const confirmacionTexts = {
  TITLE_MOTIVO: 'Motivo del Bloqueo',
  DESC_MOTIVO: '¿Por qué deseas bloquear tu tarjeta?',
  MOTIVO_1: 'Robo / Extravío',
  MOTIVO_2: 'Deterioro',
  TITLE_FIRMA: 'Firma electrónica',
  DESC_FIRMA: 'Autoriza el bloqueo ingresando tu clave de internet Coopeuch',
  RETURN_BUTTON: 'Volver',
  CONTINUE_BUTTON: 'Bloquear',
};

export const comprobanteTexts = {
  TOAST_MESSAGE: 'El Bloqueo ha sido realizado exitosamente.',
  TITLE_COMPROBANTE: 'Comprobante',
  SUBTITLE_COMPROBANTE_TARJETA_DEBITO: 'Haz bloqueado tu Tarjeta de Cuenta Vista Coopeuch:',
  SUBTITLE_COMPROBANTE_TARJETA_COORDENADAS: 'Haz bloqueado tu Tarjeta de Coordenadas:',
  PREFIX_NUM: 'Nº ',
  SUBTITLE_CORREO_TARJETA_DEBITO:
    'Te hemos enviado un email con la copia del comprobante de bloqueo de tu Tarjeta de Cuenta Vista Coopeuch, revísalo en tu mail: ',
  SUBTITLE_CORREO_TARJETA_COORDENADAS:
    'Te hemos enviado un email con la copia del comprobante de bloqueo de tu Tarjeta de Coordenadas, revísalo en tu mail: ',
  TITLE_DETALLE: 'Detalle del bloqueo',
  TARJETA: 'Tarjeta bloqueada:',
  NUMERO_TARJETA: 'Número de Tarjeta:',
  FECHA_BLOQUEO: 'Fecha de bloqueo:',
  RETURN_HOME: 'Ir a home',
  RETURN_CUENTA_VISTA: 'Volver a Cuenta Vista',
  MESSAGE_BLOQUEO_DEFINITIVO:
    'El bloqueo ha sido realizado exitosamente. Sin embargo, no hemos podido enviarte una notificación por correo. ',
  CHECK_DATA: ' Revisa tus datos.'
};