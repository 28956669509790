import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 24px;
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    margin-top: 24px;
  }
  .linkBack {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: 991px) {
      margin-top: 36px;
    }
    p {
      color: #ec1b2b;
      font-family: 'Ubuntu-Medium';
      font-size: 14px;
      margin-left: 8px;
      @media (max-width: 991px) {
        font-size: 16px;
      }
    }
    &:hover {
      text-decoration: none;
    }
    & path {
      fill: #e81e2b;
    }
  }
  button {
    width: 100%;
    justify-content: center;
    &._FFf8J::before {
      position: initial !important;
      margin-right: 0.7rem !important;
    }
    span {
      font-size: 14px;
      font-family: 'Ubuntu-Medium';
    }
    @media (min-width: 991px) {
      max-width: 220px;
    }
  }
`;

export default Wrapper;
