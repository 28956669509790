import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;
export const RightIcon = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 12px;
  display: flex;
  align-items: center;
`;

export const SmallWrapper = styled.small`
  margin-bottom: 4px;
  margin-top: 4px;
  float: right;
`;

const InputWrapper = styled.input`
  padding: 8px 12px;
  width: 100%;
  height: ${({ size }) => `${size}px`};
  font-size: ${({ textSize }) => `${textSize}px`};
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: ${({ rounded }) => (rounded ? `${rounded}px` : 'unset')};
  line-height: 1.1em;

  &::-webkit-credentials-auto-fill-button {
    margin: 0;
    width: 0;
    background-color: transparent;
  }

  &:disabled,
  &[disabled] {
    background-color: #edeff3;
    cursor: not-allowed;
    border: none;
    color: #383638;
  }

/* 
  ${({ locked }) => locked && '&:disabled,&[locked]{cursor: not-allowed;color: #9C9A9F;}'} */
`;

export default InputWrapper;
