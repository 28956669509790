import React from 'react';

import Stepper from '../../../../components/organism/Stepper';
import CardPassCoopeuch from '../../../organism/ActivacionTarjetas/Autorizacion/CardPassCoopeuch';
import CardSMS from '../../../../components/organism/ActivacionTarjetas/Autorizacion/CardSMS';
import FormSMS from '../../../organism/ActivacionTarjetas/Autorizacion/FormSMS';
import CardBlock from '../../../organism/ActivacionTarjetas/Error/CardBlock';
import CardBlockButton from '../../../organism/ActivacionTarjetas/Error/CardBlockButton';
// Styles
import Wrapper from './styles';

const AutorizacionTemplate = ({
  steps,
  counterActive,
  setCounterActive,
  handleClickSendSMS,
  errorTimeOut,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  retryError,
  handleSetSmsCode,
  authorizeBtn,
  handleClickValidateSMS,
  smsCode,
  validateSMS,
  smsCodeOne,
  smsCodeTwo,
  smsCodeThree,
  smsCodeFour,
  smsCodeFive,
  smsCodeSix,
  desafio1,
  desafio2,
  desafio3,
  persona,
  timerValue,
  backToActivacion,
  switchPassCoopeuchOrSms,
  handleClickAuthorizePassCoopeuch,
  retryTimeOutPass,
  validatePassCoopeuch,
  IsValidatePassCoopeuch,
  IsLoading,
  isVisibleModalError,
  handleClickCloseModalError,
  isAvailableFactor,
  handleSwitchMethods,
  handleClickSendTC,
  stepSolicitartarjetaCoordenadas,
  solicitarTarjetaCoordenadas,
  availablePassCoopeuch,
  handleSetTC,
  setHablitarBtn,
  hablitarBtn,
  handleClickRetry,
  handleClickValidateTC,
  validarCoordenadas,
  validateTC,
  errorIngresoCoordenadas,
  validateTD,
  disabledInput,
  isAvailableFactorTC,
  activateKit,
  handleClickDownloadApp
}) => {
  return (
    <Wrapper>
      <Stepper className="start-xs" position={2} steps={steps} />
      {smsCode.error?.code === 'KIT-04' && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="EL código SMS ha sido bloqueado. Por favor, comunícate con nuestro Contact Center al 600 200 1200."
          link="/ecd/inicio"
          msgErrorGA="Clave dinámica SMS Bloqueada"
        />
      )}
      {validateSMS.data?.bloqueado === true && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="EL código SMS ha sido bloqueado. Por favor, comunícate con nuestro Contact Center al 600 200 1200."
          link="/ecd/inicio"
          msgErrorGA="Clave dinámica SMS Bloqueada"
        />
      )}

      {smsCode.error?.statusCode === 500 && (
        <CardBlock
          section="Autoriza con Tarjeta de Coordenadas"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
        />
      )}

      {validateSMS.error?.statusCode === 500 && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
        />
      )}
      {validateTC?.error?.statusCode === 408 && (
        <CardBlock
          section="Activacion tarjeta débito"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
        />
      )}
      {validateTC?.error?.statusCode === 500 && (
        <CardBlock
          section="Activacion tarjeta débito"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
        />
      )}
      {solicitarTarjetaCoordenadas?.error?.statusCode === 408 && (
        <CardBlockButton
        section="Autoriza con Tarjeta de Coordenadas"
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        link="/ecd/inicio"
        msgErrorGA="Servicio no disponible"
      />
      )}
      {availablePassCoopeuch?.error?.statusCode === 500 && (
        <CardBlockButton
        section="Activacion tarjeta débito"
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        link="/ecd/inicio"
        msgErrorGA="Servicio no disponible"
      />
      )}
      {availablePassCoopeuch?.error?.statusCode === 408 && (
        <CardBlockButton
        section="Activacion tarjeta débito"
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        link="/ecd/inicio"
        msgErrorGA="Servicio no disponible"
      />
      )}
      {validarCoordenadas?.error?.code === 'TD-40' && (
        <CardBlockButton
        section="Autoriza con Tarjeta de Coordenadas"
        title="¡Lo sentimos!"
        message="tú tarjeta de coordenadas está bloqueada, Solicítala nuevamente en App Coopeuch."
        link="/ecd/inicio"
        msgErrorGA="Tarjeta de Coordenadas Bloqueada"
      />
      )}
        {validarCoordenadas?.error?.code === '500 INTERNAL_SERVER_ERROR' && (
          <CardBlockButton
          section="Autoriza con Tarjeta de Coordenadas"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
        />
        )}
        {activateKit?.error?.code === 'TD-30' && (
          <CardBlockButton
          section="Autoriza con Tarjeta de Coordenadas"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
        />
        )}
      {solicitarTarjetaCoordenadas?.error?.code === 'TD-40' && (
        <CardBlockButton
        section="Autoriza con Tarjeta de Coordenadas"
        title="¡Lo sentimos!"
        message="tú tarjeta de coordenadas está bloqueada, Solicítala nuevamente en App Coopeuch."
        link="/ecd/inicio"
        msgErrorGA="Tarjeta de Coordenadas Bloqueada"
      />
      )}
      {activateKit?.error?.code === '500 INTERNAL_SERVER_ERROR' && (
        <CardBlockButton
        section="Autoriza con Tarjeta de Coordenadas"
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        link="/ecd/inicio"
        msgErrorGA="Servicio no disponible"
      />
      )}
      

      {switchPassCoopeuchOrSms &&
      solicitarTarjetaCoordenadas?.error?.code !== 'TD-40' &&
      availablePassCoopeuch?.error?.statusCode !== 408 &&
      availablePassCoopeuch?.error?.statusCode !== 500 &&
      validateTC?.error?.statusCode !== 500 && 
      validateTC?.error?.statusCode !== 408 ?
      
      (
        <CardPassCoopeuch
          {...{
            timerValue,
            errorTimeOut,
            validateSMS,
            handleClickAuthorizePassCoopeuch,
            counterActive,
            setCounterActive,
            setErrorTimeOut,
            setFactorErrorTimeOut,
            authorizeBtn,
            retryTimeOutPass,
            validatePassCoopeuch,
            IsValidatePassCoopeuch,
            backToActivacion,
            IsLoading,
            isVisibleModalError,
            handleClickCloseModalError,
            isAvailableFactor,
            handleSwitchMethods,
            availablePassCoopeuch,
            isAvailableFactorTC,
            handleClickDownloadApp
          }}
        />
      ) 
      
      : (
        !counterActive &&
        // validarCoordenadas.error?.statusCode !== 500 && 
        solicitarTarjetaCoordenadas.error?.statusCode !== 500 &&
        solicitarTarjetaCoordenadas.error?.statusCode !== 408 && 
        solicitarTarjetaCoordenadas?.error?.code !== 'TD-40' &&
        validarCoordenadas?.error?.code !== "500 INTERNAL_SERVER_ERROR" &&
        activateKit?.error?.code !== '500 INTERNAL_SERVER_ERROR' &&
        validarCoordenadas?.error?.code !== 'TD-30' &&
        (
          <CardSMS
            {...{
              handleClickSendSMS,
              handleClickSendTC,
              setErrorTimeOut,
              setFactorErrorTimeOut,
              errorTimeOut,
              persona,
              backToActivacion,
              handleSwitchMethods,
              stepSolicitartarjetaCoordenadas,
              solicitarTarjetaCoordenadas,
              counterActive,
              setCounterActive,
              desafio1,
              desafio2,
              desafio3,
              availablePassCoopeuch,
              handleSetTC,
              setHablitarBtn,
              hablitarBtn,
              handleClickRetry,
              handleClickValidateTC,
              validarCoordenadas,
              errorIngresoCoordenadas,
              disabledInput
            }}
          />
        )
      )}
      {smsCode.data === true && validateSMS.data?.bloqueado !== true && validateSMS.error === null && (
        <FormSMS
          {...{
            counterActive,
            setErrorTimeOut,
            setFactorErrorTimeOut,
            errorTimeOut,
            retryError,
            handleSetSmsCode,
            authorizeBtn,
            handleClickValidateSMS,
            validateSMS,
            smsCodeOne,
            smsCodeTwo,
            smsCodeThree,
            smsCodeFour,
            smsCodeFive,
            smsCodeSix,
            persona,
            timerValue,
            backToActivacion,
          }}
        />
      )}
    </Wrapper>
  );
};

export default AutorizacionTemplate;
