import React from 'react';
import PropTypes from 'prop-types';
// Components
import Input from '../Input';
// styles
import Wrapper, { IconWrapper, LabelWrapper } from './styles';

const InputLabel = ({
  inputTextSize,
  bottomMessage,
  errorMessage,
  borderColor,
  placeholder,
  inputClass,
  errorClass,
  labelClass,
  maxLength,
  iconRight,
  disabled,
  locked,
  onChange,
  onPaste,
  iconFunc,
  dataSet,
  rounded,
  error,
  value,
  title,
  float,
  type,
  name,
  size,
  step,
  min,
  max,
  id,
}) => (
  <>
    <LabelWrapper className={labelClass} main>
      {title}
    </LabelWrapper>
    <Wrapper float={float}>
      <Input
        rounded={rounded}
        disabled={disabled}
        locked={locked}
        textSize={inputTextSize}
        borderColor={borderColor}
        placeholder={placeholder}
        className={`${inputClass} padding-right-icon`}
        onChange={onChange}
        onPaste={onPaste}
        maxLength={maxLength}
        dataSet={dataSet}
        name={name}
        value={value}
        error={error}
        type={type}
        min={min}
        max={max}
        step={step}
        id={id}
        size={size}
        iconRight={
          iconRight ? (
            <IconWrapper id={`${id}-right-icon`} onClick={iconFunc}>
              {iconRight}
            </IconWrapper>
          ) : null
        }
      />
    </Wrapper>
    {errorMessage && <LabelWrapper className={errorClass}>{errorMessage}</LabelWrapper>}
    {bottomMessage && !errorMessage && bottomMessage}
  </>
);

InputLabel.propTypes = {
  ...Input.propTypes,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  iconRight: PropTypes.node,
  iconFunc: PropTypes.func,
  float: PropTypes.string,
  size: PropTypes.number,
  id: PropTypes.string,
};

InputLabel.defaultProps = {
  ...Input.defaultProps,
  labelClass: '',
  iconRight: null,
  iconFunc: null,
  float: 'left',
  size: 24,
  id: '',
};

export default InputLabel;
