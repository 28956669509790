import React, { useCallback, useMemo } from 'react';

import { useHistory } from 'react-router-dom';

import Wrapper, { Header } from './styles';
import Stepper from '../../../organism/Stepper';

import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { registerGA } from '../../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const WrapperTemplates = (props) => {
  const { children, position, onStepsHandle, returnMetodo } = props;

  const history = useHistory();

  const steps = useMemo(() => {
    return ['Validación de identidad', 'Método', 'Validación', 'Creación de Clave'];
  }, []);

  const goLogin = useCallback(() => {
    const pathReturn = (!localStorage.getItem('pathLogin') || localStorage.getItem('pathLogin') == 'undefined' || localStorage.getItem('pathLogin') == 'login') ? '/' : localStorage.getItem('pathLogin');
    window.location.replace(`${pathReturn}`);
    registerGA({
      event: 'trigger_event',
      'event-config': {
        'eve-acc': '/crea-rec-clave/paso' + position,
        'eve-cat': 'Click',
        'eve-lab': 'salir',
      },
    });
  }, [history, position]);

  const goReturn = useCallback(() => {
    returnMetodo();
    onStepsHandle(2);
  }, [history]);

  return (
    <Wrapper className="w-100">
      <Header className="w-100 pl-36 pr-36 pt-20 pb-20">
        <img srcSet={`${staticPath}logo_white.png`} alt="Coopeuch" />
      </Header>
      <div className="row center-xs bmt-3 bmt-md-4">
        <div className="col-xs-10 col-sm-10 col-md-9 col-lg-9">
          <div className="row center-xs">
            <div className="content-wrapper-gestion-clave w-100">
              <Stepper className="mb-3 start-xs" position={position} steps={steps} progressOutContext={true}/>
              <div className="bmt-3 bmt-md-4">{children}</div>
              <div className="row">
                <div className="col-xs-12">
                  {position !== 3 ? (
                    <Button
                      color="#E81E2B"
                      className="none-underline middle-items w-100"
                      asTextLink
                      onClick={goLogin}
                    >
                      <div className="row contenedorBotonCentradoSalir">
                        <LoadIcon
                          src={`${iconPath}arrow_left.svg`}
                          size={22}
                          className="mr-8 back--icon-rojo"
                        />
                        <span>Salir</span>
                      </div>
                    </Button>
                  ) : (
                    <Button
                      color="#E81E2B"
                      className="none-underline middle-items w-100"
                      asTextLink
                      onClick={goReturn}
                    >
                      <div className="row middle-xs contenedorBotonCentradoSalir">
                        <LoadIcon
                          src={`${iconPath}arrow_left.svg`}
                          size={22}
                          className="mr-8 back--icon-rojo"
                        />
                        <span>Salir</span>
                      </div>
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default WrapperTemplates;
