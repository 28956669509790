// eslint-disable no-param-reassign
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/upgradeMonedero';

// ------- SAGA ------ //

function fetchMonederoValidarSuccess(state, action) {
    
    state.showBanner = action.payload.showBanner;
    state.message = action.payload.message;
}

function fetchMonederoValidarFailed(state, action) {

    state.showBanner = false;
    state.message = '';

}

const fetchMonederoValidar = createAction('fetchMonederoValidar');

const fetchMonederoValidarReducer = createSlice({
    name: 'fetchMonederoValidar',
    initialState,
    reducers: {
        fetchMonederoValidarSuccess,
        fetchMonederoValidarFailed,
    }
});

export const fetchMonederoValidarActions = {
    ...fetchMonederoValidarReducer.actions,
    fetchMonederoValidar,
};

export default fetchMonederoValidarReducer.reducer;
