import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Wrapper, { CircleLineAnimation } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Countdown = (props) => {
  const { ilustration, withCountdownAnimation, timer, duration, className } = props;

  const porcentajito = useMemo(() => {
    return Math.round(100 - (timer * 100) / 120);
  }, [timer]);

  return (
    <Wrapper className={`row center-xs  ${className && className}`}>
      <div className="factor-countdown--wrapper--ilustration">
        <div className="factor-countdown--wrapper--info-icon">
          <LoadIcon src={`${iconPath}info.svg`} size={26} />
        </div>
        <div>
          <LoadIcon
            src={`${iconPath}${ilustration}.svg`}
            size={180}
            className="factor-countdown-image"
          />
          {withCountdownAnimation && (
            <CircleLineAnimation duration={duration} percentage={porcentajito}>
              <svg width="200" height="200">
                <circle cx="95" cy="95" r="85" transform="rotate(-90, 95, 95)" />
              </svg>
            </CircleLineAnimation>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

Countdown.propTypes = {
  ilustration: PropTypes.string,
  duration: PropTypes.number,
  withCountdownAnimation: PropTypes.bool,
  percentage: PropTypes.number,
  className: PropTypes.string,
  timer:PropTypes.number,
};

Countdown.defaultProps = {
  ilustration: '',
  duration: 129,
  withCountdownAnimation: false,
  percentage: 0,
  className: '',
  timer:120,
};

export default Countdown;
