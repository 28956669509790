export const activacionTexts = {
  TITLE_CARD: 'Activa tu Kit Cuenta Coopeuch',
  TITLE_CARD_TARJETA_COORDENADAS: 'Activa tu Tarjeta de Coordenadas',
  TITLE_DESC: 'Ingresa el Nº de serie que se encuentra al reverso de tu Tarjeta de Coordenadas ',
  TITLE_DESC_1: 'Nº de serie tarjeta de coordenadas',
  BUTTON_CONTINUE: 'Continuar',
  BUTTON_RETURN: 'Volver al inicio',
  BUTTON_BACK: 'Volver',
  BUTTON_RETRY: 'Reintentar',
  BUTTON_CANCEL: 'Cancelar',
  MESSAGE_NUMERO_INCORRECTO:
    'El número de serie ingresado es incorrecto. Por favor, intenta nuevamente.',
  MESSAGE_NUMERO_BLOQUEADO:
    'El número de serie ingresado ha sido bloqueado, ya que has superado la cantidad de intentos permitidos. Para activar tu Kit Cuenta Coopeuch <b>debes dirigirte a la sucursal Coopeuch más cercana.</b>',
};
