// eslint-disable-next-line import/no-extraneous-dependencies
import { Message } from '@design-system-coopeuch/web';
import React from 'react';
// Template
import DocsTemplate from './DocsTemplate';
// Organism
import Stepper from '../../../../components/organism/Stepper';
import CardBlockBonos from '../../../organism/BonoEscolar/Error';
import FooterBackAndContinue from '../../../organism/BonoEscolar/FooterBackAndContinue';
// Atoms
import CardShadow from '../../../atoms/CardShadow';
// Styles
import Wrapper from './styles';

const DocumentacionTemplate = ({
  steps,
  handleUploadFileToServer,
  dataBono,
  btnContinueDisabled,
  handleClickContinue,
  isErrorGeneric,
  isLoading,
  loadFile,
  setbtnContinueDisabled,
  handleClickBack,
  handleClickRetry,
}) => {
  return isErrorGeneric === true ? (
    <Wrapper>
      <CardBlockBonos
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        handleClickRetry={handleClickRetry}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      {!dataBono.documentos.isRejected && (
        <Stepper className="start-xs customStepper" position={3} steps={steps} />
      )}
      <div className="boxContainer">
        <CardShadow
          padding={0}
          customHeader={
            <div className="boxHeader">
              <h2 className="boxHeader__title">Ingreso de documentos</h2>
              <p className="boxHeader__description">
                Formatos permitidos: PDF, JPG, PNG y DOC. El peso máximo permitido es 1 MB.
              </p>
            </div>
          }
          content={
            <>
              <div className="boxBody">
                <DocsTemplate
                  {...{ dataBono, handleUploadFileToServer, loadFile, setbtnContinueDisabled }}
                />
              </div>
            </>
          }
        />
      </div>
      {dataBono.documentos.isRejected && (
        <Message
          type="info"
          className="mt-32"
          title="Te recomendamos revisar bien tus documentos antes de adjuntarlos nuevamente ya que para poder continuar con el proceso de postulación, estos deben ser legibles y cumplir con los requisitos."
        />
      )}
      <FooterBackAndContinue
        btnContinueDisabled={btnContinueDisabled}
        handleClickContinue={handleClickContinue}
        txtBack="Volver"
        txtBtn="Continuar"
        isLoading={isLoading}
        handleClickBack={handleClickBack}
      />
    </Wrapper>
  );
};

export default DocumentacionTemplate;
