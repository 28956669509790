import React, { useEffect } from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import InputLabel from '../../../../../atoms/InputLabel';
import Typography from '@coopeuch-components/web/atoms/Typography';
import StepsActions from '../../../../../organism/Stepper/StepsActions';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import { agregarformatoPesos } from '../../../../../../utils/formatters';
import Skeleton from 'react-loading-skeleton';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { registerGA } from '../../../../../../utils/metrics';
import { AlertWrapper, CheckboxWrapper, CapPanel, MessageError } from './styles';

const Index = ({
  valorCuota,
  maximoCuotas,
  cantidadCuotas,
  selectedAccount,
  remanentePagado,
  checked,
  onHandleCheckBox,
  handleChangeInput,
  backButton,
  nextButton,
  loading,
}) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const saldoInsuficienteError =
    !!cantidadCuotas && parseInt(cantidadCuotas, 10) * valorCuota > selectedAccount.saldo;
  const maxCuotasError = cantidadCuotas > maximoCuotas;
  useEffect(() => {
    if (!!maxCuotasError) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/remanente/capitalizar/paso2',
        'eve-cat': 'Error',
        'eve-lab': `Tu máximo de cuotas a capitalizar es de ${maximoCuotas}, que corresponden a tu remanente pagado.`,
      });
    }
  }, [maxCuotasError, maximoCuotas]);

  const maxCuotasInputError = (
    <MessageError className="input-error-cuotas text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${iconPath}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        Excede la cantidad máxima
      </div>
    </MessageError>
  );
  return (
    <React.Fragment>
      <CardBox>
        <CardBox.Header>Capitaliza tu Remanente</CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12">
              <CapPanel className="w-100 bp-3 bpy-md-4 bpx-md-2rem">
                <div className="row h-100">
                  <div className="h-100 col-xs-6 col-md-3 bmb-3 bmb-md-0 col-element">
                    <Typography tag="p" fontSize="14">
                      Remanente Pagado
                    </Typography>
                    <Typography tag="p" fontSize="24" className="bmt-md-auto text-bold">
                      {remanentePagado && remanentePagado.pago ? (
                        agregarformatoPesos(remanentePagado.pago.monto)
                      ) : (
                        <Skeleton width="50px" />
                      )}
                    </Typography>
                  </div>
                  <div className="h-100 col-xs-6 col-md-3 bmb-3 bmb-md-0 col-element">
                    <Typography tag="p" fontSize="14">
                      Máx. cuotas a Capitalizar
                    </Typography>
                    <Typography tag="p" fontSize="24" className="bmt-md-auto text-bold">
                      {!!maximoCuotas || maximoCuotas === 0 ? (
                        maximoCuotas
                      ) : (
                        <Skeleton width="50px" />
                      )}
                    </Typography>
                  </div>
                  <div className="h-100 col-xs-6 col-md-3 col-element">
                    <Typography tag="p" fontSize="14">
                      Valor Cuota
                    </Typography>
                    <Typography tag="p" fontSize="24" className="bmt-md-auto text-bold">
                      {valorCuota ? agregarformatoPesos(valorCuota) : <Skeleton width="50px" />}
                    </Typography>
                  </div>
                  <div className="h-100 col-xs-6 col-md-3 col-element">
                    <Typography tag="p" fontSize="14">
                      Saldo Cuenta de Origen
                    </Typography>
                    <Typography tag="p" fontSize="24" className="bmt-md-auto text-bold">
                      {!!selectedAccount && selectedAccount.saldo ? (
                        agregarformatoPesos(selectedAccount.saldo)
                      ) : (
                        <Skeleton width="50px" />
                      )}
                    </Typography>
                  </div>
                </div>
              </CapPanel>
            </div>
          </div>
          <div className="row bmt-3 bmt-md-4">
            <div className="col-xs-12 text-center">
              <Typography tag="p" fontSize="20" className="text-center">
                ¿Cuánto deseas capitalizar?
              </Typography>
            </div>
            <div className="col-xs-12 col-md-6 col-md-offset-3 bmt-3">
              <div className="row">
                <div className="col-xs-12 col-md-6 bmb-3 bmb-md-0">
                  <div className="bmr-md-2 bmr-0">
                    <InputLabel
                      borderColor={saldoInsuficienteError || maxCuotasError ? 'red' : ''}
                      value={cantidadCuotas}
                      name={'cantidadCuotas'}
                      onChange={handleChangeInput}
                      title="Cuotas a Capitalizar"
                      labelClass="text-bold bmb-3"
                      size="48"
                      errorMessage={maxCuotasError ? maxCuotasInputError : ''}
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <div className="bml-md-2 bml-0">
                    <InputLabel
                      disabled
                      value={agregarformatoPesos(cantidadCuotas * valorCuota)}
                      title="Monto a Capitalizar"
                      labelClass="text-bold bmb-3"
                      size="48"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(saldoInsuficienteError || maxCuotasError) && (
            <React.Fragment>
              {maxCuotasError ? (
                <div className="row bmt-3 bmt-md-4 ">
                  <AlertWrapper className="col-xs-12 ">
                    <Alert
                      className="alert-warning bp-3 "
                      bgType="warning"
                      id="warning-saldo-insuficiente"
                      show
                    >
                      {`Tu máximo de cuotas a capitalizar es de ${maximoCuotas}, que corresponden a tu remanente pagado.`}
                    </Alert>
                  </AlertWrapper>
                </div>
              ) : (
                <div className="row bmt-3 bmt-md-4 ">
                  <AlertWrapper className="col-xs-12 ">
                    <Alert
                      className="alert-warning bp-3 "
                      bgType="warning"
                      id="warning-saldo-insuficiente"
                      show
                    >
                      No tienes saldo suficiente para capitalizar. Abona a tu cuenta de origen.
                    </Alert>
                  </AlertWrapper>
                </div>
              )}
            </React.Fragment>
          )}

          <div className="row bmt-3 bmt-md-4">
            <CheckboxWrapper className="col-xs-12">
              <CheckBox
                name="auth-check-capitalizacion"
                className="checkbox-element"
                text={
                  <React.Fragment>
                    <p className="checkbox-label">
                      Autorizo a Coopeuch adquirir a mi nombre Cuotas de Participación, por montos
                      que se detallan en esta solicitud y con cargo a mi Cuenta Vista.
                    </p>
                  </React.Fragment>
                }
                borderColor="#646569"
                id="input-auth-contratacion-monedero"
                fontSize={16}
                isChecked={checked}
                onChange={onHandleCheckBox}
              />
            </CheckboxWrapper>
          </div>
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmy-3 bmy-md-4"
        backAction={backButton}
        backText={'No por ahora'}
        nextAction={nextButton}
        nextText="Capitalizar"
        disableNextAction={saldoInsuficienteError || maxCuotasError || !checked || !cantidadCuotas}
        id="contratacion-paso-1"
        loading={loading}
      />
    </React.Fragment>
  );
};

export default Index;
