import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { loadersActions } from '../../../application/actions/ui_loaders';
import { certificadosSIIActions } from '../../../application/actions/certificadosSII';

// api
import api from '../../api';

const apiCertificadoSII = api(process.env.REACT_APP_CERTIFICADOS);

export function* fetchPDFCertificadoSII(action) {
  try {
    yield put(loadersActions.fetchLoadingCertificadoSII({ isLoading: true }));

    const { data, error } = yield call(apiCertificadoSII.post, 'sii/obtener', action.payload);

    if (error) {
      yield put(certificadosSIIActions.fetchPDFCertificadoSIIError({ ...action.payload, error }));
      yield put(loadersActions.fetchLoadingCertificadoSII({ isLoading: false }));
    } else {
      yield put(
        certificadosSIIActions.fetchPDFCertificadoSIISuccess({
          ...action.payload,
          data: data || '',
        })
      );
      yield put(loadersActions.fetchLoadingCertificadoSII({ isLoading: false }));
    }
  } catch (error) {
    yield put(certificadosSIIActions.fetchPDFCertificadoSIIError({ ...action.payload, error }));
  }
}

export function* watchFetchPDFCertificadoSII() {
  yield takeLatest(certificadosSIIActions.fetchPDFCertificadoSII.type, fetchPDFCertificadoSII);
}

/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */
export default function* rootSaga() {
  yield spawn(watchFetchPDFCertificadoSII);
}
