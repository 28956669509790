import React, { useMemo } from 'react';

import Skeleton from 'react-loading-skeleton';

import DashboardCard from '../../../organism/DashboardCard';

const PagarTarjetaMontoAPagarLoading = () => {
  return (
    <div className="row modulos-container">
      <div className="col-xs-12">
        <DashboardCard classes="modulo">
          <DashboardCard.Body classes="modulo-content">
            <div className="row">
              <div className="col-xs-12">
                <div className="hide-on-mobile">
                  <Skeleton height={10} width={372} />
                </div>
                <div className="hide-on-desktop">
                  <Skeleton height={10} width="100%" />
                </div>
              </div>

              <div className="col-xs-12 pt-24">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="pb-8 mt-24">
                      <Skeleton height={10} width={212} />
                    </div>
                    <Skeleton height={10} width={172} />
                  </div>

                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <div className="pb-8 mt-24">
                      <Skeleton height={10} width={212} />
                    </div>

                    <Skeleton height={10} width={172} />
                  </div>
                </div>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>

      <div className="col-xs-12">
        <DashboardCard classes="modulo">
          <DashboardCard.Body classes="modulo-content">
            <div className="row monto-radio-container">
              <div className="col-xs-12">
                <Skeleton height={10} width={172} />
              </div>
              <div className="col-xs-12">
                <div className="row pt-24">
                  {[1, 2, 3]?.map(() => (
                    <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                      <div className="d-flex mt-12">
                        <Skeleton circle height={24} width={24} />
                        <div className="pl-16">
                          <div className="pb-4">
                            <Skeleton height={10} width={142} />
                          </div>
                          <Skeleton height={10} width={92} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>

        <div className="col-xs-12">
          <div className="row">
            <div className="col-xs-12 end-xs pr-0 hide-on-mobile">
              <Skeleton height={51} width={180} />
            </div>
            <div className="col-xs-12 pl-0 pr-0 hide-on-desktop">
              <Skeleton height={51} width="100%" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagarTarjetaMontoAPagarLoading;
