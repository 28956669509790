import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 24px;
  border-bottom: 1px solid #e9e9e9;
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 17px;
`;

export const Title = styled.h2`
  font-size: 18px;
  padding-bottom: 16px;
  font-family: 'Ubuntu-Regular';
`;

export const Description = styled.p`
  font-size: 16px;
  padding-bottom: 26px;
  font-family: 'Ubuntu-Regular';
  max-width: 850px;
  @media screen and (max-width: 320px) {
    max-width: 150px;
  }
`;

export const DocumentBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 24px;
  cursor: pointer;
`;

export const TextDocument = styled.p`
  font-size: 16px;
  color: #ec1b2b;
  padding-left: 10px;
  font-family: 'Ubuntu-Medium';
`;
