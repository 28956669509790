/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/remanente';

// ------- SAGA ------ //

function fetchProductosDisponiblesSuccess(state, action) {
  state.productosDisponibles = {
    ...state.productosDisponibles,
    cuentas: action.payload,
  };
}

function fetchProductosDisponiblesFailed(state, action) {
  state.errorLogObtenerCuentas = action.payload.errorLog;
}

function fetchTarjetasProductosDisponiblesSuccess(state, action) {
  state.productosDisponibles = {
    ...state.productosDisponibles,
    tarjetas: action.payload,
  };
}

function fetchTarjetasProductosDisponiblesFailed(state, action) {
  state.errorLogObtenerTarjetas = action.payload.errorLog;
}

function fetchComprobanteCobroSuccess(state, action) {
  state.comprobanteCobro = action.payload.data;
}

function fetchComprobanteCobroFailed(state, action) {
  state.errorComprobanteCobroLog = action.payload.errorLog;
}

function fetchRemanenteSuccess(state, action) {
  const saldoIndividual = action.payload.saldoIndividual;
  if (!saldoIndividual.sinRemanente) {
    saldoIndividual.pago.monto = Math.trunc(saldoIndividual.pago.monto);
  }
  state.saldoIndividual = saldoIndividual;
}

function fetchRemanenteError(state, action) {
  state.saldoIndividual = { error: true };
}

function fetchBannerEstacionalSuccess(state, action) {
  const { bannerEstacional } = action.payload;

  state.bannerEstacional = bannerEstacional;
}

function fetchRemanenteComposicionSuccess(state, action) {
  const { composicion } = action.payload;
  state.composicion = composicion;
}

function fetchRemanenteComposicionError(state) {
  state.composicionError = true
}

function fetchRemanenteContenidoSuccess(state, action) {
  const { contenido } = action.payload;

  state.remanenteCalculo = contenido.remanenteCalculo;
  state.remanenteMontoTotal = contenido.remanenteMontoTotal;
}

function fetchLogoutSuccess(state) {
  state.saldoIndividual = {};
  state.bannerEstacional = {};
  state.composicion = [];
}

function setRemanenteMainError(state, action) {
  state.error = Boolean(action.payload);
  state.errorLog = action.payload;
}
function setRemanenteContenidoError(state, action) {
  state.contenidoError = action.payload;
  state.remanenteCalculo = {};
  state.remanenteMontoTotal = {};
}

function fetchCobroRemanenteWebSuccess(state, action) {
  state.cobreRemanenteWeb = action.payload;
}
function fetchCobroRemanenteWebError(state, action) {
  state.cobreRemanenteWeb = action.payload;
}
function cobrarRemanenteSuccess(state, action) {
  state.cobrarRemanente = {
    ...state.cobrarRemanente,
    monto: action.payload.monto,
    medio: action.payload.medio,
    numeroCuenta: action.payload.numeroCuenta,
    fecha: action.payload.fecha,
    rawFecha: action.payload.rawFecha,
    email: action.payload.email,
    success: true,
    error: false,
  };
  state.comprobanteCobro = null;
  state.errorCobrarRemanenteLog = '';
}
function cobrarRemanenteError(state, action) {
  state.cobrarRemanente = action.payload.errorData;
  state.errorCobrarRemanenteLog = action.payload.errorLog;
}

function refreshHabilitadoCobrar(state) {
  state.cobreRemanenteWeb = {};
}

function fetchEnvioEmailSuccess(state, action) {
  const { envioEmail } = action.payload;
  state.emailComprobante = envioEmail;
  state.emailComprobanteError = false;
}

function fetchEnvioEmailError(state) {
  state.emailComprobante = false;
  state.emailComprobanteError = true;
}

function clearEnvio(state) {
  state.emailComprobante = false;
  state.emailComprobanteError = false;
}

function fetchRemanenteSwitchOnOffSuccess(state, action) {
  state.switchOnOff = {
    data: action.payload?.data,
    success: true,
    error: false
  };
}

function fetchRemanenteSwitchOnOffFailed(state, action) {
  state.switchOnOff = {
    data: action.payload,
    success: false,
    error: true
  };
}




const fetchComprobanteCobro = createAction('fetchComprobanteCobro');
const fetchProductosDisponibles = createAction('fetchProductosDisponibles');
const fetchTarjetasProductosDisponibles = createAction('fetchTarjetasProductosDisponibles');
const fetchRemanente = createAction('fetchRemanente');
const fetchBannerEstacional = createAction('fetchBannerEstacional');
const fetchRemanenteComposicion = createAction('fetchRemanenteComposicion');
const fetchRemanenteContenido = createAction('fetchRemanenteContenido');
const fetchCobroRemanenteWeb = createAction('fetchCobroRemanenteWeb');
const cobrarRemanente = createAction('cobrarRemanente');
const fetchEnvioEmail = createAction('fetchEnvioEmail');
const fetchRemanenteSwitchOnOff = createAction('fetchRemanenteSwitchOnOff');

// ------------- connection ------------- //

const remanenteReducer = createSlice({
  name: 'remanente',
  initialState,
  reducers: {
    fetchProductosDisponiblesSuccess,
    fetchTarjetasProductosDisponiblesSuccess,
    fetchComprobanteCobroSuccess,
    fetchRemanenteContenidoSuccess,
    fetchCobroRemanenteWebSuccess,
    fetchCobroRemanenteWebError,
    fetchRemanenteSuccess,
    fetchRemanenteError,
    fetchBannerEstacionalSuccess,
    fetchRemanenteComposicionSuccess,
    fetchRemanenteComposicionError,
    fetchLogoutSuccess,
    setRemanenteMainError,
    setRemanenteContenidoError,
    cobrarRemanenteSuccess,
    cobrarRemanenteError,
    refreshHabilitadoCobrar,
    fetchTarjetasProductosDisponiblesFailed,
    fetchProductosDisponiblesFailed,
    fetchComprobanteCobroFailed,
    fetchEnvioEmailSuccess,
    fetchEnvioEmailError,
    clearEnvio,
    fetchRemanenteSwitchOnOffSuccess,
    fetchRemanenteSwitchOnOffFailed
  },
});

export const remanenteActions = {
  ...remanenteReducer.actions,
  fetchComprobanteCobro,
  fetchProductosDisponibles,
  fetchTarjetasProductosDisponibles,
  fetchRemanente,
  fetchBannerEstacional,
  fetchRemanenteComposicion,
  fetchRemanenteContenido,
  fetchCobroRemanenteWeb,
  cobrarRemanente,
  fetchEnvioEmail,
  fetchRemanenteSwitchOnOff,
};

export default remanenteReducer.reducer;
