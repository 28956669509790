import styled from 'styled-components';

const Wrapper = styled.div`
  .bonoSolicitar {
    > div > h1 {
      font-weight: 900;
    }

    padding: 0px;

    &__content {
      box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 5%);
      width: 100vmax;
      position: absolute;
      border-bottom: 1px solid #e7e7e7;
      padding-left: 50px;

      div {
        font-size: 16px;
      }

      div:first-child {
        width: 15%;
      }
      div:last-child {
        width: 15%;
      }
      @media screen and (max-width: 720px) {
        width: auto;
        position: initial;
        padding-left: 0px;

        div:first-child {
          width: 50%;
        }
        div:last-child {
          width: 50%;
        }
      }
    }
  }

  .footerInformativo {
    margin-top: 32px;
   
    > p {
      color: #6c757d;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    }

    @media screen and (max-width: 500px) {
      margin-left: 16px;
    }
  }
`;
export const WrapperError = styled.div`
  margin-left: 57px;
  margin-top: 37px;
  @media (max-width: 1280px) {
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export const WrapperSkeleton = styled.div`
  .bodySkeleton,
  .footerSkeleton {
    position: relative;
    padding: 20px 32px 20px 32px;
    @media (max-width: 991px) {
      padding: 16px;
    }
  }
  .skeleton-description {
    height: 44px;
  }

  .boxMonto {
    display: flex;
    flex-direction: column;
  }

  .skeleton-list {
    height: 22px;
  }

  .skeleton-txt {
    height: 22px;
    max-width: 365px;
  }

  .footerSkeleton {
    .skeleton-btn {
      height: 48px;
    }
  }
`;

export default Wrapper;
