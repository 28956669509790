import React, { PureComponent } from 'react';
import produce from 'immer';

import StepperRouter from './StepperRouter';

const initState = {
  pathStep: 1,
  cuentaSeleccionada: {},
  tipoCuentas: [
    { id: 1, descripcion: 'AhorroAdultoNominal', code: 'AN07'},
    { id: 2, descripcion: 'AhorroAdultoReajustable', code: 'AR06'},
    { id: 3, descripcion: 'AhorroVivienda', code: 'AR08'},
  ],
  mailSeleccionado: '',
};

class SolicitudCA extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  handleClickCuenta = (item) => {
    this.setState(
      produce((draft) => {
        draft.cuentaSeleccionada = item;
      })
    );
  };

  handleStep = (step) => {
    this.setState(
      produce((draft) => {
        draft.pathStep = step;
      })
    );
  };

  handleMail = (mail) => {
    this.setState({
      mailSeleccionado: mail.toString(),
    });
  };

  handleCleanCuentaSeleccionada = () => {
    this.setState({
      cuentaSeleccionada: {},
    });
  };

  render() {
    return (
      <StepperRouter
        {...this.props}
        {...this.state}
        handleClickCuenta={this.handleClickCuenta}
        handleStep={this.handleStep}
        handleMail={this.handleMail}
        handleCleanCuenta={this.handleCleanCuentaSeleccionada}
      />
    );
  }
}

export default SolicitudCA;
