import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { SinRemanenteWrapper } from './styles';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const SinRemanenteAlert = () => {
  return (
    <SinRemanenteWrapper className="w-100 h-100 text-center">
      <LoadIcon src={`${iconPath}info-desktop.svg`} />
      <p>
        <b>Información pendiente según resultados de Asamblea 2024.</b>
      </p>
    </SinRemanenteWrapper>
  );
};

export default SinRemanenteAlert;
