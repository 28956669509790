import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import ButtonBack from '../../ButtonBack';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetasKit/texts';
import { registerGA } from '../../../../../utils/metrics';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const backToActivacion = (accion, msgErrorGA, pageGA) => {
  if (accion !== '' && msgErrorGA && msgErrorGA !== '' && pageGA && pageGA !== '') {
    registerGA({
      event: 'eve',
      'eve-acc': `${pageGA}`,
      'eve-cat': 'Click',
      'eve-lab': `${accion} - ${msgErrorGA}`,
    });
  }
};

const CardBlock = ({ section, title, message, link, msgErrorGA, pageGA }) => {
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} className="titleActivacionKit">
            {section}
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="cardBlockBody">
            <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="imageCenter" size={180} />
            <Typography align="center" tag="p" className="titleAlertKit">
              {title}
            </Typography>
            <Typography align="center" tag="p" className="descriptionKit">
              {message}
            </Typography>
          </div>
        </CardBox.Body>
      </CardBox>
      <ButtonBack
        onClick={() => backToActivacion(activacionTexts.BUTTON_CANCEL, msgErrorGA, pageGA)}
        link={link}
        text={activacionTexts.BUTTON_CANCEL}
      />
    </Wrapper>
  );
};

export default CardBlock;
