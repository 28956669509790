/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/transferencias';

// ------- SAGA ------ //

function fetchDestinatariosSuccess(state, action) {
  const { destinatarios, errorDestinatarios, errorDestinatariosLog } = action.payload;

  state.destinatarios = destinatarios;
  state.errorDestinatarios = errorDestinatarios;
  state.errorDestinatariosLog = errorDestinatariosLog;
}

function fetchBancosSuccess(state, action) {
  const { bancos } = action.payload;
  state.bancos = bancos;
  state.errorBancos = '';
}

function fetchBancosFailed(state, action) {
  const { errorLog } = action.payload;
  state.bancos = [];
  state.errorBancos = errorLog;
}

function fetchTipoCuentasSuccess(state, action) {
  const { tipoCuentas } = action.payload;
  state.tipoCuentas = tipoCuentas;
  state.errorTipoCuentas = '';
}

function fetchTipoCuentasFailed(state, action) {
  const { errorLog } = action.payload;
  state.tipoCuentas = [];
  state.errorTipoCuentas = errorLog;
}

function fetchCreateDestinatarioFailed(state, action) {
  const { errorCrearOEditarDestinatario } = action.payload;

  state.errorCrearOEditarDestinatario = errorCrearOEditarDestinatario;
}

function fetchEditarDestinatarioFailed(state, action) {
  const { errorCrearOEditarDestinatario, destinatarioMessageError } = action.payload;

  state.errorCrearOEditarDestinatario = errorCrearOEditarDestinatario;
  state.destinatarioMessageError = destinatarioMessageError;
}

function fetchDeleteDestinatarioSuccess(state, action) {
  const { errorBorrarDestinatarios } = action.payload;

  state.errorBorrarDestinatarios = errorBorrarDestinatarios;
}

function changeDestinoSeleccionado(state, action) {
  const { payload } = action;
  state.destinoSeleccionado = payload;
}

function changeOrigenSeleccionado(state, action) {
  const { payload } = action;
  state.origenSeleccionado = payload;
}

function fetchReglasTercerosSuccess(state, action) {
  const {
    reglasTerceros,
    errorReglasTerceros,
    errorReglasTercerosLog,
    montoAcumuladoTransferenciasTerceros,
    cantidadTransferenciasTerceros,
    isNuevo,
    permiteTransferir,
    bancosBloqueados,
  } = action.payload;
  state.reglasTerceros = reglasTerceros;
  state.montoAcumuladoTransferenciasTerceros = montoAcumuladoTransferenciasTerceros;
  state.isNuevo = isNuevo;
  state.permiteTransferir = permiteTransferir;
  state.cantidadTransferenciasTerceros = cantidadTransferenciasTerceros;
  state.errorReglasTerceros = errorReglasTerceros;
  state.errorReglasTercerosLog = errorReglasTercerosLog;

  state.bancosBloqueados = bancosBloqueados;
}

function changeMontoYMensajeTransferenciaTerceros(state, action) {
  const { payload } = action;
  state.montoATransferirTerceros = payload.montoATransferir;
  state.comentarioATransferirTerceros = payload.comentarioATransferir;
}

function resetTransferenciasATerceros(state) {
  const { contadorEncuestas } = state;
  Object.assign(state, initialState, { contadorEncuestas });
}

function changeValueDestinoSeleccionado(state, action) {
  const { payload } = action;
  state.valueDestinoSeleccionado = payload;
}

function selectDestinatarioRequest(state, action) {
  const { track, idTransferencia } = action.payload;
  state.trackTransferencia = track;
  state.idTransferencia = idTransferencia;
}

function validarTransferenciaRequest(state, action) {
  const { errorValidarTransferencia, error } = action.payload;
  state.errorValidarTransferencia = errorValidarTransferencia;
  state.errorValidarTransferenciaLog = error;
}

function ejecutarTransferenciaResponse(state, action) {
  const { errorEjecutarTransferencia, error, detalleComprobanteTerceros } = action.payload;
  state.errorEjecutarTransferencia = errorEjecutarTransferencia;
  state.detalleComprobanteTerceros = detalleComprobanteTerceros;
  state.errorEjecutarTransferenciaLog = error;
}

function fetchUpdateFavoriteDestinatarioSuccess(state, action) {
  state.updateDestinatario.data = action.payload;
  state.updateDestinatario.error = null;
}
function descargarComprobanteError(state, action) {
  state.descargaComprobanteTEF = {
    error: true,
    success: false,
    errorCode: '500',
    data: null,
    code: '500',
  };
}

function fetchUpdateFavoriteDestinatarioFailed(state, action) {
  state.updateDestinatario.error = action.payload;
  state.updateDestinatario.data = null;
}
function fetchUpdateFavoriteDestinatarioReset(state) {
  state.updateDestinatario.data = null;
  state.updateDestinatario.error = null;
}
function ftuFuncionalidadSuccess(state, action) {
  state.isFTUOpen.data = action.payload;
  state.isFTUOpen.error = null;
}
function ftuFuncionalidadFailed(state, action) {
  state.isFTUOpen.error = action.payload;
  state.isFTUOpen.data = null;
}
function ftuCloseSuccess(state) {
  state.isFTUOpen.data = false;
  state.isFTUOpen.error = null;
}
function ftuCloseFailed(state, action) {
  state.isFTUOpen.error = action.payload;
  state.isFTUOpen.data = null;
}


function descargarComprobanteSuccess(state, action) {
  console.log('action: 1: ', action)
  state.descargaComprobanteTEF = {
    success: true,
    error: false,
    errorCode: '200',
    data: null,
    code: '200',
  };
}

const fetchDestinatarios = createAction('fetchDestinatarios');
const fetchBancos = createAction('fetchBancos');
const fetchTipoCuentas = createAction('fetchTipoCuentas');
const createDestinatario = createAction('createDestinatario');
const deleteDestinatario = createAction('deleteDestinatario');
const editarDestinatario = createAction('editarDestinatario');
const validarDestinatario = createAction('validarDestinatario');
const fetchReglasTerceros = createAction('fetchReglasTerceros');
const selectDestinatario = createAction('selectDestinatario');
const validarTransferencia = createAction('validarTransferencia');
const ejecutarTransferencia = createAction('ejecutarTransferencia');
const descargarComprobanteTerceros = createAction('descargarComprobanteTerceros');
const fetchUpdateFavoriteDestinatario = createAction('fetchUpdateFavoriteDestinatario');
const ftuFuncionalidad = createAction('ftuFuncionalidad');
const ftuClose = createAction('ftuClose');
const transferenciasReducer = createSlice({
  name: 'transferencias',
  initialState,
  reducers: {
    fetchDestinatariosSuccess,
    fetchBancosSuccess,
    fetchBancosFailed,
    fetchTipoCuentasSuccess,
    fetchTipoCuentasFailed,
    fetchDeleteDestinatarioSuccess,
    fetchCreateDestinatarioFailed,
    fetchEditarDestinatarioFailed,
    changeDestinoSeleccionado,
    changeOrigenSeleccionado,
    fetchReglasTercerosSuccess,
    changeMontoYMensajeTransferenciaTerceros,
    resetTransferenciasATerceros,
    changeValueDestinoSeleccionado,
    selectDestinatarioRequest,
    validarTransferenciaRequest,
    ejecutarTransferenciaResponse,
    fetchUpdateFavoriteDestinatarioSuccess,
    fetchUpdateFavoriteDestinatarioFailed,
    fetchUpdateFavoriteDestinatarioReset,
    ftuFuncionalidadSuccess,
    ftuFuncionalidadFailed,
    ftuCloseSuccess,
    ftuCloseFailed,
    descargarComprobanteSuccess,
    descargarComprobanteError,
  },
});

export const transferenciasActions = {
  ...transferenciasReducer.actions,
  fetchDestinatarios,
  fetchBancos,
  fetchTipoCuentas,
  createDestinatario,
  deleteDestinatario,
  editarDestinatario,
  validarDestinatario,
  fetchReglasTerceros,
  selectDestinatario,
  validarTransferencia,
  ejecutarTransferencia,
  descargarComprobanteTerceros,
  fetchUpdateFavoriteDestinatario,
  ftuFuncionalidad,
  ftuClose
};

export default transferenciasReducer.reducer;
