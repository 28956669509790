import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 54px;

  @media screen and (max-width: 768px) {
    margin-top: 42px;
  }
`;

export const CustomList = styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 100%;
      position: absolute;
      content: '';
      height: 6px;
      width: 6px;
      display: inline-block;
      left: -16px;
      top: 6px;
    }
  }
`;

export const ExpandHeaderWrapper = styled.div`
  padding-left: 32px;
  font-size: 20px;
  & > svg {
    fill: black;
  }
`;

export const Banner = styled.div`
  height: 200px;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});

  .banner-actions {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    height: 320px;
    background-image: url(${({ mobileSrc }) => mobileSrc});
    border-radius: 0px;
    margin-left: -1rem;
    margin-right: -1rem;

    .banner-text {
      padding-left: 24px !important;
    }
  }
`;
