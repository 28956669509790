/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/cuotaParticipacion';

// ------- SAGA ------ //

function fetchFactoresCuotaParticipacionSuccess(state, action) {
  const auxKeys = {};
  action.payload.data.map((v) => {
    auxKeys[v.key] = v.value;
    
  });
  const keys = {
    valorCuota: auxKeys.VALOR,
    minimoCuotas: auxKeys.MINIMOCUOTAS,
    maximoCuotas: auxKeys.MAXIMOCUOTAS,
  };

  state.factores = keys;
  state.errorFactores = false;
  state.errorFactoresLogs = null;
}

function fetchCVCuotasParticipacionSuccess(state, action) {
  state.cuentasDisponibles = {
    success: true,
    error: false,
    data: action.payload,
    date: new Date().toISOString(),
  };
}

function fetchCVCuotasParticipacionError(state, action) {
  state.cuentasDisponibles = {
    success: false,
    error: true,
    data: [],
    date: new Date().toISOString(),
  };
}

function fetchFactoresCuotaParticipacionError(state, action) {
  state.errorFactores = true;
  state.errorFactoresLogs = action.payload?.error;
}

function fetchCuotasParticipacionSuccess(state, action) {
  state.cuotas = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}
function fetchModificarCuotaParticipacionError(state, action) {
  state.errorCuotaModify = true;
}
function fetchBannerCuotaParticipacionError(state, action) {
  state.errorCuotaBanner = true;
}
function fetchModificarCuotasParticipacionSuccess(state, action) {
  const data = action?.payload?.data
  state.modify_cuotas = { 
    success: data?.mostrarBoton, 
    error: false, 
    data, 
    date: new Date().toISOString() };
}
function fetchBannerCuotasParticipacionSuccess(state, action) {
  const data = action?.payload?.data
  console.log('modify_data_banner ', data)
   state.banner_cuotas = { 
     success: data?.mostrarBanner, 
  //   error: false, 
  //   data, 
  //   date: new Date().toISOString() 
  };
}

function fetchSugerenciaCuotasParticipacionSuccess(state, action) {
  const data = action?.payload?.data
  state.sugerenciaCuotas = { 
    success: true, 
    error: false, 
    data, 
    date: new Date().toISOString() };
}
function fetchSugerenciaCuotaParticipacionError(state, action) {
  state.sugerenciaCuotas = { success: false, error: true, data: null, date: new Date().toISOString() };
}
function fetchCuotasParticipacionError(state, action) {
  state.cuotas = { success: false, error: true, data: null, date: new Date().toISOString() };
}

function pagarCuotasParticipacionSuccess(state, action) {
  state.pagarCuotas = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function pagarCuotasParticipacionError(state, action) {
  state.pagarCuotas = {
    success: false,
    error: true,
    errorCode: action.payload.error,
    data: false,
    date: new Date().toISOString(),
  };
}

function fetchDetallePagoCDPSuccess(state, action) {
  state.detallePagoCuotas = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchDetallePagoCDPError(state) {
  state.detallePagoCuotas = {
    success: false,
    error: true,
    data: false,
    date: new Date().toISOString(),
  };
}

function fetchDataCuotasPagadasSuccess(state, action) {
  action.payload.data.sort((a, b) => a.fechaPago - b.fechaPago).reverse();
  state.dataCuotasPagadas = {
    success: true,
    error: false,
    data: action.payload.data,
  };
}

function fetchDataCuotasPagadasError(state) {
  state.dataCuotasPagadas = {
    success: false,
    error: true,
    data: null,
  };
}
/// /////////////////////////////////////////////////
function fetchBorradorDescargarCuotasParticipacionSuccess(state, action) {
  const data = action?.payload?.data

  state.borradorDescargarCuotas = { 
    success: true, 
    error: false, 
    data, 
    date: new Date().toISOString() };
}
function fetchBorradorDescargarCuotaParticipacionError(state, action) {
  state.errorCuotaBorradorDescargar = true;
}
function fetchActualizacionCuotaParticipacionError(state, action) {
  const { code, message, statusCode, uri, error } = action.payload
  state.errorCuotaActualizacion = { 
    message: message, 
    error: true, 
    code: code, 
    statusCode: statusCode,
    uri: uri
  }
}
function fetchActualizaErrorCuotaParticipacion(state) {
  state.errorCuotaActualizacion = { 
    message: '', 
    error: false, 
    code: '', 
    statusCode: '',
    uri: ''
  }
}
function fetchActualizacionCuotasParticipacionSuccess(state, action) {
  const data = action?.payload?.data
  state.actualizacionCuotas = { 
    success: true, 
    error: false, 
    data, 
    date: new Date().toISOString() };
}

function fetchDescargarComprobanteModificacionCuotasParticipacionSuccess (state, action) {
  state.descargarComprobanteModificacionCuotasParticipacion = {
    success: true,
    error: false,
    data: action.payload.data,
  };
}

function fetchDescargarComprobanteModificacionCuotasParticipacionError (state, action) {
  const { code, message, statusCode, uri } = action.payload
  state.descargarComprobanteModificacionCuotasParticipacionError = { 
    message: message, 
    error: true, 
    code: code, 
    statusCode: statusCode,
    uri: uri
  }
}



/// /////////////////////////////////////////////////

const fetchFactoresCuotaParticipacion = createAction('fetchFactoresCuotaParticipacion');
const fetchCuotasParticipacion = createAction('fetchCuotasParticipacion');
const fetchCVCuotasParticipacion = createAction('fetchCVCuotasParticipacion');
const pagarCuotasParticipacion = createAction('pagarCuotasParticipacion');
const fetchDetallePagoCDP = createAction('fetchDetallePagoCDP');
const fetchDataCuotasPagadas = createAction('fetchDataCuotasPagadas');
const fetchDownloadPDF = createAction('fetchDownloadPDF');
const fetchModificacionCuotasParticipacion = createAction('fetchModificacionCuotasParticipacion');
const fetchSugerenciaCuotasParticipacion = createAction('fetchSugerenciaCuotasParticipacion');
const fetchActualizacionCuotasParticipacion = createAction('fetchActualizacionCuotasParticipacion');
const fetchBorradorDescargarCuotasParticipacion = createAction('fetchBorradorDescargarCuotasParticipacion');
const fetchDescargarComprobanteModificacionCuotasParticipacion = createAction('fetchDescargarComprobanteModificacionCuotasParticipacion');
const fetchActualizaStatusErrorCuotaParticipacion = createAction('fetchActualizaStatusErrorCuotaParticipacion');
const fetchBannerCuotasParticipacion = createAction('fetchBannerCuotasParticipacion');

// const fetchComprobanteContratacion = createAction('fetchComprobanteContratacion');

// ------------- connection ------------- //

const cuotaParticipacionReducer = createSlice({
  name: 'cuotaParticipacion',
  initialState,
  reducers: {
    fetchFactoresCuotaParticipacionSuccess,
    fetchCuotasParticipacionSuccess,
    fetchCuotasParticipacionError,
    fetchFactoresCuotaParticipacionError,
    fetchCVCuotasParticipacionSuccess,
    fetchCVCuotasParticipacionError,
    pagarCuotasParticipacionSuccess,
    pagarCuotasParticipacionError,
    fetchDetallePagoCDPSuccess,
    fetchDetallePagoCDPError,
    fetchDataCuotasPagadasSuccess,
    fetchDataCuotasPagadasError,
    fetchModificarCuotasParticipacionSuccess,
    fetchModificarCuotaParticipacionError,
    fetchSugerenciaCuotasParticipacionSuccess,
    fetchSugerenciaCuotaParticipacionError,
    fetchBorradorDescargarCuotasParticipacionSuccess,
    fetchDescargarComprobanteModificacionCuotasParticipacionSuccess,
    fetchDescargarComprobanteModificacionCuotasParticipacionError,
    fetchBorradorDescargarCuotaParticipacionError,
    fetchActualizacionCuotaParticipacionError,
    fetchActualizacionCuotasParticipacionSuccess,
    fetchActualizaErrorCuotaParticipacion,
    fetchBannerCuotaParticipacionError,
    fetchBannerCuotasParticipacionSuccess,
  },
});

export const cuotaParticipacionActions = {
  ...cuotaParticipacionReducer.actions,
  fetchFactoresCuotaParticipacion,
  fetchCuotasParticipacion,
  fetchCVCuotasParticipacion,
  pagarCuotasParticipacion,
  fetchDetallePagoCDP,
  fetchDataCuotasPagadas,
  fetchDownloadPDF,
  fetchModificacionCuotasParticipacion,
  fetchSugerenciaCuotasParticipacion,
  fetchActualizacionCuotasParticipacion,
  fetchBorradorDescargarCuotasParticipacion,
  fetchDescargarComprobanteModificacionCuotasParticipacion,
  fetchActualizaStatusErrorCuotaParticipacion,
  fetchBannerCuotasParticipacion,
};

export default cuotaParticipacionReducer.reducer;
