import { all, call, delay, put, select, spawn, takeLatest } from 'redux-saga/effects';

// Actions
import { cuentaAhorroActions } from '../../../application/actions/cuentaAhorro';
import { loadersActions } from '../../../application/actions/ui_loaders';
import { sortDates } from '../../../ui/utils/dates';
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';

import api from '../../api';

import defaultRequest, { errTarjetaCoordenadasFactor } from './errors';

const apiCuentaAhorro = api(process.env.REACT_APP_CUENTA_AHORRO);
const apiMovimientos = api(process.env.REACT_APP_CUENTA_AHORRO_MOVIMIENTOS);
const apiPersona = api(process.env.REACT_APP_PERSONA);
const apiMsfactor = api(process.env.REACT_APP_FACTORES_SEGURIDAD);
export function* fetchCuentasAhorro(action) {
  try {
    const { params } = action.payload;

    yield put(loadersActions.fetchLoadingCuentasAhorro({ isLoading: true }));

    const { data, error } = yield call(apiCuentaAhorro.post, 'cuenta/v1/obtener', params);

    if (data) {
      yield put(cuentaAhorroActions.fetchCuentasAhorroSuccess({ cuentas: data }));
      yield put(loadersActions.fetchLoadingCuentasAhorro({ isLoading: false }));
    } else {
      yield put(cuentaAhorroActions.fetchCuentasAhorroFailed({ error }));
      yield put(loadersActions.fetchLoadingCuentasAhorro({ isLoading: false }));
    }
  } catch (error) {
    yield all([
      put(cuentaAhorroActions.fetchCuentasAhorroFailed({ error })),
      put(loadersActions.fetchLoadingCuentasAhorro({ isLoading: false })),
    ]);
  }
}

export function* fetchMovimientosCuentasAhorro(action) {
  try {
    // Se limpia store de contratacion anterior, si es que existe
    yield put(cuentaAhorroActions.cleanContratGenerar());
    const { ...body } = action.payload;

    yield put(loadersActions.fetchLoadingMovimientosCuentasAhorro({ isLoading: true }));

    const { data, error } = yield call(apiMovimientos.post, 'movimiento/v1/obtener', body);

    if (data) {
      yield put(
        cuentaAhorroActions.fetchMovimientosCuentasAhorroSuccess({
          movimientos: sortDates(data, 'fecha'),
        })
      );
    } else {
      yield put(cuentaAhorroActions.fetchMovimientosCuentasAhorroFailed({ error }));
    }

    yield put(loadersActions.fetchLoadingMovimientosCuentasAhorro({ isLoading: false }));
  } catch (error) {
    yield all([
      put(cuentaAhorroActions.fetchMovimientosCuentasAhorroFailed({ error })),
      put(loadersActions.fetchLoadingMovimientosCuentasAhorro({ isLoading: false })),
    ]);
  }
}

export function* fetchDetalleCuentasAhorro(action) {
  try {
    const { numeroCuenta } = action.payload;

    yield put(loadersActions.fetchLoadingDetalleCuentasAhorro({ isLoading: true }));

    const { data, error } = yield call(
      apiCuentaAhorro.get,
      // eslint-disable-next-line prefer-template
      'cuenta/detalle/v1/obtener/' + numeroCuenta
    );

    if (data) {
      yield put(
        cuentaAhorroActions.fetchDetalleCuentasAhorroSuccess({
          detalle: data,
        })
      );
    } else {
      yield put(cuentaAhorroActions.fetchDetalleCuentasAhorroFailed({ error }));
    }
    yield put(loadersActions.fetchLoadingDetalleCuentasAhorro({ isLoading: false }));
  } catch (error) {
    yield all([
      put(cuentaAhorroActions.fetchDetalleCuentasAhorroFailed({ error })),
      put(loadersActions.fetchLoadingDetalleCuentasAhorro({ isLoading: false })),
    ]);
  }
}

export function* fetchPDFMovimientosCuentaAhorro(action) {
  try {
    const { fechaInicio, fechaTermino, numeroCuenta, filtroDescripcion, ultimosMovimientos } =
      action.payload;
    yield put(cuentaAhorroActions.resetDataPDFMovimientos());

    const { data, error } = yield call(apiMovimientos.post, 'movimiento/v1/pdf/obtener', {
      fechaInicio,
      fechaTermino,
      numeroCuenta,
      filtroDescripcion,
      ultimosMovimientos,
    });

    if (data) {
      yield put(
        cuentaAhorroActions.fetchPDFMovimientosCuentaAhorroSuccess({
          data,
        })
      );
    } else if (error) {
      yield put(cuentaAhorroActions.fetchPDFMovimientosCuentaAhorroError());
    }
  } catch (err) {
    yield put(cuentaAhorroActions.fetchPDFMovimientosCuentaAhorroError());
  }
}

export function* fetchContratacionValidar() {
  try {
    // Se limpia store de contratacion anterior, si es que existe
    yield put(cuentaAhorroActions.cleanContratGenerar());
    // Se valida socio para contratacion
    yield put(loadersActions.fetchLoadingContratable({ isLoading: true }));
    const { data, error } = yield call(apiCuentaAhorro.get, 'cuenta/v1/contratacion/validar');
    if (data) {
      yield put(cuentaAhorroActions.fetchContratacionValidarSuccess({ data }));
      yield put(loadersActions.fetchLoadingContratable({ isLoading: false }));
    }

    if (error) {
      yield put(cuentaAhorroActions.fetchContratacionValidarError());
      yield put(loadersActions.fetchLoadingContratable({ isLoading: false }));
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchContratacionValidarError());
    yield put(loadersActions.fetchLoadingContratable({ isLoading: false }));
  }
}

export function* fetchGetContratosByCuenta(action) {
  try {
    const { track } = action.payload;
    const headers = { track };
    const { data } = yield call(apiCuentaAhorro.get, 'cuenta/pdf/borrador/listar', null, headers);
    if (data) {
      yield put(cuentaAhorroActions.fetchGetContratosByCuentaSuccess({ data }));
    } else {
      yield put(cuentaAhorroActions.fetchGetContratosByCuentaError());
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchGetContratosByCuentaError());
  }
}

export function* fetchGetPDFGenerar(action) {
  try {
    const { track, nameDocument } = action.payload;
    const headers = { track };
    const { data } = yield call(
      apiCuentaAhorro.get,
      `cuenta/pdf/borrador/generar/${nameDocument}`,
      null,
      headers
    );
    if (data) {
      downloadPDFBase64OtherTab(data, 'contrato');
    }
    // eslint-disable-next-line no-empty
  } catch (error) {}
}

export function* fetchDatosPersonales() {
  try {
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: true }));
    const { data, error } = yield call(apiPersona.post, 'datospersonales/v1/obtener', {});

    if (data) {
      yield put(cuentaAhorroActions.fetchDatosPersonalesSuccess({ data }));
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    }

    if (error) {
      yield put(cuentaAhorroActions.fetchDatosPersonalesError());
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchDatosPersonalesError());
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
  }
}

// TODO: se debe ingresar aca el envio del nombre ejecutivo
export function* fetchContratacionGenerar(action) {
  try {
    yield put(loadersActions.fetchLoadingContratado({ isLoading: true }));
    const { correo, producto, track, codEjecutivo, nombreEjecutivo } = action.payload;

    const { data, error } = yield call(
      apiCuentaAhorro.post,
      'cuenta/v1/contratacion/generar-externa',
      {
        validacionExterna: true,
        correo,
        producto,
        codEjecutivo,
        nombreEjecutivo,
      },
      { track }
    );

    if (data) {
      yield put(
        cuentaAhorroActions.fetchContratGenerarSuccess({
          data,
        })
      );
      yield put(loadersActions.fetchLoadingContratado({ isLoading: false }));
    }

    if (error) {
      yield put(cuentaAhorroActions.fetchContratGenerarError({ error }));
      yield put(loadersActions.fetchLoadingContratado({ isLoading: false }));
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchContratGenerarError({ errorCode: error }));
    yield put(loadersActions.fetchLoadingContratado({ isLoading: false }));
  }
}

export function* fetchContratacionIniciar(action) {
  try {
    const { producto } = action.payload;
    const { data, error } = yield call(apiCuentaAhorro.post, 'cuenta/v1/contratacion/iniciar', {
      producto,
    });
    if (data) {
      yield put(cuentaAhorroActions.fetchContratacionIniciarSuccess({ data }));
    } else {
      yield put(cuentaAhorroActions.fetchContratacionIniciarError({ error }));
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchContratacionIniciarError({ errorCode: error }));
  }
}

export function* fetchMigracionIniciar(action) {
  try {
    const { numeroCuenta } = action.payload;
    const { data, error } = yield call(apiCuentaAhorro.post, 'cuenta/v1/migracion/iniciar', {
      numeroCuenta,
    });

    if (data) {
      yield put(cuentaAhorroActions.fetchMigracionIniciarSuccess({ data }));
    } else {
      yield put(cuentaAhorroActions.fetchMigracionIniciarError({ error }));
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchMigracionIniciarError({ errorCode: error }));
  }
}

export function* getFactors() {
  yield put(cuentaAhorroActions.getFactorsPending());
  const { data, error } = yield apiCuentaAhorro.post('factor/v1/persona/obtener');
  if (error) {
    yield put(cuentaAhorroActions.getFactorsError());
  } else {
    yield put(cuentaAhorroActions.getFactorsSuccess(data));
  }
}

export function* initializeCpass() {
  // const accountName = account.payload;

  const firstName = yield select((state) => state.entities.persona.primerNombre);
  const secondName = yield select((state) => state.entities.persona.apellidoPaterno);

  const name = `${firstName} ${secondName}`;

  yield put(cuentaAhorroActions.initializeCpassPending());
  const track = yield select(
    (state) => state.entities.cuentasAhorro.contratacionIniciar.data?.track
  );
  const { error } = yield apiMsfactor.post(
    'web/desafio/passcoopeuch/v1/obtener',
    {
      idOperacion: 'CAH001',
      data: {
        nombreSocio: name,
        producto: 'Cuenta Ahorro',
        tipoCuenta: 'Ahorro',
      },
    },
    { track: `${track}-70` }
  );
  if (error) {
    yield put(cuentaAhorroActions.initializeCpassError());
  } else {
    yield put(cuentaAhorroActions.initializeCpassSuccess());
  }
}

export function* validateCpass() {
  // TODO : obtener payload con nombre de cuenta y enviar en polling

  const firstName = yield select((state) => state.entities.persona.primerNombre);
  const secondName = yield select((state) => state.entities.persona.apellidoPaterno);

  const name = `${firstName} ${secondName}`;

  const { isPolling } = yield select((state) => state.entities.cuentasAhorro.cpass);
  if (!isPolling) {
    return;
  }
  yield put(cuentaAhorroActions.validateCpassPending());
  yield delay(3000);
  const track = yield select(
    (state) => state.entities.cuentasAhorro.contratacionIniciar.data?.track
  );
  const { data, error } = yield apiMsfactor.post(
    'web/desafio/passcoopeuch/v1/validar',
    {
      idOperacion: 'CAH001',
      data: {
        nombreSocio: name,
        producto: 'Cuenta Ahorro',
        tipoCuenta: 'Ahorro',
      },
    },
    { track: `${track}-72` }
  );
  if (error) {
    yield put(cuentaAhorroActions.validateCpassError());
  } else {
    const code = data?.codigo;
    if (code === 25) {
      yield call(validateCpass);
    } else {
      yield put(cuentaAhorroActions.validateCpassSuccess(code));
    }
  }
}

export function* validarDesafioTcoo(action) {
  try {
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const { desafio, track, history, params } = action.payload;

    const optionalsHeaders = { track };

    const { data, error } = yield call(
      apiMsfactor.post,
      'factor/desafio/v1/coordenada/validar',
      desafio,
      optionalsHeaders
    );

    if (data) {
      yield put(
        cuentaAhorroActions.validarDesafioTcooResponse({
          validacionFactorDeSeguridad: data,
        })
      );

      if (history) {
        history.push(params);
      }
    } else {
      yield put(
        cuentaAhorroActions.validarUsingTcooFailed({
          type: 'tarjetaCoordenadasErrors',
          error: errTarjetaCoordenadasFactor[Number(error.code)] || defaultRequest,
          errorFactorLog: error,
        })
      );
    }

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  } catch (err) {
    yield put(
      // esta accion se debe importar de factoresSeguridad
      cuentaAhorroActions.validarUsingTcooFailed({
        type: 'tarjetaCoordenadasErrors',
        error: defaultRequest,
        errorFactorLog: err,
      })
    );
  }
}

export function* fetchExecutiveName(action) {
  try {
    const { code } = action.payload;
    // eslint-disable-next-line no-console
    // loader
    const { data, error } = yield call(
      apiCuentaAhorro.post,
      'cuenta/v1/contratacion/obtener/ejecutivo',
      {
        codigoejecutivo: code,
      }
    );
    if (data) {
      yield put(cuentaAhorroActions.fetchExecutiveNameSuccess({ data }));
    } else {
      yield put(cuentaAhorroActions.fetchExecutiveNameFailed({ error }));
    }
  } catch (error) {
    yield put(cuentaAhorroActions.fetchExecutiveNameFailed({ error }));
  }
}

export function* watchFetchDatosPersonales() {
  yield takeLatest(cuentaAhorroActions.fetchDatosPersonales.type, fetchDatosPersonales);
}

export function* watchFetchCuentasAhorro() {
  yield takeLatest(cuentaAhorroActions.fetchCuentasAhorro.type, fetchCuentasAhorro);
}

export function* watchFetchMovimientosCuentasAhorro() {
  yield takeLatest(
    cuentaAhorroActions.fetchMovimientosCuentasAhorro.type,
    fetchMovimientosCuentasAhorro
  );
}

export function* watchFetchDetalleCuentasAhorro() {
  yield takeLatest(cuentaAhorroActions.fetchDetalleCuentasAhorro.type, fetchDetalleCuentasAhorro);
}

export function* watchfetchPDFMovimientosCuentaAhorro() {
  yield takeLatest(
    cuentaAhorroActions.fetchPDFMovimientosCuentaAhorro.type,
    fetchPDFMovimientosCuentaAhorro
  );
}

export function* watchFetchContratacionValidar() {
  yield takeLatest(cuentaAhorroActions.fetchContratacionValidar.type, fetchContratacionValidar);
}

export function* watchFetchGetContratosByCuenta() {
  yield takeLatest(cuentaAhorroActions.fetchGetContratosByCuenta.type, fetchGetContratosByCuenta);
}

export function* watchFetchGetPDFGenerar() {
  yield takeLatest(cuentaAhorroActions.fetchGetPDFGenerar.type, fetchGetPDFGenerar);
}

export function* watchFetchContratacionGenerar() {
  yield takeLatest(cuentaAhorroActions.fetchContratacionGenerar.type, fetchContratacionGenerar);
}

export function* watchFetchContratacionIniciar() {
  yield takeLatest(cuentaAhorroActions.fetchContratacionIniciar.type, fetchContratacionIniciar);
}

export function* watchFetchMigracionIniciar() {
  yield takeLatest(cuentaAhorroActions.fetchMigracionIniciar.type, fetchMigracionIniciar);
}

export function* watchGetFactors() {
  yield takeLatest(cuentaAhorroActions.getFactors.type, getFactors);
}

export function* watchInitializeCpass() {
  yield takeLatest(cuentaAhorroActions.initializeCpass.type, initializeCpass);
}

export function* watchValidateCpass() {
  yield takeLatest(cuentaAhorroActions.validateCpass.type, validateCpass);
}

export function* watchValidarDesafioTcoo() {
  yield takeLatest(cuentaAhorroActions.validarDesafioTcoo.type, validarDesafioTcoo);
}

export function* watchfetchExecutiveName() {
  yield takeLatest(cuentaAhorroActions.fetchExecutiveName.type, fetchExecutiveName);
}

export default function* rootSaga() {
  yield spawn(watchFetchCuentasAhorro);
  yield spawn(watchFetchMovimientosCuentasAhorro);
  yield spawn(watchFetchDetalleCuentasAhorro);
  yield spawn(watchfetchPDFMovimientosCuentaAhorro);
  yield spawn(watchFetchContratacionValidar);
  yield spawn(watchFetchGetContratosByCuenta);
  yield spawn(watchFetchGetPDFGenerar);
  yield spawn(watchFetchDatosPersonales);
  yield spawn(watchFetchContratacionGenerar);
  yield spawn(watchFetchContratacionIniciar);
  yield spawn(watchFetchMigracionIniciar);
  yield spawn(watchGetFactors);
  yield spawn(watchInitializeCpass);
  yield spawn(watchValidateCpass);
  yield spawn(watchValidarDesafioTcoo);
  yield spawn(watchfetchExecutiveName);
}
