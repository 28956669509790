import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { WrapperSkeleton } from './styles';
import DashboardCard from '../../../organism/DashboardCard';

export const SkeletonResultado = () => (
  <React.Fragment>
    <WrapperSkeleton className="display-desktop">
      <div className="bmt-3 bmt-md-0">
        <Skeleton height={48} />
      </div>
      <div className="bmt-3 bmt-md-4">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="resutado-simulacion-card-body">
            <div className="row">
              <div className="col-md-4 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-8" />
              <div className="col-md-6 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-6" />
              <div className="col-md-4">
                <div className="row bpr-md-3 bpr-0">
                  <div className="col-md-1 bmb-4">
                    <Skeleton circle={true} height={24} width={24} />
                  </div>
                  <div className="col-md-11 bpl-3 bpr-5 bpt-1">
                    <Skeleton />
                  </div>
                  <div className="col-md-8 bmb-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-8 bpr-5 bmb-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-8">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row bpr-md-2 bpl-md-2 bpl-0 bpr-0">
                  <div className="col-md-1 bmb-4">
                    <Skeleton circle={true} height={24} width={24} />
                  </div>
                  <div className="col-md-11 bpl-3 bpr-5 bpt-1">
                    <Skeleton />
                  </div>
                  <div className="col-md-8 bmb-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-8 bpr-5 bmb-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-8">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row bpl-md-3 bpl-0">
                  <div className="col-md-1 bmb-4">
                    <Skeleton circle={true} height={24} width={24} />
                  </div>
                  <div className="col-md-11 bpl-3 bpr-5 bpt-1">
                    <Skeleton />
                  </div>
                  <div className="col-md-8 bmb-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-8 bpr-5 bmb-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                  <div className="col-md-8">
                    <Skeleton />
                  </div>
                  <div className="col-md-4">
                    <Skeleton />
                  </div>
                </div>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
        <div className="bmt-4">
          <div className="row">
            <div className="col-md-6 skeleton-center-vertical">
              <Skeleton width={20} className="bmr-2" />
              <Skeleton width={50} />
            </div>
            <div className="col-md-6 btext-right">
              <Skeleton width={186} height={51} />
            </div>
          </div>
        </div>
      </div>
    </WrapperSkeleton>
    <WrapperSkeleton className="display-mobile">
      <div className="bmt-3 bmt-md-0 bmb-3">
        <Skeleton height={72} />
      </div>
      <div>
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="resutado-simulacion-card-body">
            <div className="row">
              <div className="col-xs-12 bmb-5">
                <Skeleton />
              </div>
              <div className="col-xs-12 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-8 bmb-4">
                <Skeleton />
              </div>
              <div className="col-xs-4" />
              <div className="row w-100 m-0 bpt-4">
                <div className="col-xs-1">
                  <Skeleton circle={true} height={24} width={24} />
                </div>
                <div className="col-xs-8 bpl-3 skeleton-center-vertical">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
                <div className="col-xs-7 bmb-4 bmt-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4 bmt-4">
                  <Skeleton />
                </div>
                <div className="col-xs-7 bmb-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4">
                  <Skeleton />
                </div>
                <div className="col-xs-7 bmb-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4">
                  <Skeleton />
                </div>
              </div>
              <div className="row w-100 m-0 bpt-4">
                <div className="col-xs-1">
                  <Skeleton circle={true} height={24} width={24} />
                </div>
                <div className="col-xs-8 bpl-3 skeleton-center-vertical">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
                <div className="col-xs-7 bmb-4 bmt-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4 bmt-4">
                  <Skeleton />
                </div>
                <div className="col-xs-7 bmb-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4">
                  <Skeleton />
                </div>
                <div className="col-xs-7 bmb-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4">
                  <Skeleton />
                </div>
              </div>
              <div className="row w-100 m-0 bpt-4">
                <div className="col-xs-1">
                  <Skeleton circle={true} height={24} width={24} />
                </div>
                <div className="col-xs-8 bpl-3 skeleton-center-vertical">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
                <div className="col-xs-7 bmb-4 bmt-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4 bmt-4">
                  <Skeleton />
                </div>
                <div className="col-xs-7 bmb-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4">
                  <Skeleton />
                </div>
                <div className="col-xs-7 bmb-4">
                  <Skeleton />
                </div>
                <div className="col-xs-1" />
                <div className="col-xs-4">
                  <Skeleton />
                </div>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
        <div className="bmt-3">
          <div className="row">
            <div className="col-xs-12">
              <Skeleton height={51} />
            </div>
            <div className="col-xs-3" />
            <div className="col-xs-6 bmt-4">
              <Skeleton />
            </div>
            <div className="col-xs-3" />
          </div>
        </div>
      </div>
    </WrapperSkeleton>
  </React.Fragment>
);
