import React from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import Skeleton from 'react-loading-skeleton';

function SkeletonPagoComprobante() {
  return (
    <div className="modulos-container">
      <div className="modulo">
        <Skeleton height={47} />
      </div>

      <DashboardCard classes="modulo">
        <DashboardCard.Body classes="modulo-content">
          <div className="row start-xs mb-54">
            <div className="col-xs-12">
              <Skeleton height={10} width={174} />
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-12 mb-12">
              <Skeleton height={10} width={174} />
            </div>
            <div className="col-xs-12 mb-12">
              <Skeleton height={10} width={174} />
            </div>
            <div className="col-xs-12">
              <Skeleton height={10} width={444} />
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>

      <DashboardCard classes="modulo">
        <DashboardCard.Body classes="modulo-content">
          <div className="row start-xs mb-36">
            <div className="col-xs-12">
              <Skeleton height={10} width={174} />
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-12 mb-36">
              <Skeleton height={50} />
            </div>
            <div className="col-xs-12 mb-36">
              <Skeleton height={50} />
            </div>
            <div className="col-xs-12">
              <Skeleton height={50} />
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>

      <div className="row middle-xs mb-36">
        <div className="col-md-8 col-lg-8 col-sm-12 start-md start-lg center-xs">
          <div className="hide-on-desktop pl-8 pb-16">
            <Skeleton height={10} width={296} />
          </div>
          <Skeleton height={10} width={180} className="hide-on-mobile" />
        </div>

        <div className="col-md-4 col-lg-4 col-sm-12 end-md end-lg center-xs">
          <div className="hide-on-desktop pl-8 pb-16">
            <Skeleton height={48} width={296} />
          </div>
          <Skeleton height={48} width={236} className="hide-on-mobile" />
        </div>
      </div>
    </div>
  );
}

export default SkeletonPagoComprobante;
