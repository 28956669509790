import React, { useEffect, useState } from 'react';
import AlertModal from './../../atoms/AlertModal';
import Button from '@coopeuch-components/web/atoms/Button';
import logger from '../../../utils/logger'; 
import { registerGA } from '../../../utils/metrics';
import IlustracionMujer from './../../../assets/Ilustracion-mujer.svg'
import Promp from './../../../assets/prompt.png'
import styles from './styles';

const FTUClipboard = ({
  isFTUOpen,
  onHandleCloseFtu,
}) => {
  const [showModal, setShowModal] = useState(isFTUOpen && isFTUOpen)
  const [firstStep, setFirstStep] = useState(true)
  
  useEffect(() => {
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/transferencias/fondos/destinatario-nuevo',
      'eve-cat' : 'Mensaje',
    'eve-lab' : `Modal copiar y pegar datos de destinatario`
    });
  }, [])
  const nextStep = () => {
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/transferencias/fondos/destinatario-nuevo',
      'eve-cat' : 'Click',
     'eve-lab' : `Modal copiar y pegar datos de destinatario - Continuar`
    });
    setFirstStep(false)
  }

  const cerrarModal = () => {
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/transferencias/fondos/destinatario-nuevo',
      'eve-cat' : 'Click',
     'eve-lab' : `Modal Copiar y pegar datos de destintario - X`
    });
    setShowModal(false)
  }
  const onSubmit = () => {
    onHandleCloseFtu('CPDESAWEB')
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/transferencias/fondos/destinatario-nuevo',
      'eve-cat' : 'Click',
     'eve-lab' : `Modal Activar funcion - Entendido`
    });
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/transferencias/fondos/destinatario-nuevo',
      'eve-cat' : 'Mensaje',
     'eve-lab' : `Modal Activar funcion`
    });
    setShowModal(false)
  }
  return (
    <AlertModal
      showModal={showModal}
      onClose={cerrarModal} //handleClickCancelPasteData
      width={616}>
      {firstStep ? (
        <div style={styles.containerModal}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={IlustracionMujer} />
          </div>
          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 22, marginBottom: 40 }}>
              Copiar y pegar Datos de destinatario
          </div>
          <div style={styles.descriptionModal}>Los datos de tus destinatarios podrán ser copiados y pegados automáticamente para que puedas transferir más rápido y fácil.</div>
          <hr style={{marginTop: 30}} />
          <div style={styles.containerButtons}>
            <Button
                bgColor="rgb(0, 125, 183)"
                color="white"
                className="text-size-s"
                id="btn-dap"
                onClick={() => nextStep(false)}
              >
                Continuar
              </Button>
          </div>
        </div>
      ):(
        <div style={styles.containerModal}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={Promp} />
          </div>
          <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 22, marginBottom: 40 }}>
              ¿Como activarla?
          </div>
          <div style={styles.descriptionModal}>Haz clic en el botón <strong>"Permitir"</strong> que aparece en la parte superior de tu pantalla.</div>
          <hr style={{marginTop: 30}} />
          <div style={styles.containerButtons}>
            <Button
                bgColor="rgb(0, 125, 183)"
                color="white"
                className="text-size-s"
                id="btn-dap"
                onClick={onSubmit}
              >
                Entendido
              </Button>
          </div>
        </div>
      )}
    </AlertModal>
  );
};

export default FTUClipboard;