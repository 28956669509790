import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// UI & Componentes
import Link from '@coopeuch-components/web/atoms/Link';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

// styles
import { colors } from '../../../assets/styles';
import BaseFooter from './styles';

const imgPath = process.env.REACT_APP_CLOUDFRONT_IMAGES;
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Footer = ({ rightContent, onListenerHandle, links, id }) => {
  const logo = links.find((item) => item.tipo === 'LOGO_COOPEUCH');
  const tmpLinks = links.filter((item) => item.tipo !== 'LOGO_COOPEUCH');

  return (
    <BaseFooter className="bpl-3 bpl-md-5" id={id}>
      <div className="row w-100">
        <div className="col-md-2 col-sm-3 col-xs-12 footer__logo">
          <div className="mr-12">
            {logo && <img src={`${imgPath}${logo.imagen}`} alt="Coopeuch" className="footer-logo-style" />}
          </div>
        </div>
        <div className="col-xs">
          <div className="row text-right">
            <div className="footer__link start-sm between-xs" id="footer-links">
              {tmpLinks.map((link, index) => (
                <Fragment key={link.id}>
                  <Link
                    underline={false}
                    className="dark-grey decoration mr-32"
                    href={link.url}
                    size={14}
                    data-lab={link.titulo}
                    onClick={() => {
                      onListenerHandle(link.titulo);
                    }}
                    id={`link-footer-${link.id}`}
                  >
                    {link.titulo}
                  </Link>
                  {!(index % 2) ? (
                    <span className="hide-on-desktop dark-grey">|</span>
                  ) : null}
                </Fragment>
              ))}
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12 footer__callicon end-md">
              <div className="footer__callicon-content">
                <div className="mr-4">
                  {rightContent.icono && (
                    <LoadIcon
                      src={`${iconPath}${rightContent.icono}`}
                      size={30}
                      color={colors.black}
                    />
                  )}
                </div>
                <div>
                  <div className="text-size-s  dark-grey">{rightContent.titulo}</div>
                  <div>
                    {rightContent.descripcion && (
                      <>
                        <Link
                          className="text-size-s  text-bold dark-grey"
                          underline={false}
                          href={`tel:${rightContent.descripcion.split('-')[0]}`}
                          id="phone-number"
                          onClick={() => {
                            onListenerHandle('phone-number');
                          }}
                        >
                          {rightContent.descripcion.split('-')[0]}
                        </Link>
                        {' '}
                        <span className="text-size-s  dark-grey">
                          {rightContent.descripcion.split('-')[1]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseFooter>
  );
};

Footer.defaultProps = {
  links: PropTypes.array,
  onListenerHandle: PropTypes.func,
  id: PropTypes.string,
  rightContent: PropTypes.object,
};

Footer.defaultProps = {
  rightContent: {},
  links: [],
  id: '',
  onListenerHandle: null,
};
export default Footer;
