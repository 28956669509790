/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/activacionTarjetas';

// ------- SAGA ------ //

function fetchGetKitSuccess(state, action) {
  const { data } = action.payload;

  state.kitData.data = data;
  state.kitData.error = null;
}

function fetchGetKitError(state, action) {
  const { error } = action.payload;

  state.kitData.data = null;
  state.kitData.error = error;
}

function fetchGetKitResetSuccess(state) {
  state.kitData.data = null;
  state.kitData.error = null;
}

function fetchfetchValidarTarjetaCoordenadasSuccess(state, action) {
  console.log("VALIDA TC: SS", action);
  const { data } = action.payload;

  state.validateTC.data = data;
  state.validateTC.error = null;
}

function fetchfetchValidarTarjetaCoordenadasError(state, action) {
  console.log("VALIDA TC: EE", action);
  const { error } = action.payload;

  state.validateTC.data = null;
  state.validateTC.error = error;
}

function fetchValidarTarjetaCoordenadasResetSuccess(state) {
  state.validateTC.data = null;
  state.validateTC.error = null;
}

function fetchValidarTarjetaDebitoSuccess(state, action) {
  const { data } = action.payload;

  state.validateTD.data = data;
  state.validateTD.error = null;
}

function fetchValidarTarjetaDebitoError(state, action) {
  const { error } = action.payload;

  state.validateTD.data = null;
  state.validateTD.error = error;
}

function fetchSendSMSCodeSuccess(state, action) {
  const { data } = action.payload;

  state.smsCode.data = data;
  state.smsCode.error = null;
}

function fetchSendSMSCodeError(state, action) {
  const { error } = action.payload;

  state.smsCode.data = null;
  state.smsCode.error = error;
}

function fetchSendSMSCodeResetSuccess(state) {
  state.smsCode.data = null;
  state.smsCode.error = null;
}

function fetchValidateSMSCodeSuccess(state, action) {
  const { data } = action.payload;

  state.validateSMS.data = data;
  state.validateSMS.error = null;
}

function fetchValidateSMSCodeError(state, action) {
  const { error } = action.payload;

  state.validateSMS.data = null;
  state.validateSMS.error = error;
}

function fetchValidateSMSCodeResetSuccess(state) {
  state.validateSMS.data = null;
  state.validateSMS.error = null;
}

function fetchDownloadComprobanteSuccess(state) {
  state.downloadPDF.error = null;
}

function fetchDownloadComprobanteError(state, action) {
  const { error } = action.payload;
  state.downloadPDF.error = error;
}

function fetchGetKitBannerSuccess(state, action) {
  state.kitDataBanner.data = action.payload;
  state.kitDataBanner.error = null;
}

function fetchGetKitBannerError(state, action) {
  const { error } = action.payload;

  state.kitDataBanner.data = null;
  state.kitDataBanner.error = error;
}

function fetchIsEnabledPassCoopeuchSuccess(state, action) {
  state.availablePassCoopeuch.data = action.payload;
  state.availablePassCoopeuch.error = null;
}

function fetchIsEnabledPassCoopeuchError(state, action) {
  state.availablePassCoopeuch.data = null;
  state.availablePassCoopeuch.error = action.payload;
}

function fetchIsEnabledPassCoopeuchResetSuccess(state) {
  state.availablePassCoopeuch.data = null;
  state.availablePassCoopeuch.error = null;
}

function fetchSolicitarPassCoopeuchSuccess(state, action) {
  state.solicitarPassCoopeuch.data = action.payload;
  state.solicitarPassCoopeuch.error = null;
}

function fetchSolicitarPassCoopeuchError(state) {
  state.solicitarPassCoopeuch.data = null;
  state.solicitarPassCoopeuch.error = true;
}

function fetchSolicitarPassCoopeuchResetSuccess(state) {
  state.solicitarPassCoopeuch.data = null;
  state.solicitarPassCoopeuch.error = null;
}

function fetchValidarPassCoopeuchSuccess(state, action) {
  state.validarPassCoopeuch.data = action.payload;
  state.validarPassCoopeuch.error = null;
}

function fetchValidarPassCoopeuchError(state, action) {
  state.validarPassCoopeuch.data = null;
  state.validarPassCoopeuch.error = action.payload;
}

function fetchValidarPassCoopeuchResetSuccess(state) {
  state.validarPassCoopeuch.data = null;
  state.validarPassCoopeuch.error = null;
}

function fetchActivateKitCVSuccess(state, action) {
  state.activateKit.data = action.payload;
  state.activateKit.error = null;
}

function fetchActivateKitCVError(state, action) {
  state.activateKit.data = null;
  state.activateKit.error = action.payload;
}

function fetchActivateKitCVResetSuccess(state) {
  state.activateKit.data = null;
  state.activateKit.error = null;
}

function fetchSolicitarTarjetaCoordenadasSuccess(state, action) {
  const data = action.payload

  state.solicitarTarjetaCoordenadas.data = data;
  state.solicitarTarjetaCoordenadas.error = null;
}

function fetchSolicitarTarjetaCoordenadasError(state, action) {
  state.solicitarTarjetaCoordenadas.data = null;
  state.solicitarTarjetaCoordenadas.error = action.payload;
}

function fetchValidarCoordenadasSuccess(state, action) {
  const data = action.payload

  state.validarCoordenadas.data = data;
  state.validarCoordenadas.error = null;
}

function fetchValidarCoordenadasError(state, action) {
  state.validarCoordenadas.data = null;
  state.validarCoordenadas.error = action.payload;
}

function fetchValidarCoordenadasResetSuccess(state) {
  state.validarCoordenadas.data = null;
  state.validarCoordenadas.error = null;
}



const fetchGetKit = createAction('fetchGetKit');
const fetchValidarTarjetaCoordenadas = createAction('fetchValidarTarjetaCoordenadas');
const fetchSendSMSCode = createAction('fetchSendSMSCode');
const fetchValidateSMSCode = createAction('fetchValidateSMSCode');
const fetchGetKitReset = createAction('fetchGetKitReset');
const fetchValidarTarjetaCoordenadasReset = createAction('fetchValidarTarjetaCoordenadasReset');
const fetchSendSMSCodeReset = createAction('fetchSendSMSCodeReset');
const fetchValidateSMSCodeReset = createAction('fetchValidateSMSCodeReset');
const fetchDownloadComprobante = createAction('fetchDownloadComprobante');
const fetchGetKitBanner = createAction('fetchGetKitBanner');
const fetchIsEnabledPassCoopeuch = createAction('fetchIsEnabledPassCoopeuch');
const fetchSolicitarPassCoopeuch = createAction('fetchSolicitarPassCoopeuch');
const fetchIsEnabledPassCoopeuchReset = createAction('fetchIsEnabledPassCoopeuchReset');
const fetchSolicitarPassCoopeuchReset = createAction('fetchSolicitarPassCoopeuchReset');
const fetchValidarPassCoopeuch = createAction('fetchValidarPassCoopeuch');
const fetchValidarPassCoopeuchReset = createAction('fetchValidarPassCoopeuchReset');
const fetchActivateKitCV = createAction('fetchActivateKitCV');
const fetchActivateKitCVReset = createAction('fetchActivateKitCVReset');
const fetchSolicitarTarjetaCoordenadas = createAction('fetchSolicitarTarjetaCoordenadas');
const fetchValidarCoordenadas = createAction('fetchValidarCoordenadas');
const fetchValidarCoordenadasReset = createAction('fetchValidarCoordenadasResetSuccess');
const fetchValidarTarjetaDebito = createAction('fetchValidarTarjetaDebito')

// ------------- connection ------------- //

const activacionTarjetasReducer = createSlice({
  name: 'activacionTarjetas',
  initialState,
  reducers: {
    fetchGetKitSuccess,
    fetchGetKitError,
    fetchfetchValidarTarjetaCoordenadasSuccess,
    fetchfetchValidarTarjetaCoordenadasError,
    fetchSendSMSCodeSuccess,
    fetchSendSMSCodeError,
    fetchValidateSMSCodeSuccess,
    fetchValidateSMSCodeError,
    fetchGetKitResetSuccess,
    fetchValidarTarjetaCoordenadasResetSuccess,
    fetchSendSMSCodeResetSuccess,
    fetchValidateSMSCodeResetSuccess,
    fetchDownloadComprobanteSuccess,
    fetchDownloadComprobanteError,
    fetchGetKitBannerSuccess,
    fetchGetKitBannerError,
    fetchIsEnabledPassCoopeuchSuccess,
    fetchIsEnabledPassCoopeuchError,
    fetchSolicitarPassCoopeuchSuccess,
    fetchSolicitarPassCoopeuchError,
    fetchIsEnabledPassCoopeuchResetSuccess,
    fetchSolicitarPassCoopeuchResetSuccess,
    fetchValidarPassCoopeuchSuccess,
    fetchValidarPassCoopeuchError,
    fetchValidarPassCoopeuchResetSuccess,
    fetchActivateKitCVSuccess,
    fetchActivateKitCVError,
    fetchActivateKitCVResetSuccess,
    fetchSolicitarTarjetaCoordenadasSuccess,
    fetchSolicitarTarjetaCoordenadasError,
    fetchValidarCoordenadasSuccess,
    fetchValidarCoordenadasError,
    fetchValidarCoordenadasResetSuccess,
    fetchValidarTarjetaDebitoSuccess,
    fetchValidarTarjetaDebitoError
  },
});

export const activacionTarjetasActions = {
  ...activacionTarjetasReducer.actions,
  fetchGetKit,
  fetchValidarTarjetaCoordenadas,
  fetchSendSMSCode,
  fetchValidateSMSCode,
  fetchValidateSMSCodeReset,
  fetchGetKitReset,
  fetchValidarTarjetaCoordenadasReset,
  fetchSendSMSCodeReset,
  fetchDownloadComprobante,
  fetchGetKitBanner,
  fetchIsEnabledPassCoopeuch,
  fetchSolicitarPassCoopeuch,
  fetchIsEnabledPassCoopeuchReset,
  fetchSolicitarPassCoopeuchReset,
  fetchValidarPassCoopeuch,
  fetchValidarPassCoopeuchReset,
  fetchActivateKitCV,
  fetchActivateKitCVReset,
  fetchSolicitarTarjetaCoordenadas,
  fetchValidarCoordenadas,
  fetchValidarCoordenadasReset,
  fetchValidarTarjetaDebito
};

export default activacionTarjetasReducer.reducer;
