import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

  .comprobante-title-card-head h5 {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }

  .comprobante-simulacion-card-body {
    padding: 24px 32px 24px 32px;
  }

  .comprobante-desc-plazo {
    font-family: 'Ubuntu-Medium';
    color: #383638;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  .comprobante-desc-numero {
    color: #383638;
    font-size: 24px;
    font-family: 'Ubuntu-Medium';
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }

  .comprobante-desc-mail {
    color: #383638;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .comprobante-card-header {
    background-color: #001140;
    padding: 16px;
  }

  .comprobante-card-header-title {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .comprobante-card-header-value {
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }

  .card-impar {
    background-color: #f6f3f5;
  }
  .card-par {
    background-color: #ffffff;
  }

  .comprobante-card-text {
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .comprobante-card-list {
    padding: 12px 16px 12px 16px;
  }

  .icon-sello-container {
    align-self: center;
    text-align: right;
  }

  .boton-deposito-plazo {
    width: 236px;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 1rem 1rem 1rem;

    .comprobante-simulacion-card-body {
      padding: 16px;
    }

    .comprobante-card-header-value {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    .comprobante-card-list {
      padding: 12px 8px 12px 8px;
    }

    .boton-deposito-plazo {
      width: 100%;
    }

    .icon-alert svg {
      height: 18px;
      width: 18px;
      margin-top: 3px;
    }

    .icon-alert > .right-content {
      align-items: start;
    }

    .icon-sello-container {
      align-self: center;
      text-align: left;
    }

    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }
  }
`;

export const TitleWrapper = styled.div`
  .simulador-icon {
    display: flex;
    align-items: center;
  }

  .simulador-icon svg {
    height: 24px;
    width: 24px;
  }
`;

export const Opinion = styled.div`
  width: 48px;
  height: 168px;
  position: fixed;
  z-index: 3;
  top: 39%;
  right: 0;
  text-align: right;
  border-radius: 4px 0 0 4px;
  background-color: #007db7;
  text-align: center;
  display: inline-flex;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
  cursor: pointer;

  .comprobante-opinion-text {
    writing-mode: vertical-rl;
    transform: rotate(0.5turn);
    margin: auto;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 20px;
    cursor: pointer;
  }
`;

export const WrapperSkeleton = styled.div`
  .skeleton-center-vertical {
    align-self: center;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }
  }
`;

export default Wrapper;
