import styled from 'styled-components';

export const Wrapper = styled.div`
  .producto-dashboard-card {
    border-radius: 4px;
  }

  .producto-dashboard-card section {
    border-radius: 4px;
  }

  .producto-container {
    min-height: 178px;
    padding: 0px !important;
  }

  .producto-app-container {
    display: inline-flex;
  }

  .display-desktop {
    display: block;
  }

  .display-mobile {
    display: none;
  }

  .socio-container {
    padding: 16px 32px 16px 32px;
  }

  .socio-title {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .socio-subtitle {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .socio-button {
    width: 168px;
  }

  @media screen and (max-width: 1028px) {
    .producto-container {
      padding: 0px !important;
    }
    .display-desktop {
      display: none;
    }
    .display-mobile {
      display: block;
    }

    .producto-app-container {
      display: block;
      text-align: center;
    }
  }
`;

export const Image = styled.div`
  border-radius: 4px;
  height: 178px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  background-position-x: -172px;

  @media screen and (max-width: 1028px) {
    background-position-x: 43%;
  }
`;

export default Wrapper;
