import styled from 'styled-components';

const ExpandContent = styled.div`
  align-items: center;
  padding: 0 18px;

  h5 {
    font-size: 18px;
    margin: 0 10px;
    width: 75%;
  }

  ul {
    list-style: none;
    line-height: 26px;
    margin-right: 24px;

    li {
      padding-bottom: 10px;
    }

    div {
      padding-bottom: 8px;
      padding-left: 12px;
    }
  }
`;

export default ExpandContent;
