import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';

import { formatCurrency } from '../../../../utils/functions';
import { dateSlash } from '../../../../utils/dates';

const MobileRender = (props) => {
  const { fechaVencimiento, fechaPago, numeroDividendo, montopagoclp } = props;

  return (
    <div>
      <div className="row between-xs black text-size-s">
        <Typography tag="p" fontSize={16}>
          Fecha de vencimiento
        </Typography>
        <Typography fontSize={14} tag="p">
          {dateSlash(fechaVencimiento)}
        </Typography>
      </div>
      <div className="row between-xs black">
        <Typography tag="p" fontSize={16}>
          Fecha Pago
        </Typography>
        <Typography tag="p" fontSize={14} className="mobile--movement-historial--description">
          {dateSlash(fechaPago)}
        </Typography>
      </div>
      <div className="row between-xs black">
        <Typography tag="p">Dividendo Pagado</Typography>
        <Typography tag="p" fontSize={14}>
          {numeroDividendo}
        </Typography>
      </div>
      <div className="row between-xs black">
        <Typography tag="p">Monto</Typography>
        <Typography tag="p" fontSize={14}>
          {formatCurrency(montopagoclp)}
        </Typography>
      </div>
    </div>
  );
};

MobileRender.propTypes = {
  fechaVencimiento: PropTypes.string,
  fechaPago: PropTypes.string,
  numeroCuota: PropTypes.number,
  montoTotalPagado: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

MobileRender.defaultProps = {
  fechaVencimiento: '',
  fechaPago: '',
  numeroCuota: '',
  montoTotalPagado: 0,
};

export default MobileRender;
