import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

  .button-cambiar {
    font-weight: bold;
    text-decoration: underline;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .simulacion-title-card-head h5 {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }

  .detalle-simulacion-body-card {
    padding: 24px 32px 24px 32px;
  }

  .detalle-simulacion-text-title {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    font-family: 'Ubuntu-Medium';
  }

  .detalle-card-header {
    background-color: #001140;
    padding: 16px;
  }

  .detalle-card-header-title {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .detalle-card-header-value {
    color: #ffffff;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 40px;
  }
  .detalle-card-list {
    padding: 12px 16px 12px 16px;
  }
  .card-impar {
    background-color: #f6f3f5;
  }
  .card-par {
    background-color: #ffffff;
  }

  .title-bold {
    font-weight: bold;
  }

  .detalle-card-text {
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .button-return {
    font-weight: bold;
    width: 187px;
    text-align: left;
  }

  .button-continue {
    min-width: 186px;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 1rem 1rem 1rem;

    .detalle-simulacion-body-card {
      padding: 16px;
    }

    .detalle-card-header-title {
      color: #ffffff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
    }

    .detalle-card-header-value {
      color: #ffffff;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    .detalle-card-list {
      padding: 12px 8px 12px 8px;
    }

    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }

    .button-continue {
      width: 100%;
    }

    .button-return {
      margin-left: 35%;
    }

    .icon-alert svg {
      height: 18px;
      width: 18px;
      margin-top: 3px;
    }

    .icon-alert > .right-content {
      align-items: start;
    }
  }
`;

export const TitleWrapper = styled.div`
  .simulador-icon {
    display: flex;
    align-items: center;
  }

  .simulador-icon svg {
    height: 24px;
    width: 24px;
  }
`;

export default Wrapper;
