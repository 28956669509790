import React from 'react';
import { Link } from 'react-router-dom';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import DashboardCard from '../../../../organism/DashboardCard';

import Wrapper, { SuccessMessage } from './styles';
import { capitalize } from '../../../../../utils/functions';
import { dateFormat } from '../../../../../utils/dates';

import { comprobanteTexts } from '../../../../../containers/BloqueoTarjetas/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const revisaTusDatos = (
  <Link className="style-alert" to="/ecd/mi-perfil">
    {comprobanteTexts.CHECK_DATA}
  </Link>
);

const ComprobanteTemplate = ({
  tarjetaSeleccionada,
  handleReturnHome,
  handleReturnCuentaVista,
  datosTarjeta,
  correo,
}) => {
  return (
    <Wrapper>
      <div className="bmb-md-4 bmb-3">
        <SuccessMessage show>
          <div className="row">
            <div className="success-icon succes-message-icon-center">
              <LoadIcon src={`${iconPath}success_circle_check.svg`} size={20} />
            </div>
            <div className="success-text">
              <label>
                {datosTarjeta?.resultado === 2 ? (
                  <>
                    {comprobanteTexts.MESSAGE_BLOQUEO_DEFINITIVO}
                    {revisaTusDatos}
                  </>
                ) : (
                  comprobanteTexts.TOAST_MESSAGE
                )}
              </label>
            </div>
          </div>
        </SuccessMessage>
      </div>
      <div className="bmb-md-4 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Head title={comprobanteTexts.TITLE_COMPROBANTE} borderBottom="complete" />
          <DashboardCard.Body classes="bloqueo-definitivo-title">
            <div className="row">
              <div className="col-md-10 col-xs-12 bpr-md-4">
                <div className="bmb-3">
                  <label className="comprobante-desc">
                    {tarjetaSeleccionada?.tipo === 'TC'
                      ? comprobanteTexts.SUBTITLE_COMPROBANTE_TARJETA_COORDENADAS
                      : comprobanteTexts.SUBTITLE_COMPROBANTE_TARJETA_DEBITO}
                  </label>
                </div>
                <div className="bmb-2">
                  <label className="comprobante-titular">
                    {comprobanteTexts.PREFIX_NUM}
                    {tarjetaSeleccionada?.numero} {tarjetaSeleccionada?.owner}
                  </label>
                </div>
                <div>
                  <label className="comprobante-mail">
                    {tarjetaSeleccionada?.tipo === 'TC'
                      ? comprobanteTexts.SUBTITLE_CORREO_TARJETA_COORDENADAS
                      : comprobanteTexts.SUBTITLE_CORREO_TARJETA_DEBITO}
                    <b>{correo}</b>.
                  </label>
                </div>
              </div>
              <div className="col-md-2 col-xs-12 bmt-2 bmt-md-0">
                <LoadIcon src={`${iconPath}icon_sello.svg`} className="icon-sello" />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmb-md-4 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Head title={comprobanteTexts.TITLE_DETALLE} borderBottom="complete" />
          <DashboardCard.Body classes="bloqueo-definitivo-title">
            <div>
              <div className="row bloqueo-row bloqueo-impar">
                <div className="col-md-6 col-xs-12">
                  <label className="bloqueo-text-row">{comprobanteTexts.TARJETA}</label>
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="bloqueo-text-row">{tarjetaSeleccionada?.descripcion}</label>
                </div>
              </div>
            </div>
            <div>
              <div className="row bloqueo-row">
                <div className="col-md-6 col-xs-12">
                  <label className="bloqueo-text-row">{comprobanteTexts.NUMERO_TARJETA}</label>
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="bloqueo-text-row">
                    {comprobanteTexts.PREFIX_NUM}
                    {tarjetaSeleccionada?.numero}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className="row bloqueo-row bloqueo-impar">
                <div className="col-md-6 col-xs-12">
                  <label className="bloqueo-text-row">{comprobanteTexts.FECHA_BLOQUEO}</label>
                </div>
                <div className="col-md-6 col-xs-12">
                  <label className="bloqueo-text-row">
                    {capitalize(dateFormat(datosTarjeta?.fechaBloqueo))}
                  </label>
                </div>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="row bmb-md-4 bmb-3">
        <div className="col-md-6 col-xs-12 return-bloqueo-center">
          <Button
            color="#EC1B2B"
            className="none-underline middle-items w-100"
            asTextLink
            onClick={handleReturnHome}
          >
            <div className="row middle-xs">
              <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} className="mr-8 back--icon" />
              <span>{comprobanteTexts.RETURN_HOME}</span>
            </div>
          </Button>
        </div>
        <div className="col-md-6 col-xs-12 text-right">
          <div className="bloqueo-definitivo-button-container">
            <Button
              bgColor="#E81E2B"
              color="white"
              id="btn"
              className="button-style-bloqueo"
              onClick={handleReturnCuentaVista}
            >
              {comprobanteTexts.RETURN_CUENTA_VISTA}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ComprobanteTemplate;
