import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Error from '../../../components/atoms/Error';
import CuotasParticipacionTemplate from '../../../components/templates/CuotaParticipacion/Resumen';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

const URL_PAGO_ONLINE = 'https://pago-online.coopeuch.cl/';
const CODIGO_CUENTAS = ['VI01', 'VI03', 'VI06'];

const initState = {
  tipoPago: null,
  pagoAutomatico: null,
  aporte: null,
  cuotasPagadas: null,
  cuotasPorMes: null,
  cuotasPendientes: null,
  valorCuota: null,
  montoPendiente: null,
  hasAccounts: false,
  cuentasChecked: false,
  cuotasChecked: false,
  error: false,
  loadingData: true,
  showModificacion: false,
  showButton: false,
  baseRut: null,

};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount = () => {
    const { cuotas, cuentasDisponibles, persona } = this.props;
    if (cuotas.success !== cuotas.error) this.checkCuotasResponse(cuotas);
    if (cuentasDisponibles.success !== cuentasDisponibles.error)
      this.checkCuentaVistaResponse(cuentasDisponibles);
    this.setState({
      baseRut: String((persona?.rut || '').replace('-', ''))
    })
  };

  componentDidUpdate = (prevProps) => {
    const { cuotas, cuentasDisponibles } = this.props;
    const { cuentasChecked, cuotasChecked, loadingData } = this.state;
    if (cuotas !== prevProps.cuotas) {
      this.checkCuotasResponse(cuotas);
    }
    if (cuentasDisponibles !== prevProps.cuentasDisponibles) {
      this.checkCuentaVistaResponse(cuentasDisponibles);
    }
    if (cuentasChecked && cuotasChecked && loadingData) {
      /**
       * Textos de la alerta se definen de nuevo para evitar enviar tags html en el mensaje a gtm
       */
      let msg;
      if (
        ['PA', 'PL', 'WE'].includes(this.state.tipoPago) ||
        (this.state.tipoPago === 'CA' && !this.state.hasAccounts)
      ) {
        switch (this.state.tipoPago) {
          case 'CA':
            msg =
              'Te invitamos a realizar el pago de tus cuotas por medio de pago online de forma rápida y segura a través de Webpay';
            break;
          case 'PA':
            msg =
              'Estás suscrito a Pago Automático de Cuentas (PAC). Tus Cuotas serán descontadas automáticamente.';
            break;
          case 'PL':
            msg =
              'Estás suscrito a pago por planilla. Tus cuotas de participación serán descontadas de tu liquidación de sueldo.';
            break;
          case 'WE':
            msg =
              'Estás suscrito a Pago Automático de Cuentas (PAC). Tus Cuotas serán descontadas automáticamente.';
            break;
          default:
            msg = '';
        }
      }

      registerGA({
        event: 'vpv-privado',
        page: '/tef/mis-productos/cuota-participacion',
        producto: `${!!this.state.cuotasPendientes ? 'pendiente' : 'pagado'}`,
        valoracion: msg,
        monto: this.state.aporte,
      });
      this.setState({ loadingData: false });
    }
  };



  setShowModificacion = (state) => {
    this.setState({
      showModificacion: state
    })
  }
  checkCuentaVistaResponse = (response) => {
    if (response.error) {
      this.setGlobalError();
    } else {
      this.checkCuentas(response.data);
    }
  };

  checkCuotasResponse = (response) => {
    if (response.success) {
      this.setDataCuotas(response.data);
    } else {
      this.setGlobalError();
    }
  };

  setDataCuotas = (cuotas) => {
    this.setState({
      tipoPago: cuotas.pactadas.codMedioPago,
      pagoAutomatico: cuotas.pactadas.codMedioPago !== 'CA',
      aporte: cuotas.pagadas.montoTotalCuotasPagadas,
      cuotasPagadas: cuotas.pagadas.totalCuotasPagadas,
      cuotasPorMes: cuotas.pactadas.cuotasPactadas,
      cuotasPendientes: cuotas.pendientes.cuotasPendientes,
      valorCuota: cuotas.valorCuota,
      montoPendiente: cuotas.pendientes.cuotasPendientes * cuotas.valorCuota,
      cuotasChecked: true,
    });
  };

  checkCuentas = (cuentas) => {
    const cuentasPago = cuentas.filter(
      (cuenta) => cuenta.estadoCuenta === 'A' && CODIGO_CUENTAS.includes(cuenta.codigoProducto)
    );
    this.setState({ hasAccounts: !!cuentasPago.length, cuentasChecked: true });
  };

  toPagarCuotas = () => {
    const { history } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/cuota-participacion',
      'eve-cat': 'Click',
      'eve-lab': 'Pagar Cuotas',
    });
    history.push('/ecd/productos/cuota-de-participacion/pago');
  };

  toSugerencia = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/mis-productos/cuota-participacion',
      'eve-cat' : 'Click',
      'eve-lab' : 'Modificar cuotas pactadas'
    });
    logger(null, {
      rut: this.state.baseRut.slice(0, -1),
      digito_verificador: this.state.baseRut.slice(-1),
      accion: 'CP_VER_MODIFICACION',
      informacion_adicional: {
        origen: 'mi_cuota',
        tienecampania: false
      },
    });
  }

  toModificacion = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/mis-productos/cuota-participacion',
      'eve-cat' : 'Click',
      'eve-lab' : 'Modificar cuotas pactadas'
    });
    logger(null, {
      rut: this.state.baseRut.slice(0, -1),
      digito_verificador: this.state.baseRut.slice(-1),
      accion: 'CP_VER_MODIFICACION',
      informacion_adicional: {
        origen: 'mi_cuota',
        tienecampania: false
      },
    });
  }

  toPagoOnline = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/cuota-participacion',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a pago online',
    });
    window.open(URL_PAGO_ONLINE, '_blank').focus();
  };

  setGlobalError = () => {
    this.setState({ error: true });
    registerGA({
      event: 'eve',
      'eve-acc': `/tef/mis-productos/cuota-participacion`,
      'eve-cat': 'Error',
      'eve-lab': 'Servicio no disponible',
    });
  };

  render() {
    const {
      loadingData,
      aporte,
      cuotasPagadas,
      cuotasPorMes,
      cuotasPendientes,
      montoPendiente,
      valorCuota,
      tipoPago,
      hasAccounts,
      pagoAutomatico,
      error,
      showButton,
    } = this.state;
    const payButton = {
      show: !pagoAutomatico && !!cuotasPendientes,
      action: hasAccounts ? this.toPagarCuotas : this.toPagoOnline,
      title: hasAccounts ? 'Pagar Cuotas' : 'Ir a pago online',
    };
    return (
      <>
        {error ? (
          <Error className="bmt-3 bmt-md-4" />
        ) : (
          !this.state.showModificacion ?
          <CuotasParticipacionTemplate
            {...{
              loadingData,
              aporte,
              cuotasPagadas,
              cuotasPorMes,
              cuotasPendientes,
              montoPendiente,
              valorCuota,
              tipoPago,
              hasAccounts,
              payButton,
              showButton,
            }}
            setShowModificacion={this.setShowModificacion}
            toSugerencia={this.toSugerencia}
            toModificacion={this.toModificacion}
          />
          :
          null
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  cuentasDisponibles: store.entities.cuotaParticipacion.cuentasDisponibles,
  cuotas: store.entities.cuotaParticipacion.cuotas,
  persona: store.entities.persona,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchCuentasVista(props) {
    dispatch(cuotaParticipacionActions.fetchCVCuotasParticipacion(props));
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
