import {call, put, spawn, takeLatest} from 'redux-saga/effects';
import api from '../../api';
import {avanceTarjetaCreditoActions} from "../../../application/actions/avanceTarjetaCredito";
import {loadersActions} from "../../../application/actions/ui_loaders";

const apiAvanceTc = api(process.env.REACT_APP_AVANCE_TC_URL_ENDPOINT);

export function* fetchValidarAptoAvance() {

  try {

    yield put(loadersActions.fetchLoadingValidarAptoAvance({isLoading: true}));

    const response = yield call(apiAvanceTc.post, '/ofertaicono/validar', {});
    const {data} = response;
    if (data) {
      yield put(avanceTarjetaCreditoActions.fetchValidarAptoAvanceSuccess({data}));
    } else {
      yield put(avanceTarjetaCreditoActions.fetchValidarAptoAvanceError({error: response}));
    }

    yield put(loadersActions.fetchLoadingValidarAptoAvance({isLoading: false}));

  } catch (error) {
    yield put(avanceTarjetaCreditoActions.fetchValidarAptoAvanceError({error}));
    yield put(loadersActions.fetchLoadingValidarAptoAvance({isLoading: false}));
  }
}

export function* watchFetchValidarAptoAvance() {
  yield takeLatest(avanceTarjetaCreditoActions.fetchValidarAptoAvance.type, fetchValidarAptoAvance);
}

export default function* rootSaga() {
  yield spawn(watchFetchValidarAptoAvance);
}
