import React from 'react';
import Iframe from '../../components/organism/Iframe';

const index = () => {
  const tokenLegado = localStorage.getItem('tokenLegado');
  const urlUpgradeMonedero = process.env.REACT_APP_UPGRADE_MONEDERO_URL;

  return (
    <Iframe
      tokenLegado={tokenLegado}
      urlIframe={urlUpgradeMonedero}
      height="1700px"
      params="isUM=true&origin=ecdPrivado"
    />
  );
};

export default index;