import React, { Component } from 'react';
import { connect } from 'react-redux';
import produce from 'immer';

import MigracionCuentaAhorroComprobanteTemplate from '../../../../components/templates/CuentaAhorro/Migracion/Comprobante';

class MigracionCuentaAhorroComprobante extends Component {
  state = { fechaMigracion: new Date(), correo: '' };

  componentDidMount() {
    const { history } = this.props;
    this.setState(
      produce((draft) => {
        draft.correo = history?.location?.state?.correo;
      })
    );
  }

  handleReturnCuentaAhorro = () => {
    const { history, migrarCuentaAhorro } = this.props;
    history.push({
      pathname: '/ecd/productos/cuenta-ahorro',
      state: { infoMigracion: true, numeroCuenta: migrarCuentaAhorro?.numeroCuenta },
    });
  };

  render() {
    const { migrarCuentaAhorro } = this.props;
    const { fechaMigracion, correo } = this.state;
    return (
      <MigracionCuentaAhorroComprobanteTemplate
        correo={correo}
        fechaMigracion={fechaMigracion}
        migrarCuentaAhorro={migrarCuentaAhorro}
        handleReturnCuentaAhorro={this.handleReturnCuentaAhorro}
      />
    );
  }
}

const mapStateToProps = ({ entities }) => ({
  migrarCuentaAhorro: entities.migracionCuentaAhorro.migrarCuentaAhorro,
});

export default connect(mapStateToProps)(MigracionCuentaAhorroComprobante);
