import Typography from '@coopeuch-components/web/atoms/Typography';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import { Button, Card, Grid } from '@design-system-coopeuch/web';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bonoEscolarActions } from '../../../../../../application/actions/bonoEscolar';
import dateFormatter from '../../../../../../application/core/formatters/dateFormatter';
import { formatRut } from '../../../../../utils/formatters';
import { registerGA } from '../../../../../utils/metrics';
import CardNadaPorAqui from '../../../../atoms/CardNadaPorAqui';
import Wrapper, { Container, HeaderDesktop, ItemDesktop, ItemMobile, Status } from './styles';

const TableBonos = ({ dataSolicitudes, toHome }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const listSolicitudes = dataSolicitudes?.data?.lista;
  const postulationType = {
    1: 'Ingreso',
    2: 'Ingreso',
    3: 'Titulación',
    4: 'Titulación',
  };
  const status = {
    1: 'Solicitud ingresada',
    2: 'En revisión',
    3: 'Documentos objetados',
    4: 'Aprobada-pendiente de pago',
    5: 'Pagada',
    6: 'Rechazada',
  };
  const statusColor = {
    1: '#32A734',
    2: '#C9A71C',
    3: '#C9A71C',
    4: '#32A734',
    5: '#32A734',
    6: '#CC284E',
  };
  const detail = {
    1: 'Tu solicitud ha sido ingresada con éxito',
    2: 'Estamos revisando tus documentos',
    4: 'Pronto realizaremos el abono en tu Cuenta Vista',
    5: 'Abono realizado en tu Cuenta Vista',
    6: 'Tu solicitud no cuenta con los requisitos para postular',
  };

  function onClickAttachDocuments(item) {
    dispatch(
      bonoEscolarActions.setRejectedDocuments({
        ...item,
        id: item.uuid,
        typeBono: `Bono de ${postulationType[item.idTipoPostulacion]}`,
        typeBeneficiario:
          item.idTipoPostulacion === 1 || item.idTipoPostulacion === 3 ? 'Para Mí' : 'Para mi hijo',
      })
    );
    history.push('/ecd/bono-escolar/documentos-objetados/documentacion');
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios/solicitudes',
      'eve-cat': 'Click',
      'eve-lab': 'Adjuntar documentos',
    });
  }

  if (!listSolicitudes) {
    return null;
  }
  return (
    <Wrapper>
      {listSolicitudes?.length <= 0 ? (
        <CardNadaPorAqui toHome={toHome} />
      ) : (
        <Card className="mb-5">
          <CardBox.Header>
            <Typography tag="p" fontSize={24} fontWeight="bold">
              Solicitudes de Bonos
            </Typography>
          </CardBox.Header>
          <HeaderDesktop className="d-none d-lg-block">
            <Grid row>
              <Grid col>Fecha</Grid>
              <Grid col>RUT Benef.</Grid>
              <Grid col>Tipo</Grid>
              <Grid col>Nº Seg.</Grid>
              <Grid col={3}>Estado</Grid>
              <Grid col={3}>Detalle</Grid>
            </Grid>
          </HeaderDesktop>
          {listSolicitudes.map((item) => (
            <Container key={item.uuid}>
              <ItemMobile className="d-lg-none">
                <Grid row className="justify-content-between">
                  <Grid col="auto">
                    <b>Fecha Solicitud</b>
                  </Grid>
                  <Grid col="auto">
                    {dateFormatter({
                      date: item.fechaCreacion,
                      splitter: '-',
                      yearPosition: 0,
                      monthPosition: 1,
                      dayPosition: 2,
                      outputFormat: 'dd/MM/yy',
                    })}
                  </Grid>
                </Grid>
                <Grid row className="pt-3 justify-content-between">
                  <Grid col="auto">
                    <b>RUT Beneficiario</b>
                  </Grid>
                  <Grid col="auto">{formatRut(item.rutBeneficiario)}</Grid>
                </Grid>
                <Grid row className="pt-3 justify-content-between">
                  <Grid col="auto">
                    <b>Tipo</b>
                  </Grid>
                  <Grid col="auto">{postulationType[item.idTipoPostulacion]}</Grid>
                </Grid>
                <Grid row className="pt-3 justify-content-between">
                  <Grid col="auto">
                    <b>Nº Seguimiento</b>
                  </Grid>
                  <Grid col="auto">{item.numeroSeguimiento}</Grid>
                </Grid>
                <Grid row className="pt-3 justify-content-between">
                  <Grid col="auto">
                    <b>Estado</b>
                  </Grid>
                  <Grid col="auto">
                    <Status color={statusColor[item.idEstado]} />
                    {status[item.idEstado]}
                  </Grid>
                </Grid>
                {item.idEstado === 3 ? (
                  <Button
                    variant="link"
                    color="secondary"
                    className="mt-3"
                    onClick={() => {
                      onClickAttachDocuments(item);
                    }}
                  >
                    Adjuntar documentos
                  </Button>
                ) : (
                  <Grid row className="pt-3 justify-content-between">
                    <Grid col={3}>
                      <b>Detalle</b>
                    </Grid>
                    <Grid col={9} className="text-right">
                      {detail[item.idEstado]}
                    </Grid>
                  </Grid>
                )}
              </ItemMobile>
              <ItemDesktop className="d-none d-lg-block">
                <Grid row className="align-items-center">
                  <Grid col>
                    {dateFormatter({
                      date: item.fechaCreacion,
                      splitter: '-',
                      yearPosition: 0,
                      monthPosition: 1,
                      dayPosition: 2,
                      outputFormat: 'dd/MM/yyyy',
                    })}
                  </Grid>
                  <Grid col>{formatRut(item.rutBeneficiario)}</Grid>
                  <Grid col>{postulationType[item.idTipoPostulacion]}</Grid>
                  <Grid col>{item.numeroSeguimiento}</Grid>
                  <Grid col={3}>
                    <Status color={statusColor[item.idEstado]} />
                    {status[item.idEstado]}
                  </Grid>
                  <Grid col={3}>
                    {item.idEstado === 3 ? (
                      <Button
                        variant="link"
                        color="secondary"
                        className="my-n2"
                        onClick={() => {
                          onClickAttachDocuments(item);
                        }}
                      >
                        Adjuntar documentos
                      </Button>
                    ) : (
                      detail[item.idEstado]
                    )}
                  </Grid>
                </Grid>
              </ItemDesktop>
            </Container>
          ))}
        </Card>
      )}
    </Wrapper>
  );
};

export default TableBonos;
