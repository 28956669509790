import Styled from 'styled-components';

export const Paragraph = Styled.p`
  font-size: ${({ size }) => `${size ? size : '16'}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft ? marginLeft : '0'}px`};
  letter-spacing: 0;
  line-height: 26px;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
`;

export const Wrapper = Styled.div`
  .alert-comprobante-contratacion-monedero {
    height: unset
  }
  .action-button-text {
    font-size: 16px;
  }
  .comprobante-card-text {
    display: flex;
    align-items: center;
  }
  .contract-action-button {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  .container_number{
    width: 40px;
    height:40px;
    border-radius:50px;
    border: 1px solid #007DB7;
    color: #007DB7;
    display: flex;
    .number{
      font-family:'Ubuntu-Regular';
      font-size:14px;
      align-self: center;
      margin: 0 auto;
    }
  }

  .container_number_bkg{
    width: 40px;
    height:40px;
    border-radius:50px;
    background-color:#007DB7;
    color:#fff;
    display: flex;
    .number{
      font-family:'Ubuntu-Regular';
      font-size:14px;
      align-self: center;
      margin: 0 auto;
    }
  }
.underline{
  text-decoration: underline;
}
.container_next{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media screen and (max-width: 820px) {
  .container_next{
    justify-content: center;
}
    }
`;

export const ActionButtons = Styled.div`
    color: #F42534;
    display: inline-flex;
    font-weight: bold;
    & > .comprobante-action {
      display: inline-flex;
      cursor: pointer;
    }
`;
export const StepsCol = Styled.div`
  display: flex;
  .center-icon {
    margin: 0 auto
  }
`;
export const StepsRow = Styled.div`
  border-bottom: 2px solid #F4F4F4;
  padding: 16px 24px !important;
  @media screen and (min-width: 64em) {
    padding: 16px 24px !important;
  }
  background-color: ${({ first }) => `${first ? '#F6F3F5' : 'white'}`};
  .right-icon {
    display: flex;
    align-items: flex-end;
  }
`;

export const CardBoxWrapper = Styled.div`
  .steps-card-box {
    section div:first-of-type {
      padding:0;
    }
  }
`;
