export const STATE_TYPE_USER_TEXT_ME = {
    EN_CURSO: {
        typeMessage: 'warning',
        description:
            'Estimado socio: Ya cuentas con una solicitud en curso, lo que no te permitirá volver a ingresar una postulación nueva bajo el mismo tipo de bono y RUT de beneficiario.',
    },
    PAGADO: {
        typeMessage: 'warning',
        description:
            'Estimado socio: Este bono ha sido otorgado anteriormente. Recuerda que este beneficio se otorga por una única vez.',
    },
};

export const STATE_TYPE_USER_TEXT_SON = {
    EN_CURSO: {
        typeMessage: 'warning',
        description:
            'Estimado socio: Ya cuentas con una solicitud en curso, lo que no te permitirá volver a ingresar una postulación nueva bajo el mismo tipo de bono y RUT de beneficiario.',
    },
    PAGADO: {
        typeMessage: 'warning',
        description:
            'Estimado socio: Este bono ha sido otorgado anteriormente. Si deseas continuar con la solicitud para otro hijo, debes ingresar un RUT diferente. Recuerda que este beneficio se otorga por una única vez para cada hijo.',
    },
};

