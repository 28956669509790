import { spawn, call, put, all, takeLatest } from 'redux-saga/effects';
import qs from 'querystring';
import { loginActions } from '../../../application/actions/ui_login';
import { userActions } from '../../../application/actions/user';
import getOS from '../../../ui/utils/getOS';
import { registerGA } from '../../../ui/utils/metrics';
import api from '../../api';
import apiLegado from '../../apiLegado';
import { refreshLegacyTokenOn } from '../legacyToken';
import { errMessages,ERROR_CODES_WITH_BUTTON,ERROR_MESSAGES } from '../../../ui/utils/constantes/loginConstant';
const apiRequest = api(process.env.REACT_APP_AUTH_API);
const apiRequestLegado = apiLegado(process.env.REACT_APP_TOKEN_LEGADO);



function handleLoginError(error) {
  if (error.code === ERROR_CODES_WITH_BUTTON) {
    const errorWithButton = {
      id: error.code,
      title: error.title,
      message: error.message,
      link: { name: error.boton, url: error.urlRedireccionSitio },
    };
    return { type: 'button', error: errorWithButton };
  } else {
    const label = ERROR_MESSAGES[error.code] || 'Servicio no disponible';
    registerGA({
      event: 'eve',
      'eve-acc': '/login',
      'eve-cat': 'Error',
      'eve-lab': label,
    });
    return { type: 'standard', error: errMessages[Number(error.code)] || errMessages.default };
  }
}

function* fetchLogin(action) {
  try {
    const { rut, password, passwordPlain, remember, captcha, versionCaptcha } = action.payload;
    const { data, error } = yield call(apiRequest.post, 'token/v2/obtener', {
      canal: 'WEB',
      usuario: rut.replace(/[^\dk]/g, ''),
      clave: password,
      captcha,
      versionCaptcha,
      ...getOS(),
    });

    if (!remember) localStorage.removeItem('remember-rut');

    if (error) {
      const { type, error: loginError } = handleLoginError(error);
      if (type === 'button') {
        yield put(loginActions.fetchUserFailed(loginError));
      } else {
        yield put(loginActions.fetchUserFailed(loginError));
      }
    } else {
      yield call(handleSuccessfulLogin, { rut, passwordPlain, data, remember });
    }
  } catch (err) {
    console.log(err.message, 'fetchLogin');
  }
}

function* handleSuccessfulLogin({ rut, passwordPlain, data, remember }) {
  const param = qs.stringify({
    username: rut.replace('-', ''),
    password: passwordPlain,
    grant_type: 'password',
    client_id: 'restClient',
    client_secret: 'restSecret',
    scope: 'webPrivada',
  });

  const resp = yield call(apiRequestLegado.post, 'token', param);

  if (resp.err) {
    localStorage.removeItem('tokenLegado');
  } else if (resp?.data) {
    localStorage.setItem('tokenLegado', resp?.data.access_token);
    refreshLegacyTokenOn(resp?.data.access_token);
  }

  localStorage.setItem('token', data.token);
  sessionStorage.setItem('ecdtoken', data.token);
  localStorage.setItem('user-rut', rut);
  localStorage.setItem('login-timedate', new Date());

  if (remember) localStorage.setItem('remember-rut', rut);

  yield all([put(loginActions.fetchUserSuccess()), put(userActions.fetchUserSuccess({ rut }))]);
}

function* fetchLogout() {
  const token = sessionStorage.getItem('ecdtoken');

  yield call(apiRequest.post, 'token/v1/abortar', { token });

  yield all([
    put({ type: 'CLEAR_STORE' }),
    localStorage.removeItem('token'),
    sessionStorage.removeItem('ecdtoken'),
    localStorage.removeItem('user-rut'),
    localStorage.removeItem('login-timedate'),
  ]);
}

function* watchFetchLogin() {
  yield takeLatest(loginActions.fetchUserAuth.type, fetchLogin);
}

function* watchFetchLogout() {
  yield takeLatest(loginActions.fetchLogoutUser.type, fetchLogout);
}

export default function* rootSaga() {
  yield spawn(watchFetchLogin);
  yield spawn(watchFetchLogout);
}