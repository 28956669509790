/* eslint-disable no-use-before-define */

export function refreshLegacyTokenOn(token) {
  try {
      this.timer = setInterval(() => {
          _refreshLegacyToken(token)
      }, 30000);
  } catch (err) {
  }
}


export function refreshLegacyTokenOnOff() {
  clearInterval(this.timer);
}



function _refreshLegacyToken(token) {
  try {
      fetch(process.env.REACT_APP_TOKEN_LEGADO_REFRESH,{
          method: 'POST',
          headers:{ 'Authorization': `Bearer ${token}`,}
      })
      .then(response => response.json())
      .then(data => console.log(data));
  } catch (error) {
  }
}