import styled from 'styled-components';

const Wrapper = styled.div`
  p,
  label {
    color: #383638 !important;
  }

  .error-card-title {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }

  .error-card-desc {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
    text-align: center;
  }

  .card-error-container {
    padding: 24px 32px 24px 32px;
  }

  .text-button-style {
    width: 296px;
  }

  .text-button-container {
    text-align: center;
  }

  @media screen and (max-width: 1024px) {
    .card-error-container {
      padding: 16px;
    }

    .text-button-style {
      width: 100%;
    }
  }
`;

export default Wrapper;
