import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Card from '@coopeuch-components/web/atoms/Card';
import StepsActions from '../../organism/Stepper/StepsActions';

import { SinSolicitudes, Text } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardNadaPorAqui = ({ toHome }) => {

    return (
        <>
            <Card className="mt-3">
                <div className="center-xs">
                    <div className="row center-xs mt-3">
                        <SinSolicitudes className="mr-16">
                            <LoadIcon src={`${iconPath}copywriting.svg`} size={60} className="ml-10" color="#D3D3D3" />
                        </SinSolicitudes>
                    </div>
                    <Text textSize={18} weight="bold" className='mt-3'>
                        <span className='hide-on-mobile'><b>¡Nada por aquí! No presentas solicitudes</b></span>
                        <span className=' hide-on-desktop'><b>¡Nada por aquí! <br /> No presentas solicitudes</b></span>
                    </Text>
                    <div className="col-xs-12 col-md-8 col-md-offset-2 mt-1 mb-4">
                        <Text textSize={14} weight="normal" >
                            Al completar el proceso de postulación, podrás visualizar tus <br /> solicitudes de bono aquí.
                        </Text>
                    </div>
                </div>
            </Card>
            <StepsActions
                id="cardNadaporAqui"
                className="bmt-3 mt-24"
                backText="Volver"
                backAction={toHome}
                hideNextAction={true}
            />
        </>
    );

};

export default CardNadaPorAqui;