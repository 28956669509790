import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import DownloadLink from '../../atoms/DownloadLink';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { Message } from '@design-system-coopeuch/web';
import Wrapper from './styles';
import SpinnerLoader from '../../atoms/SpinnerLoader';

const CertProdVigentesTemplate = ({ handleDownloadPDF, showMessage, isLoadingCert }) => {
  return (
    <Wrapper className="bpx-3 bpx-md-5">
      <div className="row bmt-md-4 bmt-3 bmb-md-4 bmb-3">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>
              <Typography tag="span" fontSize={24} fontWeight={600}>
                Productos vigentes
              </Typography>{' '}
            </CardBox.Header>
            <CardBox.Body>
              <div className="mvts--header-rows py-5">
                <Typography tag="p" fontSize={16}>
                  El certificado de productos vigentes es un documento que se solicita para
                  certificar la tenencia y el estado de los productos o servicios contratados en
                  Coopeuch correspondientes a
                  <Typography tag="span" fontSize={16} fontWeight={600}>
                    &nbsp;Cuentas de Ahorro, Depositos a plazo y/o cuotas de participacion.&nbsp;
                  </Typography>
                </Typography>
                <Typography tag="p" fontSize={16} className={'mt-3'}>
                  {' '}
                  La información contenida en el certificado está actualizada al día en que se
                  solicita el certificado.
                </Typography>
                <div className="text-left py-5">
                  {isLoadingCert ? (
                    <SpinnerLoader />
                  ) : showMessage?.show ? (
                    <Message type={showMessage.type} title={showMessage.title} />
                  ) : (
                    <DownloadLink
                      onClick={(e) => handleDownloadPDF(e)}
                      text="Generar Certificado"
                      href={'#'}
                    />
                  )}
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </Wrapper>
  );
};

export default CertProdVigentesTemplate;
