export const solicitudTexts = {
  TITLE_PAGE: 'Solicitud Cuenta de Ahorro',
  BANNER_DESC_1: '¿Pensando en tu futuro?',
  BANNER_DESC_2: 'Cuenta de Ahorro Coopeuch',
  BANNER_BUTTON_TEXT: 'Solicita tu Cuenta',
  TITLE_SUB_BENEFICIOS: '¿Cuáles son los beneficios?',
  CARD_HABITO_TITLE: 'Hábito del ahorro',
  CARD_HABITO_DESC: 'Inicia tu ahorro abriendo tu cuenta con tan sólo $1.000 pesos.',
  CARD_SIN_PROBLEMAS_TITLE: 'Tus ahorros sin problemas',
  CARD_SIN_PROBLEMAS_DESC: 'Puedes girar 6 veces al año sin perder los reajustes.',
  CARD_MANTENCION_TITLE: '0 mantención ni comisión',
  CARD_MANTENCION_DESC: 'La cuenta de ahorro no genera comisiones ni mantención.',
  CARD_SOCIO_TITLE: 'Hazte Socio',
  CARD_SOCIO_DESC: 'Únete a nuestra Cooperativa y descubre los beneficios que tenemos para tí.',
};

export const misAhorrosTexts = {
  ALERT_TEXT_1: 'Aún no tienes saldo en tu cuenta. ',
  ALERT_TEXT_2: '¡Transfiere saldo a tu cuenta y comienza a ahorrar!',
  CARD_MONTO_DESC: 'Monto ahorrado a la fecha:',
  CARD_FECHA_APERTURA_DESC: 'Fecha de apertura: ',
  CARD_AHORRO_DESC: 'Ahorro mensual: ',
  CARD_DETALLE_TITLE: 'Detalle de la Cuenta',
  CARD_DETALLE_TITULAR: 'Titular: ',
  CARD_DETALLE_ESTADO: 'Estado: ',
  CARD_DETALLE_CUENTA: 'Cuenta: ',
  CARD_DETALLE_CONVENIO: 'Convenio: ',
  CARD_DETALLE_PATROCINANTE: 'Patrocinante: ',
  CARD_DETALLE_REPRESENTANTE: 'Representante: ',
  CARD_HISTORIAL_TITLE: 'Resumen de mis últimos movimientos',
  CARD_HISTORIAL_EMPTY_1: '¡Nada por aquí! ',
  CARD_HISTORIAL_EMPTY_2: 'Aun no tienes movimientos en tu Cuenta de Ahorro',
  CARD_DETALLE_CON_LIBRETA: 'Con libreta',
  CARD_DETALLE_SIN_LIBRETA: 'Sin libreta',
  CARD_DETALLE_SI: 'Sí',
  CARD_DETALLE_NO: 'No',
  ALERT_MIGRACION_CUENTA:
    'Tu Libreta de Ahorro fue actualizada correctamente a una Cuenta de Ahorro sin libreta.',
};
  
export const cuentaAhorroTexts = {
  TITLE_CUENTA_AHORRO: 'Cuenta Ahorro',
  TAB_CUENTA: 'Mis Ahorros',
  TAB_MOVIMIENTOS: 'Movimientos',
  TAB_AHORRANDO: 'Metas de Ahorro',
};

export const errorCardText = {
  ERROR_CARD_TITLE: '¡Lo sentimos!',
  ERROR_CARD_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
};

export const escogeCACuentaTexts = {
  WARNING: 'Lo sentimos, solo puedes tener una Cuenta de Ahorro Vivienda',
  CARD_TITLE: 'Escoge tu Cuenta de Ahorro',
  CARD_DESC: 'Selecciona la Cuenta de Ahorro más conveniente de acuerdo a tu necesidad',
  EXPAND1_TITLE: 'Cuenta de Ahorro Adulto Nominal',
  EXPAND1_DESC_1: 'Sin costo de mantención ni pago de comisiones.',
  EXPAND1_DESC_2: 'Intereses y reajustes cada 12 meses desde la fecha de apertura.',
  EXPAND1_DESC_3: '10% de bonificación si no hay giros en un año.',
  EXPAND2_TITLE: 'Cuenta de Ahorro Adulto Reajustable',
  EXPAND2_DESC_1: 'Sin costos de mantención ni pago de comisiones.',
  EXPAND2_DESC_2: 'Intereses y reajustes cada 12 meses.',
  EXPAND2_DESC_3: 'Puedes girar 6 veces en un año sin perder reajustes.',
  EXPAND3_TITLE: 'Cuenta de Ahorro Vivienda',
  EXPAND3_DESC_1: 'Sin costos de mantención ni pago de comisiones.',
  EXPAND3_DESC_2: 'Intereses y reajustes cada 12 meses.',
  EXPAND3_DESC_3: 'Puedes girar 6 veces en un año sin perder reajustes.',
  EXPAND3_DESC_4: 'Convenio con Minvu para postulación online.',
  BUTTON_RETURN: 'Volver',
  BUTTON_CONTINUE: 'Continuar',
};

export const contratacionCATexts = {
  CARD_TITLE: 'Conoce tus contratos',
  CARD_DESC:
    'Revisa y firma los siguientes documentos para formalizar tu contratación. Es importante que conozcas los detalles de cada uno de ellos, haciendo clic en descargar contrato.',
};

export const migracionCuentaAhorroContratacionTexts = {
  CARD_CONOCE_TITLE: 'Conoce tus contratos',
  CARD_CONOCE_DESC:
    'Revisa y firma los siguientes documentos para formalizar tu contratación. Es importante que conozcas los detalles de cada uno de ellos, haciendo clic en descargar contrato.',
  CARD_ENVIO_TITLE: 'Envío de documentos y contratos',
  CARD_FIRMA_TITLE: 'Firma electrónica',
  DESCARGAR: 'Descargar contrato',
  ACEPTAR_TERMINOS:
    'Acepto los términos y condiciones establecidos en los contratos y declaro haberlos leído al ingresar la firma electrónica.',
  CARD_EMAIL_DESC: 'Al firmar electrónicamente, te enviaremos tus contratos a tu email:',
  CARD_EMAIL_DESC_2: '¿Tu email está correcto?',
  CARD_FIRMA_DESC:
    'Al ingresar tu clave de internet estarás firmando electrónicamente en virtud de la ley ',
  CARD_FIRMA_LEY: '19.799.',
  CARD_FIRMA_INGRESA: 'Ingresa tu clave de Internet',
  BOTON_AUTORIZAR: 'Autorizar y Actualizar',
};

export const migracionCuentaAhorroComprobanteTexts = {
  SUCCESS_MESSAGE: 'Actualización exitosa. Ahora podrás usar tu Ahorro sin Libreta',
  CARD_COMPROBANTE_TITLE: 'Comprobante',
  CARD_COMPROBANTE_SUBTITLE: 'Cuenta de Ahorro',
  CARD_COMPROBANTE_NUM: 'Nº ',
  CARD_COMPROBANTE_DESC: 'Actualizaste una Libreta de Ahorro a una Cuenta de Ahorro ',
  CARD_COMPROBANTE_DESC_1: ' con fecha ',
  CARD_COMPROBANTE_DESC_2: '. Enviaremos el comprobante y contratos a tu mail: ',
  CARD_DETALLE_TITLE: 'Detalle de Contratación',
  CARD_DETALLE_PRODUCTO: 'Producto actualizado:',
  CARD_DETALLE_NUMERO: 'Nº Cuenta de Ahorro:',
  CARD_DETALLE_FECHA: 'Fecha de apertura:',
  CARD_DETALLE_ESTADO: 'Estado:',
  BOTON_ACTUALIZAR: 'Ver Actualización',
};
