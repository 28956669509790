import React, { Component } from 'react';
import { connect } from 'react-redux';

import DetallesSimulacionTemplate from '../../../components/templates/DepositoAPlazo/DetallesSimulacion';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';

class DetallesSimulacion extends Component {
  handleReturn = () => {
    const { history, match } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-3',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    history.push(`${match?.url}/resultado`);
  };

  handleChange = () => {
    const { history, match } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-3',
      'eve-cat': 'Click',
      'eve-lab': 'Cambiar simulacion',
    });
    history.push(`${match?.url}/resultado`);
  };

  handleInvertir = () => {
    const { history, match } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-3',
      'eve-cat': 'Click',
      'eve-lab': 'Invertir',
    });
    history.push(`${match?.url}/confirmacion`);
  };

  handleSolicitar = () => {
    const { history } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-3',
      'eve-cat': 'Click',
      'eve-lab': 'Solicitar Cuenta Vista',
    });
    history.push('/ecd/solicita/cuenta-coopeuch');
  };

  componentDidMount() {
    registerGA({ event: 'vpv-privado', page: '/tef/mis-productos/deposito-a-plazo/paso-3' });
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP_SIMULADOR-P3');
  }

  render() {
    return (
      <DetallesSimulacionTemplate
        {...this.props}
        handleReturn={this.handleReturn}
        handleInvertir={this.handleInvertir}
        handleSolicitar={this.handleSolicitar}
        handleChange={this.handleChange}
      />
    );
  }
}

const mapStateToProps = ({ entities }) => ({
  simulaciones: entities.depositoAPlazo.simulaciones,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DetallesSimulacion);
