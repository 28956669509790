import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Information = (props) => {
  const { items, isLocationCrearClave } = props;

  return (
    <div className="row col-sm-12 col-md-12 col-lg-12 paddingEspecial">
      <div className="ml-32 mr-32 h-100 margin--mobile">
        <div className="h-100 pl-0 pr-0 pt-4 pb-16">
          <Typography tag="p" fontSize={16} className="black text-regular-medium">
            Por <b className="black text-bold"> tu seguridad </b> tu nueva clave debe:
          </Typography>
          <div className="pl-24 pt-12 pb-12">
            <ul className="listaCajaInformacion">
              {items?.map((text, index) => (
                <li key={index} className="pb-0">
                  <img className='float-left cajaInformacionImagen'
                    src={`${iconPath}check_success.svg`}
                  />
                  <Typography tag="p" fontSize={14} className="black">
                    {text}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {!isLocationCrearClave && (
          <div className="info--wrapper--crear-clave-2 h-100 pt-24">
            <img className='float-left cajaInformacionNoRepetirClave'
              src={`${iconPath}info.svg`}
            />
            <Typography tag="p" fontSize={14} className="black info-lineal">
              Asegúrate que tu nueva clave sea <b className="black text-bold"> distinta </b> a las últimas 5 claves usadas.
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

Information.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

Information.defaultProps = {
  title: '',
  items: [],
};

export default Information;
