import React from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Inversiones from '@coopeuch-project/inversiones';
import { BannerActFinancieraRouter } from '@coopeuch-project/react-micro-actfinanciera';
import { BannerFormalizacionRouter } from '@coopeuch-project/react-micro-formalizacion';
import Avance from '@coopeuch-project/avance-tc';
import Monedero from '@coopeuch-project/gmp-monederodigitalapp';
import DesbloqueoTD from '@coopeuch-project/desbloqueo-td';

import ReprogramacionCuotas from '@coopeuch-project/reprogramacion_cuotas';

// utils
import PrivateRouter from '../hoc/PrivateRouter';
import ErrorBoundary from '../hoc/ErrorBoundary';

// Components
import Login from '../containers/Login';
import RecuperarOCrearClave from '../containers/RecuperarOCrearClave';

import Dashboard from '../containers/Dashboard';
import CuentaVista from '../containers/CuentaVista';
import Remanente from '../containers/Remanente';
import AbonoAcreenciasRemanente from '../containers/AbonoAcreenciasRemanente';
// import BloqueoTarjetas from '../containers/BloqueoTarjetas';
import TransferirFondos from '../containers/TransferirFondos';
import Destinatarios from '../containers/MisDestinatarios';
import DatosDestinatario from '../containers/MisDestinatarios/DatosDestinatario';
import SolicitaCV from '../containers/CuentaVista/Solicita';
import HistorialTransferencias from '../containers/HistorialTransferencias';
import CuentaAhorro from '../containers/CuentaAhorro';
import MigracionCuentaAhorro from '../containers/CuentaAhorro/Migracion';

import DepositoAPlazo from '../containers/DepositoAPlazo';
import CuotaParticipacion from '../containers/CuotaParticipacion';
import SolicitaCuotaParticipacion from '../containers/CuotaParticipacion/Solicita';
import TarjetaDeCredito from '../containers/TarjetaDeCredito';
import CreditoDeConsumo from '../containers/CreditoDeConsumo';
import SolicitaCreditoDeConsumo from '../containers/CreditoDeConsumo/Solicita';
import SimuladorCreditoDeConsumo from '../containers/CreditoDeConsumo/Simulador';
import { BannerReliquidacionRouter } from '@coopeuch-project/react-micro-reliquidacion';
import CreditoHipotecario from '../containers/CreditoHipotecario';
import Beneficios from '../containers/Beneficios';
import CertificadosN70 from '../containers/CertificadosN70';
import CertificadosN7 from '../containers/CertificadosN7';
import CertificadosLiquidacion from '../containers/CertificadosLiquidacion';
import CertificadosProdVigentes from '../containers/CertificadosProdVigentes';
import BloqueoTarjetas from '../containers/BloqueoTarjetas';
import { AclaracionMovimientos } from '../containers/AclaracionMovimientos';
import BonoEscolar from '../containers/BonoEscolar';
import Norma484 from '@coopeuch-project/norma484';
import AlivioTc from '@coopeuch-project/alivio-tc';
import RenegociacionCobranzaOnline from '@coopeuch-project/renegociacion-cobranza-online';
import AteWeb from '@coopeuch-project/ate-web';
import PagoQr from '../containers/pagoqr';

// private layout (header - menu - footer)
import Layout from '../containers/Layout';
import MiPerfil from '../containers/MiPerfil';

import IframeAte from '../containers/IframeAte';
import UpgradeMonedero from '../containers/UpgradeMonedero';

import ActivacionTarjetas from '../containers/ActivacionTarjetas';
import ActivacionTarjetasKit from '../containers/ActivacionTarjetasKit';

import CDPModificacion from '../containers/CuotaParticipacion/Modificacion';
import CDPSugerencia from '../containers/CuotaParticipacion/Sugerencia';
import CDPComprobante from '../containers/CuotaParticipacion/Comprobante';

// styles
import 'flexboxgrid';
import 'react-datepicker/dist/react-datepicker.css';
import 'swiper/swiper-bundle.css';
import '../assets/styles/globals.css';

const App = () => (
  <>
    <Helmet>
      <title>Cooperativa | Coopeuch</title>
    </Helmet>
    <HashRouter>
      <Switch>
        <ErrorBoundary>
          <Route exact path="/" component={Login} />
          <Route path="*/login/:id" component={Login} />
          <Route path="/recuperar-clave" exact component={RecuperarOCrearClave} />
          <Route path="/crear-clave" exact component={RecuperarOCrearClave} />
          <Route
            exact
            path="/internal/login"
            component={(props) => <Login {...props} isInternal={true} />}
          />
          <Route path="/pagoqr/deeplink" component={PagoQr} />
          <PrivateRouter path="/ecd">
            <div className="container-fluid p-0">
              <div className="row">
                <Layout>
                  <Switch>
                    <Route path="/ecd/inicio" component={Dashboard} />
                    <Route
                      exact
                      path="*/productos/cuota-de-participacion/:flujo(pago)?"
                      component={CuotaParticipacion}
                    />
                    <Route
                      exact
                      path="*/productos/cuota-de-participacion/:tab(historial)?"
                      component={CuotaParticipacion}
                    />
                    <Route
                      exact
                      path="*/productos/cuota-de-participacion/sugerencia"
                      component={CDPSugerencia}
                    />
                    <Route
                      exact
                      path="*/productos/cuota-de-participacion/modificacion"
                      component={CDPModificacion}
                    />
                    <Route
                      exact
                      path="*/productos/cuota-de-participacion/comprobante"
                      component={CDPComprobante}
                    />
                    <Route path="/ecd/solicita/monedero-digital">
                      <Monedero
                        viajeOrigen="Contratación monedero digital"
                        viajeDestino="Cuenta Vista"
                        tipoDispositivo="Desktop"
                        tipoCanal="Web"
                      />
                    </Route>
                      <Route path="/ecd/desbloqueo-tarjeta-debito">
                        <DesbloqueoTD
                          viajeOrigen="Bloqueo/Desbloqueo Tarjetas"
                          viajeDestino="Desbloqueo tarjeta Débito"
                          tipoDispositivo="Desktop"
                          tipoCanal="Web"
                        />
                      </Route>
                    <Route
                      exact
                      path="*/productos/cuenta-coopeuch/:tab(movimientos)?"
                      component={CuentaVista}
                    />
                    <Route
                      exact
                      path="*/productos/credito-de-consumo"
                      component={CreditoDeConsumo}
                    />
                    <Route
                      exact
                      path="*/productos/credito-de-consumo/:tab(historial)?"
                      component={CreditoDeConsumo}
                    />
                    <Route
                      exact
                      path="*/productos/cuenta-coopeuch/:tab(movimientos)?"
                      component={CuentaVista}
                    />
                    <Route
                      exact
                      path="*/productos/credito-hipotecario"
                      component={CreditoHipotecario}
                    />
                    <Route path="*/solicita/cuenta-coopeuch" component={SolicitaCV} />
                    <Route
                      path="*/solicita/cuota-de-participacion"
                      component={SolicitaCuotaParticipacion}
                    />
                    <Route
                      path="*/solicita/credito-de-consumo"
                      component={SolicitaCreditoDeConsumo}
                    />
                    <Route
                      path="*/solicita/simulador-credito-de-consumo"
                      component={SimuladorCreditoDeConsumo}
                    />
                    <Route path="*/poder-especial" component={AbonoAcreenciasRemanente} />
                    <Route path="*/productos/remanente" component={Remanente} />

                    <Route path="*/transferir/fondos" component={TransferirFondos} />
                    <Route path="*/transferir/destinatarios" component={Destinatarios} />
                    <Route path="*/transferir/datos-destinatario" component={DatosDestinatario} />
                    <Route path="*/transferir/historial" component={HistorialTransferencias} />
                    <Route
                      exact
                      path="*/productos/cuenta-ahorro/:tab(movimientos)?"
                      component={CuentaAhorro}
                    />
                    <Route
                      path="*/productos/cuenta-ahorro/migracion"
                      component={MigracionCuentaAhorro}
                    />
                    <Route path="*/conoce/beneficios-y-ofertas" component={Beneficios} />
                    <Route path="*/productos/tarjeta-de-credito" component={TarjetaDeCredito} />
                    <Route path="*/productos/deposito-a-plazo" component={DepositoAPlazo} />
                    <Route exact path="/ecd/reliquidacion/*">
                      <BannerReliquidacionRouter ruta="/ecd/reliquidacion" />
                    </Route>
                    <Route exact path="/ecd/banner/*">
                      <BannerActFinancieraRouter ruta="/ecd/banner" />
                    </Route>
                    <Route exact path="/ecd/banner-formalizacion/*">
                      <BannerFormalizacionRouter ruta="/ecd/banner-formalizacion" />
                    </Route>
                    <Route path="/ecd/inversiones" component={Inversiones} />
                    <Route path="*/seguridad/activacion-tarjetas" component={ActivacionTarjetas} />
                    <Route exact path="/ecd/mi-perfil" component={MiPerfil} />
                    <Route path="*/certificados/n-07" component={CertificadosN7} />
                    <Route path="*/certificados/n-70" component={CertificadosN70} />
                    <Route
                      path="*/seguridad/activacion-tarjetas-kit"
                      component={ActivacionTarjetasKit}
                    />
                    <Route path="*/certificados/liquidacion" component={CertificadosLiquidacion} />
                    <Route path="*/seguridad/bloqueo-de-tarjetas" component={BloqueoTarjetas} />
                    <Route
                      path="*/certificados/productos-vigentes"
                      component={CertificadosProdVigentes}
                    />
                    <Route path="/ecd/ate" component={IframeAte} />
                    <Route path="/ecd/upgradeMonedero" component={UpgradeMonedero} />
                    <Route
                      path="/ecd/aclaracion-de-movimientos"
                      component={AclaracionMovimientos}
                    />
                    <Route
                      path="/ecd/aclaracion-de-movimientos-iva"
                      component={AclaracionMovimientos}
                    />
                    <Route path="/ecd/bono-escolar" component={BonoEscolar} />
                    <Route path="*/avance" component={Avance} />
                    <Route path="/ecd/anexo" component={Norma484} />
                    <Route path="/ecd/alivio-tc" component={AlivioTc} />
                    <Route path="/ecd/renegociacion-cobranza-online" component={RenegociacionCobranzaOnline} />
                    <Route path="/ecd/ateweb" component={AteWeb} />
                    <Route path="/ecd/reprogramacion-consumo" component={ReprogramacionCuotas} />
                    <Redirect path="*/" to="/ecd/inicio" />
                  </Switch>
                </Layout>
              </div>
            </div>
          </PrivateRouter>
        </ErrorBoundary>
      </Switch>
    </HashRouter>
  </>
);

export default App;
