import styled from 'styled-components';

const Wrapper = styled.header`
  color: #383638;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding};
  margin: 0;
  height: ${({ height }) => `${height}px`};

  background-color: ${({ bgColor }) => bgColor};
  border-radius: ${({ bordered }) => `${bordered}px ${bordered}px 0 0`};
  
  & .dashboard--header_left {
    display: flex;
  }

  @media screen and (max-width: 766px) {
    padding: 16px;
  }
`;

export const Line = styled.div`
  ${({ borderBottom }) => (borderBottom === 'complete' ? 'margin-left: 0; margin-right: 0;' : 'margin-left: 26px; margin-right: 26px;')}
 /*  margin-left: 26px;
  margin-right: 26px; */
  border-bottom: 1px solid #e2e1e3;
`;

export const Title = styled.h5`
  ${({ titleWeight }) => (titleWeight === 'bold' ? 'font-family: "Ubuntu-Bold";' : '')}
  font-size: ${({ size }) => `${size}px`};
  color: ${({ color }) => color};
  margin: 0;
  padding: 0;
  align-self: center;

  @media screen and (max-width: 769px) {
    font-size: ${({ mSize, size }) => `${mSize || size}px`};
  }
`;

export default Wrapper;
