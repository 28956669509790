import React from 'react';

import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';

import BluePanel from '../../core/blueaPanel';

function PaymentOverview({ feeNumber, expirationDate, feeUFAmount, feeAmount }) {
  return (
    <div className="row bmt-3 bmt-md-4">
      <div className="col-xs-12">
        <CardBox>
          <CardBox.Header className="new-header-padding">Resumen de Pago</CardBox.Header>
          <CardBox.Body>
            <BluePanel className="row bpy-md-2rem bpx-md-4 bp-3">
              <div className="col-xs-6 col-md-1-de-5 bmb-3 bmb-md-0 text-center">
                <Typography className="bmb-1" tag="p" fontSize={14}>
                  N° dividendo
                </Typography>
                <Typography id="fee-number" tag="p" fontSize={18} fontFamily="ubuntu-bold">
                  {feeNumber}
                </Typography>
              </div>
              <div className="col-xs-6 col-md-1-de-5 bmb-3 bmb-md-0 text-center">
                <Typography className="bmb-1" tag="p" fontSize={14}>
                  Fecha vencimiento
                </Typography>
                <Typography id="expiration-date" tag="p" fontSize={18} fontFamily="ubuntu-bold">
                  {expirationDate}
                </Typography>
              </div>
              <div className="col-xs-6 col-md-1-de-5 text-center">
                <Typography className="bmb-1" tag="p" fontSize={14}>
                  Valor dividendo
                </Typography>
                <Typography id="fee-uf-amount" tag="p" fontSize={18} fontWeight={700}>
                  {feeUFAmount.label}
                </Typography>
              </div>
              <div className="col-xs-6 col-md-1-de-5 text-center">
                <Typography className="bmb-1" tag="p" fontSize={14}>
                  Monto a pagar
                </Typography>
                <Typography id="fee-amount" tag="p" fontSize={18} fontWeight={700}>
                  {feeAmount.label}
                </Typography>
              </div>
            </BluePanel>
          </CardBox.Body>
        </CardBox>
      </div>
    </div>
  );
}

PaymentOverview.propTypes = {
  feeNumber: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  feeUFAmount: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  feeAmount: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
};

export default PaymentOverview;
