import styled from 'styled-components';

const Button = styled.div`
  width: 100%;
  max-width: 296px;
  border-radius: 4px;
  padding: 14px 24px;
  text-align: center;
  color: white;
  font-family: 'Ubuntu-Medium';
  font-size: 14px;
  cursor: not-allowed;
  background-color: rgb(229, 229, 229) !important;
  pointer-events:none;
`;

export default Button;
