import styled from 'styled-components';

export const AdjustIcons = styled.div`
  .adjust {
    margin-right: 3px;
    float: left;
  }
  .font{
    font-size: 18px;
    margin-top: 5px;
    display: inline-block;
    margin-left: 4px;
  }
`;
export const Banner = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  .banner-actions {
    display: flex;
    align-items: center;
  }
`;

export const CustomList = styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 100%;
      position: absolute;
      content: '';
      height: 6px;
      width: 6px;
      display: inline-block;
      left: -16px;
      top: 6px;
    }
  }
`;
