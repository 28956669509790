import React, { useEffect } from 'react';

// Ui
// import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import DashboardCard from '../../../../../organism/DashboardCard';
import StepsActions from '../../../../../organism/Stepper/StepsActions';
import { registerGA } from '../../../../../../utils/metrics';

// Styles
import Wrapper, { CustomList } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({ handlebackLanding, irAContratacion, loading }) => {
  
  useEffect(() => {
    registerGA({
      'event': 'vpv-privado','page' : '/tef/solicitar/monederoDigital/paso1'
    });
  }, [])
  
  return (
    <Wrapper>
      <DashboardCard className="bmt-xs-3 bmt-sm-4 ml-0 mr-0">
        <DashboardCard.Head title="Características" borderBottom="complete" />

        <DashboardCard.Body>
          <div className="row">
            <div className="col-xs-12 col-sm-10">
              <CustomList className="mt-20">
                <li>Costo $0</li>
                <li>Realiza transferencias desde tu Cuenta de Ahorro Coopeuch</li>
                <li>Transfiere tu dinero a tus cuentas desde otros bancos</li>
                <li>Cuenta Vista 100% digital sin tarjeta de débito asociada</li>
                <li>Recibe tu remanente y los saldos disponibles a tu nombre</li>
              </CustomList>
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>

      <StepsActions
        className="bmt-3 bmb-3"
        backAction={handlebackLanding}
        backText={'No por ahora'}
        nextAction={irAContratacion}
        loading={loading}
        id="contratacion-paso-2"
      />
      {/* <div className="bmt-4">
        <div className="row container_buttons">
          <div className="col-md-6">
            <Button
              asTextLink
              color="#E81E2B"
              iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
              onClick={handlebackLanding}
              className="button-return p-0"
            >
              No por ahora
            </Button>
          </div>
          <div className="col-md-6 col-xs-12 btext-right">
            <Button
              bgColor="#E81E2B"
              color="white"
              onClick={handleClickContinue}
              className="button-continue"
            >
              Continuar
            </Button>
          </div>
        </div>
      </div> */}
    </Wrapper>
  );
};

export default Index;
