import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// actions
import { bonoEscolarActions } from '../../../../application/actions/bonoEscolar';
// Template
import DocumentacionTemplate from '../../../components/templates/BonoEscolar/Documentacion';
// Metrics
import { registerGA } from '../../../utils/metrics';

const Documentacion = ({ steps, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // Booleans
  const [btnContinueDisabled, setbtnContinueDisabled] = useState(true);
  const [isErrorGeneric, setIsErrorGeneric] = useState(false);
  const [loadFile, setLoadFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);
  const saveBono = useSelector((store) => store.entities.bonoEscolar.saveBono);

  const setIdTipoPostulacion = (data) => {
    if (data.typeBono === 'Bono de Ingreso' && data.typeBeneficiario === 'Para Mí') {
      return 1;
    }
    if (data.typeBono === 'Bono de Ingreso' && data.typeBeneficiario === 'Para mi hijo') {
      return 2;
    }
    if (data.typeBono === 'Bono de Titulación' && data.typeBeneficiario === 'Para Mí') {
      return 3;
    }
    if (data.typeBono === 'Bono de Titulación' && data.typeBeneficiario === 'Para mi hijo') {
      return 4;
    }
  };

  const setParams = (data) => {
    const result = {
      nombreSocio: data.nombreSocio,
      rutBeneficiario: data.rutBeneficiario,
      nombreBeneficiario: data.nombreBeneficiario,
      telefono: data.phone,
      email: data.email,
      idInstitucion: data.institucion,
      idCarrera: data.carrera,
      idTipoPostulacion: setIdTipoPostulacion(dataBono),
      documentos: [
        data.documentos.CEDULA_IDENTIDAD_SOCIO && data.documentos.CEDULA_IDENTIDAD_SOCIO,
        data.documentos.CEDULA_IDENTIDAD_HIJO && data.documentos.CEDULA_IDENTIDAD_HIJO,
        data.documentos.DOCUMENTO_ADICIONAL && data.documentos.DOCUMENTO_ADICIONAL,
        data.documentos.CERTIFICADO_TITULO && data.documentos.CERTIFICADO_TITULO,
      ].filter((el) => el),
    };

    return result;
  };

  const handleUploadFileToServer = ({ mimeType, tipoDocumento, contenido }) => {
    setLoadFile(true);
    setbtnContinueDisabled(true);
    const params = {
      mimeType,
      tipoDocumento,
      contenido,
    };
    dispatch(bonoEscolarActions.fetchUploadFileToServer(params));
  };

  const handleClickContinue = () => {
    setIsLoading(true);
    const params = setParams(dataBono);
    dispatch(bonoEscolarActions.fetchSaveBono(params));
    setbtnContinueDisabled(true);
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${
        dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/paso3`,
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
  };

  const handleClickBack = () => {
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${
        dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/paso3`,
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    history.push('/ecd/bono-escolar/');
  };

  const handleClickRetry = (text) => {
    registerGA({
      event: 'eve',
      'eve-acc': 'paso3',
      'eve-cat': 'Error',
      'eve-lab': text,
    });
    history.push('/ecd/inicio');
  };

  // Validate routes
  useEffect(() => {
    currentStep < 3 && history.push('/ecd/bono-escolar/');
    registerGA({
      event: 'vpv-privado',
      page: `/beneficios/${
        dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/paso3`,
    });
  }, []);

  // Validate Btn continue
  useEffect(() => {
    if (dataBono.typeBono === 'Bono de Ingreso' && dataBono.typeBeneficiario === 'Para Mí') {
      if (dataBono.documentos?.CEDULA_IDENTIDAD_SOCIO && dataBono.documentos?.DOCUMENTO_ADICIONAL) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }

    if (dataBono.typeBono === 'Bono de Ingreso' && dataBono.typeBeneficiario === 'Para mi hijo') {
      if (
        dataBono.documentos?.CEDULA_IDENTIDAD_SOCIO &&
        dataBono.documentos?.CEDULA_IDENTIDAD_HIJO &&
        dataBono.documentos?.DOCUMENTO_ADICIONAL
      ) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }

    if (dataBono.typeBono === 'Bono de Titulación' && dataBono.typeBeneficiario === 'Para Mí') {
      if (dataBono.documentos?.CEDULA_IDENTIDAD_SOCIO && dataBono.documentos?.CERTIFICADO_TITULO) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }

    if (
      dataBono.typeBono === 'Bono de Titulación' &&
      dataBono.typeBeneficiario === 'Para mi hijo'
    ) {
      if (
        dataBono.documentos.CEDULA_IDENTIDAD_SOCIO &&
        dataBono.documentos.CEDULA_IDENTIDAD_HIJO &&
        dataBono.documentos.CERTIFICADO_TITULO
      ) {
        setbtnContinueDisabled(false);
      } else {
        setbtnContinueDisabled(true);
      }
    }
    setLoadFile(false);
  }, [dataBono.documentos]);

  // Control error generic
  useEffect(() => {
    setIsLoading(false);
    if (dataBono?.documentos.error === true) {
      setIsErrorGeneric(true);
      registerGA({
        event: 'eve',
        'eve-acc': 'paso3',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    } else {
      setIsErrorGeneric(false);
    }
  }, [dataBono.documentos]);

  // Response save data
  useEffect(() => {
    if (saveBono.data !== null) {
      setCurrentStep(currentStep + 1);
      history.push('/ecd/bono-escolar/comprobante');
    }
  }, [saveBono]);

  return (
    <DocumentacionTemplate
      {...{
        steps,
        handleUploadFileToServer,
        dataBono,
        btnContinueDisabled,
        handleClickContinue,
        isErrorGeneric,
        isLoading,
        loadFile,
        setbtnContinueDisabled,
        handleClickBack,
        handleClickRetry,
      }}
    />
  );
};

export default Documentacion;
