import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import StepActions from '../../../../../organism/Stepper/StepsActions';
import ContratacionEmail from '../../../../../organism/ContratacionEmail';
import ContratacionFirmaElectronica from '../../../../../organism/ContratacionFirmaElectronica';
import ContratacionTerminos from '../../../../../organism/ContratacionTerminos';

import PassCoopeuch from '../../../../../organism/ContratacionCV/PassCoopeuch';
import TarjetaDeCoordenadas from '../../../../../organism/ContratacionCV/TarjetaDeCoordenadas';

import Contratos from './Contratos';

// Styles
import { MessageError } from './styles';
import Wrapper, { CardFooter, Blocker, Overlay } from './styles';

import Typography from '@coopeuch-components/web/atoms/Typography';

const Index = ({
  checked,
  checkdRequiredError,
  password,
  showPassword,
  passwordError,
  blockedPassword,
  serverPasswordError = '',
  wrongPassword,
  serviceError,
  email,
  emailError,
  persona,
  handleCheckBox,
  handleChangeEmail,
  handlePasswordInput,
  handleWrongPassword,
  handleTogglePassword,
  loading=true,  
  onDownloadGenericContract,
  availablePassCoopeuch,
  solicitarPassCoopeuch,
  solicitarDesafioPassCoopeuch,
  IsValidatePassCoopeuch,
  validarPassCoopeuchCP,
  errorTimeOutCP,
  errorTimeOut,
  counterActive,
  handleSwitchMethods,
  onHandleObtenerDesafio,
  tarjetaDeCoord,
  IsAvailablePassCoopeuch,
  IsAvailableTarCoo,
  onHandleSetDesafio,
  sendDesafio,
  IsLoading,
  retryTimeOutPass, 
  timerValue,
  validateSMS,
  backAction,
  nextAction,
  isVisibleModalError,
  handleClickCloseModalError,
  tarjeta,
  desafio,
  coordenadas,
  validarDesafio,
  validaDesafio,
  continuaFirma,
}) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const documentsPath = process.env.REACT_APP_CLOUDFRONT_DOCUMENTS;
  
  const emailErrorMessage = (
    <MessageError className="input-error-cuotas text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${iconPath}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        {emailError}
      </div>
    </MessageError>
  );

  const texEnvioEmailAviso = (<p> Si deseas que enviemos tus contratos a un email diferente, debes cambiarlo en la siguiente casilla. El cambio será solo para envío de los documentos y no reemplazarán el email que tienes registrado en Coopeuch.</p>);
  const texEnvioEmail = (<p> Al firmar electrónicamente, te enviaremos tus contratos a tu email:</p>);
  const textEmailRegistrado = (<p> Email registrado ({persona.correo})</p>);
  const textOtroEmail = (<p> Enviar a otra cuenta de email</p>);
  const textEmailNoTiene = (<p><b>No tienes un email registrado</b>, ingresa uno para continuar el proceso de contratación. Este email no reemplaza tus datos actuales.</p>);
  const textTerminosCondiciones = (<p> Acepto los términos y condiciones establecidos en los contratos y declaro haberlos leido. Al autorizar con PassCoopeuch o Tarjeta de Coordenadas, estaré firmando de manera electrónica.</p>);
  const textAutorizoTerminos = (<p>Sí, autorizo</p>);
  const texErrortAutorizo = (<p>Debes ingresar un email para continuar.</p>);
  const textFirmaElectronica = (<p>Firma tus contratos de forma digital con tu clave de internet Coopeuch: <br/><br/>  Al ingresar tu contraseña alfanumérica <b>estarás declarando que has leído los términos y condiciones</b> establecidos en los contratos. De lo contrario puedes cancelar la operación y realizar la contratación en una oficina.</p>);

  return (
    <>
      <Contratos
        iconPath={iconPath}
        onDownloadGenericContract={onDownloadGenericContract}
        documentsPath={documentsPath}
      />
      <ContratacionEmail
        persona={persona}
        email={email}
        emailError={emailError}
        emailErrorMessage={emailErrorMessage}
        handleChangeEmail={handleChangeEmail}
        texEnvioEmailAviso={texEnvioEmailAviso}
        texEnvioEmail={texEnvioEmail}
        textEmailRegistrado={textEmailRegistrado}
        textOtroEmail={textOtroEmail}
        textEmailNoTiene={textEmailNoTiene}
      />
      <ContratacionTerminos
        checked={checked}
        handleCheckBox={handleCheckBox}
        checkdRequiredError={checkdRequiredError}
        textTerminosCondiciones={textTerminosCondiciones}
        textAutorizoTerminos={textAutorizoTerminos}
        texErrortAutorizo={texErrortAutorizo}
      />
      
      <div className="bmb-3 bmb-md-4">
      <Blocker>
        <div className='blocker'>
        {!checked && 
          <Overlay><div className='overlay'></div></Overlay>
        }  
        <Wrapper>
          {!tarjetaDeCoord &&
            <PassCoopeuch
              loading={loading}
              counterActive={counterActive}
              timer={timerValue}
              errorTimeOut={errorTimeOut}
              IsValidatePassCoopeuch={IsValidatePassCoopeuch}
              validarPassCoopeuchCP={validarPassCoopeuchCP}
              IsAvailablePassCoopeuch={IsAvailablePassCoopeuch}
              errorTimeOutCP={errorTimeOutCP}
              solicitarPassCoopeuch={solicitarPassCoopeuch}
              IsAvailableTarCoo={IsAvailableTarCoo}
              IsLoading={IsLoading}
              handleSwitchMethods={handleSwitchMethods}
              retryTimeOutPass={retryTimeOutPass}
              solicitarDesafioPassCoopeuch={solicitarDesafioPassCoopeuch}
            />
          }
          {tarjetaDeCoord &&
            <TarjetaDeCoordenadas
              loading={loading}
              counterActive={counterActive}
              timer={timerValue}
              errorTimeOut={errorTimeOut}
              validarPassCoopeuchCP={validarPassCoopeuchCP}
              IsAvailablePassCoopeuch={IsAvailablePassCoopeuch}
              errorTimeOutCP={errorTimeOutCP}
              solicitarPassCoopeuch={solicitarPassCoopeuch}
              IsAvailableTarCoo={IsAvailableTarCoo}
              IsLoading={IsLoading}
              handleSwitchMethods={handleSwitchMethods}
              onHandleObtenerDesafio={onHandleObtenerDesafio}
              onHandleSetDesafio={onHandleSetDesafio}
              sendDesafio={sendDesafio}
              tarjeta={tarjeta}
              desafio={desafio}
              retryTimeOutPass={retryTimeOutPass}
              coordenadas={coordenadas}
              validarDesafio={validarDesafio}
              validaDesafio={validaDesafio}
              continuaFirma={continuaFirma}
            />
          }
          
        </Wrapper>
        </div>
      </Blocker>
      </div>
      {!loading && (
        <div className="row bmb-3 bmb-md-4">
          <div className="col-xs-12">
            <StepActions
              {...{ backAction, nextAction, loading }}
              backText="Volver"
              hideNextAction={true}
            />
          </div>
        </div>
      )}
    </>
  );
};


Index.displayName = 'Template_Firma';

Index.propTypes = {
  checked: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  showPassword: PropTypes.bool.isRequired,
  passwordError: PropTypes.string.isRequired,
  blockedPassword: PropTypes.bool.isRequired,
  serverPasswordError: PropTypes.string,
  wrongPassword: PropTypes.bool,
  checkdRequiredError: PropTypes.bool.isRequired,
  serviceError: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  persona: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  handlePasswordInput: PropTypes.func.isRequired,
  handleWrongPassword: PropTypes.func.isRequired,
  handleTogglePassword: PropTypes.func.isRequired,
  backAction: PropTypes.func.isRequired,
  nextAction: PropTypes.func.isRequired,
  handleChangeEmail: PropTypes.func.isRequired,
  onDownloadGenericContract: PropTypes.func.isRequired,
  solicitarPassCoopeuch: PropTypes.func.isRequired,
  validarPassCoopeuchCP: PropTypes.func.isRequired,
  errorTimeOutCP: PropTypes.func.isRequired,
  continuaFirma: PropTypes.func.isRequired,
};

Index.defaultProps = {
  serverPasswordError: '',
  wrongPassword: false,
};

export default Index;
