import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 50px;
  margin-top: 74px;
  margin-bottom: 24px;
  @media (max-width: 720px) {
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
  }
`;
export default Wrapper;
