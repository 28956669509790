import styled from 'styled-components';

const Wrapper = styled.div`
  .cardComprobante {
    padding: 0px;

    .text-comprobante {
      font-size: 24px;
      font-family: 'Ubuntu-Bold';
    }

    > div:first-child > h1 {
      font-family: 'Ubuntu-Bold';
    }

    &__title {
      padding: 20px 32px;
      font-size: 18px;
      line-height: 26px;
      font-family: 'Ubuntu-Bold';

      @media screen and (max-width: 500px) {
        padding: 16px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    ._3yWEa > tbody > tr > td {
      padding-left: 32px;
      padding-right: 32px;
      font-size: 14px;
      line-height: 24px;

      @media screen and (max-width: 500px) {
        padding-left: 16px;
        padding-right: 16px;
        line-height: 20px;
      }
    }

    ._3yWEa {
      border: 1px solid transparent;
    }

    ._3yWEa > tbody > tr {
      border: none;
    }

    ._3yWEa > tbody > tr:first-child > td:last-child {
      max-width: 130px;
    }

    ._3yWEa > tbody > tr > td:first-child {
      font-family: 'Ubuntu-Bold';
      font-weight: 900;
    }

    ._3yWEa > tbody > tr > td:last-child {
      font-family: 'Ubuntu-Regular';
      text-align: start;

      @media screen and (max-width: 500px) {
        text-align: end;
      }
    }

    &__footer {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 32px;
      align-items: center;

      &_fechaHora {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        line-height: 24px;

        > p:first-child {
          font-family: 'Ubuntu-Regular';
        }
        > p:last-child {
          padding-left: 5px;
          font-family: 'Ubuntu-Bold';
          font-weight: 900;
        }

        @media screen and (max-width: 500px) {
          flex-direction: column;
          line-height: 20px;

          > p:last-child {
            padding-left: 0px;
          }
        }
      }

      &_sello {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        > svg {
          width: 90px;
          height: 90px;

          @media screen and (max-width: 500px) {
            width: 72px;
            height: 72px;
          }
        }

        > p {
          display: block;
          font-family: 'Ubuntu-Regular';
          font-size: 12px;
          line-height: 18px;
          color: #a5a5a5;
          padding-right: 16px;

          @media screen and (max-width: 500px) {
            padding-right: 0px;
            padding-left: 16px;
          }
        }

        @media screen and (max-width: 500px) {
          flex-direction: row;
          margin-top: 16px;
        }
      }

      @media screen and (max-width: 500px) {
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  ._2p8qe span {
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
    text-decoration: none;
  }
`;
export default Wrapper;
