import { put, takeLatest, call, spawn, all, delay } from 'redux-saga/effects';

import { gestionClaveActions } from '../../../application/actions/gestionClave';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';
import { logReqError } from '../../../ui/utils/functions';
import { registerGA } from '../../../ui/utils/metrics';
import { metodoFactores } from '../../../ui/utils/constants';

import defaultRequest, {
  errPassCoopeuchFactor,
  errTarjetaCoordenadasFactor,
  errSMSFactor,
  errRecuperarClave,
} from './errors';

const apiGestionClave = api(process.env.REACT_APP_GESTION_CLAVE);

export function* validarRutToCrearClave(action) {
  try {
    const { captcha, ...body } = action.payload;

    yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: true }));

    const { data, error } = yield call(apiGestionClave.post, `persona/rut/v2/validar`, body, {
      Authorization: captcha,
    });

    if (data && data.track) {
      const { track } = data;

      yield put(gestionClaveActions.validarRutToCrearClaveSuccess({ track }));
    } else if (error) {
      let data = [];
      switch (error.code) {
        case 'GC-01':
          data['code'] = error.code;
          data['title'] = 'Cédul de identidad vencida';
          data['message'] =
            'No es posible validar tu identidad ya que tu cédula se encuentra vencida. Por favor, verifica la vigencia.';
          data['showButton'] = false;
          data['type'] = 'warning';

          registerGA({
            event: 'trigger_event',
            'event-config': {
              'eve-acc': '/crea-rec-clave/paso1',
              'eve-cat': 'Error',
              'eve-lab': 'Cedula vencida',
            },
          });

          break;

        case 'GC-02':
          data['code'] = error.code;
          data['title'] = 'No pudimos validar tu cédula de identidad';
          data['message'] =
            'El número de serie o de documento que ingresaste no corresponde. Inténtalo nuevamente.';
          data['showButton'] = true;
          data['type'] = 'warning';

          registerGA({
            event: 'trigger_event',
            'event-config': {
              'eve-acc': '/crea-rec-clave/paso1',
              'eve-cat': 'Error',
              'eve-lab': 'Cedula sin validar  ',
            },
          });

          break;

        case 'GC-03':
          data['code'] = error.code;
          data['title'] = 'Cédula de identidad bloqueada';
          data['message'] =
            'No fue posible validar tu identidad ya que tu cédula se encuentra bloqueada.';
          data['showButton'] = false;
          data['type'] = 'warning';

          registerGA({
            event: 'trigger_event',
            'event-config': {
              'eve-acc': '/crea-rec-clave/paso1',
              'eve-cat': 'Error',
              'eve-lab': 'Cedula bloqueada',
            },
          });

          break;

        case 'GC-04':
          data['code'] = error.code;
          data['title'] = '¡Hazte Socio!';
          data['message'] =
            'Al parecer aún no eres parte de Coopeuch. Te invitamos a ser parte de la cooperativa y disfrutar de los beneficios que tenemos para ti.';
          data['showButton'] = true;
          data['specialButtonMessage'] = 'Hazte socio Coopeuch';
          data['showSpecialButton'] = true;
          data['type'] = 'info';

          break;

        default:
          // No colocar valores, permite que se utilice 'errorTexts' como los valores por defecto.
          data['title'] = 'Servicio no disponible';
          data['message'] =
            'Estamos trabajando para restablecer la conexión. Vuelve a intentarlo más tarde.';
          data['showButton'] = false;
          data['type'] = 'default';

          break;
      }

      yield put(
        gestionClaveActions.validarRutToCrearClaveFailed({
          message: data,
        })
      );
    } else {
      yield put(
        gestionClaveActions.validarRutToCrearClaveFailed({
          message:
            error?.statusCode === 500 || error?.statusCode === undefined
              ? 'Ha ocurrido un Error, intentalo nuevamente.'
              : error?.message,
        })
      );
    }

    yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false }));
  } catch (err) {
    yield all([
      yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false })),
      yield put(
        gestionClaveActions.validarRutToCrearClaveFailed({ message: 'Ha ocurrido un Error' })
      ),
    ]);
  }
}

export function* resetErrorValidarRut(action) {
  try {
    const { captcha, ...body } = action.payload;

    yield put(gestionClaveActions.validarRutToCrearClaveReset());
  } catch (err) {
    yield all([
      yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false })),
      yield put(
        gestionClaveActions.validarRutToCrearClaveFailed({ message: 'Ha ocurrido un Error' })
      ),
    ]);
  }
}

export function* fetchFactoresSeguridadCrearClave(action) {
  try {
    const { track, rut } = action.payload;
    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: true }));

    const { data } = yield call(
      apiGestionClave.get,
      'persona/' + rut + '/desafio/factor/v1/obtener',
      null,
      optionalsHeaders
    );

    if (data) {
      yield put(gestionClaveActions.fetchFactoresSeguridadSuccess({ factoresSeguridad: data }));
      yield put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: false }));
    } else {
      yield put(gestionClaveActions.fetchFactoresSeguridadFailed());
      yield put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: false }));
    }
  } catch (err) {
    yield put(gestionClaveActions.fetchFactoresSeguridadFailed());
    yield put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: false }));
  }
}

export function* fetchReglasFactores() {
  try {
    yield put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: true }));

    const { data } = yield call(apiGestionClave.get, 'desafio/v1/configuracion/obtener');

    if (data) {
      yield put(gestionClaveActions.fetchReglasFactoresSuccess({ reglasFactores: data }));
      yield put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: false }));
    } else {
      yield put(gestionClaveActions.fetchReglasFactoresFailed({ reglasFactores: [] }));
      yield put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: false }));
    }
  } catch (err) {
    yield put(gestionClaveActions.fetchReglasFactoresFailed({ reglasFactores: [] }));
    yield put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: false }));
  }
}

export function* fetchDesafioOTP(action) {
  try {
    const { track, rut } = action.payload;
    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: true }));

    const { data } = yield call(
      apiGestionClave.get,
      'otp/desafio/v1/obtener/' + rut,
      null,
      optionalsHeaders
    );

    if (data?.enviado) {
      yield put(gestionClaveActions.fetchDesafioOTPSuccess());
      yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
    } else {
      yield put(gestionClaveActions.fetchDesafioOTPFailed());
      yield put(gestionClaveActions.selectMetodoFailed());
      yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
    }
  } catch (err) {
    yield put(gestionClaveActions.fetchDesafioOTPFailed());
    yield put(gestionClaveActions.selectMetodoFailed());
    yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
  }
}

export function* validarDesafioOTP(action) {
  try {
    const { track, params } = action.payload;
    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: true }));

    const { data } = yield call(
      apiGestionClave.post,
      'otp/desafio/v1/validar',
      params,
      optionalsHeaders
    );

    if (data) {
      if (data.valido) {
        yield put(
          gestionClaveActions.validarDesafioOTPSuccess({
            desafioOTPValido: data.valido,
          })
        );
        yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
      } else {
        if (data.bloqueado) {
          yield put(
            gestionClaveActions.validarUsingFactorFailed({
              error: errSMSFactor[2],
              type: 'smsErrors',
            })
          );
          yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
          registerGA({
            event: 'trigger_event_visto',
            producto: metodoFactores['3.1'],
            'event-config': {
              'eve-acc': '/crea-rec-clave/paso3',
              'eve-cat': 'Error',
              'eve-lab': 'SMS - Codigo bloqueado',
            },
          });
        } else {
          if (data.expirado) {
            yield put(
              gestionClaveActions.validarUsingFactorFailed({
                error: errSMSFactor[3],
                type: 'smsErrors',
              })
            );
            yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
          } else {
            yield put(
              gestionClaveActions.validarUsingFactorFailed({
                error: errSMSFactor[1],
                type: 'smsErrors',
              })
            );
            yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
            registerGA({
              event: 'trigger_event',
              producto: metodoFactores['3.1'],
              'event-config': {
                'eve-acc': '/crea-rec-clave/paso3',
                'eve-cat': 'Error',
                'eve-lab': 'SMS - Codigo no corresponde',
              },
            });
          }
        }
      }
    } else {
      yield put(
        gestionClaveActions.validarUsingFactorFailed({
          error: defaultRequest,
          type: 'smsErrors',
        })
      );
      yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
    }
  } catch (err) {
    yield put(
      gestionClaveActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'smsErrors',
      })
    );
    yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
  }
}

export function* fetchDatosPersonalesCrearClave(action) {
  try {
    const { track, rut } = action.payload;

    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: true }));

    const { data } = yield call(apiGestionClave.get, `persona/v1/obtener/${rut}`, null, { track });

    if (data) {
      yield put(gestionClaveActions.fetchDatosPersonalesSuccess({ datosPersonales: data }));
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    } else {
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));

      if (Number(data) === 0 || data === null) {
        yield put(gestionClaveActions.fetchDatosPersonalesWithoutPhone());
      } else {
        yield put(gestionClaveActions.fetchDatosPersonalesFailed());
      }
    }
  } catch (err) {
    yield put(gestionClaveActions.fetchDatosPersonalesFailed());
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
  }
}

export function* fetchReglasCrearClave() {
  try {
    yield put(loadersActions.fetchLoadingReglasCrearClave({ isLoading: true }));

    const { data, error } = yield call(apiGestionClave.get, `clave/reglas/v1/obtener`);

    if (data) {
      const { reglaClaves } = data;

      yield put(gestionClaveActions.fetchReglasCrearClaveSuccess({ reglaClaves }));
    } else {
      yield put(gestionClaveActions.fetchReglasCrearClaveFailed({ ...error }));
    }

    yield logReqError(error);
    yield put(loadersActions.fetchLoadingReglasCrearClave({ isLoading: false }));
  } catch (err) {
    yield all([
      yield put(loadersActions.fetchLoadingReglasCrearClave({ isLoading: false })),
      yield put(
        gestionClaveActions.fetchReglasCrearClaveFailed({ message: 'Ha ocurrido un Error' })
      ),
    ]);
  }
}

export function* crearClave(action) {
  try {
    const { track, ...body } = action.payload;

    yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: true }));

    const { data, error } = yield call(apiGestionClave.post, `clave/v1/crear`, body, { track });

    if (data) {
      yield put(gestionClaveActions.crearORecuperarClaveSuccess());
    } else {
      yield put(gestionClaveActions.crearORecuperarClaveFailed({ message: '' }));
    }

    yield logReqError(error);
    yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false }));
  } catch (err) {
    yield all([
      yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false })),
      yield put(gestionClaveActions.crearORecuperarClaveFailed({ message: '' })),
    ]);
  }
}

export function* recuperarClave(action) {
  try {
    const { track, ...body } = action.payload;

    yield all([
      yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: true })),
      yield put(gestionClaveActions.clearCrearORecuperarStates()),
    ]);

    const { data, error } = yield call(apiGestionClave.post, `clave/v1/recuperar`, body, { track });

    if (data) {
      yield put(gestionClaveActions.crearORecuperarClaveSuccess());
    } else {
      yield put(
        gestionClaveActions.crearORecuperarClaveFailed({
          message: errRecuperarClave[error?.code]?.message || '',
        })
      );
    }

    yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false }));
  } catch (err) {
    yield all([
      yield put(loadersActions.fetchLoadingValidarRutOCrearClave({ isLoading: false })),
      yield put(gestionClaveActions.crearORecuperarClaveFailed({ message: '' })),
    ]);
  }
}
export function* redirectFromDiffOrigins(action) {
  try {
    const { track } = action.payload;
    const optionalsHeaders = { track: track };
    const { data } = yield call(apiGestionClave.get, 'origen/v1/volver', null, optionalsHeaders);
    if (data) {
      yield put(gestionClaveActions.redirectSuccess(data));
    } else {
      yield put(gestionClaveActions.redirectError(data));
    }
  } catch (err) {
    yield put(gestionClaveActions.redirectError(err));
  }
}
export function* selectMetodo(action) {
  try {
    const { track, params } = action.payload;

    const optionalsHeaders = { track: track };

    yield put(loadersActions.fetchLoadingSelectMetodo({ isLoading: true }));

    const { data } = yield call(
      apiGestionClave.post,
      'clave/metodo/v1/seleccionar',
      params,
      optionalsHeaders
    );

    if (data) {
      yield put(gestionClaveActions.selectMetodoSuccess());
      yield put(loadersActions.fetchLoadingSelectMetodo({ isLoading: false }));
    } else {
      yield put(gestionClaveActions.selectMetodoFailed());
      yield put(loadersActions.fetchLoadingSelectMetodo({ isLoading: false }));
    }
  } catch (err) {
    yield put(gestionClaveActions.selectMetodoFailed());
    yield put(loadersActions.fetchLoadingSelectMetodo({ isLoading: false }));
  }
}

export function* fetchDesafioTCOO(action) {
  try {
    const { track, rut } = action.payload;
    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const { data, error } = yield call(
      apiGestionClave.get,
      'tarjetacoordenada/desafio/v1/obtener/' + rut,
      null,
      optionalsHeaders
    );

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));

    if (data) {
      yield put(gestionClaveActions.fetchDesafioTCOOSuccess({ ...data }));
    } else {
      yield put(
        gestionClaveActions.validarUsingFactorFailed({
          error: errTarjetaCoordenadasFactor[Number(error.code)] || defaultRequest,
          type: 'tarjetaCoordenadasErrors',
        })
      );
    }
  } catch (err) {
    yield all([
      put(
        gestionClaveActions.validarUsingFactorFailed({
          error: defaultRequest,
          type: 'tarjetaCoordenadasErrors',
        })
      ),
      put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false })),
    ]);
  }
}

export function* validarDesafioTCOO(action) {
  try {
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const { track, desafio, rut } = action.payload;

    const optionalsHeaders = { track: track };

    const { data, error } = yield call(
      apiGestionClave.post,
      'tarjetacoordenada/desafio/v1/validar/' + rut,
      desafio,
      optionalsHeaders
    );

    if (data) {
      yield put(
        gestionClaveActions.validarDesafioTCOOSuccess({
          desafioTCOOValido: data,
        })
      );
    } else {
      yield put(
        gestionClaveActions.validarUsingFactorFailed({
          type: 'tarjetaCoordenadasErrors',
          error: errTarjetaCoordenadasFactor[Number(error.code)] || defaultRequest,
        })
      );

      if (error) {
        switch (true) {
          case Number(error.code) === 2:
            registerGA({
              event: 'trigger_event_visto',
              'event-config': {
                'eve-acc': '/crea-rec-clave/paso3',
                'eve-cat': 'Error',
                'eve-lab': 'Coordenadas no corresponden',
              },
            });
            break;
          case Number(error.code) === 4:
            registerGA({
              event: 'trigger_event',
              'event-config': {
                'eve-acc': '/crea-rec-clave/paso3',
                'eve-cat': 'Error',
                'eve-lab': 'Tarjeta de Coordenadas bloqueada',
              },
            });
            break;
          case Number(error.code) === 5:
            registerGA({
              event: 'trigger_event',
              'event-config': {
                'eve-acc': '/crea-rec-clave/paso3',
                'eve-cat': 'Error',
                'eve-lab': 'Tarjeta de Coordenadas bloqueada',
              },
            });
            break;
          default:
        }
      }
    }

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  } catch (err) {
    yield put(
      gestionClaveActions.validarUsingFactorFailed({
        type: 'tarjetaCoordenadasErrors',
        error: defaultRequest,
      })
    );
  }
}

export function* fetchDesafioCPASS(action) {
  try {
    const { rut, track } = action.payload;

    yield put(
      gestionClaveActions.fetchDesafioCPASSSuccess({
        obtencionFactorCPASS: false,
      })
    );

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const optionalsHeaders = { track: track };

    const { data, error } = yield call(
      apiGestionClave.get,
      'web/passcoopeuch/desafio/v1/obtener/' + rut,
      null,
      optionalsHeaders
    );

    if (data) {
      yield put(
        gestionClaveActions.fetchDesafioCPASSSuccess({
          obtencionFactorCPASS: data,
        })
      );
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    } else {
      yield put(
        gestionClaveActions.validarUsingFactorFailed({
          error: errPassCoopeuchFactor[Number(error.code)] || defaultRequest,
          type: 'passCoopeuchErrors',
        })
      );
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
  } catch (err) {
    console.log(err);
    yield put(
      gestionClaveActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'passCoopeuchErrors',
      })
    );
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  }
}

export function* validarDesafioCPASS(action) {
  try {
    const { rut, track, tiempo } = action.payload;

    const optionalsHeaders = { track: track };

    const tiempoActivo = tiempo || 120;

    var tiempoLimite = tiempoActivo - (tiempoActivo * 22) / 100;
    var tiempoTranscurrido = 0;

    while (tiempoTranscurrido < tiempoLimite) {
      const { data, error } = yield call(
        apiGestionClave.post,
        'web/passcoopeuch/desafio/v1/validar/' + rut,
        {},
        optionalsHeaders
      );

      if (data) {
        if (data.codigo !== 25) {
          if (data.codigo === 0) {
            yield put(
              gestionClaveActions.validarDesafioCPASSSuccess({
                desafioCPASSValido: true,
              })
            );
            tiempoTranscurrido = tiempoLimite;
          } else {
            yield put(
              gestionClaveActions.validarUsingFactorFailed({
                type: 'passCoopeuchErrors',
                error: errPassCoopeuchFactor[Number(data.codigo)] || defaultRequest,
              })
            );
            switch (true) {
              case Number(data.codigo) === 8:
                registerGA({
                  event: 'trigger_event',
                  producto: metodoFactores['2.2'],
                  'event-config': {
                    'eve-acc': '/crea-rec-clave/paso3',
                    'eve-cat': 'Error',
                    'eve-lab': 'Autorizacion celular rechazada',
                  },
                });
                break;
              case error && Number(error.code) === 14:
                registerGA({
                  event: 'trigger_event',
                  'event-config': {
                    'eve-acc': '/crea-rec-clave/paso3',
                    'eve-cat': 'Error',
                    'eve-lab': 'PC - Tiempo agotado',
                  },
                });
                break;
              case error && Number(error.code) === 7:
                registerGA({
                  event: 'trigger_event_visto',
                  'event-config': {
                    'eve-acc': '/crea-rec-clave/paso3',
                    'eve-cat': 'Error',
                    'eve-lab': 'PC - Bloqueada',
                  },
                });
                break;
              default:
                registerGA({
                  event: 'trigger_event',
                  producto: metodoFactores['2.2'],
                  'event-config': {
                    'eve-acc': '/crea-rec-clave/paso3',
                    'eve-cat': 'Error',
                    'eve-lab': 'PC - Sin servicio disponible',
                  },
                });
            }
            tiempoTranscurrido = tiempoLimite;
          }
        } else {
          tiempoTranscurrido = tiempoTranscurrido + 3;
          yield delay(3000);
        }
      } else {
        yield put(
          gestionClaveActions.validarUsingFactorFailed({
            type: 'passCoopeuchErrors',
            error: errPassCoopeuchFactor[Number(error.code)] || defaultRequest,
          })
        );
        tiempoTranscurrido = tiempoLimite;
      }
    }
  } catch (err) {
    console.log(err);
    yield put(
      gestionClaveActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'passCoopeuchErrors',
      })
    );
  }
}

export function* watchValidarRutToCrearClave() {
  yield takeLatest(gestionClaveActions.validarRutToCrearClave.type, validarRutToCrearClave);
}

export function* watchResetErrorValidarRut() {
  yield takeLatest(gestionClaveActions.resetErrorValidarRut.type, resetErrorValidarRut);
}

export function* watchFetchReglasFactores() {
  yield takeLatest(gestionClaveActions.fetchReglasFactores.type, fetchReglasFactores);
}

export function* watchFetchFactoresSeguridadCrearClave() {
  yield takeLatest(
    gestionClaveActions.fetchFactoresSeguridadCrearClave.type,
    fetchFactoresSeguridadCrearClave
  );
}
export function* watchFetchDesafioOTP() {
  yield takeLatest(gestionClaveActions.fetchDesafioOTP.type, fetchDesafioOTP);
}

export function* watchValidarDesafioOTP() {
  yield takeLatest(gestionClaveActions.validarDesafioOTP.type, validarDesafioOTP);
}

export function* watchFetchDatosPersonalesClearClave() {
  yield takeLatest(
    gestionClaveActions.fetchDatosPersonalesCrearClave.type,
    fetchDatosPersonalesCrearClave
  );
}

export function* watchFetchReglasCrearClave() {
  yield takeLatest(gestionClaveActions.fetchReglasCrearClave.type, fetchReglasCrearClave);
}

export function* watchCrearClave() {
  yield takeLatest(gestionClaveActions.crearClave.type, crearClave);
}

export function* watchRecuperarClave() {
  yield takeLatest(gestionClaveActions.recuperarClave.type, recuperarClave);
}

export function* watchSelectMetodo() {
  yield takeLatest(gestionClaveActions.selectMetodo.type, selectMetodo);
}

export function* watchFetchDesafioTCOO() {
  yield takeLatest(gestionClaveActions.fetchDesafioTCOO.type, fetchDesafioTCOO);
}

export function* watchValidarDesafioTCOO() {
  yield takeLatest(gestionClaveActions.validarDesafioTCOO.type, validarDesafioTCOO);
}

export function* watchFetchDesafioCPASS() {
  yield takeLatest(gestionClaveActions.fetchDesafioCPASS.type, fetchDesafioCPASS);
}

export function* watchValidarDesafioCPASS() {
  yield takeLatest(gestionClaveActions.validarDesafioCPASS.type, validarDesafioCPASS);
}
export function* watchRedirectFromDiffOrigins() {
  yield takeLatest(gestionClaveActions.redirectFromDiffOrigins.type, redirectFromDiffOrigins);
}
export default function* rootSaga() {
  yield spawn(watchValidarRutToCrearClave);
  yield spawn(watchResetErrorValidarRut);
  yield spawn(watchFetchFactoresSeguridadCrearClave);
  yield spawn(watchFetchReglasFactores);
  yield spawn(watchFetchDesafioOTP);
  yield spawn(watchValidarDesafioOTP);
  yield spawn(watchFetchDatosPersonalesClearClave);
  yield spawn(watchFetchReglasCrearClave);
  yield spawn(watchCrearClave);
  yield spawn(watchRecuperarClave);
  yield spawn(watchSelectMetodo);
  yield spawn(watchFetchDesafioTCOO);
  yield spawn(watchValidarDesafioTCOO);
  yield spawn(watchFetchDesafioCPASS);
  yield spawn(watchValidarDesafioCPASS);
  yield spawn(watchRedirectFromDiffOrigins);
}
