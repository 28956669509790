import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import ButtonBack from '../../ButtonBack';
import Button from '@coopeuch-components/web/atoms/Button';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
import { registerGA } from '../../../../../utils/metrics';

// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const refresh = (accion, msgErrorGA) => {
  if (accion !== '' && msgErrorGA && msgErrorGA !== '') {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': `${accion} - ${msgErrorGA}`,
    });
  }
};

const CardBlockButton = ({ section, title, message, link, msgErrorGA }) => {
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} className="titleActivacionKit">
            {section}
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="cardBlockBody">
            <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="imageCenter" size={180} />
            <Typography align="center" tag="p" className="titleAlertKit">
              {title}
            </Typography>
            <Typography align="center" tag="p" className="descriptionKit">
              {message}
            </Typography>
          </div>
          <div className="contentSMS">
            <Button
              bgColor="#E81E2B"
              color="white"
              fontSize={14}
              className="contentSMS__btn"
              onClick={() => refresh('Reintentar', msgErrorGA)}
            >
              Reintentar
            </Button>
          </div>
        </CardBox.Body>
      </CardBox>
      <ButtonBack
        onClick={() => refresh(activacionTexts.BUTTON_RETURN, msgErrorGA)}
        link={link}
        text={activacionTexts.BUTTON_RETURN}
      />
    </Wrapper>
  );
};

export default CardBlockButton;
