import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// Components
import Error from '../../components/atoms/Error';
import HistorialTemplate from '../../components/templates/CuotaParticipacion/Historial';
// Actions
import { cuotaParticipacionActions } from '../../../application/actions/cuotaParticipacion';
// Utils
import { currentYear } from '../../utils/functions';
import { simpleFormatArrForSelect } from '../../utils/formatters';

const Historial = () => {
  const [mounted, setMounted] = useState(false);
  const [fillforSelect, setFillforSelect] = useState([]);
  const [yearSelected, setYearSelected] = useState(currentYear());
  const [detalleCuota, setDetalleCuota] = useState(null);
  const [isLoadMicroService, setIsLoadMicroService] = useState(false);

  // Metodos Redux
  const dispatch = useDispatch();
  const dataCuotasPagadas = useSelector(
    (store) => store.entities.cuotaParticipacion.dataCuotasPagadas
  );

  // Calcular años para array select
  const yearsToSearch = 6;

  const lastYears = (currentYear, substractYear) => {
    const yearsArr = [];
    for (let index = 0; index < substractYear; index++) {
      const year = currentYear - index;
      yearsArr.push(year);
    }
    return yearsArr;
  };
  const arrYears = lastYears(yearSelected, yearsToSearch);

  const handleDownloadHistorial = () => {
    dispatch(cuotaParticipacionActions.fetchDownloadPDF({ yearSelected }));
  };

  useEffect(() => {
    if (!mounted) {
      setFillforSelect(simpleFormatArrForSelect(arrYears, 'label', 'value'));
      setMounted(true);
      // console.log('montado');
      // console.log('llenado select');
    }
  }, [mounted]);

  useEffect(() => {
    if (yearSelected) {
      dispatch(cuotaParticipacionActions.fetchDataCuotasPagadas({ yearSelected }));
    }
  }, [yearSelected]);

  useEffect(() => {
    if (dataCuotasPagadas.success && dataCuotasPagadas.data.length > 0) {
      setDetalleCuota(dataCuotasPagadas.data);
    } else {
      setDetalleCuota(null);
    }
    if (dataCuotasPagadas.error) {
      setIsLoadMicroService(true);
    }
  }, [dataCuotasPagadas]);

  return (
    <>
      {isLoadMicroService ? (
        <Error className="bmt-3 bmt-md-4 bmb-xs-4 bmb-md-4" />
      ) : (
        <HistorialTemplate
          {...{
            fillforSelect,
            yearSelected,
            setYearSelected,
            detalleCuota,
            handleDownloadHistorial,
          }}
        />
      )}
    </>
  );
};
Historial.displayName = 'Historial_CP_container';
export default Historial;
