import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/empleador';

function fetchEmpleadorObtenerSuccess(state, action) {
  state.empleadorObtener = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchEmpleadorObtenerError(state) {
  state.empleadorObtener = {
    success: false,
    error: true,
    errorCode: '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchRegionObtenerSuccess(state, action) {
  state.regionObtener = {
    success: true,
    error: false,
    data: [{ codigo: '00', descripcion: '' }, ...action.payload.data],
    date: new Date().toISOString(),
  };
}

function fetchRegionObtenerError(state) {
  state.regionObtener = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchComunasSuccess(state, action) {
  state.comunasObtener = {
    success: true,
    error: false,
    data: [{ codigo: '00', codigoRegion: '00', descripcion: '' }, ...action.payload.data],
    date: new Date().toISOString(),
  };
}
function fetchComunasError(state) {
  state.comunasObtener = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

const fetchEmpleadorObtener = createAction('fetchEmpleadorObtener');
const fetchRegionObtener = createAction('fetchRegionObtener');
const fetchComunas = createAction('fetchComunas');

const empleadorReducer = createSlice({
  name: 'empleador',
  initialState,
  reducers: {
    fetchEmpleadorObtenerSuccess,
    fetchEmpleadorObtenerError,
    fetchRegionObtenerSuccess,
    fetchRegionObtenerError,
    fetchComunasSuccess,
    fetchComunasError,
  },
});

export const empleadorActions = {
  ...empleadorReducer.actions,
  fetchEmpleadorObtener,
  fetchRegionObtener,
  fetchComunas,
};

export default empleadorReducer.reducer;
