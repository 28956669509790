export default [
  {
    id: 'menu-1',
    parent: 'menu-',
    icon: 'home_alt.svg',
    name: 'Inicio',
    url: '/inicio',
    showChildren: false,
    children: [],
  },
  {
    id: 'menu-2',
    parent: 'menu-',
    icon: 'wallet.svg',
    name: 'Mis Productos',
    url: null,
    showChildren: true,
    children: [
      {
        id: 'menu-2-1',
        parent: 'menu-2',
        icon: null,
        name: 'Cuota de Participación',
        url: '/productos/cuota-de-participacion',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-2',
        parent: 'menu-2',
        icon: null,
        name: 'Remanente',
        url: '/productos/remanente',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-3',
        parent: 'menu-2',
        icon: null,
        name: 'Cuenta Vista',
        url: '/productos/cuenta-coopeuch',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-4',
        parent: 'menu-2',
        icon: null,
        name: 'Tarjeta de Crédito',
        url: '/productos/tarjeta-de-credito',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-5',
        parent: 'menu-2',
        icon: null,
        name: 'Cuenta de Ahorro',
        url: '/productos/cuenta-ahorro',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-6',
        parent: 'menu-2',
        icon: null,
        name: 'Depósito a Plazo',
        url: '/productos/deposito-a-plazo',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-7',
        parent: 'menu-2',
        icon: null,
        name: 'Crédito de Consumo',
        url: '/productos/credito-de-consumo',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-2-8',
        parent: 'menu-2',
        icon: null,
        name: 'Crédito Hipotecario',
        url: '/productos/credito-hipotecario',
        showChildren: false,
        children: [],
      },
    ],
  },
  {
    id: 'menu-3',
    parent: 'menu-',
    icon: 'money_exchange.svg',
    name: 'Transferencias',
    url: null,
    showChildren: false,
    children: [
      {
        id: 'menu-3-1',
        parent: 'menu-3',
        icon: null,
        name: 'Transferir fondos',
        url: '/transferir/fondos',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-3-2',
        parent: 'menu-3',
        icon: null,
        name: 'Mis destinatarios',
        url: '/transferir/destinatarios',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-3-3',
        parent: 'menu-3',
        icon: null,
        name: 'Historial',
        url: '/transferir/historial',
        showChildren: false,
        children: [],
      },
    ],
  },
  {
    id: 'menu-inversiones',
    parent: 'menu-',
    icon: 'investment.svg',
    name: 'Inversiones',
    url: '/inversiones/inicio',
    showChildren: false,
    children: [],
  },
  {
    id: 'menu-4',
    parent: 'menu-',
    icon: 'calculator.svg',
    name: 'Simulador Consumo',
    url: '/solicita/simulador-credito-de-consumo',
    showChildren: false,
    children: [],
  },
  {
    id: 'menu-6',
    parent: 'menu-',
    icon: 'deal.svg',
    name: 'Certificados',
    url: null,
    showChildren: false,
    children: [
      {
        id: 'menu-6-1',
        parent: 'menu-6',
        icon: null,
        name: 'Certificado N° 7',
        url: '/certificados/n-07',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-6-2',
        parent: 'menu-6',
        icon: null,
        name: 'Certificado N° 70',
        url: '/certificados/n-70',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-7-3',
        parent: 'menu-7',
        icon: null,
        name: 'Certificados Liquidación',
        url: '/certificados/liquidacion',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-6-3',
        parent: 'menu-6',
        icon: null,
        name: 'Productos Vigentes',
        url: '/certificados/productos-vigentes',
        showChildren: false,
        children: [],
      },
    ],
  },
  {
    id: 'menu-5',
    parent: 'menu-',
      icon: 'credit_card.svg',
      name: 'Mis Tarjetas',
    url: null,
    showChildren: false,
    children: [
      {
        id: 'menu-5-1',
        parent: 'menu-5',
        icon: null,
          name: 'Bloqueo/desbloqueo de Tarjetas',
          url: '/seguridad/bloqueo-de-tarjetas',
        showChildren: false,
        children: [],
      },
      {
        id: 'menu-5-2',
        parent: 'menu-5',
        icon: null,
        name: 'Activación de Tarjetas',
        url: '/seguridad/activacion-tarjetas',
        showChildren: false,
        children: [],
      },
    ],
  },
  {
    id: 'menu-6',
    parent: 'menu-',
    icon: 'gift.svg',
    name: 'Beneficios',
    url: null,
    showChildren: false,
    children: [
      {
        id: 'menu-6-1',
        parent: 'menu-6',
        icon: null,
        name: 'Descuentos y promociones',
        url: '/conoce/beneficios-y-ofertas',
        showChildren: false,
        children: [],
      },
    ],
  },
  // {
  //   id: 'menu-9',
  //   parent: 'menu-',
  //   icon: 'magnifying_glass_red.svg',
  //   name: 'Aclaración de Movimientos',
  //   url: '/aclaracion-de-movimientos',
  //   showChildren: false,
  //   children: [],
  // },
  // {
  //   id: 'menu-7',
  //   parent: 'menu-',
  //   icon: 'puntos_coopeuch.svg',
  //   name: 'Puntos Coopeuch',
  //   url: '#',
  //   showChildren: false,
  //   children: [],
  // },
];
