import React from 'react';
import DashboardCard from '../../../organism/DashboardCard';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ErrorDashboard = ({ descText }) => {
  return (
    <Wrapper>
      <DashboardCard classes="dashboard-card-shadow">
        <DashboardCard.Body classes="card-error-container">
          <div className="text-align-center bmb-2">
            <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="icon-error-card" />
          </div>

          <div className="row m-0">
            <div className="w-100 text-center">
              <label className="error-card-desc">
                <div dangerouslySetInnerHTML={{ __html: `<div>${descText}</div>` }} />
              </label>
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </Wrapper>
  );
};

ErrorDashboard.defaultProps = {
  descText: 'Actualmente no está disponible',
};

export default ErrorDashboard;
