import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// UI
import Button from '@coopeuch-components/web/atoms/Button';
import { addClass } from '@coopeuch-components/web';

// Styles
import Wrapper, { Title, BtnWrapper } from './styles';

const CardBody = ({
  classes,
  children,
  title,
  titleClass,
  btnText,
  btnWidth,
  btnClass,
  btnDataSet,
  linkText,
  linkUrl,
  linkDataSet,
  linkClass,
  cbLink,
  padding,
}) => (
  <Wrapper className={addClass(['wrapper-container', classes])} padding={padding}>
    {title && (
      <div className="row">
        <div className="col-xs">
          <Title className={titleClass}>{title}</Title>
        </div>
      </div>
    )}
    <div className="row">
      <div className="col-xs">{children}</div>
    </div>
    <div className="dashboard--wrapper-btn-link">
      <div className="row middle-xs">
        {btnText && (
          <div className={addClass(['col-xs-12 col-sm-6', btnClass])}>
            <BtnWrapper className="row" btnWidth={btnWidth}>
              <div className="col-xs-12 col-sm-6">
                <Button
                  bgColor="#e81e2b"
                  dataset={{
                    id: btnDataSet,
                  }}
                  color="white"
                  className="action-button"
                  onClick={cbLink}
                  fullWidth
                  id="action-button"
                >
                  {btnText}
                </Button>
              </div>
            </BtnWrapper>
          </div>
        )}
        {linkText && (
          <div className="col-xs-12 col-sm-6 last-sm first-xs mt-8 mb-8">
            <div className={addClass(['dashboard-link--btn', linkClass])}>
              <Link to={linkUrl} data-id={linkDataSet} onClick={cbLink} className="red">
                {linkText}
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  </Wrapper>
);

CardBody.propTypes = {
  classes: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.string,
  btnText: PropTypes.string,
  btnWidth: PropTypes.number,
  linkDataSet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  btnDataSet: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  btnClass: PropTypes.string,
  linkClass: PropTypes.string,
  padding: PropTypes.string,
};

CardBody.defaultProps = {
  btnWidth: 250,
  classes: 'dashboardcard-body',
  linkDataSet: null,
  titleClass: '',
  btnClass: '',
  linkClass: '',
  padding: '8px 26px 20px 26px',
};

export default CardBody;
