import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { remanenteActions } from '../../../application/actions/remanente';
import { loadersActions } from '../../../application/actions/ui_loaders';
import { logReqError } from '../../../ui/utils/functions';

// api
import api from '../../api';

const apiRemanente = api(process.env.REACT_APP_REMANENTE);
const apiConfiguracion = api(process.env.REACT_APP_CONFIGURATION_API);

export function* fetchRemanente(action) {
  try {
    yield put(loadersActions.fetchLoadingRemanente({ isLoading: true }));
    const { data, error } = yield call(apiRemanente.get, 'pago/v1/obtener');
    if (data) {
      yield put(
        remanenteActions.fetchRemanenteSuccess({
          saldoIndividual: { ...data, sinRemanente: false },
        })
      );
    } else if (error) {
      if (error?.statusCode === 404 && error?.code === 'RE-03') {
        yield put(
          remanenteActions.fetchRemanenteSuccess({ saldoIndividual: { sinRemanente: true } })
        );
      } else {
        yield put(remanenteActions.setRemanenteMainError(error));
      }
    }
    yield put(loadersActions.fetchLoadingRemanente({ isLoading: false }));
  } catch (err) {
    console.error(err.message, 'fetchRemanente');
    yield put(remanenteActions.setRemanenteMainError(err));
    yield put(loadersActions.fetchLoadingRemanente({ isLoading: false }));
  }
}

export function* fetchComprobanteCobroRemanente(action) {
  try {
    yield put(loadersActions.fetchLoadingComprobanteCobroRemanente({ isLoading: true }));
    const { flujoId, fechaContratacion } = action.payload;
    const { data, error } = yield call(apiRemanente.post, 'pago/documento/comprobante/v1/obtener', {
      flujoId,
      fechaContratacion,
    });
    if (data) {
      yield put(remanenteActions.fetchComprobanteCobroSuccess({ data }));
    }
    if (error) {
      yield put(remanenteActions.fetchComprobanteCobroFailed({ errorLog: error }));
      console.error(error, 'fetchComprobanteCobroRemanente');
    }
    yield put(loadersActions.fetchLoadingComprobanteCobroRemanente({ isLoading: false }));
  } catch (err) {
    console.error(err.message, 'fetchComprobanteCobroRemanente');
    yield put(remanenteActions.fetchComprobanteCobroFailed({ errorLog: err }));
    yield put(loadersActions.fetchLoadingComprobanteCobroRemanente({ isLoading: false }));
  }
}

export function* fetchBannerEstacional() {
  try {
    const { data, error } = yield call(apiRemanente.get, 'web/bannerestacional/v1/obtener');

    yield logReqError(error, 'GET');

    if (data) {
      yield put(
        remanenteActions.fetchBannerEstacionalSuccess({
          bannerEstacional: data,
        })
      );
    }
  } catch (err) {
    console.error(err.message, 'fetchBannerEstacional');
  }
}

function* _fetchComposicion(res) {
  const { data, error } = res;
  if (data) {
    yield put(
      remanenteActions.fetchRemanenteComposicionSuccess({
        composicion: data,
      })
    );
  }
  if (error) {
    if (error?.statusCode === 404 && error?.code === 'RE-03') {
      const _data = [
        { baseCalculo: '0', monto: '0', tipoOperacion: 'Cuotas de participación' },
        { baseCalculo: '0', monto: '0', tipoOperacion: 'Operaciones crédito' },
        { baseCalculo: '0', monto: '0', tipoOperacion: 'Operaciones captación' },
        { baseCalculo: '0', monto: '0', tipoOperacion: 'Operaciones terceros' },
        { monto: '0', tipoOperacion: 'Remanente pendiente' },
        { monto: '0', tipoOperacion: 'Total' },
      ];
      yield put(
        remanenteActions.fetchRemanenteComposicionSuccess({
          composicion: _data,
        })
      );
      yield put(
        remanenteActions.fetchRemanenteComposicionError(error)
      );
    } else {
      yield logReqError(error, 'GET');
      yield put(remanenteActions.setRemanenteMainError(error));
    }
  }
}

function* _fetchContenido(res) {
  const { data, error } = res;

  yield logReqError(error);

  if (data) {
    yield put(remanenteActions.fetchRemanenteContenidoSuccess({ contenido: data }));
  }
  if (error) {
    yield put(remanenteActions.setRemanenteMainError(error));
    // console.log(error);
  }
}

export function* fetchRemanenteComposicion() {
  try {
    yield put(loadersActions.fetchLoadingComposicionRemanente({ isLoading: true }));
    const { data, error } = yield call(apiRemanente.get, 'web/composicion/v1/obtener');
    yield _fetchComposicion({ data, error });
    yield put(loadersActions.fetchLoadingComposicionRemanente({ isLoading: false }));
  } catch (err) {
    yield put(remanenteActions.setRemanenteMainError(err));
    yield put(loadersActions.fetchLoadingComposicionRemanente({ isLoading: false }));
  }
}

export function* fetchRemanenteContenido() {
  try {
    yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: true }));
    const { data, error } = yield call(apiRemanente.get, 'web/composicion/estructura/v1/obtener');
    // yield logReqError(error, 'GET');
    yield _fetchContenido({ data, error });
    yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: false }));
  } catch (err) {
    yield put(remanenteActions.setRemanenteContenidoError(true));
    yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: false }));
  }
}



export function* fetchRemanenteSwitchOnOff() {
  try {
    yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: true }));
    const { data, error } = yield call(apiConfiguracion.get, 'feature-flags/v1/obtener');
    if(data){
      yield put(remanenteActions.fetchRemanenteSwitchOnOffSuccess({data}));
      yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: false }));
    }
    if(error){
      yield put(remanenteActions.fetchRemanenteSwitchOnOffFailed({ error }));
      yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: false }));
    }
   
  } catch (err) {
    yield put(remanenteActions.setRemanenteContenidoError(true));
    yield put(loadersActions.fetchLoadingContenidoRemanente({ isLoading: false }));
  }
}


export function* fetchCobroRemanenteWeb() {
  try {
    const { data, error } = yield call(apiRemanente.get, 'pago/habilitado/v1/cobrar');
    if (data) {
      yield put(remanenteActions.fetchCobroRemanenteWebSuccess(data));
    }
    if (error) {
      const errorData = {
        error: true,
        ...error,
        errorListaNegra: error.code === 'RE-25' ? true : false,
      };
      yield put(remanenteActions.fetchCobroRemanenteWebError(errorData));
    }
  } catch (err) {
    console.log(err.message);
  }
}

export function* cobrarRemanente(action) {
  const numeroCuenta = action.payload;
  try {
    yield put(loadersActions.fetchLoadingCobrarRemanente({ isLoading: true }));
    const { data, error } = yield call(apiRemanente.put, 'pago/v1/cobrar', { numeroCuenta });
    if (data) {
      const date = new Date(data.fecha);
      const capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
      };
      const finalData = {
        ...data,
        fecha: `${date.getDate()} de ${capitalize(
          date.toLocaleString('default', {
            month: 'long',
          })
        )} ${date.getFullYear()}`,
        rawFecha: data.fecha,
        error: false,
        success: true,
        email: data.correo,
      };
      yield put(remanenteActions.cobrarRemanenteSuccess(finalData));
    }
    if (error) {
      const errorData = {
        error: true,
      };
      yield put(remanenteActions.cobrarRemanenteError({ errorData, errorLog: error }));
    }
    yield put(loadersActions.fetchLoadingCobrarRemanente({ isLoading: false }));
  } catch (err) {
    console.log(err.message);
    const errorData = {
      error: true,
    };
    yield put(remanenteActions.cobrarRemanenteError({ errorData, errorLog: err }));
    yield put(loadersActions.fetchLoadingCobrarRemanente({ isLoading: false }));
  }
}

export function* fetchEnvioEmail(action) {
  try {
    const { correo, flujoId } = action.payload;
    const { data, error } = yield call(apiRemanente.post, 'notificacion/email/v1/enviar', {
      correo,
      flujoId
    });
    if (data) {
      yield put(remanenteActions.fetchEnvioEmailSuccess({envioEmail: data}));
    } else {
      yield put(remanenteActions.fetchEnvioEmailError({ error }));
    }
  } catch (err) {
    yield put(remanenteActions.fetchEnvioEmailError());
    console.log(err.message, 'catch error');
  }
}

export function* watchFetchRemanente() {
  yield takeLatest(remanenteActions.fetchRemanente.type, fetchRemanente);
}

export function* watchBannerEstacional() {
  yield takeLatest(remanenteActions.fetchBannerEstacional.type, fetchBannerEstacional);
}

export function* watchFetchRemanenteComposicion() {
  yield takeLatest(remanenteActions.fetchRemanenteComposicion.type, fetchRemanenteComposicion);
}
export function* watchFetchRemanenteContenido() {
  yield takeLatest(remanenteActions.fetchRemanenteContenido.type, fetchRemanenteContenido);
}

export function* watchFetchCobroRemanenteWeb() {
  yield takeLatest(remanenteActions.fetchCobroRemanenteWeb.type, fetchCobroRemanenteWeb);
}

export function* watchCobrarRemanente() {
  yield takeLatest(remanenteActions.cobrarRemanente.type, cobrarRemanente);
}

export function* watchFetchComprobanteCobroRemanente() {
  yield takeLatest(remanenteActions.fetchComprobanteCobro.type, fetchComprobanteCobroRemanente);
}

export function* watchFetchEnvioEmail() {
  yield takeLatest(remanenteActions.fetchEnvioEmail.type, fetchEnvioEmail);
}
export function* watchFetchRemanenteSwitchOnOff() {
  yield takeLatest(remanenteActions.fetchRemanenteSwitchOnOff.type, fetchRemanenteSwitchOnOff);
}



/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */
export default function* rootSaga() {
  yield spawn(watchFetchRemanente);
  yield spawn(watchBannerEstacional);
  yield spawn(watchFetchRemanenteComposicion);
  yield spawn(watchFetchRemanenteContenido);
  yield spawn(watchFetchCobroRemanenteWeb);
  yield spawn(watchCobrarRemanente);
  yield spawn(watchFetchComprobanteCobroRemanente);
  yield spawn(watchFetchEnvioEmail);
  yield spawn(watchFetchRemanenteSwitchOnOff)
}
