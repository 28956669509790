const initState = {
  cuentaVista: {
    error: false,
    account: null,
    assoCards: {},
    balance: [],
    sinProductos: false,
    lastMovements: {},
    movements: {},
    pdfMovements: { success: false, error: false, data: null },
    loaders: {
      isMvts: true,
      isLastMvts: false,
    },
  },
  bloqueoTemporalTarjetas: {
    tarjetasDisponibles: { success: false, error: false, data: null },
  },
  errorCuentasVistas: false,
  dashboardProductos: [],
  dashboardProductosError: false,
  errCuentasLog: null,
  errMovimientosLog: null,
  errTarjetasLog: null,
  errUltMovimientosLog: null,
  cambioEstadoTjDebito: false,
  errorCambioEstadoTjDebito: false,
  mensajeErrorCambioEstadoTjDebito: '',
  statusCambioEstadoTjDebito : false,
};

export default initState;
