import {createAction, createSlice} from "@reduxjs/toolkit";
import initialState from "../../../domain/entities/avanceTarjetaCredito";

// ------- SAGA ------ //

function fetchValidarAptoAvanceSuccess(state, action) {
  const { data } = action.payload;
  state.validarAptoAvance.apto = data?.usuarioEsAptoParaAvance || false;
  state.validarAptoAvance.error = null;
}

function fetchValidarAptoAvanceError(state, action) {
  const { error } = action.payload;
  state.validarAptoAvance.apto = false;
  state.validarAptoAvance.error = error;
}

function resetValidarAptoAvance(state) {
  state.validarAptoAvance.apto = false;
  state.validarAptoAvance.error = null;
}

const fetchValidarAptoAvance = createAction('fetchValidarAptoAvance');

// ------------- connection ------------- //
const avanceTarjetaCreditoReducer = createSlice({
  name: 'avanceTarjetaCredito',
  initialState,
  reducers: {
    fetchValidarAptoAvanceSuccess,
    fetchValidarAptoAvanceError,
    resetValidarAptoAvance,
  }
});

export const avanceTarjetaCreditoActions = {
  ...avanceTarjetaCreditoReducer.actions,
  fetchValidarAptoAvance,
};

export default avanceTarjetaCreditoReducer.reducer;
