import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
  perfilComercial: '',
  userId: '',
};

// ------- SAGA ------ //
function getPerfilarSuccess(state, action) {
  state.perfilComercial = action.payload.data;
}

function setUserIdDataLayer(state, action) {
  const { payload } = action;
  state.userId = payload;
}

const fetchPerfilCliente = createAction('fetchPerfilCliente');

const perfilarReducer = createSlice({
  name: 'perfilComercial',
  initialState,
  reducers: {
    getPerfilarSuccess,
    setUserIdDataLayer,
  },
});

export const perfilComercialActions = {
  ...perfilarReducer.actions,
  fetchPerfilCliente,
};

export default perfilarReducer.reducer;
