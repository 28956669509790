import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasActions } from '../../../../application/actions/activacionTarjetas';
import ActivacionTemplate from '../../../components/templates/ActivacionTarjetas/Activacion';
// Utils
import { registerGA } from '../../../utils/metrics';

const Activacion = ({ steps }) => {
  const dispatch = useDispatch();

  const kitData = useSelector((store) => store.entities.activacionTarjetas.kitData);
  const validateTC = useSelector((store) => store.entities.activacionTarjetas.validateTC);
  const validateTD = useSelector((store) => store.entities.activacionTarjetas.validateTD);
  const statusCode = useSelector((store) => store.entities.activacionTarjetas.kitData.error);
  const availablePassCoopeuch = useSelector((store) => store.entities.activacionTarjetas.availablePassCoopeuch);

  const [cardSerieOne, setCardSerieOne] = useState('');
  const [cardSerieTwo, setCardSerieTwo] = useState('');
  const [cardSerieThree, setCardSerieThree] = useState('');
  const [cardSerieFour, setCardSerieFour] = useState('');
  const [desafio1, setDesafio1] = useState('');
  const [desafio2, setDesafio2] = useState('');
  const [desafio3, setDesafio3] = useState('');

  //Boolean
  const [error, setError] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const handleSetCardSerie = (id, value) => {
    id === 'serie1' && setCardSerieOne(value);
    id === 'serie2' && setCardSerieTwo(value);
    id === 'serie3' && setCardSerieThree(value);
    id === 'serie4' && setCardSerieFour(value);
  };

  const handleClickContinue = () => {
    
    const numSerie = `${cardSerieOne}${cardSerieTwo}${cardSerieThree}${cardSerieFour}`;
      dispatch(
        activacionTarjetasActions.fetchValidarTarjetaCoordenadas({
          idTarjetaCoordenadas: kitData.data.idTarjetaCoordenadas,
          numeroSerie: numSerie,
        })
      );

      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso1',
        'eve-cat': 'Click',
        'eve-lab': 'Continuar',
      });
    /*}else{
      setError('TC-01');
    }*/
    dispatch(activacionTarjetasActions.fetchIsEnabledPassCoopeuch());

  };

  const retryButton = () => {
    setError(false);
    setCardSerieOne('');
    setCardSerieTwo('');
    setCardSerieThree('');
    setCardSerieFour('');
    const serie1 =document.getElementById('serie1');
    serie1.value='';
    const serie2 =document.getElementById('serie2');
    serie2.value='';
    const serie3 =document.getElementById('serie3');
    serie3.value='';
    const serie4 =document.getElementById('serie4');
    serie4.value='';
    setDesafio1('');
    setDesafio2('');
    setDesafio3('');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Reintentar - Servicio no disponible',
    });
  };

  const handleClickBack = (accion) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso1',
      'eve-cat': 'Click',
      'eve-lab': `${accion}`,
    });
  };

  useEffect(() => {
    console.log("Status code: " , statusCode)
    registerGA({
      event: 'vpv-privado',
      page: '/tef/seguridad/activacionTarjetas/paso1',
    });
  }, [statusCode]);

  // Validar para no entrar directamente a la URL activacion
  useEffect(() => {
    if (kitData.data?.activable === false || kitData.error !== null || kitData.data === null) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas');
    }
  }, []);

  useEffect(() => {
    console.log("VALIDA TC: ", validateTC)

    if(validateTC.data === false) {
      console.log("VALIDA TC: TC-01")
      setError('TC-01');
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso1',
        'eve-cat': 'Error',
        'eve-lab': 'Numero de tarjeta no coincide',
      });
      return;
    }

    if (validateTC.data !== true) {
      setError(validateTC.error?.code);
    }
    if (validateTC.data === true) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas/autorizacion');
    }
  }, [validateTC]);

  

  useEffect(() => {
    if (
      cardSerieOne !== '' &&
      cardSerieTwo !== '' &&
      cardSerieThree !== '' &&
      cardSerieFour !== '' 
    ) {
      setDisabledBtn(false);
    } else {
      setDisabledBtn(true);
    }
  }, [cardSerieOne, cardSerieTwo, cardSerieThree, cardSerieFour]);

  return (
    kitData.data?.activable === true &&
    kitData.error === null && (
      <ActivacionTemplate
        {...{
          steps,
          handleSetCardSerie,
          disabledBtn,
          handleClickContinue,
          error,
          retryButton,
          kitData,
          cardSerieOne,
          cardSerieTwo,
          cardSerieThree,
          cardSerieFour,
          handleClickBack,
          desafio1,
          desafio2,
          desafio3,
          validateTC
        }}
      />
    )
  );
};

export default Activacion;
