import styled from 'styled-components';
import { colors } from '../../../assets/styles';
import { devices } from '../../../utils/breakpoints';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ minWidth }) => `${minWidth}px`};
`;

export const BaseHeader = styled.div`
  background-color: ${() => colors.red};
`;
export const BaseContentRow = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  /* .menu-container-border {
    border-right: 1px solid #c3c6dc !important;
  } */
`;

export const BaseFooter = styled.div`
  /* max-width: 1280px;
  margin: 0 auto; */
  margin: 0 auto;
  width: 100%;
  @media screen and (min-width: 64rem) {
    /* position: absolute;
    bottom: 0; */
    border-left: 1px solid #e9e9e9;
  }
`;

export const FakePageTitle = styled.div`
  background-color: #e4e5ed;
  border-bottom: 1px solid #c3c6dc;
  position: absolute;
  z-index: 0;
  height: 65px;
  width: 100%;
`;

export const BaseContent = styled.div`
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  @media screen and (min-width: 64rem) {
    border-left: 1px solid #e9e9e9;
  }
  .tab--wrapper-shadows {
    -webkit-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
  }

  @media screen and (max-width: 768px) {
    .tabs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 !important;
      text-align: center;
    }
  }
`;
export const ContainerMenuContentFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: self-start;
  justify-content: center;
  background-color: #fff !important;
  /* & div:first-child{
    background-color:#f00!important;
  } */
`
export const ContainerParent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ContainerComponent = styled.div`
  ${({ hideIvicIcon }) => hideIvicIcon && `display: none;`}

  position: fixed;
  width: 58px;
  height: 58px;
  z-index: 4;
  right: 30px;
  bottom: 50px;
  margin: auto;

  @media ${devices.mobileS} {
    right: 10px;
  }
  @media ${devices.laptop} {
    right: 6px;
  }
  @media ${devices.laptopL} {
    right: 27px;
  }
  @media ${devices.desktop} {
    right: 27px;
  }

  .button {
    cursor: pointer;
    pointer-events: auto;
    position: absolute;
    right: -6px;
    top: -6px;
    background-color: #ffffff00;
    padding: 0px;
    border: 0px;
    z-index: 4;

    /* @media ${devices.mobileS} {
      top: -74px;
    } */
  }
`;
export const ContainerIcons = styled.div`
  overflow: hidden;
  position: absolute;
  background-color: rgb(255 255 255);
  box-shadow: 0 2px 10px 0 rgba(22, 22, 22, 0.2);
  padding: 5px 2px;
  border-radius: 50px;
  width: 58px;
  height: 58px;

  .clickeable {
    cursor: pointer;
    pointer-events: auto;
  }
  .icon {
    margin-bottom: 4px;
    text-align: center;
    font-size: 10px;
    margin-top: 3px !important;
    animation: fade 1s;
    transition: all 0.5s ease;
    background-color: #ffffff;
    .child {
      margin: 0 auto;
    }
    span {
      margin-top: 3px;
      display: block;
    }
    div,
    svg {
      background-color: transparent !important;
    }
    &:first-child {
      margin-bottom: 0px;
    }
    &:last-child {
      /* margin-top: 4px !important; */
    }
    & :hover {
      background-color: #2d47b526;
      border-radius: 50px;
    }
  }
`;

export const ButtonIcon = styled.button`
  cursor: pointer;
  pointer-events: auto;
  background-color: #ffffff00;
  padding: 4px;
  border: 0px;
  border-radius: 50px;
  transition: all 0.5s ease;
  width: 44px;
  height: 44px;
`;

export const Content = styled.div`
  width: calc(100vw - 24.8vw);
  padding: 0px;
  position: relative;
  @media (max-width: 75em) {
    width: 100%;
}
`
export default Container;
