const defaultRequest = {
  id: 500,
  title: 'Lo sentimos',
  message:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  showInput: false,
};

export const errPassCoopeuchFactor = {
  default: defaultRequest,
  8: {
    id: 8,
    title: '¡Operación rechazada!',
    message: 'Has rechazado la autorización. Si quieres, <br> puedes intentarlo nuevamente.'
  },
  1: {
    id: 1,
    title: '¡Lo sentimos!',
    message: 'La transferencia no ha podido ser realizada, por favor intenta nuevamente.',
  },
  12: {
    id: 12,
    title: '¡Lo sentimos!',
    message: 'Agregar destinatarios no ha podido ser realizado, por favor intenta nuevamente',
  },
  13: {
    id: 13,
    title: '¡Operación rechazada!',
    message: 'Haz rechazado agregar destinatario. Si quieres, puedes intentarlo nuevamente.',
  },
  14: {
    id: 14,
    title: 'Se agotó el tiempo',
    message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
  },
  15: {
    id: 15,
    title: '¡Operación rechazada!',
    message: 'Haz rechazado la transferencia. Si quieres, puedes intentarlo nuevamente',
  },
  7: {
    id: 7,
    title: 'Lo sentimos',
    message: 'Tu clave PassCoopeuch ha sido bloqueada. Puedes registrarte nuevamente en la app para desbloquearla.',
    hideButton: true,
  },
};

export const errSMSFactor = {
  default: defaultRequest,
  1: {
    id: 1,
    title: 'Código SMS no corresponde',
    message: 'Recuerda que al tercer intento erróneo, la clave será bloqueada por seguridad.',
    showInput: true,
  },
  2: {
    id: 2,
    title: 'Código SMS bloqueado',
    message:
      'Has superado la cantidad de intentos permitidos. Para más información, llama al <b class="text-bold">Contact Center <u>600 200 1200</u></b>',
    hideButton: true,
  },
  3: {
    id: 3,
    title: '¡Se agotó el tiempo!',
    message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
  },
  4: {
    id: 4,
    title: '¡Se agotó el tiempo!',
    message: 'Has superado el límite de intentos de envio de sms por transacción.',
    hideButton: true,
  },
};

export const errTarjetaCoordenadasFactor = {
  1: {
    id: 1,
    title: '¡Se agotó el tiempo!',
    message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
    showInput: false,
  },
  2: {
    id: 2,
    title: '¡Lo sentimos!',
    message: 'Las coordenadas no corresponden.<br>Por favor, intenta nuevamente.',
    showInput: false,
  },
  3: {
    id: 3,
    title: '¡Lo sentimos!',
    message:
      'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
    showInput: false,
  },
  4: {
    id: 4,
    title: '¡Operación rechazada!',
    message:
      'Por su seguridad, su tarjeta de coordenadas ha sido bloqueada, ha superado la cantidad de intentos',
    showInput: false,
  },
  5: {
    id: 5,
    title: '¡Lo sentimos!',
    message: 'Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente en App Coopeuch',
    showInput: false,
  },
};

export const errRecuperarClave = {
  1: {
    id: 1,
    message: 'La contraseña debe ser distinta a la anterior.',
  },
};

export default defaultRequest;
