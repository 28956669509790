/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/tarjetaCredito';

// ------- SAGA ------ //

function fetchTarjetasCreditoSuccess(state, action) {
  state.obtenerTarjetas = {
    error: false,
    success: true,
    tarjetas: action.payload,
  };
}

function fetchTarjetasCreditoError(state) {
  state.obtenerTarjetas = {
    error: true,
    success: false,
    tarjetas: [],
  };
}

function fetchTarjetasCreditoTCSuccess(state, action) {
  const { tarjetasCredito } = action.payload;
  state.tarjetasCredito = tarjetasCredito;
  state.tarjetaCreditoError = false;
}

function fetchTarjetasCreditoTCFailed(state) {
  state.tarjetasCredito = [];
  state.tarjetaCreditoError = true;
}

function fetchFacturacionTarjetaCreditoSuccess(state, action) {
  const { facturacionTarjetaCredito } = action.payload;
  state.facturacionTarjetaCredito = facturacionTarjetaCredito;
  state.facturacionTarjetaCreditoError = false;
}

function fetchFacturacionTarjetaCreditoFailed(state) {
  state.facturacionTarjetaCredito = {};
  state.facturacionTarjetaCreditoError = true;
}

function fetchCupoTarjetaCreditoSuccess(state, action) {
  const { cupoTarjetaCredito } = action.payload;
  state.cupoTarjetaCredito = cupoTarjetaCredito;
  state.cupoTarjetaCreditoError = false;
}

function fetchCupoTarjetaCreditoFailed(state) {
  state.cupoTarjetaCredito = {};
  state.cupoTarjetaCreditoError = true;
}

function fetchPatPassTarjetaCreditoSuccess(state, action) {
  const { patPass } = action.payload;
  state.patPass = patPass;
  state.patPassError = false;
}

function fetchPatPassTarjetaCreditoFailed(state) {
  state.patPass = '';
  state.patPassError = true;
}

function pagarTarjetaCreditoSuccess(state, action) {
  const { data } = action.payload;

  state.pagoConfirmado = data;
  state.pagoError = {};
}

function pagarTarjetaCreditoFailed(state, action) {
  const { error } = action.payload;

  state.pagoConfirmado = '';
  state.pagoError = error;
}

function clearLastPago(state) {
  state.pagoConfirmado = '';
  state.pagoError = {};
  state.pagoComprobante = {};
  state.pagoComprobanteError = false;
}

function pagarTarjetaCreditoComprobanteSuccess(state, action) {
  const { data } = action.payload;

  state.pagoComprobante = data;
  state.pagoComprobanteError = false;
}

function pagarTarjetaCreditoComprobanteFailed(state) {
  state.pagoComprobante = {};
  state.pagoComprobanteError = true;
}

function fetchCupoTarjetaAdicionalesSuccess(state, action) {
  const { data, numeroTarjeta } = action.payload;

  state.cuposTarjetasAdicionales[numeroTarjeta] = data;
}

function fetchCupoTarjetaAdicionalesFailed(state) {
  state.cuposTarjetasAdicionales = {};
  state.cuposTarjetasAdicionalesError = true;
}

function clearCuposAdicionales(state) {
  state.cuposTarjetasAdicionales = {};
  state.cuposTarjetasAdicionalesError = false;
}

function resetPatPass(state) {
  state.patPass = '';
  state.patPassError = false;
}

function fetchMovimientosNoFacturadosSuccess(state, action) {
  const { data } = action.payload;

  state.movimientos = data;
  state.movimientosError = false;
}

function fetchMovimientosNoFacturadosFailed(state) {
  state.movimientos = {};
  state.movimientosError = true;
}

function resetEstadoCuenta(state) {
  state.pdfTarjetaCredito = '';
  state.pdfTarjetaCreditoError = false;
  state.errorNoEstadoDeCuenta = false;
}

function fetchPDFTarjetaCreditoSuccess(state, action) {
  const { pdfTarjetaCredito } = action.payload;
  state.pdfTarjetaCredito = pdfTarjetaCredito;
  state.pdfTarjetaCreditoError = false;
}

function fetchPDFTarjetaCreditoFailed(state) {
  state.pdfTarjetaCredito = '';
  state.pdfTarjetaCreditoError = true;
}

function fetchErrorNoEstadoDeCuenta(state) {
  state.errorNoEstadoDeCuenta = true;
}

function fetchErrorNoEstadoDeCuentaReset(state) {
  state.errorNoEstadoDeCuenta = false;
}

const fetchTarjetasCredito = createAction('fetchTarjetasCredito');
const fetchTarjetasCreditoTC = createAction('fetchTarjetasCreditoTC');
const fetchFacturacionTarjetaCredito = createAction('fetchFacturacionTarjetaCredito');
const fetchCupoTarjetaCredito = createAction('fetchCupoTarjetaCredito');
const fetchPatPassTarjetaCredito = createAction('fetchPatPassTarjetaCredito');
const fetchPagarTarjetaCredito = createAction('fetchPagarTarjetaCredito');
const pagarTarjetaCreditoComprobante = createAction('pagarTarjetaCreditoComprobante');
const fetchCupoOfTarjetaAdicionales = createAction('fetchCupoOfTarjetaAdicionales');
const fetchMovimientosNoFacturados = createAction('fetchMovimientosNoFacturados');
const fetchPDFTarjetaCredito = createAction('fetchPDFTarjetaCredito');

// ------------- connection ------------- //

const tarjetaCreditoReducer = createSlice({
  name: 'monedero',
  initialState,
  reducers: {
    fetchTarjetasCreditoSuccess,
    fetchTarjetasCreditoError,
    fetchTarjetasCreditoTCSuccess,
    fetchTarjetasCreditoTCFailed,
    fetchFacturacionTarjetaCreditoSuccess,
    fetchFacturacionTarjetaCreditoFailed,
    fetchCupoTarjetaCreditoSuccess,
    fetchCupoTarjetaCreditoFailed,
    fetchPatPassTarjetaCreditoSuccess,
    fetchPatPassTarjetaCreditoFailed,
    pagarTarjetaCreditoSuccess,
    pagarTarjetaCreditoFailed,
    pagarTarjetaCreditoComprobanteSuccess,
    pagarTarjetaCreditoComprobanteFailed,
    clearLastPago,
    fetchCupoTarjetaAdicionalesSuccess,
    fetchCupoTarjetaAdicionalesFailed,
    clearCuposAdicionales,
    resetPatPass,
    fetchMovimientosNoFacturadosSuccess,
    fetchMovimientosNoFacturadosFailed,
    fetchPDFTarjetaCreditoSuccess,
    fetchPDFTarjetaCreditoFailed,
    resetEstadoCuenta,
    fetchErrorNoEstadoDeCuenta,
    fetchErrorNoEstadoDeCuentaReset,
  },
});

export const tarjetaCreditoActions = {
  ...tarjetaCreditoReducer.actions,
  fetchTarjetasCredito,
  fetchTarjetasCreditoTC,
  fetchFacturacionTarjetaCredito,
  fetchCupoTarjetaCredito,
  fetchPatPassTarjetaCredito,
  fetchPagarTarjetaCredito,
  pagarTarjetaCreditoComprobante,
  fetchCupoOfTarjetaAdicionales,
  fetchMovimientosNoFacturados,
  fetchPDFTarjetaCredito,
};

export default tarjetaCreditoReducer.reducer;
