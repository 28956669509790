import React from 'react';
// UI
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Expand from '../../../atoms/Expand';
// styles
import ExpandContent from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const RemanenteInfo = (props) => (
  <Expand elevation={1} iconColor="#00A1DF" rounded>
    <Expand.Item position={1}>
      <Expand.Header color="white">
        <ExpandContent className="row">
          <LoadIcon src={`${iconPath}book.svg`} size={32} color="#383638" />
          <h5 className="text-bold">Lo que debes saber sobre Remanente</h5>
        </ExpandContent>
      </Expand.Header>
      <Expand.Body>
        <ExpandContent>
          <ul>
            <li className="bullet-red">
              <span className="text-bold">Puedes aumentar tu remanente</span>
              <div>
                Mientras más productos de la cooperativa utilices (tarjetas de crédito y débito,
                créditos de consumo e hipotecario, cuentas de ahorro, entre otros) mayor será tu
                remanente.
              </div>
            </li>
            <li className="bullet-red">
              <span className="text-bold">
                Aspecto diferenciador del modelo empresarial cooperativo
              </span>
              <div>
                Se distribuye entre todos sus socios de acuerdo a la proporción de cuotas de
                participación promedio, el uso de productos y un comportamiento responsable al
                mantener sus cuotas y pagos al día.
              </div>
            </li>
            <li className="bullet-red">
              <span className="text-bold">Genera un círculo virtuoso en todo el país</span>
              <div>
                Cerca de un 80% del remanente llega a regiones, recursos que se quedan donde viven
                nuestros socios, dinamizando las economías locales y generando, de esa manera, un
                círculo virtuoso.
              </div>
            </li>
          </ul>
        </ExpandContent>
      </Expand.Body>
    </Expand.Item>
  </Expand>
);

export default RemanenteInfo;
