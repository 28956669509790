import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

function IconoOjo({ passwordInputIcon }) {
  return (
    <LoadIcon
      src={`${iconPath}${passwordInputIcon}.svg`}
      size={20}
      color="black"
      className="eye-icon"
    />
  );
}

const FactorClaveInternet = (props) => {
  const {
    showClave,
    value,
    error,
    helpText,
    placeholder,
    onTogglePassword,
    onHandlePasswordInput,
    disabled,
  } = props;

  const passwordInputIcon = useMemo(() => `eye_${showClave ? 'open' : 'close'}`, [showClave]);

  return (
    <Wrapper>
      <div className={error ? 'password-error w-100' : 'w-100'}>
        <LoadIcon src={`${iconPath}lock.svg`} size={17} color="#1F1E1E" className="lock-icon" />
        <InputLabel
          borderColor={error ? '#E81E2B' : '#646569'}
          size={48}
          textSize={16}
          type={showClave ? 'text' : 'password'}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onHandlePasswordInput}
          maxLength={8}
          inputClass="input-text-writer"
          iconFunc={onTogglePassword}
          iconRight={<IconoOjo passwordInputIcon={passwordInputIcon} />}
        />
        {Boolean(error) ? (
          <div
            className={
              error
                ? 'help-text dark-red text-size-s back--icon error-help-text'
                : 'help-text dark-red text-size-s back--icon'
            }
          >
            <div className="row ml-0">
              <div className="col-md-1 p-0">
                <LoadIcon src={`${iconPath}circle_close.svg`} size={14} className="mr-4 dark-red" />
              </div>
              <div className="col-md-11 p-0">
                <p className="error-text">{error}</p>
              </div>
            </div>
          </div>
        ) : (
          <label className="help-text">{helpText}</label>
        )}
      </div>
    </Wrapper>
  );
};

FactorClaveInternet.propTypes = {
  helpText: PropTypes.string,
  showClave: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

FactorClaveInternet.defaultProps = {
  helpText: 'Cantidad de caracteres',
  showClave: false,
  error: false,
  value: '',
  placeholder: '•••••••',
};

export default FactorClaveInternet;
