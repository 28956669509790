import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';
import { factoresSeguridadActions } from '../../../../application/actions/factoresSeguridad';
import { loginActions } from '../../../../application/actions/ui_login';

import { errSMSFactor } from '../../../../infrastructure/services/depositoAPlazo/errors';

import LiquidacionConfirmarTemplate from '../../../components/templates/DepositoAPlazo/LiquidacionConfirmar';
import { findByKey } from '../../../utils/functions';
import { useShallowStore } from '../../../utils/hooks';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';
import { hasValuesOnObject } from '../../../utils/validators';

function LiquidacionConfirmar(props) {
  const { cuentasVista, history, match } = props;

  const { idSimulacion, montoToMatch } = history?.location?.state || {};

  const dispatch = useDispatch();
  const intervalSms = useRef(null);

  const defaultEmail = useShallowStore(({ entities }) => entities.persona.correo);

  const liquidarConfirmado = useShallowStore(
    ({ entities }) => entities.depositoAPlazo.liquidarConfirmado
  );

  const reglas = useShallowStore(({ entities }) => entities.depositoAPlazo.reglasLiquidacion);
  const telefono = useShallowStore(({ entities }) => entities.persona.telefono);

  const loading = useShallowStore(({ ui }) => ui.loaders.isLoadingLiquidarDAP);
  const error = useShallowStore(({ entities }) => entities.depositoAPlazo.liquidarError);

  const habilitadoOtpSmsError = useShallowStore(
    ({ entities }) => entities.depositoAPlazo.habilitadoOtpSmsError
  );
  const habilitadoOtpSms = useShallowStore(
    ({ entities }) => entities.depositoAPlazo.habilitadoOtpSms
  );
  const reglasFactores = useShallowStore(
    ({ entities }) => entities.factoresSeguridad.reglasFactoresSeguridad
  );

  const [cuenta, setCuenta] = useState(String(cuentasVista?.[0]?.numeroCuenta || ''));
  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [stepFactor, setStepFactor] = useState(0);
  const [timer, setTimer] = useState(120);
  const [intentos, setIntentos] = useState(3);
  const [desafios, setDesafios] = useState({
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
  });

  const isMontoHigher = useMemo(() => {
    const ruleMontoMax = reglas?.find(({ key }) => key === 'MONTO_MAX_FACTOR')?.value;

    return montoToMatch > Number(ruleMontoMax);
  }, [reglas, montoToMatch]);

  const handleChangeEmail = useCallback((event) => setEmail(event?.target?.value), [setEmail]);

  const handleChangeCuenta = useCallback((event) => setCuenta(event?.target?.value), [setCuenta]);

  const handlePassword = useCallback((event) => setPassword(event?.target?.value), [setPassword]);

  const handlePasswordVisibility = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword, setShowPassword]
  );

  const handleChangeDesafios = useCallback(
    (event) => {
      const { dataset, value } = event.target;

      const newDesafios = { ...desafios, [dataset.id]: value };

      const newDesafiosLength = Object.values(newDesafios).join('').length;

      setStepFactor(newDesafiosLength >= 0 && newDesafiosLength <= 5 ? 1 : 2);
      setDesafios(newDesafios);
    },
    [desafios, setDesafios, setStepFactor]
  );

  const handleStartCountdown = useCallback(() => {
    setStepFactor(1);

    intervalSms.current = setInterval(() => setTimer((prevTime) => prevTime - 1), 1000);
  }, [timer, setTimer, setStepFactor]);

  const handleClearCountdown = useCallback(() => {
    clearInterval(intervalSms.current);
    intervalSms.current = null;
  }, [intervalSms]);

  const handleBack = useCallback(() => history?.goBack(), [history?.goBack]);

  const clearSmsStates = useCallback(() => {
    if (error?.id === 3) {
      setTimer(findByKey(reglasFactores, 'codigo', 'OTP_TIME')?.valor || 120);
      dispatch(depositoAPlazoActions.fetchOtpDesafio({ numeroDeposito: idSimulacion }));

      handleStartCountdown();
      setDesafios({
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
      });
    }

    setStepFactor(1);
    setIntentos(intentos - 1);

    dispatch(depositoAPlazoActions.liquidarDepositoContratadoFailed({ error: {} }));
  }, [intentos, error, setStepFactor, reglasFactores, setTimer, setIntentos, handleStartCountdown]);

  const clearPasswordStates = useCallback(() => {
    setPassword('');
    setShowPassword(false);
    setIntentos(intentos - 1);

    dispatch(depositoAPlazoActions.liquidarDepositoContratadoFailed({ error: {} }));

    if (String(error?.id) === '4') {
      history.push('/recuperar-clave');
      dispatch(loginActions.fetchLogoutUser());
    }
  }, [intentos, error, setPassword, setShowPassword, setIntentos, history]);

  const handleSubmit = useCallback(() => {
    dispatch(
      depositoAPlazoActions.liquidarDepositoContratado({
        clave: password,
        numeroDeposito: idSimulacion,
        numeroCuenta: cuenta,
        correo: email,
        codigoSms: Object.values(desafios)?.join(''),
        intentos: intentos,
      })
    );

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/liquidar/paso-1',
      'eve-cat': 'Click',
      'eve-lab': 'Confirmar',
    });
  }, [password, idSimulacion, email, cuenta, desafios, intentos, handleClearCountdown]);

  const handleNextStep = useCallback(() => {
    return history?.push({
      pathname: `${match?.url}/deposito-contratado/comprobante`,
      state: history?.location?.state,
    });
  }, [history, match]);

  useEffect(() => {
    if (liquidarConfirmado) handleNextStep();
  }, [liquidarConfirmado]);

  useEffect(() => {
    if (isMontoHigher) {
      dispatch(factoresSeguridadActions.fetchReglasFactoresSeguridad());
      dispatch(depositoAPlazoActions.fetchOtpDesafio({ numeroDeposito: idSimulacion }));
    }
  }, [isMontoHigher]);

  useEffect(() => {
    if (reglasFactores?.length >= 1 && habilitadoOtpSms) {
      setTimer(findByKey(reglasFactores, 'codigo', 'OTP_TIME')?.valor || 120);
      setIntentos(findByKey(reglasFactores, 'codigo', 'OTP_INT')?.valor || 3);

      handleStartCountdown();
    }
  }, [reglasFactores, habilitadoOtpSms]);

  useEffect(() => {
    if (timer === 0) {
      if (!hasValuesOnObject(error)) {
        dispatch(
          depositoAPlazoActions.liquidarDepositoContratadoFailed({ error: errSMSFactor[3] })
        );
      }

      handleClearCountdown();
    }
  }, [timer]);

  useEffect(() => {
    if (defaultEmail) setEmail(defaultEmail);
  }, [defaultEmail]);

  useEffect(() => {
    dispatch(depositoAPlazoActions.liquidarDepositoContratadoFailed({ error: {} }));

    registerGA({
      event: 'vpv-privado',
      page: '/tef/mis-productos/deposito-a-plazo/liquidar/paso-1',
    });
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP_LIQUIDAR-P1');
  }, []);

  if (!idSimulacion) {
    return <Redirect to={{ pathname: match?.url }} />;
  }

  return (
    <LiquidacionConfirmarTemplate
      error={error}
      habilitadoOtpSmsError={habilitadoOtpSmsError}
      loading={loading}
      isMontoHigher={isMontoHigher}
      cuentasVista={cuentasVista}
      cuenta={cuenta}
      email={email}
      password={password}
      showPassword={showPassword}
      stepFactor={stepFactor}
      desafios={desafios}
      timer={timer}
      telefono={telefono}
      handleChangeDesafios={handleChangeDesafios}
      handlePassword={handlePassword}
      handleChangeEmail={handleChangeEmail}
      handleChangeCuenta={handleChangeCuenta}
      handlePasswordVisibility={handlePasswordVisibility}
      handleBack={handleBack}
      handleSubmit={handleSubmit}
      clearSmsStates={clearSmsStates}
      clearPasswordStates={clearPasswordStates}
    />
  );
}

export default LiquidacionConfirmar;
