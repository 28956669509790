import styled from 'styled-components';
import CardBoxOriginal from '@coopeuch-components/web/molecules/CardBox'

export const CustomList = styled.ul`
  color: #383638;
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 50%;
      position: absolute;
      content: '';
      display: inline-block;
      margin: auto 16px auto 0;
      height: 6px;
      bottom: 0;
      width: 6px;
      left: -16px;
      top: 0;
    }
  }
`;

export const CardBox = styled(CardBoxOriginal)`
  color: #383638;
`
