import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

// Components
import Header from '../Header';
import Footer from '../Footer';
import Menu from '../Menu';

// Styles
import {
  BaseHeader,
  BaseContent,
  BaseFooter,
  // ContainerMenuContentFooter,
  // Content,
  BaseContentRow,
} from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Layout = ({
  onToggleMenuVisibility,
  onClickMenuWithChildren,
  handlePressButtonLogout,
  onDispatchGmtEvent,
  onClickMenuItem,
  onClickHeaderPerfil,
  onClickFooter,
  handlePressIvic,
  children,
  showMenu,
  hideIvicIcon,
  menuOpts,
  persona,
  footer,
  header,
  fullScreen,
}) => (
  <>
    <BaseHeader className="col-xs-12 center-xs">
      <Header
        onDispatchGmtEvent={onDispatchGmtEvent}
        onClickLogoutHandle={handlePressButtonLogout}
        onClickHeaderPerfil={onClickHeaderPerfil}
        avatarLetters={persona?.iniciales}
        onClickMenu={onToggleMenuVisibility}
        lastAccess={
          header.ultimoAcceso
            ? `Último acceso ${header.ultimoAcceso}`
            : 'Bienvenido a Coopeuch en línea'
        }
        subtitle="Editar mis Datos"
        elements={header.elementos}
        title={
          !!persona.nombre && persona.nombre.length > 29
            ? `${persona.nombre.slice(0, 26)}...`
            : persona.nombre
        }
        id="header"
      />
    </BaseHeader>
    <div className="col-xs-12 center-xs base-main-content">
      {/* <ContainerMenuContentFooter className='max_width center'> */}
      <BaseContentRow className="row">
        <Menu
          onClickLogoutHandle={handlePressButtonLogout}
          onToggleMenuHandle={onToggleMenuVisibility}
          onClickMenuItem={onClickMenuItem}
          onClickMenuWithChildren={onClickMenuWithChildren}
          showMenu={showMenu}
          classes={`${!showMenu  ? 'd-none d-md-block' : 'd-block d-md-none'} menu-container-border`}
          avatarLetters={persona?.iniciales}
          itemList={menuOpts}
          lastAccess={
            header.ultimoAcceso
              ? `Último acceso ${header.ultimoAcceso}`
              : 'Bienvenido a Coopeuch en línea'
          }
          subtitle="Editar mis Datos"
          title={
            !!persona?.nombre && persona.nombre.length > 29
              ? `${persona?.nombre.slice(0, 26)}...`
              : persona?.nombre
          }
          fullScreen={fullScreen}
        />
        <div className={`col-xs-12 col-md-${!fullScreen? '9':'12'} col-lg-${!fullScreen? '10':'12'} p-0 p-relative`}>
          <BaseContent className="row bg-gray-page">
            <>{children}</>
          </BaseContent>
          <BaseFooter className="row">
            <Footer
              onListenerHandle={onClickFooter}
              links={footer.elementos}
              rightContent={footer.rightContent}
              id="footer"
            />
          </BaseFooter>
        </div>
      </BaseContentRow>
    </div>
  </>
);

Layout.propTypes = {
  children: PropTypes.node,
  minWidth: PropTypes.number,
  onClickMenuItem: PropTypes.func,
  onToggleMenuVisibility: PropTypes.func,
  handlePressButtonLogout: PropTypes.func,
  onDispatchGmtEvent: PropTypes.func,
  showMenu: PropTypes.bool,
  menuOpts: PropTypes.array,
  footer: PropTypes.object,
  header: PropTypes.object,
  persona: PropTypes.object,
};

Layout.defaultProps = {
  minWidth: 1280,
  persona: { nombre: '', iniciales: '' },
  onClickMenuItem: null,
  onToggleMenuVisibility: null,
  handlePressButtonLogout: null,
  onDispatchGmtEvent: null,
  showMenu: false,
  menuOpts: [],
  footer: {
    elementos: [],
    rightContent: {},
  },
  header: {},
};
Layout.displayName = 'Layout_organism_component';
export default Layout;
