import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import InputLabel from '../../../../../atoms/InputLabel';
import PasswordInput from '../../../../../atoms/PasswordInput';
import Error from '../../../../../atoms/Error';
import StepsActions from '../../../../../organism/Stepper/StepsActions';
import DownloadLink from '../../../../../atoms/DownloadLink';
import { CustomList, HRow, Paragraph, MessageError } from './styles';
import './styles.css';
import Skeleton from 'react-loading-skeleton';

const Index = ({
  email,
  persona,
  checked,
  loading,
  password,
  emailError,
  showPassword,
  passwordError,
  serverPasswordError = '',
  serviceError = false,
  blockedPassword = false,
  onHandleCheckBox,
  onHandlePasswordInput,
  onTogglePassword,
  backButton,
  nextButton,
  onHandleEmailInputChange,
  onDownloadGenericContract,
}) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const documentsPath = process.env.REACT_APP_CLOUDFRONT_DOCUMENTS;
  const emailErrorMessage = (
    <MessageError className="input-error-cuotas text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${iconPath}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        {emailError}
      </div>
    </MessageError>
  );
  return (
    <React.Fragment>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Conoce tus contratos</CardBox.Header>
        <CardBox.Body>
          <Paragraph className="mb-16">
            Revisa y firma los siguientes documentos para formalizar tu contratación. Es importante
            que conozcas los detalles de cada uno de ellos, haciendo click en descargar contrato.
          </Paragraph>
          {/* <div className="row middle-xs ml-0"> */}
          <Paragraph size="18">
            <div className="w-100">
              <LoadIcon
                className="float-left mr-16"
                src={`${iconPath}note.svg`}
                color="#1F1E1E"
                size={22.5}
              />
              <p>Contrato de Prestación de servicios mediante canales</p>
            </div>
          </Paragraph>
          <Paragraph marginLeft="38.5" className="mt-16 mb-16">
            Habilita la atención y contratación de productos a través de canales remotos, como la
            página web, App, Contact center, Cajeros automáticos, etc.
          </Paragraph>
          <Paragraph marginLeft="38.5" className="mb-16">
            <DownloadLink
              text="Descargar contrato"
              onClick={() =>
                onDownloadGenericContract('Contrato de Prestación de Servicios mediante canales')
              }
              href={`${documentsPath}contratacion_monedero/contrato_prestacion_servicio_canales_monedero.pdf`}
            />
          </Paragraph>
          <HRow className="mb-16" />
          <div className="row middle-xs ml-0">
            <Paragraph size="18">
              <LoadIcon
                className="float-left mr-16"
                src={`${iconPath}note.svg`}
                color="#1F1E1E"
                size={22.5}
              />
              Contrato de apertura de “<b>Monedero Digital</b>” y <b> &nbsp;Poder especial&nbsp;</b>{' '}
              abono de acreencias
            </Paragraph>
            <Paragraph marginLeft="38.5" className="mt-16 mb-16">
              Acepta los términos y condiciones de uso establecidos en el contrato de producto
            </Paragraph>
            <Paragraph marginLeft="38.5" className="mb-16">
              <CustomList>
                <li className="mt-8 mb-8">
                  Autorizo a Coopeuch para aperturar en forma inmediata una Cuenta Vista.
                </li>
                <li className="mt-8 mb-8">
                  Aceptas el uso personal e intransferible de claves y dispositivos de seguridad que
                  Coopeuch disponga para autorizar transacciones de tu Cuenta Vista.
                </li>
                <li className="mt-8 mb-8">
                  Autorizas el envío de estados de cuentas y cualquier comunicación vía email.
                </li>
                <li className="mt-8 mb-8">
                  Autorizas a Coopeuch a depositar en tu Cuenta Vista cualquier acreencia que exista
                  a tu nombre
                </li>
              </CustomList>
            </Paragraph>
            <Paragraph marginLeft="38.5" className="mb-16">
              <DownloadLink
                text="Descargar contrato"
                onClick={() =>
                  onDownloadGenericContract('Contrato de apertura de “Monedero Digital” y Poder especial abono de acreencias')
                }
                href={`${documentsPath}contratacion_monedero/contrato_mandato_monedero.pdf`}
              />
            </Paragraph>
            <Paragraph marginLeft="30.5" className="mb-16">
              <CheckBox
                name="contratacionChecked"
                className="checkbox-element"
                text="Acepto suscribir el poder especial abono de acreencias y autorizo a Coopeuch a abonar el remanente en esta cuenta."
                borderColor="#646569"
                id="input-auth-contratacion-monedero"
                fontSize={16}
                isChecked={checked}
                onChange={onHandleCheckBox}
              />
            </Paragraph>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Envío de documentos y contratos</CardBox.Header>
        <CardBox.Body>
          {!!persona && (
            <React.Fragment>
              {!!persona.correo ? (
                <Alert
                  className="bp-3 bmb-3"
                  bgType="info"
                  id="alert-change-email-contratacion-monedero"
                  show
                >
                  Recuerda que puedes editar la casilla de email, borrando el email actual e
                  ingresando uno nuevo.
                </Alert>
              ) : (
                <Alert
                  className="bp-3 bmb-3"
                  bgType="info"
                  id="alert-change-email-contratacion-monedero"
                  show
                >
                  No posees un email registrado. Por favor ingresa uno para continuar el proceso de
                  contratación.
                </Alert>
              )}
            </React.Fragment>
          )}
          <React.Fragment>
            <Paragraph className="mb-16">
              Al firmar electrónicamente, te enviaremos tus contratos a tu email:
            </Paragraph>
            <div className="row">
              <div className="col-xs-12 col-md-5">
                {!!persona ? (
                  <InputLabel
                    name="email"
                    size={48}
                    borderColor={'#646569'}
                    title="Tu cuenta de email es:"
                    labelClass="text-bold bmb-3"
                    value={email}
                    onChange={onHandleEmailInputChange}
                    errorMessage={emailError ? emailErrorMessage : null}
                    errorClass={'red'}
                  />
                ) : (
                  <Skeleton />
                )}
              </div>
            </div>
          </React.Fragment>
        </CardBox.Body>
      </CardBox>
      {!!serverPasswordError.length && (
        <Alert
          className="bp-3 bmb-3"
          bgType="error"
          id="alert-blocked-password-contratacion-monedero"
          show
        >
          {serverPasswordError}
        </Alert>
      )}
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Firma electrónica</CardBox.Header>
        <CardBox.Body>
          {serviceError ? (
            <Error className="error-service-contratacion-monedero" />
          ) : (
            <React.Fragment>
              <Paragraph className="mb-16">
                Firma tus contratos de forma digital con tu clave de internet Coopeuch:
              </Paragraph>
              <Paragraph className="mb-16">
                <p>
                  Al ingresar tu contraseña alfanumérica{' '}
                  <b>estarás declarando que has leído los términos y condiciones</b> establecidos en
                  los contratos. De lo contrario puedes cancelar la operación y realizar la
                  contratación en una oficina.
                </p>
              </Paragraph>
              <div className="col-xs-12 col-md-5">
                <PasswordInput
                  {...{
                    password,
                    showPassword,
                    passwordError,
                    onTogglePassword,
                    onHandlePasswordInput,
                  }}
                />
              </div>
            </React.Fragment>
          )}
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmb-3 bmb-md-4"
        backAction={backButton}
        backText={'No por ahora'}
        nextAction={nextButton}
        disableNextAction={
          !(
            checked &&
            password.length > 0 &&
            passwordError.length === 0 &&
            !serviceError &&
            !blockedPassword &&
            email &&
            !emailError
          )
        }
        loading={loading}
        id="contratacion-paso-1"
      />
    </React.Fragment>
  );
};

export default Index;
