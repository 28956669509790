import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';

import { historialTransferenciasActions } from '../../../application/actions/historialTransferencias';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';
import { logReqError, downloadPDFBase64OtherTab } from '../../../ui/utils/functions';
import { sortDates } from '../../../ui/utils/dates';

const apiHistorialTef = api(process.env.REACT_APP_TEF_HISTORIA);

export function* fetchHistorialTransferencias(action) {
  try {
    const { ...body } = action.payload;

    yield put(loadersActions.fetchLoadingHistorialTransferencias({ isLoading: true }));

    const { data, error } = yield call(apiHistorialTef.post, `movimiento/v1/obtener`, body);

    if (data) {
      const { transferencias } = data;

      yield put(
        historialTransferenciasActions.fetchHistorialTefSuccess({
          historial: sortDates(transferencias, 'fecha'),
        })
      );
    } else {
      yield put(historialTransferenciasActions.fetchHistorialTefFailed({ errorLog: error }));
    }

    yield put(loadersActions.fetchLoadingHistorialTransferencias({ isLoading: false }));
  } catch (err) {
    yield all([
      yield put(historialTransferenciasActions.fetchHistorialTefFailed({ errorLog: err })),
      yield put(loadersActions.fetchLoadingHistorialTransferencias({ isLoading: false })),
    ]);
  }
}

export function* descargarHistorial(action) {
  try {
    const { ...params } = action.payload;

    const { data } = yield call(apiHistorialTef.post, `movimiento/v1/pdf/obtener`, params);

    if (data) {
      downloadPDFBase64OtherTab(data);
    }
  } catch {}
}

export function* watchFetchHistorialTransferencias() {
  yield takeLatest(
    historialTransferenciasActions.fetchHistorialTransferencias.type,
    fetchHistorialTransferencias
  );
}

export function* watchDescargarHistorial() {
  yield takeLatest(historialTransferenciasActions.descargarHistorial.type, descargarHistorial);
}

export default function* rootSaga() {
  yield spawn(watchFetchHistorialTransferencias);
  yield spawn(watchDescargarHistorial);
}
