import { eachMonthOfInterval, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { capitalize } from './functions';

export const formatDateShortMonth = (stringDate) => {
  try {
    let newDateString;
    if (!!stringDate) {
      newDateString = format(new Date(stringDate), 'd MMM. y', {
        locale: es,
      });
    } else {
      newDateString = '';
    }
    return newDateString;
  } catch (error) {
    return '';
  }
};

export const getMonthByDate = (date = '') => {
  // const dateToFormat = typeof date === 'string' ? date?.split('-')?.join() : date;
  const month = format(new Date(date), 'MMMM', { locale: es });

  return `${month.charAt(0).toUpperCase()}${month.slice(1)}`;
};

export const getGroupedByMonths = (items = []) => {
  return items?.reduce((results, org) => {
    (results[getMonthByDate(org.fecha)] = results[getMonthByDate(org.fecha)] || []).push(org);
    return results;
  }, {});
};

export const sortDates = (historial, keyToSort = '') => {
  return historial?.slice()?.sort((a, b) => {
    const aa = keyToSort ? a[keyToSort] : a;
    const bb = keyToSort ? b[keyToSort] : b;

    return aa > bb ? -1 : aa < bb ? 1 : 0;
  });
};

export const getObjectOfMonthsByRangeDate = ({ ...data }) => {
  const { start, end } = data;

  const eachMonths = eachMonthOfInterval({ start, end });

  return sortDates(eachMonths)
    ?.map((date) => getMonthByDate(date))
    ?.reduce((acc, curr) => ((acc[curr] = []), acc), {});
};

export const formatDPickerDates = (date) => {
  return new Date(date).toISOString().slice(0, 10);
};

export const cleanDate = (date) => new Date(date.replace(/-/g, '/'));

export const dateFormat = (date, fallback) => {
  let formattedDate = null;

  if (date) {
    const currentDate = typeof date === 'string' ? cleanDate(date.replace(/-/g, '/')) : date;
    formattedDate = currentDate.toLocaleDateString('es-cl', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    });

    const [day, month, year] = formattedDate.split(' de ');
    formattedDate = `${day} ${month} ${year}`.toLocaleLowerCase().replace(/undefined/g, '');
  }
  return formattedDate || fallback;
};

export const standarizeDate = (date = '') => {
  if (!date?.length) return '';

  const [day, month, year] = dateFormat(date).split(' ');

  return `${day} ${capitalize(month).replace('.', '')}. ${year}`;
};

export const formatStandardDate = (date) => {
  let _date = '';

  if (date) {
    const formattedDate = format(new Date(date), 'dd MMM u', {
      locale: es,
    });

    const [day, month, year] = formattedDate.split(/\s+/);

    const upperMonth = `${month[0].toUpperCase()}${month.substring(1)}`;

    _date = `${day} de ${upperMonth} ${year}`;
  }

  return _date;
};

export const patternDateFormat = (date, pattern) => {
  let _date = '';

  try {
    if (date) {
      const formattedDate = format(new Date(date), pattern, {
        locale: es,
      });

      const [day, month, year] = formattedDate.split(/\s+/);

      const upperMonth = `${month[0].toUpperCase()}${month.substring(1)}`;

      _date = `${day} de ${upperMonth} ${year}`;
    }
  } catch (err) {
    console.error(err);
  }

  return _date;
};

export const patternSimpleDateFormat = (date, pattern) => {
  let fecha = '';

  try {
    if (date) {
      const formattedDate = format(new Date(date), pattern, {
        locale: es,
      });

      fecha = formattedDate;
    }
  } catch (err) {
    console.error(err);
  }

  return fecha;
};

export const mesAnterior = (date) => {
  const fechaAnterior = new Date(date.setMonth(date.getMonth() - 1));
  return fechaAnterior;
};

export const formatStringToDate = (fecha) => {
  const year = fecha.slice(0, 4);
  const month = fecha.slice(4, 6);
  const day = fecha.slice(6, 8);
  const date = new Date(parseInt(year), parseInt(month - 1), parseInt(day));
  const shortMonth = date.toLocaleString('es-CL', { month: 'short' });
  const formatDate = `${day} ${shortMonth.toUpperCase()} ${year}`;
  return formatDate;
};

export const months = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const monthsExtend = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
/**
 * Funcion para formatear string de fecha, devuelve ej: 15 de Dic 2022
 * @param {string}
 * @returns string
 */
export const dayMonthYear = (date) => {
  let newDate = date?.toString();
  newDate = new Date(date);
  return `${newDate.getDate() + 1} de ${months[newDate.getMonth()]} ${newDate.getFullYear()}`;
};

/**
 * Funcion para formatear string de fecha que no viene del servidor sino de new Date(), devuelve ej: 15 Dic 2022
 * @param {string}
 * @returns string
 */
export const dayMonthYearNewDay = (date) => {
  let newDate = date?.toString();
  newDate = new Date(date);
  return `${newDate.getDate()} ${months[newDate.getMonth()]} ${newDate.getFullYear()}`;
};
/**
 * Funcion para obtener año actual
 * @returns number
 */
export const currentYear = () => {
  const yearCurrent = new Date().getFullYear();
  return yearCurrent;
};

/**
 * Funcion para formatear string de fecha, devuelve ej: 15 de Diciembre 2022
 * @param {string}
 * @returns string
 */
export const dayMonthYearExtend = (date) => {
  let newDate = date?.toString();
  newDate = new Date(date);
  return `${newDate.getDate() + 1} de ${monthsExtend[newDate.getMonth()]} ${newDate.getFullYear()}`;
};

/**
 *
 * @param {string}  "2022/07/13"
 * @returns {string} "13 de Julio 2022 17:19 hrs"
 */
export const dateWithSlash = (fecha) => {
  const numbers = fecha.split('/');
  let result;
  if (numbers.length === 3) {
    const [day, month, year] = numbers;
    const currentTime = new Date();
    result = `${day} de ${
      monthsExtend[month * 1 - 1]
    } ${year} ${currentTime.getHours()}:${currentTime.getMinutes()} hrs`;
  } else {
    result = fecha;
    console.log('Formato de fecha erroneo');
  }
  return result;
};

export const dateSlash = (fecha) => {
  const numbers = fecha.split('/');
  let result;
  if (numbers.length === 3) {
    const [day, month, year] = numbers;
    result = `${day} ${
      months[month * 1 - 1]
    }. ${year}`;
  } else {
    result = fecha;
  }
  return result;
};