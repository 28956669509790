import { Message } from '@design-system-coopeuch/web';
import React from 'react';
// Organism
import Stepper from '../../../../components/organism/Stepper';
import CardComprobante from '../../../organism/BonoEscolar/Comprobante';
import CardBlockBonos from '../../../organism/BonoEscolar/Error';
import FooterBackAndContinue from '../../../organism/BonoEscolar/FooterBackAndContinue';
import NextSteps from '../../../organism/NextSteps';
// Text
import { nextStepsContent } from '../../../../containers/BonoEscolar/Comprobante/nextSteps';
// Styles
import Wrapper from './styles';

const ComprobanteTemplate = ({
  steps,
  dataBonos,
  saveBono,
  handleClickBack,
  handleDownloadPdfBono,
  isFetchingDownloadPdfBono,
  isErrorDownloadPdfBono,
  handleRetryDownloadPdfBono,
}) => {
  if (isErrorDownloadPdfBono) {
    return (
      <Wrapper>
        <CardBlockBonos
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          handleClickRetry={handleRetryDownloadPdfBono}
        />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      {!dataBonos.documentos.isRejected && (
        <Stepper className="start-xs" position={4} steps={steps} />
      )}

      <div className="containerCard">
        <Message
          className="messageInfoSuccess"
          type="success"
          description={
            <span>
              Tu solicitud de postulación ha sido procesada
              {dataBonos.documentos.isRejected && ' nuevamente'}, te enviamos una copia del
              comprobante a tu email: <b>{saveBono.data?.email}</b>
            </span>
          }
        />

        <div className="comprobanteContainer">
          <CardComprobante
            dataBonos={dataBonos}
            saveBono={saveBono}
            isFetchingDownloadPdfBono={isFetchingDownloadPdfBono}
            handleDownloadPdfBono={handleDownloadPdfBono}
          />
        </div>

        <NextSteps nextStepsContent={nextStepsContent} />
      </div>

      <FooterBackAndContinue txtBack="Volver" handleClickBack={handleClickBack} />
    </Wrapper>
  );
};

export default ComprobanteTemplate;
