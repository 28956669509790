export const transferenciaTexts = {
  TITLE: 'Transferencia de fondos',
  TAB_1: 'A Terceros',
  TAB_2: 'Entre Productos',
};

export const transferenciaStepperTexts = {
  STEPPER_TITLE_1: '1. Monto a Transferir',
  STEPPER_TITLE_2: '2. Confirmación',
  STEPPER_TITLE_3: '3. Comprobante',
};

export const montoATransferirTexts = {
  TITLE_ORIGEN: 'Cuenta Origen',
  TITLE_DESTINO: 'Cuenta Destino',
  TITLE_MONTO: 'Ingresa Monto',
  LABEL_MONTO: 'Monto',
  LABEL_COMENTARIO: 'Mensaje de Transferencia',
  LABEL_SALDO_DISPONIBLE: 'Saldo disponible:',
  LABEL_NUMERO_CUENTA: 'Nº ',
  BUTTON_CONTINUE: 'Continuar',
  BUTTON_RETURN: 'Volver',
  PLACEHOLDER_MONTO: '$ Ingresa monto',
  PLACEHOLDER_COMENTARIO: 'Ingresa un mensaje',
  ERROR_TITLE: '¡Lo sentimos!',
  ERROR_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  ERROR_BUTTON: 'Volver a intentar',
};

export const tipoCuentaTexts = {
  VI01: 'Cuenta Vista Coopeuch',
  VI03: 'Cuenta Convenio',
  VI06: 'Monedero Digital',
};

export const confirmacionTexts = {
  TITLE_ORIGEN: 'Cuenta Origen',
  TITLE_DESTINO: 'Cuenta Destino',
  TITLE_COOPEUCHPASS: 'PassCoopeuch',
  TITLE_TCOO: 'Tarjeta de Coordenadas',
  TITLE_SMS: 'Código SMS enviado a tu celular',
  LABEL_NUMERO_CUENTA: 'Nº ',
  BUTTON_RETURN: 'Volver',
  LABEL_MONTO_TRANSFERIR: 'Monto a Transferir: ',
  LABEL_DESC_MONTO_TRANSFERIR: 'Mensaje de Transferencia: ',
  LABEL_CAMBIAR_FACTOR_COOPEUCH_PASS: 'Quiero autorizar con Tarjeta de Coordenadas',
  LABEL_CAMBIAR_FACTOR_TARJETA_COORDENADAS: 'Quiero autorizar con PassCoopeuch',
  LABEL_CAMBIAR_NUMERO_TELEFONO: 'Este no es mi número y deseo cambiarlo',
  LABEL_DESC_COOPEUCH_PASS:
    'Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.',
  LABEL_BUTTON_COOPEUCH_PASS: 'Autorizar con PassCoopeuch',
  LABEL_BUTTON_SMS: 'Validar transacción',
  LABEL_DESC_TARJETA_COORDENADAS:
    'Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.',
  LABEL_BUTTON_TARJETA_COORDENADAS: 'Autorizar',
  ERROR_TITLE: '¡Lo sentimos!',
  ERROR_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  ERROR_BUTTON: 'Volver a intentar',
};

export const comprobanteTexts = {
  LABEL_TOAST_SUCCESS: 'Transferencia a terceros fue realizada con éxito',
  BUTTON_RETURN_HOME: 'Ir a home',
  BUTTON_RETURN_TRANSFERENCIAS: 'Volver a Transferir',
  BUTTON_BACK_TO_TRANSFERENCIAS: 'Volver a Transferencias',
  LABEL_MODULO_COOPEUCH_PASS_DESC:
    'Te invitamos a descargar PassCoopeuch, la App que te permite autorizar transacciones de forma fácil, rápida y segura. Encuéntrala en:',
  LABEL_MODULO_COOPEUCH_PASS_TITLE: '¡Usa PassCoopeuch!',
  HEADER_MODULO_COOPEUCH_PASS: '¿No tienes tu PassCoopeuch?',
  HEADER_MODULO_DETALLE: 'Detalle de la transferencia',
  HEADER_MODULO_COMPROBANTE: 'Comprobante',
  BUTTON_DOWNLOAD_COMPROBANTE: 'Descargar',
  BUTTON_ENVIAR_COMPROBANTE: 'Enviar',
  LABEL_MONTO_MODULO_COMPROBANTE: 'Monto Transferido',
  LABEL_ENVIO_MODULO_COMPROBANTE:
    'Se enviará un comprobante de Transferencia a terceros a tu email:',
  LABEL_MODULO_DETALLE_CUENTA_ORIGEN: 'Cuenta Origen',
  LABEL_MODULO_DETALLE_NUMERO_CUENTA_ORIGEN: 'Número de cuenta',
  LABEL_MODULO_DETALLE_CUENTA_DESTINO: 'Cuenta destino',
  LABEL_MODULO_DETALLE_BANCO: 'Banco',
  LABEL_MODULO_DETALLE_NUMERO_CUENTA_DESTINO: 'Número de cuenta',
  LABEL_MODULO_DETALLE_EMAIL: 'Email',
  LABEL_MODULO_DETALLE_MENSAJE: 'Mensaje de Transferencia',
  ERROR_TITLE: '¡Lo sentimos!',
  ERROR_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  ERROR_BUTTON: 'Volver a intentar',
};

export const entreProductos = {
  witchOrigenAccount: '¿Desde qué cuenta quieres transferir?',
  selectOrigenAccount: 'Selecciona una cuenta de origen para realizar tu transferencia',
  origenAccount: 'Cuenta de Origen',
  witchDestinoAccount: '¿Cuál es la cuenta de Destino?',
  selectDestinoAccount: 'Selecciona una cuenta de destino para completar tu transferencia',
  destinoAccount: 'Cuenta de Destino',
  authorizeWithTCOO:
    'Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.',
  authorizeWithPassCoopeuchDescription:
    'Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.',
  goBack: 'Volver',
  next: 'Continuar',
  save: 'Guardar',
  buttonAuthorizeWithPassCoopeuch: 'Autorizar con PassCoopeuch',
  iWantToAuthorizeWithPassCoopeuch: 'Quiero autorizar con PassCoopeuch',
  enterCoords: 'Ingresa los valores indicados en las coordenadas de tu tarjeta N°',
  tryAgain: 'Reintentar',
  iWantToAuthorizeWithTCOO: 'Quiero autorizar con Tarjeta de Coordenadas',
  buttonAuthorize: 'Autorizar',
  enterPass:
    'Para autorizar la operación, ingresa la clave Coopeuch antes que termine el tiempo permitido.',
  smsAuthDesc1: 'Te enviamos un SMS con el código a tu celular ',
  smsAuthdesc2: 'Si este no es tu número por favor contáctate al Call Center al',
  buttonAuthorizeSMS: 'Validar transacción',
  smsAuthdesc3: '600 200 1200',
  titlePassCoopeuch: 'PassCoopeuch',
  titleCoordsCard: 'Tarjeta de Coordenadas',
  amountToTransfer: 'Monto a Transferir',
  descriptionToTransfer: 'Mensaje de Transferencia',
  notHasAccountsTitle: 'No posees otros productos',
  notHasAccountsDescriprion:
    'Te invitamos a conocer todos nuestros productos y tener más opciones de transferencia o ahorro',
  successTefs: 'Transferencia entre productos realizada con exito',
  invoice: 'Comprobante',
  download: 'Descargar',
  amountTransfer: 'Monto Transferido',
  sendInvoiceToEmail:
    'Se te enviará un comprobante de Transferencia entre tus productos a tu email:',
  usePassCoopeuch: '¡Usa PassCoopeuch!',
  dontHavePassCoopeuch: '¿No tienes PassCoopeuch?',
  findAndInstallApp:
    'Te invitamos a descargar PassCoopeuch, la App que te permite autorizar transcciones de forma facil, rapida y segura. Encuentrala en:',
};
