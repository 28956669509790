import styled from 'styled-components';

export default styled.div`
  .content {
    text-align: left;
    padding: 24px 3rem 24px 3rem;
  }

  .modulos-container {
    margin-top: 24px;
  }

  .modulo {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .modulo-content {
    padding: 24px 32px;
  }

  .monto-radio-container {
    p {
      line-height: 24px !important;
    }
  }

  .monto-radio-input {
    width: 196px !important;

    input {
      border-radius: 5px;
      font-size: 14px;
    }
  }

  .monto-radio {
    height: 24px;
    width: 24px;
    margin-top: 6px;
    margin-bottom: 24px;
    margin-right: 0px;
    margin-left: 0px;

    &::before {
      height: 14px;
      width: 14px;
    }

    &::after {
      height: 24px;
      width: 24px;
    }
  }

  .err-color {
    color: #cc284e;
  }

  .err-component {
    p,
    label {
      color: #383638 !important;
    }
  }

  .input-otro-monto-wrapper {
    position: relative;
    height: 68px;

    .error-warning-password {
      position: absolute;
      left: 0;
      bottom: -20px;

      & svg {
        fill: #bf0338;

        path {
          fill: #bf0338;
        }
      }
    }
  }

  #pago-tarjeta-credito-next-action {
    width: 160px !important;
  }

  #pago-tarjeta-credito-prev-action {
    align-items: center;
  }

  @media screen and (max-width: 766px) {
    .content {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 16px !important;
    }

    .modulos-container {
      margin-top: 16px;
    }

    .modulo {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .modulo-content {
      padding: 16px;
    }

    .modulo-second-text {
      padding-top: 16px;
    }

    #pago-tarjeta-credito-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }

    .pl-32,
    .pl-24 {
      padding-left: 16px !important;
    }

    .pr-32,
    .pr-24 {
      padding-right: 16px !important;
    }

    #pago-tarjeta-credito-next-action,
    .step-action-button-wrapper {
      width: 100% !important;
    }
  }
`;
