import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { agregarformatoPesos, formatThousandsSeparators } from '../../../../utils/formatters';

import Wrapper from './styles';
import DashboardCard from '../../../organism/DashboardCard';
import { SkeletonCard } from '../Skeleton';
import ErrorDashboard from '../ErrorDashboard';

import { cuotaParticipacionTexts } from '../../../../containers/Dashboard/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  aporte,
  cuotasPagadas,
  cuotasPorMes,
  aporteMensual,
  isLoading,
  errorCuotas,
  handleClickTarjeta,
}) => {
  return (
    <Wrapper>
      <div>
        {errorCuotas ? (
          <div className="bmr-md-1 bmr-0 bmb-md-4 bmb-3 bml-md-1 bml-0">
            <ErrorDashboard descText={cuotaParticipacionTexts.ERROR_DESC} />
          </div>
        ) : (
          <div className="bmr-md-1 bmr-0 bmb-md-4 bmb-3 bml-md-1 bml-0">
            <DashboardCard classes="m-0 dashboard-card-shadow cuotas-dashboard-card">
              <DashboardCard.Body classes="cuotas-container">
                {isLoading ? (
                  <SkeletonCard />
                ) : (
                  <div className="row cursor-pointer" onClick={handleClickTarjeta}>
                    <div className="col-md-1 col-xs-2 cuotas-icon-container">
                      <LoadIcon
                        src={`${iconPath}ico_cuota.svg`}
                        size={30}
                        color="#000"
                        className="float-left bmr-3 cv-icon-tj"
                      />
                    </div>
                    <div className="col-md-11 col-xs-10 bpl-md-3 bpl-0">
                      <div className="bml-2 bmb-1">
                        <label className="cuotas-title cursor-pointer">
                          {cuotaParticipacionTexts.TITLE_CUOTAS}
                        </label>
                      </div>
                      <div className="bml-2">
                        <label className="cursor-pointer">
                          {cuotaParticipacionTexts.TOTAL_CUOTAS_PAGADAS}{' '}
                          <b>{formatThousandsSeparators(cuotasPagadas)}</b>
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3">
                      <div className="bmb-2">
                        <label className="cuotas-text cursor-pointer">
                          {cuotaParticipacionTexts.APORTE_FECHA}
                        </label>
                      </div>
                      <div>
                        <label className="cuotas-value-fecha cursor-pointer">{agregarformatoPesos(aporte)}</label>
                      </div>
                    </div>
                    <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 cuotas-texto-direccion">
                      <div className="coutas-margin-text">
                        <label className="cuotas-text cursor-pointer">
                          {cuotaParticipacionTexts.CUOTA_PACTADA_MES} <b>{cuotasPorMes}</b>
                        </label>
                      </div>
                      <div>
                        <label className="cuotas-text cursor-pointer">
                          {cuotaParticipacionTexts.APORTE_MENSUAL}{' '}
                          <b>{agregarformatoPesos(aporteMensual)}</b>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default Index;
