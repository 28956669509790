import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { migracionCuentaAhorroActions } from '../../../../../application/actions/migracionCuentaAhorro';

import MigracionCuentaAhorroDatosCuentaTemplate from '../../../../components/templates/CuentaAhorro/Migracion/DatosCuenta';
import { cuentaAhorroActions } from '../../../../../application/actions/cuentaAhorro';

// 0: Tributa; Chile.
// 1: Tributa; Extranjero.

const MigracionCuentaAhorroDatosCuenta = ({ cuenta }) => {
  const [tributaEnExtranjero, setTributaEnExtranjero] = useState(null);
  const [showErrorScreen, setShowErrorScreen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const migracion = useSelector(({ entities }) => entities.migracionCuentaAhorro, shallowEqual);
  const migracionIniciar = useSelector(({ entities }) => entities.cuentasAhorro.migracionIniciar, shallowEqual);
  
  const loading = useSelector(
    ({ ui }) => ui.loaders.isLoadingMigracionCuentaAhorroFatca,
    shallowEqual
  );

  const handleTributaEnExtranjero = useCallback(
    (event) => {
      setTributaEnExtranjero(Number(event?.target?.value));
    },
    [setTributaEnExtranjero]
  );

  const handleSubmit = useCallback(() => {
    if (loading || !!tributaEnExtranjero) return null;
    return dispatch(
      cuentaAhorroActions.fetchMigracionIniciar({ numeroCuenta: cuenta?.numeroCuenta })
    );

  }, [tributaEnExtranjero, loading]);

  const goBack = useCallback(() => {
    history.replace({
      pathname: '/ecd/productos/cuenta-ahorro',
      state: {
        ...cuenta,
      },
    });
  }, [cuenta, history]);

  useEffect(() => {
    return () => {
      setShowErrorScreen(false);
    }
  }, []);

  useEffect(() => {
    if (migracionIniciar.success) {
      dispatch(
        migracionCuentaAhorroActions.setDeclaracionFatca({
          tributaEnExtranjero: !!tributaEnExtranjero,
          go: () => history.push('/ecd/productos/cuenta-ahorro/migracion/contratacion'),
        })
      );
    };
    if (migracionIniciar.error){
      setShowErrorScreen(true);
      dispatch(cuentaAhorroActions.fetchMigracionIniciarReset())
    }
  }, [migracionIniciar]);

  return (
    <MigracionCuentaAhorroDatosCuentaTemplate
      tributaEnExtranjero={tributaEnExtranjero}
      cuenta={cuenta}
      handleTributaEnExtranjero={handleTributaEnExtranjero}
      error={!!migracion.fatcaError || showErrorScreen}
      loading={loading}
      goBack={goBack}
      handleSubmit={handleSubmit}
    />
  );
};

MigracionCuentaAhorroDatosCuenta.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  cuenta: PropTypes.object,
};

MigracionCuentaAhorroDatosCuenta.defaultProps = {
  cuenta: {},
};

export default MigracionCuentaAhorroDatosCuenta;
