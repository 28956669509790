import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';

import { CheckBoxText, CheckBoxWrapper } from './styles';

const ContratacionTerminos = (props) => {
  const { 
    checked,
    handleCheckBox,
    checkdRequiredError = false,
    tituloTerminos,
    textTerminosCondiciones,
  } = props;

  const checkRef = useRef();
  if (checkdRequiredError && checkRef.current) {
    checkRef.current.scrollIntoView();
  }

  return (
    <div ref={checkRef}>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>
          <Typography tag="p" fontSize={22} fontWeight="bold">{tituloTerminos}</Typography>
        </CardBox.Header>
        <CardBox.Body>
          <CheckBoxWrapper className="flex-row">
            <CheckBox
              labelClassName="checkboxText"
              name="checked"
              className="checkbox-element"
              borderColor={checkdRequiredError ? '#e81e2b' : '#646569'}
              id="input-auth-contratacion-monedero"
              fontSize={16}
              isChecked={checked}
              onChange={handleCheckBox}
            />
            <CheckBoxText>{textTerminosCondiciones}</CheckBoxText>
          </CheckBoxWrapper>
        </CardBox.Body>
      </CardBox>
    </div>
  );
};

ContratacionTerminos.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  checkdRequiredError: PropTypes.bool,
  textTerminosCondiciones: PropTypes.string.isRequired,
  tituloTerminos: PropTypes.string
};

ContratacionTerminos.defaultProps = {
  checkdRequiredError: false,
  tituloTerminos: 'Términos y condiciones'
};

export default ContratacionTerminos;
