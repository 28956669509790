import styled from 'styled-components';

export const HeaderDesktop = styled.div`
  padding: 1rem 2rem;
  font-size: 14px;
  font-weight: 700;
  background-color: rgba(0, 17, 64, 0.75);
  color: #fff;
`;

export const Container = styled.div`
  &:nth-child(odd) {
    background-color: #f6f6f6;
  }
  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const ItemMobile = styled.div`
  padding: 1.5rem 1rem;
  font-size: 14px;
`;

export const ItemDesktop = styled.div`
  padding: 1.25rem 2rem;
  font-size: 14px;
`;

export const Status = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 0.4rem;
  display: inline-block;
  background-color: ${({ color }) => color};
  border-radius: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  margin-left: 50px;
  margin-top: 74px;
  margin-bottom: 24px;
  @media (max-width: 720px) {
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
  }
`;

export default Wrapper;
