/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/persona';

// ------- SAGA ------ //

function fetchDatosPersonalesResponse(state, action) {
  const { datosPersonales, errorDatosPersonales, success, errorLogPersona } = action.payload;
  state.errorDatosPersonales = errorDatosPersonales;
  state.edad = datosPersonales?.edad;
  state.numeroCliente = datosPersonales?.numeroCliente;
  state.genero = datosPersonales?.genero;
  state.rut = datosPersonales?.rut;
  state.primerNombre = datosPersonales?.primerNombre;
  state.segundoNombre = datosPersonales?.segundoNombre;
  state.apellidoPaterno = datosPersonales?.apellidoPaterno;
  state.apellidoMaterno = datosPersonales?.apellidoMaterno;
  state.correo = datosPersonales?.correo;
  state.profesion = datosPersonales?.profesion;
  state.telefono = datosPersonales?.telefono;
  state.rol = datosPersonales?.rol;
  state.performance = performance.now(); // patch para tener un update de la data
  state.success = success;
  state.errorLogPersona = errorLogPersona;
}

function fetchPersonaObtenerSuccess(state, action) {
  state.personaObtener = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchPersonaObtenerError(state) {
  state.personaObtener = {
    success: false,
    error: true,
    errorCode: '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchDireccionObtenerSuccess(state, action) {
  state.direccionObtener = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchDireccionObtenerError(state, action) {
  state.direccionObtener = {
    success: false,
    error: true,
    errorCode: action.payload.error,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchDatosContactoObtenerSuccess(state, action) {
  state.datosContactoObtener = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchDatosContactoObtenerError(state) {
  state.datosContactoObtener = {
    success: false,
    error: true,
    errorCode: '',
    data: null,
    date: new Date().toISOString(),
  };
}

function cleanTCoordenadasDesafio(state) {
  state.tCoordenadasDesafio = {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  };
}

function fetchTCoordenadasDesafioObtenerSuccess(state, action) {
  state.tCoordenadasDesafio = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchTCoordenadasDesafioObtenerError(state, action) {
  state.tCoordenadasDesafio = {
    success: false,
    error: true,
    errorCode: action.payload.error.code,
    data: null,
    date: new Date().toISOString(),
  };
}
function cleanTCoordenadasDesafioEnviar(state) {
  state.tCoordenadasDesafioResult = {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  };
}

function fetchTCoordenadasDesafioEnviarSuccess(state, action) {
  state.tCoordenadasDesafioResult = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchTCoordenadasDesafioEnviarError(state, action) {
  state.tCoordenadasDesafioResult = {
    success: false,
    error: true,
    errorCode: action.payload.error,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchEditDatosContactoSuccess(state, action) {
  state.datosContactoUpdate = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchEditDatosContactoError(state, action) {
  state.datosContactoUpdate = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function dComercialUpdateSuccess(state, action) {
  state.dComercialUpdate = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function dComercialUpdateError(state, action) {
  state.dComercialUpdate = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchEnvioEmailSuccess(state, action) {
  state.envioEmail = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchEnvioEmailError(state, action) {
  state.envioEmail = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchGetDesafioPassCoopeuchSuccess(state, action) {
  state.passCoopeuchDesafio = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchGetDesafioPassCoopeuchError(state, action) {
  state.passCoopeuchDesafio = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchSendDesafioPassCoopeuchSuccess(state, action) {
  state.passCoopeuchDesafioResult = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchSendDesafioPassCoopeuchError(state, action) {
  state.passCoopeuchDesafioResult = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchGetHabilitadoCellSuccess(state, action) {
  state.habilitadoCelular = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchGetHabilitadoCellError(state, action) {
  state.habilitadoCelular = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchGetHabilitadoEmailSuccess(state, action) {
  state.habilitadoCorreo = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchGetHabilitadoEmailError(state, action) {
  state.habilitadoCorreo = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

const fetchDatosPersonales = createAction('fetchDatosPersonales');
const fetchPersonaObtener = createAction('fetchPersonaObtener');
const fetchDireccionObtener = createAction('fetchObtenerDireccion');
const fetchDatosContactoObtener = createAction('fetchDatosContactoObtener');
const fetchTCoordenadasDesafioObtener = createAction('fetchTCoordenadasDesafioObtener');
const fetchTCoordenadasDesafioEnviar = createAction('fetchTCoordenadasDesafioEnviar');
const fetchEditProfileObtener = createAction('fetchEditProfileObtener');
const fetchEditDatosContacto = createAction('fetchEditDatosContacto');
const fetchEnvioEmailObtener = createAction('fetchEnvioEmailObtener');
const fetchDComercialUpdate = createAction('fetchDComercialUpdate');
const fetchGetPassCoopeuchDesafio = createAction('fetchGetPassCoopeuchDesafio');
const fetchSendPassCoopeuchDesafio = createAction('fetchSendPassCoopeuchDesafio');
const fetchGetHabilitadoCell = createAction('fetchGetHabilitadoCell');
const fetchGetHabilitadoEmail = createAction('fetchGetHabilitadoEmail');

const personaReducer = createSlice({
  name: 'persona',
  initialState,
  reducers: {
    fetchDatosPersonalesResponse,
    fetchPersonaObtenerSuccess,
    fetchPersonaObtenerError,
    fetchDireccionObtenerSuccess,
    fetchDireccionObtenerError,
    fetchDatosContactoObtenerSuccess,
    fetchDatosContactoObtenerError,
    cleanTCoordenadasDesafio,
    fetchTCoordenadasDesafioObtenerSuccess,
    fetchTCoordenadasDesafioObtenerError,
    cleanTCoordenadasDesafioEnviar,
    fetchTCoordenadasDesafioEnviarSuccess,
    fetchTCoordenadasDesafioEnviarError,
    fetchEditDatosContactoSuccess,
    fetchEditDatosContactoError,
    dComercialUpdateSuccess,
    dComercialUpdateError,
    fetchGetDesafioPassCoopeuchSuccess,
    fetchGetDesafioPassCoopeuchError,
    fetchSendDesafioPassCoopeuchSuccess,
    fetchSendDesafioPassCoopeuchError,
    fetchEnvioEmailSuccess,
    fetchEnvioEmailError,
    fetchGetHabilitadoCellSuccess,
    fetchGetHabilitadoCellError,
    fetchGetHabilitadoEmailSuccess,
    fetchGetHabilitadoEmailError,
  },
});

export const personaActions = {
  ...personaReducer.actions,
  fetchDatosPersonales,
  fetchPersonaObtener,
  fetchDireccionObtener,
  fetchDatosContactoObtener,
  fetchTCoordenadasDesafioObtener,
  fetchTCoordenadasDesafioEnviar,
  fetchEditProfileObtener,
  fetchEditDatosContacto,
  fetchEnvioEmailObtener,
  fetchDComercialUpdate,
  fetchGetPassCoopeuchDesafio,
  fetchSendPassCoopeuchDesafio,
  fetchGetHabilitadoCell,
  fetchGetHabilitadoEmail,
};

export default personaReducer.reducer;
