import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';


// UI
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Badge from '@coopeuch-components/web/atoms/Badge';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Button from '@coopeuch-components/web/atoms/Button';
import Skeleton from 'react-loading-skeleton';

// Styles
import Wrapper, { CustomList, BannerWeb, BannerMobile } from './styles';

// Utils
import { registerGA } from '../../../../utils/metrics';
import logger from '../../../../utils/logger';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SolicitarCuentaVista = ({ solicitaCV, loading, solicitaMonedero }) => {
  const history = useHistory();

  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));

  const handleSolicitarCV = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista',
      'eve-cat': 'Click',
      'eve-lab': 'Contrata Cuenta Coopeuch',
    });

    // log athenas 
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA',
      informacion_adicional: {},
    });

    solicitaCV();
  };

  const handleSolicitarMonedero = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista',
      'eve-cat': 'Click',
      'eve-lab': 'Contrata Monedero Digital',
    });

    history.push("/ecd/solicita/monedero-digital");
  };

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/solicitar/cuentaVista',
    });
  }, []);

  return (
    <Wrapper>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12 w-100">
          {loading ? (
            <Skeleton height={250} />
          ) : (
            <>
              <BannerWeb className="hide-on-mobile">
                <div className="row h-100 banner-actions">
                  <div className="col-xs-8 col-sm-10 col-md-10 col-lg-10">
                    <div className="mb-18">
                      <Typography
                        color={'white'}
                        fontFamily="ubuntu-bold"
                        tag="p"
                        fontSize={24}
                        className="mb-20"
                      >
                        Solicita tu Cuenta Vista
                      </Typography>
                      <Typography color={'white'} tag="p" fontSize={18}>
                        ¡Con Cuenta Coopeuch y Monedero Digital,
                      </Typography>
                      <Typography color={'white'} tag="p" fontSize={18}>
                        tienes muchas posibilidades!
                      </Typography>
                    </div>
                  </div>
                </div>
              </BannerWeb>
              <BannerMobile className="hide-on-desktop">
                <div className="mb-12">
                  <Typography color={'white'} tag="p" fontSize={18}>
                    Solicita tu Cuenta Vista
                  </Typography>
                  <Typography color={'white'} tag="p" fontSize={18}>
                    ¡Con Cuenta Coopeuch y Monedero Digital,
                  </Typography>
                  <Typography color={'white'} tag="p" fontSize={18}>
                    tienes muchas posibilidades!
                  </Typography>
                </div>
              </BannerMobile>
            </>
          )}
        </div>
      </div>

      <div className="row bmt-3 bmt-md-4 mb-22">
        <div className="col-xs-12 text-bold bmb-3 bmb-md-4 text-size-l">¿Cuál elegir?</div>

        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1">
            <CardBox.Header>
              {loading ? (
                <div className="d-flex middle-items">
                  <Skeleton circle height={30} width={30} className="bmr-2" />{' '}
                  <Skeleton width={130} />{' '}
                </div>
              ) : (
                <div className="container_cards">
                  <div className="align_title">
                    <LoadIcon
                      src={`${iconPath}credit_card.svg`}
                      size={30}
                      color="#000"
                      className="float-left bmr-2"
                    />
                    <p className="title_card">Cuenta Coopeuch</p>
                  </div>
                  <Badge text="Recomendado" bgColor="#CCF1C1" color="#006B14" classes="badge" />
                </div>
              )}
            </CardBox.Header>
            <CardBox.Body>
              <>
                <img
                  src={`${iconPath}ilustracion_mujer.svg`}
                  alt="ilustracion_mujer"
                  className="center"
                />
                <Typography
                  fontFamily="ubuntu-regular"
                  tag="p"
                  fontSize={16}
                  className="mb-20 mt-20"
                >
                  Incluye una tarjeta de débito, con la que podrás realizar compras nacionales e
                  internacionales, realizar transferencias, ganar puntos, entre otros beneficios.
                  Todo Esto <b>a un costo 0 si abonas tu sueldo.</b>
                </Typography>
              </>

              <CustomList img={`${iconPath}success_circle_check.svg`} className="mb-30">
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Transfiere desde y hacia otras instituciones bancarias.'
                  )}
                </li>
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Realiza transferencias entre tus productos Coopeuch.'
                  )}
                </li>
                <li>{loading ? <Skeleton width={200} /> : 'Recibiras tu Remanente.'}</li>
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Ganarás Puntos Coopeuch, los que podrás canjear por productos, servicios y mucho más.'
                  )}
                </li>
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Realiza Compras Internacionales y suscribe tus app favoritas sin comisiones.'
                  )}
                </li>
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Abona tu remuneración y obtendrás costo 0 en mantención y transacciones.'
                  )}
                </li>
              </CustomList>
              <Button
                bgColor={'#E81E2B'}
                color="white"
                className="action-button text_button"
                id="action-button"
                onClick={handleSolicitarCV}
                fontSize={14}
                fullWidth
              >
                Contrata Cuenta Coopeuch
              </Button>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header>
              {loading ? (
                <div className="d-flex middle-items">
                  <Skeleton circle height={30} width={30} className="bmr-2" />{' '}
                  <Skeleton width={130} />{' '}
                </div>
              ) : (
                <div className="container_cards">
                  <div className="align_title">
                    <LoadIcon
                      src={`${iconPath}credit_card.svg`}
                      size={30}
                      color="#000"
                      className="float-left bmr-2"
                    />
                    <p className="title_card">Monedero Digital</p>
                  </div>
                </div>
              )}
            </CardBox.Header>
            <CardBox.Body className="cardbox">
              <>
                <img
                  src={`${iconPath}ilustracion_hombre.svg`}
                  alt="ilustracion_hombre"
                  className="center"
                />
                <Typography
                  fontFamily="ubuntu-regular"
                  tag="p"
                  fontSize={16}
                  className="mb-20 mt-20"
                >
                  Es una <b>cuenta 100% digital, sin tarjetas.</b> Con ella podrás realizar
                  transferencias a tus cuentas personales de otros banco y entre tus productos
                  Coopeuch.
                </Typography>
              </>
              <CustomList img={`${iconPath}success_circle_check.svg`} className="mb-30">
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Transfiere desde y hacia otras instituciones bancarias.'
                  )}
                </li>
                <li>
                  {loading ? (
                    <Skeleton width={200} />
                  ) : (
                    'Realiza transferencias entre tus productos Coopeuch.'
                  )}
                </li>
                <li>{loading ? <Skeleton width={200} /> : 'Recibiras tu Remanente.'}</li>
              </CustomList>
              <Button
                bgColor="#E81E2B"
                color="white"
                className="action-button text_button button_bottom"
                id="action-button"
                onClick={handleSolicitarMonedero}
                fontSize={14}
                fullWidth
              >
                Contrata Monedero Digital
              </Button>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </Wrapper>
  );
};

SolicitarCuentaVista.propTypes = {
  coopBenefits: PropTypes.array,
  redCompra: PropTypes.array,
  payAndTransfer: PropTypes.array,
};

SolicitarCuentaVista.defaultProps = {
  coopBenefits: [],
  redCompra: [],
  payAndTransfer: [],
};

SolicitarCuentaVista.displayName = 'SolicitarCuentaVista_Template';

export default SolicitarCuentaVista;
