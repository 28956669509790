import { put, takeLatest, call, spawn } from 'redux-saga/effects';

import { personaActions } from '../../../application/actions/persona';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';

const apiPersona = api(process.env.REACT_APP_PERSONA);

export function* fetchDatosPersonales() {
  try {
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: true }));

    const { data, error } = yield call(apiPersona.post, 'datospersonales/v1/obtener', {});

    if (data) {
      yield put(
        personaActions.fetchDatosPersonalesResponse({
          datosPersonales: data,
          errorDatosPersonales: false,
          success: true,
          errorLogPersona: null,
        })
      );
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    } else {
      yield put(
        personaActions.fetchDatosPersonalesResponse({
          datosPersonales: {},
          errorDatosPersonales: true,
          success: false,
          errorLogPersona: error,
        })
      );
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    }
  } catch (err) {
    yield put(
      personaActions.fetchDatosPersonalesResponse({
        datosPersonales: {},
        errorDatosPersonales: true,
        success: false,
        errorLogPersona: err,
      })
    );
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
  }
}

export function* fetchPersonaObtener() {
  try {
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: true }));

    const { data, error } = yield call(apiPersona.post, 'datospersonales/v1/obtener', {});
    // yield logReqError(error); // TODO: esta función se usa en varios puntos pero en su definición no tiene ningún efecto: ui/utils/functions
    if (data) {
      yield put(personaActions.fetchPersonaObtenerSuccess({ data }));
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchPersonaObtenerError({ error }));
      yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
    }
  } catch (error) {
    console.log(error, 'fetchPersonaObtener');
    yield put(personaActions.fetchPersonaObtenerError({ error }));
    yield put(loadersActions.changeStatusLoadingDatosPersonales({ isLoading: false }));
  }
}

export function* fetchDireccionObtener() {
  try {
    yield put(loadersActions.fetchLoadingDireccionObtener({ isLoading: true }));
    const { data, error } = yield call(apiPersona.get, 'direccion/v1/obtener');

    if (data) {
      yield put(personaActions.cleanTCoordenadasDesafio());
      yield put(personaActions.cleanTCoordenadasDesafioEnviar());
      yield put(personaActions.fetchDireccionObtenerSuccess({ data }));
      yield put(loadersActions.fetchLoadingDireccionObtener({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchDireccionObtenerError({ errorCode: error }));
      yield put(loadersActions.fetchLoadingDireccionObtener({ isLoading: false }));
    }
  } catch (error) {
    yield put(personaActions.fetchDireccionObtenerError({ errorCode: error }));
    yield put(loadersActions.fetchLoadingDireccionObtener({ isLoading: false }));
  }
}

export function* fetchDatosContactoObtener() {
  try {
    yield put(loadersActions.fetchLoadingDatosContacto({ isLoading: true }));
    const { data } = yield call(apiPersona.get, 'datoscontacto/v1/obtener');

    if (data) {
      yield put(personaActions.cleanTCoordenadasDesafio());
      yield put(personaActions.cleanTCoordenadasDesafioEnviar());
      yield put(personaActions.fetchDatosContactoObtenerSuccess({ data }));
      yield put(loadersActions.fetchLoadingDatosContacto({ isLoading: false }));
    } else {
      yield put(personaActions.fetchDatosContactoObtenerError());
      yield put(loadersActions.fetchLoadingDatosContacto({ isLoading: false }));
    }
  } catch (error) {
    yield put(personaActions.fetchDatosContactoObtenerError());
    yield put(loadersActions.fetchLoadingDatosContacto({ isLoading: false }));
  }
}

export function* fetchTCoordenadasDesafioObtener(action) {
  try {
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const { data, error } = yield call(
      apiPersona.post,
      'datospersonales/factor/desafio/tcoo/v1/obtener',
      action.payload
    );

    if (data) {
      yield put(personaActions.cleanTCoordenadasDesafio());
      yield put(personaActions.cleanTCoordenadasDesafioEnviar());
      yield put(personaActions.fetchTCoordenadasDesafioObtenerSuccess({ data }));
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchTCoordenadasDesafioObtenerError({ error }));
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
  } catch (err) {
    console.log(err.message, 'fetchTCoordenadasDesafioObtener');
    yield put(personaActions.fetchTCoordenadasDesafioObtenerError({ error: '' }));
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  }
}

export function* fetchTCoordenadasDesafioEnviar(action) {
  try {
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));
    const { desafio1, desafio2, desafio3, track } = action.payload;
    const { data, error } = yield call(
      apiPersona.post,
      'datospersonales/factor/desafio/tcoo/v1/validar',
      {
        valorDesafio1: desafio1,
        valorDesafio2: desafio2,
        valorDesafio3: desafio3,
      },
      {
        track: `${track}`,
      }
    );
    if (data) {
      yield put(personaActions.fetchTCoordenadasDesafioEnviarSuccess({ data }));
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchTCoordenadasDesafioEnviarError({ error: error.code }));
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
  } catch (err) {
    console.log(err.message, 'fetchTCoordenadasDesafioEnviar');
    yield put(personaActions.fetchTCoordenadasDesafioEnviarError({ error: '' }));
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  }
}

export function* fetchEditDatosContacto(action) {
  try {
    yield put(loadersActions.fetchLoadingEditDatosContacto({ isLoading: true }));
    const { telefonoCelular, telefonoParticular, telefonoComercial, correo, track } =
      action.payload;

    const { data, error } = yield call(
      apiPersona.put,
      'datoscontacto/v1/actualizar',
      {
        telefonoCelular,
        telefonoParticular,
        telefonoComercial,
        correo,
      },
      {
        track: `${track}`,
      }
    );
    if (data) {
      yield put(personaActions.fetchEditDatosContactoSuccess({ data }));
      yield put(loadersActions.fetchLoadingEditDatosContacto({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchEditDatosContactoError({ errorCode: error || '' }));
      yield put(loadersActions.fetchLoadingEditDatosContacto({ isLoading: false }));
    }
  } catch (error) {
    yield put(personaActions.fetchEditDatosContactoError({ errorCode: error }));
    yield put(loadersActions.fetchLoadingEditDatosContacto({ isLoading: false }));
  }
}

export function* fetchDirecComercialUpdate(action) {
  try {
    yield put(loadersActions.fetchLoadingEditDirecComercial({ isLoading: true }));

    const { calle, comuna, departamentoOCasa, numero, region, tipoDireccion, track } =
      action.payload;
    const { data, error } = yield call(
      apiPersona.post,
      'direccion/v1/actualizar',
      {
        calle,
        comuna,
        departamentoOCasa,
        numero,
        region,
        tipoDireccion,
      },
      {
        track: `${track}`,
      }
    );
    if (data) {
      yield put(personaActions.dComercialUpdateSuccess({ data }));
      yield put(loadersActions.fetchLoadingEditDirecComercial({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.dComercialUpdateError({ errorCode: error || '' }));
      yield put(loadersActions.fetchLoadingEditDirecComercial({ isLoading: false }));
    }
  } catch (error) {
    console.log(error.message, 'fetchDirecComercialUpdate');
    yield put(personaActions.dComercialUpdateError({ errorCode: error || '' }));
    yield put(loadersActions.fetchLoadingEditDirecComercial({ isLoading: false }));
  }
}

export function* fetchEnvioEmailObtener() {
  try {
    const { data, error } = yield call(apiPersona.put, 'envioemail/v1/actualizar');
    if (data) {
      yield put(personaActions.fetchEnvioEmailSuccess({ data }));
    }
    if (error) {
      yield put(personaActions.fetchEnvioEmailError({ error }));
    }
  } catch (error) {
    console.log(error.message, 'fetchEnvioEmailObtener');
    yield put(personaActions.fetchEnvioEmailError({ error }));
  }
}

export function* fetchGetPassCoopeuchDesafio() {
  try {
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));
    const { data, error } = yield call(
      apiPersona.post,
      'datospersonales/factor/desafio/passcoopeuch/v1/obtener'
    );
    if (data) {
      yield put(personaActions.fetchGetDesafioPassCoopeuchSuccess({ data }));
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
    if (error) {
      yield put(
        personaActions.fetchGetDesafioPassCoopeuchError({ error: error.code || error || '' })
      );
    }
  } catch (error) {
    console.log(error.message, 'fetchGetDesafioPassCoopeuch');
    yield put(
      personaActions.fetchGetDesafioPassCoopeuchError({ error: error.code || error || '' })
    );
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  }
}

export function* fetchSendPassCoopeuchDesafio(action) {
  try {
    const { track } = action.payload;
    // console.log('track: ', track);

    const { data, error } = yield call(
      apiPersona.post,
      'datospersonales/factor/desafio/passcoopeuch/v1/validar',
      {
        authorizerCode: null,
      },
      {
        track,
      }
    );

    if (data) {
      yield put(personaActions.fetchSendDesafioPassCoopeuchSuccess({ data }));
    }
    if (error) {
      yield put(
        personaActions.fetchSendDesafioPassCoopeuchError({ error: error.code || error || '' })
      );
      console.log(error);
    }
  } catch (error) {
    console.log(error.message, 'fetchGetResolvePassCoopeuch');
    yield put(personaActions.fetchSendDesafioPassCoopeuchError({ error: error.code || '' }));
    console.log(error);
  }
}

export function* fetchGetHabilitadoCell() {
  try {
    yield put(loadersActions.fetchLoadingHabilitadoCelular({ isLoading: true }));
    const { data, error } = yield call(
      apiPersona.get,
      'datospersonales/configuracion/v1/consultar/ACTUALIZAR_CELULAR'
    );
    if (data) {
      yield put(personaActions.fetchGetHabilitadoCellSuccess({ data }));
      yield put(loadersActions.fetchLoadingHabilitadoCelular({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchGetHabilitadoCellError({ error: error.code || error || '' }));
      yield put(loadersActions.fetchLoadingHabilitadoCelular({ isLoading: false }));
    }
  } catch (error) {
    yield put(personaActions.fetchGetHabilitadoCellError({ error: error.code || error || '' }));
    yield put(loadersActions.fetchLoadingHabilitadoCelular({ isLoading: false }));
  }
}

export function* watchFetchGetHabilitadoCell() {
  yield takeLatest(personaActions.fetchGetHabilitadoCell.type, fetchGetHabilitadoCell);
}

export function* fetchGetHabilitadoEmail() {
  try {
    yield put(loadersActions.fetchLoadingHabilitadoCorreo({ isLoading: true }));
    const { data, error } = yield call(
      apiPersona.get,
      'datospersonales/configuracion/v1/consultar/ACTUALIZAR_CORREO'
    );
    if (data) {
      yield put(personaActions.fetchGetHabilitadoEmailSuccess({ data }));
      yield put(loadersActions.fetchLoadingHabilitadoCorreo({ isLoading: false }));
    }
    if (error) {
      yield put(personaActions.fetchGetHabilitadoEmailError({ error: error.code || error || '' }));
      yield put(loadersActions.fetchLoadingHabilitadoCorreo({ isLoading: false }));
    }
  } catch (error) {
    yield put(personaActions.fetchGetHabilitadoEmailError({ error: error.code || error || '' }));
    yield put(loadersActions.fetchLoadingHabilitadoCorreo({ isLoading: false }));
  }
}

export function* watchFetchGetHabilitadoEmail() {
  yield takeLatest(personaActions.fetchGetHabilitadoEmail.type, fetchGetHabilitadoEmail);
}

export function* watchFetchPassCoopeuchDesafioResult() {
  yield takeLatest(personaActions.fetchSendPassCoopeuchDesafio.type, fetchSendPassCoopeuchDesafio);
}

export function* watchFetchGetPassCoopeuchDesafio() {
  yield takeLatest(personaActions.fetchGetPassCoopeuchDesafio.type, fetchGetPassCoopeuchDesafio);
}
export function* watchFetchDatosPersonales() {
  yield takeLatest(personaActions.fetchDatosPersonales.type, fetchDatosPersonales);
}
export function* watchFetchPersonaObtener() {
  yield takeLatest(personaActions.fetchPersonaObtener.type, fetchPersonaObtener);
}
export function* watchFetchDireccionObtener() {
  yield takeLatest(personaActions.fetchDireccionObtener.type, fetchDireccionObtener);
}
export function* watchFetchDatosContactoObtener() {
  yield takeLatest(personaActions.fetchDatosContactoObtener.type, fetchDatosContactoObtener);
}
export function* watchFetchTCoordenadasDesafioObtener() {
  yield takeLatest(
    personaActions.fetchTCoordenadasDesafioObtener.type,
    fetchTCoordenadasDesafioObtener
  );
}
export function* watchFetchTCoordenadasDesafioEnviar() {
  yield takeLatest(
    personaActions.fetchTCoordenadasDesafioEnviar.type,
    fetchTCoordenadasDesafioEnviar
  );
}
export function* watchFetchEditDatosContacto() {
  yield takeLatest(personaActions.fetchEditDatosContacto.type, fetchEditDatosContacto);
}

export function* watchfetchEnvioEmailObtener() {
  yield takeLatest(personaActions.fetchEnvioEmailObtener.type, fetchEnvioEmailObtener);
}

export function* watchFetchDirecComercialUpdate() {
  yield takeLatest(personaActions.fetchDComercialUpdate.type, fetchDirecComercialUpdate);
}

export default function* rootSaga() {
  yield spawn(watchFetchDatosPersonales);
  yield spawn(watchFetchPersonaObtener);
  yield spawn(watchFetchDireccionObtener);
  yield spawn(watchFetchDatosContactoObtener);
  yield spawn(watchFetchTCoordenadasDesafioObtener);
  yield spawn(watchFetchTCoordenadasDesafioEnviar);
  yield spawn(watchFetchEditDatosContacto);
  yield spawn(watchfetchEnvioEmailObtener);
  yield spawn(watchFetchDirecComercialUpdate);
  yield spawn(watchFetchGetPassCoopeuchDesafio);
  yield spawn(watchFetchPassCoopeuchDesafioResult);
  yield spawn(watchFetchGetHabilitadoCell);
  yield spawn(watchFetchGetHabilitadoEmail);
}
