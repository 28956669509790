import React, { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

// Ui
import Error from '../../../../components/atoms/Error';

// Templates
import ComprobanteTemplate from '../../../../components/templates/CuentaAhorro/SolicitudCAFlujo/Comprobante';

// Hook
import useWindowSize from '../../../../utils/hooks/useWindowSize';

// Styles
import { size } from '../../../../utils/breakpoints';

// logger

import logger from '../../../../utils/logger';

const Comprobante = (props) => {
  const {
    mailSeleccionado,
    handleStep,
    handleClickReturnSolicitudCA,
    handleReloadCuentas,
    handleCleanCuenta,
  } = props;

  const [dataContratado, setDataContratado] = useState(null);
  const [errorGeneral, setErrorGeneral] = useState(false);

  // State ancho pantalla
  const [ischangeWidth, setIschangeWidth] = useState(false);

  // Redux
  const strContratacionGenerar = useSelector(
    (store) => store.entities.cuentasAhorro.contratacionGenerar,
    shallowEqual
  );

  const isLoadingContratGenerada = useSelector(
    (store) => store.ui.loaders.isLoadingContratado,
    shallowEqual
  );

  const baseRut = useSelector((state) => state.entities?.persona?.rut);

  // const handleClickReturn = () => handleStep(2);

  useEffect(() => {
    if (baseRut)
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CA-SOLICITUDPRODUCTO_EXITO',
        informacion_adicional: {},
      });
  }, [baseRut]);

  const handleClickReturn = () => {
    handleClickReturnSolicitudCA();
    handleReloadCuentas();
    handleCleanCuenta();
  };
  // Observador resultado envio
  useEffect(() => {
    if (strContratacionGenerar.success && !isLoadingContratGenerada) {
      if (Object.keys(strContratacionGenerar.data).length) {
        setDataContratado(strContratacionGenerar.data);
        console.log('se autoriza paso 3');
      }
    }
    if (strContratacionGenerar.error) {
      setErrorGeneral(strContratacionGenerar.error);
      console.log('Error en contratacion');
    }
  }, [strContratacionGenerar, isLoadingContratGenerada]);

  // Observador tamaño de pantalla
  const wideScreen = useWindowSize();
  const sizeTablet = parseInt(size.tablet.slice(0, -2), 10);

  useEffect(() => {
    if (wideScreen < sizeTablet) {
      setIschangeWidth(true);
    } else {
      setIschangeWidth(false);
    }
  }, [wideScreen, sizeTablet]);

  return (
    <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
      {errorGeneral ? (
        <Error className="bmt-3 bmt-md-4" />
      ) : (
        <ComprobanteTemplate
          loading={isLoadingContratGenerada}
          dataContratado={dataContratado}
          mailSeleccionado={mailSeleccionado}
          handleStep={handleStep}
          handleClickReturn={handleClickReturn}
          ischangeWidth={ischangeWidth}
        />
      )}
    </div>
  );
};

Comprobante.displayName = 'Comprobante_Container';
export default Comprobante;
