import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

  .resutado-simulacion-card-body {
    padding: 24px 32px 24px 32px;
  }

  .resultado-simulacion-body-desc {
    color: #383838;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }

  .resultado-simulacion-header-tarjeta {
    padding: 19px 22px 19px 22px;
    border-radius: 4px 4px 0 0;
    border: 1px solid rgba(0, 0, 0, 0.09);
  }

  .resultado-simulacion-body-tarjeta {
    border-radius: 0 0 4px 4px;
    border: 1px solid rgba(0, 0, 0, 0.09);
  }

  .resultado-simulacion-header-title {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .resultado-tarjeta-descripcion-impar {
    background-color: #f6f3f5;
    padding: 8px 16px 8px 16px;
  }

  .resultado-tarjeta-descripcion-par {
    background-color: #ffffff;
    padding: 8px 16px 8px 16px;
  }

  .resultado-simulacion-text-desc {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .resultado-active {
    background-color: rgba(0, 161, 223, 0.35);
  }
  .resultado-simulacion-icon svg {
    width: 24px;
    height: 24px;
    display: flex;
  }

  .vertical-center {
    align-self: center;
  }
  .button-return {
    font-weight: bold;
    width: 187px;
    text-align: left;
  }

  .button-continue {
    width: 187px;
  }

  .resultado-simulacion-tarjeta {
    cursor: pointer;
  }

  .display-active {
    display: initial;
  }

  .display-none {
    display: none;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 1rem 1rem 1rem;

    .resutado-simulacion-card-body {
      padding: 16px;
    }

    .simulacion-title-card-head h5 {
      color: #383638;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
    }

    .icon-alert {
      padding: 16px;
    }

    .icon-alert svg {
      height: 18px;
      width: 18px;
      margin-top: 3px;
    }

    .icon-alert > .right-content {
      align-items: start;
    }

    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }

    .button-continue {
      width: 100%;
    }

    .button-return {
      margin-left: 35%;
    }

    .resultado-tarjeta-descripcion-impar {
      padding: 16px 8px 16px 8px;
    }

    .resultado-tarjeta-descripcion-par {
      padding: 16px 8px 16px 8px;
    }
  }
`;

export const TitleWrapper = styled.div`
  .simulador-icon {
    display: flex;
    align-items: center;
  }

  .simulador-icon svg {
    height: 24px;
    width: 24px;
  }
`;

export const WrapperSkeleton = styled.div`
  .skeleton-center-vertical {
    align-self: center;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }
  }
`;

export default Wrapper;
