import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
// Utils
import { ResetCSS, UtilsCSS } from '@coopeuch-components/web';
import { LoadIconProvider } from '@coopeuch-components/web/atoms/LoadIcon';
// Configuration
import store from './application/config/store';
// Importar register de serviceWorker para producción
import { unregister } from './serviceWorker';
import GlobalStyles from './ui/assets/Theme';
// Main app
import App from './ui/pages';

ReactDOM.render(
  <Provider store={store} context={ReactReduxContext}>
    <React.StrictMode>
      <ResetCSS />
      <UtilsCSS />
      <GlobalStyles />
      <LoadIconProvider>
        <App />
      </LoadIconProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// Descomentar para producción
unregister();
// register();
