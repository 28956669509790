import { call, put, spawn, takeLatest } from 'redux-saga/effects';
// actions
import { bonoEscolarActions } from '../../../application/actions/bonoEscolar';
// api
import api from '../../api';
// Utils
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';

const apiBono = api(process.env.REACT_APP_BONO_ESCOLAR);

export function* fetchGetInstitutesAndCareers() {
  try {
    const { data, error } = yield call(apiBono.post, 'carreras/v1/listar');
    if (data) {
      yield put(bonoEscolarActions.fetGetInstitutesAndCareersSuccess({ data }));
    } else {
      yield put(bonoEscolarActions.fetGetInstitutesAndCareersFailed({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.fetGetInstitutesAndCareersFailed({ error }));
  }
}

export function* fetchUploadFileToServer(action) {
  const { contenido, mimeType, tipoDocumento } = action.payload;
  const params = {
    contenido,
    mimeType,
    tipoDocumento,
  };
  try {
    const { data, error } = yield call(
      apiBono.post,
      'carga-documentos-temporal/v1/agregar',
      params
    );
    if (data) {
      yield put(bonoEscolarActions.fetchUploadFileToServerSuccess({ data, tipoDocumento }));
    } else {
      yield put(bonoEscolarActions.fetchUploadFileToServerFailed({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.fetchUploadFileToServerFailed({ error }));
  }
}

export function* fetchSaveBono(action) {
  const {
    idPostulacion,
    nombreSocio,
    rutBeneficiario,
    nombreBeneficiario,
    telefono,
    email,
    idInstitucion,
    idCarrera,
    idTipoPostulacion,
    documentos,
    isUpdate,
  } = action.payload;

  const params = {
    idPostulacion,
    nombreSocio,
    rutBeneficiario,
    nombreBeneficiario,
    telefono,
    email,
    idInstitucion,
    idCarrera,
    idTipoPostulacion,
    documentos,
  };
  try {
    const { data, error } = yield call(
      apiBono.post,
      `v1/${isUpdate ? 'actualizar' : 'ingresar'}`,
      params
    );
    if (data) {
      yield put(bonoEscolarActions.fetchSaveBonoSuccess({ data }));
    } else {
      yield put(bonoEscolarActions.fetchSaveBonoFailed({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.fetchSaveBonoFailed({ error }));
  }
}

export function* fetchTypeUser() {
  try {
    const { data, error } = yield call(apiBono.post, 'reglas/v1/validar');
    if (data) {
      yield put(bonoEscolarActions.fetchTypeUserSuccess({ data }));
    } else {
      yield put(bonoEscolarActions.fetchTypeUserFailed({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.fetchTypeUserFailed({ error }));
  }
}

export function* fetchDownloadPdfBono(action) {
  const { idPostulacion, tipoComprobante } = action.payload;
  try {
    yield put(bonoEscolarActions.fetchDownloadPdfBonoPending());
    const { data, error } = yield call(apiBono.post, 'comprobante/v1/descargar', {
      idPostulacion,
      tipoComprobante,
    });
    if (data) {
      downloadPDFBase64OtherTab(data.contenido);
      yield put(bonoEscolarActions.fetchDownloadPdfBonoSuccess({ data }));
    } else {
      yield put(bonoEscolarActions.fetchDownloadPdfBonoError({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.fetchDownloadPdfBonoError({ error }));
  }
}

export function* fetchListaSolicitudes() {
  try {
    const { data, error } = yield call(apiBono.post, 'v1/listar');
    if (data) {
      yield put(bonoEscolarActions.listaSolicitudesSuccess({ lista: data }));
    } else {
      yield put(bonoEscolarActions.listaSolicitudesError({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.listaSolicitudesError({ error }));
  }
}

export function* fetchDuplicidadBono(action) {
  const { rutBeneficiario , tipoPostulacion } = action.payload;
  try {
    const { data, error } = yield call(apiBono.post, 'beneficiario/v1/validar', {
      rutBeneficiario,
      tipoPostulacion,
    });
    if (data) {
      yield put(bonoEscolarActions.fetchDuplicidadBonoSuccess({ data }));
    } else {
      yield put(bonoEscolarActions.fetchDuplicidadBonoError({ error }));
    }
  } catch (error) {
    yield put(bonoEscolarActions.fetchDuplicidadBonoError({ error }));
  }
}

export function* watchFetchGetInstitutesAndCareers() {
  yield takeLatest(
    bonoEscolarActions.fetchGetInstitutesAndCareers.type,
    fetchGetInstitutesAndCareers
  );
}

export function* watchFetchUploadFileToServer() {
  yield takeLatest(bonoEscolarActions.fetchUploadFileToServer.type, fetchUploadFileToServer);
}

export function* watchFetchSaveBono() {
  yield takeLatest(bonoEscolarActions.fetchSaveBono.type, fetchSaveBono);
}

export function* watchFetchTypeUser() {
  yield takeLatest(bonoEscolarActions.fetchTypeUser.type, fetchTypeUser);
}

export function* watchFetchDownloadPdfBono() {
  yield takeLatest(bonoEscolarActions.fetchDownloadPdfBono.type, fetchDownloadPdfBono);
}

export function* watchFetchListaSolicitudes() {
  yield takeLatest(bonoEscolarActions.fetchListaSolicitudes.type, fetchListaSolicitudes);
}

export function* watchFetchDuplicidadBono() {
  yield takeLatest(bonoEscolarActions.fetchDuplicidadBono.type, fetchDuplicidadBono);
}

export default function* rootSaga() {
  yield spawn(watchFetchGetInstitutesAndCareers);
  yield spawn(watchFetchUploadFileToServer);
  yield spawn(watchFetchSaveBono);
  yield spawn(watchFetchTypeUser);
  yield spawn(watchFetchDownloadPdfBono);
  yield spawn(watchFetchListaSolicitudes);
  yield spawn(watchFetchDuplicidadBono);
}
