import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Actions
import { creditoHipotecarioActions } from '../../../../application/actions/creditoHipotecario';

// Ui
import Error from '../../../components/atoms/Error';

// Utils
import { simpleFormatArrForSelect } from '../../../utils/formatters';
import { arrayInRange } from '../../../utils/functions';
// Templates
import HistorialTemplate from '../../../components/templates/CreditoHipotecario/Historial';

const Historial = () => {
  // States
  const [mounted, setMounted] = useState(false);
  const [errorGeneral, setErrorGeneral] = useState(false);

  const [credito, setCredito] = useState(null);
  const [feePaid, setFeePaid] = useState(null);
  const [isloadingPaid, setIsloadingPaid] = useState(true);
  const [selectedCredito, setSelectedCredito] = useState(null);

  const [yearSelected, setYearSelected] = useState(null);
  const [fillforSelect, setFillforSelect] = useState([]);
  const [dataHistorial, setDataHistorial] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isLoadFillForSelect, setIsLoadFillForSelect] = useState(true);

  // states infinite scroll
  const [isLoadingMoreItems, setLoadingMoreItems] = useState(false);
  const [offset, setOffset] = useState(20);

  // Metodos Redux
  const dispatch = useDispatch();

  const storageCreditos = useSelector(
    (store) => store.entities.creditoHipotecario.credito,
    shallowEqual
  );

  const storageHistorialPagado = useSelector(
    (store) => store.entities.creditoHipotecario.historialPago,
    shallowEqual
  );

  const prepareFeePaid = (data) => {
    const feePaidData = data.map((item, i) => ({
      fecha: new Date(item.fechaPago),
      index: i,
      ...item,
    }));
    return feePaidData;
  };

  const handleOnChangeSelectAccount = (e) => {
    setSelectedCredito(credito.find((c) => c.value === e.target.value));
    setYearSelected(null);
  };

  const handleOnChangeSelectAno = (e) => {
    setYearSelected(e.target.value);
  };

  const handleDownloadPDF = () => {
    dispatch(
      creditoHipotecarioActions.fetchHistorialPdfPago({
        accountNumber: selectedCredito.value,
        yearSelected,
      })
    );
  };

  const onLoadMoreItems = () => {
    setLoadingMoreItems(true);
    setTimeout(() => {
      const limit = offset + 20;
      setDataHistorial(feePaid.slice(0, limit));
      setOffset(offset + 20);
      setLoadingMoreItems(false);
    }, 1500);
  };

  useEffect(() => {
    if (!mounted) {
      dispatch(creditoHipotecarioActions.fetchCredito());
      setMounted(true);
    }
  }, [mounted, dispatch]);

  useEffect(() => {
    if (storageCreditos.success && storageCreditos.data.length > 0) {
      setCredito(
        storageCreditos.data.map((creditos) => ({
          label: `Vivienda Tasa Fija Nº ${creditos.numeroReferencia}`,
          value: creditos.numeroReferencia,
          ...creditos,
        }))
      );
      setErrorGeneral(false);
    }
    if (storageCreditos.error) {
      setErrorGeneral(true);
    }
  }, [storageCreditos]);

  useEffect(() => {
    if (credito?.length) {
      setSelectedCredito(credito[0]);
      setIsLoaded(false);
      const anioMinimo = Number(credito[0]?.fechaOtorgamiento.slice(0, 4));
      const anioMaximo = new Date().getFullYear();
      setFillforSelect(
        simpleFormatArrForSelect(arrayInRange(anioMinimo, anioMaximo), 'label', 'value')
      );
    }
  }, [credito]);

  useEffect(() => {
    if (selectedCredito) {
      const anioMinimo = Number(selectedCredito.fechaOtorgamiento.slice(0, 4));
      const anioMaximo = new Date().getFullYear();
      setFillforSelect(
        simpleFormatArrForSelect(arrayInRange(anioMinimo, anioMaximo), 'label', 'value')
      );
    }
  }, [selectedCredito]);

  useEffect(() => {
    if (selectedCredito) {
      dispatch(
        creditoHipotecarioActions.fetchHistorialPago({
          accountNumber: selectedCredito.value,
          yearSelected,
        })
      );
    }
  }, [selectedCredito, yearSelected, dispatch]);

  useEffect(() => {
    if (storageHistorialPagado.success && storageHistorialPagado.data.length > 0) {
      const data = prepareFeePaid(storageHistorialPagado.data);
      setFeePaid(data, 'fechaPago');
    } else {
      setDataHistorial(null);
    }
    if (storageHistorialPagado.error) {
      setErrorGeneral(true);
    }
  }, [storageHistorialPagado, yearSelected]);

  // Observador cargador isLoadFillForSelect
  useEffect(() => {
    if (fillforSelect.length > 0) {
      setIsLoadFillForSelect(false);
    } else {
      setIsLoadFillForSelect(true);
    }
  }, [fillforSelect]);

  useEffect(() => {
    if (feePaid) {
      setDataHistorial(feePaid.slice(0, offset));
      setIsloadingPaid(false);
    }
  }, [feePaid, offset]);

  return (
    <>
      {errorGeneral ? (
        <Error className="bmt-3 bmt-md-4 bmb-xs-4 bmb-md-4" />
      ) : (
        <HistorialTemplate
          {...{
            credito,
            isLoaded,
            selectedCredito,
            handleOnChangeSelectAccount,
            handleOnChangeSelectAno,
            yearSelected,
            fillforSelect,
            isLoadFillForSelect,
            isloadingPaid,
            isLoadingMoreItems,
            onLoadMoreItems,
            dataHistorial,
            handleDownloadPDF,
          }}
        />
      )}
    </>
  );
};
Historial.displayName = 'Historial_CredHipotecario_Container';
export default Historial;
