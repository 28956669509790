import { Button, Stamp, Table } from '@design-system-coopeuch/web';
import React from 'react';
// Atoms
import { formatRut } from '../../../../utils/formatters';
import { titleCase } from '../../../../utils/functions';
import CardShadow from '../../../atoms/CardShadow';
import Wrapper from './styles';

const CardComprobante = ({
  dataBonos,
  saveBono,
  isFetchingDownloadPdfBono,
  handleDownloadPdfBono,
}) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const postulationType = {
    1: 'Ingreso',
    2: 'Ingreso',
    3: 'Titulación',
    4: 'Titulación',
  };
  const attachedDocumentsTableRow = [];

  if (dataBonos.documentos.isRejected) {
    attachedDocumentsTableRow.push([
      'Documentos adjuntados',
      <>
        {saveBono.data?.documentos.map((document) => (
          <span key={document} className="d-block">
            {/CEDULA_IDENTIDAD_SOCIO/.test(document) && 'Cédula de identidad del socio'}
            {/CEDULA_IDENTIDAD_HIJO/.test(document) && 'Cédula de identidad del postulante'}
            {/DOCUMENTO_ADICIONAL/.test(document) && 'Comprobante de pago de matrícula'}
            {/CERTIFICADO_TITULO/.test(document) && 'Certificado de título'}
          </span>
        ))}
      </>,
    ]);
  }

  return (
    <Wrapper>
      <div className="cardComprobante">
        <CardShadow
          padding={0}
          title={
            <>
              <div className="row">
                <div className="col-xs-6 text-comprobante">Comprobante</div>
                <div className="col-xs-6 d-flex justify-content-end">
                  <Button
                    color="primary"
                    variant="text"
                    className="my-n3 mr-n5"
                    loading={isFetchingDownloadPdfBono}
                    prefix={isFetchingDownloadPdfBono ? null : 'line-download-2'}
                    onClick={() => {
                      if (!isFetchingDownloadPdfBono) {
                        handleDownloadPdfBono();
                      }
                    }}
                  >
                    Descargar
                  </Button>
                </div>
              </div>
            </>
          }
          content={
            <>
              <h1 className="cardComprobante__title">
                {dataBonos.documentos.isRejected
                  ? 'Carga de documentos objetados'
                  : 'Postulación a Bono de estudio'}
              </h1>
              {dataBonos?.typeBeneficiario === 'Para Mí' ? (
                <Table
                  tableData={[
                    [
                      'Titular',
                      saveBono.data?.nombreSocio && titleCase(saveBono.data?.nombreSocio),
                    ],
                    ['RUT', saveBono.data?.rutSocio && formatRut(saveBono.data?.rutSocio)],
                    ['Beneficio', `Bono de ${postulationType[saveBono.data?.idTipoPostulacion]}`],
                    ...attachedDocumentsTableRow,
                  ]}
                />
              ) : (
                <Table
                  tableData={[
                    [
                      'Hijo(a)',
                      saveBono.data?.nombreBeneficiario &&
                        titleCase(saveBono.data?.nombreBeneficiario),
                    ],
                    [
                      'RUT',
                      saveBono.data?.rutBeneficiario && formatRut(saveBono.data?.rutBeneficiario),
                    ],
                    ['Beneficio', `Bono de ${postulationType[saveBono.data?.idTipoPostulacion]}`],
                    ...attachedDocumentsTableRow,
                  ]}
                />
              )}
              <div
                className={`cardComprobante__footer${
                  dataBonos.documentos.isRejected ? ' border-top' : ''
                }`}
              >
                <div className="cardComprobante__footer_fechaHora">
                  <p>Fecha y hora:</p>
                  <p>
                    {dataBonos.documentos.isRejected
                      ? saveBono.data?.fechaModificacion
                      : saveBono.data?.fechaIngreso}
                  </p>
                </div>

                <div className="cardComprobante__footer_sello">
                  <Stamp type="check" />
                  <p>Transacciones 100% seguras</p>
                </div>
              </div>
            </>
          }
        />
      </div>
    </Wrapper>
  );
};

export default CardComprobante;
