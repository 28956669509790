import styled from 'styled-components';

export const SwiperContainer = styled.div`
  /* height: 200px; */
  border-radius: 5px;
  overflow: hidden;
  .swiper-slide {
    width: 100% !important;
  }
  .slider-action-column {
    /* display: flex;
    align-items: center; */
  }
  .slider-action-banner {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }

  .swiper-pagination-bullet-active{
    background-color: #f42534 !important;
    width: 20,
    height; 20
  }
`;
