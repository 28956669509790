import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

// Ui
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import Error from '../../components/atoms/Error';

// Actions
import { personaActions } from '../../../application/actions/persona';
import { empleadorActions } from '../../../application/actions/empleador';
import { factoresSeguridadActions } from '../../../application/actions/factoresSeguridad';
import logger from '../../utils/logger';

// Metrics
import { registerGA } from '../../utils/metrics';

// Components
import Forms from './Forms';

// Utils
import {
  formatArrayForSelect,
  formatArrayForSelectThreeV,
  formatCellPhone,
  formatRegionPhone,
  isValidEmail,
} from '../../utils/formatters';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

// Estado inicial cuando socio no posee direccion comercial
const initDirecComercial = {
  calle: '',
  codigoComuna: '00',
  codigoPostal: '',
  codigoRegion: '00',
  departamento: '',
  tipoDireccion: 'COM',
};

const Index = () => {
  const [isServiceError, setIsServiceError] = useState(false);

  // Data Personal Socio
  const [dataPersona, setDataPersona] = useState(null);
  const [datosContacto, setDatosContacto] = useState(null);
  const [direccionPersona, setDireccionPersona] = useState(null);
  const [direccionComercial, setDireccionComercial] = useState(initDirecComercial);
  const [termsAndCondicions, setTermsAndCondicions] = useState(false);
  const [habilitadoCell, setHabilitadoCell] = useState(false);
  const [habilitadoEmail, setHabilitadoEmail] = useState(false);
  const [datosAnteriores, setDatosAnteriores] = useState();
  // Flags button factor
  const [hasDisabledButton, setHasDisabledButton] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  // Flags reload llamado a servicios
  const [isReloadDContacto, setIsReloadDContacto] = useState(false);
  const [isReloadDComercial, setIsReloadDComercial] = useState(false);

  const [alertState, setAlertState] = useState('info');
  // Data Regiones
  const [dataRegiones, setDataRegiones] = useState(null);
  const [isloadingDRegiones, setIsloadingDRegiones] = useState(true);

  // Data Comunas
  const [dataComunas, setDataComunas] = useState(null);
  const [isLoadingDComunas, setIsLoadingDComunas] = useState(true);

  //flags
  const [flagReintentar, setFlagReintentar] = useState({status: false, method: ''});

  // Flag para mensaje de exito
  const [userUpdate, setUserUpdate] = useState({ dContacto: false, dComercial: false });

  //direccion datos anteriores
  const [dataDireccionAnterior, setDataDireccionAnterior] = useState()

  // States Factores de Seguridad
  const [factoresSeguridad, setFactoresSeguridad] = useState(null);
  const [reloadFactores, setReloadFactores] = useState(false);
  const [factorError, setFactorError] = useState(null);
  const [desafio, setDesafio] = useState({ loader: false });
  const [sendDesafio, setSendDesafio] = useState({ desafio1: '', desafio2: '', desafio3: '' });
  const [resultDesafio, setResultDesafio] = useState(false);

  // Counter
  const [counterActive, setCounterActive] = useState(false);
  const [timeOut, setTimeOut] = useState(false);

  // Ref
  const datosContactoRef = useRef(null);
  const direcComercialRef = useRef(null);

  //modal
  const [showModal, setShowModal] = useState(false);

  // Metodos Redux
  const dispatch = useDispatch();

  const handleShowModal = (show) => {
    setShowModal(show);
  };

  const loadingPersona = useSelector(
    (store) => store.ui.loaders.isLoadingDatosPersonales,
    shallowEqual
  );

  const storageDataPersona = useSelector(
    (store) => store.entities.persona.personaObtener,
    shallowEqual
  );

  const loadingDirecciones = useSelector(
    (store) => store.ui.loaders.isLoadingDireccionObtener,
    shallowEqual
  );

  const loadingEditDirecComercial = useSelector(
    (store) => store.ui.loaders.isLoadingEditDireccionComercial,
    shallowEqual
  );

  const strDirecciones = useSelector(
    (store) => store.entities.persona.direccionObtener,
    shallowEqual
  );

  const loadingDatosContacto = useSelector(
    (store) => store.ui.loaders.isLoadingDatosContacto,
    shallowEqual
  );

  const loadingEditDatosContacto = useSelector(
    (store) => store.ui.loaders.isLoadingEditDatosContacto,
    shallowEqual
  );

  const strDatosContacto = useSelector(
    (store) => store.entities.persona.datosContactoObtener,
    shallowEqual
  );

  const storageDataRegiones = useSelector(
    (store) => store.entities.empleador.regionObtener,
    shallowEqual
  );

  const storageDataComunas = useSelector(
    (store) => store.entities.empleador.comunasObtener,
    shallowEqual
  );

  const loadingFactores = useSelector(
    (store) => store.ui.loaders.isLoadingFactoresSeguridad,
    shallowEqual
  );

  const storageDataFactores = useSelector(
    (store) => store.entities.factoresSeguridad,
    shallowEqual
  );

  const loadingHabilitadoCell = useSelector(
    (store) => store.ui.loaders.isLoadingHabilitadoCell,
    shallowEqual
  );

  const loadingHabilitadoEmail = useSelector(
    (store) => store.ui.loaders.isLoadingHabilitadoEmail,
    shallowEqual
  );

  const strHabilitadoCelular = useSelector(
    (store) => store.entities.persona.habilitadoCelular,
    shallowEqual
  );

  const strHabilitadoCorreo = useSelector(
    (store) => store.entities.persona.habilitadoCorreo,
    shallowEqual
  );

  const persona = useSelector((store) => store.entities.persona, shallowEqual);


  const handleCleanStates = () => {
    setUserUpdate({ dContacto: false, dComercial: false });
    setFactorError(null);
    setDesafio({
      loader: false,
    });
    setSendDesafio({ desafio1: '', desafio2: '', desafio3: '' });
    setCounterActive(false);
    setTimeOut(false);
    setTermsAndCondicions(false);
    setResultDesafio(false);
  };

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  };

  /**
   * Se limpian estados al primer montaje
   * Primer llamado de Data a los Saga
   */
  useEffect(() => {  
    const baseRut = String((((persona || {}).rut || localStorage.getItem('user-rut') || '') || '').replace('-', ''));
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'EDITAR_DATOS',
      informacion_adicional: {},
    });
  }, [])
  useEffect(() => {
    registerGA({
      'event': 'vpv-privado',
      'page' : '/tef/editar-datos'
    });
  }, []);
  useEffect(() => {
    setAlertState('info')
    dispatch(personaActions.fetchPersonaObtener());
    dispatch(personaActions.fetchDireccionObtener());
    dispatch(personaActions.fetchDatosContactoObtener());
    dispatch(personaActions.fetchGetHabilitadoCell());
    dispatch(personaActions.fetchGetHabilitadoEmail());

    dispatch(empleadorActions.fetchRegionObtener());
    dispatch(empleadorActions.fetchComunas(0));
    dispatch(factoresSeguridadActions.fetchFactoresSeguridad());

    handleCleanStates();
  }, []);

  /**
   *  Observador Update Factores
   */
  useEffect(() => {
    if (reloadFactores) {
      dispatch(factoresSeguridadActions.fetchFactoresSeguridad());
      handleCleanStates();
    }
  }, [reloadFactores]);

  /**
   * Observador Update de Datos de contacto
   */
  useEffect(() => {
    if (isReloadDContacto === true && !loadingEditDatosContacto) {
      dispatch(personaActions.fetchDatosContactoObtener());
      setUserUpdate({ ...userUpdate, dContacto: true });
      handleScroll(datosContactoRef.current);
    }
  }, [isReloadDContacto, dispatch, loadingEditDatosContacto]);

  /**
   * Observador Update Direccion Comercial
   */
  useEffect(() => {
    if (isReloadDComercial === true && !loadingEditDirecComercial) {
      dispatch(personaActions.fetchDireccionObtener());
      setUserUpdate({ ...userUpdate, dComercial: true });
      handleScroll(direcComercialRef.current);
      setSendDesafio('');
    }
  }, [isReloadDComercial, dispatch, loadingEditDirecComercial]);

  // Observador Cargador dataRegiones
  useEffect(() => {
    if (dataRegiones !== null) {
      setIsloadingDRegiones(false);
    } else {
      setIsloadingDRegiones(true);
    }
  }, [dataRegiones]);

  // Observador Cargador dataComunas
  useEffect(() => {
    if (dataComunas !== null) {
      setIsLoadingDComunas(false);
    } else {
      setIsLoadingDComunas(true);
    }
  }, [dataComunas]);

  /**
   * Se observa si socio puede Editar Celular
   */
  useEffect(() => {
    if (strHabilitadoCelular.success && strHabilitadoCelular.data) {
      if (strHabilitadoCelular?.data?.value === 'NO_HABILITADO') {
        setHabilitadoCell(true);
        registerGA({
          'event': 'eve',
          'eve-acc' : '/tef/editar-datos',
          'eve-cat' : 'Mensaje',
          'eve-lab' : 'Actualizar telefono celular'
        });
      } else {
        setHabilitadoCell(false);
      }
    }
    if (strHabilitadoCelular.error) {
      setIsServiceError(true);
    }
  }, [strHabilitadoCelular]);

  useEffect(() => {
    let descriptionError;
    if (factorError) {
      if (factorError === '3') {
        descriptionError = 'Tarjeta coordenadas - tiempo agotado';
        setFlagReintentar({status: true, method: 'Tarjeta coordenadas - Tiempo agotado - Reintentar'})
      }
      if (factorError === '2') {
        descriptionError = 'Tarjeta coordenadas - incorrectas';
      }
      if (factorError === '4' || factorError === '5') {
        descriptionError = 'Tarjeta coordenadas - bloqueada';
      }
      if (factorError === '1') {
        descriptionError = 'PassCoopeuch - tiempo agotado';
        setFlagReintentar({status: true, method: 'PassCoopeuch - Tiempo agotado - Reintentar'})
      }
      if (factorError === '8') {
        descriptionError = 'PassCoopeuch - autorización rechazada';
        setFlagReintentar({status: true, method: 'PassCoopeuch - Autorización rechazada - Reintentar'})
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/editar-datos',
          'eve-cat': 'Error',
          'eve-lab': `PassCoopeuch - Operación rechazada`,
        });
      }
      if (factorError === '7') {
        descriptionError = 'PassCoopeuch - bloqueada';
      }
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/editar-datos-personales',
        'eve-cat': 'Error',
        'eve-lab': `${descriptionError}`,
      });
    }
  }, [factorError]);
    /**
   * Se observa si socio puede Editar Correo
   */
    useEffect(() => {
      if (strHabilitadoCorreo.success && strHabilitadoCorreo.data) {
        if (strHabilitadoCorreo?.data?.value === 'NO_HABILITADO') {
          // console.log('no habilitado');
          setHabilitadoEmail(true);
        } else {
          // console.log('Habilitado');
          setHabilitadoEmail(false);
        }
      }
      if (strHabilitadoCorreo.error) {
        console.log('error de servicio strHabilitadoCorreo');
        setIsServiceError(true);
      }
    }, [strHabilitadoCorreo]);

  // Data Persona
  useEffect(() => {
    if (storageDataPersona.success && !loadingPersona) {
      if (Object.keys(storageDataPersona.data).length > 0) {
        setDataPersona({
          ...storageDataPersona.data,
        });
      }
    }
    if (storageDataPersona.error) {
      setIsServiceError(true);
    }
  }, [storageDataPersona, loadingPersona]);

  // Data Direcciones
  useEffect(() => {
    if (strDirecciones.success && !loadingDirecciones) {
      setIsReloadDComercial(false);
      if (strDirecciones.data.length) {
        const direccionParticularSocio = strDirecciones.data.filter(
          (direccion) =>
            direccion.tipoDireccion === 'DIRECCION PARTICULAR' || direccion.tipoDireccion === 'PAR'
        );
        const direccionComercialSocio = strDirecciones.data.filter(
          (direccion) =>
            direccion.tipoDireccion === 'DIRECCION COMERCIAL' || direccion.tipoDireccion === 'COM'
        );
        // Nuevo State direccion Personal
        setDireccionPersona({
          direccion: `${direccionParticularSocio[0]?.calle.trim() || ''} ${
            direccionParticularSocio[0]?.numero || ''
          } ${
            direccionParticularSocio[0]?.departamento
              ? `depto ${direccionParticularSocio[0]?.departamento} `
              : ''
          }${direccionParticularSocio[0]?.villa || ''}`,
        });
        setDataDireccionAnterior(direccionComercialSocio[0]);
        console.log('direccionComercialSocio[0]', direccionComercialSocio[0])
        setDireccionComercial(direccionComercialSocio[0] || initDirecComercial);
      }
    }
    if (strDirecciones.error) {
      setIsServiceError(true);
    }
  }, [strDirecciones, loadingDirecciones]);

  // Data Contacto
  useEffect(() => {
    if (strDatosContacto.success && !loadingDatosContacto) {
      // Stop reload Datos Contacto
      setIsReloadDContacto(false);
      if (strDatosContacto.data) {
        const { data } = strDatosContacto;
        setDatosAnteriores({
          telefonoCelular: formatCellPhone(data?.telefonoCelular),
          telefonoParticular: formatRegionPhone(data?.telefonoParticular),
          telefonoComercial: formatRegionPhone(data?.telefonoComercial),
          empleador: data?.empleador,
          correo: data?.correo,
        })
        setDatosContacto({
          telefonoCelular: formatCellPhone(data?.telefonoCelular),
          telefonoParticular: formatRegionPhone(data?.telefonoParticular),
          telefonoComercial: formatRegionPhone(data?.telefonoComercial),
          empleador: data?.empleador,
          correo: data?.correo,
        });
      }
    }
    if (strDatosContacto.error) {
      setIsServiceError(true);
    }
  }, [strDatosContacto, loadingDatosContacto]);

  // Data Regiones
  useEffect(() => {
    if (storageDataRegiones.success) {
      setDataRegiones(
        formatArrayForSelect(storageDataRegiones.data, 'value', 'label', 'codigo', 'descripcion')
      );
    }
    if (storageDataRegiones.error) {
      setIsServiceError(true);
    }
  }, [storageDataRegiones]);

  // Data Comunas
  useEffect(() => {
    if (storageDataComunas.success) {
      setDataComunas(
        formatArrayForSelectThreeV(
          storageDataComunas.data,
          'value',
          'label',
          'region',
          'codigo',
          'descripcion',
          'codigoRegion'
        )
      );
    }
    if (storageDataComunas.error) {
      setIsServiceError(true);
    }
  }, [storageDataComunas]);

  // Data Factores
  useEffect(() => {
    if (storageDataFactores.factoresSeguridad && !loadingFactores) {
      setReloadFactores(false);
      setFactoresSeguridad(storageDataFactores.factoresSeguridad);
    }

    if (storageDataFactores.errorFactoresSeguridad === true && !loadingFactores) {
      setIsServiceError(true);
    }
  }, [storageDataFactores, loadingFactores]);

  /**
   * Observador errores de Datos Personales
   */
  const evaluatePhone = (key, lenghtPhones) => key?.length === lenghtPhones;

  useEffect(() => {
    if (
      evaluatePhone(datosContacto?.telefonoCelular, 13) &&
      evaluatePhone(datosContacto?.telefonoParticular, 13) &&
      evaluatePhone(datosContacto?.telefonoComercial, 13) &&
      isValidEmail(datosContacto?.correo) &&
      direccionComercial &&
      [true, false].includes(termsAndCondicions)
    ) {
      setHasDisabledButton(false);
    } else {
      setHasDisabledButton(true);
    }
  }, [datosContacto, termsAndCondicions, direccionComercial]);

  return (
    <>
      <PageTitle
        id="miPerfil"
        title="Editar mis datos"
        size={24}
        icon={<LoadIcon src={`${iconPath}user.svg`} color="black" />}
      />
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        {isServiceError ? (
          <Error className="bmt-3 bmt-md-4" />
        ) : (
          <Forms
          //modal
          handleShowModalFactors={handleShowModal}
          showModalFactors={showModal}
          datosAnteriores={datosAnteriores}
          dataDireccionAnterior={dataDireccionAnterior}
          setFlagReintentar={setFlagReintentar}
          flagReintentar={flagReintentar}
          alertState={alertState}
          setAlertState={setAlertState}
            {...{
              loadingDatosContacto,
              dataPersona,
              setDataPersona,
              datosContacto,
              setDatosContacto,
              loadingPersona,
              setIsReloadDContacto,
              evaluatePhone,

              setHasDisabledButton,
              hasDisabledButton,

              loadingDirecciones,
              direccionPersona,
              direccionComercial,
              setDireccionComercial,
              setIsReloadDComercial,

              dataRegiones,
              isloadingDRegiones,

              dataComunas,
              isLoadingDComunas,

              loadingHabilitadoCell,
              habilitadoCell,
              loadingHabilitadoEmail,
              habilitadoEmail,

              // Flags mensaje exito UI
              userUpdate,
              setUserUpdate,

              // Factores
              loadingFactores,
              factoresSeguridad,
              setReloadFactores,
              factorError,
              setFactorError,
              desafio,
              setDesafio,
              sendDesafio,
              setSendDesafio,
              resultDesafio,
              setResultDesafio,

              // Counter
              counterActive,
              setCounterActive,
              timeOut,
              setTimeOut,

              handleCleanStates,
              hasSuccess,
              setHasSuccess,
              termsAndCondicions,
              setTermsAndCondicions,
              // Ref
              datosContactoRef,
              direcComercialRef,
            }}
          />
        )}
      </div>
    </>
  );
};

Index.displayName = 'MiPerfil_Container';
export default Index;
