/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/listaNegra';

// ------- SAGA ------ //

function fetchListaNegraSuccess(state, action) {
  // state = { success: true, error: false, enListaNegra: action.payload !== 0 };
  state.success = true;
  state.error = false;
  state.enListaNegra = action.payload !== 0;
}

function fetchListaNegraError(state) {
  state.success = false;
  state.error = true;
  state.enListaNegra = null
}

const fetchListaNegra = createAction('fetchListaNegra');
const fetchListaNegraConsultar = createAction('fetchListaNegraConsultar');

const listaNegraReducer = createSlice({
  name: 'listaNegra',
  initialState,
  reducers: {
    fetchListaNegraSuccess,
    fetchListaNegraError,
  },
});

export const listaNegraActions = {
  ...listaNegraReducer.actions,
  fetchListaNegra,
  fetchListaNegraConsultar
};

export default listaNegraReducer.reducer;
