import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/creditoConsumoSimulador';

function fetchConfiguracionSuccess(state, action) {
  state.errLogsConfiguracion = null;
  state.configuracion = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchConfiguracionError(state, action) {
  const { error } = action.payload || {};

  state.errLogsConfiguracion = error;
  state.configuracion = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchResultadoSuccess(state, action) {
  state.errLogResultado = null;
  state.resultado = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchResultadoError(state, action) {
  const { error } = action.payload;

  state.errLogResultado = error;
  state.resultado = {
    success: false,
    error: true,
    errorCode: error?.code || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchSolicitarSuccess(state, action) {
  state.errLogSolicitar = null;
  state.solicitar = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchSolicitarError(state, action) {
  const { error } = action.payload;

  state.errLogSolicitar = error;
  state.solicitar = {
    success: false,
    error: true,
    errorCode: error?.code,
    data: null,
    date: new Date().toISOString(),
  };
}

const fetchConfiguracion = createAction('fetchConfiguracion');
const fetchResultado = createAction('fetchResultado');
const fetchSolicitar = createAction('fetchSolicitar');

const creditoConsumoSimuladorReducer = createSlice({
  name: 'creditoConsumoSimulador',
  initialState,
  reducers: {
    fetchConfiguracionSuccess,
    fetchConfiguracionError,
    fetchResultadoSuccess,
    fetchResultadoError,
    fetchSolicitarSuccess,
    fetchSolicitarError,
  },
});

export const creditoConsumoSimuladorActions = {
  ...creditoConsumoSimuladorReducer.actions,
  fetchConfiguracion,
  fetchResultado,
  fetchSolicitar,
};

export default creditoConsumoSimuladorReducer.reducer;
