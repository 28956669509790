import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';

import InputLabel from '../../../atoms/InputLabel';
import radioOff from '../../../../assets/svg/rb1.svg';
import radioOn from '../../../../assets/svg/rb2.svg';
import { Paragraph } from '../../../templates/CuentaVista/Solicita/Flow/Firma/styles';
import { RegisteredEmail, AnotherEmailText } from './styles';

const RADIO = { nuevo: 'nuevo', registrado: 'registrado' };

function ContratacionEmail(props) {
  const {
    persona, 
    email, 
    emailError, 
    emailErrorMessage, 
    handleChangeEmail, 
    texEnvioEmailAviso,
    texEnvioEmail,
    textPersonEmail,
    textOtroEmail,
    textEmailNoTiene,
    documentHeader
  } = props;


  const [selected, setSelected] = useState(RADIO.registrado);
  useEffect(() => {
    if (selected === RADIO.nuevo) {
      handleChangeEmail({ target: { value: '', name: 'email' } });
    } else {
      handleChangeEmail({ target: { value: persona.correo, name: 'email' } });
    }
  }, [selected]);


  let input = <Skeleton />;

  if (!persona.correo) {
    input = (
      <form>
        <InputLabel
          name="email"
          size={48}
          borderColor="#646569"
          title="Email:"
          labelClass="text-bold bmb-3"
          value={email}
          placeholder="usuario@gmail.com"
          onChange={handleChangeEmail}
          errorMessage={emailError && email.length >= 1 ? emailErrorMessage : null}
          errorClass="red"
        />
      </form>
    );
  }

  if (persona.correo) {
    input = (
      <div>
        <div className="row mb-2">
          <div className="col-md-2 col-xs-2">
            <img
              src={selected === RADIO.registrado ? radioOn : radioOff}
              alt="radio_btn_registrado"
              onClick={() => setSelected(RADIO.registrado)}
            />
          </div>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <RegisteredEmail className="col-md-10 col-xs-10">
            <div className="d-flex flex-md-row flex-column registered-email-container">
              <div>Email registrado:</div>
              <p className='email'>
                {textPersonEmail}
              </p>
            </div>
          </RegisteredEmail>

        </div>
        <div className="row mb-2">
          <div className="col-md-2 col-xs-2">
            <img
              src={selected === RADIO.nuevo ? radioOn : radioOff}
              alt="radio_btn_nuevo"
              onClick={() => setSelected(RADIO.nuevo)}
            />
          </div>
          <div className="col-md-10 bmt-md-1 bmt-xs-0 col-xs-10">
            <AnotherEmailText className="bmb-3">
              <label>{textOtroEmail}</label>
            </AnotherEmailText>
            {selected === RADIO.nuevo && (
              <form>
                <InputLabel
                  inputTextSize={14}
                  className="mb-2"
                  name="email"
                  size={48}
                  borderColor="#646569"
                  labelClass="text-bold bmb-3"
                  value={email}
                  placeholder="usuario@gmail.com"
                  onChange={handleChangeEmail}
                  errorMessage={emailError && email.length >= 1 ? emailErrorMessage : null}
                  errorClass="red"
                />
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <CardBox className="bmb-3 bmb-md-4">
      <CardBox.Header>
        <Typography tag="p" fontSize={22} fontWeight="bold">{ documentHeader }</Typography>
      </CardBox.Header>
      <CardBox.Body>
        {!!persona && (
          <>
            {persona.correo && selected === RADIO.nuevo && (
              <Alert
                className="bp-3 bmb-3"
                bgType="info"
                id="alert-change-email-contratacion-monedero"
                show
              >
                {texEnvioEmailAviso}
              </Alert>
            )}
            {!persona.correo && (
              <Alert
                className="bp-3 bmb-3"
                bgType="info"
                id="alert-change-email-contratacion-monedero"
                show
              >
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                {textEmailNoTiene}
              </Alert>
            )}
          </>
        )}
        <>
          <Paragraph className="mb-16">
            {texEnvioEmail}
          </Paragraph>
          <div className="row">
            <div className="col-xs-12 col-md-5">{input}</div>
          </div>
        </>
      </CardBox.Body>
    </CardBox>
  );
}

ContratacionEmail.propTypes = {
  persona: PropTypes.oneOfType([PropTypes.object]).isRequired,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.bool.isRequired,
  emailErrorMessage: PropTypes.string.isRequired,
  handleChangeEmail: PropTypes.func.isRequired,
  texEnvioEmailAviso: PropTypes.string.isRequired,
  texEnvioEmail: PropTypes.string.isRequired,
  textPersonEmail: PropTypes.string.isRequired,
  textOtroEmail: PropTypes.string.isRequired,
  textEmailNoTiene: PropTypes.string.isRequired,
  documentHeader: PropTypes.string.isRequired,
};

export default ContratacionEmail;
