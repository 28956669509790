import React, { useEffect } from 'react';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import ButtonWithSpinner from '@coopeuch-components/web/atoms/Button';

import { colors } from '../../../../../../assets/styles';
import { registerGA } from '../../../../../../utils/metrics';

import { Text } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Index = ({ action, currentStep }) => {
  // Para evitar sobrepoblar el componentDidUpdate del container donde se usa este componente,
  // se optó por asumir que sólo se usará en dicho container, de modo que al momento de mostrarse,
  // siempre se envíe la marca de "operación rechazada", ya que es el único caso de uso para el cual
  // aplica.
  useEffect(() => {
    let paso = 'paso1';
    if (currentStep === 2) {
      paso = 'paso2';
    }
    if (currentStep === 3) {
      paso = 'comprobante';
    }
    registerGA({
      event: 'eve',
      'eve-acc': `/tef/solicitar/cuentaVista/${paso}`,
      'eve-cat': 'Error',
      'eve-lab': 'Operacion rechazada',
    });
  }, []);

  return (
    <CardBox className="bmt-3 bmt-md-4">
      <CardBox.Body>
        <div className="row">
          <div className="col-xs-12 middle-xs">
            <img className="bmx-auto" src={`${staticPath}login_error.png`} alt="login-error" />
          </div>
          <div className="col-xs-12 col-md-4 col-md-offset-4">
            <Text textSize={24} size={32} weight="bold">
              ¡Lo sentimos!
            </Text>
            <Text textSize={16} size={24} weight="normal">
              No se ha podido realizar
            </Text>
            <Text textSize={16} size={24} weight="normal">
              la Solicitud de tu Cuenta Vista
            </Text>
          </div>
          <div className="col-xs-12 col-md-4 col-md-offset-4 text-center bmt-3">
            <ButtonWithSpinner
              onClick={action}
              bgColor={colors.red}
              color={colors.white}
              id={`solicitudcv-try-again`}
            >
              Volver a intentar
            </ButtonWithSpinner>
          </div>
        </div>
      </CardBox.Body>
    </CardBox>
  );
};

export default Index;
