/* eslint-disable no-param-reassign */
import {createSlice, createAction} from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/norma484';

// ------- SAGA ------ //

function fetchValidarNorma484Success(state, action) {
  state.validar.valido = action.payload.valido;
  state.validar.error = false;
}

function fetchValidarNorma484Error(state) {
  state.validar.valido = false;
  state.validar.error = true;
}

const fetchValidarNorma484 = createAction('fetchValidarNorma484');


// ------------- connection ------------- //

const norma484Reducer = createSlice({
  name: 'norma484',
  initialState,
  reducers: {
    fetchValidarNorma484Success,
    fetchValidarNorma484Error,
  },
});

export const norma484Actions = {
  ...norma484Reducer.actions,
  fetchValidarNorma484
};

export default norma484Reducer.reducer;
