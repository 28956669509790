import React, { PureComponent } from 'react';
import { produce } from 'immer';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import {
  errPassCoopeuchFactor,
  errTarjetaCoordenadasFactor,
} from '../../../../infrastructure/services/factoresSeguridad/errors';
import { transferenciasActions } from '../../../../application/actions/transferencias';
import { factoresSeguridadActions } from '../../../../application/actions/factoresSeguridad';
import { productActions } from '../../../../application/actions/products';

import {
  isAlphanumeric,
  isAlphanumericWithSpaces,
  isAlphabetWithSpacesScript,
  formatRut,
  rutUnformat
} from '../../../utils/formatters';
import { isEmailValid, isRunValid } from '../../../utils/validators';
import { findByKey } from '../../../utils/functions';
import { registerGA } from '../../../utils/metrics';

import { datosDestinatarios as texts } from '../texts';

import DatosDestinatarioTemplate from '../../../components/templates/Destinatarios/DatosDestinatario';
import DescargaPassCoopeuch from '../../../components/templates/Destinatarios/DescargaPassCoopeuch';
import logger from '../../../utils/logger';
import { useClipboardData } from '../../../components/templates/CopyClipboard';
import ModalClipboard from '../../../components/templates/ModalClipboard';
import FTUClipboard from '../../../components/templates/FtuClipboard';

class DatosDestinatario extends PureComponent {
  intervalCoords = null;
  intervalPass = null;
  state = {
    isReqPassCoopeuchVisible: false,
    intervalId: null,
    destinatariosClipboard: null,
    clipboardPaste: null,
    numeroCuentaRut: {
      editando: true,
      mostrarAlert: false,
    },
    destinatario: {
      rut: '',
      nombre: '',
      banco: '',
      tipoCuenta: '',
      numeroCuenta: '',
      email: '',
      alias: 'false',
      clave: '',
    },
    errors: {
      email: false,
      rut: false,
    },
    factors: {
      selected: null,
      showPassword: false,
      passCoopeuch: {
        timer: 120,
        stepFactor: 0,
        message: texts.authorizeWithPassCoopeuchDescription,
        buttonText: texts.buttonAuthorizeWithPassCoopeuch,
        activeTimer: false,
      },
      tarjetaCoordenadas: {
        showInput: true,
        timer: 120,
        stepFactor: 0,
        message: texts.authorizeWithTCOO,
        buttonText: texts.buttonAuthorize,
        desafios: {
          1: '',
          2: '',
          3: '',
        },
        activeTimer: false,
      },
    },
  };

  onChangeHandle = (event) => {
    const { destinatario } = this.state;
    const { dataset, value } = event.target;
    let valueFormatted = value;

    if (dataset.id === 'rut') {
      valueFormatted = formatRut(value);
    }

    if (dataset.id === 'numeroCuenta') {
      valueFormatted = isAlphanumeric(value);
    }

    if (dataset.id === 'nombre') {
      valueFormatted = isAlphabetWithSpacesScript(value);
    }

    const editandoNroCta = (dataset.id === 'numeroCuenta') ? true : false;

    this.setState(
      produce((draft) => {
        draft.destinatario[dataset.id] = valueFormatted;
        draft.numeroCuentaRut.editando = editandoNroCta;
        if (dataset.id === 'email') {
          draft.errors.email = value === '' ? false : !isEmailValid(value);
        }
        if (dataset.id === 'rut') {
          draft.errors.rut = value.length <= 7 ? true : !isRunValid(value);
        }
      })
    );
  };

  onSwitchDifferentFactor = () => {
    const { factoresDisponibles } = this.props;
    const { factors } = this.state;

    this.setState(
      produce((draft) => {
        draft.factors.selected = factoresDisponibles?.find(
          (factor) => factor.codigo !== factors.selected
        )?.codigo;
      })
    );
  };

  onTogglePassword = () => {
    this.setState(
      produce((draft) => {
        draft.factors.showPassword = !this.state.factors.showPassword;
      })
    );
  };

  onHandleDesafioFactor = () => {
    const { factors, destinatario } = this.state;
    const {
      desafioTarjetaCoordenadas,
      desafioFactorPassCoopeuch,
      history,
      cuentasDelUsuario,
      origenSeleccionado,
      currentUserRut,
      trackId,
      validarDestinatario,
      desafiosTarjetaCoordenadas,
      bancos,
      tipoCuentas,
    } = this.props;

    const { state } = history.location;
    const { selected, numeroTarjeta } = factors || {};

    const isTransferFlow = Boolean(state?.isTransferirFondos);
    const monederWasSelected = origenSeleccionado?.codigoProducto === 'VI06';
    const hasOnlyMonedero =
      cuentasDelUsuario.length ===
        cuentasDelUsuario?.filter((cuenta) => cuenta.codigoProducto === 'VI06')?.length &&
      cuentasDelUsuario.length >= 1;

    const wasMonedero = (isTransferFlow && monederWasSelected) || hasOnlyMonedero;

    const rut = wasMonedero ? currentUserRut : destinatario.rut;
    const datosDestinatario = {
      ...destinatario,
      codigoProducto: wasMonedero ? 'VI06' : 'VI01',
      tipoCuenta: Number(destinatario.tipoCuenta),
      rut,
      modulo: isTransferFlow ? 'transferencia' : 'destinatarios',
    };

    validarDestinatario({ destinatario: datosDestinatario, history });

    if (selected === 2.1) {
      if (Object.values(desafiosTarjetaCoordenadas)?.join('')?.length === 0) {
        desafioTarjetaCoordenadas({ track: trackId + '-99' });
      }

      if (!Boolean(this.intervalCoords)) {
        this.onEnableCountdownHandle('intervalCoords');
      }
    } else {
      const bancoSeleccionado = bancos.find(
        (banco) => Number(banco.codigo) === Number(destinatario.banco)
      );

      const tipoCuentasSeleccionado = tipoCuentas.find(
        (cuenta) => Number(cuenta.codigo) === Number(destinatario.tipoCuenta)
      );

      desafioFactorPassCoopeuch({
        params: {
          data: {
            rut: wasMonedero ? currentUserRut : destinatario.rut,
            alias: destinatario.alias,
            nombre: destinatario.nombre,
            banco: bancoSeleccionado?.descripcion,
            tipoCuenta: tipoCuentasSeleccionado?.descripcion,
            numeroCuenta: destinatario.numeroCuenta,
            email: destinatario.email,
          },
        },
        track: `${trackId}-98`,
      });

      if (!Boolean(this.intervalPass)) {
        this.onEnableCountdownHandle('intervalPass');
      }
    }

    const keyFactor = selected === 2.2 ? 'passCoopeuch' : 'tarjetaCoordenadas';

    this.setState(
      produce((draft) => {
        draft.factors[keyFactor].stepFactor = 1;
        draft.factors[keyFactor].activeTimer = true;
        draft.factors[keyFactor].message =
          keyFactor === 'passCoopeuch' ? texts.enterPass : `${texts.enterCoords}: ${numeroTarjeta}`;
      })
    );
  };

  onChangeInputFactorCoordsHandle = (event) => {
    const { dataset, value } = event.target;
    const { factors } = this.state;

    const desafios = {
      ...factors.tarjetaCoordenadas.desafios,
      [dataset.id]: value,
    };

    const coordsLenght = Object.values(desafios).join('').length;

    this.setState(
      produce((draft) => {
        draft.factors.tarjetaCoordenadas.stepFactor =
          coordsLenght >= 0 && coordsLenght <= 5 ? 1 : 2;
        draft.factors.tarjetaCoordenadas.desafios = desafios;
      })
    );
  };

  onHandleValidarFactor = () => {
    const {
      validarTarjetaCoordenadas,
      validarDesafioCoopeuchPass,
      history,
      cuentasDelUsuario,
      origenSeleccionado,
      currentUserRut,
      trackId,
      bancos,
      tipoCuentas,
    } = this.props;
    const { destinatario, factors } = this.state;

    const { tarjetaCoordenadas, selected } = factors;
    const { desafios } = tarjetaCoordenadas || {};
    const { state } = history.location;

    const isTransferFlow = Boolean(state?.isTransferirFondos);
    const monederWasSelected = origenSeleccionado?.codigoProducto === 'VI06';
    const hasOnlyMonedero =
      cuentasDelUsuario.length ===
        cuentasDelUsuario?.filter((cuenta) => cuenta.codigoProducto === 'VI06')?.length &&
      cuentasDelUsuario.length >= 1;

    const wasMonedero = (isTransferFlow && monederWasSelected) || hasOnlyMonedero;

    if (selected === 2.1) {
      validarTarjetaCoordenadas({
        track: `${trackId}-99`,
        desafio: {
          valorDesafio1: desafios[1],
          valorDesafio2: desafios[2],
          valorDesafio3: desafios[3],
        },
      });
    } else {
      const tiempoReglaCoopeuchPass = Number(
        this.props.reglas.find((regla) => regla.codigo === 'CPASS_TIME')?.valor
      );

      const bancoSeleccionado = bancos.find(
        (banco) => Number(banco.codigo) === Number(destinatario.banco)
      );

      const tipoCuentasSeleccionado = tipoCuentas.find(
        (cuenta) => Number(cuenta.codigo) === Number(destinatario.tipoCuenta)
      );

      validarDesafioCoopeuchPass({
        params: {
          data: {
            rut: wasMonedero ? currentUserRut : destinatario.rut,
            alias: destinatario.alias,
            nombre: destinatario.nombre,
            banco: bancoSeleccionado?.descripcion,
            tipoCuenta: tipoCuentasSeleccionado?.descripcion,
            numeroCuenta: destinatario.numeroCuenta,
            email: destinatario.email,
          },
        },
        track: `${trackId}-98`,
        tiempo: tiempoReglaCoopeuchPass,
      });
    }
  };

  onEnableCountdownHandle = (intervalKey) => {
    const { selected } = this.state.factors;
    const keyFactor = selected === 2.2 ? 'passCoopeuch' : 'tarjetaCoordenadas';

    this[intervalKey] = setInterval(() => {
      this.setState(
        produce((draft) => {
          draft.factors[keyFactor].timer = draft.factors[keyFactor].timer - 1;
        })
      );
    }, 1000);
  };

  onResetFactorHandle = (event) => {
    const { dataset } = event?.target || {};
    const { validarUsingFactorFailed, fetchFactoresSeguridad, reglas } = this.props;
    const { factors } = this.state;

    if (dataset?.id === 'factor-error') {
      fetchFactoresSeguridad();
    } else {
      const keyInterval = factors.selected === 2.2 ? 'intervalPass' : 'intervalCoords';
      const keyFactor = factors.selected === 2.2 ? 'passCoopeuch' : 'tarjetaCoordenadas';

      clearInterval(this[keyInterval]);
      this[keyInterval] = null;
      validarUsingFactorFailed({
        type: `${keyFactor}Errors`,
        error: {},
      });

      const { valor: time } =
        findByKey(reglas, 'codigo', factors.selected === 2.2 ? 'CPASS_TIME' : 'TCOO_TIME') || {};

      this.setState(
        produce((draft) => {
          draft.factors[keyFactor].timer = Number(time || 120);
          draft.factors[keyFactor].stepFactor = 0;
          draft.factors[keyFactor].activeTimer = false;

          if (keyFactor === 'passCoopeuch') {
            draft.factors[keyFactor].message = texts.authorizeWithPassCoopeuchDescription;
            draft.factors[keyFactor].buttonText = texts.buttonAuthorizeWithPassCoopeuch;
          } else {
            draft.factors[keyFactor].message = texts.authorizeWithTCOO;
            draft.factors[keyFactor].buttonText = texts.buttonAuthorize;
            draft.factors[keyFactor].desafios = {
              1: '',
              2: '',
              3: '',
            };
          }
        })
      );
    }
  };

  onSubmitHandle = () => {
    const { destinatario, factors } = this.state;
    const { selected } = factors || {};

    const {
      history,
      cuentasDelUsuario,
      currentUserRut,
      editarDestinatario,
      createDestinatario,
      validarDestinatario,
      changeDestinoSeleccionado,
      tipoCuentas,
      bancos,
      origenSeleccionado,
      trackId,
    } = this.props;

    const { state } = history.location;

    const isTransferFlow = Boolean(state?.isTransferirFondos);
    const monederWasSelected = origenSeleccionado?.codigoProducto === 'VI06';
    const hasOnlyMonedero =
      cuentasDelUsuario.length ===
        cuentasDelUsuario?.filter((cuenta) => cuenta.codigoProducto === 'VI06')?.length &&
      cuentasDelUsuario.length >= 1;

    const wasMonedero = (isTransferFlow && monederWasSelected) || hasOnlyMonedero;

    const rut = wasMonedero ? currentUserRut : destinatario.rut;
    const datosDestinatario = {
      ...destinatario,
      codigoProducto: wasMonedero ? 'VI06' : 'VI01',
      tipoCuenta: Number(destinatario.tipoCuenta),
      rut,
      modulo: isTransferFlow ? 'transferencia' : 'destinatarios',
    };

    if (isRunValid(rut)) {
      if (isTransferFlow) {
        const bancoSeleccionado = bancos.find(
          (banco) => Number(banco.codigo) === Number(destinatario.banco)
        );

        const tipoCuentasSeleccionado = tipoCuentas.find(
          (cuenta) => Number(cuenta.codigo) === Number(destinatario.tipoCuenta)
        );

        const destinoSeleccionado = {
          alias: destinatario.alias,
          banco: bancoSeleccionado?.descripcion,
          email: destinatario.email,
          id: null,
          nombreCompleto: destinatario.nombre,
          numeroCuenta: destinatario.numeroCuenta,
          rut: rut,
          tipoCuenta: tipoCuentasSeleccionado?.descripcion,
          codigoBanco: bancoSeleccionado?.codigo,
          codigoTipoCuenta: tipoCuentasSeleccionado?.codigo,
          esFlujoNuevoDestinatario: true,
        };

        changeDestinoSeleccionado(destinoSeleccionado);
        validarDestinatario({ destinatario: datosDestinatario, history, isTransferFlow: true });
      } else if (Boolean(state?.destinatario)) {
        editarDestinatario({
          ...datosDestinatario,
          history,
        });
      } else {
        createDestinatario({
          destinatario: datosDestinatario,
          history,
          wasVI06: wasMonedero,
          track: `${trackId}-${selected === 2.2 ? '98' : '99'}`,
        });
      }
    } else {
      this.setState(
        produce((draft) => {
          draft.errors.rut = !isRunValid(rut);
        })
      );
    }
  };

  onGoBackHandle = () => {
    this.props.history.goBack();
  };
  procesarClipboard = () => {
    const clipboardData = useClipboardData();
    if (clipboardData) {
      this.setState({ destinatariosClipboard: clipboardData });
      console.log('Datos del portapapeles:', clipboardData);
    } else {
      console.log('No se encontraron datos en el portapapeles.');
    }
  };
  onPasteDataClipboard = (clipboardPaste) => {
    const { bancos, tipoCuentas } = this.props;
    if (clipboardPaste) {
      const { name, rut, cuenta, numCuenta, banco, email, isCuentaRut } = clipboardPaste;
      const quitarTildes = (texto) => {
        return texto?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      };
      const buscarBancoPorNombre = (nombreBanco) => {
        let bancoEncontrado;
        let bancoSinTildes = quitarTildes(nombreBanco);
        if (isCuentaRut) {
          bancoEncontrado = bancos.find((b) => b.descripcion.toLowerCase().includes('estado'));
        } else {
          // bancoEncontrado = bancos.find(b => b.descripcion.toLowerCase().includes(quitarTildes(nombreBanco).toLowerCase()));
          bancoEncontrado = bancos.find((b) =>
            quitarTildes(b.descripcion.toLowerCase()).includes(bancoSinTildes.toLowerCase())
          );
        }
        return bancoEncontrado;
      };

      const buscarTipoCuentaPorNombre = (tipoCuenta) => {
        const tipoCuentaEnMinusculas = tipoCuenta.toLowerCase();
        let tipoDeCuentaEncontrado;
        if (tipoCuentaEnMinusculas.includes('rut')) {
          tipoDeCuentaEncontrado = tipoCuentas.find((b) =>
            b.descripcion.toLowerCase().includes('vista')
          );
        } else {
          tipoDeCuentaEncontrado = tipoCuentas.find((b) =>
            b.descripcion.toLowerCase().includes(tipoCuenta.toLowerCase())
          );
        }
        return tipoDeCuentaEncontrado;
      };
      const codigo_banco = buscarBancoPorNombre(banco);
      const codigo_cuenta = buscarTipoCuentaPorNombre(cuenta);

      let numero_cuenta;
      if (isCuentaRut) {
        // Elimina puntos (.) y guiones (-) de numCuenta
        const numeroCuentaLimpio = numCuenta.replace(/[.-]/g, '');
        // Verifica la longitud de numeroCuentaLimpio
        if (numeroCuentaLimpio.length === 8) {
          numero_cuenta = numeroCuentaLimpio;
        } else if (numeroCuentaLimpio.length === 9) {
          // Si tiene 9 caracteres, quita el Ãºltimo
          numero_cuenta = numeroCuentaLimpio.slice(0, -1);
        } else {
          console.error('El número de cuenta no cumple con las condiciones requeridas.');
        }
      } else {
        // Si isCuentaRut es falso, simplemente asigna numCuenta a numero_cuenta
        numero_cuenta = numCuenta;
      }

      this.setState(
        produce((draft) => {
          draft.destinatario.rut = rut;
          draft.destinatario.nombre = name;
          draft.destinatario.banco = codigo_banco?.codigo;
          draft.destinatario.tipoCuenta = codigo_cuenta?.codigo;
          draft.destinatario.numeroCuenta = numero_cuenta;
          draft.destinatario.email = email;

          draft.errors.rut = rut.length <= 7 ? true : !isRunValid(rut);
          draft.errors.email = email === '' ? false : !isEmailValid(email);
        })
      );
    }
  };
  handleKeyDown = (event) => {
    // Verifica si la combinación de teclas Ctrl+C (o Cmd+C en macOS) fue presionada
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      // Realiza alguna acción, como mostrar un mensaje
      this.procesarClipboard();
    }
  };
  onFetchIsFTU = () => {
    const { isFTU } = this.props;
    isFTU({ ftuID: 'CPDESAWEB' });
  };
  onHandleCloseFtu = () => {
    const { ftuClose } = this.props;
    ftuClose({ ftuId: 'CPDESAWEB' });
    // registerGA({
    //   event: 'eve',
    //   'eve-acc' : '/tef/transferencias/terceros/inicio',
    //   'eve-cat' : 'Click',
    //   'eve-lab' : `${action}`
    // });
  };
  componentDidMount() {
    this.procesarClipboard();
    window.addEventListener('keydown', this.handleKeyDown);
    this.onFetchIsFTU();
    const {
      fetchBancos,
      fetchTipoCuentas,
      fetchCuentasVista,
      fetchReglasFactoresSeguridad,
      clearInformacionFactores,
      resetError,
      history,
      destinoSeleccionado,
      factoresDisponibles,
      changeDestinoSeleccionado,
      fetchFactoresSeguridad,
      clearValidarDesafioTarjeta,
      validarUsingFactorFailed,
      cuentasDelUsuario,
      isFTUOpen,
    } = this.props;
    if (isFTUOpen?.data === true) {
      registerGA({
        event: 'eve',
        'eve-acc': 'ef/transferencias/fondos/destinatario-nuevo',
        'eve-cat': 'Mensaje',
        'eve-lab': `Modal Copiar y pegar datos de destintario`,
      });
    }
    const { state } = history.location;

    if (Boolean(state?.destinatario)) {
      this.setState(
        produce((draft) => {
          draft.destinatario = state.destinatario;
        })
      );
    }

    if (!state?.isTransferirFondos && factoresDisponibles?.length === 0) {
      this.setState(
        produce((draft) => {
          draft.isReqPassCoopeuchVisible = true;
        })
      );
    }

    if (
      destinoSeleccionado.esFlujoNuevoDestinatario &&
      Boolean(state?.isTransferirFondos) &&
      Boolean(state?.isBacking)
    ) {
      const destinatarioSeleccionado = {
        rut: destinoSeleccionado.rut,
        nombre: destinoSeleccionado.nombreCompleto,
        banco: String(destinoSeleccionado.codigoBanco),
        tipoCuenta: String(destinoSeleccionado.codigoTipoCuenta),
        numeroCuenta: destinoSeleccionado.numeroCuenta,
        email: destinoSeleccionado.email,
        alias: destinoSeleccionado.alias,
        id: null,
      };

      this.setState(
        produce((draft) => {
          draft.destinatario = destinatarioSeleccionado;
        })
      );
    } else {
      changeDestinoSeleccionado({});
    }

    if (cuentasDelUsuario?.length <= 0) {
      fetchCuentasVista({
        codigoProducto: ['VI01', 'VI06'],
        estadoCuenta: ['A', 'T'],
      });
    }

    fetchBancos();
    fetchTipoCuentas();
    fetchFactoresSeguridad();
    clearInformacionFactores();
    resetError({ errorCrearOEditarDestinatario: '', destinatarioMessageError: {} });
    clearValidarDesafioTarjeta({ validacionFactorDeSeguridad: false });
    validarUsingFactorFailed({ error: {}, type: 'tarjetaCoordenadasErrors' });
    validarUsingFactorFailed({ error: {}, type: 'passCoopeuchErrors' });
    fetchReglasFactoresSeguridad();
    registerGA({ event: 'vpv-privado', page: '/tef/transferencias/destinatario-nuevo' });
    registerGA({ event: 'vpv-privado', page: '/tef/transferencias/fondos/destinatario-nuevo' });
  }

  componentDidUpdate(prevProps) {
    const {
      factoresDisponibles,
      validarUsingFactorFailed,
      tarjetaCoordenadasErrors,
      passCoopeuchErrors,
      isValidateWithFactor,
      reqError,
      getPassCoopeuchFactor,
      reglas,
    } = this.props;
    const { factors, destinatario, numeroCuentaRut } = this.state;

    if (destinatario?.rut != '' && destinatario?.banco === 672 && destinatario?.tipoCuenta === 40) {
      this.setState(
        produce((draft) => {
          draft.numeroCuentaRut.mostrarAlert = true;
          if (!numeroCuentaRut.editando){
            draft.destinatario.numeroCuenta = rutUnformat(destinatario?.rut).slice(0, -1);
          }
        })
      );
    } else {
      this.setState(
        produce((draft) => {
          draft.numeroCuentaRut.mostrarAlert = false;
        })
      );
    }

    if (prevProps.factoresDisponibles !== factoresDisponibles) {
      this.setState(
        produce((draft) => {
          draft.factors.selected = factoresDisponibles
            ?.slice()
            ?.sort((a, b) => b.codigo - a.codigo)?.[0]?.codigo;
          draft.factors.numeroTarjeta = factoresDisponibles.find(
            (factor) => factor.codigo === 2.1
          )?.payload;
        })
      );
    }

    if (prevProps.reglas !== reglas) {
      const { valor: passTime } = findByKey(reglas, 'codigo', 'CPASS_TIME') || {};
      const { valor: coordsTime } = findByKey(reglas, 'codigo', 'TCOO_TIME') || {};

      this.setState(
        produce((draft) => {
          draft.factors.passCoopeuch.timer = Number(passTime || 120);
          draft.factors.tarjetaCoordenadas.timer = Number(coordsTime || 120);
        })
      );
    }

    if (factors.tarjetaCoordenadas.timer === 0) {
      clearInterval(this.intervalCoords);

      if (Object.keys(tarjetaCoordenadasErrors).length === 0) {
        validarUsingFactorFailed({
          error: errTarjetaCoordenadasFactor[1],
          type: 'tarjetaCoordenadasErrors',
        });
      }
    }

    if (factors.passCoopeuch.timer === 0) {
      clearInterval(this.intervalPass);

      if (Object.keys(passCoopeuchErrors).length === 0) {
        validarUsingFactorFailed({
          error: errPassCoopeuchFactor[14],
          type: 'passCoopeuchErrors',
        });
      }
    }

    if (prevProps.isValidateWithFactor !== isValidateWithFactor) {
      if (isValidateWithFactor) {
        this.onSubmitHandle();
      }

      if (!isValidateWithFactor && reqError) {
        this.onResetFactorHandle();
      }
    }

    if (prevProps.getPassCoopeuchFactor !== getPassCoopeuchFactor) {
      if (getPassCoopeuchFactor) {
        this.onHandleValidarFactor();
      }
    }

    const {
      isLoadingFactoresSeguridad,
      isLoadingTipoCuentas,
      isLoadingReglasFactoresSeguridad,
      isLoadingBancos,
      persona,
      errorReglasFactoresSeguridad,
      errorFactoresMessageLog,
      errorTipoCuentas,
      errorBancos,
    } = this.props;

    if (
      prevProps.isLoadingFactoresSeguridad !== isLoadingFactoresSeguridad ||
      prevProps.isLoadingTipoCuentas !== isLoadingTipoCuentas ||
      prevProps.isLoadingReglasFactoresSeguridad !== isLoadingReglasFactoresSeguridad ||
      prevProps.isLoadingBancos !== isLoadingBancos
    ) {
      if (
        !isLoadingFactoresSeguridad &&
        !isLoadingTipoCuentas &&
        !isLoadingReglasFactoresSeguridad &&
        !isLoadingBancos
      ) {
        const baseRut = String(
          ((persona || {}).rut || localStorage.getItem('user-rut') || '' || '').replace('-', '')
        );
        logger(
          errorTipoCuentas ||
            errorReglasFactoresSeguridad ||
            errorBancos ||
            errorFactoresMessageLog,
          {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: 'TEF-AGREGAR-NUEVO-DESTINATARIO',
            informacion_adicional: {},
          }
        );
      }
    }
  }

  render() {
    const { numeroCuentaRut, destinatario, errors, factors, isReqPassCoopeuchVisible } = this.state;
    const {
      bancos,
      tipoCuentas,
      isLoadingDatosDestinatario,
      isLoadingFactoresSeguridad,
      isLoadingDesafioOrValidate,
      history,
      reqError,
      cuentasDelUsuario,
      currentUserRut,
      origenSeleccionado,
      factoresDisponibles,
      errorFactoresMessage,
      desafiosTarjetaCoordenadas,
      destinatarioMessageError,
      tarjetaCoordenadasErrors,
      passCoopeuchErrors,
      persona,
      isFTUOpen,
    } = this.props;
    const baseRut = String(
      ((persona || {}).rut || localStorage.getItem('user-rut') || '' || '').replace('-', '')
    );

    const { state } = history.location;

    if (isReqPassCoopeuchVisible) {
      return <DescargaPassCoopeuch />;
    }
    return (
      <>
        <DatosDestinatarioTemplate
          onChangeHandle={this.onChangeHandle}
          onSubmitHandle={this.onSubmitHandle}
          onGoBackHandle={this.onGoBackHandle}
          onTogglePassword={this.onTogglePassword}
          onSwitchDifferentFactor={this.onSwitchDifferentFactor}
          onHandleDesafioFactor={this.onHandleDesafioFactor}
          onHandleValidarFactor={this.onHandleValidarFactor}
          onChangeInputFactorCoordsHandle={this.onChangeInputFactorCoordsHandle}
          onResetFactorHandle={this.onResetFactorHandle}
          texts={texts}
          numeroCuentaRut={numeroCuentaRut}
          destinatario={destinatario}
          errors={errors}
          factors={factors}
          tipoCuentas={tipoCuentas}
          bancos={bancos}
          reqError={reqError}
          destinatarioMessageError={destinatarioMessageError}
          currentUserRut={currentUserRut}
          isEditDestinatario={Boolean(state?.destinatario)}
          isTransferirFondos={Boolean(state?.isTransferirFondos)}
          origenSeleccionado={origenSeleccionado}
          isLoading={isLoadingDatosDestinatario}
          isLoadingFactoresSeguridad={isLoadingFactoresSeguridad}
          isLoadingDesafioOrValidate={isLoadingDesafioOrValidate}
          factoresDisponibles={factoresDisponibles}
          errorFactoresMessage={errorFactoresMessage}
          tarjetaCoordenadasErrors={tarjetaCoordenadasErrors}
          passCoopeuchErrors={passCoopeuchErrors}
          cuentasDelUsuario={cuentasDelUsuario}
          desafiosTarjetaCoordenadas={desafiosTarjetaCoordenadas}
        />
        {isFTUOpen?.data === true ? (
          <FTUClipboard isFTUOpen={isFTUOpen} onHandleCloseFtu={this.onHandleCloseFtu} />
        ) : (
          <ModalClipboard
            desClipboard={this.state.destinatariosClipboard}
            onPasteDataClipboard={this.onPasteDataClipboard}
            baseRut={baseRut}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  bancos: entities.transferencias.bancos,
  tipoCuentas: entities.transferencias.tipoCuentas,
  isLoadingDatosDestinatario: ui.loaders.isLoadingDatosDestinatario,
  reqError: entities.transferencias.errorCrearOEditarDestinatario,
  destinatarioMessageError: entities.transferencias.destinatarioMessageError,
  cuentasDelUsuario: entities.products.cuentaVista.balance,
  currentUserRut: entities.user.rut,
  destinoSeleccionado: entities.transferencias.destinoSeleccionado,
  origenSeleccionado: entities.transferencias.origenSeleccionado,
  factoresDisponibles: entities.factoresSeguridad.factoresSeguridad,
  errorFactoresMessage: entities.factoresSeguridad.errorFactoresMessage,
  tarjetaCoordenadasErrors: entities.factoresSeguridad.tarjetaCoordenadasErrors,
  passCoopeuchErrors: entities.factoresSeguridad.passCoopeuchErrors,
  isLoadingFactoresSeguridad: ui.loaders.isLoadingFactoresSeguridad,
  isLoadingDesafioOrValidate: ui.loaders.isLoadingDesafioYValidarFactorSeguridad,
  desafiosTarjetaCoordenadas: {
    desafio1: entities.factoresSeguridad.desafio1,
    desafio2: entities.factoresSeguridad.desafio2,
    desafio3: entities.factoresSeguridad.desafio3,
  },
  isValidateWithFactor: entities.factoresSeguridad.validacionFactorDeSeguridad,
  getPassCoopeuchFactor: entities.factoresSeguridad.obtencionFactorCoopeuchPass,
  trackId: entities.transferencias.trackTransferencia,
  reglas: entities.factoresSeguridad.reglasFactoresSeguridad,
  errorReglasFactoresSeguridad: entities.factoresSeguridad.errorReglasFactoresSeguridad,
  errorFactoresMessageLog: entities.factoresSeguridad.errorFactoresMessageLog,
  errorTipoCuentas: entities.transferencias.errorTipoCuentas,
  errorBancos: entities.transferencias.errorBancos,
  isLoadingReglasFactoresSeguridad: ui.loaders.isLoadingReglasFactoresSeguridad,
  isLoadingTipoCuentas: ui.loaders.isLoadingTipoCuentas,
  isLoadingBancos: ui.loaders.isLoadingBancos,
  persona: entities.persona,
  isFTUOpen: entities.transferencias.isFTUOpen,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBancos() {
    dispatch(transferenciasActions.fetchBancos());
  },
  fetchTipoCuentas() {
    dispatch(transferenciasActions.fetchTipoCuentas());
  },
  createDestinatario(props) {
    dispatch(transferenciasActions.createDestinatario(props));
  },
  editarDestinatario(props) {
    dispatch(transferenciasActions.editarDestinatario(props));
  },
  validarDestinatario(props) {
    dispatch(transferenciasActions.validarDestinatario(props));
  },
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
  resetError(props) {
    dispatch(transferenciasActions.fetchEditarDestinatarioFailed(props));
  },
  changeDestinoSeleccionado(params) {
    dispatch(transferenciasActions.changeDestinoSeleccionado(params));
  },
  isFTU(params) {
    dispatch(transferenciasActions.ftuFuncionalidad(params));
  },
  ftuClose(params) {
    dispatch(transferenciasActions.ftuClose(params));
  },
  fetchFactoresSeguridad() {
    dispatch(factoresSeguridadActions.fetchFactoresSeguridad());
  },
  desafioFactorPassCoopeuch(props) {
    dispatch(factoresSeguridadActions.fetchDesafioFactorPassCoopeuch(props));
  },
  desafioTarjetaCoordenadas(props) {
    dispatch(factoresSeguridadActions.fetchDesafioTarjetaCoordenadas(props));
  },
  validarTarjetaCoordenadas(props) {
    dispatch(factoresSeguridadActions.validarDesafioTarjetaCoordenadas(props));
  },
  validarDesafioCoopeuchPass(props) {
    dispatch(factoresSeguridadActions.validarDesafioCoopeuchPass(props));
  },
  validarUsingFactorFailed(props) {
    dispatch(factoresSeguridadActions.validarUsingFactorFailed(props));
  },
  clearValidarDesafioTarjeta(props) {
    dispatch(factoresSeguridadActions.validarDesafioTarjetaCoordenadasResponse(props));
  },
  fetchReglasFactoresSeguridad() {
    dispatch(factoresSeguridadActions.fetchReglasFactoresSeguridad());
  },
  clearInformacionFactores() {
    dispatch(factoresSeguridadActions.clearInformacionFactores());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DatosDestinatario));
