import React from 'react';

import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';

import SourceAccount from '../../../../organism/CreditoHipotecario/Payment/SourceAccount';
import PaymentOverview from '../../../../organism/CreditoHipotecario/Payment/PaymentOverview';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import FirmaElectronica from '../../../../organism/FirmaElectronica';

import Wrapper from '../../core/wrapper';

function PaymentTemplate({
  selectedAccount,
  availableAccountsList,
  onChangeAccount,
  enoughMoney,
  feeNumber,
  expirationDate,
  feeUFAmount,
  feeAmount,
  password,
  handlePassword,
  passwordInputValidation,
  handleValidationError,
  validationError,
  showPassword,
  togglePassword,
  cardTitle,
  cardDescription,
  loadingPay,
  onGoBack,
  onPay,
  nextActionButtonDisabled,
}) {
  return (
    <Wrapper>
      <SourceAccount
        id="source-account"
        selectedAccount={selectedAccount}
        availableAccountsList={availableAccountsList}
        onChangeAccount={onChangeAccount}
        enoughMoney={enoughMoney}
      />
      <PaymentOverview
        id="payment-overview"
        feeNumber={feeNumber}
        expirationDate={expirationDate}
        feeUFAmount={feeUFAmount}
        feeAmount={feeAmount}
      />
      {enoughMoney && (
        <div className="row bmt-3 bmt-md-4">
          <div className="col-xs-12">
            <CardBox>
              <FirmaElectronica
                id="signature"
                password={password}
                handlePassword={handlePassword}
                passwordInputValidation={passwordInputValidation}
                handleValidationError={handleValidationError}
                validationError={validationError}
                showPassword={showPassword}
                cardTitle={cardTitle}
                cardDescription={cardDescription}
                onTogglePassword={togglePassword}
                loading={loadingPay}
              />
            </CardBox>
          </div>
        </div>
      )}
      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          <StepsActions
            id="step-actions"
            className="bmt-3 bmb-3"
            backText={enoughMoney ? 'No por ahora' : 'Volver'}
            backAction={onGoBack}
            nextText={enoughMoney ? 'Paga tu Dividendo' : 'Aceptar'}
            nextAction={onPay}
            disableNextAction={nextActionButtonDisabled}
            loading={false}
          />
        </div>
      </div>
    </Wrapper>
  );
}

PaymentTemplate.propTypes = {
  selectedAccount: PropTypes.shape({
    value: PropTypes.string,
    balance: PropTypes.shape({ label: PropTypes.string }),
  }).isRequired,
  availableAccountsList: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  onChangeAccount: PropTypes.func.isRequired,
  enoughMoney: PropTypes.bool,
  feeNumber: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  feeUFAmount: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }).isRequired,
  feeAmount: PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }).isRequired,
  password: PropTypes.string.isRequired,
  handlePassword: PropTypes.func.isRequired,
  passwordInputValidation: PropTypes.string,
  handleValidationError: PropTypes.func.isRequired,
  validationError: PropTypes.shape({
    title: PropTypes.string,
    message: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
  showPassword: PropTypes.bool,
  togglePassword: PropTypes.func.isRequired,
  cardTitle: PropTypes.string,
  cardDescription: PropTypes.string,
  loadingPay: PropTypes.bool,
  onGoBack: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
  nextActionButtonDisabled: PropTypes.bool,
};

PaymentTemplate.defaultProps = {
  enoughMoney: false,
  passwordInputValidation: '',
  showPassword: false,
  cardTitle: 'Firma electrónica',
  cardDescription: 'Valida la transacción con tu clave de internet Coopeuch.',
  loadingPay: false,
  nextActionButtonDisabled: false,
  availableAccountsList: [],
  validationError: null,
};

export default PaymentTemplate;
