const initState = {
  productosDisponibles: {
    cuentas: null,
    tarjetas: null,
    error: false,
    success: false,
  },
  guardarDatosPersonales: { success: false, error: false },
  guardarSucursal: { success: false, error: false },
  firmarSolicitud: { success: false, error: false },
  getDetalleContratacion: { success: false, error: false },
  obtenerComprobante: { success: false, error: false },
  obtenerContrato: { success: false, error: false },
  enviarComprobante: { success: false, error: false },
  trackValidarSolicitud: '',
  validarSolicitudError: false,
  validarSolicitudErrorCode: '',
  bannerContratacion: { success: false, error: false, data: null, errorCode: '', date: null },
  goToFlujoContratacionCV: false,
  availablePassCoopeuch: { success: false, error: null, data:null },
  solicitarDesafioPassCoopeuch: { success: null, error: null, data:null },
  IsValidatePassCoopeuch: { success: null, error: null, data:null },
  desafio: { success: null, error: {code:null}, data:null },
  validaDesafio: { success: null, error: {code:null}, data:null },
};

export default initState;
