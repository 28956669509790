import styled from 'styled-components';


const Wrapper = styled.div`
  .title-line-height {
    line-height: 30px !important;
  }

  .ahorrors-digitalizar-alert {
    padding: 12px 16px !important;
    margin-top: 20px;
  }

  #productos-a-portar-confirmar-next-action {
    width: 180px;
  }

  .tooltip-content-wrapper {
    padding: 16px;
    display: grid;
    justify-items: center;
    gap: 16px;
  }

  #tw {
    display: inline-block;
    .tooltip-fatca {
      left: -8px !important;
      bottom: 27px !important;
      width: 336.5px;
    }
  }

  .monto-radio {
    height: 24px;
    width: 24px;
    margin-top: 12px;
    margin-right: 0px;
    margin-left: 0px;

    &::before {
      height: 14px;
      width: 14px;
    }

    &::after {
      height: 24px;
      width: 24px;
    }
  }

  #datos-cuenta-next-action {
    width: 180px !important;
    font-size: 14px;
  }

  #datos-cuenta-prev-action {
    height: auto;
  }

  @media screen and (max-width: 768px) {
    padding-top: 16px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;

    #tw {
      display: inline-block;
      .tooltip-fatca {
        left: -135px !important;
        bottom: 27px !important;
        width: 295px;
      }

      .tooltip-fatca::after {
        left: 135px !important;
      }
    }

    .ahorrors-digitalizar-alert {
      padding: 12px 8px !important;
      margin-top: 16px;
    }

    #productos-a-portar-confirmar-next-action {
      width: 296px;
    }

    #datos-cuenta-next-action,
    .step-action-button-wrapper {
      width: 100% !important;
      display: flex;
      align-items: center;
    }
  }
`;

export const CuentaSelecionadaWrapper = styled.div`
  background-color: #22315c;
  padding: 32px 24px;
  border-radius: 5px;

  .web-display {
    display: grid;
    grid-template-columns: minmax(180px, 1fr) 160px 150px 1fr;
    gap: 60px;

    .end-row {
      display: grid;
      justify-content: flex-end;
    }
  }

  @media screen and (max-width: 1200px) {
    .web-display {
      grid-template-columns: minmax(180px, 1fr) 160px 150px 1fr;
      gap: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
`;

export const RadioWrapper = styled.div`
> div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

input {
  min-width: 24px;
}

@media (max-width: 598px) {
label {
  line-height: 1.6;
}
  }

`

export default Wrapper;
