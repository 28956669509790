import React from 'react';

import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';

import Wrapper, { Banner, CustomList } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const BannerContent = ({ bannerClick, fontSize1 = 24, fontSize2 = 24 }) => (
  <div className="row h-100 banner-actions">
    <div className="col-xs-8 col-sm-7 bpl-2rem bpl-sm-5 bpy-3">
      <Typography className="bmb-2" color='white' tag="p" fontSize={fontSize1} lineHeight={34}>
        Simula tu Crédito de Consumo
      </Typography>
      <Typography
        className="bmb-md-4 bmb-3"
        color='white'
        tag="p"
        fontSize={fontSize2}
        fontWeight="bold"
        lineHeight={34}
      >
        Obtén beneficios exclusivos.
      </Typography>
      <Button
        bgColor='#E81E2B'
        color="white"
        className="action-button"
        onClick={bannerClick}
        id="action-button"
      >
        Simular
      </Button>
    </div>
  </div>
);

const Index = ({ bannerClick }) => {
  return (
    <Wrapper>
      <div className="row bpx-md-5 bmt-md-4">
        <div className="col-xs-12 w-100">
          <Banner
            rounded
            className="d-none d-md-block"
            src={`${staticPath}creditodeconsumo/banner-desk.jpg`}
          >
            <BannerContent {...{ bannerClick }} />
          </Banner>
          <Banner
            height={320}
            className="d-block d-md-none"
            src={`${staticPath}creditodeconsumo/banner-mobile.jpg`}
          >
            <BannerContent {...{ bannerClick, fontSize1: 20 }} />
          </Banner>
        </div>
      </div>
      <div className="row bpx-3 bpx-md-5 bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <Typography tag="p" color='#383638' fontSize={18} fontWeight='bold'>
            {' '}
            ¿Cuáles son los beneficios?
          </Typography>
        </div>
      </div>
      <div className="row bpx-3 bpx-md-5 bmt-3 bmt-md-4">
        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1">
            <CardBox.Header className="new-header-padding">
              <>
                <LoadIcon
                  src={`${iconPath}time.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />
                {' '}
                <Typography color={'#383638'} tag="p" fontSize={18}>
                  Primera cuota
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li>
                  Puedes programar el pago de tu primera cuota hasta 90 días.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header className="new-header-padding">
              <>
                <LoadIcon
                  src={`${iconPath}calendar_alt.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />
                {' '}
                <Typography color='#383638' tag="p" fontSize={18}>
                  Número de cuotas
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li>Cuotas muy convenientes con un plazo de hasta 84 meses.</li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bpx-3 bpx-md-5 bmy-3 bmy-md-4">
        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1">
            <CardBox.Header className="new-header-padding">
              <>
                <LoadIcon
                  src={`${iconPath}bill.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />
                {' '}
                <Typography color='#383638' tag="p" fontSize={18}>
                  Modalidad de Pago
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li>
                  Podrás pagar tus cuotas con descuento por planilla o con cargo a tu Cuenta Vista
                  Coopeuch.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header className="new-header-padding">
              <>
                <LoadIcon
                  src={`${iconPath}dollar_coin.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />
                {' '}
                <Typography color='#383638' tag="p" fontSize={18}>
                  Aumenta tu Remanente
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li>
                  La contratación de tu crédito de consumo en Coopeuch aumenta tu Remanente.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </Wrapper>
  );
};
Index.displayName="Template_CDC_Solicita"
export default Index;
