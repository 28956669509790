import styled from 'styled-components';

const Content = styled.div`
  height: 100%;

  color: #383638;
  @media screen and (max-width: 1023px) {
    padding-left: 12px !important;
    padding-right: 12px !important;
    .card-home-pading {
      padding-bottom: 24px;
      padding-top: 24px;
    }
  }

  @media screen and (min-width: 1024px) {
    .card-home-text {
      margin-left: 8%;
      margin-right: 8%;
    }
  }

  .parrafo-base {
    line-height: 26px;
  }
  .card-home-ico {
    display: flex;
    justify-content: center;
    padding-bottom: 24px;
  }
  .card-home-text {
    text-align: center;
  }
  small {
    display: none;
  }
  .with--help-text {
    small {
      display: inline;
    }

    .line-divider {
      border-bottom: 1px solid #e2e1e3;
    }
    .parrafo-base {
      line-height: 26px;
    }
    .back--icon {
      & svg {
        fill: #e81e2b;

        path {
          fill: #e81e2b;
        }
      }
    }

    .error--input--message {
      & div {
        margin: 0;
      }

      & svg {
        fill: #e81e2b;

        path {
          fill: #e81e2b;
        }
      }
    }

    @media screen and (max-width: 766px) {
      padding: 16px 12px !important;
      .line-divider {
        margin-top: 10px !important;
      }
    }
  }
`;

export const WarningMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
  display: flex;
  padding: 12px 24px;
  min-height: 48px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f0e5b2;
  color: #876820;
`;

export const IconWrapper = styled.div`
  margin-right: 8px;
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  display: ${({ icon }) => (icon ? 'initial' : 'none')};

  & > img,
  & > svg & > div {
    height: 100%;
    width: 100%;
  }
`;

export default Content;
