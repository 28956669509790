/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// Ui
import Loading from '@coopeuch-components/web/atoms/Loading';
import Button from '@coopeuch-components/web/atoms/Button';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Input from '../../../../components/atoms/Input';
import DashboardCard from '../../../../components/organism/DashboardCard';
import Error from '../Error';
import Countdown from '../Utils/Countdown';
import { registerGA } from '../../../../utils/metrics';
// Utils
import CountdownNumbers from '../Utils/CountdownNumbers';

// Texts
import tCoordenadas, { errores } from './text';

// Styles
import Container, { InputContainer } from './styles';
const staticImages = process.env.REACT_APP_CLOUDFRONT_STATICS;
const staticIcons = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  numeroTarjeta,
  onHandleObtenerDesafio,
  desafio,
  onHandleSetDesafio,
  sendDesafio,
  onHandleSendDesafio,

  setFactorError,
  factorError,

  loading,
  hasOtherFactor,
  HandleChangeFactor,
  setAskServicePass,
  hasDisabledButton,
  hasSuccess,
  setFsChange,
  // Counter
  counterActive,
  setCounterActive,
  timeOut,
  setTimeOut,
  passLock,
}) => {
  const { desafio1, desafio2, desafio3 } = desafio || false;

  const [firstStep, setFirstStep] = useState(true);

  const [ischange, setIschange] = useState(false);
  let refCoor1 = useRef();
  let refCoor2 = useRef();
  let refCoor3 = useRef();

const handleChangeStep = () => {
  setFsChange(true)
  setFirstStep(false)
  registerGA({
    'event': 'eve',
    'eve-acc' : '/tef/editar-datos',
    'eve-cat' : 'Mensaje',
    'eve-lab' : 'Modal - Autorizar con Tarjeta de Coordenas'
  });
  registerGA({
    'event': 'eve',
    'eve-acc' : '/tef/editar-datos',
    'eve-cat' : 'Click',
    'eve-lab' : 'Modal - Autorizar con Tarjeta de Coordenas'
  });
}
  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'cor1') {
      if (value.length === 2) {
        refCoor2.current.focus();
      }
    }

    if (id === 'cor2') {
      if (value.length === 2) {
        refCoor3.current.focus();
      }
    }

    onHandleSetDesafio(e);
  };

  useEffect(() => {
    if (
      desafio1 &&
      desafio2 &&
      desafio3 &&
      counterActive &&
      (factorError === '4' || factorError === '5')
    ) {
    }
  }, [desafio1, desafio2, desafio3, counterActive, factorError]);
  const registrarGA = (method) => {
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/editar-datos',
      'eve-cat' : 'Click',
      'eve-lab' : `Modal - ${method}`
    });
  };
  return (
    <Container>
          {' '}
          {firstStep ?
          <>
            <div className="row mt-5">
              <div className="col-md-6">
                <h1 className="mb-3 text-left display-2" style={{ fontSize: 20 }}>¿No tienes tu PassCoopeuch?</h1>
                <p className="mb-3 text-left">
                  <b>¡Usa PassCoopeuch!</b> <br />
                  Descargar PassCoopeuch, la App que te permite autorizar transacciones de forma fácil, rápida y segura. <br />
                  Encuéntrala en:
                </p>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-4">
                      <a 
                        target="_blank" 
                        href="https://apps.apple.com/cl/app/passcoopeuch/id1524671704" 
                        rel="noopener noreferrer"
                        onClick={() => registrarGA('AppStore')}
                      >
                        <img
                          src={`${staticImages}qr_code_app_store.png`}
                          alt="transferencia_passcoopeuch"
                          style={{
                            border: '3px solid black', 
                            borderRadius: 5,
                            padding: 2
                          }}
                        />
                        <div style={{height: 5}}  />
                        <img
                          src={`${staticImages}logo_app_store.svg`}
                          alt="transferencia_passcoopeuch"
                        />
                      </a>
                    </div>
                    <div className="col-md-4">
                      <a 
                      target="_blank" 
                      href="https://play.google.com/store/apps/details?id=cl.coopeuch.pass&hl=es_CL&gl=US" 
                      rel="noopener noreferrer"
                      onClick={() => registrarGA('GooglePlay')}
                      >
                      <img
                          src={`${staticImages}qr_code_google_play.png`}
                          alt="transferencia_passcoopeuch"
                          style={{
                            border: '3px solid black', 
                            borderRadius: 5,
                            padding: 2
                          }}
                        />
                        <div style={{height: 5}}  />
                        <img
                          src={`${staticImages}logo_google_play.svg`}
                          alt="transferencia_passcoopeuch"
                        />
                      </a>
                    </div>
                    <div className="col-md-4">
                      <a 
                      target="_blank" 
                      href="https://appgallery.huawei.com/app/C103730561" 
                      rel="noopener noreferrer"
                      onClick={() => registrarGA('AppGallery')}
                      >
                        <img
                          src={`${staticImages}qr_code_app_gallery.svg`}
                          alt="transferencia_passcoopeuch"
                          style={{
                            border: '3px solid black', 
                            borderRadius: 5,
                            padding: 2
                          }}
                        />
                        <div style={{height: 5}}  />
                        <img
                          src={`${staticImages}logo_app_gallery.svg`}
                          alt="transferencia_passcoopeuch"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <img src={`${staticImages}thumbnail_mobile1.png`} alt="transferencia_passcoopeuch" />
              </div>
            </div>


        </>
        :
          <div className="row center-xs column margin-bottom">
            {loading ? (
              <Loading />
            ) : !!factorError === false && loading === false ? (
              <Countdown
                className="mt-24"
                ilustration="tarjeta_coordenadas"
                withCountdownAnimation={counterActive}
                timer={120}
              />
            ) : (
              <Error title={errores[factorError].title} text={errores[factorError].descrip} />
            )}

            {loading ? (
              ''
            ) : (
              <Typography
                tag="p"
                fontSize={18}
                align="center"
                // className="center text mt-20 mb-30"
                className={`center text${!!factorError === false ? ' mt-20 mb-30' : ''}`}
              >
                {!!factorError === true
                  ? ''
                  : desafio1 && desafio2 && desafio3
                    ? `${tCoordenadas.descriptDesafio} ${numeroTarjeta}`
                    : tCoordenadas.descripValidar}
              </Typography>
            )}

            {!!factorError === false && desafio1 && desafio2 && desafio3 && loading === false && (
              <InputContainer className="center mb-16">
                <div className="row">
                  <div className="col-md-4 col-xs-4 pl-12 pr-12">
                    <div className="factor-coordenadas-label-container">
                      <label htmlFor="cor1">
                        {desafio1}

                        <Input
                          id="cor1"
                          dataSet="1"
                          name="desafio1"
                          borderColor={
                            factorError ? 'red' : desafio1?.length === 2 ? '#19a1d5 ' : '#646569'
                          }
                          maxLength={2}
                          type="password"
                          className="factor-coordenadas-input"
                          value={sendDesafio.desafio1}
                          onChange={handleChange}
                          ref={refCoor1}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-4 pl-12 pr-12">
                    <div className="factor-coordenadas-label-container">
                      <label htmlFor="cor2">
                        {desafio2}
                        <Input
                          id="cor2"
                          dataSet="2"
                          maxLength={2}
                          type="password"
                          className="factor-coordenadas-input"
                          borderColor={
                            factorError ? 'red' : desafio2?.length === 2 ? '#19a1d5 ' : '#646569'
                          }
                          value={sendDesafio.desafio2}
                          name="desafio2"
                          onChange={handleChange}
                          ref={refCoor2}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-4 col-xs-4 pl-12 pr-12">
                    <div className="factor-coordenadas-label-container">
                      <label htmlFor="cor3">
                        {desafio3}
                        <Input
                          id="cor3"
                          dataSet="3"
                          name="desafio3"
                          maxLength={2}
                          type="password"
                          className="factor-coordenadas-input"
                          value={sendDesafio.desafio3}
                          borderColor={
                            factorError ? 'red' : desafio3?.length === 2 ? '#19a1d5 ' : '#646569'
                          }
                          onChange={handleChange}
                          ref={refCoor3}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </InputContainer>
            )}

            {loading ? (
              ''
            ) : (
              <Button
                // disabled={isDisabled}
                //disabled={hasDisabledButton === true || hasSuccess === true}
                rounded={5}
                bgColor={
                  (!!factorError === true && counterActive === false) || timeOut
                    ? '#e81d2b'
                    : desafio1 && desafio2 && desafio3 && counterActive
                      ? '#007DB7'
                      : '#e81d2b'
                }
                color="white"
                onClick={
                  desafio1 && desafio2 && desafio3 && counterActive && factorError === '4'
                    ? () => HandleChangeFactor()
                    : desafio1 && desafio2 && desafio3 && counterActive && factorError === null
                      ? () => onHandleSendDesafio()
                      : () => onHandleObtenerDesafio(2.1)
                }
                dataset={{ id: 2.1 }}
                shadow
                height={48}
                className="factor-button-style center"
              // disabled={!enableButton}
              >
                {(!!factorError === true && counterActive === false) || timeOut ? (
                  tCoordenadas.buttonError
                ) : desafio1 && desafio2 && desafio3 && counterActive ? (
                  <CountdownNumbers
                    time={120}
                    {...{
                      counterActive,
                      setCounterActive,
                      setTimeOut,
                      setFactorError,
                      setAskServicePass,
                    }}
                  />
                ) : (
                  tCoordenadas.buttonValidar
                )}
              </Button>
            )}
          </div>
          }
        <div className="line" />

        {hasOtherFactor === true && passLock === false && (
          <div className="row center-xs column mt-18">
            <Button
              asTextLink
              bgColor="red"
              color="red"
              fontSize={14}
              className="button under-line"
              onClick={() => HandleChangeFactor('passCoopeuch')}
            >
              Quiero autorizar con PassCoopeuch
            </Button>
          </div>
        )}
      <div className="line" />
        {firstStep && (
          <div className="row center-xs column mt-18">
            <Button
              asTextLink
              bgColor="red"
              color="red"
              fontSize={14}
              className="button under-line"
              // onClick={() => HandleChangeFactor('tarjetaCoordenadas')}
              onClick={() => handleChangeStep()}
            >
              Quiero autorizar con Tarjeta de Coordenadas
            </Button>
          </div>
        )}
    </Container>
  );
};

Index.propTypes = {
  hasDisabledButton: PropTypes.bool.isRequired,
  // isButtonDisabled: PropTypes.bool,
  // onClickButton: PropTypes.func,
  // message: PropTypes.string,
  // buttonText: PropTypes.string,
  // withCountdownAnimation: PropTypes.bool,
  // duration: PropTypes.number,
  // timer: PropTypes.number,
  // stepFactor: PropTypes.number,
  // activeTimer: PropTypes.bool,
};

Index.displayName = 'TarjetaDeCoordenadas_Container';
export default Index;
