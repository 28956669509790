import React, { Component } from 'react';
import { connect } from 'react-redux';
import { produce } from 'immer';
import { withRouter } from 'react-router-dom';

import { transferenciasActions } from '../../../../application/actions/transferencias';

import ListarDestinatariosTemplate from '../../../components/templates/Destinatarios/ListarDestinatarios';

import { capitalize } from '../../../utils/functions';
import { isStringsNormalizedEquals, isEmailValid, isRunValid } from '../../../utils/validators';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';
import { useClipboardData } from '../../../components/templates/CopyClipboard'
import ModalClipboard from '../../../components/templates/ModalClipboard';

class ListarDestinatarios extends Component {
  state = {
    destinatariosClipboard: null,
    masElementos: true,
    vistaDestinatarios: [],
    destinatariosPorCarga: 20,
    elementosCargados: 0,
    tiempoRecarga: 1500,
    listaDestinatarios: [],
    numeroDestinatarioSeleccionado: '',
    showAlert: false,
    showErrorModal: false,
    destinatarioParaBorrar: {},
    borrarDestinatariosSuccess: false,
    suggestions: [],
    query: '',
    messageDestinatarioCreado: false,
    messageDestinatarioEditado: false,
  };
  componentDidMount() {
    const { fetchDestinatarios, history, factoresSeguridad } = this.props;
    const { state } = history.location;
    const factores = factoresSeguridad.factoresSeguridad
    window.scrollTo(0, 0);
    this.setState(
      produce((draft) => {
        draft.messageDestinatarioCreado = state?.reqSuccess;
        draft.messageDestinatarioEditado = state?.wasEdition;
        draft.destinatarioId = state?.destinatarioId;
      })
    );
      if(state?.reqSuccess){
        let factor;
        if(factores && factores[0]?.codigo === 2.2){
          factor = `PassCoopeuch`;
        }else if(factores && factores[0]?.codigo === 2.1){
          factor = 'TCOO';
        }
        registerGA({ 
          'event': 'eve',
          'eve-acc': '/tef/transferencias/destinatarios',
          'eve-cat' : 'Mensaje',
          'eve-lab': 'Nuevo destinatario agregado con exito',
          'valoracion' : `${factor}`,
        });
      }
    fetchDestinatarios({ params: { modulo: 'destinatarios' } });
  }

  componentDidUpdate(prevProps) {
    const { destinatariosPorCarga } = this.state;
    const {
      destinatarios,
      isLoaderDeleteDestinatarios,
      fetchDestinatarios,
      errorBorrarDestinatarios,
      history,
      isLoaderDestinatarios,
      persona,
      errorDestinatariosLog,
    } = this.props;
    const { state } = history.location || {};

    if (prevProps.destinatarios !== destinatarios) {
      registerGA({ event: 'vpv-privado', page: '/tef/transferencias/destinatarios' });
      let lista = this.onFormatDestinatarios(destinatarios);

      if (Boolean(state?.destinatarioId)) {
        const findEditedOrCreated = lista?.findIndex(
          ({ value }) => String(value) === String(state?.destinatarioId)
        );
        const spliceDestinatario = lista?.splice(findEditedOrCreated, 1)?.[0];

        lista.unshift(spliceDestinatario);
      }

      this.setState(
        produce((draft) => {
          draft.listaDestinatarios = lista;
        })
      );

      if (lista.length > destinatariosPorCarga) {
        this.setState(
          produce((draft) => {
            draft.vistaDestinatarios = lista.slice(0, destinatariosPorCarga);
            draft.elementosCargados = draft.vistaDestinatarios.length;
          })
        );
      } else {
        this.setState(
          produce((draft) => {
            draft.vistaDestinatarios = lista.slice(0, lista.length);
            draft.elementosCargados = draft.vistaDestinatarios.length;
            draft.masElementos = false;
          })
        );
      }
    }

    if (prevProps.isLoaderDeleteDestinatarios !== isLoaderDeleteDestinatarios) {
      if (!isLoaderDeleteDestinatarios) {
        if (errorBorrarDestinatarios) {
          this.setState(
            produce((draft) => {
              draft.showAlert = false;
              draft.showErrorModal = true;
            })
          );
        } else {
          fetchDestinatarios({ params: { modulo: 'destinatarios' } });
          this.setState(
            produce((draft) => {
              draft.showAlert = false;
              draft.borrarDestinatariosSuccess = true;
            })
          );
        }
      }
    }

    if (prevProps.isLoaderDestinatarios !== isLoaderDestinatarios) {
      if (!isLoaderDestinatarios) {
        const baseRut = String((((persona || {}).rut || localStorage.getItem('user-rut') || '') || '').replace('-', ''));
        logger(errorDestinatariosLog, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'TEF-MIS DESTINATARIOS',
          informacion_adicional: {},
        });
      }
    }
  }

  onFormatDestinatarios = (destinatarios = []) => {
    return destinatarios.map((destinatario) => ({
      nombreCompleto: capitalize(destinatario.nombreCompleto),
      cuenta: capitalize(`${destinatario.tipoCuenta}: Nº ${destinatario.numeroCuenta}`),
      banco: capitalize(destinatario.banco),
      value: destinatario.id,
    }));
  };

  sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
  };


  onPasteDataClipboard = (clipboardPaste) => {
    const { bancos, tipoCuentas } = this.props;
    if (clipboardPaste) {
      const {
        name,
        rut,
        cuenta,
        numCuenta,
        banco,
        email,
        isCuentaRut
      } = clipboardPaste;
      const quitarTildes = (texto) => {
        return texto.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      }
      const buscarBancoPorNombre = (nombreBanco) => {
        let bancoEncontrado;
        let nombreBancoSinTildes = quitarTildes(nombreBanco);
        if(isCuentaRut){
          bancoEncontrado = bancos.find(b => b.descripcion.toLowerCase().includes('estado'));
        }else{
          bancoEncontrado = bancos.find(b => b.descripcion.toLowerCase().includes(nombreBancoSinTildes.toLowerCase()));
        }
        return bancoEncontrado;
      };
      const buscarTipoCuentaPorNombre = (tipoCuenta) => {
        const tipoCuentaEnMinusculas = tipoCuenta.toLowerCase();
        let tipoDeCuentaEncontrado;
        if (tipoCuentaEnMinusculas.includes('rut')) {
          tipoDeCuentaEncontrado = tipoCuentas.find(b => b.descripcion.toLowerCase().includes('vista'));
        }else{
          tipoDeCuentaEncontrado = tipoCuentas.find(b => b.descripcion.toLowerCase().includes(tipoCuenta.toLowerCase()));
        }
        return tipoDeCuentaEncontrado;
      };
      const codigo_banco = buscarBancoPorNombre(banco);
      const codigo_cuenta = buscarTipoCuentaPorNombre(cuenta);

      let numero_cuenta;
      if (isCuentaRut) {
        // Elimina puntos (.) y guiones (-) de numCuenta
        const numeroCuentaLimpio = numCuenta.replace(/[.-]/g, '');
        // Verifica la longitud de numeroCuentaLimpio
        if (numeroCuentaLimpio.length === 8) {
          numero_cuenta = numeroCuentaLimpio;
        } else if (numeroCuentaLimpio.length === 9) {
          // Si tiene 9 caracteres, quita el Ãºltimo
          numero_cuenta = numeroCuentaLimpio.slice(0, -1);
        } else {
          console.error('El número de cuenta no cumple con las condiciones requeridas.');
        }
      } else {
        // Si isCuentaRut es falso, simplemente asigna numCuenta a numero_cuenta
        numero_cuenta = numCuenta;
      }
  
      this.setState(produce((draft) => {
        draft.destinatario.rut = rut;
        draft.destinatario.nombre = name;
        draft.destinatario.banco = codigo_banco?.codigo;
        draft.destinatario.tipoCuenta = codigo_cuenta?.codigo;
        draft.destinatario.numeroCuenta = numero_cuenta;
        draft.destinatario.email = email;
  
        draft.errors.rut = rut.length <= 7 ? true : !isRunValid(rut);
        draft.errors.email = email === '' ? false : !isEmailValid(email);
      }));
    }
  }

  procesarClipboard = () => {
    const clipboardData = useClipboardData();
    if (clipboardData) {
      this.setState(
          {destinatariosClipboard: clipboardData}
      );
      console.log('Datos del portapapeles:', clipboardData);
    } else {
      console.log('No se encontraron datos en el portapapeles.');
    }
  }
  cargarElementos = () => {
    const {
      elementosCargados,
      destinatariosPorCarga,
      vistaDestinatarios,
      tiempoRecarga,
      listaDestinatarios,
    } = this.state;

    this.sleep(tiempoRecarga).then(() => {
      if (listaDestinatarios.length > elementosCargados) {
        this.setState(
          produce((draft) => {
            draft.vistaDestinatarios = vistaDestinatarios.concat(
              listaDestinatarios.slice(elementosCargados, elementosCargados + destinatariosPorCarga)
            );
            draft.elementosCargados = draft.vistaDestinatarios.length;
            if (listaDestinatarios.length === draft.elementosCargados) {
              draft.masElementos = false;
            }
          })
        );
      }
    });
  };

  onGoToDatosDestinatario = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/transferencias/destinatarios',
      'eve-cat': 'Click',
      'eve-lab': 'Agregar Nuevo Destinatario',
    });
    this.procesarClipboard();
    this.props.history.push('/ecd/transferir/datos-destinatario');
  };

  handleClickTableCRUD = (type, value) => {
    const { destinatarios, history } = this.props;

    const destinatario = destinatarios.find((item) => item.id === value) || {};

    if (type === 'delete') {
      this.setState(
        produce((draft) => {
          draft.destinatarioParaBorrar = destinatario;
          draft.showAlert = true;
        })
      );
    } else {
      history.push({
        pathname: '/ecd/transferir/datos-destinatario',
        state: {
          destinatario,
        },
      });
    }
  };

  handleErrorDestinatariosButton = () => {
    const { fetchDestinatarios } = this.props;
    fetchDestinatarios({ params: { modulo: 'destinatarios' } });
  };

  handleCloseModal = () => {
    this.setState(
      produce((draft) => {
        draft.showAlert = false;
        draft.showErrorModal = false;
      })
    );
  };

  handleClickDelete = () => {
    const { deleteDestinatario } = this.props;
    const { destinatarioParaBorrar } = this.state;
    deleteDestinatario({ params: destinatarioParaBorrar.id });

    this.setState(
      produce((draft) => {
        draft.messageDestinatarioCreado = false;
        draft.messageDestinatarioEditado = false;
        draft.destinatarioId = null;
        draft.masElementos = true;
      })
    );
  };

  onFilterDestintariosHandle = (input) => {
    const { destinatarios } = this.props;

    const query = (typeof input === 'string' ? input : this.state.query)?.toLowerCase();

    const filtereds = destinatarios
      .map((destinatario) =>
        isStringsNormalizedEquals(destinatario.nombreCompleto, query) ||
        isStringsNormalizedEquals(destinatario.alias, query)
          ? {
              ...destinatario,
              isByName: isStringsNormalizedEquals(destinatario.nombreCompleto, query),
            }
          : null
      )
      ?.filter((item) => item);

    return filtereds;
  };

  onTypeQueryHandle = (event) => {
    event.persist();

    const { value } = event.target;

    let allSuggestions = [];

    if (value && value.length >= 1) {
      allSuggestions = this.onFilterDestintariosHandle(value);
    }

    this.setState(
      produce((draft) => {
        draft.query = value;
        draft.suggestions = allSuggestions.map((item) => ({
          text: item.isByName ? item.nombreCompleto : item.alias,
          id: `destinatario-id-${item.id}`,
        }));
      })
    );
  };

  onRemoveQuerySearch = () => {
    const { destinatarios } = this.props;

    this.setState(
      produce((draft) => {
        draft.suggestions = [];
        draft.query = '';
        draft.vistaDestinatarios = this.onFormatDestinatarios(destinatarios);
      })
    );
  };

  onSubmitSearchHandle = (query) => {
    const destinatariosFiltered = this.onFilterDestintariosHandle(query);

    this.setState(
      produce((draft) => {
        draft.suggestions = [];
        draft.vistaDestinatarios = this.onFormatDestinatarios(destinatariosFiltered);
        draft.messageDestinatarioCreado = false;
        draft.messageDestinatarioEditado = false;
        draft.destinatarioId = null;
      })
    );
  };

  onClickItemSearchBar = (event) => {
    const { dataset } = event.target;

    this.onSubmitSearchHandle(dataset.text);

    this.setState(
      produce((draft) => {
        draft.query = dataset.text;
        draft.suggestions = [];
        draft.messageDestinatarioCreado = false;
        draft.messageDestinatarioEditado = false;
        draft.destinatarioId = null;
      })
    );
  };

  render() {
    const { isLoaderDestinatarios, errorDestinatarios, isLoaderDeleteDestinatarios, history, persona } =
      this.props;

    const {
      vistaDestinatarios,
      masElementos,
      numeroDestinatarioSeleccionado,
      showAlert,
      showErrorModal,
      destinatarioParaBorrar,
      borrarDestinatariosSuccess,
      suggestions,
      query,
      messageDestinatarioCreado,
      messageDestinatarioEditado,
      destinatarioId,
    } = this.state;
    const baseRut = String((((persona || {}).rut || localStorage.getItem('user-rut') || '') || '').replace('-', ''));
    return (
      <>
        <ListarDestinatariosTemplate
          destinatarios={vistaDestinatarios}
          destinatarioId={destinatarioId}
          cargandoDestinatarios={isLoaderDestinatarios}
          masElementos={masElementos}
          numeroDestinatarioSeleccionado={numeroDestinatarioSeleccionado}
          configuracionColumnasDestinatarios={[3, 3, 3, 3]}
          configuracionHeadersDestinatarios={['nombreCompleto', 'alias', 'cuenta', 'banco']}
          errorDestinatarios={errorDestinatarios}
          showAlert={showAlert}
          showErrorModal={showErrorModal}
          destinatarioParaBorrar={destinatarioParaBorrar}
          isLoaderDeleteDestinatarios={isLoaderDeleteDestinatarios}
          borrarDestinatariosSuccess={borrarDestinatariosSuccess}
          cargarElementos={this.cargarElementos}
          reqSuccess={messageDestinatarioCreado}
          reqWasEdition={messageDestinatarioEditado}
          wasVI06={history.location.state?.wasVI06}
          handleErrorDestinatariosButton={this.handleErrorDestinatariosButton}
          onClickTableCRUD={this.handleClickTableCRUD}
          handleClickDelete={this.handleClickDelete}
          handleCloseModal={this.handleCloseModal}
          onGoToDatosDestinatario={this.onGoToDatosDestinatario}
          suggestions={suggestions}
          query={query}
          onTypeQueryHandle={this.onTypeQueryHandle}
          onRemoveHandle={this.onRemoveQuerySearch}
          onSearchHandle={this.onSubmitSearchHandle}
          onClickItemSearchBar={this.onClickItemSearchBar}
        />

        <ModalClipboard 
          desClipboard={this.state.destinatariosClipboard} 
          onPasteDataClipboard={this.onPasteDataClipboard}
          baseRut={baseRut}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  destinatarios: state.entities.transferencias.destinatarios,
  isLoaderDestinatarios: state.ui.loaders.isLoaderDestinatarios,
  errorDestinatarios: state.entities.transferencias.errorDestinatarios,
  isLoaderDeleteDestinatarios: state.ui.loaders.isLoaderDeleteDestinatarios,
  errorBorrarDestinatarios: state.entities.transferencias.errorBorrarDestinatarios,
  persona: state.entities.persona,
  factoresSeguridad: state.entities.factoresSeguridad,
  errorDestinatariosLog: state.entities.transferencias.errorDestinatariosLog,
});
const mapDispatchToProps = (dispatch) => ({
  fetchDestinatarios: (params) => dispatch(transferenciasActions.fetchDestinatarios(params)),
  deleteDestinatario: (params) => dispatch(transferenciasActions.deleteDestinatario(params)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListarDestinatarios));
