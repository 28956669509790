const initState = {
  7: {
    currentYear: { data: null, error: null },
    lastYear: { data: null, error: null },
  },
  70: {
    currentYear: { data: null, error: null },
    lastYear: { data: null, error: null },
  },
};

export default initState;
