import React from 'react';
import { useSelector } from 'react-redux';
// Utils
import Skeleton from 'react-loading-skeleton';
// Components
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { formatCurrency } from '../../../../utils/functions';

import './styles.css';
import CardError from '../../../atoms/Error/CardError';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const RemanenteTotal = ({ loading, remanenteMontoTotal }) => {
  const { descripcion, montoTotal, titulo, icono } = remanenteMontoTotal || {};
  const { contenidoError } = useSelector((state) => state.entities.remanente);
  return (
    <CardBox className="h-100 text-left" id="remanente-total-card">
      <CardBox.Body>
        {contenidoError ? (
          <CardError />
        ) : (
          <React.Fragment>
            <div className="row bmb-3">
              <div className="col-xs-1">
                {!loading && !!icono ? (
                  <LoadIcon
                    src={`${iconPath}${icono}`}
                    color={'#383638'}
                    size={22}
                    className="float-left"
                  />
                ) : (
                  <Skeleton
                    circle
                    height={30}
                    width={30}
                    className="remanente-total-avatar-skeleton"
                  />
                )}
              </div>
              <div className="col-xs-11">
                <div className="row bmb-3">
                  <div className="col-xs-12">
                    <span>{!loading ? titulo : <Skeleton width={300} />}</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <div className="remanente--currency-wrapper right-module bmb-3">
                      <p className="remanente--currency text-size-xl">
                        {!loading ? formatCurrency(montoTotal) : <Skeleton width={150} />}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {!loading ? (
                      <div dangerouslySetInnerHTML={{ __html: descripcion }} />
                    ) : (
                      <Skeleton count={2} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </CardBox.Body>
    </CardBox>
  );
};

export default RemanenteTotal;
