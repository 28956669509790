import styled from 'styled-components';

const Wrapper = styled.div`
  .titleActivacionKit {
    font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  .titleAlertKit {
    font-size: 18px;
    font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  .cardBlockBody {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .imageCenter {
      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
    }

    .descriptionKit {
      width: 100%;
      max-width: 426px;
      margin: 0 auto;
      line-height: 20px;
      padding-top: 10px;
      font-size: 14px;
    }
  }
`;

export default Wrapper;
