import styled from 'styled-components';

const TabsWrapper = styled.div`
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
  padding-left: 0;
  padding-right: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  .tab-header {
    .tabs-item {
      padding-top: 24px;
      height: 100%;
    }
    padding: 0 16px 0px 16px;
    @media screen and (min-width: 64em) {
      padding: 0 32px 0px 32px;
    }
  }
  .cardbox-tab {
    border: 0;
    box-shadow: none;
  }
`;

export const Wrapper = styled.div`
  .descrip_datos {
    line-height: 26px;
  }
`;

export default TabsWrapper;
