import React, { useEffect }  from 'react';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Table from '@coopeuch-components/web/molecules/Table';
import { registerGA } from '../../../../../../utils/metrics';

import StepsActions from '../../../../../organism/Stepper/StepsActions';

import { Paragraph, Wrapper, ActionButtons } from './styles';
import './styles.css';

function Comprobate({ fecha, cuenta, goToCuentaVista, downloadComprobante, email }) {
  const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const comprobante = `${staticPath}sello-comprobante.png`;
  const contractData = [
    {
      id: 0,
      name: 'Producto contratado',
      value: 'Monedero Digital',
    },
    {
      id: 2,
      name: 'Fecha de contratación',
      value: fecha,
    },
  ];

  useEffect(() => {
    registerGA({
      'event': 'vpv-privado','page' : '/tef/solicitar/monederoDigital/comprobante','producto' : 'VI06'
    });
  }, [])

  return (
    <Wrapper>
      <Alert
        className="alert-comprobante-contratacion-monedero bp-3 bmb-3 bmb-md-4"
        bgType="success"
        id="alert-felicitaciones-contratacion-monedero"
        show
      >
        <>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <b>¡Felicitaciones!</b> Haz contratado tu <b>Monedero Digital.</b>
        </>
      </Alert>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>
          <div className="row">
            <div className="col-xs-6">Comprobante</div>
            <div className="col-xs-6 text-right">
              <ActionButtons>
                <div className="comprobante-action bmr-3" onClick={downloadComprobante}>
                  <LoadIcon
                    className="mr-2"
                    src={`${iconPath}download_arrow.svg`}
                    color="#F42534"
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text">Descargar</div>
                </div>
              </ActionButtons>
            </div>
          </div>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12 col-md-8 bmb-md-3 comprobante-card-text">
              <div className="text-wrapper">
                <Paragraph className="mb-2" size={14}>
                  <b>Monedero Digital</b>
                </Paragraph>
                <Paragraph className="mb-2" size={24}>
                  <b>{cuenta}</b>
                </Paragraph>
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                <Paragraph size={14}>Enviaremos el comprobante y contratos a tu email <br/><b>{email}</b>.</Paragraph>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className="bm-auto bmr-md-0" src={comprobante} alt="comprobante" />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Detalle de Contratación de Monedero Digital</CardBox.Header>
        <CardBox.Body>
          <Table
            borderColor="trasparent"
            fullWidth
            alignContent="left"
            stripeColor="#F6F3F5"
            stripeReverse
          >
            <Table.Body
              data={contractData}
              renderItems={(item, TR, TD) => {
                return (
                  <TR key={`detalle-contrato-monedero-${item.id}`}>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.name}
                    </TD>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.value}
                    </TD>
                  </TR>
                );
              }}
            />
          </Table>
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmb-3 bmb-md-4"
        hideBackAction
        nextText="Ir a Cuenta Vista"
        nextAction={goToCuentaVista}
        id="contratacion-monedero-comprobante"
      />
    </Wrapper>
  );
}

export default Comprobate;
