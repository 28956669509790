/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/monedero';

// ------- SAGA ------ //

function fetchContratacionMonederoSuccess(state, action) {
  const { numeroCuenta, fechaContratacion, correo } = action.payload.data;
  state.contratacionMonedero = {
    success: true,
    errorCode: '',
    error: false,
    cuenta: numeroCuenta,
    fecha: fechaContratacion,
    email: correo,
  };
  state.comprobanteContratacion = null;
  state.errorLogContratoMonedero = '';
}

function fetchComprobanteContratacionSuccess(state, action) {
  state.comprobanteContratacion = action.payload.data;
}

function fetchContratoCanalesSuccess(state, action) {
  state.contratoCanales = action.payload.data;
}

function fetchContratoMonederoSuccess(state, action) {
  state.contratoMonedero = action.payload.data;
}

function fetchContratacionMonederoError(state, action) {
  state.contratacionMonedero = {
    success: false,
    errorCode: action.payload.error,
    error: true,
  };
  state.errorLogContratoMonedero = action.payload.errorLog;
}

const fetchContratacionMonedero = createAction('fetchContratacionMonedero');
const fetchComprobanteContratacion = createAction('fetchComprobanteContratacion');
const fetchContratoCanales = createAction('fetchContratoCanales');
const fetchContratoMonedero = createAction('fetchContratoMonedero');

// ------------- connection ------------- //

const monederoReducer = createSlice({
  name: 'monedero',
  initialState,
  reducers: {
    fetchContratacionMonederoSuccess,
    fetchContratacionMonederoError,
    fetchComprobanteContratacionSuccess,
    fetchContratoCanalesSuccess,
    fetchContratoMonederoSuccess,
  },
});

export const monederoActions = {
  ...monederoReducer.actions,
  fetchContratacionMonedero,
  fetchComprobanteContratacion,
  fetchContratoCanales,
  fetchContratoMonedero,
};

export default monederoReducer.reducer;
