import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import ButtonBack from '../../../../organism/ActivacionTarjetasKit/ButtonBack';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetasKit/texts';
import { phoneNumbers } from '../../../../../utils/functions';
// Styles
import Wrapper, { CardFooter } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardSMS = ({
  handleClickSendSMS,
  errorTimeOut,
  persona,
  backToActivacion,
  handleSwitchMethods,
}) => {
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Autorización con Clave Dinámica SMS
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="contentSMS">
            <LoadIcon src={`${iconPath}sms.svg`} className="contentSMS__image" size={162} />
            <p className="contentSMS__text">
              Te enviaremos un SMS con el código a tu celular {phoneNumbers(persona.telefono)}.
              <br />
              Si este no es tu número, por favor comunícate con nuestro Contact Center al 600 200
              1200.
            </p>

            <Button
              bgColor="#007DB7"
              color="white"
              fontSize={14}
              className="contentSMS__btn"
              onClick={handleClickSendSMS}
            >
              Enviar SMS
            </Button>
          </div>
        </CardBox.Body>
        <CardFooter>
          <button type="button" onClick={() => handleSwitchMethods('sms')}>
            Quiero autorizar con PassCoopeuch
          </button>
        </CardFooter>
      </CardBox>
      <div className="row">
        <div className="col-md-12">
          <ButtonBack
            onClick={() => backToActivacion(errorTimeOut ? activacionTexts.BUTTON_CANCEL : activacionTexts.BUTTON_BACK, 'sms')}
            link="/ecd/seguridad/activacion-tarjetas-kit"
            text={errorTimeOut ? activacionTexts.BUTTON_CANCEL : activacionTexts.BUTTON_BACK}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CardSMS;
