import styled from 'styled-components';

const Wrapper = styled.div`
  .titleKitCoopeuch {
    font-family: 'Ubuntu-Bold';
    font-size: 24px;
    @media (max-width: 450px) {
      line-height: 24px;
      font-size: 18px;
      max-width: 200px;
    }
  }
  .kitDescription {
    font-size: 16px;
    @media (max-width: 450px) {
      line-height: 20px;
      font-size: 14px;
      max-width: 200px;
      margin-top: 5px;
    }
  }
  .boxNoneKit {
    &__text {
      font-family: 'Ubuntu-Bold';
      font-size: 16px;
      @media (max-width: 768px) {
        line-height: 20px;
        font-size: 14px;
      }
    }
  }
`;

export default Wrapper;

export const SinHistorialAlert = styled.div`
  border: 1px dashed #979797;
  background-color: #f4f4f4;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

export const SinHistorialIconWrapper = styled.div`
  border: 1px dashed #979797;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 40px;
`;
