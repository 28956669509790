import React from 'react';
import { Link } from 'react-router-dom';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Styled
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ButtonBack = ({ link, text, onClick }) => {
  return (
    <Wrapper>
      <div className="cardBox__footer">
        <Link to={link} className="cardBox__footer-btnBack" onClick={onClick}>
          <LoadIcon src={`${iconPath}arrow_left.svg`} size={24} />
          {text}
        </Link>
      </div>
    </Wrapper>
  );
};

export default ButtonBack;
