import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import HomeTemplate from '../../../components/templates/DepositoAPlazo/Home';

function Home(props) {
  const {
    location,
    depositosContratados,
    depositosContratadosError,
    isFetchedDepositosContratados,
    isLoadingDepositosContratados,
  } = props;

  const shouldRedirect = useMemo(() => {
    return Boolean(
      isFetchedDepositosContratados &&
        depositosContratados?.length < 1 &&
        !depositosContratadosError
    );
  }, [isFetchedDepositosContratados, depositosContratados, depositosContratadosError]);

  if (shouldRedirect) {
    return (
      <Redirect to={{ pathname: `${location?.pathname}/simulacion`, state: { redirect: true } }} />
    );
  }

  return (
    <HomeTemplate
      depositosContratados={depositosContratados}
      isLoadingDepositosContratados={isLoadingDepositosContratados}
      error={depositosContratadosError}
    />
  );
}

Home.propTypes = {
  depositosContratados: PropTypes.array,
};
Home.defaultProps = {
  depositosContratados: [],
};

export default Home;
