/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// Ui
import Typography from '@coopeuch-components/web/atoms/Typography';
import Loading from '@coopeuch-components/web/atoms/Loading';
import Button from '@coopeuch-components/web/atoms/Button';
import Countdown from '../CountDown';
import TimerPass from '../TimerButton/TimerPass';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import NotPassCoopeuch from '../NotPassCoopeuch';
import ModalPassCoopeuchError from '../Error/ModalError/ModalPassCoopeuchError';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

// Styles
import Wrapper, { CardFooter, Blocker, Overlay } from './styles';

// Texts
import passCoopeuch, { errores } from './text';

const FactorPassCoopeuch = (props) => {
  const {
    loading,
    counterActive,
    timer,
    timerValue,
    errorTimeOut,
    IsValidatePassCoopeuch,
    validarPassCoopeuchCP,
    IsAvailablePassCoopeuch,
    validateSMS,
    errorTimeOutCP,
    solicitarPassCoopeuch,
    isVisibleModalError,
    handleClickCloseModalError,
    IsAvailableTarCoo,
    IsLoading,
    handleSwitchMethods,
    retryTimeOutPass,
    solicitarDesafioPassCoopeuch,
  } = props;
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const factor='passcoopeuch';
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
            <CardBox.Header>
              <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
                Autoriza con PassCoopeuch
              </Typography>
            </CardBox.Header>        
            <CardBox.Body>
              {IsLoading === true ?( <div className="contentSMS1"><SpinnerLoader /></div>
              ) : (
                IsAvailablePassCoopeuch ? (
                <div className="contentSMS1">
                  {errorTimeOut === false && (IsValidatePassCoopeuch.success === null || IsValidatePassCoopeuch.success === true) &&  (solicitarDesafioPassCoopeuch.success === null || solicitarDesafioPassCoopeuch.success === true) && (
                    <Countdown
                      ilustration="pass_coopeuch"
                      withCountdownAnimation={counterActive}
                      timer={timerValue}
                      errorTimeOut={errorTimeOut}
                      validateSMS={validateSMS}
                    />
                  )}
                  {errorTimeOut === true && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {IsValidatePassCoopeuch.codigo === 8 && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {IsValidatePassCoopeuch.codigo === 7 && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {(IsValidatePassCoopeuch.success === false || solicitarDesafioPassCoopeuch.success === false || IsValidatePassCoopeuch.codigo===50 || IsValidatePassCoopeuch.codigo===2) && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {errorTimeOut === true && <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Se agotó el tiempo!</p>}
                  {IsValidatePassCoopeuch.codigo === 8 && (
                    <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Operación rechazada!</p>
                  )}
                  {IsValidatePassCoopeuch.codigo === 7 && (
                    <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Lo sentimos!</p>
                  )}
                  {(IsValidatePassCoopeuch.success === false || solicitarDesafioPassCoopeuch.success === false || IsValidatePassCoopeuch.codigo===50 || IsValidatePassCoopeuch.codigo===2) && (
                    <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Lo sentimos!</p>
                  )}
                  { (solicitarDesafioPassCoopeuch.success === true || solicitarDesafioPassCoopeuch.success === null) && counterActive === false && errorTimeOut === false && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.
                    </p>
                  )}
                  {solicitarDesafioPassCoopeuch.success === true && errorTimeOut === false && counterActive === true && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Ingresa clave PassCoopeuch antes que acabe el tiempo para autorizar la operación.
                    </p>
                  )}
                  {errorTimeOut === true && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Tienes un tiempo limitado para autorizar, inténtalo nuevamente.
                    </p>
                  )}

                  {IsValidatePassCoopeuch.codigo === 8 && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Haz rechazado la operación. Si quieres, puedes intentarlo <br /> nuevamente.
                    </p>
                  )}
                  {IsValidatePassCoopeuch.codigo === 7 && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      PassCoopeuch está bloqueada. Regístrate nuevamente 
                      <br />en la App o vuelve para autorizar con Tarjeta de Coordenadas.
                    </p>
                  )}
                  {(IsValidatePassCoopeuch.success === false || solicitarDesafioPassCoopeuch.success === false || IsValidatePassCoopeuch.codigo===50 || IsValidatePassCoopeuch.codigo===2) && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      El servicio no se encuentra disponible. Vuelve a intentarlo
                      <br /> más tarde.
                    </p>
                  )}
                  {solicitarDesafioPassCoopeuch.success === true && counterActive === true && (
                    <TimerPass
                      {...{
                        counterActive,
                        validarPassCoopeuchCP,
                        errorTimeOutCP,
                        factor,
                        IsValidatePassCoopeuch,
                      }}
                    />
                  )}
                  {solicitarDesafioPassCoopeuch.success === null && counterActive === false && errorTimeOut === false && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      fontSize={14}
                      className="contentSMS1__btn"
                      onClick={solicitarPassCoopeuch}
                      style={{ textAlign: "center" }}
                    >
                      Autorizar con PassCoopeuch
                    </Button>
                  )}
                  {errorTimeOut === true && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      className="contentSMS1__btn"
                      style={{ textAlign: "center" }}
                      onClick={retryTimeOutPass}
                    >
                      Reintentar
                    </Button>
                  )}
                  {errorTimeOut === null && IsValidatePassCoopeuch.codigo === 8 && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      className="contentSMS1__btn"
                      style={{ textAlign: "center" }}
                      onClick={retryTimeOutPass}
                    >
                      Reintentar
                    </Button>
                  )}
                  {(IsValidatePassCoopeuch.success === false || solicitarDesafioPassCoopeuch.success === false || IsValidatePassCoopeuch.codigo===50 || IsValidatePassCoopeuch.codigo===2) && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      className="contentSMS1__btn"
                      style={{ textAlign: "center" }}
                      onClick={retryTimeOutPass}
                    >
                      Volver a intentar
                    </Button>
                  )}
                  
                </div>
              ) : (
                <NotPassCoopeuch />
              )
              )}
              <ModalPassCoopeuchError
                visibleModal={isVisibleModalError}
                handleClickCloseModalError={handleClickCloseModalError}
              />
            </CardBox.Body>
            {IsAvailableTarCoo && (
              <CardFooter>
                <button type="button" onClick={() => handleSwitchMethods('tarjetacoord')}>
                  Quiero autorizar con Tarjeta de Coordenadas
                </button>
              </CardFooter>
            )}
          </CardBox>
    </Wrapper>
  );
};

FactorPassCoopeuch.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  withCountdownAnimation: PropTypes.bool,
  duration: PropTypes.number,
  timer: PropTypes.number,
  stepFactor: PropTypes.number,
  activeTimer: PropTypes.bool,
  solicitarPassCoopeuch: PropTypes.func.isRequired,
  validarPassCoopeuchCP: PropTypes.func.isRequired,
  errorTimeOutCP: PropTypes.func.isRequired,
  handleSwitchMethods: PropTypes.func.isRequired,
};

FactorPassCoopeuch.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  message: '',
  buttonText: '',
  withCountdownAnimation: false,
  duration: 120,
  // timer: 0,
  stepFactor: 0,
  activeTimer: false,
};

export default FactorPassCoopeuch;
