import React from 'react';
import PropTypes from 'prop-types';
import Button from '@coopeuch-components/web/atoms/Button';

const AuthButton = ({ bgColor, color, fontSize, disabled, className, onClick, children, style }) => {
  return (
    <Button
      fontSize={`${fontSize}px`}
      color={color}
      bgColor={bgColor}
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={style}
      
    >
      {children}
    </Button>
  );
};

AuthButton.propTypes = {
  bgColor: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default AuthButton;
