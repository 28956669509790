import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// actions
import { bonoEscolarActions } from '../../../../application/actions/bonoEscolar';
// Template
import PostulacionTemplate from '../../../components/templates/BonoEscolar/Postulacion';
// Metrics
import { registerGA } from '../../../utils/metrics';
// Utils
import { isRunValid } from '../../../utils/validators';
import { removeGuion } from '../../../utils/formatters';

const Postulacion = ({ steps, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Tabs Options
  const [isBenefitMe, setIsBenefitMe] = useState(false);
  const [isBenefitSon, setIsBenefitSon] = useState(false);
  // States Information
  const [rutSon, setRutSon] = useState('');
  const [nameSon, setNameSon] = useState('');
  const [institute, setInstitute] = useState('');
  const [career, setCareer] = useState('');
  // Habilited
  const [isDisabledBtnContinue, setIsDisabledBtnContinue] = useState(true);
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  // Errors
  const [isErrorRut, setIsErrorRut] = useState(false);
  const [errorGeneric, setErrorGeneric] = useState(false);
  // Stores
  const persona = useSelector((store) => store.entities.persona);
  const dataInstitutes = useSelector((store) => store.entities.bonoEscolar.dataInstitutes);
  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);
  const duplicidadBono = useSelector((store) => store.entities.bonoEscolar.duplicidadBono);
  const cardDuplicidad = duplicidadBono?.data?.estado;

  const setTipoPostulacion = (data) => {
    if (data.typeBono === 'Bono de Ingreso' && isBenefitMe === true) {
      return 1;
    }
    if (data.typeBono === 'Bono de Ingreso' && isBenefitSon === true) {
      return 2;
    }
    if (data.typeBono === 'Bono de Titulación' && isBenefitMe === true) {
      return 3;
    }
    if (data.typeBono === 'Bono de Titulación' && isBenefitSon === true) {
      return 4;
    }
  };

  // Functions
  const handleCleanStates = () => {
    setRutSon('');
    setNameSon('');
    setInstitute('');
    setCareer('');
    setIsErrorRut(false);
    setIsDisabledBtnContinue(true);
  };

  // Events
  const handleChangeBenefit = (benefit) => {
    if (benefit === 0) {
      setIsBenefitMe(true);
      setIsBenefitSon(false);
    } else {
      setIsBenefitMe(false);
      setIsBenefitSon(true);
    }
    handleCleanStates();
  };

  const nameComplete = (persona) => {
    const fullName = `${persona.primerNombre && persona.primerNombre + ' '}${persona.segundoNombre && persona.segundoNombre + ' '
      }${persona.apellidoPaterno && persona.apellidoPaterno + ' '}${persona.apellidoMaterno && persona.apellidoMaterno + ' '
      }`;
    return fullName;
  };

  const handleClickContinue = () => {
    setCurrentStep(currentStep + 1);
    const params = {
      nombreSocio: nameComplete(persona),
      typeBeneficiario: isBenefitMe ? 'Para Mí' : 'Para mi hijo',
      nombreBeneficiario: nameSon !== '' ? nameSon : nameComplete(persona),
      rutBeneficiario: rutSon !== '' ? removeGuion(rutSon) : persona.rut,
      institucion: institute,
      carrera: career,
    };
    dispatch(bonoEscolarActions.setStatesBonoPostulacion(params));
    history.push('/ecd/bono-escolar/documentacion', { replace: true });
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
        }/paso2`,
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
  };

  const handleClickBack = () => {
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
        }/paso2`,
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    history.push('/ecd/bono-escolar/');
  };

  const handleClickBtnModal = () => {
    setIsVisibleModal(!isVisibleModal);
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
        }/paso2`,
      'eve-cat': 'Click',
      'eve-lab': 'Entendido',
    });
  };

  const handleClickRetry = (text) => {
    registerGA({
      event: 'eve',
      'eve-acc': 'paso2',
      'eve-cat': 'Error',
      'eve-lab': text,
    });
    history.push('/ecd/inicio');
  };

  // Validate routes
  useEffect(() => {
    currentStep < 2 && history.push('/ecd/bono-escolar/');
    registerGA({
      event: 'vpv-privado',
      page: `/beneficios/${dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
        }/paso2`,
    });
  }, []);

  // Load Institutes and Careers
  useEffect(() => {
    dispatch(bonoEscolarActions.fetchGetInstitutesAndCareers());
  }, []);

  useEffect(() => {
    const tipoPostulacion = setTipoPostulacion(dataBono);
    let rutBeneficiario = '';
    if (isBenefitMe === true) {
      dispatch(bonoEscolarActions.fetchDuplicidadBono({ tipoPostulacion, rutBeneficiario }));
    } else if (isBenefitSon === true && isRunValid(rutSon)) {
      rutBeneficiario = removeGuion(rutSon);
      dispatch(bonoEscolarActions.fetchDuplicidadBono({ tipoPostulacion, rutBeneficiario }));
    }
    return () => {
      dispatch(bonoEscolarActions.setResetValuesDuplicidad());
    };
  }, [rutSon, isBenefitMe, isBenefitSon]);

  useEffect(() => {
    if (cardDuplicidad === 'EN_CURSO' || cardDuplicidad === 'PAGADO') {
      registerGA({
        event: 'eve',
        'eve-acc': `/beneficios/${dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'}/paso2`,
        'eve-cat': 'Mensaje',
        'eve-lab': `${cardDuplicidad === 'EN_CURSO' ? 'Solicitud de bono en curso' : 'Bono otorgado anteriormente'} - ${isBenefitMe === true ? 'Para mi' : 'Para hijo/a'}`,
      });
    }
  }, [cardDuplicidad])


  // Control error duplicidad 
  useEffect(() => {
    if (duplicidadBono.error === true ) {
      setErrorGeneric(true)
    }
  }, [duplicidadBono]);


  // Control loading to selects
  useEffect(() => {
    setLoading(true);
    if (dataInstitutes.institutes && dataInstitutes.institutes) {
      setLoading(false);
      setErrorGeneric(false);
    }
    if (dataInstitutes.error === true) {
      setErrorGeneric(true);
      registerGA({
        event: 'eve',
        'eve-acc': 'paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [dataInstitutes]);

  // Validates inputs
  useEffect(() => {
    if (rutSon.length > 0) {
      isRunValid(rutSon) ? setIsErrorRut(false) : setIsErrorRut(true);
    }
  }, [rutSon]);

  // Validate btn Continue
  useEffect(() => {
    // Validate if for me or to son
    if (isBenefitMe === true) {
      institute !== '' && career !== ''
        ? setIsDisabledBtnContinue(false)
        : setIsDisabledBtnContinue(true);
    }
    if (isBenefitSon === true) {
      nameSon !== '' &&
        rutSon !== '' &&
        isRunValid(rutSon) === true &&
        institute !== '' &&
        career !== ''
        ? setIsDisabledBtnContinue(false)
        : setIsDisabledBtnContinue(true);
    }
  }, [rutSon, nameSon, institute, career]);

  return (
    <PostulacionTemplate
      {...{
        steps,
        handleChangeBenefit,
        isBenefitMe,
        isBenefitSon,
        isErrorRut,
        nameSon,
        setNameSon,
        rutSon,
        setRutSon,
        isDisabledBtnContinue,
        setInstitute,
        setCareer,
        dataInstitutes,
        handleClickContinue,
        isVisibleModal,
        setIsVisibleModal,
        loading,
        errorGeneric,
        handleClickBack,
        handleClickBtnModal,
        handleClickRetry,
        cardDuplicidad,
      }}
    />
  );
};

export default Postulacion;
