import globalStyles, { fonts, colors } from './../../../assets/styles';
const styles = {
  containerModal: {
    paddingTop: 10,
    paddingBottom: 25,
  },
  tdContainer: {
    display: 'flex', 
    justifyContent: 'flex-end'
  },
  spanText: {
    marginRight: '5px'
  },
  tableContainer: {
    width: '100%'
  },
  titleModal: {
    fontSize: fonts.big,
    fontFamily: fonts.fontFamily.BOLD,
  },
  descriptionModal: {
    fontSize: fonts.normal,
    fontFamily: fonts.fontFamily.REGULAR,
    marginBottom: 20,
    textAlign: 'center',
  },
  tableModal: {
    marginVertical: 30,
    display: 'flex',
    flexDirection: 'column',
  },
  rowModal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#E7E7E7',
    paddingVertical: 10,
  },
  infoModal: {
    borderBottom: '1px solid #E7E7E7'
  },
  paddingTD: {
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: fonts.normal,
    fontFamily: fonts.fontFamily.REGULAR,
    textAlign: 'left',
    paddingTop: 10,
    paddingBottom: 10,
  },
  textStrong: { 
    fontSize: fonts.normal, 
    fontFamily: fonts.fontFamily.BOLD, 
    textAlign: 'right', 
    paddingTop: 10,
    paddingBottom: 10,
  },
  subTitle:{
    fontSize: fonts.normal, 
    fontFamily: fonts.fontFamily.BOLD,
    textAlign: 'center',
    marginBottom: 4, 
  },
  btnLink: {
    textAlign: 'center',
    fontSize: fonts.medium,
    fontFamily: fonts.fontFamily.MEDIUM,
    color: colors.blueInputColor,
  },
  containerButtons: {
    display: 'flex', 
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10
  },
  containerbtnClose: {
    display: 'flex', 
    alignItems: 'center'
  }
};
export default styles;