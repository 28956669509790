import styled from 'styled-components';

const Wrapper = styled.div`
.pantalla{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh; /* Altura total de la pantalla */
  box-sizing: border-box;
  position: relative;
  background: #FFFFFF;
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 375px; /* Máximo ancho de la pantalla móvil */
  padding: 0 20px; /* Padding opcional */
  margin-top: 20px;
  
}
.rectangue,
  .imageV1,
  .imageV2,
  .imageV3,
  .imageV4 {
    width: 90%;
    max-width: 339px;
    position: absolute;
    border-radius: 5%;
    left: 50%;
    transform: translateX(-50%);
}
.rectangue {
  height: 219px; 
  top: 7%; 
  opacity: 1; 
  background: #E81E2B;
}

.appCoopeuch{
  width: 174px;
  height: 44px;
  top: calc(7% + 20px);
  left: 50%;
  transform: translateX(-85.91%); 
  gap: 0px;
  opacity: 1px;
  position: absolute;
}

.iconM{
  width: 24px;
  height: 24px;
  top: calc(7% + 80px);
  left: 50%;
  transform: translateX(-622.91%);
  gap: 0px;
  opacity: 1px;
  position: absolute;
}
.pConQR{
  width: 76px;
  height: 20px;
  top: calc(7% + 88px);
  left: 50%;
  transform: translateX(-151.97%);
  gap: 0px;
  opacity: 1px;
  position: absolute;
}
.realizaPago{
  width: 159px;
  height: 56px;
  top: calc(7% + 125px);
  left: 50%;
  transform: translateX(-94.02%);
  gap: 0px;
  opacity: 1px;
  z-index: 2;
  position: absolute;
  font-family: Ubuntu;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.celular{
  width: 170px;
  height: 296px;
  top: calc(7% - 35px);
  left: 51%;
  gap: 0px;
  opacity: 1px;
  position: absolute;
  z-index:2;
}
.imageV3{
  height: 86px;
  position: absolute;
  top: calc(7% + 219px - 86px);
  opacity: 0.9;
}
.imageV2{
  height: 82px;
  position: absolute;
  top: calc(7% + 219px - 82px);
}
.imageV1{
  height: 96px;
  position: absolute;
  top: calc(7% + 219px - 96px);
  opacity: 0.5;
}
.imageV4{
  height: 109px;
  position: absolute;
  top: calc(7% + 219px - 109px);
  opacity: 0.6;
}
.logoCoopeuch {
  width: 26%; 
  max-width: 97px; 
  height: auto; 
  position: absolute;
  top: 40%; 
  left: 50%;
  transform: translateX(-50%);
  gap: 0px;
  opacity: 1;
}
.appCoopeuch1 {
  width: 47%; 
  max-width: 174px; 
  height: auto; 
  position: absolute;
  top: 57%; 
  left: 50%;
  transform: translateX(-50%);
  gap: 0px;
  opacity: 1;
  font-family: Ubuntu;
  font-size: 6.4vw; /* Tamaño de fuente relativo al ancho de la vista */
  font-weight: 700;
  line-height: 8.5vw; /* Ajustar el interlineado relativo al tamaño de la fuente */
  text-align: center;
}
.descarga {
  width: 77%; 
  max-width: 287px; 
  height: auto; 
  position: absolute;
  top: 64%; 
  left: 50%;
  transform: translateX(-50%);
  gap: 0px;
  opacity: 1;
  font-family: Ubuntu;
  font-size: 3.7vw; /* Tamaño de fuente relativo al ancho de la vista */
  font-weight: 400;
  line-height: 5.3vw; /* Ajustar el interlineado relativo al tamaño de la fuente */
  text-align: center;
}
.botonDescarga {
  width: 80%;
  max-width: 298px;
  height: auto; 
  position: absolute;
  top: 80%; 
  left: 50%;
  transform: translateX(-50%);
  gap: 0px;
  opacity: 1;
  animation-duration: 0ms;
  font-family: Ubuntu;
  font-size: 4vw; /* Tamaño de fuente relativo al ancho de la vista */
  font-weight: 700;
  line-height: 5.6vw; /* Ajustar el interlineado relativo al tamaño de la fuente */
  text-align: center;
  background-color: #E81E2B;
  color: white;
  border: none;
  border-radius: 24px;
}
`;

export default Wrapper;
