import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import {useHistory} from "react-router-dom";
import Button from "@coopeuch-components/web/atoms/Button";
import StyledBannerNorma484 from './styles';
import {registerGA} from "../../../../../utils/metrics";
import {accesosRapidosTexts} from "../../../../../containers/Dashboard/texts";

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Index = () => {
  const history = useHistory();

  const handleClickNorma484 = () => {
    history.push('/ecd/anexo');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': accesosRapidosTexts.NORMA_TITLE,
    });
  };
  return (
    <StyledBannerNorma484
      src={`${staticPath}dashboardbanner/banner_norma484_desk.svg`}
      srcMobile={`${staticPath}dashboardbanner/banner_norma484_mobile.svg`}
    >
      <div className="banner-norma484-container row">
        <div className="col-xs-8 col-sm-8  col-sm-8 col-md-6 banner-row-container">
          <div>
            <label className="banner-norma484-title">
              Infórmate sobre las rebajas en las comisiones de tu tarjeta de crédito
            </label>
          </div>
          <div>
            <div className="banner-norma484-list bmb-2 display-desktop">
              <LoadIcon src={`${iconPath}check.svg`} size={20} color="#fff" className="bmr-1" />
              <label className="banner-norma484-list-text">Mejores condiciones</label>
            </div>
            <div className="banner-norma484-list bmb-2 display-desktop">
              <LoadIcon src={`${iconPath}check.svg`} size={20} color="#fff" className="bmr-1" />
              <label className="banner-norma484-list-text">100% en línea</label>
            </div>
          </div>

          <Button bgColor="red" color="white" className="btn_red" onClick={handleClickNorma484}>
            Revisar condiciones
          </Button>
        </div>
      </div>
    </StyledBannerNorma484>
  );
};

export default Index;
