import React from 'react';

import CardKit from '../../../organism/ActivacionTarjetasKit/Activacion/CardKit';
import NoKit from '../../../../components/organism/ActivacionTarjetasKit/Activacion/NoKit';
import CardBlock from '../../../organism/ActivacionTarjetasKit/Error/CardBlock';
// Styled
import Wrapper from './styles';

const HomeTemplateKit = ({ kitData, handleClickActivate, handleReturnInicio }) => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-xs-12">
          {kitData.data !== null &&
            (kitData.data?.activable === true ? (
              <CardKit kitData={kitData} handleClickActivate={handleClickActivate} handleReturnInicio={handleReturnInicio}/>
            ) : (
              <NoKit
                icon="copywriting.svg"
                destacado="¡Nada por aquí!"
                text="Si ya activaste tus tarjetas, puedes revisarla en la sección Cuenta Vista Coopeuch"
              />
            ))}
          {kitData.error?.code === 'TC-03' && (
            <NoKit
              icon="block.svg"
              destacado="¡Tu producto está bloqueado!"
              text="Dirigite a la sucursal más cercana para continuar con la activación."
            />
          )}
          {kitData.error !== null && kitData.error?.code !== 'TC-03' && (
            <CardBlock
              section="Tus tarjetas están listas para ser activadas"
              title="¡Lo sentimos!"
              message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
              link="/ecd/inicio"
              msgErrorGA="Servicio no disponible"
              pageGA="/ecd/seguridad/activacion-tarjetas"
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeTemplateKit;
