import React, { Component } from 'react';
import { produce } from 'immer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Stepper from '../../components/organism/Stepper';
import TarjetaTemplate from '../../components/templates/BloqueoTarjetas/BloqueoPermantente/Tarjeta';
import ConfirmacionTemplate from '../../components/templates/BloqueoTarjetas/BloqueoPermantente/Confirmacion';
import { tCoordenadaActions } from '../../../application/actions/tarjetaCoordenada';
import { productActions } from '../../../application/actions/products';
import { tipoActions } from '../../../application/actions/tipo';
import ComprobanteTemplate from '../../components/templates/BloqueoTarjetas/BloqueoPermantente/Comprobante';

const steps = ['Selecciona Tarjeta', 'Retiro y confirmación', 'Comprobante'];

class BloqueoPermanente extends Component {
  state = {
    position: 1,
    tarjetasSeleccionables: [],
    tarjetaSeleccionada: {},
    motivoSeleccionado: '',
    showClave: false,
    valuePassword: '',
  };

  handleClickTarjeta = (tarjeta) => {
    this.setState(
      produce((draft) => {
        draft.tarjetaSeleccionada = tarjeta;
      })
    );
  };

  handleClickContinue = (step) => {
    const { clearEstadoTarjetaCoordenada, clearEstadoTarjetaDebito } = this.props;
    clearEstadoTarjetaCoordenada();
    clearEstadoTarjetaDebito();
    this.setState(
      produce((draft) => {
        draft.position = step;
        draft.motivoSeleccionado = '';
        draft.showClave = false;
        draft.valuePassword = '';
      })
    );
  };

  handleClickReturn = (step) => {
    this.setState(
      produce((draft) => {
        draft.position = step;
      })
    );
  };

  handleMotivo = (step) => {
    this.setState(
      produce((draft) => {
        draft.motivoSeleccionado = step;
      })
    );
  };

  handleReturnLanding = () => {
    const { changeFlujo } = this.props;
    this.setState(
      produce((draft) => {
        draft.tarjetasSeleccionables = [];
      })
    );
    changeFlujo('LANDING');
  };

  handleShowPassword = () => {
    this.setState(
      produce((draft) => {
        draft.showClave = !draft.showClave;
      })
    );
  };

  onHandleInput = (event) => {
    const value = event.target.value;
    this.setState(
      produce((draft) => {
        draft.valuePassword = value;
      })
    );
  };

  handleBloqueo = () => {
    const { rut, fetchCambioEstadoTarjetaCoordenadas, fetchCambioEstadoTarjetaDebito } = this.props;
    const { tarjetaSeleccionada, valuePassword, motivoSeleccionado } = this.state;
    if (tarjetaSeleccionada?.tipo === 'TC') {
      const params = {
        rut: rut,
        numeroTarjeta: tarjetaSeleccionada?.numero,
        descripcion: 'CANCELED',
        comentario: motivoSeleccionado,
        clave: valuePassword,
      };
      fetchCambioEstadoTarjetaCoordenadas({ params });
    }
    if (tarjetaSeleccionada?.tipo === 'CV1' || tarjetaSeleccionada?.tipo === 'CV2') {
      const params = {
        clave: valuePassword,
        definitivo: true,
        motivo: motivoSeleccionado,
        tarjetaId: tarjetaSeleccionada?.id,
      };
      fetchCambioEstadoTarjetaDebito({ params });
    }
  };

  handleReturnHome = () => {
    const { history } = this.props;
    history.push('/ecd/inicio');
  };

  handleReturnCuentaVista = () => {
    const { history } = this.props;
    history.push('/ecd/productos/cuenta-coopeuch');
  };

  componentDidMount() {
    const { tarjetasDebito, estadoTCoordenadas, fetchMotivoTarjetaBloqueo } = this.props;
    const { tarjetasSeleccionables } = this.state;

    fetchMotivoTarjetaBloqueo();

    let tarjetasDebitoSeleccionable = new Array();

    if (tarjetasDebito) {
      // console.log({ tarjetasDebito });
      const uniqueValuesSet = new Set();

      const filteredArr = tarjetasDebito.filter((obj) => {
        const isPresentInSet = uniqueValuesSet.has(obj.numeroTarjeta);
        uniqueValuesSet.add(obj.numeroTarjeta);
        return !isPresentInSet;
      });

      filteredArr.forEach((tarjeta) => {
        if (tarjeta?.numeroTarjeta) {
          tarjetasDebitoSeleccionable.push({
            tipo: tarjeta?.tieneChip ? 'CV2' : 'CV1',
            numero: tarjeta?.numeroTarjeta,
            descripcion: tarjeta?.descripcion,
            owner: tarjeta?.tipo,
            id: tarjeta?.id,
          });
        }
      });
    }

    let tarjetaCoordenadasSeleccionable = new Array();

    if (estadoTCoordenadas) {
      estadoTCoordenadas.forEach((tarjeta) => {
        if (tarjeta?.estado === 'CURRENT' || tarjeta?.estado === 'HOLD') {
          tarjetaCoordenadasSeleccionable.push({
            tipo: 'TC',
            numero: tarjeta.numeroSerie,
            descripcion: 'Tarjeta de Coordenadas',
            owner: 'Titular',
          });
        }
      });
    }

    this.setState(
      produce((draft) => {
        draft.tarjetasSeleccionables = tarjetasSeleccionables.concat(
          tarjetasDebitoSeleccionable,
          tarjetaCoordenadasSeleccionable
        );
      })
    );
  }

  componentDidUpdate(prevProps) {
    const {
      isLoadingCambioEstadoTjCoord,
      cambioEstadoTarjeta,
      isLoadingCambioEstadoTjDebito,
      statusCambioEstadoTjDebito,
    } = this.props;

    if (prevProps.isLoadingCambioEstadoTjCoord !== isLoadingCambioEstadoTjCoord) {
      if (!isLoadingCambioEstadoTjCoord) {
        if (cambioEstadoTarjeta) {
          this.setState(
            produce((draft) => {
              draft.position = 3;
            })
          );
        }
      }
    }

    if (prevProps.isLoadingCambioEstadoTjDebito !== isLoadingCambioEstadoTjDebito) {
      if (!isLoadingCambioEstadoTjDebito) {
        if (statusCambioEstadoTjDebito) {
          this.setState(
            produce((draft) => {
              draft.position = 3;
            })
          );
        }
      }
    }
  }

  render() {
    const {
      position,
      tarjetasSeleccionables,
      tarjetaSeleccionada,
      motivoSeleccionado,
      showClave,
      valuePassword,
    } = this.state;

    const {
      mensajeErrorCambioEstadoTjCoordenadas,
      mensajeErrorCambioEstadoTjDebito,
      motivoTarjetaBloqueo,
      isLoadingMotivoTarjetaBloqueo,
      isLoadingCambioEstadoTjCoord,
      isLoadingCambioEstadoTjDebito,
      cambioEstadoTarjetaCoordenadas,
      cambioEstadoTjDebito,
      correo,
    } = this.props;

    return (
      <div>
        <div className="bmb-md-4 bmb-3 bmt-md-4 bmt-3">
          <Stepper className="start-xs" position={position} steps={steps} />
        </div>
        {position === 1 && (
          <TarjetaTemplate
            tarjetasSeleccionables={tarjetasSeleccionables}
            tarjetaSeleccionada={tarjetaSeleccionada}
            handleClickTarjeta={this.handleClickTarjeta}
            handleReturnLanding={this.handleReturnLanding}
            handleClickContinue={this.handleClickContinue}
          />
        )}
        {position === 2 && (
          <ConfirmacionTemplate
            motivoSeleccionado={motivoSeleccionado}
            handleMotivo={this.handleMotivo}
            handleClickContinue={this.handleClickContinue}
            showClave={showClave}
            handleShowPassword={this.handleShowPassword}
            valuePassword={valuePassword}
            onHandleInput={this.onHandleInput}
            handleBloqueo={this.handleBloqueo}
            mensajeErrorCambioEstado={
              mensajeErrorCambioEstadoTjCoordenadas || mensajeErrorCambioEstadoTjDebito
            }
            motivoTarjetaBloqueo={motivoTarjetaBloqueo}
            isLoadingMotivoTarjetaBloqueo={isLoadingMotivoTarjetaBloqueo}
            handleClickReturn={this.handleClickReturn}
            loadingCambioEstado={isLoadingCambioEstadoTjCoord || isLoadingCambioEstadoTjDebito}
          />
        )}
        {position === 3 && (
          <ComprobanteTemplate
            tarjetaSeleccionada={tarjetaSeleccionada}
            handleReturnHome={this.handleReturnHome}
            handleReturnCuentaVista={this.handleReturnCuentaVista}
            datosTarjeta={
              tarjetaSeleccionada?.tipo === 'TC'
                ? cambioEstadoTarjetaCoordenadas
                : cambioEstadoTjDebito
            }
            correo={correo}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  cambioEstadoTarjetaCoordenadas: entities.tarjetaCoordenada.cambioEstadoTarjetaCoordenadas,
  errorCambioEstadoTjCoord: entities.tarjetaCoordenada.errorCambioEstadoTjCoord,
  isLoadingCambioEstadoTjCoord: ui.loaders.isLoadingCambioEstadoTjCoord,
  isLoadingCambioEstadoTjDebito: ui.loaders.isLoadingCambioEstadoTjDebito,
  rut: entities.user.rut,
  mensajeErrorCambioEstadoTjCoordenadas:
    entities.tarjetaCoordenada.mensajeErrorCambioEstadoTjCoordenadas,
  mensajeErrorCambioEstadoTjDebito: entities.products.mensajeErrorCambioEstadoTjDebito,
  errorCambioEstadoTjDebito: entities.products.errorCambioEstadoTjDebito,
  cambioEstadoTjDebito: entities.products.cambioEstadoTjDebito,
  motivoTarjetaBloqueo: entities.tipo.motivoTarjetaBloqueo,
  isLoadingMotivoTarjetaBloqueo: ui.loaders.isLoadingMotivoTarjetaBloqueo,
  cambioEstadoTarjeta: entities.tarjetaCoordenada.cambioEstadoTarjeta,
  statusCambioEstadoTjDebito: entities.products.statusCambioEstadoTjDebito,
  correo: entities.persona.correo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCambioEstadoTarjetaCoordenadas(params) {
    dispatch(tCoordenadaActions.fetchCambioEstadoTarjetaCoordenadas(params));
  },
  fetchCambioEstadoTarjetaDebito(params) {
    dispatch(productActions.fetchCambioEstadoTarjetaDebito(params));
  },
  fetchMotivoTarjetaBloqueo() {
    dispatch(tipoActions.fetchMotivoTarjetaBloqueo());
  },
  clearEstadoTarjetaCoordenada() {
    dispatch(tCoordenadaActions.clearEstadoTarjetaCoordenada());
  },
  clearEstadoTarjetaDebito() {
    dispatch(productActions.clearEstadoTarjetaDebito());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BloqueoPermanente));
