import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidEmail } from '../../../../utils/formatters';
import { downloadPDFBase64 } from '../../../../utils/functions';

// componentes
import ContratoMonederoTemplate from '../../../../components/templates/Remanente/CapitalizarContratando';
import { monederoActions } from '../../../../../application/actions/monedero';
import { layoutActions } from '../../../../../application/actions/ui_layout';
import { personaActions } from '../../../../../application/actions/persona';
import { registerGA } from '../../../../utils/metrics';

const initState = {
  contratacionChecked: false,
  cuenta: null,
  fecha: null,
  email: '',
  monto: null,
  secondEmail: '',
  password: '',
  passwordError: '',
  emailError: '',
  secondEmailError: '',
  serverPasswordError: '',
  okStep2: false,
  showPassword: false,
  loadingContratacion: false,
  steps: ['Características', 'Contratación y firma', 'Comprobante'],
  currentStep: 1,
  serviceError: false,
  blockedPassword: false,
  showEnviarComprobanteModal: false,
  showEnviarComprobanteErrorModal: false,
  showComprobanteEnviadoModal: false,
  globalError: false,
};
class ContratoMonedero extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  componentDidMount = () => {
    const { fetchDatosPersonales } = this.props;
    fetchDatosPersonales();
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/capitalizar/paso1B',
    });
  };

  componentDidUpdate(prevProps) {
    const {
      monedero,
      persona,
      comprobanteContratacion,
      contratoCanales,
      contratoMonedero,
    } = this.props;
    if (monedero !== prevProps.monedero) {
      this.handleContratoMonederoResponse(monedero);
    } else if (
      comprobanteContratacion !== prevProps.comprobanteContratacion &&
      comprobanteContratacion !== null
    ) {
      downloadPDFBase64(comprobanteContratacion, 'Comprobante Contratacion Monedero');
    } else if (contratoCanales !== prevProps.contratoCanales && contratoCanales !== null) {
      this.getContratoCanales();
    } else if (contratoMonedero !== prevProps.contratoMonedero && contratoMonedero !== null) {
      this.getContratoMonedero();
    } else if (persona !== prevProps.persona) {
      if (persona.errorDatosPersonales) {
        this.setState({
          globalError: true,
        });
      } else {
        this.setState({
          email: persona.correo,
        });
      }
    }
  }

  handleContratoMonederoResponse = (monedero) => {
    if (monedero.error) {
      let serverErrorText;
      switch (monedero.errorCode) {
        case '02':
        case '03':
          serverErrorText = 'Clave Bloqueada. Por seguridad hemos bloqueado tu clave.';
          break;
        case '01':
          serverErrorText = 'Los datos ingresados son incorrectos. Favor intente nuevamente';
          break;
        default:
          serverErrorText = '';
      }
      this.setState({
        loadingContratacion: false,
        serverPasswordError: serverErrorText,
        serviceError: serverErrorText.length ? false : true,
        blockedPassword: ['02', '03'].includes(monedero.errorCode) ? true : false,
      });
    } else if (monedero.success) {
      /**
       * Se elimina el llamado al servicio por tener el menú estático
       * TODO: habilitar cuando se vuelva a utilizar el servicio de configuración
       */
      // const { fetchLayoutConfigs } = this.props;
      // fetchLayoutConfigs();
      const { cuenta, fecha, email } = monedero;
      this.setState({
        loadingContratacion: false,
        cuenta,
        fecha,
        email,
      });
      registerGA({
        event: 'vpv-privado',
        page: '/tef/remanente/capitalizar/comprobanteB',
        producto: 'Monedero Digital',
      });
      this.toNextStep();
    }
  };

  getContratoCanales = () => {
    const { fetchContratoCanales, contratoCanales } = this.props;
    if (!!contratoCanales) {
      downloadPDFBase64(contratoCanales, 'Contrato Canales');
    } else {
      fetchContratoCanales();
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/comprobanteB',
      'eve-cat': 'Click',
      'eve-lab': 'Contrato de canales',
    });
  };

  getContratoMonedero = () => {
    const { fetchContratoMonedero, contratoMonedero } = this.props;
    if (!!contratoMonedero) {
      downloadPDFBase64(contratoMonedero, 'Contrato Apertura Monedero');
    } else {
      fetchContratoMonedero();
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/comprobanteB',
      'eve-cat': 'Click',
      'eve-lab': 'Contrato de apertura de monedero',
    });
  };

  toNextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };
  toHome = () => {
    this.props.history.push('/ecd/inicio');
  };
  toRemanente = () => {
    this.props.onGoBackToRemanente();
  };
  onHandleCheckBox = (e) => {
    const name = e.target.name;
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };
  onHandlePasswordInput = (e) => {
    const value = e.target.value;
    const errorMessage = 'La clave debe tener entre 6 y 8 caracteres.';
    this.setState({
      password: value,
      passwordError: value.length < 6 || value.length > 8 ? errorMessage : '',
    });
  };
  onHandleChangeEmail = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      [name]: value,
      [`${name}Error`]: isValidEmail(value) ? '' : errorMessage,
    });
  };
  openSendEmailModal = () => {
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: true,
    });
  };
  onHandleSendComprobante = () => {
    this.setState(
      {
        secondEmail: '',
        secondEmailError: '',
        showEnviarComprobanteModal: false,
        showComprobanteEnviadoModal: true,
      },
      () => {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/capitalizar/comprobanteB',
          'eve-cat': 'Click',
          'eve-lab': 'Enviar',
        });
      }
    );
  };
  onHandleDownloadComprobante = () => {
    const { fetchComprobanteContratacion, comprobanteContratacion } = this.props;
    if (comprobanteContratacion !== null) {
      downloadPDFBase64(comprobanteContratacion, 'Comprobante Contratacion Monedero');
    } else {
      fetchComprobanteContratacion();
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/comprobanteB',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
  };
  onTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  onHandleExitWithoutContract = () => {
    this.setState({
      showCobrarDespuesSinContratoModal: true,
    });
  };
  onHandleExitWithContract = () => {
    this.setState({
      showCobrarDespuesConContratoModal: true,
    });
  };
  backStep2 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso2B',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.toRemanente();
  };
  continueStep2 = async () => {
    const { fetchContratacionMonedero } = this.props;
    const { password, email } = this.state;
    this.setState(
      {
        loadingContratacion: true,
      },
      () => {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/capitalizar/paso2B',
          'eve-cat': 'Click',
          'eve-lab': 'Contratar',
        });
        fetchContratacionMonedero({ clave: password, correo: email });
      }
    );
  };
  continueStep3 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/comprobanteB',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a Remanente',
    });
    this.toRemanente();
  };

  closeAllModals = () => {
    this.setState({
      showCobrarDespuesSinContratoModal: false,
      showCobrarDespuesConContratoModal: false,
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: false,
      showEnviarComprobanteErrorModal: false,
    });
  };

  backStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso1B',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.toRemanente();
  };
  continueStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso1B',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
    this.toNextStep();
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/capitalizar/paso2B',
    });
  };

  handleSendGTMDownloadContract = (contractName = '') => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso2B',
      'eve-cat': 'Click Descargar',
      'eve-lab': contractName,
    });
  };

  render() {
    const {
      contratacionChecked,
      cuenta,
      fecha,
      email,
      emailError,
      secondEmail,
      password,
      okStep2,
      showPassword,
      passwordError,
      secondEmailError,
      serverPasswordError,
      loadingContratacion,
      steps,
      currentStep,
      serviceError,
      blockedPassword,
      showEnviarComprobanteModal,
      showComprobanteEnviadoModal,
      showEnviarComprobanteErrorModal,
      globalError,
    } = this.state;
    const { persona } = this.props;
    return (
      <ContratoMonederoTemplate
        {...{
          contratacionChecked,
          cuenta,
          fecha,
          email,
          emailError,
          secondEmail,
          password,
          okStep2,
          showPassword,
          passwordError,
          secondEmailError,
          serverPasswordError,
          loadingContratacion,
          steps,
          currentStep,
          serviceError,
          blockedPassword,
          showEnviarComprobanteModal,
          showComprobanteEnviadoModal,
          showEnviarComprobanteErrorModal,
          persona,
          globalError,
        }}
        onDownloadGenericContract={this.handleSendGTMDownloadContract}
        onHandleCheckBox={this.onHandleCheckBox}
        onHandlePasswordInput={this.onHandlePasswordInput}
        onTogglePassword={this.onTogglePassword}
        backStep1={this.backStep1}
        continueStep1={this.continueStep1}
        backStep2={this.backStep2}
        continueStep2={loadingContratacion ? null : this.continueStep2}
        backStep3={this.toHome}
        continueStep3={this.continueStep3}
        closeModal={this.closeAllModals}
        toHome={this.toHome}
        toRemanente={this.toRemanente}
        onHandleEmailInputChange={this.onHandleChangeEmail}
        onHandleSendComprobante={this.onHandleSendComprobante}
        onHandleDownloadComprobante={this.onHandleDownloadComprobante}
        openSendEmailModal={this.openSendEmailModal}
        getContratoCanales={this.getContratoCanales}
        getContratoMonedero={this.getContratoMonedero}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  monedero: store.entities.monedero.contratacionMonedero,
  comprobanteContratacion: store.entities.monedero.comprobanteContratacion,
  persona: store.entities.persona,
  contratoCanales: store.entities.monedero.contratoCanales,
  contratoMonedero: store.entities.monedero.contratoMonedero,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContratacionMonedero(props) {
    dispatch(monederoActions.fetchContratacionMonedero(props));
  },
  fetchComprobanteContratacion(props) {
    dispatch(monederoActions.fetchComprobanteContratacion(props));
  },
  fetchContratoCanales(props) {
    dispatch(monederoActions.fetchContratoCanales(props));
  },
  fetchContratoMonedero(props) {
    dispatch(monederoActions.fetchContratoMonedero(props));
  },
  fetchLayoutConfigs() {
    dispatch(layoutActions.fetchLayoutConfigs());
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContratoMonedero));
