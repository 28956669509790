import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 64px 3rem 24px 3rem;

  .cardTitle {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    margin: 0;
  }

  .cardBodyTitle {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
  }

  .cardBodySubTitles {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
  }

  .cardBodyProducts {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin: 0;
  }


  @media (max-width: 768px) {
    padding: 64px 16px 24px 16px;

    .cardTitle {
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    .cardBodyTitle {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    .cardBodySubTitles {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    .cardBodyProducts {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      margin: 0;
    }

    /* .icon-sello svg {
      width: 96px;
      height: 114px;
    } */
  }

`;

export const PageTitleWrapper = styled.div`
  @media screen and (min-width: 768px) {
    #header-cuota-participacion {
      width: 100vmax;
    }
  }
`;


export const DownloadLink = styled.div`
    color: #EC1B2B;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    font-weight: bold;
`