import { addClass } from '@coopeuch-components/web';
import React from 'react';
// Utils
import Skeleton from 'react-loading-skeleton';
import { formatCurrency } from '../../../../utils/functions';
// Components
import CardBox from '@coopeuch-components/web/molecules/CardBox';

// Styles
import Composition from './styles';

const alphabet = ['a', 'b', 'c', 'd', 'e', 'f'];

const RemanenteGraphic = ({ loading, composicion, sinRemanente }) => {
  return (
    <CardBox id="grafico-remanente">
      <CardBox.Body>
        <div className="row bmb-3">
          <div className="col-xs-12">
            <p>
              <b>
                {loading ? (
                  <Skeleton width={300} />
                ) : (
                  'Composición de Mi Remanente con base en el monto'
                )}
              </b>
            </p>
          </div>
        </div>
        <Composition
          className="row"
          enableChart={!loading && composicion.length >= 1 && !sinRemanente}
        >
          <div className="col-md-6 col-sm-12 pb-16 w-100 text-center">
            {loading ? (
              <Skeleton circle height={290} width={290} />
            ) : (
              <div className="ct-chart-remanente" />
            )}
          </div>
          <div className="col-md-6 col-sm-12 w-100">
            <div className="remanente--wrapper-table">
              {loading ? (
                <Skeleton width={400} count={6} style={{ marginBottom: '1em' }} />
              ) : (
                <>
                  <div className="row">
                    <p className="col-xs-6 text-bold">Tipo de operación</p>
                    <p className="col-xs-3 text-bold">Base cálculo</p>
                    <p className="col-xs-3 end-xs text-bold">Monto</p>
                  </div>
                  {composicion.map((item, index) => (
                    <div key={alphabet[index]}>
                      <span className={`ct-series-${alphabet[index]} row m-0 w-100 middle-xs`}>
                        <p
                          className={addClass([
                            'col-xs-6 pl-8 pr-8',
                            item.tipoOperacion.toLowerCase() === 'total' && 'text-bold',
                          ])}
                        >
                          {item.tipoOperacion}
                        </p>
                        <p className="col-xs-3 p-0">{item.baseCalculo}</p>
                        <p
                          className={addClass([
                            'col-xs-3 p-0 end-xs',
                            item.tipoOperacion.toLowerCase() === 'total' && 'text-bold',
                          ])}
                        >
                          {formatCurrency(Math.round(item.monto) || Math.round(item.total))}
                        </p>
                      </span>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </Composition>
      </CardBox.Body>
    </CardBox>
  );
};

export default RemanenteGraphic;
