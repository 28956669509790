import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ButtonLoader = ({ icon, text }) => {
  return (
    <Wrapper>
      <div className="btnUpload">
        <LoadIcon src={`${iconPath}${icon}.svg`} color="black" size={25} />
        <p>{text}</p>
      </div>
    </Wrapper>
  );
};

export default ButtonLoader;
