import { log } from '@coopeuch-aws/logger';
import getOS from './getOS';
import getBrowser from './getBrowser';

async function logger(_error, _result) {
  try {
    const { sistemaOperativo, versionSo } = getOS();
    const token = localStorage.getItem('token');

    const params = {
      ..._result,
      sistema_operativo: sistemaOperativo,
      version_so: versionSo,
      canal: 'WEB',
      codigo_respuesta: _error?.code || '200',
      detalle_error: _error?.message || '',
      estado: !_error ? 'OK' : 'NOT OK',
      navegador: getBrowser()?.name,
    };
    log({
      data: params,
      headers: { Authorization: `Bearer ${token}` },
      urlBase: process.env.REACT_APP_LOGS,
    });
    console.log('debugger__ ', params)
  } catch (err) {
    console.error('No se pudo hacer el log: ', err);
  }
}

export default logger;
