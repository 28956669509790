import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Skeleton from 'react-loading-skeleton';
import Wrapper from './styles';

const HomeSkeleton = () => {
  return (
    <div>
      <Wrapper>
        <div className="row bmb-md-4 bmb-3">
          <div className="col-xs-12">
            <CardBox>
              <CardBox.Header>
                <Skeleton width={400} />
              </CardBox.Header>
              <CardBox.Body>
                <div className="row">
                  <div className="col-xs-4">
                    <Skeleton count={6} className="bmb-3" />
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
        <div className="row bmb-md-4 bmb-3">
          <div className="col-xs-12">
            <CardBox>
              <CardBox.Header>
                <Skeleton width={400} />
              </CardBox.Header>
              <CardBox.Body>
                <div className="row">
                  <div className="col-xs-4">
                    <Skeleton count={6} className="bmb-3" />
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default HomeSkeleton;
