import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const SuccessOrError = (props) => {
  const { icon, type, onClick, button } = props;

  return (
    <div className="row center-xs">
      <div className="col-md-8 col-sm-8">
        <div>
          <div className="row center-xs">
            <LoadIcon src={`${iconPath}${icon}.svg`} size={175} />
          </div>
          {type === 'success' ? (
            <Fragment>
              <Typography tag="p" fontSize={18} className="pb-0 pt-24 text-center lh-1_5 black">
                Has creado tu clave con éxito.
              </Typography>
              <Typography tag="p" fontSize={18} className="pb-24 pt-0 text-center lh-1_5 black">
                Accede con tu nueva clave de internet a Coopeuch en línea.
              </Typography>
            </Fragment>
          ) : (
            <Fragment>
              <Typography tag="p" fontSize={24} className="pt-12 text-center pb-24 text-bold black">
                ¡Lo sentimos!
              </Typography>
              <Typography tag="p" fontSize={18} className="pb-24 text-center lh-1_5 black">
                En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más
                tarde.
              </Typography>
            </Fragment>
          )}

          <Button
            rounded={5}
            bgColor="#e81d2b"
            color="white"
            onClick={onClick}
            width={274}
            shadow
            height={48}
          >
            {button}
          </Button>
        </div>
      </div>
      <div className="mt-30">
          <img src={`${staticPath}banner-invita-descarga-app.png`} 
          alt="descarga-app-coopeuch"
          />
      </div>
    </div>
  );
};

SuccessOrError.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string,
  type: PropTypes.oneOf(['success', 'error']),
  button: PropTypes.string,
};

SuccessOrError.defaultProps = {
  onClick: null,
  icon: '',
  type: '',
  button: '',
};

export default SuccessOrError;
