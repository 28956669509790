import React from 'react';
import PropTypes from 'prop-types';


import SpinnerLoader from '../../../atoms/SpinnerLoader';
import DashboardCard from '../../DashboardCard';
import Mobile from './Mobile';
import Web from './Web';

// Utils
import { generateUniqueKey } from '../../../../utils/functions';

// Styles
import { WrapperList, WrapperLoader } from './styles';

const assets = {
  default: {},
  realizadas: {
    icon: 'arrow_entra',
    color: '#C9A71C',
  },
  recibidas: {
    icon: 'arrow_sale',
    color: '#69C562',
  },
};

const ListaHistorial = (props) => {
  const { historial, isLoadingMoreItems, headers, onLoadMoreItems, title } =
    props;

  return (
    <WrapperList>
      <DashboardCard classes="dashboard-card-shadow">
        <DashboardCard.Head
          borderBottom="complete"
          title={title || 'Detalles de la Cuenta de Ahorro'}
        />
        <DashboardCard.Body classes="bpx-3 bpx-md-2rem bpy-4">
          <div
            dataLength={historial.length}
            next={onLoadMoreItems}
            loader={
              isLoadingMoreItems === true && (
                <WrapperLoader>
                  <SpinnerLoader />
                  <p className="black">Cargando movimientos</p>
                </WrapperLoader>
              )
            }
            hasMore
            endMessage={<div />}
          >
            <div>
              <div className="row ml-0 mr-0">
                <div className="col-xs-12 hide-on-mobile">
                  <div className="mb-14 black">
                    <div className="row">
                      <p className="text-bold pl-0 col-md-3">{headers.row_1}</p>
                      <p className="text-bold col-md-3">{headers.row_2}</p>
                      <p className="text-bold col-md-3 end-xs">{headers.row_3}</p>
                      <p className="text-bold pr-0 col-md-3 end-xs">{headers.row_4}</p>
                    </div>
                  </div>
                </div>
                {historial.map((historicalItem, i) => (
                  <div className="w-100" key={generateUniqueKey()}>
                    <div className="col-xs-12 pt-16 pb-16 line-divider-top hide-on-mobile">
                      <Web
                        {...historicalItem}
                      />
                    </div>
                    <div
                      className={
                        'col-xs-12 pb-16 hide-on-desktop ' + (i > 0 && 'line-divider-top pt-16')
                      }
                    >
                      <Mobile
                        {...historicalItem}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </WrapperList>
  );
};

ListaHistorial.propTypes = {
  onLoadMoreItems: PropTypes.func,
  historial: PropTypes.array,
  hasMore: PropTypes.bool,
  isLoadingMoreItems: PropTypes.bool,
  headers: PropTypes.shape({
    row_1: PropTypes.string,
    row_2: PropTypes.string,
    row_3: PropTypes.string,
    row_4: PropTypes.string,
  }),
};

ListaHistorial.defaultProps = {
  onLoadMoreItems: null,
  historial: [],
  hasMore: true,
  isLoadingMoreItems: false,
  headers: {
    row_1: '',
    row_2: '',
    row_3: '',
    row_4: '',
  },
};

export default ListaHistorial;
