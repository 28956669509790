import React from 'react';
import AlertModal from '../../atoms/AlertModal';
import { Text, WarningWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({ show, count, onCloseModal }) => {
  return (
    <AlertModal showModal={show} width="424" onClose={onCloseModal}>
      <div className="center-xs">
        <div className="row center-xs">
          <img src={`${iconPath}ALERTA-DESKTOP.svg`} alt="info-desktop" />
        </div>
        <Text className="bmb-2" textSize={18} size={24} weight="bold">
          ¡Aviso!
        </Text>
        <p className="bmb-3">Tu sesión se cerrará en {count} segundo(s)</p>
        <WarningWrapper className="bp-3" textSize={18} size={24} weight="bold" onClick={onCloseModal}>
          <p>Continuar navegando</p>
        </WarningWrapper>
      </div>
    </AlertModal>
  );
};

export default Index;
