import React, { Component } from 'react';
import produce from 'immer';
import { connect } from 'react-redux';

import { isValidEmail } from '../../../../utils/formatters';
import { downloadPDFBase64OtherTab } from '../../../../utils/functions';

import { migracionCuentaAhorroActions } from '../../../../../application/actions/migracionCuentaAhorro';

import MigracionCuentaAhorroContratacionTemplate from '../../../../components/templates/CuentaAhorro/Migracion/Contratacion';
import { cuentaAhorroActions } from '../../../../../application/actions/cuentaAhorro';

class MigracionCuentaAhorroContratacion extends Component {
  state = {
    checkTerminos: false,
    email: '',
    validMail: '',
    password: '',
    showPassword: false,
    errorInputPassword: '',
  };

  componentDidMount() {
    const { fetchContratosCuentaAhorro, correo, migracionIniciar } = this.props;
    const track = { track: migracionIniciar?.data.track}
    fetchContratosCuentaAhorro(track);

    let validMail = '';
    if (correo === '') {
      validMail = 'Campo obligatorio';
    } else {
      if (!isValidEmail(correo)) {
        validMail = 'Debes ingresar un email válido';
      }
    }
    this.setState(
      produce((draft) => {
        draft.email = correo;
        draft.validMail = validMail;
      })
    );
  }

  componentDidUpdate(prevProps) {
    const {
      isLoadingBorradorContratoCuentaAhorro,
      borradorCuentaAhorro,
      errorBorradorCuentaAhorro,
      isLoadingMigrarCuentaAhorro,
      errorMigrarCuentaAhorro,
      history,
    } = this.props;
    const { email } = this.state;
    if (prevProps.isLoadingBorradorContratoCuentaAhorro !== isLoadingBorradorContratoCuentaAhorro) {
      if (!isLoadingBorradorContratoCuentaAhorro) {
        if (!errorBorradorCuentaAhorro) {
          downloadPDFBase64OtherTab(borradorCuentaAhorro);
        }
      }
    }
    if (prevProps.isLoadingMigrarCuentaAhorro !== isLoadingMigrarCuentaAhorro) {
      if (!isLoadingMigrarCuentaAhorro) {
        if (!errorMigrarCuentaAhorro) {
          history.push({
            pathname: '/ecd/productos/cuenta-ahorro/migracion/comprobante',
            state: { correo: email },
          });
        }
      }
    }
  }

  componentWillUnmount = () => {
   this.resetMigracionIniciar();
  };

  resetMigracionIniciar = () => {
    const {
      fetchMigracionIniciarReset,
     } = this.props;
     fetchMigracionIniciarReset();
  }


  handleDescargarContrato = (nombreDocumento) => {
    const { fetchBorradorContratoCuentaAhorro, migracionIniciar } = this.props;
    const track =  migracionIniciar?.data.track;
    fetchBorradorContratoCuentaAhorro({ nombreDocumento, track });
  };

  handleCheckTerminos = () => {
    const { checkTerminos } = this.state;
    this.setState(
      produce((draft) => {
        draft.checkTerminos = !checkTerminos;
      })
    );
  };

  changeCorreo = (event) => {
    let validMail = '';
    if (event.target.value === '') {
      validMail = 'Campo obligatorio';
    } else {
      if (!isValidEmail(event.target.value)) {
        validMail = 'Debes ingresar un email válido';
      }
    }
    this.setState(
      produce((draft) => {
        draft.email = event.target.value;
        draft.validMail = validMail;
      })
    );
  };

  changePassword = (event) => {
    let error = '';

    if (event.target.value.length < 6) {
      error = 'La clave debe tener entre 6 y 8 caract.';
    }

    this.setState(
      produce((draft) => {
        draft.password = event.target.value;
        draft.errorInputPassword = error;
      })
    );
  };

  onTogglePassword = () => {
    const { showPassword } = this.state;
    this.setState(
      produce((draft) => {
        draft.showPassword = !showPassword;
      })
    );
  };

  handleActualizar = () => {
    const { migrarCuentaAhorro, cuenta } = this.props;
    const { password, email } = this.state;

    migrarCuentaAhorro({ clave: password, correo: email, numeroCuenta: cuenta?.numeroCuenta });
  };

  handleReturn = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleReturnRecuperar = () => {
    const { history } = this.props;
    this.resetMigracionIniciar();
    history.push('/recuperar-clave');
  };

  render() {
    const {
      isLoadingContratosCuentaAhorro,
      contratosCuentaAhorro,
      errorContratosCuentaAhorro,
      errorDataMigrarCuentaAhorro,
      isLoadingMigrarCuentaAhorro,
      errorBorradorCuentaAhorro,
    } = this.props;
    const { checkTerminos, email, validMail, password, showPassword, errorInputPassword } =
      this.state;
    return (
      <MigracionCuentaAhorroContratacionTemplate
        contratosCuentaAhorro={contratosCuentaAhorro}
        isLoadingContratosCuentaAhorro={isLoadingContratosCuentaAhorro}
        errorContratosCuentaAhorro={errorContratosCuentaAhorro}
        errorBorradorCuentaAhorro={errorBorradorCuentaAhorro}
        checkTerminos={checkTerminos}
        email={email}
        validMail={validMail}
        password={password}
        showPassword={showPassword}
        errorPassword={errorInputPassword}
        errorServiceMigrar={errorDataMigrarCuentaAhorro}
        isLoadingMigrarCuentaAhorro={isLoadingMigrarCuentaAhorro}
        onTogglePassword={this.onTogglePassword}
        handleDescargarContrato={this.handleDescargarContrato}
        handleCheckTerminos={this.handleCheckTerminos}
        changeCorreo={this.changeCorreo}
        changePassword={this.changePassword}
        handleActualizar={this.handleActualizar}
        handleReturn={this.handleReturn}
        handleReturnRecuperar={this.handleReturnRecuperar}
      />
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  contratosCuentaAhorro: entities.migracionCuentaAhorro.contratosCuentaAhorro,
  isLoadingContratosCuentaAhorro: ui.loaders.isLoadingContratosCuentaAhorro,
  isLoadingBorradorContratoCuentaAhorro: ui.loaders.isLoadingBorradorContratoCuentaAhorro,
  borradorCuentaAhorro: entities.migracionCuentaAhorro.borradorCuentaAhorro,
  errorContratosCuentaAhorro: entities.migracionCuentaAhorro.errorContratosCuentaAhorro,
  correo: entities.persona.correo,
  errorBorradorCuentaAhorro: entities.migracionCuentaAhorro.errorBorradorCuentaAhorro,
  migrarCuentaAhorro: entities.migracionCuentaAhorro.migrarCuentaAhorro,
  isLoadingMigrarCuentaAhorro: ui.loaders.isLoadingMigrarCuentaAhorro,
  errorDataMigrarCuentaAhorro: entities.migracionCuentaAhorro.errorDataMigrarCuentaAhorro,
  errorMigrarCuentaAhorro: entities.migracionCuentaAhorro.errorMigrarCuentaAhorro,
  migracionIniciar: entities.cuentasAhorro.migracionIniciar,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContratosCuentaAhorro(props) {
    dispatch(migracionCuentaAhorroActions.fetchContratosCuentaAhorro(props));
  },
  fetchBorradorContratoCuentaAhorro(props) {
    dispatch(migracionCuentaAhorroActions.fetchBorradorContratoCuentaAhorro(props));
  },
  migrarCuentaAhorro(props) {
    dispatch(migracionCuentaAhorroActions.migrarCuentaAhorro(props));
  },
  fetchMigracionIniciarReset() {
    dispatch(cuentaAhorroActions.fetchMigracionIniciarReset());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MigracionCuentaAhorroContratacion);
