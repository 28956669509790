import React from 'react';
import Alert from '@coopeuch-components/web/atoms/Alert';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Stepper from '../../../../organism/Stepper';
import StepsActions from '../../../../organism/Stepper/StepsActions';

import Wrapper from './styles';
import { migracionCuentaAhorroComprobanteTexts } from '../../../../../containers/CuentaAhorro/texts';
import { dateFormat, patternDateFormat, patternSimpleDateFormat } from '../../../../../utils/dates';
import { capitalize } from '../../../../../utils/functions';

const steps = ['Datos Cuenta', 'Contratación', 'Comprobante'];
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const MigracionCuentaAhorroComprobanteTemplate = ({
  correo,
  handleReturnCuentaAhorro,
  fechaMigracion,
  migrarCuentaAhorro,
}) => {
  return (
    <Wrapper className="bpx-3 bpx-md-5 bmt-md-0 bmt-3">
      <Stepper className="start-xs bmt-md-4 bmt-3" position={3} steps={steps} />
      <div className="bmt-4 bmb-4">
        <Alert show bgType="success">
          {migracionCuentaAhorroComprobanteTexts.SUCCESS_MESSAGE}
        </Alert>
      </div>
      <CardBox className="bmt-4">
        <CardBox.Header>
          {migracionCuentaAhorroComprobanteTexts.CARD_COMPROBANTE_TITLE}
        </CardBox.Header>
        <CardBox.Body>
          <div className="row m-0">
            <div className="col-md-9 col-xs-12 p-0">
              <div className="bmb-2">
                <label className="comprobante-title-texts">
                  {migracionCuentaAhorroComprobanteTexts.CARD_COMPROBANTE_SUBTITLE}
                </label>
              </div>
              <div className="bmb-2">
                <label className="comprobante-numero-texts">
                  {migracionCuentaAhorroComprobanteTexts.CARD_COMPROBANTE_NUM}
                  {migrarCuentaAhorro?.numeroCuenta}
                </label>
              </div>
              <div>
                <label className="comprobante-desc-texts">
                  {migracionCuentaAhorroComprobanteTexts.CARD_COMPROBANTE_DESC}
                  {migrarCuentaAhorro?.producto?.descripcion}
                  {migracionCuentaAhorroComprobanteTexts.CARD_COMPROBANTE_DESC_1}
                  {capitalize(patternSimpleDateFormat(fechaMigracion, 'dd MMMM u'))}
                  {migracionCuentaAhorroComprobanteTexts.CARD_COMPROBANTE_DESC_2}
                  <b>{correo}</b>
                </label>
              </div>
            </div>
            <div className="col-md-3 col-xs-12 p-0 bmt-3 bmt-md-0 icon-sello-container">
              <LoadIcon src={`${iconPath}icon_sello.svg`} />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmt-4">
        <CardBox.Header>{migracionCuentaAhorroComprobanteTexts.CARD_DETALLE_TITLE}</CardBox.Header>
        <CardBox.Body>
          <div>
            <div className="row m-0 comprobante-card-list card-impar">
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {migracionCuentaAhorroComprobanteTexts.CARD_DETALLE_PRODUCTO}
                </label>
              </div>
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {migrarCuentaAhorro?.producto?.descripcion}
                </label>
              </div>
            </div>
            <div className="row m-0 comprobante-card-list card-par">
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {migracionCuentaAhorroComprobanteTexts.CARD_DETALLE_NUMERO}
                </label>
              </div>
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">{migrarCuentaAhorro?.numeroCuenta}</label>
              </div>
            </div>
            <div className="row m-0 comprobante-card-list card-impar">
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {migracionCuentaAhorroComprobanteTexts.CARD_DETALLE_FECHA}
                </label>
              </div>
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {patternDateFormat(migrarCuentaAhorro?.fechaApertura, 'dd MMM u')}
                </label>
              </div>
            </div>
            <div className="row m-0 comprobante-card-list card-par">
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {migracionCuentaAhorroComprobanteTexts.CARD_DETALLE_ESTADO}
                </label>
              </div>
              <div className="col-md-6 col-xs-12 p-0">
                <label className="comprobante-card-text">
                  {migrarCuentaAhorro?.estado?.descripcion}
                </label>
              </div>
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <div className="bmt-4 bmb-4">
        <StepsActions
          nextText={migracionCuentaAhorroComprobanteTexts.BOTON_ACTUALIZAR}
          nextAction={handleReturnCuentaAhorro}
          hideBackAction={true}
        />
      </div>
    </Wrapper>
  );
};

export default MigracionCuentaAhorroComprobanteTemplate;
