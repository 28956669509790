import React, { PureComponent, Fragment, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import produce from 'immer';

import { productActions } from '../../../../application/actions/products';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';

import PageTitle from '../../../components/atoms/PageTitle';
import { registerDY } from '../../../utils/metrics';

const MisTarjetas = lazy(() => import('./MisTarjetas'));
const Movimientos = lazy(() => import('./Movimientos'));

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const tabs = {
  0: 'misTarjetas',
  1: 'movimientos',
};

class TarjetaDeCreditoHome extends PureComponent {
  state = {
    tab: 'misTarjetas',
  };

  onTabsHandle = (position) => {
    this.setState(
      produce((draft) => {
        draft.tab = tabs[position];
      })
    );
  };

  componentDidMount() {
    this.props.fetchCuentasVista({
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A'],
    });
    registerDY({ type: 'PRODUCT', data: ['tarjeta_creditos'] });
  }

  render() {
    const { tab } = this.state;

    return (
      <Fragment>
        <PageTitle
          title="Tarjeta de Crédito"
          icon={<LoadIcon src={`${iconPath}money_exchange.svg`} color="#333" />}
        />
        <div className="w-100 margin-after-title">
          <Tabs
            bgColor="#fff"
            fontSize={15}
            fontFamily="Ubuntu-Regular"
            activeColor="#e81e2b"
            className="pl-54 tab--wrapper-shadows tabs full-tab"
          >
            <Tabs.Header className="tabs-header-transferencia">
              <Tab callback={this.onTabsHandle} className="tab-style" id="mis-tarjetas">
                Mis Tarjetas
              </Tab>
              <Tab callback={this.onTabsHandle} className="tab-style w-150p" id="movimientos">
                Movimientos
              </Tab>
            </Tabs.Header>
            <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
              <Fragment>
                <Tabs.Body>
                  <Suspense fallback={<div />}>{tab === 'misTarjetas' && <MisTarjetas />}</Suspense>
                  <Suspense fallback={<div />}>{tab === 'movimientos' && <Movimientos />}</Suspense>
                </Tabs.Body>
              </Fragment>
            </div>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
});

export default connect(null, mapDispatchToProps)(TarjetaDeCreditoHome);
