import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Skeleton from 'react-loading-skeleton';
import Domicilio from './Domicilio';
import RetiroSucursal from './RetiroSucursal';
import StepActions from '../../../../../organism/Stepper/StepsActions';

// Utils
import { phoneWithTwoSpaces } from '../../../../../../utils/formatters';

// Styles
import TabsWrapper, { Wrapper } from './styles';

const Index = ({
  fetchingData,
  regionOptions,
  comunaOptions,
  isLoadingSucursalesComuna,
  sucursalOptions,
  region,
  comuna,
  notSucursal,
  sucursal,
  calle,
  numeroCasa,
  otrosCasa,
  poseeFactibilidad,
  despachoDomicilio,
  changeTab,
  loading,
  handleOnChangeInput,
  handleOnChangeRegion,
  handleOnChangeComuna,
  handleOnChangeSucursal,
  backAction,
  nextAction,
  persona,
  isLoadingPersona,
}) => {
  return (
    <Wrapper>
      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>
              {isLoadingPersona ? <Skeleton width={200} /> : 'Datos de Contacto'}
            </CardBox.Header>
            <CardBox.Body>
              {isLoadingPersona ? (
                <Skeleton />
              ) : (
                <Typography
                  tag="h2"
                  fontSize={16}
                  fontFamily="ubuntu-regular"
                  align="left"
                  className="descrip_datos"
                >
                  Para agendar el despacho de tus tarjetas y en caso de cualquier eventualidad nos
                  contactaremos contigo al siguiente número telefónico:
                </Typography>
              )}

              <div className="row mt-16">
                {isLoadingPersona ? (
                  <div className="col-xs-12 col-md-4">
                    <Skeleton width={100} />
                    <Skeleton width={200} className="mt-10" />
                  </div>
                ) : (
                  <div className="col-xs-12 col-md-4">
                    <Typography tag="h2" fontSize={16} fontFamily="ubuntu-bold" align="left">
                      Nombre
                    </Typography>
                    <Typography tag="h2" fontSize={16} fontFamily="ubuntu-regular" align="left">
                      {`${persona?.primerNombre} ${persona?.apellidoPaterno} ${persona?.apellidoMaterno}`}
                    </Typography>
                  </div>
                )}

                {isLoadingPersona ? (
                  <div className="col-xs-12 col-md-4">
                    <Skeleton width={100} />
                    <Skeleton width={200} className="mt-10" />
                  </div>
                ) : (
                  <div className="col-xs-12 col-md-4">
                    <Typography tag="h2" fontSize={16} fontFamily="ubuntu-bold" align="left">
                      Teléfono
                    </Typography>
                    <Typography tag="h2" fontSize={16} fontFamily="ubuntu-regular" align="left">
                      {phoneWithTwoSpaces(persona?.telefono)}
                    </Typography>
                  </div>
                )}

                {isLoadingPersona ? (
                  <div className="col-xs-12 col-md-4">
                    <Skeleton width={100} />
                    <Skeleton width={200} className="mt-10" />
                  </div>
                ) : (
                  <div className="col-xs-12 col-md-4">
                    <Typography tag="h2" fontSize={16} fontFamily="ubuntu-bold" align="left">
                      Email
                    </Typography>
                    <Typography tag="h2" fontSize={16} fontFamily="ubuntu-regular" align="left">
                      {persona?.correo}
                    </Typography>
                  </div>
                )}

                <div className="col-xs-12 mt-20">
                  {!persona?.telefono ? (
                    <Alert
                      className="alert-info col-xs bpy-md-3 "
                      bgType="info"
                      id="alert-factibilidad-ok"
                      show
                    >
                      <b>No tienes teléfono registrado, </b>
                      llámanos al Contact Center <b>600 200 1200</b>
                    </Alert>
                  ) : (
                    <Alert
                      className="alert-info col-xs bpy-md-3 "
                      bgType="info"
                      id="alert-factibilidad-ok"
                      show
                    >
                      <b>Si tu email o teléfono no corresponde </b>
                      llámanos al Contact Center <b>600 200 1200</b>
                    </Alert>
                  )}
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>

      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>
              {fetchingData ? <Skeleton width={200} /> : 'Despacho de tus tarjetas'}
            </CardBox.Header>
            <CardBox.Body>
              {fetchingData ? (
                <Skeleton />
              ) : (
                'Tu Cuenta Vista incluye una Tarjeta de Débito y Tarjeta de Coordenadas Coopeuch, indícanos dónde las recibirás.'
              )}
            </CardBox.Body>
          </CardBox>
        </div>
      </div>

      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          <TabsWrapper>
            <Tabs
              bgColor="#fff"
              fontSize={16}
              fontFamily="Ubuntu-Medium"
              activeColor="#e81e2b"
              className="tab-header tabs"
            >
              {fetchingData ? (
                <div className="row bmt-5">
                  <div className="col-xs-12">
                    <Skeleton width={50} />
                    <Skeleton width={50} />
                  </div>
                </div>
              ) : (
                <Tabs.Header>
                  <Tab className="tab-style w-auto" callback={changeTab} fontSize={16}>
                    Despacho a Domicilio
                  </Tab>
                  <Tab className="tab-style w-auto" callback={changeTab} fontSize={16}>
                    Retiro en Sucursal
                  </Tab>
                </Tabs.Header>
              )}

              <div className="tab-body w-100 text-left">
                <Tabs.Body>
                  <CardBox className="cardbox-tab w-100">
                    <CardBox.Body>
                      <Domicilio
                        {...{
                          fetchingData,
                          region,
                          comuna,
                          regionOptions,
                          comunaOptions,
                          calle,
                          numeroCasa,
                          otrosCasa,
                          poseeFactibilidad,
                          handleOnChangeInput,
                          handleOnChangeRegion,
                          handleOnChangeComuna,
                        }}
                      />
                    </CardBox.Body>
                  </CardBox>
                  <CardBox className="cardbox-tab w-100">
                    <CardBox.Body>
                      <RetiroSucursal
                        {...{
                          fetchingData,
                          region,
                          regionOptions,
                          comuna,
                          comunaOptions,
                          notSucursal,
                          sucursal,
                          sucursalOptions,
                          isLoadingSucursalesComuna,
                          handleOnChangeComuna,
                          handleOnChangeRegion,
                          handleOnChangeSucursal,
                        }}
                      />
                    </CardBox.Body>
                  </CardBox>
                </Tabs.Body>
              </div>
            </Tabs>
          </TabsWrapper>
        </div>
      </div>
      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          <StepActions
            {...{ backAction, nextAction, loading }}
            disableNextAction={
              despachoDomicilio
                ? !(!!region && !!comuna && !!calle && !!numeroCasa && !!poseeFactibilidad)
                : !(!!region && !!sucursal)
            }
            backText="Volver"
          />
        </div>
      </div>
    </Wrapper>
  );
};
Index.displayName = 'Template_Datos';
export default Index;
