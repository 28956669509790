import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Table from '@coopeuch-components/web/molecules/Table';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { agregarformatoPesos } from '../../../../../utils/formatters';
import Wrapper from './styles';
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const comprobante = `${staticPath}sello-comprobante.png`;
const Index = ({ detallePago, backButton, nextButton }) => {
  const payData = [
    {
      id: 0,
      name: 'Cuenta Origen',
      value: detallePago.tipoCuenta,
    },
    {
      id: 1,
      name: 'N° de cuenta',
      value: detallePago.numeroCuenta,
    },
    {
      id: 2,
      name: 'Fecha de pago',
      value: detallePago.fechaPago,
    },
    {
      id: 3,
      name: 'Valor de la cuota',
      value: agregarformatoPesos(detallePago.valorCuota),
    },
    {
      id: 3,
      name: 'Cuotas pagadas',
      value: detallePago.cuotasPagadas,
    },
  ];
  return (
    <Wrapper>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <Alert className="bp-3" bgType="success" id="alert-felicitaciones-pago-cdp" show>
            Pago exitoso.
          </Alert>
        </div>
      </div>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <CardBox className="bmb-3 bmb-md-4">
            <CardBox.Header>Comprobante</CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12 col-md-8 bmb-md-3 comprobante-card-text d-flex middle-items">
                  <div>
                    <Typography tag="p" fontWeight="bold" fontSize={14}>
                      Monto Pagado
                    </Typography>
                    <Typography tag="p" fontWeight="bold" fontSize={24}>
                      <b> {agregarformatoPesos(detallePago.montoPagado)}</b>
                    </Typography>
                    {!!detallePago.correo && (
                      <Typography tag="p" fontSize={14}>
                        Te enviamos copia de este comprobante a tu email:{' '}
                        <b>{detallePago.correo}</b>.
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <img
                    className="sello-comprobante-pago-agua bml-sm-0 bmr-md-0"
                    src={comprobante}
                    alt="comprobante"
                  />
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>Detalle de la Capitalización</CardBox.Header>
            <CardBox.Body>
              <Table
                borderColor="trasparent"
                fullWidth
                alignContent="left"
                stripeColor="#F6F3F5"
                stripeReverse
              >
                <Table.Body
                  data={payData}
                  renderItems={(item, TR, TD) => {
                    return (
                      <TR key={`detalle-contrato-monedero-${item.id}`}>
                        <TD className="td-table-element-capitalizacion pt-16 pb-16 pl-8 pr-8">
                          {item.name}
                        </TD>
                        <TD className="td-table-element-capitalizacion pt-16 pb-16 pl-8 pr-8">
                          {item.value}
                        </TD>
                      </TR>
                    );
                  }}
                ></Table.Body>
              </Table>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12">
          <StepsActions
            backAction={backButton}
            backText={'Ir a home'}
            nextAction={nextButton}
            nextText="Ir a Cuota de Participación"
            id="pago-cdp-paso-3"
          />{' '}
        </div>
      </div>
    </Wrapper>
  );
};

export default Index;
