import styled from 'styled-components';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Wrapper = styled.div`
  .container_cards {
    display: flex;
    flex: wrap;
    justify-content: space-between;
  }

  .align_title {
    display: flex;
    align-items: center;
    .title_card {
      font-size: 18px;
    }
  }
  .badge {
    border-radius: 4px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }
  .text_button {
    font-size: 14;
    font-family: 'Ubuntu-Medium';
  }
  .cardbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .button_bottom {
    margin-top: 194px;
  }
  @media (max-width: 768px) {
    .button_bottom {
      margin-top: 0px;
    }
  }
`;

export const CustomList = styled.ul`
  padding: 0px 10px;

  & > li {
    font-size: 16px;
    line-height: 26px;
    position: relative;
    margin-left: 16px;
    margin-bottom: 6px;

    &::before {
      background-image: url(${({ img }) => img}) !important;
      background-size: contain;
      background-repeat: no-repeat;

      position: absolute;
      content: '';
      height: 18px;
      width: 18px;
      display: inline-block;
      left: -26px;
      top: 4px;
    }
  }
`;
export const ExpandHeaderWrapper = styled.div`
  padding-left: 32px;
  font-size: 20px;
  & > svg {
    fill: black;
  }
`;
export const BannerWeb = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${staticPath}banner_sloicita_cuentavista_v1.png);
  border-radius: 4px;
  padding: 32px;
  padding-top: 24px;

  .banner-actions {
    display: flex;
    align-items: center;
    max-width: 500px;
  }

  .button-hide {
    visibility: hidden;
  }
`;

export const BannerMobile = styled.div`
  height: 365px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${staticPath}banner_solicita_cuentavista_v2.png);
  padding: 26px;
  padding-top: 32px;

  margin-top: -20px;
  margin-left: -18px;
  margin-right: -18px;

  button {
    filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.35)) !important;
  }

  .button-hide {
    visibility: hidden;
  }

  .banner-actions {
    display: flex;
    align-items: center;
  }
`;

export default Wrapper;
