import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import produce from 'immer';

import { depositoAPlazoActions } from '../../../application/actions/depositoAPlazo';
import { productActions } from '../../../application/actions/products';

import StepperRouter from './StepperRouter';
import { listaNegraActions } from '../../../application/actions/listaNegra';
import { configuracionActions } from '../../../application/actions/configuracion';
import { registerDY, registerGA } from '../../utils/metrics';
import { loguearRutUsuario } from '../../utils/formateoParametriaLog';

class DepositoAPlazo extends PureComponent {
  state = {
    simulacion: {
      tipo: 'renovable',
      monto: null,
      plazo: 7,
    },
    tarjetaActiva: 0,
    simulacionSeleccionada: {},
  };

  handleInfoSimulacion = (key, value) => {
    this.setState(
      produce((draft) => {
        draft.simulacion[key] = value;
      })
    );
  };

  handleChangeTarjetaActiva = (opcion) => {
    this.setState(
      produce((draft) => {
        draft.tarjetaActiva = opcion;
      })
    );
  };

  handleChangeSimulacionSeleccionada = (simulacion) => {
    this.setState(
      produce((draft) => {
        draft.simulacionSeleccionada = simulacion;
      })
    );
  };

  componentDidMount() {
    this.props.resetDAP();
    this.props.fetchListaNegra();
    this.props.fetchDepositosContratados({ estado: 'vigente' });
    this.props.fetchObtenerMantencion();
    this.props.fetchCuentasVista({
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A'],
    });

    registerGA({ event: 'vpv-privado', page: '/tef/mis-productos/deposito-a-plazo' });
    registerDY({ type: 'PRODUCT', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP-INGRESO-INFORMACION');
  }

  componentDidUpdate(nextProps) {
    if (nextProps.location !== this.props.location) {
      if (this.props.location.pathname === '/ecd/productos/deposito-a-plazo') {
        this.props.resetDAP();
        this.props.fetchListaNegra();
        this.props.fetchDepositosContratados({ estado: 'vigente' });
        this.props.fetchObtenerMantencion();
        this.props.fetchCuentasVista({
          codigoProducto: ['VI01', 'VI03', 'VI06'],
          estadoCuenta: ['A'],
        });
        if (nextProps.location.pathname !== '/ecd/productos/deposito-a-plazo/simulacion') {
          this.handleInfoSimulacion('tipo', 'renovable');
          this.handleInfoSimulacion('monto', null);
          this.handleInfoSimulacion('plazo', 7);
        }
      }
    }
  }

  render() {
    return (
      <StepperRouter
        {...this.props}
        {...this.state}
        handleInfoSimulacion={this.handleInfoSimulacion}
        handleChangeTarjetaActiva={this.handleChangeTarjetaActiva}
        handleChangeSimulacionSeleccionada={this.handleChangeSimulacionSeleccionada}
      />
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  depositosContratados: entities.depositoAPlazo.depositosContratados,
  depositosContratadosError: entities.depositoAPlazo.depositosContratadosError,
  isFetchedDepositosContratados: entities.depositoAPlazo.isFetchedDepositosContratados,
  isLoadingDepositosContratados: ui.loaders.isLoadingDepositosContratados,
  cuentasVista: entities.products.cuentaVista.balance,
  isLoadingCuentasVista: ui.loaders.isLoadingCuentasVista,
  errorCuentasVistas: entities.products.errorCuentasVistas,
  listaNegra: entities.listaNegra,
  mantencion: entities.configuracion.mantencion.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDepositosContratados(props) {
    dispatch(depositoAPlazoActions.fetchDepositosContratados(props));
  },
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
  resetDAP() {
    dispatch(depositoAPlazoActions.resetDAP());
  },
  fetchListaNegra() {
    dispatch(listaNegraActions.fetchListaNegraConsultar());
  },
  fetchObtenerMantencion() {
    dispatch(configuracionActions.fetchObtenerMantencion());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DepositoAPlazo));
