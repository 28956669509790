import React from 'react';
// Components
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
// Organism
import DashboardCard from '../../../organism/DashboardCard';
import { colors } from '../../../../assets/styles';
// Texts
import { puntosCoopeuchTexts } from '../../../../containers/Dashboard/texts';
// Styles
import Wrapper from './styled';
// Formater
import { agregarformatoPuntos, datePuntosCoopeuch } from '../../../../utils/formatters';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SocioPuntos = ({ puntosObtener, handleClickCanjear }) => {
  return (
    <div>
      <Wrapper>
        <DashboardCard className="dashboard-card-shadow">
          <DashboardCard.Body classes="dashboard-puntos dashboard-custom">
            <div className="dashboard-puntos-body">
              <div className="dashboard-puntos__icon">
                <LoadIcon
                  src={`${iconPath}icon_puntoscoo_dashboard.svg`}
                  size={30}
                  color="#1E1E1E"
                  className="access-icon"
                />
                <p className="dashboard-puntos__title">
                  Tienes{' '}
                  <strong>
                    {agregarformatoPuntos(puntosObtener?.data?.puntosDisponibles)} Puntos Coopeuch
                  </strong>
                </p>
                <div className="dashboard-puntos__puntos-clp">
                  <label>{puntosCoopeuchTexts.PTSCLP}</label>
                </div>
                <div className="dashboard-puntos__puntos-date">
                  <label>
                    El {datePuntosCoopeuch(puntosObtener?.data?.fechaPuntosPorVencerProyectados)}{' '}
                    vencen
                    <strong>
                      {' '}
                      {agregarformatoPuntos(puntosObtener?.data?.puntosPorVencerProyectados)}
                    </strong>{' '}
                    puntos
                  </label>
                </div>
              </div>
              <div className="dashboard-puntos__info">
                <Button
                  onClick={handleClickCanjear}
                  asTextLink
                  iconRight={
                    <LoadIcon src={`${iconPath}arrow_right.svg`} color={colors.red} size={22} />
                  }
                  className="btnPuntos"
                  color={colors.red}
                >
                  {puntosCoopeuchTexts.CANJEAR}
                </Button>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </Wrapper>
    </div>
  );
};

export default SocioPuntos;
