import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Containers
import Caracteristicas from '../../../../components/templates/CuentaVista/Solicita/SolicitaMonederoFlujo/Caracteristicas';
import ContratosYFirma from '../../../../components/templates/CuentaVista/Solicita/SolicitaMonederoFlujo/ContratoYFirma';
import Comprobante from '../../../../components/templates/CuentaVista/Solicita/SolicitaMonederoFlujo/Comprobante';

// templates
import WrapperTemplate from '../../../../components/templates/CuentaVista/Solicita/SolicitaMonederoFlujo/Wrapper';

const components = {
  1: Caracteristicas,
  2: ContratosYFirma,
  3: Comprobante,
};

const StepperRouter = (props) => {
  const { pathStep } = props;

  const Component = useMemo(() => components[pathStep]);

  return (
    <WrapperTemplate position={pathStep}>
      <Component {...props} />
    </WrapperTemplate>
  );
};

StepperRouter.propTypes = {
  pathStep: PropTypes.oneOf([1]).isRequired,
  ...Caracteristicas.propTypes,
  ...ContratosYFirma.propTypes,
  ...Comprobante.propTypes,
};

StepperRouter.defaultProps = {
  ...Caracteristicas.defaultProps,
  ...ContratosYFirma.propTypes,
  ...Comprobante.propTypes,
};

export default StepperRouter;
