import styled from 'styled-components';

export default styled.div`
  .content-dap {
    width: 100%;
    text-align: left;
    padding: 24px 3rem 24px 3rem;
  }

  .dap--card {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .dap-top--card {
    margin-top: 24px;
  }

  .alert--wrapper {
    height: auto;
    width: 100%;
    align-items: center;

    .right-content {
      align-items: center;
    }
  }

  .cv--resumen--seleccion-simulacion {
    height: 48px;
    padding: 12px;
    border: 1px solid #646569;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }

  .editar-destinatarios--password-wrapper {
    position: relative;
    height: 68px;

    .lock-icon {
      position: absolute;
      z-index: 2;
      top: 13px;
      left: 5px;
    }

    .eye-icon {
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .help-text {
      position: absolute;
      left: 0;
      bottom: 0px;
    }

    .error-warning-password {
      position: absolute;
      left: 0;
      bottom: 0px;

      & svg {
        fill: #bf0338;

        path {
          fill: #bf0338;
        }
      }
    }
  }

  #liquidacion-confirmar-next-action {
    width: 178px;
  }

  #liquidacion-confirmar-prev-action {
    align-items: center;
  }

  @media screen and (max-width: 766px) {
    .content-dap {
      padding: 1rem 1rem 1rem 1rem;
    }

    .dap--card {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .dap-top--card {
      margin-top: 16px;
    }

    .dap--card--content {
      padding: 16px !important;
    }

    .saldo--dap {
      padding-top: 8px;
    }

    #dap-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }

    .alert--wrapper {
      padding-left: 14px !important;

      .right-content {
        align-items: start;

        & > div:first-child {
          width: 30px !important;
          height: 40px !important;
        }

        div,
        p {
          text-align: start;
        }

        svg {
          width: 20px;
          height: 30px;
        }
      }
    }

    .pl-32,
    .pl-24 {
      padding-left: 16px !important;
    }

    .pr-32,
    .pr-24 {
      padding-right: 16px !important;
    }

    #liquidacion-confirmar-next-action,
    .step-action-button-wrapper {
      width: 100%;
    }
  }
`;
