import React from 'react';

import Stepper from '../../../../components/organism/Stepper';
import CardPassCoopeuch from '../../../organism/ActivacionTarjetasKit/Autorizacion/CardPassCoopeuch';
import CardSMS from '../../../../components/organism/ActivacionTarjetasKit/Autorizacion/CardSMS';
import FormSMS from '../../../organism/ActivacionTarjetasKit/Autorizacion/FormSMS';
import CardBlock from '../../../organism/ActivacionTarjetasKit/Error/CardBlock';
// Styles
import Wrapper from './styles';

const AutorizacionTemplate = ({
  steps,
  counterActive,
  setCounterActive,
  handleClickSendSMS,
  errorTimeOut,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  retryError,
  handleSetSmsCode,
  authorizeBtn,
  handleClickValidateSMS,
  smsCode,
  validateSMS,
  smsCodeOne,
  smsCodeTwo,
  smsCodeThree,
  smsCodeFour,
  smsCodeFive,
  smsCodeSix,
  persona,
  timerValue,
  backToActivacion,
  switchPassCoopeuchOrSms,
  handleClickAuthorizePassCoopeuch,
  retryTimeOutPass,
  validatePassCoopeuch,
  IsValidatePassCoopeuch,
  IsLoading,
  isVisibleModalError,
  handleClickCloseModalError,
  IsAvailablePassCoopeuch,
  handleSwitchMethods,
  handleClickDownloadApp
}) => {
  return (
    <Wrapper>
      <Stepper className="start-xs" position={2} steps={steps} />
      {smsCode.error?.code === 'KIT-04' && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="EL código SMS ha sido bloqueado. Por favor, comunícate con nuestro Contact Center al 600 200 1200."
          link="/ecd/inicio"
          msgErrorGA="Clave dinámica SMS Bloqueada"
          pageGA="/tef/seguridad/activacionTarjetas/paso2"
        />
      )}
      {validateSMS.data?.bloqueado === true && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="EL código SMS ha sido bloqueado. Por favor, comunícate con nuestro Contact Center al 600 200 1200."
          link="/ecd/inicio"
          msgErrorGA="Clave dinámica SMS Bloqueada"
          pageGA="/tef/seguridad/activacionTarjetas/paso2"
        />
      )}

      {smsCode.error?.statusCode === 500 && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
          pageGA="/tef/seguridad/activacionTarjetas/paso2"
        />
      )}

      {validateSMS.error?.statusCode === 500 && (
        <CardBlock
          section="Autoriza con Clave Dinámica SMS"
          title="¡Lo sentimos!"
          message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          link="/ecd/inicio"
          msgErrorGA="Servicio no disponible"
          pageGA="/tef/seguridad/activacionTarjetas/paso2"
        />
      )}

      {switchPassCoopeuchOrSms ? (
        <CardPassCoopeuch
          {...{
            timerValue,
            errorTimeOut,
            validateSMS,
            handleClickAuthorizePassCoopeuch,
            counterActive,
            setCounterActive,
            setErrorTimeOut,
            setFactorErrorTimeOut,
            authorizeBtn,
            retryTimeOutPass,
            validatePassCoopeuch,
            IsValidatePassCoopeuch,
            backToActivacion,
            IsLoading,
            isVisibleModalError,
            handleClickCloseModalError,
            IsAvailablePassCoopeuch,
            handleSwitchMethods,
            handleClickDownloadApp
          }}
        />
      ) : (
        !counterActive &&
        smsCode.error?.code !== 'KIT-04' &&
        smsCode.data !== true &&
        validateSMS.error === null &&
        smsCode.error?.statusCode !== 500 && (
          <CardSMS
            {...{
              handleClickSendSMS,
              errorTimeOut,
              persona,
              backToActivacion,
              handleSwitchMethods,
            }}
          />
        )
      )}
      {smsCode.data === true &&
        validateSMS.data?.bloqueado !== true &&
        validateSMS.error === null && (
          <FormSMS
            {...{
              counterActive,
              setErrorTimeOut,
              setFactorErrorTimeOut,
              errorTimeOut,
              retryError,
              handleSetSmsCode,
              authorizeBtn,
              handleClickValidateSMS,
              validateSMS,
              smsCodeOne,
              smsCodeTwo,
              smsCodeThree,
              smsCodeFour,
              smsCodeFive,
              smsCodeSix,
              persona,
              timerValue,
              backToActivacion,
            }}
          />
        )}
    </Wrapper>
  );
};

export default AutorizacionTemplate;
