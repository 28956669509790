import CryptoJS from 'react-native-crypto-js';
import logger from './logger';

export const downloadPDFBase64 = (data, filename) => {
  const linkSource = `data:application/pdf;base64,${data}`;
  const downloadLink = document.createElement('a');
  const fileName = `${filename}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.target = '_blank';
  downloadLink.rel = 'noopener noreferrer';
  downloadLink.download = fileName;
  downloadLink.click();
};
export const setCookie = (name, value) => {
  const expires = new Date();
  expires.setTime(expires.getTime() + 5 * 60 * 1000);
  document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
};
export function downloadPDFBase64OtherTab(base64, filename) {
  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  const blob = dataURItoBlob(base64);
  const url = URL.createObjectURL(blob);

  window.open(url, '_blank');
}

const currencyFormatter = (num, fix) => {
  var p = Number(num).toFixed(fix).split('.');
  return (
    '$' +
    (p[0].split('').reduceRight(function (acc, num, i, orig) {
      if ('-' === num && 0 === i) {
        return num + acc;
      }
      var pos = orig.length - i - 1;
      return num + (pos && !(pos % 3) ? '.' : '') + acc;
    }, '') +
      (p[1] ? ',' + p[1] : ''))
  );
};

export const formatCurrency = (value, fix = 0) => {
  // return currencyFormatter(value, fix);
  const regExp = /\B(?=(\d{3})+(?!\d))/g;
  const amount = String(value).replace(regExp, '.');

  return value ? (value < 0 ? `-$${amount.slice(1)}` : `$${amount}`) : 0;
};

export const formatCurrencyWithout0 = (value, fix = 0) => {
  // return currencyFormatter(value, fix);
  const regExp = /\B(?=(\d{3})+(?!\d))/g;
  const amount = String(value).replace(regExp, '.');

  return value ? (value < 0 ? `$${amount.slice(1)}` : `$${amount}`) : 0;
};

export const formatCurrencyWithoutSimbol = (value, fix = 0) => {
  // return currencyFormatter(value, fix);
  const regExp = /\B(?=(\d{3})+(?!\d))/g;
  const amount = String(value).replace(regExp, '.');

  return value ? (value < 0 ? `${amount.slice(1)}` : `${amount}`) : 0;
};

export const capitalize = (text) => {
  const names = (text || '').split(/\s+/g).filter((name) => name);

  if (names.length) {
    names.forEach((name, index) => {
      names[index] = `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}`;
    });
  }

  return names.join(' ');
};

const processWithPattern = (string, pattern) => {
  const words = string.split(' ');
  return words
    .map((word, index) => {
      const processor = pattern[index % pattern.length];
      return processor(word);
    })
    .join(' ');
};

const getPatternForValue = (value, specialValues) => {
  const special = specialValues.find((item) => item.value === value);
  return special ? special.pattern : null;
};

export const capitalizeDescriptions = (list, specialCodes) =>
  list.map((item) => {
    const pattern = getPatternForValue(item.value, specialCodes);
    return {
      ...item,
      label: pattern ? processWithPattern(item.label, pattern) : capitalize(item.label),
    };
  });
export const capitalizeFirstLetter = (text) => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  } else {
    return text;
  }
};

export const filterDuplicatedItems = (arrItems = [], keyToMatch = '') => {
  const filtered = arrItems.reduce((acc, current) => {
    const x = acc.find((item) => item[keyToMatch] === current[keyToMatch]);

    if (!x) return acc.concat([current]);
    return acc;
  }, []);

  return filtered;
};

const suffixes = [];

(function suffix() {
  for (let iter = 97; iter < 123; iter += 1) {
    suffixes.push(String.fromCharCode(iter));
  }
})();

export const setMenuStructure = (data, activeMenu = 'menu-2', parent = '') => {
  return data.map((option, index) => {
    const id = `menu-${parent ? `${parent}-` : ''}${index + 1}`;
    const hasChildren = option.submenu && option.submenu.length;
    return {
      id,
      parent: `menu-${parent}`,
      icon: option.icono ? `${option.icono}.svg` : null,
      name: option.descripcion,
      url: !!option.url ? option.url : null,
      showChildren: id === activeMenu,
      children: hasChildren
        ? setMenuStructure(option.submenu, activeMenu, `${parent ? `${parent}-` : ''}${index + 1}`)
        : [],
    };
  });
};
export const handleMenuOptionClick = (menu, selectedOption) => {
  const show = (option) => {
    return selectedOption === option.id ? !option.showChildren : selectedOption.includes(option.id);
  };
  return menu.map((option) => {
    const hasChildren = !!option.children && !!option.children.length;
    return {
      ...option,
      showChildren: show(option),
      children: hasChildren ? handleMenuOptionClick(option.children, selectedOption) : [],
    };
  });
};

export const filterIncludesCode = (arrItems = [], codes = [], keyToMatch = '') => {
  return arrItems.filter((item) => codes.includes(item[keyToMatch]));
};

export const concatArrays = (...args) => [].concat(...args);

/**
 * Función para buscar item dentro de array
 * @param {array} arrItems
 * @param {string} keyToFind
 * @param {string} valueToMach
 * @returns
 */
export const findByKey = (arrItems = [], keyToFind, valueToMach) => {
  return arrItems.find((item) => item[keyToFind] === valueToMach);
};

export const hasKey = (arrItems = [], keyToFind, valueToMach) =>
  arrItems.some((item) => item[keyToFind] === valueToMach);

export const hiddeNumber = (number) => `Nº **** **** **** ${String(number).slice(-4)}`;
export const phoneNumbers = (number = '') => `+569 **** ** ${String(number).slice(-2)}`;

// TODO: esta función no tiene efecto y se usa en muchos puntos
export const logReqError = async (error, method = 'POST') => {
  return null;

  if (error && process.env.NODE_ENV !== 'test') {
    const token = localStorage.getItem('token');
    await logger(token || 'login', 'request', {
      method,
      ...error,
    });
  }
};

export async function encrypt(value) {
  var input = new TextEncoder('utf-8').encode(value);
  let encryptData = await crypto.subtle
    .digest('SHA-256', input)
    .then(function (digest) {
      var view = new DataView(digest);
      var rutHash = '';
      for (var i = 0; i < view.byteLength; i++) {
        var b = view.getUint8(i);
        rutHash += '0123456789abcdef'[(b & 0xf0) >> 4];
        rutHash += '0123456789abcdef'[b & 0x0f];
      }
      return rutHash;
    })
    .catch(function (err) {
      console.error(err);
      return null;
    });

  return encryptData;
}

export function RNEncrypt(value, key) {
  return CryptoJS.AES.encrypt(value || '', key || '').toString();
}
export function RNDecrypt(value, key) {
  return CryptoJS.AES.decrypt(value || '', key || '').toString(CryptoJS.enc.Utf8);
}
export const currentYear = () => {
  const yearCurrent = new Date().getFullYear();
  return yearCurrent;
};

/**
 * Función para acceder a url por medio del hook useHistory(react-router-dom)
 * @param {Object} history
 * @param {String} url
 * @returns {Object,String} history, url
 */
export const handleUrlwithUseHistory = (history = null, url = null) => {
  const checkHistory = history || false;
  const checkUrl = url || false;
  if (checkHistory && checkUrl) {
    history.push(url);
  }
};

/**
 * funcion para obtener valores de array sin duplicados y ordenados de mayor a menor
 * @param {array} data
 * @param {string} key
 * @returns {array}
 */
export const obtainValueArrNoduplicates = (data, key) =>
  data
    .map((item) => item?.[key]?.slice(0, 4))
    .reduce((accArr, valor) => {
      if (accArr.indexOf(valor) < 0) {
        accArr.push(valor);
      }
      return accArr;
    }, [])
    .map((i) => Number(i))
    .sort((a, b) => {
      if (a < b) {
        return 1;
      }
      if (a > b) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });

export const arrayInRange = (minimum, maximum) => {
  return Array(maximum - minimum + 1)
    .fill()
    .map((_, idx) => minimum + idx);
};

/**
 * Funcion que sirve para generar un id unico, para listas por ejemplo
 * @returns {string}
 */
export const generateUniqueKey = () => Math.random().toString(36).slice(2);

/*
Funcion que sirve para modificar el texto 
Primera letra mayuscula y el resto minuscula, por cada palabra
*/
export const titleCase = (string) => {
  string = string.toLowerCase();

  string = string.split(' ');

  for (var i = 0; i < string.length; i++) {
    string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
  }

  return string.join(' ');
};

export const sortByFavoritesAndDates = (data) => {
  const dataFavorites = data.filter((item) => item.esFavorito === true);
  const dataNoFavorites = data.filter((item) => item.esFavorito === false);
  dataFavorites.sort(function (a, b) {
    var nameA = a.nombreCompleto.toUpperCase();
    var nameB = b.nombreCompleto.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  dataNoFavorites.sort(function (a, b) {
    var nameA = a.nombreCompleto.toUpperCase();
    var nameB = b.nombreCompleto.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  const result = dataFavorites.concat(dataNoFavorites);
  return result;
};
