import styled from 'styled-components';

const BaseHeader = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  #arrow-color {
    fill: white;
  }
  .desktop-header-wrapper {
    height: 72px;
  }
  .mobile-header-wrapper .coopeuch-logo-header-wrapper {
    height: 72px;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }
  .mobile-header-wrapper .coopeuch-logo-header {
    margin: 0 auto;
    height: 30px;
    width: 170px;
  }

  .desktop-header-wrapper .coopeuch-logo-header-wrapper {
    height: 72px;
    /* width: auto; */
    display: flex;
    align-items: center;
  }
  .desktop-header-wrapper .coopeuch-logo-header-wrapper img {
    margin-left: 8px;
    height: 30px;
    width: 170px;
  }

  .actions-header {
    justify-content: flex-end;
  }
  .actions-header button {
    height: unset;
    padding: 0;
  }
  .actions-header button .row {
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }
  .logout-icon-action {
    margin-right: 5px;
    margin-top: 3px;
  }

  /* .menu--wrapper-navbar {
    width: calc(100% - 16px);
  } */

  @media (max-width: 768px) {
    & button {
      height: 56px;
      line-height: initial;
    }

    .menu--hamburger {
      display: flex;
    }
    .logo-wrapper a {
      justify-content: center;
    }
  }
`;
export const AccessHeader = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  padding-left: 50px;
  text-align: left;
`;
export default BaseHeader;
