import { spawn, call, takeLatest, put } from 'redux-saga/effects';
import { logReqError } from '../../../ui/utils/functions';

// Actions
import { layoutActions } from '../../../application/actions/ui_layout';

// Configs
import api from '../../api';

const apiRequest = api(process.env.REACT_APP_CONFIGURATION_API);

export function* fetchLayout() {
  try {
    const { data, error } = yield call(apiRequest.get, 'web/v1/obtener/');

    yield logReqError(error, 'GET');

    if (data) {
      const { estructura } = data;
      yield put(layoutActions.fetchLayoutSuccess(estructura));
      //se quita menú para dejar una versión estatica en front

      yield put(
        layoutActions.fetchFormattedLayoutSuccess({
          footer: {
            elementos: estructura.footer.elementos.filter((item) => !item.descripcion),
            rightContent: estructura.footer.elementos.find((item) => item.descripcion),
          },
          menu: null,
        })
      );
    }
  } catch (err) {
    console.log(err.message, 'fetchLayout');
  }
}

export function* watchFetchLayout() {
  yield takeLatest(layoutActions.fetchLayoutConfigs.type, fetchLayout);
}

export default function* rootSaga() {
  yield spawn(watchFetchLayout);
}
