import styled from 'styled-components';

const Wrapper = styled.div`
  .dashboard-custom {
    padding: 0px;
    padding: 0px 30px 0px 33px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 991px) {
    .dashboard-custom {
      padding: 16px;
    }
  }

  .dashboard-puntos-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  @media screen and (max-width: 991px) {
    .dashboard-puntos-body {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  /* Icon Puntos Coopeuch */

  .dashboard-puntos__icon {
    display: flex;
    align-items: center;
  }
  @media screen and (max-width: 991px) {
    .dashboard-puntos__icon {
      flex-direction: column;
      align-items: flex-start;
      padding-right: 0px;
    }
  }

  /* Icon Puntos Coopeuch */

  /* Title Puntos */

  .dashboard-puntos__title {
    font-size: 16px;
    padding-left: 3px;
    padding-right: 16px;
  }

  @media screen and (max-width: 991px) {
    .dashboard-puntos__title {
      padding-left: 0px;
      margin-top: 10px;
    }
  }

  /* Puntos a CLP */

  .dashboard-puntos__puntos-clp {
    display: flex;
    align-items: center;
    font-size: 16px;
  }
  @media screen and (max-width: 991px) {
    .dashboard-puntos__puntos-clp {
      margin-left: 0px;
    }
  }
  .dashboard-puntos__puntos-clp::before {
    content: '';
    width: 1px;
    height: 33px;
    background-color: #dad9d9;
  }
  @media screen and (max-width: 991px) {
    .dashboard-puntos__puntos-clp::before {
      display: none;
    }
  }

  .dashboard-puntos__puntos-clp label {
    padding-left: 16px;
  }
  @media screen and (max-width: 991px) {
    .dashboard-puntos__puntos-clp label {
      padding-left: 0px;
    }
  }

  /* Validación Canjear o Acumular Puntos */
  .dashboard-puntos__info {
    display: flex;
    align-items: center;
  }

  .acumularOcanjear {
    color: #f42534;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
    float: left;
  }
  @media screen and (max-width: 991px) {
    .acumularOcanjear {
      margin-top: 15px;
    }
  }

  .btnPuntos {
    font-weight: bold;
  }
`;

export default Wrapper;
