import React from 'react';
import PropTypes from 'prop-types';
// UI
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SinMovimientos = ({ icon, message }) => (
  <Wrapper>
    <div className="mt-12 mb-12">
      <div className="row center-xs">
        <LoadIcon src={`${iconPath}${icon}.svg`} size={30} className="w-100" color="#646569" />
        <div className="col-xs-12">
          <p className="dark-grey text-size-s  pt-4">{message}</p>
        </div>
      </div>
    </div>
  </Wrapper>
);

SinMovimientos.propTypes = {
  icon: PropTypes.string,
  message: PropTypes.string,
};

SinMovimientos.defaultProps = {
  icon: '',
  message: '',
};

export default SinMovimientos;
