import React from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';

import { SinHistorialAlert, SinHistorialIconWrapper } from '../styles';


const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;


const SinHistorial = () => {
  return (
    <CardBox className="h-100 w-auto">
      <CardBox.Header className="padding-headers">
        <>
          <Typography id="title" color="#383638" tag="p" fontSize={20} fontWeight={500}>
            Resultado
          </Typography>
        </>
      </CardBox.Header>
      <CardBox.Body>
        <SinHistorialAlert>
          <SinHistorialIconWrapper className="mr-16">
            <LoadIcon src={`${iconPath}copywriting.svg`} size={20} className="w-100" color="#979797" />
          </SinHistorialIconWrapper>

          <div className="row">
            <span className="text-bold black ml-2">¡Nada por aquí!</span>
            <span className="black ml-4">
              No encontramos resultados para tu búsqueda, intenta con otro  rango de fecha.
            </span>
          </div>
        </SinHistorialAlert>
      </CardBox.Body>
    </CardBox>
  );
};

export default SinHistorial;
