import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 32px;
  margin-top: 32px;
  @media (max-width: 1280px) {
    margin-right: 32px;
  }

  .congratulation {
    display: flex;
    width: 100%;
    max-width: 970px;
    margin-top: 32px;
    margin-bottom: 32px;
    color: #0e891a;
    &__text {
      font-size: 14px;
      line-height: 20px;

      .text__letterBold {
        font-family: 'Ubuntu-Bold';
      }
    }
  }

  @media (max-width: 650px) {
    .congratulation {
      height: auto;
      padding-top: 14px;
      padding-right: 14px;
      padding-bottom: 17px;
    }
  }

  .body__container {
    padding: 0px;
    width: 100%;
  }

  .comprobante,
  .detalle__activacion,
  .proximos__pasos {
    width: 100%;
    max-width: 970px;
    margin-bottom: 32px;
  }
`;

export default Wrapper;
