import React from 'react';
import Alert from '@coopeuch-components/web/atoms/Alert';
// Organismos
import Stepper from '../../../../components/organism/Stepper';
import DetalleActivacion from '../../../organism/ActivacionTarjetas/DetalleActivacion';
import ProximosPasos from '../../../organism/ActivacionTarjetas/Pasos';
import ButtonBack from '../../../organism/ActivacionTarjetas/ButtonBack';
import ModalError from '../../../organism/ActivacionTarjetas/Error/ModalError';
// Utils
import { toEllipsis } from '../../../../utils/formatters';
// Styled
import Wrapper from './styles';

const ComprobanteTemplate = ({
  steps,
  kitData,
  validateSMS,
  activateKit,
  persona,
  handleDownloadPDF,
  visibleModal,
  setVisibleModal,
  validateTC,
  handleReturnInicio
}) => {
  return (
    <Wrapper>
      <Stepper className="start-xs" position={3} steps={steps} />
      <Alert className="congratulation" bgType="success" show>
        <p className="congratulation__text">
          Tu tarjeta ha sido activada, te enviamos una copia del comprobante con el detalle a tu
          email:{' '}
          <b className="text__letterBold">{toEllipsis({ text: persona?.correo, maxLength: 30 })}</b>
        </p>
      </Alert>
      <div className="body__container">
        <div className="detalle__activacion">
          <DetalleActivacion
            {...{ kitData, persona, validateSMS,validateTC, activateKit, handleDownloadPDF }}
          />
        </div>

        <div className="proximos__pasos">
          <ProximosPasos />
        </div>
      </div>
      <ButtonBack link="/ecd/inicio" text="Volver al inicio" onClick={() => handleReturnInicio()} />
      <ModalError {...{ visibleModal, setVisibleModal }} />
    </Wrapper>
  );
};

export default ComprobanteTemplate;
