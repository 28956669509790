import styled from 'styled-components';

export default styled.div`
  .content {
    text-align: left;
    padding: 24px 3rem 24px 3rem;
  }

  .modulos-container {
    margin-top: 24px;
  }

  .modulo {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .col-pl-0 {
    padding-left: 0px !important;
  }

  .modulo-content {
    padding: 24px 32px;
  }

  .alert--wrapper {
    height: auto;
    width: 100%;
    align-items: center;
    margin-bottom: 24px;

    .right-content {
      align-items: center;
    }
  }

  .err-component {
    .button-error-class {
      width: 296px;
      background-color: #e81e2b;
    }
  }

  #pago-tarjeta-credito-next-action {
    width: 236px !important;
  }

  #pago-tarjeta-credito-prev-action {
    align-items: center;
  }

  @media screen and (max-width: 766px) {
    .content {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 16px !important;
    }

    .modulos-container {
      margin-top: 16px;
    }

    .modulo {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .modulo-content {
      padding: 16px;
    }

    .modulo-second-text {
      padding-top: 16px;
    }

    .col-pl-0 {
      padding-left: 0.5rem !important;
    }

    .sello-container {
      margin-top: 14px;
    }

    .err-component {
      .button-error-class {
        width: auto;
      }
    }

    .alert--wrapper {
      padding-left: 14px !important;
      margin-bottom: 16px;

      .right-content {
        align-items: start;

        & > div:first-child {
          width: 30px !important;
          height: 40px !important;
        }

        div,
        p {
          text-align: start;
        }

        svg {
          width: 20px;
          height: 30px;
        }
      }
    }

    #pago-tarjeta-credito-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }

    .pl-32,
    .pl-24 {
      padding-left: 16px !important;
    }

    .pr-32,
    .pr-24 {
      padding-right: 16px !important;
    }

    #pago-tarjeta-credito-next-action,
    .step-action-button-wrapper {
      width: 100% !important;
    }
  }
`;
