import styled from 'styled-components'

export const CheckBoxWrapper = styled.div`
  margin-left: -8px;
`
export const ActionsWrapper = styled.div`
  button {
    min-width: 160px;
  }
`
export default styled.div`
  color: #383638;
  line-height:20px;
`