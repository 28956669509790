import styled from 'styled-components';

const Button = styled.div`
  display: ${(props) => !props.visibility && 'none'};
  width: 100%;
  max-width: 296px;
  border-radius: 4px;
  background-color: #007db7;
  padding: 14px 24px;
  text-align: center;
  color: white;
  font-family: 'Ubuntu-Medium';
  font-size: 14px;
`;

export default Button;
