import styled, { css } from 'styled-components';

const BaseItem = styled.div`
  position: relative;
  .anim-card--expand {
    transition: all 256ms cubic-bezier(0.25, 1, 0.5, 1);
    max-height: 100%;
    transform: scaleY(1);
  }
`;

export const BaseBody = styled.div`
  transform-origin: top;
  transform: scaleY(0);
  overflow: hidden;
  max-height: 0;
`;

export const BaseHeader = styled.div`
  align-items: center;
  display: flex;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ isBordered }) => isBordered && `border-bottom: 1px solid #e9e9e9;`}
  ${({ rounded, isExpanded }) => {
    let br = '';

    if (rounded) {
      br = 'border-radius: 5px;';

      if (isExpanded) {
        br = css`
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        `;
      }
    }

    return br;
  }}
  .item-arrow-wrapper {
    justify-content: flex-end;
  }
`;

export const BaseIcon = styled.div`
  /* position: absolute; */
  /* right: 0px; */
  height: ${({ size }) => `${size || 32}px`};
  width: ${({ size }) => `${size || 32}px`};
  transform: rotate(${({ isExpanded }) => (isExpanded ? '-90deg' : '90deg')});
`;

export default BaseItem;
