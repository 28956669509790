import React, { useState, useEffect } from 'react';
import Button from './styles';
import { formatSecondToMinutes } from '../../../../../utils/formatters';

const TimerButton = ({
  counterActive,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  authorizeBtn,
  errorTimeOut,
  validateSMS,
  timerValue,
}) => {
  let time = timerValue;
  const [value, setValue] = useState();

  const updateCountDown = () => {
    time--;
    if (time === 0) {
      setValue('');
      setErrorTimeOut(true);
      setFactorErrorTimeOut('sms');
    }
    return formatSecondToMinutes(time);
  };

  useEffect(() => {
    // ACTUALIZACION: componentDidUpdate
    let temp;
    if (counterActive === true) {
      temp = setInterval(() => setValue(updateCountDown()), 1000);
    } else {
      clearInterval(temp);
    }
  }, [counterActive]);

  return (
    <Button
      visibility={
        authorizeBtn === false && errorTimeOut === false && validateSMS.data === null && true
      }
    >
      {value ? `${value} para autorizar` : `${formatSecondToMinutes(time)} para autorizar`}
    </Button>
  );
};

TimerButton.displayName = 'CountdownNumbers';
export default TimerButton;
