import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Select from '../../../../atoms/Select';
import InputLabel from '../../../../atoms/InputLabel';
import PasswordInput from '../../../../atoms/PasswordInput';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import WrongPassword from '../Errors/WrongPassword';
import BlockedPassword from '../Errors/BlockedPassword';
import { agregarformatoPesos, formatThousandsSeparators } from '../../../../../utils/formatters';
import PasswordWrapper from './styles';

const Index = ({
  accounts,
  selectedAccount,
  montoAPagar,
  cantidadCuotas,
  valorCuota,
  handleChangeSelect,
  handlePasswordInput,
  handleTogglePassword,
  handleRetryTryToPay,
  handleToCambiarClave,
  secondFailPasswordError,
  password,
  showPassword,
  passwordError,
  blockedPassword,
  serverPasswordError,
  backButton,
  nextButton,
  continueStep2WithoutPago,
  waiting,
}) => {
  const PasswordErrorMSG = ({ serverPasswordError }) => {
    switch (serverPasswordError) {
      case 'AUTH-01':
        return <WrongPassword retry={handleRetryTryToPay} secondFail={secondFailPasswordError} />;
      case 'AUTH-02':
      case 'AUTH-03':
        return <BlockedPassword onClick={handleToCambiarClave} />;
      default:
        return <></>;
    }
  };
  return (
    <>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>Cuenta de origen</CardBox.Header>
            <CardBox.Body>
              <div className="row bmt-3">
                <div className="col-xs-12 col-md-6">
                  <div className="row">
                    <div className="col-xs-12 col-md-8 bmb-3 bmb-md-0">
                      {accounts.length > 1 ? (
                        <Select
                          title="Cuenta de Origen"
                          borderColor="#333"
                          labelClass="text-bold bmb-3"
                          rounded={1}
                          onChange={handleChangeSelect}
                          options={accounts}
                          value={selectedAccount ? selectedAccount.value : ''}
                          size={48}
                        />
                      ) : (
                        <InputLabel
                          onChange={() => {}}
                          size={48}
                          title="Número de cuenta"
                          labelClass="bmb-3 text-bold"
                          value={selectedAccount.label}
                          rounded={4}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <Typography className="bmb-3" tag="p" fontSize="16">
                    Saldo disponible
                  </Typography>
                  <Typography tag="p" fontSize="28" fontWeight="bold">
                    {agregarformatoPesos(selectedAccount.saldo)}
                  </Typography>
                </div>
              </div>
              {selectedAccount.saldo < montoAPagar && (
                <div className="row bmt-3 bmt-md-4 ">
                  <div className="col-xs-12 ">
                    <Alert
                      className="alert-warning bp-3 w-100"
                      bgType="warning"
                      id="warning-saldo-insuficiente-pago-cdp"
                      show
                    >
                      {`No tienes saldo suficiente. Te invitamos a abonar saldo a tu cuenta`}
                    </Alert>
                  </div>
                </div>
              )}
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>Resumen de pago</CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12 col-md-6">
                  <Typography className="bmb-2" tag="p" fontSize={16}>
                    Monto a pagar:
                  </Typography>
                  <Typography tag="p" fontSize={28} fontWeight="bold">
                    {agregarformatoPesos(montoAPagar)}
                  </Typography>
                </div>
                <div className="col-xs-12 col-md-6 bmt-2 bmt-md-0">
                  <Typography className="bmb-2" tag="p" fontSize={16}>
                    Cuotas a pagar:
                    <b>{formatThousandsSeparators(cantidadCuotas)}</b>
                  </Typography>
                  <Typography tag="p" fontSize={16}>
                    Valor cuota:
                    <b>{agregarformatoPesos(valorCuota)}</b>
                  </Typography>
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      {montoAPagar <= selectedAccount.saldo && (
        <div className="row bmt-3 bmt-md-4">
          <PasswordWrapper className="col-xs-12">
            <CardBox>
              <CardBox.Header>Firma electrónica</CardBox.Header>
              <CardBox.Body>
                {serverPasswordError.length ? (
                  <PasswordErrorMSG {...{ serverPasswordError }} />
                ) : (
                  <div className="row">
                    <div className="col-xs-12">
                      <Typography tag="p" fontSize={16}>
                        Valida la transacción con tu clave de internet Coopeuch
                      </Typography>
                    </div>
                    <div className="col-xs-12 col-md-5">
                      <PasswordInput
                        {...{
                          password,
                          showPassword,
                          passwordError,
                          onTogglePassword: handleTogglePassword,
                          onHandlePasswordInput: handlePasswordInput,
                        }}
                      />
                    </div>
                  </div>
                )}
              </CardBox.Body>
            </CardBox>
          </PasswordWrapper>
        </div>
      )}
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12">
          <StepsActions
            backAction={backButton}
            backText={'Volver'}
            nextText={montoAPagar <= selectedAccount.saldo ? 'Pagar' : 'Aceptar'}
            nextAction={
              montoAPagar <= selectedAccount.saldo ? nextButton : continueStep2WithoutPago
            }
            disableNextAction={
              montoAPagar <= selectedAccount.saldo
                ? !(
                    password.length > 0 &&
                    passwordError.length === 0 &&
                    !serverPasswordError &&
                    !blockedPassword &&
                    selectedAccount.saldo >= montoAPagar &&
                    !waiting
                  )
                : false
            }
            loading={waiting}
            id="pago-cdp-paso-2"
          />
        </div>
      </div>
    </>
  );
};
Index.displayName = 'Pago_CP_template';
export default Index;
