import React from 'react';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { formatStringToDate } from '../../../../utils/dates';
import { formatCurrency } from '../../../../utils/functions';

const Mobile = ({ data }) => {
  return data.map((item, index) => (
    <div key={index}>
      <div className="row between-xs">
        <div className="col-md-6">
          <Typography tag="p">Fecha de Pago</Typography>
        </div>
        <div className="col-md-6">
          <Typography align="right" tag="p">{`${formatStringToDate(
            item.fechaPago
          )} - ${item.horaPago.slice(0, 5)} hrs.`}</Typography>
        </div>
      </div>
      <div className="row between-xs">
        <div className="col-md-6">
          <Typography tag="p">Descripción</Typography>
        </div>
        <div className="col-md-6">
          <Typography align="right" tag="p" className="mw-180">
            {item.descripcion}
          </Typography>
        </div>
      </div>
      <div className="row between-xs">
        <div className="col-md-6">
          <Typography tag="p">Nº de cuotas</Typography>
        </div>
        <div className="col-md-6">
          <Typography tag="p">{item.cuotasPagadas}</Typography>
        </div>
      </div>
      <div className="row between-xs">
        <div className="col-md-6">
          <Typography tag="p">Valor</Typography>
        </div>
        <div className="col-md-6">
          <Typography tag="p">{formatCurrency(item.valorCuota)}</Typography>
        </div>
      </div>
      <div className="row between-xs">
        <div className="col-md-6">
          <Typography tag="p">Monto</Typography>
        </div>
        <div className="col-md-6">
          <Typography tag="p">{formatCurrency(item.montoTotalPagado)}</Typography>
        </div>
      </div>
    </div>
  ));
};

export default Mobile;
