import styled from 'styled-components';

export const Wrapper = styled.div`
  .bloqueo-definitivo-title {
    padding: 24px 32px;
  }

  .bloqueo-confirmacion-motivo-desc {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .bloqueo-confirmacion-motivo-select {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
  .button-style-bloqueo {
    width: 168px;
  }

  .bloqueo-firma-text {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .bloqueo-confirmacion-select {
    display: flex;
  }

  .bloqueo-definitivo-factor {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
  }

  .bloqueo-confirmacion-container {
    padding-top: 4px;
    padding-left: 8px;
  }

  .bloqueo-definitivo-button-container {
    display: inline-flex;
  }

  @media (max-width: 768px) {
    .bloqueo-definitivo-title {
      padding: 16px;
    }
    .button-style-bloqueo {
      width: 100%;
    }
    .return-bloqueo-center {
      margin-left: 34%;
    }
    .bloqueo-definitivo-button-container {
      display: flex;
    }
  }
`;

export default Wrapper;
