import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Atoms
import CardShadow from '../../atoms/CardShadow';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const NextSteps = ({ nextStepsContent }) => {
  return (
    <Wrapper>
      <CardShadow
        className="cardNextSteps"
        title="Próximos pasos"
        padding={0}
        content={
          nextStepsContent &&
          nextStepsContent.map((i) => (
            <div className={`cardNextSteps__container ${i.bgActive && 'activeBg'}`}>
              <div className="cardNextSteps__container_step">
                <p className={`${i.bgActive && 'activeStep'}`}>{i.step}</p>
              </div>
              <div className="cardNextSteps__container_text">
                <div className="cardNextSteps__container_text__header">
                  <p className="cardNextSteps__container_content__title">{i.title}</p>
                  <p className="cardNextSteps__container_content__subtitle">{i.subtitle}</p>
                </div>
                <div className="cardNextSteps__container_text__body">
                  <p>{i.body} <strong>{i?.destacado && i.destacado}</strong></p>
                </div>
              </div>
              {i.iconName !== '' && (
                <div className="cardNextSteps__container_imgs">
                  <LoadIcon src={`${iconPath}${i.iconName}`} />
                </div>
              )}
            </div>
          ))
        }
      />
    </Wrapper>
  );
};

export default NextSteps;
