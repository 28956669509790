import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import MiCuota from './MiCuota';
import Historial from './Historial';
import { TabsWrapper, PageTitleWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = () => {
  return (
    <>
      <PageTitleWrapper>
        {' '}
        <PageTitle
          id="header-cuota-participacion"
          title="Cuota de Participación"
          icon={<LoadIcon src={`${iconPath}pie_chart.svg`} color="#333" />}
        />
      </PageTitleWrapper>
      <TabsWrapper className="w-100 margin-after-title">
        <Tabs
          bgColor="#fff"
          fontSize={15}
          fontFamily="Ubuntu-Medium"
          tabSize={20}
          activeColor="#e81e2b"
          className="pl-54 tab--wrapper-shadows tabs"
          // defaultTab={selectedTab}
        >
          <Tabs.Header>
            <Tab className="tab-style">
              <Link to="/ecd/productos/cuota-de-participacion">Mi Cuota</Link>
            </Tab>

            <Tab className="tab-style w-150p">
              <Link to="/ecd/productos/cuota-de-participacion/historial">Historial</Link>
            </Tab>
          </Tabs.Header>
          <div className="w-100 bpx-3 bpx-md-5 margin-after-tabs-vista text-left">
            <Tabs.Body>
              {/* <Suspense fallback={<div />}> */}
              <div className="w-100">
                <MiCuota />
                {/* <MiCuenta
                  onChangeAccount={this.onChangeAccountHandle}
                  allAccounts={balance}
                  cuenta={accountNumber}
                  tarjetasAsociadas={assoCards}
                  ultimosMovimientos={lastMovements}
                  loadingData={loadingData}
                  errorCuentasVistas={errorCuentasVistas}
                  isOpenTab={tabs[selectedTab] === 'MiCuenta'}
                /> */}
              </div>
              {/* </Suspense> */}
              {/* <Suspense fallback={<div />}> */}
              <div className="w-100">
                {/* {selectedTab === 1 && (
                    <Movimientos
                      onChangeAccount={this.onChangeAccountHandle}
                      movements={movements}
                      accountNumber={accountNumber}
                      allAccounts={balance}
                      loading={loaders.isMvts}
                      onSearch={this.onSetMovementsHandle}
                      query={query}
                      onQueryHandle={this.onQueryHandle}
                      allMovements={AllMvts[accountNumber]}
                      onPushMovementsHandle={this.onPushMovementsHandle}
                      isOpenTab={tabs[selectedTab] === 'Movimientos'}
                      errMovimientosLog={errMovimientosLog}
                      persona={persona}
                    />
                  )} */}
                <Historial />
              </div>
              {/* </Suspense> */}
            </Tabs.Body>
          </div>
        </Tabs>
      </TabsWrapper>
    </>
  );
};
Index.displayName = 'Container_CDP';
export default withRouter(Index);
