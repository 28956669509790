import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkRRD } from 'react-router-dom';
// UI
import Avatar from '@coopeuch-components/web/atoms/Avatar';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Utils
import { capitalize } from '../../../utils/functions';
// components
import MenuMobile from '../MenuMobile';
// styles
import { colors } from '../../../assets/styles';
import BaseHeader, { AccessHeader } from './styles';

const imgPath = process.env.REACT_APP_CLOUDFRONT_IMAGES;
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Header = ({
  onClickLogoutHandle,
  onClickHeaderPerfil,
  avatarLetters,
  onClickMenu,
  elements,
  subtitle,
  title,
  id,
  children,
  lastAccess,
}) => {
  let logout = null;
  let logo = null;
  if (elements && elements.length) {
    logout = elements.find((ele) => ele.tipo === 'CERRAR_SESION');
    logo = elements.find((ele) => ele.tipo === 'LOGO_COOPEUCH');
  }
  // icon arrow en subtitle
  let arrow = false;
  subtitle === 'Editar mis Datos' ? (arrow = true) : (arrow = false);

  return (
    <BaseHeader className="row" id={id}>
      <div className="mobile-header-wrapper col-xs-12 d-block d-md-none">
        <div className="row">
          <div className="col-xs-2 bpl-3 center-xs">
            <MenuMobile onClick={onClickMenu} height={72} id="mobile-menu" />
          </div>
          <div className="col-xs-8 center-xs">
            {logo && (
              <div className="coopeuch-logo-header-wrapper">
                <a href={logo.url}>
                  <img
                    className="coopeuch-logo-header"
                    srcSet={`${imgPath}${logo.imagen}`}
                    alt="Coopeuch"
                  />
                </a>
              </div>
            )}
          </div>
          <div className="col-xs-2 center-xs" />
        </div>
      </div>

      <div className="desktop-header-wrapper col-xs-12 d-none d-md-block">
        <div className="row">
          <div className="col-xs-2">
            {logo && (
              <div className="coopeuch-logo-header-wrapper">
                <a href={logo.url}>
                  <img
                    className="coopeuch-logo-header"
                    srcSet={`${imgPath}${logo.imagen}`}
                    alt="Coopeuch"
                  />
                </a>
              </div>
            )}
          </div>
          <AccessHeader className="col-xs-5">{lastAccess && <p>{lastAccess}</p>}</AccessHeader>

          <div className="col-xs-5">
            <div className="coopeuch-logo-header-wrapper actions-header">
              {logout && (
                <>
                  <LinkRRD
                    to="/ecd/mi-perfil"
                    data-lab="Consultar Perfil"
                    onClick={onClickHeaderPerfil}
                    className="none-underline"
                    underline={false}
                  >
                    <Avatar
                      subtitleClass="text-thin text-size-xs white"
                      letterColor={colors.white}
                      contentClass="bpy-2"
                      titleClass="text-bold white"
                      subtitle={subtitle}
                      letters={avatarLetters}
                      bgColor={colors.darkRed}
                      title={capitalize(title)}
                      size={40}
                      id="avatar-header"
                    >
                      {arrow && (
                        <LoadIcon
                          className="logout-icon-action"
                          src={`${iconPath}arrow_right.svg`}
                          size={16}
                        />
                      )}
                    </Avatar>
                  </LinkRRD>

                  <Button
                    className="bml-5"
                    onClick={onClickLogoutHandle}
                    bgColor="transparent"
                    color={colors.white}
                    id="logout-button"
                  >
                    <div className="row center-xs">
                      <LoadIcon
                        className="logout-icon-action"
                        src={`${iconPath}${logout.icono}`}
                        size={24}
                      />
                      <p className="white text-size-xs">{logout.titulo}</p>
                    </div>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </BaseHeader>
  );
};

Header.propTypes = {
  onClickLogoutHandle: PropTypes.func,
  avatarLetters: PropTypes.string,
  onClickMenu: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  onDispatchGmtEvent: PropTypes.func,
  children: PropTypes.elementType,
};

Header.defaultProps = {
  onClickLogoutHandle() {},
  avatarLetters: '',
  onClickMenu: null,
  subtitle: '',
  title: '',
  id: '',
  onDispatchGmtEvent: null,
  children: '',
};

export default Header;
