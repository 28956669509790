import styled from 'styled-components';

export const Wrapper = styled.div`
  color: #383638;
  .align-button-right {
    justify-content: flex-end;
  }
  .align-button-left {
    justify-content: flex-start;
  }
  .align-button-between {
    justify-content: space-between;
  }
  .button-next {
    float: right;
  }
  .border-inputLabel {
    border: 1px solid;
  }
  .height-searchbar {
    height: 46px;
  }
  .padding-headers {
    padding: 16px 32px;
  }
  .separation-inputs {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .pdf-download-link {
    cursor: pointer;
  }
  .select-ano {
    width: 294px;
    min-width: 267px;
  }

  @media screen and (min-width: 320px) {
    .select-ano {
      width: 100%;
      min-width: 240px;
    }
  }
  .select-cuenta {
    max-width: 299px;
  }
  @media screen and (min-width: 320px) {
    .select-cuenta {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`;

export const ContainerTitles = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 2.5fr 1fr 1fr;
  gap: 10px;
  padding: 16px 0;
  color: #383638;
  .mvts--wrapper-left {
    justify-self: end;
  }
`;

export const MovementsTable = styled.div`
  .mvts--wrapper-left {
    justify-self: end;
  }
  .hide-top-border {
    border-top: none !important;
  }
  .mvts--header-rows,
  .mvts--body-rows {
    display: grid;
    grid-template-columns: 1.5fr 2.5fr 1fr 1fr;
    gap: 10px;
    padding: 16px 0;
    color: #383638;
  }

  .mvts--header-rows {
  }

  .mvts--wrapper-m {
    padding-bottom: 8px;
    padding-top: 8px;
  }

  .mvts--wrapper-rows-m {
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 10px;
    padding: 5px 0;
  }

  .mvts--body-rows,
  .mvts--wrapper-m {
    border-top: 1px solid #e9e9e9;
  }

  @media screen and (max-width: 768px) {
    .mvts--wrapper-rows-m {
      p:last-child {
        text-align: right;
      }
    }
  }
`;

export default Wrapper;
