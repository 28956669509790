import React from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { addClass } from '@coopeuch-components/web';
import Countdown from '../Utils/Countdown';
import CountdownCard from '../Utils/CountdownCard';
import ButtonContainer from './styles';

const pasosFactor = {
  0: 'inicio',
  1: 'validarCoopeuchPass',
};

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const FactorPassCoopeuch = (props) => {
  const {
    isButtonDisabled,
    onClickButton,
    message,
    buttonText,
    withCountdownAnimation,
    // eslint-disable-next-line no-unused-vars
    duration,
    timer,
    hasError,
    stepFactor,
    activeTimer,
    title,
    titleClass,
    hideButton,
  } = props;

  return (
    <div className="col-md-6 col-sm-8 col-xs-12 center-xs">
      {hasError ? (
        <div className="row center-xs">
          <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={175} />
        </div>
      ) : (
        <Countdown
          ilustration="pass_coopeuch"
          withCountdownAnimation={withCountdownAnimation}
          timer={timer}
        />
      )}

      {title && <p className={addClass([titleClass, 'text-size-l text-bold'])}>{title}</p>}
      <div className={addClass([!title && 'bmt-3', 'bmb-3 text-size-m'])}>
        <Typography tag="p" color="#383638" fontSize={16} className="text-center pb-4">
          {message}
        </Typography>
      </div>

      {pasosFactor[stepFactor] === 'validarCoopeuchPass' && !hasError && (
        <CountdownCard timer={timer} active={activeTimer} />
      )}

      {(pasosFactor[stepFactor] === 'inicio' || hasError) && !hideButton && (
        <ButtonContainer>
          <Button
            rounded={5}
            bgColor="#e81d2b"
            color="white"
            dataset={{ id: 2.2 }}
            onClick={onClickButton}
            disabled={isButtonDisabled}
            width={274}
            shadow
            height={48}
            className="factor-button-style"
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

FactorPassCoopeuch.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  withCountdownAnimation: PropTypes.bool,
  duration: PropTypes.number,
  timer: PropTypes.number,
  hasError: PropTypes.bool,
  stepFactor: PropTypes.number,
  activeTimer: PropTypes.bool,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  hideButton: PropTypes.bool,
};

FactorPassCoopeuch.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  message: '',
  buttonText: '',
  withCountdownAnimation: false,
  duration: 120,
  timer: 0,
  stepFactor: 0,
  activeTimer: false,
  hideButton: false,
  hasError: false,
  title: '',
  titleClass: '',
};

export default FactorPassCoopeuch;
