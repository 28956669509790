import styled from 'styled-components';
import {colors} from '../../../assets/styles';
export const Text = styled.p`
  color: #383638;
  letter-spacing: 0;
  text-align: center;
  font-size: ${({ textSize }) => `${textSize}px`};
  line-height: ${({ size }) => `${size}px`};
  font-weight: ${({ weight }) => weight};
`;
export const WarningWrapper = styled.div`
  border-radius: 4px;
  cursor: pointer;
  background-color: ${colors.red};
  p {
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }
`;

export const Img = styled.img`
  height: 100px;
  width: auto;
  margin: 0 auto;
`;
