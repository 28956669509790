import { createSlice, createAction } from '@reduxjs/toolkit';
import initState from '../../../domain/entities/products';

// ---------------- Saga's response ----------------
function fetchCuentasVistaSuccess(state, action) {
  state.cuentaVista.balance = action.payload;
  state.cuentaVista.sinProductos = action.payload.length === 0;
  state.errorCuentasVistas = false;
  state.errCuentasLog = null;
}

function fetchCuentasVistaFailed(state, action) {
  const { error } = action.payload;

  state.cuentaVista.error = true;
  state.errorCuentasVistas = true;
  state.cuentaVista.balance = [];
  state.errCuentasLog = error;
}
// Action All tarjetas
function fetchAllTarjetasCVSuccess(state, action) {
  const { tarjetasDebito } = action.payload;

  const cleanedDebitCards = Object.entries(tarjetasDebito).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: value.data,
    }),
    {}
  );
  state.cuentaVista.assoCards = cleanedDebitCards;
}

function fetchAllTarjetasCVError(state, action) {
console.log( action);
  const { error } = action.payload;
  state.cuentaVista.assoCards = null;
  state.errTarjetasLog = error;
}

function fetchTarjetasCuentaVistaSuccess(state, action) {
  const { account, cards } = action.payload;
  state.cuentaVista.error = false;
  state.cuentaVista.account = account;
  state.cuentaVista.assoCards[account] = cards;
  state.errTarjetasLog = null;
}

function clearTarjetasCuentaVista(state) {
  state.cuentaVista.error = false;
  state.cuentaVista.assoCards = null;
  state.cuentaVista.account = null;
  state.errTarjetasLog = null;
}

function fetchTarjetasCuentaVistaError(state, action) {
  const { error } = action.payload;

  state.errorCuentasVistas = true;
  state.errTarjetasLog = error;
}

function fetchUltimosMovimientsoCuentaVistaSuccess(state, action) {
  const { account, lastMovements } = action.payload;

  state.cuentaVista.lastMovements[account] = lastMovements;
  state.errUltMovimientosLog = null;
}

function fetchUltimosMovimientsoCuentaVistaError(state, action) {
  const { error } = action.payload;

  state.errorCuentasVistas = true;
  state.errUltMovimientosLog = error;
}

function fetchMovimientosCuentaVistaSuccess(state, action) {
  const { account, movements, isMvts } = action.payload;

  state.cuentaVista.movements[account] = movements;
  state.cuentaVista.loaders.isMvts = isMvts;
}

function fetchPDFMovimientosCuentaVistaSuccess(state, action) {
  state.cuentaVista.pdfMovements = { success: true, error: false, data: action.payload.data };
}

function fetchPDFMovimientosCuentaVistaError(state) {
  state.cuentaVista.pdfMovements = { success: false, error: true, data: null };
}

function resetDataPDFMovimientos(state) {
  state.cuentaVista.pdfMovements = { success: false, error: false, data: null };
}

function fetchLoadingMvtsCurrentAccountSuccess(state, action) {
  const { isMvts } = action.payload;
  state.cuentaVista.loaders.isMvts = isMvts;
}

function fetchBloqueoTemporalTarjetaSuccess(state, action) {
  // console.log('success', action.payload);
  state.bloqueoTemporalTarjetas.tarjetasDisponibles = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchBloqueoTemporalTarjetaError(state) {
  // console.log('error', action.payload);
  state.bloqueoTemporalTarjetas.tarjetasDisponibles = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchDashboardProductosSuccess(state, action) {
  const { dashboardProductos } = action.payload;
  state.dashboardProductos = dashboardProductos;
  state.dashboardProductosError = false;
}

function fetchDashboardProductosFailed(state) {
  state.dashboardProductos = {};
  state.dashboardProductosError = true;
}

function fetchCambioEstadoTarjetaDebitoSuccess(state, action) {
  const { cambioEstadoTjDebito } = action.payload;
  state.cambioEstadoTjDebito = cambioEstadoTjDebito;
  state.mensajeErrorCambioEstadoTjDebito = '';
  state.errorCambioEstadoTjDebito = false;
  state.statusCambioEstadoTjDebito = true;
}

function fetchCambioEstadoTarjetaDebitoFailed(state, action) {
  const { mensajeErrorCambioEstadoTjDebito } = action.payload;
  state.cambioEstadoTjDebito = false;
  state.mensajeErrorCambioEstadoTjDebito = mensajeErrorCambioEstadoTjDebito;
  state.errorCambioEstadoTjDebito = true;
  state.statusCambioEstadoTjDebito = false;
}

function clearEstadoTarjetaDebito(state) {
  state.cambioEstadoTjDebito = false;
  state.mensajeErrorCambioEstadoTjDebito = '';
  state.errorCambioEstadoTjDebito = false;
  state.statusCambioEstadoTjDebito = false;
}

/*
 * Here you should create actions that won't intereact with the store.
 * Prefer for actions that will comunicate with Saga
 */

const fetchCuentasVista = createAction('fetchCuentasVista');
const fetchTarjetasCuentaVista = createAction('fetchTarjetasCuentaVista');
const fetchUltimosMovimientosCuentaVista = createAction('fetchUltimosMovimientosCuentaVista');
const fetchMovimientosCuentaVista = createAction('fetchMovimientosCuentaVista');
const fetchPDFMovimientosCuentaVista = createAction('fetchPDFMovimientosCuentaVista');
const fetchDashboardProductos = createAction('fetchDashboardProductos');
const fetchAllTarjetasCV = createAction('fetchAllTarjetasCV');
const fetchCambioEstadoTarjetaDebito = createAction('fetchCambioEstadoTarjetaDebito');
const fetchBloqueoTemporalTarjeta = createAction('fetchBloqueoTemporalTarjeta');

// ------------- connection ------------- //
const productReducer = createSlice({
  name: 'productEntity',
  initialState: initState,
  reducers: {
    fetchCuentasVistaSuccess,
    fetchCuentasVistaFailed,
    fetchTarjetasCuentaVistaSuccess,
    fetchTarjetasCuentaVistaError,
    fetchUltimosMovimientsoCuentaVistaSuccess,
    fetchUltimosMovimientsoCuentaVistaError,
    fetchMovimientosCuentaVistaSuccess,
    fetchLoadingMvtsCurrentAccountSuccess,
    fetchBloqueoTemporalTarjetaSuccess,
    fetchBloqueoTemporalTarjetaError,
    fetchPDFMovimientosCuentaVistaSuccess,
    fetchPDFMovimientosCuentaVistaError,
    resetDataPDFMovimientos,
    fetchDashboardProductosSuccess,
    fetchDashboardProductosFailed,
    fetchAllTarjetasCVSuccess,
    fetchAllTarjetasCVError,
    clearTarjetasCuentaVista,
    fetchCambioEstadoTarjetaDebitoSuccess,
    fetchCambioEstadoTarjetaDebitoFailed,
    clearEstadoTarjetaDebito,
  },
});

export const productActions = {
  ...productReducer.actions,
  fetchCuentasVista,
  fetchTarjetasCuentaVista,
  fetchUltimosMovimientosCuentaVista,
  fetchMovimientosCuentaVista,
  fetchPDFMovimientosCuentaVista,
  fetchBloqueoTemporalTarjeta,
  fetchDashboardProductos,
  fetchAllTarjetasCV,
  fetchCambioEstadoTarjetaDebito,
};

export default productReducer.reducer;
