import styled from 'styled-components';

const Wrapper = styled.div`
  .titlePasos {
    font-size: 24px;
    font-family: 'Ubuntu-Bold';
    @media (max-width: 420px) {
      font-size: 18px;
    }
  }

  .body__container {
    display: flex;
    flex-flow: column wrap;
  }

  .card__container {
    width: 100%;
    font-size: 14px;
    border-bottom: 1px solid #f8f8f8;
  }

  @media (max-width: 768px) {
    .card__container {
      height: auto;
    }
  }

  .colorbg {
    background: rgba(217, 241, 251, 0.5);
  }

  .card__content-info {
    display: flex;
    height: 128px;
  }

  @media (max-width: 606px) {
    .card__content-info {
      width: 100%;
      height: auto;
      padding-bottom: 2px;
    }
  }

  .card__container-info {
    width: 100%;
    display: flex;
    @media (max-width: 768px) {
      padding-bottom: 20px;
    }
  }

  .card__number p {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    margin-top: 22px;
    margin-left: 32px;
    margin-right: 16px;
    font-size: 15px;
    color: #007db7;
    border: 1px solid #007db7;
    border-radius: 999px;
  }

  .color p {
    color: #ffffff;
    border: 0px;
    background: #007db7;
  }

  .titleFamily {
    font-family: 'Ubuntu-Bold';
  }

  .card__title {
    display: flex;
    width: 100%;
    max-width: 680px;
    font-size: 18px;
    margin-top: 24px;
  }

  @media (max-width: 500px) {
    .card__title {
      flex-flow: column wrap;
    }
    .card__title p {
      font-size: 18px;
      line-height: 24px;
      padding-right: 16px;
    }
  }

  .card__title b {
    font-size: 18px;
    padding-left: 15px;
    color: #007db7;
  }

  @media (max-width: 500px) {
    .card__title b {
      padding-left: 5px;
    }
  }

  @media (max-width: 500px) {
    .card__title b {
      font-size: 14px;
      padding-left: 0px;
      line-height: 20px;
      margin-top: 7px;
    }
  }

  .card__content {
    width: 100%;
    max-width: 680px;
    font-size: 14px;
    line-height: 20px;
    margin-top: 4px;
  }

  @media (max-width: 768px) {
    .card__content {
      font-size: 14px;
      padding-right: 16px;
    }
  }

  @media (max-width: 500px) {
    .card__content {
      width: 100%;
      margin-top: 5px;
      max-width: 300px;
    }
  }

  .correo-icon,
  .tarjeta-icon,
  .candado-icon,
  .celular-icon {
    width: 80px;
    height: 80px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-right: 32px;
  }

  @media (max-width: 768px) {
    .card__img {
      display: none;
    }
  }
`;

export default Wrapper;
