import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform:rotate(0deg);
  }

  to {
    transform:rotate(360deg);
  }
`;

const Wrapper = styled.div`
  .btnUpload {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;

    div > svg {
      font-size: 25px !important;
      font-weight: 500;
      animation: ${spin} 1.5s linear infinite;
    }

    p {
      font-size: 14px;
      color: #e81e2b;
      padding-left: 10px;
      font-family: 'Ubuntu-Medium';
      margin-top: 8px;
    }
  }
`;

export default Wrapper;
