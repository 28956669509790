import styled from 'styled-components';

export const IconWrapper = styled.div``;

export const LabelWrapper = styled.label`
  float: left;
  font-size: ${({ main }) => (main ? '16px' : '14px')};
  color: ${({ main }) => (main ? '#383638' : '#6d6d6d')};
  font-weight: ${({main})=> main? "bold": "normal"};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  float: ${({ float }) => float};

  & > div {
    /* position: absolute; */
    margin-bottom: auto;
    line-height: 50px;
    margin-top: auto;
    bottom: 0;
    right: 0;
    top: 0;
  }
`;

export default Wrapper;
