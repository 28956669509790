/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

  & small {
    display: none;
  }

  @media (max-width: 768px) {
    padding: 24px 1.5rem
  }

.textDatosBancarios{
  line-height: 18px;
}

 .rutDisabled{
  color:#383638;
  border: 1px solid #646569;
  background-color: #F6F6F6;
 }

 .textLegal{
  line-height: 22px;
 }

  #tw {
    display: inline-block;
    .tooltip-acreencias {
        bottom: 27px !important;
        left: -200px !important;
        width: 250px;
      }
      .tooltip-acreencias::after {
        left: 80% !important;
      }
    }

   @media (min-width: 768px) {
    #datosBancarios-next-action{
      width: 160px;
    }
  }

  .alertCuentaNoValida{
    font-size: 14px;
  }

`;


