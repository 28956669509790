/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/gestionClave';

// ------- SAGA ------ //

function validarRutToCrearClaveSuccess(state, action) {
  const { track } = action.payload;

  state.trackId = track;
  state.errorValidarRut = '';
}

function validarRutToCrearClaveFailed(state, action) {
  const { message } = action.payload;

  state.trackId = '';
  state.errorValidarRut = message;
}

function validarRutToCrearClaveReset(state) {
  state.trackId = '';
  state.errorValidarRut = '';
}

function fetchFactoresSeguridadSuccess(state, action) {
  const { factoresSeguridad } = action.payload;
  state.factoresSeguridad = factoresSeguridad;
  state.errorFactoresSeguridad = false;
}

function fetchFactoresSeguridadFailed(state) {
  state.factoresSeguridad = [];
  state.errorFactoresSeguridad = true;
}

function fetchReglasFactoresSuccess(state, action) {
  const { reglasFactores } = action.payload;
  state.reglasFactores = reglasFactores;
  state.errorReglasFactores = false;
}

function fetchReglasFactoresFailed(state) {
  state.reglasFactores = [];
  state.errorReglasFactores = true;
}

function fetchDesafioOTPSuccess(state) {
  state.envioDesafioOTP = true;
}

function fetchDesafioOTPFailed(state) {
  state.envioDesafioOTP = false;
}

function validarUsingFactorFailed(state, action) {
  const { error, type } = action.payload;

  state[type || 'errorFactoresMessage'] = error;
}

function validarDesafioOTPSuccess(state) {
  state.desafioOTPValido = true;
}

function fetchDatosPersonalesSuccess(state, action) {
  const { datosPersonales } = action.payload;
  state.datosPersonales = datosPersonales;
  state.errorDatosPersonales = false;
  state.errorDatosSinTelefonoIBS = false;
}

function fetchDatosPersonalesFailed(state) {
  state.datosPersonales = '';
  state.errorDatosPersonales = true;
  state.errorDatosSinTelefonoIBS = false;
}

function fetchDatosPersonalesWithoutPhone(state) {
  state.datosPersonales = '';
  state.errorDatosPersonales = false;
  state.errorDatosSinTelefonoIBS = true;
}

function fetchReglasCrearClaveSuccess(state, action) {
  const { reglaClaves } = action.payload;

  state.reglaClaves = reglaClaves;
  state.errorReglaClaves = '';
}

function fetchReglasCrearClaveFailed(state, action) {
  const { message } = action.payload;

  state.reglaClaves = [];
  state.errorReglaClaves = message;
}

function crearORecuperarClaveSuccess(state) {
  state.successCrearClave = true;
  state.errorCrearClave = false;
  state.errorClaveMessage = {};
}

function crearORecuperarClaveFailed(state, action) {
  const { message } = action?.payload || {};

  state.successCrearClave = false;
  state.errorCrearClave = true;
  state.errorClaveMessage = message;
}

function clearCrearORecuperarStates(state) {
  state.successCrearClave = false;
  state.errorCrearClave = false;
  state.errorClaveMessage = '';
}

function selectMetodoSuccess(state) {
  state.successSelectMetodo = true;
}

function selectMetodoFailed(state) {
  state.successSelectMetodo = false;
}

function returnMetodo(state) {
  state.successSelectMetodo = false;
  state.datosPersonales = '';
  state.errorDatosPersonales = false;
  state.errorDatosSinTelefonoIBS = false;
  state.envioDesafioOTP = false;
}

function fetchDesafioTCOOSuccess(state, action) {
  const { desafio1, desafio2, desafio3 } = action.payload;

  state.desafio1 = desafio1;
  state.desafio2 = desafio2;
  state.desafio3 = desafio3;
}

function validarDesafioTCOOSuccess(state, action) {
  const { desafioTCOOValido } = action.payload;

  state.desafioTCOOValido = desafioTCOOValido;
}

function resetAllState(state) {
  Object.assign(state, initialState);
}

function fetchDesafioCPASSSuccess(state, action) {
  const { obtencionFactorCPASS } = action.payload;
  state.obtencionFactorCPASS = obtencionFactorCPASS;
}

function validarDesafioCPASSSuccess(state, action) {
  const { desafioCPASSValido } = action.payload;
  state.desafioCPASSValido = desafioCPASSValido;
}
function redirectSuccess(state, action) {
  state.redirectOrigin.data = action.payload;
  state.redirectOrigin.error = null;
}
function redirectError(state, action) {
  state.redirectOrigin.data = null;
  state.redirectOrigin.error = action.payload;
}
const validarRutToCrearClave = createAction('validarRutToCrearClave');
const resetErrorValidarRut = createAction('resetErrorValidarRut');
const fetchFactoresSeguridadCrearClave = createAction('fetchFactoresSeguridadCrearClave');
const fetchReglasFactores = createAction('fetchReglasFactores');
const fetchDesafioOTP = createAction('fetchDesafioOTP');
const validarDesafioOTP = createAction('validarDesafioOTP');
const fetchDatosPersonalesCrearClave = createAction('fetchDatosPersonalesCrearClave');
const fetchReglasCrearClave = createAction('fetchReglasCrearClave');
const crearClave = createAction('crearClave');
const recuperarClave = createAction('recuperarClave');
const selectMetodo = createAction('selectMetodo');
const fetchDesafioTCOO = createAction('fetchDesafioTCOO');
const validarDesafioTCOO = createAction('validarDesafioTCOO');
const fetchDesafioCPASS = createAction('fetchDesafioCPASS');
const validarDesafioCPASS = createAction('validarDesafioCPASS');
const redirectFromDiffOrigins = createAction('redirectFromDiffOrigins');

const gestionClaveReducer = createSlice({
  name: 'gestionClave',
  initialState,
  reducers: {
    validarRutToCrearClaveSuccess,
    validarRutToCrearClaveFailed,
    validarRutToCrearClaveReset,
    fetchFactoresSeguridadSuccess,
    fetchFactoresSeguridadFailed,
    fetchReglasFactoresSuccess,
    fetchReglasFactoresFailed,
    fetchDesafioOTPSuccess,
    fetchDesafioOTPFailed,
    fetchDatosPersonalesSuccess,
    fetchDatosPersonalesFailed,
    fetchDatosPersonalesWithoutPhone,
    validarUsingFactorFailed,
    validarDesafioOTPSuccess,
    fetchReglasCrearClaveSuccess,
    fetchReglasCrearClaveFailed,
    crearORecuperarClaveSuccess,
    crearORecuperarClaveFailed,
    clearCrearORecuperarStates,
    selectMetodoSuccess,
    selectMetodoFailed,
    fetchDesafioTCOOSuccess,
    validarDesafioTCOOSuccess,
    resetAllState,
    fetchDesafioCPASSSuccess,
    validarDesafioCPASSSuccess,
    returnMetodo,
    redirectSuccess,
    redirectError,
  },
});

export const gestionClaveActions = {
  ...gestionClaveReducer.actions,
  validarRutToCrearClave,
  resetErrorValidarRut,
  fetchFactoresSeguridadCrearClave,
  fetchReglasFactores,
  fetchDesafioOTP,
  fetchDatosPersonalesCrearClave,
  validarDesafioOTP,
  fetchReglasCrearClave,
  crearClave,
  recuperarClave,
  selectMetodo,
  fetchDesafioTCOO,
  validarDesafioTCOO,
  fetchDesafioCPASS,
  validarDesafioCPASS,
  redirectFromDiffOrigins,
};

export default gestionClaveReducer.reducer;
