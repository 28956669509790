/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/solicitudCuentaVista';

function fetchCuentasSuccess(state, action) {
  state.productosDisponibles = {
    ...state.productosDisponibles,
    cuentas: action.payload,
  };
}
function fetchCuentasError(state) {
  state.productosDisponibles.error = true;
}
function fetchTarjetasSuccess(state, action) {
  state.productosDisponibles = {
    ...state.productosDisponibles,
    tarjetas: action.payload,
  };
}
function fetchTarjetasError(state) {
  state.productosDisponibles.error = true;
}
function guardarDatosPersonalesSuccess(state, action) {
  state.guardarDatosPersonales = { ...action.payload.data, success: true };
}
function guardarDatosPersonalesError(state) {
  state.guardarDatosPersonales = { success: false, error: true };
}
function guardarSucursalSuccess(state, action) {
  state.guardarSucursal = { ...action.payload.data, success: true };
}
function guardarSucursalError(state) {
  state.guardarSucursal = { success: false, error: true };
}
function firmarSolicitudSuccess(state, action) {
  state.firmarSolicitud = { ...action.payload.data, success: true };
}
function firmarSolicitudError(state, action) {
  state.firmarSolicitud = { success: false, error: true, code: action.payload.error };
}
function getDetalleContratacionSuccess(state, action) {
  state.getDetalleContratacion = { ...action.payload.data, success: true };
}
function getDetalleContratacionError(state) {
  state.getDetalleContratacion = { success: false, error: true };
}
function obtenerComprobanteSuccess(state, action) {
  state.obtenerComprobante = { data: action.payload.data, success: true };
}
function obtenerComprobanteError(state) {
  state.obtenerComprobante = { success: false, error: true };
}
function obtenerContratoSuccess(state, action) {
  state.obtenerContrato = { data: action.payload.data, success: true };
}
function obtenerContratoError(state) {
  state.obtenerContrato = { success: false, error: true };
}
function enviarComprobanteSuccess(state, action) {
  state.enviarComprobante = { ...action.payload.data, success: true };
}
function enviarComprobanteError(state) {
  state.enviarComprobante = { success: false, error: true };
}
function validarSolicitudSuccess(state, action) {
  state.trackValidarSolicitud = action.payload.trackValidarSolicitud;
  state.validarSolicitudError = false;
  state.validarSolicitudErrorCode = '';
}
function validarSolicitudFailed(state, action) {
  state.trackValidarSolicitud = '';
  state.validarSolicitudError = true;
  state.validarSolicitudErrorCode = action.payload.code;
}

function bannerContratacionCVSuccess(state, action) {
  state.bannerContratacion = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function bannerContratacionCVError(state, action) {
  state.bannerContratacion = {
    success: false,
    error: true,
    errorCode: action.payload,
    data: null,
    date: new Date().toISOString(),
  };
}

function goToFlujoContratacionCV(state) {
  state.goToFlujoContratacionCV = true;
}

function notGoFlujoContratacionCV(state) {
  state.goToFlujoContratacionCV = false;
}

function fetchObtenerDesafiosSuccess(state, action) {
  state.availablePassCoopeuch = { data: action.payload, success: true };
}
function fetchObtenerDesafiosError(state) {
  state.availablePassCoopeuch = { success: false, error: true };
}

function solicitarPassCoopeuchSuccess(state, action) {
  state.solicitarDesafioPassCoopeuch = { ...action.payload, success: true };
}

function solicitarPassCoopeuchError(state) {
  state.solicitarDesafioPassCoopeuch = { success: false, error: true };
}

function solicitarPassCoopeuchResetSuccess(state) {
  state.solicitarDesafioPassCoopeuch = { success: null, error: null, data: null };
}

function validarPassCoopeuchCPSuccess(state, action) {
  state.IsValidatePassCoopeuch = { ...action.payload, success: true };
}

function validarPassCoopeuchCPError(state) {
  state.IsValidatePassCoopeuch = { success: false, error: true };
}

function validarPassCoopeuchCPResetSuccess(state) {
  state.IsValidatePassCoopeuch = { success: null, error: null, data: null };
}

function onHandleObtenerDesafioSuccess(state, action) {
  state.desafio = { ...action.payload, success: true, error:{code:null} };
}

function onHandleObtenerDesafioError(state, action) {
  state.desafio = { ...action.payload, success: false };
  if(!state.desafio.error){
    state.desafio={error:{code:null},success:false};
  } else if(!state.desafio.error.code){
    state.desafio={error:{code:null},success:false};
  }
}

function onHandleObtenerDesafioResetSuccess(state) {
  state.desafio = { success: null, error: {code:null}, data:null };
}

function validarDesafioSuccess(state, action) {
  state.validaDesafio = { ...action.payload, success: true, error:{code:null} };
}

function validarDesafioError(state, action) {
  state.validaDesafio = { ...action.payload, success: false };
  if(!state.validaDesafio.error){
    state.validaDesafio={error:{code:null},success:false};
  } else if(!state.validaDesafio.error.code){
    state.validaDesafio={error:{code:null},success:false};
  }
}

function validarDesafioResetSuccess(state) {
  state.validaDesafio = { success: null, error: {code:null}, data:null };
}

const fetchCuentas = createAction('fetchCuentas');
const fetchTarjetas = createAction('fetchTarjetas');
const guardarDatosPersonales = createAction('guardarDatosPersonales');
const guardarSucursal = createAction('guardarSucursal');
const firmarSolicitud = createAction('firmarSolicitud');
const getDetalleContratacion = createAction('getDetalleContratacion');
const obtenerComprobante = createAction('obtenerComprobante');
const obtenerContrato = createAction('obtenerContrato');
const enviarComprobante = createAction('enviarComprobante');
const validarSolicitud = createAction('validarSolicitud');
const fetchBannerContratacionCV = createAction('fetchBannerContratacionCV');
const fetchObtenerDesafios = createAction('fetchObtenerDesafios');
const solicitarPassCoopeuch = createAction('solicitarPassCoopeuch');
const validarPassCoopeuchCP = createAction('validarPassCoopeuchCP');
const onHandleObtenerDesafio = createAction('onHandleObtenerDesafio');
const validarPassCoopeuchCPReset = createAction('validarPassCoopeuchCPReset');
const solicitarPassCoopeuchReset = createAction('solicitarPassCoopeuchReset');
const onHandleObtenerDesafioReset = createAction('onHandleObtenerDesafioReset');
const validarDesafio = createAction('validarDesafio');
const validarDesafioReset = createAction('validarDesafioReset');

// ------------- connection ------------- //

const solicitudCVReducer = createSlice({
  name: 'solicitudCuentaVista',
  initialState,
  reducers: {
    fetchCuentasSuccess,
    fetchCuentasError,
    fetchTarjetasSuccess,
    fetchTarjetasError,
    guardarDatosPersonalesSuccess,
    guardarDatosPersonalesError,
    guardarSucursalSuccess,
    guardarSucursalError,
    firmarSolicitudSuccess,
    firmarSolicitudError,
    getDetalleContratacionSuccess,
    getDetalleContratacionError,
    obtenerComprobanteSuccess,
    obtenerComprobanteError,
    obtenerContratoSuccess,
    obtenerContratoError,
    enviarComprobanteSuccess,
    enviarComprobanteError,
    validarSolicitudSuccess,
    validarSolicitudFailed,
    bannerContratacionCVSuccess,
    bannerContratacionCVError,
    goToFlujoContratacionCV,
    notGoFlujoContratacionCV,
    fetchObtenerDesafiosSuccess,
    fetchObtenerDesafiosError,
    solicitarPassCoopeuchSuccess,
    solicitarPassCoopeuchError,
    validarPassCoopeuchCPSuccess,
    validarPassCoopeuchCPError,
    onHandleObtenerDesafioSuccess,
    onHandleObtenerDesafioError,
    validarPassCoopeuchCPResetSuccess,
    solicitarPassCoopeuchResetSuccess,
    onHandleObtenerDesafioResetSuccess,
    validarDesafioSuccess,
    validarDesafioError,
    validarDesafioResetSuccess,
  },
});

export const solicitudCVActions = {
  ...solicitudCVReducer.actions,
  fetchCuentas,
  fetchTarjetas,
  guardarDatosPersonales,
  guardarSucursal,
  firmarSolicitud,
  getDetalleContratacion,
  obtenerComprobante,
  obtenerContrato,
  enviarComprobante,
  validarSolicitud,
  fetchBannerContratacionCV,
  fetchObtenerDesafios,
  solicitarPassCoopeuch,
  validarPassCoopeuchCP,
  onHandleObtenerDesafio,
  validarPassCoopeuchCPReset,
  solicitarPassCoopeuchReset,
  onHandleObtenerDesafioReset,
  validarDesafio,
  validarDesafioReset,
};

export default solicitudCVReducer.reducer;
