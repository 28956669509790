import styled from 'styled-components';

export const AlertWrapper = styled.div`
  .alert-cdc {
    .right-content > :first-child {
      width: unset;
      margin-top: 3px;
    }
    .right-content {
      align-items: flex-start;
      * > svg {
        width: auto;
      }
    }
  }
`;

export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;

const Message = styled.div`
  background-color: #ccf1c1;
  padding: 11px 4px;
  width: 100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #006b14;

    path {
      fill: #006b14;
    }
  }
  .align {
    display: flex;
    align-items: center;
  }
`;

export default Message;
