import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// actions
import { bonoEscolarActions } from '../../../../application/actions/bonoEscolar';
// Template
import HomeTemplate from '../../../components/templates/BonoEscolar/Home';
// Metrics
import { registerGA } from '../../../utils/metrics';

const Home = ({ currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [visibleModal, setVisibleModal] = useState(false);
  const [checked, setChecked] = useState(false);

  // Stores
  const userType = useSelector((store) => store.entities.bonoEscolar.userType);
  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);
  const dataSolicitudes = useSelector((store) => store.entities.bonoEscolar.listaSolicitudes);

  const handleClickBtn = (typeBono) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios',
      'eve-cat': 'Click',
      'eve-lab': `Postula aqui - ${typeBono === 'Bono de Ingreso' ? 'Ingreso' : 'Titulacion'}`,
    });
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios',
      'eve-cat': 'Mensaje',
      'eve-lab': `Consentimiento para el tratamiento de datos personales - ${
        typeBono === 'Bono de Ingreso' ? 'Ingreso' : 'Titulacion'
      }`,
    });
    const params = {
      typeBono,
    };
    dispatch(bonoEscolarActions.setStatesTypeBono(params));
    setVisibleModal(true);
  };

  const handleClickCloseModalCerrar = () => {
    setVisibleModal(false)
    setChecked(false)
  }

  const handleClickModalContinuar = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios',
      'eve-cat': 'Click',
      'eve-lab': `Aceptar Consentimiento - ${
        dataBono.typeBono === 'Bono de Ingreso' ? 'Ingreso' : 'Titulacion'
      }`,
    });
    history.push('/ecd/bono-escolar/confirmacion');
    setCurrentStep(currentStep + 1);
  }

  const handleCheckBox = () => {
    setChecked((prevState) => !prevState);
  };

  const toHome = () => {
    history.push('/ecd/inicio');
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-solicitudes',
      'eve-cat': 'Click',
      'eve-lab': 'Volver- sin solicitud',
    });
  };

  const handleClickCuentaVista = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios',
      'eve-cat': 'Click',
      'eve-lab': 'Contratar Cuenta Vista',
    });
    history.push('/ecd/solicita/cuenta-coopeuch');
  };

  const handleClickRetry = (text) => {
    registerGA({
      event: 'eve',
      'eve-acc': 'home',
      'eve-cat': 'Error',
      'eve-lab': text,
    });
    history.push('/ecd/inicio');
  };

  const handleClickBonusTab = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios',
      'eve-cat': 'Click',
      'eve-lab': 'Bonos',
    });
  };

  const handleClickApplicationsTab = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/beneficios/bono-estudios',
      'eve-cat': 'Click',
      'eve-lab': 'Mis Solicitudes',
    });
    registerGA({
      event: 'vpv-privado',
      page: '/beneficios/bono-estudios/solicitudes',
    });
    if (dataSolicitudes?.data?.lista?.length === 0) {
      registerGA({
        event: 'eve',
        'eve-acc': '/beneficios/bono-solicitudes',
        'eve-cat': 'Error',
        'eve-lab': 'Sin solicitud',
      });
    }
  };

  // Validate routes
  useEffect(() => {
    setCurrentStep(0);
    setIsLoading(true);
    dispatch(bonoEscolarActions.setResetValues());
    return () => {
      dispatch(bonoEscolarActions.setResetValuesHome());
    };
  }, []);

  // Validate type user
  useEffect(() => {
    dispatch(bonoEscolarActions.fetchTypeUser());
    registerGA({
      event: 'vpv-privado',
      page: '/beneficios/bono-estudios',
    });
  }, []);

  // Loading fetch type user
  useEffect(() => {
    if (userType.data !== null || userType.error !== false) {
      setIsLoading(false);
    }
    if (userType?.error === true) {
      registerGA({
        event: 'eve',
        'eve-acc': 'home',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [userType]);

  // Analytics by user type
  useEffect(() => {
    switch (userType?.data?.regla) {
      case 'ANTIGUEDAD':
      case 'CLIENTE':
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios',
          'eve-cat': 'Mensaje',
          'eve-lab': 'Debes tener un año de antiguedad como socio para postular',
        });
        break;
      case 'MORA_ACTUAL':
      case 'MORA_6_MESES':
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios',
          'eve-cat': 'Mensaje',
          'eve-lab': 'Debes cumplir con los compromisos de pago para postular',
        });
        break;
      case 'SIN_CUENTA_VISTA':
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios',
          'eve-cat': 'Mensaje',
          'eve-lab': 'Contratar Cuenta Vista',
        });
        break;
      default:
    }
  }, [userType]);

    // Validate lista Solicitudes
    useEffect(() => {
      dispatch(bonoEscolarActions.fetchListaSolicitudes());
    }, []);

  return (
    <HomeTemplate
      {...{
        handleClickBtn,
        userType,
        handleClickCuentaVista,
        isLoading,
        handleClickRetry,
        visibleModal,
        handleClickCloseModalCerrar,
        handleClickModalContinuar,
        checked,
        handleCheckBox,
        dataSolicitudes,
        toHome,
        handleClickBonusTab,
        handleClickApplicationsTab,
      }}
    />
  );
};

export default Home;
