export function isAlphanumeric(string) {
  return string ? string.replace(/[^0-9]/g, '') : string;
}

export function isAlphabetWithSpaces(string) {
  return string ? string.replace(/[0-9]/g, '') : string;
}

export function isAlphanumericWithSpaces(string) {
  return string ? string.replace(/[^A-Za-z-0-9 ]/g, '') : string;
}

export function isAlphabetWithSpacesScript(string) {
  return string ? string.replace(/[^A-Za-z -]/g, '') : string;
}

export const formatRut = (rut) => {
  let clearRut = rut.toLocaleLowerCase().replace(/[^\dk]/g, '');

  if (clearRut.length > 1) {
    const lastDigit = clearRut.slice(-1);
    const formattedRut = clearRut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    clearRut = `${formattedRut}-${lastDigit}`;
  }

  return clearRut;
};

export const formatRutSinPuntos = (rut) => {
  let clearRut = rut.toLocaleLowerCase().replace(/[^\dk]/g, '');

  if (clearRut.length > 1) {
    const lastDigit = clearRut.slice(-1);
    const formattedRut = clearRut.slice(0, -1);

    clearRut = `${formattedRut}-${lastDigit}`;
  }

  return clearRut;
};

export const normalizeString = (string = '') => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

function isNumeric(number) {
  const regex = new RegExp('^[0-9]+$');
  return regex.test(number?.trim());
}

export function serieFormat(serie) {
  if (!isNumeric(serie?.substr(0, 1))) {
    return serie?.length <= 10 ? serie : serie?.substr(0, 10);
  } else {
    return serie
      .toString()
      .replace(/\./g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}

export function regexOnlyNumber(word) {
  return word ? String(word).replace(/[^0-9]/g, '') : word;
}

export function rutUnformat(rut) {
  return rut ? rut.replace(/[^0-9kK]/g, '').replace(/^0+/, '') : rut;
}

export function formatThousandsSeparators(word) {
  try {
    var formatePuntos = word
      .toString()
      .replace(/\./g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    var formateThousandsSeparators = formatePuntos;
    return formateThousandsSeparators;
  } catch (error) {
    return '';
  }
}

export const agregarFormatoDolar = (numero) => {
  try {
    if (numero.toString().includes('.')) {
      const numbers = numero.toString().split('.');
      return formatThousandsSeparators(numbers[0]) + ',' + numbers[1];
    } else {
      return formatThousandsSeparators(numero);
    }
  } catch (error) {
    return '';
  }
};

export function formatSecondToMinutes(seconds) {
  if (seconds) {
    var minute = Math.floor((seconds / 60) % 60);
    minute = minute < 10 ? '0' + minute : minute;
    var second = seconds % 60;
    second = second < 10 ? '0' + second : second;
    return minute + ':' + second;
  } else {
    return '00:00';
  }
}
/**
 *  Este método recibe una cadena numerica, retorna la
 *  cadena con formato de moneda local CLP.
 */
export const agregarformatoPesos = (numero) => {
  try {
    return `$${formatThousandsSeparators(numero)}`;
  } catch (error) {
    return '';
  }
};

/**
 *  Este método recibe una cadena numerica, retorna la
 *  cadena con formato de puntos miles.
 */
export const agregarformatoPuntos = (numero) => {
  try {
    return `${formatThousandsSeparators(numero)}`;
  } catch (error) {
    return '';
  }
};

export const agregarFormatoDecimal = (numero) => {
  try {
    if (numero?.toString().includes('.')) {
      const numbers = numero?.toString().split('.');
      return formatThousandsSeparators(numbers[0]) + ',' + numbers[1];
    } else {
      return formatThousandsSeparators(numero);
    }
  } catch (error) {
    return '';
  }
};

/**
 *  Método que recibe una cadena de texto con el formato
 *  de moneda local, retorna la cadena sin formato.
 */
export const limpiarFormatoPesos = (string) => {
  return string.toString().replace(/[.]/g, '').replace('$', '');
};

/**
 * Método que recibe una cadena y valida que tenga formato de email
 * retorna true en caso de ser valido
 */
export const isValidEmail = (email) => {
  const expRegular =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
  return expRegular.test(email?.toLowerCase());
};

export const formatAccountNumber = (account) => {
  return account
    .toString()
    .split(/(?=(?:...)*$)/)
    .join(' ');
};

/**
 * Función para formatear String con espacios a formato capital
 * @param {str}
 * @returns {str}
 */
export const capitalizeStrFormat = (str) => {
  const convertInArray = str?.split(' ');
  const lowerCaseArray = convertInArray.map((item) => item.toLowerCase());
  const capitalizeArray = lowerCaseArray.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const string = capitalizeArray.toString();
  const deleteComma = string.replace(/,/gi, ' ');
  return deleteComma;
};

/**
 * Funcion para formatear array para componente Select de biblioteca
 * https://componenteswebecd.coopeuch.cl/
 * @param {Array.<Object>}
 * @param {string} keyA
 * @param {string} keyB
 * @return {Array.<Object{value:number, label:String }>} retorna un array de objetos
 */
export const formatArrayForSelect = (data, keyA = '', keyB = '', valueA = '', valueB = '') =>
  data.map((item) => ({
    [keyA]: item[valueA],
    [keyB]: item[valueB]
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
  }));

/**
 * TODO: Refactor this Function
 */
export const formatArrayForSelectThreeV = (
  data,
  keyA = '',
  keyB = '',
  keyC = '',
  valueA = '',
  valueB = '',
  valueC = ''
) =>
  data.map((item) => ({
    [keyA]: item[valueA],
    [keyB]: item[valueB]
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' '),
    [keyC]: item[valueC],
  }));

/**
 * Funcion simplificada para componente Select
 * @param {Array.<Object>} data
 * @param {string} keyA
 * @param {string} keyB
 * @returns {Array.<Object>}
 */
export const simpleFormatArrForSelect = (data, keyA = '', keyB = '') =>
  data.map((item) => ({
    [keyA]: item,
    [keyB]: item,
  }));

/**
 * Función para pasar string a minusculas y quitar acentos
 * @param {string} str
 * @returns {string}
 */
export const lowerCaseAndRemoveAccents = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

/**
 * Función para normalizar string,
 * quitar acentos, eliminar espacios en blanco y pasar a minusculas
 * @param {string} str
 * @returns {string}
 */
export const lowerCaseRemoveAccentsDeleteSpaces = (str) =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^-A-Za-z0-9]+/g, '')
    .toLowerCase();

/**
 * Función para separar numero de telefono con 2 espacios
 * ej: 995471475 retorna '99 547 1475'
 *
 * @param {number} number
 * @returns {string}
 */
export const phoneWithTwoSpaces = (number) => {
  const phone = number.toString();
  return `${phone.slice(0, 2)} ${phone.slice(2, 5)} ${phone.slice(5)}`;
};

/**
 * TODO: Refactor this Function
 */
export const formatCellPhone = (phone) => {
  let temporal = phone.toString();
  const espacio = ' ';

  if (temporal.substr(0, 3) !== '+56') {
    temporal = `+56 ${phone}`;
  }
  if (temporal[3] !== ' ') {
    const slice = temporal.slice(0, 3);
    const resto = temporal.slice(4);
    temporal = `${slice}${espacio}${resto}`;
  }
  // Flujo cuando No incluye el 9
  if (temporal[4] !== '9') {
    const slice = temporal.slice(0, 4);
    const resto = temporal.slice(4);
    temporal = `${slice}9${resto}`;
  }
  // Flujo cuando incluye el 9
  if (temporal[4] === '9') {
    const slice = temporal.slice(0, 5);
    const resto = temporal.slice(5);
    temporal = slice + resto;
  }
  return temporal;
};

/**
 * TODO: Refactor this Function
 */
export const formatRegionPhone = (phone) => {
  let temporal = phone?.toString();
  const espacio = ' ';

  if (!temporal?.includes('+56')) {
    temporal = `+56${phone}`;
  }
  if (temporal[3] !== ' ') {
    const slice = temporal.slice(0, 3);
    const resto = temporal.slice(3);
    temporal = `${slice}${espacio}${resto}`;
  }
  return temporal;
};

/*
 * Función para formatear la fecha,
 * Deja el formato solicitado para las fechas (Día-Mes)
 * @param {string} str
 * @returns {string}
 */
export const cleanDate = (date) => new Date(date.replace(/-/g, '/'));

export const datePuntosCoopeuch = (date, fallback) => {
  let formattedDate = null;

  if (date) {
    const currentDate = typeof date === 'string' ? cleanDate(date.replace(/-/g, '/')) : date;

    formattedDate = currentDate.toLocaleDateString('es-cl', {
      day: 'numeric',
      month: 'long',
    });

    formattedDate.replace('-', ' de ');
  }

  return formattedDate || fallback;
};

/**
 * Funcion que sirve para traspasar un string a formato ellipsis
 * @returns {string}
 */

export const toEllipsis = ({ maxLength, text }) => {
  return text.length > maxLength ? text.slice(0, maxLength - 1) + '...' : text;
};

/**
 * Funcion que sirve para eliminar todos los guiones de un string
 * @returns {string}
 */

export const removeGuion = (text) => {
  return text.replace('-', '');
};

/*
Función que sirve para formatear la fecha
Deja el formato solicitado para las fechas ("Día" de "Mes" "Año" "Hora":"Minuto" hrs)
*/

export const generateDateFormat = (date) => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${day} de ${month} ${year} ${hours}:${minutes} hrs`;
};
/**
 * Funcion que recibe un string y transforma la oracion en Pascal Case: Ejemplo : cuenta rut => Cuenta Rut.
 * @returns string
 */
export const stringToPascalCase = (string) => {
  const arrayName = string.split(' ');
  let namePascalCase = '';
  arrayName.map((name) => {
    namePascalCase =
      namePascalCase + ' ' + name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  });
  return namePascalCase.trim();
};
export const removeAcentosYEnes = (string) => {
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
export const removeGuionesYPuntos = (string) => {
  return string.replace(/\./g, '').replace('-', '');
};

// Uppercase solo letras

export const upperCaseOnlyLetters = (string) => {
  const regex = /[^a-zA-Z]/g;
  const transformedInput = string.replace(regex, '').toUpperCase();
  return transformedInput;
};
