/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';

import { abonoAcreenciasRemanenteActions } from '../../../../application/actions/abonoAcreenciasRemanente';

import Error from '../Errors';
import StepsActions from '../../../components/organism/Stepper/StepsActions';
import AutorizacionTemplate from '../../../components/templates/AbonoAcreenciasRemanente/Autorizacion';

import { isEmailValid } from '../../../utils/validators';
import { useShallowStore } from '../../../utils/hooks';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';


function AutorizacionContainer(props) {
  const {
    persona,
    banco,
    tipoCuenta,
    numeroCuenta,
    email,
    setEmail,
    setEmailComprobante,
  } = props;
  
  const {
    loading: loadingFirmar,
    success: successFirmar,
    data: dataFirmar,
    error: errorFirmar,
    logErr: logError
  } = useShallowStore((store) => store.entities.abonoAcreenciasRemanente.poderEspecialFirmar);

  const [checked, setChecked] = useState(false);
  const [checkdRequiredError, setCheckdRequiredError] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordInputValidation, setPasswordInputValidation] = useState('');
  const [showPassword, setShowPassword] = useState(false)
  const [serviceError, setServiceError] = useState(false);
  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (successFirmar) {
      history.push('/ecd/poder-especial/comprobante');
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'PODER_ESPECIAL_FIRMA',
        informacion_adicional: {}
      })
    }
    return () => {
      dispatch(abonoAcreenciasRemanenteActions.clearFirmar());
    };
  }, [dispatch, successFirmar]);

  useEffect(() => {
    if (!banco && !tipoCuenta && !numeroCuenta && !email) {
      history.push('/ecd/poder-especial/datos-bancarios');
    }
  }, [persona, banco, tipoCuenta, numeroCuenta, email, history]);

  useEffect(() => {
    if (errorFirmar?.server) {
      setServiceError(true)
    }
    if (errorFirmar?.code === "01") {
      logger(logError, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'PODER_ESPECIAL_FIRMA',
        informacion_adicional: {}
      })
    }
    if (errorFirmar?.code === "02") {
      logger(logError, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'PODER_ESPECIAL_FIRMA',
        informacion_adicional: {}
      })
    }
    if (errorFirmar?.code === "03") {
      logger(logError, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'PODER_ESPECIAL_FIRMA',
        informacion_adicional: {}
      })
    }
    if (errorFirmar?.statusCode === "500") {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/remanente-acreencias/paso2',
        'eve-cat' : 'Error',
        'eve-lab' : 'Sin servicio',
        'ambiente' : 'sitio'
      });
      logger(logError, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'PODER_ESPECIAL_FIRMA',
        informacion_adicional: {}
      })
    }
  }, [logError]);

  useEffect(() => {
    if (password && !checked) {
      setCheckdRequiredError(true)
    }
  }, [password, checked]);

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      'page' : '/tef/remanente-acreencias/paso2',
      'ambiente' : 'sitio'
    });
  }, []);

  const handleGoBack = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/paso2',
      'eve-cat' : 'Click',
      'eve-lab' : 'Volver',
      'ambiente' : 'sitio'
    });
    history.goBack();
  }, [history]);

  useEffect(() => {
    if (serviceError) {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/remanente-acreencias/paso2',
        'eve-cat' : 'Error',
        'eve-lab' : 'Servicio no disponible',
        'ambiente' : 'sitio'
      });
    }
  }, [serviceError]);

  const handleChangeEmail = (event) => {
    const {value} = event.target;
    const errorMessage = 'Ingresa un email válido';
    setEmail(value);
    setEmailError(isEmailValid(value) ? '' : errorMessage)
  };

  const handleCheckBox = () => {
    if (!checked) {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/remanente-acreencias/paso2',
        'eve-cat' : 'Click',
        'eve-lab' : 'Aceptar terminos y condiciones',
        'ambiente' : 'sitio'
      });
    }
    setChecked((prevState) => !prevState);
    setCheckdRequiredError(false);
  };

  const handlePassword = ({ target: { value } }) => {
    setPassword(value);
    setPasswordInputValidation('');
    if (value.length < 6) {
      setPasswordInputValidation('La clave debe tener entre 6 y 8 caracteres.');
    }
  };

  const onWrongPassword = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/ref/remanente-acreencias-paso2',
      'eve-cat' : 'Error',
      'eve-lab' : 'Clave incorrecta - reintentar',
      'ambiente' : 'sitio'
    });
    dispatch(abonoAcreenciasRemanenteActions.clearFirmar());
    setShowPassword(false);
  };

  const onBlockedPassword = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias-paso2',
      'eve-cat' : 'Error',
      'eve-lab' : 'Clave bloqueada - cambiar clave',
      'ambiente' : 'sitio'
    });
    history.push('/recuperar-clave');
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  let handleValidationError = onWrongPassword;
  if (errorFirmar.claveBloqueada) {
    handleValidationError = onBlockedPassword;
  }

  let validationError = null;
  if (errorFirmar.claveInvalida || errorFirmar.claveBloqueada) {
    validationError = dataFirmar;
  }

  const buttonDisabled = !passwordInputValidation && password && email && !emailError && checked;
  const nextActionButtonDisabled =
    !buttonDisabled || !!validationError || loadingFirmar;

  const handleSubmit = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/paso2',
      'eve-cat' : 'Click',
      'eve-lab' : 'Continuar',
      'ambiente' : 'sitio'
    });
    dispatch(
      abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmar({
        codigoBanco: banco?.codigo,
        codigoTipoCuenta: tipoCuenta?.codigo,
        numeroCuenta,
        clave: password,
        email
      })
    );
    setEmailComprobante(email);
  }, [banco, tipoCuenta, numeroCuenta, password, email]);

  useEffect(() => {
    if (!banco.descripcion || !tipoCuenta.descripcion || numeroCuenta === 0) {
      setChecked(false);
      history.push('/ecd/poder-especial/datos-bancarios');
    }
  }, [banco, tipoCuenta, numeroCuenta, history]);

  const handleReintentar = (step) => {
    switch (step) {
      case 1:
        registerGA({
          event: 'eve',
          'eve-acc' : '/tef/remanente-acreencias/paso2',
          'eve-cat' : 'Error',
          'eve-lab' : 'Contraseña ingresada incorrecta',
          'ambiente' : 'sitio'
        });
        dispatch(abonoAcreenciasRemanenteActions.clearFirmar());
        setShowPassword(false);
      break;
      case 2:
        registerGA({
          event: 'eve',
          'eve-acc' : '/tef/remanente-acreencias/paso2',
          'eve-cat' : 'Error',
          'eve-lab' : 'Contraseña ingresada incorrecta - reintentar',
          'ambiente' : 'sitio'
        });
        dispatch(abonoAcreenciasRemanenteActions.clearFirmar());
        setShowPassword(false);
      break;
      case 3:
        registerGA({
          event: 'eve',
          'eve-acc' : '/tef/remanente-acreencias/paso2',
          'eve-cat' : 'Error',
          'eve-lab' : 'Clave bloqueada',
          'ambiente' : 'sitio'
        });
        localStorage.removeItem('token');
        localStorage.removeItem('user-rut');
        localStorage.removeItem('login-timedate');
        history.push('/recuperar-clave');
      break;
      case 4:
        registerGA({
          event: 'eve',
          'eve-acc' : '/tef/remanente-acreencias/paso2',
          'eve-cat' : 'Error',
          'eve-lab' : 'Sin servicio - Ir al home',
          'ambiente' : 'sitio'
        });
        dispatch(abonoAcreenciasRemanenteActions.clearFirmar());
        history.push('/ecd/inicio')
      break;
    }
  }
  return (
    <>
    {errorFirmar?.statusCode === "500" ? 
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        <Error
          title="Servicio no disponible"
          message="Estamos presentando problemas técnicos. Por favor, intenta más tarde"
          buttonText="Volver"
          onClick={() => handleReintentar(4)}
        />
      </div>
    :
    errorFirmar?.code === "01" ?
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        <Error
          title="¡Lo sentimos!"
          message="La contraseña es incorrecta. Por favor vuelva a intentar"
          buttonText="Reintentar"
          onClick={() => handleReintentar(1)}
        />
      </div>
    :
    errorFirmar?.code === "02" ?
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        <Error
          title="Datos incorrectos"
          message="La contraseña ingresada es incorrecta, por favor vuelve a intentar. Recuerda que al tercer intento erróneo, tu clave será bloqueada."
          buttonText="Reintentar"
          onClick={() => handleReintentar(2)}
        />
      </div>
    :
    errorFirmar?.code === "03" ?
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        <Error
            title="Clave bloqueada"
            message="Por tu seguridad, hemos bloqueado tu clave. Si lo deseas puedes volver a cambiar tu clave"
            buttonText="Cambiar clave"
            onClick={() => handleReintentar(3)}
        />
      </div>
    :
        <AutorizacionTemplate
          loading={loadingFirmar}
          persona={persona}
          banco={banco}
          tipoCuenta={tipoCuenta}
          numeroCuenta={numeroCuenta}
          checked={checked}
          handleChangeEmail={handleChangeEmail}
          checkdRequiredError={checkdRequiredError}
          email={email}
          emailError={emailError}
          handleCheckBox={handleCheckBox}
          password={password}
          handlePassword={handlePassword}
          passwordInputValidation={passwordInputValidation}
          handleValidationError={handleValidationError}
          showPassword={showPassword}
          togglePassword={togglePassword}
          validationError={validationError}
          handleGoBack={handleGoBack}
          handleSubmit={handleSubmit}
          nextActionButtonDisabled={nextActionButtonDisabled}
        />
      }
        
      {/* )} */}
    </>
  );
};

AutorizacionContainer.propTypes = {
  persona: PropTypes.object,
  banco: PropTypes.object,
  tipoCuenta: PropTypes.object,
  numeroCuenta: PropTypes.number,
  email: PropTypes.string,
  setEmail: PropTypes.func,
  setEmailComprobante: PropTypes.func,
};

AutorizacionContainer.defaultProps = {
  persona: {},
  banco: {},
  tipoCuenta: {},
  numeroCuenta: 0,
  email: '',
  setEmail: () => {},
  setEmailComprobante: () => {},
};

export default AutorizacionContainer;
