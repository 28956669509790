import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasActions } from '../../../../application/actions/activacionTarjetas';
import { activacionTarjetasKitActions } from '../../../../application/actions/activacionTarjetasKit';
// Template
import HomeTemplate from '../../../components/templates/ActivacionTarjetas/Home';
import HomeTemplateKit from '../../../components/templates/ActivacionTarjetasKit/Home';
// Utils
import { registerGA } from '../../../utils/metrics';

const Home = () => {
  const dispatch = useDispatch();
  const kitData = useSelector((store) => store.entities.activacionTarjetas.kitData);
  const kitDataKit = useSelector((store) => store.entities.activacionTarjetasKit.kitDataKit);
  const handleClickActivate = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas',
      'eve-cat': 'Click',
      'eve-lab': 'Activar',
    });
  };

  const handleReturnInicio = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas',
      'eve-cat': 'Click',
      'eve-lab': 'Volver al inicio',
    });
  };

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/seguridad/activacionTarjetas',
    });
  }, []);

  useEffect(() => {
    dispatch(activacionTarjetasActions.fetchValidarTarjetaCoordenadasReset());
    dispatch(activacionTarjetasActions.fetchSendSMSCodeReset());
    dispatch(activacionTarjetasActions.fetchValidateSMSCodeReset());
    dispatch(activacionTarjetasActions.fetchIsEnabledPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchSolicitarPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchValidarPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchGetKit());
    dispatch(activacionTarjetasActions.fetchActivateKitCVReset());

    // Flujo antiguo
    dispatch(activacionTarjetasKitActions.fetchValidarTarjetaCoordenadasResetKit());
    dispatch(activacionTarjetasKitActions.fetchSendSMSCodeResetKit());
    dispatch(activacionTarjetasKitActions.fetchValidateSMSCodeResetKit());
    dispatch(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchValidarPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchGetKitKit());
    dispatch(activacionTarjetasKitActions.fetchActivateKitCVResetKit());
  }, [dispatch]);

  let esKit = kitDataKit?.data?.numeroTarjetaCoordenadas !== null;

  return esKit ? (
    <HomeTemplateKit {...{ kitData: kitDataKit, handleClickActivate, handleReturnInicio }} />
  ) : (
    <HomeTemplate {...{ kitData, handleClickActivate, handleReturnInicio }} />
  );
};

export default Home;
