import styled from 'styled-components';

export const SliderPage = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  color: #fff;

  .inversiones-list {
    display: flex;
    margin-top: 1.5rem !important;
  }

  .inversiones-container {
    padding: 24px;
  }

  .display-desktop {
    display: flex;
  }

  .icon-inversiones svg {
    height: 80px;
    width: 180px;
  }

  .icon-inversiones-container {
    text-align: right;
  }

  .btn_red {
    min-width: 145px;
  }

  @media screen and (max-width: 766px) {
    background-image: url(${({ srcMobile }) => srcMobile});

    .display-desktop {
      display: none;
    }
    .icon-inversiones svg {
      height: 91px;
      width: 66px;
    }
    .icon-inversiones-container {
      text-align: left;
    }
    
  }
  @media screen and (max-width: 1200px) {
    .inversiones-list {
      margin-top: 2px !important;
    }
  }

  @media screen and (min-width: 1025px) and (max-width: 1200px){
    .inversiones-list .col-2{
      margin-left: 2.5em !important;
    }
  }
`;
