import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasActions } from '../../../../application/actions/activacionTarjetas';
import { personaActions } from '../../../../application/actions/persona';
// Template
import AutorizacionTemplate from '../../../components/templates/ActivacionTarjetas/Autorizacion';
// Utils
import { registerGA } from '../../../utils/metrics';

const Autorizacion = ({ steps, switchPassCoopeuchOrSms, setSwitchPassCoopeuchOrSms }) => {
  const dispatch = useDispatch();
  // Stores
  const persona = useSelector((store) => store.entities.persona);
  const kitData = useSelector((store) => store.entities.activacionTarjetas.kitData);
  const smsCode = useSelector((store) => store.entities.activacionTarjetas.smsCode);
  const validateSMS = useSelector((store) => store.entities.activacionTarjetas.validateSMS);
  const validateTC = useSelector((store) => store.entities.activacionTarjetas.validateTC);
  const validateTD = useSelector((store) => store.entities.activacionTarjetas.validateTD);

  const validarCoordenadas = useSelector(
    (store) => store.entities.activacionTarjetas.validarCoordenadas
  );
  const availablePassCoopeuch = useSelector(
    (store) => store.entities.activacionTarjetas.availablePassCoopeuch
  );
  const solicitarPassCoopeuch = useSelector(
    (store) => store.entities.activacionTarjetas.solicitarPassCoopeuch
  );
  const IsValidatePassCoopeuch = useSelector(
    (store) => store.entities.activacionTarjetas.validarPassCoopeuch
  );
  const activateKit = useSelector((store) => store.entities.activacionTarjetas.activateKit);

  const solicitarTarjetaCoordenadas = useSelector(
    (store) => store.entities.activacionTarjetas.solicitarTarjetaCoordenadas
  );

  // States
  const [smsCodeOne, setSmsCodeOne] = useState('');
  const [smsCodeTwo, setSmsCodeTwo] = useState('');
  const [smsCodeThree, setSmsCodeThree] = useState('');
  const [smsCodeFour, setSmsCodeFour] = useState('');
  const [smsCodeFive, setSmsCodeFive] = useState('');
  const [smsCodeSix, setSmsCodeSix] = useState('');
  const [timerValue, setTimerValue] = useState(120);
  const [valorDesafio1, setValorDesafio1] = useState('');
  const [valorDesafio2, setValorDesafio2] = useState('');
  const [valorDesafio3, setValorDesafio3] = useState('');
  const [desafio1, setDesafio1] = useState('');
  const [desafio2, setDesafio2] = useState('');
  const [desafio3, setDesafio3] = useState('');
  // Boolean
  const [counterActive, setCounterActive] = useState(false);
  const [errorTimeOut, setErrorTimeOut] = useState(false);
  const [factorErrorTimeOut, setFactorErrorTimeOut] = useState('');
  const [authorizeBtn, setAuthorizeBtn] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [isAvailableFactor, setisAvailableFactor] = useState(true);
  const [isAvailableFactorTC, setisAvailableFactorTC] = useState(true);
  const [stepSolicitartarjetaCoordenadas, setStepSolicitarTarjetaCoordenadas] = useState(false);
  const [hablitarBtn, setHablitarBtn] = useState(false);
  const [errorIngresoCoordenadas, setErrorIngresoCoordenadas] = useState(false);
  const [disabledInput, setDisabledInput] = useState(false);

  const handleClickSendTC = () => {
    dispatch(activacionTarjetasActions.fetchSolicitarTarjetaCoordenadas());
    setDisabledInput(false);
    setStepSolicitarTarjetaCoordenadas(true);
    setErrorTimeOut(false);
    setFactorErrorTimeOut('');
    cleanInputsTC();
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar Tarjeta de Coordenadas',
    });
  };

  const handleClickRetry = () => {
    setStepSolicitarTarjetaCoordenadas(false);
    setErrorTimeOut(false);
    setErrorIngresoCoordenadas(false);
    setHablitarBtn(false);
    cleanInputsTC();
    if (errorTimeOut === false && errorIngresoCoordenadas === true) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - Tarjeta de Coordenadas No corresponde',
      });
    }
    if (errorTimeOut === true && errorIngresoCoordenadas === false) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - Tarjeta de Coordenadas Tiempo Agotado',
      });
    }
    setFactorErrorTimeOut('');
  };

  const handleClickValidateTC = () => {
    dispatch(
      activacionTarjetasActions.fetchValidarCoordenadas({
        valorDesafio1: valorDesafio1,
        valorDesafio2: valorDesafio2,
        valorDesafio3: valorDesafio3,
      })
    );
  };

  const cleanInputsTC = () => {
    setValorDesafio1('');
    setValorDesafio2('');
    setValorDesafio3('');
  };

  useEffect(() => {
    if (validarCoordenadas?.error?.code === 'TD-41' || errorTimeOut == true) {
      setDisabledInput(true);
    } else {
      setDisabledInput(false);
    }
  }, [validarCoordenadas, errorTimeOut]);

  useEffect(() => {
    let factorTimeout = '';
    switch (factorErrorTimeOut) {
      case 'passcoopeuch':
        factorTimeout = 'Passcoopeuch Tiempo Agotado';
        break;
      case 'sms':
        factorTimeout = 'Clave Dinámica SMS';
        break;
      case 'tarjetacoord':
        factorTimeout = 'Tarjeta de Coordenadas Tiempo Agotado';
        break;
      default:
        factorTimeout = factorErrorTimeOut;
    }
    if (factorErrorTimeOut !== '') {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': `${factorTimeout}`,
      });
    }
  }, [factorErrorTimeOut]);

  useEffect(() => {
    dispatch(activacionTarjetasActions.fetchValidarPassCoopeuchReset());
    if (validarCoordenadas?.error?.code === 'TD-41' && errorIngresoCoordenadas) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Tarjeta de Coordenadas No corresponde',
      });
    }
    setErrorIngresoCoordenadas(false);
    //console.log('reinicia el error de coordenadas 1', errorIngresoCoordenadas);
    if (validarCoordenadas?.error?.code === 'TD-41') {
      setErrorIngresoCoordenadas(true);
      //console.log('reinicia el error de coordenadas 2', errorIngresoCoordenadas);
    } else {
      setErrorIngresoCoordenadas(false);
      if (validarCoordenadas?.error?.code === 'TD-40') {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
          'eve-cat': 'Error',
          'eve-lab': 'Tarjeta de Coordenadas Bloqueada',
        });
      }
    }
  }, [validarCoordenadas, errorIngresoCoordenadas]);

  useEffect(() => {
    //validacion llenado de inputs
    if (valorDesafio1 !== '' && valorDesafio2 !== '' && valorDesafio3 !== '') {
      //console.log('todos los input llenos');
      setHablitarBtn(true);
    } else {
      //console.log('todos los input vacios');
      setHablitarBtn(false);
    }
  }, [valorDesafio1, valorDesafio2, valorDesafio3, hablitarBtn]);

  const handleClickValidateSMS = () => {
    const codigo = `${smsCodeOne}${smsCodeTwo}${smsCodeThree}${smsCodeFour}${smsCodeFive}${smsCodeSix}`;
    dispatch(activacionTarjetasActions.fetchValidateSMSCode(codigo));
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar - Clave Dinámica SMS',
    });
  };

  const handleClickAuthorizePassCoopeuch = () => {
    //dispatch(activacionTarjetasActions.fetchIsEnabledPassCoopeuch());
    dispatch(activacionTarjetasActions.fetchSolicitarPassCoopeuch());
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar con PassCoopeuch - PassCoopeuch',
    });
  };

  const cleanInputsSMS = () => {
    setSmsCodeOne('');
    setSmsCodeTwo('');
    setSmsCodeThree('');
    setSmsCodeFour('');
    setSmsCodeFive('');
    setSmsCodeSix('');
  };

  const retryError = (typeError) => {
    cleanInputsSMS();
    if (typeError === 'errorTime') {
      dispatch(activacionTarjetasActions.fetchSendSMSCodeReset());
      setCounterActive(false);
      setTimerValue(120);
      setErrorTimeOut(false);
      setFactorErrorTimeOut('');
    } else {
      setCounterActive(true);
      dispatch(activacionTarjetasActions.fetchValidateSMSCodeReset());
    }
  };

  const retryTimeOutPass = () => {
    dispatch(activacionTarjetasActions.fetchIsEnabledPassCoopeuch());
    dispatch(activacionTarjetasActions.fetchIsEnabledPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchSolicitarPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchValidarPassCoopeuchReset());
    setCounterActive(false);
    setErrorTimeOut(false);
    setFactorErrorTimeOut('');
    if (errorTimeOut === true) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - PassCoopeuch Tiempo Agotado',
      });
    }
    if (IsValidatePassCoopeuch?.data?.codigo === 8) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - PassCoopeuch autorización rechazada desde movil',
      });
    }
  };

  const backToActivacion = (accion, factor) => {
    dispatch(activacionTarjetasActions.fetchValidarTarjetaCoordenadasReset());
    dispatch(activacionTarjetasActions.fetchSendSMSCodeReset());
    dispatch(activacionTarjetasActions.fetchValidateSMSCodeReset());
    dispatch(activacionTarjetasActions.fetchIsEnabledPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchSolicitarPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchValidarPassCoopeuchReset());
    dispatch(activacionTarjetasActions.fetchValidarCoordenadasReset());
    setErrorTimeOut(false);
    setFactorErrorTimeOut('');
    let nombreFactor = '';
    switch (factor) {
      case 'passcoopeuch':
        nombreFactor = 'PassCoopeuch';
        break;
      case 'sms':
        nombreFactor = 'Clave Dinámica SMS';
        break;
      case 'tarjetacoord':
        nombreFactor = 'Tarjeta de Coordenadas';
        break;
      default:
        nombreFactor = factor;
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': `${accion} - ${nombreFactor}`,
    });
  };

  const handleClickDownloadApp = (plataforma) => {
    let nombreStore = '';
    switch (plataforma) {
      case 'AppStore':
        nombreStore = 'AppStore';
        break;
      case 'GooglePlay':
        nombreStore = 'GooglePlay';
        break;
      default:
        nombreStore = plataforma;
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': `${nombreStore} - PassCoopeuch`,
    });
  };

  const handleSetSmsCode = (id, value) => {
    id === 'cor1' && setSmsCodeOne(value);
    id === 'cor2' && setSmsCodeTwo(value);
    id === 'cor3' && setSmsCodeThree(value);
    id === 'cor4' && setSmsCodeFour(value);
    id === 'cor5' && setSmsCodeFive(value);
    id === 'cor6' && setSmsCodeSix(value);
  };

  const handleSetTC = (id, value) => {
    id === 'cor1' && setValorDesafio1(value);
    //console.log('desafio 1  :', valorDesafio1);
    id === 'cor2' && setValorDesafio2(value);
    //console.log('desafio 2  :', valorDesafio2);
    id === 'cor3' && setValorDesafio3(value);
    //console.log('desafio 3  :', valorDesafio3);
  };

  const handleClickCloseModalError = () => {
    setIsVisibleModalError(false);
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Entendido - Servicio no disponible',
    });
    backToActivacion('Volver al inicio','Servicio no disponible');
    window.location.replace('#/ecd');
  };

  const validatePassCoopeuch = () => {
    dispatch(activacionTarjetasActions.fetchValidarPassCoopeuch());
  };

  const handleSwitchMethods = (method) => {
    if (method === 'passcoopeuch') {
      setSwitchPassCoopeuchOrSms(!switchPassCoopeuchOrSms);
      setStepSolicitarTarjetaCoordenadas(false);
      dispatch(activacionTarjetasActions.fetchValidarCoordenadasReset());
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Quiero autorizar con Tarjeta de Coordenadas - PassCoopeuch',
      });
    }

    if (method === 'tarjetacoord') {
      setSwitchPassCoopeuchOrSms(!switchPassCoopeuchOrSms);
      let origen = 'Tarjeta de Coordenadas';
      if (errorIngresoCoordenadas === false && errorTimeOut === true) {
        origen = 'Tarjeta de Coordenadas Tiempo Agotado';
      }
      if (errorIngresoCoordenadas === true && errorTimeOut === false) {
        origen = 'Tarjeta de Coordenadas No corresponde';
      }
      if (validarCoordenadas?.error?.code === 'TD-40') {
        origen = 'Tarjeta de Coordenadas Bloqueada';
      }
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': `Quiero autorizar con PassCoopeuch - ${origen}`,
      });
    }
  };

  useEffect(() => {
    dispatch(personaActions.fetchDatosPersonales());
    registerGA({
      event: 'vpv-privado',
      page: '/tef/seguridad/activacionTarjetas/paso2',
    });
  }, []);

  useEffect(() => {
    //console.log('Solicitar tarjeta coordenadas', solicitarTarjetaCoordenadas?.data);
    if (solicitarTarjetaCoordenadas?.error?.code === 'TD-40') {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Tarjeta de Coordenadas Bloqueada',
      });
    }
    if (solicitarTarjetaCoordenadas?.error?.statusCode === 408){
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [solicitarTarjetaCoordenadas]);

  // Valida factor de seguridad
  useEffect(() => {
    if (availablePassCoopeuch?.data) {
      processData(availablePassCoopeuch);
    } else {
      setisAvailableFactor(false);
      setisAvailableFactorTC(false);
    }
  }, [availablePassCoopeuch, isAvailableFactor, isAvailableFactorTC]);

  const processData = (factor) => {
    let hasValue21 = false;
    let hasValue22 = false;

    for (let i = 0; i < factor.data.length; i++) {
      const codigo = factor.data[i].codigo;

      if (codigo === 2.2) {
        hasValue22 = true;
      } else if (codigo === 2.1) {
        hasValue21 = true;
      }
    }

    if (hasValue21 && hasValue22) {
      setisAvailableFactor(true);
      setisAvailableFactorTC(true);
    } else if (hasValue22) {
      setisAvailableFactor(true);
      setisAvailableFactorTC(false);
    } else if (hasValue21) {
      setisAvailableFactor(false);
      setisAvailableFactorTC(true);
    } else {
      setisAvailableFactor(false);
      setisAvailableFactorTC(false);
    }
  };

  //valida coordenandas y activa

  useEffect(() => {
    if (validarCoordenadas?.data === true) {
      //console.log('debe activar tarjeta ');
      dispatch(activacionTarjetasActions.fetchActivateKitCV());
    }
  }, [validarCoordenadas]);

  // Validate response solicitar passcoopeuch
  useEffect(() => {
    if (solicitarPassCoopeuch?.data !== null) {
      setCounterActive(true);
    }
    if (solicitarPassCoopeuch?.error !== null) {
      setIsVisibleModalError(true);
      setCounterActive(false);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [solicitarPassCoopeuch]);

  useEffect(() => {
    if (IsValidatePassCoopeuch?.data?.codigo === 8) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setFactorErrorTimeOut('');
      setIsLoading(false);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PassCoopeuch autorización rechazada desde movil',
      });
    }
    if (IsValidatePassCoopeuch?.data?.codigo === 7) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setFactorErrorTimeOut('');
      setIsLoading(false);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PassCoopeuch Bloqueada',
      });
    }
    if (IsValidatePassCoopeuch?.data?.codigo === 0) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setFactorErrorTimeOut('');
      dispatch(activacionTarjetasActions.fetchActivateKitCV());
      setIsLoading(true);
    }
    if (IsValidatePassCoopeuch?.error !== null) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setFactorErrorTimeOut('');
      setIsLoading(false);
      setIsVisibleModalError(true);
    }
    if (IsValidatePassCoopeuch?.error?.statusCode === 408){
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [IsValidatePassCoopeuch]);

  useEffect(() => {
    if (
      smsCodeOne !== '' &&
      smsCodeTwo !== '' &&
      smsCodeThree !== '' &&
      smsCodeFour !== '' &&
      smsCodeFive !== '' &&
      smsCodeSix !== ''
    ) {
      setAuthorizeBtn(true);
    } else {
      setAuthorizeBtn(false);
    }
  }, [smsCodeOne, smsCodeTwo, smsCodeThree, smsCodeFour, smsCodeFive, smsCodeSix]);

  useEffect(() => {
    if (validateTC.data === null || kitData.data === null) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas');
    }
  }, []);

  useEffect(() => {
    smsCode.data === true && setCounterActive(true);
  }, [smsCode]);

  useEffect(() => {
    validateSMS.data?.valido === true &&
      window.location.replace('#/ecd/seguridad/activacion-tarjetas/comprobante');
  }, [validateSMS]);

  useEffect(() => {
    if (activateKit.data?.valido === true) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas/comprobante');
      setIsLoading(false);
    }
  }, [activateKit]);

  useEffect(() => {
    //console.log('data tarjedebito', availablePassCoopeuch?.data);
  }, []);

  useEffect(() => {
    //console.log(solicitarTarjetaCoordenadas);
    if (solicitarTarjetaCoordenadas?.data !== null) setStepSolicitarTarjetaCoordenadas(true);
  }, [solicitarTarjetaCoordenadas]);

  return (
    <AutorizacionTemplate
      {...{
        steps,
        counterActive,
        setCounterActive,
        handleClickSendTC,
        errorTimeOut,
        setErrorTimeOut,
        setFactorErrorTimeOut,
        errorTimeOut,
        retryError,
        handleSetSmsCode,
        authorizeBtn,
        handleClickValidateSMS,
        smsCode,
        validateSMS,
        smsCodeOne,
        smsCodeTwo,
        smsCodeThree,
        smsCodeFour,
        smsCodeFive,
        smsCodeSix,
        desafio1,
        desafio2,
        desafio3,
        persona,
        timerValue,
        setTimerValue,
        backToActivacion,
        switchPassCoopeuchOrSms,
        handleClickAuthorizePassCoopeuch,
        retryTimeOutPass,
        validatePassCoopeuch,
        IsValidatePassCoopeuch,
        IsLoading,
        isVisibleModalError,
        handleClickCloseModalError,
        isAvailableFactor,
        handleSwitchMethods,
        stepSolicitartarjetaCoordenadas,
        solicitarTarjetaCoordenadas,
        handleSetTC,
        availablePassCoopeuch,
        setHablitarBtn,
        hablitarBtn,
        handleClickRetry,
        handleClickValidateTC,
        validarCoordenadas,
        validateTC,
        errorIngresoCoordenadas,
        validateTD,
        disabledInput,
        isAvailableFactorTC,
        activateKit,
        handleClickDownloadApp,
      }}
    />
  );
};

export default Autorizacion;
