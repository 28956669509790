import React from 'react';
// UI Components
import Stepper, { RenderStep } from '../../organism/Stepper';
// Components
import Caracteristicas from '../../organism/ContratoMonedero/Caracteristicas';
import Contratacion from '../../organism/ContratoMonedero/Contratacion';
import Deposito from '../../organism/ContratoMonedero/Deposito';
import Comprobante from '../../organism/ContratoMonedero/Comprobante';
import ModalCobrarDespues from '../../organism/ContratoMonedero/ModalCobrarDespues';
// import ModalEnviarComprobante from '../../organism/ContratoMonedero/ModalEnviarComprobante';
import ModalComprobanteEnviado from '../../organism/ContratoMonedero/ModalComprobanteEnviado';
import ModalEnviarComprobanteError from '../../organism/ContratoMonedero/ModalEnviarComprobanteError';

const ContratoMonedero = ({
  steps,
  currentStep,
  depositoChecked,
  contratacionChecked,
  password,
  okStep2,
  cuenta,
  fecha,
  monto,
  fechaCobro,
  cuentaCobro,
  errorCobroRemanente,
  // secondEmail,
  showPassword,
  passwordError,
  // secondEmailError,
  serverPasswordError,
  loadingContratacion,
  loadingCobro,
  onTogglePassword,
  onDownloadGenericContract,
  onHandleCheckBox,
  onHandlePasswordInput,
  // onHandleSendComprobante,
  onHandleDownloadComprobante,
  openSendEmailModal,
  continueStep1,
  continueStep2,
  backStep2,
  continueStep3,
  backStep3,
  finalizarSinDeposito,
  serviceError,
  blockedPassword,
  showCobrarDespuesSinContratoModal,
  showCobrarDespuesConContratoModal,
  // showEnviarComprobanteModal,
  showComprobanteEnviadoModal,
  showEnviarComprobanteErrorModal,
  closeModal,
  toHome,
  toRemanente,
  toRemanenteComprobante,
  persona,
  email,
  onHandleEmailInputChange,
  emailError,
  emailErrorMessage,
  backStepModalContratarSinCobro,
  nextStepModalContratarSinCobro,
}) => {
  return (
    <React.Fragment>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <Stepper className="bmb-3" position={currentStep} {...{ steps }} />
        </div>
      </div>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <RenderStep step={currentStep - 1}>
            <Caracteristicas backButton={toRemanente} nextButton={continueStep1} />
            <Contratacion
              {...{
                checked: contratacionChecked,
                password,
                okStep2,
                passwordError,
                serverPasswordError,
                showPassword,
                onTogglePassword,
                onHandleCheckBox,
                onHandlePasswordInput,
                serviceError,
                blockedPassword,
                persona,
                email,
                onHandleEmailInputChange,
                emailError,
                emailErrorMessage,
                onDownloadGenericContract,
              }}
              loading={loadingContratacion}
              backButton={backStep2}
              nextButton={continueStep2}
            />
            <Deposito
              {...{
                cuenta,
                fecha,
                email,
                errorCobroRemanente,
                checked: depositoChecked,
                onHandleCheckBox,
              }}
              loading={loadingCobro}
              backButton={backStep3}
              nextButton={continueStep3}
            />
            <Comprobante
              {...{ cuenta, fecha, email, monto, fechaCobro, cuentaCobro }}
              remanenteCobrado={depositoChecked}
              openSendEmailModal={openSendEmailModal}
              downloadComprobante={onHandleDownloadComprobante}
              backButton={toHome}
              nextButton={toRemanenteComprobante}
            />
          </RenderStep>
        </div>
      </div>
      <ModalCobrarDespues
        show={showCobrarDespuesSinContratoModal}
        closeAction={closeModal}
        backButton={toRemanente}
        nextButton={closeModal}
      />
      <ModalCobrarDespues
        show={showCobrarDespuesConContratoModal}
        closeAction={closeModal}
        backButton={backStepModalContratarSinCobro}
        nextButton={nextStepModalContratarSinCobro}
      />
      {/* <ModalEnviarComprobante
        show={showEnviarComprobanteModal}
        email={secondEmail}
        emailError={secondEmailError}
        onHandleChangeEmail={onHandleChangeEmail}
        closeAction={closeModal}
        nextButton={onHandleSendComprobante}
      /> */}
      <ModalComprobanteEnviado
        show={showComprobanteEnviadoModal}
        closeAction={closeModal}
        backButton={finalizarSinDeposito}
        nextButton={closeModal}
      />
      <ModalEnviarComprobanteError
        show={showEnviarComprobanteErrorModal}
        closeAction={closeModal}
      />
    </React.Fragment>
  );
};

ContratoMonedero.propTypes = {};

ContratoMonedero.defaultProps = {};

export default ContratoMonedero;
