import React from 'react';
// Components
import { Button, Card} from '@design-system-coopeuch/web';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Web from './Web';
import Mobile from './Mobile';

// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const InfoBonosIngreso = ({ handleClickBtn, userType }) => {
  return (
    <Wrapper>
      <Card className="cardContainer">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold">
            Bono de Ingreso
          </Typography>
        </CardBox.Header>
        <div className='mt-3'>
          <img
            src={`${iconPath}image-ingresos.svg`}
            alt="ilustracion_mujer"
            className="center"
          />
        </div>
        <div className="bonoSolicitar__content__space hide-on-mobile">
          <Web/>
        </div>
        <div className="bonoSolicitar__content__space hide-on-desktop">
          <Mobile/>
        </div>
        <div className="btnSolicitar">
          <Button
            color="primary"
            variant="link"
            disabled={
              userType.data?.valido === false 
            }
            onClick={() =>
               handleClickBtn('Bono de Ingreso')
            }
          >
           Postula aquí
          </Button>
        </div>
      </Card>
    </Wrapper>
  );
};

export default InfoBonosIngreso;
