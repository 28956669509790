import {
  isRunValid,
} from '../../../utils/validators';

const NAME_PATTERN = /(?:Nombre: ?)?([ a-zA-ZñÑáéíóúÁÉÍÓÚ]+)(?:[\r\n]+|$)/;
const EMAIL_PATTERN = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
const BANCO_PATTERN =
  /(de Chile|Estado|Santander|Scotiabank|Edwards|Coopeuch|Bice|Consorcio|Corpbanca|Condell|Edwards|Citi|Credichile|Falabella|Internacional|Itau|Itaú|Paris|Ripley|Santander|Banefe|Security|Bci|Heroes|Copec|Hsbc|Bank|Mercado|Pago|Polar|Scotiabank|Tenpo|Banco Edwards)/i;
const CUENTA_TYPE_PATTERN = /(Corriente|Vista|ahorro|cuenta rut|cte|cta rut)/i;
const NUM_CUENTA_PATTERN = /(?:^|\s)(?:(?:\d+[-\s]*){2,}\d+)(?=$|\s)/ ///(?:^|\s)(?:(?:\d{1,3}\s*){4,})(?=$|\s)/;
const RUT_PATTERN = /(\d{1,3}\.\d{3}\.\d{2,3}-[0-9Kk]|\d{7,8}-?[0-9Kk]|\d{9})/;

const processClipboardText = (text) => {
    const destinatarioInfo = {};
    const clipboardArray = text.split('\n');
    console.log('numCuenta -> ', clipboardArray)
    clipboardArray.forEach((item) => {
      const name = item.match(NAME_PATTERN);
      const email = item.match(EMAIL_PATTERN);
      const banco = item.match(BANCO_PATTERN);
      const cuenta = item.match(CUENTA_TYPE_PATTERN);
      const numCuenta = item.match(NUM_CUENTA_PATTERN);
      const rut = item.match(RUT_PATTERN);
      if (name && !cuenta && !banco && !destinatarioInfo?.name) {
        destinatarioInfo.name = item.trim().toUpperCase()
        .replace('NOMBRE:', '')
        .replace('NOMBRE: ', '');
      }
      if (rut && !destinatarioInfo?.rut) {
        if(isRunValid(item.trim().toUpperCase())){
            destinatarioInfo.rut = item.trim().toUpperCase()
            .replace('RUT:', '')
            .replace('RUT: ', '');
        }
      }
      if (email) {
        destinatarioInfo.email = email[0].trim();
      }
      if (banco && !cuenta) {
        destinatarioInfo.banco = banco[0]
          .trim()
          .toUpperCase()
          .replace('BCO', 'Banco')
          .replace(':', ' ')
          .replace('_', ' ');
      }
      if (cuenta && !banco) {
        destinatarioInfo.cuenta = cuenta[0]
          .trim()
          .toUpperCase()
          .replace('CTE', 'Corriente')
          .replace('TIPO:', '')
          .replace('TIPO: ', '')
          .replace('_', '');
        if (item.toUpperCase().includes('RUT')) {
          destinatarioInfo.isCuentaRut = true;
        }
      }
      if (numCuenta) {
        if (!isRunValid(numCuenta[0])) {
            destinatarioInfo.numCuenta = numCuenta[0].trim().replace(/-/g, '');
        }
      }    
    });
    console.log('destinatarioInfo: ', destinatarioInfo)
    return destinatarioInfo;
  };
export const useClipboardData = async () => {
    try {
      const textCopiedByClipboard = await navigator.clipboard.readText();
  
      if (textCopiedByClipboard && textCopiedByClipboard.includes('\n')) {
        const destinatarioInfo = processClipboardText(textCopiedByClipboard);
        await navigator.clipboard.writeText(''); // Esto intentará borrar el contenido
        return destinatarioInfo;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error al leer el portapapeles: ', error);
      return null;
    }
  };