/* eslint-disable react/forbid-prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { abonoAcreenciasRemanenteActions } from '../../../../application/actions/abonoAcreenciasRemanente';

import StepsActions from '../../../components/organism/Stepper/StepsActions';
import DatosBancariosTemplate from '../../../components/templates/AbonoAcreenciasRemanente/DatosBancarios';
import Error from '../../../components/atoms/Error';

import { useShallowStore } from '../../../utils/hooks';
import { regexOnlyNumber } from '../../../utils/formatters';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

function DatosBancariosContainer(props) {
  const {
    persona,
    errorDatosPersonales,
    bancos,
    Errorbancos,
    tipoCuentas,
    ErrorTiposCuentas,
    banco,
    setBanco,
    tipoCuenta,
    setTipoCuenta,
    numeroCuenta,
    setNumeroCuenta,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loading: loadingValidar,
    success: successValidar,
    error: errorValidar,
  } = useShallowStore((store) => store.entities.abonoAcreenciasRemanente.poderEspecialValidar);

  const [serviceError, setServiceError] = useState(false);
  const [errorNumeroCuenta, setErrorNumeroCuenta] = useState();
  const [errorCuenta, setErrorCuenta] = useState(false);
  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''))

  function onChangeHandle(event) {
    const { dataset, value } = event.target;
    if (dataset.id === 'banco') {
      setBanco(value);
    }
    if (dataset.id === 'tipoCuenta') {
      setTipoCuenta(value);
    }
    if (dataset.id === 'numeroCuenta') {
      setNumeroCuenta(regexOnlyNumber(value));
      setErrorNumeroCuenta(!!(value.length >= 1 && value.length <= 5));
    }
  }

  useEffect(() => {
    if (successValidar) {
      history.push('/ecd/poder-especial/autorizacion');
    }
    return () => {
      dispatch(abonoAcreenciasRemanenteActions.clearValidar());
    };
  }, [dispatch, successValidar]);

  useEffect(() => {
    if (errorValidar.cuenta) {
      setErrorCuenta(true)
    }
    if (errorValidar.server || errorDatosPersonales || Errorbancos || ErrorTiposCuentas ) {
      setServiceError(true)
    }
  }, [errorValidar, errorDatosPersonales, Errorbancos, ErrorTiposCuentas]);

  const handleSubmit = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/paso1',
      'eve-cat' : 'Click',
      'eve-lab' : 'Continuar',
      'ambiente' : 'sitio'
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'PODER_ESPECIAL_INGRESODATOS_VALIDAR',
      informacion_adicional: {}
    })
    dispatch(
      abonoAcreenciasRemanenteActions.fetchPoderEspecialValidar({
        codigoBanco: banco?.codigo,
        codigoTipoCuenta: tipoCuenta?.codigo,
        numeroCuenta
      })
    );
  }, [banco, tipoCuenta, numeroCuenta]);

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      'page' : '/tef/remanente-acreencias/paso1',
      'ambiente' : 'sitio'
    });
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/paso1',
      'eve-cat' : 'Mensaje',
      'eve-lab' : 'Cinta - Verificar que la cuenta este asociada al RUT',
      'ambiente' : 'sitio'
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'PODER_ESPECIAL_INGRESODATOS',
      informacion_adicional: {}
    })
  }, []);

  const onGoHome = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/paso1',
      'eve-cat' : 'Click',
      'eve-lab' : 'Volver',
      'ambiente' : 'sitio'
    });
    history.push('/ecd/inicio');
  }, [history]);

  const onGoHomeError = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/paso1',
      'eve-cat' : 'Error',
      'eve-lab' : 'Servicio no disponible - volver',
      'ambiente' : 'sitio'
    });
    history.push('/ecd/inicio');
  }, [history]);

  useEffect(() => {
    if (serviceError) {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/remanente-acreencias/paso1',
        'eve-cat' : 'Error',
        'eve-lab' : 'Servicio no disponible',
        'ambiente' : 'sitio'
      });
    }
  }, [serviceError]);

  return (
    <>
      {serviceError ? (
        <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
          <Error className="bmt-3 bmt-md-4" />
          <StepsActions
            id="error-datosBancarios"
            className="bmt-3 mt-24"
            backText="Volver"
            backAction={onGoHomeError}
            hideNextAction
          />
        </div>
      ) : (
        <DatosBancariosTemplate
          isLoading={loadingValidar}
          persona={persona}
          bancos={bancos}
          banco={banco}
          tipoCuentas={tipoCuentas}
          tipoCuenta={tipoCuenta}
          numeroCuenta={numeroCuenta}
          errorNumeroCuenta={errorNumeroCuenta}
          // eslint-disable-next-line react/jsx-no-bind
          onChangeHandle={onChangeHandle}
          errorCuenta={errorCuenta}
          handleGoBack={onGoHome}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

DatosBancariosContainer.propTypes = {
  persona: PropTypes.object,
  errorDatosPersonales: PropTypes.bool,
  bancos: PropTypes.array,
  Errorbancos: PropTypes.bool,
  tipoCuentas: PropTypes.array,
  ErrorTiposCuentas: PropTypes.bool,
  banco: PropTypes.object,
  setBanco: PropTypes.func,
  tipoCuenta: PropTypes.object,
  setTipoCuenta: PropTypes.func,
  numeroCuenta: PropTypes.string,
  setNumeroCuenta: PropTypes.func,
};

DatosBancariosContainer.defaultProps = {
  persona: {},
  errorDatosPersonales: false,
  bancos: [],
  Errorbancos: false,
  tipoCuentas: [],
  ErrorTiposCuentas: false,
  banco: {},
  setBanco: () => {},
  tipoCuenta: {},
  setTipoCuenta: () => {},
  numeroCuenta: '',
  setNumeroCuenta: () => {},
};

export default DatosBancariosContainer;
