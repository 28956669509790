import React, { Fragment, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { SpinnerWrapper } from '../Wrapper/styles';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import DashboardCard from '../../../organism/DashboardCard';
import Information from '../../../organism/RecuperarOCrearClave/Information';

import Button from '@coopeuch-components/web/atoms/Button';
import Spinner from '@coopeuch-components/web/atoms/ButtonWithSpinner/Spinner';
import Alert from '@coopeuch-components/web/atoms/Alert';
import InputForForm from '../../../organism/RecuperarOCrearClave/InputForForm';
import SuccessOrError from '../../../organism/RecuperarOCrearClave/SuccessOrError';
import { addClass } from '@coopeuch-components/web';
import { registerGA } from '../../../../utils/metrics';
import { useLocation } from 'react-router-dom';

const getTypeInput = (isShow) => {
  return { icon: isShow ? 'eye_open' : 'eye_close', type: isShow ? 'text' : 'password' };
};

const CreacionClaveTemplate = (props) => {
  const {
    onChangeHandle,
    onShowHiddeHandle,
    onSubmitHandle,
    onFetchReglasHandle,
    isLoadingValidarOCrear,
    isLoadingReglasCrearClave,
    errorReglaClaves,
    successCrearClave,
    errorCrearClave,
    errorClaveMessage,
    clave,
    repeat,
    showClave,
    showRepeat,
    claveError,
    repeatError,
    redirectOrigin,
    repeatDisabled,
  } = props;

  const onShowClave = useCallback(() => onShowHiddeHandle('clave'), [onShowHiddeHandle]);
  const onShowRepeat = useCallback(() => onShowHiddeHandle('repeat'), [onShowHiddeHandle]);
  const location = useLocation();

  // Solo existen 2 estados, el crear-clave y el recuperar, asi que esta es una manera okay de identificarlos.
  const [isLocationCrearClave, setIsLocationCrearClave] = useState(
    location?.pathname.includes('crear-clave')
  );
  const goToHome = useCallback(
    (redirectOrigin) => {
      const searchParams = new URLSearchParams(location.search);
      const origen = searchParams.get('origen');
      const redreccion = redirectOrigin.data?.origen;
      if (origen) {
        window.location.replace(`${redreccion}`);
      } else {
        window.location.replace(`${window.location.origin}/`);
      }
      registerGA({
        event: 'trigger_event',
        'event-config': {
          'eve-acc': '/crea-rec-clave/exito',
          'eve-cat': 'Click',
          'eve-lab': 'Ingresar',
        },
      });
    },
    [redirectOrigin]
  );

  const claveInput = useMemo(() => getTypeInput(showClave), [showClave]);
  const repeatInput = useMemo(() => getTypeInput(showRepeat), [showRepeat]);

  const isDisabledButton = useMemo(() => {
    return claveError?.length !== 0 || repeatError || clave?.length === 0 || repeat?.length === 0;
  }, [claveError, repeatError]);

  const isSuccessOrFailed = useMemo(() => {
    return {
      show: successCrearClave || errorReglaClaves,
      title: successCrearClave ? 'Coopeuch en línea' : 'Error de servicio',
      type: successCrearClave ? 'success' : 'error',
      icon: successCrearClave ? 'success-info-with-phone' : 'ilustracion_error',
      onClick: successCrearClave ? () => goToHome(redirectOrigin) : onFetchReglasHandle,
      button: successCrearClave ? 'Ingresar' : 'Reintentar',
    };
  }, [successCrearClave, goToHome, errorReglaClaves, onFetchReglasHandle]);

  if (isSuccessOrFailed?.show || isLoadingReglasCrearClave) {
    return (
      <DashboardCard classes="dashboard-card-shadow">
        <DashboardCard.Head
          title={isLoadingReglasCrearClave ? 'Coopeuch en línea' : isSuccessOrFailed?.title}
          borderBottom="complete"
        />
        <DashboardCard.Body>
          {isLoadingReglasCrearClave ? (
            <div className="row center-xs">
              <div className="col-xs-12 center-xs">
                <SpinnerLoader />
              </div>
            </div>
          ) : (
            <SuccessOrError {...isSuccessOrFailed} />
          )}
        </DashboardCard.Body>
      </DashboardCard>
    );
  }

  return (
    <DashboardCard classes="dashboard-card-shadow">
      <DashboardCard.Head title="¿Cómo crear tu nueva clave?" borderBottom="complete" />

      <DashboardCard.Body>
        <div className="row center-xs">
          <div className="col-xs-11 mb-16">
            <div className="ml-16 mr-24 margin--mobile">
              <Alert
                bgType="error"
                iconType="error"
                show={Boolean(errorCrearClave && !errorClaveMessage)}
                className="pr-0 mb-14 alert--wrapper"
              >
                En este momento nuestro servicio no se encuetra disponible. Vuelve a intentarlo mas
                tarde.
              </Alert>
            </div>
            <div className="row ">
              <div className="col-sm-12 col-md-5 col-lg-5 bmt-2 bmt-md-4">
                <div
                  className={addClass([
                    'ml-12 mr-12 margin--mobile text-left',
                    (errorClaveMessage || claveError?.length > 0) && 'hide-help-text',
                  ])}
                >
                  <InputForForm
                    label="Crea nueva clave"
                    showLockIcon
                    onChange={onChangeHandle}
                    dataset="clave"
                    value={clave}
                    type={claveInput.type}
                    id="creacion-clave"
                    placeholder="Ingresa nueva clave"
                    onClickIconRight={onShowClave}
                    iconRight={claveInput.icon}
                    maxLength={8}
                    error={claveError}
                  />
                </div>

                <div
                  className={addClass([
                    'mt-16 ml-12 mr-12 margin--mobile text-left',
                    repeatError && 'hide-help-text',
                  ])}
                >
                  <InputForForm
                    label="Confirma nueva clave"
                    showLockIcon
                    onChange={onChangeHandle}
                    dataset="repeat"
                    value={repeat}
                    type={repeatInput.type}
                    id="creacion-clave-repeat"
                    placeholder="Ingresa nueva clave"
                    onClickIconRight={onShowRepeat}
                    iconRight={repeatInput.icon}
                    disabled={repeatDisabled}
                    maxLength={8}
                    error={repeatError ? 'Tu clave no coincide' : ''}
                  />
                </div>

                <div className="mt-12 ml-12 mr-12 margin--mobile">
                  <Button
                    disabled={isDisabledButton}
                    bgColor="#E81E2B"
                    color="white"
                    onClick={isLoadingValidarOCrear ? null : onSubmitHandle}
                    fullWidth
                  >
                    <SpinnerWrapper>
                      {isLoadingValidarOCrear ? (
                        <Fragment>
                          <Spinner fill="white" />
                          Cargando
                        </Fragment>
                      ) : (
                        <Fragment>Crear</Fragment>
                      )}
                    </SpinnerWrapper>
                  </Button>
                </div>
              </div>

              <div className="col-sm-12 col-md-7 col-lg-7 bmt-3 bmt-md-5 paddingEspecial">
                <Information
                  items={[
                    'Tener entre 6 y 8 caracteres.',
                    'Incluir una mayúscula.',
                    'Tener al menos un número.',
                  ]}
                  isLocationCrearClave={isLocationCrearClave}
                />
              </div>
            </div>
          </div>
        </div>
      </DashboardCard.Body>
    </DashboardCard>
  );
};

CreacionClaveTemplate.propTypes = {
  onChangeHandle: PropTypes.func,
  onShowHiddeHandle: PropTypes.func,
  onSubmitHandle: PropTypes.func,
  onFetchReglasHandle: PropTypes.func,
  isLoadingValidarOCrear: PropTypes.bool,
  isLoadingReglasCrearClave: PropTypes.bool,
  errorReglaClaves: PropTypes.string,
  successCrearClave: PropTypes.bool,
  errorCrearClave: PropTypes.bool,
  clave: PropTypes.string,
  repeat: PropTypes.string,
  showClave: PropTypes.bool,
  showRepeat: PropTypes.bool,
  claveError: PropTypes.string,
  repeatError: PropTypes.bool,
};

CreacionClaveTemplate.defaultProps = {
  onChangeHandle: null,
  onShowHiddeHandle: null,
  onSubmitHandle: null,
  onFetchReglasHandle: null,
  isLoadingValidarOCrear: false,
  isLoadingReglasCrearClave: false,
  errorReglaClaves: '',
  successCrearClave: false,
  errorCrearClave: false,
  clave: '',
  repeat: '',
  showClave: false,
  showRepeat: false,
  claveError: '',
  repeatError: false,
};

export default CreacionClaveTemplate;
