import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';
import { fetchContextoAteActions } from '../../../application/actions/ate';
import { loadersActions } from '../../../application/actions/ui_loaders';
import apiLegado from '../../../infrastructure/apiLegado';

const apiDashboardTef = apiLegado(process.env.REACT_APP_API_ATE_DASHBOARD_TEF);

export function* fetchContextoAte() {
  try {
    yield put(loadersActions.fetchLoadingContextoAte({ isLoading: true }));

    const response  = yield call(apiDashboardTef.post, 'cpb/api/configuracion/dashboard-tef')

    if (response.error === false) {
      yield put(
        fetchContextoAteActions.fetchContextoAteSuccess({
          contexto: 'Ate',
          activoTef: response.data.destacados[0].activoTef,
          detalle: response.data.destacados[0].detalle,
          vigencia: response.data.destacados[0].vigencia,
        })
      );
    } else {
      yield put(fetchContextoAteActions.fetchContextoAteFailed());
    }

    yield put(loadersActions.fetchLoadingContextoAte({ isLoading: false }));
  } catch (error) {
    yield all([
      put(loadersActions.fetchLoadingContextoAte({ isLoading: false })),
      yield put(fetchContextoAteActions.fetchContextoAteFailed()),
    ]);
  }
}

export function* watchFetchContextoAte() {
  yield takeLatest(fetchContextoAteActions.fetchContextoAte.type, fetchContextoAte);
}

export default function* rootSaga() {
    yield spawn(watchFetchContextoAte);
}