/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/indicadorEconomico';

// ------- SAGA ------ //

function fetchIndicadorEconomicoUfSuccess(state, action) {
  const { monto } = action.payload;

  state.indicadorUf = monto;
  state.errorIndicadorUf = false;
}

function fetchIndicadorEconomicoUfFailed(state) {
  state.errorIndicadorUf = true;
  state.indicadorUf = null;
}

const fetchIndicadorEconomicoUf = createAction('fetchIndicadorEconomicoUf');

const indicadorEconomicoReducer = createSlice({
  name: 'indicadorEconomico',
  initialState,
  reducers: {
    fetchIndicadorEconomicoUfSuccess,
    fetchIndicadorEconomicoUfFailed,
  },
});

export const indicadorEconomicoActions = {
  ...indicadorEconomicoReducer.actions,
  fetchIndicadorEconomicoUf,
};

export default indicadorEconomicoReducer.reducer;
