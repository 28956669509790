import React from 'react';
import PropTypes from 'prop-types';

import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { addClass } from '@coopeuch-components/web';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Errores = (props) => {
  const {
    isButtonDisabled,
    onClickButton,
    showButton,
    title,
    message,
    buttonText,
    messageClass,
    titleClass,
    className,
  } = props;

  return (
    <div className={`col-md-6 col-sm-8 center-xs ${className}`}>
      <div className="row center-xs">
        <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={175} />
      </div>

      <p className={addClass([titleClass, 'text-size-l text-bold'])}>{title}</p>
      <p className={addClass([messageClass, 'text-size-m'])}>{message}</p>

      {showButton && (
        <Button
          rounded={5}
          bgColor="#e81d2b"
          color="white"
          dataset={{ id: 'factor-error' }}
          onClick={onClickButton}
          disabled={isButtonDisabled}
          width={274}
          shadow
          height={48}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

Errores.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  showButton: PropTypes.bool,
  messageClass: PropTypes.string,
  titleClass: PropTypes.string,
};

Errores.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  message: '',
  title: '',
  buttonText: '',
  showButton: false,
  messageClass: '',
  titleClass: '',
};

export default Errores;
