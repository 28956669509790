import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 57px;
  margin-top: 37px;
  @media (max-width: 1280px) {
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 16px;
  }
  .boxContainer {
    margin-top: 32px;
    @media (max-width: 991px) {
      margin-top: 16px;
    }
  }
  ._sqrUW {
    font-weight: bold !important;
    font-family: 'Ubuntu-Bold' !important;
  }
  ._1W4ig {
    padding: 0px;
  }
  .inline {
    display: flex;
    align-items: center;
  }
  .icon {
    cursor: pointer;
    margin-left: 10px;
  }
  .title {
    font-family: 'Ubuntu-Bold';
    margin-bottom: 20px;
    font-size: 16px;
  }
  .btnTooltip {
    background-color: transparent;
    font-size: 14px;
    border: 1px solid #007db7;
    border-radius: 50%;
    color: #007db7;
    margin-left: 6px;
  }

  .footer {
    button {
      margin-bottom: 24px;
      span {
        font-size: 14px !important;
        text-decoration: none !important;
        font-family: 'Ubuntu-Medium' !important;
      }
    }
  }

  .boxModal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    svg path {
      mask: none;
   }
    button {
      font-size: 14px !important;
      width: 100%;
      max-width: 250px;
      span {
        font-family: 'Ubuntu-Medium';
      }
    }
    p {
      font-size: 14px;
      font-family: 'Ubuntu-Regular';
      text-align: center;
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  .btnIcon {
    cursor: pointer;
    margin-left: 6px;
    width: 16px;
    height: 16px;
    margin-top: -5px;
  }

  label {
    font-size: 14px !important;
  }

  .boxDatos {
    input {
      font-size: 14px;
    }
  }
`;

export default Wrapper;
