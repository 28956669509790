/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import List from '@coopeuch-components/web/molecules/List';
import Skeleton from 'react-loading-skeleton';
import PageTitle from '../../../atoms/PageTitle';
import DashboardCard from '../../../organism/DashboardCard';

import Wrapper, { BannerWrapper } from './styles';
import { solicitudTexts } from '../../../../containers/CuentaAhorro/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const SolicitaCuentaAhorroTemplate = ({ handleSolicitudCAFlujo, onHandleClickBanner, isBlackListed, persona }) => {
  const cliente = persona?.rol;
  const habito = useMemo(
    () => [
      {
        id: '1',
        name: solicitudTexts.CARD_HABITO_DESC,
        classes: 'bullet-red line-height26 solicitud-cuenta-ahorra-desc start-li',
      },
    ],
    []
  );

  const sinProblemas = useMemo(
    () => [
      {
        id: '1',
        name: solicitudTexts.CARD_SIN_PROBLEMAS_DESC,
        classes: 'bullet-red line-height26 solicitud-cuenta-ahorra-desc start-li',
      },
    ],
    []
  );
  const mantencion = useMemo(
    () => [
      {
        id: '1',
        name: solicitudTexts.CARD_MANTENCION_DESC,
        classes: 'bullet-red line-height26 solicitud-cuenta-ahorra-desc start-li',
      },
    ],
    []
  );
  const socio = useMemo(
    () => [
      {
        id: '1',
        name: solicitudTexts.CARD_SOCIO_DESC,
        classes: 'bullet-red line-height26 solicitud-cuenta-ahorra-desc start-li',
      },
    ],
    []
  );

  return (
    <Wrapper>
      <div className="title-cuenta-ahorro">
        <PageTitle
          title={solicitudTexts.TITLE_PAGE}
          icon={<LoadIcon src={`${iconPath}ico_cta_ahorro.svg`} />}
        />
      </div>
      <div className="container-margin-banner padding-title mb-24 cuenta-ahorro-desktop">
        {isBlackListed === false && persona.rol === 'SOCIO' ? (
          <BannerWrapper className="row m-0" src={`${staticPath}banner_solicita_cuentaahorro_1.jpg`}>
            <div className="banner-container mt-24">
              <div className="banner-desc-1">
                <label className="banner-text-1">{solicitudTexts.BANNER_DESC_1}</label>
              </div>
              <div className="pb-20">
                <label className="banner-text-2">{solicitudTexts.BANNER_DESC_2}</label>
              </div>
              <div className="mb-24">
                {/* <button onClick={handleSolicitudCAFlujo}>Flujo</button> */}
                {/* // TODO: convertir a componente */}
                {isBlackListed === false ? (
                  <Button
                    bgColor="red"
                    color="white"
                    className="text-size-m"
                    id="btn-cuenta-ahorro-solicita"
                    onClick={onHandleClickBanner}
                  >
                    {' '}
                    <label className="btn-cuenta-ahorro-solicita">
                      {solicitudTexts.BANNER_BUTTON_TEXT}
                    </label>
                  </Button>
                ) : isBlackListed === true ? (
                  ''
                ) : (
                  <Skeleton width={175} height={48} />
                )}
              </div>
            </div>
          </BannerWrapper>
        ) : ''}
      </div>
      <div className="container-margin-banner padding-title mb-24 cuenta-ahorro-mobile">
        <BannerWrapper className="row m-0" src={`${staticPath}banner_solicita_cuentaahorro_2.jpg`}>
          <div className="row banner-container mt-24">
            <div className="col-xs-6 banner-desc-1">
              <label className="banner-text-1">{solicitudTexts.BANNER_DESC_1}</label>
            </div>
            <div className="col-xs-6" />
            <div className="col-xs-6 pb-20">
              <label className="banner-text-2">{solicitudTexts.BANNER_DESC_2}</label>
            </div>
            <div className="col-xs-6" />
            <div className="banner-button-margin-mobile">
              {/* // TODO: convertir a componente */}
              {isBlackListed === false ? (
                <Button
                  bgColor="red"
                  color="white"
                  className="text-size-m"
                  id="btn-cuenta-ahorro-solicita"
                  onClick={onHandleClickBanner}
                >
                  {' '}
                  <label className="btn-cuenta-ahorro-solicita">
                    {solicitudTexts.BANNER_BUTTON_TEXT}
                  </label>
                </Button>
              ) : isBlackListed === true ? (
                ''
              ) : (
                <Skeleton width={175} height={48} />
              )}
            </div>
          </div>
        </BannerWrapper>
      </div>
      <div className="container-margin mb-24">
        <label className="label-title">{solicitudTexts.TITLE_SUB_BENEFICIOS}</label>
      </div>
      <div className="container-margin container-end">
        <div className="row m-0 container-card-solicitud">
          <div className="col-md-6 col-xs-12 bmb-3 bmb-md-0 bpr-0 bpl-0 bpr-md-2">
            <div className="bpr-0 bpr-md-1">
              <DashboardCard classes="dashboard-card-shadow ">
                <DashboardCard.Head
                  title={solicitudTexts.CARD_HABITO_TITLE}
                  titleSize={18}
                  icon="coins"
                  borderBottom="complete"
                  size={27}
                />
                <DashboardCard.Body classes="cuenta-ahorro-card-container">
                  <List classes="list-dashboard" itemList={habito} />
                </DashboardCard.Body>
              </DashboardCard>
            </div>
          </div>
          <div className="col-md-6 col-xs-12 bpl-0 bpr-0 bpl-md-2">
            <div className="bpl-0 bpl-md-1">
              <DashboardCard classes="dashboard-card-shadow">
                <DashboardCard.Head
                  title={solicitudTexts.CARD_SIN_PROBLEMAS_TITLE}
                  titleSize={18}
                  icon="initiate_money_transfer"
                  borderBottom="complete"
                  size={27}
                />
                <DashboardCard.Body classes="cuenta-ahorro-card-container">
                  <List classes="list-dashboard" itemList={sinProblemas} />
                </DashboardCard.Body>
              </DashboardCard>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-md-6 col-xs-12 bmb-3 bmb-md-0 bpr-0 bpl-0 bpr-md-2">
            <div className="bpr-0 bpr-md-1">
              <DashboardCard classes="dashboard-card-shadow">
                <DashboardCard.Head
                  title={solicitudTexts.CARD_MANTENCION_TITLE}
                  titleSize={18}
                  icon="free"
                  borderBottom="complete"
                  size={27}
                />
                <DashboardCard.Body classes="cuenta-ahorro-card-container">
                  <List classes="list-dashboard" itemList={mantencion} />
                </DashboardCard.Body>
              </DashboardCard>
            </div>
          </div>
          <div className="col-md-6 col-xs-12  bpl-0 bpr-0 bpl-md-2">
            <div className="bpl-0 bpl-md-1">
              <DashboardCard classes="dashboard-card-shadow">
                <DashboardCard.Head
                  title={solicitudTexts.CARD_SOCIO_TITLE}
                  titleSize={18}
                  icon="user"
                  borderBottom="complete"
                />
                <DashboardCard.Body classes="cuenta-ahorro-card-container">
                  <List classes="list-dashboard" itemList={socio} />
                </DashboardCard.Body>
              </DashboardCard>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default SolicitaCuentaAhorroTemplate;
