import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidEmail } from '../../../utils/formatters';
import { personaActions } from '../../../../application/actions/persona';
import { tipoActions } from '../../../../application/actions/tipo';
import { layoutActions } from '../../../../application/actions/ui_layout';
import { solicitudCVActions } from '../../../../application/actions/solicitudCuentaVista';
import SolicitaCVFlowTemplate from '../../../components/templates/CuentaVista/Solicita/Flow';
import Error from '../../../components/templates/CuentaVista/Solicita/Flow/Error';
import { downloadPDFBase64 } from '../../../utils/functions';
import MensajeNoHabilitado from '../../../components/templates/CuentaVista/Solicita/MensajeNoHabilitado';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

const FLUJO_DESPACHO_DOMICILIO = 0;
const initState = {
  steps: ['Datos personales', 'Documentación y Firma', 'Comprobante'],
  currentStep: 1,
  fetchingData: true,
  despachoDomicilio: true,
  region: null,
  regionOptions: [],
  comuna: null,
  comunaOptions: [],
  notSucursal: false,
  sucursal: null,
  sucursalOptions: [],
  calle: '',
  numeroCasa: '',
  otrosCasa: '',
  password: '',
  showPassword: false,
  passwordError: '',
  blockedPassword: false,
  serverPasswordError: { title: '', message: '', buttonTitle: '' },
  wrongPassword: false,
  serviceError: false,
  email: '',
  emailError: '',
  poseeFactibilidad: null,
  checked: false,
  checkdRequiredError: false,
  globalError: false,
  secondEmail: '',
  secondEmailError: '',
  respuestaDetalle: {},
  contratoSolicitado: '',
  loadingStep1: false,
  loadingStep2: false,
  loadingFetchContrato: false,
  loadingSendComprobante: false,
  showEnviarComprobanteModal: false,
  showComprobanteEnviadoModal: false,
  showEnviarComprobanteErrorModal: false,
  habilitadoContratar: true,
  showErrorNoPuedeContratar: false,
  errorEnFirma: false,
  getComprobanteIntervalId: null,
  IsAvailablePassCoopeuch: true,
  IsAvailableTarCoo: false,
  errorTimeOut: false,
  counterActive: false,
  sendDesafio: { desafio1: '', desafio2: '', desafio3: '' },
  tarjetaDeCoord: false,
  firmo: false,
  IsLoading: false,
  coordenadas: false,
  tarjeta: '',
  continua: true,
};
const nombreContratos = {
  ABONO_CREENCIA: 'Poder especial abono de acreencias',
  HOJA_RESUMEN: 'Hoja resumen contrato',
  CONTRATO_CANALES: 'Contrato de canales',
  CONTRATO_APERTURA: 'Contrato de apertura Cuenta Vista',
  ANEXO_SAC: 'Anexo servicio de atención al cliente',
  ANEXO_COMISIONES: 'Anexo comisiones Cuenta Vista',
};

const operacion = {
  validacion: 42,
  guardar: 43,
  solicitarDesafio: 44,
  validarDesafio: 46,
  firmar: 48,
  comprobante: 49,
};

class Flow extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount = () => {
    const {
      fetchDatosPersonales,
      fetchRegiones,
      fetchComunas,
      fetchCoberturas,
      fetchSucursales,
      validarSolicitud,
    } = this.props;
    fetchDatosPersonales();
    fetchRegiones();
    fetchComunas(0);
    fetchCoberturas();
    fetchSucursales(0);
    validarSolicitud();
    registerGA({
      event: 'vpv-privado',
      page: '/tef/solicitar/cuentaVista/paso1',
    });

    // Comenzar flujo arriba
    window.scrollTo(0, 0);
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { habilitadoContratar, globalError } = this.state;
    const {
      regiones,
      comunas,
      sucursales,
      cobertura,
      persona,
      guardarDatosPersonales,
      guardarSucursal,
      firmarSolicitud,
      getDetalleContratacion,
      obtenerComprobante,
      obtenerContrato,
      enviarComprobante,
      listaNegra,
      productosDisponibles,
      isLoadingSucursalesComuna,
      sucursalesComuna,
      availablePassCoopeuch,
      validaDesafio,
      IsValidatePassCoopeuch,
      solicitarDesafioPassCoopeuch,
      IsAvailablePassCoopeuch,
    } = this.props;

    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));

    if (validaDesafio !== prevProps.validaDesafio && validaDesafio.success === true) {
      this.continuaFirma();
    }
    if (validaDesafio.success === false) {
      this.continuaFlujo();
    }

    if (prevProps.isLoadingSucursalesComuna !== isLoadingSucursalesComuna) {
      if (!isLoadingSucursalesComuna) {
        if (sucursalesComuna.data.length) {
          const sucursalFilter = sucursalesComuna.data.map((sucursal) => ({
            label: sucursal.descripcion,
            value: sucursal.codigo.toString(),
            ...sucursal,
          }));

          this.setState({
            sucursalOptions: sucursalFilter,
            notSucursal: false,
          });
        } else {
          this.setState({
            notSucursal: true,
            sucursal: null,
          });
          console.log('no hay comunas');
        }
      }
    }

    if (
      (regiones !== prevProps.regiones && regiones.error) ||
      (comunas !== prevProps.comunas && comunas.error) ||
      (sucursales !== prevProps.sucursales && sucursales.error) ||
      (cobertura !== prevProps.cobertura && cobertura.error) ||
      (persona !== prevProps.persona && persona.errorDatosPersonales) ||
      (productosDisponibles !== prevProps.productosDisponibles && productosDisponibles.error) ||
      (listaNegra !== prevProps.listaNegra && listaNegra.error)
    ) {
      this.setGlobalError();
    } else if (persona !== prevProps.persona && !!persona.correo) {
      this.setState({ email: persona.correo });
    } else if (
      (regiones !== prevProps.regiones ||
        comunas !== prevProps.comunas ||
        sucursales !== prevProps.sucursales ||
        cobertura !== prevProps.cobertura ||
        persona !== prevProps.persona ||
        habilitadoContratar !== prevState.habilitadoContratar) &&
      regiones.success &&
      comunas.success &&
      sucursales.success &&
      cobertura.success &&
      !persona.errorDatosPersonales &&
      !!persona.rol &&
      !!habilitadoContratar
    ) {
      this.setOptions({ regiones });
      this.setState({ fetchingData: false });
    } else if (guardarDatosPersonales !== prevProps.guardarDatosPersonales) {
      if (guardarDatosPersonales.error) {
        this.setGlobalError();
      } else {
        registerGA({
          event: 'vpv-privado',
          page: '/tef/solicitar/cuentaVista/paso2',
        });
        this.toNextStep();
      }
    } else if (guardarSucursal !== prevProps.guardarSucursal) {
      if (guardarSucursal.error) {
        this.setGlobalError();
      } else {
        registerGA({
          event: 'vpv-privado',
          page: '/tef/solicitar/cuentaVista/paso2',
        });
        this.toNextStep();
      }
    } else if (availablePassCoopeuch !== prevProps.availablePassCoopeuch) {
      if (availablePassCoopeuch.error === true) {
        this.setGlobalError();
      }
      if (availablePassCoopeuch.success === true) {
        this.verificarFactores();
      }
    } else if (firmarSolicitud !== prevProps.firmarSolicitud) {
      this.handleFirmaResponse(firmarSolicitud);
    } else if (getDetalleContratacion !== prevProps.getDetalleContratacion) {
      if (getDetalleContratacion.error) {
        this.setState({ errorEnFirma: true });
      } else if (getDetalleContratacion.etapa === 3) {
        clearInterval(this.state.getComprobanteIntervalId);
        this.setDetalle(getDetalleContratacion);
      }
    } else if (obtenerComprobante !== prevProps.obtenerComprobante && obtenerComprobante.success) {
      this.handleDownloadComprobante();
    } else if (obtenerContrato !== prevProps.obtenerContrato && obtenerContrato.success) {
      this.handleDownloadContrato();
    } else if (enviarComprobante !== prevProps.enviarComprobante) {
      if (enviarComprobante.error) {
        this.setState({
          showEnviarComprobanteModal: false,
          showComprobanteEnviadoModal: false,
          showEnviarComprobanteErrorModal: true,
          loadingSendComprobante: false,
        });
      } else if (enviarComprobante.success) {
        this.setState({
          loadingSendComprobante: false,
          showEnviarComprobanteModal: false,
          showComprobanteEnviadoModal: true,
          showEnviarComprobanteErrorModal: false,
        });
      }
    }
    if (habilitadoContratar !== prevState.habilitadoContratar && !habilitadoContratar) {
      this.setState({ showErrorNoPuedeContratar: true });
    }
    if (
      (productosDisponibles !== prevProps.productosDisponibles ||
        listaNegra !== prevProps.listaNegra ||
        persona !== prevProps.persona) &&
      !!productosDisponibles.cuentas &&
      !!productosDisponibles.tarjetas &&
      !!listaNegra.success &&
      !!persona.numeroCliente &&
      !persona.errorDatosPersonales
    ) {
    }
    if (prevState.globalError !== globalError && !!globalError) {
      this.markDataLayerError({ stepNumber: 1, message: 'Servicio no disponible' });
    }
    //Marca error generico
    if (
      IsValidatePassCoopeuch.success === false ||
      solicitarDesafioPassCoopeuch.success === false ||
      IsValidatePassCoopeuch.codigo === 50 ||
      IsValidatePassCoopeuch.codigo === 2
    ) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PassCoopeuch Servicio no disponible',
      });
    }
    //Marca operación rechazada
    if (IsValidatePassCoopeuch.codigo === 8) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PassCoopeuch Operación Rechazada',
      });
    }
    // Marca Tarjeta de coordenadas bloqueada
    if (validaDesafio.error.code === '5' || validaDesafio.error.code === '4') {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PassCoopeuch Operación Rechazada',
      });
      // log athenas
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_TCOO_VALIDAR_BLOQUEO',
        informacion_adicional: { error : validaDesafio.error },
      });
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.state.getComprobanteIntervalId);
  };

  markDataLayerError = ({ stepNumber, message }) => {
    let paso = 'paso1';
    if (stepNumber === 2) {
      paso = 'paso2';
    }
    if (stepNumber === 3) {
      paso = 'comprobante';
    }
    registerGA({
      event: 'eve',
      'eve-acc': `/tef/solicitar/cuentaVista/${paso}`,
      'eve-cat': 'Error',
      'eve-lab': message,
    });
  };

  markDataLayerClick = ({ stepNumber, buttonLabel }) => {
    let paso = 'paso1';
    if (stepNumber === 2) {
      paso = 'paso2';
    }
    if (stepNumber === 3) {
      paso = 'comprobante';
    }
    registerGA({
      event: 'eve',
      'eve-acc': `/tef/solicitar/cuentaVista/${paso}`,
      'eve-cat': 'Click',
      'eve-lab': buttonLabel,
    });
  };

  checkActiveCard = (accountId, cards) => {
    const accountCards = cards.filter(
      (card) =>
        card.numeroCuenta === accountId &&
        card.codEstado === '01' &&
        ['', 'O', 'S', 'N'].includes(card.bloqueo)
    );
    return accountCards.length;
  };

  handleFirmaResponse = (response) => {
    if (response.error) {
      let serverPasswordError,
        blockedPassword = false,
        wrongPassword = false,
        habilitadoContratar = this.state.habilitadoContratar,
        errorEnFirma = false;
      switch (response.code) {
        case 'SCV-01':
          habilitadoContratar = false;
          break;
        case '01':
          wrongPassword = true;
          serverPasswordError = {
            title: 'Clave incorrecta',
            message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
            buttonTitle: 'Reintentar',
          };
          break;
        case '02':
          wrongPassword = true;
          serverPasswordError = {
            title: 'Clave incorrecta',
            message: 'Recuerda que al tercer intento se bloqueará la contraseña.',
            buttonTitle: 'Reintentar',
          };
          break;
        case '03':
          wrongPassword = true;
          blockedPassword = true;
          serverPasswordError = {
            title: 'Clave Bloqueada',
            message: 'Clave Bloqueada. Por seguridad hemos bloqueado tu clave.',
            buttonTitle: 'Cambiar Clave',
          };
          break;
        default:
          serverPasswordError = { title: '', message: '', buttonTitle: '' };
          errorEnFirma = true;
      }
      if (errorEnFirma) {
        this.markDataLayerError({ stepNumber: 2, message: 'Servicio no disponible' });
      }
      if (blockedPassword) {
        this.markDataLayerError({ stepNumber: 2, message: 'Clave bloqueada' });
      }
      this.setState({
        loadingStep2: false,
        habilitadoContratar,
        errorEnFirma,
        wrongPassword,
        serverPasswordError,
        blockedPassword,
      });
    } else if (response.success) {
      const { fetchGetDetalleContratacion } = this.props;
      this.setState({
        serverPasswordError: { title: '', message: '', buttonTitle: '' },
      });
      // fetchLayoutConfigs();
      /**
       * se debe invocar cada 3 segundos el servicio para obtener el detalle de
       * contratacion. Si el servicio retorna etapa = 3 fue exitoso y se pasa al siguiente step
       */
      const getComprobanteIntervalId = setInterval(() => {
        fetchGetDetalleContratacion();
      }, 3000);
      this.setState({ getComprobanteIntervalId });
    }
  };

  handleWrongPassword = () => {
    if (this.state.blockedPassword) {
      this.markDataLayerClick({ stepNumber: 2, buttonLabel: 'Cambiar clave' });
      this.props.history.push('/recuperar-clave');
    } else {
      this.setState({ wrongPassword: false, password: '' });
    }
  };

  setOptions = ({ regiones }) => {
    this.setState({
      regionOptions: regiones.values.map((region) => ({
        label: region.descripcion,
        value: region.codigo,
      })),
    });
  };

  setDetalle = (detalle) => {
    const { despachoDomicilio } = this.state;
    const { sucursales } = this.props;
    let sucursal;

    try {
      sucursal = sucursales.values.find(
        (sucursal) => sucursal.codigo.toString() === detalle.sucursal.toString()
      ).direccion;
    } catch (error) {
      sucursal = '';
    }

    const direccion = despachoDomicilio ? `${detalle.direccion}` : `${sucursal}`;

    this.setState(
      {
        respuestaDetalle: {
          ...detalle,
          direccionCompleta: direccion,
        },
      },
      () => {
        this.toNextStep();
      }
    );
  };

  setGlobalError = () => {
    this.setState({
      globalError: true,
    });
  };

  handleSendGTMDownloadContract = () => {
    this.markDataLayerClick({ stepNumber: 2, buttonLabel: 'Descargar contrato' });
  };

  changeTab = (tabNumber) => {
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));

    this.setState({
      despachoDomicilio: tabNumber === FLUJO_DESPACHO_DOMICILIO ? true : false,
      region: initState.region,
      comuna: initState.comuna,
      sucursal: initState.sucursal,
      calle: initState.calle,
      numeroCasa: initState.numeroCasa,
      otrosCasa: initState.otrosCasa,
    });

    if (tabNumber === FLUJO_DESPACHO_DOMICILIO) {
    } else {
    }
  };

  handleOnChangeInput = (e) => {
    const { value, name } = e.target;
    if (value.length > 128) return;
    this.setState({
      [name]: value,
    });
  };

  handleOnChangeRegion = (e) => {
    const { value } = e.target;
    const { comunas, sucursales } = this.props;
    const { regionOptions } = this.state;

    const comunasFiltradas = comunas.values.filter(
      (comuna) => comuna.codigoRegion === value.toString()
    );

    const sucursalesFiltradas = sucursales.values.filter(
      (sucursal) => sucursal.codigoRegion === value.toString()
    );

    const region = regionOptions.filter((region) => region.value === value.toString())[0];

    this.setState({
      comunaOptions: comunasFiltradas.map((comuna) => ({
        label: comuna.descripcion,
        value: comuna.codigo,
      })),
      sucursalOptions: sucursalesFiltradas.map((sucursal) => ({
        label: sucursal.descripcion,
        value: sucursal.codigo.toString(),
        ...sucursal,
      })),
      region,
      sucursal: initState.sucursal,
    });
  };

  handleOnChangeComuna = (e) => {
    const { fetchSucursalesComuna, sucursales, cobertura } = this.props;
    const { comunaOptions } = this.state;
    const { value } = e.target;
    fetchSucursalesComuna(value);
    let tieneCobertura = false;
    try {
      tieneCobertura = cobertura.values[0].comunas.find(
        (comuna) => comuna.codComuna === value.toString()
      );
    } catch (error) {
      console.error('comuna sin cobertura');
    }
    const sucursalesFiltradas = sucursales.values.filter(
      (sucursal) => sucursal.codigoRegion === value.toString()
    );

    this.setState({
      comuna: comunaOptions.filter((comuna) => comuna.value === value.toString())[0],
      poseeFactibilidad: !!tieneCobertura,
    });
  };

  handleOnChangeSucursal = (e) => {
    const { value } = e.target;
    const { sucursalOptions } = this.state;
    const sucursal = sucursalOptions.filter((sucursal) => sucursal.value === value)[0];
    this.setState({
      sucursal,
    });
  };

  handleCheckBox = () => {
    const { email } = this.state;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    if (email === '') {
      this.setState({
        checked: false,
        checkdRequiredError: true,
      });
      return;
    } else if (!isValidEmail(email)) {
      this.setState({
        checked: false,
        checkdRequiredError: true,
      });
      return;
    }
    this.setState((prevState) => ({
      checked: !prevState.checked,
      // Remover alerta de "check" requerido en los términos y condiciones
      checkdRequiredError: false,
    }));
    // log athenas terminos y condiciones

    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_ACEPTOTERMINOSYCONDICIONES',
      informacion_adicional: { condiciones: 'acepta' },
    });
  };

  handlePasswordInput = (e) => {
    const value = e.target.value;
    if (value.length > 8) return;
    const errorMessage = 'La clave debe tener entre 6 y 8 caracteres.';
    this.setState({
      password: value,
      passwordError: value.length < 6 || value.length > 8 ? errorMessage : '',
    });
  };

  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleChangeEmail = (e) => {
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    const value = e.target.value;
    this.setState({
      checked: false,
    });
    if (value.length > 255) return;
    const name = e.target.name;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      [name]: value,
      [`${name}Error`]: isValidEmail(value) ? '' : errorMessage,
    });
    //LOG ATHENAS
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_OTROMAIL',
      informacion_adicional: { nuevoEmail: value },
    });
  };

  handleDownloadContrato = (e) => {
    const { fetchObtenerContrato, obtenerContrato } = this.props;
    const { contratoSolicitado } = this.state;
    if (obtenerContrato.success && obtenerContrato.data.length > 0) {
      try {
        const selectedContrato = contratoSolicitado
          ? contratoSolicitado
          : e.currentTarget.getAttribute('data-id');
        downloadPDFBase64(
          obtenerContrato.data.find((contrato) => contrato.tipo === selectedContrato).cuerpo,
          nombreContratos[selectedContrato]
        );
        this.setState({
          contratoSolicitado: '',
          loadingFetchContrato: false,
        });
      } catch (error) {
        console.error('error descargando contrato');
      }
    } else {
      const contratoSolicitado = e.currentTarget.getAttribute('data-id');
      this.setState({
        contratoSolicitado,
        loadingFetchContrato: true,
      });
      fetchObtenerContrato();
    }
  };

  handleDownloadComprobante = () => {
    const { fetchObtenerComprobante, obtenerComprobante } = this.props;
    if (obtenerComprobante.success && obtenerComprobante.data !== null) {
      downloadPDFBase64(obtenerComprobante.data, 'Comprobante Contratacion Cuenta Vista');
      this.markDataLayerClick({ stepNumber: 3, buttonLabel: 'Descargar' });
    } else {
      fetchObtenerComprobante();
    }
  };

  handleStartSendComprobanteEmail = () => {
    this.setState(
      {
        showEnviarComprobanteModal: true,
      },
      () => {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/solicitar/cuentaVista/paso3',
          'eve-cat': 'Click',
          'eve-lab': 'Enviar',
        });
      }
    );
  };

  handleSendComprobanteEmail = () => {
    const { fetchEnviarComprobante } = this.props;
    const correo = this.state.secondEmail;
    this.setState({
      loadingSendComprobante: true,
    });
    fetchEnviarComprobante({ correo });
  };

  toHome = () => {
    this.props.history.push('/ecd/inicio');
  };

  toCuentaVista = () => {
    this.props.history.push('/ecd/productos/cuenta-coopeuch');
  };

  toNextStep = () => {
    this.setState(
      (prevState) => ({
        currentStep: prevState.currentStep + 1,
      }),
      () => {
        // Scrollear arriba cada vez que se avance al siguiente paso
        window.scrollTo(0, 0);
      }
    );
  };

  backStep1 = () => {
    this.markDataLayerClick({ stepNumber: 1, buttonLabel: 'Salir' });
    this.toHome();
  };

  continueStep1 = () => {
    const { despachoDomicilio, region, comuna, sucursal, calle, numeroCasa, otrosCasa } =
      this.state;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    const {
      fetchGuardarDatosPersonales,
      fetchGuardarSucursal,
      trackValidarSolicitud,
      fetchObtenerDesafios,
    } = this.props;
    this.setState({
      loadingStep1: true,
    });
    if (despachoDomicilio) {
      fetchGuardarDatosPersonales({
        track: trackValidarSolicitud,
        region: region.value,
        comuna: comuna.value,
        calle,
        numero: numeroCasa,
        otros: otrosCasa,
        operacion: operacion.guardar,
      });
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_DOMICILIO',
        informacion_adicional: {},
      });
    } else {
      fetchGuardarSucursal({
        track: trackValidarSolicitud,
        region: region.value,
        sucursal: parseInt(sucursal.value, 10),
        operacion: operacion.guardar,
      });
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_SUCURSAL',
        informacion_adicional: {},
      });
    }
    fetchObtenerDesafios();
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
  };

  backStep2 = () => {
    this.markDataLayerClick({ stepNumber: 2, buttonLabel: 'Salir' });
    this.toHome();
  };

  continueStep2 = () => {
    const { fetchFirmarSolicitud, trackValidarSolicitud } = this.props;
    const { password, email, checked } = this.state;
    if (!checked) {
      this.setState({ checkdRequiredError: true });
      return;
    }

    const correo = email;
    this.setState({
      loadingStep2: true,
    });
    fetchFirmarSolicitud({ track: trackValidarSolicitud, clave: password, correo });
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
  };

  goToHome = () => {
    this.markDataLayerClick({ stepNumber: 3, buttonLabel: 'Ir a Home' });
    this.toHome();
  };

  onRedirectToSchedule = () => {
    this.markDataLayerClick({ stepNumber: 3, buttonLabel: 'Agendar visita' });
  };

  continueStep3 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista/paso3',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a cuenta vista',
    });
    this.toCuentaVista();
  };

  onErrorCallback = () => {
    const { currentStep } = this.state;
    this.markDataLayerClick({ stepNumber: currentStep, buttonLabel: 'Volver a intentar' });

    this.toCuentaVista();
  };

  closeAllModals = () => {
    this.setState({
      showComprobanteEnviadoModal: false,
      showEnviarComprobanteErrorModal: false,
      showEnviarComprobanteModal: false,
    });
  };

  validarPassCoopeuchCP = () => {
    const {
      validarPassCoopeuchCP,
      IsValidatePassCoopeuch,
      trackValidarSolicitud,
      fetchFirmarSolicitud,
      persona,
      despachoDomicilio,
    } = this.props;
    const { firmo, email, IsLoading } = this.state;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    this.setState({
      IsAvailableTarCoo: false,
    });
    if (
      IsValidatePassCoopeuch.success === false ||
      IsValidatePassCoopeuch.codigo === 7 ||
      IsValidatePassCoopeuch.codigo === 8 ||
      IsValidatePassCoopeuch.codigo === 50
    ) {
      this.errorTimeOutCP(null, false);
      // marca error passCoopeuch bloqueada
      if (IsValidatePassCoopeuch.codigo === 7) {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/solicitar/cuentaVista/paso2',
          'eve-cat': 'Error',
          'eve-lab': 'PassCoopeuch bloqueada',
        });
        // log athenas passCoopeuch bloqueada
        logger(null, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_PASSCOOPEUCH_VALIDAR_BLOQUEO',
          informacion_adicional: { error: IsValidatePassCoopeuch },
        });
      }
    } else if (IsValidatePassCoopeuch.success === null || IsValidatePassCoopeuch.codigo === 25) {
      validarPassCoopeuchCP({
        track: trackValidarSolicitud,
        operacion: operacion.validarDesafio,
        params: {
          data: {
            producto: '',
            authorizerCode: null,
          },
        },
      });
    } else {
      if (IsValidatePassCoopeuch.codigo === 0) {
        //marca metodo de autorización pass coopeuch
        let correo = email;
        let passwd = '********';
        if (!firmo) {
          this.setState({
            firmo: true,
            IsLoading: true,
          });
          fetchFirmarSolicitud({
            track: trackValidarSolicitud,
            operacion: operacion.firmar,
            clave: passwd,
            correo,
          });
          registerGA({
            event: 'vpv-privado',
            page: '/tef/solicitar/cuentaVista/comprobante',
            // Se ha optado por indicar literalmente el código, ya que para efectos de analítica,
            // contextualmente se entiende que este es el valor del producto contratado
            producto: 'VI01',
            valoracion: despachoDomicilio ? 'Despacho a Domicilio' : 'Retiro en Sucursal',
            formaPago: 'PassCoopeuch',
          });
          //log contratacion exitosa
          logger(null, {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_CONTRATACIONEXITOSA',
            informacion_adicional: { firma: true },
          });
        }
      }
    }
  };

  errorTimeOutCP = (estadoError, estadoCounter) => {
    this.setState({
      errorTimeOut: estadoError,
      counterActive: estadoCounter,
    });
  };

  solicitarPassCoopeuch = () => {
    const { solicitarPassCoopeuch, trackValidarSolicitud } = this.props;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    this.setState({
      counterActive: true,
    });

    solicitarPassCoopeuch({
      track: trackValidarSolicitud,
      operacion: operacion.solicitarDesafio,
      params: {
        data: {
          producto: '',
          authorizerCode: null,
        },
      },
    });

    this.setState({
      counterActive: true,
    });

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar con PassCoopeuch',
    });

    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_PASSCOOPEUCH_AUTORIZAR',
      informacion_adicional: {},
    });
  };

  handleSwitchMethods = (method) => {
    const {
      solicitarPassCoopeuchReset,
      validarPassCoopeuchCPReset,
      onHandleObtenerDesafioReset,
      validarDesafioReset,
    } = this.props;
    this.setState({
      counterActive: false,
      errorTimeOut: false,
    });
    this.verificarFactores();
    if (method == 'tarjetacoord') {
      this.setState({
        tarjetaDeCoord: true,
        continua: true,
      });
      solicitarPassCoopeuchReset();
      validarPassCoopeuchCPReset();
    } else {
      this.setState({
        tarjetaDeCoord: false,
      });
      let sendDesafio1 = {
        desafio1: '',
        desafio2: '',
        desafio3: '',
      };
      this.setState({ sendDesafio: sendDesafio1 });
      onHandleObtenerDesafioReset();
      validarDesafioReset();
    }
    // Marca cambio método de autorización
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista/paso2',
      'eve-cat': 'Click',
      'eve-lab':
        method === 'tarjetacoord'
          ? 'Quiero autorizar con tarjeta de coordenadas'
          : 'Quiero autorizar con PassCoopeuch',
    });
  };

  retryTimeOutPass = () => {
    const {
      solicitarPassCoopeuchReset,
      validarPassCoopeuchCPReset,
      onHandleObtenerDesafioReset,
      validarDesafioReset,
      IsValidatePassCoopeuch,
      solicitarDesafioPassCoopeuch,
      errorTimeOut,
    } = this.props;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    this.setState({
      counterActive: false,
      errorTimeOut: false,
      continua: true,
    });
    let sendDesafio1 = {
      desafio1: '',
      desafio2: '',
      desafio3: '',
    };
    this.setState({ sendDesafio: sendDesafio1 });
    solicitarPassCoopeuchReset();
    validarPassCoopeuchCPReset();
    onHandleObtenerDesafioReset();
    validarDesafioReset();
    this.verificarFactores();
    // Marca click boton reintentar se agoto el tiempo y marca evento click error generico
    if (
      IsValidatePassCoopeuch.success === false ||
      solicitarDesafioPassCoopeuch.success === false ||
      IsValidatePassCoopeuch.codigo === 50 ||
      IsValidatePassCoopeuch.codigo === 2
    ) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'volver a intentar - PassCoopeuch no disponible ',
      });

      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_PASSCOOPEUCH_REINTENTAR',
        informacion_adicional: {},
      });
    }
    if (errorTimeOut === true) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - PassCoopeuch tiempo agotado',
      });
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_PASSCOOPEUCH_REINTENTAR',
        informacion_adicional: {},
      });
    }
    if (errorTimeOut === null && IsValidatePassCoopeuch.codigo === 8) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - PassCoopeuch Operación Rechazada',
      });
    }
  };

  onHandleObtenerDesafio = () => {
    const { onHandleObtenerDesafio, trackValidarSolicitud } = this.props;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    this.setState({
      counterActive: true,
    });
    onHandleObtenerDesafio({
      track: trackValidarSolicitud,
      operacion: operacion.solicitarDesafio,
      params: {
        data: {
          operacion: operacion.solicitarDesafio,
        },
      },
    });

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/solicitar/cuentaVista/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar',
    });

    //
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_TCOO_AUTORIZAR',
      informacion_adicional: {},
    });
  };

  onHandleSetDesafio = (e) => {
    const { sendDesafio } = this.state;
    let { desafio1, desafio2, desafio3 } = sendDesafio;
    const { value, name } = e.target;
    if (name == 'desafio1') {
      desafio1 = value;
    } else if (name == 'desafio2') {
      desafio2 = value;
    } else if (name == 'desafio3') {
      desafio3 = value;
    }
    let sendDesafio1 = {
      desafio1: desafio1,
      desafio2: desafio2,
      desafio3: desafio3,
    };
    this.setState({ sendDesafio: sendDesafio1 });
    if (
      sendDesafio1.desafio1.length === 2 &&
      sendDesafio1.desafio2.length === 2 &&
      sendDesafio1.desafio3.length === 2
    ) {
      this.setState({
        coordenadas: true,
      });
    } else {
      this.setState({
        coordenadas: false,
      });
    }
  };

  validarDesafio = () => {
    const { validarDesafio, trackValidarSolicitud } = this.props;
    const { sendDesafio } = this.state;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    this.setState({
      counterActive: false,
      IsAvailablePassCoopeuch: false,
      coordenadas: false,
      IsLoading: true,
    });
    validarDesafio({
      desafio: {
        valorDesafio1: sendDesafio.desafio1,
        valorDesafio2: sendDesafio.desafio2,
        valorDesafio3: sendDesafio.desafio3,
      },
      track: trackValidarSolicitud,
      operacion: operacion.validarDesafio,
    });
    //
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CCV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_TCOO_VALIDAR',
      informacion_adicional: {},
    });
  };

  continuaFirma = () => {
    const { trackValidarSolicitud, fetchFirmarSolicitud, validaDesafio } = this.props;
    const { firmo, email, despachoDomicilio } = this.state;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    let correo = email;
    let passwd = '********';

    if (!firmo && validaDesafio.success === true) {
      this.setState({
        firmo: true,
        IsLoading: true,
      });
      fetchFirmarSolicitud({
        track: trackValidarSolicitud,
        operacion: operacion.firmar,
        clave: passwd,
        correo,
      });
      //marca método autorización TC
      registerGA({
        event: 'vpv-privado',
        page: '/tef/solicitar/cuentaVista/comprobante',
        // Se ha optado por indicar literalmente el código, ya que para efectos de analítica,
        // contextualmente se entiende que este es el valor del producto contratado
        producto: 'VI01',
        valoracion: despachoDomicilio ? 'Despacho a Domicilio' : 'Retiro en Sucursal',
        formaPago: 'Tarjeta de Coordenadas',
      });
      //log contratacion exitosa
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_CONTRATACIONEXITOSA',
        informacion_adicional: { firma: true },
      });
    } else {
      this.setState({
        IsLoading: false,
      });
    }
  };

  continuaFlujo = () => {
    const { continua } = this.state;
    if (continua === true) {
      this.setState({
        IsLoading: false,
        continua: false,
      });
    }
  };

  verificarFactores = () => {
    const { availablePassCoopeuch } = this.props;
    const { IsAvailableTarCoo, IsAvailablePassCoopeuch, tarjeta } = this.state;
    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    this.setState({
      IsAvailableTarCoo: false,
      IsAvailablePassCoopeuch: false,
    });
    if (availablePassCoopeuch.data && availablePassCoopeuch.data.length > 0) {
      for (let i in availablePassCoopeuch.data) {
        //Log Athenas
        logger(null, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_FACTORES',
          informacion_adicional: {data : availablePassCoopeuch.data},
        });
        if (availablePassCoopeuch.data[i].codigo == 2.1) {
          this.setState({
            IsAvailableTarCoo: true,
            tarjeta: availablePassCoopeuch.data[i].payload,
          });
          // marca sin pass
          registerGA({
            event: 'eve',
            'eve-acc': '/tef/solicitar/cuentaVista/paso2',
            'eve-cat': 'Error',
            'eve-lab': 'PassCoopeuch sin App',
          });
          //Log Athenas
          logger(null, {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_DESCARGAPASSCOOPEUCH',
            informacion_adicional: {},
          });
        }
        if (availablePassCoopeuch.data[i].codigo == 2.2) {
          this.setState({
            IsAvailablePassCoopeuch: true,
          });
        }
      }
    }
  };

  render() {
    const {
      steps,
      currentStep,
      fetchingData,
      despachoDomicilio,
      region,
      regionOptions,
      comuna,
      comunaOptions,
      notSucursal,
      sucursal,
      sucursalOptions,
      calle,
      numeroCasa,
      otrosCasa,
      poseeFactibilidad,
      checked,
      checkdRequiredError,
      password,
      showPassword,
      passwordError,
      blockedPassword,
      serverPasswordError,
      wrongPassword,
      serviceError,
      email,
      emailError,
      globalError,
      secondEmail,
      secondEmailError,
      respuestaDetalle,
      loadingStep1,
      loadingStep2,
      loadingFetchContrato,
      loadingSendComprobante,
      showEnviarComprobanteModal,
      showComprobanteEnviadoModal,
      showEnviarComprobanteErrorModal,
      showErrorNoPuedeContratar,
      errorEnFirma,
      errorTimeOut,
      counterActive,
      tarjetaDeCoord,
      sendDesafio,
      IsLoading,
      coordenadas,
      IsAvailableTarCoo,
      IsAvailablePassCoopeuch,
      tarjeta,
    } = this.state;
    const {
      persona,
      isLoadingPersona,
      getDetalleContratacion,
      isLoadingValidarSolicitudCV,
      validarSolicitudError,
      validarSolicitudErrorCode,
      isLoadingSucursalesComuna,
      availablePassCoopeuch,
      solicitarDesafioPassCoopeuch,
      IsValidatePassCoopeuch,
      desafio,
      validaDesafio,
      baseRut,
    } = this.props;

    const errorOperacionRechazada =
      errorEnFirma || (validarSolicitudError && validarSolicitudErrorCode !== 'SCV-03');

    return (
      <>
        {showErrorNoPuedeContratar ||
        (validarSolicitudError && validarSolicitudErrorCode === 'SCV-03') ? (
          <MensajeNoHabilitado />
        ) : (
          <>
            {errorOperacionRechazada ? (
              <Error action={this.onErrorCallback} currentStep={currentStep} />
            ) : (
              <SolicitaCVFlowTemplate
                {...{
                  steps,
                  currentStep,
                  despachoDomicilio,
                  region,
                  regionOptions,
                  comuna,
                  comunaOptions,
                  isLoadingSucursalesComuna,
                  notSucursal,
                  sucursal,
                  sucursalOptions,
                  calle,
                  numeroCasa,
                  otrosCasa,
                  poseeFactibilidad,
                  checked,
                  checkdRequiredError,
                  password,
                  showPassword,
                  passwordError,
                  blockedPassword,
                  serverPasswordError,
                  wrongPassword,
                  serviceError,
                  email,
                  emailError,
                  globalError,
                  persona,
                  isLoadingPersona,
                  secondEmail,
                  secondEmailError,
                  respuestaDetalle,
                  loadingStep1,
                  loadingStep2,
                  getDetalleContratacion,
                  loadingSendComprobante,
                  showEnviarComprobanteModal,
                  showComprobanteEnviadoModal,
                  showEnviarComprobanteErrorModal,
                  availablePassCoopeuch,
                  solicitarDesafioPassCoopeuch,
                  IsValidatePassCoopeuch,
                  errorTimeOut,
                  counterActive,
                  tarjetaDeCoord,
                  desafio,
                  sendDesafio,
                  IsLoading,
                  coordenadas,
                  validaDesafio,
                  IsAvailableTarCoo,
                  IsAvailablePassCoopeuch,
                  tarjeta,
                  baseRut,
                }}
                fetchingData={fetchingData || isLoadingValidarSolicitudCV}
                onDownloadGenericContract={this.handleSendGTMDownloadContract}
                changeTab={this.changeTab}
                handleOnChangeInput={this.handleOnChangeInput}
                handleWrongPassword={this.handleWrongPassword}
                handlePasswordInput={this.handlePasswordInput}
                handleTogglePassword={this.handleTogglePassword}
                handleOnChangeRegion={this.handleOnChangeRegion}
                handleOnChangeComuna={this.handleOnChangeComuna}
                handleOnChangeSucursal={this.handleOnChangeSucursal}
                handleCheckBox={this.handleCheckBox}
                handleChangeEmail={this.handleChangeEmail}
                handleDownloadContrato={loadingFetchContrato ? null : this.handleDownloadContrato}
                handleDownloadComprobante={this.handleDownloadComprobante}
                handleStartSendComprobanteEmail={this.handleStartSendComprobanteEmail}
                handleSendComprobanteEmail={this.handleSendComprobanteEmail}
                closeModal={this.closeAllModals}
                backStep1={this.backStep1}
                continueStep1={this.continueStep1}
                backStep2={this.backStep2}
                continueStep2={this.continueStep2}
                goToHome={this.goToHome}
                onRedirectToSchedule={this.onRedirectToSchedule}
                solicitarPassCoopeuch={this.solicitarPassCoopeuch}
                validarPassCoopeuchCP={this.validarPassCoopeuchCP}
                errorTimeOutCP={this.errorTimeOutCP}
                handleSwitchMethods={this.handleSwitchMethods}
                onHandleObtenerDesafio={this.onHandleObtenerDesafio}
                onHandleSetDesafio={this.onHandleSetDesafio}
                retryTimeOutPass={this.retryTimeOutPass}
                validarPassCoopeuchCPReset={this.validarPassCoopeuchCPReset}
                solicitarPassCoopeuchReset={this.solicitarPassCoopeuchReset}
                onHandleObtenerDesafioReset={this.onHandleObtenerDesafioReset}
                validarDesafio={this.validarDesafio}
                validarDesafioReset={this.validarDesafioReset}
                continuaFirma={this.continuaFirma}
                continuaFlujo={this.continuaFlujo}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  listaNegra: store.entities.listaNegra,
  productosDisponibles: store.entities.solicitudCuentaVista.productosDisponibles,
  regiones: store.entities.tipo.regiones,
  comunas: store.entities.tipo.comunas,
  sucursales: store.entities.tipo.sucursales,
  cobertura: store.entities.tipo.coberturaComuna,
  persona: store.entities.persona,
  guardarDatosPersonales: store.entities.solicitudCuentaVista.guardarDatosPersonales,
  guardarSucursal: store.entities.solicitudCuentaVista.guardarSucursal,
  firmarSolicitud: store.entities.solicitudCuentaVista.firmarSolicitud,
  getDetalleContratacion: store.entities.solicitudCuentaVista.getDetalleContratacion,
  obtenerComprobante: store.entities.solicitudCuentaVista.obtenerComprobante,
  obtenerContrato: store.entities.solicitudCuentaVista.obtenerContrato,
  enviarComprobante: store.entities.solicitudCuentaVista.enviarComprobante,
  isLoadingValidarSolicitudCV: store.ui.loaders.isLoadingValidarSolicitudCV,
  validarSolicitudError: store.entities.solicitudCuentaVista.validarSolicitudError,
  trackValidarSolicitud: store.entities.solicitudCuentaVista.trackValidarSolicitud,
  validarSolicitudErrorCode: store.entities.solicitudCuentaVista.validarSolicitudErrorCode,
  isLoadingPersona: store.ui.loaders.isLoadingDatosPersonales,
  isLoadingSucursalesComuna: store.ui.loaders.isLoadingSucursalesComuna,
  sucursalesComuna: store.entities.tipo.sucursalesComuna,
  availablePassCoopeuch: store.entities.solicitudCuentaVista.availablePassCoopeuch,
  solicitarDesafioPassCoopeuch: store.entities.solicitudCuentaVista.solicitarDesafioPassCoopeuch,
  IsValidatePassCoopeuch: store.entities.solicitudCuentaVista.IsValidatePassCoopeuch,
  desafio: store.entities.solicitudCuentaVista.desafio,
  validaDesafio: store.entities.solicitudCuentaVista.validaDesafio,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
  fetchRegiones() {
    dispatch(tipoActions.fetchRegiones());
  },
  fetchComunas(props) {
    dispatch(tipoActions.fetchComunas(props));
  },
  fetchSucursales(props) {
    dispatch(tipoActions.fetchSucursales(props));
  },
  fetchCoberturas() {
    dispatch(tipoActions.fetchCoberturaComuna());
  },
  fetchGuardarDatosPersonales(props) {
    dispatch(solicitudCVActions.guardarDatosPersonales(props));
  },
  fetchGuardarSucursal(props) {
    dispatch(solicitudCVActions.guardarSucursal(props));
  },
  fetchFirmarSolicitud(props) {
    dispatch(solicitudCVActions.firmarSolicitud(props));
  },
  fetchGetDetalleContratacion() {
    dispatch(solicitudCVActions.getDetalleContratacion());
  },
  fetchObtenerComprobante() {
    dispatch(solicitudCVActions.obtenerComprobante());
  },
  fetchEnviarComprobante(props) {
    dispatch(solicitudCVActions.enviarComprobante(props));
  },
  fetchLayoutConfigs() {
    dispatch(layoutActions.fetchLayoutConfigs());
  },
  fetchObtenerContrato() {
    dispatch(solicitudCVActions.obtenerContrato());
  },
  validarSolicitud() {
    dispatch(solicitudCVActions.validarSolicitud());
  },
  fetchSucursalesComuna(props) {
    dispatch(tipoActions.fetchSucursalesComuna(props));
  },
  fetchObtenerDesafios(props) {
    dispatch(solicitudCVActions.fetchObtenerDesafios(props));
  },
  validarPassCoopeuchCP(props) {
    dispatch(solicitudCVActions.validarPassCoopeuchCP(props));
  },
  solicitarPassCoopeuch(props) {
    dispatch(solicitudCVActions.solicitarPassCoopeuch(props));
  },
  onHandleObtenerDesafio(props) {
    dispatch(solicitudCVActions.onHandleObtenerDesafio(props));
  },
  validarPassCoopeuchCPReset(props) {
    dispatch(solicitudCVActions.validarPassCoopeuchCPReset());
  },
  solicitarPassCoopeuchReset(props) {
    dispatch(solicitudCVActions.solicitarPassCoopeuchReset());
  },
  onHandleObtenerDesafioReset(props) {
    dispatch(solicitudCVActions.onHandleObtenerDesafioReset());
  },
  validarDesafio(props) {
    dispatch(solicitudCVActions.validarDesafio(props));
  },
  validarDesafioReset(props) {
    dispatch(solicitudCVActions.validarDesafioReset());
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Flow));
