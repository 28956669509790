import React from 'react';

const Spinner = ({ fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="100%"
    height="100%"
    x="0"
    y="0"
    preserveAspectRatio="xMidYMid meet"
  >
    <path
      d="M12,0.75 C18.21675,0.75 23.25,5.781 23.25,12 C23.25,18.21675 18.219,23.25 12,23.25 C5.793,23.25 0.75,18.219 0.75,12 C0.75,5.78325 5.781,0.75 12,0.75 Z M12,2.25 C6.61125,2.25 2.25,6.6105 2.25,12 C2.25,17.38275 6.60975,21.75 12,21.75 C17.38875,21.75 21.75,17.3895 21.75,12 C21.75,6.612 17.39025,2.25 12,2.25 Z M12,17.25 C12.41475,17.25 12.75,17.586 12.75,18 L12.75,19.5 C12.75,19.914 12.41475,20.25 12,20.25 C11.58525,20.25 11.25,19.914 11.25,19.5 L11.25,18 C11.25,17.586 11.58525,17.25 12,17.25 Z M16.28025,15.21975 L17.78025,16.71975 C18.0735,17.013 18.0735,17.487 17.78025,17.78025 C17.487,18.0735 17.013,18.0735 16.71975,17.78025 L15.21975,16.28025 C14.9265,15.987 14.9265,15.513 15.21975,15.21975 C15.513,14.9265 15.987,14.9265 16.28025,15.21975 Z M8.78025,15.21975 C9.0735,15.513 9.0735,15.987 8.78025,16.28025 L7.28025,17.78025 C6.987,18.0735 6.513,18.0735 6.21975,17.78025 C5.9265,17.487 5.9265,17.013 6.21975,16.71975 L7.71975,15.21975 C8.013,14.9265 8.487,14.9265 8.78025,15.21975 Z M19.5,11.25 C19.91475,11.25 20.25,11.586 20.25,12 C20.25,12.414 19.91475,12.75 19.5,12.75 L18,12.75 C17.58525,12.75 17.25,12.414 17.25,12 C17.25,11.586 17.58525,11.25 18,11.25 L19.5,11.25 Z M17.78025,6.21975 C18.0735,6.513 18.0735,6.987 17.78025,7.28025 L16.28025,8.78025 C15.987,9.0735 15.513,9.0735 15.21975,8.78025 C14.9265,8.487 14.9265,8.013 15.21975,7.71975 L16.71975,6.21975 C17.013,5.9265 17.487,5.9265 17.78025,6.21975 Z M12,3.75 C12.41475,3.75 12.75,4.086 12.75,4.5 L12.75,6 C12.75,6.414 12.41475,6.75 12,6.75 C11.58525,6.75 11.25,6.414 11.25,6 L11.25,4.5 C11.25,4.086 11.58525,3.75 12,3.75 Z"
      id="color-icono"
      fill={fill}
    />
  </svg>
);

export default Spinner;
