import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  .label-title {
    color: #333333;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
  }

  .container-margin {
    margin-left: 52px;
    margin-right: 52px;
  }

  .container-margin-banner {
    margin-left: 52px;
    margin-right: 52px;
  }

  .padding-card-left {
    padding-right: 12px !important;
  }

  .padding-card-right {
    padding-left: 12px !important;
  }

  .padding-title {
    padding-top: 84px;
  }

  .start-li {
    align-items: flex-start;
  }

  .container-end {
    margin-bottom: 200px;
  }

  .cuenta-ahorro-card-container {
    min-height: 128px;
    padding-left: 32px !important;
    padding-right: 32px !important;
  }

  .banner-desc-1 {
    padding-top: 32px;
  }

  .container-card-solicitud {
    margin-bottom: 24px !important;
  }

  .solicitud-cuenta-ahorra-desc {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .title-cuenta-ahorro {
    text-align: center;
    & svg {
      height: 27px;
      width: 27px;
    }
  }

  .cuenta-ahorro-desktop {
    display: inherit;
  }

  .cuenta-ahorro-mobile {
    display: none;
  }

  .btn-cuenta-ahorro-solicita {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .container-margin-banner {
      margin: 0px;
    }
    .padding-title {
      padding-top: 60px;
    }
    .banner-desc-1 {
      padding-top: 0px;
    }
    .container-margin {
      margin-left: 12px;
      margin-right: 12px;
    }
    .padding-card-left {
      padding-right: 0px !important;
      margin-bottom: 16px !important;
    }

    .padding-card-right {
      padding-left: 0px !important;
    }
    .container-card-solicitud {
      margin-bottom: 16px !important;
    }
    .container-end {
      margin-bottom: 16px;
    }
    .cuenta-ahorro-card-container {
      min-height: 84px;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
    .solicitud-cuenta-ahorra-desc {
      margin-top: 16px;
      margin-bottom: 16px;
    }
    .cuenta-ahorro-desktop {
      display: none;
    }

    .cuenta-ahorro-mobile {
      display: inherit;
    }

    .title-cuenta-ahorro {
      text-align: left;
      & svg {
        height: 24px;
        width: 24px;
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  border-radius: 8px;

  .banner-container {
    margin-left: 32px;
  }

  .banner-text-1 {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 34px;
    color: #ffffff;
  }
  .banner-text-2 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 34px;
    color: #ffffff;
  }

  .banner-button-margin-mobile {
    margin-bottom: 104px;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0px;

    .banner-container {
      margin-left: 12px;
    }
  }
`;

export default Wrapper;
