import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Banners from '../../../components/templates/Dashboard/Banners';
import { registerGA } from '../../../utils/metrics';
import { abonoAcreenciasRemanenteActions } from '../../../../application/actions/abonoAcreenciasRemanente';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';

function Index({pasoactual, showUmBanner, showNorma484Banner}) {

  const dispatch = useDispatch();
  const banner = useSelector((store) => store.entities.abonoAcreenciasRemanente.bannerPoderEspecial);
  const showBannerCDP = useSelector((store) => store.entities.cuotaParticipacion.banner_cuotas);

  useEffect(() => {
    dispatch(abonoAcreenciasRemanenteActions.fetchBannerPoderEspecial());
    dispatch(cuotaParticipacionActions.fetchBannerCuotasParticipacion());
  }, []);

  const GARedirectUM = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Banner Cambiar monedero a CV - Ingresa aquí',
      ambiente: 'sitio',
    });
  });

  useEffect(() => {
    if (showNorma484Banner) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/',
        'eve-cat': 'Impresion',
        'eve-lab': 'Banner - Cambiar monedero a Norma 484',
        ambiente: 'sitio',
      });
    } else if (showUmBanner) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/',
        'eve-cat': 'Impresion',
        'eve-lab': 'Banner - Cambiar monedero a CV',
        ambiente: 'sitio',
      });
    }
  }, []);

  return (
    <div>
      <Banners pasoactual={pasoactual} showUmBanner={showUmBanner} GARedirectUM={GARedirectUM} banner={banner} showBannerCDP={showBannerCDP} showNorma484Banner={showNorma484Banner} />
    </div>
  );
}

Index.propTypes = {
  pasoactual: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showUmBanner: PropTypes.bool.isRequired,
  showNorma484Banner: PropTypes.bool.isRequired,
};

export default Index;
