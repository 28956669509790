import { call, put, takeLatest, spawn, all } from 'redux-saga/effects';

// actions
import { productActions } from '../../../application/actions/products';
import { loadersActions } from '../../../application/actions/ui_loaders';
import { remanenteActions } from '../../../application/actions/remanente';
import { capitalizacionActions } from '../../../application/actions/capitalizacion';
import { cuotaParticipacionActions } from '../../../application/actions/cuotaParticipacion';
import { creditoActions } from '../../../application/actions/credito';
import { solicitudCVActions } from '../../../application/actions/solicitudCuentaVista';

// api
import { logReqError } from '../../../ui/utils/functions';
import api from '../../api';

const apiCuentaVista = api(process.env.REACT_APP_CURRENT_ACCOUNT);
const apiMovimientos = api(process.env.REACT_APP_MOVIMIENTO);
const apiDashboard = api(process.env.REACT_APP_DASHBOARD);
const apiTarjetaDebito = api(process.env.REACT_APP_TARJETA_DEBITO);

function* fetchingCuentas(action) {
  const { codigoProducto, estadoCuenta, tipoCliente } = action.payload;

  const { data, error } = yield call(apiCuentaVista.post, 'cuenta/v1/obtener', {
    codigoProducto,
    estadoCuenta,
    tipoCliente,
  });
  return { data, error };
}

function* fetchingTarjetas(action) {
  const { numeroCuenta, codigoEstado, codigoBloqueo } = action.payload;
  const { data, error } = yield call(apiTarjetaDebito.post, 'tarjeta/obtener', {
    numeroCuenta,
    codigoEstado,
    codigoBloqueo,
  });
  return { data, error };
}

export function* fetchCuentasSolicitaCV(action) {
  try {
    const { data, error } = yield fetchingCuentas(action);
    if (data) {
      yield put(solicitudCVActions.fetchCuentasSuccess(data));
    } else {
      yield put(solicitudCVActions.fetchCuentasError());
    }
    yield logReqError(error, 'POST');
  } catch (err) {
    console.log(err);
    yield put(solicitudCVActions.fetchCuentasError());
  }
}

export function* fetchTarjetasSolicitaCV(action) {
  try {
    const { data, error } = yield fetchingTarjetas(action);
    if (data) {
      yield put(solicitudCVActions.fetchTarjetasSuccess(data));
    } else {
      yield put(solicitudCVActions.fetchTarjetasError());
    }
    yield logReqError(error, 'POST');
  } catch (err) {
    console.log(err);
    yield put(solicitudCVActions.fetchTarjetasError());
  }
}

export function* fetchCuentasVista(action) {
  try {
    yield put(loadersActions.fetchLoadingCuentasVista({ isLoading: true }));
    const { data, error } = yield fetchingCuentas(action);

    if (data) {
      yield put(productActions.fetchCuentasVistaSuccess(data));
    } else {
      yield put(productActions.fetchCuentasVistaFailed({ error }));
    }

    yield put(loadersActions.fetchLoadingCuentasVista({ isLoading: false }));
  } catch (error) {
    yield put(productActions.fetchCuentasVistaFailed({ error }));
    yield put(loadersActions.fetchLoadingCuentasVista({ isLoading: false }));
  }
}

export function* fetchCuentasVistaPagoCDP(action) {
  try {
    const { data } = yield fetchingCuentas(action);
    if (data) {
      yield put(cuotaParticipacionActions.fetchCVCuotasParticipacionSuccess(data));
    } else {
      yield put(cuotaParticipacionActions.fetchCVCuotasParticipacionError());
    }
  } catch (err) {
    yield put(cuotaParticipacionActions.fetchCVCuotasParticipacionError());
  }
}

export function* fetchCuentasVistaCreditoDeConsumo(action) {
  try {
    const { data, error } = yield fetchingCuentas(action);
    if (data) {
      yield put(creditoActions.fetchCuentasVistaSuccess({ data }));
    } else {
      yield put(creditoActions.fetchCuentasVistaError());
      yield logReqError(error, 'POST');
    }
  } catch (err) {
    yield put(creditoActions.fetchCuentasVistaError());
  }
}

export function* fetchProductosDisponiblesRemanente(action) {
  try {
    yield put(loadersActions.fetchLoadingObtenerCuentasRemanente({ isLoading: true }));
    const { data, error } = yield fetchingCuentas(action);
    if (data) {
      yield put(remanenteActions.fetchProductosDisponiblesSuccess(data));
    }
    if (error) {
      yield put(remanenteActions.fetchProductosDisponiblesFailed({ errorLog: error }));
    }
    yield put(loadersActions.fetchLoadingObtenerCuentasRemanente({ isLoading: false }));
    yield logReqError(error, 'POST');
  } catch (err) {
    console.log(err.message, 'fetchProductosDisponiblesRemanente');
  }
}

export function* fetchProductosDisponiblesCapitalizacion(action) {
  try {
    yield put(loadersActions.fetchLoadingObtenerCuentasRemanente({ isLoading: true }));
    const { data, error } = yield fetchingCuentas(action);
    if (data) {
      yield put(capitalizacionActions.fetchProductosDisponiblesCapitalizacionSuccess(data));
    } else if (error) {
      yield put(capitalizacionActions.fetchProductosDisponiblesCapitalizacionError({ error }));
    }
    yield put(loadersActions.fetchLoadingObtenerCuentasRemanente({ isLoading: false }));
  } catch (error) {
    yield put(capitalizacionActions.fetchProductosDisponiblesCapitalizacionError({ error }));
    yield put(loadersActions.fetchLoadingObtenerCuentasRemanente({ isLoading: false }));
  }
}

export function* fetchTarjetasCuentaVista(action) {
  try {
    yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: true }));

    const { numeroCuenta } = action.payload;
    const { data, error } = yield fetchingTarjetas(action);

    if (data) {
      yield put(
        productActions.fetchTarjetasCuentaVistaSuccess({
          account: numeroCuenta,
          cards: data,
        })
      );
    }
    if (error) {
      yield put(productActions.fetchTarjetasCuentaVistaError({ error }));
    }

    yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: false }));
  } catch (error) {
    yield put(productActions.fetchTarjetasCuentaVistaError({ error }));
    yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: false }));

    console.log(error.message, 'fetchTarjetasCuentaVista');
  }
}

export function* fetchTarjetasCuentaVistaRemanente(action) {
  try {
    yield put(loadersActions.fetchLoadingObtenerTarjetasRemanente({ isLoading: true }));
    const { data, error } = yield fetchingTarjetas(action);
    yield logReqError(error, 'POST');
    if (data) {
      yield put(remanenteActions.fetchTarjetasProductosDisponiblesSuccess(data));
    }
    if (error) {
      yield put(remanenteActions.fetchTarjetasProductosDisponiblesFailed({ errorLog: error }));
    }
    yield put(loadersActions.fetchLoadingObtenerTarjetasRemanente({ isLoading: false }));
  } catch (err) {
    yield put(loadersActions.fetchLoadingObtenerTarjetasRemanente({ isLoading: false }));
    console.log(err.message, 'fetchTarjetasCuentaVistaRemanente');
  }
}

export function* fetchTarjetasCuentaVistaCapitalizacion(action) {
  try {
    yield put(loadersActions.fetchLoadingObtenerTarjetasRemanente({ isLoading: true }));
    const { data, error } = yield fetchingTarjetas(action);
    yield logReqError(error, 'POST');
    if (data) {
      yield put(capitalizacionActions.fetchTarjetasProductosDisponiblesCapitalizacionSuccess(data));
    } else if (error) {
      yield put(
        capitalizacionActions.fetchTarjetasProductosDisponiblesCapitalizacionError({ error })
      );
    }
    yield put(loadersActions.fetchLoadingObtenerTarjetasRemanente({ isLoading: false }));
  } catch (error) {
    yield put(
      capitalizacionActions.fetchTarjetasProductosDisponiblesCapitalizacionError({ error })
    );
    yield put(loadersActions.fetchLoadingObtenerTarjetasRemanente({ isLoading: false }));
  }
}

export function* fetchUltimosMovimientosCuentaVista(action) {
  try {
    const numeroCuenta = action.payload;

    yield put(loadersActions.fetchLoadingUltMovimientosCuentasVista({ isLoading: true }));

    const { data, error } = yield call(apiMovimientos.post, 'resumen/v1/obtener', {
      numeroCuenta,
    });

    if (data) {
      yield put(
        productActions.fetchUltimosMovimientsoCuentaVistaSuccess({
          account: numeroCuenta,
          lastMovements: data,
        })
      );
    } else {
      yield put(productActions.fetchUltimosMovimientsoCuentaVistaError({ error }));
    }

    yield put(loadersActions.fetchLoadingUltMovimientosCuentasVista({ isLoading: false }));
  } catch (error) {
    yield put(productActions.fetchUltimosMovimientsoCuentaVistaError({ error }));
    yield put(loadersActions.fetchLoadingUltMovimientosCuentasVista({ isLoading: false }));

    console.log(error.message, 'fetchUltimosMovimientosCuentaVista');
  }
}

export function* fetchMovimientosCuentaVista(action) {
  try {
    const { start, end, numeroCuenta } = action.payload;

    yield put(productActions.fetchLoadingMvtsCurrentAccountSuccess({ isMvts: true }));

    const { data, error } = yield call(apiMovimientos.post, 'v1/obtener', {
      numeroCuenta: numeroCuenta,
      fechaInicio: start,
      fechaTermino: end,
    });

    const movements = error ? [] : data.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));

    yield logReqError(error, 'POST');

    if (data) {
      yield put(
        productActions.fetchMovimientosCuentaVistaSuccess({
          account: numeroCuenta,
          movements,
          isMvts: false,
        })
      );
    }
  } catch (err) {
    console.log(err.message, 'fetchMovimientosCuentaVista');
  }
}

export function* fetchPDFMovimientosCuentaVista(action) {
  try {
    const { start, end, numeroCuenta } = action.payload;

    yield put(productActions.resetDataPDFMovimientos());

    const { data, error } = yield call(apiMovimientos.post, 'pdf/v1/obtener', {
      numeroCuenta: numeroCuenta,
      fechaInicio: start,
      fechaTermino: end,
    });

    if (data) {
      yield put(
        productActions.fetchPDFMovimientosCuentaVistaSuccess({
          data,
        })
      );
    } else if (error) {
      yield put(productActions.fetchPDFMovimientosCuentaVistaError());
    }
  } catch (err) {
    console.log(err.message, 'fetchMovimientosCuentaVista');
    yield put(productActions.fetchPDFMovimientosCuentaVistaError());
  }
}

export function* fetchBloqueoTemporalTarjeta(action) {
  console.log(action);
  const { isDefinitivo, tarjetaId } = action.payload;

  try {
    const { data, error } = yield call(apiTarjetaDebito.post, 'tarjeta/estado/cambiar', {
      isDefinitivo,
      tarjetaId,
    });

    if (data) {
      yield put(productActions.fetchBloqueoTemporalTarjetaSuccess({ data }));
    } else if (error) {
      yield put(productActions.fetchBloqueoTemporalTarjetaError({ error }));
    }
  } catch (error) {
    
    yield put(productActions.fetchBloqueoTemporalTarjetaError({ error }));
  }
}

export function* fetchDashboardProductos() {
  try {
    yield put(loadersActions.fetchLoadingDashboardProductos({ isLoading: true }));
    const { data } = yield call(apiDashboard.get, 'web/card/obtener');
    if (data) {
      yield put(productActions.fetchDashboardProductosSuccess({ dashboardProductos: data }));
      yield put(loadersActions.fetchLoadingDashboardProductos({ isLoading: false }));
    } else {
      yield put(productActions.fetchDashboardProductosFailed());
      yield put(loadersActions.fetchLoadingDashboardProductos({ isLoading: false }));
    }
  } catch (error) {
    yield put(productActions.fetchDashboardProductosFailed());
    yield put(loadersActions.fetchLoadingDashboardProductos({ isLoading: false }));
  }
}
/**
 * Tarjetas de Débito y Tarjetas de Crédito
 *
 * Cualquier acción relacionadas con esta entidad
 */
function getAllCards(numerosCuentas) {
  return numerosCuentas.reduce(
    (prev, current) => ({
      ...prev,
      [current]: call(apiTarjetaDebito.post, 'tarjeta/obtener', {
        numeroCuenta: current,
        codigoBloqueo: ['', 'G'],
        codigoEstado: ['01'],
      }),
    }),
    {}
  );
}

export function* fetchAllTarjetasCV(action) {
  try {
    yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: true }));
    const { numerosCuentas } = action.payload;

    const tarjetasDebito = yield all(getAllCards(numerosCuentas));
    // console.log({ tarjetasDebito });

    const hasNotErrors = Object.values(tarjetasDebito).every((data) => !data.error);
    if (hasNotErrors) {
      yield put(productActions.fetchAllTarjetasCVSuccess({ tarjetasDebito }));
      yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: false }));
      // console.log(Object.keys(tarjetasDebito).length);
      // if (Object.keys(tarjetasDebito).length > 0) {
      //   yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: true }));
      // }
    } else {
      yield put(productActions.fetchAllTarjetasCVError());
      yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: false }));
    }
  } catch (err) {
    console.log(err, 'fetchAllTarjetasCV');
    yield put(productActions.fetchAllTarjetasCVError());
    yield put(loadersActions.fetchLoadingTarjetasCuentasVista({ isLoading: false }));
  }
}

export function* fetchCambioEstadoTarjetaDebito(action) {
  try {
    const { params } = action.payload;
    yield put(loadersActions.fetchLoadingCambioEstadoTjDebito({ isLoading: true }));
    const { data, error } = yield call(apiTarjetaDebito.post, 'tarjeta/estado/cambiar', params);
    if (data) {
      yield put(
        productActions.fetchCambioEstadoTarjetaDebitoSuccess({ cambioEstadoTjDebito: data })
      );
      yield put(loadersActions.fetchLoadingCambioEstadoTjDebito({ isLoading: false }));
    } else {
      yield put(
        productActions.fetchCambioEstadoTarjetaDebitoFailed({
          mensajeErrorCambioEstadoTjDebito: error.code ? error.message : 'Servicio no disponible',
        })
      );
      yield put(loadersActions.fetchLoadingCambioEstadoTjDebito({ isLoading: false }));
    }
  } catch (err) {
    yield put(
      productActions.fetchCambioEstadoTarjetaDebitoFailed({
        mensajeErrorCambioEstadoTjDebito: 'Servicio no disponible',
      })
    );
    yield put(loadersActions.fetchLoadingCambioEstadoTjDebito({ isLoading: false }));
  }
}

export function* watchfetchCuentasSolicitaCV() {
  yield takeLatest(solicitudCVActions.fetchCuentas.type, fetchCuentasSolicitaCV);
}

export function* watchFetchCVCuotaDeParticipacion() {
  yield takeLatest(
    cuotaParticipacionActions.fetchCVCuotasParticipacion.type,
    fetchCuentasVistaPagoCDP
  );
}

export function* watchFetchCuentasVistaCreditoDeConsumo() {
  yield takeLatest(
    creditoActions.fetchCuentasVistaCreditoDeConsumo.type,
    fetchCuentasVistaCreditoDeConsumo
  );
}

export function* watchfetchTarjetasSolicitaCV() {
  yield takeLatest(solicitudCVActions.fetchTarjetas.type, fetchTarjetasSolicitaCV);
}

export function* watchfetchCuentasVista() {
  yield takeLatest(productActions.fetchCuentasVista.type, fetchCuentasVista);
}

export function* watchFetchProductsRemanente() {
  yield takeLatest(
    remanenteActions.fetchProductosDisponibles.type,
    fetchProductosDisponiblesRemanente
  );
}

export function* watchFetchTarjetasRemanente() {
  yield takeLatest(
    remanenteActions.fetchTarjetasProductosDisponibles.type,
    fetchTarjetasCuentaVistaRemanente
  );
}

export function* watchFetchProductsCapitalizacion() {
  yield takeLatest(
    capitalizacionActions.fetchProductosDisponiblesCapitalizacion.type,
    fetchProductosDisponiblesCapitalizacion
  );
}

export function* watchFetchTarjetasCapitalizacion() {
  yield takeLatest(
    capitalizacionActions.fetchTarjetasProductosDisponiblesCapitalizacion.type,
    fetchTarjetasCuentaVistaCapitalizacion
  );
}

export function* watchfetchTarjetasCuentaVista() {
  yield takeLatest(productActions.fetchTarjetasCuentaVista.type, fetchTarjetasCuentaVista);
}

export function* watchfetchUltimosMovimientosCuentaVista() {
  yield takeLatest(
    productActions.fetchUltimosMovimientosCuentaVista.type,
    fetchUltimosMovimientosCuentaVista
  );
}

export function* watchfetchMovimientosCuentaVista() {
  yield takeLatest(productActions.fetchMovimientosCuentaVista.type, fetchMovimientosCuentaVista);
}

export function* watchFetchPDFMovimientosCuentaVista() {
  yield takeLatest(
    productActions.fetchPDFMovimientosCuentaVista.type,
    fetchPDFMovimientosCuentaVista
  );
}

export function* watchFetchBloqueoTemporalTarjeta() {
  yield takeLatest(productActions.fetchBloqueoTemporalTarjeta.type, fetchBloqueoTemporalTarjeta);
}

export function* watchFetchDashboardProductos() {
  yield takeLatest(productActions.fetchDashboardProductos.type, fetchDashboardProductos);
}

export function* watchFetchAllTarjetasCV() {
  yield takeLatest(productActions.fetchAllTarjetasCV.type, fetchAllTarjetasCV);
}

export function* watchFetchCambioEstadoTarjetaDebito() {
  yield takeLatest(
    productActions.fetchCambioEstadoTarjetaDebito.type,
    fetchCambioEstadoTarjetaDebito
  );
}
/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */

export default function* rootSaga() {
  yield spawn(watchfetchCuentasSolicitaCV);
  yield spawn(watchfetchTarjetasSolicitaCV);
  yield spawn(watchfetchCuentasVista);
  yield spawn(watchFetchProductsCapitalizacion);
  yield spawn(watchFetchTarjetasCapitalizacion);
  yield spawn(watchFetchProductsRemanente);
  yield spawn(watchFetchTarjetasRemanente);
  yield spawn(watchfetchTarjetasCuentaVista);
  yield spawn(watchfetchUltimosMovimientosCuentaVista);
  yield spawn(watchfetchMovimientosCuentaVista);
  yield spawn(watchFetchPDFMovimientosCuentaVista);
  yield spawn(watchFetchBloqueoTemporalTarjeta);
  yield spawn(watchFetchCVCuotaDeParticipacion);
  yield spawn(watchFetchDashboardProductos);
  yield spawn(watchFetchCuentasVistaCreditoDeConsumo);
  yield spawn(watchFetchAllTarjetasCV);
  yield spawn(watchFetchCambioEstadoTarjetaDebito);
}
