import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

// Actions
import { solicitudCVActions } from '../../../application/actions/solicitudCuentaVista';
import { BannerActFinancieraContainer } from '@coopeuch-project/react-micro-actfinanciera';
import ModalCampana from '@coopeuch-project/react-micro-campanias_front';
import { productActions } from '../../../application/actions/products';
import { layoutActions } from '../../../application/actions/ui_layout';
import { puntosActions } from '../../../application/actions/puntos';
import { cuentaAhorroActions } from '../../../application/actions/cuentaAhorro';
import { configuracionActions } from '../../../application/actions/configuracion';
import { activacionTarjetasActions } from '../../../application/actions/activacionTarjetas';
import { perfilComercialActions } from '../../../application/actions/perfilComercial';
import { fetchCampanaCrmActions } from '../../../application/actions/campanaCrm';

import { ftuActions } from '../../../application/actions/ftu';
import { fetchMonederoValidarActions } from '../../../application/actions/upgradeMonedero';

import { BarraEcommerceContainer as BarraEcommerce } from '@coopeuch-project/barra-ecommerce';
import ModalAteDesembolso from '@coopeuch-project/modal_ate_desembolso';
import Banner from './Banner';
import BannerEstacional from './BannerEstacional';
import CuentasVista from './Cuentas';
import CuentasAhorro from './CuentasAhorro';
import CuotasParticipacion from './CuotasParticipacion';
import TarjetaCredito from './TarjetaCredito';
import CoopeuchPass from './PassCoopeuch';
import Socio from './Socio';
import AccesosRapidos from './AccesosRapidos';
import { registerGA } from '../../utils/metrics';
import { loguearRutUsuario } from '../../utils/formateoParametriaLog';
import { encrypt } from '../../utils/functions';

import PuntosCoopeuch from './PuntosCoopeuch';

import Skeleton from '../../components/templates/Dashboard/Skeleton';
import ModalFtu from '../../components/organism/Dashboard/ModalFtu';
import { dashboardTexts } from './texts';
import { Wrapper } from './styles';

import './styles.css';
import { norma484Actions } from '../../../application/actions/norma484';

const { width } = window.screen;
const MENU_ICON_SELECTOR = '#mobile-menu > div';
const MENU_INV_SELECTOR = '#menu-inversiones';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const { ftuObtener } = this.props;
    this.state = {
      pasoactual: 1,
      data: [],
      run: ftuObtener.data,
      stepIndex: 0,
      steps: [
        {
          target: width < 1025 ? MENU_ICON_SELECTOR : MENU_INV_SELECTOR,
          content: (
            <div>
              <ModalFtu
                textTitle="Nueva Sección de Inversiones"
                textContent=" Recibe recomendaciones según tu perfil de inversionista, realiza operaciones y sigue tus inversiones en línea."
                width="240"
                height="73"
                widthmovil="160"
                heightmovil="20"
                top="118"
                left="-52"
                backgroundMenu="red"
                topMovil="-60"
                leftMovil="-15"
                borderinversiones="2px solid #007DB7"
                paddingRight="10"
                borderBottom="15px solid transparent"
                borderTop="15px solid transparent"
                borderRight="15px solid white"
                borderLeft="15px solid transparent"
                borderBottomMovil="15px solid white"
                borderTopMovil="15px solid transparent"
                borderRightMovil="15px solid transparent"
                borderLeftMovil="15px solid transparent"
                onclose={this.onClose}
                pasoactual="1"
                totalPasos={5}
              />
            </div>
          ),
          disableBeacon: true,
          locale: { next: 'Continuar', skip: '' },
          offset: 8,
          placement: width < 600 ? 'bottom-end' : 'right-end',
          type: 'tour:start',
          stepIndex: 1,
        },
        {
          target: '#banner',
          content: (
            <div>
              <ModalFtu
                textTitle="Entérate de noticias y ofertas"
                textContent="Te mantendremos al día, para que no te pierdas ninguna de nuestras ofertas."
                width="200"
                height="73"
                widthmovil="160"
                heightmovil=""
                top="-45"
                left="5"
                topMovil="-45"
                leftMovil="-15"
                heightPasos="95"
                borderBottom="15px solid white"
                borderTop="15px solid trasnparent"
                borderRight="15px solid transparent"
                borderLeft="15px solid transparent"
                onclose={this.onClose}
                pasoactual="2"
                totalPasos={5}
              />
            </div>
          ),
          locale: { next: 'Continuar', skip: '' },
          offset: 8,
          placement: 'bottom-start',
          stepIndex: 2,
          placementBeacon: 'left',
        },
        {
          target: '.dashboard-margin-target',
          content: (
            <div>
              <ModalFtu
                textTitle="Productos"
                textContent="Ten siempre a mano el saldo de tus productos y detalles de todos tus movimientos."
                top="160"
                left="5"
                topMovil="175"
                leftMovil="-7"
                heightPasos="95"
                width="230"
                height="73"
                widthmovil="140"
                heightmovil=""
                borderBottom="15px solid transparent"
                borderTop="15px solid white"
                borderRight="15px solid transparent"
                borderLeft="15px solid transparent"
                onclose={this.onClose}
                pasoactual="3"
                totalPasos={5}
              />
            </div>
          ),
          locale: { next: `Continuar`, skip: '' },
          offset: 8,
          placement: 'top-start',
          placementBeacon: 'left',
          stepIndex: 3,
        },

        {
          target: '#accesos',
          content: (
            <div>
              <ModalFtu
                textTitle="Nueva Barra de Accesos directos"
                textContent="Para que operes facil y cómodamente, usa la nueva barra de accesos directos."
                top="160"
                left="5"
                topMovil="175"
                leftMovil="-7"
                width="230"
                height="73"
                widthmovil="140"
                heightmovil=""
                heightPasos="95"
                borderBottom="15px solid transparent"
                borderTop="15px solid white"
                borderRight="15px solid transparent"
                borderLeft="15px solid transparent"
                onclose={this.onClose}
                pasoactual="4"
                totalPasos={5}
              />
            </div>
          ),
          locale: { next: 'Continuar', skip: '' },
          offset: 8,
          placement: 'top-start',
          stepIndex: 4,
        },
        {
          target: '#simulacion',
          content: (
            <div>
              <ModalFtu
                textTitle="Simulador de Crédito mejorado"
                textContent="Simula cómodamente la cuota y detalles de tu crédito, cuando lo necesites."
                top="160"
                left="5"
                topMovil="175"
                leftMovil="-7"
                width="230"
                height="73"
                widthmovil="140"
                heightmovil=""
                heightPasos="95"
                borderBottom="15px solid transparent"
                borderTop="15px solid white"
                borderRight="15px solid transparent"
                borderLeft="15px solid transparent"
                onclose={this.onClose}
                pasoactual="5"
                totalPasos={5}
              />
            </div>
          ),
          locale: { last: 'Finalizar', skip: '' },
          offset: 8,
          placement: 'top-start',
          stepIndex: 5,
        },
      ],
    };
    this.props.fetchGetPuntosCoopeuch();
    this.props.fetchMonederoValidar();
  }

  componentDidMount() {
    const {
      dashboardProductos,
      fetchFtuObtener,
      fetchBannerContratacionCV,
      fetchGetKitBanner,
      fetchPerfilCliente,
      persona,
      fetchCampanaCrm,
      fetchValidarNorma484,
    } = this.props;

    if (!(Array.isArray(dashboardProductos) && dashboardProductos.length)) {
      this.props.fetchDashboardProductos();
    }

    const consultarContexto = localStorage.getItem('consultarContexto');

    // Gatillar FTU sólo si no se está usando login con contexto, para evitar conflictos
    if (consultarContexto === 'null') {
      fetchFtuObtener();
    }
    fetchGetKitBanner();
    fetchBannerContratacionCV();

    fetchCampanaCrm();
    fetchValidarNorma484();

    window.scrollTo(0, 0);
    registerGA({
      event: 'vpv-privado',
      page: '/tef/resumen',
    });

    this.handleFullScreen(false);

    // perfilar obteniendo userid
    const rut = persona?.rut || localStorage.getItem('user-rut');
    encrypt(rut)
      .then((result) => {
        this.setState({ userId: result });
        fetchPerfilCliente(this.state.userId);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidUpdate(prevProps) {
    const {
      campanaCrm,
      isLoadingCampanaCrm,
      persona,
      ftuObtener,
      dashboardProductos,
      barraEcommerce,
      isLoadingMotorCampana,
      isLoadingFormalizacion,
      isLoadingReliquidacion,
      isLoadingAte,
    } = this.props;
    const { run, pasoactual } = this.state;
    const { banner_ate, banner_formalizacion, banner_reliquidacion, motor_campana } =
      barraEcommerce || {};
      if (dashboardProductos && persona) {
        const productoDapValido = dashboardProductos.some(
          (item) => item.producto === 'depositoplazo' && item.mostrar === true
        );
        if (productoDapValido) {
         loguearRutUsuario('HOME_PRODUCTOS_DAP');
        }
      }
    if (prevProps.ftuObtener !== ftuObtener) {
      this.setState({ run: ftuObtener.data }, () => {
        const { run: marcar } = this.state;

        if (marcar) {
          registerGA({
            event: 'eve',
            'eve-acc': '/tef/home',
            'eve-cat': 'Mensaje',
            'eve-lab': 'Aparicion tooltip',
          });
          this.marcarPasoFTUDataLayer();
        }
      });
    }

    const consultarContexto = localStorage.getItem('consultarContexto');
    if (consultarContexto !== 'null') {
      this.props.fetchConsultarContexto(consultarContexto);
      localStorage.setItem('consultarContexto', 'null');
    }

    if (prevProps.contexto !== '' && this.props.contexto !== prevProps.contexto) {
      this.redirectFunction(this.props.contexto);
      this.props.cleanContexto();
    }

    const menuInv = document.querySelector(MENU_INV_SELECTOR);
    const menuIcon = document.querySelector(MENU_ICON_SELECTOR);

    if (run) {
      menuInv.style.border = '3px solid #007DB7';
      menuInv.style.borderRadius = '4px';

      menuIcon.style.border = '3px solid #007DB7';
      menuIcon.style.borderRadius = '4px';
      menuIcon.style.width = '40px';
      menuIcon.style.height = '40px';
      menuIcon.style.padding = '4px';

      if (pasoactual !== 1) {
        menuIcon.style.border = '';
        menuInv.style.border = '';
      }
    }

    if (
      prevProps.dashboardProductos !== dashboardProductos ||
      prevProps.isLoadingCampanaCrm !== isLoadingCampanaCrm ||
      prevProps.isLoadingMotorCampana !== isLoadingMotorCampana ||
      prevProps.isLoadingFormalizacion !== isLoadingFormalizacion ||
      prevProps.isLoadingReliquidacion !== isLoadingReliquidacion ||
      prevProps.isLoadingAte !== isLoadingAte
    ) {
      if (
        !isLoadingCampanaCrm &&
        !isLoadingMotorCampana &&
        !isLoadingFormalizacion &&
        !isLoadingReliquidacion &&
        !isLoadingAte
      ) {
        const atributoConsumo = this.campanaCreditoConsumo(motor_campana);
        const atributoCampana = this.atributoCampana(
          banner_ate,
          banner_formalizacion,
          banner_reliquidacion,
          motor_campana
        );
        const campanasCRM = this.idsCampanasCrm(campanaCrm);
        this.crearDLdataCliente(
          dashboardProductos,
          atributoCampana.campana,
          atributoCampana.montoCampana,
          campanasCRM,
          atributoConsumo
        );
      }
    }
  }

  atributoCampana = (banner_ate, banner_formalizacion, banner_reliquidacion, motorCampana) => {
    let campana = '';
    let montoCampana = '';
    if (banner_ate?.data) {
      const { vigencia, monto } = banner_ate.data;
      if (vigencia && vigencia === 'S' && monto) {
        campana = 'ATE';
        montoCampana = `ATE$${monto}`;
      }
    }
    if (banner_formalizacion?.data) {
      const { mostrarBanner: mostrarBannerFormalizacion, resumenSolicitud } =
        banner_formalizacion.data;
      if (mostrarBannerFormalizacion && resumenSolicitud) {
        campana += campana !== '' ? '-formalizacion' : 'formalizacion';
        montoCampana +=
          montoCampana !== ''
            ? `-formalizacion$${resumenSolicitud.montoLiquido}`
            : `formalizacion$${resumenSolicitud.montoLiquido}`;
      }
    }
    if (banner_reliquidacion?.data) {
      const { campana: campanaReliquidacion } = banner_reliquidacion.data;
      if (campanaReliquidacion.codigoCampana === 'CAM9') {
        campana += campana !== '' ? '-reliquidacion' : 'reliquidacion';
        montoCampana +=
          montoCampana !== ''
            ? `-reliquidacion$${campanaReliquidacion.montoPromocion}`
            : `reliquidacion$${campanaReliquidacion.montoPromocion}`;
      }
    }
    if (motorCampana) {
      const { campana: detalleCampana, campanaError } = motorCampana;
      if (!campanaError && detalleCampana?.tieneCampanyas) {
        if (detalleCampana.ofertaCampanya.campanya.codigo === 'CAM5') {
          campana += campana !== '' ? '-ATEPD' : 'ATEPD';
          montoCampana +=
            montoCampana !== ''
              ? `-ATEPD$${detalleCampana.ofertaCampanya.montoPromocion}`
              : `ATEPD$${detalleCampana.ofertaCampanya.montoPromocion}`;
        }
        if (detalleCampana.ofertaCampanya.campanya.codigo === 'CAM6') {
          campana += campana !== '' ? '-CAM6' : 'CAM6';
          montoCampana +=
            montoCampana !== ''
              ? `-CAM6$${detalleCampana.ofertaCampanya.montoPromocion}`
              : `CAM6$${detalleCampana.ofertaCampanya.montoPromocion}`;
        }
        if (detalleCampana.ofertaCampanya.campanya.codigo === 'CAM8') {
          campana += campana !== '' ? '-ATEPDWEB' : 'ATEPDWEB';
          montoCampana +=
            montoCampana !== ''
              ? `-ATEPDWEB$${detalleCampana.ofertaCampanya.montoPromocion}`
              : `ATEPDWEB$${detalleCampana.ofertaCampanya.montoPromocion}`;
        }
      }
    }
    campana = campana === '' ? 'no-tiene' : campana;
    return { campana, montoCampana };
  };

  campanaCreditoConsumo = (motorCampana) => {
    const { campana, campanaError } = motorCampana;
    return !campanaError && campana?.tieneCampanyas ? 'tiene' : 'no-tiene';
  };

  idsCampanasCrm = (campanaCrm) => {
    let campaniaCRM = 'no-tiene';
    const { campanaCrmData } = campanaCrm;
    if (campanaCrmData.data && campanaCrmData.data.campanas) {
      const campanas = campanaCrmData.data.campanas;
      campaniaCRM = '';
      campanas.forEach((c) => {
        campaniaCRM += c.id + '-';
      });
      campaniaCRM = campaniaCRM != '' ? campaniaCRM.slice(0, -1) : 'no-tiene';
      this.crearDLCampanaCRM(campanas);
    }
    return campaniaCRM;
  };

  crearDLdataCliente = (productos, campana, montoCampana, campanasCRM, atributoConsumo) => {
    const dataLayer = window.dataLayer || [];
    const findIndex = dataLayer.findIndex((item) => item.event === 'dataCliente');
    if (findIndex >= 0) {
      dataLayer.splice(findIndex, 1);
    }
    const rut = localStorage.getItem('user-rut');
    if (rut && rut.length > 0) {
      encrypt(rut)
        .then((result) => {
          registerGA({
            event: 'dataCliente',
            dataCliente: 'dataLayerON',
            userid: result,
            consumo: atributoConsumo,
            cuentavista: this.findProductoMostrar(productos, 'cuentavista') ? 'tiene' : 'no-tiene',
            campana: campana,
            'monto-campana': montoCampana,
            aprobacion: 'no-tiene',
            campanaCRM: campanasCRM,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  crearDLCampanaCRM = (campanas) => {
    let id = '',
      descripcion = '',
      pagina = '',
      monto = '',
      vigencia = '',
      bajada = '',
      campos = '';

    campanas.forEach((campana) => {
      id = campana.id ? 'CRM ' + campana.id : '';
      descripcion = campana.descripcion || '';
      campos = campana.campos || [];
      pagina = campos.find((item) => item.nombre === 'pagina') || { valor: '' };
      monto = campos.find((item) => item.nombre === 'monto') || { valor: '' };
      vigencia = campos.find((item) => item.nombre === 'vigencia') || { valor: '' };
      bajada = campos.find((item) => item.nombre === 'bajada') || { valor: '' };
      registerGA({
        event: id,
        CRMdescripcion: descripcion,
        CRMpagina: pagina.valor,
        CRMmonto: monto.valor,
        CRMvigencia: vigencia.valor,
        CRMbajada: bajada.valor,
      });
    });
  };

  marcarPasoFTUDataLayer = (pasoActual = 1) => {
    let content;

    if (pasoActual === 1) {
      content = 'Inversiones';
    }
    if (pasoActual === 2) {
      content = 'Banner';
    }
    if (pasoActual === 3) {
      content = 'Productos';
    }
    if (pasoActual === 4) {
      content = 'Acceso directo';
    }
    if (pasoActual === 5) {
      content = 'Simulador';
    }

    if (content) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/home',
        'eve-cat': 'Click tooltip',
        'eve-lab': `Continuar - ${content}`,
      });
    }
  };

  findProductoMostrar = (array, producto) => {
    if (Array.isArray(array)) {
      const productoMostrar = array?.find((index) => index.producto === producto && index.mostrar);
      return productoMostrar?.mostrar;
    }
    return false;
  };

  redirectFunction = (e) => {
    const link = window.location.href;
    const fin = link.indexOf('/#/');
    const path = link.substring(0, fin);

    if (e?.url && e?.url !== '') {
      const url = e.url;
      window.location.href = path + url;
    }
  };

  handleFullScreen = (status) => {
    if (status != undefined) {
      this.props.fetchfullScreen({ fullScreen: status });
    } else {
      const { isFullScreen } = this.props;
      this.props.fetchfullScreen({ fullScreen: !isFullScreen });
    }
  };

  onClose = () => {
    const { fetchFtuEnviar } = this.props;

    this.setState({ run: false, pasoactual: null });
    fetchFtuEnviar(false);

    const menuIcon = document.querySelector(MENU_ICON_SELECTOR);
    const menuInv = document.querySelector(MENU_INV_SELECTOR);

    menuIcon.style.border = '';
    menuInv.style.border = '';

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/home',
      'eve-cat': 'Click tooltip',
      'eve-lab': 'Cerrar',
    });
  };

  handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    const { pasoactual } = this.state;
    const { fetchFtuEnviar } = this.props;
    if (document.querySelector('.react-joyride__tooltip')) {
      document.querySelector('.react-joyride__tooltip').style.borderRadius = '3px';
    }

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      this.setState({ pasoactual: pasoactual + 1 }, () => {
        // eslint-disable-next-line react/destructuring-assignment
        this.marcarPasoFTUDataLayer(this.state.pasoactual);
      });
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      fetchFtuEnviar(false);
      this.setState({ run: false });
    }

    this.setState({ data: data });
  };

  render() {
    const { persona, dashboardProductos, isLoadingDashboardProductos, history } = this.props;
    const { run, steps, pasoactual } = this.state;
    const { showUmCard, showNorma484Banner } = this.props;

    return (
      <Wrapper className="w-100 bpx-3 bpx-md-5 bmt-md-4 bmt-3 text-left">
        {isLoadingDashboardProductos ? (
          <Skeleton />
        ) : (
          <>
            <ModalCampana />
            <BarraEcommerce handleFullScreen={this.handleFullScreen} history={history} />
            {persona?.rut !== undefined && <ModalAteDesembolso {...{ persona }} />}
            <BannerActFinancieraContainer path={'banner'} />
            <ReactJoyride
              steps={steps}
              run={run}
              continuous
              showProgress={false}
              showSkipButton
              callback={this.handleJoyrideCallback}
              hideBackButton={true}
              scrollDuration={1000}
              spotlightPadding={-1}
              scrollOffset={4}
              disableOverlayClose={true}
              hideCloseButton={true}
              disableOverlay={false}
              disabledFlip={true}
              hideNextButton={true}
              styles={{
                width: 378,
                height: 157.78,
                options: {
                  zIndex: 20,
                  borderRadius: 8,
                },
                buttonNext: {
                  backgroundColor: 'white',
                  textDecoration: 'underline',
                  borderRadius: 4,
                  marginRight: '20px',
                  marginTop: '-12px',
                  marginBottom: '15px',
                  color: 'red',
                  fontFamily: 'Ubuntu-medium',
                  fontSize: '14px',
                },
              }}
            />
            <Banner pasoactual={pasoactual} showUmBanner={showUmCard} showNorma484Banner={showNorma484Banner} />
            <BannerEstacional />
            <div className="row">
              <div className="col-md-12 col-xs-12 bmb-3">
                <label className="title-dashboard">{dashboardTexts.TITLE_PRODUCTOS}</label>
              </div>
            </div>
            <div
              className={
                pasoactual === 3
                  ? 'row dashboard-margin-target products'
                  : 'row dashboard-margin-target'
              }
            >
              {this.findProductoMostrar(dashboardProductos, 'cuotaparticipacion') && (
                <CuotasParticipacion {...{ persona }} />
              )}

              {this.findProductoMostrar(dashboardProductos, 'cuentavista') && <CuentasVista />}
              {this.findProductoMostrar(dashboardProductos, 'tarjetacredito') && <TarjetaCredito />}
              {this.findProductoMostrar(dashboardProductos, 'cuentaahorro') && <CuentasAhorro />}
              {this.findProductoMostrar(dashboardProductos, 'bannersocio') && <Socio />}
              {this.findProductoMostrar(dashboardProductos, 'passcoopeuch') && <CoopeuchPass />}
            </div>
            <PuntosCoopeuch />
            <AccesosRapidos pasoactual={pasoactual} />
          </>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (store) => ({
  persona: store.entities.persona,
  dashboardProductos: store.entities.products.dashboardProductos,
  ftuObtener: store.entities.ftu.ftuObtener,
  isLoadingDashboardProductos: store.ui.loaders.isLoadingDashboardProductos,
  contexto: store.entities.configuracion.contexto.data,
  isFullScreen: store.ui.layout.FullScreen,
  flagReliquidacion: store.reliquidacion.dataCampana.tieneCampana,
  showUmCard: store.entities.monederoValidar.showBanner,
  campanaCrm: store.entities.campanaCrm,
  isLoadingCampanaCrm: store.ui.loaders.isLoadingCampanaCrm,
  barraEcommerce: store.barraEcommerce,
  showNorma484Banner: store.entities.norma484.validar.valido,
  isLoadingMotorCampana: store.barraEcommerce.ui.isLoadingCampana,
  isLoadingFormalizacion: store.barraEcommerce.ui.isLoadingFormalizacion,
  isLoadingReliquidacion: store.barraEcommerce.ui.isLoadingReliquidacion,
  isLoadingAte: store.barraEcommerce.ui.isLoadingAte,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasAhorro(params) {
    dispatch(cuentaAhorroActions.fetchCuentasAhorro({ params }));
  },
  fetchDashboardProductos() {
    dispatch(productActions.fetchDashboardProductos());
  },

  fetchBannerContratacionCV() {
    dispatch(solicitudCVActions.fetchBannerContratacionCV());
  },
  fetchConsultarContexto(id) {
    dispatch(configuracionActions.fetchConsultarContexto({ id }));
  },
  fetchfullScreen(params) {
    dispatch(layoutActions.LayoutSuccessFullScreen(params));
  },
  fetchFtuEnviar(params) {
    dispatch(ftuActions.fetchFtuEnviar(params));
  },
  fetchFtuObtener: () => {
    dispatch(ftuActions.fetchFtuObtener());
  },
  fetchFtuObtenerNoMostrar: () => {
    dispatch(ftuActions.fetchFtuObtenerNoMostrar());
  },
  cleanContexto() {
    dispatch(configuracionActions.cleanContexto());
  },
  fetchGetPuntosCoopeuch() {
    dispatch(puntosActions.fetchGetPuntosCoopeuch());
  },
  fetchMonederoValidar() {
    dispatch(fetchMonederoValidarActions.fetchMonederoValidar());
  },
  fetchGetKitBanner() {
    dispatch(activacionTarjetasActions.fetchGetKitBanner());
  },
  fetchPerfilCliente(userId) {
    dispatch(perfilComercialActions.fetchPerfilCliente(userId));
  },
  fetchCampanaCrm() {
    dispatch(fetchCampanaCrmActions.fetchCampanaCrm());
  },
  fetchValidarNorma484() {
    dispatch(norma484Actions.fetchValidarNorma484());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
