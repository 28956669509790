import React from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SliderPage, ContentPage } from './styles';
import BannerUpgradeDesk from '../../../../../assets/desk-banner-upgrade.png';
import BannerUpgradeMobile from '../../../../../assets/mob-banner-upgrade.png';

const Index = ({ GARedirectUM = () => undefined }) => {
  return (
    <SliderPage src={BannerUpgradeDesk} srcMobile={BannerUpgradeMobile}>
      <div className="um-banner-container row">
        <ContentPage className="col-8 col-lg-6 text-md-center mt-2 pt-3 pt-md-4">
          <span className="um-banner-title">Cambia tu Monedero </span>
          <br className="d-md-none" />
          <span className="um-banner-title">Digital a </span>
          <br className="d-none d-sm-block" />
          <span className="um-banner-title">una Cuenta</span>
          <br className="d-md-none" />
          <span className="um-banner-title">Vista Coopeuch</span>
          <br />
          <Link to="/ecd/upgradeMonedero" onClick={GARedirectUM}>
            <Button
              fontSize={14}
              className="um-banner-button"
              rounded={4}
              color="#007DB7"
              bgColor="#fff"
              width={168}
            >
              Ingresa aquí
            </Button>
          </Link>
        </ContentPage>
      </div>
    </SliderPage>
  );
};

Index.propTypes = {
  GARedirectUM: PropTypes.func.isRequired
}


export default Index;
