import React from 'react';

import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';

import { Banner, CustomList } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({ bannerClick }) => {
  return (
    <>
      <div className="row bpx-md-5 bmt-md-4">
        <div className="col-xs-12 w-100">
          <Banner
            rounded
            className="d-none d-md-block"
            src={`${staticPath}cuotaparticipacion/banner_desk.jpg`}
          >
            <div className="row h-100 banner-actions">
              <div className="col-xs-8 col-sm-5 bpl-5">
                <Typography className="mb-8" color={'white'} tag="p" fontSize={14}>
                  Hazte Socio Coopeuch
                </Typography>
                <Typography className="mb-8" color={'white'} tag="p" fontSize={18}>
                  Únete a nuestra Cooperativa y descubre los beneficios que tenemos para tí.
                </Typography>
                <Button
                  bgColor={'#E81E2B'}
                  color="white"
                  className="action-button"
                  onClick={bannerClick}
                  id="action-button"
                >
                  Hazte Socio
                </Button>
              </div>
            </div>
          </Banner>
          <Banner
            height={320}
            className="d-block d-md-none"
            src={`${staticPath}cuotaparticipacion/banner_cp_mobile.jpg`}
          >
            <div className="row h-100 banner-actions">
              <div className="col-xs-8 col-sm-5 bpl-5">
                <Typography className="mb-8" color={'white'} tag="p" fontSize={14}>
                  Hazte Socio Coopeuch
                </Typography>
                <Typography className="mb-8" color={'white'} tag="p" fontSize={18}>
                  Únete a nuestra Cooperativa y descubre los beneficios que tenemos para tí.
                </Typography>
                <Button
                  bgColor={'#E81E2B'}
                  color="white"
                  className="action-button"
                  onClick={bannerClick}
                  id="action-button"
                >
                  Hazte Socio
                </Button>
              </div>
            </div>
          </Banner>
        </div>
      </div>
      <div className="row bpx-3 bpx-md-5 bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <Typography tag="p" color='#333333' fontSize={18} fontWeight={700}>
            {' '}
            ¿Cuáles son los beneficios?
          </Typography>
        </div>
      </div>
      <div className="row bpx-3 bpx-md-5 bmt-3 bmt-md-4">
        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1">
            <CardBox.Header>
              <>
                <LoadIcon
                  src={`${iconPath}gift.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />
                {' '}
                <Typography className="mb-8" color={'#383638'} tag="p" fontSize={18}>
                  Descuentos y beneficios
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  Más de 100 descuentos en comercios, como farmacias, preuniversitarios, VTR, entre
                  otros.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header>
              <>
                <LoadIcon
                  src={`${iconPath}money_exchange.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />{' '}
                <Typography className="mb-8" color={'#383638'} tag="p" fontSize={18}>
                  Ser parte del Remanente
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  Una vez al año se distribuye el resultado positivo de la cooperativa.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bpx-3 bpx-md-5 bmy-3 bmy-md-4">
        <div className="col-xs-12 col-md-6">
          <CardBox className="h-100 w-auto bmr-md-1">
            <CardBox.Header>
              <>
                <LoadIcon
                  src={`${iconPath}coins.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />{' '}
                <Typography className="mb-8" color={'#383638'} tag="p" fontSize={18}>
                  Invertir en Cuotas de Participación
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  Invierte desde $3.290 mensuales para ser parte del Remanente anual de Coopeuch.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
        <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
          <CardBox className="h-100 w-auto bml-md-1">
            <CardBox.Header>
              <>
                <LoadIcon
                  src={`${iconPath}credit_card.svg`}
                  size={30}
                  color="#000"
                  className="float-left bmr-3"
                />
                {' '}
                <Typography className="mb-8" color={'#383638'} tag="p" fontSize={18}>
                  Obtén Cuenta Vista Coopeuch
                </Typography>
              </>
            </CardBox.Header>
            <CardBox.Body>
              <CustomList>
                <li className="bmy-3">
                  Tarjeta de débito sin costo de mantención, paga solo lo que usas.
                </li>
              </CustomList>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </>
  );
};

export default Index;
