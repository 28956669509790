import React, {useEffect, useState} from 'react';
import { withRouter , Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Alert from '@coopeuch-components/web/atoms/Alert';
import Button from '@coopeuch-components/web/atoms/Button';
import Tooltip from '@coopeuch-components/web/atoms/Tooltip';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Skeleton from 'react-loading-skeleton';
import { agregarformatoPesos, formatThousandsSeparators } from '../../../../utils/formatters';
import Expand from '../../../atoms/Expand';
import { cuotaParticipacionActions } from '../../../../../application/actions/cuotaParticipacion';

import { Hr, ExpandContent, RightCardWrapper, AporteWrapper, Wrapper } from './styles';
import Icon_pencil from '../../../../assets/svg/Icon-pencil.svg'
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  loadingData,
  aporte,
  cuotasPagadas,
  cuotasPorMes,
  cuotasPendientes,
  montoPendiente,
  valorCuota,
  tipoPago,
  hasAccounts,
  payButton,
  showButton,
  toModificacion,
  toSugerencia,
  fetchModificacionCuotasParticipacion,
  modify_cuotas,
  sugerenciaCuotas,
  fetchSugerenciaCuotasParticipacion
}) => {
  console.log('modify_cuotas: ', modify_cuotas)
  useEffect(() => {
    fetchModificacionCuotasParticipacion();
    fetchSugerenciaCuotasParticipacion();
  }, [fetchModificacionCuotasParticipacion]);
  let sugerenciaDestacada = null;

  if (sugerenciaCuotas?.data && Array.isArray(sugerenciaCuotas.data) && sugerenciaCuotas.data.length > 0) {
    sugerenciaDestacada = sugerenciaCuotas.data.find((sugerencia) => sugerencia.destacado);
  }
  const AlertMessage = () => {
    switch (tipoPago) {
      case 'CA':
        return (
          <Typography tag="p" color="#005D8F" fontSize={16}>
            Te invitamos a realizar
            <b> el pago de tus cuotas </b>
            por medio de pago online de forma rápida y segura
            <b> a través de Webpay. </b>
          </Typography>
        );
      case 'PA':
        return (
          <Typography tag="p" color="#005D8F" fontSize={16}>
            <b>Estás suscrito a Pago Automático de Cuentas (PAC).</b>
            Tus Cuotas serán descontadas automáticamente.
          </Typography>
        );
      case 'WE':
        return (
          <Typography tag="p" color="#005D8F" fontSize={16}>
            <b>Estás suscrito a Pago Automático de Cuentas (PAC).</b>
            Tus Cuotas serán descontadas automáticamente.
          </Typography>
        );
      case 'PL':
        return (
          <Typography tag="p" color="#005D8F" fontSize={16}>
            <b>Estás suscrito a pago por planilla.</b>
            Tus cuotas de participación serán descontadas de tu liquidación de sueldo.
          </Typography>
        );
      case 'NP':
        return (
          <Typography tag="p" color="#005D8F" fontSize={16}>
            Te invitamos a realizar
            <b> el pago de tus cuotas </b>
            por medio de pago online de forma rápida y segura
            <b> a través de Webpay. </b>
          </Typography>
        );        
      default:
        return '';
    }
  };
  return (
      <Wrapper>
      {!loadingData &&
        !!cuotasPendientes &&
        (['PA', 'PL', 'WE'].includes(tipoPago) || ((tipoPago === 'CA'||tipoPago === 'NP') && !hasAccounts)) && (
          <div className="row bmt-3 bmt-md-4">
            <div className="col-xs-12">
              <Alert
                className="alert-cdp-pendiente bp-3 "
                bgType="info"
                id="alert-cdp-pendiente"
                show
              >
                <AlertMessage />
              </Alert>
            </div>
          </div>
        )}
      <div className="row bmy-md-4 bmy-3">
        <div className="col-md-6 col-xs-12">
          <div className="w-100 h-100 bpr-md-1">
            <CardBox className="h-100 bmr-md-1">
              <CardBox.Body>
                <div className="row">
                  <div className="col-xs-12">
                    <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                      {loadingData ? <Skeleton width="50%" /> : 'Monto ahorrado a la fecha:'}
                    </Typography>
                    {loadingData ? (
                      <Typography
                        className="bmb-3"
                        tag="p"
                        color="#333333"
                        fontSize={28}
                        fontWeight={700}
                      >
                        <Skeleton width="40%" />
                      </Typography>
                    ) : (
                      <AporteWrapper className="bmb-3 middle-items" ammount={aporte}>
                        <Typography tag="p" color="#333333" fontSize={28} fontWeight={700}>
                          {agregarformatoPesos(aporte)}
                        </Typography>
                        <Tooltip
                          // position="top"
                          rounded={4}
                          tooltipclassName="ammount-tooltip"
                          content={
                            <div className="tt-content">
                              En caso de retiros de capital, este será reajustado de acuerdo a la
                              corrección monetaria acumulada al día de retiro de los fondos.
                            </div>
                          }
                          position="bottom"
                          bgColor="white"
                        >
                          <LoadIcon
                            src={`${iconPath}ask-icon.svg`}
                            size={15}
                            color="#00A1DF"
                            className="float-left bml-2"
                          />
                        </Tooltip>
                      </AporteWrapper>
                    )}
                    <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                      {loadingData ? (
                        <Skeleton width="50%" />
                      ) : (
                        <>
                          Total cuotas pagadas:
                          <b> 
                            {' '}
                            {formatThousandsSeparators(cuotasPagadas)}
                          </b>
                        </>
                      )}
                    </Typography>
                    <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                      {loadingData ? (
                        <Skeleton width="50%" />
                      ) : (
                        <>
                          Cuotas mensuales pactadas:
                          <b> 
                            {' '}
                            {cuotasPorMes}
                          </b>
                        </>
                      )}
                    </Typography>
                    {modify_cuotas?.success ? 
                       sugerenciaDestacada ? 
                         <Link 
                           to="/ecd/productos/cuota-de-participacion/sugerencia" 
                           onClick={toSugerencia}
                         >
                           <div style={{ display: 'flex'}}>
                             <img src={Icon_pencil} />
                             <span style={{marginLeft: 5}}>Modificar N° de cuotas pactadas</span>
                           </div>
                         </Link>
                      :
                         <Link 
                           to="/ecd/productos/cuota-de-participacion/modificacion"
                           onClick={toModificacion}
                         >
                           <div style={{ display: 'flex'}}>
                             <img src={Icon_pencil} />
                             <span style={{marginLeft: 5}}>Modificar N° de cuotas pactadas</span>
                           </div>
                         </Link>
                      :
                      null}
                      
                   
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
        <div className="col-md-6 col-xs-12 bmt-3 bmt-md-0">
          <RightCardWrapper className="w-100 h-100 bpr-md-1">
            <CardBox className="h-100 bml-md-1 right-card-resumen-cdp">
              <CardBox.Body>
                <div className="row h-100">
                  <div className="col-xs-12">
                    <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                      {loadingData ? <Skeleton width="50%" /> : 'Monto pendiente a la fecha:'}
                    </Typography>
                    {loadingData ? (
                      <>
                        <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                          <Skeleton width="30%" />
                        </Typography>
                        <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                          <Skeleton width="30%" />
                        </Typography>
                      </>
                    ) : (
                      <>
                        {cuotasPendientes ? (
                          <>
                            <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                              En cuotas:
                              <b> 
                                {' '}
                                {formatThousandsSeparators(cuotasPendientes)}
                              </b>
                            </Typography>
                            <Typography className="bmb-3" tag="p" color="#333333" fontSize={16}>
                              En pesos:
                              <b> 
                                {' '}
                                {agregarformatoPesos(montoPendiente)}
                              </b>
                            </Typography>
                          </>
                        ) : (
                          <>
                            <Alert
                              className="alert-ok-cdp bp-3 bmb-3"
                              bgType="info"
                              id="alert-ok-cdp"
                              show
                            >
                              <Typography tag="p" color="#005D8F" fontSize={16}>
                                <b>¡Muy bien!</b>
                                {' '}
                                Estás al día en el pago de tus cuotas.
                              </Typography>
                            </Alert>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-xs-12 bmt-auto">
                    <Hr />
                    <div className="d-flex middle-items">
                      {loadingData ? (
                        <>
                          <Skeleton circle height={22.5} width={22.5} className="bmr-2" />
                          <Skeleton width={160} />
                        </>
                      ) : (
                        <>
                          <LoadIcon
                            src={`${iconPath}coin.svg`}
                            size={24}
                            color="#000"
                            className="float-left bmr-2"
                          />
                          {' '}
                          <Typography color="#383638" tag="p" fontSize={18}>
                            1 Cuota de Participación es igual
                            {' '}
                            <b>
                              a
                              {' '}
                              {agregarformatoPesos(valorCuota)}
                              {' '}
                            </b>
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </RightCardWrapper>
        </div>
      </div>
      {!loadingData && !!cuotasPendientes && (tipoPago === 'CA' || tipoPago === 'NP') && (
        <div className="row bmb-3 bmb-md-4">
          <div className="col-xs-12 col-md-3">
            <Button
              bgColor="#e81e2b"
              color="white"
              className="action-button"
              onClick={payButton.action}
              fullWidth
              id="action-button"
            >
              {payButton.title}
            </Button>
          </div>
        </div>
      )}
      {loadingData ? (
        <div className="row bmb-md-4 bmb-3">
          <div className="col-xs-12">
            <CardBox>
              <CardBox.Body>
                <div className="row">
                  <div className="col-xs-1">
                    <Skeleton circle height={30} width={30} />
                  </div>
                  <div className="col-xs d-flex middle-items">
                    <Skeleton width={100} />
                  </div>
                  <div className="col-xs text-right">
                    <Skeleton circle height={30} width={30} className="bml-auto" />
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
      ) : (
        <div className="row bmb-md-4 bmb-3">
          <div className="col-xs-12">
            <Expand elevation={1} iconColor="#00A1DF" rounded defaultSelectedItem={1}>
              <Expand.Item position={1}>
                <Expand.Header color="white">
                  <LoadIcon
                    src={`${iconPath}book.svg`}
                    size={23}
                    color="black"
                    className="float-left bmr-3"
                  />
                  <span className="d-none d-md-block">
                    Conoce sobre las Cuotas de Participación
                  </span>
                  <span className="d-block d-md-none font-title-mobile">
                    Conoce sobre las Cuotas de Participación
                  </span>
                </Expand.Header>
                <Expand.Body>
                  <ExpandContent>
                    <ul>
                      <li className="bullet-red d-flex middle-items">
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Cada cuota es tu aporte a la cooperativa y lo que da derecho a ser socio.
                        </Typography>
                      </li>
                      <li className="bullet-red d-flex middle-items">
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          El valor de la cuota se reajusta anualmente.
                        </Typography>
                      </li>
                      <li className="bullet-red d-flex middle-items">
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          El pago de las cuotas es mensual y su valor corresponde al número de
                          cuotas pactadas.
                        </Typography>
                      </li>
                      <li className="bullet-red d-flex middle-items">
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Cada cuota es tuya, si quieres dejar de ser socio de la cooperativa puedes
                          retirar el monto ahorrado.
                        </Typography>
                      </li>
                      <li className="bullet-red d-flex middle-items">
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Tu pago es muy importante, con el aporte de todos los socios podemos crear
                          nuevos y mejores productos financieros.
                        </Typography>
                      </li>
                      <li className="bullet-red d-flex middle-items">
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          En caso de retirar el capital, este deberá ser reajustado de acuerdo a la
                          corrección monetaria acumulada al día de retiro de ese fondo.
                        </Typography>
                      </li>
                    </ul>
                  </ExpandContent>
                </Expand.Body>
              </Expand.Item>
            </Expand>
          </div>
        </div>
      )}
    </Wrapper>
  );
};
const mapStateToProps = (store) => ({
  modify_cuotas: store.entities.cuotaParticipacion.modify_cuotas,
  sugerenciaCuotas: store.entities.cuotaParticipacion.sugerenciaCuotas,
});

const mapDispatchToProps = (dispatch) => ({
  fetchModificacionCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchModificacionCuotasParticipacion());
  },
  fetchSugerenciaCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchSugerenciaCuotasParticipacion());
  },
  
});
Index.displayName = 'Template_CuotaParticipacion_Resumen';
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));