import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidEmail } from '../../../utils/formatters';
import { downloadPDFBase64 } from '../../../utils/functions';
import { registerGA } from '../../../utils/metrics';

// componentes
import StepperRouter from './StepperRouter';
import { monederoActions } from '../../../../application/actions/monedero';
import { personaActions } from '../../../../application/actions/persona';
import { layoutActions } from '../../../../application/actions/ui_layout';
import logger from '../../../utils/logger';

const initState = {
  contratacionChecked: false,
  currentStep: 1,
  fecha: null,
  email: '',
  emailError: '',
  secondEmail: null,
  password: '',
  passwordError: '',
  secondEmailError: '',
  serverPasswordError: { title: '', message: '', buttonTitle: '' },
  showPassword: false,
  loadingContratacion: false,
  serviceError: false,
  blockedPassword: false,
  showEnviarComprobanteModal: false,
  showEnviarComprobanteErrorModal: false,
  showComprobanteEnviadoModal: false,
  checkdRequiredError: false,
};
class ContratoMonedero extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  componentDidMount = () => {
    const { fetchDatosPersonales, persona } = this.props;
    fetchDatosPersonales();
    const baseRut = String((persona?.rut || '').replace('-', ''));

    // Comenzar flujo arriba
    window.scrollTo(0, 0);

    logger('', {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'MONEDERO-INFORMACION',
      informacion_adicional: {},
    });
  };

  componentDidUpdate(prevProps) {
    const { monedero, comprobanteContratacion, persona } = this.props;

    if (monedero !== prevProps.monedero) {
      this.handleContratoMonederoResponse(monedero);
    } else if (
      comprobanteContratacion !== prevProps.comprobanteContratacion &&
      comprobanteContratacion !== null
    ) {
      downloadPDFBase64(comprobanteContratacion, 'Comprobante Contratacion Monedero');
    } else if (persona !== prevProps.persona) {
      if (persona.errorDatosPersonales) {
        this.setState({
          globalError: true,
        });
      } else {
        this.setState({
          email: persona.correo,
        });
      }
    }
  }

  handleContratoMonederoResponse = (monedero) => {
    if (monedero.error) {
      let serverPasswordError;
      let blockedPassword = false;
      let wrongPassword = false;
      let serviceError = false;
      switch (monedero.errorCode) {
        case '01':
          wrongPassword = true;
          serverPasswordError = {
            title: 'Clave incorrecta',
            message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
            buttonTitle: 'Reintentar',
          };
          break;
        case '02':
          wrongPassword = true;
          serverPasswordError = {
            title: 'Clave incorrecta',
            message: 'Recuerda que al tercer intento se bloqueará la contraseña.',
            buttonTitle: 'Reintentar',
          };
          break;
        case '03':
          wrongPassword = true;
          blockedPassword = true;
          serverPasswordError = {
            title: 'Clave Bloqueada',
            message: 'Clave Bloqueada. Por seguridad hemos bloqueado tu clave.',
            buttonTitle: 'Cambiar Clave',
          };
          break;
        default:
          serverPasswordError = { title: '', message: '', buttonTitle: '' };
          serviceError= true;
      }
      if (wrongPassword) {
        registerGA({
          'event': 'eve', 'eve-acc': '/tef/solicitar/monederoDigital/paso2', 'eve-cat': 'Error', 'eve-lab': 'Contraseña incorrecta'
        });
      }

      if (blockedPassword) {
        registerGA({
          'event': 'eve', 'eve-acc': '/tef/solicitar/monederoDigital/paso2', 'eve-cat': 'Error', 'eve-lab': 'Clave bloqueada'
        });
      }

      if (serviceError) {
        registerGA({
          'event': 'eve','eve-acc' : '/tef/solicitar/monederoDigital/paso2','eve-cat' : 'Error','eve-lab' : 'Servicio no disponible'
        });
      }
      
      console.log('CODE: ', monedero.errorCode);
      this.setState({
        loadingContratacion: false,
        serverPasswordError,
        serviceError,
        blockedPassword,
        wrongPassword,
      });
    } else if (monedero.success) {
      /**
       * Se elimina el llamado al servicio por tener el menú estático
       * TODO: habilitar cuando se vuelva a utilizar el servicio de configuración
       */
      // const { fetchLayoutConfigs } = this.props;
      // fetchLayoutConfigs();
      const { cuenta, fecha, email } = monedero;
      this.setState(
        {
          loadingContratacion: false,
          cuenta,
          fecha,
          email,
        },
        this.toNextStep
      );
    }
  };

  toNextStepPaso1 = () => {
    this.toNextStep();
    registerGA({
      'event': 'eve', 'eve-acc': '/tef/solicitar/monederoDigital/paso1', 'eve-cat': 'Click', 'eve-lab': 'Continuar'
    });
  };

  toNextStep = () => {
    this.setState(
      (prevState) => ({
        currentStep: prevState.currentStep + 1,
      }),
      () => {
        // Scrollear arriba cada vez que se avance al siguiente paso
        window.scrollTo(0, 0);
      }
    );
  };

  toPrevStep = () => {
    this.setState(
      (prevState) => ({
        currentStep: prevState.currentStep - 1,
      }),
      () => {
        // Scrollear arriba cada vez que se retroceda
        window.scrollTo(0, 0);
      }
    );
    registerGA({
      'event': 'eve', 'eve-acc': '/tef/solicitar/monederoDigital/paso2', 'eve-cat': 'Click', 'eve-lab': 'Volver'
    });
  };

  toHome = () => {
    this.props.history.push('/ecd/inicio');
  };

  onDownloadGenericContract = () => {
    registerGA({
      'event': 'eve','eve-acc' : '/tef/solicitar/monederoDigital/paso2','eve-cat' : 'Click','eve-lab' : 'Descargar contrato'
    });
  };

  onHandleCheckBox = () => {
    this.setState((prevState) => ({
      contratacionChecked: !prevState.contratacionChecked,
      // Remover alerta de "check" requerido en los términos y condiciones
      checkdRequiredError: false,
    }));
  };

  onHandlePasswordInput = (e) => {
    const value = e.target.value;
    const errorMessage = 'La clave debe tener entre 6 y 8 caracteres.';
    this.setState({
      password: value,
      passwordError: value.length < 6 || value.length > 8 ? errorMessage : '',
    });
  };

  onHandleChangeEmail = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      [name]: value,
      [`${name}Error`]: isValidEmail(value) ? '' : errorMessage,
    });
  };


  openSendEmailModal = () => {
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: true,
    });
  };

  onHandleSendComprobante = () => {
    const { secondEmail } = this.state;
    console.log(`enviando comprobante al email ${secondEmail}`);
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: true,
    });
  };

  onTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  onHandleExitWithoutContract = () => {
    this.setState({
      showCobrarDespuesSinContratoModal: true,
    });
  };

  closeAllModals = () => {
    this.setState({
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: false,
      showEnviarComprobanteErrorModal: false,
    });
  };

  contratar = () => {
    const { fetchContratacionMonedero } = this.props;
    const { password: clave, email: correo, contratacionChecked } = this.state;

    if (!contratacionChecked) {
      this.setState({ checkdRequiredError: true });
      return;
    }

    this.setState({ loadingContratacion: true });
    fetchContratacionMonedero({ clave, correo });

    registerGA({
      'event': 'eve', 'eve-acc': '/tef/solicitar/monederoDigital/paso2', 'eve-cat': 'Click', 'eve-lab': 'Contratar'
    });
  };

  onHandleDownloadComprobante = () => {
    const { fetchComprobanteContratacion, comprobanteContratacion } = this.props;

    if (comprobanteContratacion !== null) {
      downloadPDFBase64(comprobanteContratacion, 'Comprobante Contratacion Monedero');
      registerGA({
        'event': 'eve', 'eve-acc': '/tef/solicitar/monederoDigital/comprobante', 'eve-cat': 'Click', 'eve-lab': 'Descargar'
      });
    } else {
      fetchComprobanteContratacion();
    }
  };

  handleWrongPassword = () => {
    if (this.state.blockedPassword) {
      this.props.history.push('/recuperar-clave');
      registerGA({
        'event': 'eve','eve-acc' : '/tef/solicitar/monederoDigital/paso2','eve-cat' : 'Error','eve-lab' : 'Cambiar clave'
      });
    } else {
      // TODO: limpiar después de que falle o se bloquee, no al Reintentar,
      // para que no quede habilitado el botón de Contratar mientras se muestra la card
      // de pass incorrecta (o validar botón para se deshabilite cuando es incorrecta)
      this.setState({ wrongPassword: false, password: '' });
      registerGA({
        'event': 'eve','eve-acc' : '/tef/solicitar/monederoDigital/paso2','eve-cat' : 'Error','eve-lab' : 'Reintentar'
      });
    }
  };

  render() {
    const {
      contratacionChecked,
      cuenta,
      fecha,
      email,
      emailError,
      secondEmail,
      password,
      okStep2,
      showPassword,
      passwordError,
      secondEmailError,
      serverPasswordError,
      loadingContratacion,
      steps,
      currentStep,
      serviceError,
      blockedPassword,
      showCobrarDespuesSinContratoModal,
      showCobrarDespuesConContratoModal,
      showEnviarComprobanteModal,
      showComprobanteEnviadoModal,
      showEnviarComprobanteErrorModal,
      wrongPassword,
      checkdRequiredError,
    } = this.state;
    const { persona, handlebackLanding, goToCuentaVista } = this.props;
    return (
      <StepperRouter
        {...{
          cuenta,
          fecha,
          email,
          emailError,
          secondEmail,
          password,
          okStep2,
          showPassword,
          passwordError,
          secondEmailError,
          serverPasswordError,
          loadingContratacion,
          steps,
          currentStep,
          serviceError,
          blockedPassword,
          showCobrarDespuesSinContratoModal,
          showCobrarDespuesConContratoModal,
          showEnviarComprobanteModal,
          showComprobanteEnviadoModal,
          showEnviarComprobanteErrorModal,
          persona,
          wrongPassword,
          checkdRequiredError,
        }}
        checked={contratacionChecked}
        onHandleCheckBox={this.onHandleCheckBox}
        onHandlePasswordInput={this.onHandlePasswordInput}
        onTogglePassword={this.onTogglePassword}
        handlebackLanding={handlebackLanding}
        goToCuentaVista={goToCuentaVista}
        onDownloadGenericContract={this.onDownloadGenericContract}
        irAContratacion={this.toNextStepPaso1}
        volverACaracteristicas={this.toPrevStep}
        contratar={loadingContratacion ? null : this.contratar}
        continueStep3={this.continueStep3}
        closeModal={this.closeAllModals}
        toHome={this.toHome}
        onHandleEmailInputChange={this.onHandleChangeEmail}
        onHandleSendComprobante={this.onHandleSendComprobante}
        downloadComprobante={this.onHandleDownloadComprobante}
        openSendEmailModal={this.openSendEmailModal}
        handleWrongPassword={this.handleWrongPassword}
        pathStep={currentStep}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  monedero: store.entities.monedero.contratacionMonedero,
  comprobanteContratacion: store.entities.monedero.comprobanteContratacion,
  persona: store.entities.persona,
  isLoadingDatosPersonales: store.ui.loaders.isLoadingDatosPersonales,
  isLoadingContratacionMonedero: store.ui.loaders.isLoadingContratacionMonedero,
  errorLogContratoMonedero: store.entities.monedero.errorLogContratoMonedero,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContratacionMonedero(props) {
    dispatch(monederoActions.fetchContratacionMonedero(props));
  },
  fetchComprobanteContratacion(props) {
    dispatch(monederoActions.fetchComprobanteContratacion(props));
  },
  fetchLayoutConfigs() {
    dispatch(layoutActions.fetchLayoutConfigs());
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContratoMonedero));
