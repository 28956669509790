import React, { Fragment } from 'react';

import DashboardCard from '../../../../components/organism/DashboardCard';
import { SkeletonCard } from '../Skeleton';
import { agregarformatoPesos } from '../../../../utils/formatters';
import { patternSimpleDateFormat } from '../../../../utils/dates';
import { capitalize } from '../../../../utils/functions';
import { Swiper, SwiperSlide } from 'swiper/react';
import ErrorDashboard from '../ErrorDashboard';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from 'swiper';

import Wrapper from './styles';
import { cuentasAhorroTexts } from '../../../../containers/Dashboard/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Index = ({ cuentasAhorro, isLoading, errorCuentasAhorro, handleClickTarjeta, handleChangeSlide }) => {
  return (
    <Wrapper loading={isLoading}>
      {isLoading ? (
        <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
          <div className="cuenta-swiper">
            <DashboardCard classes="m-0 cuenta-card-container">
              <DashboardCard.Body classes="cuenta-container">
                <SkeletonCard short />
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        </div>
      ) : (
        <Fragment>
          {errorCuentasAhorro ? (
            <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
              <ErrorDashboard descText={cuentasAhorroTexts.ERROR_DESC} />
            </div>
          ) : (
            <div>
              {cuentasAhorro?.length >= 1 && (
                <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
                  <Swiper
                    pagination={cuentasAhorro.length > 1}
                    navigation={cuentasAhorro.length > 1}
                    className="cuenta-swiper"
                    onSlideChange={handleChangeSlide}
                  >
                    {cuentasAhorro.map((item, index) => (
                      <SwiperSlide key={index}>
                        <DashboardCard classes="m-0 cuenta-card-container">
                          <DashboardCard.Body classes="cuenta-container">
                            <div
                              className="row cursor-pointer"
                              onClick={() => handleClickTarjeta(item)}
                            >
                              <div className="col-md-1 col-xs-3 cuenta-icon-container bpl-0 bpl-md-0">
                                <LoadIcon
                                  src={`${iconPath}pig-filled_new.svg`}
                                  color="#000"
                                  className="float-left bmr-3 cuenta-icon"
                                />
                              </div>
                              <div className="col-md-11 col-xs-9 bpl-md-4 bpl-0">
                                <div className="bml-0 bml-md-2 bmb-1">
                                  <label className="cuenta-title cursor-pointer">
                                    {cuentasAhorroTexts.PREFIJO} {item.descripcionProducto}
                                  </label>
                                </div>
                                <div className="bml-0 bml-md-2">
                                  <label className="cursor-pointer">
                                    {cuentasAhorroTexts.NUMERICO} {item.numeroCuenta}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 bpl-0 bpl-md-2">
                                <div className="bmb-2">
                                  <label className="cuenta-text cursor-pointer">
                                    {cuentasAhorroTexts.AHORRADO_FECHA}
                                  </label>
                                </div>
                                <div>
                                  <label className="cuenta-value cursor-pointer">
                                    {agregarformatoPesos(item.saldoDisponible)}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 bmb-3 bmb-md-0 bpl-0 bpl-md-2">
                                <div className="cuenta-margin-text cuenta-text-right">
                                  <label className="cuenta-text cursor-pointer">
                                    {cuentasAhorroTexts.FECHA_APERTURA}{' '}
                                    <b>
                                      {capitalize(
                                        patternSimpleDateFormat(item.fechaApertura, 'dd MMM.yyyy')
                                      )}
                                    </b>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </DashboardCard.Body>
                        </DashboardCard>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default Index;
