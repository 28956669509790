import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { capitalizacionActions } from '../../../application/actions/capitalizacion';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiCapitalizacion = api(process.env.REACT_APP_CAPITALIZACION);

export function* fetchHabilitadoCapitalizar() {
  try {
    const { data, error } = yield call(apiCapitalizacion.get, 'habilitado/v1/capitalizar');
    if (data) {
      yield put(capitalizacionActions.fetchHabilitadoCapitalizarSuccess(data));
    } else {
      const errorData = {
        errorServicio: true,
        ...error,
        errorListaNegra: error.code === 'RE-25' ? true : false,
      };
      yield put(capitalizacionActions.fetchHabilitadoCapitalizarError(errorData));
    }
  } catch (err) {
    yield put(capitalizacionActions.fetchHabilitadoCapitalizarError());
    console.log(err.message);
  }
}

export function* capitalizarRemanente(action) {
  try {
    const { cantidadCuotas, numeroCuenta } = action.payload;

    yield put(loadersActions.fetchLoadingCapitalizarRemanente({ isLoading: true }));

    const { data, error } = yield call(apiCapitalizacion.post, 'v1/capitalizar', {
      cantidadCuotas,
      numeroCuenta,
    });
    if (data) {
      yield put(capitalizacionActions.capitalizarRemanenteSuccess(data));
    } else {
      yield put(capitalizacionActions.capitalizarRemanenteError({ error }));
    }
    yield put(loadersActions.fetchLoadingCapitalizarRemanente({ isLoading: false }));
  } catch (error) {
    yield put(capitalizacionActions.capitalizarRemanenteError({ error }));
    yield put(loadersActions.fetchLoadingCapitalizarRemanente({ isLoading: false }));
  }
}

export function* fetchDetalleCapitalizacion(action) {
  try {
    yield put(loadersActions.fetchLoadingDetalleCapitalizacion({ isLoading: true }));

    const { data, error } = yield call(apiCapitalizacion.get, 'detalle/v1/obtener');
    if (data) {
      yield put(capitalizacionActions.fetchDetalleCapitalizacionSuccess(data));
    } else {
      yield put(capitalizacionActions.fetchDetalleCapitalizacionError({ error }));
    }
    yield put(loadersActions.fetchLoadingDetalleCapitalizacion({ isLoading: false }));
  } catch (error) {
    yield put(capitalizacionActions.fetchDetalleCapitalizacionError({ error }));
    yield put(loadersActions.fetchLoadingDetalleCapitalizacion({ isLoading: false }));
  }
}

export function* enviarComprobanteEmail(action) {
  try {
    const { correo } = action.payload;
    const { data, error } = yield call(apiCapitalizacion.post, 'documento/comprobante/v1/enviar', {
      correo,
    });
    if (data) {
      yield put(capitalizacionActions.enviarComprobanteEmailSuccess(data));
    } else {
      yield put(capitalizacionActions.enviarComprobanteEmailError({ error }));
    }
  } catch (err) {
    yield put(capitalizacionActions.enviarComprobanteEmailError());
    console.log(err.message);
  }
}

export function* fetchComprobanteCapitalizacion(action) {
  try {
    const { data, error } = yield call(apiCapitalizacion.get, 'documento/comprobante/v1/obtener');
    if (error) {
      console.error(error, 'fetchComprobanteCapitalizacion');
    }
    if (data) {
      yield put(capitalizacionActions.fetchComprobanteCapitalizacionSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchComprobanteCapitalizacion');
  }
}

export function* watchFetchHabilitadoCapitalizar() {
  yield takeLatest(
    capitalizacionActions.fetchHabilitadoCapitalizar.type,
    fetchHabilitadoCapitalizar
  );
}

export function* watchCapitalizarRemanente() {
  yield takeLatest(capitalizacionActions.capitalizarRemanente.type, capitalizarRemanente);
}

export function* watchEnviarComprobanteEmail() {
  yield takeLatest(capitalizacionActions.enviarComprobanteEmail.type, enviarComprobanteEmail);
}

export function* watchFetchComprobanteCapitalizacion() {
  yield takeLatest(
    capitalizacionActions.fetchComprobanteCapitalizacion.type,
    fetchComprobanteCapitalizacion
  );
}
export function* watchFetchDetalleCapitalizacion() {
  yield takeLatest(
    capitalizacionActions.fetchDetalleCapitalizacion.type,
    fetchDetalleCapitalizacion
  );
}

export default function* rootSaga() {
  yield spawn(watchFetchHabilitadoCapitalizar);
  yield spawn(watchCapitalizarRemanente);
  yield spawn(watchEnviarComprobanteEmail);
  yield spawn(watchFetchComprobanteCapitalizacion);
  yield spawn(watchFetchDetalleCapitalizacion);
}
