import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  display: inline-flex;
  /*   margin-left: -30px; */
  vertical-align: middle;
  pointer-events: none;
  width: 100%;
  .icon-container-select {
    text-align: left;
    width: 100%;
  }
`;

export const LabelWrapper = styled.p`
  text-align: start !important;
  margin-bottom: 14px !important;
`;

export const SelectInput = styled.div`
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  appearance: none;
  padding: 13px;
  height: 48px;
  width: 100%;
  font-size: ${({ textSize }) => `${textSize}px`};
  border: 1px solid ${({ open, borderColor }) => (open ? '#007db7' : borderColor)};
  background-color: ${({ focus }) => (focus ? 'rgba(192,233,253,0.2)' : '#ffff')};
  border-radius: 4px;
  cursor: pointer;

  white-space: nowrap;

  .select-container-value {
    overflow: hidden;
  }

  .select-container-all {
    grid-template-columns: 1fr 20px;
    gap: 5px;
    display: grid;
    margin-top: 2px;
  }
`;

export const OptionsContainer = styled.div`
  height: 104px;
  background-color: white;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 9%);

  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 9;

  ${({ open }) => !open && 'display: none;'}

  ${({ numberOfOptions }) => numberOfOptions === 1 && `height: 52px;`}
  ${({ numberOfOptions }) => numberOfOptions >= 4 && `height: 192px;`}

  div {
    padding: 18px 16px;
    font-size: 14px;
    color: #383838;
    cursor: pointer;
  }

  div:hover {
    background-color: #f7f3f5;
  }

  ::-webkit-scrollbar {
    width: 11px;
  }

  ::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    height: 51px;
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #d4d4d4;
  }
`;

export default Wrapper;
