import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/certificadosProdVigentes';

function fetchPDFCertProdVigentesSuccess(state, action) {
  const { data } = action.payload;

  state.certProdVigentes.pdf = data?.message;
}

function fetchPDFCertProdVigentesError(state, action) {
  const { data } = action.payload;

  state.certProdVigentes.error = true;
  state.certProdVigentes.code = data?.code
  state.certProdVigentes.errorMessage = data?.message
}

const fetchPDFCertProdVigentes = createAction('fetchPDFCertProdVigentes');

// ------------- connection ------------- //

const certProdVigentesReducer = createSlice({
  name: 'certProdVigentes',
  initialState,
  reducers: {
    fetchPDFCertProdVigentesSuccess,
    fetchPDFCertProdVigentesError,
  },
});

export const certProdVigentesActions = {
  ...certProdVigentesReducer.actions,
  fetchPDFCertProdVigentes,
};

export default certProdVigentesReducer.reducer;
