import React, { useState } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router';

import Home from '../Home';
import Activacion from '../Activacion';
import Autorizacion from '../Autorizacion';
import Comprobante from '../Comprobante';

const StepperRouter = () => {
  const match = useRouteMatch();
  const steps = ['Activación', 'Autorización', 'Comprobante'];

  const [switchPassCoopeuchOrSms, setSwitchPassCoopeuchOrSms] = useState(true);

  return (
    <Switch>
      <Route exact path={`${match?.url}/`} render={() => <Home {...{ steps }} />} />
      <Route exact path={`${match?.url}/activacion`} render={() => <Activacion {...{ steps }} />} />
      <Route
        exact
        path={`${match?.url}/autorizacion`}
        render={() => (
          <Autorizacion {...{ steps, switchPassCoopeuchOrSms, setSwitchPassCoopeuchOrSms }} />
        )}
      />
      <Route
        exact
        path={`${match?.url}/comprobante`}
        render={() => <Comprobante {...{ steps, switchPassCoopeuchOrSms }} />}
      />
      <Redirect path={`${match?.path}/`} to={`${match?.path}/`} />
    </Switch>
  );
};

export default StepperRouter;
