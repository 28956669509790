import styled from 'styled-components';

const Wrapper = styled.div`
  opacity: ${(props) => (props.visibility ? '1' : '0')};
  position: ${(props) => (props.visibility ? 'relative' : 'absolute')};
  .factor-countdown--wrapper--ilustration {
    position: relative;

    .factor-countdown--wrapper--info-icon {
      position: absolute;
      right: 12px;
      top: 18px;
      z-index: 2;
      background-color: white;
      border-radius: 50%;
    }
  }
  .factor-countdown-image {
    margin-left: -4px;
  }
`;

export const CircleLineAnimation = styled.div`
  position: absolute;
  top: -7px;
  left: -10px;
  z-index: 1;

  svg {
    circle {
      fill: transparent;
      stroke-width: 10;
      stroke: #03a1df;
      stroke-dashoffset: 0;
      stroke-dasharray: ${({ percentage }) => Math.round((percentage / 100) * 532 + 573)};
      transition: stroke-dashoffset 1s;
      -webkit-animation-play-state: running;
      -moz-transform: rotate(-89deg) translateX(-190px);
      -webkit-animation: ${({ duration }) => `countdownCircle ${duration}s linear forwards`};
      animation: ${({ duration }) => `countdownCircle ${duration}s linear forwards`};
    }
  }

  @keyframes countdownCircle {
    from {
      stroke-dashoffset: 573;
    }

    to {
      stroke-dashoffset: 0;
    }
  }
`;

export default Wrapper;

// 537px - 0%
// 1069px - 100%

// 1069 - 537 = 532
