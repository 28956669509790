/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
// Template
import CuentaTemplate from '../../../../components/templates/CuentaAhorro/SolicitudCAFlujo/Contratacion';

// actions
// import { getMultiValue } from 'chartist';
import { cuentaAhorroActions } from '../../../../../application/actions/cuentaAhorro';
import { factoresSeguridadActions } from '../../../../../application/actions/factoresSeguridad';

// Utils
import { isValidEmail, upperCaseOnlyLetters } from '../../../../utils/formatters';
import { registerGA } from '../../../../utils/metrics';

// Hook
import useWindowSize from '../../../../utils/hooks/useWindowSize';

// Styles
import { size } from '../../../../utils/breakpoints';

// Logger
import logger from '../../../../utils/logger';

// Objeto con los codigos del producto
const codigosCuentas = [
  {
    name: 'AhorroAdultoReajustable',
    code: 'AR06',
  },
  {
    name: 'AhorroAdultoNominal',
    code: 'AN07',
  },
  {
    name: 'AhorroVivienda',
    code: 'AR08',
  },
];

const Contratacion = ({
  cuentaSeleccionada,
  handleClickReturnSolicitudCA,
  handleStep,
  handleMail,
  handleCleanCuenta,
}) => {
  const [timeValue, setTimeValue] = useState(120);
  const timeInterval = useRef();

  const [authorizationMethod, setAuthorizationMethod] = useState({
    passCoopeuch: {
      isActive: true,
      stepFactor: 0,
      withCountdownAnimation: false,
      activeTimer: false,
      errorCard: {
        show: false,
        title: '',
        message: '',
        showButton: false,
        buttonText: '',
      },
    },
    tarjetaCoordenadas: {
      isActive: false,
      withCountdownAnimation: false,
      showInputInError: false,
      stepFactor: 0,
      activeTimer: false,
      buttonText: 'Autorizar',
      message:
        'Haz clic en el botón para validar con tu Tarjeta de Coordenadas y completar la operación.',
      desafios: {
        1: '',
        2: '',
        3: '',
      },
      valoresDesafios: {
        1: '',
        2: '',
        3: '',
      },
      hasError: false,
      errorCard: {
        show: false,
        title: '',
        message: '',
        showButton: false,
        buttonText: '',
      },
    },
  });

  const [currentCodeAccount, setcurrentCodeAccount] = useState(null);
  const [contratosPDF, setContratosPDF] = useState(null);
  const [isCheckedCheckBox, setIsCheckedCheckBox] = useState(false);
  const [showScreenError, setShowScreenError] = useState(false);

  const [mailSocio, setMailSocio] = useState('');
  const [mailSeleccion, setMailSeleccion] = useState(null);
  const [newMail, setNewMail] = useState('');
  const [validMail, setValidMail] = useState(false);
  const [timeOut, setTimeOut] = useState({
    cpass: false,
    tcoo: false,
  });

  const [buttonLock, setButtonLock] = useState(true);

  // State ancho pantalla
  const [ischangeWidth, setIschangeWidth] = useState(false);

  const dispatch = useDispatch();

  const strDocumentsPDF = useSelector(
    (store) => store.entities.cuentasAhorro.documentsPDF,
    shallowEqual
  );

  const strDatosPersonales = useSelector(
    (store) => store.entities.cuentasAhorro.datosPersonales,
    shallowEqual
  );

  const isLoadingDPersonales = useSelector(
    (store) => store.ui.loaders.isLoadingDatosPersonales,
    shallowEqual
  );

  const strContratacionGenerar = useSelector(
    (store) => store.entities.cuentasAhorro.contratacionGenerar,
    shallowEqual
  );

  const isLoadingContratGenerada = useSelector(
    (store) => store.ui.loaders.isLoadingContratado,
    shallowEqual
  );

  const strContratacionIniciar = useSelector(
    (store) => store.entities.cuentasAhorro.contratacionIniciar,
    shallowEqual
  );

  const filterCodeByAccountSelect = (currentAccount) => {
    const codeAccount = codigosCuentas.filter((code) => code.name === currentAccount);
    setcurrentCodeAccount(codeAccount);
  };

  const downloadDocumentPDF = (nameDocument) => {
    // UPDATE marca analitycs descarga de contrato
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
      'eve-cat': 'Click',
      'eve-lab': nameDocument,
    });

    const track = strContratacionIniciar?.data.track;
    dispatch(cuentaAhorroActions.fetchGetPDFGenerar({ nameDocument, track }));
  };

  const onChangeEmail = (e) => {
    const { value, name } = e.target;
    if (name === 'correo' && value.length > 255) return;

    setNewMail(value);
  };

  // Codigo ejecutivo

  const [codeExec, setCodeExec] = useState({
    showInputCode: false,
    value: '',
    code: '',
  });

  const executiveData = useSelector(
    (store) => store?.entities?.cuentasAhorro?.executiveCode,
    shallowEqual
  );

  const { error: codeExecError, success: codeExecSuccess } = useSelector(
    (store) => store?.entities?.cuentasAhorro?.executiveCode,
    shallowEqual
  );

  useEffect(() => {
    if (codeExecSuccess) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Mensaje',
        'eve-lab': 'Codigo ejecutivo correcto',
      });
    }

    if (codeExecError) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Mensaje',
        'eve-lab': 'Codigo ejecutivo incorrecto',
      });
    }
  }, [codeExecError, codeExecSuccess]);

  const handleOnClickCodeExec = (value) => {
    if (value === 'yes') {
      setCodeExec((prevState) => ({ ...prevState, value: 'yes', showInputCode: true }));
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CA_SOLICITUDPRODUCTO_CODEJECUTIVO_SI',
        informacion_adicional: {},
      });
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Atendido por ejecutivo: Si',
      });
    }
    if (value === 'no') {
      setCodeExec((prevState) => ({ ...prevState, value: 'no', showInputCode: false, code: '' }));
      dispatch(cuentaAhorroActions.resetExecutiveName());
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Atendido por ejecutivo: No',
      });
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CA_SOLICITUDPRODUCTO_CODEJECUTIVO_NO',
        informacion_adicional: {},
      });
    }
  };

  const onChangeCodeExec = (e) => {
    const validateInput = upperCaseOnlyLetters(e);
    setCodeExec((prevState) => ({ ...prevState, code: validateInput }));
    // Al tener 4 caracteres se realiza el dispatch
    if (e.length === 4) {
      dispatch(cuentaAhorroActions.fetchExecutiveName({ code: validateInput }));
    }
  };

  const handleClickReturn = () => {
    // marca analitycs volver
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Cancelar',
    });

    handleClickReturnSolicitudCA();
  };

  const handleClickContinue = () => {
    // marca analitycs boton continuar
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });

    handleMail(mailSeleccion === 'socio' ? mailSocio : newMail);
    handleCleanCuenta();
  };

  const registerGAEventCpass = (cpass = null, additionalTags = {}) => {
    const gaEvents = {
      isErrorInitialize: 'Reintentar - PC - Servicio no disponible',
      isBlocked: 'Reintentar - PC - Bloqueada',
      isInvalid: 'Reintentar - PC - Operacion rechazada',
    };

    if (cpass) {
      Object.entries(gaEvents).forEach(([key, label]) => {
        if (cpass[key]) {
          registerGA({
            event: 'eve',
            'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
            'eve-cat': 'Click',
            'eve-lab': label,
            ...additionalTags,
          });
        }
      });
    } else {
      // Solo se registran las etiquetas adicionales
      registerGA({
        event: 'eve',
        ...additionalTags,
      });
    }
  };

  /** *********************************************
   *                  TCOO                       *
   ********************************************** */

  const tcooInitializeTimer = authorizationMethod.tarjetaCoordenadas.activeTimer;

  // Definicion de los desafios y observador cuando se actualiza el store con los desafios
  const desafio1 = useSelector((state) => state.entities?.factoresSeguridad?.desafio1);
  const desafio2 = useSelector((state) => state.entities?.factoresSeguridad?.desafio2);
  const desafio3 = useSelector((state) => state.entities?.factoresSeguridad?.desafio3);

  // codigos de error y mensajes se obtienen desde el store para tcoo

  const tcooErrorCode = useSelector(
    (state) => state.entities?.cuentasAhorro?.tarjetaCoordenadasErrors?.id
  );
  const tcooErrorMessage = useSelector(
    (state) => state.entities?.cuentasAhorro?.tarjetaCoordenadasErrors?.message
  );
  const tcooErrorTitle = useSelector(
    (state) => state.entities?.cuentasAhorro?.tarjetaCoordenadasErrors?.title
  );

  const tcooValidationSuccess = useSelector(
    (state) => state.entities?.cuentasAhorro?.factor?.validacionFactorDeSeguridad
  );

  const baseRut = useSelector((state) => state.entities?.persona?.rut);

  useEffect(() => {
    if (baseRut)
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CA-SOLICITUDPRODUCTO_FIRMA_DCTOS',
        informacion_adicional: {},
      });
  }, [baseRut]);

  const resetState = (isActive) => ({
    isActive,
    stepFactor: 0,
    withCountdownAnimation: false,
    activeTimer: false,
    errorCard: {
      show: false,
      title: '',
      message: '',
      showButton: false,
      buttonText: '',
    },
  });

  const switchActiveFactor = () => {
    setTimeValue(120);
    clearInterval(timeInterval.current);
    dispatch(cuentaAhorroActions.setCpassPolling(false));

    const newAuthorizationMethod = {
      ...authorizationMethod,
      passCoopeuch: {
        ...authorizationMethod.passCoopeuch,
        ...resetState(!isCpass),
      },
      tarjetaCoordenadas: {
        ...authorizationMethod.tarjetaCoordenadas,
        ...resetState(isCpass),
      },
    };

    setAuthorizationMethod(newAuthorizationMethod);
    let gaLabel = isCpass
      ? 'Quiero Autorizar con Tarjeta coordenadas - Passcoopeuch'
      : 'Quiero Autorizar con PassCoopeuch - TCOO';
    if (timeValue === 0) {
      gaLabel += ' - Tiempo Agotado';
    }
    if (isCpass && cpass.isInvalid) {
      gaLabel = 'Quiero Autorizar con Tarjeta coordenadas - PC - Operacion Rechazada';
    }
    if (isCpass && cpass.isBlocked) {
      gaLabel = 'Quiero Autorizar con Tarjeta coordenadas - PC - Bloqueada';
    }
    if (!isCpass && tcooErrorCode === 2) {
      gaLabel = 'Quiero Autorizar con PassCoopeuch - TCOO - Coordenadas Incorrectas';
    }

    if (!isCpass && tcooErrorCode === 3) {
      gaLabel = 'Quiero Autorizar con PassCoopeuch - TCOO - Servicio no disponible';
    }
    if ((!isCpass && tcooErrorCode === 4) || tcooErrorCode === 5) {
      gaLabel = 'Quiero Autorizar con PassCoopeuch - TCOO - Bloqueada';
    }
    if (isCpass && !hasCpass) {
      gaLabel = 'Quiero Autorizar con TCOO - Sin PassCoopeuch';
    }

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
      'eve-cat': 'Click',
      'eve-lab': gaLabel,
    });
  };

  const valorDesafio1Ref = useRef('');
  const valorDesafio2Ref = useRef('');
  const valorDesafio3Ref = useRef('');

  const resetRefs = () => {
    valorDesafio1Ref.current = '';
    valorDesafio2Ref.current = '';
    valorDesafio3Ref.current = '';
  };

  useEffect(() => {
    if (tcooValidationSuccess) {
      const track = strContratacionIniciar?.data.track;
      dispatch(
        cuentaAhorroActions.fetchContratacionGenerar({
          correo: mailSeleccion === 'socio' ? mailSocio : newMail,
          producto: currentCodeAccount?.[0]?.code,
          track,
          codEjecutivo: codeExec?.code,
          nombreEjecutivo: executiveData?.executiveName,
        })
      );
      resetRefs();
      resetState(true);
      dispatch(factoresSeguridadActions.resetFactoresSeguridad());
      registerGA({
        event: 'vpv-privado',
        page: '/tef/cuenta-ahorro/solicitud/comprobante',
        producto: accountName(currentCodeAccount?.[0]?.code),
        valoracion: 'Autorizacion con TCOO',
        preexistencias: executiveData?.executiveName ? 'Si' : 'No',
      });
    }
  }, [tcooValidationSuccess, mailSocio, newMail, currentCodeAccount]);

  useEffect(() => {
    const updateAuthorizationMethod = (errorCardConfig, gaLabel) => {
      setAuthorizationMethod({
        ...authorizationMethod,
        tarjetaCoordenadas: {
          ...authorizationMethod.tarjetaCoordenadas,
          errorCard: {
            show: true,
            title: tcooErrorTitle,
            message: tcooErrorMessage,
            ...errorCardConfig,
          },
        },
      });

      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Error',
        'eve-lab': gaLabel,
      });
    };

    switch (tcooErrorCode) {
      case 2:
        updateAuthorizationMethod(
          { showButton: true, buttonText: 'Reintentar' },
          'TCOO - Coordenadas incorrectas'
        );
        clearInterval(timeInterval.current);
        break;
      case 3:
      case 4:
      case 5:
        updateAuthorizationMethod({}, 'TCOO - Bloqueada');
        clearInterval(timeInterval.current);
        break;
      default:
        break;
    }
  }, [tcooErrorCode, tcooErrorMessage, tcooErrorTitle]);

  useEffect(() => {
    if (desafio1 && desafio2 && desafio3) {
      setAuthorizationMethod({
        ...authorizationMethod,
        tarjetaCoordenadas: {
          ...authorizationMethod.tarjetaCoordenadas,
          desafios: {
            1: desafio1,
            2: desafio2,
            3: desafio3,
          },
        },
      });
    }
  }, [desafio1, desafio2, desafio3]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;

    const inputMapping = {
      cor1: '1',
      cor2: '2',
      cor3: '3',
    };

    // Update the value of the corresponding "cor" in the state with the two digits entered by the user

    setAuthorizationMethod((prevState) => ({
      ...prevState,
      tarjetaCoordenadas: {
        ...prevState.tarjetaCoordenadas,
        valoresDesafios: {
          ...prevState.tarjetaCoordenadas.valoresDesafios,
          [inputMapping[id]]: value,
        },
      },
    }));

    // Update the corresponding ref
    if (id === 'cor1') valorDesafio1Ref.current = value;
    if (id === 'cor2') valorDesafio2Ref.current = value;
    if (id === 'cor3') valorDesafio3Ref.current = value;
  };
  const handleCoorsValidation = async (valorDesafio1, valorDesafio2, valorDesafio3) => {
    const track = strContratacionIniciar?.data.track;
    try {
      await dispatch(
        cuentaAhorroActions.validarDesafioTcoo({
          desafio: {
            valorDesafio1,
            valorDesafio2,
            valorDesafio3,
          },
          track: `${track}-71`,
        })
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      setAuthorizationMethod({
        ...authorizationMethod,
        tarjetaCoordenadas: {
          ...authorizationMethod.tarjetaCoordenadas,
          errorCard: {
            show: true,
            message: 'Ha ocurrido un error.',
            showButton: true,
            buttonText: 'Reintentar',
          },
        },
      });
    }
  };

  useEffect(() => {
    if (
      valorDesafio1Ref.current.length === 2 &&
      valorDesafio2Ref.current.length === 2 &&
      valorDesafio3Ref.current.length === 2
    ) {
      handleCoorsValidation(
        valorDesafio1Ref.current,
        valorDesafio2Ref.current,
        valorDesafio3Ref.current
      );
    }
  }, [valorDesafio1Ref.current, valorDesafio2Ref.current, valorDesafio3Ref.current]);

  const handleClickTcooButton = () => {
    const track = strContratacionIniciar?.data.track;
    dispatch(factoresSeguridadActions.fetchDesafioTarjetaCoordenadas({ track: `${track}-69` }));

    setAuthorizationMethod({
      ...authorizationMethod,
      tarjetaCoordenadas: {
        ...authorizationMethod.tarjetaCoordenadas,
        activeTimer: true,
        stepFactor: 1,
        withCountdownAnimation: true,
        showInputInError: true,
        message: `Ingresa las coordenadas indicadas en tu Tarjeta de Coordenadas N°${tcooCardNumber}`,
      },
    });
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar - TCOO',
    });
  };

  const setTcooActive = () => {
    setAuthorizationMethod({
      ...authorizationMethod,
      passCoopeuch: {
        ...authorizationMethod.passCoopeuch,
        isActive: false,
      },
      tarjetaCoordenadas: {
        ...authorizationMethod.tarjetaCoordenadas,
        stepFactor: 1,
        isActive: true,
      },
    });
  };

  // TIMER TCOO

  useEffect(() => {
    const startTimer = () => {
      return setInterval(() => {
        setTimeValue((prevTimer) => prevTimer - 1);
      }, 1000);
    };

    if (tcooInitializeTimer) {
      // eslint disable-next-line no-use-before-define
      setTimeValue(tcooTime);
      timeInterval.current = startTimer();
    }

    return () => {
      clearInterval(timeInterval.current);
    };
  }, [tcooInitializeTimer]);

  // observador tiempo agotado

  useEffect(() => {
    if (authorizationMethod.tarjetaCoordenadas.isActive && timeValue === 0) {
      setAuthorizationMethod((prevMethod) => ({
        ...prevMethod,
        tarjetaCoordenadas: {
          ...prevMethod.tarjetaCoordenadas,
          activeTimer: false,
          errorCard: {
            show: true,
            title: '¡Se agotó el tiempo!',
            message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
            showButton: true,
            buttonText: 'Reintentar',
          },
        },
      }));

      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'TCOO - Tiempo agotado',
      });

      setTimeOut({ ...timeOut, tcoo: true });
    }
    if (authorizationMethod.passCoopeuch.isActive && timeValue === 0) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PC - Tiempo agotado',
      });

      setTimeOut({ ...timeOut, cpass: true });
    }
  }, [
    timeValue,
    authorizationMethod.tarjetaCoordenadas.isActive,
    authorizationMethod.passCoopeuch.isActive,
  ]);

  /** *********************************************
   *                  CPASS                      *
   ********************************************** */

  const cpass = useSelector((state) => state.entities.cuentasAhorro.cpass);
  const isLoadingFactoresSeguridad = useSelector(
    (state) => state.ui.loaders.isLoadingFactoresSeguridad
  );
  const isLoadingReglasFactoresSeguridad = useSelector(
    (state) => state.ui.loaders.isLoadingReglasFactoresSeguridad
  );
  const { factoresSeguridad, reglasFactoresSeguridad } = useSelector(
    (state) => state.entities?.factoresSeguridad
  );
  const [hasCpass, hasTcoo, tcooCardNumber] = useMemo(() => {
    if (factoresSeguridad?.length === 0) {
      return [];
    }
    const _cpass = factoresSeguridad?.find((f) => f?.codigo === 2.2);
    const _tcoo = factoresSeguridad?.find((f) => f?.codigo === 2.1);
    return [!cpass.isBlocked && Boolean(_cpass), Boolean(_tcoo), _tcoo?.payload];
  }, [factoresSeguridad]);
  const [cpassTime, tcooTime] = useMemo(() => {
    if (reglasFactoresSeguridad?.length === 0) {
      return [];
    }
    const _cpassTime = reglasFactoresSeguridad?.find((r) => r?.codigo === 'CPASS_TIME');
    const _tcooTime = reglasFactoresSeguridad?.find((r) => r?.codigo === 'TCOO_TIME');
    return [parseInt(_cpassTime?.valor, 10) || 120, parseInt(_tcooTime?.valor, 10) || 120];
  }, [reglasFactoresSeguridad]);
  const isCpass = authorizationMethod.passCoopeuch.isActive;
  const isLoadingGetFactor = isLoadingFactoresSeguridad || isLoadingReglasFactoresSeguridad;
  const isLoadingAuthorizationInitialize = cpass.isFetchingInitialize;

  const accountName = (account) => {
    switch (account) {
      case 'AR06':
        return 'Ahorro Adulto Reajustable';
      case 'AN07':
        return 'Ahorro Adulto Nominal';
      case 'AR08':
        return 'Ahorro Vivienda';
      default:
        return 'default name';
    }
  };

  const setCpassActive = () => {
    setAuthorizationMethod({
      ...authorizationMethod,
      passCoopeuch: {
        ...authorizationMethod.passCoopeuch,
        isActive: true,
      },
    });
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar con PassCoopeuch - PassCoopeuch',
    });
  };

  useEffect(() => {
    // dispatch(cuentaAhorroActions.getFactors());
    dispatch(factoresSeguridadActions.fetchFactoresSeguridad());
    dispatch(factoresSeguridadActions.fetchReglasFactoresSeguridad());
    return () => {
      dispatch(cuentaAhorroActions.resetAuthorization());
    };
  }, []);

  const handleClickPassCoopeuch = () => {
    dispatch(
      cuentaAhorroActions.initializeCpass({
        accountName: accountName(currentCodeAccount[0]?.code),
      })
    );
  };

  const handleClickErrorButton = () => {
    dispatch(cuentaAhorroActions.resetAuthorization());
    if (isCpass) {
      setAuthorizationMethod({
        ...authorizationMethod,
        passCoopeuch: {
          ...authorizationMethod.passCoopeuch,
          ...resetState(true),
        },
      });

      registerGAEventCpass(cpass);

      if (timeOut.cpass) {
        const aditionalTags = {
          'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
          'eve-cat': 'Click',
          'eve-lab': 'Reintentar - PC - Tiempo agotado',
        };
        registerGAEventCpass(null, aditionalTags);
      }
    } else {
      dispatch(factoresSeguridadActions.resetFactoresSeguridad());
      resetRefs();
      setAuthorizationMethod({
        ...authorizationMethod,
        tarjetaCoordenadas: {
          ...authorizationMethod.tarjetaCoordenadas,
          ...resetState(true),
          valoresDesafios: {
            1: '',
            2: '',
            3: '',
          },
        },
      });
      if (timeOut.tcoo) {
        const aditionalTags = {
          'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
          'eve-cat': 'Click',
          'eve-lab': 'Reintentar - TCOO - Tiempo agotado',
        };
        registerGAEventCpass(null, aditionalTags);
      }

      // aca van las condiciones de error de tcoo

      if (tcooErrorCode === 2) {
        const aditionalTags = {
          'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
          'eve-cat': 'Click',
          'eve-lab': 'Reintentar - TCOO - Coordenadas incorrectas',
        };
        registerGAEventCpass(null, aditionalTags);
      }
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (cpass.isSuccessInitialize) {
      setTimeValue(cpassTime);
      setAuthorizationMethod({
        ...authorizationMethod,
        passCoopeuch: {
          ...authorizationMethod.passCoopeuch,
          stepFactor: 1,
          withCountdownAnimation: true,
          activeTimer: true,
        },
      });
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Autorizar con PassCoopeuch - Passcoopeuch',
      });

      dispatch(cuentaAhorroActions.setCpassPolling(true));

      timeInterval.current = setInterval(() => {
        setTimeValue((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(timeInterval.current);
            setAuthorizationMethod({
              ...authorizationMethod,
              passCoopeuch: {
                ...authorizationMethod.passCoopeuch,
                activeTimer: false,
                errorCard: {
                  show: true,
                  title: '¡Se agotó el tiempo!',
                  message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
                  showButton: true,
                  buttonText: 'Reintentar',
                },
              },
            });

            dispatch(cuentaAhorroActions.setCpassPolling(false));
            return 0;
          }

          return prevTimer - 1;
        });
      }, 1000);

      dispatch(
        cuentaAhorroActions.validateCpass({
          accountName: accountName(currentCodeAccount[0]?.code),
        })
      );
      return () => {
        clearInterval(timeInterval.current);
      };
    }
    if (cpass.isErrorInitialize) {
      setAuthorizationMethod({
        ...authorizationMethod,
        passCoopeuch: {
          ...authorizationMethod.passCoopeuch,
          activeTimer: false,
          errorCard: {
            show: true,
            title: '¡Lo sentimos!',
            message: 'El servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
            showButton: true,
            buttonText: 'Reintentar',
          },
        },
      });
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PC - Servicio no disponible',
      });
    }
  }, [cpass.isSuccessInitialize, cpass.isErrorInitialize]);

  useEffect(() => {
    if (cpass.isInvalid) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PC - Operación rechazada',
      });

      clearInterval(timeInterval.current);
      setAuthorizationMethod({
        ...authorizationMethod,
        passCoopeuch: {
          ...authorizationMethod.passCoopeuch,
          activeTimer: false,
          errorCard: {
            show: true,
            title: '¡Operación rechazada!',
            message: 'Has rechazado la autorización. Si quieres, puedes intentarlo nuevamente.',
            showButton: true,
            buttonText: 'Reintentar',
          },
        },
      });
    } else if (cpass.isBlocked) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuenta-ahorro/solicitud/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PC - Bloqueada',
      });

      clearInterval(timeInterval.current);
      setAuthorizationMethod({
        ...authorizationMethod,
        passCoopeuch: {
          ...authorizationMethod.passCoopeuch,
          activeTimer: false,
          errorCard: {
            show: true,
            title: 'Has superado el número de intentos',
            message: 'PassCoopeuch se ha bloqueado por seguridad. Regístrate nuevamente en la app.',
            showButton: true,
            buttonText: 'Reintentar',
          },
        },
      });
    } else if (cpass.isErrorValidate) {
      clearInterval(timeInterval.current);
      setAuthorizationMethod({
        ...authorizationMethod,
        passCoopeuch: {
          ...authorizationMethod.passCoopeuch,
          activeTimer: false,
          errorCard: {
            show: true,
            title: '¡Lo sentimos!',
            message: 'El servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
            showButton: true,
            buttonText: 'Reintentar',
          },
        },
      });
    } else if (cpass.isSuccessValidate) {
      const track = strContratacionIniciar?.data.track;
      dispatch(
        cuentaAhorroActions.fetchContratacionGenerar({
          correo: mailSeleccion === 'socio' ? mailSocio : newMail,
          producto: currentCodeAccount[0]?.code,
          codEjecutivo: codeExec?.code,
          nombreEjecutivo: executiveData?.executiveName,
          track,
        })
      );
      registerGA({
        event: 'vpv-privado',
        page: '/tef/cuenta-ahorro/solicitud/comprobante',
        producto: accountName(currentCodeAccount?.[0]?.code),
        valorizacion: 'Autorizacion con PassCoopeuch',
        preexistencias: executiveData?.executiveName ? 'Si' : 'No',
      });
    }
  }, [cpass.isSuccessValidate, cpass.isInvalid, cpass.isBlocked, cpass.isErrorValidate]);

  useEffect(() => {
    filterCodeByAccountSelect(cuentaSeleccionada.descripcion);
    dispatch(cuentaAhorroActions.fetchDatosPersonales());
    const track = strContratacionIniciar?.data.track;
    dispatch(
      cuentaAhorroActions.fetchGetContratosByCuenta({
        track,
      })
    );
    return () => {
      setShowScreenError(false);
    };
  }, []);

  useEffect(() => {
    if (strDatosPersonales.success) {
      if (strDatosPersonales?.data?.correo.length > 4) {
        setMailSeleccion('socio');
        setMailSocio(strDatosPersonales?.data?.correo);
      } else {
        setMailSeleccion('otro');
        setMailSocio('');
      }
    }
  }, [strDatosPersonales]);

  // Set para visualizar los contratos en el template
  useEffect(() => {
    if (strDocumentsPDF.data) {
      setContratosPDF(strDocumentsPDF.data);
    }
    if (strDocumentsPDF.error) {
      setShowScreenError(true);
    }
  }, [strDocumentsPDF]);

  // Observador validador mail
  useEffect(() => {
    if (mailSeleccion === 'socio') {
      if (mailSocio.length > 4) {
        setValidMail(true);
      } else {
        setValidMail(false);
      }
    }

    if (mailSeleccion === 'otro') {
      if (newMail.length > 4 && isValidEmail(newMail)) {
        setValidMail(true);
      } else {
        setValidMail(false);
      }
    }
  }, [mailSeleccion, newMail, mailSocio]);

  // Observador desbloqueo boton Next
  useEffect(() => {
    if (
      currentCodeAccount &&
      currentCodeAccount[0]?.code &&
      // password.length > 5 &&
      isCheckedCheckBox &&
      validMail === true
    ) {
      setButtonLock(false);
    } else {
      setButtonLock(true);
    }
  }, [currentCodeAccount, isCheckedCheckBox, validMail]);

  // Observador resultado envio
  useEffect(() => {
    if (strContratacionGenerar.success && !isLoadingContratGenerada) {
      if (Object.keys(strContratacionGenerar.data).length) {
        handleStep(3);
      }
    }
    if (strContratacionGenerar.error) {
      // setErrorCode(strContratacionGenerar?.errorCode?.code?.slice(1));
      setButtonLock(true);
      // eslint-disable-next-line no-console
      console.log('Error en contratacion');
    }
  }, [strContratacionGenerar, isLoadingContratGenerada]);

  // Observador tamaño de pantalla
  const wideScreen = useWindowSize();
  const sizeTablet = parseInt(size.tablet.slice(0, -2), 10);

  useEffect(() => {
    if (wideScreen < sizeTablet) {
      setIschangeWidth(true);
    } else {
      setIschangeWidth(false);
    }
  }, [wideScreen, sizeTablet]);

  useEffect(() => {
    // marca analitycs paso 2
    registerGA({
      event: 'vpv-privado',
      page: '/tef/cuenta-ahorro/solicitud/paso2',
    });
  }, []);

  return (
    <>
      <CuentaTemplate
        {...{
          ischangeWidth,
          contratosPDF,
          showScreenError,
          downloadDocumentPDF,
          isCheckedCheckBox,
          setIsCheckedCheckBox,
          isLoadingDPersonales,
          mailSocio,
          setMailSocio,
          onChangeEmail,
          mailSeleccion,
          setMailSeleccion,
          newMail,
          setNewMail,
          handleClickReturn,
          handleClickContinue,
          isLoadingContratGenerada,
          codeExec,
          setCodeExec,
          executiveData,
          onChangeCodeExec,
          handleOnClickCodeExec,

          // authorization
          cpassTime,
          tcooTime,
          hasCpass,
          hasTcoo,
          tcooCardNumber,
          authorizationMethod,
          isLoadingGetFactor,
          isLoadingAuthorizationInitialize,
          handleClickPassCoopeuch,
          handleClickErrorButton,
          timeValue,
          setCpassActive,
          setTcooActive,
          switchActiveFactor,
          handleClickTcooButton,
          handleInputChange,
          buttonLock,
        }}
      />
    </>
  );
};

Contratacion.propTypes = {
  cuentaSeleccionada: PropTypes.string,
  handleClickReturnSolicitudCA: PropTypes.func,
  handleStep: PropTypes.func,
  handleMail: PropTypes.func.isRequired,
  handleCleanCuenta: PropTypes.func.isRequired,
};

Contratacion.defaultProps = {
  cuentaSeleccionada: '',
  handleClickReturnSolicitudCA: null,
  handleStep: null,
  // handleMail: null,
};

Contratacion.displayName = 'Contratacion_Container';

export default Contratacion;
