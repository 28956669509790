import styled from 'styled-components';

export const GlobalStyles = styled.div`
  .boldCredito {
    font-weight: bold;
  }
  .adjust_alert {
    height: 120px;
    padding-top: 20px;
    @media (max-width: 640px) {
      height: auto;
      padding-top: 10px;
    }
  }
`;
export const BadgeWrapper = styled.div`
  font-weight: bold;
  display: inline;
  margin-left: 6px;
  > * {
    padding: 2px 6px;
  }
`;
export const ExpandWrapper = styled.div`
  .bullet-red {
    &::before {
      font-size: 24px;
      content: '•';
      color: #e81e2b;
      position: absolute;
    }
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 25px;
  .btn_red {
    width: 240px;
  }
`;

export const ContainerTipoCredito = styled.div`
  .border {
    border: 1px solid gray;
    border-radius: 4px;
    padding: 0px 10px;
    height: 48px;
  }
`;

export const AdjustAlert = styled.div`
  height: 120px;
  padding-top: 20px;
  @media (min-width: 640px) {
    height: 0px;
    padding-top: 0px;
  }
`;
