import React from 'react';
import PropTypes from 'prop-types';

// Ui
import Button from '@coopeuch-components/web/atoms/Button';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Typography from '@coopeuch-components/web/atoms/Typography';
import DashboardCard from '../../../../organism/DashboardCard';

// Utils
import { months, monthsExtend } from '../../../../../utils/dates';

// Styles
import Wrapper, { Globe } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  loading,
  dataContratado,
  mailSeleccionado,
  handleStep,
  handleClickReturn,
  ischangeWidth,
}) => {
  // Formateo de fecha para este componente en específico como parte de una corrección.
  // Anteriormente se usaba las funciones globales dayMonthYear y dayMonthYearExtend
  // que agregan un día extra a la fecha, lo cual es incorrecto en este caso de uso.
  const formatDate = (date, fullMonth = false) => {
    let newDate = date?.toString();
    newDate = new Date(date);

    const day = newDate.getDate();
    const month = fullMonth ? monthsExtend[newDate.getMonth()] : months[newDate.getMonth()];
    const year = newDate.getFullYear();

    return `${day} de ${month} ${year}`;
  };

  return (
    <Wrapper>
      {loading ? (
        'Cargando...'
      ) : (
        <>
          <Alert
            className="alert-deposito-contratacion-monedero "
            bgType="success"
            id="alert-felicitaciones-contratacion-monedero"
            show
          >
            <p>
              <b>¡Felicitaciones!.</b> La Cuenta de Ahorro {dataContratado?.producto?.descripcion}{' '}
              se ha contratado con éxito.
            </p>
          </Alert>

          <DashboardCard className="bmt-xs-3 bmt-sm-4 ml-0 mr-0">
            <DashboardCard.Head title="Comprobante" borderBottom="complete" />

            <DashboardCard.Body>
              <div className="row">
                <div className="col-xs-12 col-sm-10">
                  <Typography
                    tag="p"
                    fontSize={14}
                    fontWeight={700}
                    fontFamily="ubuntu-bold"
                    className="bmt-3"
                  >
                    Cuenta de Ahorro
                  </Typography>
                  <Typography tag="h4" fontSize={24} fontWeight={700} fontFamily="ubuntu-bold">
                    Nº
                    {dataContratado?.numeroCuenta}
                  </Typography>
                  <Typography tag="p" fontSize={13}>
                    Contrataste una Cuenta de Ahorro {dataContratado?.producto?.descripcion} con
                    fecha {formatDate(dataContratado?.fechaApertura, true)}. Enviaremos el
                    comprobante y
                    <br />
                    contratos a tu mail:
                    <b> {mailSeleccionado}</b>
                  </Typography>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <img
                    src={`${staticPath}sello-comprobante.png`}
                    alt="sello-comprobante"
                    className="sello"
                  />
                </div>
              </div>
            </DashboardCard.Body>
          </DashboardCard>

          <DashboardCard className="bmt-xs-3 bmt-sm-4 ml-0 mr-0">
            <DashboardCard.Head title="Detalle de Contratación" borderBottom="complete" />
            <DashboardCard.Body classes="" padding="26px">
              <div className="row card_impar">
                <div className="col bp-3 text-left">Producto Contratado</div>
                <div className="col bp-3 text-left">
                  Cuenta de Ahorro {dataContratado?.producto?.descripcion}
                </div>
              </div>
              <div className="row">
                <div className="col bp-3 text-left">N° de Cuenta de Ahorro</div>
                <div className="col bp-3 px-md-5 text-left">{dataContratado?.numeroCuenta}</div>
              </div>
              <div className="row card_impar">
                <div className="col bp-3 text-left">Fecha de Contratación</div>

                <div className="col bp-3 text-left">
                  {formatDate(dataContratado?.fechaApertura)}
                </div>
              </div>
              <div className="row">
                <div className="col bp-3 text-left">Estado</div>
                <div className="col bp-3 text-left">
                  {dataContratado?.estado?.codigo === 'I'
                    ? 'Por Activar'
                    : dataContratado?.estado?.descripcion}
                </div>
              </div>
            </DashboardCard.Body>
          </DashboardCard>
          <Globe className="bmt-xs-3 bmt-sm-4 ml-0 mr-0">
            <div className="p-14">
              <div className="container">
                <div>
                  <img
                    src={`${ICON_PATH}ejecutivo.svg`}
                    alt="sello-comprobante"
                    className="image"
                  />
                </div>
                <div className="container_text">
                  <Typography
                    tag="p"
                    fontSize={16}
                    fontWeight={700}
                    fontFamily="ubuntu-bold"
                    className="bmt-3"
                    align="left"
                    color="#262D60"
                  >
                    Estimado socio:
                  </Typography>

                  <Typography tag="p" fontSize={16} align="left" color="#262D60" className="mt-12">
                    No dejes para mañana lo que puedes hacer hoy. Te recordamos <b>activar</b> tu
                    nueva Cuenta de Ahorro.
                  </Typography>
                </div>
              </div>
            </div>
          </Globe>

          <DashboardCard className="bmt-xs-3 bmt-sm-4 ml-0 mr-0 mb-24">
            <DashboardCard.Head
              title="¿Cómo y cuando activar tu nueva cuenta de ahorro?"
              borderBottom="complete"
            />
            <DashboardCard.Body classes="" padding="26px">
              <div className="bmb-2">
                <ul>
                  <li className="bullet-red expand-list">
                    <p className="list-desc">
                      Realiza ahora tu abono inicial desde <b>$1.000</b>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="bmb-2">
                <ul>
                  <li className="bullet-red expand-list">
                    <p className="list-desc">
                      Puedes transferir a tu Cuenta de Ahorro desde <b>Cuenta Coopeuch</b> u otro
                      medio de pago
                    </p>
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li className="bullet-red expand-list">
                    <p className="list-desc">
                      Tienes 90 días para abonar y activar tu Cuenta de Ahorro, de lo contrario será
                      eliminada.
                    </p>
                  </li>
                </ul>
              </div>
            </DashboardCard.Body>
          </DashboardCard>

          <div className="bmt-4">
            <div className="row">
              <div className="col-md-6 display-desktop" />
              <div className="col-md-6 col-xs-12 btext-right">
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  onClick={() => handleClickReturn()}
                  className="button-continue"
                  fullWidth={ischangeWidth}
                >
                  Ir a Cuenta de Ahorro
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Wrapper>
  );
};

Index.propTypes = {
  loading: PropTypes.bool,
  mailSeleccionado: PropTypes.string,
  handleStep: PropTypes.func,
  handleClickReturn: PropTypes.func,
  ischangeWidth: PropTypes.bool,
};

Index.defaultProps = {
  loading: true,
  mailSeleccionado: '',
  handleStep: null,
  handleClickReturn: null,
  ischangeWidth: false,
};

Index.displayName = 'Comprobante_Template';
export default Index;
