import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { Button, Grid, Message } from '@design-system-coopeuch/web';
import React, { useEffect, useRef, useState } from 'react';
import ButtonLoading from '../../../../atoms/ButtonLoading';
// Utils
import { validateExtensionFiles } from '../../../../../utils/validators';
// Styles
import Wrapper, { StyledAlertModal } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const imgPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const LoadDocument = ({
  dataBono,
  handleUploadFileToServer,
  tipoDocumento,
  backgroundColor,
  text,
  loadFile,
  setbtnContinueDisabled,
}) => {
  let typeFile = '';
  const [errorMessage, setErrorMessage] = useState('');
  const [retryLoad, setRetryLoad] = useState(false);
  const [successIcon, setSuccessIcon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const inputRef = useRef();

  const handleClickUploadFile = () => {
    const isId = /CEDULA_IDENTIDAD/.test(tipoDocumento);
    if (isId && dataBono.documentos.isRejected) {
      setShowHelpModal(true);
    } else {
      inputRef.current.click();
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    const base64 = readerEvt.target.result;
    const result = window.btoa(base64);
    handleUploadFileToServer({
      mimeType: typeFile,
      tipoDocumento,
      contenido: result,
    });
  };

  const onChangeInputFile = (file) => {
    const fileUpload = file;
    setSuccessIcon(false);
    if (fileUpload) {
      if (validateExtensionFiles(fileUpload) === true) {
        if (fileUpload.size <= 1_000_000) {
          setLoading(true);
          setErrorMessage('');
          setRetryLoad(true);
          if (fileUpload) {
            typeFile = fileUpload.type;
            const reader = new FileReader();
            reader.onload = _handleReaderLoaded;
            reader.readAsBinaryString(fileUpload);
          }
        } else {
          fileUpload.size > 1_000_000 &&
            setErrorMessage('El tamaño del archivo excedió el límite (1 MB).');
          setSuccessIcon(false);
          setLoading(false);
          setbtnContinueDisabled(true);
        }
      } else {
        setErrorMessage('Formato no permitido.');
        setSuccessIcon(false);
        setLoading(false);
        setbtnContinueDisabled(true);
      }
    }
  };

  const onCloseHelpModal = () => {
    setShowHelpModal(false);
    inputRef.current.click();
  };

  useEffect(() => {
    if (dataBono?.documentos[tipoDocumento] !== '') {
      setLoading(false);
      setSuccessIcon(true);
    } else {
      setSuccessIcon(false);
    }
  }, [dataBono]);

  return (
    <Wrapper backgroundColor={backgroundColor}>
      <div className="boxFile">
        <div className="boxFile__iconStatus">
          <LoadIcon
            src={`${iconPath}${successIcon === true ? 'check_success.svg' : 'cloud_upload.svg'}`}
            size={32}
            color="#D4D4D4"
          />
        </div>
        <div className="boxFile__info">
          <p className="boxFile__info-text">
            {retryLoad === false && 'Adjunta documento de'}
            <strong> {text}</strong>
          </p>
          <div className="boxFile__info-actions">
            {loading ? (
              <ButtonLoading icon="loading_upload" text="Adjuntando" />
            ) : (
              <>
                <label
                  className={`boxFile__info-actions-text ${
                    loadFile === true && 'boxFile__info-actions-load'
                  }`}
                  htmlFor="adjuntar"
                  onClick={handleClickUploadFile}
                >
                  <LoadIcon
                    src={`${iconPath}${retryLoad === true ? 'refresh.svg' : 'upload.svg'}`}
                    size={24}
                    color={loadFile ? '#D4D4D4' : '#f42534'}
                    className="boxFile__info-actions-icon"
                  />
                  {retryLoad === true ? 'Volver adjuntar' : 'Adjuntar'}
                </label>
                <input
                  ref={inputRef}
                  className={`boxFile__info-actions-input inputFile${tipoDocumento}`}
                  type="file"
                  name="adjuntar"
                  accept=".doc,.docx,application/msword,application, application/pdf, image/*"
                  onChange={(file) => onChangeInputFile(file.target.files[0])}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {errorMessage !== '' && (
        <div className="boxAlert">
          <Message description={<p className="errorMessage">{errorMessage}</p>} type="error" />
        </div>
      )}
      {showHelpModal && (
        <StyledAlertModal
          showModal
          width={600}
          title="¿Cómo adjuntar mi cédula de identidad?"
          onClose={onCloseHelpModal}
        >
          <div className="mx-3 mx-lg-4">
            <Grid row className="mt-20">
              <Grid col={12} lg={6} className="mt-20">
                <img src={`${imgPath}carnet_antiguo_ilu.jpg`} alt="Cédula de identidad antigua" />
              </Grid>
              <Grid col={12} lg={6} className="mt-20">
                <img src={`${imgPath}carnet_nuevo_ilu.jpg`} alt="Cédula de identidad nueva" />
              </Grid>
            </Grid>
            <p className="my-4 mx-auto text-center">
              El documento que vas a adjuntar,{' '}
              <b>debe contener ambas caras de tu cédula de identidad</b> y recuerda que es muy
              importante que los datos sean legibles.
            </p>
          </div>
          <div className="px-4 border-top">
            <Button
              variant="link"
              color="secondary"
              className="mt-20 mx-lg-auto"
              onClick={onCloseHelpModal}
            >
              Entendido
            </Button>
          </div>
        </StyledAlertModal>
      )}
    </Wrapper>
  );
};

export default LoadDocument;
