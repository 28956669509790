import React from 'react';

import Wrapper from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const SpinnerLoader = () => (
  <Wrapper>
    <img src={`${staticPath}spinner.gif`} alt="Spinner" />
  </Wrapper>
);

export default SpinnerLoader;
