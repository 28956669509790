import styled from 'styled-components';

export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }
  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;

export const FalseInput = styled.div`
  padding: 8px 12px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  border: 1px solid #646569;
  border-radius: 2px;
  line-height: 1.1em;
  line-height: 30px;
`;

const Message = styled.div`
  background-color: #ccf1c1;
  padding: 11px 4px;
  width: 100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #006b14;

    path {
      fill: #006b14;
    }
  }
  .align {
    display: flex;
    align-items: center;
  }
`;

export default Message;
