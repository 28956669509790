import staticMenu from './static-menu';

const initState = {
  header: {},
  footer: {
    elementos: [],
  },
  menu: [],
  formatted: {
    menu: staticMenu,
    footer: {},
  },
  fullScreen: false
};

export default initState;
