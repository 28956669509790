import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  .dashboard-margin-target {
    margin-left: -0.75rem !important;
    margin-right: -0.75rem !important;
   
  }
  .products{
    border: 3px solid #007DB7; 
    border-radius: 4px; 
    padding-top: 12px;
    padding-left: 0px; 
    padding-right: 0px;
    padding-bottom: 0px; 
    height: 208px;
  }
  .__floater__open .__floater__body .__floater__arrow{

    left: 6px !important;
    
   
    
    }
    
    .__floater__open .__floater__body .__floater__arrow span{
    
    top: 155px !important;
    
    height: 10px;
    
    width: 10px;
    
    }
    
  @media screen and (max-width: 1028px) {
    .dashboard-margin-target {
      margin-left: -0.5rem !important;
      margin-right: -0.5rem !important;
    }
    .products{
      
      height: 46.5%;
    }
  }
`;

export const TooltipBody = styled.div`
   background: white;
   height:200px;
`;
 


