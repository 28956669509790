import React from 'react';
import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';

import ContratacionBloqueoClave from '../ContratacionBloqueoClave';
import PasswordInput from '../../atoms/PasswordInput';
import Error from '../../atoms/Error';

import { Paragraph } from '../../../assets/styles/styled_components';

function ContratacionFirmaElectronica(props) {
  const {
    serviceError,
    password,
    showPassword,
    passwordError,
    handleTogglePassword,
    handlePasswordInput,
    handleWrongPassword,
    serverPasswordError,
    wrongPassword,
    textFirmaElectronica, // aviso de lo que se estara firmando con el ingreso de la clave electronica
  } = props;

  let content = (
    <>
      <Paragraph className="mb-16">
      {textFirmaElectronica}
      </Paragraph>
      <div className="row">
        <div className="col-xs-12 col-md-5">
          <PasswordInput
            {...{
              password,
              showPassword,
              passwordError,
              onTogglePassword: handleTogglePassword,
              onHandlePasswordInput: handlePasswordInput,
            }}
          />
        </div>
      </div>
    </>
  );
  if (wrongPassword) {
    content = (
      <ContratacionBloqueoClave
        serverPasswordError={serverPasswordError}
        onClick={handleWrongPassword}
      />
    );
  }
  return (
    <>
     <CardBox className="bmb-3 bmb-md-4"></CardBox>
      <CardBox.Header>Firma electrónica</CardBox.Header>
      <CardBox.Body>
        {serviceError ? <Error className="error-service-contratacion-cv" /> : content}
      </CardBox.Body>
    </>
  );
}

ContratacionFirmaElectronica.propTypes = {
  serviceError: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  showPassword: PropTypes.bool.isRequired,
  passwordError: PropTypes.string.isRequired,
  textFirmaElectronica: PropTypes.string.isRequired,
  handleTogglePassword: PropTypes.func.isRequired,
  handlePasswordInput: PropTypes.func.isRequired,
  handleWrongPassword: PropTypes.func.isRequired,
  serverPasswordError: PropTypes.oneOf([PropTypes.object]),
  wrongPassword: PropTypes.bool,
};

ContratacionFirmaElectronica.defaultProps = {
  serverPasswordError: {
    title: '',
    message: '',
    buttonTitle: '',
  },
  wrongPassword: false,
};

export default ContratacionFirmaElectronica;
