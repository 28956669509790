import { es } from 'date-fns/locale';
import { format } from 'date-fns';

// Retorna "null" ante cualquier error al momento de formatear
export default function dateFormatter({
  date,
  splitter,
  yearPosition,
  monthPosition,
  dayPosition,
  outputFormat = 'dd MMM yyyy',
}) {
  try {
    const dateArray = date.split(splitter);

    const y = dateArray[yearPosition];
    const m = dateArray[monthPosition] - 1;
    const d = dateArray[dayPosition];

    return format(new Date(y, m, d), outputFormat, { locale: es });
  } catch (_) {
    return null;
  }
}
