import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import { transferenciasActions } from '../../../application/actions/transferencias';

import PageTitle from '../../components/atoms/PageTitle';

import StepperRouter from './StepperRouter';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const AbonoAcreenciasRemanente = (props) => {

  const [banco, setBanco] = useState();
  const [tipoCuenta, setTipoCuenta] = useState();
  const [numeroCuenta, setNumeroCuenta] = useState();
  const [email, setEmail] = useState('');
  const [emailComprobante, setEmailComprobante] = useState('');
  

  const dispatch = useDispatch();

  const persona = useSelector((store) => store.entities.persona);
  const errorDatosPersonales = useSelector((store) => store.entities.errorDatosPersonales);
  const bancos = useSelector((store) => store.entities.transferencias.bancos);
  const Errorbancos = useSelector((store) => store.entities.transferencias.errorBancos);
  const tipoCuentas = useSelector((store) => store.entities.transferencias.tipoCuentas);
  const ErrorTiposCuentas = useSelector((store) => store.entities.transferencias.errorTipoCuentas);
  const correoPorDefecto = useSelector((store) => store.entities.persona.correo);

  useEffect(() => {
    dispatch(transferenciasActions.fetchBancos());
    dispatch(transferenciasActions.fetchTipoCuentas());
  }, []);

  useEffect(() => {
    if (correoPorDefecto) setEmail(correoPorDefecto);
  }, [correoPorDefecto]);

  return (
    <>
      <div className="hide-on-mobile">
        <PageTitle
          title="Abono de acreencias y remanente"
          icon={<LoadIcon src={`${iconPath}dollar_coin.svg`} color="black" />}
        />
      </div>
      <div className="hide-on-desktop">
        <PageTitle
          title="Abono de acreencias y remanente"
          icon={<LoadIcon src={`${iconPath}dollar_coin.svg`} color="black" />}
        />
      </div>
      <div className="w-100 margin-after-title text-left">
        <StepperRouter
          {...props}
          persona={persona}
          errorDatosPersonales={errorDatosPersonales}
          bancos={bancos}
          Errorbancos={Errorbancos}
          tipoCuentas={tipoCuentas}
          ErrorTiposCuentas={ErrorTiposCuentas}
          banco={banco}
          setBanco={setBanco}
          tipoCuenta={tipoCuenta}
          setTipoCuenta={setTipoCuenta}
          numeroCuenta={numeroCuenta}
          setNumeroCuenta={setNumeroCuenta}
          email={email}
          setEmail={setEmail}
          setEmailComprobante={setEmailComprobante}
          emailComprobante={emailComprobante}
        />
      </div>
    </>
  );
};

export default AbonoAcreenciasRemanente;
