import React from 'react';
import PropTypes from 'prop-types';

import Input from '@coopeuch-components/web/atoms/Input';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Wrapper, { Suggestions, SuggestionItem, IconWrapper, BorderLabel } from './styles';

import { capitalize } from '../../../utils/functions';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SearchBar = ({
  suggestions,
  onChange,
  onSubmit,
  width,
  onRemove,
  query,
  onClickItem,
  id,
  inputId,
  className,
  placeholder,
  rounded,
}) => {
  const onSubmitHandle = (event) => {
    event.preventDefault();

    onSubmit(event);
  };

  return (
    <Wrapper
      onSubmit={onSubmitHandle}
      autoComplete="off"
      width={width}
      id={id}
      className={className}
    >
      <BorderLabel rounded={rounded}>
        <IconWrapper>
          <LoadIcon src={`${iconPath}search_alt.svg`} size={16} color="black" />
        </IconWrapper>
        <Input
          borderColor="transparent"
          className="pl-8 pr-8"
          size={48}
          name="query"
          rounded={3}
          id={inputId}
          placeholder={placeholder}
          value={query}
          onChange={onChange}
          // colorText='green'
        />
        {query.length >= 1 && (
          <IconWrapper onClick={onRemove}>
            <LoadIcon src={`${iconPath}circle_close.svg`} size={16} color="black" />
          </IconWrapper>
        )}
      </BorderLabel>

      {suggestions.length >= 1 && (
        <Suggestions>
          {suggestions.map((item) => (
            <SuggestionItem key={item.id} onClick={onClickItem} data-text={item.text}>
              {capitalize(item.text)}
            </SuggestionItem>
          ))}
        </Suggestions>
      )}
    </Wrapper>
  );
};

SearchBar.propTypes = {
  suggestions: PropTypes.array,
  query: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickItem: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  inputId: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rounded: PropTypes.string,
};

SearchBar.defaultProps = {
  suggestions: [],
  query: '',
  width: 0,
  onChange() {},
  onRemove: null,
  onSubmit: null,
  onClickItem: null,
  onBlur: null,
  inputId: 'query_search',
  className: '',
  placeholder: '¿Qué estás buscando?',
  rounded: null,
};

export default SearchBar;
