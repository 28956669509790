/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';

import Skeleton from 'react-loading-skeleton';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Expand from '@coopeuch-components/web/molecules/Expand';

import Sincuota from './SinCuota';
import ConCuota from './ConCuota';
import HipotecarioLoading from '../HipotecarioLoading';
import InfoAlert from './InfoAlert';

import { ExpandWrapper, GlobalStyles } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const index = ({
  skeleton,
  creditos,
  ventanaHoraria,
  creditoSeleccionado,
  loadingCreditoSeleccionado,
  handleChangeSelect,
  handleClickPago,
  handleClickPagoOnline,
  referToOnlinePayment,
  paymentAllowed,
  automaticPaymentMethod,
}) => {
  let payment = <HipotecarioLoading />;
  if (paymentAllowed || automaticPaymentMethod) {
    payment = (
      <ConCuota
        {...{
          skeleton,
          creditos,
          ventanaHoraria,
          creditoSeleccionado,
          loadingCreditoSeleccionado,
          handleChangeSelect,
          handleClickPago,
          handleClickPagoOnline,
          referToOnlinePayment,
        }}
        showButton={!automaticPaymentMethod}
      />
    );
  } else {
    payment = (
      <Sincuota
        {...{
          skeleton,
          creditos,
          creditoSeleccionado,
          loadingCreditoSeleccionado,
          handleChangeSelect,
        }}
      />
    );
  }

  return (
    <GlobalStyles>
      {paymentAllowed && referToOnlinePayment && (
        <InfoAlert>
          Te invitamos a <b className="boldCredito">pagar tu crédito por medio de pago online </b>
          de forma rápida y segura a <b className="boldCredito">través de Webpay</b>.
        </InfoAlert>
      )}

      {automaticPaymentMethod && (
        <InfoAlert>
          El pago de tu dividendo se descuenta directamente del{' '}
          <b className="boldCredito">{automaticPaymentMethod}</b> que tienes suscrito.
        </InfoAlert>
      )}

      <div id="credito" className="row bmy-md-4 bmy-3 bmt-md-4">
        {payment}
      </div>

      {skeleton ? (
        <div className="row bmb-md-4 bmb-3">
          <div className="col-xs-12">
            <CardBox>
              <CardBox.Body>
                <div className="row">
                  <div className="col-xs-1">
                    <Skeleton circle height={30} width={30} />
                  </div>
                  <div className="col-xs d-flex middle-items">
                    <Skeleton width={250} />
                  </div>
                  <div className="col-xs text-right">
                    <Skeleton circle height={30} width={30} className="bml-auto" />
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
      ) : (
        <div className="row bmb-md-4 bmb-3">
          <div className="col-xs-12">
            <ExpandWrapper>
              <Expand elevation={1} iconColor="#00A1DF" rounded defaultSelectedItem={1}>
                <Expand.Item position={1}>
                  <Expand.Header color="white">
                    <LoadIcon
                      src={`${iconPath}book.svg`}
                      size={23}
                      color="black"
                      className="float-left bmr-3"
                    />
                    <span className="d-md-block">
                      <b className="boldCredito">Conoce sobre tu crédito</b>
                    </span>
                  </Expand.Header>
                  <Expand.Body>
                    <div className="row">
                      <div className="bullet-red col-xs-12 col-md-6 mb-10">
                        <Typography
                          fontWeight={700}
                          className="bml-3"
                          color="#383638"
                          tag="p"
                          fontSize={15}
                        >
                          Valor cuota
                        </Typography>
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Es el monto de la cuota a pagar. Sobre este monto se agregan intereses y
                          multas en caso de atraso.
                        </Typography>
                      </div>

                      <div className="bullet-red col-xs-12 col-md-6 mb-10">
                        <Typography
                          fontWeight={700}
                          className="bml-3"
                          color="#383638"
                          tag="p"
                          fontSize={15}
                        >
                          Fecha de vencimiento
                        </Typography>
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Es la fecha donde se pactó cada mes el pago de la cuota mensual del
                          crédito de consumo.
                        </Typography>
                      </div>
                      <div className="bullet-red col-xs-12 col-md-6 mb-10">
                        <Typography
                          fontWeight={700}
                          className="bml-3"
                          color="#383638"
                          tag="p"
                          fontSize={15}
                        >
                          Costo total del crédito
                        </Typography>
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Es el monto total que asumirás al tomar el crédito, corresponde a la suma
                          de todos los pagos, es decir todas las cuotas pactadas en el plazo
                          acordado.
                        </Typography>
                      </div>
                      <div className="bullet-red col-xs-12 col-md-6 mb-10">
                        <Typography
                          fontWeight={700}
                          className="bml-3"
                          color="#383638"
                          tag="p"
                          fontSize={15}
                        >
                          Cobranza extrajudicial
                        </Typography>
                        <Typography className="bml-3" color="#383638" tag="p" fontSize={14}>
                          Son los gastos de cobranza a las cuotas con mora superior a los 20 días,
                          este es un cobro de un porcentaje sobre el monto de la deuda atrasada.
                        </Typography>
                      </div>
                    </div>
                  </Expand.Body>
                </Expand.Item>
              </Expand>
            </ExpandWrapper>
          </div>
        </div>
      )}
    </GlobalStyles>
  );
};

export default index;
