import React from 'react';
import Skeleton from 'react-loading-skeleton';
import CardBox from '@coopeuch-components/web/molecules/CardBox';

export const SkeletonContratacion = () => (
  <div>
    <div className="bmb-4">
      <CardBox className="bmt-4">
        <CardBox.Body>
          <div className="row">
            <div className="col-md-3 bmb-5">
              <Skeleton />
            </div>
            <div className="col-md-9" />
            <div className="col-md-12 bmb-3">
              <Skeleton />
            </div>
            <div className="col-md-12 bmb-4">
              <Skeleton />
            </div>

            <div className="col-md-8 col-xs-12 bmb-5 bmb-md-0">
              <div className="row">
                <div className="col-md-1 col-xs-1">
                  <Skeleton circle height={24} width={24} />
                </div>
                <div className="col-md-11 col-xs-11 bmb-3 bmt-1 bmt-md-0">
                  <Skeleton />
                </div>
                <div className="col-md-1" />
                <div className="col-md-10 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-md-1" />
                <div className="col-md-1" />
                <div className="col-md-1 bmb-md-5 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-md-4">
                  <Skeleton />
                </div>
                <div className="col-md-6" />
              </div>
            </div>
            <div className="col-md-4 col-xs-0" />

            <div className="col-md-8 col-xs-12 bmb-5 bmb-md-0">
              <div className="row">
                <div className="col-md-1 col-xs-1">
                  <Skeleton circle height={24} width={24} />
                </div>
                <div className="col-md-11 col-xs-11 bmb-3 bmt-1 bmt-md-0">
                  <Skeleton />
                </div>

                <div className="col-md-1" />
                <div className="col-md-10 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-md-1" />

                <div className="col-md-1" />
                <div className="col-md-1 bmb-md-5 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-md-4">
                  <Skeleton />
                </div>
                <div className="col-md-6" />
              </div>
            </div>
            <div className="col-md-4 col-xs-0" />

            <div className="col-md-8 col-xs-12 bmb-5 bmb-md-0">
              <div className="row">
                <div className="col-md-1 col-xs-1">
                  <Skeleton circle height={24} width={24} />
                </div>
                <div className="col-md-11 col-xs-11 bmb-3 bmt-1 bmt-md-0">
                  <Skeleton />
                </div>

                <div className="col-md-1" />
                <div className="col-md-10 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-md-1" />

                <div className="col-md-1" />
                <div className="col-md-1 bmb-md-5 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-md-4 ">
                  <Skeleton />
                </div>
                <div className="col-md-6" />
              </div>
            </div>
            <div className="col-md-4 col-xs-0" />

            <div className="col-md-8 col-xs-12">
              <div className="row">
                <div className="col-md-1" />
                <div className="col-md-1 col-xs-1">
                  <Skeleton height={24} width={24} />
                </div>
                <div className="col-md-10 col-xs-10 bmt-1 bmt-md-0">
                  <Skeleton />
                </div>
              </div>
            </div>
            <div className="col-md-4 col-xs-0" />
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmt-4">
        <CardBox.Body>
          <div className="row">
            <div className="col-md-5 bmb-4">
              <Skeleton />
            </div>
            <div className="col-md-7" />
            <div className="col-md-6 bmb-3">
              <Skeleton />
            </div>
            <div className="col-md-6" />
            <div className="col-md-12">
              <Skeleton height={48} width={296} />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmt-4">
        <CardBox.Body>
          <div className="row">
            <div className="col-md-2 bmb-5">
              <Skeleton />
            </div>
            <div className="col-md-10" />
            <div className="col-md-4 bmb-4">
              <Skeleton />
            </div>
            <div className="col-md-8" />
            <div className="col-md-3 bmb-3">
              <Skeleton />
            </div>
            <div className="col-md-9" />
            <div className="col-md-12 bmb-2">
              <Skeleton height={48} width={296} />
            </div>
            <div className="col-md-2">
              <Skeleton />
            </div>
            <div className="col-md-10" />
          </div>
        </CardBox.Body>
      </CardBox>
      <div className="row bmt-4">
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-2 bmt-1">
              <Skeleton />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className="col-md-8" />
            <div className="col-md-4">
              <Skeleton height={48} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
