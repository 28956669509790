import React from 'react';

import Simulador from '@coopeuch-project/simulador_privado';

const Index = () => {
  return (
    <>
      <Simulador />
    </>
  );
};

Index.displayName = 'Template_Simulacion';
export default Index;
