import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;

  .solicitud-contenedor {
    margin-top: 24px;
  }
  .solicitud-container {
    margin-top: 60px;
    padding: 24px 54px;
    text-align: left;
  }
  @media screen and (max-width: 1024px) {
    .solicitud-container {
      margin-top: 60px;
      padding: 16px;
      text-align: left;
    }
  }
`;

export const Header = styled.div`
  background-color: #e81f2c;
  min-height: 72px;
  padding: 5px;

  img {
    width: 170px;
    height: 30px;
  }
`;

export default Wrapper;
