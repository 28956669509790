import React from 'react';
import Stepper, { RenderStep } from '../../../organism/Stepper';
import Monto from './Monto';
import Pago from './Pago';
import Comprobante from './Comprobante';

const Index = ({
  steps,
  generalError,
  currentStep = 1,

  cuotasPendiente,
  montoCuotasPendiente,
  valorCuota,
  accountsAvailable,
  selectedAccount,
  cantidadCuotas,
  montoAPagar,
  handleChangeInput,
  handleChangeSelect,
  handlePasswordInput,
  handleTogglePassword,
  handleRetryTryToPay,
  handleToCambiarClave,
  secondFailPasswordError,
  saldoInsuficienteError,
  password,
  showPassword,
  passwordError,
  blockedPassword,
  serviceError,
  serverPasswordError,
  maxCuotasError,
  loadingDataStep1,
  waitingStep2,
  detallePago,
  backStep1,
  nextStep1,
  backStep2,
  continueStep2WithoutPago,
  nextStep2,
  backStep3,
  nextStep3,
}) => {
  return (
    <React.Fragment>
      {!generalError && (
        <div className="row bmy-3 bmy-md-4">
          <div className="col-xs-12">
            <Stepper className="" position={currentStep} {...{ steps }} />
          </div>
        </div>
      )}
      <RenderStep step={currentStep - 1} error={generalError}>
        <Monto
          {...{
            cuotasPendiente,
            montoCuotasPendiente,
            valorCuota,
            cantidadCuotas,
            handleChangeInput,
            loading: loadingDataStep1,
            saldoInsuficienteError,
            maxCuotasError,
            backButton: backStep1,
            nextButton: nextStep1,
          }}
        />
        <Pago
          {...{
            accounts: accountsAvailable,
            selectedAccount,
            handleChangeSelect,
            montoAPagar,
            cantidadCuotas,
            valorCuota,
            handlePasswordInput,
            handleTogglePassword,
            password,
            showPassword,
            passwordError,
            blockedPassword,
            serviceError,
            handleRetryTryToPay,
            handleToCambiarClave,
            secondFailPasswordError,
            serverPasswordError,
            backButton: backStep2,
            nextButton: nextStep2,
            continueStep2WithoutPago,
            waiting: waitingStep2,
          }}
        />
        <Comprobante {...{detallePago, backButton: backStep3, nextButton: nextStep3}}/>
      </RenderStep>
    </React.Fragment>
  );
};

export default Index;
