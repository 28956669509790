import styled from 'styled-components';

export const PageTitleWrapper = styled.div`
  @media screen and (min-width: 768px) {
    #header-cuenta-vista {
      width: 100vmax;
    }
  }
`;

export const TabsWrapper = styled.div`
  .margin-after-tabs-vista {
    margin-top: 48px;
  }
  @media screen and (min-width: 768px) {
    .tab--wrapper-shadows {
      width: 100vmax;
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    .margin-after-tabs-vista {
      margin-top: 0 !important;
    }
  }

  .tab-style {
    a {
      font-family: 'Ubuntu-Regular';
      text-decoration: unset;
      color: unset;
    }
  }
`;
