import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Skeleton from 'react-loading-skeleton';
import { lowerCaseRemoveAccentsDeleteSpaces } from '../../../../utils/formatters';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({ resultado, loading }) => {
  return (
    <div className="col-xs-12 col-md-4 bmy-3">
      <CardBox className="h-100 w-auto bmr-md-1">
        <CardBox.Header className="padding_header_card">
          {loading ? (
            <Skeleton width={25} height={15} />
          ) : (
            <LoadIcon
              src={`${iconPath}beneficios/${lowerCaseRemoveAccentsDeleteSpaces(
                resultado.rubro.descripcion
              )}.svg`}
              size={28}
              color="#000"
              className="adjust_icon"
            />
          )}{' '}
          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <span className="text_card">{resultado.nombreTienda}</span>
          )}
        </CardBox.Header>
        <CardBox.Body className="mb-30">
          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <Typography
              className="bmb-2"
              color="#007DB7"
              tag="p"
              fontSize={18}
              fontFamily="Ubuntu-Bold"
            >
              {resultado.descuento}
            </Typography>
          )}

          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <Typography
              color="#383838"
              tag="p"
              fontSize={14}
              fontFamily="Ubuntu-Regular"
              className="line_height_card bmb-2"
            >
              {resultado.descripcion.charAt(0).toUpperCase() + resultado.descripcion.slice(1)}
            </Typography>
          )}

          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <Typography
              color="#007DB7"
              tag="p"
              fontSize={12}
              fontFamily="Ubuntu-Bold"
              className="line_height_cero mt-18"
            >
              {resultado.vigencia}
            </Typography>
          )}

          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <Typography
              className="bmb-2 line_height_card"
              color="#383838"
              tag="p"
              fontSize={12}
              fontFamily="Ubuntu-Regular"
            >
              {resultado.condicionValidez || ''}
            </Typography>
          )}

          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <Typography
              color="#383838"
              tag="p"
              fontSize={12}
              fontFamily="Ubuntu-Regular"
              className="line_height_card bmb-6"
            >
              {resultado.descripcionLink || ''}
            </Typography>
          )}
          {loading ? (
            <Skeleton width={'70%'} height={15} />
          ) : (
            <Typography
              color="#383838"
              tag="p"
              fontSize={12}
              fontFamily="Ubuntu-Regular"
              className="line_height_card bmb-6"
            >
              {resultado.urlLink || ''}
            </Typography>
          )}
        </CardBox.Body>
      </CardBox>
    </div>
  );
};

export default Index;
