import React from 'react';

import { colors } from '../../../../assets/styles';

import {
  Text,
  Textdiv,
  Textdivp,
  Img,
  Divpasos,
  Divfletch
} from './styles';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const imgPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const Index = ({
  textTitle,
  textContent,
  top,
  left,
  borderBottom,
  borderTop,
  borderRight,
  borderLeft,
  onclose,
  width,
  height,
  paddingRight,
  topMovil,
  leftMovil,
  borderBottomMovil,
  borderTopMovil,
  borderRightMovil,
  backgroundMenu,
  borderinversiones,
  heightPasos,
  pasoactual,
  widthmovil,
  heightmovil,
  totalPasos,
}) => {
  
  return (
    <>
       <div onClick={onclose}>
        <Img src={`${iconPath}cross1.svg`} />
      </div>
      <Textdiv
        top={top}
        left={left}
        topMovil={topMovil}
        leftMovil={leftMovil}
        borderBottom={borderBottom}
        borderTop={borderTop}
        borderRight={borderRight}
        borderLeft={borderLeft}
        borderBottomMovil={borderBottomMovil}
        borderTopMovil={borderTopMovil}
        borderRightMovil={borderRightMovil}
        borderLeftMovil={borderRightMovil}
        backgroundMenu={backgroundMenu}
        borderinversiones={borderinversiones}
      >
        <div className="contImagen">
          <img src={`${imgPath}womanselect.png`} className="imgWoman" />
        </div>
        <Textdivp marginLeft="15" marginTop="-20" heightPasos={heightPasos}>
          <Text className="bmb-3"  textSize={16} weight="bold" size="16">
            {textTitle}
          </Text>
          <Text
            className="textCont"
            textSize={12}
            weight="normal"
            paddingRight={paddingRight}
            width={width}
            height={height}
            widthmovil={widthmovil}
            heightmovil={heightmovil}
            size="18"
          >
            {textContent}
          </Text>
        </Textdivp>
      </Textdiv>
      { 
        totalPasos != 1 ? 
        <>
          <Divpasos>{pasoactual} de 5</Divpasos>
          <Divfletch>
          <img src={`${imgPath}fletchright.png`} className="imgBtn" />
          </Divfletch>
        </> 
      : 
        <div></div>
      }

    </>
  );
};

export default Index;