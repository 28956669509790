import React from 'react';

import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Alert from '@coopeuch-components/web/atoms/Alert';

import Select from '../../../../atoms/Select';
import InputLabel from '../../../../atoms/InputLabel';

function SourceAccount({ selectedAccount, availableAccountsList, onChangeAccount, enoughMoney }) {
  return (
    <div className="row bmt-3 bmt-md-4" id="card-pago-cdc-1">
      <div className="col-xs-12">
        {selectedAccount && availableAccountsList.length > 0 && (
          <CardBox id="content">
            <CardBox.Header className="new-header-padding">Cuenta de origen</CardBox.Header>
            <CardBox.Body>
              <div className="row bmt-3">
                <div className="col-xs-12 col-md-6">
                  <div className="row">
                    <div className="col-xs-12 col-md-8 bmb-3 bmb-md-0">
                      {availableAccountsList.length > 1 ? (
                        <Select
                          id="select"
                          title="Número de cuenta"
                          borderColor="#333"
                          labelClass="text-bold bmb-3"
                          rounded={4}
                          onChange={onChangeAccount}
                          options={availableAccountsList}
                          value={selectedAccount.value}
                          size={48}
                        />
                      ) : (
                        <InputLabel
                          id="input-label"
                          onChange={() => {}} // PropTypes requiere este parámetro pero no es necesario funcionalmente
                          rounded={4}
                          size={48}
                          title="Número de cuenta"
                          labelClass="bmb-3 text-bold"
                          value={availableAccountsList[0].label}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-md-6">
                  <Typography className="bmb-3" tag="p" fontSize={16}>
                    Saldo disponible
                  </Typography>
                  <Typography id="available-cash" tag="p" fontSize={28} fontFamily="ubuntu-bold">
                    {selectedAccount.balance.label}
                  </Typography>
                </div>
              </div>
              {!enoughMoney && (
                <Alert className="mt-3" bgType="warning" show>
                  No tienes saldo suficiente. Te invitamos a abonar saldo a tu cuenta.
                </Alert>
              )}
            </CardBox.Body>
          </CardBox>
        )}
      </div>
    </div>
  );
}

SourceAccount.propTypes = {
  selectedAccount: PropTypes.shape({
    value: PropTypes.string,
    balance: PropTypes.shape({ label: PropTypes.string }),
  }).isRequired,
  availableAccountsList: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  onChangeAccount: PropTypes.func.isRequired,
  enoughMoney: PropTypes.bool,
};

SourceAccount.defaultProps = {
  enoughMoney: false,
  availableAccountsList: [],
};

export default SourceAccount;
