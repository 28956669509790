import React from 'react';

import Skeleton from 'react-loading-skeleton';

import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import Alert from '@coopeuch-components/web/atoms/Alert';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Select from '@coopeuch-components/web/atoms/Select';
import Typography from '@coopeuch-components/web/atoms/Typography';

import { agregarformatoPesos, capitalizeStrFormat } from '../../../../../utils/formatters';
import { dateFormat } from '../../../../../utils/dates';

import { ContainerTipoCredito } from './styles';

const Index = ({
  skeleton,
  creditos,
  creditoSeleccionado,
  loadingCreditoSeleccionado,
  handleChangeSelect,
}) => {
  return (
    <>
      <div className="col-xs-12 col-md-12">
        <div className="w-100 h-100 bpr-md-1">
          <CardBox className="h-100 bmr-md-1">
            <CardBox.Body>
              <ContainerTipoCredito>
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <div className="col-xs-12 bmb-3 pl-0">
                      {skeleton ? (
                        <Skeleton height={68} />
                      ) : creditos.length === 1 ? (
                        <InputLabel
                          inputTextSize={14}
                          value={`${capitalizeStrFormat(creditos[0].nombreTipoCredito)} Nº ${
                            creditos[0].numeroReferencia
                          }`}
                          size={48}
                          inputClass="border mb-20"
                          rounded={4}
                        />
                      ) : (
                        <Select
                          textSize={14}
                          rounded={4}
                          value={creditoSeleccionado.value}
                          options={creditos}
                          onChange={handleChangeSelect}
                        />
                      )}
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-md-6">
                        <Typography tag="p" color="#333333" fontSize={16}>
                          {skeleton ? <Skeleton width={'70%'} /> : 'Monto Otorgado'}
                        </Typography>
                        <Typography
                          className="bmb-3"
                          tag="p"
                          color="#333333"
                          fontSize={20}
                          fontWeight={700}
                          fontFamily="ubuntu-bold"
                        >
                          {loadingCreditoSeleccionado ? (
                            <Skeleton width={'60%'} />
                          ) : (
                            agregarformatoPesos(creditoSeleccionado?.montoOtorgado || '')
                          )}
                        </Typography>
                      </div>
                      <div className="col-xs-12 col-md-6">
                        <Typography tag="p" color="#333333" fontSize={16}>
                          {skeleton ? <Skeleton width={'70%'} /> : 'Fecha de otorgamiento'}
                        </Typography>
                        <Typography
                          className="bmb-3"
                          tag="p"
                          color="#333333"
                          fontSize={20}
                          fontWeight={700}
                          fontFamily="ubuntu-bold"
                        >
                          {loadingCreditoSeleccionado ? (
                            <Skeleton width={'60%'} />
                          ) : (
                            dateFormat(creditoSeleccionado?.fechaOtorgamiento || '')
                          )}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <div className="row align-middle">
                      <div className="col-xs-12">
                        {skeleton ? (
                          <Skeleton height={120} />
                        ) : (
                          <Alert className="adjust_alert" bgType="info" show>
                            Lamentablemente no es posible continuar el <br />
                            proceso. Si deseas más información{' '}
                            <b className="boldCredito">
                              {' '}
                              dirígete a <br />
                              una de nuestras sucursales.
                            </b>
                          </Alert>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </ContainerTipoCredito>
            </CardBox.Body>
          </CardBox>
        </div>{' '}
      </div>
    </>
  );
};

export default Index;
