import React from 'react';
import Stepper, { RenderStep } from '../../../../organism/Stepper';
import Datos from './Datos';
import Firma from './Firma';
import Comprobante from './Comprobante';
import ModalEnviarComprobante from './ModalEnviarComprobante';
import ModalComprobanteEnviado from './ModalComprobanteEnviado';
import ModalEnviarComprobanteError from './ModalEnviarComprobanteError';

const Index = ({
  currentStep,
  steps,
  fetchingData,
  despachoDomicilio,
  regionOptions,
  comunaOptions,
  sucursales,
  region,
  comuna,
  isLoadingSucursalesComuna,
  notSucursal,
  sucursal,
  sucursalOptions,
  calle,
  numeroCasa,
  otrosCasa,
  poseeFactibilidad,
  checked,
  checkdRequiredError,
  password,
  showPassword,
  passwordError,
  blockedPassword,
  serverPasswordError,
  wrongPassword,
  serviceError,
  email,
  emailError,
  persona,
  isLoadingPersona,
  globalError,
  secondEmail,
  secondEmailError,
  respuestaDetalle,
  loadingStep1,
  loadingStep2,
  loadingSendComprobante,
  showEnviarComprobanteModal,
  showComprobanteEnviadoModal,
  showEnviarComprobanteErrorModal,
  onDownloadGenericContract,
  changeTab,
  handleOnChangeInput,
  handleWrongPassword,
  handlePasswordInput,
  handleTogglePassword,
  handleOnChangeRegion,
  handleOnChangeComuna,
  handleOnChangeSucursal,
  handleCheckBox,
  handleChangeEmail,
  handleDownloadComprobante,
  handleDownloadContrato,
  handleStartSendComprobanteEmail,
  handleSendComprobanteEmail,
  closeModal,
  backStep1,
  continueStep1,
  backStep2,
  continueStep2,
  goToHome,
  onRedirectToSchedule,
  availablePassCoopeuch,
  solicitarPassCoopeuch,
  solicitarDesafioPassCoopeuch,
  IsValidatePassCoopeuch,
  validarPassCoopeuchCP,
  errorTimeOutCP,
  errorTimeOut,
  counterActive,
  handleSwitchMethods,
  onHandleObtenerDesafio,
  tarjetaDeCoord,
  onHandleSetDesafio,
  sendDesafio,
  IsLoading,
  retryTimeOutPass,
  desafio,
  coordenadas,
  validarDesafio,
  validaDesafio,
  continuaFirma,
  IsAvailableTarCoo,
  IsAvailablePassCoopeuch,
  tarjeta,
}) => {
  return (
    <>
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12">
          <Stepper position={currentStep} {...{ steps }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <RenderStep step={currentStep - 1} error={globalError}>
            <Datos
              {...{
                fetchingData,
                regionOptions,
                comunaOptions,
                isLoadingSucursalesComuna,
                notSucursal,
                sucursalOptions,
                sucursales,
                region,
                comuna,
                sucursal,
                calle,
                numeroCasa,
                otrosCasa,
                poseeFactibilidad,
                despachoDomicilio,
                changeTab,
                loading: loadingStep1,
                handleOnChangeInput,
                handleOnChangeRegion,
                handleOnChangeComuna,
                handleOnChangeSucursal,
                persona,
                isLoadingPersona,
                availablePassCoopeuch,
                solicitarPassCoopeuch,
                solicitarDesafioPassCoopeuch,
                IsValidatePassCoopeuch,
                validarPassCoopeuchCP,
                errorTimeOutCP,
                handleSwitchMethods,
              }}
              backAction={backStep1}
              nextAction={continueStep1}
            />
            <Firma
              {...{
                checked,
                checkdRequiredError,
                password,
                showPassword,
                passwordError,
                blockedPassword,
                serverPasswordError,
                wrongPassword,
                serviceError,
                email,
                emailError,
                persona,
                handleCheckBox,
                handleChangeEmail,
                handlePasswordInput,
                handleWrongPassword,
                handleTogglePassword,
                loading: loadingStep2,
                onDownloadGenericContract,
                availablePassCoopeuch,
                solicitarPassCoopeuch,
                solicitarDesafioPassCoopeuch,
                IsValidatePassCoopeuch,
                validarPassCoopeuchCP,
                errorTimeOutCP,
                errorTimeOut,
                counterActive,
                handleSwitchMethods,
                onHandleObtenerDesafio,
                tarjetaDeCoord,
                onHandleSetDesafio,
                sendDesafio,
                IsLoading,
                retryTimeOutPass,
                desafio,
                coordenadas,
                validarDesafio,
                validaDesafio,
                continuaFirma,
                IsAvailableTarCoo,
                IsAvailablePassCoopeuch,
                tarjeta,
              }}
              backAction={backStep2}
              nextAction={continueStep2}
            />
            <Comprobante
              {...{
                despachoDomicilio,
                respuestaDetalle,
                handleChangeEmail,
                handleDownloadContrato,
                handleDownloadComprobante,
                handleStartSendComprobanteEmail,
                region,
                comuna,
                calle,
                numeroCasa,
                otrosCasa,
                onRedirectToSchedule,
                availablePassCoopeuch,
                solicitarPassCoopeuch,
                solicitarDesafioPassCoopeuch,
                IsValidatePassCoopeuch,
                validarPassCoopeuchCP,
                errorTimeOutCP,
                handleSwitchMethods,
              }}
              nextAction={goToHome}
            />
          </RenderStep>
        </div>
      </div>
      <ModalEnviarComprobante
        loading={loadingSendComprobante}
        show={showEnviarComprobanteModal}
        email={secondEmail}
        emailError={secondEmailError}
        handleChangeEmail={handleChangeEmail}
        closeAction={closeModal}
        nextButton={handleSendComprobanteEmail}
      />
      <ModalComprobanteEnviado
        show={showComprobanteEnviadoModal}
        closeAction={closeModal}
        backButton={closeModal}
        nextButton={closeModal}
      />
      <ModalEnviarComprobanteError
        show={showEnviarComprobanteErrorModal}
        closeAction={closeModal}
      />
    </>
  );
};

export default Index;
