const {
  navigator: { userAgent },
} = window;

const getBrowser = () => {
  let tem = null;
  let M = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (M[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

    if (tem !== null) {
      return {
        name: 'Opera',
        version: tem[1],
      };
    }
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = userAgent.match(/version\/(\d+)/i)) !== null) M.splice(1, 1, tem[1]);

  return {
    name: M[0],
    version: M[1],
  };
};

export default getBrowser;
