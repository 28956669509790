import React from 'react';
// Components
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Organism
import DashboardCard from '../../../organism/DashboardCard';
// Styles
import Wrapper from './styled';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ErrorPoints = ({ handleClickRetry }) => {
  return (
    <Wrapper>
      <DashboardCard className="dashboard-card-shadow">
        <DashboardCard.Body classes="dashboard-puntos dashboard-custom">
          <div className="dashboard-puntos-body">
            <div className="dashboard-puntos__icon">
              <LoadIcon
                src={`${iconPath}icon_puntoscoo_dashboard.svg`}
                size={30}
                color="#1E1E1E"
                className="access-icon"
              />
              <p className="dashboard-puntos__text">
                <strong>Puntos Coopeuch</strong> no disponibles
              </p>
            </div>

            <div className="dashboard-puntos__btn">
              <button onClick={() => handleClickRetry()}>Reintentar</button>
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </Wrapper>
  );
};

export default ErrorPoints;
