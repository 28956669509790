import styled from 'styled-components';

export const PageTitleWrapper = styled.div`
  @media screen and (min-width: 768px) {
    #header-cuota-participacion {
      width: 100vmax;
    }
  }
`;
export const Hr = styled.hr`
  height: 1px;
  background-color: #e9e9e9;
  border: none;
`;
export const RightCardWrapper = styled.div`
  .right-card-resumen-cdp {
    > section:first-child {
      height: 100%;
      > div:first-child {
        height: 100%;
      }
    }
  }
`;
export const AporteContainer = styled.div`
  width: 80%;
  margin: 20px auto;
  font-family: Arial, sans-serif;
`;

export const H2 = styled.h2`
  text-align: center;
  margin-bottom: 20px;
`;

export const Card = styled.div`
  border: 1px solid #e0e0e0;
  padding: 20px;
  margin: 10px 0;

  &.blue {
    border-color: #0099ff;
    background-color: #f0f8ff;
  }
`;

export const Detalle = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
`;

export const Button = styled.button`
  display: block;
  padding: 10px 20px;
  margin: 20px auto;
  background-color: #0099ff;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0077cc;
  }
`;

export const TabsWrapper = styled.div`
  .margin-after-tabs-vista {
    margin-top: 48px;
  }

  @media screen and (min-width: 768px) {
    .tab--wrapper-shadows {
      width: 100vmax;
      position: absolute;
    }
  }

  @media screen and (max-width: 768px) {
    .margin-after-tabs-vista {
      margin-top: 0 !important;
    }
  }

  .tab-style {
    a {
      font-family: 'Ubuntu-Regular';
      text-decoration: unset;
      color: unset;
    }
  }
`;

export const CardInput = styled.input`
  display: none;  // Esto oculta el radio input real
`;

export const CardLabel = styled.label`
  cursor: pointer;  // Esto hace que el cursor cambie a mano cuando pasas sobre la tarjeta
  // Puedes añadir otros estilos si quieres que la tarjeta se vea diferente cuando esté seleccionada, por ejemplo:
  // &:hover {
  //   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  // }
`;


// CSS Integrations
export const DashboardCard = styled.div`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  background-color: white;

  .Head {
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;
    border-bottom: 2px solid #E5E5E5;
  }
`;

export const CardBox = styled.div`
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px;
  background-color: white;
  height: 100%;
`;

export const Typography = styled.p`
  margin-bottom: 15px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

// Generic utility classes (styled-components doesn't usually use utility classes, but you can do it)
export const W100 = styled.div`
  width: 100%;
`;

export const H100 = styled.div`
  height: 100%;
`;

export const Bmb3 = styled.div`
  margin-bottom: 15px;
`;

export const Bmt3 = styled.div`
  margin-top: 15px;
`;
