import React from 'react';
// Components

import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { CardDropdown } from '@design-system-coopeuch/web'



const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const InfoBonosIngreso = ({ handleClickBtn, userType, handleClickCuentaVista }) => {
    return (
        <>
            <p className="bonoSolicitar__content__info">
                Se le otorgará un bono al beneficiario e hijos por haber ingresado a una institución de
                educación superior reconocida por el ministerio de educación.
            </p>
            <div className="bonoSolicitar__content__monto">
                <Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__monto__montoText"
                >
                    Monto del beneficio:
                </Typography>
                <Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__monto__montoNumber"
                >
                    $50.000
                </Typography>
            </div>
            <div className="bonoSolicitar__content__requisitos">
                <CardDropdown title={<Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__requisitos__title"
                >
                    Requisitos para postular:
                </Typography>}
                >
                    <div className="bonoSolicitar__content__requisitos__info">
                        <ul>
                            <li>
                                <p>
                                    Debes tener más de 1 año de antigüedad como socio Coopeuch y estar al día en tus compromisos (deudas directas o indirectas).
                                </p>
                            </li>
                            <li>
                                <p>
                                    Estar cursando el primer año de una carrera técnica o universitaria, cuya malla curricular comprenda al menos 2 años de duración.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Las carreras deben ser presenciales, semipresenciales u online, impartidas por Centros de Formación Técnica, Institutos profesionales, Universidades y escuelas de Formación de las Fuerzas Armadas y PDI.
                                </p>
                            </li>
                            <li>
                                <p>
                                    El plazo máximo para postular al bono es 90 días corridos desde la fecha de pago de la matrícula o comprobante de gratuidad.
                                </p>
                            </li>
                            <li>
                                <p>Debes tener tu Cuenta Vista activa.</p>
                            </li>
                        </ul>
                    </div>
                </CardDropdown>
            </div>
            <div className="bonoSolicitar__content__documentos">
                <CardDropdown title={<Typography
                    tag="p"
                    fontSize={24}
                    fontWeight="bold"
                    className="bonoSolicitar__content__documentos__title"
                >
                    Documentos requeridos:
                </Typography>}>
                    <div className="bonoSolicitar__content__documentos__docsRequeridos">
                        <div>
                            <LoadIcon src={`${iconPath}identification_card.svg`} />
                            <p>Cédula de identidad del socio por ambos lados.</p>
                        </div>
                        <div>
                            <LoadIcon src={`${iconPath}identification_card.svg`} />
                            <p>Cédula de identidad del hijo por ambos lados.</p>
                        </div>
                        <div>
                            <LoadIcon src={`${iconPath}certificatev2.svg`} className="comprobantemob"/>
                            <p>
                                Comprobante de pago matrícula o comprobante de gratuidad o certificado de alumno
                                regular.
                            </p>
                        </div>
                    </div>
                </CardDropdown>
            </div>
        </>
    );
};

export default InfoBonosIngreso;
