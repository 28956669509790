import React, { useState, useEffect, Suspense, lazy } from 'react';
import { withRouter, Link, useRouteMatch } from 'react-router-dom';
// UI
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import PageTitle from '../../components/atoms/PageTitle';
import { TabsWrapper, PageTitleWrapper } from './styles';

import { registerGA } from '../../utils/metrics';
import { useHistory } from 'react-router-dom';

// Components
const MiCredito = lazy(() => import('./MiCredito'));
const Historial = lazy(() => import('./Historial'));

// const tabs = {
//   0: 'MiCredito',
//   1: 'Historial',
// };
const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = () => {
  const match = useRouteMatch();
  const [selectedTab, setSelectedTab] = useState(match.params.tab ? 1 : 0);
  const [renderTabs, setRenderTabs] = useState(true); // TODO: no se usa valor


  const clickTabHandle = (position) => {
    setSelectedTab(position);
    // console.log({ position });
  };

  // Cambiar tab segun la url
  useEffect(() => {
    if (match.params.tab) {
      setSelectedTab(match.params.tab ? 1 : 0);
      setRenderTabs(true);
      // console.log('existe tab');
    }
  }, [match.params.tab]);

  // useEffect(() => {
  //   console.log({ selectedTab });
  // }, [selectedTab]);

  return (
    <>
      <PageTitleWrapper>
        <PageTitle
          title="Crédito de Consumo"
          icon={<LoadIcon src={`${ICON_PATH}coins.svg`} color="black" />}
        />
      </PageTitleWrapper>
      <TabsWrapper className="w-100 margin-after-title">
        <Tabs
          bgColor="#fff"
          fontSize={15}
          fontFamily="Ubuntu-Medium"
          tabSize={20}
          activeColor="#e81e2b"
          className="pl-54 tab--wrapper-shadows tabs"
          defaultTab={selectedTab}
        >
          <Tabs.Header>
            <Tab className="tab-style" callback={clickTabHandle}>
              <Link to="/ecd/productos/credito-de-consumo">Mi Crédito</Link>
            </Tab>

            <Tab className="tab-style w-150p" callback={clickTabHandle}>
              <Link to="/ecd/productos/credito-de-consumo/historial">Historial</Link>
            </Tab>
          </Tabs.Header>
          <div className="w-100 bpx-3 bpx-md-5 margin-after-tabs-vista text-left">
            <Tabs.Body>
              <Suspense fallback={<div />}>
                <div className="w-100">
                  <div className="w-100">{selectedTab === 0 && <MiCredito />}</div>
                </div>
              </Suspense>
              <Suspense fallback={<div />}>
                <div className="w-100">
                  <div className="w-100">{selectedTab === 1 && <Historial />}</div>
                </div>
              </Suspense>
            </Tabs.Body>
          </div>
        </Tabs>
      </TabsWrapper>
    </>
  );
};

Index.displayName = 'Container_CreditoDeConsumo';
export default withRouter(Index);
