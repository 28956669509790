import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import PassCoopeuch from './PassCoopeuch';
import TarjetaDeCoordenadas from './TarjetaDeCoordenadas';
import SMS from './SMS';
import ClaveInternet from './ClaveInternet';
import Errores from './Errores';

const factoresDeSeguridad = {
  1.1: 'claveInternet',
  2.1: 'tarjetaCoordenadas',
  2.2: 'passCoopeuch',
  3.1: 'sms',
};

const FactoresDeSeguridad = (props) => {
  const { tipo } = props;

  const FactorSeleccionado = useMemo(() => {
    if (factoresDeSeguridad[tipo] === 'passCoopeuch') {
      return PassCoopeuch;
    } else {
      if (factoresDeSeguridad[tipo] === 'tarjetaCoordenadas') {
        return TarjetaDeCoordenadas;
      } else {
        if (factoresDeSeguridad[tipo] === 'sms') {
          return SMS;
        } else {
          if (factoresDeSeguridad[tipo] === 'claveInternet') {
            return ClaveInternet;
          } else {
            return Errores;
          }
        }
      }
    }
  }, [tipo]);

  return <FactorSeleccionado {...props} />;
};

FactoresDeSeguridad.propTypes = {
  tipo: PropTypes.oneOfType([PropTypes.number, PropTypes.number]),
  ...PassCoopeuch.propTypes,
  ...TarjetaDeCoordenadas.propTypes,
  ...Errores.propTypes,
};

FactoresDeSeguridad.defaultProps = {
  tipo: '',
};

export default FactoresDeSeguridad;
