import React, { Component } from 'react';
import PageTitle from '../../../components/atoms/PageTitle';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import SolicitaCreditoDeConsumoTemplate from '../../../components/templates/CreditoDeConsumo/Solicita';
import { registerGA } from '../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

class Index extends Component {
  componentDidMount = () => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/credito-consumo',
    });
  };

  bannerClick = () => {
    const { history } = this.props;
    registerGA({
      event: 'eve',
      'event-config': {
        'eve-acc': '/tef/credito-consumo',
        'eve-cat': 'Click',
        'eve-lab': 'Simular',
      },
    });
    history.push("/ecd/solicita/simulador-credito-de-consumo")
  };
  render() {
    return (
      <React.Fragment>
        <PageTitle
          title="Crédito de Consumo"
          icon={<LoadIcon src={`${iconPath}coins.svg`} color={'black'} />}
        />
        <div className="w-100 margin-after-title text-left">
          <SolicitaCreditoDeConsumoTemplate bannerClick={this.bannerClick} />
        </div>
      </React.Fragment>
    );
  }
}

export default Index;
