import styled from 'styled-components';

const Wrapper = styled.div`
  .titleActivacionKit {
    font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  margin-left: 32px;
  margin-top: 32px;
  @media (max-width: 1280px) {
    margin-right: 32px;
  }
`;

export default Wrapper;
