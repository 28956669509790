import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Utils
import { formatRut } from '../../../../utils/formatters';
import { capitalize } from '../../../../utils/functions';
// Styled
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DetalleActivacion = ({ kitData, persona, validateSMS, activateKit, handleDownloadPDF }) => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>
              <div className="header__container">
                <Typography tag="p" className="titleDetalle">
                  Comprobante
                </Typography>
                <button className="header__descargar" onClick={handleDownloadPDF}>
                  <LoadIcon src={`${iconPath}download_arrow.svg`} size={10} color="#1E1E1E" />
                  <p>Descargar</p>
                </button>
              </div>
            </CardBox.Header>
            <CardBox className="body__container">
              <Typography tag="p" className="subTitleDetalle">
                Activación de Tarjeta de Débito
              </Typography>
              <CardBox>
                <div className="card__content color">
                  <div className="card__content-info">
                    <p className="card__content-title">Titular</p>
                    <p className="card__content-name">
                      {capitalize(persona?.primerNombre)} {capitalize(persona?.apellidoPaterno)}
                    </p>
                  </div>
                </div>
              </CardBox>
              <CardBox>
                <div className="card__content">
                  <div className="card__content-info">
                    <p className="card__content-title">RUT</p>
                    <p className="card__content-name">{formatRut(persona?.rut)}</p>
                  </div>
                </div>
              </CardBox>
              <CardBox>
                <div className="card__content color">
                  <div className="card__content-info">
                    <p className="card__content-title">Producto</p>
                    <p className="card__content-name">Tarjeta de Débito</p>
                  </div>
                </div>
              </CardBox>
              <CardBox>
                <div className="card__content">
                  <div className="card__content-info">
                    <p className="card__content-title">N° de Tarjeta de Débito</p>
                    <p className="card__content-name">{kitData.data?.numeroTarjetaDebito}</p>
                  </div>
                </div>
              </CardBox>
            </CardBox>
            <CardBox.Body className="bodyFooter">
              <div className="bodyFooter__container">
                <p className="bodyFooter__container-text">
                  Fecha y hora:
                  <b className="bodyFooter__container-text--bold">
                    {validateSMS.data?.fechaActivacion
                      ? validateSMS.data?.fechaActivacion
                      : activateKit.data?.fechaActivacion}
                  </b>
                </p>
                <p className="bodyFooter__container-text">
                  Serie operación:
                  <b className="bodyFooter__container-text--bold">
                    {validateSMS.data?.serieOperacion
                      ? validateSMS.data?.serieOperacion
                      : activateKit.data?.serieOperacion}
                  </b>
                </p>
              </div>
              <div className="bodyFooter__legal">
                <p className="bodyFooter__legal-text">Transacciones 100% seguras</p>
                <img src={`${iconPath}kit/sello.svg`} alt="Icono de sello" />
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </Wrapper>
  );
};

export default DetalleActivacion;
