import React, {useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { creditoHipotecarioActions } from '../../../../../application/actions/creditoHipotecario';
import { useShallowStore } from '../../../../utils/hooks';

import ReceiptTemplate from '../../../../components/templates/CreditoHipotecario/Payment/ReceiptTemplate';

function ReceiptContainer({ onPaymentSuccess }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loanToPay: { feeNumber, referenceNumber },
  } = useShallowStore((store) => store.entities.creditoHipotecario.pay);

  const { 
    data: receiptDetail, 
  } = useShallowStore((store) => store.entities.creditoHipotecario.comprobantePago);

  useEffect(() => {
    dispatch(
      creditoHipotecarioActions.fetchComprobantePago({
        feeNumber,
        referenceNumber,
      })
    );
  }, [feeNumber,referenceNumber]);


  const handleDownloadPdfReceipt = () => {
    dispatch(
      creditoHipotecarioActions.fetchComprobantePdfPago({
        feeNumber,
        referenceNumber,
      })
    );
  };

  const toHome = () => {
    history.push('/ecd/inicio');
  };


  return (
    <ReceiptTemplate
      onPaymentSuccess={onPaymentSuccess}
      onGoBackHome={toHome}
      handleDownloadPdfReceipt={handleDownloadPdfReceipt}
      receiptDetail={receiptDetail}
    />
  );
};

ReceiptContainer.propTypes = {
  onPaymentSuccess: PropTypes.func.isRequired,
};

export default ReceiptContainer;
