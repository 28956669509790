import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@coopeuch-components/web/atoms/Typography';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import { capitalize } from '../../../../../utils/functions';
import { formatRut } from '../../../../../utils/formatters';

// eslint-disable-next-line react/prop-types
const TextBlock = ({ children }) => (
  <Typography tag="p" fontSize={14} className="textLegal">
    {children}
  </Typography>
);

const PoderEspecial = ({ nombreCliente, persona, banco, tipoCuenta, numeroCuenta }) => {
  const correo = persona?.correo || 'No posee';
  const formattedRut = formatRut(persona.rut);

  const userBankData = [
    { label: 'Institución financiera', value: capitalize(banco?.descripcion) },
    { label: 'Tipo de cuenta', value: capitalize(tipoCuenta?.descripcion) },
    { label: 'Número de cuenta', value: numeroCuenta },
    { label: 'Email', value: correo },
  ];

  return (
    <CardBox className="bmb-3 bmb-md-4">
      <div className="col-xs-12">
        <CardBox.Header>
          <Typography tag="p" fontSize={22} fontWeight="bold">Poder Especial</Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className='row'>
            <TextBlock>
              Yo 
              {' '}
              <span className="text-bold">
                {`${capitalize(nombreCliente)},`}
              </span>
              
              {' '}
              <span className="text-bold">
                {` Rut ${formattedRut}`}
              </span>
              , en mi calidad de Socio o Cliente Coopeuch, otorgo los poderes y autorizaciones siguientes:
              <br />
              <br />
              1. Autorizo en este acto y por este instrumento a Coopeuch a efectuar abonos correspondientes a cualquier acreencia que exista a mi 
              {' '}
              <br />
              nombre en la referida Cooperativa, en cualquiera de las cuentas que mantenga en esta institución o en otras instituciones que informaré
              {' '}
              <br />
              {' '}
              en su debido momento.
              <br />
              <br />
              En caso de que yo no informe cuenta bancaria alguna, o de que la cuenta que haya informado resulte inválida, autorizo por este acto y 
              {' '}
              <br />
              {' '}
              por este instrumento a mi actual empleador o al que tenga en el futuro para que entregue a Coopeuch, en caso que exista un convenio
              {' '}
              <br />
              {' '}
              entre mi empleador y la referida Cooperativa, los datos de mi cuenta bancaria, sea ésta cuenta corriente o cuenta vista, que tenga
              {' '}
              <br />
              {' '}
              registrada a mi nombre, ello con el único objeto para que Coopeuch pueda realizar depósitos a mi nombre por cualquier acreencia que
              {' '}
              <br />
              {' '}
              exista a mi favor en dicha Cooperativa.
              {' '}
              <br /> 
              {' '}
              <br />
              Esta autorización solamente se podrá revocar mediante comunicación escrita o electrónica enviada a Coopeuch.
              <br /> 
              {' '}
              <br />
            </TextBlock>
            <Typography tag="p" fontSize={14} className="tituloDatos">
              Datos de la Cuenta bancaria para que Coopeuch abone cualquier acreencia que exista a mi favor:
            </Typography>
          </div>
          <div className='d-block d-md-none'>
            <div className='row'>
              {userBankData.map((item) => (
                <div key={item.label} className="col-12 px-0 mb-3">
                  <div className="row">
                    <div className="col-6">
                      <Typography tag="p" fontSize={12}>
                        {item.label}
                        :
                      </Typography>
                    </div>
                    <div className="col-6">
                      <Typography tag="p" fontSize={14} className="text-bold text-break text-right">
                        {item.value}
                      </Typography>
                    </div>
                  </div>
                </div>
          ))}
            </div>
          </div>
          <div className='d-none d-md-block'>
            <div className='row px-0'>
              {userBankData.map((item) => (
                <div key={item.label} className="col-md-3 col-lg-3 col-6 px-0 mb-3 mb-md-3">
                  <Typography tag="p" fontSize={14}>
                    {item.label}
                    :
                  </Typography>
                  <Typography tag="p" fontSize={16} className="text-bold text-break">
                    {item.value}
                  </Typography>
                </div>
          ))}
            </div>
          </div>
        </CardBox.Body>
      </div>
    </CardBox>
  );
};

PoderEspecial.propTypes = {
  nombreCliente: PropTypes.string,
  persona: PropTypes.shape({
    correo: PropTypes.string,
    rut: PropTypes.string.isRequired,
  }),
  banco: PropTypes.shape({
    descripcion: PropTypes.string,
  }),
  tipoCuenta: PropTypes.shape({
    descripcion: PropTypes.string,
  }),
  numeroCuenta: PropTypes.number,
};

PoderEspecial.defaultProps = {
  nombreCliente: '',
  persona: { rut: '' },
  banco: { descripcion: '' },
  tipoCuenta: { descripcion: '' },
  numeroCuenta: null,
};

export default PoderEspecial;
