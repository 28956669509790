import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import PropTypes from 'prop-types';
import { SwiperContainer } from './styles';
import BannerPassCoopeuch from './PassCoopeuch';
import NuevaAppCoopeuch from './NuevaAppCoopeuch';
import BannerInversiones from './BannerInversiones';
import UpgradeMonedero from './UpgradeMonedero';
import BannerAcreenciasRemanente from './BannerAcreenciasRemanente';
import BannerNorma484 from './BannerNorma484';
import BannerCuotasParticipacion from './BannerCuotasDeParticipacion'

const Index = ({ pasoactual, showUmBanner, GARedirectUM, banner, showBannerCDP, showNorma484Banner }) => {
  SwiperCore.use([Autoplay]);
  return (
    <div className="row bmb-3 bmb-md-4">
      <div className="col-xs-12">
        <SwiperContainer
          id="banner"
          style={pasoactual == '2' ? { border: '3px solid #007DB7', borderRadius: '4px' } : {}}
        >
          <Swiper
            height={250}
            slidesPerView="auto"
            autoplay={{ delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
          >
            {showNorma484Banner === true ? (
              <>
                <SwiperSlide>
                  <BannerNorma484 />
                </SwiperSlide>
                <SwiperSlide key="banner-inversiones">
                  <BannerInversiones />
                </SwiperSlide>
              </>
            ) : showBannerCDP?.success === true ?
                  <>
                  <SwiperSlide key="banner-cuotas">
                    <BannerCuotasParticipacion />
                  </SwiperSlide>
                  <SwiperSlide key="banner-inversiones">
                    <BannerInversiones />
                  </SwiperSlide>
                </>
            : banner === true ? (
              <>
                <SwiperSlide>
                  <BannerAcreenciasRemanente banner={banner} />
                </SwiperSlide>
                <SwiperSlide key="banner-inversiones">
                  <BannerInversiones />
                </SwiperSlide>
              </>
            ) 
            : showUmBanner === true ? (
              <>
                <SwiperSlide>
                  <UpgradeMonedero GARedirectUM={GARedirectUM} />
                </SwiperSlide>
                <SwiperSlide key="banner-inversiones">
                  <BannerInversiones />
                </SwiperSlide>
              </>
            ) : (
              <>
                <SwiperSlide key="banner-passcoopeuch">
                  <NuevaAppCoopeuch />
                </SwiperSlide>
                <SwiperSlide key="banner-inversiones">
                  <BannerInversiones />
                </SwiperSlide>
              </>
            )}
          </Swiper>
        </SwiperContainer>
      </div>
    </div>
  );
};

Index.propTypes = {
  pasoactual: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  showUmBanner: PropTypes.bool.isRequired,
  GARedirectUM: PropTypes.func.isRequired,
  showNorma484Banner: PropTypes.bool.isRequired,
};

export default Index;
