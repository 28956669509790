import styled from 'styled-components';

const InputContainer = styled.div`
  margin: auto;
  width: 305px;
  margin-bottom: 16px;

  & small {
    display: none;
  }

  .factor-sms-input {
    font-size: 16px;
    text-align: center;
    border-radius: 2px;
    height: 48px;
    padding: 0px;
  }

  .factor-coordenadas-input small {
    display: none;
  }

  .factor-coordenadas-label-container {
    margin-bottom: 8px;
    width: 100%;
    text-align: left;
  }

  .sms-input-padding {
    padding-left: 12px;
    padding-right: 12px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    .sms-input-padding {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
`;

export const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    .factor-button-style {
      width: 100% !important;
    }
  }
`;

export default InputContainer;
