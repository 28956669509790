import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/beneficios';

// ------- SAGA ------ //

function fetchBeneficiosObtenerSuccess(state, action) {
  state.beneficiosObtener = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
  state.errorBeneficios = '';
}

function fetchBeneficiosObtenerError(state, action) {
  state.beneficiosObtener = {
    success: false,
    error: true,
    errorCode: '',
    data: null,
    date: new Date().toISOString(),
  };
  state.errorBeneficios = action.payload.error;
}

function fetchRubroObtenerSuccess(state, action) {
  state.rubroObtener = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
  state.errorRubro = '';
}

function fetchRubroObtenerError(state, action) {
  state.rubroObtener = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
  state.errorRubro = action.payload.error;
}

function fetchRegionObtenerSuccess(state, action) {
  state.regionObtener = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
  state.errorRegion = '';
}

function fetchRegionObtenerError(state, action) {
  state.regionObtener = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
  state.errorRegion = action.payload.error;
}

const fetchBeneficiosObtener = createAction('fetchBeneficiosObtener');

const fetchRubroObtener = createAction('fetchRubroObtener');

const fetchRegionObtener = createAction('fetchRegionObtener');

const beneficiosReducer = createSlice({
  name: 'beneficios',
  initialState,
  reducers: {
    fetchBeneficiosObtenerSuccess,
    fetchBeneficiosObtenerError,
    fetchRubroObtenerSuccess,
    fetchRubroObtenerError,
    fetchRegionObtenerSuccess,
    fetchRegionObtenerError,
  },
});

export const beneficiosActions = {
  ...beneficiosReducer.actions,
  fetchBeneficiosObtener,
  fetchRubroObtener,
  fetchRegionObtener,
};

export default beneficiosReducer.reducer;
