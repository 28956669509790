import React from 'react';
import PropTypes from 'prop-types';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Select from '@coopeuch-components/web/atoms/Select';
import Skeleton from 'react-loading-skeleton';
import BeneficiosLoading from './BeneficiosLoading';
import BeneficioCard from './BeneficioCard';
import MessageSinResultados from './MessageSinResultados';
// Styles
import { CustomStyles } from './styles';

const Index = ({
  resultados,
  loading,
  rubro,
  region,
  selectStateRegion,
  handleChangeSelectRegion,
  selectStateRubro,
  handleChangeSelectRubro,
}) => {
  // console.log({ resultados });
  return (
    <CustomStyles>
      <div className="row bmt-3 bmt-md-4 beneficios_Container">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="new-header-padding template_beneficios">
              {loading ? (
                <Skeleton width="55%" height={12} />
              ) : (
                'Conoce todos los descuentos en comercios asociados.'
              )}
            </CardBox.Header>
            <CardBox.Body>
              <div id="selects" className="row">
                <div className="col-xs-12 col-md-4 bmb-3 bmr-md-3">
                  {loading ? (
                    <Skeleton width="65%" height={50} />
                  ) : (
                    <Select
                      id="categoria"
                      textSize={14}
                      rounded={4}
                      placeholder="Buscar por categoría"
                      value={selectStateRubro || ''}
                      options={rubro}
                      onChange={handleChangeSelectRubro}
                    />
                  )}
                </div>
                <div className="col-xs-12 col-md-4 bmb-3">
                  {loading ? (
                    <Skeleton width="65%" height={50} />
                  ) : (
                    <Select
                      textSize={14}
                      rounded={4}
                      placeholder="Buscar por región"
                      value={selectStateRegion || ''}
                      options={region}
                      onChange={handleChangeSelectRegion}
                    />
                  )}
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div id="resultado" className="row">
        {resultados != null && resultados.length !== 0 ? (
          resultados.map((resultado, i) => <BeneficioCard key={i} {...{ resultado, loading }} />)
        ) : resultados == null ? (
          <BeneficiosLoading />
        ) : (
          <MessageSinResultados />
        )}
      </div>
    </CustomStyles>
  );
};

Index.propTypes = {
  resultados: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool.isRequired,
  rubro: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  region: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  selectStateRegion: PropTypes.string.isRequired,
  selectStateRubro: PropTypes.string.isRequired,
  handleChangeSelectRegion: PropTypes.func.isRequired,
  handleChangeSelectRubro: PropTypes.func.isRequired,
};
Index.displayName = 'Template_Beneficios';
export default Index;
