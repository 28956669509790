import { put, call, takeLatest, spawn } from 'redux-saga/effects';

// actions
import { abonoAcreenciasRemanenteActions } from '../../../application/actions/abonoAcreenciasRemanente';

// api
import api from '../../api';

// utils
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';

const apiPoderEspecial = api(process.env.REACT_APP_PODER_ESPECIAL);


export function* fetchBannerPoderEspecial() {
  try {
    const { data, error } = yield call(apiPoderEspecial.get, 'v1/banner');
    console.log('data_banner: ', data)
    if (data) {
      yield put(abonoAcreenciasRemanenteActions.fetchBannerPoderEspecialSucces(data));
    } else {
      yield put(abonoAcreenciasRemanenteActions.fetchBannerPoderEspecialError());
    }
  } catch (error) {
    yield put(abonoAcreenciasRemanenteActions.fetchBannerPoderEspecialError());
  }
}

export function* fetchPoderEspecialValidar(action) {
  const { codigoBanco, codigoTipoCuenta, numeroCuenta } = action.payload;
  yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialValidarLoading());
  try {
    const { data, error } = yield call(apiPoderEspecial.post, 'v1/validar', {
      codigoBanco,
      codigoTipoCuenta,
      numeroCuenta
    });
    if (data) {
      yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialValidarSuccess({ data }));
    }
    if (error) {
      yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialValidarServerError());
    }
  } catch (_) {
    yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialValidarServerError());
  }
}

export function* fetchPoderEspecialFirmar(action) {
  const { codigoBanco, codigoTipoCuenta, numeroCuenta, clave, email,  } = action.payload;
  yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmarLoading());
  try {
    const { data, error } = yield call(apiPoderEspecial.post, 'v1/firmar', {
      codigoBanco,
      codigoTipoCuenta,
      numeroCuenta,
      clave,
      email
    });
    console.log('error_firmar: ', error)
    if (data) {
      yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmarSuccess({ data }));
    }
    if (error) {
      yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmarError(error));
    }
    if (!data && !error) {
      yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmarServerError());
    }
  } catch (_) {
    yield put(abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmarServerError());
  }
}

export function* fetchDescargarComprobantePoderEspecial() {
  try {
    yield put(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecialLoading());
    const {data, error} = yield call(apiPoderEspecial.get, 'v1/comprobante', null);
    console.log('error_comprobante 1: ', error)
    if (data) {
      downloadPDFBase64OtherTab(data.contenido, data.nombre);
    }
    if (error) {
      yield put(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecialServerError(error));
    }
    if (!data && !error) {
      yield put(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecialServerError(error));
    }
  } catch (_) {
    yield put(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecialServerError(_));
  }
}


export function* watchFetchBannerPoderEspecial() {
  yield takeLatest(abonoAcreenciasRemanenteActions.fetchBannerPoderEspecial.type, fetchBannerPoderEspecial);
}

export function* watchFetchPoderEspecialValidar() {
  yield takeLatest(abonoAcreenciasRemanenteActions.fetchPoderEspecialValidar.type, fetchPoderEspecialValidar);
}

export function* watchFetchPoderEspecialFirmar() {
  yield takeLatest(abonoAcreenciasRemanenteActions.fetchPoderEspecialFirmar.type, fetchPoderEspecialFirmar);
}

export function* watchFetchDescargarComprobantePoderEspecial() {
  yield takeLatest(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecial.type, fetchDescargarComprobantePoderEspecial);
}

export default function* rootSaga() {
  yield spawn(watchFetchBannerPoderEspecial);
  yield spawn(watchFetchPoderEspecialValidar);
  yield spawn(watchFetchPoderEspecialFirmar);
  yield spawn(watchFetchDescargarComprobantePoderEspecial);
}