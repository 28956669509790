import React from 'react';
// Components
import InfoBonosIngreso from '../InfoBonosIngreso';
import InfoBonosTitulacion from '../InfoBonosTitulacion';
import { Message } from '@design-system-coopeuch/web';
// Text
import RULES_TYPE_USER_TEXT from '../../../../../containers/BonoEscolar/Home/texts';
// Styles
import Wrapper from './styles';
import BannerIframe from '../../../BannerIframe';

const InfoBonos = ({ handleClickBtn, userType, handleClickCuentaVista }) => {
    const cardMessage = userType?.data?.regla === 'ANTIGUEDAD' || userType?.data?.regla === 'MORA_ACTUAL' || userType?.data?.regla === 'MORA_6_MESES' || userType?.data?.regla === 'CLIENTE' || userType?.data?.regla === 'SOCIO_COLABORADOR';
    return (
        <Wrapper>
            {userType?.data?.regla === 'SIN_CUENTA_VISTA' && (
                <BannerIframe 
                title="Contrata tu Cuenta Vista Coopeuch para acceder a estos beneficios"
                buttonName="Contratar"
                handleShowIframe={handleClickCuentaVista}
                />
            )}
            <div className='row'>
                <div className='col-md-6 col-12 mt-3'>
                    <InfoBonosIngreso {...{ handleClickBtn, userType}} />
                </div>
                <div className='col-md-6 col-12 mt-3'>
                    <InfoBonosTitulacion {...{ handleClickBtn, userType }} />
                </div>
            </div>
            {cardMessage && (
                <div className="bonoSolicitar__content__alert mt-3">
                    <Message
                        description={RULES_TYPE_USER_TEXT[userType?.data?.regla]?.description}
                        type={RULES_TYPE_USER_TEXT[userType?.data?.regla]?.typeMessage}
                    />
                </div>
            )}
            <div className="footerInformativo">
                <p>
                    * Coopeuch se reserva el derecho de cambiar las condiciones vigentes sin previo aviso.
                </p>
                <p>* Todas las solicitudes serán evaluadas en base a documentación recibida.</p>
                <p>* Coopeuch se reserva el derecho a aceptar o rechazar las solicitudes.</p>
                <p>
                    * Socios pueden acceder solo a un bono ingreso, lo mismo que para cada uno de sus hijos.
                </p>
                <p>* Solo se bonifican las carreras de pregrado presenciales, semipresenciales u online.</p>
            </div>
        </Wrapper>
    );
};

export default InfoBonos;
