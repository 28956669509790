import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/tarjetaCoordenada';

function fetchTCoordenadaObtenerSuccess(state, action) {
  state.tCoordenadaObtener = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchTCoordenadaObtenerError(state) {
  state.tCoordenadaObtener = {
    success: false,
    error: true,
    errorCode: '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchCambioEstadoTarjetaCoordenadasSuccess(state, action) {
  const { cambioEstadoTarjetaCoordenadas } = action.payload;
  state.cambioEstadoTarjetaCoordenadas = cambioEstadoTarjetaCoordenadas;
  state.cambioEstadoTarjeta = true;
  state.errorCambioEstadoTjCoord = false;
}

function fetchCambioEstadoTarjetaCoordenadasFailed(state, action) {
  const { mensajeError } = action.payload;
  state.cambioEstadoTarjetaCoordenadas = {};
  state.mensajeErrorCambioEstadoTjCoordenadas = mensajeError;
  state.errorCambioEstadoTjCoord = true;
  state.cambioEstadoTarjeta = false;
}

function clearEstadoTarjetaCoordenada(state){
  state.cambioEstadoTarjetaCoordenadas = {};
  state.mensajeErrorCambioEstadoTjCoordenadas = '';
  state.errorCambioEstadoTjCoord = false;
  state.cambioEstadoTarjeta = false;
}

const fetchTCoordenadaObtener = createAction('fetchTCoordenadaObtener');
const fetchCambioEstadoTarjetaCoordenadas = createAction('fetchCambioEstadoTarjetaCoordenadas');

const tCoordenadaReducer = createSlice({
  name: 'tarjetaCoordenada',
  initialState,
  reducers: {
    fetchTCoordenadaObtenerSuccess,
    fetchTCoordenadaObtenerError,
    fetchCambioEstadoTarjetaCoordenadasSuccess,
    fetchCambioEstadoTarjetaCoordenadasFailed,
    clearEstadoTarjetaCoordenada,
  },
});

export const tCoordenadaActions = {
  ...tCoordenadaReducer.actions,
  fetchTCoordenadaObtener,
  fetchCambioEstadoTarjetaCoordenadas,
};

export default tCoordenadaReducer.reducer;
