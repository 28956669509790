import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Skeleton from 'react-loading-skeleton';
import Typography from '@coopeuch-components/web/atoms/Typography';
import {
     ButtonWrapper,
     ContainerTipoCredito,
   } from '../DetalleCredito/styles';
const Index = () => {
  return (
    <>
      <div className="col-md-6 col-xs-12">
        <div className="w-100 h-100 bpr-md-1">
          <CardBox className="h-100 bmr-md-1">
            <CardBox.Body>
              <ContainerTipoCredito>
                <div className="row">
                  <div className="col-xs-12 bmb-3">
                    <Skeleton height={68} />
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      <Skeleton width={'70%'} />
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={20}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      <Skeleton width={'60%'} />
                    </Typography>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      <Skeleton width={'70%'} />
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={20}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      <Skeleton width={'60%'} />
                    </Typography>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      <Skeleton width={'70%'} />
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={16}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      <Skeleton width={'60%'} />
                    </Typography>
                  </div>

                  <div className="col-xs-12 col-md-6">
                    <Typography tag="p" color="#333333" fontSize={16}>
                      <Skeleton width={'70%'} />
                    </Typography>

                    <Typography
                      className="bmb-3"
                      tag="p"
                      color="#333333"
                      fontSize={16}
                      fontWeight={700}
                      fontFamily="ubuntu-bold"
                    >
                      <Skeleton width={'60%'} />
                    </Typography>
                  </div>
                </div>
              </ContainerTipoCredito>
            </CardBox.Body>
          </CardBox>
        </div>{' '}
      </div>
      <div className="col-md-6 col-xs-12">
        <div className="w-100 h-100 bpr-md-1">
          <CardBox className="h-100 bmr-md-1">
            <CardBox.Body>
              <div className="row align-middle">
                <div className="col-xs-12">
                  <Typography className="mb-20" tag="p" color="#333333" fontSize={16}>
                    <Skeleton width={'80%'} />
                  </Typography>
                  <Typography className="mb-20" tag="p" color="#333333" fontSize={16}>
                    <Skeleton width={'60%'} />
                  </Typography>

                  <Typography className="mb-20" tag="p" color="#333333" fontSize={16}>
                    <Skeleton width={'90%'} />
                  </Typography>
                  <Typography tag="p" color="#333333" fontSize={16}>
                    <Skeleton width={'60%'} />
                  </Typography>
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>

      <div className="col-xs-12">
        <ButtonWrapper>
          <Skeleton width={'25%'} height={48} />
        </ButtonWrapper>
      </div>
    </>
  );
};

export default Index;
