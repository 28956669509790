import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Error from '../../atoms/Error';

// Utils
import addClass from './addClass';
// styles
import BaseDesktop, {
  BaseMoblieProgress,
  BaseContentMobile,
  BaseIconStep,
  BaseProgress,
  BaseLastStep,
  BaseLoader,
  BaseMobile,
} from './styles';

const Steps = (props) => {
  const { className, steps, position, barHeight, id, progressOutContext } = props;
  const activeColor = '#F42534';
  const stepsContent = steps.map((step, index) => {
    return (
      <div
        className={`step-title-container-responsive${index > position - 1 ? '-inactive' : ''}`}
        key={`key-step-contratacion-${step.replace(' ', '')}`}
      >
        {`${index + 1}. ${step}`}
      </div>
    );
  });
  const _children = Children.toArray(stepsContent);
  return (
    <>
      <BaseDesktop id={`d-${id}`} className={className}>
        {_children.map((child, index) => (
          <div key={`step${index}`}>
            <BaseLoader activeColor={activeColor}>
              <BaseIconStep activeColor={activeColor} isAtived={index < position} />
              <BaseProgress
                activeColor={activeColor}
                barHeight={barHeight}
                className={
                  addClass([index <= position - 1 && 'anim-progress ']) +
                  addClass([
                    index === position - 1 && position !== _children.length && 'init-progress',
                  ])
                }
              />
              {index === _children.length && (
                <BaseLastStep activeColor={activeColor} isActive={_children.length < position} />
              )}
            </BaseLoader>
            {child}
          </div>
        ))}
      </BaseDesktop>
      <BaseMobile id={`m-${id}`}>
        <BaseLoader activeColor={activeColor}>
          <BaseIconStep activeColor={activeColor} isAtived />
          <BaseMoblieProgress
            activeColor={activeColor}
            barHeight={barHeight}
            position={position}
            step={steps.length}
            progressOutContext={progressOutContext}
          />
          <BaseLastStep activeColor={activeColor} isActive={_children.length === position} />
        </BaseLoader>
        <BaseContentMobile>
          <div>{_children[position - 1]}</div>
          <div>
            <label className="step-mobile-count">
              {position}/{steps.length}
            </label>
          </div>
        </BaseContentMobile>
      </BaseMobile>
    </>
  );
};

Steps.propTypes = {
  activeColor: PropTypes.string,
  barHeight: PropTypes.number,
  position: PropTypes.number,
  id: PropTypes.string,
};

Steps.defaultProps = {
  activeColor: '',
  barHeight: 2,
  position: 1,
  id: '',
};

export default Steps;

export const RenderStep = ({ step, children, error, errorComponent, errorText }) => {
  if (!error) {
    return children.length ? children[step] : children;
  } else {
    if (errorComponent) return { errorComponent };
    else return <Error className="bmt-3 bmt-md-4" {...{ text: errorText }} />;
  }
};
