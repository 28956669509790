import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Switch, Route, Redirect, useRouteMatch } from 'react-router';


import DatosBancarios from '../DatosBancarios';
import Autorizacion from '../Autorizacion';
import Comprobante from '../Comprobante';

const StepperRouter = (props) => {
const match = useRouteMatch();
  

  return (
    <Switch>
      <Route exact path={`${match?.url}/datos-bancarios`} render={() => <DatosBancarios {...props} />} />
      <Route exact path={`${match?.url}/autorizacion`} render={() => <Autorizacion {...props} />} />
      <Route exact path={`${match?.url}/comprobante`} render={() => <Comprobante {...props} />} />
      <Redirect path={`${match?.path}/`} to={`${match?.path}/`} />
    </Switch>
  );
};

export default StepperRouter;
