export const colors = {
  middleWhite: '#F2F2F2',
  lightGrey: '#D8D8D8',
  darkGrey: '#999',
  white: '#fff',
  black: '#383638',
  grey: '#E4E5ED',
  red: '#E81E2B',
  redErrorInputColor:'#CC284E',
  darkRed: '#780004',
  blueInputColor: '#007DB7',
  greyInputColor: '#646569'
};

export const textsColors = {
  inputs: {
    notSelectable:"#9C9A9F"
  }
};

export const fonts = {
  small: 14,
  normal: 16,
  medium: 18,
  big: 20,
  fontFamily: {
    REGULAR: 'Ubuntu-Regular',
    MEDIUM: 'Ubuntu-Medium',
    LIGTH: 'Ubuntu-Light',
    BOLD: 'Ubuntu-Bold',
  },
};

export const radius = '4px'