/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';
import initState from '../../../domain/entities/cuentaAhorro';

// ---------------- Saga's response ----------------
function fetchCuentasAhorroSuccess(state, action) {
  const { cuentas } = action.payload;

  state.cuentas = cuentas;
  state.errorFetchCuentas = false;
  state.errCuentasLog = null;
}

function fetchCuentasAhorroFailed(state, action) {
  const { error } = action.payload;

  state.cuentas = [];
  state.errorFetchCuentas = true;
  state.errCuentasLog = error;
}

function fetchMovimientosCuentasAhorroSuccess(state, action) {
  const { movimientos } = action.payload;

  state.movimientos = movimientos;
  state.errorFetchMovimientos = false;
  state.errMovimientosLog = null;
}

function fetchMovimientosCuentasAhorroFailed(state, action) {
  const { error } = action.payload;

  state.movimientos = [];
  state.errorFetchMovimientos = true;
  state.errMovimientosLog = error;
}

function fetchDetalleCuentasAhorroSuccess(state, action) {
  const { detalle } = action.payload;
  state.detalle = detalle;
  state.errorFetchDetalle = false;
  state.errDetallesLog = null;
}

function fetchDetalleCuentasAhorroFailed(state, action) {
  const { error } = action.payload;

  state.detalle = {};
  state.errorFetchDetalle = true;
  state.errDetallesLog = error;
}

function cleanErrorStatusCuentaAhorro(state) {
  state.errorFetchMovimientos = false;
  state.errorFetchCuentas = false;
  state.errorFetchDetalle = false;
}

function fetchPDFMovimientosCuentaAhorroSuccess(state, action) {
  state.pdfMovements = { success: true, error: false, data: action.payload.data };
}

function fetchPDFMovimientosCuentaAhorroError(state) {
  state.pdfMovements = { success: false, error: true, data: null };
}

function resetDataPDFMovimientos(state) {
  state.pdfMovements = { success: false, error: false, data: null };
}

function fetchContratacionValidarSuccess(state, action) {
  state.contratacionValidar = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}
function fetchContratacionValidarError(state) {
  state.contratacionValidar = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchGetContratosByCuentaSuccess(state, action) {
  state.documentsPDF = {
    success: true,
    error: false,
    data: action.payload.data,
  };
}

function fetchGetContratosByCuentaError(state) {
  state.documentsPDF = {
    success: false,
    error: true,
    data: null,
  };
}

function fetchDatosPersonalesSuccess(state, action) {
  state.datosPersonales = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchDatosPersonalesError(state, action) {
  state.datosPersonales = {
    success: false,
    error: true,
    errorCode: action.payload.error || '',
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchContratGenerarSuccess(state, action) {
  state.contratacionGenerar = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
    errorCode: '',
  };
}

function fetchContratGenerarError(state, action) {
  state.contratacionGenerar = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
    errorCode: action.payload.error,
  };
}

function cleanContratGenerar(state) {
  state.contratacionGenerar = {
    success: false,
    error: false,
    data: null,
    date: null,
    errorCode: '',
  };
}

function fetchContratacionIniciarSuccess(state, action) {
  state.contratacionIniciar = {
    success: true,
    error: false,
    data: action.payload.data,
    errorCode: '',
  };
}

function fetchContratacionIniciarError(state, action) {
  state.contratacionIniciar = {
    success: false,
    error: true,
    data: null,
    errorCode: action.payload.error,
  };
}

function fetchContratacionIniciarReset(state) {
  state.contratacionIniciar = {
    success: false,
    error: false,
    data: null,
    errorCode: '',
  };
}

function fetchMigracionIniciarSuccess(state, action) {
  state.migracionIniciar = {
    success: true,
    error: false,
    data: action.payload.data,
    errorCode: '',
  };
}

function fetchMigracionIniciarError(state, action) {
  state.migracionIniciar = {
    success: false,
    error: true,
    data: null,
    errorCode: action.payload.error,
  };
}

function fetchMigracionIniciarReset(state) {
  state.migracionIniciar = {
    success: false,
    error: false,
    data: null,
    errorCode: '',
  };
}

function getFactorsPending(state) {
  state.factor.isFetching = true;
  state.factor.isSuccess = false;
  state.factor.isError = false;
}

function getFactorsSuccess(state, { payload }) {
  if (payload.passCoopeuch) {
    state.factor.hasCpass = true;
  }
  if (payload.tarjetaCoordenadas) {
    state.factor.hasTcoo = true;
  }
  state.factor.isFetching = false;
  state.factor.isSuccess = true;
  state.factor.isError = false;
}

function getFactorsError(state) {
  state.factor.isFetching = false;
  state.factor.isSuccess = false;
  state.factor.isError = true;
}

function initializeCpassPending(state) {
  state.cpass.isFetchingInitialize = true;
  state.cpass.isSuccessInitialize = false;
  state.cpass.isErrorInitialize = false;
}

function initializeCpassSuccess(state) {
  state.cpass.isFetchingInitialize = false;
  state.cpass.isSuccessInitialize = true;
  state.cpass.isErrorInitialize = false;
}

function initializeCpassError(state) {
  state.cpass.isFetchingInitialize = false;
  state.cpass.isSuccessInitialize = false;
  state.cpass.isErrorInitialize = true;
}

function validateCpassPending(state) {
  state.cpass.isFetchingValidate = true;
  state.cpass.isSuccessValidate = false;
  state.cpass.isErrorValidate = false;
}

function setCpassPolling(state, { payload }) {
  state.cpass.isPolling = payload;
}

function validateCpassSuccess(state, { payload }) {
  switch (payload) {
    case 0:
      state.cpass.isValid = true;
      break;
    case 7:
      state.cpass.isBlocked = true;
      break;
    default:
      state.cpass.isInvalid = true;
      break;
  }
  state.cpass.isPolling = false;
  state.cpass.isFetchingValidate = false;
  state.cpass.isSuccessValidate = true;
  state.cpass.isErrorValidate = false;
}

function validateCpassError(state) {
  state.cpass.isPolling = false;
  state.cpass.isFetchingValidate = false;
  state.cpass.isSuccessValidate = false;
  state.cpass.isErrorValidate = true;
}

function resetAuthorization(state) {
  // estado inmutable
  // eslint-disable-next-line prefer-destructuring
  const isBlocked = state.cpass.isBlocked;

  // Create a new state object with the updated properties
  return {
    ...state,
    factor: initState.factor,
    cpass: {
      ...initState.cpass,
      // eslint-disable-next-line object-shorthand
      isBlocked: isBlocked, // Preserve the isBlocked value
    },
  };
}

function validarDesafioTcooResponse(state, action) {
  const { validacionFactorDeSeguridad } = action.payload;

  state.factor.validacionFactorDeSeguridad = validacionFactorDeSeguridad;
  state.factor.primerFactorValidado = 2.1;
}

function validarUsingTcooFailed(state, action) {
  const { error, type } = action.payload;

  state[type || 'errorFactoresMessage'] = error;
  state.factor.errorFactorLog = action.payload.errorFactorLog;
}

function fetchExecutiveNameSuccess(state, action) {
  const { data } = action.payload
  state.executiveCode.executiveName = data.nombreEjecutivo
  state.executiveCode.success =  true
  state.executiveCode.error = false
  state.executiveCode.errorCode = ''
  state.executiveCode.errorMessage = ''
}

function fetchExecutiveNameFailed( state, action ) {
  const {error} = action.payload
  state.executiveCode.executiveName = ''
  state.executiveCode.success =  false
  state.executiveCode.error = true
  state.executiveCode.errorCode = error.code
  state.executiveCode.errorMessage = error.message
}

function resetExecutiveName( state ) {
  state.executiveCode.executiveName = ''
  state.executiveCode.success =  false
  state.executiveCode.error = false
}


/*
 * Here you should create actions that won't intereact with the store.
 * Prefer for actions that will comunicate with Saga
 */

const fetchCuentasAhorro = createAction('fetchCuentasAhorro');
const fetchMovimientosCuentasAhorro = createAction('fetchMovimientosCuentasAhorro');
const fetchDetalleCuentasAhorro = createAction('fetchDetalleCuentasAhorro');
const fetchPDFMovimientosCuentaAhorro = createAction('fetchPDFMovimientosCuentaAhorro');
const fetchContratacionValidar = createAction('fetchContratacionValidar');
const fetchGetContratosByCuenta = createAction('fetchGetContratosByCuenta');
const fetchGetPDFGenerar = createAction('fetchGetPDFGenerar');
const fetchDatosPersonales = createAction('fetchDatosPersonales');
const fetchContratacionGenerar = createAction('fetchContratacionGenerar');
const fetchContratacionIniciar = createAction('fetchContratacionIniciar');
const fetchMigracionIniciar = createAction('fetchMigracionIniciar');
const getFactors = createAction('cuentaAhorro/getFactors');
const initializeCpass = createAction('cuentaAhorro/initializeCpass');
const validateCpass = createAction('cuentaAhorro/validateCpass');
const validarDesafioTcoo = createAction('cuentaAhorro/validarDesafioTcoo');
const fetchExecutiveName = createAction('cuentaAhorro/fetchExecutiveName')

// ------------- connection ------------- //
const cuentaAhorroReducer = createSlice({
  name: 'cuentaAhorroEntity',
  initialState: initState,
  reducers: {
    fetchCuentasAhorroSuccess,
    fetchCuentasAhorroFailed,
    fetchMovimientosCuentasAhorroSuccess,
    fetchMovimientosCuentasAhorroFailed,
    fetchDetalleCuentasAhorroSuccess,
    fetchDetalleCuentasAhorroFailed,
    cleanErrorStatusCuentaAhorro,
    fetchPDFMovimientosCuentaAhorroSuccess,
    fetchPDFMovimientosCuentaAhorroError,
    resetDataPDFMovimientos,
    fetchContratacionValidarSuccess,
    fetchContratacionValidarError,
    fetchGetContratosByCuentaSuccess,
    fetchGetContratosByCuentaError,
    fetchDatosPersonalesSuccess,
    fetchDatosPersonalesError,
    fetchContratGenerarSuccess,
    fetchContratGenerarError,
    cleanContratGenerar,
    fetchContratacionIniciarSuccess,
    fetchContratacionIniciarError,
    fetchContratacionIniciarReset,
    fetchMigracionIniciarSuccess,
    fetchMigracionIniciarError,
    fetchMigracionIniciarReset,
    getFactorsPending,
    getFactorsSuccess,
    getFactorsError,
    initializeCpassPending,
    initializeCpassSuccess,
    initializeCpassError,
    validateCpassPending,
    setCpassPolling,
    validateCpassSuccess,
    validateCpassError,
    resetAuthorization,
    validarDesafioTcooResponse,
    validarUsingTcooFailed,
    fetchExecutiveNameSuccess,
    fetchExecutiveNameFailed,
    resetExecutiveName
  },
});

export const cuentaAhorroActions = {
  ...cuentaAhorroReducer.actions,
  fetchCuentasAhorro,
  fetchMovimientosCuentasAhorro,
  fetchDetalleCuentasAhorro,
  fetchPDFMovimientosCuentaAhorro,
  fetchContratacionValidar,
  fetchGetContratosByCuenta,
  fetchGetPDFGenerar,
  fetchDatosPersonales,
  fetchContratacionGenerar,
  fetchContratacionIniciar,
  fetchMigracionIniciar,
  getFactors,
  initializeCpass,
  validateCpass,
  validarDesafioTcoo,
  fetchExecutiveName
};

export default cuentaAhorroReducer.reducer;