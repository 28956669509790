import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { creditoConsumoSimuladorActions } from '../../../application/actions/creditoConsumoSimulador';

// api
import api from '../../api';

const apiCreditoConsumoSimulador = api(process.env.REACT_APP_CREDITO_CONSUMO_SIMULADOR);

export function* fetchConfiguracion() {
  try {
    const { data, error } = yield call(
      apiCreditoConsumoSimulador.get,
      'creditoconsumo/simulador/configuracion/v1/obtener'
    );
    if (data) {
      yield put(creditoConsumoSimuladorActions.fetchConfiguracionSuccess({ data }));
    } else {
      yield put(creditoConsumoSimuladorActions.fetchConfiguracionError({ error }));
    }
  } catch (error) {
    yield put(creditoConsumoSimuladorActions.fetchConfiguracionError({ error }));
  }
}

export function* fetchResultado(action) {
  try {
    const { diaPago, monto, plazo, seguros } = action.payload;
    const { data, error } = yield call(
      apiCreditoConsumoSimulador.post,
      `creditoconsumo/simulador/v1/simular`,
      {
        diaPago,
        monto,
        plazo,
        seguros,
      }
    );
    if (data) {
      yield put(creditoConsumoSimuladorActions.fetchResultadoSuccess({ data }));
    } else {
      yield put(creditoConsumoSimuladorActions.fetchResultadoError({ error }));
    }
  } catch (error) {
    console.error(error.message, 'fetchResultado');
    yield put(creditoConsumoSimuladorActions.fetchResultadoError({ error }));
  }
}

export function* fetchSolicitar(action) {
  try {
    const { email, telefono, idSimulacion } = action.payload;
    const { data, error } = yield call(
      apiCreditoConsumoSimulador.post,
      `creditoconsumo/simulador/v1/solicitar`,
      {
        email,
        telefono,
        idSimulacion,
      }
    );
    if (data) {
      yield put(creditoConsumoSimuladorActions.fetchSolicitarSuccess({ data }));
    } else {
      yield put(creditoConsumoSimuladorActions.fetchSolicitarError({ error }));
    }
  } catch (error) {
    console.error(error.message, 'fetchSolicitar');
    yield put(creditoConsumoSimuladorActions.fetchSolicitarError({ error }));
  }
}

export function* watchFetchConfiguracion() {
  yield takeLatest(creditoConsumoSimuladorActions.fetchConfiguracion.type, fetchConfiguracion);
}

export function* watchFetchResultado() {
  yield takeLatest(creditoConsumoSimuladorActions.fetchResultado.type, fetchResultado);
}

export function* watchFetchSolicitar() {
  yield takeLatest(creditoConsumoSimuladorActions.fetchSolicitar.type, fetchSolicitar);
}

export default function* rootSaga() {
  yield spawn(watchFetchConfiguracion);
  yield spawn(watchFetchResultado);
  yield spawn(watchFetchSolicitar);
}
