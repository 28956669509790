const initState = {
  regiones: { values: [], success: false, error: false },
  comunas: { values: [], success: false, error: false },
  sucursales: { values: [], success: false, data: null, error: false, errorCode: '', date: null },
  sucursalesComuna:{ success: false, data: null, error: false, errorCode: '', date: null },
  coberturaComuna: { values: [], success: false, error: false },
  motivoTarjetaBloqueo: { values: [], success: false, error: false },
};

export default initState;
