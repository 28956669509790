import React from 'react';
import Card from '@coopeuch-components/web/atoms/Card';
import { Text } from './styles';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({ className }) => {
    return (
        <Card className={className}>
            <div className="center-xs bmt-4 bmb-3">
                <div className="row center-xs bmt-4 bmb-3">
                    <img src={`${iconPath}ilustracion_info.svg`} alt="login-error" />
                </div>
                <div className="mb-1">
                    <Text textSize={24} size={32} weight="bold">
                        Atención Socio
                    </Text>
                </div>
                <div className="col-xs-12 col-md-8 col-md-offset-2">
                    <Text textSize={18} size={28} weight="normal">
                        Para información de Remanente del ejercicio 2023, estamos a la espera de los resultados de la Asamblea a realizarse el 10 de abril de 2024.
                    </Text>
                    <Text textSize={18} size={28} weight="normal">
                        Gracias por su comprensión.
                    </Text>
                </div>
            </div>
        </Card>
    );
};

export default Index;
