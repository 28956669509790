import styled, { css } from 'styled-components';

const baseStyles = css`
  padding: 0;
  margin: 0;
  text-align: ${({ align }) => align};
  ${({ color }) => color && `color: ${color};`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily};`}
`;

export const H1 = styled.h1`
  font-weight: ${({ fontWeight }) => fontWeight || 500};
  font-size: ${({ fontSize }) => `${fontSize || 36}px`};
  ${({ lineHeight }) => lineHeight && 'line-height: 2.875rem;'}
  ${baseStyles};
`;

export const H2 = styled.h2`
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${({ fontSize }) => `${fontSize || 32}px`};
  ${({ lineHeight }) => lineHeight && 'line-height: 2.625rem;'}
  ${baseStyles};
`;

export const H3 = styled.h3`
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${({ fontSize }) => `${fontSize || 28}px`};
  ${({ lineHeight }) => lineHeight && 'line-height: 2.375rem;'}
  ${baseStyles};
`;

export const H4 = styled.h4`
  font-weight: ${({ fontWeight }) => fontWeight || 600};
  font-size: ${({ fontSize }) => `${fontSize || 24}px`};
  ${({ lineHeight }) => lineHeight && 'line-height: 2.125rem;'}
  ${baseStyles};
`;

export const H5 = styled.h5`
  font-weight: ${({ fontWeight }) => fontWeight || 300};
  line-height: 1.875rem;
  font-size: ${({ fontSize }) => `${fontSize || 20}px`};
  ${baseStyles};
`;

export const H6 = styled.h6`
  font-weight: ${({ fontWeight }) => fontWeight || 300};
  line-height: 1.75rem;
  font-size: ${({ fontSize }) => `${fontSize || 18}px`};
  ${baseStyles};
`;

const Paragraph = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight || 300};
  font-size: ${({ fontSize }) => `${fontSize || 16}px`};
  ${({ lineHeight }) => lineHeight && 'line-height: 1.625rem;'}
  ${baseStyles};
`;

export default Paragraph;
