import React from 'react';
// Components
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Organism
import DashboardCard from '../../../organism/DashboardCard';
import Button from '@coopeuch-components/web/atoms/Button';
// Texts
import { puntosCoopeuchTexts } from '../../../../containers/Dashboard/texts';
// Styles
import { colors } from '../../../../assets/styles';
import Wrapper from './styled';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SocioSinPuntos = ({ handleClickAcumular }) => {
  return (
    <div>
      <Wrapper>
        <DashboardCard className="dashboard-card-shadow">
          <DashboardCard.Body classes="dashboard-puntos dashboard-custom">
            <div className="dashboard-puntos-body">
              <div className="dashboard-puntos__icon">
                <LoadIcon
                  src={`${iconPath}icon_puntoscoo_dashboard.svg`}
                  size={30}
                  color="#1E1E1E"
                  className="access-icon"
                />
                <p className="dashboard-puntos__title">
                  Tienes <strong>0 Puntos Coopeuch</strong>
                </p>
                <div className="dashboard-puntos__puntos-clp">
                  <label>{puntosCoopeuchTexts.PTSCLP}</label>
                </div>
              </div>

              <div className="dashboard-puntos__info">
                <a
                  className="acumularOcanjear"
                  href="https://puntoscoopeuch.cl/index.php?route=common/home/comoganarpts"
                  target="_blank"
                >
                  <Button
                    onClick={handleClickAcumular}
                    asTextLink
                    iconRight={
                      <LoadIcon src={`${iconPath}arrow_right.svg`} color={colors.red} size={22} />
                    }
                    className="btnPuntos"
                    color={colors.red}
                  >
                    {puntosCoopeuchTexts.ACUMULAR_PTS}
                  </Button>
                </a>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </Wrapper>
    </div>
  );
};

export default SocioSinPuntos;
