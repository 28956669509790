export const listarDestinatariosTexts = {
  TITLE: 'Destinatarios autorizados',
  BUTTON_TEXT: 'Agregar nuevo destinatario',
  BODY_INPUT_PLACEHOLDER: 'Buscar Destinatarios',
  BODY_NO_DESTINATARIO_TITLE: 'No posees ningun destinatario',
  BODY_NO_DESTINATARIO_1: 'Te invitamos a agregar a todos tus destinatarios, presionando el botón ',
  BODY_NO_DESTINATARIO_2: 'agregar destinatarios',
  BODY_NO_DESTINATARIO_3: ',  para poder realizar transferencias',
  ERROR_DESTINATARIO_TITLE: '¡Lo sentimos!',
  ERROR_DESTINATARIO_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  ERROR_BUTTON_TITLE: 'Volver a intentar',
  MODAL_BUTTON_VOLVER: 'Volver',
  MODAL_BUTTON_ELIMINAR: 'Eliminar',
  MODAL_ELIMINAR_TITLE: '¿Estás seguro de que deseas eliminar al destinatario?',
  WARNING_CREATED: 'El nuevo destinatario ha sido agregado con éxito',
  WARNING_EDITED: 'El destinatario ha sido modificado con éxito',
  NEW_DESTINATARIO_INFO:
    'Por tu seguridad, la primera transferencia tiene un limite máximo de $250.000. Para volver a transferir a un nuevo destinatario debes esperar 24 horas.',
  NEW_DESTINATARIO_INFO_VI06:
    'Por tu seguridad, la primera transferencia que hagas a un nuevo destinatario tiene un límite máximo de $250.000. 24 horas después de esa transacción, puedes transferir el monto diario permitido de $1.000.000',
  ERROR_MODAL_TITLE: '¡Lo sentimos!',
  ERROR_MODAL_DESC:
    'Lamentablemente no pudimos eliminar al destinatario en este momento. Puedes volver a intentarlo en otro momento.',
};
export default listarDestinatariosTexts