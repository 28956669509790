import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import { creditoHipotecarioActions } from '../../../application/actions/creditoHipotecario';

import PageTitle from '../../components/atoms/PageTitle';

import Payment from './Payment';
import Landing from './Landing';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CreditoHipotecario = () => {
  const dispatch = useDispatch();

  const [paymentActive, setPaymentActive] = useState(false);
  const [paymentSuccesful, setPaymentSuccesful] = useState(false);

  useEffect(() => {
    dispatch(creditoHipotecarioActions.fetchAvailableAccounts());
  }, [dispatch]);

  const goToPayment = () => {
    setPaymentActive(true);
  };

  const onGoBack = () => {
    setPaymentSuccesful(false);
    setPaymentActive(false);
  };

  const onPaymentSuccess = () => {
    setPaymentSuccesful(true);
    setPaymentActive(false);
  };

  return (
    <>
      <PageTitle
        title="Crédito Hipotecario"
        icon={<LoadIcon src={`${iconPath}home_alt.svg`} color="black" />}
      />
      <div className="w-100 margin-after-title">
        {paymentActive ? (
          <Payment onGoBack={onGoBack} onPaymentSuccess={onPaymentSuccess} />
        ) : (
          <Landing goToPayment={goToPayment} paymentSuccesful={paymentSuccesful} />
        )}
      </div>
    </>
  );
};

export default CreditoHipotecario;
