import React, { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import MontoAPagar from './MontoAPagar';
import Pago from './Pago';
import Comprobante from './Comprobante';
import { useShallowStore } from '../../../utils/hooks';
import { registerGA } from '../../../utils/metrics';

const components = {
  1: MontoAPagar,
  2: Pago,
  3: Comprobante,
};

const PagarTarjetaStepper = () => {
  const [pathStep, setPathStep] = useState(1);

  const [tipoMonto, setTipoMonto] = useState('facturado');
  const [monto, setMonto] = useState(0);
  const [email, setEmail] = useState('');
  const [codigoProductoPago, setCodigoProductoPago] = useState('');

  const history = useHistory();

  const { numeroTarjeta } = history?.location?.state || {};

  const tarjetasCredito = useShallowStore(
    ({ entities }) => entities.tarjetaCredito.tarjetasCredito
  );

  const Component = useMemo(() => components[pathStep] || MontoAPagar, [pathStep]);

  const datosTarjeta = useMemo(() => {
    return tarjetasCredito?.find(({ nroTarjeta }) => nroTarjeta === numeroTarjeta);
  }, [tarjetasCredito, numeroTarjeta]);

  const handleMonto = useCallback((monto) => setMonto(monto), [setMonto]);
  const handleTipoMonto = useCallback((tipo) => setTipoMonto(tipo), [setTipoMonto]);
  const handleEmail = useCallback((email) => setEmail(email), [setEmail]);
  const handleCodigoProductoPago = useCallback(
    (codigo) => setCodigoProductoPago(codigo),
    [setCodigoProductoPago]
  );

  const handleNextStep = useCallback(() => {
    const nextStep = pathStep + 1;

    if (nextStep > 3) return history.push('/ecd/productos/tarjeta-de-credito');
    setPathStep(nextStep);
  }, [pathStep, setPathStep]);

  const handlePrevStep = useCallback(() => {
    const previusStep = pathStep - 1;

    if (pathStep === 2) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/tarjeta-de-credito/pagar/paso-2',
        'eve-cat': 'Click',
        'eve-lab': 'Volver',
      });
    }

    if (previusStep < 0) return history.goBack();
    setPathStep(previusStep);
  }, [pathStep, setPathStep, history]);

  return (
    <Component
      history={history}
      monto={monto}
      email={email}
      datosTarjeta={datosTarjeta}
      tipoMonto={tipoMonto}
      codigoProductoPago={codigoProductoPago}
      handleMonto={handleMonto}
      handleTipoMonto={handleTipoMonto}
      handleEmail={handleEmail}
      handleCodigoProductoPago={handleCodigoProductoPago}
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
    />
  );
};

export default PagarTarjetaStepper;
