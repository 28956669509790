import React from 'react';
import Wrapper from './styles';



function Iframe({ tokenLegado, urlIframe, height = '1400px', params = {} }) {
    return (
        <Wrapper>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xs-12">
                        <iframe
                            src={`${urlIframe}?token=${tokenLegado}${params ? `&${params}` : ''}`}
                            frameBorder="0"
                            width={'100%'}
                            height={height}
                            params={params}
                        />
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}
export default Iframe;