import React from 'react';
import PropTypes from 'prop-types';

import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import {colors} from '../../../assets/styles'
import BaseModal, { BaseContainer, ModalBody, ModalTitle, ModalFooter } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const AlertModal = ({
  children,
  title,
  height,
  width,
  showModal,
  onClose,
  onClick,
  labelRightButton,
  labelLeftButton,
  onLoader,
  closeOnOut,
  className,
}) => {
  function onClickOut(event) {
    if (event.target.id === 'baseContainer') {
      onClose(event);
    }
  }

  return (
    <BaseContainer isOpen={showModal} className={className} onClick={closeOnOut ? onClickOut : () => {}} id="baseContainer">
      <BaseModal className="fadein-anim modalBase" isOpen={showModal} width={width}>
        {!!title ? (
          <ModalTitle>
            <div className="row">
              {title && <div className="col-xs-10 text-bold">{title}</div>}
              <div className="col-xs text-right">
                <div className="alert-modal-icon-cerrar-container">
                  <div className="alert-modal-icon-cerrar" onClick={onClose}>
                    <LoadIcon src={`${iconPath}icono_cerrar.svg`} />
                  </div>
                </div>
              </div>
            </div>
          </ModalTitle>
        ) : (
          <div className="alert-modal-icon-cerrar-container">
            <div className="alert-modal-icon-cerrar" onClick={onClose}>
              <LoadIcon src={`${iconPath}icono_cerrar.svg`} />
            </div>
          </div>
        )}

        <ModalBody height={height} width={width} className="modalBody">
          {children}
        </ModalBody>
        {labelLeftButton && labelRightButton && (
          <ModalFooter>
            <div className="row">
              <div className="col-md-6 col-xs-12 alert-modal-link-container">
                <Button
                  asTextLink
                  color="red"
                  className="alert-modal-text alert-modal-link"
                  iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                  onClick={onClose}
                >
                  {labelLeftButton}
                </Button>
              </div>
              <div className="col-md-6 col-xs-12 alert-modal-button-container">
                <Button
                  bgColor={colors.red}
                  color="white"
                  className="alert-modal-text"
                  width="160"
                  onClick={onClick}
                  disabled={onLoader}
                >
                  {labelRightButton}
                </Button>
              </div>
              <div className="col-md-6 col-xs-12 alert-modal-link-container-mobile">
                <Button
                  asTextLink
                  color="red"
                  className="alert-modal-text-responsive alert-modal-link"
                  iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                  onClick={onClose}
                >
                  {labelLeftButton}
                </Button>
              </div>
            </div>
          </ModalFooter>
        )}
      </BaseModal>
    </BaseContainer>
  );
};

AlertModal.propTypes = {
  showModal: PropTypes.bool,
  children: PropTypes.node.isRequired,
  height: PropTypes.number,
  width: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  labelRightButton: PropTypes.string,
  labelLeftButton: PropTypes.string,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  closeOnOut: PropTypes.bool,
};

AlertModal.defaultProps = {
  showModal: false,
  width: 350,
  height: 0,
  title: '',
  labelRightButton: '',
  labelLeftButton: '',
  closeOnOut: false,
  onClose: () => {},
  onClick: () => {},
};

export default AlertModal;
