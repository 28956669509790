import React from 'react';
import PropTypes from 'prop-types';
// components
import Typography from '../Typography';
// Styles
import Wrapper, { IconWrapper } from './styles';

const PageTitle = ({ fontFamily, icon, title, id, size }) => {
  // console.log({ size })
  return (
    <>
      <Wrapper className="bpl-3 bpl-md-5" id={id}>
        <IconWrapper icon={icon} size={size}>
          {icon}
        </IconWrapper>
        <Typography tag="h2" fontSize={20} fontFamily={fontFamily} lineHeight={false}>
          {title}
        </Typography>
      </Wrapper>
    </>
  );
};

PageTitle.propTypes = {
  fontFamily: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  size: PropTypes.number,
};

PageTitle.defaultProps = {
  fontFamily: '',
  title: '',
  icon: null,
  id: '',
  size: 32,
};

export default PageTitle;
