import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { WrapperSkeleton } from './styles';
import DashboardCard from '../../../organism/DashboardCard';

export const SkeletonComprobante = () => (
  <React.Fragment>
    <WrapperSkeleton className="display-desktop">
      <div className="bmt-3 bmt-md-0">
        <Skeleton height={48} />
      </div>
      <div className="bmt-3 bmt-md-4">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="comprobante-simulacion-card-body">
            <div className="row">
              <div className="col-md-3 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-9" />
              <div className="col-md-5 bmb-4">
                <Skeleton />
              </div>
              <div className="col-md-7" />
              <div className="col-md-4 bmb-4">
                <Skeleton />
              </div>
              <div className="col-md-8" />
              <div className="col-md-6">
                <Skeleton />
              </div>
              <div className="col-md-6" />
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-3 bmt-md-4">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="comprobante-simulacion-card-body">
            <div className="row">
              <div className="col-md-3 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-9" />
              <div className="col-md-12 bmb-4">
                <Skeleton height={88} />
              </div>
              <div className="col-md-12 bmb-5">
                <Skeleton height={50} />
              </div>
              <div className="col-md-12 bmb-5">
                <Skeleton height={50} />
              </div>
              <div className="col-md-12 bmb-5">
                <Skeleton height={50} />
              </div>
              <div className="col-md-12">
                <Skeleton height={50} />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-4">
        <div className="row">
          <div className="col-md-6" />
          <div className="col-md-6 btext-right">
            <Skeleton width={186} height={51} />
          </div>
        </div>
      </div>
    </WrapperSkeleton>
    <WrapperSkeleton className="display-mobile">
      <div className="bmt-3 bmt-md-0 bmb-3">
        <Skeleton height={96} />
      </div>
      <div>
        <div className="bmb-3">
          <DashboardCard classes="dashboard-card-shadow">
            <DashboardCard.Body classes="comprobante-simulacion-card-body">
              <div className="row">
                <div className="col-xs-6 bmb-5">
                  <Skeleton />
                </div>
                <div className="col-xs-6" />
                <div className="col-xs-12 bmb-2">
                  <Skeleton />
                </div>
                <div className="col-xs-9 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
                <div className="col-xs-9 bmb-3">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
                <div className="col-xs-12 bmb-2">
                  <Skeleton />
                </div>
                <div className="col-xs-9 bmb-5">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
              </div>
            </DashboardCard.Body>
          </DashboardCard>
        </div>
        <div className="bmb-3">
          <DashboardCard classes="dashboard-card-shadow">
            <DashboardCard.Body classes="comprobante-simulacion-card-body">
              <div className="row">
                <div className="col-xs-9 bmb-5">
                  <Skeleton />
                </div>
                <div className="col-xs-3" />
                <div className="col-xs-12 bmb-5">
                  <Skeleton height={84} />
                </div>
                <div className="col-xs-12 bmb-5">
                  <Skeleton height={72} />
                </div>
                <div className="col-xs-12 bmb-5">
                  <Skeleton height={72} />
                </div>
                <div className="col-xs-12 bmb-5">
                  <Skeleton height={72} />
                </div>
                <div className="col-xs-12">
                  <Skeleton height={72} />
                </div>
              </div>
            </DashboardCard.Body>
          </DashboardCard>
        </div>
        <div>
          <div className="row">
            <div className="col-xs-12">
              <Skeleton height={51} />
            </div>
          </div>
        </div>
      </div>
    </WrapperSkeleton>
  </React.Fragment>
);
