/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Ui
import Typography from '@coopeuch-components/web/atoms/Typography';
import Loading from '@coopeuch-components/web/atoms/Loading';
import Button from '@coopeuch-components/web/atoms/Button';
//import Countdown from '../CountDown';
import TimerPass from '../TimerButton/TimerPass';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import NotPassCoopeuch from '../NotPassCoopeuch';
import ModalPassCoopeuchError from '../Error/ModalError/ModalPassCoopeuchError';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Input from '../../../../components/atoms/Input';
import Countdown from '../../../organism/FactoresDeSeguridad/Utils/Countdown';
import CountdownCard from '../../../organism/FactoresDeSeguridad/Utils/CountdownCard';
import { formatSecondToMinutes } from '../../../../utils/formatters';

// Styles
import Wrapper, { CardFooter, Blocker, Overlay, InputContainer, ButtonContainer } from './styles';

// Texts
import passCoopeuch, { errores } from './text';

const pasosFactor = {
  0: 'inicio',
  1: 'ingresarCoordenadas',
  2: 'validarCoordenadas',
};

const FactorPassCoopeuch = (props) => {
  const {
    loading,
    counterActive,
    timer,
    timerValue,
    errorTimeOut,
    validarPassCoopeuchCP,
    IsAvailablePassCoopeuch,
    validateSMS,
    errorTimeOutCP,
    retryTimeOutPass,
    isVisibleModalError,
    handleClickCloseModalError,
    IsAvailableTarCoo,
    handleSwitchMethods,
    onHandleObtenerDesafio,
    IsLoading,
    desafio,
    sendDesafio,
    onHandleSetDesafio,
    tarjeta,
    isButtonDisabled,
    hasError,
    onClickButton,
    coordenadas,
    validarDesafio,
    validaDesafio,
    continuaFirma,
  } = props;
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const factor='tarjetacoord';
  let factorError=false;
  
  if(errorTimeOut){
    factorError=errorTimeOut;
  }
  if(validaDesafio.success === false){
    factorError=true;
  }
  const { desafio1, desafio2, desafio3 } = desafio || false;
  let refCoor1 = useRef();
  let refCoor2 = useRef();
  let refCoor3 = useRef();

  const handleChange = (e) => {
    const { id, value } = e.target;
    if(value.charAt(value.length - 1).charCodeAt()>47 && value.charAt(value.length - 1).charCodeAt()<58){
      if (id === 'cor1') {
        if (value.length === 2) {
          refCoor2.current.focus();
        }
      }
      if (id === 'cor2') {
        if (value.length === 2) {
          refCoor3.current.focus();
        }
      }
      onHandleSetDesafio(e);
    }
  };

  useEffect(() => {
    if (
      desafio1 &&
      desafio2 &&
      desafio3 &&
      counterActive &&
      (factorError === '4' || factorError === '5')
    ) {
    }
  }, [desafio1, desafio2, desafio3, counterActive, factorError]);

  

  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
            <CardBox.Header>
              <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
                Autoriza con Tarjetas de Coordenadas {tarjeta}
              </Typography>
            </CardBox.Header>        
            <CardBox.Body>
            {IsLoading === true ?( <div className="contentSMS1"><SpinnerLoader /></div>
              ) : (
               true ? (
                <div className="contentSMS1">
                  {errorTimeOut === false && (desafio.success === null || desafio.success === true) && (validaDesafio.success === null || validaDesafio.success === true) && (
                    <Countdown
                      ilustration="tarjeta_coordenadas"
                      withCountdownAnimation={counterActive}
                      timer={timerValue}
                      errorTimeOut={errorTimeOut}
                    />
                  )}
                  {errorTimeOut === true && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {validaDesafio.success === false && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {desafio.success === false && (
                    <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
                  )}
                  {errorTimeOut === true && 
                    <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Se agotó el tiempo!</p>}
                  {validaDesafio.success === false && (
                    <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Lo sentimos!</p>
                  )}
                  {desafio.success === false && (
                    <p className="contentSMS1__title" style={{ textAlign: "center" }}>¡Lo sentimos!</p>
                  )}
                  {errorTimeOut === true && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Tienes un tiempo limitado para autorizar, inténtalo nuevamente.
                    </p>
                  )}
                  {validaDesafio.success === false && validaDesafio.error.code === "2" && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Las coordenadas no corresponden. Por favor, intenta nuevamente.
                    </p>
                  )}
                  {desafio.success === true && (counterActive === true || errorTimeOut === true || coordenadas === true || validaDesafio.error.code === "2") && (
                    <InputContainer>
                      <div className="row">
                        <div className="col-md-4 col-xs-4 pl-12 pr-12">
                          <div className="factor-coordenadas-label-container">
                            <label htmlFor="cor1">{desafio1}</label>
                          </div>
                          <div>      
                              <Input
                                id="cor1"
                                dataSet="1"
                                name="desafio1"
                                borderColor={
                                  factorError ? 'red !important' : desafio1?.length === 2 ? '#19a1d5 ' : '#646569'
                                }
                                maxLength={2}
                                type="password"
                                className="factor-coordenadas-input"
                                value={sendDesafio.desafio1}
                                onChange={handleChange}
                                ref={refCoor1}
                                disabled={factorError}
                              />
                          </div>  
                        </div>
                        <div className="col-md-4 col-xs-4 pl-12 pr-12">
                          <div className="factor-coordenadas-label-container">
                            <label htmlFor="cor2">{desafio2}</label>
                          </div>   
                          <div> 
                              <Input
                                id="cor2"
                                dataSet="2"
                                maxLength={2}
                                type="password"
                                className="factor-coordenadas-input"
                                borderColor={
                                  factorError ? 'red !important' : desafio2?.length === 2 ? '#19a1d5 ' : '#646569'
                                }
                                value={sendDesafio.desafio2}
                                name="desafio2"
                                onChange={handleChange}
                                ref={refCoor2}
                                disabled={factorError}
                              />
                          </div>  
                          
                        </div>
                        <div className="col-md-4 col-xs-4 pl-12 pr-12">
                          <div className="factor-coordenadas-label-container">
                            <label htmlFor="cor3">{desafio3}</label>
                          </div>
                          <div>    
                              <Input
                                id="cor3"
                                dataSet="3"
                                name="desafio3"
                                maxLength={2}
                                type="password"
                                className="factor-coordenadas-input"
                                value={sendDesafio.desafio3}
                                borderColor={
                                  factorError ? 'red !important' : desafio3?.length === 2 ? '#19a1d5 ' : '#646569'
                                }
                                onChange={handleChange}
                                ref={refCoor3}
                                disabled={factorError}
                              />
                          </div>  
                        </div>
                      </div>
                    </InputContainer>
                  )}

                  {(desafio.success === null || desafio.success === true) && counterActive === false && coordenadas === false && errorTimeOut === false && validaDesafio.success === null && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.
                    </p>
                  )}
                  {(desafio.success === null || desafio.success === true) && errorTimeOut === false && (counterActive === true || coordenadas === true) && (validaDesafio.success === null || validaDesafio.success === true) && (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Ingresa las coordenadas indicadadas de tu Tarjeta de Coordenadas N° {tarjeta}
                    </p>
                  )}
                  {(validaDesafio.error.code === "5" || validaDesafio.error.code === "4") ? (
                    <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                      Tu Tarjeta de Coordenadas está bloqueada. Solicítala
                      <br /> nuevamente en App Coopeuch.
                    </p>
                  ):(desafio.success === false || (validaDesafio.success === false && validaDesafio.error.code !== "4" && validaDesafio.error.code !== "5" && validaDesafio.error.code !== "2")) ? <p className="contentSMS1__text" style={{ textAlign: "center" }}>
                    En este momento nuestro servicio no se encuentra 
                    <br />disponible. Vuelve a intentarlo mas tarde.
                  </p>:""}
                  {desafio.success === true && counterActive === true &&  (
                    <TimerPass
                      {...{
                        counterActive,
                        validarPassCoopeuchCP,
                        errorTimeOutCP,
                        factor,
                        coordenadas,
                      }}
                    />
                  )}
                  {desafio.success === null && counterActive === false && errorTimeOut === false  && validaDesafio.success === null && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      fontSize={14}
                      className="contentSMS1__btn"
                      onClick={onHandleObtenerDesafio}
                      style={{ textAlign: "center" }}
                    >
                      Autorizar
                    </Button>
                  )}
                  {desafio.success === true && coordenadas === true && errorTimeOut === false  && validaDesafio.success === null && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      fontSize={14}
                      className="contentSMS1__btn"
                      onClick={validarDesafio}
                      style={{ textAlign: "center" }}
                    >
                      Autorizar
                    </Button>
                  )}
                  {(errorTimeOut === true || validaDesafio.error.code === "2") && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      className="contentSMS1__btn"
                      style={{ textAlign: "center" }}
                      onClick={retryTimeOutPass}
                    >
                      Reintentar
                    </Button>
                  )}
                  {desafio.success === false && desafio.error.code === null && (
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      className="contentSMS1__btn"
                      style={{ textAlign: "center" }}
                      onClick={retryTimeOutPass}
                    >
                      Volver a intentar
                    </Button>
                  )}
                </div>
              ) : (
                <NotPassCoopeuch />
              ))}
              <ModalPassCoopeuchError
                visibleModal={isVisibleModalError}
                handleClickCloseModalError={handleClickCloseModalError}
              />
            </CardBox.Body>
            {IsAvailablePassCoopeuch && (
              <CardFooter>
                <button type="button" onClick={() => handleSwitchMethods('passcoopeuch')}>
                  Quiero autorizar con PassCoopeuch
                </button>
              </CardFooter>
            )}
          </CardBox>
    </Wrapper>
  );
};

FactorPassCoopeuch.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  withCountdownAnimation: PropTypes.bool,
  duration: PropTypes.number,
  timer: PropTypes.number,
  stepFactor: PropTypes.number,
  activeTimer: PropTypes.bool,
  validarPassCoopeuchCP: PropTypes.func.isRequired,
  errorTimeOutCP: PropTypes.func.isRequired,
  handleSwitchMethods: PropTypes.func.isRequired,
  onHandleObtenerDesafio: PropTypes.func.isRequired,
  onHandleSetDesafio: PropTypes.func.isRequired,
  validarDesafio: PropTypes.func.isRequired,
  continuaFirma: PropTypes.func.isRequired,
};

FactorPassCoopeuch.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  message: '',
  buttonText: '',
  withCountdownAnimation: false,
  duration: 120,
  // timer: 0,
  stepFactor: 0,
  activeTimer: false,
};

export default FactorPassCoopeuch;
