import React, { PureComponent, lazy, Suspense, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import produce from 'immer';

import { productActions } from '../../../application/actions/products';
import { cuentaAhorroActions } from '../../../application/actions/cuentaAhorro';
import { factoresSeguridadActions } from '../../../application/actions/factoresSeguridad';
import { personaActions } from '../../../application/actions/persona';

import PageTitle from '../../components/atoms/PageTitle';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import Card from '@coopeuch-components/web/atoms/Card';

import { transferenciaTexts } from './texts';
import { TabsWrapper } from './styles';

import SolicitaPassCoopeuch from '../../components/templates/TransferirFondos/SolicitaPassCoopeuch';
import SpinnerLoader from '../../components/atoms/SpinnerLoader';
import ErrorServices from '../../components/templates/TransferirFondos/ErrorServices';

import { registerDY, registerGA } from '../../utils/metrics';
import { filterIncludesCode } from '../../utils/functions';
import logger from '../../utils/logger';
import { hasValuesOnObject } from '../../utils/validators';

const Terceros = lazy(() => import('./Terceros'));
const EntreProductos = lazy(() => import('./EntreProductos'));

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const noProductos = {
  code: 500,
  message: 'no tienes ningún producto para transferir',
};

class TransferirFondos extends PureComponent {
  state = {
    currentTab: 1,
    isReqPassCoopeuchVisible: false,
    hasAccountsVistas: false,
    cargandoFactores: true,
    tefLogEnviado: false,
    tefLogEnviadoEntreProductos: false,
  };

  onFetchFactoresAvailableHandle = () => {
    this.props.fetchFactoresSeguridad();
  };

  onFetchCuentasAhorroHandle = () => {
    this.props.fetchCuentasAhorro({
      codigoProducto: [
        'AN01',
        'AR01',
        'AN04',
        'AN02',
        'AN06',
        'AR09',
        'AN07',
        'AR02',
        'AR06',
        'AR05',
        'AR07',
        'AR03',
        'AN08',
        'AR08',
      ],
      estadoCuenta: ['A', 'I', 'D', 'O', 'T'],
    });
  };

  onFetchCuentasVistaHandle = () => {
    this.props.fetchCuentasVista({
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A'],
    });
  };

  onRetryFetch = () => {
    this.onFetchCuentasVistaHandle();
    this.onFetchFactoresAvailableHandle();
  };

  handleCheckHaveEnoughCuentas = () => {
    const { cuentasVista, cuentasAhorro, history } = this.props;

    const vistasValidas = filterIncludesCode(cuentasVista, ['A'], 'estadoCuenta');
    const ahorrosValidas = filterIncludesCode(cuentasAhorro, ['A'], 'estadoCuenta');

    if (ahorrosValidas?.length < 2 && vistasValidas?.length <= 0) {
      history.push('/ecd/solicita/cuenta-coopeuch');
    }
  };

  checkHasCuentasHandle = (cuentas) => {
    const activas = cuentas?.filter(({ estadoCuenta }) => estadoCuenta === 'A');
    const validas = filterIncludesCode(activas, ['VI01', 'VI06', 'VI03'], 'codigoProducto');

    this.setState(
      produce((draft) => {
        draft.hasAccountsVistas = validas?.length === 0;
      })
    );
  };

  onSetCurrentTabHandle = (event) => {
    const { dataset } = event.target || {};

    this.setState(
      produce((draft) => {
        draft.currentTab = Number(dataset?.id);
      })
    );

    if (Number(dataset?.id) === 2) {
      this.setState(
        produce((draft) => {
          draft.tefLogEnviadoEntreProductos = false;
        })
      );
    }
  };

  handleGoToReqPassCoopeuch = () => {
    this.setState(
      produce((draft) => {
        draft.isReqPassCoopeuchVisible = true;
      })
    );
  };

  onFetchDatosPersonales = () => {
    this.props.fetchDatosPersonales();
  };

  componentDidMount() {
    const { cuentasVista } = this.props;

    this.onFetchCuentasAhorroHandle();
    this.onFetchFactoresAvailableHandle();

    if (cuentasVista?.length <= 0) {
      this.onFetchCuentasVistaHandle();
    }
    registerDY({ type: 'PRODUCT', data: ['transferencias'] });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      factoresDisponibles,
      cuentasVista,
      sinProductos,
      isLoadingFactoresSeguridad,
      isLoadingCuentasVista,
      persona,
      errCuentasLog,
      errorFactoresMessageLog,
      history,
      errorLogPersona,
    } = this.props;
    const { currentTab, tefLogEnviado, tefLogEnviadoEntreProductos } = this.state;

    if (prevProps.isLoadingFactoresSeguridad !== isLoadingFactoresSeguridad) {
      if (!isLoadingFactoresSeguridad) {
        this.setState(
          produce((draft) => {
            draft.cargandoFactores = false;
          })
        );
      }
    }

    if (prevProps.factoresDisponibles !== factoresDisponibles) {
      if (factoresDisponibles?.length === 0) {
        registerGA({
          event: 'vpv-privado',
          page: '/tef/transferencias/fondos/inicio',
          producto: 'sin passcoopeuch',
        });
      }
    }

    if (prevProps.cuentasVista !== cuentasVista) {
      this.checkHasCuentasHandle(cuentasVista);
    }

    if (
      prevProps.cuentasVista !== cuentasVista ||
      prevProps.factoresDisponibles !== factoresDisponibles
    ) {
      const validas = filterIncludesCode(cuentasVista, ['VI01', 'VI03', 'VI06'], 'codigoProducto');

      if (validas?.length >= 1) {
        const needFactors = filterIncludesCode(validas, ['VI01', 'VI03'], 'codigoProducto');

        if (needFactors?.length >= 1 && factoresDisponibles?.length === 0) {
          this.handleGoToReqPassCoopeuch();
        } else {
          this.setState(
            produce((draft) => {
              draft.isReqPassCoopeuchVisible = false;
            })
          );
        }
      }
    }

    const {
      montoATransferirTerceros,
      isLoadingDatosPersonales,
      errorDatosPersonales,
      errorCuentasAhorro,
    } = this.props;

    if (
      prevProps.errorDatosPersonales !== errorDatosPersonales ||
      (Number(prevState.currentTab) !== Number(currentTab) &&
        !isLoadingDatosPersonales &&
        !isLoadingFactoresSeguridad)
    ) {
      if (!isLoadingDatosPersonales && Number(currentTab === 2)) {
        const baseRut = String((persona?.rut || localStorage.getItem('user-rut')).replace('-', ''));
        const errors = errCuentasLog || errorLogPersona || errorFactoresMessageLog;

        const accion =
          history.location.pathname === '/ecd/transferir/fondos'
            ? 'TEF_ENTRE_PRODUCTOS_INGRESO'
            : 'TEF_ENTRE_PRODUCTOS_CONFIRMACION';

        if (Number(currentTab) === 2) {
          logger(errors, {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: accion,
            informacion_adicional: {},
          });
        }

        this.setState(
          produce((draft) => {
            draft.tefLogEnviadoEntreProductos = true;
          })
        );
      }
    }

    // START -- Logs errores TefTerceros //
    if (
      prevProps.isLoadingCuentasVista !== isLoadingCuentasVista ||
      prevProps.isLoadingFactoresSeguridad !== isLoadingFactoresSeguridad ||
      prevProps.errorFactoresMessageLog !== errorFactoresMessageLog
    ) {
      if (!isLoadingCuentasVista && !isLoadingFactoresSeguridad) {
        const sinProductosError = sinProductos ? noProductos : null;
        const baseRut = String((persona?.rut || localStorage.getItem('user-rut')).replace('-', ''));
        const errors = errCuentasLog || errorFactoresMessageLog || sinProductosError;

        const accion =
          history.location.pathname === '/ecd/transferir/fondos'
            ? 'TEF_TERCEROS_INGRESO'
            : 'TEF_TERCEROS_CONFIRMACION';

        if (errors) {
          if (!tefLogEnviado && Number(currentTab) === 1) {
            logger(errors, {
              rut: baseRut.slice(0, -1),
              digito_verificador: baseRut.slice(-1),
              accion: accion,
              informacion_adicional: {
                ...(montoATransferirTerceros ? { monto: montoATransferirTerceros } : {}),
              },
            });
          }

          this.setState(
            produce((draft) => {
              draft.tefLogEnviado = true;
            })
          );
        }
      }
    }
    // END -- Logs errores TefTerceros //
  }

  render() {
    const {
      history,
      cuentasVista,
      cuentasAhorro,
      factoresDisponibles,
      errorFactoresSeguridad,
      errorCuentasVistas,
      errorCuentasAhorro,
      errorDatosPersonales,
    } = this.props;

    const { currentTab, isReqPassCoopeuchVisible, hasAccountsVistas, cargandoFactores } =
      this.state;

    return (
      <Fragment>
        <PageTitle
          title={transferenciaTexts.TITLE}
          icon={<LoadIcon src={`${iconPath}money_exchange.svg`} color="#333" />}
          size={24}
        />

        <TabsWrapper
          className={`w-100  ${
            history.location.pathname === '/ecd/transferir/fondos' ? 'margin-after-title ' : 'bmt-3'
          }`}
        >
          <Tabs
            bgColor="#fff"
            fontSize={15}
            fontFamily="Ubuntu-Regular"
            activeColor="#e81e2b"
            className={
              'pl-54 tab--wrapper-shadows tabs full-tab ' +
              (history.location.pathname === '/ecd/transferir/fondos' ? 'show' : 'hide')
            }
          >
            <Tabs.Header className="tabs-header-transferencia">
              <Tab className="tab-style">
                <div onClick={this.onSetCurrentTabHandle} data-id="1">
                  {transferenciaTexts.TAB_1}
                </div>
              </Tab>
              <Tab className="tab-style w-150p">
                <div onClick={this.onSetCurrentTabHandle} data-id="2">
                  {transferenciaTexts.TAB_2}
                </div>
              </Tab>
            </Tabs.Header>

            {cargandoFactores && history.location.pathname === '/ecd/transferir/fondos' ? (
              <div className="row center-xs middle-xs pt-54">
                <div className="col-xs-12">
                  <SpinnerLoader />
                </div>
              </div>
            ) : (
              <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left wrapper-responsive">
                {errorFactoresSeguridad || errorCuentasVistas ? (
                  <ErrorServices showButton={false} />
                ) : (
                  <Fragment>
                    {isReqPassCoopeuchVisible && Number(currentTab) === 1 ? (
                      <SolicitaPassCoopeuch />
                    ) : (
                      <Fragment>
                        <Tabs.Body>
                          <Suspense fallback={<div />}>
                            {Number(currentTab) === 1 && (
                              <Fragment>
                                {hasAccountsVistas ? (
                                  <Card className="mt-16">
                                    <div className="text-align-center">
                                      <LoadIcon src={`${iconPath}ilustracion_info.svg`} />
                                      <p className="text-bold text-size-l black mb-12 mt-24">
                                        No tienes ningún producto para transferir
                                      </p>
                                      <div className="row center-xs mb-12">
                                        <div className="col-xs-6">
                                          <span className="black text-size-s">
                                            Te invitamos a conocer nuestros productos y así tendrás
                                            más opciones de transferencias
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                ) : (
                                  <Terceros
                                    onFetchCuentasVistaHandle={this.onFetchCuentasVistaHandle}
                                    onGoToReqPassCoopeuch={this.handleGoToReqPassCoopeuch}
                                  />
                                )}
                              </Fragment>
                            )}
                          </Suspense>
                          <Suspense fallback={<div />}>
                            {errorCuentasAhorro ? (
                              <ErrorServices showButton={false} />
                            ) : errorDatosPersonales ? (
                              <ErrorServices showButton={false} />
                            ) : (
                              <Fragment>
                                {Number(currentTab) === 2 && (
                                  <EntreProductos
                                    cuentasVista={cuentasVista}
                                    cuentasAhorro={cuentasAhorro}
                                    factoresDisponibles={factoresDisponibles}
                                    onFetchCuentasAhorroHandle={this.onFetchCuentasAhorroHandle}
                                    onFetchCuentasVistaHandle={this.onFetchCuentasVistaHandle}
                                  />
                                )}
                              </Fragment>
                            )}
                          </Suspense>
                        </Tabs.Body>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </div>
            )}
          </Tabs>
        </TabsWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  factoresDisponibles: entities.factoresSeguridad.factoresSeguridad,
  errorFactoresSeguridad: entities.factoresSeguridad.errorFactoresSeguridad,
  errorFactoresMessageLog: entities.factoresSeguridad.errorFactoresMessageLog,
  isLoadingFactoresSeguridad: ui.loaders.isLoadingFactoresSeguridad,
  cuentasVista: entities.products.cuentaVista.balance,
  cuentasAhorro: entities.cuentasAhorro.cuentas,
  errorCuentasAhorro: entities.cuentasAhorro.errorFetchCuentas,
  errorCuentasVistas: entities.products.errorCuentasVistas,
  errorDatosPersonales: entities.persona.errorDatosPersonales,
  isLoadingCuentasVista: ui.loaders.isLoadingCuentasVista,
  persona: entities.persona,
  errCuentasLog: entities.products.errCuentasLog,
  sinProductos: entities.products.cuentaVista.sinProductos,
  montoATransferirTerceros: entities.transferencias.montoATransferirTerceros,
  errorLogPersona: entities.persona.errorLogPersona,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
  fetchCuentasAhorro(params) {
    dispatch(cuentaAhorroActions.fetchCuentasAhorro({ params }));
  },
  fetchFactoresSeguridad() {
    dispatch(factoresSeguridadActions.fetchFactoresSeguridad());
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransferirFondos));
