import styled from 'styled-components';

const Wrapper = styled.div`
  border-right: 1px solid #e9e9e9;
  background-color: #fff;
`;
export const DesktopWrapper = styled.div`
  background-color: #fff;
  margin-right: 24px;
  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    margin-right: 12px !important;
  }
  /* padding-left: 100px; */
  /* border-right: 1px solid #e9e9e9; */
`;

export const MobileWrapper = styled.div`
  position: fixed;
  background: white;
  z-index: 3;
  margin-top: -72px;
  width: 100%;
  background-color: transparent;
  min-height: 100vh;
  .mobile-menu-column {
    height: 100vh;
    margin-left: -1px;
  }
  .menu-column {
    height: 100vh;
    /* height: calc(100vh - 119px); */
    overflow: auto;
    ::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #000000;
    }
    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    ::-webkit-scrollbar-track:active {
      background: #333333;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
`;

export const Container = styled.nav`
  width: 100%;
  z-index: 3;

  @media screen and (max-width: 768px) {
    position: fixed;
    padding-right: 0px;
    padding-left: 0px;
    background: white;
    width: 80%;
    top: 0;
    bottom: 0;
    left: 0;
  }
`;

export const MobileHeader = styled.div`
  background-color: #f42534;
  .avatar-element-menu {
    padding-left: 11px;
    padding-right: 15px;
  }
  .avatar--right__icon,
  .avatar__item {
    cursor: pointer;
  }
  .avatar__item {
    margin-left: 5px;
  }
  .container-close-button {
    display: flex;
    justify-content: right;
    margin: 15px 0px 0px 10px;
  }
`;

export const ListItems = styled.div`
  background-color: white;
  /* height: 100%; */
`;

export const ShadowMobile = styled.div`
  background: rgba(0, 0, 0, 0.5);
`;

export const MobileLogout = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #f42534;
  padding: 1.1em;
  padding-left: 8px;
  .logout-icon {
    margin-right: 10px;
  }
`;
export const MobileButtonClose = styled.button`
  background: transparent;
  border: none;
  display: flex;
`;
export const AccessHeader = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  text-align: left;
  padding-bottom: 12px;
  margin-left: 15px;
  margin-top: 7px;
`;
export default Wrapper;
