import React from 'react';

import Wrapper from './styles';
import DashboardCard from '../../organism/DashboardCard';
import Skeleton from 'react-loading-skeleton';

const CertificadosSIISkeleton = () => {
  return (
    <Wrapper className="bpx-3 bpx-md-5">
      <DashboardCard className="hide-on-mobile">
        <DashboardCard.Body classes="modulo-content" padding={'0'}>
          <Skeleton width={159} height={10} className="mb-54" />
          <div className="mb-24">
            <Skeleton width={319} height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-34">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-24 pb-2">
            <Skeleton width={292} height={10} />
          </div>
          <div className="mb-36 pb-6">
            <Skeleton width="100%" height={48} />
          </div>
          <div className="mb-36 pb-8">
            <Skeleton width={292} height={10} />
          </div>
          <div className="row">
            <div className="col-xs-6">
              <div className="d-flex middle-xs">
                <Skeleton circle width={32} height={32} className="mr-14" />
                <Skeleton width={292} height={10} />
              </div>
            </div>
            <div className="col-xs-6 end-xs">
              <span className="mr-8">
                <Skeleton width={26} height={10} />
              </span>
              <span>
                <Skeleton width={74} height={10} />
              </span>
            </div>
            <div className="col-xs-6"></div>
          </div>
          <div className="mt-36 pt-8">
            <Skeleton width="100%" height={10} />
          </div>
        </DashboardCard.Body>
      </DashboardCard>
      <DashboardCard className="hide-on-desktop">
        <DashboardCard.Body classes="modulo-content" padding={'0'}>
          <Skeleton width={159} height={10} className="mb-54" />
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-12">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-34">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-24 pb-2">
            <Skeleton width={194} height={10} />
          </div>
          <div className="mb-36">
            <Skeleton width="100%" height={60} />
          </div>
          <div className="mb-18">
            <Skeleton width={223} height={10} />
          </div>
          <div className="row">
            <div className="col-xs-12">
              <div className="d-flex middle-xs">
                <Skeleton circle width={32} height={32} className="mr-14" />
                <div className="w-100">
                  <Skeleton width="100%" height={10} />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-20 pt-2 mb-10">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-10">
            <Skeleton width="100%" height={10} />
          </div>
          <div className="mb-10">
            <Skeleton width="100%" height={10} />
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </Wrapper>
  );
};

export default CertificadosSIISkeleton;
