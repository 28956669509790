import React from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import Alert from '@coopeuch-components/web/atoms/Alert';

import DashboardCard from '../../organism/DashboardCard';
import ErrorCard from '../../organism/ErrorCard';
import CertificadosSIISkeleton from './Skeleton';

import Wrapper, { MainCertificate, CertificateItem } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CertificadosSIITemplate = ({
  error,
  loading,
  title,
  message,
  currentYear,
  lastYear,
  numeroCertificado,
  thereIsACertificadoToCurrentYear,
  thereIsACertificadoToLastYear,
  handleDownloadPDF,
}) => {
  if (error) {
    return (
      <Wrapper className="bpx-3 bpx-md-5">
        <ErrorCard />
      </Wrapper>
    );
  }

  if (loading) return <CertificadosSIISkeleton />;

  return (
    <Wrapper className="bpx-3 bpx-md-5">
      <DashboardCard>
        <DashboardCard.Head title={title} borderBottom="complete" />
        <DashboardCard.Body classes="modulo-content" padding={'0'}>
          <div className="row">
            <div className="col-xs-12">
              <p className="texts">{message}</p>
            </div>
            <div className="col-xs-12 modulo-second-text">
              <p className="text-bold text-size-l pb-16">Descarga certificado año {currentYear}:</p>

              {thereIsACertificadoToCurrentYear ? (
                <MainCertificate className="d-flex middle-xs">
                  <CertificateItem className="w-100">
                    <LoadIcon src={`${iconPath}phone_certificado.svg`} size={32} />

                    <p className="text-bold hide-on-mobile">
                      Certificado N° {numeroCertificado} año {currentYear}
                    </p>
                    <p className="hide-on-desktop">
                      Certificado N° {numeroCertificado} año {currentYear}
                    </p>
                    <Button
                      asTextLink
                      iconLeft={<LoadIcon src={`${iconPath}download_arrow.svg`} color={'red'} />}
                      onClick={() => handleDownloadPDF('currentYear', currentYear)}
                      color={'red'}
                      className="download-btn text-bold middle-xs"
                    >
                      <span className="hide-on-mobile">Descargar</span>
                    </Button>
                  </CertificateItem>
                </MainCertificate>
              ) : (
                <Alert bgType="info" iconType="info" show className="alert-content">
                  <span>
                    <span className="text-bold">El certificado aún no está disponible. </span>
                    Para mayor información contáctate al
                    <span className="text-bold"> 600 200 1200.</span>
                  </span>
                </Alert>
              )}
            </div>

            {thereIsACertificadoToLastYear && (
              <div className="col-xs-12 second-module-pdf">
                <p className="text-bold text-size-l pb-16">Descarga certificado año {lastYear}:</p>
                <CertificateItem className="w-100">
                  <LoadIcon src={`${iconPath}phone_certificado.svg`} size={32} />

                  <p className="text-bold hide-on-mobile">
                    Certificado N° {numeroCertificado} año {lastYear}
                  </p>
                  <p className="hide-on-desktop">
                    Certificado N° {numeroCertificado} año {lastYear}
                  </p>
                  <Button
                    asTextLink
                    iconLeft={<LoadIcon src={`${iconPath}download_arrow.svg`} color={'red'} />}
                    onClick={() => handleDownloadPDF('lastYear', lastYear)}
                    color={'red'}
                    className="download-btn text-bold middle-xs"
                  >
                    <span className="hide-on-mobile">Descargar</span>
                  </Button>
                </CertificateItem>
              </div>
            )}

            <div className="col-xs-12 mt-24">
              <span className="text-size-s texts">
                Si deseas obterner <b>certificados anteriores, </b>debes acudir a tu sucursal
                Coopeuch más cercana.
              </span>
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </Wrapper>
  );
};

export default CertificadosSIITemplate;
