import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { solicitudCVActions } from '../../../application/actions/solicitudCuentaVista';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiSolicitudCV = api(process.env.REACT_APP_SOLICITUD_CV);
const cuentaDebitoSolicitud = api(process.env.REACT_APP_TARJETA_DEBITO);
const apiKit = api(process.env.REACT_APP_MSKIT);
const apiFactor = api(process.env.REACT_APP_FACTORES_SEGURIDAD);

export function* guardarDatosPersonales(action) {
  const { flujo, region, comuna, calle, numero, otros, track, operacion } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  try {
    const { data, error } = yield call(apiSolicitudCV.post, 'datospersonales/v1/guardar', {
      track,
      flujo,
      region,
      comuna,
      calle,
      numero,
      otros,
    }, optionalsHeaders);
    if (error) {
      yield put(solicitudCVActions.guardarDatosPersonalesError());
    }
    if (data) {
      yield put(solicitudCVActions.guardarDatosPersonalesSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'guardarDatosPersonales');
    yield put(solicitudCVActions.guardarDatosPersonalesError());
  }
}

export function* guardarSucursal(action) {
  const { region, sucursal, track, operacion } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  try {
    const { data, error } = yield call(apiSolicitudCV.post, 'sucursal/v1/guardar', {
      track,
      region,
      sucursal,
    }, optionalsHeaders);
    if (error) {
      yield put(solicitudCVActions.guardarSucursalError());
    }
    if (data) {
      yield put(solicitudCVActions.guardarSucursalSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'guardarSucursal');
    yield put(solicitudCVActions.guardarSucursalError());
  }
}

export function* firmarSolicitud(action) {
  const { track, operacion, clave, correo } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  try {
    
    const { data, error } = yield call(apiSolicitudCV.post, 'documentacionfirma/v1/firmar', {
      track,
      clave,
      correo,  
    },
    optionalsHeaders,
    );
    if (error) {
      yield put(solicitudCVActions.firmarSolicitudError({ error: error.code }));
    }
    if (data) {
      yield put(solicitudCVActions.firmarSolicitudSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'firmarSolicitud');
    yield put(solicitudCVActions.firmarSolicitudError());
  }
}

export function* getDetalleContratacion() {
  try {
    const { data, error } = yield call(apiSolicitudCV.get, 'comprobante/detalle/v1/obtener');

    console.log('ComprobanteWWWWW: ', data)
    
    const { data: data1, error: error1 } = yield call(cuentaDebitoSolicitud.post, 'tarjeta/obtener', {
      "codigoBloqueo": [
          "", "G"
      ],
      "codigoEstado": [
          "00"
      ],
      "numeroCuenta": data.numeroCuenta
  });
    
    data.numeroTarjeta = data1[0].numeroTarjeta

    console.log('Tarjeta: ', data1)
    console.log('Error de Tarjeta: ', error1)
    
    // Obtener los datos de la tarjeta del usuario
    if (error) {
      yield put(solicitudCVActions.getDetalleContratacionError());
    }
    if (data) {
      yield put(solicitudCVActions.getDetalleContratacionSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'getDetalleContratacion');
    yield put(solicitudCVActions.getDetalleContratacionError());
  }
}

export function* obtenerComprobante() {
  try {
    const { data, error } = yield call(apiSolicitudCV.get, 'documento/comprobante/v1/obtener');
    if (error) {
      yield put(solicitudCVActions.obtenerComprobanteError());
    }
    if (data) {
      yield put(solicitudCVActions.obtenerComprobanteSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'obtenerComprobante');
    yield put(solicitudCVActions.obtenerComprobanteError());
  }
}

export function* obtenerContrato() {
  try {
    const { data, error } = yield call(apiSolicitudCV.get, 'documento/contrato/v1/obtener');
    if (error) {
      yield put(solicitudCVActions.obtenerContratoError());
    }
    if (data) {
      yield put(solicitudCVActions.obtenerContratoSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'obtenerContrato');
    yield put(solicitudCVActions.obtenerContratoError());
  }
}

export function* enviarComprobante(action) {
  const { correo } = action.payload;
  try {
    const { data, error } = yield call(apiSolicitudCV.post, 'documento/comprobante/v1/enviar', {
      correo,
    });
    if (error) {
      yield put(solicitudCVActions.enviarComprobanteError());
    }
    if (data) {
      yield put(solicitudCVActions.enviarComprobanteSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'enviarComprobante');
    yield put(solicitudCVActions.enviarComprobanteError());
  }
}

export function* validarSolicitud() {
  try {
    yield put(loadersActions.fetchLoadingValidarSolicitudCV({ isLoading: true }));
    const { data, error } = yield call(apiSolicitudCV.get, 'solicitud/validar');
    if (data) {
      yield put(solicitudCVActions.validarSolicitudSuccess({ trackValidarSolicitud: data?.track }));
      yield put(loadersActions.fetchLoadingValidarSolicitudCV({ isLoading: false }));
    } else {
      yield put(solicitudCVActions.validarSolicitudFailed({ code: error?.code }));
      yield put(loadersActions.fetchLoadingValidarSolicitudCV({ isLoading: false }));
    }
  } catch (err) {
    yield put(solicitudCVActions.validarSolicitudFailed({ code: 'ERR' }));
    yield put(loadersActions.fetchLoadingValidarSolicitudCV({ isLoading: false }));
  }
}

export function* fetchBannerContratacionCV() {
  try {
    yield put(loadersActions.fetchLoadingBannerContratacionCV({ isLoading: true }));
    const { data, error } = yield call(apiSolicitudCV.get, 'banner/contratacion/v1/validar');

    if (data) {
      yield put(solicitudCVActions.bannerContratacionCVSuccess({ data }));
      yield put(loadersActions.fetchLoadingBannerContratacionCV({ isLoading: false }));
    }

    if (error) {
      yield put(solicitudCVActions.bannerContratacionCVError({ error }));
      yield put(loadersActions.fetchLoadingBannerContratacionCV({ isLoading: false }));
    }

  } catch (err) {
    console.error(err.message, 'getDetalleContratacion');
    yield put(solicitudCVActions.bannerContratacionCVError({ err }));
    yield put(loadersActions.fetchLoadingBannerContratacionCV({ isLoading: false }));
  }
}

export function* fetchObtenerDesafios() {
  try {
    const { data, error } = yield call(apiFactor.post, 'factor/v1/persona/obtener');
    if (data) {
      yield put(solicitudCVActions.fetchObtenerDesafiosSuccess(data));
    } else {
      yield put(solicitudCVActions.fetchObtenerDesafiosError(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchObtenerDesafios');
  }
}

export function* solicitarPassCoopeuch(action) {
  const { track, operacion, params  } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  try {
    const { data, error } = yield call(apiFactor.post, 'web/desafio/passcoopeuch/v1/obtener',
      params,
      optionalsHeaders
    );

    if (data) {
      yield put(solicitudCVActions.solicitarPassCoopeuchSuccess(data));
    } else {
      yield put(solicitudCVActions.solicitarPassCoopeuchError({ error }));
    }
  } catch (err) {
    console.error(err.message, 'solicitarPassCoopeuch');
  }
}

export function* validarPassCoopeuchCP(action) {
  const { track, operacion, params } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  
  try {
    const { data, error } = yield call(apiFactor.post, 'web/desafio/passcoopeuch/v1/validar', 
    params,
    optionalsHeaders
    );

    if (data) {
      yield put(solicitudCVActions.validarPassCoopeuchCPSuccess(data));
    } else {
      yield put(solicitudCVActions.validarPassCoopeuchCPError(error));
    }
  } catch (err) {
    console.error(err.message, 'validarPassCoopeuchCP');
  }
}

export function* onHandleObtenerDesafio(action) {
  const { track, operacion, params } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  try {
    const { data, error } = yield call(apiFactor.get, 'factor/desafio/v1/coordenada/obtener', 
    null,
    optionalsHeaders
    );

    if (data) {
      yield put(solicitudCVActions.onHandleObtenerDesafioSuccess(data));
    } else {
      yield put(solicitudCVActions.onHandleObtenerDesafioError({ error }));
    }
  } catch (err) {
    console.error(err.message, 'onHandleObtenerDesafio');
  }
}

export function* validarDesafio(action) {
  const { desafio, track, operacion } = action.payload;
  const optionalsHeaders = { track: `${track}-${operacion}` };
  try {
    const { data, error } = yield call(apiFactor.post, 'factor/desafio/v1/coordenada/validar', 
    desafio,
    optionalsHeaders
    );

    if (data) {
      yield put(solicitudCVActions.validarDesafioSuccess(data));
    } else {
      yield put(solicitudCVActions.validarDesafioError({ error }));
    }
  } catch (err) {
    console.error(err.message, 'validarDesafio');
  }
}

export function* validarPassCoopeuchCPReset() {
  yield put(solicitudCVActions.validarPassCoopeuchCPResetSuccess());
}

export function* solicitarPassCoopeuchReset() {
  yield put(solicitudCVActions.solicitarPassCoopeuchResetSuccess());
}

export function* onHandleObtenerDesafioReset() {
  yield put(solicitudCVActions.onHandleObtenerDesafioResetSuccess());
}

export function* validarDesafioReset() {
  yield put(solicitudCVActions.validarDesafioResetSuccess());
}

export function* watchGuardarDatosPersonales() {
  yield takeLatest(solicitudCVActions.guardarDatosPersonales.type, guardarDatosPersonales);
}

export function* watchGuardarSucursal() {
  yield takeLatest(solicitudCVActions.guardarSucursal.type, guardarSucursal);
}

export function* watchFirmarSolicitud() {
  yield takeLatest(solicitudCVActions.firmarSolicitud.type, firmarSolicitud);
}

export function* watchGetDetalleContratacion() {
  yield takeLatest(solicitudCVActions.getDetalleContratacion.type, getDetalleContratacion);
}

export function* watchObtenerComprobante() {
  yield takeLatest(solicitudCVActions.obtenerComprobante.type, obtenerComprobante);
}

export function* watchObtenerContrato() {
  yield takeLatest(solicitudCVActions.obtenerContrato.type, obtenerContrato);
}

export function* watchEnviarComprobante() {
  yield takeLatest(solicitudCVActions.enviarComprobante.type, enviarComprobante);
}

export function* watchValidarSolicitud() {
  yield takeLatest(solicitudCVActions.validarSolicitud.type, validarSolicitud);
}

export function* watchFetchBannerContratacionCV() {
  yield takeLatest(solicitudCVActions.fetchBannerContratacionCV.type, fetchBannerContratacionCV);
}

export function* watchFetchObtenerDesafios() {
  yield takeLatest(
    solicitudCVActions.fetchObtenerDesafios.type,
    fetchObtenerDesafios
  );
}

export function* watchSolicitarPassCoopeuch() {
  yield takeLatest(
    solicitudCVActions.solicitarPassCoopeuch.type,
    solicitarPassCoopeuch
  );
}

export function* watchValidarPassCoopeuch() {
  yield takeLatest(
    solicitudCVActions.validarPassCoopeuchCP.type,
    validarPassCoopeuchCP
  );
}

export function* watchOnHandleObtenerDesafio() {
  yield takeLatest(
    solicitudCVActions.onHandleObtenerDesafio.type,
    onHandleObtenerDesafio
  );
}

export function* watchValidarPassCoopeuchCPReset() {
  yield takeLatest(
    solicitudCVActions.validarPassCoopeuchCPReset.type,
    validarPassCoopeuchCPReset
  );
}

export function* watchSolicitarPassCoopeuchReset() {
  yield takeLatest(
    solicitudCVActions.solicitarPassCoopeuchReset.type,
    solicitarPassCoopeuchReset
  );
}

export function* watchOnHandleObtenerDesafioReset() {
  yield takeLatest(
    solicitudCVActions.onHandleObtenerDesafioReset.type,
    onHandleObtenerDesafioReset
  );
}

export function* watchValidarDesafioReset() {
  yield takeLatest(
    solicitudCVActions.validarDesafioReset.type,
    validarDesafioReset
  );
}

export function* watchValidarDesafio() {
  yield takeLatest(
    solicitudCVActions.validarDesafio.type,
    validarDesafio
  );
}

/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */
export default function* rootSaga() {
  yield spawn(watchGuardarDatosPersonales);
  yield spawn(watchGuardarSucursal);
  yield spawn(watchFirmarSolicitud);
  yield spawn(watchGetDetalleContratacion);
  yield spawn(watchObtenerComprobante);
  yield spawn(watchObtenerContrato);
  yield spawn(watchEnviarComprobante);
  yield spawn(watchValidarSolicitud);
  yield spawn(watchFetchBannerContratacionCV);
  yield spawn(watchFetchObtenerDesafios);
  yield spawn(watchSolicitarPassCoopeuch);
  yield spawn(watchValidarPassCoopeuch);
  yield spawn(watchOnHandleObtenerDesafio);
  yield spawn(watchValidarPassCoopeuchCPReset);
  yield spawn(watchSolicitarPassCoopeuchReset);
  yield spawn(watchOnHandleObtenerDesafioReset);
  yield spawn(watchValidarDesafio);
  yield spawn(watchValidarDesafioReset);
}
