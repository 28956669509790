import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
// Containers
import Comprobante from '../Comprobante';
import Confirmacion from '../Confirmacion';
import Documentacion from '../Documentacion';
import DocumentosObjetadosComprobante from '../DocumentosObjetados/Comprobante';
import DocumentosObjetadosDocumentacion from '../DocumentosObjetados/Documentacion';
import Home from '../Home';
import Postulacion from '../Postulacion';

const StepperRouter = ({ currentStep, setCurrentStep }) => {
  const match = useRouteMatch();
  const steps = [
    'Confirmación de datos',
    'Datos para la postulación',
    'Documentación requerida',
    'Solicitud ingresada',
  ];
  return (
    <Switch>
      <Route
        exact
        path={`${match?.url}/`}
        render={() => <Home {...{ currentStep, setCurrentStep }} />}
      />
      <Route
        exact
        path={`${match?.url}/confirmacion`}
        render={() => <Confirmacion {...{ steps, currentStep, setCurrentStep }} />}
      />
      <Route
        exact
        path={`${match?.url}/postulacion`}
        render={() => <Postulacion {...{ steps, currentStep, setCurrentStep }} />}
      />
      <Route
        exact
        path={`${match?.url}/documentacion`}
        render={() => <Documentacion {...{ steps, currentStep, setCurrentStep }} />}
      />
      <Route
        exact
        path={`${match?.url}/comprobante`}
        render={() => <Comprobante {...{ steps, currentStep, setCurrentStep }} />}
      />
      <Route
        path={`${match?.url}/documentos-objetados/documentacion`}
        component={DocumentosObjetadosDocumentacion}
      />
      <Route
        path={`${match?.url}/documentos-objetados/comprobante`}
        component={DocumentosObjetadosComprobante}
      />
      <Redirect path={`${match?.path}/`} to={`${match?.path}/`} />
    </Switch>
  );
};

export default StepperRouter;
