import styled from 'styled-components';

export const SliderPage = styled.div` 
        height: 200px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(${({ src }) => src});
        color: #fff;

.poder-especial-container {
    padding: 24px;
}

.poder-especial-title {color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-top: 5px;
}

.poder-especial-subtitle {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 5px;
    width: 330px;
}
.btn_red {
    width: 180px;
    padding: 2px;
    margin-top: 18px;
}


@media screen and (max-width: 766px) {
    background-image: url(${({ srcMobile }) => srcMobile});

    .btn_red {
        width: 180px;
        padding: 2px;
        margin-top: -8px;
    }

    .poder-especial-title {
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 24px;
        width: 180px;
        margin-top: -5px;
    }

    .poder-especial-subtitle {
        color: #ffffff;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 24px;
        width: 180px;
        margin-top: -2px;
    }
    .poder-especial-container {
        padding: 24px;
        margin-left: 5px;
        height: 500px;
    }
}
`;