import React from 'react';
import Alert from '@coopeuch-components/web/atoms/Alert';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Skeleton from 'react-loading-skeleton';
import InputLabel from '../../../../atoms/InputLabel';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import { agregarformatoPesos, formatThousandsSeparators } from '../../../../../utils/formatters';
import Wrapper, { MessageError } from './styles';
import { colors } from '../../../../../assets/styles/index';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({
  cuotasPendiente,
  montoCuotasPendiente,
  valorCuota,
  cantidadCuotas,
  handleChangeInput,
  backButton,
  nextButton,
  loading,
  maxCuotasError,
}) => {
  const maxCuotasInputError = (
    <MessageError className='input-error-cuotas text-size-s mt-6' style={{color:`${colors.redErrorInputColor}`}}>
      <div className="row">
        <LoadIcon
          src={`${iconPath}circle_close.svg`}
          size={14}
          color={colors.redErrorInputColor}
          className={`mr-8 ${colors.redErrorInputColor}`}
        />
        Excede la cantidad máxima
      </div>
    </MessageError>
  );
  return (
    <Wrapper>
      <CardBox>
        <CardBox.Header>{loading ? <Skeleton width="30%" /> : 'Cuotas a pagar'}</CardBox.Header>
        <CardBox.Body>
          <div className="valores-card-cdp w-100 bp-3 bpy-md-4 bpx-md-2rem">
            <div className="row">
              <div className="col-xs-12 col-md-4 h-100 bmb-3 bmb-md-0 col-element justify-content">
                <Typography tag="p" fontSize={12}>
                  {loading ? <Skeleton width="50%" /> : 'Cuotas pendientes:'}
                </Typography>
                <Typography className="bml-2 bml-md-0" tag="p" fontWeight="bold" fontSize={24}>
                  {loading ? <Skeleton width="50%" /> : formatThousandsSeparators(cuotasPendiente)}
                </Typography>
              </div>
              <div className="col-xs-12 col-md-4 h-100 bmb-3 bmb-md-0 col-element justify-content">
                <Typography tag="p" fontSize={12}>
                  {loading ? <Skeleton width="50%" /> : 'En pesos equivale a:'}
                </Typography>
                <Typography className="bml-2 bml-md-0" tag="p" fontWeight="bold" fontSize={24}>
                  {loading ? <Skeleton width="50%" /> : agregarformatoPesos(montoCuotasPendiente)}
                </Typography>
              </div>
              <div className="col-xs-12 col-md-4 h-100 col-element justify-content">
                <Typography tag="p" fontSize={12}>
                  {loading ? <Skeleton width="50%" /> : 'Valor de 1 cuota:'}
                </Typography>
                <Typography className="bml-2 bml-md-0" tag="p" fontWeight="bold" fontSize={24}>
                  {loading ? <Skeleton width="50%" /> : agregarformatoPesos(valorCuota)}
                </Typography>
              </div>
            </div>
          </div>
          <div className="row bmt-3 bmt-md-4">
            <div className="col-xs-12">
              <Typography className="btext-left btext-md-center" tag="p" fontSize={20}>
                {loading ? <Skeleton width="50%" /> : '¿Cuánto deseas pagar?'}
              </Typography>
              <Typography className="btext-left btext-md-center" tag="p" fontSize={14}>
                {loading ? (
                  <Skeleton width="50%" />
                ) : (
                  `La cantidad de cuotas a pagar debe ser entre 1 - ${formatThousandsSeparators(cuotasPendiente)}`
                )}
              </Typography>
            </div>
          </div>
          <div className="row bmt-3 bmt-md-4">
            <div className="col-md" />
            <div className="col-xs-12 col-md-8">
              <div className="row">
                <div className="col-xs-12 col-md-6 bmb-3 bmb-md-0">
                  {loading ? (
                    <>
                      <Skeleton className="bmb-3" />
                      <Skeleton height={48} />
                    </>
                  ) : (
                    <InputLabel
                      id="cdp-pago-numero-cuotas"
                      borderColor={maxCuotasError ? colors.redErrorInputColor : ''}
                      value={cantidadCuotas}
                      name="cantidadCuotas"
                      onChange={handleChangeInput}
                      title="Número de cuotas"
                      labelClass="text-bold bmb-3"
                      size="48"
                      errorMessage={maxCuotasError ? maxCuotasInputError : ''}
                      rounded={4}
                      placeholder="Ingresa número de cuotas"
                    />
                  )}
                </div>
                <div className="col-xs-12 col-md-6">
                  {loading ? (
                    <>
                      <Skeleton className="bmb-3" />
                      <Skeleton height={48} />
                    </>
                  ) : (
                    <InputLabel
                      id="cdp-pago-total"
                      borderColor={maxCuotasError ? colors.redErrorInputColor : ''}
                      value={agregarformatoPesos(cantidadCuotas * valorCuota)}
                      name={'totalAPagar'}
                      onChange={handleChangeInput}
                      title="Total a pagar"
                      labelClass="text-bold bmb-3"
                      size="48"
                      disabled
                      rounded={4}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-md" />
          </div>
          {maxCuotasError && (
            <div className="row bmt-3 bmt-md-4 ">
              <div className="col-xs-12 ">
                <Alert
                  className="alert-warning bp-3 w-100"
                  bgType="warning"
                  id="warning-saldo-insuficiente"
                  show
                >
                  {`Tu máximo de cuotas a pagar es de ${formatThousandsSeparators(
                    cuotasPendiente
                  )}, por favor ingresa una cantidad menor.`}
                </Alert>
              </div>
            </div>
          )}
        </CardBox.Body>
      </CardBox>
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12">
          <StepsActions
            backAction={backButton}
            backText='Volver'
            nextAction={nextButton}
            nextText="Continuar"
            disableNextAction={maxCuotasError || !cantidadCuotas}
            id="pago-cdp-paso-1"
          />
        </div>
      </div>
    </Wrapper>
  );
};
Index.displayName='Pago_CP_template'
export default Index;
