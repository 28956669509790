/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/transferenciaEntreProductos';

// ------- SAGA ------ //

function selectCuentaSuccess(state, action) {
  const { idTransferencia, track } = action.payload;

  state.idTransferencia = idTransferencia;
  state.trackId = track;
  state.errorSelectCuentaTef = false;
  state.errorSeleccionarLog = '';
}

function selectCuentaFailed(state, action) {
  state.errorSelectCuentaTef = true;
  state.errorSeleccionarLog = action.payload.errorSeleccionarLog;
  state.contadorProTef = state.contadorProTef + 1
}

function fetchReglasSuccess(state, action) {
  const { payload } = action;

  state.reglasEntreProductos = payload.reglasEntreProductos;
  state.errorReglas = false;
  state.errorReglasLog = '';
}

function fetchReglasFailed(state, action) {
  const { payload } = action;

  state.errorReglas = true;
  state.reglasEntreProductos = {};
  state.errorReglasLog = payload.errorReglasLog;
  state.contadorProTef = state.contadorProTef + 1
}

function fetchValidacionTefSuccess(state) {
  state.validacionTefIsSuccess = true;
  state.validacionTefIsFailed = false;
  state.errorValidacionLog = '';
}

function fetchValidacionTefFailed(state, action) {
  state.validacionTefIsFailed = true;
  state.validacionTefIsSuccess = false;
  state.errorValidacionLog = action.payload.errorValidacionLog;
  state.contadorProTef = state.contadorProTef + 1
}

function clearFetchValidacion(state) {
  state.validacionTefIsSuccess = false;
  state.validacionTefIsFailed = false;
}

function fetchDetallesTefSuccess(state, action) {
  state.detallesTef = action.payload;
  state.errorDetallesTef = false;
  state.errorTransferenciaLog = '';
}

function fetchDetallesTefFailed(state, action) {
  state.detallesTef = {};
  state.errorDetallesTef = true;
  state.errorTransferenciaLog = action.payload.errorTransferenciaLog;
  state.contadorProTef = state.contadorProTef + 1
}

function clearStateForTef(state) {
  state.errorReglas = false;
  state.reglasEntreProductos = {
    reglas: [],
    monto: 0,
    cantidad: 0,
    isNuevo: false,
    permiteTransferir: true,
  };
  state.idTransferencia = '';
  state.trackId = '';
  state.errorSelectCuentaTef = false;
  state.validacionTefIsSuccess = false;
  state.validacionTefIsFailed = false;
  state.detallesTef = {};
  state.errorDetallesTef = false;
}

const fetchReglas = createAction('fetchReglas');
const validacionTefEntreProductos = createAction('validacionTefEntreProductos');
const ejecucionTefEntreProductos = createAction('ejecucionTefEntreProductos');
const selectCuentaTef = createAction('selectCuentaTef');
const descargarComprobante = createAction('descargarComprobante');

const transferenciaEntreProductosReducer = createSlice({
  name: 'transferenciaEntreProductos',
  initialState,
  reducers: {
    fetchReglasSuccess,
    fetchReglasFailed,
    fetchValidacionTefSuccess,
    fetchValidacionTefFailed,
    selectCuentaSuccess,
    selectCuentaFailed,
    fetchDetallesTefSuccess,
    fetchDetallesTefFailed,
    clearStateForTef,
    clearFetchValidacion,
  },
});

export const transferenciaEntreProductosActions = {
  ...transferenciaEntreProductosReducer.actions,
  fetchReglas,
  validacionTefEntreProductos,
  ejecucionTefEntreProductos,
  selectCuentaTef,
  descargarComprobante,
};

export default transferenciaEntreProductosReducer.reducer;
