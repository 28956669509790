const initState = {
  dataBono: {
    id: null,
    typeBono: '',
    phone: '',
    email: '',
    typeBeneficiario: '',
    nombreSocio: '',
    rutBeneficiario: '',
    nombreBeneficiario: '',
    institucion: '',
    carrera: '',
    documentos: {
      error: false,
      isRejected: false,
      CEDULA_IDENTIDAD_SOCIO: '',
      CEDULA_IDENTIDAD_HIJO: '',
      DOCUMENTO_ADICIONAL: '',
      CERTIFICADO_TITULO: '',
    },
  },
  dataInstitutes: { institutes: null, careers: null, error: false },
  saveBono: { data: null, error: null },
  userType: { data: null, error: false },
  downloadPdfBono: { data: null, error: false },
  listaSolicitudes: { data: [], error: false },
  duplicidadBono: { data: null, error: false },
};

export default initState;
