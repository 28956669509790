import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import Button from '@coopeuch-components/web/atoms/Button';
import Card from '@coopeuch-components/web/atoms/Card';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { addClass } from '@coopeuch-components/web';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ErrorsCuotas = (props) => {
  const {
    onClick,
    withCard,
    message,
    htmlMessage,
    gridClass,
    title,
    buttonText,
    isWarning,
    noButton,
  } = props;

  const Wrapper = useMemo(() => (withCard ? Card : Fragment), [withCard]);

  const ilustration = useMemo(
    () => (isWarning ? 'info-desktop' : 'ilustracion_error'),
    [isWarning]
  );


  const scrollToTop = () => {
    var currentPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    if (currentPosition === 0) {
      return;
    }
    const smoothScroll = () => {
      currentPosition = currentPosition - 20;
      if (currentPosition <= 0) {
        currentPosition = 0;
      }
      window.scrollTo(0, currentPosition);
      if (currentPosition > 0) {
        requestAnimationFrame(smoothScroll);
      }
    }
    smoothScroll();
  }
  scrollToTop();
  return (
    <Wrapper>
      <div className="row center-xs">
        <div className={`col-md-6 col-sm-8 ${gridClass}`}>
          <div className="pl-4 pr-4">
            <div className="row center-xs">
              <LoadIcon src={`${iconPath}${ilustration}.svg`} size={175} />
            </div>

            <Typography tag="p" fontSize={24} className="pt-12 text-center pb-24 text-bold black">
              {title}
            </Typography> 
            {htmlMessage ? (
              <div
                className={addClass([!title && 'pt-24', 'pb-24 text-size-l'])}
                dangerouslySetInnerHTML={{ __html: `<div>${htmlMessage}</div>` }}
              />
            ) : (
              <Typography tag="p" fontSize={18} className="pb-24 text-center lh-1_5 black">
                {message}
              </Typography>
            )}

            {!noButton && (
              <Button
                rounded={5}
                bgColor="#e81d2b"
                color="white"
                onClick={onClick}
                width={264}
                shadow
                height={48}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

ErrorsCuotas.propTypes = {
  onClick: PropTypes.func,
  withCard: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  isWarning: false,
  noButton: false,
};

ErrorsCuotas.defaultProps = {
  onClick: null,
  withCard: false,
  message:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  title: '¡Lo sentimos!',
  buttonText: 'Reintentar',
};

export default ErrorsCuotas;
