import Styled from 'styled-components';

export const Paragraph = Styled.p`
  font-size: ${({ size }) => `${size ? size : '16'}px`};
  margin-left: ${({ marginLeft }) => `${marginLeft ? marginLeft : '0'}px`};
  letter-spacing: 0;
  line-height: 26px;
  box-sizing: border-box;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
`

export const Wrapper = Styled.div`
  .alert-comprobante-contratacion-monedero {
    height: unset
  }
  .action-button-text {
    font-size: 16px;
  }
  .comprobante-card-text {
    display: flex;
    align-items: center;
  }
`
export const ActionButtons = Styled.div`
    color: #F42534;
    display: inline-flex;
    font-weight: bold;
    & > .comprobante-action {
      display: inline-flex;
      cursor: pointer;
    }
`