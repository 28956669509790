const initialState = {
    certProdVigentes: {
      pdf: null,
      error: null,
      code: null,
      errorMessage: null,
    },
  };
  
  export default initialState;
  