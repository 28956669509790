import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import Home from './Home';
import Pagar from './Pagar';
import SolicitarTarjetaLayout from './SolicitarTarjetaLayout';

const TarjetaDeCreditoRouter = (props) => {
  const { match } = props || {};

  return (
    <Switch>
      <Route exact path={`${match?.url}/`} render={() => <Home {...props} />} />

      <Route exact path={`${match?.url}/pagar/`} render={() => <Pagar {...props} />} />

      <Route
        exact
        path={`${match?.url}/solicita`}
        render={() => <SolicitarTarjetaLayout {...props} />}
      />

      <Redirect path={`${match?.path}/`} to={`${match?.path}/`} />
    </Switch>
  );
};

export default TarjetaDeCreditoRouter;
