import React from 'react';
import PropTypes from 'prop-types';

// Styles & Components
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import {registerGA} from '../../../utils/metrics'

import { Content } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const BloqueoTarjetas = ({
  habilitarBT,
  habilitarBloqueoD,
  habilitarTC,
  setFlujoBloqueoTarjetas,
  handleDesbloqueoTarjetas,
  tieneTarjetasInactividadAnual,
}) => {
  // console.log({ setFlujoBloqueoTarjetas });

  const handleBloqueoTemporal = () => {
    setFlujoBloqueoTarjetas('BLOQUEO_TEMPORAL');
    registerGA({
      'event': 'eve',
      'eve-acc': '/tef/bloqueo-desbloqueo-tarjetas',
      'eve-cat': 'Click',
      'eve-lab': 'Bloquear Temporalmente',
    });
  };

  const handleBloqueoPermanente = () => {
    setFlujoBloqueoTarjetas('BLOQUEO_PERMANENTE');
    registerGA({
      'event': 'eve',
      'eve-acc': '/tef/bloqueo-desbloqueo-tarjetas',
      'eve-cat': 'Click',
      'eve-lab': 'Bloquear Definitivamente',
    });
  };

  return (
    <Content id="Landing_Template_BloqueoTarjetas">
      {/* Bloqueo Temporal */}
      <div className="row bmt-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="adjust_head_padding_card">
              <div className="dfac">
                <LoadIcon src={`${iconPath}block.svg`} size={22} color="383638" className="mr-8" />
                <Typography tag="p" fontSize={16} fontWeight={700}>
                  Bloqueo Temporal
                </Typography>
              </div>
            </CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12">
                  Esta opción te permite activar o desactivar termporalmente tu{' '}
                  <span className="text-bold"> Tarjeta de Débito titular y las Tarjetas de Débito </span>{' '}
                  <br />
                  adicionales. Podrás activarla en cualquier momento dentro de los próximos 30 días.
                </div>
                <div className="bmt-3">
                  <Button
                    width={258}
                    bgColor="#F42534"
                    color="white"
                    fullWidth
                    disabled={!habilitarBT}
                    onClick={() => setFlujoBloqueoTarjetas('BLOQUEO_TEMPORAL')}
                  >
                    <b>Bloquear Temporalmente</b>
                  </Button>
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      {/* Bloqueo Temporal /> */}

      {/* Bloqueo Definitivo */}
      <div className="row bmt-4 bmb-xs-3 bmb-sm-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="adjust_head_padding_card">
              <div className="dfac">
                <LoadIcon src={`${iconPath}cross.svg`} size={22} color="383638" className="mr-8" />
                <Typography tag="p" fontSize={16} fontWeight={700}>
                  Bloqueo Definitivo
                </Typography>
              </div>
            </CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12">
                  Esta opción te permite bloquear definitivamente tu{' '}
                  <span className="text-bold">Tarjeta de Débito</span> y la{' '}
                  <span className="text-bold">Tarjeta de Coordenada</span>
                  .
                  <br />
                  Al bloquear tus tarjetas con esta opción se generará un nuevo plástico
                  automáticamente.
                </div>
                <div className="bmt-3">
                  <Button
                    width={258}
                    bgColor="#F42534"
                    color="white"
                    fullWidth
                    disabled={!habilitarBloqueoD}
                    onClick={() => setFlujoBloqueoTarjetas('BLOQUEO_PERMANENTE')}
                  >
                    <b>Bloquear Definitivamente</b>
                  </Button>
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      {/* Bloqueo Definitivo /> */}

       {/* Desbloqueo */}
       <div className="row bmt-4 bmb-xs-3 bmb-sm-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="adjust_head_padding_card">
              <div className="dfac">
                <LoadIcon src={`${iconPath}check.svg`} size={22} color="383638" className="mr-8" />
                <Typography tag="p" fontSize={16} fontWeight={700}>
                  Desbloqueo Tarjetas
                </Typography>
              </div>
            </CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12">
                  Esta opción te permite desbloquear tu Tarjeta de Débito que fue inhabilitada de
                  forma automatica por no uso. Al desbloquearla podrás utilizarla de forma
                  inmediata.
                </div>
                <div className="bmt-3">
                  <Button
                    width={230}
                    bgColor="#F42534"
                    color="white"
                    // fullWidth
                    disabled={!tieneTarjetasInactividadAnual}
                    onClick={() =>
                      tieneTarjetasInactividadAnual ? handleDesbloqueoTarjetas() : {}
                    }
                  >
                    <b>Desbloquear</b>
                  </Button>
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      {/* Desbloqueo */}

      <div className="row bmy-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="adjust_head_padding_card">
              <div className="dfac">
                <LoadIcon src={`${iconPath}cross.svg`} size={22} color="383638" className="mr-8" />
                <Typography tag="p" fontSize={16} fontWeight={700}>
                  Bloqueo de Tarjetas de Crédito
                </Typography>
              </div>
            </CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12">
                  Para bloquear definitivamente tu{' '} <span className="text-bold">Tarjeta de Crédito titular y adicionales</span>{' '}
                  en caso de robo o pérdida debes llamar al siguiente teléfono:
                </div>
                <div className="col-xs-12 col-sm-4 bmt-3 underline-none phone-number">
                  {habilitarTC ? (
                    <a className="block-phone-link" href="tel:+566002001200">
                      600 200 1200
                    </a>
                  ) : (
                    <span className="block-phone-link">600 200 1200</span>
                  )}
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bmy-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="adjust_head_padding_card">
              <div className="dfac">
                <LoadIcon src={`${iconPath}cross.svg`} size={22} color="383638" className="mr-8" />
                <Typography tag="p" fontSize={16} fontWeight={700}>
                  Bloqueo Permanente Voluntario (Ley 21.398)
                </Typography>
              </div>
            </CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12">
                  Esta opción permite bloquear de manera permanente tu{' '}
                  <span className="text-bold">Tarjeta de Débito o Crédito,</span>{' '}pudiendo activarla en cualquier momento. Para ejecutar esta acción debes llamar al siguiente teléfono:
                </div>
                <div className="col-xs-12 col-sm-4 bmt-3 underline-none phone-number">
                  {habilitarTC ? (
                    <a className="block-phone-link" href="tel:+566002001200">
                      600 200 1200
                    </a>
                  ) : (
                    <span className="block-phone-link">600 200 1200</span>
                  )}
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
    </Content>
  );
};

BloqueoTarjetas.propTypes = {
  habilitarBT: PropTypes.bool.isRequired,
  habilitarBloqueoD: PropTypes.bool.isRequired,
  habilitarTC: PropTypes.bool.isRequired,
  setFlujoBloqueoTarjetas: PropTypes.func.isRequired,
};

BloqueoTarjetas.displayName = 'Landing_Template';
export default BloqueoTarjetas;
