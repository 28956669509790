import React from 'react';

import DashboardCard from '../../../../organism/DashboardCard';
import { accesosRapidosTexts } from '../../../../../containers/Dashboard/texts';

import { Image } from '../styles';

function BannerSimulaCredito({ pasoactual, handleClickEncuesta, iconPath }) {
  return (
    <div
      id="simulacion"
      className={pasoactual == '5' ? 'simulacionn col-md-6 col-xs-12' : 'col-md-6 col-xs-12'}
    >
      <div className="bml-0 bml-md-1">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="dashboard-encuesta-wrapper">
            <div className="row">
              <div className="col-md-6 col-xs-7 bmb-2">
                <div className="encuesta-title-container">
                  <label className="encuesta-title-text">
                    {accesosRapidosTexts.ENCUESTA_TITLE}
                  </label>
                </div>
                <div className="encuesta-desc-container">
                  <label className="encuesta-desc-text">
                    {accesosRapidosTexts.ENCUESTA_DESC}
                    <label className="encuesta-link-text" onClick={handleClickEncuesta}>
                      {accesosRapidosTexts.ENCUESTA_BUTTON}
                    </label>
                  </label>
                </div>
              </div>
              <div className="col-md-6 col-xs-5">
                <Image
                  className="encuesta-icon-container"
                  src={`${iconPath}ilustracion_dashboard.svg`}
                />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
    </div>
  );
}

export default BannerSimulaCredito;
