/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/depositoAPlazo';

// ------- SAGA ------ //

function fetchDepositoConfiguracionSimularSuccess(state, action) {
  const { rules } = action.payload;

  state.reglasSimulacion = rules;
  state.reglasSimulacionError = false;
}

function fetchDepositoConfiguracionSimularFailed(state) {
  state.reglasSimulacion = [];
  state.reglasSimulacionError = true;
}

function fetchDepositosContratadosSuccess(state, action) {
  const { depositos } = action.payload;

  state.depositosContratados = depositos;
  state.depositosContratadosError = false;
  state.isFetchDepositosContratadosCompleted = true;
}

function fetchDepositosContratadosFailed(state) {
  state.depositosContratados = [];
  state.depositosContratadosError = true;
}

function fetchDepositosIsCompleted(state, action) {
  const { isCompleted } = action.payload;

  state.isFetchedDepositosContratados = isCompleted;
}

function fetchReglasLiquidacionSuccess(state, action) {
  const { reglas } = action.payload;

  state.reglasLiquidacion = reglas;
  state.reglasLiquidacionError = false;
}

function fetchReglasLiquidacionFailed(state) {
  state.reglasLiquidacion = [];
  state.reglasLiquidacionError = true;
}

function fetchOtpDesafioSuccess(state) {
  state.habilitadoOtpSmsError = {};
  state.habilitadoOtpSms = true;
}

function fetchOtpDesafioFailed(state, action) {
  const { error } = action.payload;

  state.habilitadoOtpSmsError = error;
  state.habilitadoOtpSms = false;
}

function liquidarDepositoContratadoSuccess(state) {
  state.liquidarConfirmado = true;
  state.liquidarError = {
    message: null,
    code: null,
  };
}

function liquidarDepositoContratadoFailed(state, action) {
  const { error } = action.payload;

  state.liquidarConfirmado = false;
  state.liquidarError = error;
}

function clearLastLiquidacion(state) {
  state.liquidarConfirmado = false;
  state.liquidarError = {};
}

function fetchComprobanteLiquidarSuccess(state, action) {
  const { detalles } = action.payload;

  state.liquidarComprobante = detalles;
  state.liquidarComprobanteError = false;
}

function fetchComprobanteLiquidarFailed(state) {
  state.liquidarComprobante = {};
  state.liquidarComprobanteError = true;
}

function fetchSimulacionMultipleSuccess(state, action) {
  const { simulaciones } = action.payload;
  state.simulaciones = simulaciones;
  state.simulacionesError = false;
}

function fetchSimulacionMultipleFailed(state) {
  state.simulaciones = [];
  state.simulacionesError = true;
}

function seleccionarSimulacionSuccess(state, action) {
  const { seleccionarSimulacion } = action.payload;
  state.seleccionarSimulacion = seleccionarSimulacion;
  state.seleccionarSimulacionError = false;
}

function seleccionarSimulacionFailed(state) {
  state.seleccionarSimulacion = false;
  state.seleccionarSimulacionError = true;
}

function fetchDAPInvertirReset(state) {
  state.invertirDAP = false;
  state.invertirDAPError = false;
}

function fetchDAPInvertirSuccess(state, action) {
  const { invertirDAP } = action.payload;
  state.invertirDAP = invertirDAP;
  state.invertirDAPError = false;
  state.invertirDAPErrorCode = 0;
}

function fetchDAPInvertirFailed(state, action) {
  const { code } = action.payload;
  state.invertirDAP = false;
  state.invertirDAPError = true;
  state.invertirDAPErrorCode = code;
}

function resetDAP(state) {
  Object.assign(state, initialState);
}

function fetchComprobanteInvertirSuccess(state, action) {
  const { comprobante } = action.payload;
  state.comprobanteInvertir = comprobante;
  state.comprobanteInvertirError = false;
}

function fetchComprobanteInvertirFailed(state) {
  state.comprobanteInvertir = {};
  state.comprobanteInvertirError = true;
}

const fetchDepositoConfiguracionSimular = createAction('fetchDepositoConfiguracionSimular');
const fetchDepositosContratados = createAction('fetchDepositosContratados');
const fetchReglasLiquidacion = createAction('fetchReglasLiquidacion');
const fetchOtpDesafio = createAction('fetchOtpDesafio');
const liquidarDepositoContratado = createAction('liquidarDepositoContratado');
const fetchComprobanteLiquidar = createAction('fetchComprobanteLiquidar');
const fetchSimulacionMultiple = createAction('fetchSimulacionMultiple');
const seleccionarSimulacion = createAction('seleccionarSimulacion');
const fetchDAPInvertir = createAction('fetchDAPInvertir');
const fetchComprobanteInvertir = createAction('fetchComprobanteInvertir');

const depositoAPlazo = createSlice({
  name: 'depositoAPlazo',
  initialState,
  reducers: {
    fetchDepositoConfiguracionSimularSuccess,
    fetchDepositoConfiguracionSimularFailed,
    fetchDepositosContratadosSuccess,
    fetchDepositosContratadosFailed,
    fetchDepositosIsCompleted,
    fetchOtpDesafioSuccess,
    fetchOtpDesafioFailed,
    fetchReglasLiquidacionSuccess,
    fetchReglasLiquidacionFailed,
    liquidarDepositoContratadoSuccess,
    liquidarDepositoContratadoFailed,
    clearLastLiquidacion,
    fetchComprobanteLiquidarSuccess,
    fetchComprobanteLiquidarFailed,
    fetchSimulacionMultipleSuccess,
    fetchSimulacionMultipleFailed,
    seleccionarSimulacionSuccess,
    seleccionarSimulacionFailed,
    fetchDAPInvertirReset,
    fetchDAPInvertirSuccess,
    fetchDAPInvertirFailed,
    resetDAP,
    fetchComprobanteInvertirSuccess,
    fetchComprobanteInvertirFailed,
  },
});

export const depositoAPlazoActions = {
  ...depositoAPlazo.actions,
  fetchSimulacionMultiple,
  fetchDepositoConfiguracionSimular,
  fetchReglasLiquidacion,
  fetchOtpDesafio,
  liquidarDepositoContratado,
  fetchComprobanteLiquidar,
  fetchDepositosContratados,
  seleccionarSimulacion,
  fetchDAPInvertir,
  fetchComprobanteInvertir,
};

export default depositoAPlazo.reducer;
