import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { convenioActions } from '../../../application/actions/convenio';

// api
import api from '../../api';

const apiConvenio = api(process.env.REACT_APP_CONVENIO);

export function* fetchConvenioObtener() {
  try {
    const { data, error } = yield call(apiConvenio.get, 'v1/obtener');
    if (data) {
      yield put(convenioActions.fetchConvenioObtenerSuccess({ data }));
    } else {
      yield put(convenioActions.fetchConvenioObtenerError({ error }));
    }
  } catch (error) {
    yield put(convenioActions.fetchConvenioObtenerError({ error }));
  }
}

export function* watchFetchConvenioObtener() {
  yield takeLatest(convenioActions.fetchConvenioObtener.type, fetchConvenioObtener);
}

export default function* rootSaga() {
  yield spawn(watchFetchConvenioObtener);
}
