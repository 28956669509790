import styled from 'styled-components';

export const Wrapper = styled.div`
  .bloqueo-definitivo-title {
    padding: 24px 32px;
  }

  .comprobante-desc {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .comprobante-titular {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    font-family: 'Ubuntu-Medium';
  }

  .comprobante-mail {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .icon-sello svg {
    width: 114px;
    height: 114px;
  }

  .button-style-bloqueo {
    width: 216px;
  }

  .bloqueo-row {
    padding: 12px 16px;
  }

  .bloqueo-impar {
    background-color: #f6f3f5;
  }

  .bloqueo-text-row {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  @media (max-width: 768px) {
    .bloqueo-definitivo-title {
      padding: 16px;
    }
    .button-style-bloqueo {
      width: 100%;
    }
    .icon-sello svg {
      width: 96px;
      height: 96px;
    }
    .return-bloqueo-center {
      margin-left: 30%;
    }
  }
`;

export const SuccessMessage = styled.div`
  line-height: 24px;
  font-size: 16px;

  padding: 12px 24px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #c2f2bd;
  color: #006b14;

  .succes-message-icon-center {
    align-self: center;
  }

  .success-icon {
    width: 4%;
  }

  .success-text {
    width: 96%;
  }

  .style-alert {
    color: #383638;
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    .success-icon {
      width: 10%;
    }

    .success-text {
      width: 90%;
    }
  }
`;

export default Wrapper;
