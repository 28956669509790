import styled from 'styled-components';

const Wrapper = styled.div`
  .card_impar {
    background-color: #f6f3f5;
  }
  ul {
    list-style: none;
  }
  .expand-list {
    align-items: center;
    display: inline-flex;
  }
  .list-desc {
    margin-left: 8px;
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .sello{
    max-width:114px;
  }
`;
export const Globe = styled.div`
  position: relative;
  background: #e0e4f4;
  border-radius: 4px;
  .image {
    max-width: 82px;
  }
  .container {
    display: flex;
    padding: 0px;
  }
  .container_text {
    padding-left: 10px;
  }
  &::after {
    top: 100%;
    left: 41px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #e0e4f4;
    border-width: 8px;
    margin-left: 0px;
  }
`;

export default Wrapper;
