import styled from 'styled-components';

export default styled.div`
  .content-dap {
    width: 100%;
    text-align: left;
    padding: 24px 3rem 24px 3rem !important;
  }

  .dap--card {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .dap-top--card {
    margin-top: 24px !important;
  }

  .alert--wrapper {
    height: auto;
    width: 100%;
    align-items: center;

    .right-content {
      align-items: center;
    }
  }

  .dap--comprobante--header {
    background-color: rgba(0, 17, 64, 0.8);
    height: 88px;
    align-items: center;
  }

  @media screen and (max-width: 766px) {
    .content-dap {
      padding-left: 12px !important;
      padding-right: 12px !important;
      padding-top: 16px !important;
    }

    #dap-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }

    .dap--card {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .dap-top--card {
      margin-top: 16px !important;
    }

    .dap--card--content {
      padding: 16px !important;
    }

    .alert--wrapper {
      padding-left: 14px !important;

      .right-content {
        align-items: start;

        & > div:first-child {
          width: 30px !important;
          height: 40px !important;
        }

        div,
        p {
          text-align: start;
        }

        svg {
          width: 20px;
          height: 30px;
        }
      }
    }

    .dap--comprobante--header {
      th:first-child {
        width: 122px;
      }

      .pl-16 {
        padding-left: 10px !important;
      }

      .monto {
        font-size: 16px !important;
      }
    }

    .pl-32,
    .pl-24 {
      padding-left: 16px !important;
    }

    .pr-32,
    .pr-24 {
      padding-right: 16px !important;
    }

    .go-to-dap {
      width: 296px;
    }
  }
`;
