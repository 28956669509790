import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Components
import { Radio, Button, InfoImage, Message } from '@design-system-coopeuch/web';
// Organism
import Stepper from '../../../../components/organism/Stepper';
import FooterBackAndContinue from '../../../organism/BonoEscolar/FooterBackAndContinue';
import FormUniversities from '../../../organism/BonoEscolar/Postulacion/FormUniversities';
import ToSon from '../../../organism/BonoEscolar/Postulacion/ToSon';
import CardBlockBonos from '../../../organism/BonoEscolar/Error';
// Atoms
import AlertModal from '../../../atoms/AlertModal';
import CardShadow from '../../../atoms/CardShadow';
// Text
import {STATE_TYPE_USER_TEXT_ME} from '../../../../containers/BonoEscolar/Postulacion/texts';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const PostulacionTemplate = ({
  steps,
  handleChangeBenefit,
  isBenefitMe,
  isBenefitSon,
  isErrorRut,
  nameSon,
  setNameSon,
  rutSon,
  setRutSon,
  isDisabledBtnContinue,
  setInstitute,
  setCareer,
  dataInstitutes,
  handleClickContinue,
  isVisibleModal,
  setIsVisibleModal,
  loading,
  errorGeneric,
  handleClickBack,
  handleClickBtnModal,
  handleClickRetry,
  cardDuplicidad,
}
) => {
  const formVisibility = isBenefitMe && cardDuplicidad === 'HABILITADO';
  const formSonVisibility = isBenefitSon && cardDuplicidad === 'HABILITADO';
  const mesaggeDuplicity = (isBenefitMe && ['EN_CURSO', 'PAGADO'].includes(cardDuplicidad));
  const mesaggeSonDuplicity = (isBenefitSon && ['EN_CURSO', 'PAGADO'].includes(cardDuplicidad));


  return errorGeneric === true ? (
    <Wrapper>
      <CardBlockBonos
        title="¡Lo sentimos!"
        message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        handleClickRetry={handleClickRetry}
      />
    </Wrapper>
  ) : (
    <Wrapper>
      <Stepper className="start-xs" position={2} steps={steps} />
      <div className="boxContainer">
        <CardShadow
          title="Datos del beneficiario"
          content={
            <>
              <h1 className="title">¿Para quién deseas solicitar este beneficio?</h1>
              <Radio
                label="Para mí"
                checked={isBenefitMe}
                onChange={() => handleChangeBenefit(0)}
              />
              <div className="inline">
                <Radio
                  label="Para un hijo(a)"
                  checked={isBenefitSon}
                  onChange={() => handleChangeBenefit(1)}
                />
                <div className="btnIcon" onClick={() => setIsVisibleModal(true)}>
                  <LoadIcon src={`${iconPath}icon_info.svg`} size={16} />
                </div>
              </div>
              <div className="boxDatos">
                {formVisibility && (
                  <FormUniversities {...{ setInstitute, setCareer, dataInstitutes, loading }} />
                )}
                {mesaggeDuplicity && (
                  <div className="bonoSolicitar__content__alert mt-3">
                    <Message
                      description={STATE_TYPE_USER_TEXT_ME[cardDuplicidad]?.description}
                      type={STATE_TYPE_USER_TEXT_ME[cardDuplicidad]?.typeMessage}
                    />
                  </div>
                )}
                {isBenefitSon === true && (
                  <ToSon
                    {...{
                      isErrorRut,
                      nameSon,
                      setNameSon,
                      rutSon,
                      setRutSon,
                      setInstitute,
                      setCareer,
                      dataInstitutes,
                      loading,
                      formSonVisibility,
                      mesaggeSonDuplicity,
                      cardDuplicidad,
                    }}
                  />
                )}
              </div>
            </>
          }
        />
        <AlertModal showModal={isVisibleModal} width={490} onClose={handleClickBtnModal}>
          <div className="boxModal">
            <InfoImage type="info" />
            <p>
              Puedes solicitar este beneficio para tus hijos de forma online. Si eres tutor legal de
              una persona, dirígete a una sucursal.
            </p>
            <Button color="secondary" variant="link" onClick={handleClickBtnModal}>
              Entendido
            </Button>
          </div>
        </AlertModal>
      </div>
      <FooterBackAndContinue
        btnContinueDisabled={isDisabledBtnContinue}
        handleClickContinue={handleClickContinue}
        txtBack="Volver"
        txtBtn="Continuar"
        handleClickBack={handleClickBack}
      />
    </Wrapper>
  );
};

export default PostulacionTemplate;
