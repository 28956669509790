import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../../atoms/Card';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { Link as LinkRRD } from 'react-router-dom';
import { BannerWrapper, LeftColBannerRemanente } from './styles';

const imgPath = process.env.REACT_APP_CLOUDFRONT_IMAGES;
const Index = ({ bannerEstacional, saldoIndividual }) => {
  return (
    <React.Fragment>
      {bannerEstacional.publicado && saldoIndividual?.pago?.monto >= 1 && (
        <div className="row bmb-3 bmb-md-4">
          <BannerWrapper className="col-xs-12">
            <Card className="row p-0 m-0">
              <div className="col-xs-12 col-sm-6 p-0">
                <LeftColBannerRemanente
                  className="row m-0"
                  src={`${imgPath}${bannerEstacional.imagenPredeterminada}`}
                >
                  <div className="col-xs-8 banner-wrapper bp-3 bp-md-2rem white">
                    <Typography tag="p" fontWeight="bold" fontSize={18}>
                      {bannerEstacional.titulo}
                    </Typography>
                    <LinkRRD
                      to="/ecd/productos/remanente"
                      className="text-size-m white d-block d-sm-none"
                    >
                      {bannerEstacional.nombreBoton}
                    </LinkRRD>
                  </div>
                </LeftColBannerRemanente>
              </div>
              <div className="col-sm-6 banner-wrapper d-none d-sm-block bp-3 bp-md-2rem">
                <div
                  className="text-size-m bmb-3"
                  dangerouslySetInnerHTML={{ __html: bannerEstacional.descripcion }}
                />
                <LinkRRD to="/ecd/productos/remanente" className="text-size-m red">
                  <div className="row middle-xs text-bold m-0">{bannerEstacional.nombreBoton}</div>
                </LinkRRD>
              </div>
            </Card>
          </BannerWrapper>
        </div>
      )}
    </React.Fragment>
  );
};

Index.propTypes = {
  bannerEstacional: PropTypes.object,
  saldoIndividual: PropTypes.object,
};

Index.defaultProps = {
  bannerEstacional: {},
  saldoIndividual: {},
};

export default Index;
