import React, { Component } from 'react';
import produce from 'immer';
import { connect } from 'react-redux';
import { isEmailValid } from '../../../utils/validators';

import { errClaveInternet } from '../../../../infrastructure/services/depositoAPlazo/errors';

import { confirmacionTexts } from '../texts';
import ConfirmacionTemplate from '../../../components/templates/DepositoAPlazo/Confirmacion';
import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';
import { descripcionCuentas } from '../../../utils/constants';

class Confirmacion extends Component {
  state = {
    showModal: false,
    opcionCuentasVistaOrigen: [],
    opcionCuentasVistaDestino: [],
    cuentaOrigenSeleccionada: {},
    cuentaDestinoSeleccionada: {},
    mail: '',
    validMail: true,
    invalidMailMessage: '',
    factorPassword: '',
    showPassword: false,
    errorInputPassword: false,
    check: false,
    errorInvertir: {},
    contadorError: 0,
    showAlertCambioTasa: false,
  };

  componentDidMount() {
    const { fetchCuentasVista, correo } = this.props;
    fetchCuentasVista({
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A'],
    });
    this.setState(
      produce((draft) => {
        if (correo.length === 0) {
          draft.validMail = false;
          draft.invalidMailMessage = confirmacionTexts.MAIL_REQUERIDO;
        } else {
          if (isEmailValid(correo)) {
            draft.validMail = true;
            draft.invalidMailMessage = '';
          } else {
            draft.validMail = false;
            draft.invalidMailMessage = confirmacionTexts.MAIL_INVALIDO;
          }
        }
        draft.mail = correo;
      })
    );
    registerGA({ event: 'vpv-privado', page: '/tef/mis-productos/deposito-a-plazo/paso-4' });
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP_SIMULADOR-P4');
  }

  componentDidUpdate(prevProps) {
    const {
      cuentasVista,
      isLoadingCuentasVista,
      invertirDAPError,
      invertirDAP,
      invertirDAPErrorCode,
      simulacion,
    } = this.props;

    const { contadorError } = this.state;

    if (prevProps.isLoadingCuentasVista !== isLoadingCuentasVista) {
      if (!isLoadingCuentasVista) {
        if (cuentasVista.length > 0) {
          this.setState(
            produce((draft) => {
              draft.opcionCuentasVistaOrigen = this.optionsCuentas(cuentasVista);
              draft.opcionCuentasVistaDestino = this.optionsCuentas(cuentasVista);
              draft.cuentaOrigenSeleccionada = cuentasVista[0];
              draft.cuentaDestinoSeleccionada = cuentasVista[0];
            })
          );
          if (cuentasVista[0].saldoDisponible < simulacion.monto) {
            registerGA({
              event: 'eve',
              'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-4',
              'eve-cat': 'Error',
              'eve-lab': 'Saldo insuficiente',
            });
          }
        }
      }
    }
    if (prevProps.invertirDAP !== invertirDAP) {
      if (invertirDAP) {
        const { history, match } = this.props;
        history.push(`${match?.url}/comprobante`);
      }
    }
    if (prevProps.invertirDAPError !== invertirDAPError) {
      if (invertirDAPError) {
        switch (true) {
          case invertirDAPErrorCode === '3':
            if (contadorError > 0) {
              this.setState(
                produce((draft) => {
                  draft.errorInvertir = errClaveInternet[3];
                })
              );
            } else {
              this.setState(
                produce((draft) => {
                  draft.errorInvertir = errClaveInternet[2];
                  draft.contadorError = draft.contadorError + 1;
                })
              );
            }
            break;
          case invertirDAPErrorCode === '1':
            this.setState(
              produce((draft) => {
                draft.errorInvertir = errClaveInternet[4];
              })
            );
            break;
          case invertirDAPErrorCode === 'DAP-03':
            this.setState(
              produce((draft) => {
                draft.showAlertCambioTasa = true;
              })
            );
            break;
          default:
            this.setState(
              produce((draft) => {
                draft.errorInvertir = errClaveInternet[1];
              })
            );
        }
      }
    }
  }

  optionsCuentas = (cuentas) => {
    return cuentas.map(function (cuenta) {
      var descripcionCuenta = '';

      if (cuenta.codigoProducto === 'VI01') {
        descripcionCuenta = descripcionCuentas['VI01'];
      }
      if (cuenta.codigoProducto === 'VI03') {
        descripcionCuenta = descripcionCuentas['VI03'];
      }
      if (cuenta.codigoProducto === 'VI06') {
        descripcionCuenta = 'Monedero Digital';
      }

      var label = {
        label: descripcionCuenta + ' Nº ' + cuenta.numeroCuenta,
        value: cuenta.numeroCuenta,
      };

      return label;
    });
  };

  descripcionCuentas = (cuenta) => {
    var descripcionCuenta = '';

    if (cuenta.codigoProducto === 'VI01') {
      descripcionCuenta = descripcionCuentas['VI01'];
    }
    if (cuenta.codigoProducto === 'VI03') {
      descripcionCuenta = descripcionCuentas['VI03'];
    }
    if (cuenta.codigoProducto === 'VI06') {
      descripcionCuenta = 'Monedero Digital';
    }

    return descripcionCuenta;
  };

  handleCuentaVistaOrigen = (event) => {
    const { cuentasVista, simulacion } = this.props;

    const cuentaSeleccionada = cuentasVista.filter(
      (cuenta) => Number(cuenta.numeroCuenta) === Number(event.target.value)
    );

    this.setState(
      produce((draft) => {
        draft.cuentaOrigenSeleccionada = cuentaSeleccionada[0];
      })
    );
    if (cuentaSeleccionada[0].saldoDisponible < simulacion.monto) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-4',
        'eve-cat': 'Error',
        'eve-lab': 'Saldo insuficiente',
      });
    }
  };

  handleCuentaVistaDestino = (event) => {
    const { cuentasVista } = this.props;

    const cuentaSeleccionada = cuentasVista.filter(
      (cuenta) => Number(cuenta.numeroCuenta) === Number(event.target.value)
    );

    this.setState(
      produce((draft) => {
        draft.cuentaDestinoSeleccionada = cuentaSeleccionada[0];
      })
    );
  };

  handleChangeMail = (event) => {
    this.setState(
      produce((draft) => {
        if (event.target.value.length === 0) {
          draft.validMail = false;
          draft.invalidMailMessage = confirmacionTexts.MAIL_REQUERIDO;
        } else {
          if (isEmailValid(event.target.value)) {
            draft.validMail = true;
            draft.invalidMailMessage = '';
          } else {
            draft.validMail = false;
            draft.invalidMailMessage = confirmacionTexts.MAIL_INVALIDO;
          }
        }
        draft.mail = event.target.value;
      })
    );
  };

  handleClickTerminosModal = () => {
    this.setState(
      produce((draft) => {
        draft.showModal = true;
      })
    );
  };

  handleCloseModal = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-4',
      'eve-cat': 'Click',
      'eve-lab': 'Aceptar terminos y condiciones',
    });
    this.setState(
      produce((draft) => {
        draft.showModal = false;
      })
    );
  };

  handleReturnSimular = () => {
    const { history, match } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-4',
      'eve-cat': 'Click',
      'eve-lab': 'Volver a Simular',
    });
    history.push(`${match?.url}/simulacion`);
  };

  handleReturn = () => {
    const { history, match } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-4',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    history.push(`${match?.url}/resultado`);
  };

  handleIconPassword = () => {
    this.setState(
      produce((draft) => {
        draft.showPassword = !draft.showPassword;
      })
    );
  };

  handleLinkTerminosYCondiciones = () => {
    window.open('https://www.cmfchile.cl/', '_blank');
  };

  onHandlePasswordInput = (event) => {
    const errorMessage = 'La clave debe tener entre 6 y 8 caract.';
    this.setState(
      produce((draft) => {
        draft.factorPassword = event.target.value;
        draft.errorInputPassword =
          event.target.value.length < 6 || event.target.value.length > 8 ? errorMessage : '';
      })
    );
  };

  handleChangeCheck = () => {
    this.setState(
      produce((draft) => {
        draft.check = !draft.check;
      })
    );
  };

  handleClickContinue = () => {
    const { fetchDAPInvertir, simulacionSeleccionada } = this.props;
    const { cuentaOrigenSeleccionada, factorPassword, mail, cuentaDestinoSeleccionada } =
      this.state;
    const params = {
      cuentaOrigen: cuentaOrigenSeleccionada?.numeroCuenta,
      cuentaDestino: cuentaDestinoSeleccionada?.numeroCuenta,
      clave: factorPassword,
      correo: mail,
      identificacionSimulacion: simulacionSeleccionada?.identificacionSimulacion,
    };
    fetchDAPInvertir({ params });
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-4',
      'eve-cat': 'Click',
      'eve-lab': 'Confirmar',
    });
  };

  handleClickRetryError = () => {
    const { errorInvertir } = this.state;

    if (errorInvertir.id === 4) {
      const { history } = this.props;
      history.push('/recuperar-clave');
    } else {
      this.setState(
        produce((draft) => {
          draft.factorPassword = '';
          draft.errorInvertir = {};
        })
      );
    }
  };

  handleClickAlertTasa = () => {
    const { history, match } = this.props;
    history.push(`${match?.url}/simulacion`);
  };

  render() {
    return (
      <ConfirmacionTemplate
        {...this.props}
        {...this.state}
        handleClickTerminosModal={this.handleClickTerminosModal}
        handleCloseModal={this.handleCloseModal}
        descripcionCuentas={this.descripcionCuentas}
        handleCuentaVistaOrigen={this.handleCuentaVistaOrigen}
        handleCuentaVistaDestino={this.handleCuentaVistaDestino}
        handleReturnSimular={this.handleReturnSimular}
        handleChangeMail={this.handleChangeMail}
        handleReturn={this.handleReturn}
        handleIconPassword={this.handleIconPassword}
        onHandlePasswordInput={this.onHandlePasswordInput}
        handleChangeCheck={this.handleChangeCheck}
        handleClickContinue={this.handleClickContinue}
        handleClickRetryError={this.handleClickRetryError}
        handleClickAlertTasa={this.handleClickAlertTasa}
        handleLinkTerminosYCondiciones={this.handleLinkTerminosYCondiciones}
      />
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  correo: entities.persona.correo,
  invertirDAPError: entities.depositoAPlazo.invertirDAPError,
  invertirDAP: entities.depositoAPlazo.invertirDAP,
  isLoadingDAPInvertir: ui.loaders.isLoadingDAPInvertir,
  invertirDAPErrorCode: entities.depositoAPlazo.invertirDAPErrorCode,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDAPInvertir(params) {
    dispatch(depositoAPlazoActions.fetchDAPInvertir(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmacion);
