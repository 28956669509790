import React from 'react';
import PropTypes from 'prop-types';
import { addClass } from '@coopeuch-components/web';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import InputLabel from '../../InputLabel';
import { PasswordWrapper } from './styles';

const Index = ({
  id = 'login-input-password',
  password,
  name = 'password',
  showPassword,
  inputClass,
  onTogglePassword,
  onHandlePasswordInput,
}) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  return (
    <PasswordWrapper>
      <div
        className={addClass([
          'login--warpper-input input--password row'
        ])}
      >
        <p className="text-bold mt-12 mb-12 start-xs">Clave de internet</p>
        <LoadIcon
          src={`${iconPath}lock.svg`}
          size={20}
          color="#1F1E1E"
          className="login--icon-lock"
        />
        <InputLabel
          rounded={4}
          name={name}
          textSize={16}
          onChange={onHandlePasswordInput}
          onPaste={(event) => {
            event.preventDefault();
          }}
          value={password}
          type={showPassword ? 'text' : 'password'}
          className="pl-7"
          id={id}
          inputClass={inputClass}
          placeholder="Ingresar clave de Internet"
          iconFunc={onTogglePassword}
          iconRight={
            <>
              <LoadIcon
                src={`${iconPath}eye_open.svg`}
                className={`eye-icon-password ${showPassword ? 'd-flex' : 'd-none'}`}
                size={20}
                color="black"
              />
              <LoadIcon
                src={`${iconPath}eye_close.svg`}
                className={`eye-icon-password ${showPassword ? 'd-none' : 'd-flex'}`}
                size={20}
                color="black"
              />
            </>
          }
          maxLength={8}
        />
      </div>
    </PasswordWrapper>
  );
};

Index.propTypes = {
  id: PropTypes.string,
  password: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  showPassword: PropTypes.bool.isRequired,
  inputClass: PropTypes.string,
  onTogglePassword: PropTypes.func.isRequired,
  onHandlePasswordInput: PropTypes.func.isRequired,
};

Index.defaultProps = {
  id: 'login-input-password',
  label: 'Clave de internet Coopeuch',
  name: 'password',
  inputClass: '',
};

export default Index;
