import React from 'react';
// Organism
import LoadDocument from '../../../organism/BonoEscolar/Documentacion/LoadDocument';

const DocsTemplate = ({ dataBono, handleUploadFileToServer, loadFile, setbtnContinueDisabled }) => {
  return (
    <>
      {dataBono.typeBono === 'Bono de Ingreso' && dataBono.typeBeneficiario === 'Para Mí' && (
        <DocsIngresoParaMi
          {...{ dataBono, handleUploadFileToServer, loadFile, setbtnContinueDisabled }}
        />
      )}
      {dataBono.typeBono === 'Bono de Ingreso' && dataBono.typeBeneficiario === 'Para mi hijo' && (
        <DocsIngresoParaHijo
          {...{ dataBono, handleUploadFileToServer, loadFile, setbtnContinueDisabled }}
        />
      )}
      {dataBono.typeBono === 'Bono de Titulación' && dataBono.typeBeneficiario === 'Para Mí' && (
        <DocsTitulacionParaMi
          {...{ dataBono, handleUploadFileToServer, loadFile, setbtnContinueDisabled }}
        />
      )}
      {dataBono.typeBono === 'Bono de Titulación' &&
        dataBono.typeBeneficiario === 'Para mi hijo' && (
          <DocsTitulacionParaHijo
            {...{ dataBono, handleUploadFileToServer, loadFile, setbtnContinueDisabled }}
          />
        )}
    </>
  );
};

export default DocsTemplate;

export const DocsIngresoParaMi = ({
  dataBono,
  handleUploadFileToServer,
  loadFile,
  setbtnContinueDisabled,
}) => {
  return (
    <>
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CEDULA_IDENTIDAD_SOCIO"
        backgroundColor="#F6F6F6"
        text="Cédula de identidad del socio por ambos lados"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="DOCUMENTO_ADICIONAL"
        backgroundColor="#FFFFFF"
        text="Comprobante de pago de matrícula o comprobante de gratuidad o certificado de alumno regular 1er año"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
    </>
  );
};

export const DocsIngresoParaHijo = ({
  dataBono,
  handleUploadFileToServer,
  loadFile,
  setbtnContinueDisabled,
}) => {
  return (
    <>
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CEDULA_IDENTIDAD_SOCIO"
        backgroundColor="#F6F6F6"
        text="Cédula de identidad del socio por ambos lados"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CEDULA_IDENTIDAD_HIJO"
        backgroundColor="#FFFFFF"
        text="Cédula de identidad del postulante por ambos lados"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="DOCUMENTO_ADICIONAL"
        backgroundColor="#F6F6F6"
        text="Comprobante de pago de matrícula o comprobante de gratuidad o certificado de alumno regular 1er año"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
    </>
  );
};

export const DocsTitulacionParaMi = ({
  dataBono,
  handleUploadFileToServer,
  loadFile,
  setbtnContinueDisabled,
}) => {
  return (
    <>
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CEDULA_IDENTIDAD_SOCIO"
        backgroundColor="#F6F6F6"
        text="Cédula de identidad del socio por ambos lados"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CERTIFICADO_TITULO"
        backgroundColor="#FFFFFF"
        text="Certificado de título obtenido"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
    </>
  );
};

export const DocsTitulacionParaHijo = ({
  dataBono,
  handleUploadFileToServer,
  loadFile,
  setbtnContinueDisabled,
}) => {
  return (
    <>
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CEDULA_IDENTIDAD_SOCIO"
        backgroundColor="#F6F6F6"
        text="Cédula de identidad del socio por ambos lados"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CEDULA_IDENTIDAD_HIJO"
        backgroundColor="#FFFFFF"
        text="Cédula de identidad del postulante por ambos lados"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
      <LoadDocument
        handleUploadFileToServer={handleUploadFileToServer}
        tipoDocumento="CERTIFICADO_TITULO"
        backgroundColor="#F6F6F6"
        text="Certificado de título obtenido"
        dataBono={dataBono}
        loadFile={loadFile}
        setbtnContinueDisabled={setbtnContinueDisabled}
      />
    </>
  );
};
