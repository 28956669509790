/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { abonoAcreenciasRemanenteActions } from '../../../../application/actions/abonoAcreenciasRemanente';

import { useShallowStore } from '../../../utils/hooks';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';
// import Error from '../../../components/atoms/Error';
// import StepsActions from '../../../components/organism/Stepper/StepsActions';
import ComprobanteTemplate from '../../../components/templates/AbonoAcreenciasRemanente/Comprobante';

const Index = ({
  loading,
  emailComprobante,
  banco,
  tipoCuenta,
  numeroCuenta,
}) => {
  
  const { error: errorDescarga } = useShallowStore((store) => store.entities.abonoAcreenciasRemanente.descargarComprobantePoderEspecial) || {};
  const [serviceError, setServiceError] = useState(false);
  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''))
  const history = useHistory();
  const dispatch = useDispatch();

  const fechaFormateada = format(new Date(), "dd 'de' MMM. yyyy HH:mm' hrs'", {
    locale: es,
  });

  useEffect(() => {
    if (!banco && !tipoCuenta && !numeroCuenta) {
      history.push('/ecd/poder-especial/datos-bancarios');
    }
  }, [banco, tipoCuenta, numeroCuenta, history]);

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      'page' : '/tef/remanente-acreencias/comprobante',
      'valoracion' : 'Firma electronica',
      'ambiente' : 'sitio'
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'PODER_ESPECIAL_COMPROBANTE',
      metodo: 'clave',
      informacion_adicional: {}
    })
  }, []);

  useEffect(() => {
    if (errorDescarga?.server === true) {
      logger(errorDescarga, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'PODER_ESPECIAL_DESCARGAR_COMPROBANTE',
        informacion_adicional: {}
      })
      setServiceError(true)
    }
  }, [errorDescarga]);

  const handleGoHome = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente-acreencias/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
      'ambiente': 'sitio'
    });
    history.push('/ecd/inicio');
  };

  const handleDownload = async () => {
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'PODER_ESPECIAL_DESCARGAR_COMPROBANTE',
      informacion_adicional: {}
    })
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/remanente-acreencias/comprobante',
      'eve-cat' : 'Click',
      'eve-lab' : 'Descargar',
      'ambiente' : 'sitio'
    });
    dispatch(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecial());
  }

  useEffect(() => {
    if (serviceError) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/remanente-acreencias/comprobante',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
        'ambiente': 'sitio'
      });
    }
  }, [serviceError]);

  return (
    <>
      {/* {serviceError ? (
        <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
          <Error className="bmt-3 bmt-md-4" />
          <StepsActions
            id="error-autorizacion"
            className="bmt-3 mt-24"
            backText="Volver"
            backAction={handleGoHomeError}
            hideNextAction
          />
        </div>
      ) : ( */}
        <ComprobanteTemplate
          loading={loading}
          emailComprobante={emailComprobante}
          banco={banco}
          tipoCuenta={tipoCuenta}
          fechaActualizacion={fechaFormateada}
          numeroCuenta={numeroCuenta}
          onHandleDownload={handleDownload}
          onGoBackHome={handleGoHome}
          serviceError={serviceError}
        />
      {/* )} */}
    </>
  );
};

Index.propTypes = {
  loading: PropTypes.bool,
  banco: PropTypes.object,
  tipoCuenta: PropTypes.object,
  numeroCuenta: PropTypes.number,
  emailComprobante: PropTypes.string,
};

Index.defaultProps = {
  loading: false,
  numeroCuenta: null,
  emailComprobante: '',
  banco: null,
  tipoCuenta: null,
};

const mapStateToProps = (store) => ({
  fetchDescargarComprobantePoderEspecial: store.entities.abonoAcreenciasRemanente.descargarComprobanteModificacionCuotasParticipacion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchDescargarComprobantePoderEspecial: () => {
    dispatch(abonoAcreenciasRemanenteActions.fetchDescargarComprobantePoderEspecial());
  },
});

Index.displayName = 'Container_AAR_COM';
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
