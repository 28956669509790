/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { NavLink, useLocation } from 'react-router-dom';
import { Container } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({ active, item, onToggle, onClickMenuWithChildren, onClickMenuItem }) => {
  const { pathname } = useLocation();

  return (
    <Container className={`accordion_item ${active ? 'active' : ''} `}>
      {item.children.length ? (
        <>
          <button
            className="button"
            onClick={(event) => {
              onToggle(event);
              onClickMenuWithChildren({ target: { textContent: item.name } });
            }}
          >
            <div className="content_icon">
              {item.icon && (
                <LoadIcon src={`${iconPath}${item.icon}`} size={16} className="padding_icon" />
              )}
              {item.name}
            </div>
            <LoadIcon
              src={`${iconPath}arrow_down.svg`}
              size={16}
              className={`icon ${active ? 'icon_rotate' : ''}`}
            />
          </button>

          {/* Contenido desplegado *********** */}
          <div className="answer_wrapper" style={active ? { height: '100%' } : { height: '0px' }}>
            <div className="answer">
              {item.children.map((elem) => {
                return (
                  <NavLink
                    key={elem.id}
                    isActive={() => pathname.includes(`/ecd${elem.url}`)}
                    id={elem.id}
                    key={elem.id}
                    className="menu-item"
                    activeClassName="active-menu-item"
                    to={`/ecd${elem.url}`}
                    onClick={onClickMenuItem}
                    data-id={elem.id}
                  >
                    {elem.name}
                  </NavLink>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <NavLink
          isActive={() => pathname.includes(`/ecd${item.url}`)}
          id={item.id}
          className={`button_normal ${item.icon === 'home_alt.svg' ? 'inicio' : ''}`}
          activeClassName="active-menu-item"
          to={`/ecd${item.url}`}
          onClick={onClickMenuItem}
          data-id={item.id}
        >
          <LoadIcon
            src={`${iconPath}${item.icon}`}
            size={16}
            className={`padding_icon ${
              item.icon === 'arrow_right.svg' ? 'remove-padding-arrow-icon' : ''
            }`}
          />
          {item.name}
        </NavLink>
      )}
    </Container>
  );
};

Index.displayName = 'Menu_AccordionItem';
export default Index;
