import styled, { keyframes } from 'styled-components';

export const Content = styled.div`
  height: 100%;
  line-height: 26px;
  .block-phone-link {
    color: #ec1b2b;
  }
  .adjust_padding_button {
    padding: 12px 24px;
  }
  @media screen and (max-width: 766px) {
  }

  .bloqueo--wrapper-text {
    padding: 16px 30px 10px 0;
    line-height: 26px;
  }

  .underline-none {
    a {
      text-decoration: none;
    }
  }
  @media screen and (min-width: 64em) {
    .adjust_head_padding_card {
      padding: 21.5px 32px;
    }
  }
`;

export const MinicardWrapper = styled.div`
  text-align: center;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

export const CardWrapper = styled.table`
  color: #383638;
  table-layout: fixed;
  .tipo-tarjeta {
    font-size: 16px;
    font-weight: bold;
  }
  .titular-tarjeta {
    font-size: 14px;
    font-weight: bold;
  }
  .numero-tarjeta {
    font-size: 14px;
  }
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > svg {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    transform-origin: center;
    animation: ${spin} 2s linear infinite;
  }
`;

export const DescripcionWrapper = styled.div`
  .bloqueo-title {
    color: #383638;
    font-size: 20px;
    line-height: 32px;
  }
  .bloqueo-descripcion {
    font-size: 16px;
    color: #383638;
    line-height: 26px;
  }
`;

export const AlertWrapper = styled.div`
  .style-alert {
    color: #383638;
    text-decoration: underline;
  }
`;
