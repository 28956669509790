import React from 'react';

import Typography from '@coopeuch-components/web/atoms/Typography';

import DashboardCard from '../../../../organism/DashboardCard';
import { accesosRapidosTexts } from '../../../../../containers/Dashboard/texts';

function BannerSolicitalaAhora({ pasoactual, handleLinkSolicitaCV, Couple }) {
  return (
    <div
      id="simulacion"
      className={pasoactual == '5' ? 'simulacionn col-md-6 col-xs-12' : 'col-md-6 col-xs-12'}
    >
      <div className="bml-0 bml-md-1">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="dashboard-encuesta-wrapper">
            <div className="row bottom-xs">
              <div className="col-md-7 col-xs-7 bmb-2">
                <div className="encuesta-title-container">
                  <Typography fontFamily="ubuntu-bold" tag="p" fontSize={17}>
                    {accesosRapidosTexts.SOLICITUD_CV_TITLE}
                  </Typography>
                </div>
                <div className="encuesta-desc-container">
                  <label className="encuesta-desc-text">
                    {accesosRapidosTexts.SOLICITUD_CV_DESC}{' '}
                    <label className="encuesta-link-text" onClick={handleLinkSolicitaCV}>
                      {accesosRapidosTexts.ENCUESTA_LINK}
                    </label>
                  </label>
                </div>
              </div>
              <div className="col-md-5 col-xs-5 container_image">
                <img src={Couple} className="image" alt="Pareja" />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
    </div>
  );
}

export default BannerSolicitalaAhora;
