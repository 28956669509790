import styled from 'styled-components';

export default styled.div`
  .comprobante-card-text-wrapper {
  }
  .cont-sello{
    text-align: right;
  }
  @media screen and (max-width: 400px) {
    .cont-sello{
    text-align: left;
  }
  }
  .sello-comprobante-pago-agua {
    width: 114px;
    display: inline-block;
  }
  @media screen and (max-width: 400px) {
    .sello-comprobante-pago-agua {
      margin-left: -11px;
    }
  }
`;
