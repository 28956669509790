import React, { useState } from 'react';

const Index = () => {
  const [height, setHeight] = useState('1200px');
  const token = localStorage.getItem('tokenLegado');

  const urlBase = process.env.REACT_APP_ATE_URL;
  const urlAte = `${urlBase}?token=${token}&origen=ecdPrivado`;

  const onLoad = () => {
    setHeight(`${window.innerHeight * 1.1}px`);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 mt-4">
          <iframe
            title="iframe-ate"
            id="iframe-ate"
            src={urlAte}
            frameBorder="0"
            width="100%"
            height={height}
            onLoad={onLoad}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
