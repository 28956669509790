/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// Ui
import Typography from '@coopeuch-components/web/atoms/Typography';
import Loading from '@coopeuch-components/web/atoms/Loading';
import Button from '@coopeuch-components/web/atoms/Button';
import Countdown from '../Utils/Countdown';
import DashboardCard from '../../../../components/organism/DashboardCard';
import Error from '../Error';
import CountdownNumbers from '../Utils/CountdownNumbers';

// Styles
import Container from './styles';

// Texts
import passCoopeuch, { errores } from './text';

const FactorPassCoopeuch = (props) => {
  const {
    loading,
    hasOtherFactor,
    HandleChangeFactor,
    factorError,
    setFactorError,
    handleReloadFactores,
    onHandleObtenerDesafio,
    sendDesafio,
    setAskServicePass,
    intentos,
    hasDisabledButton,
    hasSuccess,

    // Counter
    counterActive,
    setCounterActive,
    timeOut,
    setTimeOut,
    timer = 120,
    passLock,
    timeOutPassCoopeuch,
  } = props;
  return (
    <Container>
          {' '}
          <div className="row center-xs column margin-bottom">
            {/* {passLock && <Error title={errores[7]?.title} text={errores[7]?.descrip} />} */}
            {loading && !passLock ? (
              <Loading />
            ) : factorError === null ? (
              <Countdown
                className="mt-24"
                ilustration="pass_coopeuch"
                withCountdownAnimation={counterActive}
                timer={timer}
              />
            ) : (
              <Error title={errores[factorError]?.title} text={errores[factorError]?.descrip} />
            )}

            {loading ? (
              ''
            ) : (
              <Typography
                tag="p"
                fontSize={18}
                align="center"
                // className="center text mt-20 mb-30"
                className={`center text${!!factorError === false ? ' mt-20 mb-30' : ''}`}
              >
                {!!factorError === true
                  ? ''
                  : sendDesafio.track
                  ? passCoopeuch.descriptDesafio
                  : passCoopeuch.descripIngresa}
              </Typography>
            )}

            {(loading || factorError === '0') && passLock === false ? (
              ''
            ) : (
              <Button
                // disabled={hasEnabledButton}
                //disabled={hasDisabledButton === true || hasSuccess === true}
                color="white"
                rounded={5}
                shadow
                height={48}
                className="factor-button-style center"
                bgColor={!!factorError === true || timeOut ? '#007DB7' : counterActive ? '#007DB7' : '#E81E2B'}
                onClick={
                  factorError === '7'
                    ? () => handleReloadFactores()
                    : () => onHandleObtenerDesafio(2.2)
                }
              >
                {!!factorError === true ? (
                  passCoopeuch.buttonError
                ) : counterActive ? (
                  <CountdownNumbers
                    time={timer}
                    {...{
                      counterActive,
                      setCounterActive,
                      setTimeOut,
                      setFactorError,
                      setAskServicePass,
                      timeOutPassCoopeuch,
                    }}
                  />
                ) : (
                  passCoopeuch.buttonIngresa
                )}
              </Button>
            )}
          </div>
    </Container>
  );
};

FactorPassCoopeuch.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  withCountdownAnimation: PropTypes.bool,
  duration: PropTypes.number,
  timer: PropTypes.number,
  stepFactor: PropTypes.number,
  activeTimer: PropTypes.bool,
};

FactorPassCoopeuch.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  message: '',
  buttonText: '',
  withCountdownAnimation: false,
  duration: 120,
  // timer: 0,
  stepFactor: 0,
  activeTimer: false,
};

export default FactorPassCoopeuch;
