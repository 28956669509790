import styled from 'styled-components';

const Container = styled.div`
  .max-width-descarga {
    max-width: 743px;
  }
  .reverse{
    flex-direction: column-reverse;
  }
  /* .align-right-desktop{

  }
  .align-left-desktop{} */
`;
export default Container;
