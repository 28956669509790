import React, { useEffect, useState } from 'react';
import AlertModal from './../../atoms/AlertModal';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import logger from '../../../utils/logger';
import { registerGA } from '../../../utils/metrics';
import {
  formatRut,
  removeGuionesYPuntos,
  stringToPascalCase,
} from '../../../utils/formatters';
import styles from './styles';

import check_active from './../../../assets/svg/check-verde.svg'
import check_inactive from './../../../assets/svg/check-gris.svg'
import icon_back from './../../../assets/svg/16x16svg.svg'

const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ModalClipboard = ({
  desClipboard,
  onPasteDataClipboard,
  baseRut,
}) => {
  const [destinatariosClipboard, setDestinatariosClipboard] = useState(null);
  const [showModal, setShowModal] = useState(null)
  useEffect(() => {
    if (desClipboard) {
      desClipboard
        .then((data) => {
          setDestinatariosClipboard(data);
          if(data){
            setShowModal(true)
            registerGA({
              event: 'eve',
              'eve-acc': '/tef/transferencias/fondos/destinatario-nuevo',
              'eve-cat': 'Mensaje',
              'eve-lab': 'Modal Pegar los datos copiados',
            });
          }
        })
        .catch((error) => {
          console.error('Error al obtener la data del clipboard:', error);
        });
    }
  }, [desClipboard]);
  const closeModal = () => {
     logger(null, {
       rut: baseRut.slice(0, -1),
       digito_verificador: baseRut.slice(-1),
      accion: 'TEF-AGREGAR-NUEVO-DESTINATARIO-COPIAR-PEGAR-CANCELAR',
       informacion_adicional: {},
     });
    setShowModal(false)
    registerGA({
     event: 'eve',
     'eve-acc': '/tef/transferencias/fondos/destinatario-nuevo',
     'eve-cat': 'Click',
     'eve-lab': 'Modal Pegar los datos copiados - Cancelar',
   });
  }

  const cerrarModal = () => {
     logger(null, {
       rut: baseRut.slice(0, -1),
       digito_verificador: baseRut.slice(-1),
       accion: 'TEF-AGREGAR-NUEVO-DESTINATARIO-COPIAR-PEGAR-CERRAR',
       informacion_adicional: {},
     });
    setShowModal(false)
    registerGA({
     event: 'eve',
     'eve-acc': '/tef/transferencias/fondos/destinatario-nuevo',
     'eve-cat': 'Click',
     'eve-lab': 'Modal Pegar los datos copiados - Cerrar',
   });
  }
  const onSubmit = () => {
    const {
        name,
        rut,
        cuenta,
        numCuenta,
        banco,
        email,
        isCuentaRut
    } = destinatariosClipboard;
    onPasteDataClipboard({
      name: name || '', // Acceder a la propiedad 'name'
      rut: rut || '',   // Acceder a la propiedad 'rut'
      cuenta: cuenta || '', // Acceder a la propiedad 'cuenta'
      numCuenta: isCuentaRut ? rut ? removeGuionesYPuntos(rut.slice(0, -1)) : numCuenta : numCuenta || '', // Acceder a la propiedad 'numCuenta'
      banco: banco || '', // Acceder a la propiedad 'banco'
      email: email || '', // Acceder a la propiedad 'email'
      isCuentaRut: isCuentaRut || ''
    });
    setShowModal(false)

     logger(null, {
       rut: baseRut.slice(0, -1),
       digito_verificador: baseRut.slice(-1),
       accion: 'TEF-AGREGAR-NUEVO-DESTINATARIO-COPIAR-PEGAR-PEGARDATOS',
       informacion_adicional: {},
     });

     registerGA({
      event: 'eve',
      'eve-acc': '/tef/transferencias/fondos/destinatario-nuevo',
      'eve-cat': 'Click',
      'eve-lab': 'Modal Pegar los datos copiados - Pegar Datos',
    });
  }

  //iconPath}icon_check_inactive.svg
  //}
  return (
    <AlertModal
      showModal={showModal}
      onClose={cerrarModal} //handleClickCancelPasteData
      width={616}
      title={
        <div style={{ textAlign: 'left', fontWeight: 'bold', fontSize: 22 }}>
            ¿Quieres pegar los datos copiados?
        </div>
      }>
      {destinatariosClipboard && (
        <div style={styles.containerModal}>
          <div style={styles.descriptionModal}>Los datos se pueden editar más adelante.</div>
          <table style={styles.tableContainer}>
            <tr style={styles.infoModal}>
                <td style={styles.text}>Nombre:</td>
                <td>
                  {
                  destinatariosClipboard?.name ? 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      {stringToPascalCase(destinatariosClipboard.name)}
                    </span>
                    <img width={18} src={check_active} alt="Check" />
                  </div>
                  : 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      -
                    </span>
                    <img width={18} src={check_inactive} alt="Check" />
                  </div>
                  }
                  </td>
            </tr>
            
            <tr style={styles.infoModal}>
                <td style={styles.text}>RUT:</td>
                <td>
                  {
                  destinatariosClipboard?.rut ? 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      {formatRut(removeGuionesYPuntos(destinatariosClipboard.rut))}
                    </span>
                    <img width={18} src={check_active} alt="Check" />
                  </div>
                  : 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      -
                    </span>
                    <img width={18} src={check_inactive} alt="Check" />
                  </div>
                  }
                  </td>
            </tr>
            <tr style={styles.infoModal}>
                <td style={styles.text}>Email:</td>
                <td>
                  {
                  destinatariosClipboard?.email ? 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      {destinatariosClipboard.email}
                    </span>
                    <img width={18} src={check_active} alt="Check" />
                  </div>
                  : 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      -
                    </span>
                    <img width={18} src={check_inactive} alt="Check" />
                  </div>
                  }
                  </td>
            </tr>
            <tr style={styles.infoModal}>
                <td style={styles.text}>Institución Financiera:</td>
                <td>
                {destinatariosClipboard?.isCuentaRut
                    ? <div style={styles.tdContainer}>
                        <span style={{...styles.spanText, ...styles.textStrong }}>
                        Banco Estado
                        </span>
                        <img width={18} src={check_active} alt="Check" />
                      </div>
                    :  destinatariosClipboard?.banco ? 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      Banco {stringToPascalCase(destinatariosClipboard.banco)}
                    </span>
                    <img width={18} src={check_active} alt="Check" />
                  </div>
                  : 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      -
                    </span>
                    <img width={18} src={check_inactive} alt="Check" />
                  </div>
                }
                </td>
            </tr>
            <tr style={styles.infoModal}>
                <td style={styles.text}>Tipo de cuenta:</td>
                <td>
                  {
                    destinatariosClipboard?.isCuentaRut ?
                    <div style={styles.tdContainer}>
                      <span style={{...styles.spanText, ...styles.textStrong }}>
                        Cuenta Vista
                      </span>
                      <img width={18} src={check_active} alt="Check" />
                    </div>
                    :
                  destinatariosClipboard?.cuenta ? 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      Cuenta {stringToPascalCase(destinatariosClipboard.cuenta)}
                    </span>
                    <img width={18} src={check_active} alt="Check" />
                  </div>
                  : 
                  <div style={styles.tdContainer}>
                    <span style={{...styles.spanText, ...styles.textStrong }}>
                      -
                    </span>
                    <img width={18} src={check_inactive} alt="Check" />
                  </div>
                  }
                  </td>
            </tr>
            <tr>
                <td style={styles.text}>N° de cuenta:</td>
                <td>
                {destinatariosClipboard?.isCuentaRut
                    ? destinatariosClipboard?.rut
                      ?  <div style={styles.tdContainer}>
                            <span style={{...styles.spanText, ...styles.textStrong }}>
                              {removeGuionesYPuntos(destinatariosClipboard.rut.slice(0, -1))}
                            </span>
                            <img width={18} src={check_active} alt="Check" />
                          </div>
                      : '-'
                    :  destinatariosClipboard?.numCuenta ? 
                    <div style={styles.tdContainer}>
                      <span style={{...styles.spanText, ...styles.textStrong }}>
                        {destinatariosClipboard.numCuenta}
                      </span>
                      <img width={18} src={check_active} alt="Check" />
                    </div>
                    : 
                    <div style={styles.tdContainer}>
                      <span style={{...styles.spanText, ...styles.textStrong }}>
                        -
                      </span>
                      <img width={18} src={check_inactive} alt="Check" />
                    </div>
                  }
                </td>
            </tr>
          </table>
          <hr style={{marginTop: 30}} />
          <div style={styles.containerButtons}>
            <div style={styles.containerbtnClose}>
              <LoadIcon style={styles.colorRED} src={icon_back} size={22} margin="0" />
              <div onClick={closeModal} style={styles.btnLink}>
                Cancelar
              </div>
            </div>
            <Button
              bgColor="#E81E2B"
              color="white"
              className="text-size-s"
              id="btn-dap"
              onClick={onSubmit}
            >
              Pegar datos
            </Button>
          </div>
        </div>
      )}
    </AlertModal>
  );
};

export default ModalClipboard;