import styled from 'styled-components';

const Wrapper = styled.div`
  .banner-link-button:hover {
    background: #e00000;
    color: #ebebeb;
    & path {
      fill: white;
    }
  }
  .contedor_titulo_card_banner {
    display: none;
  }
  .icono_card_banner {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .text-card-banner {
    color: black;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: semi-bold;
    padding-top: 15px;
    line-height: 20px;
  }
  .mensaje_semi_bold {
    font-weight: semi-bold;
  }
  .link-right{
    color:#F42534;
  }
  .override-red-link-btn{
    padding: 0px 0px;
  }
  
  .arrow-red {
    color: #E81E2B;

    path {
      fill: #E81E2B;
    }
  }
`;

export default Wrapper;