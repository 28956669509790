/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

// Ui
import Loading from '@coopeuch-components/web/atoms/Loading';
import { registerGA } from '../../../utils/metrics';

// Container
import TarjetaDeCoordenadas from './TarjetaDeCoordenadas';
import PassCoopeuch from './PassCoopeuch';

// Templates
import Descarga from './Descarga';

const factoresDeSeguridad = {
  1.1: 'claveInternet',
  2.1: 'tarjetaCoordenadas',
  2.2: 'passCoopeuch',
  3.1: 'sms',
  descarga: 'descarga',
};
const factorPreferente = 2.2;

const timeOutPassCoopeuch = 'Tiempo agotado PassCoopeuch';

const Index = (props) => {
  const {
    loadingFactores,
    factoresSeguridad,
    handleReloadFactores,
    onHandleObtenerDesafio,
    termsAndCondicions,
    desafio,
    onHandleSetDesafio,
    sendDesafio,
    onHandleSendDesafio,
    factorError,
    setFactorError,

    loadingDesafioObtener,
    handleCleanStates,
    setAskServicePass,
    intentos,
    activateButton,
    isDisabled,
    hasDisabledButton,

    // Counter
    counterActive,
    setCounterActive,
    timeOut,
    setTimeOut,
    setFsChange,
    hasSuccess,
    passLock,
  } = props;
  const [factor, setFactor] = useState(null);
  const [numeroTarjeta, setNumeroTarjeta] = useState('');

  const [hasOtherFactor, setHasOtherFactor] = useState(false);

  const HandleChangeFactor = (key) => {
    let descriptionFactor;
    if (factorError === '7' || factorError === '4' || factorError === '5') {
      handleReloadFactores();
      // handleCleanStates();
    }
    if (key === 'tarjetaCoordenadas') {
      descriptionFactor = 'Autorizar con Tarjeta Coordenadas';
    } else {
      descriptionFactor = 'Quiero autorizar con PassCoopeuch';
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/editar-datos-personales',
      'eve-cat': 'Click',
      'eve-lab': `${descriptionFactor}`,
    });
    handleCleanStates();
    setFactor(key);
  };

  const findFactor = (arr, key, value) => arr.find((item) => item[key] === value);

  const sortFactorPreferente = (arr) => {
    const passCoopeuch = () => arr.find((item) => item.codigo === factorPreferente);

    const restoArr = () => arr.filter((items) => items.codigo !== factorPreferente);

    const arrayFiltrado = passLock
      ? restoArr(arr)
      : [passCoopeuch(arr) || '', restoArr(arr)].flat();
    const filtrado = arrayFiltrado.filter((items) => items !== '');
    return filtrado;
  };


  useEffect(() => {
    if (factoresSeguridad !== null) {
      const codigos = factoresSeguridad.map((f) => f.codigo);
      const pass = codigos.includes(2.2);
      const tarjetaCor = codigos.includes(2.1);
      registerGA({
        event: 'vpv-privado',
        page: '/tef/editar-datos-personales',
        producto: `${pass ? 'con passcoopeuch' : 'sin passcoopeuch'}`,
      });
      registerGA({
        event: 'vpv-privado',
        page: '/tef/editar-datos-personales',
        producto: `${tarjetaCor ? 'con tarjeta de coordenadas' : 'sin tarjeta de coordenadas'}`,
      });
    }
  }, [factoresSeguridad]);

  useEffect(() => {
    if (factoresSeguridad !== null && !loadingFactores) {
      if (factoresSeguridad.length === 0) {
        setFactor(factoresDeSeguridad['descarga']);
        setHasOtherFactor(false);
      }
      if (factoresSeguridad.length === 1) {
        setFactor(factoresDeSeguridad[factoresSeguridad[0]?.codigo]);
        setHasOtherFactor(false);
      }
      if (factoresSeguridad.length > 1) {
        setFactor(factoresDeSeguridad[sortFactorPreferente(factoresSeguridad)[0].codigo]);
        setHasOtherFactor(true);
      }

      // Identificacion numero de tarjeta
      if (!!findFactor(factoresSeguridad, 'codigo', 2.1)) {
        setNumeroTarjeta(findFactor(factoresSeguridad, 'codigo', 2.1).payload);
      }
    }
  }, [factoresSeguridad, loadingFactores]);

  return (
    <>
      {loadingFactores ? (
        <Loading />
      ) : (
        {
          tarjetaCoordenadas: (
            <TarjetaDeCoordenadas
              numeroTarjeta={numeroTarjeta}
              onHandleObtenerDesafio={onHandleObtenerDesafio}
              enableButton={termsAndCondicions}
              desafio={desafio}
              onHandleSetDesafio={onHandleSetDesafio}
              sendDesafio={sendDesafio}
              onHandleSendDesafio={onHandleSendDesafio}
              // resultDesafio={resultDesafio}
              loading={loadingDesafioObtener}
              factorError={factorError}
              setFactorError={setFactorError}
              handleReloadFactores={handleReloadFactores}
              hasDisabledButton={hasDisabledButton}
              hasSuccess={hasSuccess}
              // Cambio de factor
              HandleChangeFactor={HandleChangeFactor}
              setFsChange={setFsChange}
              setAskServicePass={setAskServicePass}
              hasOtherFactor={hasOtherFactor}
              activateButton={activateButton}
              isDisabled={isDisabled}
              // Counter
              counterActive={counterActive}
              setCounterActive={setCounterActive}
              timeOut={timeOut}
              setTimeOut={setTimeOut}
              passLock={passLock}
            />
          ),
          passCoopeuch: (
            <PassCoopeuch
              // {...props}
              factorError={factorError}
              setFactorError={setFactorError}
              handleReloadFactores={handleReloadFactores}
              loading={loadingDesafioObtener}
              onHandleObtenerDesafio={onHandleObtenerDesafio}
              hasDisabledButton={hasDisabledButton}
              hasSuccess={hasSuccess}
              // Cambio de factor
              HandleChangeFactor={HandleChangeFactor}
              hasOtherFactor={hasOtherFactor}
              sendDesafio={sendDesafio}
              setAskServicePass={setAskServicePass}
              intentos={intentos}
              activateButton={activateButton}
              // Counter
              counterActive={counterActive}
              setCounterActive={setCounterActive}
              timeOut={timeOut}
              setTimeOut={setTimeOut}
              passLock={passLock}
              timeOutPassCoopeuch={timeOutPassCoopeuch}
            />
          ),
          //descarga: <Descarga {...props} />,
        }[factor]
      )}
    </>
  );
};
Index.displayName = 'Factores_Container';
export default Index;
