import Styled from 'styled-components';

export const Wrapper = Styled.div`
& > button:first-of-type {
  margin-left: 0 
}
& > button:last-of-type {
  margin-right: 0 
}
& .step-action-button-wrapper {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem; 
}
& .step-action-button-wrapper button {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 577px) {
  .step-action-button-wrapper {
    flex-basis: 50%;
    max-width: 50%;
  }
  .step-action-button-wrapper:last-of-type {
    text-align:-webkit-right;
  }
}
@media screen and (max-width: 576px) {
  flex-direction: column-reverse;
  .step-action-button-wrapper {
    flex-basis: 100%;
    max-width: 100%;
  }

  button {
    text-align: center;
    width: 100%
  }
}
`