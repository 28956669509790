import { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Utils
// import logger from '../../utils/logger';

class ErrorBoundary extends Component {
  componentDidCatch(error, _info) {
    console.log('componentDidCatch', error);
    // const stream = localStorage.getItem('token') || 'loginStream';
    // logger(stream, 'component', {
    //   pathname: this.props.history.location.pathname,
    //   message: error.message,
    //   stack: info.componentStack,
    // });
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
