import React, { PureComponent, Fragment, Suspense, lazy } from 'react';
import produce from 'immer';

import { connect } from 'react-redux';
import { productActions } from '../../../application/actions/products';
import { historialTransferenciasActions } from '../../../application/actions/historialTransferencias';
import { personaActions } from '../../../application/actions/persona';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';

import PageTitle from '../../components/atoms/PageTitle';

import { formatDPickerDates } from '../../utils/dates';
import { isSameWord } from '../../utils/validators';
import { filterDuplicatedItems } from '../../utils/functions';
import { registerDY } from '../../utils/metrics';
import logger from '../../utils/logger';

const HistorialTefRealizadas = lazy(() => import('./Realizadas'));
const HistorialTefRecibidas = lazy(() => import('./Recibidas'));

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const tabs = {
  0: 'realizadas',
  1: 'recibidas',
};

class HistorialTransferencias extends PureComponent {
  state = {
    tab: 'realizadas',
  };

  componentDidMount() {
    const { fetchDatosPersonales } = this.props;

    fetchDatosPersonales();
    this.onFetchCuentasVistaHandle();
    registerDY({ type: 'PRODUCT', data: ['transferencias'] });
  }

  componentDidUpdate(prevProps) {
    const { cuentas, sinProductos, history, isLoadingHistorial, persona, errorHistorialLog } =
      this.props;

    const { tab } = this.state;

    if (cuentas !== prevProps.cuentas && sinProductos) {
      history.push('/ecd/solicita/cuenta-coopeuch');
    }

    if (prevProps.isLoadingHistorial !== isLoadingHistorial) {
      if (!isLoadingHistorial) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorHistorialLog, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: tab === 'realizadas' ? 'TEF-HISTORIAL REALIZADAS' : 'TEF-HISTORIAL RECIBIDAS',
          informacion_adicional: {},
        });
      }
    }
  }

  onFetchCuentasVistaHandle = () => {
    const { fetchCuentasVista } = this.props;

    fetchCuentasVista({
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A', 'T'],
    });
  };

  onFetchHistorialHandle = ({ ...data }) => {
    const { fechaInicio, fechaFin, tipo, numeroCuenta } = data;

    this.props.fetchHistorialTransferencias({
      fechaInicio: formatDPickerDates(fechaInicio),
      fechaFin: formatDPickerDates(fechaFin),
      tipo,
      numeroCuenta,
    });
  };

  onTabsHandle = (position) => {
    this.props.fetchClearHistorial();

    this.setState(
      produce((draft) => {
        draft.tab = tabs[position];
      })
    );
  };

  onSuggestionsHandle = (historial, query) => {
    const matches = historial?.filter((historialItem) => {
      const { descripcionBanco, numeroCuenta, nombreDestinatario, rutDestinatario } = historialItem;

      const matchBancos = isSameWord(descripcionBanco, query);
      const matchNumeroCuenta = isSameWord(numeroCuenta, query);
      const matchNombre = isSameWord(nombreDestinatario, query);
      const matchRut = isSameWord(rutDestinatario, query);

      if (matchBancos || matchNumeroCuenta || matchNombre || matchRut) {
        return historialItem;
      }
    });

    const suggestions = filterDuplicatedItems(matches, 'nombreDestinatario')?.map((item) => ({
      text:
        item.nombreDestinatario ||
        item.descripcionBanco ||
        String(item.numeroCuenta) ||
        String(item.rutDestinatario),
      id: item.numeroCuenta,
    }));

    return {
      matches,
      suggestions,
    };
  };

  onDownloadHistorialHandle = ({ ...data }) => {
    this.props.descargarHistorial(data);
  };

  render() {
    const { tab } = this.state;
    const {
      cuentas,
      historial,
      isLoadingHistorial,
      isLoadingCuentasVista,
      errorFetchHistorial,
      persona,
      errorCuentasVistas,
    } = this.props;

    return (
      <Fragment>
        <PageTitle
          title="Historial de transferencias"
          icon={<LoadIcon src={`${iconPath}money_exchange.svg`} color="#333" size={24} />}
        />
        <div className="w-100 margin-after-title">
          <Tabs
            bgColor="#fff"
            fontSize={15}
            fontFamily="Ubuntu-Regular"
            activeColor="#e81e2b"
            className="pl-54 tab--wrapper-shadows tabs full-tab"
          >
            <Tabs.Header className="tabs-header-transferencia">
              <Tab callback={this.onTabsHandle} className="tab-style" id="historial-realizadas">
                Realizadas
              </Tab>
              <Tab
                callback={this.onTabsHandle}
                className="tab-style w-150p"
                id="historial-recibidas"
              >
                Recibidas
              </Tab>
            </Tabs.Header>
            <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
              <Fragment>
                <Tabs.Body>
                  <Suspense fallback={<div />}>
                    {tab === 'realizadas' && (
                      <HistorialTefRealizadas
                        onFetchHistorialHandle={this.onFetchHistorialHandle}
                        onFetchCuentasVistaHandle={this.onFetchCuentasVistaHandle}
                        onSuggestionsHandle={this.onSuggestionsHandle}
                        onDownloadHistorialHandle={this.onDownloadHistorialHandle}
                        cuentas={cuentas}
                        historialCompleto={historial}
                        isLoadingServices={isLoadingHistorial || isLoadingCuentasVista}
                        errorFetchHistorial={errorFetchHistorial}
                        errorCuentasVistas={errorCuentasVistas}
                        nombreSocio={`${persona.primerNombre} ${persona.apellidoPaterno}`}
                        rutSocio={persona.rut}
                      />
                    )}
                  </Suspense>
                  <Suspense fallback={<div />}>
                    {tab === 'recibidas' && (
                      <HistorialTefRecibidas
                        onFetchHistorialHandle={this.onFetchHistorialHandle}
                        onFetchCuentasVistaHandle={this.onFetchCuentasVistaHandle}
                        onSuggestionsHandle={this.onSuggestionsHandle}
                        onDownloadHistorialHandle={this.onDownloadHistorialHandle}
                        cuentas={cuentas}
                        historialCompleto={historial}
                        isLoadingServices={isLoadingHistorial || isLoadingCuentasVista}
                        errorFetchHistorial={errorFetchHistorial}
                        errorCuentasVistas={errorCuentasVistas}
                        nombreSocio={`${persona.primerNombre} ${persona.apellidoPaterno}`}
                        rutSocio={persona.rut}
                      />
                    )}
                  </Suspense>
                </Tabs.Body>
              </Fragment>
            </div>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  cuentas: entities.products.cuentaVista.balance,
  sinProductos: entities.products.cuentaVista.sinProductos,
  errorCuentasVistas: entities.products.errorCuentasVistas,
  historial: entities.historialTransferencias.historial,
  errorFetchHistorial: entities.historialTransferencias.errorFetchHistorial,
  isLoadingHistorial: ui.loaders.isLoadingHistorialTransferencias,
  isLoadingCuentasVista: ui.loaders.isLoadingCuentasVista,
  persona: entities.persona,
  errorHistorialLog: entities.historialTransferencias.errorHistorialLog,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
  fetchHistorialTransferencias(props) {
    dispatch(historialTransferenciasActions.fetchHistorialTransferencias(props));
  },
  fetchClearHistorial() {
    dispatch(historialTransferenciasActions.fetchHistorialTefSuccess({ historial: [] }));
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
  descargarHistorial(props) {
    dispatch(historialTransferenciasActions.descargarHistorial(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HistorialTransferencias);
