import styled from 'styled-components';

const Wrapper = styled.div`
  .card-escoge-cuenta-title {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }

  .card-escoge-cuenta-desc {
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }
  .metodo-radio-group-container {
    display: inline-flex;
    align-items: center;
  }
  .metodo-radio-group-container {
    display: inline-flex;
    align-items: center;
  }
  .metodo-input-mail-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .containerCheck {
    display: flex;
    flex-direction: column;
  }
  .widthInput {
    max-width: 298px;
  }
  .error {
    display: flex;
    flex-direction: column;
  }
  .align_continue {
    display: flex;
    justify-content: flex-end;
  }
  .metodo-input-cod-ejecutivo-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;

  }
`;

export const ContainerCheck = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  .textCheck {
    font-size: 14px;
    font-family: 'Ubuntu-Regular';
  }
`;
export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }
  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;

export const CardFooter = styled.div`
  border-top: 2px solid #f4f4f4;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
  button {
    color: #f42534;
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
    border-bottom: 1px solid #f42534;
  }
`;

export default Wrapper;

export const LoaderWrapper = styled.div`
  height: 316px;
  @media (min-width: 960px) {
    height: 319px;
  }
`;
