import {call, put, spawn, takeLatest} from 'redux-saga/effects';
import api from '../../api';
import {alivioTcActions} from "../../../application/actions/aliviotc";
import {loadersActions} from "../../../application/actions/ui_loaders";

const apiAlivioTc = api(process.env.REACT_APP_ALIVIOTC_URL_ENDPOINT);

export function* fetchValidarAlivioTc() {

  try {

    yield put(loadersActions.fetchLoadingValidarAlivioTc({isLoading: true}));

    const response = yield call(apiAlivioTc.post, '/alivio/validar', {});
    const {data} = response;
    if (data) {
      yield put(alivioTcActions.fetchValidarAlivioTcSuccess({data}));
    } else {
      yield put(alivioTcActions.fetchValidarAlivioTcError({error: response}));
    }

    yield put(loadersActions.fetchLoadingValidarAlivioTc({isLoading: false}));

  } catch (error) {
    yield put(alivioTcActions.fetchValidarAlivioTcError({error}));
    yield put(loadersActions.fetchLoadingValidarAlivioTc({isLoading: false}));
  }
}

export function* watchValidarAlivioTc() {
  yield takeLatest(alivioTcActions.fetchValidarAlivioTc.type, fetchValidarAlivioTc);
}

export default function* rootSaga() {
  yield spawn(watchValidarAlivioTc);
}
