import React from 'react';
import Stepper, { RenderStep } from '../../../organism/Stepper';
import Caracteristicas from '../../../organism/Remanente/Capitalizar/ContratoMonedero/Caracteristicas';
import Contratacion from '../../../organism/Remanente/Capitalizar/ContratoMonedero/Contratacion';
import Comprobante from '../../../organism/Remanente/Capitalizar/ContratoMonedero/Comprobante';
import ModalEnviarComprobante from '../../../organism/ContratoMonedero/ModalEnviarComprobante';
import ModalComprobanteEnviado from '../../../organism/ContratoMonedero/ModalComprobanteEnviado';
import ModalEnviarComprobanteError from '../../../organism/ContratoMonedero/ModalEnviarComprobanteError';

const Index = ({
  steps = [],
  currentStep,
  password,
  passwordError,
  serverPasswordError,
  email,
  cuenta,
  fecha,
  secondEmail,
  secondEmailError,
  emailError,
  serviceError,
  backStep1,
  continueStep1,
  backStep2,
  continueStep2,
  backStep3,
  continueStep3,
  closeModal,
  loadingContratacion,
  changingEmailError = false,
  contratacionChecked,
  onHandleCheckBox,
  onHandlePasswordInput,
  onHandleSendComprobante,
  onTogglePassword,
  showPassword,
  openSendEmailModal,
  onHandleEmailInputChange,
  onHandleDownloadComprobante,
  showEnviarComprobanteModal,
  showComprobanteEnviadoModal,
  showEnviarComprobanteErrorModal,
  persona,
  globalError,
  getContratoCanales,
  getContratoMonedero,
  onDownloadGenericContract,
}) => {
  return (
    <React.Fragment>
      {!globalError && (
        <div className="row bmy-3 bmy-md-4">
          <div className="col-xs-12">
            <Stepper position={currentStep} {...{ steps }} />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <RenderStep step={currentStep - 1} error={globalError}>
            <Caracteristicas backButton={backStep1} nextButton={continueStep1} />
            <Contratacion
              {...{
                email,
                persona,
                password,
                emailError,
                serviceError,
                showPassword,
                passwordError,
                onTogglePassword,
                onHandleCheckBox,
                changingEmailError,
                serverPasswordError,
                onHandlePasswordInput,
                onHandleEmailInputChange,
                loading: loadingContratacion,
                onDownloadGenericContract,
              }}
              backButton={backStep2}
              nextButton={continueStep2}
              checked={contratacionChecked}
            />
            <Comprobante
              {...{ cuenta, fecha, email, getContratoCanales, getContratoMonedero }}
              backButton={backStep3}
              nextButton={continueStep3}
              openSendEmailModal={openSendEmailModal}
              downloadComprobante={onHandleDownloadComprobante}
            />
          </RenderStep>
        </div>
      </div>
      <ModalEnviarComprobante
        show={showEnviarComprobanteModal}
        email={secondEmail}
        emailError={secondEmailError}
        onHandleChangeEmail={onHandleEmailInputChange}
        closeAction={closeModal}
        nextButton={onHandleSendComprobante}
      />
      <ModalComprobanteEnviado
        show={showComprobanteEnviadoModal}
        closeAction={closeModal}
        nextButton={closeModal}
      />
      <ModalEnviarComprobanteError
        show={showEnviarComprobanteErrorModal}
        closeAction={closeModal}
      />
    </React.Fragment>
  );
};

export default Index;
