const initState = {
  beneficiosObtener: { success: false, error: false, errorCode: '', data: null, date: null },
  rubroObtener: { success: false, error: false, data: null, date: null },
  regionObtener: { success: false, error: false, data: null, date: null },
  errorBeneficios: '',
  errorRubro: '',
  errorRegion: '',
};

export default initState;
