import { createSlice, createAction } from '@reduxjs/toolkit';
import initState from '../../../domain/entities/migracionCuentaAhorro';

// ---------------- Saga's response ----------------
function setDeclaracionFatcaSuccess(state) {
  state.fatcaSuccess = true;
  state.fatcaError = null;
}

function setDeclaracionFatcaFailed(state, action) {
  const { error } = action.payload;

  state.fatcaSuccess = false;
  state.fatcaError = error;
}

function resetDeclaracionFatca(state) {
  state.fatcaSuccess = initState.fatcaSuccess;
  state.fatcaError = initState.fatcaError;
}

function fetchContratosCuentaAhorroSuccess(state, action) {
  const { contratosCuentaAhorro } = action.payload;
  state.contratosCuentaAhorro = contratosCuentaAhorro;
  state.errorContratosCuentaAhorro = false;
  state.errorDataContratosCuentaAhorro = {};
}

function fetchContratosCuentaAhorroFailed(state, action) {
  const { errorDataContratosCuentaAhorro } = action.payload;
  state.contratosCuentaAhorro = [];
  state.errorContratosCuentaAhorro = true;
  state.errorDataContratosCuentaAhorro = errorDataContratosCuentaAhorro;
}

function fetchBorradorContratoCuentaAhorroSuccess(state, action) {
  const { borradorCuentaAhorro } = action.payload;
  state.borradorCuentaAhorro = borradorCuentaAhorro;
  state.errorBorradorCuentaAhorro = false;
  state.errorDataBorradorCuentaAhorro = {};
}

function fetchBorradorContratoCuentaAhorroFailed(state, action) {
  const { errorDataBorradorCuentaAhorro } = action.payload;
  state.borradorCuentaAhorro = '';
  state.errorBorradorCuentaAhorro = true;
  state.errorDataBorradorCuentaAhorro = errorDataBorradorCuentaAhorro;
}

function migrarCuentaAhorroSuccess(state, action) {
  const { migrarCuentaAhorro } = action.payload;
  state.migrarCuentaAhorro = migrarCuentaAhorro;
  state.errorMigrarCuentaAhorro = false;
  state.errorDataMigrarCuentaAhorro = {};
}

function migrarCuentaAhorroFailed(state, action) {
  const { errorDataMigrarCuentaAhorro } = action.payload;
  state.migrarCuentaAhorro = false;
  state.errorMigrarCuentaAhorro = true;
  state.errorDataMigrarCuentaAhorro = errorDataMigrarCuentaAhorro;
}

/*
 * Here you should create actions that won't intereact with the store.
 * Prefer for actions that will comunicate with Saga
 */

const setDeclaracionFatca = createAction('setDeclaracionFatca');
const fetchContratosCuentaAhorro = createAction('fetchContratosCuentaAhorro');
const fetchBorradorContratoCuentaAhorro = createAction('fetchBorradorContratoCuentaAhorro');
const migrarCuentaAhorro = createAction('migrarCuentaAhorro');

// ------------- connection ------------- //
const migracionCuentaAhorroReducer = createSlice({
  name: 'migracionCuentaAhorroEntity',
  initialState: initState,
  reducers: {
    setDeclaracionFatcaSuccess,
    setDeclaracionFatcaFailed,
    resetDeclaracionFatca,
    fetchContratosCuentaAhorroSuccess,
    fetchContratosCuentaAhorroFailed,
    fetchBorradorContratoCuentaAhorroSuccess,
    fetchBorradorContratoCuentaAhorroFailed,
    migrarCuentaAhorroSuccess,
    migrarCuentaAhorroFailed,
  },
});

export const migracionCuentaAhorroActions = {
  ...migracionCuentaAhorroReducer.actions,
  setDeclaracionFatca,
  fetchContratosCuentaAhorro,
  fetchBorradorContratoCuentaAhorro,
  migrarCuentaAhorro,
};

export default migracionCuentaAhorroReducer.reducer;
