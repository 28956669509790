import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import React from 'react';
import DashboardCard from '../../../../organism/DashboardCard';
import radioOff from '../../../../../assets/svg/rb1.svg';
import radioOn from '../../../../../assets/svg/rb2.svg';

import Wrapper from './styles';

import { tarjetaTexts } from '../../../../../containers/BloqueoTarjetas/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const iconTarjeta = { CV1: 'cv_a.svg', CV2: 'cv_b.svg', TC: 'tc.svg' };

const TarjetaTemplate = ({
  tarjetasSeleccionables,
  tarjetaSeleccionada,
  handleClickTarjeta,
  handleReturnLanding,
  handleClickContinue,
}) => {
  return (
    <Wrapper>
      <div className="bmb-md-4 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Head title={tarjetaTexts.TITLE_BLOQUEO} borderBottom="complete" />
          <DashboardCard.Body classes="bloqueo-definitivo-title">
            {tarjetaTexts.DESC_BLOQUEO}
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      {tarjetasSeleccionables?.map((item, index) => (
        <div className="bmb-md-4 bmb-3" onClick={() => handleClickTarjeta(item)} key={index}>
          <DashboardCard classes="dashboard-card-shadow">
            <DashboardCard.Body classes="bloqueo-definitivo-title">
              <div className="row">
                <div className="col-md-1 col-xs-2">
                  <img src={item.numero === tarjetaSeleccionada.numero ? radioOn : radioOff} />
                </div>
                <div className="col-md-1 col-xs-3 tarjeta-mobile-container-icon">
                  <div className="bmb-2">
                    <LoadIcon src={`${iconPath}${iconTarjeta[item.tipo]}`} className="cv-icon" />
                  </div>
                  <div>
                    <label className="owner-tarjeta-text">{item.owner}</label>
                  </div>
                </div>
                <div className="col-md-10 col-xs-7 tarjeta-mobile-text-container">
                  <div className="bmb-2">
                    <label className="product-tarjeta-text">{item.descripcion}</label>
                  </div>
                  <div className="mt-10">
                    <label className="number-tarjeta-text">
                      {tarjetaTexts.PRE_NUMERO}
                      {item.numero}
                    </label>
                  </div>
                </div>
              </div>
            </DashboardCard.Body>
          </DashboardCard>
        </div>
      ))}
      <div className="row bmb-md-4 bmb-3">
        <div className="col-md-6 col-xs-12 return-bloqueo-center">
          <Button
            color="#EC1B2B"
            className="none-underline middle-items w-100"
            asTextLink
            onClick={handleReturnLanding}
          >
            <div className="row middle-xs">
              <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} className="mr-8 back--icon" />
              <span>{tarjetaTexts.RETURN_BUTTON}</span>
            </div>
          </Button>
        </div>
        <div className="col-md-6 col-xs-12 text-right">
          <Button
            bgColor="#E81E2B"
            color="white"
            id="btn"
            className="button-style-bloqueo"
            disabled={!tarjetaSeleccionada?.numero}
            onClick={() => handleClickContinue(2)}
          >
            {tarjetaTexts.CONTINUE_BUTTON}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default TarjetaTemplate;
