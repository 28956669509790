import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/convenio';

// ------- SAGA ------ //

function fetchConvenioObtenerSuccess(state, action) {
  state.errLogsConvenio = null;
  state.convenioObtener = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchConvenioObtenerError(state, action) {
  const { error } = action.payload || {}

  state.errLogsConvenio = error;
  state.convenioObtener = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

const fetchConvenioObtener = createAction('fetchConvenioObtener');

const convenioReducer = createSlice({
     name: 'convenio',
     initialState,
     reducers: {
      fetchConvenioObtenerSuccess,
      fetchConvenioObtenerError,
     },
});
   
export const convenioActions = {
     ...convenioReducer.actions,
     fetchConvenioObtener,
};
   
export default convenioReducer.reducer;