import styled from 'styled-components';

const Wrapper = styled.div`
  padding-bottom: 16px;
  height: 100%;

  .container {
    padding: 16px !important;
    height: 100%;
  }

  svg {
    width: 100% !important;
    height: 100% !important;
  }

  .red-link {
    color: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }

  button {
    height: auto;
  }

  @media screen and (max-width: 766px) {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: ${({ itIsTheLast }) => (itIsTheLast ? 0 : 16)}px;
  }
`;

export default Wrapper;
