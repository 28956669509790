import Styled from 'styled-components';
import { colors } from '../../../../assets/styles';

export const Container = Styled.div`

.inicio > div > svg >path{
      fill:${() => colors.red};
    }

.padding_icon{
  margin-right: 8px;
        svg{
          path{
            fill:#646569!important;
          }
        }
        div > svg > g > g > path{
      fill:${colors.red} !important;
    }
     
}

.active-menu-item{
  color: #e81e2b !important;
   div > svg >path{
      fill:${colors.red}!important;
    }
}

.icon{
  transition: all 0.3s ease-out;
  transform: rotate(0deg);
}
.icon_rotate{
  transform: rotate(180deg);
}
.button_normal{
  text-decoration: none;
  color:#383638;
  font-size: 16px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  border: 0px transparent;
  padding: 16px 0px 16px 8px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-out;
 
      div{
        svg{
          path{
            fill:#646569;
          }
        }
      }
   
  &:hover{
    background-color: #f6f3f5;
  }
}
.accordion_item {
  border-top: 1px solid #9f9f9f;
}

/* Inicio de la Modificación Texto Aclaración de Movimientos */
#menu-9{
  display: flex;
  font-size: 16px;
  flex-wrap: nowrap;
  align-items: start;

  div {
    margin-top: 3px;
  }
}
/* Fin de la Modificación Texto Aclaración de Movimientos */

.button {
  color:#383638;
  background-color: transparent;
  padding: 16px 0px 16px 8px;
  font-size: 16px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  cursor: pointer;
  border: none;
  &:hover{
    background-color: #f6f3f5;
  }
    .content_icon{
      display: flex;
      div{
        svg{
          path{
            fill:#646569 !important;
          }
        }
      }
    }
}
.control {
  font-size: 20px;
}

.answer_wrapper {
  height: 0;
  overflow: hidden;
  transition: height ease 0.2s;
  .answer {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left:24px;
    .menu-item , .menu-item:root{
      padding: 16px 0px 16px 8px;
      text-decoration: none;
      color: #383638;
      cursor: pointer;
      font-size: 14px!important;
      transition: all 0.3s ease-out;
      &:hover{
        background-color: #f6f3f5;
      }
    }
  }
}
/* activate toggle */
.accordion_item.active .button {
  background-color: #105057;
}
`;
export const Item = Styled.div`
display: flex;
align-items: center;
color: #383638;
height: 3em;
padding-top: 1em;
padding-bottom: 1em;
padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
${this}: hover  {
  background-color: #F6F3F5
}
 
 


.active-menu-item p{
  color: ${() => colors.red};
  font-weight: bold!important;
}

.remove-padding-arrow-icon {
  margin-left: -5px;
  margin-right: 3px;
}
.first-level-menu-item {
  font-size: 16px;
}
.cursor-default {
  cursor: default
}

`;

export default Container;
