import React from 'react';
import DashboardCard from '../../../organism/DashboardCard';
import Button from '@coopeuch-components/web/atoms/Button';

import Wrapper, { Image } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Index = ({ handleClickSocio }) => {
  return (
    <Wrapper>
      <div className="bmr-md-1 bmr-0 bmb-md-4 bmb-3 bml-md-1 bml-0">
        <DashboardCard classes="m-0 dashboard-card-shadow producto-dashboard-card">
          <DashboardCard.Body classes="producto-container">
            <Image src={`${staticPath}dashboardbanner/socio_desk.jpg`}>
              <div className="socio-container">
                <div className="display-desktop">
                  <label className="socio-title">Hazte Socio Coopeuch</label>
                </div>
                <div className="bmt-2 display-desktop">
                  <label className="socio-subtitle">
                    Únete a Coopeuch y aprovecha <br /> todos los beneficios
                  </label>
                </div>
                <div className="bmt-2 display-mobile">
                  <label className="socio-subtitle">
                    Únete a Coopeuch
                    <br /> y aprovecha todos <br /> los beneficios
                  </label>
                </div>
                <div className="bmt-3">
                  <Button
                    bgColor="#E81E2B"
                    color="white"
                    onClick={handleClickSocio}
                    className="socio-button"
                  >
                    Hazte Socio
                  </Button>
                </div>
              </div>
            </Image>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
    </Wrapper>
  );
};

export default Index;
