/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/tipo';

function fetchRegionesSuccess(state, action) {
  state.regiones = { values: action.payload.data, success: true, error: false };
}
function fetchComunasSuccess(state, action) {
  state.comunas = { values: action.payload.data, success: true, error: false };
}
function fetchSucursalesSuccess(state, action) {
  const values = action.payload.data.filter(
    (sucursal) => !!sucursal.latitud && !!sucursal.longitud
  );
  state.sucursales = { values, success: true, error: false };
}
function fetchCoberturaComunaSuccess(state, action) {
  state.coberturaComuna = { values: action.payload.data, success: true, error: false };
}
function fetchRegionesError(state, action) {
  state.regiones = { values: [], error: true, success: false };
}
function fetchComunasError(state, action) {
  state.comunas = { values: [], error: true, success: false };
}
function fetchSucursalesError(state, action) {
  state.sucursales = { values: [], error: true, success: false };
}
function fetchCoberturaComunaError(state, action) {
  state.coberturaComuna = { values: [], error: true, success: false };
}
function fetchMotivoTarjetaBloqueoSuccess(state, action) {
  state.motivoTarjetaBloqueo = { values: action.payload.data, success: true, error: false };
}
function fetchMotivoTarjetaBloqueoFailed(state) {
  state.motivoTarjetaBloqueo = { values: [], error: true, success: false };
}

function fetchSucursalesComunaSuccess(state, action) {
  state.sucursalesComuna = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchSucursalesComunaFailed(state, action) {
  state.sucursalesComuna = {
    success: false,
    error: true,
    data: null,
    errorCode: action.payload.data,
    date: new Date().toISOString(),
  };
}

const fetchRegiones = createAction('fetchRegiones');
const fetchComunas = createAction('fetchComunas');
const fetchSucursales = createAction('fetchSucursales');
const fetchCoberturaComuna = createAction('fetchCoberturaComuna');
const fetchMotivoTarjetaBloqueo = createAction('fetchMotivoTarjetaBloqueo');
const fetchSucursalesComuna = createAction('fetchSucursalesComuna');

// ------------- connection ------------- //

const tipoReducer = createSlice({
  name: 'tipo',
  initialState,
  reducers: {
    fetchRegionesSuccess,
    fetchComunasSuccess,
    fetchSucursalesSuccess,
    fetchRegionesError,
    fetchComunasError,
    fetchSucursalesError,
    fetchCoberturaComunaSuccess,
    fetchCoberturaComunaError,
    fetchMotivoTarjetaBloqueoSuccess,
    fetchMotivoTarjetaBloqueoFailed,
    fetchSucursalesComunaSuccess,
    fetchSucursalesComunaFailed,
  },
});

export const tipoActions = {
  ...tipoReducer.actions,
  fetchRegiones,
  fetchComunas,
  fetchSucursales,
  fetchCoberturaComuna,
  fetchMotivoTarjetaBloqueo,
  fetchSucursalesComuna,
};

export default tipoReducer.reducer;
