/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/factoresSeguridad';
import { confirmacionTexts } from '../../../ui/containers/TransferirFondos/texts';

// ------- SAGA ------ //
function fetchFactoresSeguridadSuccess(state, action) {
  const { factoresSeguridad } = action.payload;

  state.factoresSeguridad = factoresSeguridad;
  state.errorFactoresSeguridad = false;
  state.errorFactoresMessage = {};
  state.errorFactoresMessageLog = null;
}

function fetchFactoresSeguridadFailed(state, action) {
  const { error, errLog } = action.payload;

  state.factoresSeguridad = [];
  state.errorFactoresSeguridad = true;
  state.errorFactoresMessage = error;
  state.errorFactoresMessageLog = errLog;
}

function validarUsingFactorFailed(state, action) {
  const { error, type } = action.payload;

  state[type || 'errorFactoresMessage'] = error;
  state.errorFactorLog = action.payload.errorFactorLog;
}

function fetchDesafioTarjetaCoordenadasResponse(state, action) {
  const { desafio1, desafio2, desafio3 } = action.payload;

  state.desafio1 = desafio1;
  state.desafio2 = desafio2;
  state.desafio3 = desafio3;
  state.errorFactorLog = '';
}

function validarDesafioTarjetaCoordenadasResponse(state, action) {
  const { validacionFactorDeSeguridad } = action.payload;

  state.validacionFactorDeSeguridad = validacionFactorDeSeguridad;
  state.primerFactorValidado = 2.1;
}

function cambiarFactorSeguridad(state, action) {
  const { factorActivo } = action.payload;
  state.factorActivo = factorActivo;
}

function cambiarPropiedadesFactor(state, action) {
  const { factorActivo, propiedad, valor } = action.payload;

  state.propiedadesFactor[factorActivo][propiedad] = valor;
}

function resetFactoresSeguridad(state) {
  state.factorActivo = 0;
  state.desafio1 = '';
  state.desafio2 = '';
  state.desafio3 = '';
  state.validacionFactorDeSeguridad = false;
  state.tarjetaCoordenadasErrors = {};
  state.passCoopeuchErrors = {};
  state.smsErrors = {};
  state.errorFactoresMessage = {};
  state.errorFactoresMessageLog = null;
  state.errorFactoresSeguridad = false;
  state.propiedadesFactor = {
    2.1: {
      step: 0,
      imagen: 'tarjeta_coordenadas',
      mensaje: confirmacionTexts.LABEL_DESC_TARJETA_COORDENADAS,
      botonText: confirmacionTexts.LABEL_BUTTON_TARJETA_COORDENADAS,
      tipo: 2.1,
      valorDesafio1: '',
      valorDesafio2: '',
      valorDesafio3: '',
      activeTimer: false,
    },
    2.2: {
      step: 0,
      imagen: 'pass_coopeuch',
      mensaje: confirmacionTexts.LABEL_DESC_COOPEUCH_PASS,
      botonText: confirmacionTexts.LABEL_BUTTON_COOPEUCH_PASS,
      tipo: 2.2,
      activeTimer: false,
    },
    3.1: {
      step: 0,
      imagen: 'sms',
      mensaje: '',
      botonText: confirmacionTexts.LABEL_BUTTON_SMS,
      tipo: 3.1,
      activeTimer: false,
      valorDesafio1: '',
      valorDesafio2: '',
      valorDesafio3: '',
      valorDesafio4: '',
      valorDesafio5: '',
      valorDesafio6: '',
    },
  };
  state.obtencionFactorCoopeuchPass = false;
  state.validacionFactorDeSeguridadSMS = false;
  state.primerFactorValidado = '';
}

function resetObtenerDesafioFactorCoopeuchPass(state) {
  state.obtencionFactorCoopeuchPass = false;
}

function resetDesafioSMSFactor(state) {
  state.propiedadesFactor[3.1].valorDesafio1 = '';
  state.propiedadesFactor[3.1].valorDesafio2 = '';
  state.propiedadesFactor[3.1].valorDesafio3 = '';
  state.propiedadesFactor[3.1].valorDesafio4 = '';
  state.propiedadesFactor[3.1].valorDesafio5 = '';
  state.propiedadesFactor[3.1].valorDesafio6 = '';
}

function fetchDesafioFactorPassCoopeuchResponse(state, action) {
  const { obtencionFactorCoopeuchPass } = action.payload;
  state.obtencionFactorCoopeuchPass = obtencionFactorCoopeuchPass;
  state.errorFactorLog = '';
}

function validarDesafioCoopeuchPassResponse(state, action) {
  const { validacionFactorDeSeguridad } = action.payload;

  state.validacionFactorDeSeguridad = validacionFactorDeSeguridad;
  state.primerFactorValidado = 2.2;
}

function fetchDesafioSMSResponse() {}

function validarDesafioSMSResponse(state, action) {
  const { validacionFactorDeSeguridadSMS } = action.payload;
  state.validacionFactorDeSeguridadSMS = validacionFactorDeSeguridadSMS;
}

function clearInformacionFactores(state) {
  state.errorFactoresSeguridad = false;
  state.errorFactoresMessage = {};
  state.errorFactoresMessageLog = null;
  state.passCoopeuchErrors = {};
  state.tarjetaCoordenadasErrors = {};
  state.smsErrors = {};
  state.validacionFactorDeSeguridad = false;
  state.validacionFactorDeSeguridadSMS = false;
  state.obtencionFactorCoopeuchPass = false;
  state.desafio1 = '';
  state.desafio2 = '';
  state.desafio3 = '';
}

function clearDesafiosCoords(state) {
  state.desafio1 = '';
  state.desafio2 = '';
  state.desafio3 = '';
}

function fetchReglasFactoresSeguridadResponse(state, action) {
  const { reglasFactoresSeguridad, errorLog } = action.payload;
  state.reglasFactoresSeguridad = reglasFactoresSeguridad;
  state.errorReglasFactoresSeguridad = errorLog;
}

const fetchFactoresSeguridad = createAction('fetchFactoresSeguridad');
const fetchDesafioFactorPassCoopeuch = createAction('fetchDesafioFactorPassCoopeuch');
const fetchDesafioTarjetaCoordenadas = createAction('fetchDesafioTarjetaCoordenadas');
const validarDesafioTarjetaCoordenadas = createAction('validarDesafioTarjetaCoodenadas');
const validarDesafioCoopeuchPass = createAction('validarDesafioCoopeuchPass');
const fetchDesafioSMS = createAction('fetchDesafioSMS');
const validarDesafioSMS = createAction('validarDesafioSMS');
const fetchReglasFactoresSeguridad = createAction('fetchReglasFactoresSeguridad');

const factoresSeguridadReducer = createSlice({
  name: 'factoresSeguridad',
  initialState,
  reducers: {
    fetchFactoresSeguridadSuccess,
    fetchFactoresSeguridadFailed,
    validarUsingFactorFailed,
    fetchDesafioTarjetaCoordenadasResponse,
    validarDesafioTarjetaCoordenadasResponse,
    cambiarFactorSeguridad,
    cambiarPropiedadesFactor,
    resetFactoresSeguridad,
    validarDesafioCoopeuchPassResponse,
    fetchDesafioFactorPassCoopeuchResponse,
    resetObtenerDesafioFactorCoopeuchPass,
    fetchDesafioSMSResponse,
    validarDesafioSMSResponse,
    resetDesafioSMSFactor,
    clearInformacionFactores,
    clearDesafiosCoords,
    fetchReglasFactoresSeguridadResponse,
  },
});

export const factoresSeguridadActions = {
  ...factoresSeguridadReducer.actions,
  fetchFactoresSeguridad,
  fetchDesafioFactorPassCoopeuch,
  fetchDesafioTarjetaCoordenadas,
  validarDesafioTarjetaCoordenadas,
  validarDesafioCoopeuchPass,
  fetchDesafioSMS,
  validarDesafioSMS,
  fetchReglasFactoresSeguridad,
};

export default factoresSeguridadReducer.reducer;
