import React from 'react';
import PropTypes from 'prop-types';

const ArrowRightIcon = ({ color }) => (
  <svg width="100%" height="100%" viewBox="0 0 8 12" preserveAspectRatio="xMidYMid meet">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-126.000000, -266.000000)">
        <g transform="translate(120.000000, 262.000000)">
          <rect x="0" y="0" width="20" height="20" />
          <g transform="translate(6.250000, 3.750000)" fill={color} id="arrow-color">
            <path d="M0.302083333,10.2916667 L4.34375,6.25 L0.302083333,2.20833333 C-0.104166667,1.80208333 -0.104166667,1.14583333 0.302083333,0.739583333 C0.708333333,0.333333333 1.36458333,0.333333333 1.77083333,0.739583333 L6.55208333,5.52083333 C6.95833333,5.92708333 6.95833333,6.58333333 6.55208333,6.98958333 L1.77083333,11.7708333 C1.36458333,12.1770833 0.708333333,12.1770833 0.302083333,11.7708333 C-0.09375,11.3645833 -0.104166667,10.6979167 0.302083333,10.2916667 Z" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ArrowRightIcon.propTypes = {
  color: PropTypes.string,
};

ArrowRightIcon.defaultProps = {
  color: '#333',
};

export default ArrowRightIcon;
