import React, { useEffect, useState } from 'react';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';


import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';

import DashboardCard from '../../../components/organism/DashboardCard';
import PageTitle from '../../../components/atoms/PageTitle';
import NumericInput from '../../../components/atoms/NumericInput';
import SolicitudModificacion from '../SolicitudModificacion';
import Information from '../../../assets/information.png'

import Autorizacion from '../Autorizacion';
import Comprobante from '../Comprobante';

import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';
import ErrorsCuotas from '../Errors';
import AuthButton from '../../../components/atoms/ButtonAuth'

import { PageTitleWrapper, TabsWrapper } from '../styles';
import './style.css'

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({
  fetchCuotasParticipacion,
  cuotas,
  fetchSugerenciaCuotasParticipacion,
  handleUpdateCuotas,
  errorCuotaActualizacion,
  actualizacionCuotas,
  handleSetErrors,
  // eslint-disable-next-line camelcase
  modify_cuotas,
  persona,
 }) => {
  useEffect(() => {
    fetchCuotasParticipacion();
    fetchSugerenciaCuotasParticipacion();
  }, [fetchCuotasParticipacion, fetchSugerenciaCuotasParticipacion]);
  const [validateAuth, setValidateAuth] = useState(true)
  const [clave, setClave] = useState()
  const [validateMinMax, setValidateMinMax] = useState(0);
  const [calculateCuota, setCalculateCuota] = useState(0);
  const [secondStep, setSecondStep] = useState(false)
  const [thirdStep, setThirdStep] = useState(false)
  const [error, setError] = useState(false);
  const [errorActualizacion, setErrorActualizacion] = useState(false)
  const [baseRut,] = useState(String((persona?.rut || localStorage.getItem('user-rut') || '').replace('-', '')))
  const fechaActual = new Date();
  const history = useHistory();

  // Obtiene los componentes de la fecha que necesitas
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth() + 1; // Los meses empiezan desde 0
  const año = fechaActual.getFullYear();
  const horas = fechaActual.getHours();
  const minutos = fechaActual.getMinutes();

   // Agrega ceros a la izquierda si la hora o los minutos son menores a 10
   const formatoDosDigitos = (valor) => (valor < 10 ? `0${valor}` : valor);

   // Formatea la fecha como "Dia-Mes-Año Hora:Minutos"
   const fechaFormateada = `${dia}-${mes}-${año} ${formatoDosDigitos(horas)}:${formatoDosDigitos(minutos)}`; 

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const [cuotasPactadasPorMes, setCuotasPactadasPorMes] = useState(cuotas?.data?.pactadas?.cuotasPactadas)

  useEffect(() => {
    if(!modify_cuotas?.success){
      history.push('/ecd/inicio/');
    }
  // eslint-disable-next-line camelcase
  }, [modify_cuotas])

  useEffect(() => {
    if (cuotas?.data?.pactadas?.cuotasPactadas !== undefined) {
      setCuotasPactadasPorMes(cuotas.data.pactadas.cuotasPactadas.toString());
    }
  }, [cuotas]);

  useEffect(() => {
    const errors = {
      code: errorCuotaActualizacion?.statusCode,
      message: errorCuotaActualizacion?.message
    };
    setErrorActualizacion(errorCuotaActualizacion?.error);
    const logData = {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      informacion_adicional: {},
    };
  
    const registerAndLogError = (eveLabel, accion) => {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso4',
        'eve-cat': 'Error',
        'eve-lab': eveLabel
      });
  
      logger(errors, { ...logData, accion });
    };
  
    if (errorCuotaActualizacion) {
      switch (errorCuotaActualizacion.code) {
        case "AUTH-01":
        case "AUTH-02":
          registerAndLogError('Clave incorrecta', 'CP_AUTORIZAR_MODIFICACION');
          break;
        case "AUTH-03":
          registerAndLogError('Clave bloqueada', 'CP_AUTORIZAR_MODIFICACION');
          break;
        default:
          if (errors.code >= 500) {
            registerAndLogError('Servicio no disponible', 'CP_AUTORIZAR_MODIFICACION');
          }
          break;
      }
    }
  }, [errorCuotaActualizacion]);
  

  const logAction = (accion, informacionAdicional = {}) => {
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion,
      informacion_adicional: informacionAdicional,
    });
  };

  useEffect(() => {
    setErrorActualizacion(errorCuotaActualizacion?.error);

    if (actualizacionCuotas?.success) {
      const cuotaAnterior = cuotas?.data?.pactadas?.cuotasPactadas;

      // Check if cuotas were decreased or increased
      if (cuotasPactadasPorMes < cuotaAnterior) {
        logAction('CP_DISMINUIR_CANTIDAD');
      } else if (cuotasPactadasPorMes > cuotaAnterior) {
        logAction('CP_AUMENTAR_CANTIDAD');
      }

      // Log authorization of modification
      logAction('CP_AUTORIZAR_MODIFICACION');
    }
  }, [actualizacionCuotas]);

  useEffect(() => {
    if (cuotasPactadasPorMes >= 10 && cuotasPactadasPorMes <= 100 && cuotasPactadasPorMes !== cuotas?.data?.pactadas?.cuotasPactadas) {
      setSecondStep(true);
    }
    if (cuotasPactadasPorMes === "") {
      setSecondStep(false);
    }
    if (validateMinMax === cuotas?.data?.pactadas?.cuotasPactadas) {
      setSecondStep(false);
    }
    if (validateMinMax > 100) {
      setSecondStep(false);
    }
    if (validateMinMax < 10) {
      setSecondStep(false);
    }
  }, [cuotasPactadasPorMes, validateMinMax])

  useEffect(() => {
    const calculateInitialValue = cuotasPactadasPorMes * cuotas?.data?.valorCuota;
    setCalculateCuota(formatNumber(calculateInitialValue));
  }, [])

  const handleInputChange = (text) => {
    setCuotasPactadasPorMes(text);
    const numericValue = parseInt(text, 10);
    const validateMinMax = numericValue;
    const validateErrorMaxMin =
      Number.isNaN(numericValue) || numericValue < 10 || numericValue > 100;
    setError(Boolean(validateErrorMaxMin));
    setValidateMinMax(validateMinMax);
    const calculatedValue =
      validateMinMax <= 100 ? numericValue * cuotas?.data?.valorCuota : 0;
    setCalculateCuota(formatNumber(calculatedValue));
  };

  function getAssistText() {
    if (validateMinMax === cuotas?.data?.pactadas?.cuotasPactadas) {
      return [{ text: 'Debes ingresar un aporte mayor o menor al actual.', type: 'error' }];
    } if (cuotasPactadasPorMes >= 10 && cuotasPactadasPorMes <= 100 || cuotasPactadasPorMes === "") {
      return [{ text: 'Mínimo 10, máximo 100.', type: 'assist' }];
    } if (validateMinMax > 100) {
      return [{ text: 'Excediste el máximo permitido. Ingresa un aporte menor a 100 cuotas.', type: 'error' }];
    } if (validateMinMax < 10) {
      return [{ text: 'Por favor ingresa un aporte mayor a 10.', type: 'error' }];
    }
    return null;
  }

  const assistText = getAssistText();

  useEffect(() => {
    if (validateMinMax === cuotas?.data?.pactadas?.cuotasPactadas) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso1-a',
        'eve-cat': 'Error',
        'eve-lab': 'Debes ingresar un aporte mayor o menor al actual.'
      });
    }
    if (cuotasPactadasPorMes >= 10 && cuotasPactadasPorMes <= 100 || cuotasPactadasPorMes === "") {
      return;
    }
    if (validateMinMax > 100) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso1-a',
        'eve-cat': 'Error',
        'eve-lab': 'Excediste el máximo permitido. Ingresa un aporte menor a 100 cuotas.'
      });
    }
  }, [assistText]);

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      'page' : '/tef/cuota-participacion/modificacion-cuota/paso1-a',
      'producto' : 'Otro aporte'
    });
  }, []);

  useEffect(() => {
      registerGA({
        event: 'vpv-privado',
        'page' : '/tef/cuota-participacion/modificacion-cuota/paso2',
        'producto' : 'Otro aporte'
      });
  }, []);

  useEffect(() => {
    if (thirdStep) {
      registerGA({
        event: 'vpv-privado',
        'page' : '/tef/cuota-participacion/modificacion-cuota/paso3',
        'producto' : 'Otro aporte'
      });
    }
  }, [thirdStep]);

  const downloadHistorialGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/cuota-participacion/modificacion-cuota/paso2',
      'eve-cat' : 'Click',
      'eve-lab' : 'Descargar solicitud',
      'producto' : 'Otro aporte'
    });
  }

  const acceptTermsGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/cuota-participacion/modificacion-cuota/paso2',
      'eve-cat' : 'Click',
      'eve-lab' : 'Acepto términos y condiciones',
      'producto' : 'Otro aporte'
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CP_ACEPTAR_TERMINOS',
      informacion_adicional: {},
    });
  }

  const returnGAsecondStep = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/cuota-participacion/modificacion-cuota/paso2',
      'eve-cat' : 'Click',
      'eve-lab' : 'Volver',
      'producto' : 'Otro aporte'
    });
  }  

  const returnGAthirdStep = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/cuota-participacion/modificacion-cuota/paso3',
      'eve-cat' : 'Click',
      'eve-lab' : 'Volver',
      'producto' : 'Otro aporte'
    });
  }

  const authorizeGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc' : '/tef/cuota-participacion/modificacion-cuota/paso3',
      'eve-cat' : 'Click',
      'eve-lab' : 'Autorizar',
      'producto' : 'Otro aporte'
    });
  }

  useEffect(() => {
    if (error) {
      if (validateMinMax > 100) {
        registerGA({
          event: 'eve',
          'eve-acc' : '/tef/cuota-participacion/modificacion-cuota/paso1-a',
          'eve-cat' : 'Error',
          'eve-lab' : 'Excediste el máximo permitido. Ingresa un aporte menor a 100 cuotas',
        });
      }
    }
  }, [error]);

  // eslint-disable-next-line no-shadow
  const handleClickAuth = (cuotasPactadasPorMes, condsandterms, password) => {
    handleUpdateCuotas(
      cuotaParticipacionActions.fetchActualizacionCuotasParticipacion(
        {
          'params': {
            'cuotasPactadas': cuotasPactadasPorMes,
            'clave': password,
            'aceptoTerminos': condsandterms
          }
        }
      )
    )
  }
  const handleReintentar = (step) => {
    if (step === 1) {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/cuota-participaicon/modificacion-cuota/paso4',
        'eve-cat' : 'Error',
        'eve-lab' : 'Reintentar - Clave incorrecta'

      });
      handleSetErrors(
        cuotaParticipacionActions.fetchActualizaStatusErrorCuotaParticipacion({ error: false })
      )
    } else {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/cuota-participaicon/modificacion-cuota/paso4',
        'eve-cat' : 'Error',
        'eve-lab' : 'Cambiar clave - Clave bloqueada'

      });
      localStorage.removeItem('token');
      localStorage.removeItem('user-rut');
      localStorage.removeItem('login-timedate');
      history.push('/recuperar-clave');
    }
  }
  return (
    <>
      {errorActualizacion &&
        <>
          <PageTitleWrapper>
            {' '}
            <PageTitle
              id="header-cuota-participacion"
              title={<span style={{fontSize: 18, fontWeight: 700}}>Cuota de Participación</span>}
              icon={<LoadIcon src={`${iconPath}pie_chart.svg`} color="#333" />}
            />
          </PageTitleWrapper>
          <TabsWrapper className="col-sm-11 margin-container-after-title">
            <DashboardCard className='widthSection'>
              <DashboardCard.Head
                title={<span className='txtTitle-CP'>Autorización</span>}
                borderBottom="complete"
                classes="btext-xs-left bmt-xs-3 bmt-sm-4"
              />
              <DashboardCard.Body classes="text-left-destinatarios black">
                {
                  errorCuotaActualizacion?.code === "AUTH-01" &&
                  <ErrorsCuotas
                    title="¡Lo sentimos!"
                    message="La contraseña es incorrecta. Por favor vuelva a intentar"
                    buttonText="Reintentar"
                    onClick={() => handleReintentar(1)}
                  />
                }
                {
                  errorCuotaActualizacion?.code === "AUTH-02" &&
                  <ErrorsCuotas
                    title="Datos incorrectos"
                    message="La contraseña ingresada es incorrecta. Por favor vuelva a intentar. Recuerda que al tercer intento erróneo, tu clave será bloqueada"
                    buttonText="Reintentar"
                    onClick={() => handleReintentar(1)}
                  />
                }
                {
                  errorCuotaActualizacion?.code === "AUTH-03" &&
                  <ErrorsCuotas
                    title="Clave bloqueada"
                    message="Por tu seguridad, hemos bloqueado tu clave. Si lo deseas puedes volver a cambiar tu clave"
                    buttonText="Cambiar clave"
                    onClick={() => handleReintentar(2)}
                  />
                }
                {
                  errorCuotaActualizacion?.statusCode === 500 &&
                  <ErrorsCuotas
                    title="Servicio no disponible"
                    message="Estamos presentando problemas técnicos. Por favor, intenta más tarde"
                    buttonText="Volver"
                    onClick={() => history.push('/ecd/inicio')}
                  />
                }
              </DashboardCard.Body>
            </DashboardCard>
          </TabsWrapper>
        </>}
      {actualizacionCuotas?.success &&
        <Comprobante
          productoGA="Otro aporte"
          previousCuotasPactadas={cuotas?.data?.pactadas?.cuotasPactadas}
          cuotasPactadas={cuotasPactadasPorMes}
          montoPagado={calculateCuota.toLocaleString('es', { minimumFractionDigits: 0 })}
          fechaActualizacion={fechaFormateada}
          persona={persona}
        />}
      {!errorActualizacion && !actualizacionCuotas?.success &&
        <>
          <PageTitleWrapper>
            {' '}
            <PageTitle
              id="header-cuota-participacion"
              title={<span style={{fontSize: 18, fontWeight: 700}}>Cuota de Participación</span>}
              icon={<LoadIcon src={`${iconPath}pie_chart.svg`} color="#333" />}
            />
          </PageTitleWrapper>
          <TabsWrapper className="col-sm-11 margin-container-after-title">
            <DashboardCard className='widthSection'>
              <DashboardCard.Head
                title={<span className='txtTitle-CP'>Modifica tus cuotas de participación</span>}
                borderBottom="complete"
                classes="btext-xs-left bmt-xs-3 bmt-sm-4"
              />
              <DashboardCard.Body classes="text-left-destinatarios black">
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-12 col-xs-12 bmt-3">
                    <div className="w-100 h-100 bpr-md-1">
                      <div className="row">
                        <div className="col-md-4 col-xs-12" style={{ textAlign: 'left' }}>
                          <NumericInput
                            assistText={assistText}
                            error={error}
                            onlyNumber
                            value={cuotasPactadasPorMes}
                            label="Tus cuotas pactadas por mes"
                            onChange={(text) => handleInputChange(text)}
                          />
                        </div>
                        <div className="col-md-4 col-xs-12" style={{ textAlign: 'left' }}>
                          <div className="cdpMonthlyBanner" style={{ fontSize: '14px', paddingTop: '10px', paddingBottom: '10px', borderRadius: '5px' }}>
                            <h2>Tu aporte mensual será:</h2>
                            <p style={{ fontSize: '22px', fontWeight: 600, paddingTop: '5px', paddingBottom: '5px' }}>
                              $
                              {calculateCuota.toLocaleString('es', { minimumFractionDigits: 0 })}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 30, alignItems: 'center' }}>
                        <img src={Information} style={{ marginRight: 5, height: 20 }} alt='CuotaParticipacionCoopeuch' />
                        <p style={{ textAlign: 'left' }}>
                          1 Cuota de Participación es
                          {' '}
                          <strong>
                          <span style={{ fontWeight: 700 }}>igual a ${cuotas?.data?.valorCuota}</span>
                            {' '}
                            según el valor del año en curso
                          </strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </DashboardCard.Body>
            </DashboardCard>
            <SolicitudModificacion
              cuotasPactadasPorMes={cuotasPactadasPorMes}
              secondStep={secondStep}
              setThirdStep={setThirdStep}
              thirdStep={thirdStep}
              downloadHistorialGA={downloadHistorialGA}
              acceptTermsGA={acceptTermsGA}
            />
            <Autorizacion
              thirdStep={thirdStep}
              setValidateAuth={setValidateAuth}
              clave={clave}
              setClave={setClave}
            />

            <div>
              <div className="row flex-column-reverse flex-md-row justify-content-md-between align-items-center">
                <div className="col-12 col-md-4 col-lg-2 mb-2 order-md-1">
                  <Link
                    to="/ecd/productos/cuota-de-participacion/"
                    className="w-100 mt-md-0 d-md-block d-flex align-items-center justify-content-center"
                    onClick={() => {
            if (thirdStep) {
              returnGAthirdStep();
            } else if (cuotasPactadasPorMes && !thirdStep) {
              returnGAsecondStep();
            }
          }}
                  >
                    <div className="d-flex align-items-center">
                      <LoadIcon src={`${iconPath}arrow_left.svg`} size={24} color="E81E2B" />
                      {' '}
                      Volver
                    </div>
                  </Link>
                </div>
                <div className="col-12 col-md-8 col-lg-3 mb-1 order-md-2">
                  <AuthButton
                      bgColor="#E81E2B"
                      color="white"
                      fontSize={14}
                      disabled={validateAuth}
                      className="d-block w-100 mt-3"
                      onClick={() => {
                        authorizeGA();
                        handleClickAuth(cuotasPactadasPorMes, thirdStep, clave);
                        setValidateAuth(true)
                      }}
                    >
                      Autorizar
                    </AuthButton>
                </div>
              </div>
            </div>

          </TabsWrapper>
        </>}
    </>
  );
};

Index.propTypes = {
  fetchCuotasParticipacion: PropTypes.func.isRequired,
  fetchSugerenciaCuotasParticipacion: PropTypes.func.isRequired,
  handleUpdateCuotas: PropTypes.func.isRequired,
  handleSetErrors: PropTypes.func.isRequired,
  errorCuotaActualizacion: PropTypes.shape({
    error: PropTypes.bool,
    statusCode: PropTypes.number,
    message: PropTypes.string,
    code: PropTypes.string,
  }).isRequired,
  actualizacionCuotas: PropTypes.shape({
    success: PropTypes.bool,
  }).isRequired,
  modify_cuotas: PropTypes.shape({
    success: PropTypes.bool,
  }).isRequired,
  persona: PropTypes.shape({
    rut: PropTypes.string,
  }).isRequired,
  cuotas: PropTypes.shape({
    data: PropTypes.shape({
      pactadas: PropTypes.shape({
        cuotasPactadas: PropTypes.number,
      }),
      valorCuota: PropTypes.number,
    }),
  }).isRequired,
};

const mapStateToProps = (store) => ({
  persona: store.entities.persona,
  cuotas: store.entities.cuotaParticipacion.cuotas,
  sugerenciaCuotas: store.entities.cuotaParticipacion.sugerenciaCuotas,
  modify_cuotas: store.entities.cuotaParticipacion.modify_cuotas,
  errorCuotaActualizacion: store.entities.cuotaParticipacion.errorCuotaActualizacion,
  actualizacionCuotas: store.entities.cuotaParticipacion.actualizacionCuotas,
  isLoadingSugerenciaCuotaParticipacion: store.ui.loaders.isLoadingSugerenciaCuotaParticipacion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchSugerenciaCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchSugerenciaCuotasParticipacion());
  },
  handleUpdateCuotas: (params) => {
    dispatch(cuotaParticipacionActions.fetchActualizacionCuotasParticipacion(params));
  },
  handleSetErrors: (params) => {
    dispatch(cuotaParticipacionActions.fetchActualizaStatusErrorCuotaParticipacion(params));
  }
});
Index.displayName = 'Container_CDP_SUG';
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
