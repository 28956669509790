import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@design-system-coopeuch/web';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const FooterBackAndContinue = ({
  btnContinueDisabled,
  handleClickContinue,
  txtBack,
  txtBtn,
  isLoading,
  handleClickBack,
}) => {
  return (
    <Wrapper>
      {txtBack && (
        <Link className="linkBack" onClick={handleClickBack && handleClickBack}>
          <LoadIcon src={`${iconPath}arrow_left.svg`} size={24} />
          <p>{txtBack}</p>
        </Link>
      )}
      {txtBtn && (
        <Button
          color="primary"
          variant="link"
          disabled={btnContinueDisabled}
          onClick={handleClickContinue}
          loading={isLoading && isLoading}
        >
          {txtBtn}
        </Button>
      )}
    </Wrapper>
  );
};

export default FooterBackAndContinue;
