import React from 'react';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;


const NotPassCoopeuch = ({handleClickDownloadApp}) => {
  return (
    <Wrapper>
      <div className="containerPass">
        <img
          src={`${staticPath}celular_desk.png`}
          alt="imagen passcoopeuch"
          className="containerPass__image"
        />
        <div className="containerPass__info">
          <p className="containerPass__info-title">¡Usa tu PassCoopeuch!</p>
          <p className="containerPass__info-description">
            Te invitamos a descargar PassCoopeuch, la App que te permite autorizar transacciones de
            forma fácil, rápida y segura. Encuéntrala en App Store y Google Play.
          </p>
        </div>
        <div className="containerPass__apps">
        <img
            src={`${staticPath}activacionTdPassCoopeuch/QR AppStore.svg`}
            className="containerPass__apps-image visible-desktop"
            alt="imagen app store"
            onClick={() => handleClickDownloadApp('AppStore')}
          />
          <img
            src={`${iconPath}appstore.svg`}
            className="containerPass__apps-image visible-mobile"
            alt="imagen app store"
            onClick={() => handleClickDownloadApp('AppStore')}
          />
          <img
            src={`${staticPath}activacionTdPassCoopeuch/android_QR.svg`}
            className="containerPass__apps-image visible-desktop"
            alt="imagen play store"
            onClick={() => handleClickDownloadApp('GooglePlay')}
          />
          <img
            src={`${iconPath}google_play.svg`}
            className="containerPass__appsAD-image visible-mobile"
            alt="imagen play store"
            onClick={() => handleClickDownloadApp('GooglePlay')}
          />
          </div>
      </div>
    </Wrapper>
  );
};

export default NotPassCoopeuch;
