import React, { Fragment, useMemo } from 'react';

import PageTitle from '../../../atoms/PageTitle';
import Stepper from '../../../../components/organism/Stepper';
import DashboardCard from '../../../organism/DashboardCard';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Input from '@coopeuch-components/web/atoms/Input';
import Typography from '@coopeuch-components/web/atoms/Typography';
import ServiceError from '../../../organism/DepositoAPlazo/ServiceError';
import StepsActions from '../../../organism/Stepper/StepsActions';

import FullBannerGoToSimulacion from '../../../organism/DepositoAPlazo/FullBannerGoToSimulacion';
import DepositoContratado from '../../../organism/DepositoAPlazo/Home/DepositoContratado';

import Wrapper, { TitleWrapper } from './styles';
import SkeletonSimulacion from './Skeleton';
import { capitalize, formatCurrency } from '../../../../utils/functions';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const errListaNegra = {
  message: 'No fue posible acceder al Simulador, si necesitas ayuda contáctenos al 600 200 1200',
  noButton: true,
};

const errCierreContable = {
  message:
    'Esta función se encuentra deshabilitada en este momento. Vuelve a intentarlo más tarde.',
  noButton: true,
};

function SimulacionTemplate(props) {
  const {
    error,
    loading,
    isListaNegra,
    tipo,
    monto,
    plazo,
    plazoErr,
    montoErr,
    findRule,
    makeRule,
    handleInput,
    handleTab,
    handleSubmit,
    handleGoBack,
    loadingRedirect,
  } = props;

  const thereAreInputsEmpty = useMemo(
    () => !plazo || plazo?.length === 0 || !monto || monto?.length === 0,
    [plazo, monto]
  );

  const montoMinimo = useMemo(
    () => findRule(tipo === 'renovable' ? 'REN_MONMIN' : 'FIJ_MONMIN'),
    [findRule, tipo]
  );

  const buildPlazoMsg = useMemo(() => {
    let min = findRule(makeRule(tipo, 'PLAMIN'));
    let max = findRule(makeRule(tipo, 'PLAMAX'));

    return `Desde ${min} días hasta ${max} días`;
  }, [tipo, findRule]);

  const buildMontoMsg = useMemo(() => {
    let max = findRule(makeRule(tipo, 'MONMAX'));

    if (montoErr === 'min') {
      return ` Desde ${formatCurrency(montoMinimo)} para ${capitalize(tipo)}`;
    }

    return `El monto máximo debe ser ${formatCurrency(max)}`;
  }, [tipo, findRule, montoMinimo, montoErr]);

  const steps = useMemo(() => {
    return ['Simulación', 'Resultado', 'Detalle simulación', 'Confirmación', 'Comprobante'];
  }, []);

  return (
    <Fragment>
      <TitleWrapper>
        <PageTitle
          id="dap-title-page"
          title="Simulador Depósito a Plazo"
          icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" className="simulador-icon" />}
        />
      </TitleWrapper>
      <Wrapper className="margin-after-title">
        {error || isListaNegra ? (
          <div className="mt-24">
            <ServiceError
              withCard
              noButton
              isWarning={isListaNegra || error === 'GEN-02'}
              {...(isListaNegra ? errListaNegra : {})}
              {...(error === 'GEN-02' ? errCierreContable : {})}
            />
          </div>
        ) : (
          <Fragment>
            {loading ? (
              <>
                {loadingRedirect ? (
                  <div className="row start-xs simulacion-loading-daps">
                    <div className="col-xs-12">
                      <FullBannerGoToSimulacion.Skeleton />
                    </div>

                    <div className="col-xs-12">
                      <DepositoContratado.Skeleton />
                      <DepositoContratado.Skeleton />
                      <DepositoContratado.Skeleton />
                    </div>
                  </div>
                ) : (
                  <>
                    <Stepper className="mb-24 start-xs" position={1} steps={steps} />
                    <SkeletonSimulacion />
                  </>
                )}
              </>
            ) : (
              <>
                <Stepper className="mb-24 start-xs" position={1} steps={steps} />
                <div className="row">
                  <div className="col-xs-12">
                    <DashboardCard>
                      <Tabs
                        bgColor="#fff"
                        fontSize={15}
                        fontFamily="Ubuntu-Medium"
                        tabSize={20}
                        activeColor="#e81e2b"
                        className="pl-34 tabs"
                        defaultTab={tipo === 'renovable' ? 0 : 1}
                      >
                        <Tabs.Header>
                          <Tab className="tab-style black" callback={handleTab}>
                            Renovable
                          </Tab>
                          <Tab className="tab-style w-150p black" callback={handleTab}>
                            Fijo
                          </Tab>
                        </Tabs.Header>
                        <div className="w-100 text-left simulacion--container-content">
                          <div className="row">
                            <div className="col-xs-12">
                              {tipo === 'renovable' ? (
                                <Typography tag="p">
                                  <span className="pb-12 black">
                                    Cuando se cumpla el plazo que definas, tendrás{' '}
                                  </span>
                                  <span className="pb-12 black text-bold">3 días hábiles </span>
                                  <span className="pb-12 black">
                                    para retirar tu depósito. De no ser así Coopeuch lo renovará de
                                    forma automática.
                                  </span>
                                </Typography>
                              ) : (
                                <Typography tag="p" className="pb-12 black">
                                  El Depósito a plazo fijo solo genera intereses en pesos hasta el
                                  momento de su vencimiento.
                                </Typography>
                              )}
                            </div>

                            <div className="col-xs-12 col-md-6 col-sm-12 p-0">
                              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                <div className="mt-16">
                                  <label className="text-bold black">Monto a invertir</label>
                                  <div className="mt-14 wrapper-input">
                                    <Input
                                      dataSet="monto"
                                      onChange={handleInput}
                                      value={formatCurrency(monto)}
                                      borderColor={montoErr ? '#e81d2b' : '#646569'}
                                      id="dap-simulacion-monto"
                                      size={50}
                                      className="mr-4"
                                    />
                                    <div className="mt-8 error--input--message">
                                      <div className="row">
                                        {montoErr && (
                                          <LoadIcon
                                            src={`${iconPath}circle_close.svg`}
                                            size={14}
                                            color="#CC284E"
                                            className="mr-8 red"
                                            staticIcon
                                          />
                                        )}
                                        <p className={`${montoErr ? 'red' : 'black'} text-size-s`}>
                                          {montoErr
                                            ? buildMontoMsg
                                            : `Desde ${formatCurrency(
                                                montoMinimo
                                              )} para ${capitalize(tipo)}`}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-xs-12 col-md-6 col-sm-12 p-0">
                              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                                <div className="mt-16">
                                  <label className="text-bold black">Plazo (Días)</label>
                                  <div className="mt-14">
                                    <Input
                                      dataSet="plazo"
                                      onChange={handleInput}
                                      value={plazo}
                                      borderColor={plazoErr ? '#e81d2b' : '#646569'}
                                      placeholder="7"
                                      id="dap-simulacion-plazo"
                                      size={50}
                                    />
                                    <div className="mt-8 error--input--message">
                                      <div className="row">
                                        {plazoErr && (
                                          <LoadIcon
                                            src={`${iconPath}circle_close.svg`}
                                            size={14}
                                            color="#CC284E"
                                            className="mr-8 red"
                                            staticIcon
                                          />
                                        )}
                                        <p className={`${plazoErr ? 'red' : 'black'} text-size-s`}>
                                          {buildPlazoMsg}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tabs>
                    </DashboardCard>
                    <StepsActions
                      id="simulacion"
                      className="bmt-3 middle-xs mt-24"
                      backText="Volver"
                      nextText="Simular"
                      backAction={handleGoBack}
                      nextAction={handleSubmit}
                      disableNextAction={montoErr || plazoErr || thereAreInputsEmpty}
                    />
                  </div>
                </div>
              </>
            )}
          </Fragment>
        )}
      </Wrapper>
    </Fragment>
  );
}

SimulacionTemplate.propTypes = {};
SimulacionTemplate.defaultProps = {};

export default SimulacionTemplate;
