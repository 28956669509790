import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Templates
import AccesosRapidosTemplate from '../../../components/templates/Dashboard/AccesosRapidos';
import { registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';
import { accesosRapidosTexts } from '../texts';

const AccesosRapidos = ({ pasoactual }) => {
  const [isContractableCV, setIsContractableCV] = useState(null);
  const [isAvailableBannerCV, setIsAvailableBannerCV] = useState(false);

  const history = useHistory();

  const loadingContratacionCV = useSelector(
    (store) => store.ui.loaders.isLoadingBannerContratacionCV,
    shallowEqual
  );

  const strContratacionCV = useSelector(
    (store) => store.entities.solicitudCuentaVista.bannerContratacion,
    shallowEqual
  );

  const kitDataBanner = useSelector((store) => store.entities.activacionTarjetas.kitDataBanner);

  const handleClickEncuesta = () => {
    history.push('/ecd/solicita/simulador-credito-de-consumo');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': accesosRapidosTexts.ENCUESTA_TITLE,
    });
  };

  const handleClickTransferencia = () => {
    history.push('/ecd/transferir/fondos');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': accesosRapidosTexts.TRANSFERIR_BUTTON,
    });
  };

  const handleClickDAP = () => {
    loguearRutUsuario( 'HOME_PRODUCTOS_VER_DAP');
    history.push('/ecd/productos/deposito-a-plazo');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': accesosRapidosTexts.DAP_BUTTON,
    });
  };

  const handleClickBloqueo = () => {
    history.push('/ecd/seguridad/bloqueo-de-tarjetas');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': accesosRapidosTexts.BLOQUEAR_BUTTON,
    });
  };

  const handleLinkSolicitaCV = () => {
    history.push('/ecd/solicita/cuenta-coopeuch');
  };

  const handleClickBannerKitCV = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Comenzar - Kit Cuenta Vista Coopeuch',
    });
  };

  useEffect(() => {
    if (kitDataBanner.data) {
      setIsAvailableBannerCV(kitDataBanner.data);
    }
  }, [kitDataBanner]);

  /**
   * Observador Banner ContratacionCV
   */
  useEffect(() => {
    if (strContratacionCV.success && !loadingContratacionCV) {
      if (Object.keys(strContratacionCV.data).length) {
        setIsContractableCV(strContratacionCV.data.puedeVerBanner);
      }
    }
    if (strContratacionCV.error) {
      setIsContractableCV(null);
    }
  }, [strContratacionCV, loadingContratacionCV]);

  return (
    <AccesosRapidosTemplate
      handleClickEncuesta={handleClickEncuesta}
      handleClickTransferencia={handleClickTransferencia}
      handleClickDAP={handleClickDAP}
      handleClickBloqueo={handleClickBloqueo}
      handleLinkSolicitaCV={handleLinkSolicitaCV}
      handleClickBannerKitCV={handleClickBannerKitCV}
      loadingContratacionCV={loadingContratacionCV}
      isContractableCV={isContractableCV}
      pasoactual={pasoactual}
      isAvailableBannerCV={isAvailableBannerCV}
    />
  );
};

export default AccesosRapidos;
