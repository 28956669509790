import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';

import ErrorCard from '../../../organism/ErrorCard';
import HomeSkeleton from './HomeSkeletonTemplate';
import CardHome from './CardHome';

import Wrapper from './styles';

const HomeTemplate = ({ isLoading, isError, mostrarIframeCertificado }) => {
  const [cuentavista, setCuentaVista] = useState({});
  const [creditohipotecario, setCreditoHipotecario] = useState({});
  const [creditoconsumo, setCreditoConsumo] = useState({});
  const [tarjetacredito, setTarjetaCredito] = useState({});

  const product = useSelector((state) => state.entities.products.dashboardProductos);

  useEffect(() => {
    setCuentaVista(product[2]);
    setCreditoHipotecario(product[5]);
    setCreditoConsumo(product[0]);
    setTarjetaCredito(product[6]);
  }, []);

  if (isLoading) return <HomeSkeleton />;

  if (isError)
    return (
      <div className="mt-24">
        <ErrorCard
          titleText="¡Lo sentimos!"
          descText="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
        />
      </div>
    );

  const habilitarSolicitar =
    cuentavista?.mostrar ||
    creditohipotecario?.mostrar ||
    creditoconsumo?.mostrar ||
    tarjetacredito?.mostrar;

  return (
    <Wrapper className="bpx-3 bpx-md-5">
      <div className="row bmt-md-4 bmt-3 bmb-md-4 bmb-3">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>Liquidación</CardBox.Header>
            <CardBox.Body>
              <div className="mvts--header-rows">
                <p className="mvts--wrapper-right parrafo-base">
                  El certificado de liquidación{' '}
                  <strong>
                    es el documento gratuito por el cual se certifica el estado y antecedentes de
                    uno o más productos o servicios financieros contratados en Coopeuch,
                  </strong>{' '}
                  con la finalidad de que usted pueda dar término anticipado, sea parcial o total,
                  renegociar, portar uno o más productos o servicios contratados o conocer el estado
                  y antecedentes de los mismos.
                </p>
                <br />
                <p className="mvts--wrapper-right parrafo-base">
                  El certificado de liquidación para término anticipado se entregará dentro de 3 días hábiles desde la respectiva solicitud.
                </p>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bmb-md-4 bmb-3">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>Tres razones para quedarte con nosotros</CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <CardHome
                  icon={
                    <LoadIcon
                      src={`${process.env.REACT_APP_CLOUDFRONT_ICONS}paid-60.svg`}
                      size={54}
                    />
                  }
                  title={'Hoy tienes un beneficio único'}
                  description={
                    'Al ser socio y preferir los productos Coopeuch, aumenta tu remanente.'
                  }
                  size="54"
                />

                <CardHome
                  conPading
                  icon={
                    <LoadIcon
                      src={`${process.env.REACT_APP_CLOUDFRONT_ICONS}heart-50.svg`}
                      size={54}
                    />
                  }
                  title={'Eres parte del modelo cooperativo'}
                  description={'Somos una institución financiera cooperativa que genera inclusión.'}
                  size="54"
                />
                <CardHome
                  icon={
                    <LoadIcon
                      src={`${process.env.REACT_APP_CLOUDFRONT_ICONS}headphones.svg`}
                      size={54}
                    />
                  }
                  title={'Evaluemos una mejora en condiciones'}
                  description={'Danos la oportunidad de buscar otras opciones.'}
                  size="54"
                />
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      {habilitarSolicitar ? (
        <div className="row bmb-md-4 bmb-3">
          <div className="col-lg-offset-10 col-lg-2 col-xs-12 col-sm-12">
            <div>
              <Button
                fullWidth
                bgColor="#e81e2b"
                color="white"
                id="certificado-liquidacion-button"
                onClick={mostrarIframeCertificado}
              >
                Solicitar
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Alert bgType="info" iconType="info" show className="alert-content bmb-md-4 bmb-3">
          En estos momentos no posees productos para portar.
        </Alert>
      )}
    </Wrapper>
  );
};

export default HomeTemplate;
