import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Table from '@coopeuch-components/web/molecules/Table';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import { Paragraph, Wrapper, ActionButtons, ActionsWrapper } from './styles';
import { agregarformatoPesos } from '../../../../../utils/formatters';
import { colors } from '../../../../../assets/styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  type = 'CV',
  data = {},
  backAction,
  nextAction,
  openSendEmailModal,
  downloadComprobante,
}) => {
  const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
  const comprobante = `${staticPath}sello-comprobante.png`;
  const payData = [
    {
      id: 0,
      name: 'Remanente Pagado',
      value: agregarformatoPesos(data.monto),
    },
    {
      id: 1,
      name: 'Cuenta de Destino',
      value: type === 'MONEDERO' ? 'Monedero Digital' : 'Cuenta Vista Coopeuch',
    },
    {
      id: 2,
      name: 'Número de Cuenta',
      value: data.numeroCuenta,
    },
    {
      id: 3,
      name: 'Fecha de Pago',
      value: data.fecha,
    },
  ];
  const alertText = `¡Felicitaciones! Tu
  Remanente fue pagado en tu ${
    type === 'MONEDERO' ? 'Monedero Digital.' : 'Cuenta Vista Coopeuch.'
  }`;
  return (
    <Wrapper>
      <Alert
        className="alert-comprobante-contratacion-monedero bp-3 bmb-3 bmb-md-4"
        bgType="success"
        id="alert-felicitaciones-contratacion-monedero"
        show
      >
        <React.Fragment>{alertText}</React.Fragment>
      </Alert>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>
          <div className="row">
            <div className="col-xs-6">Comprobante</div>
            <div className="col-xs-6 text-right">
              <ActionButtons>
                <div className="comprobante-action bmr-3 red" onClick={downloadComprobante}>
                  <LoadIcon
                    className="bmr-2"
                    src={`${iconPath}download_arrow.svg`}
                    color={colors.red}
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text bmr-4">Descargar</div>
                </div>
                <div className="comprobante-action red" onClick={openSendEmailModal}>
                  <LoadIcon
                    className="bmr-1"
                    src={`${iconPath}email.svg`}
                    color={colors.red}
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text ml-2 bmr-2">Enviar</div>
                </div>
              </ActionButtons>
            </div>
          </div>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12 col-md-8  text-card-column">
              <div className="">
                <Paragraph className="mb-2" size={14}>
                  <b>{`Remanente pagado`}</b>
                </Paragraph>
                <Paragraph className="mb-2" size={24}>
                  <b>{agregarformatoPesos(data.monto)}</b>
                </Paragraph>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className=" bm-auto bmr-md-0" src={comprobante} alt="comprobante" />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Detalle del Pago de Remanente</CardBox.Header>
        <CardBox.Body>
          <Table
            borderColor="trasparent"
            fullWidth
            alignContent="left"
            stripeColor="#F6F3F5"
            stripeReverse
          >
            <Table.Body
              data={payData}
              renderItems={(item, TR, TD) => {
                return (
                  <TR key={`detalle-contrato-monedero-deposito-${item.id}`}>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.name}
                    </TD>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16pl-8 pr-8">
                      {item.value}
                    </TD>
                  </TR>
                );
              }}
            ></Table.Body>
          </Table>
        </CardBox.Body>
      </CardBox>
      <ActionsWrapper>
        <StepsActions
          className="bmb-3 bmb-md-4"
          backAction={backAction}
          backText={'Ir a home'}
          nextText={'Ir a Remanente'}
          nextAction={nextAction}
          id="contratacion-monedero-comprobante"
        />
      </ActionsWrapper>
    </Wrapper>
  );
};

export default Index;
