import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import produce from 'immer';

// UI Components
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';

// Actions
import { remanenteActions } from '../../../application/actions/remanente';
import { capitalizacionActions } from '../../../application/actions/capitalizacion';

// Components
import Error from '../../components/atoms/Error';
import Maintenance from '../../components/atoms/Maintenance';
import { colors } from '../../assets/styles';

import MiRemanente from './MiRemanente';
import ContratoMonedero from './ContratoMonedero';
import CobroRemanente from './CobroRemanente';
import CapitalizarConContrato from './Capitalizar/ContratarMonedero';
import Capitalizar from './Capitalizar/CapitalizarRemanente';
import { registerDY, registerGA } from '../../utils/metrics';
import logger from '../../utils/logger';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

class Remanente extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedFlow: 'MI_REMANENTE',
      errorService: false,
    };

    const { setRemanenteMainError, setRemanenteContenidoError } = this.props;

    setRemanenteMainError('');
    setRemanenteContenidoError(false);
  }

  componentDidMount() {
    const { fetchRemanente, refreshHabilitadoCobrar, refreshHabilitadoCapitalizar } = this.props;
    refreshHabilitadoCobrar();
    refreshHabilitadoCapitalizar();
    fetchRemanente();
    registerDY({ type: 'PRODUCT', data: ['remanente'] });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { selectedFlow } = this.state;
    const {
      remanente: { saldoIndividual, error },
      refreshHabilitadoCobrar,
      refreshHabilitadoCapitalizar,
      fetchCobroRemanenteWeb,
      fetchHabilitadoCapitalizar,
      fetchRemanente,
    } = this.props;

    if (prevProps.remanente.error !== error) {
      if (error) {
        this.setState({ errorService: true });
        // console.log('error');
      }
    }

    if (prevState.selectedFlow !== selectedFlow && selectedFlow === 'MI_REMANENTE') {
      refreshHabilitadoCobrar();
      refreshHabilitadoCapitalizar();
      fetchRemanente();
    }
    if (
      prevProps.remanente.saldoIndividual !== saldoIndividual &&
      !saldoIndividual.sinRemanente &&
      !!saldoIndividual.pago
    ) {
      if (saldoIndividual.pago.estado.codigo === 'N' && saldoIndividual.grupo.codigo === 32) {
        fetchCobroRemanenteWeb();
      } else if (saldoIndividual.pago.estado.codigo === 'P') {
        fetchHabilitadoCapitalizar();
      }
    }

    const {
      isLoadingRemanente,
      isLoadingContenidoRemanente,
      isLoadingComposicionRemanente,
      persona,
      errorLog,
    } = this.props;

    if (
      prevProps.isLoadingRemanente !== isLoadingRemanente ||
      prevProps.isLoadingContenidoRemanente !== isLoadingContenidoRemanente ||
      prevProps.isLoadingComposicionRemanente !== isLoadingComposicionRemanente
    ) {
      if (!isLoadingRemanente && !isLoadingContenidoRemanente && !isLoadingComposicionRemanente) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorLog, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'REMANENTE INFORMACIÓN',
          informacion_adicional: {},
        });
      }
    }
  };

  onClickCobrarButtonHandle = () => {
    const { cobreRemanenteWeb } = this.props;
    if (cobreRemanenteWeb.monedero) {
      let selectedFlow = '';
      const mantencion = this.checkMantencion();
      if (mantencion) {
        selectedFlow = 'MANTENCION';
      } else {
        if (cobreRemanenteWeb.monedero.contratar === true) {
          selectedFlow = 'CONTRATACION_MONEDERO';
        } else {
          selectedFlow = 'COBRO_REMANENTE';
        }
      }
      this.setState(
        produce((draft) => {
          draft.selectedFlow = selectedFlow;
        })
      );
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/remanente/miremanente',
        'eve-cat': 'Click',
        'eve-lab': 'Cobrar mi Remanente',
      });
    }
  };

  onClickCapitalizarButton = () => {
    const { habilitadoCapitalizar } = this.props;
    if (habilitadoCapitalizar.habilitado) {
      let selectedFlow = '';
      const mantencion = this.checkMantencion();
      if (mantencion) {
        selectedFlow = 'MANTENCION';
      } else {
        if (habilitadoCapitalizar.monedero.contratar) {
          selectedFlow = 'CAPITALIZAR_CON_CONTRATO';
        } else {
          selectedFlow = 'CAPITALIZAR';
        }
      }
      this.setState(
        produce((draft) => {
          draft.selectedFlow = selectedFlow;
        })
      );
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/remanente/miremanente',
        'eve-cat': 'Click',
        'eve-lab': 'Capitalizar mi remanente',
      });
    }
  };

  onGoBackToRemanente = () => {
    this.setState(
      produce((draft) => {
        draft.selectedFlow = 'MI_REMANENTE';
      })
    );
  };

  checkMantencion = () => {
    const { mantencion } = this.props;
    const mantencionRemanente = mantencion.data.find(
      (elem) => elem.producto.codigoProducto === 'RE'
    );
    return mantencionRemanente ? mantencionRemanente.mantencion.estado : false;
  };

  FlowRender = () => {
    const { selectedFlow } = this.state;
    switch (selectedFlow) {
      case 'MI_REMANENTE':
        return (
          <MiRemanente
            onClickCobrarButton={this.onClickCobrarButtonHandle}
            onClickCapitalizarButton={this.onClickCapitalizarButton}
          />
        );
      case 'CONTRATACION_MONEDERO':
        return <ContratoMonedero onGoBackToRemanente={this.onGoBackToRemanente} />;
      case 'COBRO_REMANENTE':
        return <CobroRemanente onGoBackToRemanente={this.onGoBackToRemanente} />;
      case 'CAPITALIZAR_CON_CONTRATO':
        return <CapitalizarConContrato onGoBackToRemanente={this.onGoBackToRemanente} />;
      case 'CAPITALIZAR':
        return <Capitalizar onGoBackToRemanente={this.onGoBackToRemanente} />;
      case 'MANTENCION':
        return <Maintenance className="bmt-3 bmt-md-4" />;
      default:
        return (
          <MiRemanente
            onClickCobrarButton={this.onClickCobrarButtonHandle}
            onClickCapitalizarButton={this.onClickCapitalizarButton}
          />
        );
    }
  };

  render() {
    const { error } = this.props.remanente;
    const { errorService } = this.state;
    return (
      <>
        <PageTitle
          title="Remanente"
          icon={<LoadIcon src={`${iconPath}dollar_coin.svg`} color={colors.black} />}
        />
        <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
          {errorService ? (
            <div className="row center-xs">
              <Error className="mt-24 col-xs-10 col-xs-offset-1" />
            </div>
          ) : (
            <>
              <this.FlowRender />
            </>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  remanente: store.entities.remanente,
  cobreRemanenteWeb: store.entities.remanente.cobreRemanenteWeb,
  habilitadoCapitalizar: store.entities.capitalizacion.habilitado,
  mantencion: store.entities.configuracion.mantencion,
  isLoadingRemanente: store.ui.loaders.isLoadingRemanente,
  isLoadingContenidoRemanente: store.ui.loaders.isLoadingContenidoRemanente,
  isLoadingComposicionRemanente: store.ui.loaders.isLoadingComposicionRemanente,
  errorLog: store.entities.remanente.errorLog,
  persona: store.entities.persona,
});

const mapDispatchToProps = (dispatch) => ({
  setRemanenteMainError(error) {
    dispatch(remanenteActions.setRemanenteMainError(error));
  },
  setRemanenteContenidoError(error) {
    dispatch(remanenteActions.setRemanenteContenidoError(error));
  },
  fetchRemanente(props) {
    dispatch(remanenteActions.fetchRemanente(props));
  },
  fetchCobroRemanenteWeb() {
    dispatch(remanenteActions.fetchCobroRemanenteWeb());
  },
  fetchHabilitadoCapitalizar() {
    dispatch(capitalizacionActions.fetchHabilitadoCapitalizar());
  },
  refreshHabilitadoCobrar() {
    dispatch(remanenteActions.refreshHabilitadoCobrar());
  },
  refreshHabilitadoCapitalizar() {
    dispatch(capitalizacionActions.refreshHabilitadoCapitalizar());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Remanente);
