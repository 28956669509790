import React, { Component, Suspense, lazy } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { produce } from 'immer';

import { productActions } from '../../../application/actions/products';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';

import { TabsWrapper, PageTitleWrapper } from './styles';

import { isSameWord } from '../../utils/validators';
import { registerDY, registerGA } from '../../utils/metrics';
import logger from '../../utils/logger';

const MiCuenta = lazy(() => import('./MiCuenta'));
const Movimientos = lazy(() => import('./Movimientos'));

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const tabs = {
  0: 'MiCuenta',
  1: 'Movimientos',
};

class CuentaVista extends Component {
  constructor(props) {
    super(props);
    const { match } = props;
    this.state = {
      accountNumber: '',
      accountType: '',
      movements: [],
      query: '',
      loadingData: true,
      selectedTab: match.params.tab ? 1 : 0,
      renderTabs: true,
      cuentaLog: false,
    };
  }

  async componentDidMount() {
    const { fetchCuentasVista, history } = this.props;
    fetchCuentasVista({
      codigoProducto: ['VI01', 'VI02', 'VI03', 'VI04', 'VI06'],
      estadoCuenta: ['A', 'T'],
      tipoCliente: ['R'],
    });
    registerDY({ type: 'PRODUCT', data: ['cta_vista'] });

    if (history?.location?.pathname === '/ecd/productos/cuenta-coopeuch/movimientos') {
      registerGA({ event: 'vpv-privado', page: '/tef/cuentaVista/movimientos' });
    } else {
      registerGA({ event: 'vpv-privado', page: '/tef/cuentaVista' });
    }
  }

  async componentDidUpdate(prevProps) {
    const {
      match,
      history,
      isLoadingCuentasVista,
      isLoadingTarjetas,
      isLoadingUltMovimientos,
      persona,
      errCuentasLog,
      errTarjetasLog,
      errUltMovimientosLog,
    } = this.props;
    const { accountNumber, accountType, cuentaLog } = this.state;

    const { cuentaVista } = this.props.products;
    const { cuentaVista: prevCuentaVista } = prevProps.products;

    const { movements } = cuentaVista;

    /**
     * Si no tiene cuentas, se envía a landing solicitud cv
     */
    if (cuentaVista !== prevCuentaVista && cuentaVista.sinProductos) {
      history.push('/ecd/solicita/cuenta-coopeuch');
    }
    /**
     * seleccionar primera cuenta del array para orquestar siguientes servicios
     */
    if (cuentaVista.balance !== prevCuentaVista.balance && !!cuentaVista.balance.length) {
      const { numeroCuenta } = history?.location?.state || {};
      if (numeroCuenta) {
        const cuenta = cuentaVista.balance.find((cuentaV) => cuentaV.numeroCuenta === numeroCuenta);
        this.handleSelectedAccount(cuenta);
      } else {
        this.handleSelectedAccount(cuentaVista.balance[0]);
      }
    }
    /**
     * cambiar tab segun la url
     */
    if (prevProps.match.params.tab !== match.params.tab) {
      // this.setState({ renderTabs: false }, () => {
      this.setState({ selectedTab: match.params.tab ? 1 : 0, renderTabs: true });
      // });
    }

    /**
     * Update de movimientos de una cuenta
     * (solicitados desde container de movimientos)
     */
    if (movements !== prevCuentaVista.movements) {
      this.onPushMovementsHandle(accountNumber, true);
    }

    /**
     * se desactiva el skeleton (loadingData = false) cuando:
     * la cuenta seleccionada es !== monedero y se recibió la data de tarjetas y últimos movimientos
     * o bien, la cuenta es monedero y se recibió la data de últimos movimientos
     */
    if (
      (accountType !== 'VI06' &&
        (cuentaVista.lastMovements !== prevCuentaVista.lastMovements ||
          cuentaVista.assoCards !== prevCuentaVista.assoCards) &&
        !!cuentaVista.lastMovements[accountNumber] &&
        !!cuentaVista.assoCards[accountNumber]) ||
      (accountType === 'VI06' &&
        cuentaVista.lastMovements !== prevCuentaVista.lastMovements &&
        !!cuentaVista.lastMovements[accountNumber])
    ) {
      this.setState({ loadingData: false });
    }

    if (!cuentaVista.sinProductos) {
      if (
        prevProps.isLoadingCuentasVista !== isLoadingCuentasVista ||
        prevProps.isLoadingTarjetas !== isLoadingTarjetas ||
        prevProps.isLoadingUltMovimientos !== isLoadingUltMovimientos
      ) {
        if (!isLoadingCuentasVista && !isLoadingTarjetas && !isLoadingUltMovimientos) {
          const errors = errCuentasLog || errTarjetasLog || errUltMovimientosLog;
          const baseRut = String((persona?.rut || '').replace('-', ''));

          const ifTarjetas =
            Object.keys(cuentaVista.assoCards)?.find(
              (number) => String(number) === String(accountNumber)
            ) || errTarjetasLog;

          if (errCuentasLog || errUltMovimientosLog || ifTarjetas) {
            if (!cuentaLog) {
              logger(errors, {
                rut: baseRut.slice(0, -1),
                digito_verificador: baseRut.slice(-1),
                accion: 'CV-INGRESO',
                informacion_adicional: {},
              });
            }

            this.setState(
              produce((draft) => {
                draft.cuentaLog = true;
              })
            );
          }
        }
      }
    }
  }

  /**
   * Si tiene 1 o mas cuentas, se deben orquestar los servicios de
   * tarjetas y movimientos asociadas a la primera cuenta.
   * Si esa cuenta es un monedero, no es necesario llamar a tarjetas
   */
  handleSelectedAccount = (account) => {
    this.setState({ loadingData: true });
    const accountNumber = account.numeroCuenta;
    if (account.codigoProducto !== 'VI06') {
      //llamar tarjetas
      this.props.fetchTarjetasCuentaVista({
        numeroCuenta: accountNumber,
        codigoEstado: ['01'],
        codigoBloqueo: ['', 'G'],
      });
    }
    // llamar ultimos movimientos
    this.props.fetchUltimosMovimientosCuentaVista(accountNumber);
    this.setState(
      produce((draft) => {
        draft.accountNumber = accountNumber;
        draft.accountType = account.codigoProducto;
      })
    );
  };

  clickTabHandle = (position) => {
    if (position !== this.state.selectedTab) {
      this.props.fetchLoadingMvtsCurrentAccountSuccess();
      registerGA({
        event: 'vpv-privado',
        page: `/tef/cuentaVista${tabs[position] === 'Movimientos' ? '/movimientos' : ''}`,
      });

      this.setState(
        produce((draft) => {
          draft.selectedTab = position;
          draft.query = '';
        })
      );
    }
  };

  onQueryHandle = (input) => {
    const query = typeof input === 'string' ? input : '';
    this.setState(
      produce((draft) => {
        draft.query = query;
      })
    );
  };

  onSetMovementsHandle = (movements) => {
    this.setState(
      produce((draft) => {
        draft.movements = movements;
      })
    );
  };

  onChangeAccountHandle = async (event) => {
    const { cuentaVista } = this.props.products;
    const numeroCuenta = parseInt(event.target.value, 10);
    const isMovimientos = tabs[this.state.selectedTab] === 'Movimientos';
    registerGA({
      event: 'eve',
      'eve-acc': `/tef/cuentaVista${isMovimientos ? '/movimientos' : ''}`,
      'eve-cat': 'Click',
      'eve-lab': 'Seleccion cuenta',
    });
    const account = cuentaVista.balance.find((elem) => elem.numeroCuenta === numeroCuenta);
    this.handleSelectedAccount(account);
  };

  onPushMovementsHandle = (numeroCuenta, isReseting) => {
    const { accountNumber, movements, query } = this.state;
    const { cuentaVista } = this.props.products;

    const accountMvts =
      cuentaVista.movements[Boolean(accountNumber) ? accountNumber : numeroCuenta] || [];

    const filteredMvts = accountMvts.filter((item) => isSameWord(item.descripcion, query));

    const mtvs = Boolean(isReseting)
      ? filteredMvts.slice(0, 20)
      : movements.concat(filteredMvts.slice(movements.length, movements.length + 20));

    this.onSetMovementsHandle(mtvs);
  };

  render() {
    const { accountNumber, movements, query, loadingData, selectedTab, renderTabs } = this.state;
    const { products, errMovimientosLog, persona } = this.props;
    const { cuentaVista } = products;
    const { assoCards, lastMovements, balance, loaders, movements: AllMvts } = cuentaVista;

    return (
      <React.Fragment>
        <PageTitleWrapper>
          <PageTitle
            id="header-cuenta-vista"
            title="Cuenta Vista"
            icon={<LoadIcon src={`${iconPath}credit_card_alt.svg`} color="#333" />}
          />
        </PageTitleWrapper>
        <TabsWrapper className="w-100 margin-after-title">
          {!!renderTabs && (
            <Tabs
              bgColor="#fff"
              fontSize={15}
              fontFamily="Ubuntu-Medium"
              tabSize={20}
              activeColor="#e81e2b"
              className="pl-54 tab--wrapper-shadows tabs"
              defaultTab={selectedTab}
            >
              <Tabs.Header>
                <Tab className="tab-style" callback={this.clickTabHandle}>
                  <Link to="/ecd/productos/cuenta-coopeuch">Mi Cuenta</Link>
                </Tab>
                <Tab className="tab-style w-150p" callback={this.clickTabHandle}>
                  <Link to="/ecd/productos/cuenta-coopeuch/movimientos">Movimientos</Link>
                </Tab>
              </Tabs.Header>
              <div className="w-100 bpx-3 bpx-md-5 margin-after-tabs-vista text-left">
                <Tabs.Body>
                  <Suspense fallback={<div />}>
                    <div className="w-100">
                      {selectedTab === 0 && (
                        <MiCuenta
                          {...this.props}
                          onChangeAccount={this.onChangeAccountHandle}
                          allAccounts={balance}
                          cuenta={accountNumber}
                          tarjetasAsociadas={assoCards}
                          ultimosMovimientos={lastMovements}
                          loadingData={loadingData}
                          isOpenTab={tabs[selectedTab] === 'MiCuenta'}
                        />
                      )}
                    </div>
                  </Suspense>
                  <Suspense fallback={<div />}>
                    <div className="w-100">
                      {selectedTab === 1 && (
                        <Movimientos
                          onChangeAccount={this.onChangeAccountHandle}
                          movements={movements}
                          accountNumber={accountNumber}
                          allAccounts={balance}
                          loading={loaders.isMvts}
                          onSearch={this.onSetMovementsHandle}
                          query={query}
                          onQueryHandle={this.onQueryHandle}
                          allMovements={AllMvts[accountNumber]}
                          onPushMovementsHandle={this.onPushMovementsHandle}
                          isOpenTab={tabs[selectedTab] === 'Movimientos'}
                          errMovimientosLog={errMovimientosLog}
                          persona={persona}
                        />
                      )}
                    </div>
                  </Suspense>
                </Tabs.Body>
              </div>
            </Tabs>
          )}
        </TabsWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  products: entities.products,
  isLoadingCuentasVista: ui.loaders.isLoadingCuentasVista,
  isLoadingTarjetas: ui.loaders.isLoadingTarjetasCuentasVista,
  isLoadingUltMovimientos: ui.loaders.isLoadingUltMovimientosCuentasVista,
  persona: entities.persona,
  errCuentasLog: entities.products.errCuentasLog,
  errTarjetasLog: entities.products.errTarjetasLog,
  errUltMovimientosLog: entities.products.errUltMovimientosLog,
  showUmCard: entities.monederoValidar.showBanner,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
  fetchTarjetasCuentaVista(props) {
    dispatch(productActions.fetchTarjetasCuentaVista(props));
  },
  fetchUltimosMovimientosCuentaVista(props) {
    dispatch(productActions.fetchUltimosMovimientosCuentaVista(props));
  },
  fetchLoadingMvtsCurrentAccountSuccess() {
    dispatch(productActions.fetchLoadingMvtsCurrentAccountSuccess({ isMvts: true }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CuentaVista));
