import styled from 'styled-components';

const StyledBannerNorma484 = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({src}) => src});
  color: #fff;

  .banner-norma484-list {
    display: flex;
  }

  .banner-norma484-container {
    padding: 16px 24px;
    height: 100%;
  }

  .banner-row-container {
    place-content: space-between;
    display: flex;
    flex-direction: column;
    width: 337px;
    flex-basis: 337px;
    max-width: 337px;
    height: 100%;
  }

  .banner-norma484-title {
    color: #ffffff;
    font-size: 18px;
    font-family: Ubuntu-Bold, "Ubuntu", sans-serif;
    letter-spacing: 0;
    line-height: 28px;
  }

  .banner-norma484-subtitle {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }

  .banner-norma484-list-text {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .display-desktop {
    display: flex;
  }

  .display-mobile {
    display: none;
  }

  .btn_red {
    width: 180px;
    padding: 14px 24px;
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.10), rgba(0, 0, 0, 0.30)), url(${({srcMobile}) => srcMobile});
    background-size: cover;

    .display-mobile {
      display: flex;
    }

    .display-desktop {
      display: none;
    }

    .banner-row-container {
      place-content: baseline;
      gap: 10px;
    }

  }
  @media screen and (max-width: 400px) {
    .banner-row-container {
      width: 100% !important;
      flex-basis: 100%;
      max-width: 100%;
    }

    .banner-norma484-title {
      font-size: 16px;
    }
  }
`;

export default StyledBannerNorma484;