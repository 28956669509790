import styled from 'styled-components';

export const FiltroItem = styled.div`
  height: 48px;
  padding: 12px;
  border: 1px solid #646569;
  display: flex;
  align-items: center;
`;

export const SinHistorialAlert = styled.div`
  border: 1px dashed #979797;
  background-color: #f4f4f4;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 16px 12px;
`;

export const SinHistorialIconWrapper = styled.div`
  border: 1px dashed #979797;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 40px;
`;

export const WrapperList = styled.div`
  .mobile--movement-historial--description {
    max-width: 160px;
    text-align: right;
  }
`;

export const WrapperLoader = styled.div`
  text-align: center;
`;
