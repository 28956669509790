import CardBox from '@coopeuch-components/web/molecules/CardBox';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import {Wrapper} from './styles';

export const SkeletonDatosBancarios = () => (
    <>
        <Wrapper className="display-desktop">
            <div className="bmt-3">
                <CardBox classes="dashboard-card-shadow">
                    <CardBox.Header>
                        <Skeleton width={160} height={10} />
                    </CardBox.Header>
                    <CardBox.Body>
                        <div className="row pb-28 pl-8">
                            <div className="col-md-12">
                                <Skeleton height={10} />
                            </div>
                            <div className="col-md-12">
                                <Skeleton height={10} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 mb-32 col-sm-12  w-100">
                                <div>
                                    <Skeleton width={60} height={10} />
                                </div>
                                <div className="ml-4 mr-4">
                                    <Skeleton width={190} height={45} />
                                </div>
                            </div>
                            <div className="col-md-3 mb-32 col-sm-12  w-100">
                                <div>
                                    <Skeleton width={60} height={10} />
                                </div>
                                <div className="ml-4 mr-4">
                                    <Skeleton width={190} height={45} />
                                </div>
                            </div>
                            <div className="col-md-3 mb-32 col-sm-12  w-100">
                                <div>
                                    <Skeleton width={60} height={10} />
                                </div>
                                <div className="ml-4 mr-4">
                                    <Skeleton width={190} height={45} />
                                </div>
                            </div>
                            <div className="col-md-3 mb-32 col-sm-12  w-100">
                                <div>
                                    <Skeleton width={60} height={10} />
                                </div>
                                <div className="ml-4 mr-4">
                                    <Skeleton width={190} height={45} />
                                </div>
                            </div>
                        </div>
                    </CardBox.Body>
                </CardBox>
            </div>
            <div className="bmt-3">
                <CardBox.Header>
                <Skeleton width={160} height={10} />
                </CardBox.Header>
                <CardBox classes="dashboard-card-shadow">
                    <CardBox.Body classes="confirmacion-simulacion-card-body">
                        <div className="row pb-28 pl-8">
                            <div className="col-md-12">
                                <Skeleton height={10} />
                            </div>
                            <div className="col-md-12">
                                <Skeleton height={10} />
                            </div>
                            <div className="col-md-12">
                                <Skeleton width={280} height={10} />
                            </div>
                        </div>
                    </CardBox.Body>
                </CardBox>
            </div>

            <div className="bmt-4">
                <div className="row">
                    <div className="col-md-6 skeleton-center-vertical">
                        <Skeleton width={20} className="bmr-2" />
                        <Skeleton width={50} />
                    </div>
                    <div className="col-md-6 btext-right">
                        <Skeleton width={186} height={51} />
                    </div>
                </div>
            </div>
        </Wrapper>
    </>
);

export default SkeletonDatosBancarios;
