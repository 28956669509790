/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/user';

// ---------------- Saga ---------------- //

function fetchUserSuccess(state, action) {
  const { rut } = action.payload;

  state.rut = rut;
}

function fetchLogoutSuccess(state) {
  state.rut = '';
}

/**
 * Acá deberían de ir las acciones que no van a interactuar con el store
 * directamente. De preferencia son las acciones que se comunicaran con la saga
 */

// ------------- connection -------------
const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserSuccess,
    fetchLogoutSuccess,
  },
});

export const userActions = {
  ...userReducer.actions,
};

export default userReducer.reducer;
