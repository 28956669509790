import React, { useEffect } from 'react';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bonoEscolarActions } from '../../../../application/actions/bonoEscolar';
// Template
import ComprobanteTemplate from '../../../components/templates/BonoEscolar/Comprobante';
// Metrics
import { registerGA } from '../../../utils/metrics';

const Comprobante = ({ steps, currentStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const dataBonos = useSelector((state) => state.entities.bonoEscolar.dataBono);
  const saveBono = useSelector((store) => store.entities.bonoEscolar.saveBono);
  const downloadPdfBono = useSelector((store) => store.entities.bonoEscolar.downloadPdfBono);
  const isFetchingDownloadPdfBono = downloadPdfBono.isFetching;
  const isErrorDownloadPdfBono = downloadPdfBono.error;

  const handleClickBack = () => {
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${
        dataBonos.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/comprobante`,
      'eve-cat': 'Click',
      'eve-lab': 'Ir al inicio',
    });
    history.push('/ecd/');
  };

  const handleDownloadPdfBono = () => {
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${
        dataBonos.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/comprobante`,
      'eve-cat': 'Click',
      'eve-lab': 'Descargar comprobante',
    });
    const idPostulacion = saveBono.data?.uuid;
    dispatch(bonoEscolarActions.fetchDownloadPdfBono({ idPostulacion }));
  };

  function handleRetryDownloadPdfBono() {
    dispatch(bonoEscolarActions.retryDownloadPdfBono());
  }

  // Validate routes
  useEffect(() => {
    currentStep < 3 && history.push('/ecd/bono-escolar/');
    registerGA({
      event: 'vpv-privado',
      page: `/beneficios/${
        dataBonos.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/comprobante`,
      producto: dataBonos.typeBono,
      monto: dataBonos.typeBono === 'Bono de Ingreso' ? '$50.000' : '$60.000',
      valoracion: dataBonos.typeBeneficiario,
    });
  }, []);

  return (
    <ComprobanteTemplate
      {...{
        steps,
        dataBonos,
        saveBono,
        handleClickBack,
        handleDownloadPdfBono,
        isFetchingDownloadPdfBono,
        isErrorDownloadPdfBono,
        handleRetryDownloadPdfBono,
      }}
    />
  );
};

export default Comprobante;
