import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { segurosActions } from '../../../application/actions/seguros';

// api
import api from '../../api';

const apiConvenio = api(process.env.REACT_APP_SEGUROS);

export function* fetchSegurosAdicionalesObtener() {
  try {
    const { data, error } = yield call(apiConvenio.get, 'v1/obtener/CN01');

    if (data) {
      yield put(segurosActions.fetchSegurosAdicionalesObtenerSuccess({ data }));
    } else {
      yield put(segurosActions.fetchSegurosAdicionalesObtenerError({ error }));
    }
  } catch (error) {
    yield put(segurosActions.fetchSegurosAdicionalesObtenerError({ error }));
  }
}

export function* watchFetchSegurosAdicionalesObtener() {
  yield takeLatest(segurosActions.fetchSegurosAdicionalesObtener.type, fetchSegurosAdicionalesObtener);
}

export default function* rootSaga() {
  yield spawn(watchFetchSegurosAdicionalesObtener);
}
