import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { allRequiredFields } from '../../../../utils/validators';

import Wrapper, { ErrMessage } from './styles';
import { colors } from '../../../../assets/styles';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import addClass from '@coopeuch-components/web/functions/addClass';

import DashboardCard from '../../../organism/DashboardCard';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import FactoresDeSeguridad from '../../../organism/FactoresDeSeguridad';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const factoresDeSeguridad = {
  2.1: 'tarjetaCoordenadas',
  2.2: 'passCoopeuch',
};

const FactoresDatosDestinatario = ({
  onChangeHandle,
  onTogglePassword,
  onSwitchDifferentFactor,
  onHandleDesafioFactor,
  onChangeInputFactorCoordsHandle,
  onHandleValidarFactor,
  onResetFactorHandle,
  texts,
  destinatario,
  fields,
  fieldsValids,
  factors,
  isLoading,
  isLoadingDesafioOrValidate,
  isEditDestinatario,
  factoresDisponibles,
  errorFactoresMessage,
  tarjetaCoordenadasErrors,
  passCoopeuchErrors,
  destinatarioMessageError,
  desafiosTarjetaCoordenadas,
  errors,
}) => {
  const { selected, tarjetaCoordenadas, passCoopeuch, numeroTarjeta } = factors || {};

  const hasErrorTarjeta = useMemo(
    () => Object.keys(tarjetaCoordenadasErrors).length >= 1,
    [tarjetaCoordenadasErrors]
  );
  const hasErrorPass = useMemo(
    () => Object.keys(passCoopeuchErrors).length >= 1,
    [passCoopeuchErrors]
  );

  const informacionDelFactor = useMemo(
    () => ({
      tipo: selected,
      hasError: selected === 2.2 ? hasErrorPass : hasErrorTarjeta,
      ...(selected === 2.2 ? passCoopeuch : tarjetaCoordenadas),
      ...(hasErrorTarjeta && selected === 2.1 ? tarjetaCoordenadasErrors : {}),
      ...(hasErrorPass && selected === 2.2 ? passCoopeuchErrors : {}),
    }),
    [
      selected,
      hasErrorPass,
      hasErrorTarjeta,
      passCoopeuch,
      tarjetaCoordenadas,
      tarjetaCoordenadasErrors,
      passCoopeuchErrors,
    ]
  );

  const showWarningPassword = useMemo(() => destinatario?.clave?.length < 6, [destinatario.clave]);

  const factorTitle = useMemo(
    () =>
      isLoading
        ? ''
        : selected === 2.2
        ? texts.titlePassCoopeuch
        : `${texts.titleCoordsCard}: ${numeroTarjeta || ''}`,
    [isLoading, selected, texts, numeroTarjeta]
  );

  return (
    <Wrapper>
      {isEditDestinatario ? (
        <>
          {Object.keys(destinatarioMessageError).length > 1 &&
            destinatarioMessageError?.id !== 500 && (
              <ErrMessage className="mt-24 mb-24 text-left">
                <div className="row w-100">
                  <div
                    className={addClass([
                      Boolean(destinatarioMessageError.link) ? 'col-md-9' : 'col-md-12',
                      'row',
                    ])}
                  >
                    <LoadIcon src={`${iconPath}circle_close.svg`} size={20} />
                    <p className="ml-16 col-xs">{destinatarioMessageError?.message}</p>
                  </div>
                  {Boolean(destinatarioMessageError.link) && (
                    <div className="col-xs-12 col-sm-12 col-md-3 row center-xs end-md">
                      <Button
                        size={15}
                        asTextLink
                        color="#bf0338"
                        iconRight={
                          <LoadIcon src={`${iconPath}arrow_right.svg`} size={22} margin="0" />
                        }
                        onClick={() => {}}
                      >
                        {destinatarioMessageError?.link?.name}
                      </Button>
                    </div>
                  )}
                </div>
              </ErrMessage>
            )}
          <DashboardCard className="bmt-xs-3 bmt-sm-4 ml-0 mr-0">
            <DashboardCard.Head title="Clave Internet" borderBottom="complete" />
            <DashboardCard.Body classes="text-left-destinatarios">
              {destinatarioMessageError?.id === 500 ? (
                <div className="row center-xs mt-24">
                  <FactoresDeSeguridad
                    tipo="error"
                    message={destinatarioMessageError?.message}
                    titleClass="pt-8 pb-8"
                    messageClass="pt-8 pb-8"
                    title={destinatarioMessageError?.title}
                  />
                </div>
              ) : (
                <>
                  <p className="pb-32 pt-24 pl-8">{texts.autorizeWithPasswordDescription}</p>
                  <div className="col-md-4 mb-32 col-sm-12 w-100">
                    <div className="ml-4 mr-4 with--help-text ">
                      <p className="black text-bold mb-14">{texts.passwordLabel}</p>
                      <div className="editar-destinatarios--password-wrapper">
                        <LoadIcon
                          src={`${iconPath}lock.svg`}
                          size={20}
                          color="#1F1E1E"
                          className="lock-icon"
                        />
                        <InputLabel
                          textSize={16}
                          size={48}
                          borderColor={showWarningPassword ? colors.red : '#646569'}
                          onChange={onChangeHandle}
                          dataSet="clave"
                          value={destinatario?.clave}
                          type={factors?.showPassword ? 'text' : 'password'}
                          className="w-100"
                          id="editar-destinatarios-input-password"
                          inputClass="pl-28"
                          placeholder="•••••••"
                          iconFunc={onTogglePassword}
                          rounded={4}
                          iconRight={
                            <LoadIcon
                              src={`${iconPath}${
                                factors?.showPassword ? 'eye_open' : 'eye_close'
                              }.svg`}
                              size={20}
                              color="black"
                              className="eye-icon"
                            />
                          }
                          maxLength={showWarningPassword ? null : 8}
                        />
                        {showWarningPassword ? (
                          <div className="error-warning-password">
                            <LoadIcon
                              src={`${iconPath}circle_close.svg`}
                              size={14}
                              className="red"
                            />
                            <span className="text-size-s red pl-2">
                              {texts.errorLengthPassword}
                            </span>
                          </div>
                        ) : (
                          <span className="text-size-s help-text pl-2 black">
                            {texts.helpTextPassword}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </DashboardCard.Body>
          </DashboardCard>
        </>
      ) : (
        <DashboardCard className="bmt-xs-3 bmt-sm-4 ml-0 mr-0">
          <DashboardCard.Head title={factorTitle} borderBottom="complete" />
          <DashboardCard.Body classes="pl-0 pr-0">
            <div className="row center-xs mt-14">
              <div className="col-xs-12 center-xs">
                {isLoading || isLoadingDesafioOrValidate ? (
                  <div className="row center-xs middle-xs destinarios--spinner-card">
                    <SpinnerLoader />
                  </div>
                ) : (
                  <div className="row center-xs destinatarios--wrapper-autorization pb-54">
                    {Object.keys(errorFactoresMessage).length > 1 ? (
                      <FactoresDeSeguridad
                        {...errorFactoresMessage}
                        tipo="error"
                        showButton={true}
                        onClickButton={onResetFactorHandle}
                        buttonText={texts.tryAgain}
                        titleClass="text-size-xxl pt-12 pb-24"
                        messageClass="pb-24 lh-1_5"
                      />
                    ) : (
                      <FactoresDeSeguridad
                        {...desafiosTarjetaCoordenadas}
                        {...informacionDelFactor}
                        onClickButton={
                          informacionDelFactor?.hasError
                            ? onResetFactorHandle
                            : factors[factoresDeSeguridad[selected]]?.stepFactor === 0
                            ? onHandleDesafioFactor
                            : onHandleValidarFactor
                        }
                        buttonText={
                          informacionDelFactor?.hasError
                            ? texts.tryAgain
                            : informacionDelFactor?.buttonText
                        }
                        showInputInError={
                          informacionDelFactor?.hasError ? informacionDelFactor.showInput : true
                        }
                        withCountdownAnimation={informacionDelFactor.activeTimer}
                        isButtonDisabled={
                          isLoading ||
                          !allRequiredFields(fields, fieldsValids) ||
                          isLoadingDesafioOrValidate ||
                          errors.email || 
                          errors.rut
                        }
                        valorDesafio1={factors?.tarjetaCoordenadas?.desafios[1]}
                        valorDesafio2={factors?.tarjetaCoordenadas?.desafios[2]}
                        valorDesafio3={factors?.tarjetaCoordenadas?.desafios[3]}
                        onChangeInput={onChangeInputFactorCoordsHandle}
                        titleClass="text-size-xxl pt-12 pb-24"
                        messageClass="pb-24 lh-1_5"
                      />
                    )}
                  </div>
                )}

                {factoresDisponibles?.length === 2 && !isLoading && (
                  <div>
                    <div className="line-divider mb-14 mt-54" />
                    <Button
                      asTextLink
                      onClick={onSwitchDifferentFactor}
                      color="#e81d2b"
                      className="underline text-size-s"
                    >
                      {selected === 2.2
                        ? texts.iWantToAuthorizeWithTCOO
                        : texts.iWantToAuthorizeWithPassCoopeuch}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      )}
    </Wrapper>
  );
};

FactoresDatosDestinatario.propTypes = {
  onChangeHandle: PropTypes.func,
  onTogglePassword: PropTypes.func,
  onSwitchDifferentFactor: PropTypes.func,
  onHandleDesafioFactor: PropTypes.func,
  onResetFactorHandle: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingDesafioOrValidate: PropTypes.bool,
  isEditDestinatario: PropTypes.bool,
  destinatario: PropTypes.object,
  factors: PropTypes.object,
  texts: PropTypes.object,
  errorFactoresMessage: PropTypes.object,
  factoresDisponibles: PropTypes.array,
  desafiosTarjetaCoordenadas: PropTypes.object,
  onChangeInputFactorCoordsHandle: PropTypes.func,
};

FactoresDatosDestinatario.defaultProps = {
  onChangeHandle: null,
  onTogglePassword: null,
  onSwitchDifferentFactor: null,
  onHandleDesafioFactor: null,
  onChangeInputFactorCoordsHandle: null,
  onResetFactorHandle: null,
  isLoading: false,
  isLoadingDesafioOrValidate: false,
  isEditDestinatario: false,
  destinatario: {},
  factors: {},
  texts: {},
  errorFactoresMessage: {},
  factoresDisponibles: [],
  desafiosTarjetaCoordenadas: {},
};

FactoresDatosDestinatario.displayName = 'FactoresDatosDestinatario_organism';
export default FactoresDatosDestinatario;
