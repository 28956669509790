import styled from 'styled-components';

export const AlertWrapper = styled.div`
  .alert-error {
    height: auto;
  }
`;
const Wrapper = styled.div`
  color: #383638;

  .remanente--currency-wrapper {
    align-items: center;
    margin-bottom: 14px;
    margin-top: 6px;
    display: flex;
    color: #383638;

    & .remanente--currency {
      margin-right: 14px;
      color: #383638;
      float: left;
    }
  }

  .right-module {
    margin-left: 30px;
  }

  .remanente--description-text {
    margin-left: 30px;
  }

  @media screen and (max-width: 720px) {
    .remanente--currency-wrapper {
      margin-top: 0;

      p {
        padding-left: 25px;
      }
    }

    .dashboardcard-body {
      padding-top: 0;
    }

    .remanente--wrapper-icon {
      min-width: 24px;
    }

    .dashboardcard-head .icon {
      min-width: 30px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  button {
    @media screen and (max-width: 63.99em) {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  margin-bottom: 16px;

  .remanente--item-quote {
    padding-right: 30px;
    padding-left: 10px;
  }

  @media screen and (max-width: 320px) {
    padding: 5px 10px;
  }
`;

export default Wrapper;
