export const nextStepsContent = [
  {
    step: '1',
    title: 'Correo de confirmación',
    subtitle: '¡Estás aquí!',
    body: 'Te enviaremos un correo confirmando el ingreso de tu solicitud de bono.',
    bgActive: true,
    iconName: 'icon_mail.svg',
  },
  {
    step: '2',
    title: 'Validación de documentos',
    subtitle: '',
    body: 'Nuestros ejecutivos revisarán los documentos adjuntos y se comunicarán contigo por email o teléfono si necesitan algún documento adicional.',
    bgActive: false,
    iconName: 'icon_file.svg',
  },
  {
    step: '3',
    title: '¡Bienvenido al proceso de postulación!',
    subtitle: '',
    body: 'Una vez validados los documentos, tu solicitud será evaluada por nuestros ejecutivos.',
    bgActive: false,
    iconName: 'icon_profile.svg',
  },
  {
    step: '4',
    title: 'Tu solicitud está en proceso',
    subtitle: '',
    body: '¡Debes estar atento! Podrás revisar el estado de tu solicitud en',
    destacado:'Mis Solicitudes.',
    bgActive: false,
    iconName: 'icon_clock.svg',
  },
];
