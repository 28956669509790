import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
  padding: 32px 54px;
  margin-bottom: 40px;
  color: #333;

  @media screen and (min-width: 320px) {
    margin-top: 45px;
  }
  @media screen and (min-width: 720px) {
    margin-top: 28px;
  }

  small {
    display: none;
  }

  .with--help-text {
    small {
      display: inline;
    }
  }

  .line-divider {
    border-bottom: 1px solid #e2e1e3;
  }

  .destinarios--spinner-card {
    height: 367px;
  }

  .back--icon {
    & svg {
      fill: #e81e2b;

      path {
        fill: #e81e2b;
      }
    }
  }

  .error--input--message {
    & div {
      margin: 0;
    }

    & svg {
      fill: #e81e2b;

      path {
        fill: #e81e2b;
      }
    }
  }

  .text-left-destinatarios {
    text-align: left;
  }

  @media screen and (max-width: 766px) {
    padding: 16px 12px !important;

    .line-divider {
      margin-top: 10px !important;
    }

    .destinatarios--wrapper-autorization {
      padding-bottom: 10px !important;

      p {
        padding-left: 0 36px !important;
        padding-right: 0 36px !important;
      }
    }
  }
`;

export const WarningMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
  display: flex;
  padding: 12px 24px;
  min-height: 48px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f0e5b2;
  color: #876820;
`;

export default Content;
