import React from 'react';
import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import DownloadLink from '../../../../../../atoms/DownloadLink';
import { HRow, Paragraph } from '../styles';

function Contratos({ iconPath, onDownloadGenericContract, documentsPath }) {
  return (
    <CardBox className="bmb-3 bmb-md-4">
      <CardBox.Header>Contratos</CardBox.Header>
      <CardBox.Body>
        <Paragraph className="mb-16">
          <p>
            Conoce y lee tus contratos, estos formalizaran la contratación de tu Cuenta Vista
            Coopeuch, haciendo clic en <b>{` descargar`}</b>
          </p>
        </Paragraph>
        <div className="row middle-xs ml-0">
          <Paragraph size="18">
            <div className="container_icon_title">
              <LoadIcon className="mr-16" src={`${iconPath}note.svg`} color="#1F1E1E" size={22.5} />
              <p>
                Contrato de apertura de Cuenta Vista, afiliación al sistema y uso de Tarjeta de
                Débito
              </p>
            </div>
          </Paragraph>
          <Paragraph marginLeft="38.5" className="mt-16 mb-16">
            <p>
              Autoriza la creación inmediata de una Cuenta Vista y sus comisiones asociadas,
              entendiendo que, en caso de existir, estas podrán ser cobradas automáticamente a tu
              cuenta.
            </p>
            Aceptas que las claves y cualquier dispositivo de seguridad que Coopeuch disponga es de
            uso personal e intransferible. Damos a conocer los canales de comunicación disponibles
            para atenderte y nos facultas para enviar estados de cuenta y cualquier comunicación vía
            e-mail.
          </Paragraph>
          <Paragraph marginLeft="38.5" className="mb-16">
            <DownloadLink
              text="Descargar contrato"
              onClick={onDownloadGenericContract}
              href={`${documentsPath}solicitud_cv/CONTRATO_APERTURA.pdf`}
            />
          </Paragraph>
        </div>
        <HRow className="mb-16" />
        <Paragraph size="18">
          <div className="w-100">
            <LoadIcon
              className="float-left mr-16"
              src={`${iconPath}note.svg`}
              color="#1F1E1E"
              size={22.5}
            />
            <p>Contrato de Prestación de servicios mediante canales</p>
          </div>
        </Paragraph>
        <Paragraph marginLeft="38.5" className="mt-16 mb-16">
          Habilita la atención y contratación de productos a través de canales remotos, como la
          página web, App, Contact center, Cajeros automáticos y otros.
        </Paragraph>
        <Paragraph marginLeft="38.5" className="mb-16">
          <DownloadLink
            text="Descargar contrato"
            onClick={onDownloadGenericContract}
            href={`${documentsPath}solicitud_cv/CONTRATO_PS.pdf`}
          />
        </Paragraph>
        <HRow className="mb-16" />
        <Paragraph size="18">
          <div className="w-100">
            <LoadIcon
              className="float-left mr-16"
              src={`${iconPath}note.svg`}
              color="#1F1E1E"
              size={22.5}
            />
            <p>Poder especial de abono de acreencias</p>
          </div>
        </Paragraph>
        <Paragraph marginLeft="38.5" className="mt-16 mb-16">
          Autorizas a Coopeuch a depositar en esta cuenta cualquier acreencia que exista a tu
          nombre, por ejemplo: Remanente.
        </Paragraph>
        <Paragraph marginLeft="38.5" className="mb-16">
          <DownloadLink
            text="Descargar contrato"
            onClick={onDownloadGenericContract}
            href={`${documentsPath}solicitud_cv/PODER_AA.pdf`}
          />
        </Paragraph>
      </CardBox.Body>
    </CardBox>
  );
}

Contratos.propTypes = {
  iconPath: PropTypes.string.isRequired,
  onDownloadGenericContract: PropTypes.func.isRequired,
  documentsPath: PropTypes.string.isRequired,
};

export default Contratos;
