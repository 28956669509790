import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cuotaParticipacionActions } from '../../../application/actions/cuotaParticipacion';
import Resumen from './Resumen';
import Pago from './Pago';

const CODIGO_CUENTAS = ['VI01', 'VI03', 'VI06'];

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFlow: 'NONE',
      showButton: false
    };
  }

  componentDidMount = () => {
    const { persona } = this.props;
    if (!!persona.success) {
      this.checkSocio(persona);
    }
  };
  
  componentDidUpdate = (prevProps) => {
    const { persona, match } = this.props;
    if (persona !== prevProps.persona && persona) {
      this.checkSocio(persona);
    }
    if (match !== prevProps.match) {
      switch (match.params.flujo) {
        case 'pago':
          this.setState({ selectedFlow: 'PAGO' });
          break;
        default:
          this.setState({ selectedFlow: 'RESUMEN' });
          break;
      }
    }
  };

  checkSocio = (persona) => {
    const { history, match, fetchCuotasParticipacion, fetchCuentasVista } = this.props;
    if (persona.rol !== 'SOCIO') {
      history.push('/ecd/solicita/cuota-de-participacion');
    } else {
      fetchCuotasParticipacion();
      fetchCuentasVista({
        codigoProducto: CODIGO_CUENTAS,
        estadoCuenta: ['A'],
        tipoCliente: ['R'],
      });
      if (match.params.flujo === 'pago') {
        this.setState({ selectedFlow: 'PAGO' });
      } else {
        this.setState({ selectedFlow: 'RESUMEN' });
      }
    }
  };

  toPago = () => {
    this.setState({ selectedFlow: 'PAGO' });
  };

  toResumen = () => {
    this.setState({ selectedFlow: 'RESUMEN' });
  };

  FlowRender = () => {
    const { selectedFlow, showButton } = this.state;
    switch (selectedFlow) {
      case 'RESUMEN':
        return <Resumen toPago={this.toPago} showButton={showButton}/>;
      case 'PAGO':
        return <Pago toResumen={this.toResumen} />;
      default:
        return <></>;
    }
  };

  render() {
    return (
      <>
        <this.FlowRender />
      </>
    );
  }
  
}

Index.displayName = "Container_CDP_MiCuota";

const mapStateToProps = (store) => ({
  persona: store.entities.persona,
  cuentasDisponibles: store.entities.cuotaParticipacion.cuentasDisponibles,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchCuentasVista(props) {
    dispatch(cuotaParticipacionActions.fetchCVCuotasParticipacion(props));
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
