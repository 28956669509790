import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import produce from 'immer';
import IdleTimer from 'react-idle-timer';
import { differenceInSeconds } from 'date-fns';
// Actions
import { loginActions } from '../../../application/actions/ui_login';
import { layoutActions } from '../../../application/actions/ui_layout';
import { personaActions } from '../../../application/actions/persona';
import { userActions } from '../../../application/actions/user';
import { configuracionActions } from '../../../application/actions/configuracion';

import { handleMenuOptionClick } from '../../utils/functions';

import { registerGA } from '../../utils/metrics';

// Components
import LayoutComponent from '../../components/organism/Layout';
import IdleModal from '../../components/organism/IdleModal';
import logger from '../../utils/logger';
import { loguearRutUsuario } from '../../utils/formateoParametriaLog';

const initialState = {
  persona: {},
  showMenu: false,
  showIdleModal: false,
  countdown: 60,
  intervalId: null,
  expiredSessionTimerId: null,
  expiredSession: false,
  logoutAuto: false,
  messageLogoutAuto: '',
  askMantenimientoIntervalId: null,
};

const idleTime = 1000 * 60 * 4; // 4 min
const expiredSessionSeconds = 30 * 60; // 30 min
/**
 * intervalo de tiempo para preguntar si estamos en mantenimiento (5 minutos)
 */
const intervaloMantenimiento = 1000 * 60 * 5;

class Layout extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.state = initialState;
  }

  componentDidMount() {
    const {
      login,
      layout,
      fetchUserSuccess,
      fetchLoginFromLocalStorage,
      fetchLayoutConfigs,
      fetchDatosPersonales,
      fetchObtenerMantencion,
    } = this.props;
    const rut = localStorage.getItem('user-rut');
    const token = sessionStorage.getItem('ecdtoken');
    if (!login.authenticated && !!token) {
      fetchLoginFromLocalStorage();
    }
    const loginDate = localStorage.getItem('login-timedate');
    const secondsElapsed = Math.abs(differenceInSeconds(new Date(), new Date(loginDate)));
    const secondsLeft = expiredSessionSeconds - secondsElapsed;
    const expiredSessionTimerId = setTimeout(() => {
      this.handleLogout('Por seguridad, tu sesión ha finalizado. Ingresa nuevamente tus datos.');
    }, secondsLeft * 1000); //la sesión dura 30 minutos
    this.setState({ expiredSessionTimerId });

    if (!!layout && !!layout.menu && layout.menu.length <= 1) {
      fetchLayoutConfigs();
      fetchDatosPersonales();
      fetchObtenerMantencion();
      const askMantenimientoIntervalId = setInterval(() => {
        fetchObtenerMantencion();
      }, intervaloMantenimiento);
      this.setState({ askMantenimientoIntervalId });
    }
    fetchUserSuccess({ rut });
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { persona, history, login, automaticLogout, logoutState } = this.props;
    const { showIdleModal, logoutAuto, messageLogoutAuto } = this.state;
    if (prevState.showIdleModal !== showIdleModal && !!showIdleModal) {
      this.startCountDown();
    }
    if (prevProps.login.authenticated !== login.authenticated && !login.authenticated) {
      if (logoutAuto) {
        automaticLogout(messageLogoutAuto);
      } else {
        history.push('/');
      }
    }
    if (prevState.logoutState !== logoutState && !!logoutState.message.length) {
      history.push('/');
    }
    if (prevProps.persona !== persona && !!persona.numeroCliente) {
      this.setState({
        persona: {
          nombre: `${persona.primerNombre} ${persona.apellidoPaterno}`,
          iniciales: `${!!persona.primerNombre ? persona.primerNombre[0] : ''}${
            !!persona.apellidoPaterno ? persona.apellidoPaterno[0] : ''
          }`,
        },
      });
    }
  };

  componentWillUnmount = () => {
    const { askMantenimientoIntervalId } = this.state;
    clearInterval(askMantenimientoIntervalId);
  };

  handleLogout = (msg) => {
    const { intervalId, expiredSessionTimerId } = this.state;
    clearInterval(intervalId);
    clearTimeout(expiredSessionTimerId);
    this.setState({ showIdleModal: false, logoutAuto: true, messageLogoutAuto: msg }, () => {
      this.onClickLogoutHandle();
    });
  };

  handleOnIdle = () => {
    this.setState({
      showIdleModal: true,
    });
  };

  startCountDown = () => {
    const intervalId = setInterval(() => {
      this.setState(
        (prevState) => ({ countdown: prevState.countdown - 1 }),
        () => {
          const { countdown } = this.state;
          if (countdown <= 0) {
            this.handleLogout(
              'Tu sesión ha expirado por inactividad. Ingresa nuevamente tus datos'
            );
            registerGA({
              event: 'trigger_event_visto',
              'event-config': {
                'eve-acc': '/login',
                'eve-cat': 'Error',
                'eve-lab': 'Sesión expirada por inactividad',
              },
            });
          }
        }
      );
    }, 1000);
    this.setState({ intervalId });
  };

  onSelectMenuOptionHandler = (selectedOption) => {
    const { layout, fetchFormattedLayoutSuccess } = this.props;
    const elementos = layout.footer.elementos.filter((item) => !item.descripcion);
    const rightContent = layout.footer.elementos.find((item) => item.descripcion);
    const menuOpts = handleMenuOptionClick(layout.formatted.menu, selectedOption);
    fetchFormattedLayoutSuccess({
      menu: menuOpts,
      footer: {
        elementos,
        rightContent,
      },
    });
  };

  onClickMenuItem = (event) => {
    this.onDispatchGmtEvent(event.target.textContent, 'Menu');
    this.onToggleMenuVisibility();
    loguearRutUsuario('HOME_PRODUCTOS_VER_DAP');
  };

  onClickMenuWithChildren = (event) => {
    this.onDispatchGmtEvent(event.target.textContent, 'Menu');

    const { persona } = this.props;
    const baseRut = String((persona?.rut || '').replace('-', ''));

    if (event.target.textContent === 'Certificados') {
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CERTIFICADOS',
        informacion_adicional: {},
      });
    }
  };

  onToggleMenuVisibility = () => {
    this.setState(
      produce((draft) => {
        draft.showMenu = !draft.showMenu;
      })
    );
  };

  onDispatchGmtEvent = (event, section) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': section ? `Click ${section}` : 'Click',
      'eve-lab': typeof event === 'object' ? event.target.dataset.lab : event,
    });
  };

  onClickFooter = (section) => {
    const { persona } = this.props;

    const baseRut = String((persona?.rut || '').replace('-', ''));

    registerGA({ event: 'eve', 'eve-acc': '/tef/', 'eve-cat': 'Click Footer', 'eve-lab': section });

    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: `FOOTER-${String(section).toUpperCase()}`,
      informacion_adicional: {},
    });
  };

  onClickLogoutHandle = () => {
    const { fetchLogoutUser } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Cerrar Sesion',
    });

    fetchLogoutUser();
  };

  handlePressButtonLogout = () => {
    const { persona } = this.props;

    const baseRut = String((persona?.rut || '').replace('-', ''));

    this.onClickLogoutHandle();

    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'HEADER-CERRAR SESIÓN',
      informacion_adicional: {},
    });
  };

  onCloseIdleModal = () => {
    const { showIdleModal, countdown, intervalId: initialIntervalId } = initialState;
    const { intervalId } = this.state;
    clearInterval(intervalId);
    this.setState({
      showIdleModal,
      countdown,
      intervalId: initialIntervalId,
    });
  };

  onClickHeaderPerfil = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Consultar Perfil',
    });
  };

  handlePressIvic = () => {
    console.log({ asd: 'CognitiveAssistantMain' });
    window.CognitiveAssistantMain.launcherHandler(this);
  };

  render() {
    const { children, location, layout } = this.props;
    const { persona, showIdleModal, countdown } = this.state;

    return (
      <>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          timeout={idleTime}
          onIdle={this.handleOnIdle}
        />
        <LayoutComponent
          persona={persona}
          showMenu={this.state.showMenu}
          menuOpts={layout.formatted.menu}
          footer={layout.formatted.footer}
          header={layout.header}
          pathname={location.pathname}
          hideIvicIcon={location.pathname === '/ecd/inicio'}
          onToggleMenuVisibility={this.onToggleMenuVisibility}
          handlePressButtonLogout={this.handlePressButtonLogout}
          onDispatchGmtEvent={this.onDispatchGmtEvent}
          onClickMenuItem={this.onClickMenuItem}
          onClickMenuWithChildren={this.onClickMenuWithChildren}
          onClickHeaderPerfil={this.onClickHeaderPerfil}
          onClickFooter={this.onClickFooter}
          handlePressIvic={this.handlePressIvic}
          fullScreen={layout.fullScreen}
        >
          {children}
        </LayoutComponent>
        {showIdleModal && (
          <IdleModal show={showIdleModal} count={countdown} onCloseModal={this.onCloseIdleModal} />
        )}
      </>
    );
  }
}

Layout.propTypes = {
  layout: PropTypes.shape({
    header: PropTypes.object,
    footer: PropTypes.object,
    menu: PropTypes.array,
    formatted: PropTypes.object,
  }),
};

Layout.defaultProps = {
  layout: {
    header: {},
    footer: {},
    menu: [],
    formatted: {},
  },
};

const mapStateToProps = (state) => ({
  layout: state.ui.layout,
  login: state.ui.login,
  logoutState: state.ui.login.logout,
  persona: state?.entities?.persona,
});

const mapDispatchToProps = (dispatch) => ({
  fetchLoginFromLocalStorage() {
    dispatch(loginActions.fetchUserSuccess());
  },
  fetchLogoutUser() {
    dispatch(loginActions.fetchLogoutUser());
  },
  fetchLogoutSuccess() {
    dispatch(loginActions.fetchLogoutSuccess());
  },
  fetchLayoutConfigs() {
    dispatch(layoutActions.fetchLayoutConfigs());
  },
  fetchFormattedLayoutSuccess(props) {
    dispatch(layoutActions.fetchFormattedLayoutSuccess(props));
  },
  fetchUserSuccess(props) {
    dispatch(userActions.fetchUserSuccess(props));
  },
  automaticLogout(props) {
    dispatch(loginActions.automaticLogout(props));
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
  fetchObtenerMantencion() {
    dispatch(configuracionActions.fetchObtenerMantencion());
  },
  userActions,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));
