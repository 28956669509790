import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isValidEmail } from '../../../utils/formatters';
import { downloadPDFBase64 } from '../../../utils/functions';

// componentes
import ContratoMonederoTemplate from '../../../components/templates/ContratoMonedero';
import { monederoActions } from '../../../../application/actions/monedero';
import { remanenteActions } from '../../../../application/actions/remanente';
import { personaActions } from '../../../../application/actions/persona';
import { layoutActions } from '../../../../application/actions/ui_layout';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

const initState = {
  depositoChecked: false,
  contratacionChecked: false,
  cuenta: null,
  fecha: null,
  email: '',
  emailError: '',
  monto: null,
  fechaCobro: null,
  rawFechaCobro: null,
  cuentaCobro: null,
  errorCobroRemanente: false,
  secondEmail: null,
  password: '',
  passwordError: '',
  secondEmailError: '',
  serverPasswordError: '',
  okStep2: false,
  showPassword: false,
  loadingContratacion: false,
  loadingCobro: false,
  steps: ['Características', 'Contratación y firma', 'Depósito', 'Comprobante'],
  currentStep: 1,
  serviceError: false,
  blockedPassword: false,
  showCobrarDespuesSinContratoModal: false,
  showCobrarDespuesConContratoModal: false,
  showEnviarComprobanteModal: false,
  showEnviarComprobanteErrorModal: false,
  showComprobanteEnviadoModal: false,
};
class ContratoMonedero extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  componentDidMount = () => {
    const { fetchDatosPersonales, persona } = this.props;
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/cobro/paso1B',
    });
    fetchDatosPersonales();
    const baseRut = String((persona?.rut || '').replace('-', ''));
    logger('', {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'REMANENTE-COBRO MONEDERO-INFORMACION',
      informacion_adicional: {},
    });
  };
  onHandleChangeEmail = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      [name]: value,
      [`${name}Error`]: isValidEmail(value) ? '' : errorMessage,
    });
  };
  componentDidUpdate(prevProps, prevState) {
    const { monedero, cobrarRemanenteData, comprobanteCobro, comprobanteContratacion, persona } =
      this.props;
    if (monedero !== prevProps.monedero) {
      this.handleContratoMonederoResponse(monedero);
    } else if (cobrarRemanenteData !== prevProps.cobrarRemanenteData) {
      this.handleCobroMonederoResponse(cobrarRemanenteData);
    } else if (comprobanteCobro !== prevProps.comprobanteCobro && comprobanteCobro !== null) {
      downloadPDFBase64(comprobanteCobro, 'Comprobante Cobro Remanente');
    } else if (
      comprobanteContratacion !== prevProps.comprobanteContratacion &&
      comprobanteContratacion !== null
    ) {
      downloadPDFBase64(comprobanteContratacion, 'Comprobante Contratacion Monedero');
    } else if (persona !== prevProps.persona) {
      if (persona.errorDatosPersonales) {
        this.setState({
          globalError: true,
        });
      } else {
        this.setState({
          email: persona.correo,
        });
      }
    }

    //LOGS CONTRATO MONEDERO
    const {
      isLoadingContratacionMonedero,
      errorLogContratoMonedero,
      isLoadingCobrarRemanente,
      errorCobrarRemanenteLog,
    } = this.props;

    const { currentStep } = this.state;

    if (prevProps.isLoadingContratacionMonedero !== isLoadingContratacionMonedero) {
      if (!isLoadingContratacionMonedero) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorLogContratoMonedero, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'REMANENTE-COBRO MONEDERO-CONTRATACION',
          informacion_adicional: {},
        });
      }
    }
    if (prevProps.isLoadingCobrarRemanente !== isLoadingCobrarRemanente) {
      if (!isLoadingCobrarRemanente) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorCobrarRemanenteLog, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'REMANENTE-COBRO MONEDERO-ABONO',
          informacion_adicional: {},
        });
      }
    }

    if (prevState.currentStep !== currentStep) {
      if (currentStep === 4) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger('', {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'REMANENTE-COBRO MONEDERO-COMPROBANTE',
          informacion_adicional: {},
        });
      }
    }
  }

  handleCobroMonederoResponse = (cobrarRemanente) => {
    if (cobrarRemanente.error) {
      this.setState({
        loadingCobro: false,
        errorCobroRemanente: true,
      });
    } else if (cobrarRemanente.success) {
      const { monto, fecha, rawFecha, numeroCuenta, email } = cobrarRemanente;
      this.setState(
        {
          loadingCobro: false,
          monto,
          fechaCobro: fecha,
          rawFechaCobro: rawFecha,
          cuentaCobro: numeroCuenta,
          email,
        },
        () => this.toNextStep()
      );
    }
  };

  handleContratoMonederoResponse = (monedero) => {
    if (monedero.error) {
      let serverErrorText;
      switch (monedero.errorCode) {
        case '02':
        case '03':
          serverErrorText = 'Clave Bloqueada. Por seguridad hemos bloqueado tu clave.';
          break;
        case '01':
          serverErrorText = 'Los datos ingresados son incorrectos. Favor intente nuevamente';
          break;
        default:
          serverErrorText = '';
      }
      this.setState({
        loadingContratacion: false,
        serverPasswordError: serverErrorText,
        serviceError: serverErrorText.length ? false : true,
        blockedPassword: ['02', '03'].includes(monedero.errorCode) ? true : false,
      });
    } else if (monedero.success) {
      /**
       * Se elimina el llamado al servicio por tener el menú estático
       * TODO: habilitar cuando se vuelva a utilizar el servicio de configuración
       */
      // const { fetchLayoutConfigs } = this.props;
      // fetchLayoutConfigs();
      const { cuenta, fecha, email } = monedero;
      this.setState({
        loadingContratacion: false,
        cuenta,
        fecha,
        email,
      });
      this.toNextStep();
      registerGA({
        event: 'vpv-privado',
        page: '/tef/remanente/cobro/paso3B',
      });
    }
  };

  toNextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };
  toHome = () => {
    this.props.history.push('/ecd/inicio');
  };
  toRemanente = () => {
    this.props.onGoBackToRemanente();
  };
  onHandleCheckBox = (e) => {
    const name = e.target.name;
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };
  onHandlePasswordInput = (e) => {
    const value = e.target.value;
    const errorMessage = 'La clave debe tener entre 6 y 8 caracteres.';
    this.setState({
      password: value,
      passwordError: value.length < 6 || value.length > 8 ? errorMessage : '',
    });
  };
  onHandleChangeEmail = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      [name]: value,
      [`${name}Error`]: isValidEmail(value) ? '' : errorMessage,
    });
  };
  openSendEmailModal = () => {
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: true,
    });
  };
  onHandleSendComprobante = () => {
    const { secondEmail } = this.state;
    console.log(`enviando comprobante al email ${secondEmail}`);
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: true,
    });
  };
  onHandleDownloadComprobante = () => {
    const {
      fetchComprobanteCobro,
      fetchComprobanteContratacion,
      comprobanteCobro,
      comprobanteContratacion,
    } = this.props;
    const { depositoChecked, rawFechaCobro } = this.state;
    if (depositoChecked) {
      if (comprobanteCobro !== null) {
        downloadPDFBase64(comprobanteCobro, 'Comprobante Cobro Remanente');
      } else {
        const date = new Date(rawFechaCobro); //modificar fecha cobro para que se vea ej: "2021-01-12"
        let params = {
          flujoId: 2,
          fechaContratacion: `${date.getFullYear()}-${
            date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
          }-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`,
        };
        fetchComprobanteCobro(params);
      }
    } else {
      if (comprobanteContratacion !== null) {
        downloadPDFBase64(comprobanteContratacion, 'Comprobante Contratacion Monedero');
      } else {
        fetchComprobanteContratacion();
      }
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/comprobanteB',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
  };
  onTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };
  onHandleExitWithoutContract = () => {
    this.setState({
      showCobrarDespuesSinContratoModal: true,
    });
  };
  onHandleExitWithContract = () => {
    this.setState(
      {
        showCobrarDespuesConContratoModal: true,
      },
      () => {
        registerGA({
          event: 'vpv-privado',
          page: '/tef/remanente/cobro/modal-paso3',
        });
      }
    );
  };
  continueStep2 = async () => {
    const { fetchContratacionMonedero } = this.props;
    const { password: clave, email: correo } = this.state;
    this.setState(
      {
        loadingContratacion: true,
      },
      () => {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/cobro/paso2B',
          'eve-cat': 'Click',
          'eve-lab': 'Contratar',
        });
        fetchContratacionMonedero({ clave, correo });
      }
    );
  };

  continueStep3 = () => {
    const { cobrarRemanente } = this.props;
    const { cuenta } = this.state;
    this.setState(
      {
        loadingCobro: true,
      },
      () => {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/cobro/paso3B',
          'eve-cat': 'Click',
          'eve-lab': 'continuar',
        });
        cobrarRemanente(cuenta);
      }
    );
  };
  finalizarSinDeposito = () => {
    const { steps } = this.state;
    const newSteps = [...steps];
    newSteps.splice(2, 1);
    this.setState(
      {
        steps: newSteps,
        currentStep: 4,
        depositoChecked: false,
      },
      () => {
        this.closeAllModals();
      }
    );
  };
  closeAllModals = () => {
    this.setState({
      showCobrarDespuesSinContratoModal: false,
      showCobrarDespuesConContratoModal: false,
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: false,
      showEnviarComprobanteErrorModal: false,
    });
  };

  backStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso1B',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.onHandleExitWithoutContract();
  };
  continueStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso1B',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
    this.toNextStep();
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/cobro/paso2B',
    });
  };
  backStep2 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso2B',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.onHandleExitWithoutContract();
  };
  backStep3 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso3B',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.onHandleExitWithContract();
  };
  handleSendGTMDownloadContract = (contractName = '') => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso2B',
      'eve-cat': 'Click Descargar',
      'eve-lab': contractName,
    });
  };
  backStepModalContratarSinCobro = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/modal-paso3',
      'eve-cat': 'Click',
      'eve-lab': 'Cobrar despues',
    });
    this.finalizarSinDeposito();
  };
  nextStepModalContratarSinCobro = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/modal-paso3',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar ahora',
    });
    this.closeAllModals();
  };
  toRemanenteComprobante = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/comprobanteB',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a Remanente',
    });
    this.toRemanente();
  };

  render() {
    const {
      depositoChecked,
      contratacionChecked,
      cuenta,
      fecha,
      email,
      emailError,
      monto,
      fechaCobro,
      cuentaCobro,
      errorCobroRemanente,
      secondEmail,
      password,
      okStep2,
      showPassword,
      passwordError,
      secondEmailError,
      serverPasswordError,
      loadingContratacion,
      loadingCobro,
      steps,
      currentStep,
      serviceError,
      blockedPassword,
      showCobrarDespuesSinContratoModal,
      showCobrarDespuesConContratoModal,
      showEnviarComprobanteModal,
      showComprobanteEnviadoModal,
      showEnviarComprobanteErrorModal,
    } = this.state;
    const { persona } = this.props;
    return (
      <ContratoMonederoTemplate
        {...{
          depositoChecked,
          contratacionChecked,
          cuenta,
          fecha,
          email,
          emailError,
          monto,
          fechaCobro,
          cuentaCobro,
          errorCobroRemanente,
          secondEmail,
          password,
          okStep2,
          showPassword,
          passwordError,
          secondEmailError,
          serverPasswordError,
          loadingContratacion,
          loadingCobro,
          steps,
          currentStep,
          serviceError,
          blockedPassword,
          showCobrarDespuesSinContratoModal,
          showCobrarDespuesConContratoModal,
          showEnviarComprobanteModal,
          showComprobanteEnviadoModal,
          showEnviarComprobanteErrorModal,
          persona,
        }}
        onDownloadGenericContract={this.handleSendGTMDownloadContract}
        onHandleCheckBox={this.onHandleCheckBox}
        onHandlePasswordInput={this.onHandlePasswordInput}
        onTogglePassword={this.onTogglePassword}
        backStep1={this.backStep1}
        continueStep1={this.continueStep1}
        backStep2={this.backStep2}
        continueStep2={loadingContratacion ? null : this.continueStep2}
        backStep3={this.backStep3}
        continueStep3={loadingCobro ? null : this.continueStep3}
        toRemanenteComprobante={this.toRemanenteComprobante}
        closeModal={this.closeAllModals}
        toHome={this.toHome}
        toRemanente={this.toRemanente}
        onHandleEmailInputChange={this.onHandleChangeEmail}
        onHandleSendComprobante={this.onHandleSendComprobante}
        onHandleDownloadComprobante={this.onHandleDownloadComprobante}
        openSendEmailModal={this.openSendEmailModal}
        finalizarSinDeposito={this.finalizarSinDeposito}
        backStepModalContratarSinCobro={this.backStepModalContratarSinCobro}
        nextStepModalContratarSinCobro={this.nextStepModalContratarSinCobro}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  monedero: store.entities.monedero.contratacionMonedero,
  cobrarRemanenteData: store.entities.remanente.cobrarRemanente,
  comprobanteCobro: store.entities.remanente.comprobanteCobro,
  comprobanteContratacion: store.entities.monedero.comprobanteContratacion,
  persona: store.entities.persona,
  isLoadingDatosPersonales: store.ui.loaders.isLoadingDatosPersonales,
  isLoadingContratacionMonedero: store.ui.loaders.isLoadingContratacionMonedero,
  errorLogContratoMonedero: store.entities.monedero.errorLogContratoMonedero,
  isLoadingCobrarRemanente: store.ui.loaders.isLoadingCobrarRemanente,
  errorCobrarRemanenteLog: store.entities.remanente.errorCobrarRemanenteLog,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContratacionMonedero(props) {
    dispatch(monederoActions.fetchContratacionMonedero(props));
  },
  cobrarRemanente(props) {
    dispatch(remanenteActions.cobrarRemanente(props));
  },
  fetchComprobanteCobro(props) {
    dispatch(remanenteActions.fetchComprobanteCobro(props));
  },
  fetchComprobanteContratacion(props) {
    dispatch(monederoActions.fetchComprobanteContratacion(props));
  },
  fetchLayoutConfigs() {
    dispatch(layoutActions.fetchLayoutConfigs());
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContratoMonedero));
