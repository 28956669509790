import React from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import Skeleton from 'react-loading-skeleton';

function SkeletonLiquidarComprobante() {
  return (
    <div className="col-xs-12">
      <div className="mt-24">
        <Skeleton height={47} />
      </div>

      <DashboardCard classes="mb-16 mt-32 pt-28 pb-28 pl-32 pr-32">
        <div className="row start-xs mb-54">
          <div className="col-xs-12">
            <Skeleton height={10} width={174} />
          </div>
        </div>
        <div className="row start-xs">
          <div className="col-xs-12 mb-12">
            <Skeleton height={10} width={274} />
          </div>
          <div className="col-xs-12 mb-12">
            <Skeleton height={10} width={174} />
          </div>
          <div className="col-xs-12">
            <Skeleton height={10} width={444} />
          </div>
        </div>
      </DashboardCard>

      <DashboardCard classes="mb-16 mt-32 pt-28 pb-28 pl-32 pr-32">
        <div className="row start-xs mb-36">
          <div className="col-xs-12">
            <Skeleton height={10} width={174} />
          </div>
        </div>
        <div className="row start-xs">
          <div className="col-xs-12 mb-36">
            <Skeleton height={88} />
          </div>
          <div className="col-xs-12 mb-36">
            <Skeleton height={50} />
          </div>
          <div className="col-xs-12 mb-36">
            <Skeleton height={50} />
          </div>
          <div className="col-xs-12 mb-36">
            <Skeleton height={50} />
          </div>
          <div className="col-xs-12">
            <Skeleton height={50} />
          </div>
        </div>
      </DashboardCard>

      <div className="row end-xs mb-36">
        <div className="col-xs-4">
          <Skeleton height={48} width={236} />
        </div>
      </div>
    </div>
  );
}

export default SkeletonLiquidarComprobante;
