import styled from 'styled-components';

export const Wrapper = styled.div`
  .producto-dashboard-card {
    border-radius: 4px;
  }

  .producto-dashboard-card section {
    border-radius: 4px;
  }

  .producto-container {
    min-height: 178px;
    padding: 16px 32px 16px 32px !important;
  }

  .producto-app-container {
    display: inline-flex;
  }

  .producto-title {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    font-family: 'Ubuntu-Medium';
  }

  .producto-descripcion {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .producto-icon-ios {
    height: 29.75px;
    width: 90.98px;
  }

  .producto-icon-android {
    height: 30px;
    width: 102.46px;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  .producto-icon-passcoopeuch {
    height: 146px;
  }

  @media screen and (max-width: 1028px) {
    .producto-container {
      padding: 16px 16px 16px 16px !important;
    }
    .display-desktop {
      display: initial;
    }
    .display-desktop {
      display: none;
    }
    .display-mobile {
      display: initial;
    }
    .producto-icon-ios {
      height: 10%;
      width: 42%;
      display: inline-flex;
    }

    .producto-icon-android {
      height: 10%;
      width: 46%;
      display: inline-flex;
    }

    .producto-app-container {
      display: block;
      text-align: center;
    }
  }
`;

export default Wrapper;
