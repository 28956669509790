// eslint-disable no-param-reassign
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/ate';

// ------- SAGA ------ //

function fetchContextoAteSuccess(state, action) {
    
        state.contexto = action.payload.contexto;
        state.mostrarBanner = action.payload.activoTef;
        state.detalle = action.payload.detalle;
        state.vigencia = action.payload.vigencia;

}

function fetchContextoAteFailed(state, action) {

    state.contexto = null;
    state.mostrarBanner = false;
    state.detalle = null;
    state.vigencia = null;

}

const fetchContextoAte = createAction('fetchContextoAte');

const fetchContextoAteReducer = createSlice({
    name: 'fetchContextoAte',
    initialState,
    reducers: {
        fetchContextoAteSuccess,
        fetchContextoAteFailed,
    }
});

export const fetchContextoAteActions = {
    ...fetchContextoAteReducer.actions,
    fetchContextoAte,
};

export default fetchContextoAteReducer.reducer;
