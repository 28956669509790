import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { tarjetaCreditoActions } from '../../../../../application/actions/tarjetaCredito';

import PagarTarjetaMontoAPagarTemplate from '../../../../components/templates/TarjetaDeCredito/PagarTarjetaMontoAPagar';
import { regexOnlyNumber } from '../../../../utils/formatters';
import { useShallowStore } from '../../../../utils/hooks';
import { registerGA } from '../../../../utils/metrics';

const PagarTarjetaMontoAPagar = (props) => {
  const { history, monto, tipoMonto, datosTarjeta, handleTipoMonto, handleMonto, handleNextStep } =
    props;
  const { numeroTarjeta } = history?.location?.state || {};

  const dispatch = useDispatch();
  const facturacion = useShallowStore(
    ({ entities }) => entities.tarjetaCredito.facturacionTarjetaCredito
  );

  const loading = useShallowStore(({ ui }) => ui.loaders.isLoadingFacturacionTarjetaCredito);
  const error = useShallowStore(
    ({ entities }) => entities.tarjetaCredito.facturacionTarjetaCreditoError
  );

  const errorCuentasVista = useShallowStore(({ entities }) => entities.products.errorCuentasVistas);
  const errorMantencion = useShallowStore(
    ({ entities }) => entities.configuracion.mantencion?.error
  );

  const [montoErr, setMontoErr] = useState('');

  const { montoMinimo, montoTotal } = facturacion?.facturacionNacional || {};

  const handleTypeOfMonto = useCallback(
    (event) => {
      const tipo = event?.target?.value;

      handleTipoMonto(tipo);

      if (tipo === 'otro') return handleMonto(0);

      setMontoErr('');

      if (tipo === 'facturado') handleMonto(montoTotal);
      if (tipo === 'minimo') handleMonto(montoMinimo);
    },
    [handleTipoMonto, handleMonto]
  );

  const handleManualMonto = useCallback(
    (event) => {
      const nuevoMonto = Number(regexOnlyNumber(event?.target?.value));

      setMontoErr('');
      handleMonto(nuevoMonto);

      if (nuevoMonto < montoMinimo) setMontoErr('min');
      if (nuevoMonto > montoTotal) setMontoErr('max');
    },
    [handleMonto, montoMinimo, montoTotal]
  );

  useEffect(() => {
    if (monto === 0 && montoTotal) handleMonto(montoTotal);
  }, [montoTotal]);

  useEffect(() => {
    dispatch(tarjetaCreditoActions.fetchFacturacionTarjetaCredito({ numeroTarjeta }));
  }, [numeroTarjeta]);

  useEffect(() => {
    dispatch(tarjetaCreditoActions.clearLastPago());

    registerGA({
      event: 'vpv-privado',
      page: '/tef/tarjeta-de-credito/pagar/paso-1',
    });
  }, []);

  useEffect(() => {
    if (error || errorCuentasVista || errorMantencion) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/tarjeta-de-credito/pagar/paso-1',
        'eve-cat': 'Error',
        'eve-lab': 'No se pudo realizar pago',
      });
    }
  }, [error, errorCuentasVista, errorMantencion]);

  return (
    <PagarTarjetaMontoAPagarTemplate
      error={error || errorCuentasVista || errorMantencion}
      loading={loading}
      numeroTarjeta={numeroTarjeta}
      datosTarjeta={datosTarjeta}
      tipoMonto={tipoMonto}
      monto={monto}
      montoErr={montoErr}
      facturacion={facturacion}
      handleManualMonto={handleManualMonto}
      handleTypeOfMonto={handleTypeOfMonto}
      handleNextStep={handleNextStep}
    />
  );
};

export default PagarTarjetaMontoAPagar;
