import React from "react";
import './styles.css'

function CashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Capa 1"
      viewBox="0 0 32 32"
      className="cash-icon"
    >
      <g>
        <path d="M0 32L32 32 32 0 0 0 0 32z" className="cls-1"></path>
        <path d="M5 22L17 22 17 2 5 2 5 22z" className="cls-2"></path>
        <path
          d="M25.6 15.18l-3.18-2.89-4.27 11.31zM18 18.33l3.05-8.09.77-2.05L18 6.69zM16.24 23h-5.88l5.13 2zm-1.09 4l-5.49-2.13 4.09 3.71zM6 21h10V3H6zm21.75-5.24l-13.17 14.9a1 1 0 01-.69.34h-.06a1 1 0 01-.67-.26l-8.83-8a1 1 0 01-.14-.2.86.86 0 01-.07-.1.9.9 0 010-.16A1.16 1.16 0 014 22V2a1 1 0 011-1h12a1 1 0 011 1v2.55l5.48 2.12A1 1 0 0124.05 8l-.87 2.33 4.49 4.08a1 1 0 01.08 1.4zM10 10.5a.5.5 0 00.5.5h1a2.5 2.5 0 01.5 5v1a1 1 0 01-2 0v-1H9a1 1 0 010-2h2.5a.5.5 0 000-1h-1a2.5 2.5 0 01-.5-4.95V7a1 1 0 012 0v1h1a1 1 0 010 2h-2.5a.5.5 0 00-.5.5z"
          className="cls-3"
        ></path>
      </g>
    </svg>
  );
}

export default CashIcon;