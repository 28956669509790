import React, { Fragment } from 'react';
import DashboardCard from '../../../../components/organism/DashboardCard';
import { capitalize } from '../../../../utils/functions';
import { agregarformatoPesos, agregarFormatoDolar } from '../../../../utils/formatters';
import { tarjetaCreditoDorada } from '../../../../utils/constants';
import { SkeletonCard, SkeletonSlideContent } from '../Skeleton';
import ErrorDashboard from '../ErrorDashboard';

import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from 'swiper';

import Wrapper from './styles';
import { tarjetasCreditoTexts } from '../../../../containers/Dashboard/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Index = ({
  tarjetasCredito,
  cupoTarjetaCredito,
  handleChangeSlide,
  isLoadingTarjetasCredito,
  isLoadingCupoTarjetaCredito,
  tarjetaCreditoError,
  handleClickTarjeta,
}) => {
  return (
    <Wrapper>
      {isLoadingTarjetasCredito ? (
        <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
          <div className="cuenta-swiper">
            <DashboardCard classes="m-0 cuenta-card-container">
              <DashboardCard.Body classes="cuenta-container">
                <SkeletonCard short />
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        </div>
      ) : (
        <Fragment>
          {tarjetaCreditoError ? (
            <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
              <ErrorDashboard descText={tarjetasCreditoTexts.ERROR_DESC} />
            </div>
          ) : (
            <div>
              {tarjetasCredito?.length >= 1 && (
                <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
                  <Swiper
                    pagination={tarjetasCredito.length > 1}
                    navigation={tarjetasCredito.length > 1}
                    className="cuenta-swiper"
                    onSlideChange={handleChangeSlide}
                  >
                    {tarjetasCredito.map((item, index) => (
                      <SwiperSlide key={index}>
                        <DashboardCard classes="m-0 cuenta-card-container">
                          <DashboardCard.Body classes="cuenta-container">
                            <div
                              className="row cursor-pointer"
                              onClick={() => handleClickTarjeta(item)}
                            >
                              <div className="col-md-2 col-xs-3 cuenta-icon-container bpl-0 bpl-md-2">
                                <img
                                  src={
                                    tarjetaCreditoDorada.includes(item?.codigoProducto)
                                      ? `${iconPath}tc2_new.svg`
                                      : `${iconPath}tc_new.svg`
                                  }
                                  className="float-left bmr-3 cuenta-icon"
                                />
                              </div>
                              <div className="col-md-10 col-xs-9 bpl-2">
                                <div className="bml-0 bml-md-2 bmb-1">
                                  <label className="cuenta-title cursor-pointer">
                                    {capitalize(item.descripcionProducto)}
                                  </label>
                                </div>
                                <div className="bml-0 bml-md-2">
                                  <label className="cursor-pointer">
                                    {tarjetasCreditoTexts.NUMERICO}{' '}
                                    {tarjetasCreditoTexts.PREFIJO_NUMERO_TARJETA}{' '}
                                    {item.nroTarjeta?.substring(
                                      item.nroTarjeta?.length - 4,
                                      item.nroTarjeta
                                    )}
                                  </label>
                                </div>
                              </div>
                              {isLoadingCupoTarjetaCredito ? (
                                <SkeletonSlideContent />
                              ) : (
                                <Fragment>
                                  <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 bpl-0 bpl-md-2">
                                    <div className="bmb-2">
                                      <label className="cuenta-text cursor-pointer">
                                        {tarjetasCreditoTexts.DISPONIBLE_NACIONAL}
                                      </label>
                                    </div>
                                    <div>
                                      <label className="cuenta-value cursor-pointer">
                                        {agregarformatoPesos(
                                          cupoTarjetaCredito?.cupoResumenNacional?.saldoDisponible
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                  {cupoTarjetaCredito?.cupoResumenInternacional && (
                                    <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 bmb-3 bmb-md-0 bpl-0 bpl-md-2">
                                      <div className="bmb-2 cuenta-text-right">
                                        <label className="cuenta-text cursor-pointer">
                                          {tarjetasCreditoTexts.DISPONIBLE_INTERNACIONAL}
                                        </label>
                                      </div>
                                      <div className="cuenta-text-right">
                                        <label className="cuenta-value cursor-pointer">
                                          {tarjetasCreditoTexts.PREFIJO_USD}
                                          {agregarFormatoDolar(
                                            cupoTarjetaCredito?.cupoResumenInternacional
                                              ?.saldoDisponible
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  )}
                                </Fragment>
                              )}
                            </div>
                          </DashboardCard.Body>
                        </DashboardCard>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

export default Index;
