import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/seguros';

// ------- SAGA ------ //

function fetchSegurosAdicionalesObtenerSuccess(state, action) {
  state.errLogsSegurosAdicionales = null;
  
  state.segurosAdicionales = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchSegurosAdicionalesObtenerError(state, action) {
  const { error } = action.payload || {}

  state.errLogsSegurosAdicionales = error;
  state.segurosAdicionales = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

const fetchSegurosAdicionalesObtener = createAction('fetchSegurosAdicionalesObtener ');

const segurosReducer = createSlice({
     name: 'seguros',
     initialState,
     reducers: {
      fetchSegurosAdicionalesObtenerSuccess,
      fetchSegurosAdicionalesObtenerError,
     },
});
   
export const segurosActions = {
     ...segurosReducer.actions,
     fetchSegurosAdicionalesObtener ,
};
   
export default segurosReducer.reducer;