import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Wrapper = styled.div`
  .content-wrapper-gestion-clave {
    max-width: 910px !important;
  }

  .metodo-radio-button {
    height: 24px;
    width: 24px;
    margin-top: 12px;
    margin-bottom: 24px;

    &::before {
      height: 14px;
      width: 14px;
    }

    &::after {
      height: 24px;
      width: 24px;
    }
  }

  .metodo-card-container {
    text-align: left;
    margin-left: 50px;
    margin-top: 32px;
    margin-bottom: 6px;
  }

  .metodo-radio-group-container {
    margin-top: 24px;
    display: inline-block;
  }

  .metodo-button-container {
    margin-top: 10px;
  }

  .sms-text-class {
    font-size: 16px;
    margin-right: 152px;
    margin-bottom: -10px;
  }

  .alert--wrapper {
    height: 60px;
  }

  .disabled {
    color: #D5D0D5 !important;
  }

  .info--wrapper--crear-clave {
    background-color: #d9f1fa;
    border-radius: 8px;
  }

  .info--wrapper--crear-clave-2 {
    background-color: #F2FBFF;
    padding-left: 30px !important;
    padding-bottom: 14px !important;
    padding-top: 14px !important;
    margin-top: 35px !important;
    border:1px solid #8FDAFA;
  }

  div.contenedorBotonCentradoSalir {
    
  }

  div.paddingEspecial {
    padding-right: 24px !important;
  }

  @media screen and (max-width: 465px) {
    div.contenedorBotonCentradoSalir {
      justify-content: center;
    }

    .info--wrapper--crear-clave-2 {
      margin-top: 0px !important;
    }

    div.paddingEspecial {
      padding-right: 2px !important;
    }
  }

  ul.listaCajaInformacion {
    list-style-type: none;

    img.cajaInformacionImagen {
      filter:none;
      width:16px;
      height:26px;
      margin-left: -24px; //Basicamente 8px de separacion con el texto.
    }
  }

  img.cajaInformacionNoRepetirClave {
    width:16px;
    height:26px;
    margin-left: -24px; //Basicamente 8px de separacion con el texto.
  }

  .input-for-form {
    border-radius: 4px;
  }

  .wrapper--input--clave {
    position: relative;
    height: 68px;

    .lock-icon {
      position: absolute;
      z-index: 2;
      top: 13px;
      left: 5px;
    }

    .eye-icon {
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .help-text {
      position: absolute;
      left: 0;
      bottom: 0px;
    }
  }

  .hide-help-text {
    small {
      display: none;
    }
  }

  #tw {
    display: inline-block;
    .tooltip-dni {
      left: -8px !important;
      bottom: 27px !important;
      width: 250px;
    }
  }

  .question--tooltip {
    margin-bottom: 12px;
    display: flex;
  }

  .question-tooltip-text {

    p {
      color: #02a1e0 !important;
      line-height: 1.5 !important;
    }
  }

  .question--icon {
    border: 1px solid #02a1e0;
    border-radius: 50px;
    width: 17px;
    padding-left: 5px;
    height: 18px;

    p {
      color: #02a1e0 !important;
      line-height: 1.5 !important;
    }
  }

  .spinner-container {
    margin-top: 190px;
    margin-bottom: 190px;
  }

  .imgDNI {
    margin-left: 14px;
    width: 90%;
  }

  .error-title {
    font-size: 18px;
    text-align: center;
    font-family: 'Ubuntu-Bold';
    color: #383638;
  }

  .error-desc {
    font-size: 18px;
    text-align: center;
    font-family: 'Ubuntu-Regular';
    line-height: 28px;
    color: #383638;
  }

  .label-desc {
    font-size: 19px;
  }

  .link-button {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    text-decoration: underline;
    text-underline-position: under;
    font-weight: bold;
  }

  .link-button:disabled {
    background-color: transparent;
    color: #d6d0d5;
  }

  .link-text {
    cursor: pointer;
  }

  .validacion-icon-error {
    margin-top: 3px;
  }

  .validacion-cp-container {
    margin-left: -46px;
    margin-right: -46px;
  }

  .rutyserie-container {
    padding: 32px;
  }

  @media (max-width: 767px) {
    .rutyserie-mensaje-container{
      margin-bottom: 24px;
      text-align: left;
    }
  }
  @media (min-width: 768px) {
    .rutyserie-mensaje-container{
      margin-left: 40px;
      margin-bottom: 12px;
      text-align: left;
    }
  }

  .rutyserie-input-container {
    margin-left: 40px;
    text-align: left;
  }

  .rutyserie-alert-container {
    margin-left: 40px;
    margin-right: 40px;
    text-align: left;
  }

  .rutyserie-input-margin {
    margin-bottom: 12px;
  }

  .rutyserie-2nd-input-margin {
    margin-bottom: 0px;
  }

  .rutyserie-information-container {
    margin-top: 32px;
  }

  .rutyserie-input {
    input::placeholder {
      color: #9c9a9f;
    }
  }

  .validacion-sms-container {
    padding: 24px 26px;
  }

  .metodo-card-text {
    line-height: 26px;
  }

  .info-lineal {
    line-height: 20px;
  }

  @media (max-width: 768px) {
    .margin--mobile {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .margin--mobile-informacion {
      margin-left: -12px !important;
      margin-right: -12px !important;
    }

    .info--wrapper--crear-clave {
      padding-left: 12px !important;
      padding-right: 12px !important;

      div {
        padding-left: 20px !important;
      }

      li {
        padding-bottom: 4px !important;
      }
    }

    .metodo-card-container {
      margin-left: 0px;
      margin-top: 8px;
    }

    .button-style-crear-clave {
      width: 100%;
    }

    .validacion-cp-container {
      margin-left: -0.5rem;
      margin-right: -0.5rem;
    }

    .rutyserie-container {
      padding: 16px;
    }

    .rutyserie-input-container {
      margin-left: 0px;
      text-align: left;
    }

    .rutyserie-information-container {
      margin-top: 16px;
    }

    .rutyserie-alert-container {
      margin-left: 0;
      margin-right: 0;
    }

    .validacion-sms-container {
      padding: 16px;
    }

    .validacion-sms-number {
      white-space: nowrap;
    }

    .sms-text-class {
      margin-right: 0px;
      text-align: left;
    }
  }
`;

export const Header = styled.div`
  background-color: #e81f2c;
  min-height: 72px;
  padding: 5px;

  img {
    width: 170px;
    height: 30px;
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > svg {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    transform-origin: center;
    animation: ${spin} 2s linear infinite;
  }
`;

export const WarningMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
  display: flex;
  padding: 12px 24px;
  min-height: 48px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f0e5b2;
  color: #876820;
`;

export const ErrorMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
  display: flex;
  padding: 12px 24px;
  min-height: 48px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f9d4d5;
  color: #bf0238;
`;

export default Wrapper;
