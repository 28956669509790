import React from 'react';
import AlertModal from '../../../../../atoms/AlertModal';
import { Text } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({ show, closeAction }) => {
  return (
    <AlertModal showModal={show} width="424" onClose={closeAction}>
      <div className="center-xs">
        <div className="row center-xs">
          <img src={`${iconPath}success-icon.svg`} alt="success" />
        </div>
        <Text className="bmb-3" textSize={18} size={24} weight="bold">
          ¡Felicidades, el comprobante ha sido enviado exitosamente!
        </Text>
      </div>
    </AlertModal>
  );
};

export default Index;
