import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { tarjetaCreditoActions } from '../../../../application/actions/tarjetaCredito';

import TarjetaCreditoTemplate from '../../../components/templates/Dashboard/TarjetaCredito';

import { capitalize } from '../../../utils/functions';
import { registerGA } from '../../../utils/metrics';

class TarjetaCredito extends Component {
  componentDidMount() {
    const { fetchTarjetasCreditoTC } = this.props;
    const params = { estados: ['01'] };
    fetchTarjetasCreditoTC({ params });
  }

  componentDidUpdate(prevProps) {
    const { isLoadingTarjetasCredito, tarjetasCredito, fetchCupoTarjetaCredito } = this.props;
    if (prevProps.isLoadingTarjetasCredito !== isLoadingTarjetasCredito) {
      if (tarjetasCredito?.length > 0) {
        fetchCupoTarjetaCredito({
          numeroTarjeta: tarjetasCredito?.filter(({ tipo }) => tipo === 'T')[0]?.nroTarjeta,
        });
      }
    }
  }

  obtenerTarjetasTitulares = () => {
    const { tarjetasCredito } = this.props;
    return tarjetasCredito?.filter(({ tipo }) => tipo === 'T');
  };

  handleChangeSlide = (element) => {
    const { tarjetasCredito, fetchCupoTarjetaCredito } = this.props;

    fetchCupoTarjetaCredito({
      numeroTarjeta: tarjetasCredito?.filter(({ tipo }) => tipo === 'T')[element.activeIndex]
        ?.nroTarjeta,
    });

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Visualizacion de estado de cuenta'
    });
  };

  handleClickTarjeta = ({ nroTarjeta, descripcionProducto }) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': capitalize(descripcionProducto),
    });

    this.props.history.push({
      pathname: '/ecd/productos/tarjeta-de-credito',
      state: {
        numeroCuenta: nroTarjeta,
      },
    });
  };

  render() {
    const {
      cupoTarjetaCredito,
      isLoadingTarjetasCredito,
      isLoadingCupoTarjetaCredito,
      tarjetaCreditoError,
    } = this.props;

    return (
      <div className="col-md-6 col-xs-12">
        <TarjetaCreditoTemplate
          tarjetasCredito={this.obtenerTarjetasTitulares()}
          cupoTarjetaCredito={cupoTarjetaCredito}
          handleChangeSlide={this.handleChangeSlide}
          isLoadingTarjetasCredito={isLoadingTarjetasCredito}
          isLoadingCupoTarjetaCredito={isLoadingCupoTarjetaCredito}
          tarjetaCreditoError={tarjetaCreditoError}
          handleClickTarjeta={this.handleClickTarjeta}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  isLoadingTarjetasCredito: ui.loaders.isLoadingTarjetasCredito,
  tarjetasCredito: entities.tarjetaCredito.tarjetasCredito,
  tarjetaCreditoError: entities.tarjetaCredito.tarjetaCreditoError,
  cupoTarjetaCredito: entities.tarjetaCredito.cupoTarjetaCredito,
  cupoTarjetaCreditoError: entities.tarjetaCredito.cupoTarjetaCreditoError,
  isLoadingCupoTarjetaCredito: ui.loaders.isLoadingCupoTarjetaCredito,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTarjetasCreditoTC(params) {
    dispatch(tarjetaCreditoActions.fetchTarjetasCreditoTC(params));
  },
  fetchCupoTarjetaCredito(params) {
    dispatch(tarjetaCreditoActions.fetchCupoTarjetaCredito(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TarjetaCredito));
