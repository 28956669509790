import styled from 'styled-components';

export const SliderPage = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  color: #fff;
`;

export const AppStoreCol = styled.div`
  img {
    width: auto;
    height: 30px;
    /* height: 36px; */
    float: left;
  }
`;
