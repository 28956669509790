import React from 'react';

// Components
import Wrapper from './styles';
import DashboardCard from '../../../organism/DashboardCard';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Alert from '@coopeuch-components/web/atoms/Alert';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const SolicitaPassCoopeuch = () => (
  <Wrapper className="">
    <Alert bgType="info" iconType="info" show className="alert-content">
      Nos dimos cuenta que no tienes descargada la App PassCoopeuch la cual es necesaria para
      autorizar tus transferencias.
    </Alert>
    <DashboardCard margin="0" classes="modulo">
      <DashboardCard.Head
        title="Usa PassCoopeuch"
        titleClass="text-size-xl"
        borderBottom="complete"
      />
      <DashboardCard.Body
        classes="modulo-content"
        title="¿Qué es PassCoopeuch?"
        titleClass="text-bold text-size-m"
      >
        <Typography className="text-size-m mt-4 black" tag="p">
          Es una aplicación que permite validar todas tus transacciones en línea como
          transferencias, compras, actualización de datos y creación de destinatarios.
        </Typography>
      </DashboardCard.Body>
    </DashboardCard>
    <DashboardCard margin="0">
      <DashboardCard.Head
        title="¿Cómo descargarla?"
        titleClass="text-size-xl"
        borderBottom="complete"
      />
      <DashboardCard.Body classes="modulo-content">
        <div className="row">
          <div className="col-xs">
            <div>
              <p className="text-bold">1. Ingresa a Google Play o APP Store</p>
              <Typography tag="p" className="black">
                Descarga PassCoopeuch e instala la APP
              </Typography>

              <div className="row mt-14">
                <div className="col-xs">
                  <img src={`${staticPath}logo_app_store.png`} alt="logo_app_store" />
                  <img
                    src={`${staticPath}qr_code_pass_coopeuch_ios.png`}
                    alt="qr_code_pass_coopeuch_ios"
                    className="hide-on-mobile pt-8"
                  />
                </div>

                <div className="col-xs">
                  <img src={`${staticPath}logo_google_play.png`} alt="logo_google_play" />
                  <img
                    src={`${staticPath}qr_code_pass_coopeuch_android.png`}
                    alt="qr_code_pass_coopeuch_android"
                    className="hide-on-mobile pt-8"
                  />
                </div>
              </div>
            </div>
            <div className="mt-12">
              <p className="text-bold pt-24 line-divider-top">
                2. Puedes realizar tu autenticacion
              </p>
              <Typography tag="p" className="black">
                Con tu tarjeta de coordenadas o contestando algunas preguntas
              </Typography>
            </div>
            <div className="mt-12">
              <p className="text-bold pt-24 line-divider-top">3. Crea tu clave PassCoopeuch</p>
              <Typography tag="p" className="black">
                Para que puedas autorizar tus transacciones
              </Typography>
            </div>
            <div className="mt-12">
              <p className="text-bold pt-24 line-divider-top">4. Felicitaciones</p>
              <Typography tag="p" className="black">
                Puedes comenzar a utilizar tu PassCoopeuch
              </Typography>
            </div>
          </div>
          <div className="col-xs hide-on-mobile mt-34">
            <img src={`${staticPath}transferencia_passcoopeuch.png`} alt="pass_coopeuch_mobile" />
          </div>
        </div>
      </DashboardCard.Body>
    </DashboardCard>
  </Wrapper>
);

export default SolicitaPassCoopeuch;
