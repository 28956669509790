import React from 'react';
import DashboardCard from '../DashboardCard';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import { addClass } from '@coopeuch-components/web';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ErrorCard = ({
  titleText,
  descText,
  isCard,
  showButton,
  buttonText,
  onClickButton,
  isButtonDisabled,
  buttonClass,
  largeMessage,
}) => {
  return (
    <Wrapper>
      {isCard ? (
        <div>
          <DashboardCard classes="dashboard-card-shadow">
            <DashboardCard.Body classes="card-error-container">
              <div className="text-align-center bmb-4">
                <LoadIcon src={`${iconPath}ilustracion_error.svg`} />
              </div>
              <div className="text-align-center bmb-2">
                <label className="error-card-title">{titleText}</label>
              </div>
              <div className="row m-0">
                <div className={largeMessage ? 'col-md-2' : 'col-md-3'} />
                <div className={`${largeMessage ? 'col-md-8' : 'col-md-6'} text-align-center`}>
                  <label className="error-card-desc">
                    <div dangerouslySetInnerHTML={{ __html: `<div>${descText}</div>` }} />
                  </label>
                </div>
                <div className={largeMessage ? 'col-md-2' : 'col-md-3'} />
              </div>
              {showButton && (
                <div className="bmt-3 text-button-container">
                  <Button
                    bgColor="#e81d2b"
                    color="white"
                    onClick={onClickButton}
                    disabled={isButtonDisabled}
                    className={addClass(['text-button-style', buttonClass])}
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </DashboardCard.Body>
          </DashboardCard>
        </div>
      ) : (
        <div>
          <div className="text-align-center bmb-4">
            <LoadIcon src={`${iconPath}ilustracion_error.svg`} />
          </div>
          <div className="text-align-center bmb-2">
            <label className="error-card-title">{titleText}</label>
          </div>
          <div>
            <div className="text-align-center">
              <label className="error-card-desc">
                <div dangerouslySetInnerHTML={{ __html: `<div>${descText}</div>` }} />
              </label>
            </div>
          </div>
          {showButton && (
            <div className="bmt-3 text-button-container">
              <Button
                bgColor="#e81d2b"
                color="white"
                onClick={onClickButton}
                disabled={isButtonDisabled}
                className={addClass([buttonClass])}
                shadow
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};

ErrorCard.defaultProps = {
  isCard: true,
  showButton: false,
  buttonText: 'Continuar',
  titleText: '¡Lo sentimos!',
  descText:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
};

export default ErrorCard;
