import React from 'react';
import AlertModal from '../../../../atoms/AlertModal';
import Typography from '../../../../atoms/Typography';
import StepsActions from '../../../../organism/Stepper/StepsActions';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ModalWithoutCuentasVistas = (props) => {
  const { show, backButton, nextButton, closeAction } = props;

  return (
    <AlertModal showModal={show} width="546" onClose={closeAction}>
      <div className="center-xs">
        <div className="row center-xs">
          <img src={`${iconPath}info-desktop.svg`} alt="info-desktop" />
        </div>
        <p className="text-size-xxl text-bold black pt-18 p-10">¡Lo sentimos!</p>
        <div className="row center-xs">
          <div className="col-xs-9">
            <Typography tag="p" align="center" className="text-size-s black pt-8 pb-12">
              <span>Para realizar la liquidación de un Depósito a Plazo, necesitas </span>
              <span className="text-bold">una Cuenta Vista Activa.</span>
            </Typography>
          </div>
        </div>
      </div>
      <StepsActions
        id="liquidacion-sin-cuentas-vistas-modal"
        className="bmt-3 middle-xs"
        backText="No por ahora"
        nextText="Solicitar Cuenta Vista"
        backAction={backButton}
        nextAction={nextButton}
      />
    </AlertModal>
  );
};

export default ModalWithoutCuentasVistas;
