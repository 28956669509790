export const resultadoTexts = {
  PAGE_TITLE: 'Simulador Depósito a Plazo',
  PAGE_CARD_TITLE: 'Resultados de la simulación',
  CARD_BODY_DESC_1: 'Selecciona una de las 3 opciones de inversión a ',
  CARD_BODY_DESC_2: 'Plazo ',
  MONTO_VENCIMIENTO: 'Monto al vencimiento',
  TASA_BASE: 'Tasa base',
  FECHA_VENCIMIENTO: 'Fecha de Vencimiento',
  BOTON_CONTINUE: 'Continuar',
  BOTON_RETURN: 'Volver',
  TOAST_DESC_REAJUSTE: 'La simulación se ajustó al día más próximo. ',
  TOAST_DESC_1: 'El Depósito a Plazo terminaría el ',
  TOAST_DESC_2: ' días',
};

export const detalleTexts = {
  PAGE_TITLE: 'Simulador Depósito a Plazo',
  PAGE_CARD_TITLE: 'Detalles de la simulación',
  BOTON_CAMBIAR: 'Cambiar',
  BOTON_INVERTIR: 'Invertir',
  BOTON_SOLICITAR: 'Solicitar Cuenta Vista',
  BOTON_RETURN: 'Volver',
  MONTO_VENCIMIENTO: 'Monto al vencimiento',
  MONTO_VENCIMIENTO_MOBILE: 'Monto al venc.',
  GANANCIA: 'Ganancia',
  MONTO_INVERTIR: 'Monto a invertir',
  TIPO_DEPOSITO: 'Tipo de depósito',
  FECHA_INICIO: 'Fecha de inicio',
  FECHA_VENCIMIENTO: 'Fecha de vencimiento',
  TITLE_TASA_BASE: 'Tasa base',
  DESC_TASA_BASE: 'Tasa definida basada en un período de 30 días',
  TITLE_TASA_PERIODO: 'Tasa período',
  DESC_TASA_PERIODO:
    'Tasa de interés total para todo el período estipulado en la captación. Resulta de la división de la Tase base por 30 y multiplicándola por el número de la captación.',
  INFO_MESSAGE_TOAST:
    'Para realizar la contratación del Despósito a Plazo, necesitas una Cuenta Vista Coopeuch activa.',
};

export const comprobanteTexts = {
  PAGE_TITLE: 'Simulador Depósito a Plazo',
  OPINION_FLOTANTE: 'Danos tu opinión',
  TOAST_SUCCESS: '¡Felicidades! Tu contratación de Depósito a Plazo fue realizada con éxito.',
  DESC_MAIL: 'Te enviamos copia de este comprobante a tu mail: ',
  DEPOSIT_CONTRATADO: 'Depósito a Plazo contratado el ',
  MONTO_INVERTIDO: 'Monto invertido',
  MONTO_VENCIMIENTO: 'Monto al vencimiento',
  MONTO_VENCIMIENTO_MOBILE: 'Monto al venc.',
  CUENTA_ORIGEN: 'Cuenta de origen',
  CUENTA_DESTINO: 'Cuenta de destino',
  GANANCIA: 'Ganancia',
  FECHA_INICIO: 'Fecha de inicio',
  FECHA_VENCIMIENTO: 'Fecha de vencimiento',
  TIPO_DEPOSITO: 'Tipo de depósito',
  TASA_BASE: 'Tasa base',
  TASA_PERIODO: 'Tasa período',
  BOTON_RETURN: 'Ir a Depósito a Plazo',
};

export const stepperTexts = {
  STEPPER_PASO_1: 'Simulación',
  STEPPER_PASO_2: 'Resultado',
  STEPPER_PASO_3: 'Detalle simulación',
  STEPPER_PASO_4: 'Confirmación',
  STEPPER_PASO_5: 'Comprobante',
};

export const confirmacionTexts = {
  PAGE_TITLE: 'Simulador Depósito a Plazo',
  CARD_CUENTA_ORIGEN: 'Cuenta de origen',
  CARD_CUENTA_DESTINO: 'Cuenta de destino',
  NUMERO_CUENTA: 'Número de cuenta',
  SALDO_DISPONIBLE: 'Saldo disponible:',
  CARD_DATOS_CONTACTO: 'Datos de Contacto',
  CONTACTO_NO_REEMPLAZA: 'Estos datos no reemplazan tus datos actuales en nuestros registros.',
  CONTACTO_CONFIRMA:
    'Para finalizar, confirma tu información de contacto. En caso contrario ingresa un email.',
  CONTACTO_LABEL_EMAIL: 'Email',
  CARD_FIRMA_ELECTRONICA: 'Firma electrónica',
  FIRMA_VALIDA: 'Valida la transacción con tu clave de internet Coopeuch',
  FIRMA_INGRESA_CLAVE: 'Ingresa tu clave de Internet',
  FIRMA_TERMINOS_1: 'He leído y acepto los ',
  FIRMA_TERMINOS_2: 'términos y condiciones',
  FIRMA_TERMINOS_3: ' del Depósito a Plazo a contratar.',
  FIRMA_TERMINOS_MODAL_TITLE: 'Términos y condiciones',
  FIRMA_TERMINOS_MODAL_1:
    'Todos los depósitos serán extendidos en forma nominativa y quedarán en custodia electrónica.',
  FIRMA_TERMINOS_MODAL_2:
    'Las inversiones en depósitos se realizan con cargo a su cuenta vista Coopeuch.',
  FIRMA_TERMINOS_MODAL_3:
    'Monto mínimo de inversión de $20.000 para renovables y $100.000 para fijos.',
  FIRMA_TERMINOS_MODAL_4: 'Plazos de inversión desde 7 a 900 días.',
  FIRMA_TERMINOS_MODAL_5:
    'Para el depósito a plazo fijo online, el abono se realiza el día del vencimiento automáticamente a la cuenta vista Coopeuch.',
  FIRMA_TERMINOS_MODAL_6:
    'Los Depósitos Renovables se podrán cobrar a partir del vencimiento considerando 4 días hábiles.',
  FIRMA_TERMINOS_MODAL_7:
    'Las inversiones que se realicen después de las 18:00 hrs o en días inhábiles bancarios se harán efectivas el día hábil siguiente antes de las 9:00 hrs.',
  FIRMA_TERMINOS_MODAL_8:
    'Los depósitos a plazo tomados en Sucursales, también podrán ser cobrados por Internet, siempre y cuando el titular mantenga Cuenta Vista Coopeuch vigente.',
  FIRMA_TERMINOS_MODAL_9:
    'Los depósitos a plazo renovables tomados en Internet, también podrán ser cobrados en Sucursales.',
  FIRMA_TERMINOS_MODAL_10:
    'Para los depósitos renovables, desde las 18:00 hrs. del último día, si el titular no solicita la liquidación, el depósito será renovado automáticamente por el plazo inicial y la tasa vigente del día del vencimiento.',
  FIRMA_TERMINOS_MODAL_11:
    'Coopeuch no acepta solicitudes de retiro anticipado de Depósitos a Plazo.',
  FIRMA_TERMINOS_MODAL_12:
    'Infórmese sobre la garantía estatal de los depósitos en su cooperativa o en ',
  FIRMA_TERMINOS_MODAL_13: 'www.cmfchile.cl',
  BOTON_CONTINUE: 'Confirmar',
  BOTON_RETURN: 'No por ahora',
  BOTON_TERMINOS_MODAL: 'Aceptar',
  BOTON_RETURN_SIMULACION: 'Volver a Simular',
  TOAST_SALDO_INSUFICIENTE: 'No tienes saldo suficiente. Te invitamos a abonar saldo a tu cuenta.',
  MAIL_INVALIDO: 'Debes ingresar un email válido',
  MAIL_REQUERIDO: 'Campo obligatorio',
  CAMBIO_TASA_MODAL_TITLE: 'Acerca de la simulación',
  CAMBIO_TASA_MODAL_1: 'La ',
  CAMBIO_TASA_MODAL_2: 'tasa de interés de tu simulación ha variado',
  CAMBIO_TASA_MODAL_3: '. Re intenta simular para conocer la vigente.',
  CAMBIO_TASA_MODAL_BUTTON: 'Volver a Simular',
};

export const errorCardText = {
  ERROR_CARD_TITLE: '¡Lo sentimos!',
  ERROR_CARD_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
};
