import styled from "styled-components";

export const Text = styled.p`
color: #383638;
letter-spacing: 0;
text-align: left;

font-size: ${({ textSize }) => `${textSize}px`};
line-height: ${({ size }) => `${size}px`};
font-weight: ${({ weight }) => weight};
width: ${({ width }) => `${width}px`};
height: ${({ height }) => `${height}px`};
padding-right: ${({ paddingRight }) => `${paddingRight}px`};



@media screen and (max-width: 600px) {
  color: #383638;
  letter-spacing: 0;
  text-align: left;
  line-height: 16px;
  font-weight: ${({ weight }) => weight};
  width: ${({ widthmovil }) => `${widthmovil}px`};
  height: 20px;
 
}
`
export const Img = styled.img`
  height: 22px;
  width: 22px;
  object-fit: contain;
  margin-left: auto;
  padding-left: 8px;
  margin-right: 15px;
  position: absolute;
  right: 0;
  cursor: pointer;
  margin-top: -25px;
 
`

export const Textdiv = styled.p`
  display: flex;
  width: ${({ width }) => (width ? `${width}%` : '')};
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  right: 0;
  
  
  .contImagen{
    width:120px;
    height: 120px;
    margin-top: -10px;
    
    margin-bottom: 10px;
   
  }
  .imgWoman{
    height: 100px;
    width: 130px;
    margin-right: 5px;
    margin-left: -5px;
    object-fit: contain;
  
  }
  ::before{
    content: "";
    display: block;
    background: transparent;
    height: 6px;
    width: 6px;
    border-top: ${({ borderTop }) => `${borderTop}`}; 
    border-left: ${({ borderLeft }) => `${borderLeft}`};
    border-right: ${({ borderRight }) => `${borderRight}`};
    border-bottom: ${({ borderBottom }) => `${borderBottom}`};
    position: absolute;
    margin-left: ${({ left }) => `${left}px`};
    margin-top: ${({ top }) => `${top}px`};
  }
  
  @media screen and (max-width: 600px) {
    ::before{
      content: "";
      display: block;
      background: transparent;
      height: 6px;
      width: 6px;
      border-top: ${({ borderTopMovil }) => `${borderTopMovil}`}; 
      border-left: ${({ borderLeftMovil }) => `${borderLeftMovil}`};
      border-right: ${({ borderRightMovil }) => `${borderRightMovil}`};
      border-bottom: ${({ borderBottomMovil }) => `${borderBottomMovil}`};
      position: absolute;
      margin-left: ${({ leftMovil }) => `${leftMovil}px`};
      margin-top: ${({ topMovil }) => `${topMovil}px`};
    }
    
   
  }
  
`
export const Textdivp = styled.p`
  width: 230px;
  height: 120px;
  margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  margin-top: 0px;
  margin-Bottom: 0px;
  padding-right: 20px;
  
  .textCont{
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    width: 140px;
    height: ${({ heightPasos }) => `${heightPasos}px`};
  }

`
export const ContBtn = styled.p`
margin-left: auto;
right: 0;
`
export const ContInv= styled.p`
background: white;
padding: 10px;
width: 190px;
height: 40px;
position: absolute;
margin-left: -230px;
margin-top: 120px;
display: flex;

.textInversiones{
  margin-left: 0px;
}
.imgIner{
  width: 15px;
  margin-top: -25px;
}
`
export const ButtonWithSpinner =styled.button`
 color: ${({ color }) => color};
 background: ${({ background }) => background};
 border: none;
 display: flex;

 .imgBtn{
  width: 6px;
  margin-top: -25px;
  margin-left: 7px;
 }


`

export const Divpasos= styled.p`
margin-bottom: -60px;
margin-left: -92px;
margin-top: -8px;
margin-bottom: -50px;
font-family: Ubuntu-bold;
font-size: 12px;


@media screen and (max-width: 600px) {
  margin-left: -165px;
  margin-top: 10px;
}
`
export const Divfletch=styled.div`
  position: absolute;
  margin-left: auto;
  right: 0;
  margin-top: 33px;
  margin-right: 30px;
`
