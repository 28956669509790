import React, { Fragment, useMemo } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Table from '@coopeuch-components/web/molecules/Table';

import StepsActions from '../../../organism/Stepper/StepsActions';
import Stepper from '../../../organism/Stepper';
import DashboardCard from '../../../organism/DashboardCard';
import PageTitle from '../../../atoms/PageTitle';
import Skeleton from './Skeleton';

import { formatCurrency } from '../../../../utils/functions';

import Wrapper from './styles';
import ErrorCard from '../../../organism/ErrorCard';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const PagarTarjetaMontoAPagarTemplate = (props) => {
  const {
    monto,
    correo,
    loading,
    error,
    detallesComprobante,
    goHome,
    goToTarjetaHome,
    fetchComprobante,
  } = props;

  const steps = useMemo(() => ['Monto a pagar', 'Pago', 'Comprobante'], []);

  return (
    <Wrapper className="w-100">
      <PageTitle
        id="pago-tarjeta-credito-title-page"
        title="Tarjeta de Crédito"
        icon={<LoadIcon src={`${iconPath}mastercard.svg`} color="#383638" />}
      />

      <div className="w-100 margin-after-title content">
        {error ? (
          <div className="row center-xs err-component">
            <div className="col-xs-12">
              <ErrorCard
                buttonClass="button-error-class"
                buttonText="Reintentar"
                showButton
                onClickButton={fetchComprobante}
              />
            </div>
          </div>
        ) : (
          <Fragment>
            <Stepper className="mb-24 start-xs" position={3} steps={steps} />

            {loading ? (
              <Skeleton />
            ) : (
              <Fragment>
                <div className="row modulos-container">
                  <div className="col-xs-12">
                    <Alert bgType="success" iconType="success" show className="pr-0 alert--wrapper">
                      Tu pago de tarjeta de crédito fue realizado con éxito.
                    </Alert>
                  </div>
                  <div className="col-xs-12">
                    <DashboardCard classes="modulo">
                      <DashboardCard.Head title="Comprobante" borderBottom="complete" />
                      <DashboardCard.Body classes="modulo-content">
                        <div className="col-xs-12 col-pl-0">
                          <div className="row">
                            <div className="col-mb-10 col-lg-10 col-sm-12 black">
                              <p className="black text-bold text-size-s pb-8">Monto Pagado</p>
                              <p className="black text-bold text-size-xxl pb-8">
                                {formatCurrency(monto)}
                              </p>
                              <div className="black text-size-s">
                                <span>Te enviamos copia de este comprobante a tu mail: </span>
                                <span className="text-bold">{correo}</span>
                              </div>
                            </div>
                            <div className="col-mb-2 col-lg-2 col-sm-12 end-xs sello-container">
                              <LoadIcon src={`${iconPath}icon_sello.svg`} />
                            </div>
                          </div>
                        </div>
                      </DashboardCard.Body>
                    </DashboardCard>
                  </div>

                  <div className="col-xs-12">
                    <DashboardCard classes="modulo">
                      <DashboardCard.Head title="Detalle del pago" borderBottom="complete" />
                      <DashboardCard.Body classes="modulo-content">
                        <Table
                          className="mb-4 hide-on-mobile"
                          borderColor="trasparent"
                          fullWidth
                          alignContent="left"
                          stripeColor="#F6F3F5"
                          stripeReverse
                        >
                          <Table.Body
                            data={detallesComprobante}
                            renderItems={(item, TR, TD) => (
                              <TR key={`detalle-dap-web-${item.id}`}>
                                <TD className="pt-16 pb-16 pl-16 pr-16 black">{item.name}</TD>
                                <TD className="pt-16 pb-16 pl-16 pr-16 black">{item.value}</TD>
                              </TR>
                            )}
                          />
                        </Table>
                        <div className="hide-on-desktop">
                          <Table
                            className="mb-4 "
                            borderColor="trasparent"
                            fullWidth
                            alignContent="left"
                            stripeColor="#F6F3F5"
                            stripeReverse
                          >
                            <Table.Body
                              data={detallesComprobante}
                              renderItems={(item, TR, TD) => (
                                <TR key={`detalle-dap-mobile-${item.id}`}>
                                  <TD className="pt-16 pb-16 pl-16 pr-16 black">
                                    <p>{item.name}</p> <p className="pt-4">{item.value}</p>
                                  </TD>
                                </TR>
                              )}
                            />
                          </Table>
                        </div>
                      </DashboardCard.Body>
                    </DashboardCard>
                  </div>
                </div>

                <StepsActions
                  backText="Ir a home"
                  backAction={goHome}
                  nextAction={goToTarjetaHome}
                  nextText="Ir a Tarjeta de Crédito"
                  id="pago-tarjeta-credito"
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
};

export default PagarTarjetaMontoAPagarTemplate;
