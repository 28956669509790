import React, { useEffect, useState, useCallback} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CertProdVigentesTemplate from '../../components/templates/CertificadosProdVigentes';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';

import { certProdVigentesActions } from '../../../application/actions/certificadosProdVigentes';
import { downloadPDFBase64 } from '../../utils/functions';

import { registerGA } from '../../utils/metrics';
import logger from '../../utils/logger';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CertificadosProdVigentes = () => {
  const [showMessage, setShowMessage] = useState({ title: '', type: '', show: false });

  const certPdf = useSelector(
    (state) => state.entities?.certificadosProdVigentes?.certProdVigentes?.pdf
  );
  const certPdfError = useSelector(
    (state) => state.entities?.certificadosProdVigentes?.certProdVigentes?.error
  );
  const certPdfErrorCode = useSelector(
    (state) => state.entities?.certificadosProdVigentes?.certProdVigentes?.code
  );
  const isLoadingCert = useSelector((state) => state.ui?.loaders?.isLoadingCertProdVigentes);

  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));

  const dispatch = useDispatch();

  useEffect(() => {
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CERTIFICADOS_PRODUCTOSVIGENTES',
      informacion_adicional: {},
    });
  }, [baseRut]);

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/certificados/productos-vigentes',
    });
  }, [registerGA]);

  useEffect(() => {
    if (certPdf) {
      downloadPDFBase64(certPdf, 'Certificado_Productos_Vigentes');
    }
  }, [certPdf]);

  const handleDownloadPDF = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(certProdVigentesActions.fetchPDFCertProdVigentes());

      registerGA({
        event: 'eve',
        'eve-acc': '/tef/certificados/productos-vigentes',
        'eve-cat': 'Click',
        'eve-lab': 'Generar certificado',
      });

      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CERTIFICADOS_PRODUCTOSVIGENTES_GENERARCERTIFICADO',
        informacion_adicional: {},
      });

      // Si hay un error antes de intentar descargar, manejar aquí
      if (!certPdf && certPdfError) {
        handleCertDownloadError();
      }
    },
    [dispatch, baseRut]
  );

  const handleCertDownloadError = () => {
    let message = { title: '', type: '', show: true };
    let gaEventErrorLabel = '';

    if (certPdfErrorCode) {
      switch (certPdfErrorCode) {
        case 1:
          message.title =
            'Se ha producido un error al generar el certificado. Vuelve a intentarlo más tarde.';
          message.type = 'error';
          gaEventErrorLabel = 'Descarga';
          break;
        case 2:
          message.title =
            'Usted actualmente no posee productos para solicitar para este certificado';
          message.type = 'info';
          gaEventErrorLabel = 'Sin productos';
          break;
        default:
          message.show = false; // No mostrar mensaje si el código de error no está manejado específicamente
      }
    } else if (!certPdf) {
      message.title = 'El certificado no se encuentra disponible. Vuelve más tarde.';
      message.type = 'error';
      gaEventErrorLabel = 'Sin servicio';
    }

    // Registrar evento GA para el error específico, si procede
    if (gaEventErrorLabel) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/certificados/productos-vigentes',
        'eve-cat': 'Error',
        'eve-lab': gaEventErrorLabel,
      });
    }

    setShowMessage(message);
  };

  // Observar cambios en certPdfError y certPdfErrorCode para manejar errores después de intentar descargar
  useEffect(() => {
    if (certPdfError || certPdfErrorCode) {
      handleCertDownloadError();
    }
  }, [certPdfError, certPdfErrorCode]);

  return (
    <>
      <PageTitle
        title="Certificado de Productos Vigentes"
        icon={<LoadIcon src={`${iconPath}deal.svg`} color="black" />}
      />
      <CertProdVigentesTemplate
        handleDownloadPDF={handleDownloadPDF}
        showMessage={showMessage}
        isLoadingCert={isLoadingCert}
      />
    </>
  );
};

export default CertificadosProdVigentes;
