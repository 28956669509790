import styled from 'styled-components';

export const CustomList = styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 100%;
      position: absolute;
      content: '';
      height: 6px;
      width: 6px;
      display: inline-block;
      left: -16px;
      top: 6px;
    }
  }
`;

export const CustomStyles = styled.div`
.line_height_cero{
  line-height: 16px;
}
.line_height_card{
  line-height: 18px;
}
.padding_header_card{
  padding: 19px 32px;
}
.adjust_icon{
  float: left;
}
.text_card{
  font-family: 'Ubuntu-Bold', Helvetica, sans-serif;
  font-size: 18px;
  margin-left: 8px;
  vertical-align: bottom;
}
`
