import styled from 'styled-components';

export const MapWrapper = styled.div`
  height: 500px;
`;

export const Marker = styled.div`
  position: 'relative';
  height: 140px;
  max-width: 100%;
  overflow-y: auto;
  padding: 15px;
  line-height: 15px;
  background-color: white;
  border: 1px solid black;
`;

export const Icon = styled.div`
  margin: 10px auto auto 0;
  .display-content {
    display: contents;
  }
`;
export const TimeText = styled.div`
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  .container_alert_Bottom {
    /* padding: 0px 16px; */
    margin-top: 26px;
  }
`;

export default Container;
