import styled from 'styled-components';

const Wrapper = styled.div`
  .contentSMS {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__text {
      width: 100%;
      max-width: 470px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Ubuntu-Regular';
      margin-top: 11px;
      margin-bottom: 15px;
    }
    &__btn {
      margin-top: 24px;
      width: 100%;
      max-width: 300px;
      margin-bottom: 20px;
      font-size: 14px;
      font-family: 'Ubuntu-Medium';
    }
  }
`;

export const CardFooter = styled.div`
  border-top: 2px solid #f4f4f4;
  padding-top: 18px;
  padding-bottom: 18px;
  text-align: center;
  button {
    color: #f42534;
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
    border-bottom: 1px solid #f42534;
  }
`;

export default Wrapper;
