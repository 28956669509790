import React from 'react';
import { Input } from '@design-system-coopeuch/web'

const NumericInput = ({ value, onChange, ...restProps }) => {
  // Esta función se encarga de asegurar que solo se ingresen caracteres numéricos
  const handleInputChange = (text) => {
    const numericValue = text.replace(/[^0-9]/g, ''); // Filtrar caracteres no numéricos
    onChange(numericValue); // Llamar a la función onChange original con el nuevo valor filtrado
  };

  return (
    <Input
      value={value}
      onChange={handleInputChange} // Usar la nueva función handleInputChange
      {...restProps} // Pasar las demás props sin cambios
    />
  );
};

export default NumericInput;
