import styled from 'styled-components';

const Wrapper = styled.div``;

export const Card = styled.div`
  background-color: ${({ active }) => (active ? '#007db7' : '#D5D0D5')};
  border-radius: 4px;
  width: 296px;
  margin: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  box-shadow: 0 2px 4px 0 rgba(31, 30, 30, 0.35);

  .countdown-card-label {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    font-family: 'Ubuntu-Medium';
  }

  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
`;

export default Wrapper;
