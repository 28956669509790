const defaultRequest = {
  id: 500,
  title: '¡Lo sentimos!',
  message:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
};

export const errClaveInternet = {
  1: {
    errorGeneral: true,
  },
  2: {
    id: 2,
    title: '¡Lo sentimos!',
    message: 'La contraseña ingresada es incorrecta.<br> Por favor vuelve a intentar',
    buttonTitle: 'Reintentar',
    showButton: true,
  },
  3: {
    id: 3,
    title: '¡Lo sentimos!',
    message:
      'La contraseña ingresada es incorrecta. Por favor vuelve a intentar.<br> Recuerda que <b>al tercer intento erróneo</b>, tu clave será bloqueada.',
    buttonTitle: 'Reintentar',
    showButton: true,
  },
  4: {
    id: 4,
    title: '¡Clave bloqueada!',
    message:
      'Por tu seguridad, hemos bloqueado tu clave. Si lo deseas<br> puedes volver a cambiar tu clave.',
    buttonTitle: 'Cambiar clave',
    showButton: true,
  },
};

export const errSMSFactor = {
  default: defaultRequest,
  1: {
    id: 1,
    title: '¡Lo sentimos!',
    message: `<p class='black mb-8'>El código SMS ingresado es incorrecto.</p> <p class='black'>Por favor, vuelve a intentar.</p>`,
    hasError: true,
    buttonText: 'Reintentar',
    showInputInError: false,
  },
  4: {
    id: 4,
    title: '¡Lo sentimos!',
    message: `<p class='black mb-8'>El código SMS ingresado es incorrecto. Por favor, vuelve a intentar.</p> <div class='black'>Recuerda que <b> al tercer intento erróneo</b>, tu clave será bloqueada </div>`,
    hasError: true,
    buttonText: 'Reintentar',
    showInputInError: false,
  },
  2: {
    id: 2,
    title: '¡Lo sentimos!',
    message: `<span class='lh-1_5 black'>El código SMS ha sido bloqueado. Por favor comunicarse con nuestro contact center al <b>600 200 1200.</b></span>`,
    hasError: true,
    hideButton: true,
    showInputInError: false,
  },
  3: {
    id: 3,
    title: '¡Se agotó el tiempo!',
    message: `<p class='lh-1_5 black'>Tienes un tiempo limitado para autorizar, inténtalo</p><p class='lh-1_5 black'> nuevamente.</p>`,
    hasError: true,
    buttonText: 'Reintentar',
    showInputInError: false,
  },
};

export default defaultRequest;
