import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { Content, Info, Title, Description, DocumentBox, TextDocument } from './styles.js';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardContrato = ({ nombreDocumento, titulo, descripcion, downloadDocumentPDF }) => {
  return (
    <Content>
      <LoadIcon size={22} src={`${iconPath}note.svg`} color="#1F1E1E" />
      <Info>
        <Title>{titulo}</Title>
        <Description>{descripcion}</Description>
        <DocumentBox onClick={() => downloadDocumentPDF(nombreDocumento)}>
          <LoadIcon size={22} src={`${iconPath}download_arrow.svg`} />
          <TextDocument>Descargar contrato</TextDocument>
        </DocumentBox>
      </Info>
    </Content>
  );
};

export default CardContrato;
