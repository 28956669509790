import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Stepper, { RenderStep } from '../../../components/organism/Stepper';
import Error from '../../../components/atoms/Error';

import PaymentContainer from './Payment';
import ReceiptContainer from './Receipt';

function Payment({ onGoBack, onPaymentSuccess }) {
  const [error, setError] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const onError = () => {
    setError(true);
  };

  const onCompletePayment = () => {
    setCurrentStep(currentStep + 1);
  };

  return error ? (
    <Error
      className="bmt-3 bmt-md-4"
      title="¡Lo sentimos!"
      text="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
    />
  ) : (
    <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
      <Stepper className="start-xs" steps={['Pago', 'Comprobante']} position={currentStep + 1} />
      <RenderStep step={currentStep}>
        <PaymentContainer
          onError={onError}
          onGoBack={onGoBack}
          onCompletePayment={onCompletePayment}
        />
        <ReceiptContainer onPaymentSuccess={onPaymentSuccess} />
      </RenderStep>
    </div>
  );
}

Payment.propTypes = {
  onGoBack: PropTypes.func.isRequired,
  onPaymentSuccess: PropTypes.func.isRequired,
};

export default Payment;
