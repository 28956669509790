import React, { useEffect } from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import ButtonBack from '../../ButtonBack';
import Countdown from '../CountDown';
import TimerPass from '../TimerButton/TimerPass';
import NotPassCoopeuch from '../NotPassCoopeuch';
import ModalPassCoopeuchError from '../../Error/ModalError/ModalPassCoopeuchError';
import SpinnerLoader from '../../../../atoms/SpinnerLoader';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
// Styles
import Wrapper, { CardFooter } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardPassCoopeuch = ({
  backToActivacion,
  timerValue,
  errorTimeOut,
  validateSMS,
  handleClickAuthorizePassCoopeuch,
  counterActive,
  setCounterActive,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  retryTimeOutPass,
  validatePassCoopeuch,
  IsValidatePassCoopeuch,
  IsLoading,
  isVisibleModalError,
  handleClickCloseModalError,
  isAvailableFactor,
  handleSwitchMethods,
  isAvailableFactorTC,
  handleClickDownloadApp
}) => {
  useEffect(() => {
    console.log('passCoopeuch activo ?', IsValidatePassCoopeuch);
  });

  return (
    
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Autorización con PassCoopeuch
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          {isAvailableFactor === false ? (
            <NotPassCoopeuch handleClickDownloadApp={handleClickDownloadApp}/>
          ) : (
            <div className="contentSMS">
              {errorTimeOut === false && (
                <Countdown
                  ilustration="pass_coopeuch"
                  withCountdownAnimation={counterActive}
                  timer={timerValue}
                  errorTimeOut={errorTimeOut}
                  validateSMS={validateSMS}
                />
              )}
              {errorTimeOut === true && (
                <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
              )}
              {IsValidatePassCoopeuch?.data?.codigo === 8 && (
                <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
              )}
              {IsValidatePassCoopeuch?.data?.codigo === 7 && (
                <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={180} />
              )}
              {errorTimeOut === true && <p className="contentSMS__title">¡Se agotó el tiempo!</p>}
              {IsValidatePassCoopeuch?.data?.codigo === 8 && (
                <p className="contentSMS__title">¡Lo sentimos!</p>
              )}
              {IsValidatePassCoopeuch?.data?.codigo === 7 && (
                <p className="contentSMS__title">¡Lo sentimos!</p>
              )}
              {errorTimeOut === false && (
                <p className="contentSMS__text">
                  {counterActive
                    ? 'Ingresa clave PassCoopeuch antes que acabe el tiempo para autorizar la operación.'
                    : 'Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.'}
                </p>
              )}

              {errorTimeOut === true && (
                <p className="contentSMS__text">
                  Tienes un tiempo limitado para autorizar, inténtalo nuevamente.
                </p>
              )}

              {IsValidatePassCoopeuch?.data?.codigo === 8 && (
                <p className="contentSMS__text">
                  La autorización desde tu celular ha sido rechazada, <br /> intenta realizar la
                  autorización nuevamente.
                </p>
              )}
              {IsValidatePassCoopeuch?.data?.codigo === 7 && (
                <p className="contentSMS__text">
                  PassCoopeuch está bloqueada. Regístrate nuevamente
                  <br /> en la App o autoriza con Tarjeta de Coordenadas.
                </p>
              )}
              {counterActive === true && (
                <TimerPass
                  {...{
                    counterActive,
                    setErrorTimeOut,
                    setFactorErrorTimeOut,
                    validatePassCoopeuch,
                    setCounterActive,
                  }}
                />
              )}
              {counterActive === false && errorTimeOut === false && (
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  fontSize={14}
                  className="contentSMS__btn"
                  onClick={handleClickAuthorizePassCoopeuch}
                >
                  Autorizar con PassCoopeuch
                </Button>
              )}
              {errorTimeOut === true && (
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  className="contentSMS__btn"
                  onClick={retryTimeOutPass}
                >
                  Reintentar
                </Button>
              )}
              {IsValidatePassCoopeuch?.data?.codigo === 8 && (
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  className="contentSMS__btn"
                  onClick={retryTimeOutPass}
                >
                  Reintentar
                </Button>
              )}
              {IsLoading === true && <SpinnerLoader />}
            </div>
          )}

          <ModalPassCoopeuchError
            visibleModal={isVisibleModalError}
            handleClickCloseModalError={handleClickCloseModalError}
          />
        </CardBox.Body>
        {isAvailableFactorTC === false ? null : (
          <CardFooter>
            <button type="button" onClick={() => handleSwitchMethods('passcoopeuch')}>
              Quiero autorizar con Tarjeta de Coordenadas
            </button>
          </CardFooter>
        )}
      </CardBox>
      <div className="row">
        <div className="col-md-12">
          <ButtonBack
            onClick={() => backToActivacion(activacionTexts.BUTTON_RETURN, 'passcoopeuch')}
            link="/ecd/seguridad/activacion-tarjetas"
            text={activacionTexts.BUTTON_RETURN}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CardPassCoopeuch;
