import Styled from 'styled-components';

export const CheckboxContainer = Styled.div`
  input, div:last-child {
    margin-left: 0
  }
`
export const Wrapper = Styled.div`
  .alert-deposito-contratacion-monedero {
    height:unset;
  }
`