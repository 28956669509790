const initState = {
  edad: 0,
  numeroCliente: 0,
  genero: '',
  rut: '',
  primerNombre: '',
  segundoNombre: '',
  apellidoPaterno: '',
  apellidoMaterno: '',
  correo: '',
  profesion: '',
  telefono: 0,
  rol: '',
  performance: null,
  errorDatosPersonales: false,
  success: false,
  errorLogPersona: '',
  errLog: null,
  personaObtener: { success: false, error: false, errorCode: '', data: null, date: null },
  direccionObtener: { success: false, error: false, errorCode: '', data: null, date: null },
  datosContactoObtener: { success: false, error: false, errorCode: '', data: null, date: null },
  tCoordenadasDesafio: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
  tCoordenadasDesafioResult: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
  passCoopeuchDesafio: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
  passCoopeuchDesafioResult: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
  datosContactoUpdate: {
    success: false,
    error: false,
    data: null,
    errorCode: '',
    date: null,
  },
  dComercialUpdate: {
    success: false,
    error: false,
    data: null,
    errorCode: '',
    date: null,
  },
  envioEmail: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
  habilitadoCelular: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
  habilitadoCorreo: {
    success: false,
    error: false,
    errorCode: '',
    data: null,
    date: null,
  },
};

export default initState;
