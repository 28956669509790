import styled from 'styled-components';

export const Wrapper = styled.div`
  .cuotas-container {
    min-height: 178px;
    padding: 16px 32px 16px 32px !important;
  }
  .cuotas-title {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }
  .cuotas-icon-container {
    align-self: center;
  }
  .cuotas-value-fecha {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    font-family: 'Ubuntu-Medium';
  }
  .cuotas-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .coutas-margin-text {
    margin-bottom: 13px;
  }

  .cuotas-dashboard-card {
    border-radius: 4px;
  }

  .cuotas-dashboard-card section {
    border-radius: 4px;
  }

  .cuotas-texto-direccion {
    text-align: right;
  }

  @media screen and (max-width: 1028px) {
    .cuotas-container {
      padding: 16px 16px 16px 16px !important;
    }
    .cuotas-button {
      width: 100%;
    }
    .cuotas-texto-direccion {
      text-align: left;
    }
  }
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const DetailWrapper = styled.div`
  button {
    text-decoration: underline;
  }
`;

export default Wrapper;
