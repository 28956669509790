import styled from 'styled-components';

const InputContainer = styled.div`
  padding-left: 79px;
  padding-right: 79px;
  margin-bottom: 16px;

  & small {
    display: none;
  }

  .factor-coordenadas-input {
    font-size: 30px;
    text-align: center;
    border-radius: 2px;
    height: 48px;
  }

  .factor-coordenadas-input small {
    display: none;
  }

  .factor-coordenadas-label-container {
    margin-bottom: 8px;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;

export const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    .factor-button-style {
      width: 246px ;
    }
  }
`;

export default InputContainer;
