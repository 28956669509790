import styled from 'styled-components';

export const AccountWrapper = styled.div`
  .select-skeleton {
    height: 48px;
    width: 80%
  }
`

export const AlertWrapper = styled.div`
  .alert-warning {
    height: auto;
  }
`;

export default styled.div`
 color: #383638
`
