/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { registerGA } from '../../../utils/metrics';
import { isValidEmail } from '../../../utils/formatters';
import logger from '../../../utils/logger';

// Actions
import { personaActions } from '../../../../application/actions/persona';

// Containers
import Factores from '../Factores';

// Templates
import DatosDeContacto from '../../../components/templates/MiPerfil/DatosDeContacto';
import DatosContactoEmpleador from '../../../components/templates/MiPerfil/DatosContactoEmpleador';
// import { Button } from '@design-system-coopeuch/web'
import Button from '@coopeuch-components/web/atoms/Button';
import DashboardCard from '../../../components/organism/DashboardCard';
import AlertModal from '../../../components/atoms/AlertModal';
import Alert from '@coopeuch-components/web/atoms/Alert';
import DescargaYUsa from './../../../components/templates/MiPerfil/DescargaYUsa/index'
import TerminosYCondiciones from '../../../components/templates/MiPerfil/TerminosYCondiciones';
// const factores = {
//   tarjetaCoordenadas: 2.1,
//   passCoopeuch: 2.2,
// };

const Index = ({
  dataPersona,
  datosContacto,
  setDatosContacto,
  loadingDatosContacto,
  loadingPersona,
  setIsReloadDContacto,
  hasDisabledButton,
  setHasDisabledButton,
  evaluatePhone,

  loadingDirecciones,
  direccionPersona,
  direccionComercial,
  setDireccionComercial,
  setIsReloadDComercial,

  dataRegiones,
  isloadingDRegiones,

  dataComunas,
  isLoadingDComunas,

  loadingHabilitadoCell,
  habilitadoCell,
  loadingHabilitadoEmail,
  habilitadoEmail,

  // Flags mensaje exito UI
  userUpdate,
  setUserUpdate,

  // Factores
  loadingFactores,
  factoresSeguridad,
  setReloadFactores,
  factorError,
  setFactorError,
  desafio,
  setDesafio,
  sendDesafio,
  setSendDesafio,
  resultDesafio,
  setResultDesafio,

  // Counter
  counterActive,
  setCounterActive,
  timeOut,
  setTimeOut,

  handleCleanStates,
  hasSuccess,
  setHasSuccess,
  termsAndCondicions,
  setTermsAndCondicions,

  // Ref
  datosContactoRef,
  direcComercialRef,
  handleShowModalFactors,
  showModalFactors,
  datosAnteriores,
  dataDireccionAnterior,
  setFlagReintentar,
  flagReintentar,
  alertState,
  setAlertState,
}) => {
  const [regionSocioCode, setRegionSocioCode] = useState(null);
  const [regionSocioDescripcion, setRegionSocioDescripcion] = useState(null);
  const [updateRegion, setUpdateRegion] = useState(false);

  const [unlockInputs, setUnlockInputs] = useState(true);

  const [comunaSocioRegion, setComunaSocioRegion] = useState(null);
  const [comunaSocioCode, setComunaSocioCode] = useState(null);
  const [comunaSocioDescripcion, setComunaSocioDescripcion] = useState(null);
  const [isComunaLoading, setIsComunaLoading] = useState(true);



  //changes
  const [onChangeFieldCelular, setEditFieldCelular] = useState('');
  const [onChangeFieldCalle, setEditFieldCalle] = useState('');
  const [onChangeFieldNumero, setEditFieldNumero] = useState('');
  const [onChangeFieldComuna, setEditFieldComuna] = useState('');
  const [onChangeFieldRegion, setEditFieldRegion] = useState('');
  const [onChangeFieldTelComercial, setEditFieldTelComercial] = useState('');
  const [onChangeFieldParticular, setEditFieldParitucular] = useState('');
  const [onChangeFieldDepto, setEditFieldDpto] = useState('');
  const [onChangeFieldCorreo, setEditFieldCorreo] = useState('');

  // PassCoopeuch
  const [passLock, setPassLock] = useState(false);
  const [intentos, setIntentos] = useState(3);
  const [askServicePass, setAskServicePass] = useState(false);
  const [btnSave, setBtnSave] = useState('')
  const [lockAutorizador, setLockAutorizador] = useState(true)
  // Actualizacion de datos de socio

  // Flags para ejecutar dispatch de actualizacion
  const [updateDContacto, setUpdateDContacto] = useState(false);
  const [updateDComercial, setUpdateDComercial] = useState(false);

  // Metodos Redux
  const dispatch = useDispatch();

  const storageTCDesafioObtener = useSelector(
    (store) => store.entities.persona.tCoordenadasDesafio,
    shallowEqual
  );

  const storagePassDesafioObtener = useSelector(
    (store) => store.entities.persona.passCoopeuchDesafio,
    shallowEqual
  );

  const storagePassDesafioResult = useSelector(
    (store) => store.entities.persona.passCoopeuchDesafioResult,
    shallowEqual
  );

  const loadingDesafioObtener = useSelector(
    (store) => store.ui.loaders.isLoadingDesafioYValidarFactorSeguridad,
    shallowEqual
  );
  
  const persona = useSelector((store) => store.entities.persona, shallowEqual);

  const loadingDesafioEnviar = useSelector(
    (store) => store.ui.loaders.isLoadingDesafioYValidarFactorSeguridad,
    shallowEqual
  );

  const storageTCsDesafioResult = useSelector(
    (store) => store.entities.persona.tCoordenadasDesafioResult,
    shallowEqual
  );

  const storageDContactoUpdate = useSelector(
    (store) => store.entities.persona.datosContactoUpdate,
    shallowEqual
  );

  const storageDComercialUpdate = useSelector(
    (store) => store.entities.persona.dComercialUpdate,
    shallowEqual
  );

  const storageEnvioEmail = useSelector((store) => store.entities.persona.envioEmail, shallowEqual);

  const onHandleCleanInputs = () => {
    //setResultDesafio(false);
    setHasDisabledButton(false);
    setHasSuccess(false);
    setUserUpdate({ dContacto: false, dComercial: false });
    //setSendDesafio({ desafio1: '', desafio2: '', desafio3: '' });
  };

  const onChangePhones = (e) => {
    let { value } = e.target;
    const { name } = e.target;
    const space = ' ';
    const chileDigit = '+56';
    const celularDigit = '9';
    setLockAutorizador(false);
    const strSliceOne = (str, numOne, numTwo) => str.slice(numOne, numTwo);
    const strSpliceTwo = (str, num) => str.slice(num);
    const dataKey = e.nativeEvent.data;
    if (isNaN(dataKey)) return;
    if (
      (name === 'telefonoComercial' ||
        name === 'telefonoCelular' ||
        name === 'telefonoParticular') &&
      value.length > 13
    )
      return;
    if(name === 'telefonoComercial'){
      setEditFieldTelComercial('telefonoComercial')
    }
    if(name === 'telefonoCelular'){
      setEditFieldCelular('telefonoCelular')
    }
    if(name === 'telefonoParticular'){
      setEditFieldParitucular('telefonoParticular')
    }
    if (value.substr(0, 1) === '0') return;

    /**
     * TODO: Refactor this Function
     */
    if (name === 'telefonoCelular') {
      if (value.length > 1 && !datosContacto?.telefonoCelular?.includes('+56')) {
        value = `+56${value}`;
      }
      if (value.length > 4 && !datosContacto?.telefonoCelular[3]?.includes(' ')) {
        value = strSliceOne(value, 0, 3) + space + strSpliceTwo(value, 3);
      }
      if (value.length > 5 && !datosContacto?.telefonoCelular[4]?.includes('9')) {
        value = strSliceOne(value, 0, 4) + celularDigit + strSpliceTwo(value, 4);
      }
    }

    /**
     * TODO: Refactor this Function
     */
    if (['telefonoParticular'].includes(name)) {
      if (value.length > 1 && !datosContacto?.telefonoParticular?.includes('+56')) {
        value = chileDigit + value;
      }
      if (value.length > 4 && !datosContacto?.telefonoParticular[3]?.includes(' ')) {
        value = strSliceOne(value, 0, 3) + space + strSpliceTwo(value, 3);
      }
    }

    /**
     * TODO: Refactor this Function
     */
    if (['telefonoComercial'].includes(name)) {
      if (value.length > 1 && !datosContacto?.telefonoComercial?.includes('+56')) {
        value = chileDigit + value;
      }
      if (value.length > 4 && !datosContacto?.telefonoComercial[3]?.includes(' ')) {
        value = strSliceOne(value, 0, 3) + space + strSpliceTwo(value, 3);
      }
    }

    setDatosContacto({ ...datosContacto, [name]: value });
    setUpdateDContacto(true);

    // Banderas para boton envio
    onHandleCleanInputs();
  };

  const onChangeEmail = (e) => {
    const { value, name } = e.target;
    if (name === 'correo' && value.length > 255) return;
    setDatosContacto({ ...datosContacto, [name]: value });
    setEditFieldCorreo(name)
    setUpdateDContacto(true);
    setLockAutorizador(false);
    // Banderas para boton envio
    onHandleCleanInputs();
  };

  const onChangeCheckBox = () => {
    setTermsAndCondicions((prevState) => !prevState);

    // Banderas para boton envio
    onHandleCleanInputs();
  };

  const onChangeDirecComercial = (e) => {
    const { value, name } = e.target;
    const dataKey = e.nativeEvent.data;
    setLockAutorizador(false);
    if (name === 'numero' && isNaN(dataKey)) return;
    setDireccionComercial((prevstate) => ({ ...prevstate, [name]: value }));
    setUpdateDComercial(true);
    if(name === 'numero'){
      setEditFieldNumero(name)
    }else if(name === 'calle'){
      setEditFieldCalle(name)
    }else if(name === 'departamento'){
      setEditFieldDpto(name)
    }
    
    // Banderas para boton envio
    onHandleCleanInputs();
  };

  const onHandleRegion = (e) => {
    const { value } = e.target;
    setRegionSocioCode(value);
    setDireccionComercial((prevState) => ({ ...prevState, codigoRegion: value }));

    setUpdateRegion(true);
    setUpdateDComercial(true);
    setEditFieldRegion('region')
    setLockAutorizador(false);
    // setUserUpdate({ ...userUpdate, dComercial: true });
    // Banderas para boton envio
    onHandleCleanInputs();
  };

  const onHandleComuna = (e) => {
    const { value } = e.target;
    setComunaSocioCode(value);

    setDireccionComercial((prevState) => ({ ...prevState, codigoComuna: value }));

    setEditFieldComuna('comuna')
    setUpdateRegion(false);
    setUpdateDComercial(true);
    setLockAutorizador(false);
    // setUserUpdate({ ...userUpdate, dComercial: true });
    // Banderas para boton envio
    onHandleCleanInputs();
  };
  const handleReloadFactores = () => setReloadFactores(true);

  const onHandleObtenerDesafio = (id) => {
    setFactorError(null);
    setDesafio({ loader: true });
    if(flagReintentar.status){
      registerGA({
        'event': 'eve',
        'eve-acc' : '/tef/editar-datos',
        'eve-cat' : 'Click',
        'eve-lab' : `${flagReintentar.method}`
      });
      setFlagReintentar({status: false, method: ''})
    }
    if (id === 2.1) {
      dispatch(personaActions.fetchTCoordenadasDesafioObtener());
      registerGA({
        'event': 'eve',
        'eve-acc': '/tef/editar-datos-personales',
        'eve-cat': 'Click',
        'eve-lab': 'Autorizar con Tarjeta Coordenadas',
      });
      registerGA({
        'event': 'eve',
        'eve-acc' : '/tef/editar-datos',
        'eve-cat' : 'Click',
        'eve-lab' : 'Modal - Autorizar con Tarjeta de Coordenas paso 1'
      });
    }

    if (id === 2.2 && passLock === true) {
      handleReloadFactores();
    }

    if (id === 2.2 && passLock === false) {
      dispatch(personaActions.fetchGetPassCoopeuchDesafio());
      setIntentos(intentos - 1);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/editar-datos-personales',
        'eve-cat': 'Click',
        'eve-lab': 'Autorizar con PassCoopeuch',
      });
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/editar-datos',
        'eve-cat': 'Mensaje',
        'eve-lab': 'Modal - Autorizar con PassCoopeuch',
      });
    }

  };

  const onHandleSetDesafio = (e) => {
    const { value, name } = e.target;
    setSendDesafio({ ...sendDesafio, [name]: value });
  };

  const onHandleSendDesafio = () => {
    dispatch(personaActions.fetchTCoordenadasDesafioEnviar(sendDesafio));
    setCounterActive(false);
    setFactorError(null);
    //revisar
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/editar-datos',
      'eve-cat' : 'Click',
      'eve-lab' : 'Modal - Autorizar con Tarjeta de Coordenas paso 2'
    });
  };

  const cleanToSendUpdate = (x) => x?.replace('+56 ', '') * 1;

  const handleSendUpdateDContacto = () => {
    dispatch(
      personaActions.fetchEditDatosContacto({
        telefonoCelular: cleanToSendUpdate(datosContacto.telefonoCelular),
        telefonoParticular: cleanToSendUpdate(datosContacto.telefonoParticular),
        telefonoComercial: cleanToSendUpdate(datosContacto.telefonoComercial),
        correo: datosContacto.correo,
        track: sendDesafio.track,
      })
    );
  };

  const handleSendUpdateDComercial = () => {
    dispatch(
      personaActions.fetchDComercialUpdate({
        calle: direccionComercial.calle,
        comuna: direccionComercial.codigoComuna,
        departamentoOCasa: direccionComercial.departamento,
        numero: direccionComercial.numero,
        region: direccionComercial.codigoRegion,
        tipoDireccion: 'COM',
        track: sendDesafio.track,
      })
    );
  };
  /**
   * Obtener desafio Passcopeuch y trackId
   */
  useEffect(() => {
    if (storagePassDesafioObtener.success && storagePassDesafioObtener.data) {
      setDesafio({
        loader: false,
        ...storageTCDesafioObtener.data,
      });
      setSendDesafio({ track: storagePassDesafioObtener.data.track });
      setCounterActive(true);
      // ??
      setTimeOut(false);

      setAskServicePass(true);
      // setFactorError(false);
    }
    if (storagePassDesafioObtener.error) {
      setFactorError('0');
    }
  }, [storagePassDesafioObtener]);


  /**
   *  Obtener desafio Passcopeuch
   */
  useEffect(() => {
    let temp;
    if (askServicePass === true && sendDesafio.track) {
      temp = setInterval(
        () => dispatch(personaActions.fetchSendPassCoopeuchDesafio({ track: sendDesafio.track })),
        3000
      );
    }
    return () => clearInterval(temp);
  }, [askServicePass, sendDesafio.track]);

  /**
   *  Obtener Resultado desafio Passcopeuch
   */
  //  OK(0),
  //  BLOQUEADO(7),
  //  CANCELADO(8),
  //  EN_ESPERA(25),
  //  OPERATION_ID_NULL(61),
  //  CODIGO_INVALIDO(50),
  const handleAsyncSaveChanges = async () => {
    await handleSaveChanges();
  };
  useEffect(() => {
    if (storagePassDesafioResult.success) {
      if (storagePassDesafioResult?.data?.codigo === 0) {
        setFactorError(null);
        setDesafio({ loader: false });
        setAskServicePass(false);
        setCounterActive(false);
        setTimeOut(false);

        setResultDesafio(true);
        setHasSuccess(true);
        handleShowModalFactors(false)
        setAlertState('success')
        setBtnSave('success')
        setUnlockInputs(false)
        handleAsyncSaveChanges();
        console.log('operacion exitosa PassDesafioResult');
      }
      if (storagePassDesafioResult?.data?.codigo === 7) {
        setFactorError('7');
        setPassLock(true);
        setDesafio({ loader: false });
        setAskServicePass(false);
        registerGA({
          'event': 'eve',
          'eve-acc' : '/tef/editar-datos',
          'eve-cat' : 'Error',
          'eve-lab' : 'PassCoopeuch - Bloqueada'
        });
        console.log('PassCoopeuch está bloqueada.');
      }
      if (storagePassDesafioResult?.data?.codigo === 8) {
        setFactorError('8');
        setDesafio({ loader: false });
        setAskServicePass(false);
        registerGA({
          'event': 'eve',
          'eve-acc' : '/tef/editar-datos',
          'eve-cat' : 'Error',
          'eve-lab' : 'PassCoopeuch - Autorización rechazada'
        });
        console.log('operacion cancelada PassDesafioResult');
      }

      if (storagePassDesafioResult?.data?.codigo === 25) {
        /**
         * Se declara condicion de error (setFactorError('1')) cuando se vence el timer,
         * dentro del componente CountdownNumbers.
         *  */
        console.log('Esperando que se realice alguna interacción con la aplicación');
      }
    }
    if (storagePassDesafioResult.error) {
      setAskServicePass(false);
      setHasSuccess(false);
      console.log('error servicio resultado PassDesafio');
      setFactorError('0');
    }
  }, [storagePassDesafioResult]);

  useEffect(() => {
    if (factoresSeguridad === null || factoresSeguridad.length === 0) {
      registerGA({
        'event': 'eve',
        'eve-acc': '/tef/editar-datos',
        'eve-cat': 'Mensaje',
        'eve-lab': 'Sin factor autorizacion'
      });
    }
  }, [])

  /**
   *  Obtener desafio data TCoordenadas(desafio y trackId)
   */
  useEffect(() => {
    if (storageTCDesafioObtener.success && !loadingDesafioObtener) {
      if (Object.keys(storageTCDesafioObtener.data).length > 0) {
        setFactorError(null);
        setDesafio({ loader: false, ...storageTCDesafioObtener.data });
        setSendDesafio({ track: storageTCDesafioObtener.data.track });
        setCounterActive(true);
        setTimeOut(false);
      }
    }
    if (storageTCDesafioObtener.error && storageTCDesafioObtener.errorCode === '5') {
      setDesafio({ loader: false });
      setFactorError('5');
    }
    if (storageTCDesafioObtener.error && storageTCDesafioObtener.errorCode === '4') {
      setDesafio({ loader: false });
      setFactorError('5');
    }
    if (storageTCDesafioObtener.error && storageTCDesafioObtener.errorCode === '0') {
      setDesafio({ loader: false });
      setFactorError('0');
    }
  }, [storageTCDesafioObtener, loadingDesafioObtener]);

  /**
   *  Obtener resultado desafio TCoordenadas
   */
  useEffect(() => {
    // Resultado Exitoso
    if (storageTCsDesafioResult.success && !loadingDesafioEnviar) {
      // State para pasar a envio de actualizacion de Datos
      setResultDesafio(true);
      setFactorError(null);
      setDesafio({ loader: false });
      setCounterActive(false);

      setHasSuccess(true);
      handleShowModalFactors(false)
      setUnlockInputs(false)
      setAlertState('success')
      setBtnSave('success')

      handleAsyncSaveChanges();
    }

    // Resultado Coordenadas no corresponden
    if (storageTCsDesafioResult.errorCode === '2' && storageTCsDesafioResult.error) {
      console.log('Coordenadas no corresponden');
      setFactorError('2');
      setTimeOut(true);
      setCounterActive(false);
    }

    // Resultado se agoto el tiempo
    if (storageTCsDesafioResult.errorCode === '1' && storageTCsDesafioResult.error) {
      console.log('Coordenadas no corresponden');
      setFactorError('1');
      setTimeOut(true);
      setCounterActive(false);
    }

    // Resultado tarjeta bloqueada
    if (storageTCsDesafioResult.errorCode === '5' && storageTCsDesafioResult.error) {
      console.log('Resultado tarjeta bloqueada. Solicítala nuevamente');
      setFactorError('5');
      setTimeOut(true);
      setCounterActive(false);
      registerGA({
        'event': 'eve',
        'eve-acc' : '/tef/editar-datos',
        'eve-cat' : 'Error',
        'eve-lab' : 'PassCoopeuch - Bloqueada'
      });
    }

    // Resultado error Tarjeta de Coordenadas está bloqueada
    if (storageTCsDesafioResult.errorCode === '4' && storageTCsDesafioResult.error) {
      console.log('Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente');
      setFactorError('4');
      setTimeOut(true);
      setCounterActive(false);
    }
    // Resultado error en servicio
    if (storageTCsDesafioResult.errorCode === '' && storageTCsDesafioResult.error) {
      console.log('error resultado desafio');
      setFactorError('0');
      setTimeOut(true);
      setCounterActive(false);
    }
  }, [storageTCsDesafioResult, loadingDesafioEnviar]);

  /**
   * Observador de dispatchs para actualizacion  Direccion Contacto y Direc Comercial
   */
  /**
   * Obtener resultado envio mail
   */
  useEffect(() => {
    if (storageEnvioEmail.success) {
      if (storageEnvioEmail.data) {
      }
    }
    if (storageEnvioEmail.error) {
      setTermsAndCondicions(false);
    }
  }, [storageEnvioEmail]);

  /**
   * Observar actualizacion de Datos de Contacto
   */
  useEffect(() => {
    let factor;
    if(factoresSeguridad && factoresSeguridad[0]?.payload && factoresSeguridad[0]?.codigo === 2.1){
      factor = 'TCOO';
    }else if(factoresSeguridad && factoresSeguridad[0]?.payload === null && factoresSeguridad[0]?.codigo === 2.2){
      factor = `PassCoopeuch`;
    }
    if (storageDContactoUpdate.success) {
      setHasDisabledButton(true);
      setResultDesafio(true);
      setAlertState('save-exito')
      setLockAutorizador(true)
      const tmpStringModifyEmail = `${onChangeFieldCorreo == 'correo' ? 'Correo -' : ''}`
      const tmpStringModifyPhoneComercial = `${onChangeFieldTelComercial == 'telefonoComercial' ? 'Telefono Comercial -' : ''}`
      const tmpStringModifyCelular = `${onChangeFieldCelular == 'telefonoCelular' ? 'Celular -' : ''}`
      const tmpStringModifyTefPart = `${onChangeFieldParticular == 'telefonoParticular' ? 'Telefono Particular -' : ''}`
      const tmpStringModifyRegion = `${onChangeFieldRegion == 'region' ? 'Region -' : ''}`
      const tmpStringModifyCalle = `${onChangeFieldCalle == 'calle' ? 'Calle -' : ''}`
      const tmpStringModifyNumero = `${onChangeFieldNumero == 'numero' ? 'Numero -' : ''}`
      const tmpStringModifyDepto = `${onChangeFieldDepto == 'departamento' ? 'Departamento -' : ''}`
      const tmpStringModifyComuna = `${onChangeFieldComuna == 'comuna' ? 'Comuna -' : ''}`
      const resultMarks = `${tmpStringModifyCalle}${tmpStringModifyCelular}${tmpStringModifyComuna}${tmpStringModifyComuna}${tmpStringModifyDepto}${tmpStringModifyEmail}${tmpStringModifyNumero}${tmpStringModifyPhoneComercial}${tmpStringModifyRegion}${tmpStringModifyTefPart}`;
      const finalResultMarks = resultMarks.endsWith("-") ? resultMarks.slice(0, -1) : resultMarks;

      registerGA({
        'event': 'eve',
        'eve-acc' : '/tef/editar-datos',
        'eve-cat' : 'Mensaje',
        'eve-lab' : 'Datos editados correctamente',
        'valoracion' : `${factor}`,
        'seguros' : finalResultMarks
      });
      // Mandar a recargar obtener Datos Contacto
      setIsReloadDContacto(true);
      setUpdateDContacto(false);
      handleRegisterLogs("OK");
    }

    if (storageDContactoUpdate.error) {
      handleRegisterLogs("NOK");
      setAlertState('error-servicio')
      setResultDesafio(false);
      //setFactorError('0'); 
      // aqui meter logs
      setUpdateDContacto(false);
      setUserUpdate({ ...userUpdate, dContacto: false });
    }
  }, [storageDContactoUpdate]);

  /**
   * Observador actualizacion de Direccion Comerciales
   */
  useEffect(() => {
    let factor;
    if(factoresSeguridad && factoresSeguridad[0]?.payload && factoresSeguridad[0]?.codigo === 2.1){
      factor = 'TCOO';
    }else if(factoresSeguridad && factoresSeguridad[0]?.payload === null && factoresSeguridad[0]?.codigo === 2.2){
      factor = `PassCoopeuch`;
    }
    if (storageDComercialUpdate.success) {
      setAlertState('save-exito')
      setLockAutorizador(true)
      const tmpStringModifyEmail = `${onChangeFieldCorreo == 'correo' ? 'Correo -' : ''}`
      const tmpStringModifyPhoneComercial = `${onChangeFieldTelComercial == 'telefonoComercial' ? 'Telefono Comercial -' : ''}`
      const tmpStringModifyCelular = `${onChangeFieldCelular == 'telefonoCelular' ? 'Celular -' : ''}`
      const tmpStringModifyTefPart = `${onChangeFieldParticular == 'telefonoParticular' ? 'Telefono Particular -' : ''}`
      const tmpStringModifyRegion = `${onChangeFieldRegion == 'region' ? 'Region -' : ''}`
      const tmpStringModifyCalle = `${onChangeFieldCalle == 'calle' ? 'Calle -' : ''}`
      const tmpStringModifyNumero = `${onChangeFieldNumero == 'numero' ? 'Numero -' : ''}`
      const tmpStringModifyDepto = `${onChangeFieldDepto == 'departamento' ? 'Departamento -' : ''}`
      const tmpStringModifyComuna = `${onChangeFieldComuna == 'comuna' ? 'Comuna -' : ''}`
      const resultMarks = `${tmpStringModifyCalle}${tmpStringModifyCelular}${tmpStringModifyComuna}${tmpStringModifyComuna}${tmpStringModifyDepto}${tmpStringModifyEmail}${tmpStringModifyNumero}${tmpStringModifyPhoneComercial}${tmpStringModifyRegion}${tmpStringModifyTefPart}`;
      const finalResultMarks = resultMarks.endsWith("-") ? resultMarks.slice(0, -1) : resultMarks;
      registerGA({
        'event': 'eve',
        'eve-acc' : '/tef/editar-datos',
        'eve-cat' : 'Mensaje',
        'eve-lab' : 'Datos editados correctamente',
        'valoracion' : `${factor}`,
        'seguros' : finalResultMarks
      });
      setHasDisabledButton(true);
      setIsReloadDComercial(true);
      setUpdateDComercial(false);
      handleRegisterLogs("OK");
    }
    if (storageDComercialUpdate.error) {
      handleRegisterLogs("NOK");
      setAlertState('error-servicio')
      //aqui meter logs
      setUserUpdate({ ...userUpdate, dComercial: false });
      setUpdateDComercial(false);
    }
  }, [storageDComercialUpdate]);

  useEffect(() => {
    if (direccionComercial) {
      setRegionSocioCode(direccionComercial?.codigoRegion);
      setComunaSocioCode(direccionComercial?.codigoComuna);
    }
  }, [direccionComercial]);

  useEffect(() => {
    if (comunaSocioCode !== null && comunaSocioRegion !== null) {
      setComunaSocioDescripcion(
        comunaSocioRegion
          .filter((item) => item.value === comunaSocioCode.toString())
          .map((item) => item.label)[0]
      );
    }
    if (updateRegion) {
      setComunaSocioDescripcion(comunaSocioRegion[0].label);
      setComunaSocioCode(comunaSocioRegion[0].value);
      setDireccionComercial((prevState) => ({
        ...prevState,
        codigoComuna: comunaSocioRegion[0].value,
      }));
    }
  }, [comunaSocioCode, comunaSocioRegion, updateRegion]);

  useEffect(() => {
    if (comunaSocioDescripcion !== null) {
      setIsComunaLoading(false);
    } else {
      setIsComunaLoading(true);
    }
  }, [comunaSocioDescripcion]);

  useEffect(() => {
    if (regionSocioCode !== null && isloadingDRegiones === false) {
      setRegionSocioDescripcion(
        dataRegiones
          .filter((item) => item.value === regionSocioCode.toString())
          .map((item) => item.label)[0]
      );
    }
    if (regionSocioCode !== null && isLoadingDComunas === false) {
      setComunaSocioRegion(
        dataComunas.filter((item) => item.region === regionSocioCode.toString())
      );
    }
  }, [regionSocioCode, isloadingDRegiones, isLoadingDComunas]);

  // Se setea region y comuna en el state direccionComercial
  useEffect(() => {
    if (regionSocioDescripcion) {
      setDireccionComercial((prevState) => ({
        ...prevState,
        region: regionSocioDescripcion.toUpperCase(),
      }));
    }
    if (comunaSocioDescripcion) {
      setDireccionComercial((prevState) => ({
        ...prevState,
        comuna: comunaSocioDescripcion.toUpperCase(),
      }));
    }
  }, [regionSocioDescripcion, comunaSocioDescripcion]);
const validateCampos = () => {
  if(evaluatePhone(datosContacto?.telefonoCelular, 13) && evaluatePhone(datosContacto?.telefonoComercial, 13) &&
  evaluatePhone(datosContacto?.telefonoParticular, 13) &&
  isValidEmail(datosContacto?.correo) && factoresSeguridad?.length !== 0 && !lockAutorizador){
    return false;
  }else{
    return true;
  }
}

const resetFlags = () => {
  setEditFieldCelular('');
  setEditFieldCalle('');
  setEditFieldNumero('')
  setEditFieldComuna('')
  setEditFieldRegion('')
  setEditFieldTelComercial('')
  setEditFieldParitucular('')
  setEditFieldDpto('')
  setEditFieldCorreo('')
}
const handleRegisterLogs = (estado) => {
  const baseRut = String((persona?.rut || localStorage.getItem('user-rut')).replace('-', ''));
  let factor;
  let _error = null;
  if(factoresSeguridad && factoresSeguridad[0]?.payload && factoresSeguridad[0]?.codigo === 2.1){
    factor = 'TCOO';
  }else if(factoresSeguridad && factoresSeguridad[0]?.payload === null && factoresSeguridad[0]?.codigo === 2.2){
    factor = `PassCoopeuch`;
  }
  if(estado === "NOK"){
    _error = {
      codigo_respuesta: storageDContactoUpdate?.errorCode?.code,
      statuscode: storageDContactoUpdate?.errorCode?.statusCode,
      code:storageDContactoUpdate?.errorCode?.code,
      message:storageDContactoUpdate?.errorCode?.message,
      uri: storageDContactoUpdate?.errorCode?.uri,
      estado: "NOK",
    }
  }

  if(onChangeFieldCorreo === 'correo'){
    logger(_error, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'EDITAR_DATOS_EMAIL',
      informacion_adicional: {
        factorseguridad: factor,
        correonuevo: datosContacto?.correo,
        correoanterior: datosAnteriores?.correo,
      },
    });
  }
  if(onChangeFieldTelComercial === 'telefonoComercial'){
    logger(_error, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'EDITAR_DATOS_OTROS',
      informacion_adicional: {
        datosContacto: {
          factorseguridad: factor,
          telefonocomercialnuevo: datosContacto.telefonoComercial,
          telefonocomercialanterior: datosAnteriores?.telefonoComercial,
        },
      },
    });
  }
  if(onChangeFieldCelular === 'telefonoCelular'){
    logger(_error, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'EDITAR_DATOS_CELULAR',
      informacion_adicional: {
        factorseguridad: factor,
        telefonocelularnuevo: datosContacto?.telefonoCelular,
        telefonocelularanterior: datosAnteriores?.telefonoCelular,
      },
    });
  }
  if(onChangeFieldParticular === 'telefonoParticular'){
    logger(_error, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'EDITAR_DATOS_OTROS',
      informacion_adicional: {
        datosContacto: {
         factorseguridad: factor,
         telefonoparticularnuevo: datosContacto.telefonoParticular,
         telefonoparticularanterior: datosAnteriores?.telefonoParticular,
        },
      },
    });
  }

  const direccion = {}
  if(onChangeFieldRegion === 'region'){
    direccion.regionnuevo = direccionComercial.region;
    direccion.regionanterior = dataDireccionAnterior?.region;
  }
  if(onChangeFieldCalle === 'calle'){
    direccion.callenuevo = direccionComercial.calle;
    direccion.callenanterior = dataDireccionAnterior?.calle;
  }
  if(onChangeFieldNumero === 'numero'){
    direccion.numeronuevo = direccionComercial.numero;
    direccion.numeronanterior = dataDireccionAnterior?.numero;
  }
  if(onChangeFieldDepto === 'departamento'){
    direccion.departamentonuevo = direccionComercial.departamento;
    direccion.departamentoanterior = dataDireccionAnterior?.departamento;
  }
  if(onChangeFieldComuna === 'comuna'){
    direccion.comunanuevo = direccionComercial.comuna;
    direccion.comunaanterior = dataDireccionAnterior?.comuna;
  }
  if(onChangeFieldNumero === 'numero' || 
  onChangeFieldDepto === 'departamento' || 
  onChangeFieldComuna === 'comuna' || 
  onChangeFieldCalle === 'calle' || 
  onChangeFieldRegion === 'region'){
    logger(_error, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'EDITAR_DATOS_DIRECCION',
      informacion_adicional: {
        factorseguridad: factor,
        direccion,
      },
    });
  }
  resetFlags();
}
const handleSaveChanges = () => {
  if (updateDContacto && sendDesafio.track) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    handleSendUpdateDContacto();
  }
  if (updateDComercial && sendDesafio.track) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    handleSendUpdateDComercial();
  }
  if (termsAndCondicions && resultDesafio === true) {
    dispatch(personaActions.fetchEnvioEmailObtener());
  }
}
const handleEditDatos = (key) => {
  handleShowModalFactors(key)
  if(factoresSeguridad && factoresSeguridad[0]?.codigo === 2.1){
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/editar-datos',
      'eve-cat' : 'Mensaje',
      'eve-lab' : 'Modal - No tienes tu PassCoopeuch'
    });
  }
  registerGA({
    'event': 'vpv-privado',
    'page' : '/tef/editar-datos'
  });
  const tmpStringModifyEmail = `${onChangeFieldCorreo == 'correo' ? 'Correo -' : ''}`
  const tmpStringModifyPhoneComercial = `${onChangeFieldTelComercial == 'telefonoComercial' ? 'Telefono Comercial -' : ''}`
  const tmpStringModifyCelular = `${onChangeFieldCelular == 'telefonoCelular' ? 'Celular -' : ''}`
  const tmpStringModifyTefPart = `${onChangeFieldParticular == 'telefonoParticular' ? 'Telefono Particular -' : ''}`
  const tmpStringModifyRegion = `${onChangeFieldRegion == 'region' ? 'Region -' : ''}`
  const tmpStringModifyCalle = `${onChangeFieldCalle == 'calle' ? 'Calle -' : ''}`
  const tmpStringModifyNumero = `${onChangeFieldNumero == 'numero' ? 'Numero -' : ''}`
  const tmpStringModifyDepto = `${onChangeFieldDepto == 'departamento' ? 'Departamento -' : ''}`
  const tmpStringModifyComuna = `${onChangeFieldComuna == 'comuna' ? 'Comuna -' : ''}`
  const resultMarks = `${tmpStringModifyCalle}${tmpStringModifyCelular}${tmpStringModifyComuna}${tmpStringModifyComuna}${tmpStringModifyDepto}${tmpStringModifyEmail}${tmpStringModifyNumero}${tmpStringModifyPhoneComercial}${tmpStringModifyRegion}${tmpStringModifyTefPart}`;
  const finalResultMarks = resultMarks.endsWith("-") ? resultMarks.slice(0, -1) : resultMarks;
  registerGA({
    'event': 'eve',
    'eve-acc' : '/tef/editar-datos',
    'eve-cat' : 'Click',
    'eve-lab' : 'Autorizar cambios',
    'seguros' : finalResultMarks
  });
}
const [fsChange, setFsChange] = useState();
const TitleModal = () => {
  let title;
  if(factoresSeguridad && factoresSeguridad[0]?.codigo === 2.1){
    title = "PassCoopeuch";
    if(fsChange){
      title = `Tarjeta de Coordenadas ${factoresSeguridad[0]?.payload}`
    }
  }else if(factoresSeguridad && factoresSeguridad[0]?.codigo === 2.2){
    title = "PassCoopeuch";
  }
  return <strong>{title}</strong>;
}
  return (
    <>
    <DashboardCard className="ml-0 mr-0 bmb-xs-3 bmy-md-4">
        <DashboardCard.Head
          title={<strong>Datos de contacto</strong>}
          borderBottom="complete"
          classes="btext-xs-left bmt-xs-3 bmt-sm-4"
        />
        <DashboardCard.Body classes="text-left-destinatarios black">
            
          {habilitadoCell &&
            <Alert show bgType="warning" className="bml-1">
              Por el momento, los cambios en tu número de celular estan deshabilitados. Para más información comunicate al <strong>600 200 1200</strong>.
            </Alert> 
          }
          {
            factoresSeguridad?.length === 0 ? 
            <Alert show bgType="warning" className="bml-1">
              Para actualizar tus datos debes tener un método de autorización válido.
            </Alert>
          :
          alertState === 'info' &&
            <Alert show bgType="info" className="bml-1">
              Revisa tus datos y mantenlos actualizados.
            </Alert>
          }
          {
          alertState === 'success' && 
          <Alert show bgType="info" className="bml-1">
            Ahora puedes modificar tus datos, editando el campo requerido.
          </Alert>
          }
          {
          alertState === 'save-exito' &&
          <Alert show bgType="success" className="bml-1">
            Los datos fueron editados correctamente.
          </Alert>
          }
          {
            alertState === 'error-servicio' &&
            <Alert show bgType="error" className="bml-1">
              Tus datos de contacto no han podido ser actualizados. Intenta nuevamente.
            </Alert>
          }
            <br />
          <DatosDeContacto
              data={dataPersona}
              loading={loadingPersona}
              datosContactoRef={datosContactoRef}
              unlockInputs={unlockInputs}
              factoresSeguridad={factoresSeguridad}
              {...{
                loadingDirecciones,
                direccionPersona,
                datosContacto,
                evaluatePhone,
                onChangePhones,
                onChangeEmail,
                userUpdate,
                loadingHabilitadoCell,
                habilitadoCell,
                loadingHabilitadoEmail,
                habilitadoEmail,
                loadingDatosContacto,
              }}
            />
            <br />
          <div className='line-block' style={
            {
              marginLeft: 0,
              marginRight: 0,
              borderBottom: '1px solid #e2e1e3',
            }
          }></div>
          <br />
            <DatosContactoEmpleador
              direcComercialRef={direcComercialRef}
              empleador={datosContacto?.empleador}
              loadingDatosContacto={loadingDatosContacto}
              factoresSeguridad={factoresSeguridad}
              {...{
                direccionComercial,
                onChangeDirecComercial,

                isloadingDRegiones,
                dataRegiones,
                regionSocioDescripcion,
                onHandleRegion,

                isComunaLoading,
                comunaSocioRegion,
                comunaSocioDescripcion,
                onHandleComuna,

                userUpdate,
              }}
            />
            <br />
          <div className='line-block' style={
            {
              marginLeft: 0,
              marginRight: 0,
              borderBottom: '1px solid #e2e1e3',
            }
          }></div>
     {
      dataPersona?.rol === 'CLIENTE' && <TerminosYCondiciones {...{ termsAndCondicions, onChangeCheckBox }} />
     }
        
      <div className='line-block' style={
        {
          marginLeft: 0,
          marginRight: 0,
          borderBottom: '1px solid #e2e1e3',
        }
      }></div>
          <br />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Button
              // variant="outline"
              bgColor={'#2a9fd8'}
              // style={{
              //   backgroundColor: '#2a9fd8',
              //   color: '#FFF'
              // }}
            >
              Editar Datos
            </Button> */}
            <Button
              bgColor="#007DB7"
              disabled={validateCampos()}
              color="white"
              className="contentSMS__controls-btn"
              onClick={() => handleEditDatos(true)}
            >
              Autorizar cambios
            </Button>
            
          </div>
          

    <AlertModal 
    showModal={showModalFactors} 
    width={900}
    title={<TitleModal />}
    onClose={() => handleEditDatos(false)}
    >
      <div className="center-xs">
        <Factores
        {...{
          loadingFactores,
          factoresSeguridad,
          handleReloadFactores,
          onHandleObtenerDesafio,
          termsAndCondicions,
          desafio,
          onHandleSetDesafio,
          sendDesafio,
          onHandleSendDesafio,
          resultDesafio,
          factorError,
          setFactorError,
          loadingDesafioObtener,
          loadingDesafioEnviar,
          handleCleanStates,
          setAskServicePass,
          intentos,
          hasDisabledButton,
          // Counter
          counterActive,
          setCounterActive,
          timeOut,
          setTimeOut,
          setFsChange,
          hasSuccess,
          passLock,
        }}
        activateButton={resultDesafio}
      />
      </div>
    </AlertModal>
        </DashboardCard.Body>
    </DashboardCard>
    {
      factoresSeguridad?.length === 0 && <DescargaYUsa />
    }
      {/*  */}
        {/* </div> */}
      {/* // </section> */}
      {/* // </div> */}
      
    </>
  );
};

Index.displayName = 'Forms_Container';
export default Index;
