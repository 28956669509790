import React, { Component, Suspense } from 'react';

import { connect } from 'react-redux';
import produce from 'immer';

import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import Alert from '@coopeuch-components/web/atoms/Alert';

import { creditoHipotecarioActions } from '../../../../application/actions/creditoHipotecario';
import Historial from '../Historial';
import DetalleCredito from '../DetalleCredito';
import SinCredito from '../SinCredito';
import Error from '../../../components/atoms/Error';

import { registerDY, registerGA } from '../../../utils/metrics';
import { capitalizeStrFormat } from '../../../utils/formatters';

const tabs = {
  0: 'DetalleCredito',
  1: 'Historial',
};

const initState = {
  errorGeneral: false,
  flujoHipotecario: 'DETALLE_CREDITO_HIPOTECARIO',
  creditos: null,
  skeleton: true,
  tab: 'DetalleCredito',
  loadedData: {
    cuotaObtener: false,
    creditoObtener: false,
  },
  paymentActive: false,
};

class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  onTabsHandle = (position) => {
    this.setState(
      produce((draft) => {
        draft.tab = tabs[position];
      })
    );
  };

  componentDidMount = () => {
    const { fetchCredito, fetchCuota, fetchVentanaHoraria } = this.props;
    fetchCredito();
    fetchCuota();
    fetchVentanaHoraria();
    registerDY({ type: 'PRODUCT', data: ['creditos'] });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { resultadoCredito, resultadoCuota } = this.props;
    const { loadedData } = this.state;

    if (prevProps.resultadoCredito !== resultadoCredito) {
      if (resultadoCredito.success) {
        let creditos = null;
        if (!!resultadoCredito.data.length > 0) {
          creditos = resultadoCredito.data.map((credito) => ({
            label: `${capitalizeStrFormat(credito.nombreTipoCredito)} ${credito?.numeroReferencia || ''
              }`,
            value: credito.numeroCredito,
            ...credito,
          }));

          this.setState((state) => ({
            creditos,
            loadedData: { ...state.loadedData, creditoObtener: true },
          }));
        } else {
          // Redirigir a contenedor sin producto
          this.setState({
            flujoHipotecario: 'SIN_CREDITO_HIPOTECARIO',
          });
        }
      } else {
        this.setGlobalError();
        // Registro analitycs sin servicio
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/mis-productos/credito-hipotecario/credito-hipotecario',
          'eve-cat': 'Error',
          'eve-lab': 'Servicio no disponible',
        });
      }
    }

    if (prevProps.resultadoCuota !== resultadoCuota) {
      if (resultadoCuota.success) {
        this.setState((state) => ({
          loadedData: { ...state.loadedData, cuotaObtener: true },
        }));
      } else {
        this.setGlobalError();
        // Registro analitycs sin servicio
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/mis-productos/credito-hipotecario/credito-hipotecario',
          'eve-cat': 'Error',
          'eve-lab': 'Servicio no disponible',
        });
      }
    }
 
    if (
      prevState.loadedData !== loadedData &&
      loadedData.creditoObtener &&
      loadedData.cuotaObtener
    ) {
      this.setState({ skeleton: false });
    }
  };

  setGlobalError = () => {
    this.setState({ errorGeneral: true });
  };

  render() {
    const { errorGeneral, skeleton, flujoHipotecario, creditos, tab} = this.state;
    const { goToPayment, paymentSuccesful } = this.props;

    let landingPage = <SinCredito />;
    let content = <DetalleCredito {...{ skeleton, creditos}} goToPayment={goToPayment} />;

    return (
      <>
        {errorGeneral ? (<Error className="bmt-3 bmt-md-4" />) : (
          <>
            {flujoHipotecario === 'SIN_CREDITO_HIPOTECARIO' ? (
              <div className="w-100 bpx-3 bpx-md-5 text-left">
                {landingPage}
              </div>
            ) : (
              <Tabs
                bgColor="#fff"
                fontSize={15}
                fontFamily="Ubuntu-Regular"
                activeColor="#e81e2b"
                className="pl-54 tab--wrapper-shadows tabs full-tab"
              >
                <Tabs.Header className="tabs-header-transferencia">
                  <Tab callback={this.onTabsHandle} className="tab-style" id="mi-hipotecario">
                    Mi Hipotecario
                  </Tab>
                  <Tab callback={this.onTabsHandle} className="tab-style w-150p" id="historial">
                    Historial
                  </Tab>
                </Tabs.Header>
                <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
                  <>
                    <Tabs.Body>
                      <Suspense fallback={<div />}>
                        <div className="w-100">
                          {/* Mensaje informativo respecto al pago de una cuota. Debido a sincronización son Asicom,
                  la actualización demora 24hrs en visualizarse. El mensaje sólo se muetsra una vez luego de
                  concretado un pago exitoso. */}
                          {paymentSuccesful && (
                            <Alert bgType="info" show>
                              ¡Tu pago fue exitoso! Estamos procesando la información y en 24 horas hábiles
                              lo verás reflejado en tu saldo.
                            </Alert>
                          )}
                          {content}
                        </div>
                      </Suspense>
                      <Suspense fallback={<div />}>
                        <div className="w-100">
                          <div className="w-100">{tab === 'Historial' && <Historial />}</div>
                        </div>
                      </Suspense>
                    </Tabs.Body>
                  </>
                </div>
              </Tabs>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  resultadoCredito: store.entities.creditoHipotecario.credito,
  resultadoCuota: store.entities.creditoHipotecario.cuota,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCredito: () => {
    dispatch(creditoHipotecarioActions.fetchCredito());
  },
  fetchCuota: () => {
    dispatch(creditoHipotecarioActions.fetchCuota());
  },
  fetchVentanaHoraria: () => {
    dispatch(creditoHipotecarioActions.fetchVentanaHoraria());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
