import styled from 'styled-components';

const Wrapper = styled.div`
  ._3sTov {
    > div > h1 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 900;
      font-family: 'Ubuntu-Bold';
    }
  }

  .cardNextSteps {
    padding: 0px;

    &__container {
      display: flex;
      padding: 22px 32px 24px;
      border-bottom: 1px solid #e7e7e7;

      :last-child {
        border-bottom: 0px;
      }
      &_step {
        display: flex;
        width: 100%;
        max-width: 32px;
        > p {
          width: 100%;
          max-width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          border: 1px solid #007db7;
          border-radius: 99px;
          color: #007db7;
          font-family: 'Ubuntu-Medium';
          font-size: 14px;
        }
      }

      .activeStep {
        color: white;
        background-color: #007db7;
      }

      &_text {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
        &__header {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 85%;
          padding-bottom: 10px;

          > p:first-child {
            font-family: 'Ubuntu-Bold';
            font-size: 18px;
            line-height: 24px;
            font-weight: 900;
            color: #383838;
            margin-right: 16px;
          }

          > p:last-child {
            font-family: 'Ubuntu-Bold';
            font-size: 18px;
            line-height: 28px;
            font-weight: 900;
            color: #007db7;
          }

          @media screen and (max-width: 600px) {
            flex-direction: column;

            > p:first-child {
              width: 100%;
              margin-right: 0px;
            }

            > p:last-child {
              width: 100%;
              margin-top: 10px;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        &__body {
          width: 85%;
          font-family: 'Ubuntu-Regular';
          font-size: 14px;
          line-height: 20px;
          color: #383838;
          @media screen and (max-width: 500px) {
          }
        }
      }

      &_imgs {
        width: 10%;

        @media screen and (max-width: 500px) {
          display: none;
        }
      }

      @media screen and (max-width: 500px) {
        width: 100%;
        padding: 16px 16px 24px;
      }
    }

    .activeBg {
      background-color: rgba(217, 241, 251, 0.5);
    }
  }
`;
export default Wrapper;
