import React, { Component } from 'react';
import { connect } from 'react-redux';
import produce from 'immer';
import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';

import ResultadoTemplate from '../../../components/templates/DepositoAPlazo/Resultado';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';

class Resultado extends Component {
  handleReturn = () => {
    const { history, match } = this.props;
    history.push(`${match?.url}/simulacion`);
  };

  handleContinue = () => {
    const {
      seleccionarSimulacion,
      tarjetaActiva,
      simulaciones,
      handleChangeSimulacionSeleccionada,
    } = this.props;
    let idSimulacion = simulaciones[tarjetaActiva - 1]?.id;
    seleccionarSimulacion({ idSimulacion });
    handleChangeSimulacionSeleccionada(simulaciones[tarjetaActiva - 1]);
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-2',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
  };

  componentDidUpdate(prevProps) {
    const {
      isLoadingSimulacionMultiple,
      simulaciones,
      handleChangeTarjetaActiva,
      isLoadingSeleccionarSimulacionDAP,
      seleccionarSimulacion,
      tarjetaActiva,
    } = this.props;

    if (prevProps.isLoadingSimulacionMultiple !== isLoadingSimulacionMultiple) {
      if (!isLoadingSimulacionMultiple) {
        if (simulaciones.length > 0) {
          if (tarjetaActiva === 0) {
            let tarjetaDefecto = simulaciones.findIndex((simulacion) => simulacion.clienteSimulado);
            handleChangeTarjetaActiva(tarjetaDefecto + 1);
          }
        }
      }
    }

    if (prevProps.isLoadingSeleccionarSimulacionDAP !== isLoadingSeleccionarSimulacionDAP) {
      if (!isLoadingSeleccionarSimulacionDAP) {
        if (seleccionarSimulacion) {
          const { history, match } = this.props;
          history.push(`${match?.url}/detalles-simulacion`);
        }
      }
    }
  }

  componentDidMount() {
    const { fetchSimulacionMultiple, simulacion } = this.props;

    const params = {
      montoInversion: simulacion?.monto,
      plazoInversion: simulacion?.plazo,
      tipoDepositoEnum: simulacion?.tipo.toUpperCase(),
    };
    fetchSimulacionMultiple({ params });

    registerGA({ event: 'vpv-privado', page: '/tef/mis-productos/deposito-a-plazo/paso-2' });
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP_SIMULADOR-P2');
  }

  render() {
    return (
      <ResultadoTemplate
        {...this.props}
        handleReturn={this.handleReturn}
        handleContinue={this.handleContinue}
      />
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  simulaciones: entities.depositoAPlazo.simulaciones,
  isLoadingSimulacionMultiple: ui.loaders.isLoadingSimulacionMultiple,
  simulacionesError: entities.depositoAPlazo.simulacionesError,
  seleccionarSimulacion: entities.depositoAPlazo.seleccionarSimulacion,
  isLoadingSeleccionarSimulacionDAP: ui.loaders.isLoadingSeleccionarSimulacionDAP,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSimulacionMultiple(props) {
    dispatch(depositoAPlazoActions.fetchSimulacionMultiple(props));
  },
  seleccionarSimulacion(props) {
    dispatch(depositoAPlazoActions.seleccionarSimulacion(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Resultado);
