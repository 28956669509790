import { spawn, call, takeLatest, put } from 'redux-saga/effects';
import { logReqError, setMenuStructure } from '../../../ui/utils/functions';

// Actions
import { configuracionActions } from '../../../application/actions/configuracion';

// Configs
import api from '../../api';

const apiRequest = api(process.env.REACT_APP_CONFIGURATION_API);

export function* obtenerMantencion() {
  try {
    const { data, error } = yield call(apiRequest.get, 'mantencion/v1/obtener');
    if (data) {
      yield put(configuracionActions.fetchObtenerMantencionSuccess(data));
    } else {
      yield put(configuracionActions.fetchObtenerMantencionError(error));
    }
  } catch (err) {
    console.error(err.message, 'obtenerMantencion');
    yield put(configuracionActions.fetchObtenerMantencionError(err));
  }
}

export function* consultarContexto(action) {
  try {
    const { id } = action.payload;
    const { data, error } = yield call(apiRequest.get, `contexto/v1/consultar/${id}`);
    if (data) {
      yield put(configuracionActions.fetchConsultarContextoSuccess(data));
    } else {
      yield put(configuracionActions.fetchConsultarContextoError(error));
    }
  } catch (err) {
    console.error(err.message, 'consultarContexto');
    yield put(configuracionActions.fetchConsultarContextoError(err));
  }
}

export function* watchobtenerMantencion() {
  yield takeLatest(configuracionActions.fetchObtenerMantencion.type, obtenerMantencion);
}

export function* watchconsultarContexto() {
  yield takeLatest(configuracionActions.fetchConsultarContexto.type, consultarContexto);
}

export default function* rootSaga() {
  yield spawn(watchobtenerMantencion);
  yield spawn(watchconsultarContexto);
}
