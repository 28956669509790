import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import React, { useCallback, useMemo } from 'react';
import PageTitle from '../../../../atoms/PageTitle';
import Stepper from '../../../../organism/Stepper';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const WrapperTemplate = (props) => {
  const { children, position } = props;

  const steps = useMemo(() => {
    return ['Escoge Cuenta', 'Contratación', 'Comprobante'];
  }, []);

  return (
    <Wrapper>
      <PageTitle
        title="Solicitud de Cuenta de Ahorro"
        icon={<LoadIcon src={`${iconPath}user.svg`} color="#333" />}
      />
      <div className="solicitud-container">
        <Stepper position={position} steps={steps} progressOutContext={true} />
        <div className="solicitud-contenedor">{children}</div>
      </div>
    </Wrapper>
  );
};

export default WrapperTemplate;
