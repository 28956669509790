import React from 'react';

// Ui
import Typography from '@coopeuch-components/web/atoms/Typography';
import DashboardCard from '../../../organism/DashboardCard';
import { registerGA } from '../../../../utils/metrics';
// Styles
import Container from './styles';

const staticImages = process.env.REACT_APP_CLOUDFRONT_STATICS;


const Index = () => {
  const registrarGA = (method) => {
    registerGA({
      'event': 'eve',
      'eve-acc' : '/tef/editar-datos',
      'eve-cat' : 'Click',
      'eve-lab' : `Sin factor - ${method}`
    });
  };
  return (
    <DashboardCard className="ml-0 mr-0 bmb-xs-3 bmy-md-4">
      <DashboardCard.Head
        title={<strong>Descarga y usa PassCoopeuch</strong>}
        borderBottom="complete"
        classes="btext-xs-left bmt-xs-3 bmt-sm-4"
      />
      <DashboardCard.Body classes="text-left-destinatarios black">
        <Container>

            <div className="row">
              <div className="col-md-2">
                <img
                  src={`${staticImages}thumbnail_mobile1.png`}
                  className="width-image bmr-2"
                  alt="transferencia_passcoopeuch"
                />
              </div>
              <div className="col-md-10">
                <p className="mb-3 text-left">
                      Ahora también puedes autorizar estas operaciones con{' '}
                  <b>
                    App PassCoopeuch
                    de manera fácil, rápida y segura.
                  </b>
                  Encuéntrala en AppStore, App Gallery o Play
                  Store.
                </p>
                
              <div className="col-md-9">
                  <div className="row">
                    <div className="col-md-2">
                      <a onClick={() => registrarGA('AppStore')} target="_blank" href="https://apps.apple.com/cl/app/passcoopeuch/id1524671704" rel="noopener noreferrer">
                        <img
                          src={`${staticImages}logo_app_store.svg`}
                          alt="transferencia_passcoopeuch"
                        />
                        <div style={{height: 5}}  />
                        <img
                          src={`${staticImages}qr_code_app_store.png`}
                          alt="transferencia_passcoopeuch"
                          style={{
                            border: '3px solid black', 
                            borderRadius: 5,
                            padding: 2
                          }}
                        />
                      </a>
                    </div>
                    <div className="col-md-2">
                      <a onClick={() => registrarGA('GooglePlay')} target="_blank" href="https://play.google.com/store/apps/details?id=cl.coopeuch.pass&hl=es_CL&gl=US" rel="noopener noreferrer">
                        <img
                          src={`${staticImages}logo_google_play.svg`}
                          alt="transferencia_passcoopeuch"
                        />
                        <div style={{height: 5}}  />
                      <img
                          src={`${staticImages}qr_code_google_play.png`}
                          alt="transferencia_passcoopeuch"
                          style={{
                            border: '3px solid black', 
                            borderRadius: 5,
                            padding: 2
                          }}
                        />
                      </a>
                    </div>
                    <div className="col-md-2">
                      <a onClick={() => registrarGA('AppGallery')} target="_blank" href="https://appgallery.huawei.com/app/C103730561" rel="noopener noreferrer">
                        <img
                          src={`${staticImages}logo_app_gallery.svg`}
                          alt="transferencia_passcoopeuch"
                        />
                        <div style={{height: 5}}  />
                        <img
                          src={`${staticImages}qr_code_app_gallery.svg`}
                          alt="transferencia_passcoopeuch"
                          style={{
                            border: '3px solid black', 
                            borderRadius: 5,
                            padding: 2
                          }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {
            /**
             * <Typography
            tag="p"
            color="#383638"
            fontSize={18}
            fontFamily="ubuntu-regular"
            align="left"
            className="mt-32 mb-24"
          >
            Ahora también puedes autorizar estas operaciones con{' '}
            <b>
              App PassCoopeuch
              <br />
              de manera fácil, rápida y segura.
            </b>
            {' '}
            Encuéntrala en AppStore, App Gallery o Play
            <br />
            Store.
          </Typography>
             */
          }
          
        </Container>
      </DashboardCard.Body>
    </DashboardCard>
  );
};

export default Index;
