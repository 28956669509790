import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import Switch from '../../atoms/Switch';
import { CardWrapper, SpinnerWrapper, DescripcionWrapper, AlertWrapper } from './styles';
import Spinner from './Spinner';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const revisaTusDatos = (
  <Link className="style-alert" to="/ecd/mi-perfil">
    Revisa tus datos.
  </Link>
);

const bloqueoTemporalResultDos = (
  <>
    Bloqueo temporal exitoso. Sin embargo, no hemos podido enviarte una notificación por correo.
    &nbsp;
    {revisaTusDatos}
  </>
);

const ActivacionExitosaResultDos = (
  <>
    Activación exitosa. Sin embargo, no hemos podido enviarte una notificación por correo. &nbsp;
    {revisaTusDatos}
  </>
);

const BloqueoTemporalTemplate = ({ isloading, cards, onHandleSwitch, resultado }) => {
  return (
    <>
      <div className="row bmt-4" id="BloqueoTemporalTemplate">
        <DescripcionWrapper className="col-xs-12">
          <CardBox>
            <CardBox.Header className="adjust_head_padding_card">
              {isloading ? (
                <div className="col-xs-5">
                  <Skeleton />
                </div>
              ) : (
                'Bloqueo de Tarjetas Temporal'
              )}
            </CardBox.Header>
            <CardBox.Body className="bloqueo-descripcion">
              {isloading ? (
                <>
                  <div className="col-xs-12">
                    <Skeleton />
                  </div>
                  <div className="col-xs-10">
                    <Skeleton />
                  </div>
                </>
              ) : (
                <>
                  Esta opción te permite activar o desactivar temporalmente tus tarjetas de débito.
                  Luego de bloquear tu tarjeta podrás volver a activarla cuando lo necesites.{' '}
                  <b>Selecciona la tarjeta que necesitas bloquear</b> temporalmente:
                </>
              )}
            </CardBox.Body>
          </CardBox>
        </DescripcionWrapper>
      </div>

      {/* Cards Tarjetas */}
      <div id="cards">
        {cards.map((card) => (
          <div className="row bmt-4" key={`tarjeta-card-${card.id}`} name={card.bloqueo}>
            <div className="col-xs-12">
              <CardBox>
                <CardBox.Body>
                  <AlertWrapper>
                    {card.success && resultado === 1 && (
                      <Alert className="bp-3 bmb-4" bgType="success" show>
                        {card.temporaryBlock ? 'Activación Exitosa' : 'Bloqueo Temporal Exitoso'}
                      </Alert>
                    )}
                    {card.success && resultado === 2 && (
                      <Alert className="bp-3 bmb-4" bgType="success" show>
                        {card.temporaryBlock ? ActivacionExitosaResultDos : bloqueoTemporalResultDos}
                      </Alert>
                    )}
                    {card.error && (
                      <Alert className="bp-3 bmb-4" bgType="error" show>
                        Ocurrió un error, inténtelo nuevamente
                      </Alert>
                    )}
                  </AlertWrapper>
                  <div className="row">
                    <div className="col-xs-10">
                      <CardWrapper>
                        <tbody>
                          <tr>
                            <td className="bpx-3  bpb-3">
                              <LoadIcon
                                src={`${iconPath}${card.icon}.svg`}
                                size={22}
                                color="383638"
                                className="w-auto"
                              />
                            </td>
                            <td className="tipo-tarjeta bpl-3 bpb-3">{card.tipo}</td>
                          </tr>
                          <tr>
                            <td className="bpx-3 titular-tarjeta">{card.lvl}</td>
                            <td className="numero-tarjeta  bpl-3">{card.numero}</td>
                          </tr>
                        </tbody>
                      </CardWrapper>
                    </div>
                    <div className="col-xs-2 dfac justify-content-center">
                      {card.loading ? (
                        <SpinnerWrapper>
                          <Spinner fill="#383838" />
                        </SpinnerWrapper>
                      ) : (
                        <>
                          <Switch
                            disabled={false}
                            checked={card.temporaryBlock}
                            name={card.id}
                            dataValue={card.bloqueo}
                            onChange={() => onHandleSwitch(card.id)}
                            activeColor="#007DB7"
                          />
                          {/* <button onChange={onHandleSwitch} type="button">
                          {card.id}
                        </button> */}
                        </>
                      )}
                    </div>
                  </div>
                </CardBox.Body>
              </CardBox>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

BloqueoTemporalTemplate.displayName = 'BloqueoTemporal_BT_Template';
export default BloqueoTemporalTemplate;
