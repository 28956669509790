const initState = {
    bannerPoderEspecial: false,
    bannerPoderEspecialError: {
        loading: false,
        success: false,
        error: false,
        data: {}
    },
    poderEspecialValidar: {
        loading: false,
        success: false,
        error: { server: false, cuenta: false }
    },
    poderEspecialFirmar: {
        loading: false,
        success: false,
        data: {},
        error: { server: false, claveInvalida: false, claveBloqueada: false, code: '', logErr: {} }
    },
    descargarComprobantePoderEspecialError: {
        message: null,
        code: null,
        statusCode: null,
        uri: null,
        error: false,
    },
    descargarComprobantePoderEspecial: {
        success: false,
        loading: false,
        error: { 
            server: false, 
            code: null, 
            message: null, 
            statusCode: null, 
            uri: null 
        },
    },
    descargarComprobantePoderEspecial: {
        loading: false,
        success: false,
        error: { server: false },
        data: {}
    }
}

export default initState;

