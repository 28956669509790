import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasKitActions } from '../../../../application/actions/activacionTarjetasKit';
// Templates
import ComprobanteTemplate from '../../../components/templates/ActivacionTarjetasKit/Comprobante';
// Utils
import { registerGA } from '../../../utils/metrics';

const Comprobante = ({ steps, switchPassCoopeuchOrSms }) => {
  const dispatch = useDispatch();

  const [visibleModal, setVisibleModal] = useState(false);
  const [factorAutorizador, setFactorAutorizador] = useState('');

  const persona = useSelector((store) => store.entities.persona);
  const kitData = useSelector((store) => store.entities.activacionTarjetasKit.kitDataKit);
  const validateTC = useSelector((store) => store.entities.activacionTarjetasKit.validateTCKit);
  const validateSMS = useSelector((store) => store.entities.activacionTarjetasKit.validateSMSKit);
  const activateKit = useSelector((store) => store.entities.activacionTarjetasKit.activateKitKit);
  const downloadPDF = useSelector((store) => store.entities.activacionTarjetasKit.downloadPDFKit);

  useEffect(() => {
    console.log("entro a KIt useEffect -> validateSMS", validateSMS);
    if (validateSMS.data?.valido === true) {
      setFactorAutorizador('Clave dinámica SMS');
      console.log("se guarda KIT setFactorAutorizador -> Clave dinámica SMS");
    }
  }, [validateSMS]);

  useEffect(() => {
    console.log("entro a KIt useEffect -> activateKit", activateKit);
    if (activateKit.data?.valido === true) {
      setFactorAutorizador('PassCoopeuch');
      console.log("se guarda KIT setFactorAutorizador -> PassCoopeuch");
    }
  }, [activateKit]);

  useEffect(() => {
    if (factorAutorizador !== '') {
      registerGA({
        event: 'vpv-privado',
        page: '/tef/seguridad/activacionTarjetas/comprobante',
        producto: 'Kit Cuenta Vista',
        valoracion: factorAutorizador,
      });
    }
  }, [factorAutorizador]);

  const handleDownloadPDF = () => {
    dispatch(activacionTarjetasKitActions.fetchDownloadComprobanteKit());
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
  };

  const handleReturnInicio = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Volver al inicio',
    });
  };

  useEffect(() => {
    if (validateTC.data === null) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas-kit');
    }
  }, []);

  useEffect(() => {
    if (downloadPDF.error !== null) {
      setVisibleModal(true);
    } else {
      setVisibleModal(false);
    }
  }, [downloadPDF]);

  return (
    <ComprobanteTemplate
      {...{
        steps,
        kitData,
        validateSMS,
        activateKit,
        persona,
        handleDownloadPDF,
        visibleModal,
        setVisibleModal,
        handleReturnInicio,
      }}
    />
  );
};

export default Comprobante;
