import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cuentaAhorroActions } from '../../../../application/actions/cuentaAhorro';

import { codigosModuloCuentaAhorro, estadosModuloCuentaAhorro } from '../../../utils/constants';

import CuentasAhorroTemplate from '../../../components/templates/Dashboard/CuentasAhorro';

import { registerGA } from '../../../utils/metrics';
import { cuentasAhorroTexts } from '../texts';

class CuentasAhorro extends Component {
  componentDidMount() {
    const { fetchCuentasAhorro } = this.props;
    fetchCuentasAhorro({
      codigoProducto: codigosModuloCuentaAhorro,
      estadoCuenta: estadosModuloCuentaAhorro,
    });
  }

  handleChangeSlide = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Visualizacion de estado de cuenta'
    });
  }

  handleClickTarjeta = ({ numeroCuenta, descripcionProducto }) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': `${cuentasAhorroTexts.PREFIJO} ${descripcionProducto}`,
    });

    this.props.history.push({
      pathname: '/ecd/productos/cuenta-ahorro',
      state: {
        numeroCuenta,
      },
    });
  };

  render() {
    const { cuentasAhorro, isLoading, errorCuentasAhorro } = this.props;
    return (
      <div className="col-md-6 col-xs-12">
        <CuentasAhorroTemplate
          cuentasAhorro={cuentasAhorro}
          isLoading={isLoading}
          errorCuentasAhorro={errorCuentasAhorro}
          handleClickTarjeta={this.handleClickTarjeta}
          handleChangeSlide={this.handleChangeSlide}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  isLoading: ui.loaders.isLoadingCuentasAhorro,
  cuentasAhorro: entities.cuentasAhorro.cuentas,
  errorCuentasAhorro: entities.cuentasAhorro.errorFetchCuentas,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasAhorro(params) {
    dispatch(cuentaAhorroActions.fetchCuentasAhorro({ params }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CuentasAhorro));
