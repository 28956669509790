const initState = {
  productosDisponibles: {
    cuentas: null,
    tarjetas: null,
    error: false,
    errLog: null
  },
  cuentasErrLog: null,
  tarjetasErrLog: null,
  habilitado: {},
  capitalizar: { error: false, errorLog: null },
  comprobanteCapitalizacion: null,
  detalleCapitalizacion: { error: false, fetched: false, errorLog: null },
  emailComprobante: { error: false, enviado: false },
};

export default initState;
