/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/capitalizacion';

// ------- SAGA ------ //

function fetchProductosDisponiblesCapitalizacionSuccess(state, action) {
  state.productosDisponibles = {
    ...state.productosDisponibles,
    cuentas: action.payload,
  };
  state.cuentasErrLog = null
}
function fetchProductosDisponiblesCapitalizacionError(state, action) {
  state.productosDisponibles.error = true;
  state.cuentasErrLog = action.payload?.error;
}
function fetchTarjetasProductosDisponiblesCapitalizacionSuccess(state, action) {
  state.productosDisponibles = {
    ...state.productosDisponibles,
    tarjetas: action.payload,
  };
  state.tarjetasErrLog = null;
}
function fetchTarjetasProductosDisponiblesCapitalizacionError(state, action) {
  state.productosDisponibles.error = true;
  state.tarjetasErrLog = action.payload?.error;
}

function fetchHabilitadoCapitalizarSuccess(state, action) {
  state.habilitado = {
    ...action.payload,
    errorServicio: false,
  };
}

function fetchHabilitadoCapitalizarError(state, action) {
  state.habilitado = action.payload;
}

function capitalizarRemanenteSuccess(state, action) {
  state.capitalizar = {
    ...action.payload,
    error: false,
    errorLog: null
  };
}

function capitalizarRemanenteError(state, action) {
  state.capitalizar.error = true;
  state.capitalizar.errorLog = action.payload?.error;
}

function enviarComprobanteEmailSuccess(state) {
  state.emailComprobante = { error: false, enviado: true };
}
function enviarComprobanteEmailError(state) {
  state.emailComprobante = { error: true, enviado: false };
}
function fetchDetalleCapitalizacionSuccess(state, action) {
  state.detalleCapitalizacion = {
    ...action.payload,
    error: false,
    fetched: true,
    errorLog: null,
  };
}

function fetchDetalleCapitalizacionError(state, action) {
  state.detalleCapitalizacion = {
    error: true,
    fetched: false,
    errorLog: action.payload?.error
  };
}


function fetchComprobanteCapitalizacionSuccess(state, action) {
  state.comprobanteCapitalizacion = action.payload.data;
}

function refreshHabilitadoCapitalizar(state) {
  state.habilitado = {}
}

const fetchProductosDisponiblesCapitalizacion = createAction(
  'fetchProductosDisponiblesCapitalizacion'
);
const fetchTarjetasProductosDisponiblesCapitalizacion = createAction(
  'fetchTarjetasProductosDisponiblesCapitalizacion'
);
const fetchHabilitadoCapitalizar = createAction('fetchHabilitadoCapitalizar');
const capitalizarRemanente = createAction('capitalizarRemanente');
const fetchDetalleCapitalizacion = createAction('fetchDetalleCapitalizacion');
const fetchComprobanteCapitalizacion = createAction('fetchComprobanteCapitalizacion');
const enviarComprobanteEmail = createAction('enviarComprobanteEmail');

// ------------- connection ------------- //

const capitalizacionReducer = createSlice({
  name: 'capitalizacion',
  initialState,
  reducers: {
    fetchProductosDisponiblesCapitalizacionSuccess,
    fetchTarjetasProductosDisponiblesCapitalizacionSuccess,
    fetchProductosDisponiblesCapitalizacionError,
    fetchTarjetasProductosDisponiblesCapitalizacionError,
    fetchHabilitadoCapitalizarSuccess,
    fetchHabilitadoCapitalizarError,
    capitalizarRemanenteSuccess,
    capitalizarRemanenteError,
    fetchDetalleCapitalizacionSuccess,
    fetchDetalleCapitalizacionError,
    fetchComprobanteCapitalizacionSuccess,
    enviarComprobanteEmailSuccess,
    enviarComprobanteEmailError,
    refreshHabilitadoCapitalizar,
  },
});

export const capitalizacionActions = {
  ...capitalizacionReducer.actions,
  fetchProductosDisponiblesCapitalizacion,
  fetchTarjetasProductosDisponiblesCapitalizacion,
  fetchHabilitadoCapitalizar,
  capitalizarRemanente,
  fetchDetalleCapitalizacion,
  fetchComprobanteCapitalizacion,
  enviarComprobanteEmail,
};

export default capitalizacionReducer.reducer;
