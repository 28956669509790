import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { tarjetaCreditoActions } from '../../../../../application/actions/tarjetaCredito';

import PagarTarjetaComprobanteTemplate from '../../../../components/templates/TarjetaDeCredito/PagarTarjetaComprobante';
import { dateFormat } from '../../../../utils/dates';
import { capitalize, hiddeNumber } from '../../../../utils/functions';
import { useShallowStore } from '../../../../utils/hooks';
import { registerGA } from '../../../../utils/metrics';

const PagarTarjetaComprobante = (props) => {
  const { email, monto, history, codigoProductoPago } = props;

  const dispatch = useDispatch();

  const pagoId = useShallowStore(({ entities }) => entities.tarjetaCredito.pagoConfirmado);
  const detalles = useShallowStore(({ entities }) => entities.tarjetaCredito.pagoComprobante);
  const error = useShallowStore(({ entities }) => entities.tarjetaCredito.pagoComprobanteError);
  const loading = useShallowStore(({ ui }) => ui.loaders.isLoadingPagarTarjetaCreditoComprobante);

  const { cuentaOrigen, tarjeta, fechaPago, horaPago, numeroTransaccion } = detalles || {};

  const detallesComprobante = useMemo(
    () => [
      {
        id: 1,
        name: 'Cuenta de Origen',
        value: `No. ${cuentaOrigen || ''}`,
      },
      {
        id: 2,
        name: 'Método de pago',
        value: `${codigoProductoPago === 'VI06' ? 'Monedero Digital' : 'Cuenta Vista'} Coopeuch`,
      },
      {
        id: 3,
        name: 'Nº de tarjeta',
        value: hiddeNumber(tarjeta || ''),
      },
      {
        id: 4,
        name: 'Fecha de pago',
        value: `${fechaPago ? capitalize(dateFormat(fechaPago)) : ''} ${horaPago || ''}`,
      },
      {
        id: 5,
        name: 'Transacción número',
        value: numeroTransaccion,
      },
    ],
    [detalles, codigoProductoPago]
  );

  const goHome = useCallback(() => {
    history.push('/ecd/inicio');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/tarjeta-de-credito/pagar/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Ir al home',
    });
  }, [history]);

  const goToTarjetaHome = useCallback(() => {
    history.push('/ecd/productos/tarjeta-de-credito');

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/tarjeta-de-credito/pagar/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a tarjetas de credito',
    });
  }, [history]);

  const fetchComprobante = useCallback(() => {
    dispatch(tarjetaCreditoActions.pagarTarjetaCreditoComprobante({ pagoId }));
  }, [pagoId]);

  const clearLastPago = useCallback(() => {
    dispatch(tarjetaCreditoActions.clearLastPago());
  }, []);

  useEffect(() => {
    fetchComprobante();

    return () => {
      clearLastPago();
    };
  }, []);

  useEffect(() => {
    if (typeof codigoProductoPago === 'string' && monto) {
      registerGA({
        event: 'vpv-privado',
        page: '/tef/tarjeta-de-credito/pagar/comprobante',
        monto,
        producto: `${codigoProductoPago === 'VI06' ? 'Monedero Digital' : 'Cuenta Vista'} Coopeuch`,
      });
    }
  }, [monto, codigoProductoPago]);

  useEffect(() => {
    if (error) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/tarjeta-de-credito/pagar/comprobante',
        'eve-cat': 'Error',
        'eve-lab': 'No se pudo realizar pago',
      });
    }
  }, [error]);

  return (
    <PagarTarjetaComprobanteTemplate
      loading={loading}
      error={error}
      monto={monto}
      detallesComprobante={detallesComprobante}
      correo={email}
      goHome={goHome}
      goToTarjetaHome={goToTarjetaHome}
      fetchComprobante={fetchComprobante}
    />
  );
};

export default PagarTarjetaComprobante;
