import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chartist from 'chartist';
// Styles and UI Components
import Button from '@coopeuch-components/web/atoms/Button';
import Alert from '@coopeuch-components/web/atoms/Alert';
// Components
import { useShallowStore } from '../../../utils/hooks';
import RemanenteCalculo from '../../organism/Remanente/RemanenteCalculo';
import RemanenteOff from '../../organism/Remanente/RemanenteOff';
import RemanenteGraphic from '../../organism/Remanente/RemanenteGraphic';
import MiRemanenteCard from '../../organism/Remanente/MiRemanenteCard';
import RemanenteInfo from '../../organism/Remanente/RemanenteInfo';
// Styles
import { AlertWrapper, ButtonWrapper } from './styles';
import RemanenteTotal from '../../organism/Remanente/RemanenteTotal';
import { agregarformatoPesos } from '../../../utils/formatters';

function _convertToNumber({ monto }) {
  return Number(String(monto).replace(/\./, ''));
}

const Remanente = ({
  onClickCapitalizarButton,
  detalleCapitalizacion,
  habilitadoCapitalizar,
  onClickCobrarButton,
  remanenteMontoTotal,
  isCobroRemanenteWeb,
  errorListaNegraCobroRemanente,
  errorListaNegraCapitalizacionRemanente,
  remanenteCalculo,
  composicion,
  fechaDePago,
  destino,
  tituloAno,
  estado,
  monto,
  grupo,
  sinRemanente,
  loading,
  cobreRemanenteLoading,
  capitalizarLoading,
  switchOnOff,
}) => {
  useEffect(() => {
    if (!loading) {
      const series = composicion
        .filter((item) => item.tipoOperacion.toLowerCase() !== 'total')
        .map(_convertToNumber);
      // eslint-disable-next-line no-new
      new Chartist.Pie(
        '.ct-chart-remanente',
        {
          series:
            series.length && !sinRemanente
              ? series
              : [{ value: 100, className: 'sin-remanente-chart' }],
        },
        {
          donut: true,
          donutWidth: '50%',
          donutSolid: true,
          startAngle: 0,
          showLabel: false,
          responsive: true,
          width: '290px',
          height: '290px',
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [composicion, loading]);

  const remanenteOff = useShallowStore(({ entities }) => entities.remanente.composicionError);

  return (
    <React.Fragment>
      {(!!errorListaNegraCobroRemanente || !!errorListaNegraCapitalizacionRemanente) && (
        <div className="row bmt-3 bmt-md-4">
          <AlertWrapper className="col-xs-12 bpy-0">
            <Alert
              className="alert-error  bp-3 "
              bgType="error"
              id="alert-lista-negra-remanente"
              show
            >
              {`Actualmente no posees una Cuenta Coopeuch para ${errorListaNegraCobroRemanente ? 'Cobrar' : 'Capitalizar'
                } tu remanente. Para más información llámanos al`}{' '}
              <b>600 200 1200</b>
            </Alert>
          </AlertWrapper>
        </div>
      )}
      {!!detalleCapitalizacion.fetched && (
        <div className="row bmt-3 bmt-md-4">
          <AlertWrapper className="col-xs-12 bpy-0">
            <Alert
              className="alert-error col-xs bp-3 "
              bgType="info"
              id="alert-info-capitalizacion-ok"
              show
            >
              La capitalización de tu remanente por{' '}
              {agregarformatoPesos(detalleCapitalizacion.montoCapitalizado)} se ha realizado con
              éxito. Has adquirido {detalleCapitalizacion.cuotasCapitalizadas} Cuotas de
              Participación.
            </Alert>
          </AlertWrapper>
        </div>
      )}
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12 col-md-6 bmb-3 bmb-md-0">
          <div className="w-100 h-100 bpr-md-1">
            <MiRemanenteCard
              {...{ fechaDePago, destino, tituloAno, estado, monto, grupo, sinRemanente, loading, switchOnOff }}
            />
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="w-100 h-100 bpl-md-1">
            <RemanenteTotal {...{ loading, remanenteMontoTotal }} />
          </div>
        </div>
      </div>
      {isCobroRemanenteWeb && (
        <div className="row bmb-3 bmb-md-4">
          <ButtonWrapper className="col-xs-12 text-left">
            <Button
              onClick={onClickCobrarButton}
              bgColor="#e81e2b"
              color="white"
              id="btn-cobrar-remanente"
            >
              Cobrar Mi Remanente
            </Button>
          </ButtonWrapper>
        </div>
      )}
      {habilitadoCapitalizar && (
        <div className="row bmb-3 bmb-md-4">
          <ButtonWrapper className="col-xs-12 text-left">
            <Button
              onClick={onClickCapitalizarButton}
              bgColor="#e81e2b"
              color="white"
              id="btn-capitalizar-remanente"
            >
              Capitalizar Mi Remanente
            </Button>
          </ButtonWrapper>
        </div>
      )}
      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          {remanenteOff ? (
            <RemanenteOff/>
          ) : (
            <RemanenteGraphic {...{ loading, composicion, sinRemanente }} />
          )}
        </div>
      </div>

      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12">
          <RemanenteCalculo remanenteCalculo={remanenteCalculo} />
        </div>
      </div>

      <div className="row bmb-3 bmb-md-4">
        <div className="col-xs-12 text-left">
          <RemanenteInfo />
        </div>
      </div>
    </React.Fragment>
  );
};

Remanente.propTypes = {
  onClickCobrarButton: PropTypes.func,
  isCobroRemanenteWeb: PropTypes.bool,
  habilitadoCapitalizar: PropTypes.bool,
  composicion: PropTypes.arrayOf(PropTypes.object).isRequired,
  fechaDePago: PropTypes.string,
  titulo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  estado: PropTypes.string,
  monto: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  grupo: PropTypes.number,
};

Remanente.defaultProps = {
  onClickCobrarButton: null,
  isCobroRemanenteWeb: false,
  habilitadoCapitalizar: false,
  fechaDePago: '',
  titulo: '',
  estado: '',
  monto: 0,
  grupo: null,
};

export default Remanente;
