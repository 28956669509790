import React from 'react';

import { Text } from './styles';

// import './styles.css';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const Index = ({ title, text }) => {
  return (
    <div className="center-xs">
      <div className="row center-xs">
        <img src={`${staticPath}login_error.png`} alt="login-error" />
      </div>
      <Text textSize={24} size={32} weight="bold">
        {title || '!Lo sentimos!'}
      </Text>
      <div className="col-xs-12 col-md-8 col-md-offset-2">
        <Text textSize={18} size={28} weight="normal">
          {text ||
            'En este momento nuestro servicio no se encuentra disponible. Agradecemos tu comprensión.'}
        </Text>
      </div>
    </div>
  );
};

export default Index;
