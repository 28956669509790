import React, { useMemo } from 'react';

import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import DashboardCard from '../../../organism/DashboardCard';
import Stepper from '../../../../components/organism/Stepper';
import Button from '@coopeuch-components/web/atoms/Button';
import PageTitle from '../../../atoms/PageTitle';
import ErrorCard from '../../../organism/ErrorCard';
import { capitalize } from '../../../../utils/functions';
import { agregarformatoPesos, agregarFormatoDecimal } from '../../../../utils/formatters';
import { dateFormat } from '../../../../utils/dates';
import { SkeletonResultado } from './skeleton';

import {
  resultadoTexts,
  stepperTexts,
  errorCardText,
} from '../../../../containers/DepositoAPlazo/texts';

import Wrapper, { TitleWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ResultadoTemplate = ({
  tarjetaActiva,
  simulaciones,
  handleChangeTarjetaActiva,
  handleReturn,
  handleContinue,
  isLoadingSimulacionMultiple,
  simulacionesError,
}) => {
  const steps = useMemo(() => {
    return [
      stepperTexts.STEPPER_PASO_1,
      stepperTexts.STEPPER_PASO_2,
      stepperTexts.STEPPER_PASO_3,
      stepperTexts.STEPPER_PASO_4,
      stepperTexts.STEPPER_PASO_5,
    ];
  }, []);

  return (
    <React.Fragment>
      <TitleWrapper>
        <PageTitle
          title={resultadoTexts.PAGE_TITLE}
          icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" className="simulador-icon" />}
        />
      </TitleWrapper>
      <Wrapper className="margin-after-title">
        <Stepper className="mb-24 start-xs" position={2} steps={steps} />
        {isLoadingSimulacionMultiple ? (
          <SkeletonResultado />
        ) : (
          <div>
            {simulacionesError ? (
              <div className="bmt-md-0 bmt-3">
                <ErrorCard
                  titleText={errorCardText.ERROR_CARD_TITLE}
                  descText={errorCardText.ERROR_CARD_DESC}
                />
              </div>
            ) : (
              <div>
                <div className="bmt-3 bmt-md-0">
                  <Alert show bgType="info" className="icon-alert">
                    {simulaciones[tarjetaActiva - 1]?.clienteSimulado &&
                      simulaciones[tarjetaActiva - 1]?.reajustado &&
                      resultadoTexts.TOAST_DESC_REAJUSTE}
                    {resultadoTexts.TOAST_DESC_1}
                    {dateFormat(simulaciones[tarjetaActiva - 1]?.fechaVencimiento)} (
                    {simulaciones[tarjetaActiva - 1]?.plazoInversion}
                    {resultadoTexts.TOAST_DESC_2})
                  </Alert>
                </div>

                <div className="bmt-3 bmt-md-4">
                  <DashboardCard classes="dashboard-card-shadow">
                    <DashboardCard.Head
                      title={resultadoTexts.PAGE_CARD_TITLE}
                      borderBottom="complete"
                      classes="simulacion-title-card-head"
                    />
                    <DashboardCard.Body classes="resutado-simulacion-card-body">
                      <div className="bmb-4">
                        <label className="resultado-simulacion-body-desc">
                          {resultadoTexts.CARD_BODY_DESC_1}
                          <b>
                            {resultadoTexts.CARD_BODY_DESC_2}
                            {capitalize(simulaciones[0]?.tipoDepositoPlazo)}
                          </b>
                        </label>
                      </div>
                      <div className="row">
                        <div className="col-md-4 col-xs-12 bmb-3 bmb-md-0 bpr-md-3 bpr-2">
                          <div
                            className="resultado-simulacion-tarjeta"
                            onClick={() => handleChangeTarjetaActiva(1)}
                          >
                            <div
                              className={
                                'resultado-simulacion-header-tarjeta ' +
                                (tarjetaActiva === 1 && 'resultado-active')
                              }
                            >
                              <div className="row">
                                <div className="col-md-2 col-xs-2">
                                  <div
                                    className={
                                      tarjetaActiva === 1 ? 'display-active' : 'display-none'
                                    }
                                  >
                                    <LoadIcon
                                      src={`${iconPath}radio1_on.svg`}
                                      className="resultado-simulacion-icon"
                                    />
                                  </div>
                                  <div
                                    className={
                                      tarjetaActiva !== 1 ? 'display-active' : 'display-none'
                                    }
                                  >
                                    <LoadIcon
                                      src={`${iconPath}radio1_off.svg`}
                                      className="resultado-simulacion-icon"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-10 col-xs-10 p-0 vertical-center">
                                  <label className="resultado-simulacion-header-title">
                                    Plazo {simulaciones[0]?.plazoInversion} días -{' '}
                                    {capitalize(simulaciones[0]?.tipoDepositoPlazo)}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="resultado-simulacion-body-tarjeta">
                              <div className="resultado-tarjeta-descripcion-impar row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.MONTO_VENCIMIENTO}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {agregarformatoPesos(simulaciones[0]?.montoVencimiento)}
                                  </label>
                                </div>
                              </div>
                              <div className="resultado-tarjeta-descripcion-par row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.TASA_BASE}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {agregarFormatoDecimal(simulaciones[0]?.tasaBase)}%
                                  </label>
                                </div>
                              </div>
                              <div className="resultado-tarjeta-descripcion-impar row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.FECHA_VENCIMIENTO}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {capitalize(dateFormat(simulaciones[0]?.fechaVencimiento))}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-12 bmb-3 bmb-md-0 bpl-md-2 bpl-2 bpr-md-2 bpr-2">
                          <div
                            className="resultado-simulacion-tarjeta"
                            onClick={() => handleChangeTarjetaActiva(2)}
                          >
                            <div
                              className={
                                'resultado-simulacion-header-tarjeta ' +
                                (tarjetaActiva === 2 ? 'resultado-active' : '')
                              }
                            >
                              <div className="row">
                                <div className="col-md-2 col-xs-2">
                                  <div
                                    className={
                                      tarjetaActiva === 2 ? 'display-active' : 'display-none'
                                    }
                                  >
                                    <LoadIcon
                                      src={`${iconPath}radio1_on.svg`}
                                      className="resultado-simulacion-icon"
                                    />
                                  </div>
                                  <div
                                    className={
                                      tarjetaActiva !== 2 ? 'display-active' : 'display-none'
                                    }
                                  >
                                    <LoadIcon
                                      src={`${iconPath}radio1_off.svg`}
                                      className="resultado-simulacion-icon"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-10 col-xs-10 p-0 vertical-center">
                                  <label className="resultado-simulacion-header-title">
                                    Plazo {simulaciones[1]?.plazoInversion} días -{' '}
                                    {capitalize(simulaciones[1]?.tipoDepositoPlazo)}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="resultado-simulacion-body-tarjeta">
                              <div className="resultado-tarjeta-descripcion-impar row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.MONTO_VENCIMIENTO}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {agregarformatoPesos(simulaciones[1]?.montoVencimiento)}
                                  </label>
                                </div>
                              </div>
                              <div className="resultado-tarjeta-descripcion-par row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.TASA_BASE}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {agregarFormatoDecimal(simulaciones[1]?.tasaBase)}%
                                  </label>
                                </div>
                              </div>
                              <div className="resultado-tarjeta-descripcion-impar row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.FECHA_VENCIMIENTO}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {capitalize(dateFormat(simulaciones[1]?.fechaVencimiento))}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-12 bmb-md-0 bpl-md-3 bpl-2">
                          <div
                            className="resultado-simulacion-tarjeta"
                            onClick={() => handleChangeTarjetaActiva(3)}
                          >
                            <div
                              className={
                                'resultado-simulacion-header-tarjeta ' +
                                (tarjetaActiva === 3 && 'resultado-active')
                              }
                            >
                              <div className="row">
                                <div className="col-md-2 col-xs-2">
                                  <div
                                    className={
                                      tarjetaActiva === 3 ? 'display-active' : 'display-none'
                                    }
                                  >
                                    <LoadIcon
                                      src={`${iconPath}radio1_on.svg`}
                                      className="resultado-simulacion-icon"
                                    />
                                  </div>
                                  <div
                                    className={
                                      tarjetaActiva !== 3 ? 'display-active' : 'display-none'
                                    }
                                  >
                                    <LoadIcon
                                      src={`${iconPath}radio1_off.svg`}
                                      className="resultado-simulacion-icon"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-10 col-xs-10 p-0 vertical-center">
                                  <label className="resultado-simulacion-header-title">
                                    Plazo {simulaciones[2]?.plazoInversion} días -{' '}
                                    {capitalize(simulaciones[2]?.tipoDepositoPlazo)}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="resultado-simulacion-body-tarjeta">
                              <div className="resultado-tarjeta-descripcion-impar row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.MONTO_VENCIMIENTO}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {agregarformatoPesos(simulaciones[2]?.montoVencimiento)}
                                  </label>
                                </div>
                              </div>
                              <div className="resultado-tarjeta-descripcion-par row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.TASA_BASE}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {agregarFormatoDecimal(simulaciones[2]?.tasaBase)}%
                                  </label>
                                </div>
                              </div>
                              <div className="resultado-tarjeta-descripcion-impar row m-0">
                                <div className="col-md-7 col-xs-7 p-0">
                                  <label className="resultado-simulacion-text-desc">
                                    {resultadoTexts.FECHA_VENCIMIENTO}
                                  </label>
                                </div>
                                <div className="col-md-5 col-xs-5 p-0 btext-right">
                                  <label className="resultado-simulacion-text-desc">
                                    {capitalize(dateFormat(simulaciones[2]?.fechaVencimiento))}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>
                <div className="bmt-4">
                  <div className="row">
                    <div className="col-md-6 display-desktop">
                      <Button
                        asTextLink
                        color="#E81E2B"
                        iconLeft={
                          <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />
                        }
                        onClick={handleReturn}
                        className="button-return p-0"
                      >
                        {resultadoTexts.BOTON_RETURN}
                      </Button>
                    </div>
                    <div className="col-md-6 col-xs-12 btext-right">
                      <Button
                        bgColor="#E81E2B"
                        color="white"
                        onClick={handleContinue}
                        className="button-continue"
                      >
                        {resultadoTexts.BOTON_CONTINUE}
                      </Button>
                    </div>
                    <div className="col-md-6 col-xs-12 display-mobile">
                      <Button
                        asTextLink
                        color="#E81E2B"
                        iconLeft={
                          <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />
                        }
                        onClick={handleReturn}
                        className="button-return p-0"
                      >
                        {resultadoTexts.BOTON_RETURN}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

ResultadoTemplate.propTypes = {};
ResultadoTemplate.defaultProps = {};

export default ResultadoTemplate;
