import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/ui/loaders';

// ------- SAGA ------ //

function changeStatusLoaderDestinatarios(state, action) {
  const { isLoader } = action.payload;
  state.isLoaderDestinatarios = isLoader;
}

function changeStatusLoaderDeleteDestinatarios(state, action) {
  const { isLoader } = action.payload;
  state.isLoaderDeleteDestinatarios = isLoader;
}

function fetchLoadingDatosDestinatario(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingDatosDestinatario = isLoading;
}

function changeStatusLoadingReglasTerceros(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingReglasTerceros = isLoading;
}

function changeStatusLoadingFactoresSeguridad(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingFactoresSeguridad = isLoading;
}

function changeStatusLoadingFactorSeguridad(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingDesafioYValidarFactorSeguridad = isLoading;
}

function changeStatusLoadingSeleccionarDestinatario(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingSeleccionarDestinatario = isLoading;
}

function fetchLoadingCuentasAhorro(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingCuentasAhorro = isLoading;
}

function fetchLoadingMigracionCuentaAhorroFatca(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingMigracionCuentaAhorroFatca = isLoading;
}

function changeStatusLoadingDesafioSMS(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDesafioSMS = isLoading;
}

function changeStatusLoadingValidarSMS(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingValidarSMS = isLoading;
}

function changeStatusLoadingDatosPersonales(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDatosPersonales = isLoading;
}

function changeStatusLoadingValidarTransferencia(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingValidarTransferencia = isLoading;
}

function changeStatusLoadingEjecutarTransferencia(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingEjecutarTransferencia = isLoading;
}

function fetchLoadingCuentaParticipacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCuotaParticipacion = isLoading;
}
////////////////////////////////////////////////
function fetchLoadingActualizacionCuotaParticipacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingActualizacionCuotaParticipacion = isLoading;
}
function fetchLoadingBorradorDescargarCuotaParticipacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingBorradorDescargarCuotaParticipacion = isLoading;
}

function fetchLoadingDescargarComprobanteModificacionCuotaParticipacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDescargarComprobanteModificacionCuotaParticipacion = isLoading;
}
////////////////////////////////////////////////

function fetchLoadingFactoresCuotaParticipacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingFactoresCuotaParticipacion = isLoading;
}

function fetchLoadingSugerenciaCuotaParticipacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingSugerenciaCuotaParticipacion = isLoading;
}

function fetchLoadingCapitalizarRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCapitalizarRemanente = isLoading;
}

function fetchLoadingDetalleCapitalizacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDetalleCapitalizacion = isLoading;
}

function fetchLoadingSelectTefEntreProducto(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingSelectTefEntreProductos = isLoading;
}

function fetchLoadingReglasEntreProductos(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingReglasEntreProductos = isLoading;
}

function fetchLoadingContextoAte(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingContextoAte = isLoading;
}

function fetchLoadingCampanaCrm(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingCampanaCrm = isLoading;
}

function fetchLoadingMonederoValidar(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingMonederoValidar = isLoading;
}

function fetchLoadingValidacionTefEntreProductos(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingValidacionTefEntreProductos = isLoading;
}

function fetchLoadingEjecucionTefEntreProductos(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingEjecucionTefEntreProductos = isLoading;
}

function fetchLoadingIndicadorEconomico(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingIndicadorEconomico = isLoading;
}

function fetchLoadingHistorialTransferencias(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingHistorialTransferencias = isLoading;
}

function fetchLoadingValidarRutOCrearClave(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingValidarRutOCrearClave = isLoading;
}

function fetchLoadingReglasCrearClave(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingReglasCrearClave = isLoading;
}

function fetchLoadingSelectMetodo(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingSelectMetodo = isLoading;
}

function fetchLoadingCuentasVista(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingCuentasVista = isLoading;
}

function fetchLoadingTarjetasCuentasVista(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingTarjetasCuentasVista = isLoading;
}

function fetchLoadingUltMovimientosCuentasVista(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingUltMovimientosCuentasVista = isLoading;
}

function fetchLoadingMovimientosCuentasAhorro(state, action) {
  const { isLoading } = action.payload;

  state.isLoadingMovimientosCuentasAhorro = isLoading;
}

function fetchLoadingDetalleCuentasAhorro(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDetalleCuentasAhorro = isLoading;
}

function fetchLoadingTarjetasCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingTarjetasCredito = isLoading;
}

function fetchLoadingFacturacionTarjetaCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingFacturacionTarjetaCredito = isLoading;
}

function fetchLoadingCupoTarjetaCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCupoTarjetaCredito = isLoading;
}

function fetchPatPassTarjetaCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingPatPassTarjetaCredito = isLoading;
}

function fetchLoadingPagarTarjetaCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingPagarTarjetaCredito = isLoading;
}

function fetchLoadingPagarTarjetaCreditoComprobante(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingPagarTarjetaCreditoComprobante = isLoading;
}

function fetchLoadingCupoTarjetaAdicionales(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCupoTarjetaAdicionales = isLoading;
}

function fetchMovimientosTarjetaCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingMovimientosTarjetaCredito = isLoading;
}

function fetchLoadingPDFTarjetaCredito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingPDFTarjetaCredito = isLoading;
}

function fetchLoadingDepositosContratados(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDepositosContratados = isLoading;
}

function fetchLoadingLiquidarDAP(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingLiquidarDAP = isLoading;
}

function fetchLoadingComprobanteLiquidar(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingComprobanteLiquidar = isLoading;
}

function fetchLoadingSimulacionMultiple(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingSimulacionMultiple = isLoading;
}

function fetchLoadingDAPSimulacion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDAPSimulacion = isLoading;
}

function seleccionarSimulacionDAPLoading(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingSeleccionarSimulacionDAP = isLoading;
}

function fetchLoadingDAPInvertir(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDAPInvertir = isLoading;
}

function fetchLoadingDAPComprobanteInvertir(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDAPComprobanteInvertir = isLoading;
}

function fetchLoadingTarjetasCoordenadas(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingTarjetasCoordenadas = isLoading;
}

function fetchLoadingTarjetasDebito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingTarjetasDebito = isLoading;
}

function fetchLoadingIniciarFlujo(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingIniciarFlujo = isLoading;
}

function fetchLoadingDashboardProductos(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDashboardProductos = isLoading;
}

function fetchLoadingValidarSolicitudCV(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingValidarSolicitudCV = isLoading;
}
function fetchLoadingContratosCuentaAhorro(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingContratosCuentaAhorro = isLoading;
}

function fetchLoadingBorradorContratoCuentaAhorro(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingBorradorContratoCuentaAhorro = isLoading;
}

function fetchLoadingMigrarCuentaAhorro(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingMigrarCuentaAhorro = isLoading;
}

function fetchLoadingCertificadoSII(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCertificadoSII = isLoading;
}

function fetchLoadingBancos(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingBancos = isLoading;
}

function fetchLoadingTipoCuentas(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingTipoCuentas = isLoading;
}

function fetchLoadingReglasFactoresSeguridad(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingReglasFactoresSeguridad = isLoading;
}

function fetchLoadingCambioEstadoTjCoord(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCambioEstadoTjCoord = isLoading;
}

function fetchLoadingCambioEstadoTjDebito(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCambioEstadoTjDebito = isLoading;
}

function fetchLoadingMotivoTarjetaBloqueo(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingMotivoTarjetaBloqueo = isLoading;
}
function fetchLoadingRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingRemanente = isLoading;
}

function fetchLoadingContenidoRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingContenidoRemanente = isLoading;
}

function fetchLoadingComposicionRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingComposicionRemanente = isLoading;
}

function fetchLoadingObtenerCuentasRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingObtenerCuentasRemanente = isLoading;
}

function fetchLoadingObtenerTarjetasRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingObtenerTarjetasRemanente = isLoading;
}

function fetchLoadingCobrarRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCobrarRemanente = isLoading;
}

function fetchLoadingComprobanteCobroRemanente(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingComprobanteCobroRemanente = isLoading;
}

function fetchLoadingContratacionMonedero(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingContratacionMonedero = isLoading;
}

function fetchLoadingBannerContratacionCV(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingBannerContratacionCV = isLoading;
}

function fetchLoadingSucursalesComuna(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingSucursalesComuna = isLoading;
}
function fetchLoadingBeneficios(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingBeneficios = isLoading;
}

function fetchLoadingRubro(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingRubro = isLoading;
}

function fetchLoadingRegion(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingRegion = isLoading;
}

function fetchLoadingHabilitadoCelular(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingHabilitadoCell = isLoading;
}

function fetchLoadingHabilitadoCorreo(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingHabilitadoEmail = isLoading;
}

function fetchLoadingDatosContacto(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDatosContacto = isLoading;
}

function fetchLoadingEditDatosContacto(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingEditDatosContacto = isLoading;
}

function fetchLoadingDireccionObtener(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingDireccionObtener = isLoading;
}

function fetchLoadingEditDirecComercial(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingEditDireccionComercial = isLoading;
}

function fetchLoadingListaNegra(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingListaNegra = isLoading;
}

function fetchLoadingContratable(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingContratable = isLoading;
}

function fetchLoadingContratado(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingContratado = isLoading;
}

function fetchLoadingValidarTarjetaCoordenadas(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingValidarTarjetaCoordenadas = isLoading;
}

function fetchLoadingValidarAptoAvance(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingValidarAptoAvance = isLoading;
}

function fetchLoadingValidarAlivioTc(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingValidarAlivioTc = isLoading;
}

function fetchLoadingReprogramacionCuotas(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingReprogramacionCuotas = isLoading;
}

function fetchLoadingCertificadoProdVigentes(state, action) {
  const { isLoading } = action.payload;
  state.isLoadingCertProdVigentes = isLoading;
}

const loaderDestinatarios = createAction('loaderDestinatarios');
const loaderDeleteDestinatarios = createAction('loaderDeleteDestinatarios');
const loaderReglasTerceros = createAction('loaderReglasTerceros');
const loaderFactoresSeguridad = createAction('loaderFactoresSeguridad');
const loaderSeleccionarDestinatario = createAction('loaderSeleccionarDestinatario');

const loadersReducer = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    changeStatusLoaderDestinatarios,
    changeStatusLoaderDeleteDestinatarios,
    fetchLoadingDatosDestinatario,
    changeStatusLoadingReglasTerceros,
    changeStatusLoadingFactoresSeguridad,
    changeStatusLoadingFactorSeguridad,
    changeStatusLoadingSeleccionarDestinatario,
    fetchLoadingCuentaParticipacion,
    fetchLoadingFactoresCuotaParticipacion,
    fetchLoadingCapitalizarRemanente,
    fetchLoadingDetalleCapitalizacion,
    fetchLoadingCuentasAhorro,
    fetchLoadingMigracionCuentaAhorroFatca,
    changeStatusLoadingDesafioSMS,
    changeStatusLoadingValidarSMS,
    changeStatusLoadingDatosPersonales,
    changeStatusLoadingValidarTransferencia,
    changeStatusLoadingEjecutarTransferencia,
    fetchLoadingReglasEntreProductos,
    fetchLoadingValidacionTefEntreProductos,
    fetchLoadingEjecucionTefEntreProductos,
    fetchLoadingSelectTefEntreProducto,
    fetchLoadingIndicadorEconomico,
    fetchLoadingHistorialTransferencias,
    fetchLoadingValidarRutOCrearClave,
    fetchLoadingReglasCrearClave,
    fetchLoadingSelectMetodo,
    fetchLoadingCuentasVista,
    fetchLoadingTarjetasCuentasVista,
    fetchLoadingUltMovimientosCuentasVista,
    fetchLoadingMovimientosCuentasAhorro,
    fetchLoadingDetalleCuentasAhorro,
    fetchLoadingTarjetasCredito,
    fetchLoadingFacturacionTarjetaCredito,
    fetchLoadingCupoTarjetaCredito,
    fetchPatPassTarjetaCredito,
    fetchLoadingPagarTarjetaCredito,
    fetchLoadingPagarTarjetaCreditoComprobante,
    fetchLoadingCupoTarjetaAdicionales,
    fetchMovimientosTarjetaCredito,
    fetchLoadingPDFTarjetaCredito,
    fetchLoadingDepositosContratados,
    fetchLoadingLiquidarDAP,
    fetchLoadingComprobanteLiquidar,
    fetchLoadingSimulacionMultiple,
    fetchLoadingDAPSimulacion,
    seleccionarSimulacionDAPLoading,
    fetchLoadingDAPInvertir,
    fetchLoadingDAPComprobanteInvertir,
    fetchLoadingTarjetasCoordenadas,
    fetchLoadingTarjetasDebito,
    fetchLoadingIniciarFlujo,
    fetchLoadingDashboardProductos,
    fetchLoadingValidarSolicitudCV,
    fetchLoadingContratosCuentaAhorro,
    fetchLoadingBorradorContratoCuentaAhorro,
    fetchLoadingMigrarCuentaAhorro,
    fetchLoadingCertificadoSII,
    fetchLoadingBancos,
    fetchLoadingTipoCuentas,
    fetchLoadingReglasFactoresSeguridad,
    fetchLoadingCambioEstadoTjCoord,
    fetchLoadingCambioEstadoTjDebito,
    fetchLoadingMotivoTarjetaBloqueo,
    fetchLoadingRemanente,
    fetchLoadingContenidoRemanente,
    fetchLoadingComposicionRemanente,
    fetchLoadingObtenerCuentasRemanente,
    fetchLoadingObtenerTarjetasRemanente,
    fetchLoadingCobrarRemanente,
    fetchLoadingComprobanteCobroRemanente,
    fetchLoadingValidarTarjetaCoordenadas,
    fetchLoadingListaNegra,
    fetchLoadingContratable,
    fetchLoadingContratado,
    fetchLoadingHabilitadoCelular,
    fetchLoadingHabilitadoCorreo,
    fetchLoadingDatosContacto,
    fetchLoadingEditDatosContacto,
    fetchLoadingDireccionObtener,
    fetchLoadingEditDirecComercial,
    fetchLoadingBeneficios,
    fetchLoadingRubro,
    fetchLoadingRegion,
    fetchLoadingContratacionMonedero,
    fetchLoadingBannerContratacionCV,
    fetchLoadingSucursalesComuna,
    fetchLoadingContextoAte,
    fetchLoadingMonederoValidar,
    fetchLoadingCampanaCrm,
    fetchLoadingValidarAptoAvance,
    fetchLoadingValidarAlivioTc,
    fetchLoadingSugerenciaCuotaParticipacion,
    fetchLoadingActualizacionCuotaParticipacion,
    fetchLoadingDescargarComprobanteModificacionCuotaParticipacion,
    fetchLoadingBorradorDescargarCuotaParticipacion,
    fetchLoadingReprogramacionCuotas,
    fetchLoadingCertificadoProdVigentes,
  },
});

export const loadersActions = {
  ...loadersReducer.actions,
  loaderDestinatarios,
  loaderDeleteDestinatarios,
  loaderReglasTerceros,
  loaderFactoresSeguridad,
  loaderSeleccionarDestinatario,
};

export default loadersReducer.reducer;
