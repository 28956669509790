import styled from 'styled-components';
import AlertModal from '../../../atoms/AlertModal';


const AlertModalWrapper = styled(AlertModal)`

.scroll{
  height: 350px;
	overflow-y: scroll;

  @media screen and (max-width: 500px) {
    height: 500px;
    }
}

.textAutorizo{
    font-size: 14px;
    line-height: 22px;  
    margin-top: 7px;
  }

  .checkboxTex{
    display: flex;
  }

.textConsentimiento{
  line-height: 22px;
}


.modalBody{
  width: initial !important;
  > div {
    padding: .8rem 2rem;
  }
}
.modalBase{
  padding: 0;
}

.alert-modal-icon-cerrar-container {
  position: relative;
.alert-modal-icon-cerrar {
  position: absolute;
  top: 2rem;
  right: 1rem;
  }

}
.btnAceptar {
    display: flex;
    justify-content: end;

    button {
      width: 100%;
      max-width: 226px;

      @media screen and (max-width: 500px) {
        width: 100%;
        max-width: initial;
      }
    }
    span {
      font-size: 14px;
      font-family: 'Ubuntu-Medium';
    }

    @media screen and (max-width: 500px) {
      padding: 16px;
      border-top: none;
    }
  }

`;

export const CustomList = styled.ul`
  padding: 0px 10px;

  & > li {
    font-size: 14px;
    line-height: 18px;
    position: relative;
    margin-left: 25px;
    margin-bottom: 6px;

    &::before {
      background-color: #383838;
      border-radius: 100%;
      position: absolute;
      content: '';
      height: 4px;
      width: 4px;
      display: inline-block;
      left: -16px;
      top: 10px;
    }
  }
`;

export default AlertModalWrapper;
