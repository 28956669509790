import styled from 'styled-components';

const CustomList = styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 50%;
      position: absolute;
      content: '';
      display: inline-block;
      margin: auto 16px auto 0;
      height: 6px;
      bottom: 0;
      width: 6px;
      left: -16px;
      top: 0;
    }
  }
`;

export default CustomList;
