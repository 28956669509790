import React from 'react';
import DashboardCard from '../../../organism/DashboardCard';

import Wrapper from './styles';

import { coopeuchPassTexts } from '../../../../containers/Dashboard/texts';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = () => {
  return (
    <Wrapper>
      <div className="bmr-md-1 bmr-0 bmb-md-4 bmb-3 bml-md-1 bml-0">
        <DashboardCard classes="m-0 dashboard-card-shadow producto-dashboard-card">
          <DashboardCard.Body classes="producto-container">
            <div className="display-desktop">
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="producto-title">{coopeuchPassTexts.TITLE}</label>
                    </div>
                    <div className="col-md-6" />
                    <div className="col-md-12 bmt-2 bmb-3">
                      <label className="producto-descripcion">{coopeuchPassTexts.DESC}</label>
                    </div>
                    <div className="col-md-12 producto-app-container">
                      <img src={`${iconPath}icon_ios.svg`} className="producto-icon-ios bmr-2" />
                      <img src={`${iconPath}icon_gp.svg`} className="producto-icon-android" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <img
                    src={`${staticPath}transferencia_passcoopeuch.png`}
                    className="producto-icon-passcoopeuch"
                  />
                </div>
              </div>
            </div>
            <div className="display-mobile">
              <div className="row">
                <div className="col-xs-7">
                  <div className="col-md-6">
                    <label className="producto-title">{coopeuchPassTexts.TITLE}</label>
                  </div>
                  <div className="col-md-6" />
                  <div className="col-md-12 bmt-2 bmb-3">
                    <label className="producto-descripcion">{coopeuchPassTexts.DESC}</label>
                  </div>
                </div>
                <div className="col-xs-5">
                  <img src={`${staticPath}transferencia_passcoopeuch.png`} />
                </div>
                <div className="col-xs-12">
                  <div className="producto-app-container bmt-2">
                    <img src={`${iconPath}appstore.svg`} className="producto-icon-ios bmr-2" />
                    <img src={`${iconPath}google_play.svg`} className="producto-icon-android" />
                  </div>
                </div>
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
    </Wrapper>
  );
};

export default Index;
