import React from 'react';
import Skeleton from 'react-loading-skeleton';
// @Components Design
import { Message, SelectAutocomplete } from '@design-system-coopeuch/web';
// Styles
import Wrapper from './styles';

const FormUniversities = ({ setInstitute, setCareer, dataInstitutes, loading }) => {
  return (
    <Wrapper className="boxUniversities">
      <h2 className="title">Indica el nombre de la institución y la carrera:</h2>
      <Message
        type="info"
        description={
          <p className="alertDescription">
            ¡A tener en cuenta! En la lista, aparecen únicamente las carreras e instituciones que
            aplican para este bono.
          </p>
        }
      />

      <div className="boxSelects">
        <div className="select">
          {!loading ? (
            <SelectAutocomplete
              isSearchable
              label="Institución"
              placeholder="Selecciona una institución"
              noOptionsMessage={() => 'No disponible'}
              selectedValue={['']}
              width="100%"
              onChange={(instituteSelected) => setInstitute(instituteSelected.id)}
              keyPar={['id', 'nombre']}
              options={dataInstitutes?.institutes}
            />
          ) : (
            <Skeleton className="bmb-3 skeleton-class" />
          )}
        </div>
        <div className="select">
          {!loading ? (
            <SelectAutocomplete
              isSearchable
              label="Carrera"
              placeholder="Selecciona una carrera"
              noOptionsMessage={() => 'No disponible'}
              width="100%"
              onChange={(careerSelected) => setCareer(careerSelected.id)}
              selectedValue={['']}
              keyPar={['id', 'nombre']}
              options={dataInstitutes?.careers}
            />
          ) : (
            <Skeleton className="bmb-3 skeleton-class" />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default FormUniversities;
