import React from 'react';

const BorrarIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M14.549375,0.625 C15.08875,0.625 15.565,0.96875 15.735625,1.48 L15.735625,1.48 L16.700625,4.375 L18.75,4.375 C19.095625,4.375 19.375,4.655 19.375,5 C19.375,5.345 19.095625,5.625 18.75,5.625 L18.75,5.625 L16.795625,5.625 L15.068125,18.294375 C14.984375,18.91 14.451875,19.375 13.829375,19.375 L13.829375,19.375 L6.170625,19.375 C5.54875,19.375 5.01625,18.910625 4.931875,18.294375 L4.931875,18.294375 L3.204375,5.625 L1.25,5.625 C0.905,5.625 0.625,5.345 0.625,5 C0.625,4.655 0.905,4.375 1.25,4.375 L1.25,4.375 L3.299375,4.375 L4.264375,1.479375 C4.435,0.968125 4.911875,0.625 5.450625,0.625 L5.450625,0.625 Z M15.534375,5.625 L4.46625,5.625 L6.170625,18.125 L13.829375,18.125 L15.534375,5.625 Z M8.125,6.875 C8.47,6.875 8.75,7.155 8.75,7.5 L8.75,7.5 L8.75,16.25 C8.75,16.595 8.47,16.875 8.125,16.875 C7.78,16.875 7.5,16.595 7.5,16.25 L7.5,16.25 L7.5,7.5 C7.5,7.155 7.78,6.875 8.125,6.875 Z M11.875,6.875 C12.220625,6.875 12.5,7.155 12.5,7.5 L12.5,7.5 L12.5,16.25 C12.5,16.595 12.220625,16.875 11.875,16.875 C11.529375,16.875 11.25,16.595 11.25,16.25 L11.25,16.25 L11.25,7.5 C11.25,7.155 11.529375,6.875 11.875,6.875 Z M14.549375,1.875 L5.450625,1.875 L4.616875,4.375 L15.3825,4.375 L14.549375,1.875 Z"
        id="color-icono"
        fill="#1F1E1E"
      ></path>
    </g>
  </svg>
);

export default BorrarIcon;
