import React from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import Skeleton from 'react-loading-skeleton';

function SkeletonCuenta() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="mb-16 pt-28 pb-28 pl-32 pr-32">
        <div className="row start-xs">
          <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
            <Skeleton height={10} width={174} />
          </div>
          <div className="col-xs-12 pt-54">
            <div className="row">
              <div className="col-xs-6">
                <Skeleton height={10} width={144} />
              </div>
              <div className="col-xs-6">
                <Skeleton height={10} width={134} />
              </div>
            </div>
          </div>
          <div className="col-xs-12 pt-12">
            <div className="row middle-xs">
              <div className="col-xs-6">
                <Skeleton height={47} width={298} />
              </div>
              <div className="col-xs-6">
                <Skeleton height={10} width={174} />
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonEmail() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="mt-16 mb-16 pt-28 pb-28 pl-32 pr-32">
        <div className="row start-xs">
          <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
            <Skeleton height={10} width={174} />
          </div>
          <div className="col-xs-12 pt-54">
            <Skeleton height={47} width="100%" />
          </div>
          <div className="col-xs-12 pt-24">
            <Skeleton height={10} width="70%" />
          </div>

          <div className="col-xs-12 pt-32">
            <Skeleton height={10} width={144} />
          </div>
          <div className="col-xs-12 pt-12">
            <div className="row middle-xs">
              <div className="col-xs-6">
                <Skeleton height={47} width={298} />
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonPassword() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="mt-16 pt-28 pb-28 pl-32 pr-32">
        <div className="row start-xs">
          <div className="col-xs-12">
            <Skeleton height={10} width="60%" />
          </div>
          <div className="col-xs-12 pt-36">
            <Skeleton height={10} width={214} />
          </div>

          <div className="col-xs-5">
            <div className="row">
              <div className="col-xs-12 pt-12 pb-12">
                <Skeleton height={47} width={298} />
              </div>
              <div className="col-xs-12">
                <div className="row">
                  <div className="col-xs-9">
                    <Skeleton height={10} width={174} />
                  </div>
                  <div className="col-xs">
                    <Skeleton height={10} width={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonLiquidacionConfirmar({ children }) {
  return (
    <div className="row">
      {children}

      <div className="col-xs-12 pt-36 pb-36">
        <div className="row middle-xs">
          <div className="col-xs-6 start-xs">
            <Skeleton height={10} width={84} />
          </div>
          <div className="col-xs-6 end-xs">
            <Skeleton height={48} width={161} />
          </div>
        </div>
      </div>
    </div>
  );
}

SkeletonLiquidacionConfirmar.Cuenta = SkeletonCuenta;
SkeletonLiquidacionConfirmar.Email = SkeletonEmail;
SkeletonLiquidacionConfirmar.Password = SkeletonPassword;

export default SkeletonLiquidacionConfirmar;
