import { put, takeLatest, call, spawn } from 'redux-saga/effects';
// actions
import { activacionTarjetasActions } from '../../../application/actions/activacionTarjetas';
// utils
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';
// api
import api from '../../api';

const apiKit = api(process.env.REACT_APP_TARJETA_DEBITO);

export function* fetchGetKit() {
  try {
    const { data, error } = yield call(apiKit.get, 'activacion-tarjetas/kit/v1/obtener');
    if (data) {
      yield put(activacionTarjetasActions.fetchGetKitSuccess({ data }));
    } else {
      yield put(activacionTarjetasActions.fetchGetKitError({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasActions.fetchGetKitError({ error }));
  }
}

export function* fetchValidarTarjetaCoordenadas(action) {
  const { idTarjetaCoordenadas, numeroSerie } = action.payload;

  try {
    const { data, error } = yield call(
      apiKit.post,

      'activacion-tarjetas/tarjetadebito/v1/validar',

      {
        idTarjetaCoordenadas,
        numeroSerie,
      }
    );
    console.log("VALIDA TC: FS", data, ", ", error);

    if (data !== null && data !== undefined) {
      yield put(activacionTarjetasActions.fetchfetchValidarTarjetaCoordenadasSuccess({ data }));
      yield put(activacionTarjetasActions.fetchGetKitSuccess({"data":{
      	"idTarjetaDebito":idTarjetaCoordenadas,"numeroTarjetaDebito":"**** **** **** " + numeroSerie,"activable":true}}));
    } else {
      yield put(activacionTarjetasActions.fetchfetchValidarTarjetaCoordenadasError({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasActions.fetchfetchValidarTarjetaCoordenadasError({ error }));
  }
}

export function* fetchValidarTarjetaDebito(action) {
  const { idTarjetaCoordenadas, numeroSerie } = action.payload;

  try {
    const { data, error } = yield call(
      apiKit.post,

      'activacion-tarjetas/tarjetadebito/v1/validar',

      {
        idTarjetaCoordenadas,
        numeroSerie,
      }
    );

    if (data) {
      yield put(activacionTarjetasActions.fetchValidarTarjetaDebitoSuccess({ data }));
    } else {
      yield put(activacionTarjetasActions.fetchValidarTarjetaDebitoError({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasActions.fetchValidarTarjetaDebitoError({ error }));
  }
}

export function* fetchSendSMSCode() {
  try {
    const { data, error } = yield call(
      apiKit.post,

      'activacion-tarjetas/tercer-factor/v1/solicitar'
    );

    if (data) {
      yield put(activacionTarjetasActions.fetchSendSMSCodeSuccess({ data }));
    } else {
      yield put(activacionTarjetasActions.fetchSendSMSCodeError({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasActions.fetchSendSMSCodeError({ error }));
  }
}

export function* fetchValidateSMSCode(action) {
  const codigo = action.payload;

  try {
    const { data, error } = yield call(apiKit.post, 'activacion-tarjetas/kit/v1/activar', {
      codigo,
    });

    if (data) {
      yield put(activacionTarjetasActions.fetchValidateSMSCodeSuccess({ data }));
    } else {
      yield put(activacionTarjetasActions.fetchValidateSMSCodeError({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasActions.fetchValidateSMSCodeError({ error }));
  }
}

export function* fetchDownloadComprobante() {
  try {
    const { data, error } = yield call(apiKit.get, 'comprobante/v1/obtener');
    if (data) {
      yield put(activacionTarjetasActions.fetchDownloadComprobanteSuccess());
      downloadPDFBase64OtherTab(data.archivo);
    } else {
      yield put(activacionTarjetasActions.fetchDownloadComprobanteError({ error }));
    }
  } catch (err) {
    console.error(err.message, 'fetchDownloadComprobante');
  }
}

export function* fetchGetKitBanner() {
  try {
    const { data, error } = yield call(apiKit.get, 'activacion-tarjetas/banner/v1/obtener');

    if (data) {
      yield put(activacionTarjetasActions.fetchGetKitBannerSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchGetKitBannerError(error));
    }
  } catch (error) {
    yield put(activacionTarjetasActions.fetchGetKitBannerError(error));
  }
}

export function* fetchIsEnabledPassCoopeuch() {
  try {
    const { data, error } = yield call(apiKit.get, 'factor/v1/consultar');
    if (data) {
      yield put(activacionTarjetasActions.fetchIsEnabledPassCoopeuchSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchIsEnabledPassCoopeuchError(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchIsEnabledPassCoopeuch');
  }
}

export function* fetchSolicitarPassCoopeuch() {
  try {
    const { data, error } = yield call(apiKit.post, 'factor/passcoopeuch/v1/solicitar');

    if (data) {
      yield put(activacionTarjetasActions.fetchSolicitarPassCoopeuchSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchSolicitarPassCoopeuchError({ error }));
    }
  } catch (err) {
    console.error(err.message, 'fetchSolicitarPassCoopeuch');
  }
}

export function* fetchValidarPassCoopeuch() {
  try {
    const { data, error } = yield call(apiKit.post, 'factor/passcoopeuch/v1/validar', {
      desafio: '',
    });

    if (data) {
      yield put(activacionTarjetasActions.fetchValidarPassCoopeuchSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchValidarPassCoopeuchError(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchValidarPassCoopeuch');
  }
}

export function* fetchActivateKitCV() {
  try {
    const { data, error } = yield call(apiKit.post, 'activacion-tarjetas/kit/v1/activar', {
      codigo: '',
    });
    if (data) {
      yield put(activacionTarjetasActions.fetchActivateKitCVSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchActivateKitCVError(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchActivateKitCV');
  }
}

export function* fetchSolicitarTarjetaCoordenadas() {
  try {
    const { data, error } = yield call(apiKit.get, 'factor/tarjetacoordenadas/v1/solicitar');
    if (data) {
      yield put(activacionTarjetasActions.fetchSolicitarTarjetaCoordenadasSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchSolicitarTarjetaCoordenadasError(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchSolicitarTarjetaCoordenadas');
  }
}

export function* fetchValidarCoordenadas(action) {
  const { valorDesafio1, valorDesafio2, valorDesafio3 } = action.payload;

  try {
    const { data, error } = yield call(apiKit.post, 'factor/tarjetacoordenadas/v1/validar', {
      valorDesafio1,
      valorDesafio2,
      valorDesafio3,
    });
    if (data) {
      yield put(activacionTarjetasActions.fetchValidarCoordenadasSuccess(data));
    } else {
      yield put(activacionTarjetasActions.fetchValidarCoordenadasError(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchValidarCoordenadas');
  }
}

export function* fetchValidarCoordenadasReset() {
  yield put(activacionTarjetasActions.fetchValidarCoordenadasResetSuccess());
}

export function* fetchValidateSMSCodeReset() {
  yield put(activacionTarjetasActions.fetchValidateSMSCodeResetSuccess());
}

export function* fetchGetKitReset() {
  yield put(activacionTarjetasActions.fetchGetKitResetSuccess());
}

export function* fetchValidarTarjetaCoordenadasReset() {
  yield put(activacionTarjetasActions.fetchValidarTarjetaCoordenadasResetSuccess());
}

export function* fetchSendSMSCodeReset() {
  yield put(activacionTarjetasActions.fetchSendSMSCodeResetSuccess());
}

export function* fetchIsEnabledPassCoopeuchReset() {
  yield put(activacionTarjetasActions.fetchIsEnabledPassCoopeuchResetSuccess());
}

export function* fetchSolicitarPassCoopeuchReset() {
  yield put(activacionTarjetasActions.fetchSolicitarPassCoopeuchResetSuccess());
}

export function* fetchValidarPassCoopeuchReset() {
  yield put(activacionTarjetasActions.fetchValidarPassCoopeuchResetSuccess());
}

export function* fetchActivateKitCVReset() {
  yield put(activacionTarjetasActions.fetchActivateKitCVResetSuccess());
}

export function* watchFetchTarjetasCoordenadas() {
  yield takeLatest(activacionTarjetasActions.fetchGetKit.type, fetchGetKit);
}

export function* watchFetchValidarTarjetaCoordenadas() {
  yield takeLatest(
    activacionTarjetasActions.fetchValidarTarjetaCoordenadas.type,

    fetchValidarTarjetaCoordenadas
  );
}

export function* watchFetchSendSMSCode() {
  yield takeLatest(activacionTarjetasActions.fetchSendSMSCode.type, fetchSendSMSCode);
}

export function* watchfetchValidarTarjetaDebito() {
  yield takeLatest(activacionTarjetasActions.fetchValidarTarjetaDebito.type, fetchValidarTarjetaDebito);
}

export function* watchFetchValidateSMSCode() {
  yield takeLatest(activacionTarjetasActions.fetchValidateSMSCode.type, fetchValidateSMSCode);
}

export function* watchFetchValidateSMSCodeReset() {
  yield takeLatest(
    activacionTarjetasActions.fetchValidateSMSCodeReset.type,
    fetchValidateSMSCodeReset
  );
}

export function* watchFetchDownloadComprobante() {
  yield takeLatest(
    activacionTarjetasActions.fetchDownloadComprobante.type,
    fetchDownloadComprobante
  );
}

export function* watchFetchGetKitReset() {
  yield takeLatest(activacionTarjetasActions.fetchGetKitReset.type, fetchGetKitReset);
}

export function* watchFetchValidarTarjetaCoordenadasReset() {
  yield takeLatest(
    activacionTarjetasActions.fetchValidarTarjetaCoordenadasReset.type,
    fetchValidarTarjetaCoordenadasReset
  );
}

export function* watchFetchSendSMSCodeReset() {
  yield takeLatest(activacionTarjetasActions.fetchSendSMSCodeReset.type, fetchSendSMSCodeReset);
}

export function* watchFetchGetKitBanner() {
  yield takeLatest(activacionTarjetasActions.fetchGetKitBanner.type, fetchGetKitBanner);
}

export function* watchFetchIsEnabledPassCoopeuch() {
  yield takeLatest(
    activacionTarjetasActions.fetchIsEnabledPassCoopeuch.type,
    fetchIsEnabledPassCoopeuch
  );
}

export function* watchFetchSolicitarPassCoopeuch() {
  yield takeLatest(
    activacionTarjetasActions.fetchSolicitarPassCoopeuch.type,
    fetchSolicitarPassCoopeuch
  );
}

export function* watchFetchIsEnabledPassCoopeuchReset() {
  yield takeLatest(
    activacionTarjetasActions.fetchIsEnabledPassCoopeuchReset.type,
    fetchIsEnabledPassCoopeuchReset
  );
}

export function* watchFetchSolicitarPassCoopeuchReset() {
  yield takeLatest(
    activacionTarjetasActions.fetchSolicitarPassCoopeuchReset.type,

    fetchSolicitarPassCoopeuchReset
  );
}

export function* watchFetchValidarPassCoopeuch() {
  yield takeLatest(
    activacionTarjetasActions.fetchValidarPassCoopeuch.type,
    fetchValidarPassCoopeuch
  );
}

export function* watchFetchValidarPassCoopeuchReset() {
  yield takeLatest(
    activacionTarjetasActions.fetchValidarPassCoopeuchReset.type,
    fetchValidarPassCoopeuchReset
  );
}

export function* watchFetchActivateKitCV() {
  yield takeLatest(activacionTarjetasActions.fetchActivateKitCV.type, fetchActivateKitCV);
}

export function* watchFetchActivateKitCVReset() {
  yield takeLatest(activacionTarjetasActions.fetchActivateKitCVReset.type, fetchActivateKitCVReset);
}

export function* watchFetchSolicitarTarjetaCoordenadas() {
  yield takeLatest(
    activacionTarjetasActions.fetchSolicitarTarjetaCoordenadas.type,
    fetchSolicitarTarjetaCoordenadas
  );
}

export function* watchFetchValidarCoordenadas() {
  yield takeLatest(activacionTarjetasActions.fetchValidarCoordenadas.type, fetchValidarCoordenadas);
}

export function* watchFetchValidarCoordenadasReset() {
  yield takeLatest(activacionTarjetasActions.fetchValidarCoordenadasReset.type, fetchValidarCoordenadasReset);
}

export default function* rootSaga() {
  yield spawn(watchFetchTarjetasCoordenadas);
  yield spawn(watchFetchValidarTarjetaCoordenadas);
  yield spawn(watchFetchSendSMSCode);
  yield spawn(watchFetchValidateSMSCode);
  yield spawn(watchFetchDownloadComprobante);
  yield spawn(watchFetchValidateSMSCodeReset);
  yield spawn(watchFetchGetKitReset);
  yield spawn(watchFetchValidarTarjetaCoordenadasReset);
  yield spawn(watchFetchSendSMSCodeReset);
  yield spawn(watchFetchGetKitBanner);
  yield spawn(watchFetchIsEnabledPassCoopeuch);
  yield spawn(watchFetchSolicitarPassCoopeuch);
  yield spawn(watchFetchIsEnabledPassCoopeuchReset);
  yield spawn(watchFetchSolicitarPassCoopeuchReset);
  yield spawn(watchFetchValidarPassCoopeuch);
  yield spawn(watchFetchValidarPassCoopeuchReset);
  yield spawn(watchFetchActivateKitCV);
  yield spawn(watchFetchActivateKitCVReset);
  yield spawn(watchFetchSolicitarTarjetaCoordenadas);
  yield spawn(watchFetchValidarCoordenadas);
  yield spawn(watchFetchValidarCoordenadasReset);
  yield spawn(watchfetchValidarTarjetaDebito);
}
