/* eslint-disable react/require-default-props */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import './style.css'
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import { Radio } from '@design-system-coopeuch/web'
import Skeleton from 'react-loading-skeleton';
import Button from '@coopeuch-components/web/atoms/Button';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';
import DashboardCard from '../../../components/organism/DashboardCard';
import PageTitle from '../../../components/atoms/PageTitle';
import { PageTitleWrapper, TabsWrapper, RightCardWrapper, Hr } from '../styles';
import Investment from '../../../assets/svg/investment.svg'
import SolicitudModificacion from '../SolicitudModificacion';
import Autorizacion from '../Autorizacion';
import Comprobante from '../Comprobante';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';
import ErrorsCuotas from '../Errors';
import AuthButton from '../../../components/atoms/ButtonAuth'

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({
  fetchCuotasParticipacion,
  cuotas,
  sugerenciaCuotas,
  fetchSugerenciaCuotasParticipacion,
  isLoadingSugerenciaCuotaParticipacion,
  errorCuotaActualizacion,
  actualizacionCuotas,
  handleSetErrors,
  handleUpdateCuotas,
  persona,
  // eslint-disable-next-line camelcase
  modify_cuotas,
}) => {

  const [secondStep, setSecondStep] = useState(false)
  const solicitudModificacionRef = useRef(null);
  const [thirdStep, setThirdStep] = useState(false)
  const [clave, setClave] = useState()
  const [baseRut,] = useState(String((persona?.rut || localStorage.getItem('user-rut') || '').replace('-', '')))
  const [validateAuth, setValidateAuth] = useState(true)
  const [errorActualizacion, setErrorActualizacion] = useState(false)
  const secondStepRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    if (!modify_cuotas?.success) {
      history.push('/ecd/inicio');
    }
    // eslint-disable-next-line camelcase
  }, [modify_cuotas])

  useEffect(() => {
    fetchCuotasParticipacion();
    fetchSugerenciaCuotasParticipacion();
  }, [fetchCuotasParticipacion, fetchSugerenciaCuotasParticipacion]);

  useEffect(() => {
    setErrorActualizacion(errorCuotaActualizacion?.error);

    const determineErrorType = (statusCode) => {
      if (statusCode >= 500) {
        return { label: 'Servicio no disponible', accion: 'CP_AUTORIZAR_MODIFICACION' };
      }

      return { label: 'Error desconocido', accion: 'CP_AUTORIZAR_MODIFICACION' };
    }

    const logError = (label, accion) => {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso4',
        'eve-cat': 'Error',
        'eve-lab': label
      });

      const errors = {
        code: errorCuotaActualizacion?.statusCode,
        message: errorCuotaActualizacion?.message
      };
      const logData = {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion,
        informacion_adicional: {}
      };

      logger(errors, logData);
    }

    if (errorCuotaActualizacion?.statusCode) {
      const { label, accion } = determineErrorType(errorCuotaActualizacion?.statusCode);
      logError(label, accion);
    }
  }, [errorCuotaActualizacion]);



  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      'page': '/tef/cuota-participacion/modificacion-cuota/paso1'
    });
  }, []);

  const elegirOtroAporteGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Elegir otro aporte'
    });
  }

  const quieroAumentarGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Quiero aumentar'
    });
  }

  const chooseRecommendendGA = () => {
    registerGA({
      event: 'vpv-privado',
      'page': '/tef/cuota-participacion/modificacion-cuota/paso2',
      'producto': 'Aporte recomendado'
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CP_SELECCIONAR_RECOMENDACION',
      informacion_adicional: {},
    });
  }

  const volverGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Volver'
    });
  }

  useEffect(() => {
    if (thirdStep) {
      registerGA({
        event: 'vpv-privado',
        'page': '/tef/cuota-participacion/modificacion-cuota/paso3',
        'producto': 'Aporte recomendado'
      });
    }
  }, [thirdStep]);


  useEffect(() => {
    if (actualizacionCuotas?.success) {
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CP_AUTORIZAR_MODIFICACION',
        informacion_adicional: {},
      });
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CP_AUMENTAR_CANTIDAD',
        informacion_adicional: {},
      });
    }
  }, [actualizacionCuotas])

  const downloadHistorialGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar solicitud',
      'producto': 'Aporte recomendado'
    });
  }

  const acceptTermsGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Acepto términos y condiciones',
      'producto': 'Aporte recomendado'
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CP_ACEPTAR_TERMINOS',
      informacion_adicional: {},
    });
  }

  const returnGAfirstStep = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
  }

  const returnGAsecondStep = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
      'producto': 'Aporte recomendado'
    });
  }

  const returnGAthirdStep = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso3',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
      'producto': 'Aporte recomendado'
    });
  }

  const authorizeGA = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso3',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar',
      'producto': 'Aporte recomendado'
    });
  }
  const handleClickAuth = (cuotasPactadasPorMes, condsandterms, password) => {
    handleUpdateCuotas(
      cuotaParticipacionActions.fetchActualizacionCuotasParticipacion(
        {
          'params': {
            'cuotasPactadas': cuotasPactadasPorMes,
            'clave': password,
            'aceptoTerminos': condsandterms
          }
        }
      )
    )
  }
  const handleClickQuieroAumentar = () => {
    quieroAumentarGA();
    chooseRecommendendGA();
    setSecondStep(true);

    // Wait for the state to be updated and the component to be rendered
    setTimeout(() => {
      if (secondStepRef.current) {
        secondStepRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 0);
  };


  let sugerenciaDestacada = null;
  const fechaActual = new Date();

  // Obtiene los componentes de la fecha que necesitas
  const dia = fechaActual.getDate();
  const mes = fechaActual.getMonth() + 1; // Los meses empiezan desde 0
  const año = fechaActual.getFullYear();
  const horas = fechaActual.getHours();
  const minutos = fechaActual.getMinutes();
  const formatoDosDigitos = (valor) => (valor < 10 ? `0${valor}` : valor);
  const fechaFormateada = `${dia}-${mes}-${año} ${formatoDosDigitos(horas)}:${formatoDosDigitos(minutos)}`;

  if (sugerenciaCuotas?.data && Array.isArray(sugerenciaCuotas.data) && sugerenciaCuotas.data.length > 0) {
    sugerenciaDestacada = sugerenciaCuotas.data.find((sugerencia) => sugerencia.destacado);
  }
  const result = cuotas?.data?.pactadas?.cuotasPactadas * cuotas?.data?.valorCuota;
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  const formattedResult = formatNumber(result);

  const handleReintentar = (step) => {
    if (step === 1) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuota-participaicon/modificacion-cuota/paso4',
        'eve-cat': 'Error',
        'eve-lab': 'Reintentar - Clave incorrecta'

      });
      handleSetErrors(
        cuotaParticipacionActions.fetchActualizaStatusErrorCuotaParticipacion({ error: false })
      )
    } else {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/cuota-participaicon/modificacion-cuota/paso4',
        'eve-cat': 'Error',
        'eve-lab': 'Cambiar clave - Clave bloqueada'

      });
      localStorage.removeItem('token');
      localStorage.removeItem('user-rut');
      localStorage.removeItem('login-timedate');
      history.push('/recuperar-clave');
    }
  }
  setTimeout(() => {
    if (solicitudModificacionRef.current) {
      solicitudModificacionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, 3000);
  console.log('sugerenciaCuotas:: ', sugerenciaCuotas)
  return (
    <>
      {errorActualizacion &&
        <>
          <PageTitleWrapper>
            {' '}
            <PageTitle
              id="header-cuota-participacion"
              title={<span style={{ fontSize: 18, fontWeight: 700 }}>Cuota de Participación</span>}
              icon={<LoadIcon src={`${iconPath}pie_chart.svg`} color="#333" />}
            />
          </PageTitleWrapper>
          <TabsWrapper className="col-sm-11 margin-container-after-title">
            <DashboardCard className='widthSection'>
              <DashboardCard.Head
                title={<span className='txtTitle-CP'>Autorización</span>}
                borderBottom="complete"
                classes="btext-xs-left bmt-xs-3 bmt-sm-4"
              />
              <DashboardCard.Body classes="text-left-destinatarios black">
                {
                  errorCuotaActualizacion?.code === "AUTH-01" &&
                  <ErrorsCuotas
                    title="¡Lo sentimos!"
                    message="La contraseña es incorrecta. Por favor vuelva a intentar"
                    buttonText="Reintentar"
                    onClick={() => handleReintentar(1)}
                  />
                }
                {
                  errorCuotaActualizacion?.code === "AUTH-02" &&
                  <ErrorsCuotas
                    title="Datos incorrectos"
                    message="La contraseña ingresada es incorrecta. Por favor vuelva a intentar. Recuerda que al tercer intento erróneo, tu clave será bloqueada"
                    buttonText="Reintentar"
                    onClick={() => handleReintentar(1)}
                  />
                }
                {
                  errorCuotaActualizacion?.code === "AUTH-03" &&
                  <ErrorsCuotas
                    title="Clave bloqueada"
                    message="Por tu seguridad, hemos bloqueado tu clave. Si lo deseas puedes volver a cambiar tu clave"
                    buttonText="Cambiar clave"
                    onClick={() => handleReintentar(2)}
                  />
                }
                {
                  errorCuotaActualizacion?.statusCode === 500 &&
                  <ErrorsCuotas
                    title="Servicio no disponible"
                    message="Estamos presentando problemas técnicos. Por favor, intenta más tarde"
                    buttonText="Volver"
                    onClick={() => history.push('/ecd/inicio')}
                  />
                }
              </DashboardCard.Body>
            </DashboardCard>
          </TabsWrapper>
        </>}
      {actualizacionCuotas?.success &&
        <Comprobante
          productoGA="Sugerencia"
          previousCuotasPactadas={cuotas?.data?.pactadas?.cuotasPactadas}
          cuotasPactadas={sugerenciaDestacada?.cantidad}
          montoPagado={sugerenciaDestacada?.monto?.toLocaleString('es', { minimumFractionDigits: 0 })}
          fechaActualizacion={fechaFormateada}
          persona={persona}
        />}
      {!errorCuotaActualizacion?.error && !actualizacionCuotas?.success &&
        <>
          <PageTitleWrapper>
            {' '}
            <PageTitle
              id="header-cuota-participacion"
              title={<span style={{ fontSize: 18, fontWeight: 700 }}>Cuota de Participación</span>}
              icon={<LoadIcon src={`${iconPath}pie_chart.svg`} color="#333" />}
            />
          </PageTitleWrapper>
          <TabsWrapper className="col-sm-11 margin-container-after-title">
            <DashboardCard className='widthSection'>
              <DashboardCard.Head
                title={<span className='txtTitle-CP'>Aumentar tu cuota de participación</span>}
                borderBottom="complete"
                classes="btext-xs-left bmt-xs-3 bmt-sm-4"
              />
              <DashboardCard.Body classes="text-left-destinatarios black">
                <div className="row" style={{ marginTop: 15 }}>
                  <div className="col-md-6 col-xs-12 bmt-3 bmt-md-0">
                    <div className="w-100 h-100 bpr-md-1">
                      <CardBox className="h-100 bmr-md-1">
                        <CardBox.Header>
                          {/* <Radio value="aporte_actual" className="monto-radio" style={{ margin: '0' }}> */}
                          <p style={{ color: '#383838', margin: '0', fontSize: 16, fontWeight: 600 }}>Aporte actual</p>
                          <p style={{ fontSize: 14, marginTop: 4, color: '#747474' }}>Tu pago mensual como socio</p>
                          {/* </Radio> */}
                        </CardBox.Header>
                        <CardBox.Body>

                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '10px'
                          }}
                          >
                            <div>
                              <div style={{ marginBottom: 10 }}>Cuotas</div>
                              <div>
                                <span style={{ fontWeight: 600, fontSize: 18, fontFamily: 'Ubuntu-Bold' }}>
                                  {
                                    isLoadingSugerenciaCuotaParticipacion ?
                                      <Skeleton height={10} width={174} />
                                      :
                                      cuotas?.data?.pactadas?.cuotasPactadas
                                  }
                                </span>
                              </div>
                            </div>
                            <div>
                              <div style={{ marginBottom: 10 }}>Aporte</div>
                              <div>
                                <span style={{ fontWeight: 600, fontSize: 18, fontFamily: 'Ubuntu-Bold' }}>
                                  $
                                  {formattedResult}
                                </span>
                              </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                              <div style={{ marginBottom: 10 }}>Beneficio</div>
                              <div><img src={Investment} alt="Investment Icon" /></div>
                            </div>
                          </div>
                        </CardBox.Body>
                      </CardBox>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12 bmt-3 bmt-md-0">
                    <RightCardWrapper className="w-100 h-100 bpr-md-1">
                      <CardBox className="h-100 bml-md-1 right-card-resumen-cdp brBlue">
                        <CardBox.Header className="cardHeader">
                          <div style={{ display: 'flex', alignItems: 'center', paddingTop: 14, paddingBottom: 14 }}>
                            <Radio
                              checked
                              inline
                              style={{ color: 'white' }}
                              onChange={() => { }}
                            />
                            <p style={{ color: '#383838', margin: '0', fontSize: 16, fontWeight: 600 }}>Aumentar aporte</p>
                          </div>
                        </CardBox.Header>
                        <CardBox.Body className="brTop">

                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            paddingBottom: '10px'
                          }}
                          >
                            <div>
                              <div style={{ marginBottom: 10 }}>Cuotas</div>
                              <div><span style={{ fontWeight: 600, fontSize: 18, fontFamily: 'Ubuntu-Bold' }}>{sugerenciaDestacada?.cantidad}</span></div>
                            </div>
                            <div>
                              <div style={{ marginBottom: 10 }}>Aporte</div>
                              <div>
                                <span style={{ fontWeight: 600, fontSize: 18, fontFamily: 'Ubuntu-Bold' }}>
                                  $
                                  {sugerenciaDestacada?.monto?.toLocaleString('es', { minimumFractionDigits: 0 })}
                                </span>
                              </div>
                            </div>
                            <div style={{ textAlign: 'right' }}>
                              <div style={{ marginBottom: 10 }}>Beneficio</div>
                              <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                <img src={Investment} alt="Investment Icon" />
                                {' '}
                                <img src={Investment} alt="Investment Icon" />
                                {' '}
                                <img src={Investment} alt="Investment Icon" />
                              </div>
                            </div>
                          </div>
                        </CardBox.Body>
                      </CardBox>
                    </RightCardWrapper>
                  </div>
                </div>
                <Hr style={{ marginTop: 30, marginBottom: 25 }} />
                <div className="row flex-column flex-md-row justify-content-md-between align-items-center">
                  <div className="col-12 col-md-8 col-lg-4 mb-2 order-md-2">
                    <Button
                      className="text-14 w-100 mt-md-0"
                      bgColor="#027DB7"
                      color="white"
                      onClick={() => handleClickQuieroAumentar()}
                      style={{
                        maxWidth: '200px',
                        margin: 'auto',
                      }}
                    >
                      Quiero Aumentar
                    </Button>
                  </div>
                  <div className="col-12 col-md-4 col-lg-2 mb-2 order-md-1">
                    <Link
                      to="/ecd/productos/cuota-de-participacion/modificacion"
                      style={{
                        textDecoration: 'underline',
                        fontSize: 14,
                        fontWeight: 600,
                        maxWidth: '200px',
                        margin: 'auto',
                      }}
                      className="d-block w-100 mt-3"
                      onClick={() => elegirOtroAporteGA()}
                    >
                      Elegir otro aporte
                    </Link>
                  </div>
                </div>

              </DashboardCard.Body>
            </DashboardCard>
            <div ref={secondStepRef}>
              <SolicitudModificacion
                ref={solicitudModificacionRef}
                cuotasPactadasPorMes={sugerenciaDestacada?.cantidad}
                secondStep={secondStep}
                setThirdStep={setThirdStep}
                thirdStep={thirdStep}
                downloadHistorialGA={downloadHistorialGA}
                acceptTermsGA={acceptTermsGA}
                secondStepRef
              />
            </div>
            <Autorizacion
              thirdStep={thirdStep}
              setValidateAuth={setValidateAuth}
              clave={clave}
              setClave={setClave}
            />
            <div className="row flex-column-reverse flex-md-row justify-content-md-between align-items-center">
              <div className="col-12 col-md-4 col-lg-2 mb-2 order-md-1">
                <Link
                  to="/ecd/productos/cuota-de-participacion/"
                  className="w-100 mt-md-0 d-md-block d-flex align-items-center justify-content-center"
                  onClick={() => {
                    volverGA()
                    if (!secondStep) {
                      returnGAfirstStep()
                    }
                    if (secondStep && !thirdStep) {
                      returnGAsecondStep()
                    }
                    if (thirdStep) {
                      returnGAthirdStep()
                    }
                  }}
                >
                  <div className="d-flex align-items-center">
                    <LoadIcon src={`${iconPath}arrow_left.svg`} size={24} color="E81E2B" />
                    {' '}
                    Volver
                  </div>
                </Link>

              </div>
              <div className="col-12 col-md-8 col-lg-3 mb-1 order-md-2">
                {/* <Button
                  bgColor="#E81E2B"
                  color="white"
                  fontSize={14}
                  disabled={validateAuth}
                  style={{
                    maxWidth: '200px',
                    margin: 'auto',
                    width: '100%',
                  }}
                  className="d-block w-100 mt-3"
                  onClick={() => {
                    authorizeGA();
                    handleClickAuth(sugerenciaDestacada?.cantidad, thirdStep, clave)
                  }}
                >
                  Autorizar
                </Button> */}

                <AuthButton
                      bgColor="#E81E2B"
                      color="white"
                      fontSize={14}
                      style={{
                        maxWidth: '200px',
                        margin: 'auto',
                        width: '100%',
                      }}
                      disabled={validateAuth}
                      className="d-block w-100 mt-3"
                      onClick={() => {
                        authorizeGA();
                        handleClickAuth(sugerenciaDestacada?.cantidad, thirdStep, clave);
                        setValidateAuth(true);
                      }}
                    >
                      Autorizar
                    </AuthButton>
              </div>
            </div>


          </TabsWrapper>
        </>}
    </>
  );
};

Index.propTypes = {
  errorCuotaActualizacion: PropTypes.shape({
    error: PropTypes.bool,
    code: PropTypes.string,
    statusCode: PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
  actualizacionCuotas: PropTypes.shape({
    success: PropTypes.bool,
  }).isRequired,
  fetchCuotasParticipacion: PropTypes.func.isRequired,
  cuotas: PropTypes.shape({
    data: PropTypes.shape({
      pactadas: PropTypes.shape({
        cuotasPactadas: PropTypes.number,
      }),
      valorCuota: PropTypes.number,
    }),
  }).isRequired,
  fetchSugerenciaCuotasParticipacion: PropTypes.func.isRequired,
  sugerenciaCuotas: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      destacado: PropTypes.bool,
      cantidad: PropTypes.number,
      monto: PropTypes.number,
    })),
  }).isRequired,
  isLoadingSugerenciaCuotaParticipacion: PropTypes.bool.isRequired,
  handleUpdateCuotas: PropTypes.func.isRequired,
  handleSetErrors: PropTypes.func.isRequired,
  persona: PropTypes.shape({
    rut: PropTypes.string,
  }),
  modify_cuotas: PropTypes.shape({
    success: PropTypes.bool,
  }),
};

const mapStateToProps = (store) => ({
  persona: store.entities.persona,
  cuotas: store.entities.cuotaParticipacion.cuotas,
  sugerenciaCuotas: store.entities.cuotaParticipacion.sugerenciaCuotas,
  errorCuotaActualizacion: store.entities.cuotaParticipacion.errorCuotaActualizacion,
  actualizacionCuotas: store.entities.cuotaParticipacion.actualizacionCuotas,
  modify_cuotas: store.entities.cuotaParticipacion.modify_cuotas,
  isLoadingSugerenciaCuotaParticipacion: store.ui.loaders.isLoadingSugerenciaCuotaParticipacion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchSugerenciaCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchSugerenciaCuotasParticipacion());
  },
  handleUpdateCuotas: (params) => {
    dispatch(cuotaParticipacionActions.fetchActualizacionCuotasParticipacion(params));
  },
  handleSetErrors: (params) => {
    dispatch(cuotaParticipacionActions.fetchActualizaStatusErrorCuotaParticipacion(params));
  }
});
Index.displayName = 'Container_CDP_MOD';
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
