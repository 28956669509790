import React from 'react';
import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';

import Typography from '@coopeuch-components/web/atoms/Typography';
import ContratacionBloqueoClave from '../../../ContratacionBloqueoClave';
import PasswordInput from '../../../../atoms/PasswordInput/v2';
import Loading from '../../../../atoms/Loading/Loading';

import { Paragraph } from '../../../../../assets/styles/styled_components';
import './styles.css';

const Index = ({
    password,
    handlePassword,
    passwordInputValidation,
    handleValidationError,
    validationError,
    showPassword,
    onTogglePassword,
    loading,
    checked,
    cardDescription,
    tituloFirma
}) => {
    const opacidad = checked ? 1 : 0.5;
    const containerClass = checked ? '' : 'forbidden-remanente-acreencias-448';
    let content = (
      <div id="input">
        <Paragraph className="mb-16">
          <p>{cardDescription}</p>
        </Paragraph>
        <div className="row">
          <div className="col-xs-12 col-md-5">
            <PasswordInput
              password={password}
              showPassword={showPassword}
              passwordError={passwordInputValidation}
              onTogglePassword={onTogglePassword}
              onHandlePasswordInput={handlePassword}
            />
          </div>
        </div>
      </div>
    );
    // if (validationError) {
    //     content = (
    //       <ContratacionBloqueoClave
    //         id="pass-error"
    //         serverPasswordError={validationError}
    //         onClick={handleValidationError}
    //       />
    //     );
    // }
    if (loading) {
        content = <Loading id="loading" />;
    }

    return (
      <div style={{ opacity: opacidad }} className={containerClass}>
        <CardBox.Header className="containerHeader-remanente-acreencias-448">
          <Typography tag="p" fontSize={22} fontWeight="bold">{tituloFirma}</Typography>
        </CardBox.Header>
        <CardBox.Body>{content}</CardBox.Body>
      </div>
    );
}

Index.propTypes = {
    password: PropTypes.string,
    handlePassword: PropTypes.func.isRequired,
    passwordInputValidation: PropTypes.string,
    handleValidationError: PropTypes.func.isRequired,
    validationError: PropTypes.shape({
        title: PropTypes.string,
        message: PropTypes.string,
        buttonTitle: PropTypes.string,
    }),
    showPassword: PropTypes.bool.isRequired,
    onTogglePassword: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    cardDescription: PropTypes.string,
    tituloFirma: PropTypes.string,
};

Index.defaultProps = {
    password: '',
    passwordInputValidation: '',
    validationError: null,
    loading: false,
    cardDescription: "Al ingresar tu clave internet, estarás firmando electrónicamente de acuerdo a la ley 19.799.",
    tituloFirma: "Firma electrónica",
};

Index.displayName = 'Container_PEAA_AUTORIZA';
export default Index;
