import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';

import { formatCurrency } from '../../../../utils/functions';
import { dateSlash } from '../../../../utils/dates';

const WebRender = (props) => {
  const { fechaVencimiento, fechaPago, numeroDividendo, montopagoclp } = props;
  // console.log(typeof fechaVencimiento);
  return (
    <div>
      <div className="black">
        <div className="row">
          <div className="col-sm-12 col-md-3 pl-0">
            <Typography tag="p">{dateSlash(fechaVencimiento)}</Typography>
          </div>
          <div className="col-md-3">
            <Typography tag="p">{dateSlash(fechaPago)}</Typography>
          </div>
          <div className="col-md-3">
            <div className="row end-xs">
              <Typography tag="p" className="pr-12">
                Dividendo {numeroDividendo}
              </Typography>
            </div>
          </div>
          <div className="col-md-3">
            <div className="row end-xs">
              <Typography tag="p">{formatCurrency(montopagoclp)}</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WebRender.propTypes = {
  fechaVencimiento: PropTypes.string,
  fechaPago: PropTypes.string,
  numeroDividendo: PropTypes.number,
  montopagoclp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

WebRender.defaultProps = {
  fechaVencimiento: '',
  fechaPago: '',
  numeroDividendo: '',
  montopagoclp: 0,
};

export default WebRender;
