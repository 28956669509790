import React from 'react';

// Ui
import Skeleton from 'react-loading-skeleton';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import DashboardCard from '../../../organism/DashboardCard';
import InputLabel from '../../../atoms/InputLabel';
import Select from '../../../atoms/Select';

// Styles
import Message from './styles';

const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DatosDeContacto = ({
  direccionComercial,
  onChangeDirecComercial,

  isloadingDRegiones,
  dataRegiones,
  regionSocioDescripcion,
  onHandleRegion,

  isComunaLoading,
  comunaSocioRegion,
  comunaSocioDescripcion,
  onHandleComuna,

  userUpdate,
  direcComercialRef,
  empleador,
  loadingDatosContacto,
  factoresSeguridad,
}) => {
  return (
    <div ref={direcComercialRef}>
      {/* <DashboardCard className="ml-0 mr-0 bmb-xs-3 bmy-md-4">
        <DashboardCard.Head
          title="Dirección Comercial"
          borderBottom="complete"
          classes="btext-xs-left bmt-xs-3 bmt-sm-4"
        />
        <DashboardCard.Body classes="text-left-destinatarios black"> */}
        <div style={{
          color: '#383638',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: 0,
          height: '70px',
          backgroundColor: 'transparent',
        }}>
          <h5 style={{
              fontSize: 20,
              color: '#383638',
              margin: 0,
              padding: 0,
              alignSelf: 'center',
              fontWeight: 'bold'
          }}>Dirección Comercial</h5>
        </div>
          {/*userUpdate.dComercial && (
            <Message className="mt-24">
              <div className="align">
                <LoadIcon
                  src={`${ICON_PATH}success_circle_check.svg`}
                  size={18}
                  color="#CC284E"
                  className="ml-20 mr-8 green"
                />
                <Typography id="input-success-message" tag="p" fontSize={16} color="#006B14">
                  Los datos fueron editados correctamente.
                </Typography>
              </div>
            </Message>
          )*/}
          <p className="pb-32 bml-1">
            Revisa y edita los datos correspondiente a tu empresa y/o empleador.
          </p>
          <div className="row">
            
            <div className="col-md-12 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loadingDatosContacto ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <>
                    <p>Empleador</p>
                    <br />
                    <span><strong>{empleador}</strong></span>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {isloadingDRegiones ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>

                ) : (
                  <>
                    <p className="text-bold mb-14 black">Región</p>
                    <Select
                      options={dataRegiones}
                      placeholder={regionSocioDescripcion}
                      value={regionSocioDescripcion}
                      onChange={onHandleRegion}
                      disabled={factoresSeguridad?.length === 0 && true}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {isComunaLoading ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-bold mb-14 black">Comuna</p>
                    <Select
                      options={comunaSocioRegion}
                      placeholder={comunaSocioDescripcion}
                      value={comunaSocioDescripcion}
                      onChange={onHandleComuna}
                      disabled={factoresSeguridad?.length === 0 && true}
                    />
                  </>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {direccionComercial === null ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    value={direccionComercial?.calle ?? ''}
                    onChange={onChangeDirecComercial}
                    name="calle"
                    id="calle"
                    size={48}
                    title="Calle"
                  />
                )}
              </div>
            </div>

            <div className="col-md-3 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {direccionComercial === null ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    value={direccionComercial?.numero ?? ''}
                    onChange={onChangeDirecComercial}
                    name="numero"
                    id="numero"
                    size={48}
                    title="Número"
                  />
                )}
              </div>
            </div>

            <div className="col-md-3 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {direccionComercial === null ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    value={direccionComercial?.departamento || ''}
                    onChange={onChangeDirecComercial}
                    name="departamento"
                    id="departamento"
                    size={48}
                    title="Depto/Villa/Oficina/Otros"
                  />
                )}
              </div>
            </div>
          </div>
        {/* </DashboardCard.Body>
      </DashboardCard> */}
    </div>
  );
};

DatosDeContacto.displayName = 'DatosDeContacto_Template';
export default DatosDeContacto;
