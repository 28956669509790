/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/historialTransferencias';

// ------- SAGA ------ //

function fetchHistorialTefSuccess(state, action) {
  const { historial } = action.payload;

  state.historial = historial;
  state.errorFetchHistorial = false;
  state.errorHistorialLog = '';
}

function fetchHistorialTefFailed(state, action) {
  const { errorLog } = action.payload;
  state.historial = [];
  state.errorFetchHistorial = true;
  state.errorHistorialLog = errorLog;
}

const fetchHistorialTransferencias = createAction('fetchHistorialTransferencias');
const descargarHistorial = createAction('descargarHistorial');

const historialTransferenciasReducer = createSlice({
  name: 'historialTransferencias',
  initialState,
  reducers: {
    fetchHistorialTefSuccess,
    fetchHistorialTefFailed,
  },
});

export const historialTransferenciasActions = {
  ...historialTransferenciasReducer.actions,
  fetchHistorialTransferencias,
  descargarHistorial,
};

export default historialTransferenciasReducer.reducer;
