import styled from 'styled-components';

export const SliderPage = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  color: #fff;

  .um-banner-container {
    padding: 24px;
  }

  .um-banner-title {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
  }
  .um-banner-button {
    margin-top: 24px;
    font-weight: 600;
  }

  @media screen and (max-width: 766px) {
    background-image: url(${({ srcMobile }) => srcMobile});

    .display-mobile {
      display: flex;
    }
    .display-desktop {
      display: none;
    }
    .um-banner-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
    .um-banner-button {
      width: 135px;
    }
  }
`;

export const ContentPage = styled.div`
  @media screen and (max-width: 820px) {
    text-align-last: left;
    }
  }
`;
