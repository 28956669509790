import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Skeleton from 'react-loading-skeleton';

import Button from '@coopeuch-components/web/atoms/Button';

import Wrapper, { WrapperSkeleton } from './styles';
import { registerGA } from '../../../../utils/metrics';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

function FullBannerGoToSimulacion() {
  const history = useHistory();

  const handleToGo = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo',
      'eve-cat': 'Click',
      'eve-lab': 'Simular',
    });
    return history?.push(`/ecd/productos/deposito-a-plazo/simulacion`);
  }, [history]);

  return (
    <Wrapper
      src={`${staticPath}banner_dap_desk.jpg`}
      srcMobile={`${staticPath}banner_dap_mobile.jpg`}
    >
      <div className="start-xs">
        <div>
          <p className="white text-bold text-size-xl hide-on-mobile">Nuevo Depósito a Plazo</p>
          <p className="white text-bold text-size-xl hide-on-desktop">Depósito a Plazo.</p>
        </div>

        <p className="white second-paragraph">
          Descubre una excelente alternativa de inversión en Coopeuch.
        </p>

        <div className="mb-24">
          <Button
            bgColor="#E81E2B"
            color="white"
            className="text-size-s"
            id="btn-dap"
            onClick={handleToGo}
          >
            Simular Depósito a Plazo
          </Button>
        </div>
      </div>
    </Wrapper>
  );
}

function SkeletonLoading() {
  return (
    <WrapperSkeleton className="bmb-3 bmb-md-4">
      <Skeleton height={200} width="100%" />
    </WrapperSkeleton>
  );
}

FullBannerGoToSimulacion.Skeleton = SkeletonLoading;

export default FullBannerGoToSimulacion;
