import { combineReducers } from 'redux';
import buttonReducer from '@coopeuch-components/web/atoms/ButtonWithSpinner/reducer';
import { PoolActFinancieraReducer } from '@coopeuch-project/react-micro-actfinanciera';
import { PoolFormalizacionReducer } from '@coopeuch-project/react-micro-formalizacion';

// Reducers
import login from './ui_login';
import layout from './ui_layout';
import products from './products';
import remanente from './remanente';
import transferencias from './transferencias';
import tefEntreProductos from './transferenciaEntreProductos';
import monedero from './monedero';
import user from './user';
import loaders from './ui_loaders';
import factoresSeguridad from './factoresSeguridad';
import cuentasAhorro from './cuentaAhorro';
import migracionCuentaAhorro from './migracionCuentaAhorro';
import creditoHipotecario from './creditoHipotecario';
import abonoAcreenciasRemanente from './abonoAcreenciasRemanente';
import persona from './persona';
import credito from './credito';
import creditoConsumoSimulador from './creditoConsumoSimulador';
import convenio from './convenio';
import cuotaParticipacion from './cuotaParticipacion';
import capitalizacion from './capitalizacion';
import indicadorEconomico from './indicadorEconomico';
import tipo from './tipo';
import solicitudCuentaVista from './solicitudCuentaVista';
import historialTransferencias from './historialTransferencias';
import gestionClave from './gestionClave';
import tarjetaCredito from './tarjetaCredito';
import listaNegra from './listaNegra';
import configuracion from './configuracion';
import depositoAPlazo from './depositoAPlazo';
import beneficios from './beneficios';
import certificadosSII from './certificadosSII';
import seguros from './seguros';
import activacionTarjetas from './activacionTarjetas';
import activacionTarjetasKit from './activacionTarjetasKit';
import { PoolReliquidacionReducer } from '@coopeuch-project/react-micro-reliquidacion';
import empleador from './empleador';
import tarjetaCoordenada from './tarjetaCoordenada';
import contextoAte from './ate';
import ftu from './ftu';
import puntos from './puntos';
import monederoValidar from './upgradeMonedero';
import { PoolBarraEcommerceReducer } from '@coopeuch-project/barra-ecommerce';
import perfilComercial from './perfilComercial';
import campanaCrm from './campanaCrm';
import bonoEscolar from './bonoEscolar';
import avanceTarjetaCredito from './avanceTarjetaCredito';
import norma484 from './norma484';
import aliviotc from './aliviotc';
import desbloqueoTd from './desbloqueoTd';
import certificadosProdVigentes from './certificadosProdVigentes';

const reducers = combineReducers({
  entities: combineReducers({
    products,
    remanente,
    transferencias,
    tefEntreProductos,
    monedero,
    user,
    factoresSeguridad,
    cuentasAhorro,
    migracionCuentaAhorro,
    creditoHipotecario,
    abonoAcreenciasRemanente,
    persona,
    empleador,
    credito,
    creditoConsumoSimulador,
    convenio,
    cuotaParticipacion,
    capitalizacion,
    indicadorEconomico,
    tipo,
    solicitudCuentaVista,
    historialTransferencias,
    gestionClave,
    tarjetaCredito,
    listaNegra,
    configuracion,
    depositoAPlazo,
    beneficios,
    certificadosSII,
    seguros,
    activacionTarjetas,
    activacionTarjetasKit,
    tarjetaCoordenada,
    contextoAte,
    ftu,
    puntos,
    avanceTarjetaCredito,
    monederoValidar,
    perfilComercial,
    campanaCrm,
    bonoEscolar,
    norma484,
    aliviotc,
    certificadosProdVigentes,
    desbloqueoTd,
  }),
  ui: combineReducers({
    login,
    layout,
    loaders,
    ...buttonReducer,
  }),
  actfinanciera: PoolActFinancieraReducer,
  formalizacion: PoolFormalizacionReducer,
  reliquidacion: PoolReliquidacionReducer,
  barraEcommerce: PoolBarraEcommerceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    state = undefined;
  }
  return reducers(state, action);
};

export default rootReducer;
