import React from 'react';
// UI Components
import Typography from '@coopeuch-components/web/atoms/Typography';
import StepsActions from '../../../organism/Stepper/StepsActions';

// Styles
import { colors } from '../../../../assets/styles';
import { CustomList, CardBox } from './styles';

const Caracteristicas = ({ backButton, nextButton }) => {
  return (
    <React.Fragment>
      <CardBox id="caracteristicas">
        <CardBox.Header>Cobra tu remanente a través de Monedero Digital</CardBox.Header>
        <CardBox.Body>
          <div>
            <Typography tag="p" color={colors.black} id="descripcion-caracteristicas">
              Para poder cobrar tu Remanente de manera digital,{' '}
              <strong>te invitamos a conocer y contratar Monedero Digital Coopeuch.</strong>
            </Typography>
          </div>
          <div className="mt-16">
            <Typography tag="p" color={colors.black} fontWeight={600}>
              Características:
            </Typography>
            <CustomList>
              <li className="mt-8 mb-8">Costo $0</li>
              <li className="mt-8 mb-8">
                Realiza transferencias desde tu Cuenta de Ahorro Coopeuch
              </li>
              <li className="mt-8 mb-8">Transfiere tu dinero a tus cuentas desde otros bancos</li>
              <li className="mt-8 mb-8">
                Cuenta Vista 100% digital sin tarjeta de débito asociada
              </li>
              <li className="mt-8 mb-8">
                Recibe tu remanente y los saldos disponibles a tu nombre
              </li>
            </CustomList>
          </div>
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmy-3 bmy-md-4"
        backAction={backButton}
        backText={'No por ahora'}
        nextAction={nextButton}
        id="contratacion-paso-1"
      />
    </React.Fragment>
  );
};

export default Caracteristicas;
