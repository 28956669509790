const initState = {
  kitDataKit: { data: null, error: null },
  validateTCKit: { data: null, error: null },
  smsCodeKit: { data: null, error: null },
  validateSMSKit: { data: null, error: null },
  downloadPDFKit: { error: null },
  availablePassCoopeuchKit: { data: null, error: null },
  solicitarPassCoopeuchKit: { data: null, error: null },
  validarPassCoopeuchKit: { data: null, error: null },
  activateKitKit: { data: null, error: null },
  kitDataBannerKit: { data: null, error: null },
};

export default initState;
