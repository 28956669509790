import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import List from '@coopeuch-components/web/molecules/List';
import RadioGroup, { Radio } from '@coopeuch-components/web/atoms/RadioGroup';
import Tooltip from '@coopeuch-components/web/atoms/Tooltip';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';

import Stepper from '../../../../organism/Stepper';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import ErrorCard from '../../../../organism/ErrorCard';

import Wrapper, { CuentaSelecionadaWrapper, RadioWrapper } from './styles';
import { standarizeDate } from '../../../../../utils/dates';
import { formatCurrency } from '../../../../../utils/functions';

const beneficios = [
  {
    id: '1',
    name: 'Ya no necesitarás la libreta para realizar giros en Sucursales Coopeuch.',
    classes: 'bullet-red pb-16 start-li',
  },
  {
    id: '2',
    name: 'Continúa ganando intereses anuales manteniendo la fecha de apertura original.',
    classes: 'bullet-red pb-16 start-li',
  },
  {
    id: '3',
    name: 'No tendrás que pagar costos de mantención ni comisión.',
    classes: 'bullet-red pb-16 start-li',
  },
  {
    id: '4',
    name: 'Conserva el número de tu cuenta.',
    classes: 'bullet-red pb-16 start-li',
  },
  {
    id: '5',
    name: 'Deposita transfiriendo desde cualquier Banco o Cuenta Vista Coopeuch.',
    classes: 'bullet-red pb-16 start-li',
  },
  {
    id: '6',
    name: 'Realiza giros hacia tu Cuenta Vista Coopeuch.',
    classes: 'bullet-red start-li',
  },
];

const steps = ['Datos Cuenta', 'Contratación', 'Comprobante'];
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const MigracionCuentaAhorroDatosCuentaTemplate = (props) => {
  const {
    tributaEnExtranjero,
    cuenta,
    error,
    loading,
    handleTributaEnExtranjero,
    goBack,
    handleSubmit,
  } = props;

  const { descripcionProducto, numeroCuenta, fechaApertura, saldoDisponible } = cuenta || {};

  const unavailable = useMemo(
    () => tributaEnExtranjero === 1 || tributaEnExtranjero === null,
    [tributaEnExtranjero]
  );

  return (
    <Wrapper className="bpx-3 bpx-md-5">
      {error ? (
        <div className="bmt-md-4 bmt-3">
          <ErrorCard
            titleText="¡Lo sentimos!"
            descText="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          />
        </div>
      ) : (
        <div className="row">
          <div className="col-xs-12">
            <Stepper className="start-xs bmt-md-4 bmt-3" position={1} steps={steps} />
          </div>
          <div className="col-xs-12">
            <CardBox className="bmt-md-4 bmt-3">
              <CardBox.Header>
                <Typography tag="p" fontSize={20} className="title-line-height">
                  ¿Cuáles son los beneficios de actualizar tu Cuenta de Ahorro?
                </Typography>
              </CardBox.Header>
              <CardBox.Body>
                <List classes="list-dashboard" itemList={beneficios} />
              </CardBox.Body>
            </CardBox>
          </div>

          <div className="col-xs-12">
            <CardBox className="bmt-md-4 bmt-3">
              <CardBox.Header>Cuenta de Ahorro Seleccionada:</CardBox.Header>
              <CardBox.Body>
                <CuentaSelecionadaWrapper className="white">
                  <div className="web-display hide-on-mobile">
                    <div>
                      <p className="text-size-s">Cuenta de Ahorro</p>
                      <p className="text-size-xxl text-bold">{descripcionProducto}</p>
                    </div>

                    <div>
                      <p className="text-size-s">Número de Cuenta</p>
                      <p className="text-size-xxl text-bold">{numeroCuenta}</p>
                    </div>
                    <div>
                      <p className="text-size-s">Fecha de Apertura</p>
                      <p className="text-size-xxl text-bold">
                        {standarizeDate(fechaApertura?.split('T')?.[0])}
                      </p>
                    </div>
                    <div className="end-row">
                      <p className="text-size-s">Saldo</p>
                      <p className="text-size-xxl text-bold">{formatCurrency(saldoDisponible)}</p>
                    </div>
                  </div>
                  <div className="hide-on-desktop text-size-s">
                    <div className="pb-16">
                      Cuenta de Ahorro: <b>{descripcionProducto}</b>
                    </div>
                    <div className="pb-16">
                      Número de Cuenta: <b>{numeroCuenta}</b>
                    </div>
                    <div className="pb-16">
                      Fecha de Apertura:{' '}
                      <b>{standarizeDate(fechaApertura?.split('T')?.[0])}</b>
                    </div>
                    <div>
                      Saldo: <b>{formatCurrency(saldoDisponible)}</b>
                    </div>
                  </div>
                </CuentaSelecionadaWrapper>
              </CardBox.Body>
            </CardBox>
          </div>

          <div className="col-xs-12">
            <CardBox className="bmt-md-4 bmt-3">
              <CardBox.Header>Declaraciones</CardBox.Header>
              <CardBox.Body>
                <div className="row">
                  <div className="col-xs-12 pb-16">
                    <span>Selecciona una de las opciones que te identifique:</span>
                  </div>
                  <div className="col-xs-12">
                    <RadioGroup
                      checkedValue={tributaEnExtranjero}
                      activedColor="#007DB7"
                      onChange={handleTributaEnExtranjero}
                    >
                      <div className="row">
                        <RadioWrapper className="col-xs-12">
                          <Radio value={1} className="monto-radio">
                            <p className="text-size-s black">Si soy Estadounidense y/o pago impuestos en un país distinto de Chile.</p>
                          </Radio>
                        </RadioWrapper>
                        <RadioWrapper className="col-xs-12">
                          <Radio value={0} className="monto-radio">
                            <p className="text-size-s black">No soy Estadounidense ni pago impuestos en un país distinto de Chile.</p>
                          </Radio>
                        </RadioWrapper>
                      </div>
                    </RadioGroup>
                  </div>
                  <div className="col-xs-12">
                    <Alert
                      bgType="warning"
                      iconType="warning"
                      show={tributaEnExtranjero === 1}
                      className="ahorrors-digitalizar-alert"
                    >
                      <span>
                        Lo sentimos, Para continuar el proceso dirígete a cualquiera de nuestras
                        sucursales
                      </span>
                    </Alert>
                  </div>
                </div>
              </CardBox.Body>
            </CardBox>
          </div>
          <div className="col-xs-12">
            <StepsActions
              id="datos-cuenta"
              className="middle-xs bmt-md-4 bmt-3 bmb-md-4 bmb-3"
              backText="Volver"
              nextText="Continuar"
              backAction={goBack}
              loading={loading}
              nextAction={handleSubmit}
              disableNextAction={unavailable}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
};

MigracionCuentaAhorroDatosCuentaTemplate.propTypes = {
  handleTributaEnExtranjero: PropTypes.func,
  goBack: PropTypes.func,
  handleSubmit: PropTypes.func,
  tributaEnExtranjero: PropTypes.string,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  cuenta: PropTypes.object,
};

MigracionCuentaAhorroDatosCuentaTemplate.defaultProps = {
  handleTributaEnExtranjero: null,
  error: false,
  loading: false,
  goBack: null,
  handleSubmit: null,
  tributaEnExtranjero: null,
  cuenta: {},
};

export default MigracionCuentaAhorroDatosCuentaTemplate;
