import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 32px;
  margin-top: 32px;
  @media (max-width: 1280px) {
    margin-right: 32px;
  }
  @media (max-width: 768px) {
    margin-right: 16px;
    margin-left: 16px;
  }
`;

export default Wrapper;
