const RULES_TYPE_USER_TEXT = {
  ANTIGUEDAD: {
    typeMessage: 'warning',
    description:
      'Debes tener un año de antigüedad como socio para postular a este beneficio. Te invitamos a intentarlo nuevamente cuando cumplas este requisito.',
  },
  MORA_ACTUAL: {
    typeMessage: 'warning',
    description:
      'Debes cumplir con tus compromisos de pago para postular a este beneficio. Te invitamos a intentarlo nuevamente cuando cumplas este requisito.',
  },
  MORA_6_MESES: {
    typeMessage: 'warning',
    description:
      'Debes cumplir con tus compromisos de pago para postular a este beneficio. Te invitamos a intentarlo nuevamente cuando cumplas este requisito.',
  },
  CLIENTE: {
    typeMessage: 'warning',
    description:
      'Debes tener un año de antigüedad como socio para postular a este beneficio. Te invitamos a intentarlo nuevamente cuando cumplas este requisito.',
  },
  SOCIO_COLABORADOR: {
    typeMessage: 'warning',
    description:
      'Estimado socio/colaborador: Este beneficio solo está disponible para nuestros socios. Recuerda revisar todos los beneficios educacionales y de otro tipo en nuestra intranet.',
  },
};

export default RULES_TYPE_USER_TEXT;
