import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
// Styled
import Wrapper from './styles';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ProximosPasos = () => {
  return (
    <Wrapper>
      <div className="row">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>
              <Typography tag="p" className="titlePasos">
                Próximos pasos a seguir
              </Typography>
            </CardBox.Header>
            <CardBox className="body__container">
              <CardBox>
                <div className="card__container">
                  <div className="card__content-info colorbg">
                    <div className="card__container-info">
                      <div className="card__number color">
                        <p>1</p>
                      </div>
                      <div className="card__content-text">
                        <div className="card__title">
                          <p className="titleFamily">Dirígete al cajero en las próximas 24 h.</p>
                          <b className="titleFamily">¡Estás aquí!</b>
                        </div>
                        <div className="card__content">
                          <p>
                            ingresa los 4 primeros dígitos de tu RUT y sigue las instrucciones para
                            crear tu clave nueva
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card__img">
                      <LoadIcon
                        src={`${iconPath}kit/candado.svg`}
                        size={20}
                        color="#1E1E1E"
                        className="candado-icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="card__container">
                  <div className="card__content-info">
                    <div className="card__container-info">
                      <div className="card__number">
                        <p>2</p>
                      </div>
                      <div className="card__content-text">
                        <div className="card__title">
                          <p className="titleFamily">¡Ya puedes utilizar tu tarjeta!</p>
                        </div>
                        <div className="card__content">
                          <p>
                            Con tu nueva clave creada, solo debes esperar 24 h. para poder comprar en comercios y hacer retiros en efectivo en cajeros automáticos con tu tarjeta de débito Coopeuch
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card__img">
                      <LoadIcon
                        src={`${iconPath}kit/tarjeta.svg`}
                        size={20}
                        color="#1E1E1E"
                        className="tarjeta-icon"
                      />
                    </div>
                  </div>
                </div>
              </CardBox>
            </CardBox>
          </CardBox>
        </div>
      </div>
    </Wrapper>
  );
};

export default ProximosPasos;
