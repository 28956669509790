import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import Error from '../../components/atoms/Error';

import { tarjetaCreditoActions } from '../../../application/actions/tarjetaCredito';
// import { creditoActions } from '../../../application/actions/credito';
import { productActions } from '../../../application/actions/products';
import { tCoordenadaActions } from '../../../application/actions/tarjetaCoordenada';
import { desbloqueoTdActions } from '../../../application/actions/desbloqueoTd';

import Landing from '../../components/templates/BloqueoTarjetas/LandingTemplate';
import BloqueoPermanente from './BloqueoPermanente';
import BloqueoTemporal from './BloqueoTemporal';

import { registerGA } from '../../utils/metrics';
import logger from '../../utils/logger';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const CODIGO_CUENTAS = ['VI01', 'VI03'];

const Index = () => {
  // Estado Flujo
  const [flujoBloqueoTarjetas, setFlujoBloqueoTarjetas] = useState('LANDING');

  // flags
  const [mounted, setMounted] = useState(false);
  const [errorGeneral, setErrorGeneral] = useState(false);

  // Tarjetas
  const [estadoCuentaVista, setEstadoCuentaVista] = useState(null);
  const [estadoTCoordenadas, setEstadoTCoordenadas] = useState(null);
  const [estadoTarjetasCredito, setEstadoTarjetasCredito] = useState(null);
  const [tarjetasDebito, setTarjetasDebito] = useState([]);
  const [tarjetasDebitoBT, setTarjetasDebitoBT] = useState(null);

  // Flags Activas condiciones
  const [CVActiva, setCVActiva] = useState(false);
  const [tCoordenadasActiva, setTCoordenadasActiva] = useState(false);
  const [tCreditoActiva, setTCreditoActiva] = useState(false);

  // Flags Tarjetas asociadas bloqueos
  const [CVTarjetasBT, setCVTarjetasBT] = useState(false);
  const [CVTarjetasBD, setCVTarjetasBD] = useState(false);

  // Flags habilitar botones
  const [habilitarBT, setHabilitarBT] = useState(false);
  const [habilitarBloqueoD, setHabilitarBloqueoD] = useState(false);
  const [habilitarTC, setHabilitarTC] = useState(false);

  // Flags Tarjetas con inactividad anual
  const [tieneTarjetasInactividadAnual, setTieneTarjetasInactividadAnual] = useState(false);

  // Flag recargar tarjetas para Bloqueo temporal
  const [reloadBloqueoTempCard, setReloadBloqueoTempCard] = useState(false);
  const [reloadStorage, setReloadStorage] = useState(false);
  // loadings
  const [isLoadingTarjetasCVista, setIsLoadingTarjetasCVista] = useState(true);
  /* 
  Metodos Redux  ***************
*/
  const dispatch = useDispatch();
  const history = useHistory();

  const CuentasVistaObtener = useSelector(
    (store) => store.entities.products.cuentaVista,
    shallowEqual
  );

  const tarjetaCoordenadaObtener = useSelector(
    (store) => store.entities.tarjetaCoordenada.tCoordenadaObtener,
    shallowEqual
  );

  const tarjetasCreditoObtener = useSelector(
    (store) => store.entities.tarjetaCredito.obtenerTarjetas,
    shallowEqual
  );

  const storageTarjetasCVista = useSelector(
    (store) => store.entities.products.cuentaVista.assoCards,
    shallowEqual
  );

  const loadingTarjetasCuentasVista = useSelector(
    (store) => store.ui.loaders.isLoadingTarjetasCuentasVista,
    shallowEqual
  );

  const tarjetasBloqeoAnio = useSelector(
    (store) => store.entities.desbloqueoTd.validarBloqueoTd,
    shallowEqual
  );
  /* 
  /Metodos Redux  ***************
*/

  useEffect(() => {
    if (!mounted) {
      dispatch(
        productActions.fetchCuentasVista({
          codigoProducto: CODIGO_CUENTAS,
          estadoCuenta: ['A'],
          tipoCliente: ['R'],
        })
      );

      dispatch(tCoordenadaActions.fetchTCoordenadaObtener());
      dispatch(tarjetaCreditoActions.fetchTarjetasCredito());
      dispatch(desbloqueoTdActions.fetchValidarBloqueoTd());
      setMounted(true);
    }
  }, [dispatch, mounted]);

  // Comprobar si se "Switcheo" alguna tarjeta
  useEffect(() => {
    if (reloadBloqueoTempCard === true) {
      dispatch(
        productActions.fetchCuentasVista({
          codigoProducto: CODIGO_CUENTAS,
          estadoCuenta: ['A'],
          tipoCliente: ['R'],
        })
      );
      setReloadStorage(true);
    }
  }, [reloadBloqueoTempCard, dispatch]);

  /*
      Cuenta vista --> codigo producto, estado cuenta
  */
  useEffect(() => {
    if (mounted && CuentasVistaObtener.error !== true) {
      setEstadoCuentaVista(CuentasVistaObtener.balance);
    }
    if (CuentasVistaObtener.error) {
      setErrorGeneral(true);
    }
  }, [CuentasVistaObtener, mounted]);

  /*
      Tarjeta Coordenadas --> codigo producto, estado cuenta
  */
  useEffect(() => {
    if (mounted && tarjetaCoordenadaObtener.success) {
      setEstadoTCoordenadas(tarjetaCoordenadaObtener.data);
    }
  }, [tarjetaCoordenadaObtener, mounted]);

  /*
      Tarjetas Credito --> sin bloqueo
  */
  useEffect(() => {
    if (mounted && tarjetasCreditoObtener.success) {
      setEstadoTarjetasCredito([tarjetasCreditoObtener.tarjetas]);
    }
  }, [tarjetasCreditoObtener, mounted]);

  useEffect(() => {
    // Condicion para habilitar Bloqueo Temporal y Bloqueo Definitivo
    if (estadoCuentaVista) {
      const cuentasVistaActivas = estadoCuentaVista.filter(
        (elem) => (elem?.codigoProducto === 'VI01' || 'VI03') && elem?.estadoCuenta === 'A'
      );
      setCVActiva(!!cuentasVistaActivas.length);

      dispatch(productActions.clearTarjetasCuentaVista());

      // Condicion para llamar tarjetas debito CV
      const tarjetasCV = estadoCuentaVista.map((elem) => elem.numeroCuenta);
      dispatch(
        productActions.fetchAllTarjetasCV({
          numerosCuentas: tarjetasCV,
        })
      );
    }

    // Tarjeta Coordenadas
    if (estadoTCoordenadas) {
      const condicionTCoordenadasActivo = estadoTCoordenadas.some(
        (item) => item?.estado === 'CURRENT' || item?.estado === 'HOLD'
      );
      setTCoordenadasActiva(condicionTCoordenadasActivo);
    }

    // Tarjeta de Credito
    if (estadoTarjetasCredito) {
      // Tarjeta de Credito
      const condicionTCredito = estadoTarjetasCredito.some((item) => item?.estado === '01');
      setTCreditoActiva(condicionTCredito);
    }
  }, [estadoCuentaVista, estadoTCoordenadas, estadoTarjetasCredito, dispatch]);

  useEffect(() => {
    if (!isLoadingTarjetasCVista) {
      if (storageTarjetasCVista) {
        const arr = Object.entries(storageTarjetasCVista).map((value) => value.flat());
        const arrTCVconData = arr.filter((value) => value.length > 1).flat();
        // Tarjetas estado activa para Bloqueo Temporal
        const tarjetaCVActivasBT = arrTCVconData.filter(
          (value) => (value?.estado === 'ACTIVA' && value?.bloqueo === '') || value?.bloqueo === 'G'
        );
        // Tarjetas estado activa para Bloqueo Temporal
        setCVTarjetasBT(!!tarjetaCVActivasBT.length);
        setTarjetasDebitoBT(tarjetaCVActivasBT);

        // Tarjetas estado activa para Bloqueo Definitivo
        const tarjetaCVActivasBD = arrTCVconData.filter(
          (value) => (value?.estado === 'ACTIVA' && value?.bloqueo === '') || value?.bloqueo === 'G'
        );

        setCVTarjetasBD(!!tarjetaCVActivasBD.length);
        setTarjetasDebito(tarjetasDebito.concat(tarjetaCVActivasBD));
      }
    }
  }, [isLoadingTarjetasCVista, storageTarjetasCVista]);

  useEffect(() => {
    // Activar Flag boton flujo Bloqueo Temporal
    if (CVActiva && CVTarjetasBT) {
      setHabilitarBT(true);
    }

    // Activar Flag boton flujo Bloqueo tarjetas de credito
    if (tCreditoActiva) {
      setHabilitarTC(true);
    }
  }, [CVActiva, CVTarjetasBT, tCreditoActiva]);

  const prevLoading = useRef(false);

  useEffect(() => {
    if (prevLoading.current !== loadingTarjetasCuentasVista) {
      if (!loadingTarjetasCuentasVista) {
        setIsLoadingTarjetasCVista(loadingTarjetasCuentasVista);
      }
    }
    prevLoading.current = loadingTarjetasCuentasVista;
  }, [loadingTarjetasCuentasVista]);

  useEffect(() => {
    // Activar Flag boton flujo Bloqueo Definitivo
    if (CVActiva && (CVTarjetasBD || tCoordenadasActiva) && !isLoadingTarjetasCVista) {
      setHabilitarBloqueoD(true);
      // Comprobacion de atualizacion de data en tarjetas debito
    }
  }, [isLoadingTarjetasCVista, CVActiva, CVTarjetasBD, tCoordenadasActiva]);


  useEffect(() => {
    if (tarjetasBloqeoAnio?.data?.tarjetas) {
      setTieneTarjetasInactividadAnual(
        tarjetasBloqeoAnio?.data?.tarjetas.some((item) => item.inactividadAnual)
      );
    }
  }, [tarjetasBloqeoAnio]);

  const handleDesbloqueoTarjetas = () => {
    console.log('INICIO DESBLOQUEO');
    history.push('/ecd/desbloqueo-tarjeta-debito');
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/bloqueo-desbloqueo-tarjetas',
      'eve-cat': 'Click',
      'eve-lab': 'Desbloquear',
    });
    logger(null,{
      accion:"BOTON_DESBLOQUEOTD_SINUSO"
    })
  };

  useEffect(() => {
    registerGA({
      'event': 'vpv-privado',
      'page': '/tef/bloqueo-desbloqueo-tarjetas',
    });
  },[])

  return (
    <>
      <PageTitle
        title="Bloqueo/Desbloqueo de tarjetas"
        icon={<LoadIcon src={`${iconPath}lock.svg`} color="black" />}
      />
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        {errorGeneral ? (
          <Error className="bmt-3 bmt-md-4" />
        ) : (
          {
            LANDING: (
              <Landing
                {...{
                  habilitarBT,
                  habilitarBloqueoD,
                  habilitarTC,
                  setFlujoBloqueoTarjetas,
                  handleDesbloqueoTarjetas,
                  tieneTarjetasInactividadAnual,
                }}
              />
            ),
            BLOQUEO_TEMPORAL: (
              <BloqueoTemporal
                {...{
                  tarjetasDebitoBT,
                  reloadBloqueoTempCard,
                  setReloadBloqueoTempCard,
                  storageTarjetasCVista,
                  reloadStorage,
                  setReloadStorage,
                }}
              />
            ),
            BLOQUEO_PERMANENTE: (
              <BloqueoPermanente
                changeFlujo={setFlujoBloqueoTarjetas}
                estadoCuentaVista={estadoCuentaVista}
                estadoTCoordenadas={estadoTCoordenadas}
                estadoTarjetasCredito={estadoTarjetasCredito}
                tarjetasDebito={tarjetasDebito}
              />
            ),
          }[flujoBloqueoTarjetas]
        )}
      </div>
    </>
  );
};
Index.displayName = 'BloqueoTarjetas_Container';
export default Index;
