/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/ui/layout';

// ------- SAGA ------ //

function fetchLogoutSuccess(state) {
  state.header = {};
  state.footer = {};
  state.menu = [];
  state.formatted.menu = [];
  state.formatted.footer = {};
}

function fetchLayoutSuccess(state, action) {
  const { menu, footer, header } = action.payload;
  state.footer = footer;
  state.header = header;
  state.menu = menu;
}

function fetchFormattedLayoutSuccess(state, action) {
  const { menu, footer } = action.payload;
  if (!!menu) {
    state.formatted.menu = menu;
  }
  state.formatted.footer = footer;
}

function LayoutSuccessFullScreen(state, action) {
  const { fullScreen } = action.payload;
  state.fullScreen = fullScreen;
}

const fetchLayoutConfigs = createAction('fetchLayoutConfigs');

// ------------- connection ------------- //

const layoutReducer = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    fetchLayoutSuccess,
    fetchLogoutSuccess,
    fetchFormattedLayoutSuccess,
    LayoutSuccessFullScreen
  },
});

export const layoutActions = {
  ...layoutReducer.actions,
  fetchLayoutConfigs,
};

export default layoutReducer.reducer;
