import React, { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';

import DashboardCard from '../../../organism/DashboardCard';
import { SpinnerWrapper } from '../Wrapper/styles';
import CajaInformacion from '../../../organism/RecuperarOCrearClave/CajaInformacion';
import InputForForm from '../../../organism/RecuperarOCrearClave/InputForForm';

import ButtonWithSpinner from '../../../atoms/ButtonWithSpinner';
import Button from '@coopeuch-components/web/atoms/Button';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Tooltip from '@coopeuch-components/web/atoms/Tooltip';
import Typography from '@coopeuch-components/web/atoms/Typography';
import addClass from '@coopeuch-components/web/functions/addClass';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { errorTexts } from '../../../../containers/RecuperarOCrearClave/texts';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const recaptchaPath = process.env.REACT_APP_RECAPTCHA;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const RutYSerieTemplate = (props) => {
  const {
    onChangeHandle,
    isLoadingValidarOCrear,
    errorValidarRut,
    rut,
    serie,
    rutError,
    serieError,
    onFocusTooltip,
    onceClic,
    setOnceClic,
    isLocationCrearClave,
    handleClickRetry,
    handleClickSerSocio,
  } = props;

  const mensajeCajaInformacion = useMemo(() => {
    return isLocationCrearClave
      ? {
          formTitle: 'crear tu clave',
          boxTitle: '¿Qué necesito para crear mi clave?',
          boxContent: [
            'Teléfono celular registrado en Coopeuch para recibir SMS.',
            'Cédula de identidad vigente y su número de serie o documento para validar tu identidad.',
          ],
        }
      : {
          formTitle: 'actualizar tu clave',
          boxTitle: '¿Qué necesito para actualizar mi clave?',
          boxContent: [
            'Cédula de identidad vigente y su número de serie o documento para validar tu identidad.',
            'Teléfono celular registrado en Coopeuch para recibir SMS o app PassCoopeuch como método autorización.',
          ],
        };
  }, [isLocationCrearClave]);

  const rutMessage = useMemo(() => {
    return `El RUT ${rut?.length === 0 ? 'es obligatorio' : 'no es válido'}`;
  }, [rut]);

  const serieMessage = useMemo(() => {
    return serieError && 'El número de serie es obligatorio';
  }, [serieError]);

  function isNumeric(number) {
    const regex = new RegExp('^[0-9]+$');
    return regex.test(number?.trim());
  }

  const isDisabledButton = useMemo(() => {
    return rut?.length <= 7 || (!isNumeric(serie?.substr(0, 1)) && serie?.length <= 8) || (isNumeric(serie?.substr(0, 1)) && serie?.length <= 10) || rutError || serieError;
  }, [rut, serie, rutError, serieError]);

  return (
    <DashboardCard classes="dashboard-card-shadow">
      <DashboardCard.Head title="Ingresa tus datos" borderBottom="complete" />
      <DashboardCard.Body classes="rutyserie-container">
        <div className={Boolean(errorValidarRut) ? 'd-block' : 'd-none'}>
          <div className="pl-32 pt-24 pr-32 pb-24">
            <div className="text-align-center">
              {errorValidarRut.type === 'warning' && (
                <LoadIcon src={`${iconPath}mensajeriaWarning.svg`} className="imagen-error" />
              )}
              {errorValidarRut.type === 'info' && (
                <LoadIcon src={`${iconPath}ilustracion_info.svg`} className="imagen-error" />
              )}
              {errorValidarRut.type === 'default' && (
                <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="imagen-error" />
              )}
            </div>
            <div className="mb-20" />
            <div className="text-align-center">
              <label className="error-title">
                {errorValidarRut.title ? errorValidarRut.title : errorTexts.ERROR_TITLE}
              </label>
            </div>
            <div className="mb-2" />
            <div className="text-align-center row">
              <div className="col-md-2" />
              <div className="col-md-8">
                <label className="error-desc">
                  {errorValidarRut.message ? errorValidarRut.message : errorTexts.ERROR_DESC}
                </label>
              </div>
              <div className="col-md-2" />
            </div>
            <div className="mb-24" />
            {errorValidarRut.showButton && Boolean(errorValidarRut.showButton) && (
              <div className="text-regular-medium">
                <Button
                  bgColor="red"
                  color="white"
                  width={274}
                  height={48}
                  shadow
                  onClick={
                    errorValidarRut.showSpecialButton && Boolean(errorValidarRut.showSpecialButton)
                      ? handleClickSerSocio
                      : handleClickRetry
                  }
                  id="btn-error"
                >
                  {errorValidarRut.showSpecialButton && Boolean(errorValidarRut.showSpecialButton)
                    ? errorValidarRut.specialButtonMessage
                    : errorTexts.ERROR_BUTTON}
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className={Boolean(errorValidarRut) ? 'd-none' : 'd-block'}>
          <div className="row">
            <div className="rutyserie-mensaje-container col-md-12 col-xs-12">
              <p>
                Para <span className="text-bold"> {mensajeCajaInformacion.formTitle} </span>,
                ingresa los siguientes datos personales.
              </p>
            </div>
          </div>

          <div className="row">
            {Boolean(errorValidarRut) && (
              <div className="col-md-12 col-xs-12">
                <div className="rutyserie-alert-container bmb-4">
                  <Alert
                    bgType="error"
                    iconType="error"
                    show={Boolean(errorValidarRut)}
                    className="pr-0 pt-12 pb-12 mb-14 pr-24"
                  >
                    {errorValidarRut}
                  </Alert>
                </div>
              </div>
            )}
            <div className="col-md-5 col-xs-12">
              <div className="rutyserie-input-container">
                <div className="hide-help-text rutyserie-input-margin rutyserie-input">
                  <InputForForm
                    label="RUT"
                    onChange={onChangeHandle}
                    dataset="rut"
                    id="rut-crear-clave"
                    value={rut}
                    placeholder="Ej: 12345678-k"
                    maxLength={12}
                    error={rutError ? rutMessage : ''}
                  />
                </div>

                <div className="hide-help-text rutyserie-2nd-input-margin rutyserie-input">
                  <InputForForm
                    label={
                      <div className="row ml-0">
                        <span className="text-bold mb-14 mr-8 black">
                          Nº serie o de documento de tu cédula
                        </span>
                      </div>
                    }
                    onChange={onChangeHandle}
                    dataset="serie"
                    id="serie-crear-clave"
                    value={serie}
                    placeholder="Ej: 333.333.333"
                    maxLength={13}
                    error={serieError ? serieMessage : ''}
                  />
                </div>

                <Tooltip
                  bgColor="white"
                  tooltipclassName="tooltip-dni"
                  rounded={8}
                  content={
                    <div>
                      <Typography
                        tag="p"
                        align={'center'}
                        fontSize={16}
                        lineHeight={false}
                        className="black text-bold"
                      >
                        ¿Dónde encuentro esta información?
                      </Typography>
                      <br />
                      <Typography tag="p" align={'center'} lineHeight={false} fontSize={14}>
                        <span className="black text-bold">Cédula nueva</span>
                        <br />
                        <span className="black">Busca el número de documento</span>
                      </Typography>
                      <img
                        src={`${staticPath}carnet_nuevo_ilu.jpg`}
                        alt="Cédula de identidad nueva"
                        className="imgDNI"
                      />
                      <br />
                      <Typography tag="p" align={'center'} lineHeight={false} fontSize={14}>
                        <span className="black text-bold">Cédula antigua</span>
                        <br />
                        <span className="black">Busca el número de serie</span>
                      </Typography>
                      <img
                        src={`${staticPath}carnet_antiguo_ilu.jpg`}
                        alt="Cédula de identidad antigua"
                        className="imgDNI"
                      />
                    </div>
                  }
                >
                  <div className="question--tooltip" onMouseEnter={onFocusTooltip}>
                    <div className="question--icon">
                      <Typography tag="p" fontSize={12} className="text-bold">
                        ?
                      </Typography>
                    </div>
                    <div className="question-tooltip-text ml-6">
                      <Typography tag="p" fontSize={14}>
                        ¿Dónde encuentro esta información?
                      </Typography>
                    </div>
                  </div>
                </Tooltip>

                <div className={addClass([!isDisabledButton && 'hide'])}>
                  <Button disabled color="white" fullWidth onClick={null}>
                    Continuar
                  </Button>
                </div>
                {!onceClic ? (
                  <div className={addClass([isDisabledButton && 'hide'])}>
                    <ButtonWithSpinner
                      fullWidth
                      bgColor="#f42534"
                      onClick={() => {
                        setOnceClic(true);
                      }}
                      className="g-recaptcha w-100"
                      data-sitekey={recaptchaPath}
                      data-callback="onSubmitValidarRut"
                      data-size="invisible"
                      id="boton--validar-rut--crear-clave"
                      loading={isLoadingValidarOCrear}
                    >
                      Continuar
                    </ButtonWithSpinner>
                  </div>
                ) : (
                  <div
                    className={addClass([isDisabledButton && 'hide'])}
                    onClick={() => {
                      return false;
                    }}
                  >
                    <ButtonWithSpinner
                      fullWidth
                      bgColor="#f42534"
                      disabled={true}
                      onClick={() => {
                        return false;
                      }}
                      className="button-style-bloqueo"
                      data-sitekey={recaptchaPath}
                      data-size="invisible"
                      id="boton--validar-rut--crear-clave"
                      loading={isLoadingValidarOCrear}
                    >
                      Continuar
                    </ButtonWithSpinner>
                  </div>
                )}
              </div>
            </div>

            <div className="col-md-7 col-xs-12">
              <div className="rutyserie-information-container ">
                <CajaInformacion
                  title={mensajeCajaInformacion.boxTitle}
                  items={mensajeCajaInformacion.boxContent}
                />
              </div>
            </div>
          </div>
        </div>
      </DashboardCard.Body>
    </DashboardCard>
  );
};

RutYSerieTemplate.propTypes = {
  onChangeHandle: PropTypes.func,
  isLoadingValidarOCrear: PropTypes.bool,
  rut: PropTypes.string,
  serie: PropTypes.string,
  rutError: PropTypes.bool,
  serieError: PropTypes.bool,
  clic: PropTypes.bool,
  setOnceClic: PropTypes.func,
};

RutYSerieTemplate.defaultProps = {
  onChangeHandle: null,
  isLoadingValidarOCrear: false,
  errorValidarRut: '',
  rut: '',
  serie: '',
  rutError: false,
  serieError: false,
  clic: false,
  setOnceClic: null,
};

export default RutYSerieTemplate;
