import {createAction, createSlice} from "@reduxjs/toolkit";
import initialState from "../../../domain/entities/aliviotc";

// ------- SAGA ------ //

function fetchValidarAlivioTcSuccess(state, action) {
  const {data} = action.payload;
  state.validarAlivioTc.isValid = data || false;
  state.validarAlivioTc.error = null;
}

function fetchValidarAlivioTcError(state, action) {
  const {error} = action.payload;
  state.validarAlivioTc.isValid = false;
  state.validarAlivioTc.error = error;
}

function resetValidarAlivioTc(state) {
  state.validarAlivioTc = {...initialState.validarAlivioTc};
}

const fetchValidarAlivioTc = createAction('fetchValidarAlivioTc');

// ------------- connection ------------- //
const alivioTcReducer = createSlice({
  name: 'alivioTc',
  initialState,
  reducers: {
    fetchValidarAlivioTcSuccess,
    fetchValidarAlivioTcError,
    resetValidarAlivioTc,
  }
});

export const alivioTcActions = {
  ...alivioTcReducer.actions,
  fetchValidarAlivioTc,
};

export default alivioTcReducer.reducer;
