import styled from 'styled-components';

const Wrapper = styled.div`
  p,
  label {
    color: #383638 !important;
  }

  .error-card-desc {
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
  }

  .icon-error-card svg {
    height: 115px;
    width: 120px;
  }

  .card-error-container {
    padding: 14px 21px 14px 21px;
    min-height: 178px;
  }

  @media screen and (max-width: 1024px) {
    .card-error-container {
      padding: 16px;
    }
  }
`;

export default Wrapper;
