/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';

import '../Sugerencia/style.css'
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import { DownloadLink, Hr } from './styles';

import DashboardCard from '../../../components/organism/DashboardCard';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';

const SolicitudModificacion = forwardRef((props, ref) => {
  const {cuotasPactadasPorMes, secondStep, thirdStep, setThirdStep, downloadHistorialGA, acceptTermsGA} = props;
  const dispatch = useDispatch();
    const opacidad = secondStep ? 1 : 0.5
    const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

  const handleDownloadHistorial = async () => {
    dispatch(cuotaParticipacionActions.fetchBorradorDescargarCuotasParticipacion({ cuotasPactadasPorMes }));
  };

  return (
    <>
      <DashboardCard className='widthSection'>
        <div style={{ opacity: opacidad }} ref={ref}>

          <DashboardCard.Head
            title={<span className='txtTitle-CP'>Solicitud de modificación</span>}
            borderBottom="complete"
            classes="btext-xs-left bmt-xs-3 bmt-sm-4"
          />
          <DashboardCard.Body classes="text-left-destinatarios black">
            <div className="row" style={{marginTop: 15}}>
              <div className="col-md-12 col-xs-12 bmt-3">
                <div className="" style={{textAlign: 'left'}}>
                  <p style={{fontSize: 14, marginTop: 4, color: '#383838'}}>Puedes revisar el documento y autorizar la modificación</p>
                  <div className='col-lg-12 col-xs-12 d-flex align-items-center bmt-4 bmt-xs-4 bmb-4 bmb-xs-4' role='button'>
                    {secondStep ?
                      <DownloadLink
                        className="middle-xs"
                        onClick={() => {
                        handleDownloadHistorial()
                        downloadHistorialGA()
                        }}
                      >
                        <LoadIcon
                          className="float-left mr-10"
                          src={`${iconPath}download_arrow.svg`}
                          color="#1F1E1E"
                          size={22.5}
                        />
                        <span className='d-md-inline-block font-weight-bold'>Descargar solicitud</span>
                      </DownloadLink>
          :
                      <DownloadLink className="middle-xs not-allowed" onClick={() => {}}>
                        <a className="text-descargar" rel="noopener noreferrer" download="Solicitud de modificacion de cuotas.pdf">
                          <LoadIcon
                            className="float-left mr-10"
                            src={`${iconPath}download_arrow.svg`}
                            color="#1F1E1E"
                            size={22.5}
                          />
                          <span className='d-md-inline-block font-weight-bold'>Descargar solicitud</span>
                        </a>
                      </DownloadLink>}
         
                  </div>
                </div>
              </div>
            </div>
            <Hr style={{marginBottom: 25}} />
            <div className='d-flex align-items-left'>
              {secondStep ?
                <CheckBox
                  name="cuotaParticipacionTerminosChecked"
                  className="checkbox-element"
                  text="He leído y acepto"
                  borderColor="#646569"
                  fontSize={16}
                  isChecked={thirdStep}
                  onChange={() => {
                    setThirdStep(!thirdStep)
                    acceptTermsGA()
                  }}
                />
:
                <CheckBox
                  name="cuotaParticipacionTerminosChecked"
                  className="checkbox-element not-allowed"
                  text="He leído y acepto"
                  borderColor="#646569"
                  fontSize={16}
                  onChange={() => {}}
                />}
    
            </div>
          </DashboardCard.Body>
        </div>
      </DashboardCard>
    </>
  );
});

SolicitudModificacion.propTypes = {
  secondStep: PropTypes.bool.isRequired,
  thirdStep: PropTypes.bool.isRequired,
  setThirdStep: PropTypes.func.isRequired,
  borradorDescargarCuotas: PropTypes.shape({
    success: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  downloadHistorialGA: PropTypes.func.isRequired,
  acceptTermsGA: PropTypes.func.isRequired,
  cuotasPactadasPorMes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = (store) => ({
  persona: store.entities.persona,
  cuotas: store.entities.cuotaParticipacion.cuotas,
  borradorDescargarCuotas: store.entities.cuotaParticipacion.borradorDescargarCuotas,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchBorradorDescargarCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchBorradorDescargarCuotasParticipacion());
  },
});

SolicitudModificacion.displayName = 'Container_CDP_SOLMOD';

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SolicitudModificacion));
