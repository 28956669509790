import styled from 'styled-components';

const Container = styled.div`
  .column {
    flex-direction: column;
  }
  .margin-bottom {
    margin-bottom: 106px;
  }
  .text {
    max-width: 470px;
    line-height: 32px !important;
  }
  .line {
    border-top: 1px solid #e2e1e3;
    /* padding-bottom: 32px; */
  }
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .under-line {
    position: relative;
  }
  .under-line:after {
    position: absolute;
    bottom: 25px;
    /* left: 0; */
    /* right: 0; */
    width: 100%;
    height: 1px;
    content: '';
    background: #f00;
    max-width: 227px;
  }
  .factor-button-style {
    width: 296px;
    @media screen and (max-width: 340px) {
      width: 252px;
    }
  }
  /* .align-right-desktop{

  }
  .align-left-desktop{} */
`;
export const InputContainer = styled.div`
  max-width: 297px;
  /* padding-left: 79px;
  padding-right: 79px; */
  margin-bottom: 16px;

  & small {
    display: none;
  }

  .factor-coordenadas-input {
    font-size: 30px;
    text-align: center;
    border-radius: 2px;
    height: 48px;
  }

  .factor-coordenadas-input small {
    display: none;
  }

  .factor-coordenadas-label-container {
    margin-bottom: 8px;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
`;
export default Container;
