import React, { Fragment } from 'react';
import Skeleton from 'react-loading-skeleton';
import DashboardCard from '../../organism/DashboardCard';
import { WrapperSkeleton } from './styles';

const SkeletonTop = () => (
  <DashboardCard margin="0px">
    <DashboardCard.Body padding="12px 32px" classes="module-content">
      <div className="row middle-xs between-xs">
        <div className="col-xs-9 d-flex middle-xs">
          <Skeleton height={32} width={32} circle />
          <Skeleton height={10} width={315} className="ml-8 hide-on-mobile" />

          <div className="ml-8 hide-on-desktop">
            <Skeleton height={10} width={214} />
            <Skeleton height={10} width={164} />
          </div>
        </div>

        <div className="col-xs-3 d-flex end-xs">
          <div className="hide-on-mobile">
            <Skeleton height={10} width={85} />
          </div>
        </div>
      </div>
    </DashboardCard.Body>
  </DashboardCard>
);

const SkeletonBanner = () => (
  <Fragment>
    <div className="hide-on-mobile">
      <DashboardCard margin="0px">
        <DashboardCard.Body padding="28px 32px">
          <div className="mt-8">
            <Skeleton height={10} width={315} />
          </div>
          <div className="mt-16">
            <Skeleton height={10} width={285} />
          </div>
          <div className="mt-16">
            <Skeleton height={10} width={285} />
          </div>
          <div className="mt-16">
            <Skeleton height={48} width={170} />
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </div>
    <Skeleton height={200} width="100%" className="hide-on-desktop" />
  </Fragment>
);

export const SkeletonCard = ({ short }) => (
  <Fragment>
    <div className="d-flex middle-xs">
      <Skeleton height={32} width={32} circle />

      <div className="ml-8 hide-on-mobile">
        <Skeleton height={10} width={185} />
        <Skeleton height={10} width={225} className="mt-8" />
      </div>

      <div className="ml-8 hide-on-desktop">
        <Skeleton height={10} width={short ? 178 : 214} />
        <Skeleton height={10} width={short ? 148 : 154} className="mt-8" />
      </div>
    </div>

    <Skeleton height={10} width={134} className="mt-8 hide-on-desktop" />

    <div>
      <Skeleton height={10} width={134} className="mt-24 hide-on-desktop" />
    </div>

    <div className="row mt-24 hide-on-desktop">
      <div className="col-xs-6">
        <Skeleton height={10} width={104} />
        <Skeleton height={10} width={74} className="mt-8" />
      </div>

      <div className="col-xs-6">
        <Skeleton height={10} width={104} />
        <Skeleton height={10} width={74} className="mt-8" />
      </div>
    </div>

    <div className="row mt-32 hide-on-mobile">
      <div className="col-xs-6">
        <div>
          <Skeleton height={10} width="70%" style={{ maxWidth: 145 }} />
        </div>
        <Skeleton height={10} width="60%" style={{ maxWidth: 105 }} className="mt-8" />
      </div>

      <div className="col-xs-6 d-flex end-xs">
        <div className="w-100">
          <Skeleton height={10} width="95%" style={{ maxWidth: 156 }} />
          <Skeleton height={10} width="95%" style={{ maxWidth: 156 }} className="mt-8" />
        </div>
      </div>
    </div>
  </Fragment>
);

export const SkeletonSlideContent = () => (
  <Fragment>
    <div className="row mt-24 hide-on-desktop">
      <div className="col-xs-6">
        <Skeleton height={10} width={104} />
        <Skeleton height={10} width={74} className="mt-8" />
      </div>

      <div className="col-xs-6">
        <Skeleton height={10} width={104} />
        <Skeleton height={10} width={74} className="mt-8" />
      </div>
    </div>

    <div className="row mt-32 hide-on-mobile">
      <div className="col-xs-6">
        <Skeleton height={10} width={145} />
        <Skeleton height={10} width={105} className="mt-8" />
      </div>

      <div className="col-xs-6 d-flex end-xs">
        <div>
          <Skeleton height={10} width={156} />
          <Skeleton height={10} width={156} className="mt-8" />
        </div>
      </div>
    </div>
  </Fragment>
);

const SkeletonSmallItem = () => (
  <DashboardCard margin="0px">
    <DashboardCard.Body padding="20px 16px 24px 16px">
      <div className="d-flex center-xs">
        <Skeleton height={32} width={32} circle />
      </div>
      <div className="d-flex center-xs hide-on-mobile">
        <div className="w-100">
          <Skeleton height={10} width="100%" style={{ maxWidth: 104 }} className="mt-8 mb-4" />
        </div>
      </div>

      <div className="center-xs hide-on-desktop">
        <Skeleton height={10} width={64} className="mt-8" />
        <div>
          <Skeleton height={10} width={44} className="mt-4 mb-4" />
        </div>
      </div>
    </DashboardCard.Body>
  </DashboardCard>
);

const SkeletonLargeItem = () => (
  <DashboardCard margin="0px">
    <DashboardCard.Body padding="18px 0 18px 32px" classes="module-content">
      <div className="hide-on-desktop">
        <Skeleton height={10} width={134} className="mb-8" />

        <Skeleton height={10} width={254} className="mt-8" />
        <Skeleton height={10} width={254} className="mt-4" />
        <Skeleton height={10} width={104} className="mt-4" />
      </div>

      <div className="d-flex hide-on-mobile">
        <Skeleton height={32} width={32} circle />

        <div className="ml-8 w-100">
          <Skeleton height={10} width={151} className="mt-8" />
          <Skeleton height={10} width="90%" style={{ maxWidth: 344 }} className="mt-4" />
          <Skeleton height={10} width="80%" style={{ maxWidth: 254 }} className="mt-4" />
        </div>
      </div>
    </DashboardCard.Body>
  </DashboardCard>
);

const SkeletonContainer = () => (
  <WrapperSkeleton className="row">
    <div className="col-xs-12 mb-24">
      <SkeletonTop />
    </div>

    <div className="col-xs-12">
      <SkeletonBanner />
    </div>

    <div className="col-xs-12 skeleton-separator">
      <Skeleton height={10} width={125} />
    </div>

    <div className="col-md-6 col-xs-12">
      <DashboardCard margin="0px">
        <DashboardCard.Body padding="24px 32px" classes="module-skeleton-card module-content">
          <SkeletonCard />
        </DashboardCard.Body>
      </DashboardCard>
    </div>
    <div className="col-md-6 col-xs-12 module-second">
      <DashboardCard margin="0px">
        <DashboardCard.Body padding="24px 32px" classes="module-skeleton-card module-content">
          <SkeletonCard />
        </DashboardCard.Body>
      </DashboardCard>
    </div>

    <div className="col-xs-12 mt-24 mb-24">
      <Skeleton height={10} width={125} />
    </div>

    <div className="col-xs-12 mb-36">
      <div className="row">
        {[1, 2, 3]?.map((position) => (
          <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2" key={position}>
            <SkeletonSmallItem />
          </div>
        ))}
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 module-second">
          <SkeletonLargeItem />
        </div>
      </div>
    </div>
  </WrapperSkeleton>
);

export default SkeletonContainer;
