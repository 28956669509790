import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';

import SimulacionTemplate from '../../../components/templates/DepositoAPlazo/Simulacion';
import { regexOnlyNumber } from '../../../utils/formatters';
import { useShallowStore } from '../../../utils/hooks';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';

function Simulacion(props) {
  const {
    history,
    match,
    simulacion,
    handleInfoSimulacion,
    handleChangeTarjetaActiva,
    listaNegra,
    mantencion,
  } = props;

  const dispatch = useDispatch();

  const [plazoErr, setPlazoErr] = useState('');
  const [montoErr, setMontoErr] = useState('');

  const reglasError = useShallowStore(
    ({ entities }) => entities.depositoAPlazo.reglasSimulacionError
  );

  const reglas = useShallowStore(({ entities }) => entities.depositoAPlazo.reglasSimulacion);
  const isLoadingDAPSimulacion = useShallowStore(({ ui }) => ui.loaders.isLoadingDAPSimulacion);

  const itsMaintenance = useMemo(() => {
    const type = mantencion?.find((item) => item?.producto?.codigoProducto === 'DP');
    return type?.mantencion?.estado;
  }, [mantencion]);

  const findRule = useCallback((rule) => reglas?.find(({ key }) => key === rule)?.value, [reglas]);
  const makeRule = useCallback((tipo, input) => `${tipo?.slice(0, 3)?.toUpperCase()}_${input}`, []);

  const rulesOfPlazo = useCallback(
    (plazo) => {
      const min = findRule(makeRule(simulacion?.tipo, 'PLAMIN'));
      const max = findRule(makeRule(simulacion?.tipo, 'PLAMAX'));

      return setPlazoErr(plazo < Number(min) || plazo > Number(max));
    },
    [setPlazoErr, findRule, simulacion?.tipo]
  );

  const rulesOfMonto = useCallback(
    (monto, nuevoTipo) => {
      let err = '';

      const min = findRule(makeRule(nuevoTipo || simulacion?.tipo, 'MONMIN'));
      const max = findRule(makeRule(nuevoTipo || simulacion?.tipo, 'MONMAX'));

      if (monto < Number(min)) err = 'min';
      if (monto > Number(max)) err = 'max';

      return setMontoErr(err);
    },
    [setMontoErr, findRule, simulacion?.tipo]
  );

  const handleInput = useCallback(
    (event) => {
      const { value, dataset } = event?.target;

      if (dataset?.id === 'plazo') {
        rulesOfPlazo(value);
        handleInfoSimulacion('plazo', value);
      } else {
        const nuevoMonto = Number(regexOnlyNumber(value));

        rulesOfMonto(nuevoMonto);
        handleInfoSimulacion('monto', nuevoMonto);
      }
    },
    [simulacion?.tipo, handleInfoSimulacion, rulesOfPlazo, rulesOfMonto]
  );

  const handleTab = useCallback(
    (position) => {
      const nuevoTipo = position === 1 ? 'fijo' : 'renovable';
      const regexMonto = Number(regexOnlyNumber(simulacion?.monto));

      handleInfoSimulacion('tipo', nuevoTipo);

      if (simulacion?.monto) rulesOfMonto(regexMonto, nuevoTipo);
    },
    [handleInfoSimulacion, simulacion?.monto, rulesOfMonto]
  );

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history, match]);

  const handleSubmit = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-1',
      'eve-cat': 'Click',
      'eve-lab': 'Simular',
    });
    return history?.push(`${match?.url}/resultado`);
  }, [history, match]);

  useEffect(() => {
    dispatch(depositoAPlazoActions.fetchDepositoConfiguracionSimular());
    handleChangeTarjetaActiva(0);
    registerGA({ event: 'vpv-privado', page: '/tef/mis-productos/deposito-a-plazo/paso-1' });
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP_SIMULADOR-P1');
  }, []);

  return (
    <SimulacionTemplate
      error={reglasError || listaNegra?.error || (itsMaintenance ? 'GEN-02' : null)}
      loading={isLoadingDAPSimulacion}
      loadingRedirect={history?.location?.state?.redirect}
      plazoErr={plazoErr}
      montoErr={montoErr}
      {...simulacion}
      isListaNegra={listaNegra?.enListaNegra}
      findRule={findRule}
      makeRule={makeRule}
      handleInput={handleInput}
      handleTab={handleTab}
      handleSubmit={handleSubmit}
      handleGoBack={handleGoBack}
    />
  );
}

Simulacion.propTypes = {};
Simulacion.defaultProps = {};

export default Simulacion;
