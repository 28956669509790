import { put, takeLatest, call, spawn } from 'redux-saga/effects';
// actions
import { activacionTarjetasKitActions } from '../../../application/actions/activacionTarjetasKit';
// utils
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';
// api
import api from '../../api';

const apiKit = api(process.env.REACT_APP_MSKIT);

export function* fetchGetKitKit() {
  try {
    const { data, error } = yield call(apiKit.get, 'activacion-tarjetas/kit/v1/obtener');
    if (data) {
      yield put(activacionTarjetasKitActions.fetchGetKitSuccessKit({ data }));
    } else {
      yield put(activacionTarjetasKitActions.fetchGetKitErrorKit({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasKitActions.fetchGetKitErrorKit({ error }));
  }
}

export function* fetchValidarTarjetaCoordenadasKit(action) {
  const { idTarjetaCoordenadas, numeroSerie } = action.payload;

  try {
    const { data, error } = yield call(
      apiKit.post,

      'activacion-tarjetas/tarjeta-coordenadas/v1/validar',

      {
        idTarjetaCoordenadas,

        numeroSerie,
      }
    );

    if (data) {
      yield put(activacionTarjetasKitActions.fetchfetchValidarTarjetaCoordenadasSuccessKit({ data }));
    } else {
      yield put(activacionTarjetasKitActions.fetchfetchValidarTarjetaCoordenadasErrorKit({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasKitActions.fetchfetchValidarTarjetaCoordenadasErrorKit({ error }));
  }
}


export function* fetchSendSMSCodeKit() {
  try {
    const { data, error } = yield call(
      apiKit.post,

      'activacion-tarjetas/tercer-factor/v1/solicitar'
    );

    if (data) {
      yield put(activacionTarjetasKitActions.fetchSendSMSCodeSuccessKit({ data }));
    } else {
      yield put(activacionTarjetasKitActions.fetchSendSMSCodeErrorKit({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasKitActions.fetchSendSMSCodeErrorKit({ error }));
  }
}

export function* fetchValidateSMSCodeKit(action) {
  const codigo = action.payload;

  try {
    const { data, error } = yield call(apiKit.post, 'activacion-tarjetas/kit/v1/activar', {
      codigo,
    });

    if (data) {
      yield put(activacionTarjetasKitActions.fetchValidateSMSCodeSuccessKit({ data }));
    } else {
      yield put(activacionTarjetasKitActions.fetchValidateSMSCodeErrorKit({ error }));
    }
  } catch (error) {
    yield put(activacionTarjetasKitActions.fetchValidateSMSCodeErrorKit({ error }));
  }
}

export function* fetchDownloadComprobanteKit() {
  try {
    const { data, error } = yield call(apiKit.get, 'comprobante/v1/obtener');
    if (data) {
      yield put(activacionTarjetasKitActions.fetchDownloadComprobanteSuccessKit());
      downloadPDFBase64OtherTab(data.archivo);
    } else {
      yield put(activacionTarjetasKitActions.fetchDownloadComprobanteErrorKit({ error }));
    }
  } catch (err) {
    console.error(err.message, 'fetchDownloadComprobanteKit');
  }
}

export function* fetchGetKitBannerKit() {
  try {
    const { data, error } = yield call(apiKit.get, 'activacion-tarjetas/banner/v1/obtener');

    if (data) {
      yield put(activacionTarjetasKitActions.fetchGetKitBannerSuccessKit(data));
    } else {
      yield put(activacionTarjetasKitActions.fetchGetKitBannerErrorKit(error));
    }
  } catch (error) {
    yield put(activacionTarjetasKitActions.fetchGetKitBannerErrorKit(error));
  }
}


export function* fetchIsEnabledPassCoopeuchKit() {
  try {
    const { data, error } = yield call(apiKit.get, 'factor/passcoopeuch/v1/consultar');
    if (data === true || data === false) {
      yield put(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchSuccessKit(data));
    } else {
      yield put(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchErrorKit(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchIsEnabledPassCoopeuchKit');
  }
}

export function* fetchSolicitarPassCoopeuchKit() {
  try {
    const { data, error } = yield call(apiKit.post, 'factor/passcoopeuch/v1/solicitar');

    if (data) {
      yield put(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchSuccessKit(data));
    } else {
      yield put(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchErrorKit({ error }));
    }
  } catch (err) {
    console.error(err.message, 'fetchSolicitarPassCoopeuchKit');
  }
}

export function* fetchValidarPassCoopeuchKit() {
  try {
    const { data, error } = yield call(apiKit.post, 'factor/passcoopeuch/v1/validar', {
      desafio: '',
    });

    if (data) {
      yield put(activacionTarjetasKitActions.fetchValidarPassCoopeuchSuccessKit(data));
    } else {
      yield put(activacionTarjetasKitActions.fetchValidarPassCoopeuchErrorKit(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchValidarPassCoopeuchKit');
  }
}

export function* fetchActivateKitCVKit() {
  try {
    const { data, error } = yield call(apiKit.post, 'activacion-tarjetas/kit/v1/activar', {
      codigo: '',
    });
    if (data) {
      yield put(activacionTarjetasKitActions.fetchActivateKitCVSuccessKit(data));
    } else {
      yield put(activacionTarjetasKitActions.fetchActivateKitCVErrorKit(error));
    }
  } catch (err) {
    console.error(err.message, 'fetchActivateKitCVKit');
  }
}


export function* fetchValidateSMSCodeResetKit() {
  yield put(activacionTarjetasKitActions.fetchValidateSMSCodeResetSuccessKit());
}

export function* fetchGetKitResetKit() {
  yield put(activacionTarjetasKitActions.fetchGetKitResetSuccessKit());
}

export function* fetchValidarTarjetaCoordenadasResetKit() {
  yield put(activacionTarjetasKitActions.fetchValidarTarjetaCoordenadasResetSuccessKit());
}

export function* fetchSendSMSCodeResetKit() {
  yield put(activacionTarjetasKitActions.fetchSendSMSCodeResetSuccessKit());
}

export function* fetchIsEnabledPassCoopeuchResetKit() {
  yield put(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchResetSuccessKit());
}

export function* fetchSolicitarPassCoopeuchResetKit() {
  yield put(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchResetSuccessKit());
}

export function* fetchValidarPassCoopeuchResetKit() {
  yield put(activacionTarjetasKitActions.fetchValidarPassCoopeuchResetSuccessKit());
}

export function* fetchActivateKitCVResetKit() {
  yield put(activacionTarjetasKitActions.fetchActivateKitCVResetSuccessKit());
}

// Watchers
export function* watchFetchTarjetasCoordenadasKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchGetKitKit.type, fetchGetKitKit);
}

export function* watchFetchValidarTarjetaCoordenadasKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchValidarTarjetaCoordenadasKit.type,
    fetchValidarTarjetaCoordenadasKit
  );
}

export function* watchFetchSendSMSCodeKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchSendSMSCodeKit.type, fetchSendSMSCodeKit);
}

export function* watchFetchValidateSMSCodeKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchValidateSMSCodeKit.type, fetchValidateSMSCodeKit);
}

export function* watchFetchValidateSMSCodeResetKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchValidateSMSCodeResetKit.type,
    fetchValidateSMSCodeResetKit
  );
}

export function* watchFetchDownloadComprobanteKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchDownloadComprobanteKit.type,
    fetchDownloadComprobanteKit
  );
}

export function* watchFetchGetKitResetKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchGetKitResetKit.type, fetchGetKitResetKit);
}

export function* watchFetchValidarTarjetaCoordenadasResetKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchValidarTarjetaCoordenadasResetKit.type,
    fetchValidarTarjetaCoordenadasResetKit
  );
}

export function* watchFetchSendSMSCodeResetKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchSendSMSCodeResetKit.type, fetchSendSMSCodeResetKit);
}

export function* watchFetchGetKitBannerKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchGetKitBannerKit.type, fetchGetKitBannerKit);
}

export function* watchFetchIsEnabledPassCoopeuchKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchKit.type,
    fetchIsEnabledPassCoopeuchKit
  );
}

export function* watchFetchSolicitarPassCoopeuchKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchSolicitarPassCoopeuchKit.type,
    fetchSolicitarPassCoopeuchKit
  );
}

export function* watchFetchIsEnabledPassCoopeuchResetKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchResetKit.type,
    fetchIsEnabledPassCoopeuchResetKit
  );
}

export function* watchFetchSolicitarPassCoopeuchResetKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchSolicitarPassCoopeuchResetKit.type,
    fetchSolicitarPassCoopeuchResetKit
  );
}

export function* watchFetchValidarPassCoopeuchKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchValidarPassCoopeuchKit.type,
    fetchValidarPassCoopeuchKit
  );
}

export function* watchFetchValidarPassCoopeuchResetKit() {
  yield takeLatest(
    activacionTarjetasKitActions.fetchValidarPassCoopeuchResetKit.type,
    fetchValidarPassCoopeuchResetKit
  );
}

export function* watchFetchActivateKitCVKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchActivateKitCVKit.type, fetchActivateKitCVKit);
}

export function* watchFetchActivateKitCVResetKit() {
  yield takeLatest(activacionTarjetasKitActions.fetchActivateKitCVResetKit.type, fetchActivateKitCVResetKit);
}


export default function* rootSaga() {
  yield spawn(watchFetchTarjetasCoordenadasKit);
  yield spawn(watchFetchValidarTarjetaCoordenadasKit);
  yield spawn(watchFetchSendSMSCodeKit);
  yield spawn(watchFetchValidateSMSCodeKit);
  yield spawn(watchFetchDownloadComprobanteKit);
  yield spawn(watchFetchValidateSMSCodeResetKit);
  yield spawn(watchFetchGetKitResetKit);
  yield spawn(watchFetchValidarTarjetaCoordenadasResetKit);
  yield spawn(watchFetchSendSMSCodeResetKit);
  yield spawn(watchFetchGetKitBannerKit);
  yield spawn(watchFetchIsEnabledPassCoopeuchKit);
  yield spawn(watchFetchSolicitarPassCoopeuchKit);
  yield spawn(watchFetchIsEnabledPassCoopeuchResetKit);
  yield spawn(watchFetchSolicitarPassCoopeuchResetKit);
  yield spawn(watchFetchValidarPassCoopeuchKit);
  yield spawn(watchFetchValidarPassCoopeuchResetKit);
  yield spawn(watchFetchActivateKitCVKit);
  yield spawn(watchFetchActivateKitCVResetKit);
}

