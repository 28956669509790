import React from 'react';
import Card from '@coopeuch-components/web/atoms/Card';
import { Text } from './styles';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({ className }) => {
  return (
    <Card className={className}>
      <div className="center-xs">
        <div className="row center-xs">
          <img src={`${iconPath}ilustracion_info.svg`} alt="login-error" />
        </div>
        <Text textSize={24} size={32} weight="bold">
          En mantención
        </Text>
        <div className="col-xs-12 col-md-8 col-md-offset-2">
          <Text textSize={18} size={28} weight="normal">
            Nos encontramos actualizando nuestra plataforma.
          </Text>
          <Text textSize={18} size={28} weight="normal">
          Agradecemos tu comprensión.
          </Text>
        </div>
      </div>
    </Card>
  );
};

export default Index;
