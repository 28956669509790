import React from 'react';
import Typography from '@coopeuch-components/web/atoms/Typography';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
//Styles
import { Banner, CustomList, AdjustIcons } from './styles';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const index = () => {
  return (
    <>
      <div className="sincredito row bmt-3 bmt-md-4">
        <div className="col-xs-12 w-100">
          <Banner src={`${staticPath}creditoHipotecario/banner_desk_hipotecario.jpg`}>
            <div className="row h-100 banner-actions">
              <div className="col-xs-8 col-sm-5 bpl-5">
                <Typography className="mb-8" color={'white'} tag="p" fontSize={24}>
                  Crédito Hipotecario
                </Typography>
                <Typography
                  className="mb-8"
                  color={'white'}
                  tag="p"
                  fontSize={24}
                  fontFamily="ubuntu-bold"
                >
                  El mejor financiamiento <br />
                  para tu nueva casa.
                </Typography>
              </div>
            </div>
          </Banner>
        </div>
      </div>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12 w-100">
          <Typography
            className="mb-8"
            color="#333333"
            tag="p"
            fontSize={18}
            fontFamily="ubuntu-bold"
          >
            ¿Cuáles son los beneficios?
          </Typography>
        </div>
      </div>

      <AdjustIcons>
        <div className="row bmt-3 bmt-md-4">
          <div className="col-xs-12 col-md-6">
            <CardBox className="h-100 w-auto bmr-md-1">
              <CardBox.Header>
                <LoadIcon
                  src={`${iconPath}home_alt.svg`}
                  size={30}
                  color="#000"
                  className="adjust"
                />{' '}
                <span className="font">90% de Financiamiento</span>
              </CardBox.Header>
              <CardBox.Body>
                <CustomList>
                  <li className="bmy-3">
                    Aplicable a compra venta complementada con subsidio habitacional. Resto de las
                    operaciones 80%.
                  </li>
                </CustomList>
              </CardBox.Body>
            </CardBox>
          </div>
          <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
            <CardBox className="h-100 w-auto bml-md-1">
              <CardBox.Header>
                <LoadIcon
                  src={`${iconPath}calendar_alt.svg`}
                  size={30}
                  color="#000"
                  className="adjust"
                />{' '}
                <span className="font">Hasta 6 meses de gracia</span>
              </CardBox.Header>
              <CardBox.Body>
                <CustomList>
                  <li className="bmy-3">
                    Prórroga en el pago de la primera cuota de tu dividendo hipotecario.
                  </li>
                </CustomList>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
        <div className="row bmt-3 bmt-md-4 bmb-3">
          <div className="col-xs-12 col-md-6">
            <CardBox className="h-100 w-auto bmr-md-1">
              <CardBox.Header>
                <LoadIcon
                  src={`${iconPath}discount.svg`}
                  size={30}
                  color="#000"
                  className="adjust"
                />{' '}
                <span className="font">Descuento Pago Oportuno MINVU</span>
              </CardBox.Header>
              <CardBox.Body>
                <CustomList>
                  <li className="bmy-3">
                    Descuento de un 10% a un 20% pagando al día tu dividendo.
                  </li>
                </CustomList>
              </CardBox.Body>
            </CardBox>
          </div>
          <div className="col-xs-12 col-md-6 bmt-3 bmt-md-0">
            <CardBox className="h-100 w-auto bml-md-1">
              <CardBox.Header>
                <LoadIcon
                  src={`${iconPath}dollar_coin.svg`}
                  size={30}
                  color="#000"
                  className="adjust"
                />{' '}
                <span className="font">Aumenta tu Remanente</span>
              </CardBox.Header>
              <CardBox.Body>
                <CustomList>
                  <li className="bmy-3">
                    El uso de productos Coopeuch aporta a aumentar tu Remanente anual.
                  </li>
                </CustomList>
              </CardBox.Body>
            </CardBox>
          </div>
        </div>
      </AdjustIcons>
    </>
  );
};

export default index;
