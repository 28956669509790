import { Input, Message } from '@design-system-coopeuch/web';
import React from 'react';
import Stepper from '../../../organism/Stepper';
import FooterBackAndContinue from '../../../organism/BonoEscolar/FooterBackAndContinue';
import { regexOnlyNumber } from '../../../../utils/formatters';
// Atoms
import CardShadow from '../../../atoms/CardShadow';
// Styles
import Wrapper from './styles';

const ConfirmacionTemplate = ({
  steps,
  active,
  setPhoneInput,
  phoneInput,
  emailInput,
  handleEmail,
  validateErrorPhone,
  validateErrorEmail,
  handleClickContinue,
  handleClickBack,
}) => {
  return (
    <Wrapper>
      <Stepper className="start-xs" position={1} steps={steps} />
      <div className="boxContainer">
        <CardShadow
          title="Datos del titular"
          content={
            <div className="cardDatos__content">
              <div className="cardDatos__content__input">
                <div
                  className={`cardDatos__content__input__phono ${
                    !validateErrorPhone && 'marginInput'
                  }`}
                >
                  <Input
                    className="cardDatos__content__input__phono__box"
                    label="Teléfono fijo o celular"
                    maxLength={9}
                    value={regexOnlyNumber(phoneInput)}
                    onChange={(phone) => setPhoneInput(phone)}
                    error={validateErrorPhone && true}
                    assistText={
                      validateErrorPhone && [
                        { text: 'Ingresa un número de 9 dígitos', type: 'error' },
                      ]
                    }
                  />
                </div>
                <div className="cardDatos__content__input__email">
                  <Input
                    className="cardDatos__content__input__email__box"
                    label="Email"
                    maxLength={100}
                    value={emailInput}
                    onChange={(e) => handleEmail(e)}
                    error={!validateErrorEmail && true}
                    assistText={
                      !validateErrorEmail && emailInput.length >= 1 && [{ text: 'Ingresa un email válido', type: 'error' }]
                    }
                  />
                </div>
              </div>
              <Message
                className="cardDatos__content__alert"
                type="info"
                description={
                  <p>
                    ¡Importante! Verifica que tu número de teléfono y correo estén actualizados, ya
                    que serás contactado por este medio.
                  </p>
                }
              />
            </div>
          }
        />
      </div>
      <FooterBackAndContinue
        btnContinueDisabled={active}
        handleClickContinue={handleClickContinue}
        txtBack="Volver"
        txtBtn="Confirmar y Continuar"
        handleClickBack={handleClickBack}
      />
    </Wrapper>
  );
};

export default ConfirmacionTemplate;
