import React, { useState, useEffect } from 'react';

const Index = ({
  time,
  counterActive,
  setCounterActive,
  setTimeOut,
  setFactorError,
  setAskServicePass,
  timeOutPassCoopeuch,
}) => {
  const [value, setValue] = useState();

  //   let time = 10;
  const updateCountDown = () => {
    const minutes = Math.floor(time / 60);
    let seconds = time % 60;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    time--;
    // stop the setInterval whe time = 0 for avoid negative time
    if (time < 0) {
      // console.log('llegamos a -1');
      // clearInterval(updateCountdown);
    }

    // console.log(minutes, seconds);

    return { minutes, seconds };
  };

  useEffect(() => {
    // ACTUALIZACION: componentDidUpdate
    let temp;
    if (counterActive) {
      // console.log('estoy en activo');
      temp = setInterval(() => setValue(updateCountDown()), 1000);
    }
    // Todo lo que esté en esta función de DESMONTAJE, es lo que se va a ejecutar cuando el Componente se desmonte.
    return () => clearInterval(temp);
  }, [counterActive]);

  useEffect(() => {
    if (value && value.minutes === 0 && value.seconds === '00') {
      setCounterActive(false);
      setTimeOut(true);
      if (timeOutPassCoopeuch) {
        setFactorError('1');
      } else {
        setFactorError('3');
      }
      setAskServicePass(false);
    }
  }, [value]);

  return (
    <>
      {value && counterActive && (
        <>
          {value.minutes}:{value.seconds} Min para autorizar
        </>
      )}

      {/* <button onClick={() => setActivo(false)}>detener</button> */}
    </>
  );
};

Index.displayName = 'CountdownNumbers';
export default Index;
