import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import OutsideClickHandler from 'react-outside-click-handler';

// styles
import Wrapper, { LabelWrapper, SelectInput, IconWrapper, OptionsContainer } from './styles';
import CalendarIcon from './CalendarIcon';

const Select = (props) => {
  const {
    borderColor,
    labelClass,
    className,
    containerClass,
    onChange,
    textSize,
    options,
    placeholder,
    dataSet,
    title,
    value,
    id,
  } = props;

  const [open, setOpen] = useState(false);

  const handleToggle = useCallback(() => setOpen(!open), [open]);
  const handleClose = useCallback(() => setOpen(false), []);

  const handlePressOption = useCallback(
    (value) => {
      onChange({
        target: {
          dataset: {
            id: dataSet,
          },
          value,
        },
      });

      handleToggle();
    },
    [dataSet, onChange, handleToggle]
  );

  const currentValue = useMemo(
    () => options?.find((item) => item?.value === value),
    [options, value]
  );

  return (
    <>
      {title ? <LabelWrapper className={labelClass}>{title}</LabelWrapper> : null}

      <OutsideClickHandler onOutsideClick={handleClose}>
        <Wrapper className={containerClass}>
          <SelectInput
            open={open}
            onClick={handleToggle}
            borderColor={borderColor}
            className={className}
            onChange={onChange}
            textSize={textSize}
            placeholder={placeholder}
            data-id={dataSet}
            value={value}
            id={id}
          >
            <div className="select-container-all">
              <div className="select-container-value">
                <label>{currentValue?.label || placeholder}</label>
              </div>
              <div className="p-0">
                <IconWrapper>
                  <div className="icon-container-select">
                    <CalendarIcon />
                  </div>
                </IconWrapper>
              </div>
            </div>
          </SelectInput>
          <OptionsContainer open={open} numberOfOptions={options?.length}>
            {options.map((item, index) => (
              <div key={index} value={item.value} onClick={() => handlePressOption(item.value)}>
                {item.label}
              </div>
            ))}
          </OptionsContainer>
        </Wrapper>
      </OutsideClickHandler>
    </>
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  borderColor: PropTypes.string,
  labelClass: PropTypes.string,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  onChange: PropTypes.func,
  textSize: PropTypes.number,
  dataSet: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  title: PropTypes.string,
  id: PropTypes.string,
};

Select.defaultProps = {
  onChange() {},
  placeholder: '',
  borderColor: '',
  labelClass: '',
  className: '',
  containerClass: '',
  textSize: 16,
  dataSet: '',
  options: [],
  title: '',
  value: '',
  id: '',
};

export default Select;
