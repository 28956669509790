import React from 'react';
import PropTypes from 'prop-types';
// styles
import SwitchWrapper, { InputWrapper, SwitchButton } from './styles';

const Switch = (props) => {
  const { checked, disabled, onChange, name, activeColor, dataValue } = props;
  // console.log(dataValue);
  return (
    <SwitchWrapper activeColor={activeColor} checked={checked} disabled={disabled}>
      <InputWrapper
        type="checkbox"
        onChange={onChange}
        checked={checked}
        name={name}
        data-value={dataValue}
      />
      <SwitchButton />
    </SwitchWrapper>
  );
};

Switch.propTypes = {
  activeColor: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  dataValue: PropTypes.string,
};

Switch.defaultProps = {
  activeColor: '#fff',
  disabled: false,
  checked: false,
  dataValue: '',
};

export default Switch;
