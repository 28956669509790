import React from 'react';
import PropTypes from 'prop-types';

import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Card, CardFooter } from '@design-system-coopeuch/web';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import PageTitle from "../../../atoms/PageTitle";
import StepsActions from '../../../organism/Stepper/StepsActions';
import { PageTitleWrapper, Wrapper, DownloadLink } from './styles';
// eslint-disable-next-line import/no-named-as-default
import SkeletonComprobante from './skeleton';

function ComprobanteTemplate({ onGoBackHome, handleDownload, loading, cuotasPactadas, montoPagado, descargarComprobanteModificacionCuotasParticipacionError }) {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const fechaFormateada = format(new Date(), "dd 'de' MMM. yyyy HH:mm 'hrs'", {
    locale: es,
  });
    return (
      <>
        <PageTitleWrapper>
          <PageTitle
            id="header-cuota-participacion"
            title="Cuota de Participación"
            icon={<LoadIcon src={`${iconPath}pie_chart.svg`} color="#333" />}
          />
        </PageTitleWrapper>
        <Wrapper>
          {loading ? <SkeletonComprobante /> : 
          <>
            <div className="row bmt-3 bmb-3 bmb-md-4">
              <div className="col-xs-12">
                {
                  descargarComprobanteModificacionCuotasParticipacionError?.error ?
                <Alert className="textExitoso" bgType="error" id="alert-felicitaciones-poder-especial" show>
                  Tenemos error en nuestros servicios, por el momento no se puede descargar el comprobante, inténtelo nuevamente.
                </Alert>
                :
                <Alert className="textExitoso" bgType="success" id="alert-felicitaciones-poder-especial" show>
                  ¡Tus cuotas de participación fueron modificadas con éxito!
                </Alert>
                }
                
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <Card>
                  <CardBox.Header>
                    <div className='row'>
                      <div className='col-lg-6 col-xs-6'>
                        <h1 className='cardTitle'>
                          Comprobante
                        </h1>
                      </div>
                      <div className='col-lg-6 col-xs-6 d-flex justify-content-end align-items-center' role='button'>
                        <DownloadLink className="middle-xs" onClick={handleDownload}>
                          <LoadIcon
                            className="float-left mr-10"
                            src={`${iconPath}download_arrow.svg`}
                            color="#1F1E1E"
                            size={22.5}
                          />
                          <span className='d-none d-md-inline-block'>Descargar</span>
                        </DownloadLink>
                      </div>
                    </div>
                  </CardBox.Header>
        
                  <CardBox.Body>
                    <div className='row'>
                      <div className='col-md-12 col-xs-10'>
                        <p className='cardBodyTitle'>
                          Modificación de cuotas de participación
                        </p>
                      </div>
                    </div>
                    <div className='row bmt-4 bmt-md-8'>
                      <div className='col-md-7'>
                        <p className='cardBodySubTitles'>
                          Nuevas cuotas pactadas
                        </p>
                      </div>
                      <div className='col-md-5'>
                        <p className='cardBodyProducts'>
                          {cuotasPactadas || '0'} cuotas
                        </p>
                      </div>
                    </div>
                    <div className='row bmt-4 bmt-md-8'>
                      <div className='col-md-7'>
                        <p className='cardBodySubTitles'>
                          Nuevo aporte a pagar
                        </p>
                      </div>
                      <div className='col-md-5'>
                        <p className='cardBodyProducts'>
                          $
                          {' '}
                          {montoPagado || '0'}
                        </p>
                      </div>
                    </div>
                  </CardBox.Body>
        
                  <CardFooter>
                    <div className='row'>
                      <div className="col-md-10 col-xs-12 bmt-4 bmt-xs-0 d-flex">
                        <Typography tag="p" fontSize={14}>
                          Fecha y hora:
                          {' '}
                          <Typography tag="span" fontSize={14} fontWeight="bold">
                            {fechaFormateada || '0'}
                          </Typography>
                        </Typography>
                      </div>
                      <div className="col-md-2 col-xs-12 bmt-4 bmt-xs-8 d-flex">
                        <LoadIcon src={`${iconPath}icon_sello.svg`} className="icon-sello" />
                      </div>
                    </div>
                  </CardFooter>
                </Card>
                <StepsActions
                  id="comprobante"
                  className="bmt-3 mt-24"
                  backText="Volver al inicio"
                  backAction={onGoBackHome}
                  hideNextAction
                />
              </div>
            </div>
          </>}
        </Wrapper>
      </>
    );
}

ComprobanteTemplate.propTypes = {
  onGoBackHome: PropTypes.func.isRequired,
  handleDownload: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  cuotasPactadas: PropTypes.string.isRequired,
  montoPagado: PropTypes.string.isRequired,
  fechaActualizacion: PropTypes.string.isRequired,
  descargarComprobanteModificacionCuotasParticipacion: PropTypes.shape({
    success: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};

ComprobanteTemplate.defaultProps = {
  loading: false,
};

export default ComprobanteTemplate;
