import styled from 'styled-components';

export default styled.div`
  .content-dap {
    padding-left: 54px;
    padding-right: 54px;
  }

  .dap-resumen-monto-vencimiento {
    padding-top: 24px;
    padding-bottom: 16px;
  }

  .dap--card--content {
    padding: 24px !important;
  }

  .dap--resumen--seleccion-simulacion {
    height: 48px;
    padding: 12px;
    border: 1px solid #646569;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }

  .dap--card {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .dap-top--card {
    margin-top: 24px;
  }

  #liquidacion-sin-cuentas-vistas-modal-prev-action {
    align-items: center !important;
  }

  @media screen and (max-width: 766px) {
    .content-dap {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 16px !important;
    }

    #dap-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }

    .dap-resumen-monto-vencimiento {
      padding-top: 16px;
      padding-bottom: 8px;
    }

    .dap--card {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .dap-top--card {
      margin-top: 24px !important;
    }

    .dap-details--card {
      margin-top: 16px !important;
    }

    .dap--card--content {
      padding: 16px !important;
    }

    .dap-resumen-texto {
      margin-top: 8px;
    }

    .pl-32,
    .pl-24 {
      padding-left: 16px !important;
    }

    .pr-32,
    .pr-24 {
      padding-right: 16px !important;
    }

    button {
      width: 100%;
    }
  }
`;
