import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { DownloadLink } from './styles';

const Index = ({ text, href, onClick }) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  return (
    <DownloadLink className="middle-xs" onClick={onClick}>
      <a href={href} target="_blank" rel="noopener noreferrer" download>
        <LoadIcon
          className="float-left mr-10"
          src={`${iconPath}download_arrow.svg`}
          color="#1F1E1E"
          size={22.5}
        />
        {`    ${text}`}
      </a>
    </DownloadLink>
  );
};

export default Index;
