export const codigosCuenta = {
  VI01: 'Cuenta Vista',
  VI02: 'Cuenta Vista Cerrada',
  VI03: 'Cuenta Vista Convenio',
  VI04: 'Cuenta Vista Recaudación',
  VI05: 'Tarjeta Dale',
  VI06: 'Cuenta Vista Monedero Digital',
  AR01: 'Ahorro (Sin nombre asignado)',
  AN04: 'Ahorro Dorada Bip',
  AR05: 'Ahorro Adulto Bip',
  AN06: 'Ahorro FUTURO Nominal',
  AR07: 'Ahorro TRADICIONAL Reajustable Bip',
  AN08: 'Ahorro TRADICIONAL Nominal Bip',
  AR09: 'Ahorro FUTURO Reajustable',
};

export const PAYMENT_METHOD = {
  1: 'Abono a mora',
  2: 'Capitalización',
  3: 'Capitalización',
  5: 'Transferencia',
  8: 'Transferencia',
  19: 'Banco Estado',
  20: 'Coopeuch',
  30: 'Cuenta Vista',
  31: 'Servipag',
  32: 'Pago Web',
};

export const codigosVistas = ['VI01', 'VI02', 'VI03', 'VI04', 'VI05', 'VI06'];
export const codigosAhorro = [
  'AR01',
  'AN01',
  'AN02',
  'AN04',
  'AR05',
  'AN06',
  'AR07',
  'AN07',
  'AN08',
  'AR09',
  'AR02',
  'AR06',
  'AR03',
  'AR08',
];

export const tipoCuentas = {
  CV: 'Cuenta Vista',
  AH: 'Cuenta Ahorro',
  CC: 'Cuenta Corriente',
};

export const tipoCuentasAbreviados = {
  CV: 'Cta. Vta.',
  AH: 'Cta. Aho.',
  CC: 'Cta. Cte.',
};

export const metodoFactores = {
  3.1: 'SMS',
  2.2: 'CoopeuchPass',
  2.1: 'TarjetaCoordenadas',
};

export const codigosModuloCuentaAhorro = [
  'AN01',
  'AN02',
  'AN04',
  'AR04',
  'AR01',
  'AR02',
  'AR05',
  'AR03',
  'AN06',
  'AN07',
  'AN08',
  'AR09',
  'AR06',
  'AR07',
  'AR08',
];

export const estadosModuloCuentaAhorro = ['A', 'I', 'D', 'O', 'E', 'T'];

export const MEDIO_DE_PAGO_HIPOTECARIO = {
  '': 'Caja',
  2: 'PAC Tarjeta de Credito',
  4: 'PAC Automático',
  NP: 'No Programado',
};
export const estadoTarjetaCredito = { '01': 'Activa', '00': 'Inactiva' };

export const tipoTarjetaCredito = { T: 'Titular', A: 'Adicional' };

export const tarjetaCreditoDorada = ['TN01', 'TN05', 'TN09'];

export const tarjetaCreditoNacional = ['TN02', 'TN07', 'TN10'];

export const descripcionCuentas = { 'VI01': 'Cuenta Vista Coopeuch', 'VI03' : 'Cuenta Vista Coopeuch'}

export const anioActual = new Date().getFullYear();

export const flujoFactoresTefInternas = {
  conFactores: {
    idValidaNuevaTef: 23,
    idValidacionTef: 28,
    idComprobante: 30
  },
  sinFactores: {
    idValidaNuevaTef: 64,
    idValidacionTef: 65,
    idComprobante: 67
  },
  codNoFactorSeguridad: 4
}