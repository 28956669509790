import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Table from '@coopeuch-components/web/molecules/Table';
import StepsActions from '../../../../../organism/Stepper/StepsActions';
import { Paragraph, Wrapper, ActionButtons } from './styles';
import { agregarformatoPesos, formatThousandsSeparators } from '../../../../../../utils/formatters';
import './styles.css';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  detalle,
  backButton,
  nextButton,
  openSendEmailModal,
  onClickDownloadComprobante,
}) => {
  const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
  const comprobante = `${staticPath}sello-comprobante.png`;
  const accountType = detalle.codigoProducto === "VI06" ? "Monedero Digital N° " : "Cuenta Vista Coopeuch N° "
  const contractData = [
    {
      id: 0,
      name: 'Remanente Pagado',
      value: agregarformatoPesos(detalle.remanentePagado),
    },
    {
      id: 1,
      name: 'Máx. Cuotas a Capitalizar',
      value: formatThousandsSeparators(detalle.maximoCuotaCapitalizar),
    },
    {
      id: 2,
      name: 'Valor Cuota',
      value: agregarformatoPesos(detalle.valorCuota),
    },
    {
      id: 3,
      name: 'Cuotas Capitalizadas',
      value: formatThousandsSeparators(detalle.cuotasCapitalizadas),
    },
    {
      id: 3,
      name: 'Monto Capitalizado',
      value: agregarformatoPesos(detalle.montoCapitalizado),
    },
    {
      id: 3,
      name: 'Fecha',
      value: detalle.fecha,
    },
    {
      id: 3,
      name: 'Cuenta de Origen',
      value: `${accountType} ${detalle.cuentaOrigen}`,
    },
  ];
  return (
    <Wrapper>
      <Alert
        className="alert-comprobante-contratacion-monedero bp-3 bmy-3 bmy-md-4"
        bgType="success"
        id="alert-felicitaciones-contratacion-monedero"
        show
      >
        <React.Fragment>La Capitalización ha sido realizada de forma exitosa</React.Fragment>
      </Alert>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>
          <div className="row">
            <div className="col-xs-6">Comprobante</div>
            <div className="col-xs-6 text-right">
              <ActionButtons>
                <div className="comprobante-action bmr-3" onClick={onClickDownloadComprobante}>
                  <LoadIcon
                    className="mr-2"
                    src={`${iconPath}download_arrow.svg`}
                    color="#F42534"
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text">Descargar</div>
                </div>
                <div className="comprobante-action" onClick={openSendEmailModal}>
                  <LoadIcon
                    className="mr-2"
                    src={`${iconPath}email.svg`}
                    color="#F42534"
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text">Enviar</div>
                </div>
              </ActionButtons>
            </div>
          </div>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12 col-md-8 bmb-md-3 comprobante-card-text">
              <div className="text-wrapper">
                <Paragraph className="mb-2" size={14}>
                  <b>Monto Capitalizado</b>
                </Paragraph>
                <Paragraph className="mb-2" size={24}>
                  <b> {agregarformatoPesos(detalle.montoCapitalizado)}</b>
                </Paragraph>
                <Paragraph size={14}>
                  <React.Fragment>
                    {!!detalle.email && (
                      <React.Fragment>
                        Se enviará un comprobante de Monto Capitalizado a tu email: <b>{detalle.email}</b>.
                      </React.Fragment>
                    )}
                  </React.Fragment>
                </Paragraph>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className="bm-auto bmr-md-0" src={comprobante} alt="comprobante" />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Detalle de la Capitalización</CardBox.Header>
        <CardBox.Body>
          <Table
            borderColor="trasparent"
            fullWidth
            alignContent="left"
            stripeColor="#F6F3F5"
            stripeReverse
          >
            <Table.Body
              data={contractData}
              renderItems={(item, TR, TD) => {
                return (
                  <TR key={`detalle-contrato-monedero-${item.id}`}>
                    <TD className="td-table-element-capitalizacion pt-16 pb-16 pl-8 pr-8">
                      {item.name}
                    </TD>
                    <TD className="td-table-element-capitalizacion pt-16 pb-16 pl-8 pr-8">
                      {item.value}
                    </TD>
                  </TR>
                );
              }}
            ></Table.Body>
          </Table>
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmb-3 bmb-md-4"
        backAction={backButton}
        backText={'Ir a home'}
        nextText={'Ir a Remanente'}
        nextAction={nextButton}
        id="contratacion-monedero-comprobante"
      />
    </Wrapper>
  );
};

export default Index;
