import React from 'react';
import PropTypes from 'prop-types';
import { Link as LinkRRD } from 'react-router-dom';
// UI
import Avatar from '@coopeuch-components/web/atoms/Avatar';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import ListToggle from './ListToggle';

// utils
import { capitalize } from '../../../utils/functions';

// styles
import {
  MobileHeader,
  ListItems,
  MobileWrapper,
  ShadowMobile,
  MobileLogout,
  DesktopWrapper,
  MobileButtonClose,
  AccessHeader,
} from './styles';
import { colors } from '../../../assets/styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Menu = ({
  title,
  avatarLetters,
  subtitle,
  itemList,
  onClickMenuItem,
  onToggleMenuHandle,
  onClickMenuWithChildren,
  showMenu,
  onClickLogoutHandle,
  fullScreen,
  lastAccess,
}) => {
  // icon arrow en subtitle
  let arrow = false;
  subtitle === 'Editar mis Datos' ? (arrow = true) : (arrow = false);
  return (
    <>
      {!fullScreen && <DesktopWrapper className="d-none d-md-block bpt-3">
        <ListToggle
          items={itemList}
          onClickMenuItem={onClickMenuItem}
          onClickMenuWithChildren={onClickMenuWithChildren}
        />
        <ListItems />
      </DesktopWrapper>
      }

      <MobileWrapper className={`col-xs-12 ${showMenu ? 'd-block' : 'd-none'} d-md-none`}>
        <div className="row">
          <div className="col-xs-11 col-sm-4 mobile-menu-column">
            <MobileHeader className="row">
              <div className="col-xs-9">
                <LinkRRD
                  to="/ecd/mi-perfil"
                  data-lab="Consultar Perfil"
                  // onClick={onClickHeaderPerfil}
                  className="none-underline"
                  underline={false}
                >
                  <Avatar
                    className="avatar-element-menu"
                    // rightTopCorner={<LoadIcon src={`${iconPath}close.svg`} size={12} />}
                    // rightTopCornerFunc={onToggleMenuHandle}
                    subtitleClass="text-thin text-size-xs white"
                    letterColor={colors.white}
                    titleClass="text-bold white"
                    subtitle={subtitle}
                    letters={avatarLetters}
                    bgColor={colors.darkRed}
                    mobile
                    title={capitalize(title)}
                    size={40}
                    id="avatar-header-mobile"
                  >
                    {arrow && (
                      <LoadIcon
                        color="#ffffff"
                        className="arrow-menu-mobile"
                        src={`${iconPath}arrow_right.svg`}
                        size={16}
                      />
                    )}
                  </Avatar>
                </LinkRRD>
                <AccessHeader>{lastAccess && <p>{lastAccess}</p>}</AccessHeader>
              </div>
              <div className="col-xs-2 container-close-button">
                <MobileButtonClose onClick={onToggleMenuHandle}>
                  <LoadIcon color="#ffffff" src={`${iconPath}close.svg`} size={12} />
                </MobileButtonClose>
              </div>
            </MobileHeader>
            <ListItems className="row">
              <div className="col-xs-12 bpt-3 menu-column">
                <ListToggle
                  items={itemList}
                  onClickMenuItem={onClickMenuItem}
                  onClickMenuWithChildren={onClickMenuWithChildren}
                />
                <MobileLogout onClick={onClickLogoutHandle}>
                  <LoadIcon
                    src={`${iconPath}logout.svg`}
                    className="logout-icon float-left"
                    size={16}
                    color="#F42534"
                  />
                  <p className="text-size-m red float-left">Cerrar sesión</p>
                </MobileLogout>
              </div>
            </ListItems>
          </div>
          <ShadowMobile onClick={onToggleMenuHandle} className="col-xs-4 col-sm-8" />
        </div>
      </MobileWrapper>
    </>
  );
};

Menu.propTypes = {
  onClickMenuItem: PropTypes.func,
  itemList: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string,
  avatarLetters: PropTypes.string,
  subtitle: PropTypes.string,
  onToggleMenuHandle: PropTypes.func,
  classes: PropTypes.string,
  onClickLogoutHandle: PropTypes.func,
};

Menu.defaultProps = {
  onClickMenuItem: null,
  itemList: [],
  title: '',
  avatarLetters: '',
  subtitle: '',
  onToggleMenuHandle: null,
  classes: '',
  onClickLogoutHandle: null,
};

export default Menu;
