const initState = {
  pdfMovements: { success: false, error: false, data: null },
  cuentas: [],
  errCuentasLog: null,
  errorFetchCuentas: false,
  movimientos: [],
  errorFetchMovimientos: false,
  detalle: {},
  errorFetchDetalle: false,
  errMovimientosLog: null,
  errDetallesLog: null,
  contratacionValidar: { success: false, error: false, data: null, date: null, errorCode: '' },
  contratacionGenerar: { success: false, error: false, data: null, date: null, errorCode: '' },
  documentsPDF: { success: false, error: false, data: null },
  datosPersonales: { success: false, error: false, errorCode: '', data: null, date: null },
  contratacionIniciar: { success: false, error: false, errorCode: '', data: null },
  migracionIniciar: { success: false, error: false, errorCode: '', data: null },
  factor: {
    hasCpass: false,
    hasTcoo: false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    validacionFactorDeSeguridad: false,
    primerFactorValidado: '',
    errorFactorLog: null,
  },
  cpass: {
    isFetchingInitialize: false,
    isSuccessInitialize: false,
    isErrorInitialize: false,
    isValid: false,
    isInvalid: false,
    isBlocked: false,
    isPolling: false,
    isFetchingValidate: false,
    isSuccessValidate: false,
    isErrorValidate: false,
  },
  executiveCode: {
    isLoading: false,
    executiveName: '',
    success: false,
    error: false,
    errorCode: null,
    errorMessage: ''
  },
};

export default initState;
