import styled from 'styled-components';
import { colors } from '../../../../../assets/styles/index';

export default styled.div`
  .justify-content {
    justify-content: space-between;
  }
  .valores-card-cdp {
    background-color: #22315c;
    color: white;
    border-radius: 5px;
    p {
      text-align: left;
    }
    .col-element {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: 64em) {
      height: 100%;
      p {
        text-align: center;
      }
      .col-element {
        flex-direction: column;
      }
    }
  }
`;

export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: ${colors.redErrorInputColor};

    path {
      fill: ${colors.redErrorInputColor};
    }
  }
`;
