import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  color: #383638 !important;

  input::-webkit-contacts-auto-fill-button,
  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
  }

  .input-text-writer {
    padding-left: 40px;
    border-radius: 2px;
  }

  .lock-icon {
    position: absolute;
    z-index: 2;
    top: 15px;
    left: 14px;
  }

  .eye-icon {
    position: absolute;
    right: 13px;
    top: 4px;
    cursor: pointer;
  }

  .help-text {
    position: absolute;
    left: 0;
    right: 32px;
    top: 52px;
    font-size: 14px;
  }

  .display {
    display: initial;
  }

  .hide {
    display: none;
  }

  small {
    margin-top: 5px;
  }

  .password-error small {
    color: #cc284e;
  }

  @media screen and (max-width: 1024px) {
    .error-text {
      width: 195px;
    }

    .error-help-text {
      margin-bottom: -16px;
    }
  }
`;

export default Wrapper;
