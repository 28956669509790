import styled from 'styled-components';
import AlertModal from '../../../../atoms/AlertModal';
import { ModalBody, ModalTitle } from '../../../../atoms/AlertModal/styles';

export const StyledAlertModal = styled(AlertModal)`
  .alert-modal-icon-cerrar svg path {
    fill: #747474;
  }
  .modalBase {
    padding-right: initial !important;
    padding-left: initial !important;
  }
  ${ModalTitle} {
    font-size: 24px;
    padding-right: 16px;
    padding-left: 16px;
    @media (min-width: 768px) {
      padding-right: 32px;
      padding-left: 32px;
      .col-xs-10 {
        max-width: initial !important;
        flex-basis: initial !important;
      }
    }
  }
  ${ModalBody} {
    width: initial !important;
  }
  ._2p8qe._3lx5h {
    min-width: 196px;
    justify-content: center;
  }
  p {
    max-width: 493px;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 400;
  }
`;

const Wrapper = styled.div`
  background-color: ${(props) => (props.backgroundColor ? `${props.backgroundColor}` : 'white')};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  .boxFile {
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 31px 16px 31px;

    @media (max-width: 991px) {
      align-items: flex-start;
      padding: 16px 16px 30px;
    }
    &__iconStatus {
      margin-right: 12px;
    }
    &__info {
      display: flex;
      @media (max-width: 991px) {
        flex-direction: column;
        margin-top: 5px;
      }
      &-text {
        display: block;
        width: 100%;
        max-width: 650px;
        font-size: 14px;
        @media (max-width: 991px) {
          display: block;
        }
        font-family: 'Ubuntu-Regular';
        strong {
          font-family: 'Ubuntu-Bold';
        }
      }
      &-actions {
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
          position: relative;
          right: 0px;
          margin-top: 20px;
        }
        &-icon {
          cursor: pointer;
          margin-right: 8px;
        }
        &-load {
          pointer-events: none;
          color: #d4d4d4 !important;
        }
        &-text {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: 'Ubuntu-Medium';
          color: #f42534;
          margin-left: 5px;
          cursor: pointer;
          margin-top: 5px;
        }
        &-input {
          display: none;
        }
      }
    }
  }
  .boxAlert {
    margin: 0px 32px 16px 32px;
    @media (max-width: 991px) {
      margin: 0px 16px 16px 16px;
    }
    .errorMessage {
      font-size: 14px;
      font-family: 'Ubuntu-Regular';
    }
  }
  @media (min-width: 768px) {
    &:last-child {
      padding-bottom: 10px;
    }
  }
`;

export default Wrapper;
