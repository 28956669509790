import React from 'react';
import { addClass } from '@coopeuch-components/web';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import InputLabel from '../InputLabel';
import { MessageError, PasswordWrapper } from './styles';
import { colors } from '../../../assets/styles/index';

const Index = ({
  id = 'login-input-password',
  password,
  label = 'Ingresa tu clave de Internet',
  name = 'password',
  errorClass = '',
  showPassword,
  passwordError,
  inputClass,
  onTogglePassword,
  onHandlePasswordInput,
  borderColor = '#646569',
}) => {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  return (
    <PasswordWrapper>
      <div
        className={addClass([
          'login--warpper-input input--password row',
          passwordError && 'input--error',
        ])}
      >
        <p className="text-bold mt-12 mb-12 start-xs">{label}</p>
        <LoadIcon
          src={`${iconPath}lock.svg`}
          size={20}
          color="#1F1E1E"
          className="login--icon-lock"
        />
        <InputLabel
          rounded={4}
          name={name}
          textSize={16}
          borderColor={passwordError ? colors.redErrorInputColor : borderColor}
          onChange={onHandlePasswordInput}
          onPaste={(event) => {
            event.preventDefault();
          }}
          value={password}
          type={showPassword ? 'text' : 'password'}
          className="pl-7"
          id={id}
          inputClass={inputClass}
          placeholder="•••••••"
          iconFunc={onTogglePassword}
          iconRight={
            <>
              <LoadIcon
                src={`${iconPath}eye_open.svg`}
                className={`eye-icon-password ${showPassword ? 'd-flex' : 'd-none'}`}
                size={20}
                color="black"
              />
              <LoadIcon
                src={`${iconPath}eye_close.svg`}
                className={`eye-icon-password ${showPassword ? 'd-none' : 'd-flex'}`}
                size={20}
                color="black"
              />
            </>
          }
          maxLength={passwordError ? null : 8}
          bottomMessage={<span className="text-size-s black">Cantidad de caracteres</span>}
          errorMessage={
            passwordError && (
              <MessageError className={`${errorClass} password-error text-size-s dark-red mt-8`}>
                <div>
                  <LoadIcon
                    src={`${iconPath}circle_close.svg`}
                    size={14}
                    color="#CC284E"
                    className="mr-8 dark-red"
                  />
                  {passwordError}
                </div>
              </MessageError>
            )
          }
        />
      </div>
    </PasswordWrapper>
  );
};

export default Index;
