import React from 'react';
// Design system
import { Input, Message } from '@design-system-coopeuch/web';
// Organism
import FormUniversities from './FormUniversities';
// Utils
import { formatRutSinPuntos, isAlphabetWithSpacesScript } from '../../../../utils/formatters';
// Text
import {STATE_TYPE_USER_TEXT_SON} from '../../../../containers/BonoEscolar/Postulacion/texts';
// Styles
import { FormSon } from './styles';

const ToSon = ({
  isErrorRut,
  nameSon,
  setNameSon,
  rutSon,
  setRutSon,
  setInstitute,
  setCareer,
  dataInstitutes,
  loading,
  formSonVisibility,
  cardDuplicidad,
  mesaggeSonDuplicity,
}) => {
  return (
    <FormSon>
      <Input
        label="Ingresa su nombre y apellido"
        onChange={(name) => setNameSon(name)}
        value={isAlphabetWithSpacesScript(nameSon)}
        allowSpaces
        maxLength={100}
      />
      <Input
        assistText={[
          {
            text: isErrorRut === false && 'Sin puntos y con guión',
            type: isErrorRut === false && 'assist',
          },
          {
            text: isErrorRut === true && 'Ingresa un RUT válido',
            type: isErrorRut === true && 'error',
          },
        ]}
        error={isErrorRut}
        label="Ingresa su Rut"
        onChange={(rut) => setRutSon(formatRutSinPuntos(rut))}
        value={rutSon}
        maxLength={10}
      />
      {formSonVisibility && (
        <FormUniversities {...{ setInstitute, setCareer, dataInstitutes, loading }} />
      )}
      {mesaggeSonDuplicity && (
        <div className="bonoSolicitar__content__alert mt-3">
          <Message
            description={STATE_TYPE_USER_TEXT_SON[cardDuplicidad]?.description}
            type={STATE_TYPE_USER_TEXT_SON[cardDuplicidad]?.typeMessage}
          />
        </div>
      )}
    </FormSon>
  );
};

export default ToSon;
