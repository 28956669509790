/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';

import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';

import ComprobanteTemplate from '../../../components/templates/CuotaParticipacion/Comprobante';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

const Index = ({
  previousCuotasPactadas,
  cuotasPactadas,
  montoPagado,
  fechaActualizacion,
  descargarComprobanteModificacionCuotasParticipacion,
  productoGA,
  persona,
  descargarComprobanteModificacionCuotasParticipacionError
}) => {
  const [baseRut,] = useState(String((persona?.rut || '').replace('-', '')))

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      'page': '/tef/cuota-participacion/modificacion-cuota/paso4',
      'producto': productoGA,
      'cuotas': `${previousCuotasPactadas}`,
      'monto': `${cuotasPactadas}`,
    });
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CP_PANTALLA_EXITO',
      metodo: 'CLAVE_INTERNET',
      informacion_adicional: {
        cuotaAnterior: previousCuotasPactadas,
        cuotaNuevo: cuotasPactadas
      }
    });
  }, []);

  useEffect(() => {
    if (descargarComprobanteModificacionCuotasParticipacionError?.error) {
      const errors = {
        code: descargarComprobanteModificacionCuotasParticipacionError?.statusCode,
        message: descargarComprobanteModificacionCuotasParticipacionError?.message
      }
      logger(errors, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CP_DESCARGAR_COMPROBANTE',
        informacion_adicional: {},
      });
    }
  }, [descargarComprobanteModificacionCuotasParticipacionError])

  const dispatch = useDispatch();

  const handleDownload = async () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso4',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
    dispatch(cuotaParticipacionActions.fetchDescargarComprobanteModificacionCuotasParticipacion());
    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CP_DESCARGAR_COMPROBANTE',
      informacion_adicional: {},
    });
  };

  const onGoBackHome = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuota-participacion/modificacion-cuota/paso4',
      'eve-cat': 'Click',
      'eve-lab': 'Volver al inicio',
    });
    window.location.href = '/#/ecd/inicio';
  }

  return (
    <ComprobanteTemplate
      onGoBackHome={onGoBackHome}
      handleDownload={handleDownload}
      descargarComprobanteModificacionCuotasParticipacion={descargarComprobanteModificacionCuotasParticipacion}
      cuotasPactadas={cuotasPactadas}
      montoPagado={montoPagado}
      fechaActualizacion={fechaActualizacion}
      loading={false}
      persona={persona}
      descargarComprobanteModificacionCuotasParticipacionError={descargarComprobanteModificacionCuotasParticipacionError}
    />
  );
};

Index.propTypes = {
  productoGA: PropTypes.string.isRequired,
  previousCuotasPactadas: PropTypes.string.isRequired,
  cuotasPactadas: PropTypes.string.isRequired,
  montoPagado: PropTypes.string.isRequired,
  fechaActualizacion: PropTypes.string.isRequired,
  descargarComprobanteModificacionCuotasParticipacion: PropTypes.shape({
    success: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  persona: PropTypes.shape({
    rut: PropTypes.string,
  }),
  descargarComprobanteModificacionCuotasParticipacionError: PropTypes.shape({
    error: PropTypes.bool,
    statusCode: PropTypes.number,
    message: PropTypes.string,
  }),
};

const mapStateToProps = (store) => ({
  persona: store.entities.persona,
  cuotas: store.entities.cuotaParticipacion.cuotas,
  descargarComprobanteModificacionCuotasParticipacion: store.entities.cuotaParticipacion.descargarComprobanteModificacionCuotasParticipacion,
  descargarComprobanteModificacionCuotasParticipacionError: store.entities.cuotaParticipacion.descargarComprobanteModificacionCuotasParticipacionError,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchDescargarComprobanteModificacionCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchDescargarComprobanteModificacionCuotasParticipacion());
  },
});
Index.displayName = 'Container_CDP_COM';
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
