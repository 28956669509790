import React from 'react';
import AlertModal from '../../../../../atoms/AlertModal';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import StepActions from '../../../../../organism/Stepper/StepsActions';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { Wrapper, MessageError } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  show,
  nextButton,
  closeAction,
  email,
  emailError,
  loading,
  handleChangeEmail,
}) => {
  return (
    <AlertModal showModal={show} width="424" onClose={closeAction} title="Envio de comprobante">
      <Wrapper className="row bmy-3">
        <div className="col-xs">
          <InputLabel
            title="Email"
            className="bmy-3"
            labelClass="label-email-comprobante"
            rounded={2}
            textSize={16}
            size={48}
            borderColor="#646569"
            onChange={handleChangeEmail}
            name="secondEmail"
            value={email}
            type="text"
            id="comprobante-email-send"
          />
          {emailError ? (
            <MessageError className="text-size-s red ">
              <div className="row">
                <LoadIcon
                  src={`${iconPath}circle_close.svg`}
                  size={14}
                  color="#CC284E"
                  className="mr-8 red"
                />
                <p>{emailError}</p>
              </div>
            </MessageError>
          ) : (
            <span className="text-size-s "></span>
          )}
        </div>
      </Wrapper>
      <div className="row bmt-3">
        <div className="col-xs text-right">
          <StepActions
            hideBackAction={true}
            nextAction={!loading ? nextButton : null}
            loading={loading}
            nextText="Enviar Email"
          />
        </div>
      </div>
    </AlertModal>
  );
};

export default Index;
