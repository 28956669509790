import styled from 'styled-components';

export default styled.div`
  background-color: #22315c;
  border-radius: 5px;
  color: white;
  justify-content: space-between;
  .col-md-1-de-5 {
    @media (min-width: 64rem) {
      box-sizing: border-box;
      flex: 0 0 auto;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      flex-basis: 20%;
      max-width: 20%;
    }
  }
`;
