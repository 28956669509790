/* eslint-disable no-param-reassign */
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/credito';

function fetchCreditosSuccess(state, action) {
  state.creditos = {
    success: true,
    error: false,
    data: action.payload.data,
    errorCode: '',
    date: new Date().toISOString(),
  };
}

function fetchCreditosError(state, action) {
  state.creditos = {
    success: false,
    error: true,
    data: [],
    errorCode: action.payload.error,
    date: new Date().toISOString(),
  };
}

function fetchPagoCreditoSuccess(state, action) {
  state.pago = {
    success: true,
    error: false,
    errorCode: '',
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchPagoCreditoError(state, action) {
  state.pago = {
    success: false,
    error: true,
    errorCode: action.payload.error,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchDetallePagoCreditoSuccess(state, action) {
  state.detallePago = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchDetallePagoCreditoError(state) {
  state.detallePago = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}

function fetchCuentasVistaSuccess(state, action) {
  state.cuentas = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchCuentasVistaError(state) {
  state.cuentas = { success: false, error: true, data: [], date: new Date().toISOString() };
}

function fetchCConsumoHistorialPagadoSuccess(state, action) {
  state.historialPagado = {
    success: true,
    error: false,
    data: action.payload.data,
    date: new Date().toISOString(),
  };
}

function fetchCConsumoHistorialPagadoError(state) {
  state.historialPagado = {
    success: false,
    error: true,
    data: null,
    date: new Date().toISOString(),
  };
}
function resetDataPDFCConsumoHistorialPagado(state) {
  state.pdfHistorialPagado = { success: false, error: false, data: null };
}

function fetchReprogramacionCuotaSuccess(state, action) {
  state.reprogramacionCuota = {
    success: true,
    error: false,
    data: { existenReprogramacionesValidas: action.payload.data.existenReprogramacionesValidas },
    errorCode: '',
  };
}

function fetchReprogramacionCuotaError(state, action) {
  state.reprogramacionCuota = {
    success: false,
    error: true,
    data: { existenReprogramacionesValidas: false },
    errorCode: action.payload.error,
  };
}

// function fetchPDFCConsumoHistorialPagadoSuccess(state, action) {
//   state.pdfHistorialPagado = { success: true, error: false, data: action.payload.data };
// }

// function fetchPDFCConsumoHistorialPagadoError(state) {
//   state.pdfHistorialPagado = { success: false, error: true, data: null };
// }

const fetchCreditos = createAction('fetchCreditos');
const fetchCuentasVistaCreditoDeConsumo = createAction('fetchCuentasVistaCreditoDeConsumo');
const fetchPagoCredito = createAction('fetchPagoCredito');
const fetchDetallePagoCredito = createAction('fetchDetallePagoCredito');
const fetchCConsumoHistorialPagado = createAction('fetchCConsumoHistorialPagado');
const fetchPDFCConsumoHistorialPagado = createAction('fetchPDFCConsumoHistorialPagado');
const fetchReprogramacionCuota = createAction('fetchReprogramacionCuota');

const creditoReducer = createSlice({
  name: 'credito',
  initialState,
  reducers: {
    fetchCreditosSuccess,
    fetchCreditosError,
    fetchCuentasVistaSuccess,
    fetchCuentasVistaError,
    fetchPagoCreditoSuccess,
    fetchPagoCreditoError,
    fetchDetallePagoCreditoSuccess,
    fetchDetallePagoCreditoError,
    fetchCConsumoHistorialPagadoSuccess,
    fetchCConsumoHistorialPagadoError,
    // fetchPDFCConsumoHistorialPagadoSuccess,
    // fetchPDFCConsumoHistorialPagadoError,
    resetDataPDFCConsumoHistorialPagado,
    fetchReprogramacionCuotaSuccess,
    fetchReprogramacionCuotaError,
  },
});

export const creditoActions = {
  ...creditoReducer.actions,
  fetchCuentasVistaCreditoDeConsumo,
  fetchCreditos,
  fetchPagoCredito,
  fetchDetallePagoCredito,
  fetchCConsumoHistorialPagado,
  fetchPDFCConsumoHistorialPagado,
  fetchReprogramacionCuota,
};

export default creditoReducer.reducer;
