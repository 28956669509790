import styled from 'styled-components';

export const Banner = styled.div`
  height: ${({ height }) => (height ? `${height}px` : '200px')};
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  .banner-actions {
    display: flex;
    align-items: center;
  }
  ${({ rounded }) => !!rounded && `border-radius: 5px;`};
  /* border-radius: 5px; */
`;

export const CustomList = styled.ul`
  & > li {
    position: relative;
    margin-left: 16px;

    &::before {
      background-color: #f42534;
      border-radius: 100%;
      position: absolute;
      content: '';
      height: 6px;
      width: 6px;
      display: inline-block;
      left: -16px;
      top: 6px;
    }
  }
`;
