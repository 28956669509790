import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import RutYSerieTemplate from '../../../components/templates/RecuperarOCrearClave/RutYSerie';

import { formatRutSinPuntos, rutUnformat, serieFormat } from '../../../utils/formatters';
import { isRunValid } from '../../../utils/validators';
import { registerGA } from '../../../utils/metrics';
import getOS from '../../../utils/getOS';

const RutYSerie = (props) => {
  const {
    onStepsHandle,
    onRutHandle,
    fetchValidarRut,
    resetErrorValidarRut,
    type,
    trackId,
    isLoadingValidarOCrear,
    errorValidarRut,
  } = props;
  const location = useLocation();

  const [rut, setRut] = useState();
  const [serie, setSerie] = useState();

  const [rutError, setRutError] = useState(false);
  const [serieError, setSerieError] = useState(false);

  const [onceClic, setOnceClic] = useState(false);

  // Solo existen 2 estados, el crear-clave y el recuperar, asi que esta es una manera okay de identificarlos.
  const [isLocationCrearClave, setIsLocationCrearClave] = useState(
    location?.pathname.includes('crear-clave')
  );

  function handleClickRetry() {
    registerGA({
      event: 'trigger_event',
      'event-config': {
        'eve-acc': '/crea-rec-clave/paso1',
        'eve-cat': 'Click',
        'eve-lab': 'Cedula sin validar - Reintentar',
      },
    });

    setOnceClic(false);
    resetErrorValidarRut('');
  }

  function handleClickSerSocio() {
    window.open('https://www.coopeuch.cl/personas/hazte-socio-digital.html', '_blank').focus();
  }

  function onChangeHandle(event) {
    const { dataset, value } = event.target;

    if (dataset?.id === 'rut') {
      onRutHandle(formatRutSinPuntos(value));
      setRut(formatRutSinPuntos(value));
      setRutError(value?.length <= 7 ? true : !isRunValid(value));
    } else {
      setSerieError(value?.length === 0);
      setSerie(serieFormat(value));
    }
  }

  function onSubmitHandle(captcha) {
    const searchParams = new URLSearchParams(location.search);
    const origen = searchParams.get('origen');
    fetchValidarRut({
      canal: 'WEB',
      rut: rutUnformat(rut),
      serie,
      captcha,
      recuperar: type === 'recuperar',
      ...getOS(),
      origen: origen || '',
    });
    registerGA({
      event: 'trigger_event',
      'event-config': {
        'eve-acc': '/crea-rec-clave/paso1',
        'eve-cat': 'Click',
        'eve-lab': 'Continuar',
      },
    });

    window.grecaptcha && window.grecaptcha.reset();
  }

  function onFocusTooltip() {
    registerGA({
      event: 'trigger_event',
      'event-config': {
        'eve-acc': '/crea-rec-clave/paso1',
        'eve-cat': 'click',
        'eve-lab': 'tooltip',
      },
    });
  }

  useEffect(() => {
    if (trackId) {
      onStepsHandle(2);
    }
  }, [trackId]);

  useEffect(() => {
    registerGA({
      event: 'vpv',
      page: '/crea-rec-clave/paso1',
      producto: type === 'recuperar' ? 'Olvidaste tu clave' : 'Crear tu clave',
    });
  }, []);

  useEffect(() => {
    if (Boolean(onceClic)) {
      window.onSubmitValidarRut = onSubmitHandle;
    }
  }, [onceClic]);

  // Efecto de hacerle focus al input de documento de serie cuando vuelves de la pantalla de error atraves del reintentar.
  useEffect(() => {
    const element = document.getElementById('serie-crear-clave');

    if (element && rut?.length > 0) {
      element.focus();
      setSerie('');
    }
  }, [errorValidarRut]);

  return (
    <RutYSerieTemplate
      onChangeHandle={onChangeHandle}
      onFocusTooltip={onFocusTooltip}
      isLoadingValidarOCrear={isLoadingValidarOCrear}
      errorValidarRut={errorValidarRut}
      rut={rut}
      serie={serie}
      rutError={rutError}
      serieError={serieError}
      onceClic={onceClic}
      setOnceClic={setOnceClic}
      isLocationCrearClave={isLocationCrearClave}
      handleClickRetry={handleClickRetry}
      handleClickSerSocio={handleClickSerSocio}
    />
  );
};

RutYSerie.propTypes = {
  onStepsHandle: PropTypes.func,
  onRutHandle: PropTypes.func,
  handleClickRetry: PropTypes.func,
  fetchValidarRut: PropTypes.func,
  trackId: PropTypes.string,
  isLoadingValidarOCrear: PropTypes.bool,
};

RutYSerie.defaultProps = {
  onStepsHandle: null,
  onRutHandle: null,
  handleClickRetry: null,
  fetchValidarRut: null,
  trackId: '',
  isLoadingValidarOCrear: false,
  errorValidarRut: '',
};

export default RutYSerie;
