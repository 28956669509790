const initState = {
  contratacionMonedero: {
    error: false,
    errorCode: '',
    success: false,
    cuenta: '',
    fecha: '',
    email: '',
  },
  comprobanteContratacion: null,
  contratoCanales: null,
  contratoMonedero: null,
  errorLogContratoMonedero: '',
};

export default initState;
