import React from 'react';

// Ui
import Skeleton from 'react-loading-skeleton';
// import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import DashboardCard from '../../../organism/DashboardCard';
import CheckBox from '../../../atoms/CheckBox';

// Styles
// import { AlertWrapper, MessageError } from './styles';

// const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DatosDeContacto = ({ loading, termsAndCondicions, onChangeCheckBox }) => {
  return (
    <>
    <div style={{
          color: '#383638',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: 0,
          height: '70px',
          backgroundColor: 'transparent',
        }}>
          <h5 style={{
              fontSize: 20,
              color: '#383638',
              margin: 0,
              padding: 0,
              alignSelf: 'center',
              fontWeight: 'bold'
          }}>Términos y condiciones
          </h5>
        </div>
        <div className="row">
          <div className="col-md-12 mb-32 col-sm-12 w-100">
            <div className="ml-4 mr-4">
              {loading ? (
                <>
                  <div className="bmb-2 w-25">
                    <Skeleton height={10} />
                  </div>
                  <div className="mb-14 w-100">
                    <Skeleton height={50} />
                  </div>
                </>
              ) : (
                <>
                  <CheckBox
                    name="seguroDesgravamen"
                    checked={termsAndCondicions}
                    label="Autorizo a Coopeuch a enviarme información comercial, de marketing o promocional sobre sus productos y servicios que puedan ser de mi interés, en mi calidad de cliente."
                    id="checkbox-seguroDesgravamen"
                    onChange={()=> onChangeCheckBox()}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        </>
  );
};

DatosDeContacto.displayName = 'DatosDeContacto_Template';
export default DatosDeContacto;
