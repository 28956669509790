import React from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import Skeleton from 'react-loading-skeleton';

function SkeletonSimulacion() {
  return (
    <div className="row">
      <div className="col-xs-12">
        <DashboardCard classes="pt-28 pb-28 pl-32 pr-32">
          <div className="row start-xs">
            <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
              <Skeleton height={10} width={160} />
            </div>
            <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
              <Skeleton height={10} width={160} />
            </div>
          </div>
          <div className="pt-50 pb-18">
            <div className="row start-xs">
              <div className="col-xs-12">
                <Skeleton height={10} width={'100%'} />
              </div>
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4">
              <Skeleton height={10} width={250} />
            </div>
          </div>
          <div className="row start-xs">
            <div className="col-xs-12 col-md-6 col-sm-12 p-0">
              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <div className="mb-16 mt-36">
                  <Skeleton height={10} width={160} />
                </div>
                <Skeleton height={50} width={300} />
                <div className="mt-16">
                  <Skeleton height={10} width={160} />
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-md-6 col-sm-12 p-0">
              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <div className="mb-16">
                  <Skeleton height={10} width={160} />
                </div>
                <Skeleton height={50} width={300} />
                <div className="mt-16">
                  <Skeleton height={10} width={160} />
                </div>
              </div>
            </div>
          </div>
        </DashboardCard>
      </div>
    </div>
  );
}

export default SkeletonSimulacion;
