import React from 'react';
import AlertModal from '../../../../atoms/AlertModal';
import { Text } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const Index = ({ show, closeAction }) => {
  return (
    <AlertModal showModal={show} width="424" onClose={closeAction}>
      <div className="center-xs">
        <div className="row center-xs">
          <img src={`${staticPath}login_error.png`} alt="error" />
        </div>
        <Text className="bmb-2" textSize={16} size={24} weight="bold">
          ¡Lo sentimos!
        </Text>
        <Text className="bmb-4" textSize={14} size={20} weight="normal">
          En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.
        </Text>
      </div>
    </AlertModal>
  );
};

export default Index;
