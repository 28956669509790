import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { bonoEscolarActions } from '../../../../application/actions/bonoEscolar';
// Templates
import ConfirmacionTemplate from '../../../components/templates/BonoEscolar/Confirmacion';
// Metrics
import { registerGA } from '../../../utils/metrics';
// Utils
import { isEmailValid } from '../../../utils/validators';

const Confirmacion = ({ steps, currentStep, setCurrentStep }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const persona = useSelector((store) => store?.entities?.persona);
  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);

  const [phoneInput, setPhoneInput] = useState(persona.telefono);
  const [emailInput, setEmailInput] = useState(persona.correo);
  const [checked, setChecked] = useState(false);
  const [active, setActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [validateErrorPhone, setValidateErrorPhone] = useState(false);
  const validateErrorEmail = isEmailValid(emailInput);

  const handleEmail = (e) => {
    setEmailInput(e);
  };

  const handleCheck = () => {
    setChecked(!checked);
  };

  const handleClickContinue = () => {
    setCurrentStep(currentStep + 1);
    const params = {
      phone: phoneInput,
      email: emailInput,
    };
    dispatch(bonoEscolarActions.setStatesConfirmacion(params));
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${
        dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/paso1`,
      'eve-cat': 'Click',
      'eve-lab': 'Confirmar y continuar',
    });
    history.push('/ecd/bono-escolar/postulacion');
  };

  const handleClickBack = () => {
    registerGA({
      event: 'eve',
      'eve-acc': `/beneficios/${
        dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/paso1`,
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    history.push('/ecd/bono-escolar/');
  };

  // Validate routes
  useEffect(() => {
    currentStep < 1 && history.push('/ecd/bono-escolar/');
    registerGA({
      event: 'vpv-privado',
      page: `/beneficios/${
        dataBono.typeBono === 'Bono de Ingreso' ? 'bono-ingreso' : 'bono-titulacion'
      }/paso1`,
    });
  }, []);

  // Valid phone
  useEffect(() => {
    phoneInput.length <= 8 ? setValidateErrorPhone(true) : setValidateErrorPhone(false);
  }, [phoneInput]);

  useEffect(() => {
    if (phoneInput.length < 9 || !isEmailValid(emailInput)) {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [handleEmail, phoneInput]);

  return (
    <ConfirmacionTemplate
      {...{
        steps,
        active,
        checked,
        phoneInput,
        emailInput,
        handleCheck,
        handleEmail,
        setPhoneInput,
        validateErrorPhone,
        validateErrorEmail,
        isOpen,
        setIsOpen,
        handleClickContinue,
        handleClickBack,
      }}
    />
  );
};

export default Confirmacion;
