import styled from 'styled-components';

const Wrapper = styled.div`
  .contentSMS {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &__title {
      font-size: 18px;
      font-family: 'Ubuntu-Bold';
    }
    &__text {
      width: 100%;
      max-width: 470px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      font-family: 'Ubuntu-Regular';
      margin-top: 11px;
      margin-bottom: 16px;
    }
    &__message {
      margin-bottom: 20px;
    }
    &__strong {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 500px;
      @media (max-width: 500px) {
        justify-content: space-between;
        max-width: 256px;
      }
      &-text {
        margin-bottom: 16px;
        font-size: 16px;
        font-family: 'Ubuntu-Bold';
        @media (max-width: 500px) {
          justify-content: space-between;
          max-width: 256px;
        }
      }
    }
    &__form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 297px;
      margin-bottom: 24px;
      @media (max-width: 500px) {
        justify-content: space-between;
        max-width: 256px;
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &-input {
        height: 48px;
        width: 36px;
        border: 1px solid #646569;
        border-radius: 4px;
        background-color: #ffffff;
        text-align: center;
        margin-left: 8px;
        margin-right: 8px;
        font-size: 14px;
        @media (max-width: 500px) {
          margin-left: 0px;
          margin-right: 0px;
        }
        &::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        [type='number'] {
          -moz-appearance: textfield;
        }
      }
      &-input.errorSMS {
        border: 1px solid #e81e2b;
      }
    }
    &__controls {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 297px;
      &-btn {
        width: 100%;
        margin-bottom: 20px;
        font-size: 14px;
        font-family: 'Ubuntu-Medium';
      }
    }
  }
`;

export default Wrapper;
