import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

// UI
import Button from '@coopeuch-components/web/atoms/Button';
import ButtonWithSpinner from '../../atoms/ButtonWithSpinner';
import InputLabel from '../../atoms/InputLabel';
import PasswordInput from '../../atoms/PasswordInput';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import Link from '@coopeuch-components/web/atoms/Link';
import { Link as InternalLink } from 'react-router-dom';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import {
  Wrapper,
  LeftCol,
  RightCol,
  TextCol,
  LoginCard,
  ButtonScreen,
  Logo,
  Alert,
  PasswordProblemWrapper,
} from './styles';
import { colors } from '../../../assets/styles';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const LoginTemplate = ({
  onChangeHandle,
  onRememberHandle,
  onToggleHandle,
  onClickErrorLink,
  remember,
  rut,
  password,
  error,
  serviceError,
  show,
  disableSubmit,
  loading,
  autoLogout,
  recaptchaPath,
}) => {
  const history = useHistory();

  const goRecuperarOCrearClave = useCallback((type) => {
    history.push({
      pathname: '/' + type,
      state: { from: `${window.location.hash == '#/' ? 'login' : window.location.hash}`, type },
    });
  }, []);

  const redirectNewButton = (serviceError) => {
    window.location.href = serviceError.link.url;
  };
  return (
    <Wrapper className="row">
      <LeftCol className="col-xs-12 col-md-5 bpy-3">
        <div className="row h-100">
          <LoginCard
            color="#f42534"
            className="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 bmt-auto bmb-auto black"
          >
            <div className="row bmb-5">
              <div className="col-xs-12 text-left public-site-link">
                <Link
                  href="https://www.coopeuch.cl/"
                  color="#f42534"
                  className="text-regular-medium"
                  target="_self"
                  underline={false}
                >
                  <>
                    <div className="float-left">
                      <LoadIcon
                        color="E81E2B"
                        src={`${iconPath}arrow_left.svg`}
                        size={22}
                        className="mr-8"
                      />
                    </div>
                    Coopeuch en línea
                  </>
                </Link>
              </div>
            </div>
            <div className="row bmb-2">
              <div className="col-xs-12 text-center login-title">
                <Logo src={`${staticPath}logo_red.png`} alt="logo-coopeuch" className="bmb-3" />
                <p className="text-size-xl center mt-24 mb-24">Ingresa a Coopeuch en línea</p>
                {((serviceError.id &&
                  serviceError.message.length >= 1 &&
                  !Boolean(serviceError.title)) ||
                  !!autoLogout.message.length) && (
                  <div className="row center-xs">
                    <div className="col-xs-12">
                      <Alert>
                        <div className="login--content-alert w-100">
                          <LoadIcon
                            src={`${iconPath}circle_close.svg`}
                            size={25}
                            className="mr-8"
                          />
                          <div>
                            <p>{serviceError.message || autoLogout.message}</p>
                            {Object.keys(serviceError.link).length >= 1 && (
                              <InternalLink
                                to={serviceError.link.url}
                                style={{ color: '#CC284E' }}
                                className="text-regular-medium mt-8"
                                onClick={() => {
                                  onClickErrorLink(serviceError.id);
                                }}
                              >
                                {serviceError.link.name}
                              </InternalLink>
                            )}
                          </div>
                        </div>
                      </Alert>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {Boolean(serviceError.title) ? (
              <div className="row">
                <div className="col-xs-12 center-xs">
                  <div className="row w-100 center-xs">
                    <img src={`${iconPath}ilustracion_info.svg`} alt="login-error" />
                  </div>
                  <p className="text-bold mt-12 mb-24 center-xs">{serviceError.title}</p>
                  <p className="mt-24 mb-12 pt-20 center-xs text-size-s">{serviceError.message}</p>
                  <div className="newButton">
                    {serviceError.link.url !== undefined && (
                      <ButtonWithSpinner
                        width={'40'}
                        bgColor="#f42534"
                        onClick={() => redirectNewButton(serviceError)}
                        className="g-recaptcha w-100"
                      >
                        {serviceError.link.name}
                      </ButtonWithSpinner>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row login-input-fields">
                <div className="col-xs-12 bmb-3">
                  <InputLabel
                    errorMessage={
                      error.rut ? (
                        <div className="row">
                          <div className="col-xs-12 d-flex align-items-center">
                            <LoadIcon
                              src={`${iconPath}circle_close.svg`}
                              size={14}
                              className="mr-8"
                            />
                            <p>{error.rut}</p>
                          </div>
                        </div>
                      ) : null
                    }
                    errorClass="text-size-s err-red bmt-1"
                    rounded={2}
                    onChange={onChangeHandle}
                    value={rut}
                    inputClass={`pb-0 pt-0 input--login ${error.rut ? 'input--login--error' : ''}`}
                    name="rut"
                    placeholder="Ej: 12345678-k"
                    labelClass="bmy-3 text-bold"
                    type="text"
                    id="login-input-rut"
                    size={48}
                    title="Ingresa tu RUT"
                  />
                </div>
                <div className="col-xs-12 bmb-2">
                  <PasswordInput
                    {...{
                      id: 'login-input-password',
                      label: 'Ingresa tu clave de Internet',
                      password,
                      errorClass: 'text-size-s err-red bmt-1',
                      inputClass: `pb-0 pt-0 ${error.password ? 'input--login--error' : ''}`,
                      showPassword: show,
                      passwordError: error.password,
                      onTogglePassword: onToggleHandle,
                      onHandlePasswordInput: onChangeHandle,
                    }}
                  />
                </div>
                <div className="col-xs-12 checkbox-remember-data bmb-3">
                  <CheckBox
                    text="Recordar tu RUT"
                    borderColor={remember ? '#007DB7' : '#646569'}
                    id="login-input-remember"
                    fontSize={16}
                    isChecked={remember}
                    onChange={onRememberHandle}
                  />
                </div>
                <div className="col-xs-12 bmb-4 submit-button-wrapper">
                  {disableSubmit && <ButtonScreen />}
                  <ButtonWithSpinner
                    fullWidth
                    bgColor="#f42534"
                    disabled={disableSubmit}
                    onClick={() => {}}
                    className="g-recaptcha w-100"
                    data-sitekey={recaptchaPath}
                    data-callback="onSubmitHandle"
                    data-size="invisible"
                    id="login-submit"
                    loading={loading}
                  >
                    Ingresar
                  </ButtonWithSpinner>
                </div>
                <PasswordProblemWrapper className="col-xs-12 text-center">
                  <div className="col center-xs middle-xs">
                    <div>
                      <Button
                        color="red"
                        className="text-size-m underline middle-items w-100"
                        asTextLink
                        onClick={() => goRecuperarOCrearClave('recuperar-clave')}
                      >
                        <span>¿Olvidaste tu clave?</span>
                      </Button>
                    </div>
                    <div>
                      <p>
                        {' '}
                        <span className="text-regular-medium text-size-m bmx-2">
                          ¿Eres nuevo?{' '}
                        </span>{' '}
                        <a
                          color="#f42534"
                          className="text-size-m crear-clave-link underline middle-items w-100"
                          asTextLink
                          onClick={() => goRecuperarOCrearClave('crear-clave')}
                        >
                          {' '}
                          Crea tu clave{' '}
                        </a>{' '}
                      </p>
                    </div>
                  </div>
                </PasswordProblemWrapper>
              </div>
            )}
          </LoginCard>
        </div>
      </LeftCol>
      <RightCol className="col-md-7 d-none d-md-block" src={`${staticPath}img_login2.jpg`}>
        <div className="row h-100">
          <TextCol className="col-md-6 col-lg-5 col-md-offset-5 bp-4 advices bmt-auto bmb-auto">
            <table>
              <tbody>
                <tr>
                  <td className="bpr-3">
                    <LoadIcon
                      size={32}
                      src={`${iconPath}check.svg`}
                      className="icon-list-item"
                      color="white"
                    />
                  </td>
                  <td className="advices-title">Consejos de Seguridad</td>
                </tr>
                <tr className="table-spacer"></tr>
                <tr>
                  <td className="bpr-3">
                    <LoadIcon
                      size={20}
                      src={`${iconPath}desktop_alt.svg`}
                      className="icon-list-item"
                      color="white"
                    />
                  </td>
                  <td className="">
                    Al realizar tus transacciones, usa siempre tu computador personal.
                  </td>
                </tr>
                <tr className="table-spacer"></tr>
                <tr>
                  <td className="bpr-3">
                    <LoadIcon
                      size={20}
                      src={`${iconPath}dashboard.svg`}
                      className="icon-list-item"
                      color="white"
                    />
                  </td>
                  <td className="">
                    Escribe siempre manualmente la dirección www.coopeuch.cl en el navegador
                  </td>
                </tr>
                <tr className="table-spacer"></tr>
                <tr>
                  <td className="bpr-3">
                    <LoadIcon
                      size={20}
                      src={`${iconPath}unlock.svg`}
                      className="icon-list-item"
                      color="white"
                    />
                  </td>
                  <td className="">
                    Verifica siempre la seguridad de tus datos con el sello verde https://
                  </td>
                </tr>
                <tr className="table-spacer"></tr>
                <tr>
                  <td className="bpr-3">
                    <LoadIcon
                      size={20}
                      src={`${iconPath}logout.svg`}
                      className="icon-list-item"
                      color="white"
                    />
                  </td>
                  <td className="">
                    Recuerda cerrar siempre tu sesión cuando termines la actividad en el sitio web
                    de Coopeuch.
                  </td>
                </tr>
                <tr className="table-spacer"></tr>
                <tr>
                  <td className="bpr-3">
                    <LoadIcon
                      size={20}
                      src={`${iconPath}password.svg`}
                      className="icon-list-item"
                      color="white"
                    />
                  </td>
                  <td className="">Nunca compartas tus claves personales con terceros.</td>
                </tr>
              </tbody>
            </table>
          </TextCol>
        </div>
      </RightCol>
    </Wrapper>
  );
};

LoginTemplate.propTypes = {
  onChangeHandle: PropTypes.func,
  onRememberHandle: PropTypes.func,
  onToggleHandle: PropTypes.func,
  remember: PropTypes.bool,
  rut: PropTypes.string,
  password: PropTypes.string,
  serviceError: PropTypes.object,
  error: PropTypes.object,
  show: PropTypes.bool,
};

LoginTemplate.defaultProps = {
  onChangeHandle: null,
  onRememberHandle: null,
  onToggleHandle: null,
  remember: false,
  rut: '',
  password: '',
  serviceError: {},
  error: {},
  show: false,
};

export default LoginTemplate;
