import React, { useMemo } from 'react';

import PageTitle from '../../../atoms/PageTitle';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Stepper from '../../../../components/organism/Stepper';
import Alert from '@coopeuch-components/web/atoms/Alert';
import DashboardCard from '../../../organism/DashboardCard';
import Button from '@coopeuch-components/web/atoms/Button';
import ErrorCard from '../../../organism/ErrorCard';
import { agregarformatoPesos, agregarFormatoDecimal } from '../../../../utils/formatters';
import { patternDateFormat } from '../../../../utils/dates';
import { capitalize } from '../../../../utils/functions';

import Wrapper, { TitleWrapper } from './styles';

import { SkeletonComprobante } from './skeleton';

import { comprobanteTexts, stepperTexts } from '../../../../containers/DepositoAPlazo/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ComprobanteTemplate = ({
  isLoadingDAPComprobanteInvertir,
  handleContinue,
  comprobanteInvertirError,
  comprobanteInvertir,
  simulacion,
}) => {
  const steps = useMemo(() => {
    return [
      stepperTexts.STEPPER_PASO_1,
      stepperTexts.STEPPER_PASO_2,
      stepperTexts.STEPPER_PASO_3,
      stepperTexts.STEPPER_PASO_4,
      stepperTexts.STEPPER_PASO_5,
    ];
  }, []);

  return (
    <React.Fragment>
      <TitleWrapper>
        <PageTitle
          title={comprobanteTexts.PAGE_TITLE}
          icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" className="simulador-icon" />}
        />
      </TitleWrapper>

      <Wrapper className="margin-after-title">
        {comprobanteInvertirError ? (
          <ErrorCard />
        ) : (
          <div>
            <Stepper className="mb-24 start-xs" position={5} steps={steps} />
            {isLoadingDAPComprobanteInvertir ? (
              <SkeletonComprobante />
            ) : (
              <div>
                <div className="bmt-3 bmt-md-0">
                  <Alert show bgType="success" className="icon-alert">
                    {comprobanteTexts.TOAST_SUCCESS}
                  </Alert>
                </div>
                <div className="bmt-3 bmt-md-4">
                  <DashboardCard classes="dashboard-card-shadow">
                    <DashboardCard.Head
                      title="Comprobante"
                      borderBottom="complete"
                      classes="comprobante-title-card-head"
                    />
                    <DashboardCard.Body classes="comprobante-simulacion-card-body">
                      <div className="row m-0">
                        <div className="col-md-8 col-xs-12 p-0">
                          <div className="bmb-2">
                            <label className="comprobante-desc-plazo">
                              {comprobanteTexts.DEPOSIT_CONTRATADO}
                              {comprobanteInvertir?.fechaSolicitud}
                            </label>
                          </div>
                          <div className="bmb-2">
                            <label className="comprobante-desc-numero">
                              Nº {comprobanteInvertir?.numeroDeposito}
                            </label>
                          </div>
                          <div>
                            <label className="comprobante-desc-mail">
                              {comprobanteTexts.DESC_MAIL}
                              <b>{comprobanteInvertir?.correoElectronico}</b>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-4 col-xs-12 p-0 bmt-3 bmt-md-0 icon-sello-container">
                          <LoadIcon src={`${iconPath}icon_sello.svg`} />
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>
                <div className="bmt-3 bmt-md-4">
                  <DashboardCard classes="dashboard-card-shadow">
                    <DashboardCard.Head
                      title="Detalles de inversión"
                      borderBottom="complete"
                      classes="comprobante-title-card-head"
                    />
                    <DashboardCard.Body classes="comprobante-simulacion-card-body">
                      <div className="comprobante-card-header row m-0">
                        <div className="col-md-6 col-xs-6 p-0">
                          <div>
                            <label className="comprobante-card-header-title">
                              {comprobanteTexts.MONTO_INVERTIDO}
                            </label>
                          </div>
                          <div>
                            <label className="comprobante-card-header-value">
                              {agregarformatoPesos(comprobanteInvertir?.montoInvertido)}
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 col-xs-6 p-0">
                          <div className="display-desktop">
                            <label className="comprobante-card-header-title">
                              {comprobanteTexts.MONTO_VENCIMIENTO}
                            </label>
                          </div>
                          <div className="display-mobile">
                            <label className="comprobante-card-header-title">
                              {comprobanteTexts.MONTO_VENCIMIENTO_MOBILE}
                            </label>
                          </div>
                          <div>
                            <label className="comprobante-card-header-value">
                              {agregarformatoPesos(comprobanteInvertir?.montoVencimiento)}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="bmt-md-4 bmt-3">
                        <div className="row m-0 comprobante-card-list card-impar">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.CUENTA_ORIGEN}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteInvertir?.tipoCuentaOrigen}
                              {' Nº '}
                              {comprobanteInvertir?.numeroCuentaVistaOrigen}
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-par">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.CUENTA_DESTINO}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteInvertir?.tipoCuentaDestino}
                              {' Nº '}
                              {comprobanteInvertir?.numeroCuentaVistaDestino}
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-impar">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.GANANCIA}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {agregarformatoPesos(comprobanteInvertir?.montoGanancia)}
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-par">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.FECHA_INICIO}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteInvertir?.fechaInicio}
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-impar">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.FECHA_VENCIMIENTO}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteInvertir?.fechaVencimiento}
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-par">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.TIPO_DEPOSITO}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {capitalize(simulacion?.tipo)}
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-impar">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.TASA_BASE}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {agregarFormatoDecimal(comprobanteInvertir?.tasaBase)}%
                            </label>
                          </div>
                        </div>
                        <div className="row m-0 comprobante-card-list card-par">
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {comprobanteTexts.TASA_PERIODO}
                            </label>
                          </div>
                          <div className="col-md-6 col-xs-12 p-0">
                            <label className="comprobante-card-text">
                              {agregarFormatoDecimal(comprobanteInvertir?.tasaPeriodo)}%
                            </label>
                          </div>
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>
                <div className="bmt-md-4 bmt-3">
                  <div className="col-md-12 col-xs-12 btext-right p-0">
                    <Button
                      bgColor="#E81E2B"
                      color="white"
                      onClick={handleContinue}
                      className="boton-deposito-plazo"
                    >
                      {comprobanteTexts.BOTON_RETURN}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Wrapper>
    </React.Fragment>
  );
};

ComprobanteTemplate.propTypes = {};
ComprobanteTemplate.defaultProps = {};

export default ComprobanteTemplate;
