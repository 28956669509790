import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';

import { transferenciasActions } from '../../../application/actions/transferencias';
import { factoresSeguridadActions } from '../../../application/actions/factoresSeguridad';
import { loadersActions } from '../../../application/actions/ui_loaders';

import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';

import api from '../../api';
import { logReqError } from '../../../ui/utils/functions';

import defaultRequest, { errEditarDestinatario } from './errors';
import { registerGA } from '../../../ui/utils/metrics';
import { listarDestinatariosTexts } from '../../../ui/components/templates/Destinatarios/ListarDestinatarios/texts';

const apiDestinatario = api(process.env.REACT_APP_DESTINATARIO);
const apiBancos = api(process.env.REACT_APP_BANCOS);
const apiTipoCuentas = api(process.env.REACT_APP_TIPO_CUENTA);
const apiTerceros = api(process.env.REACT_APP_TERCEROS);
const apiTercerosComprobante = api(process.env.REACT_APP_TRANSFERENCIA_COMPROBANTE);
const apiConfiguracion = api(process.env.REACT_APP_CONFIGURATION_API);

export function* ftuFuncionalidad(action) {
  try {
    const { ftuID } = action.payload;
    const { data, error } = yield call(apiConfiguracion.get,`ftufuncionalidad/v1/consultar/${ftuID}`);
    if (data) {
      yield put(
        transferenciasActions.ftuFuncionalidadSuccess(data),
      );
    } else {
      yield put(
        transferenciasActions.ftuFuncionalidadFailed(error),
      );
    }
  } catch (err) {
    yield put(transferenciasActions.ftuFuncionalidadFailed(err));
  }
}
export function* ftuClose(action) {
  try {
    const { ftuId } = action.payload;
    console.log('payloadFTU: ', action.payload)
    console.log('payloadFTU: ftuId', ftuId)
    const { data, error } = yield call(apiConfiguracion.post,`ftufuncionalidad/v1/crear/${ftuId}`);
    if (data) {
      yield put(
        transferenciasActions.ftuCloseSuccess(data),
      );
    } else {
      yield put(
        transferenciasActions.ftuCloseFailed(error),
      );
    }
  } catch (err) {
    yield put(transferenciasActions.ftuCloseFailed(err));
  }
}
export function* fetchUpdateFavoriteDestinatario(action) {
  try {
    const { payload } = action.payload;
    const { id, favorite } = payload;
    const { data, error } = yield call(apiDestinatario.put,`v1/actualizarfavorito/${id}`,
      {
        esFavorito: favorite,
      },
    );
    if (data) {
      yield put(
        transferenciasActions.fetchUpdateFavoriteDestinatarioSuccess(data),
      );
    } else {
      yield put(
        transferenciasActions.fetchUpdateFavoriteDestinatarioFailed(error),
      );
    }
  } catch (err) {
    yield put(transferenciasActions.fetchUpdateFavoriteDestinatarioFailed(err));
  }
}

export function* fetchDestinatarios(action) {
  try {
    const { params } = action.payload;

    yield put(loadersActions.changeStatusLoaderDestinatarios({ isLoader: true }));

    const { data, error } = yield call(apiDestinatario.post, 'v1/obtener', params);
    const responseParams = data
      ? {
          destinatarios: data,
          errorDestinatarios: false,
          errorDestinatariosLog: null,
        }
      : {
          destinatarios: [],
          errorDestinatarios: true,
          errorDestinatariosLog: error,
        };

    if (!data) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/transferencias/destinatarios',
        'eve-cat': 'Error',
        'eve-lab': listarDestinatariosTexts.ERROR_DESTINATARIO_DESC,
      });
    }

    yield all([
      put(transferenciasActions.fetchDestinatariosSuccess(responseParams)),
      put(loadersActions.changeStatusLoaderDestinatarios({ isLoader: false })),
    ]);
  } catch (err) {
    yield all([
      put(
        transferenciasActions.fetchDestinatariosSuccess({
          destinatarios: [],
          errorDestinatarios: true,
          errorDestinatariosLog: err,
        })
      ),
      put(loadersActions.changeStatusLoaderDestinatarios({ isLoader: false })),
    ]);
    console.log(err.message);
  }
}

export function* fetchBancos() {
  try {
    yield put(loadersActions.fetchLoadingBancos({ isLoader: true }));
    const { data, error } = yield call(apiBancos.post, 'v1/obtener');

    if (data) {
      yield put(
        transferenciasActions.fetchBancosSuccess({
          bancos: data,
        })
      );
    } else {
      yield put(
        transferenciasActions.fetchBancosFailed({
          errorLog: error,
        })
      );
    }
    yield put(loadersActions.fetchLoadingBancos({ isLoader: false }));
  } catch (err) {
    yield put(
      transferenciasActions.fetchBancosFailed({
        errorLog: err,
      })
    );
    yield put(loadersActions.fetchLoadingBancos({ isLoader: false }));
  }
}

export function* fetchTipoCuentas() {
  try {
    yield put(loadersActions.fetchLoadingTipoCuentas({ isLoader: true }));
    const { data, error } = yield call(apiTipoCuentas.post, 'v1/obtener');

    if (data) {
      yield put(
        transferenciasActions.fetchTipoCuentasSuccess({
          tipoCuentas: data,
        })
      );
    } else {
      yield put(
        transferenciasActions.fetchTipoCuentasFailed({
          errorLog: error,
        })
      );
    }
    yield put(loadersActions.fetchLoadingTipoCuentas({ isLoader: false }));
  } catch (err) {
    yield put(
      transferenciasActions.fetchTipoCuentasFailed({
        errorLog: err,
      })
    );
    yield put(loadersActions.fetchLoadingTipoCuentas({ isLoader: false }));
  }
}

export function* createDestinatario(action) {
  try {
    const { destinatario, history, wasVI06, isFlowTransferencias, track } = action.payload;
    destinatario.esFavorito = null;
    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: true }));

    const { data, error } = yield call(apiDestinatario.post, 'v1/agregar', destinatario, { track });

    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: false }));

    if (data) {
      const { estado, idDestinatario, idTransferencia } = data || {};

      const destinatarioParams = {
        pathname: '/ecd/transferir/destinatarios',
        state: {
          reqSuccess: estado,
          destinatarioId: idDestinatario,
          wasVI06,
        },
      };

      const transferenciaParams = {
        pathname: '/ecd/transferir/fondos/monto',
        state: {
          destinatarioId: idDestinatario,
          isFlowTransferencias: true,
        },
      };

      if (isFlowTransferencias) {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/transferencias/destinatario-nuevo',
          'eve-cat': 'Click',
          'eve-lab': 'Continuar',
        });
        registerGA({
          event: 'eve',
          'eve-acc': 'tef/transferencias/fondos/destinatario-nuevo',
          'eve-cat': 'Click',
          'eve-lab': 'Continuar',
        });
      }

      let onlyTrack = track.split('-')[0];

        localStorage.setItem('trackTransferencia', onlyTrack);
        localStorage.setItem('idTransferencia', JSON.stringify(idTransferencia));
      yield put(
        transferenciasActions.selectDestinatarioRequest({ track: onlyTrack, idTransferencia })
      );

      history.push(isFlowTransferencias ? transferenciaParams : destinatarioParams);
    } else {
      yield all([
        put(
          transferenciasActions.fetchCreateDestinatarioFailed({
            errorCrearOEditarDestinatario: error.message,
          })
        ),
        put(
          factoresSeguridadActions.validarDesafioTarjetaCoordenadasResponse({
            validacionFactorDeSeguridad: false,
          })
        ),
      ]);

      yield logReqError(error);
    }
  } catch (err) {
    console.log(err.message);
  }
}

export function* deleteDestinatario(action) {
  try {
    const { params } = action.payload;

    yield put(loadersActions.changeStatusLoaderDeleteDestinatarios({ isLoader: true }));

    const { data, error } = yield call(apiDestinatario.put, `v1/eliminar/${params}`);

    yield logReqError(error);

    yield all([
      put(
        transferenciasActions.fetchDeleteDestinatarioSuccess({
          errorBorrarDestinatarios: data ? false : true,
        })
      ),
      put(loadersActions.changeStatusLoaderDeleteDestinatarios({ isLoader: false })),
    ]);
  } catch (err) {
    yield all([
      put(
        transferenciasActions.fetchDeleteDestinatarioSuccess({
          errorBorrarDestinatarios: true,
        })
      ),
      put(loadersActions.changeStatusLoaderDeleteDestinatarios({ isLoader: false })),
    ]);
    console.log(err.message);
  }
}

export function* editarDestinatario(action) {
  try {
    const { id, email, alias, clave, history } = action.payload;

    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: true }));

    const { data, error } = yield call(apiDestinatario.put, `v1/modificar/${id}`, {
      email,
      alias,
      clave,
    });

    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: false }));

    if (data) {
      history.push({
        pathname: '/ecd/transferir/destinatarios',
        state: {
          reqSuccess: true,
          wasEdition: true,
          destinatarioId: id,
        },
      });
    } else {
      yield put(
        transferenciasActions.fetchEditarDestinatarioFailed({
          errorCrearOEditarDestinatario: Boolean(error.code) ? '' : error.message,
          destinatarioMessageError: errEditarDestinatario[Number(error.code)] || defaultRequest,
        })
      );
    }

    yield logReqError(error);
  } catch (err) {
    console.log(err.message);
    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: false }));
  }
}

export function* validarDestinatario(action) {
  try {
    const { destinatario, history, isTransferFlow } = action.payload;

    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: true }));

    const { data, error } = yield call(apiDestinatario.post, 'v1/validar', destinatario);

    yield put(loadersActions.fetchLoadingDatosDestinatario({ isLoading: false }));

    if (data) {
      const { track } = data || {};

      yield put(transferenciasActions.selectDestinatarioRequest({ track }));

      if (isTransferFlow) {
        yield put(
          transferenciasActions.createDestinatario({
            destinatario,
            history,
            isFlowTransferencias: isTransferFlow,
            track: `${track}-12`,
          })
        );
      }
    } else {
      yield put(
        transferenciasActions.fetchCreateDestinatarioFailed({
          errorCrearOEditarDestinatario: error.message,
        })
      );

      yield logReqError(error);
    }
  } catch (err) {
    console.log(err.message);
  }
}

export function* selectDestinatario(action) {
  try {
    const { params } = action.payload;

    yield put(loadersActions.changeStatusLoadingSeleccionarDestinatario({ isLoading: true }));

    const { data, error } = yield call(apiDestinatario.post, 'v1/seleccionar', params);

    localStorage.setItem('idTransferencia', JSON.stringify(data.idTransferencia));
    if (data) {
      yield all([
        put(
          transferenciasActions.selectDestinatarioRequest({
            track: data.track,
            idTransferencia: data.idTransferencia,
          })
        ),
        put(loadersActions.changeStatusLoadingSeleccionarDestinatario({ isLoading: false })),
      ]);
    } else {
      yield all([
        put(transferenciasActions.selectDestinatarioRequest({ track: '', idTransferencia: '' })),
        put(loadersActions.changeStatusLoadingSeleccionarDestinatario({ isLoading: false })),
      ]);
      yield logReqError(error);
    }
  } catch (err) {
    yield all([
      put(transferenciasActions.selectDestinatarioRequest({ track: '', idTransferencia: '' })),
      put(loadersActions.changeStatusLoadingSeleccionarDestinatario({ isLoading: false })),
    ]);
  }
}

export function* fetchReglasTerceros(action) {
  try {
    const  {params}  = action.payload
    yield all([
      put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: true })),
      put(
        transferenciasActions.fetchReglasTercerosSuccess({
          reglasTerceros: [],
          cantidadTransferenciasTerceros: 0,
          montoAcumuladoTransferenciasTerceros: 0,
          isNuevo: false,
          permiteTransferir: false,
          errorReglasTerceros: false,
          errorReglasTercerosLog: null,
          bancosBloqueados: [],
        })
      ),
    ]);

    const { data, error } = yield call(
      apiTerceros.post,
      `reglas/v2/obtener/${params.codigoProducto}`,
      {
        numeroOrigen: params.numeroOrigen,
        idDestinatario: params.idDestinatario,
      }
    );

    yield all([
      put(
        transferenciasActions.fetchReglasTercerosSuccess({
          reglasTerceros: data ? data.reglas : [],
          montoAcumuladoTransferenciasTerceros: data ? data.monto : [],
          cantidadTransferenciasTerceros: data ? data.cantidad : [],
          isNuevo: data ? data.isNuevo : false,
          permiteTransferir: data ? data.permiteTransferir : false,
          errorReglasTerceros: data ? false : true,
          errorReglasTercerosLog: data ? null : error,
          bancosBloqueados: data?.bancosBloqueados ? data.bancosBloqueados.bancos : [],
        })
      ),
      put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: false })),
    ]);
  } catch (err) {
    yield all([
      put(
        transferenciasActions.fetchReglasTercerosSuccess({
          reglasTerceros: [],
          cantidadTransferenciasTerceros: 0,
          montoAcumuladoTransferenciasTerceros: 0,
          isNuevo: false,
          permiteTransferir: false,
          errorReglasTerceros: true,
          errorReglasTercerosLog: err,
          bancosBloqueados: [],
        })
      ),
      put(loadersActions.changeStatusLoadingReglasTerceros({ isLoading: false })),
    ]);
    console.log(err.message);
  }
}

export function* validarTransferencia(action) {
  try {
    const { params, track } = action.payload;

    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingValidarTransferencia({ isLoading: true }));

    const { data, error } = yield call(apiTerceros.post, 'v2/validar', params, optionalsHeaders);

    if (data) {
      yield put(
        transferenciasActions.validarTransferenciaRequest({
          errorValidarTransferencia: false,
          error,
        })
      );
      yield put(loadersActions.changeStatusLoadingValidarTransferencia({ isLoading: false }));
    } else {
      yield put(
        transferenciasActions.validarTransferenciaRequest({
          errorValidarTransferencia: true,
          error,
        })
      );
      yield put(loadersActions.changeStatusLoadingValidarTransferencia({ isLoading: false }));
    }
  } catch (error) {
    yield put(
      transferenciasActions.validarTransferenciaRequest({ errorValidarTransferencia: true, error })
    );
    yield put(loadersActions.changeStatusLoadingValidarTransferencia({ isLoading: false }));
  }
}

export function* ejecutarTransferencia(action) {
  try {
    const { trackEjecutar, trackDetalle, idTransferencia } = action.payload;

    yield put(loadersActions.changeStatusLoadingEjecutarTransferencia({ isLoading: true }));

    let optionalsHeaders = { track: trackEjecutar };

    const { data, error } = yield call(
      apiTerceros.post,
      'v2/ejecutar/' + idTransferencia,
      {},
      optionalsHeaders
    );

    if (data) {
      optionalsHeaders = { track: trackDetalle };

      const { data, error: errObtener } = yield call(
        apiTerceros.get,
        'v1/detalle/obtener/' + idTransferencia,
        null,
        optionalsHeaders
      );

      if (data) {
        yield put(
          transferenciasActions.ejecutarTransferenciaResponse({
            errorEjecutarTransferencia: false,
            detalleComprobanteTerceros: data,
            error: null,
          })
        );
        yield put(loadersActions.changeStatusLoadingEjecutarTransferencia({ isLoading: false }));
      } else {
        yield put(
          transferenciasActions.ejecutarTransferenciaResponse({
            errorEjecutarTransferencia: false,
            detalleComprobanteTerceros: {},
            error: errObtener,
          })
        );
        yield put(loadersActions.changeStatusLoadingEjecutarTransferencia({ isLoading: false }));
      }
    } else {
      yield put(
        transferenciasActions.ejecutarTransferenciaResponse({
          errorEjecutarTransferencia: true,
          detalleComprobanteTerceros: {},
          error,
        })
      );
      yield put(loadersActions.changeStatusLoadingEjecutarTransferencia({ isLoading: false }));
    }
  } catch (error) {
    yield put(
      transferenciasActions.ejecutarTransferenciaResponse({
        errorEjecutarTransferencia: true,
        detalleComprobanteTerceros: {},
        error,
      })
    );
    yield put(loadersActions.changeStatusLoadingEjecutarTransferencia({ isLoading: false }));
  }
}

export function* descargarComprobanteTerceros(action) {
  try {
    const { idTransferencia } = action.payload;

    const { data, error } = yield call(apiTercerosComprobante.get, 'v1/obtener/' + idTransferencia);

    if (data) {
      downloadPDFBase64OtherTab(data, 'comprobante');
      yield put(transferenciasActions.descargarComprobanteSuccess());
    }
    if(error){
      yield put(transferenciasActions.descargarComprobanteError(error));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchFetchUpdateFavoriteDestinatario() {
  yield takeLatest(transferenciasActions.fetchUpdateFavoriteDestinatario.type, fetchUpdateFavoriteDestinatario);
}

export function* watchFetchDestinatarios() {
  yield takeLatest(transferenciasActions.fetchDestinatarios.type, fetchDestinatarios);
}

export function* watchFetchBancos() {
  yield takeLatest(transferenciasActions.fetchBancos.type, fetchBancos);
}

export function* watchFetchTipoCuentas() {
  yield takeLatest(transferenciasActions.fetchTipoCuentas.type, fetchTipoCuentas);
}

export function* watchCreateDestinatario() {
  yield takeLatest(transferenciasActions.createDestinatario.type, createDestinatario);
}

export function* watchDeleteDestinatario() {
  yield takeLatest(transferenciasActions.deleteDestinatario.type, deleteDestinatario);
}

export function* watchEditarDestinatario() {
  yield takeLatest(transferenciasActions.editarDestinatario.type, editarDestinatario);
}

export function* watchValidarDestinatario() {
  yield takeLatest(transferenciasActions.validarDestinatario.type, validarDestinatario);
}

export function* watchFetchReglasTerceros() {
  yield takeLatest(transferenciasActions.fetchReglasTerceros.type, fetchReglasTerceros);
}

export function* watchSelectDestinatario() {
  yield takeLatest(transferenciasActions.selectDestinatario.type, selectDestinatario);
}

export function* watchValidarTransferencia() {
  yield takeLatest(transferenciasActions.validarTransferencia.type, validarTransferencia);
}

export function* watchEjecutarTransferencia() {
  yield takeLatest(transferenciasActions.ejecutarTransferencia.type, ejecutarTransferencia);
}

export function* watchDescargarComprobante() {
  yield takeLatest(transferenciasActions.descargarComprobanteTerceros.type, descargarComprobanteTerceros);
}
export function* watchFtuFuncionalidad() {
  yield takeLatest(transferenciasActions.ftuFuncionalidad.type, ftuFuncionalidad);
}
export function* watchFtuClose() {
  yield takeLatest(transferenciasActions.ftuClose.type, ftuClose);
}

export default function* rootSaga() {
  yield spawn(watchFetchDestinatarios);
  yield spawn(watchFetchBancos);
  yield spawn(watchFetchTipoCuentas);
  yield spawn(watchCreateDestinatario);
  yield spawn(watchDeleteDestinatario);
  yield spawn(watchEditarDestinatario);
  yield spawn(watchValidarDestinatario);
  yield spawn(watchFetchReglasTerceros);
  yield spawn(watchSelectDestinatario);
  yield spawn(watchValidarTransferencia);
  yield spawn(watchEjecutarTransferencia);
  yield spawn(watchDescargarComprobante);
  yield spawn(watchFetchUpdateFavoriteDestinatario);
  yield spawn(watchFtuFuncionalidad);
  yield spawn(watchFtuClose);
}
