import React from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
// Atoms
import AlertModal from '../../../../atoms/AlertModal';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ModalError = ({ visibleModal, setVisibleModal }) => {
  return (
    <AlertModal showModal={visibleModal} width={490} onClose={() => setVisibleModal(!visibleModal)}>
      <Wrapper>
        <div className="modalContainer">
          <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="imageCenter" size={180} />
          <h3 className="modalContainer__title">¡Lo sentimos!</h3>
          <p className="modalContainer__text">
            En estos momentos el servicio no está disponible, por favor intenta más tarde.
          </p>
          <Button
            bgColor="#007DB7"
            color="white"
            fontSize={14}
            width={120}
            onClick={() => setVisibleModal(!visibleModal)}
          >
            Entendido
          </Button>
        </div>
      </Wrapper>
    </AlertModal>
  );
};

export default ModalError;
