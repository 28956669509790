import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// actions
import { puntosActions } from '../../../../application/actions/puntos';
import PuntosCoopeuchTemplate from '../../../components/templates/Dashboard/PuntosCoopeuch';
// Metrics
import { registerGA } from '../../../utils/metrics';

const PuntosCoopeuch = () => {
  const dispatch = useDispatch();

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const [isError, setIsError] = useState(null);

  const puntosObtener = useSelector((store) => store.entities.puntos.puntosData, shallowEqual);
  const tokenData = useSelector((store) => store.entities.puntos.tokenData, shallowEqual);

  const handleClickAcumular = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Como Acumular Puntos - Puntos',
    });
  };
  const handleClickCanjear = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click',
      'eve-lab': 'Canjear - Puntos',
    });
    dispatch(puntosActions.fetchOpenSesionPuntos());
  };

  const handleClickRetry = () => {
    dispatch(puntosActions.fetchGetPuntosCoopeuch());
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Modal Impresion',
      'eve-lab': 'Modal-puntos-no-disponible',
    });
    puntosObtener?.error?.statusCode === 500 && setIsVisibleModal(true);
  };

  const handleClickEntendido = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/',
      'eve-cat': 'Click Modal',
      'eve-lab': 'Modal puntos entendido',
    });
    setIsVisibleModal(!isVisibleModal);
  };

  useEffect(() => {
    puntosObtener.data !== null && setIsError(false);
    puntosObtener.error?.statusCode === 500 && setIsError(true);
  }, [puntosObtener]);

  useEffect(() => {
    tokenData.error?.statusCode === 500 && setIsVisibleModal(true);
  }, [tokenData]);

  return (
    <PuntosCoopeuchTemplate
      puntosObtener={puntosObtener}
      handleClickCanjear={handleClickCanjear}
      handleClickRetry={handleClickRetry}
      isVisibleModal={isVisibleModal}
      setIsVisibleModal={setIsVisibleModal}
      handleClickAcumular={handleClickAcumular}
      handleClickEntendido={handleClickEntendido}
      tokenData={tokenData}
      isError={isError}
    />
  );
};

export default PuntosCoopeuch;
