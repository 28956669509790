import React from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Button from '@coopeuch-components/web/atoms/Button';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Expand from '../../../../atoms/Expand';
import radioOff from './RadioIcons/rb1.svg';
import radioOn from './RadioIcons/rb2.svg';

import Wrapper from './styles';
import { escogeCACuentaTexts } from '../../../../../containers/CuentaAhorro/texts';
import ErrorCard from '../../../../organism/ErrorCard';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CuentaTemplate = ({
  cuentaSeleccionada,
  handleClickCuenta,
  handleClickReturn,
  warningCuentaAhorroVivienda,
  tipoCuentas,
  handleClickContinue,
  showScreenError,
}) => {
  return (
    <Wrapper>
      {showScreenError ? (
        <div className="bmt-md-4 bmt-3">
          <ErrorCard
            titleText="¡Lo sentimos!"
            descText="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
          />
        </div>
      ) : (
        <div>
          <div className="bmb-4">
            <CardBox>
              <CardBox.Header>
                <label className="card-escoge-cuenta-title">{escogeCACuentaTexts.CARD_TITLE}</label>
              </CardBox.Header>
              <CardBox.Body>
                <label className="card-escoge-cuenta-desc">{escogeCACuentaTexts.CARD_DESC}</label>
              </CardBox.Body>
            </CardBox>
          </div>

          <div className="bmb-4">
            <Expand iconColor="#transparent" rounded boxClassName="hidden_line">
              <Expand.Item
                position={0}
                isExpand={cuentaSeleccionada?.id === 2}
                isBordered={cuentaSeleccionada?.id === 2}
              >
                <Expand.Header color="white">
                  <div className="metodo-radio-group-container">
                    <img
                      src={cuentaSeleccionada?.id === 2 ? radioOn : radioOff}
                      onClick={() => handleClickCuenta(tipoCuentas[1])}
                      className="radio-button-style"
                    />
                    <label className="title-expand">{escogeCACuentaTexts.EXPAND2_TITLE}</label>
                  </div>
                </Expand.Header>
                <Expand.Body className="expand-body">
                  <div className="bmt-4 expand-content">
                    <div className="bmb-2">
                      <ul>
                        <li className="bullet-red expand-list">
                          <label className="list-desc">{escogeCACuentaTexts.EXPAND2_DESC_1}</label>
                        </li>
                      </ul>
                    </div>
                    <div className="bmb-2">
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND2_DESC_2}</label>
                      </li>
                    </div>
                    <div>
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND2_DESC_3}</label>
                      </li>
                    </div>
                  </div>
                </Expand.Body>
              </Expand.Item>
            </Expand>
          </div>

          <div className="bmb-4">
            <Expand iconColor="#transparent" rounded boxClassName="hidden_line">
              <Expand.Item
                position={0}
                isExpand={cuentaSeleccionada?.id === 1}
                isBordered={cuentaSeleccionada?.id === 1}
              >
                <Expand.Header color="white">
                  <div className="metodo-radio-group-container">
                    <img
                      src={cuentaSeleccionada?.id === 1 ? radioOn : radioOff}
                      onClick={() => handleClickCuenta(tipoCuentas[0])}
                      className="radio-button-style"
                    />
                    <label className="title-expand">{escogeCACuentaTexts.EXPAND1_TITLE}</label>
                  </div>
                </Expand.Header>
                <Expand.Body className="expand-body">
                  <div className="bmt-4 expand-content">
                    <div className="bmb-2">
                      <ul>
                        <li className="bullet-red expand-list">
                          <label className="list-desc">{escogeCACuentaTexts.EXPAND1_DESC_1}</label>
                        </li>
                      </ul>
                    </div>
                    <div className="bmb-2">
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND1_DESC_2}</label>
                      </li>
                    </div>
                    <div>
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND1_DESC_3}</label>
                      </li>
                    </div>
                  </div>
                </Expand.Body>
              </Expand.Item>
            </Expand>
          </div>

          <div>
            <Expand iconColor="#transparent" rounded boxClassName="hidden_line">
              <Expand.Item
                position={0}
                isExpand={cuentaSeleccionada?.id === 3}
                isBordered={cuentaSeleccionada?.id === 3}
              >
                <Expand.Header color="white">
                  <div className="metodo-radio-group-container">
                    <img
                      src={cuentaSeleccionada?.id === 3 ? radioOn : radioOff}
                      onClick={() => handleClickCuenta(tipoCuentas[2])}
                      className="radio-button-style"
                    />
                    <label className="title-expand">{escogeCACuentaTexts.EXPAND3_TITLE}</label>
                  </div>
                </Expand.Header>
                <Expand.Body className="expand-body">
                  <div className="bmt-4 expand-content">
                    {warningCuentaAhorroVivienda && cuentaSeleccionada?.id === 3 && (
                      <Alert
                        bgType="warning"
                        iconType="warning"
                        show
                        className="alert--wrapper bmb-4"
                      >
                        {escogeCACuentaTexts.WARNING}
                      </Alert>
                    )}

                    <div className="bmb-2">
                      <ul>
                        <li className="bullet-red expand-list">
                          <label className="list-desc">{escogeCACuentaTexts.EXPAND3_DESC_1}</label>
                        </li>
                      </ul>
                    </div>
                    <div className="bmb-2">
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND3_DESC_2}</label>
                      </li>
                    </div>
                    <div>
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND3_DESC_3}</label>
                      </li>
                    </div>
                    <div className="bmb-2">
                      <li className="bullet-red expand-list">
                        <label className="list-desc">{escogeCACuentaTexts.EXPAND3_DESC_4}</label>
                      </li>
                    </div>
                  </div>
                </Expand.Body>
              </Expand.Item>
            </Expand>
          </div>

          <div className="bmt-4">
            <div className="row">
              <div className="col-md-6 display-desktop">
                <Button
                  asTextLink
                  color="#E81E2B"
                  iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                  onClick={handleClickReturn}
                  className="button-return p-0"
                >
                  {escogeCACuentaTexts.BUTTON_RETURN}
                </Button>
              </div>
              <div className="col-md-6 col-xs-12 btext-right">
                <Button
                  bgColor="#E81E2B"
                  color="white"
                  onClick={handleClickContinue}
                  disabled={
                    !(cuentaSeleccionada?.id > 0) ||
                    (warningCuentaAhorroVivienda && cuentaSeleccionada?.id === 3)
                  }
                  className="button-continue"
                >
                  {escogeCACuentaTexts.BUTTON_CONTINUE}
                </Button>
              </div>
              <div className="col-md-6 col-xs-12 display-mobile">
                <Button
                  asTextLink
                  color="#E81E2B"
                  iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />}
                  onClick={handleClickReturn}
                  className="button-return p-0"
                >
                  {escogeCACuentaTexts.BUTTON_RETURN}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default CuentaTemplate;
