import React from 'react';
// UI
import Table from '@coopeuch-components/web/molecules/Table';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Loading from '@coopeuch-components/web/atoms/Loading';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';

import Select from '../../../atoms/Select';
import SinMovimientos from '../../../atoms/SinMovimientos';

import TableHistorial from '../../../organism/CuotaParticipacion/TableHistorial';
import DescargaHistorial from '../../../organism/CuotaParticipacion/DescargaHistorial';

// Styles
import Wrapper, { MonthTitle, MovementsTable, NotFoundMvts, ContainerTitles } from './styles';

const Index = ({
  fillforSelect,
  yearSelected,
  setYearSelected,
  detalleCuota,
  handleDownloadHistorial,
}) => {
  return (
    <Wrapper className="bmt-3 bmt-md-4 text-left">
      <CardBox className="h-100 w-auto">
        <CardBox.Header className="padding-headers">
          <>
            <Typography id="title" color="#383638" tag="p" fontSize={20} fontWeight={500}>
              Consulta y descarga el historial de la Cuota de Participación
            </Typography>
          </>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12 col-sm-4">
              <Select
                id="ano"
                textSize={14}
                rounded={4}
                options={fillforSelect}
                placeholder={String(yearSelected)}
                //  value={selectStateRubro || ''}
                onChange={(e) => setYearSelected(e.target.value)}
              />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>

      {/* Detalle Card*/}
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header className="padding-headers">
              <Typography color="#383638" tag="p" fontSize={20} fontWeight={500}>
                Detalle de la Cuota de participacion {yearSelected}
              </Typography>
            </CardBox.Header>
            <CardBox.Body>
              {detalleCuota ? (
                <TableHistorial dataTable={detalleCuota} />
              ) : (
                <SinMovimientos icon="cheque" message="Sin movimientos" />
              )}
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="col-xs-12">
        {detalleCuota && (
          <DescargaHistorial
            handleDownloadHistorial={handleDownloadHistorial}
            fechaInicio={yearSelected}
            fechaFin={yearSelected}
          />
        )}
      </div>
    </Wrapper>
  );
};

export default Index;
