import styled from 'styled-components';

export const Container = styled.div`
.padding_destinatarios{
  padding: 0px 26px 20px 26px !important;
}
@media (min-width: 20em) {
  .padding_destinatarios{
  padding: 0px 16px 20px 16px !important;
}
} 
`;
const Wrapper = styled.div`
  /* padding-left: 54px;
  padding-top: 24px;
  padding-right: 76px; */

  .warning-message {
    color: #333;
    line-height: 1.4;
  }

  .dashboard-card-listado section {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09) !important;
  }

  .destinatarios-text-center {
    text-align: center;
  }

  .alert-modal-first-text {
    font-weight: bold;
  }

  .destinatario-dashboard-body-responsive {
    padding: 0 !important;
  }

  .destinatarios-imagen-error-eliminar svg {
    width: 130px;
    height: 130px;
  }

  .destinatarios-error-eliminar-text {
    color: #383638;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    font-family: 'Ubuntu-Medium';
  }

  .destinatarios-error-eliminar-desc-container {
    width: 481px;
    margin: auto;
  }

  .destinatarios-error-eliminar-text-desc {
    color: #383638;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    padding-left: 12px;
    padding-top: 16px;
    padding-right: 12px;

    .destinatarios-error-eliminar-desc-container {
      width: 100%;
    }
  }
`;

export const BodyCard = styled.div`
  .destinatario-container-responsive {
    padding-left: 32px;
    padding-top: 24px;
    padding-right: 32px;
  }

  .destinatarios-body-button {
    padding: 0 !important;
    width: 270px;
    transition: all 0.3s ease 0s;
    &:hover {
      background-color: #ec1b2b;
      color: #ebebeb;
      & path {
        fill: white;
      }
    }
  }

  .destinatarios-body-icon {
    padding-left: 20px;
    padding-top: 2px;
  }

  .destinatario-search-icon {
    padding: 15px;
  }

  .destinatario-input {
    padding-left: 40px;
  }

  .destinatarios-text-title {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Ubuntu-Medium';
  }

  .destinatarios-text-desc {
    font-size: 14px;
  }

  .error-destinatarios-title {
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: #383638;
  }

  .error-destinatario-desc {
    font-size: 14px;
    text-align: center;
    color: #383638;
  }

  .destinatarios-imagen-error {
    display: initial;
  }

  @media screen and (max-width: 768px) {
    .destinatario-container-responsive {
      padding-left: 16px;
      padding-top: 16px;
      padding-right: 16px;
    }
    .destinatario-container-row-responsive {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }
    .destinatarios-body-button {
      width: 100%;
    }
    .destinatario-button-container-responsive {
      margin-bottom: 16px;
    }

    .padding-none {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const SuccessMessage = styled.div`
  line-height: 24px;
  font-size: 16px;

  padding: 12px 24px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #c2f2bd;
  color: #306e17;

  .succes-message-icon-center {
    align-self: center;
  }

  .success-icon {
    width: 4%;
  }

  .success-text {
    width: 96%;
  }

  @media screen and (max-width: 768px) {
    .success-icon {
      width: 10%;
    }

    .success-text {
      width: 90%;
    }
  }
`;

export default Wrapper;
