import styled from 'styled-components';

const Wrapper = styled.div`
  .modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
      font-family: 'Ubuntu-Bold';
      font-size: 18px;
      margin-bottom: 12px;
      text-align: center;
    }
    &__text {
      font-size: 14px;
      text-align: center;
      max-width: 330px;
      line-height: 20px;
      font-family: 'Ubuntu-Regular';
      margin-bottom: 30px;
    }
  }
`;

export default Wrapper;
