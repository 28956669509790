/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';

// UI
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import Loading from '@coopeuch-components/web/atoms/Loading';
import Skeleton from 'react-loading-skeleton';
import Select from '../../../atoms/Select';
import SelectDate from '../../../atoms/SelectDate';
import SinHistorial from '../../../organism/CreditoHipotecario/ListaHistorial/SinHistorial';
import ListaHistorial from '../../../organism/CreditoHipotecario/ListaHistorial';
import DescargaHistorial from '../../../organism/CreditoHipotecario/DescargaHistorial';


// Styles
import { Wrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  credito,
  isLoaded,
  selectedCredito,
  handleOnChangeSelectAccount,
  handleOnChangeSelectAno,
  yearSelected,
  fillforSelect,
  isLoadFillForSelect,
  isloadingPaid,
  isLoadingMoreItems,
  onLoadMoreItems,
  dataHistorial,
  handleDownloadPDF,
}) => {

  return (
    <Wrapper className="bmt-3 bmt-md-4 text-left">
      <CardBox className="h-100 w-auto">
        <CardBox.Header className="padding-headers">
          <>
            <Typography id="title" color="#383638" tag="p" fontSize={20} fontWeight={500}>
              Historial del Crédito Hipotecario
            </Typography>
          </>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="separation-inputs">
              {isLoaded ? (
                <Skeleton width={240} height={50} />
              ) : credito?.length > 1 ? (
                <Select
                  textSize={14}
                  placeholder={selectedCredito.value}
                  labelClass="mb-10"
                  containerClass="select-cuenta"
                  onChange={handleOnChangeSelectAccount}
                  options={credito}
                  value={selectedCredito.value}
                  id="select-accountNumber"
                />
              ) : (
                <InputLabel
                  inputTextSize={14}
                  value={credito[0]?.label}
                  size={48}
                  inputClass="border-inputLabel mb-20"
                  rounded={4}
                  id="InputLabel-accountNumber"
                />
              )}
            </div>

            <div className="col-xs-12 col-sm-4 bmb-3 bmb-md-0 separation-inputs">
              <div className="row">
                <div className="col-xs-12 col-sm d-flex">
                  {isLoadFillForSelect ? (
                    <Skeleton width={240} height={50} />
                  ) :
                    fillforSelect.length > 1 ? (
                      // eslint-disable-next-line react/jsx-indent
                      <SelectDate
                        id="anio"
                        textSize={14}
                        rounded={4}
                        className="select-ano"
                        options={fillforSelect}
                        placeholder="Buscar por año"
                        value={yearSelected}
                        onChange={handleOnChangeSelectAno}
                      />
                    ) : (
                      <InputLabel
                        inputTextSize={14}
                        value={fillforSelect[0]?.label}
                        size={48}
                        inputClass="border-inputLabel mb-20"
                        rounded={4}
                        id="InputLabel-anio"
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </CardBox.Body>
      </CardBox>

      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          {isloadingPaid ? (
            <Loading text="Cargando Historial" />
          ) : dataHistorial ? (
            <ListaHistorial
              title={`Detalle del Crédito Hipotecario ${yearSelected || ""}`}
              headers={{
                row_1: 'Fecha de vencimiento',
                row_2: 'Fecha de Pago',
                row_3: 'Dividendo Pagado',
                row_4: 'Monto',
              }}
              historial={dataHistorial}
              isLoadingMoreItems={isLoadingMoreItems}
              onLoadMoreItems={onLoadMoreItems}
            />
          ) : (
            <>
              <SinHistorial/>
            </>
          )}
        </div>
      </div>
      <div className="col-xs-12 bmb-xs-3 bmb-md-4">
        {dataHistorial && (
          <DescargaHistorial
            handleDownloadPDF={handleDownloadPDF}
            fechaInicio={selectedCredito.value}
            fechaFin={yearSelected}
          />
        )}
      </div>
    </Wrapper>
  );
};
Index.propTypes = {
  // Array of objects
  credito: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.number,
      nombreTipoCredito: PropTypes.string,
      numeroCredito: PropTypes.number,
      montoOtorgado: PropTypes.number,
      fechaOtorgamiento: PropTypes.string,
      numeroReferencia: PropTypes.string,
      dividendosPagados: PropTypes.number,
      dividendosTotales: PropTypes.number,
      codEstado: PropTypes.string,
      descEstado: PropTypes.string,
    })
  ),
  isLoaded: PropTypes.bool.isRequired,
  selectedCredito: PropTypes.number,
  handleOnChangeSelectAccount: PropTypes.func.isRequired,
  handleOnChangeSelectAno: PropTypes.func.isRequired,
  yearSelected: PropTypes.number.isRequired,
  fillforSelect: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.number)),
  isLoadFillForSelect: PropTypes.bool.isRequired,
  isloadingPaid: PropTypes.bool.isRequired,
  isLoadingMoreItems: PropTypes.bool.isRequired,
  onLoadMoreItems: PropTypes.func.isRequired,
  dataHistorial: PropTypes.arrayOf(
    PropTypes.shape({
      fecha: PropTypes.instanceOf(Date),
      fechaPago: PropTypes.string,
      fechaVencimiento: PropTypes.string,
      index: PropTypes.number,
      montopagoclp: PropTypes.number,
      numeroDividendo: PropTypes.number,
    })
  ),
  handleDownloadPDF: PropTypes.func.isRequired,
};

Index.displayName = 'Historial_CredHipotecario_Template';
export default Index;
