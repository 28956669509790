import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { SliderPage } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Index = () => {
  return (
    <SliderPage
      src={`${staticPath}dashboardbanner/dashboard_desk.jpg`}
      srcMobile={`${staticPath}dashboardbanner/dashboard_responsivo.jpg`}
     
    >
      <div className="nueva-app-container row">
        <div className="col-xs-12 col-sm-8 col-md-7">
          <div>
            <label className="nueva-app-title">Nueva APP Coopeuch</label>
          </div>
          <div className="bmb-2">
            <label className="nueva-app-subtitle">Todo lo que necesitas</label>
          </div>
          <div className="nueva-app-list bmb-2 display-desktop">
            <LoadIcon src={`${iconPath}check.svg`} size={20} color="#fff" className="bmr-1" />
            <label className="nueva-app-list-text">Log In Face ID y huella digital</label>
          </div>
          <div className="nueva-app-list bmb-2 display-desktop">
            <LoadIcon src={`${iconPath}check.svg`} size={20} color="#fff" className="bmr-1" />
            <label className="nueva-app-list-text">Pagos de cuotas créditos</label>
          </div>
          <div className="nueva-app-list bmb-2 display-desktop">
            <LoadIcon src={`${iconPath}check.svg`} size={20} color="#fff" className="bmr-1" />
            <label className="nueva-app-list-text">Mayor agilidad en transacciones</label>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-5">
          <div className="icon-new-app-container">
            <LoadIcon src={`${staticPath}dashboardbanner/logo.svg`} className="icon-new-app" />
          </div>
        </div>
      </div>
    </SliderPage>
  );
};

export default Index;
