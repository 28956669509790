import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';
// eslint-disable-next-line import/no-extraneous-dependencies
import Stepper from '../../../organism/Stepper';
import StepsActions from '../../../organism/Stepper/StepsActions';
import { Wrapper, DownloadLink, CardBody, CardHeader, Card, CardFooter } from './styles';
import { capitalize } from '../../../../utils/functions';
// eslint-disable-next-line import/no-named-as-default
import SkeletonComprobante from './skeleton';
function ComprobanteTemplate({ onGoBackHome, loading, onHandleDownload, banco, numeroCuenta, tipoCuenta, fechaActualizacion, serviceError }) {
  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const steps = ['Ingreso de datos', 'Autorización', 'Comprobante'];
  return (
    <>
      <Wrapper>
        <Stepper className="mb-24 start-xs" position={3} steps={steps} />
        {loading ? <SkeletonComprobante /> : 
        <>
          <div className="row bmb-3 bmb-md-4 mt-md-4 mt-3">
            <div className="col-xs-12">
              {serviceError === true ?
              <Alert className="textExitoso" bgType="error" id="alert-felicitaciones-poder-especial" show>
                Ha ocurrido un error inesperado a la hora de descargar el comprobante, vuelva a intentarlo más tarde.
              </Alert>
              :
              <Alert className="textExitoso" bgType="success" id="alert-felicitaciones-poder-especial" show>
                La autorización del Poder Especial para el abono de acreencias a la cuenta indicada, fue realizado con éxito.
              </Alert>
              }
              
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              <Card>
                <CardHeader>
                  <div className='row cardTitleContainer'>
                    <div className='col-lg-6 col-xs-6'>
                      <h1 className='cardTitle'>
                        Comprobante
                      </h1>
                    </div>
                    <div className='col-lg-6 col-xs-6 d-flex justify-content-end align-items-center' role='button'>
                      <DownloadLink className="downloadText middle-xs" onClick={onHandleDownload}>
                        <LoadIcon
                          className="float-left mr-10"
                          src={`${iconPath}download_arrow.svg`}
                          color="#1F1E1E"
                          size={22.5}
                        />
                        <span className='d-none d-md-inline-block'>Descargar</span>
                      </DownloadLink>
                    </div>
                  </div>
                </CardHeader>
        
                <CardBody>
                  <div className='row'>
                    <div className='col-md-12 col-xs-10 cardBodyTitleContainer'>
                      <p className='cardBodyTitle'>
                        Abono de acreencias y remanente
                      </p>
                    </div>
                  </div>
                  <div className="cardBackgrounds">
                    <div className='row'>
                      <div className='col-md-7'>
                        <p className='cardBodySubTitles'>
                          Institución financiera
                        </p>
                      </div>
                      <div className='col-md-5'>
                        <p className='cardBodyProducts'>
                          {capitalize(banco?.descripcion)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='row cardNoBackground'>
                    <div className='col-md-7'>
                      <p className='cardBodySubTitles'>
                        Tipo de cuenta
                      </p>
                    </div>
                    <div className='col-md-5'>
                      <p className='cardBodyProducts'>
                        {capitalize(tipoCuenta?.descripcion)}
                      </p>
                    </div>
                  </div>
                  <div className="cardBackgrounds">
                    <div className='row'>
                      <div className='col-md-7'>
                        <p className='cardBodySubTitles'>
                          Número de cuenta
                        </p>
                      </div>
                      <div className='col-md-5'>
                        <p className='cardBodyProducts'>
                          {numeroCuenta}
                        </p>
                      </div>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className='row'>
                    <div className="col-md-10 col-xs-12 bmt-xs-0 d-flex">
                      <Typography tag="p" fontSize={14}>
                        Fecha y hora: 
                        {' '}
                        {' '}
                        <Typography tag="span" fontSize={14} fontWeight="bold">
                          {fechaActualizacion || '0'}
                        </Typography>
                      </Typography>
                    </div>
                    <div className="col-md-2 col-xs-12 bmt-4 bmt-xs-8 d-flex">
                      <LoadIcon src={`${iconPath}icon_sello.svg`} className="icon-sello" />
                    </div>
                  </div>
                </CardFooter>
              </Card>
              <StepsActions
                id="comprobante"
                className="bmt-3 mt-24"
                backText="Volver al inicio"
                backAction={onGoBackHome}
                hideNextAction
              />
            </div>
          </div>
        </>}
      </Wrapper>
    </>
  );
}
ComprobanteTemplate.propTypes = {
  loading: PropTypes.bool,
  onHandleDownload: PropTypes.func.isRequired,
  banco: PropTypes.string,
  tipoCuenta: PropTypes.string,
  numeroCuenta: PropTypes.number,
  fechaActualizacion: PropTypes.string.isRequired,
  descargarComprobanteModificacionCuotasParticipacion: PropTypes.shape({
    success: PropTypes.bool,
    error: PropTypes.bool,
    data: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
  onGoBackHome: PropTypes.func.isRequired,
};
ComprobanteTemplate.defaultProps = {
  loading: false,
  numeroCuenta: 0,
  banco: '',
  tipoCuenta: '',
};
export default ComprobanteTemplate;
