import React, { useState, useEffect } from 'react';
import Button from './styles.timerTC';
import { formatSecondToMinutes } from '../../../../../utils/formatters';

const TimerTC = ({
  counterActive,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  errorTimeOut,
  authorizeBtn,
  validateSMS,
  timerValue,
  stepSolicitartarjetaCoordenadas,
  errorIngresoCoordenadas
}) => {
  let time = 120;
  const [value, setValue] = useState();

  const updateCountDown = () => {
    time--;
    if (time === 0) {
      setValue('');
      setErrorTimeOut(true);
      setFactorErrorTimeOut('tarjetacoord');
    }
    return formatSecondToMinutes(time);
  };

  useEffect(() => {
    // ACTUALIZACION: componentDidUpdate
    let temp;
    if (stepSolicitartarjetaCoordenadas === true && errorIngresoCoordenadas === false) {
      console.log('BOTOOOOON TIMER TC');
      temp = setInterval(() => setValue(updateCountDown()), 1000);
    } else {
      clearInterval(temp);
    }
  }, [stepSolicitartarjetaCoordenadas]);

  return (
    <Button className="mt-80">
      {value ? `${value} para autorizar` : `${formatSecondToMinutes(time)} para autorizar`}
    </Button>
  );
};

TimerTC.displayName = 'CountdownNumbers';
export default TimerTC;
