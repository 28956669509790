import styled from 'styled-components';

const BaseFooter = styled.div`
  width: 100%;
  align-items: center;
  position: absolute;
  display: flex;
  z-index: 1;
  @media screen and (max-width: 63.99rem) {
    left: 0;
  }
  background-color: #fff;
  padding-right: 16px;
  padding-left: 16px;
  border-top: 1px solid #e9e9e9;
  height: 70px;
  .footer__logo,
  .footer__link,
  .footer__callicon {
    display: flex;
    align-items: center;
  }

  .footer__callicon-content {
    align-items: center;
    text-align: left;
    display: flex;
  }

  .footer-logo-style {
    height: 27px;
    width: 157px;
  }

  .footer__link {
    flex-direction: row;
    display: block;

    margin: 10px 0
  }
  .decoration {
    border-bottom: 1px solid #646569;
  }
  // Media Queries

  @media (max-width: 768px) {
    height: 152px;

    .footer__logo {
      width: 100%;
      img {
        width: 157px;
      }
    }

    .footer__link {
      margin: 5px 0 20px 0;

      span {
        margin: 4px 8px;
      }
    }
  }
  
  @media screen and (max-width: 320px) {
    height: auto;
    .margin-left-fix {
      margin-left: 0px;
    }
    .footer__link > a {
      font-size: 12px;
    }
    .footer__logo {
      display: block;
      width: 100%;
      margin-left: 3px;
      margin-top: 21px;
    }
    .footer__link {
      text-align: left;
      margin-left: 3px;
      justify-items: start;
    }
  }

  @media screen and (max-width: 425px) {
    /* height: auto;
    .margin-left-fix {
      margin-left: 0px;
    }
    .footer__logo {
      display: block;
      width: 100%;
      margin-left: 3px;
      margin-top: 19px;
      margin-bottom: 8px;
    }
    .footer__link > * {
      margin-bottom: 13px;
    }
    span {
      margin-right: 12px; 
    } */
    
    .footer__link > a {
      margin-right: 0 !important;
    }
    .footer__link {
      display: grid;
      grid-template-columns: 1fr 15px 1fr;
      justify-items: center;
      text-align: center;
      padding: 0;
      color: #646569;
    }

    .footer__callicon {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    height: 152px;

    .footer__logo {
      width: 100%;
      img {
        width: 157px;
      }
    }

    .footer__link {
      margin: 5px 0 20px 0;

      span {
        margin: 4px 8px;
      }
    }
  }

  @media screen and (max-width: 63.99rem) {
    left: 0;
  }
  @media (max-width: 768px) {
    /* height: 152px; */
    .margin-left-fix {
      margin-left: 0px;
    }
    .footer__logo {
      img {
        width: 157px;
      }
    }

    .footer__link {
      margin: 5px 0 20px 0;
    }
  }
  @media screen and (max-width: 320px) {
    .footer__link > a {
      font-size: 12px;
    }
  }
  @media screen and (min-width: 64rem) {
    border-left: 1px solid #e9e9e9;
    margin-left: -1px;
  }
`;

export default BaseFooter;
