import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasActions } from '../../../../application/actions/activacionTarjetas';
// Templates
import ComprobanteTemplate from '../../../components/templates/ActivacionTarjetas/Comprobante';
// Utils
import { registerGA } from '../../../utils/metrics';

const Comprobante = ({ steps, switchPassCoopeuchOrSms }) => {
  const dispatch = useDispatch();

  const [visibleModal, setVisibleModal] = useState(false);
  const [factorAutorizador, setFactorAutorizador] = useState('');

  const persona = useSelector((store) => store.entities.persona);
  const kitData = useSelector((store) => store.entities.activacionTarjetas.kitData);
  const validateTC = useSelector((store) => store.entities.activacionTarjetas.validateTC);
  const validateTD = useSelector((store) => store.entities.activacionTarjetas.validateTD);
  const validateSMS = useSelector((store) => store.entities.activacionTarjetas.validateSMS);
  const activateKit = useSelector((store) => store.entities.activacionTarjetas.activateKit);
  const downloadPDF = useSelector((store) => store.entities.activacionTarjetas.downloadPDF);

  useEffect(() => {
    if (validateSMS.data?.valido === true && !switchPassCoopeuchOrSms) {
      setFactorAutorizador('Clave dinámica SMS');
    }
  }, [validateSMS, switchPassCoopeuchOrSms]);

  useEffect(() => {
    if (activateKit.data?.valido === true && switchPassCoopeuchOrSms) {
      setFactorAutorizador('PassCoopeuch');
    }
  }, [activateKit, switchPassCoopeuchOrSms]);

  useEffect(() => {
    if (!switchPassCoopeuchOrSms){
      setFactorAutorizador('Tarjeta de Coordenadas');
    }
  }, [switchPassCoopeuchOrSms])

  useEffect(() => {
    if (factorAutorizador !== '') {
      registerGA({
        event: 'vpv-privado',
        page: '/tef/seguridad/activacionTarjetas/comprobante',
        producto: 'Activación de Tarjeta de Débito',
        valoracion: factorAutorizador,
      });
    }
  }, [factorAutorizador]);

  const handleDownloadPDF = () => {
    dispatch(activacionTarjetasActions.fetchDownloadComprobante());
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
  };

  const handleReturnInicio = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/comprobante',
      'eve-cat': 'Click',
      'eve-lab': 'Volver al inicio',
    });
  };



  useEffect(() => {
    // if (validateTD.data === null) {
    //   console.log("NO ESTA ACTIVAAAANDOOOOO !!!")
    //   window.location.replace('#/ecd/seguridad/activacion-tarjetas');
    // }
  }, []);

  useEffect(() => {
    if (downloadPDF.error !== null) {
      setVisibleModal(true);
    } else {
      setVisibleModal(false);
    }
  }, [downloadPDF]);

  return (
    <ComprobanteTemplate
      {...{
        steps,
        kitData,
        validateSMS,
        activateKit,
        persona,
        handleDownloadPDF,
        visibleModal,
        setVisibleModal,
        validateTC,
        handleReturnInicio,
      }}
    />
  );
};

export default Comprobante;
