import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';

import DepositosContratadoTemplate from '../../../components/templates/DepositoAPlazo/DepositosContratado';
import { useShallowStore } from '../../../utils/hooks';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';

function DepositosContratado(props) {
  const {
    location,
    history,
    depositosContratados,
    cuentasVista,
    mantencion,
    depositosContratadosError,
    isLoadingDepositosContratados,
  } = props;

  const { state } = location;

  const dispatch = useDispatch();

  const [idSimulacion, setIdSimulacion] = useState(state?.id);
  const [showModal, setShowModal] = useState(false);
  const [showMantencionErr, setShowMantencionErr] = useState(false);

  const isLoadingLiquidarDAP = useShallowStore(({ ui }) => ui.loaders.isLoadingLiquidarDAP);
  const reglasError = useShallowStore(
    ({ entities }) => entities.depositoAPlazo.reglasLiquidacionError
  );

  const itsMaintenance = useMemo(() => {
    const type = mantencion?.find((item) => item?.producto?.codigoProducto === 'DP');
    return type?.mantencion?.estado;
  }, [mantencion]);

  const seleccionada = useMemo(() => {
    let simulacion = depositosContratados[0];

    if (idSimulacion) {
      simulacion = depositosContratados?.find(({ id }) => String(id) === String(idSimulacion));
    }

    return simulacion;
  }, [depositosContratados, idSimulacion]);

  const depositosContratadosInput = useMemo(() => {
    return depositosContratados?.map(({ id, descripcionTipoDeposito, numeroDeposito }) => ({
      label: `${descripcionTipoDeposito}: N° ${numeroDeposito}`,
      value: id,
    }));
  }, [depositosContratados]);

  const handleSimulacion = useCallback(
    (event) => setIdSimulacion(event?.target?.value),
    [depositosContratados]
  );

  const handleGoToLiquidar = useCallback(() => {
    if (cuentasVista?.length === 0) {
      registerGA({
        event: 'vpv-privado',
        page: '/tef/mis-productos/deposito-a-plazo/liquidar/sin-cuenta-vista',
      });
      return setShowModal(true);
    }

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/detalle',
      'eve-cat': 'Click',
      'eve-lab': 'Liquidar',
    });

    if (itsMaintenance) {
      return setShowMantencionErr(itsMaintenance);
    }

    return history.push({
      pathname: `${location.pathname}/confirmar`,
      state: {
        idSimulacion: seleccionada?.numeroDeposito,
        montoToMatch: seleccionada?.montoVencimiento,
      },
    });
  }, [history, location, cuentasVista, setShowModal, itsMaintenance, idSimulacion]);

  const handleModalActions = useCallback(
    (type) => {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/mis-productos/deposito-a-plazo/liquidar/sin-cuenta-vista',
        'eve-cat': 'Click',
        'eve-lab': type === 'solicitar' ? 'Solicitar Cuenta Vista' : 'No por ahora',
      });

      if (type === 'solicitar') {
        return history.push('/ecd/solicita/cuenta-coopeuch');
      }

      setShowModal(false);
    },
    [history, setShowModal]
  );

  useEffect(() => {
    dispatch(depositoAPlazoActions.fetchReglasLiquidacion());
    registerDY({ type: 'PRODUCT', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP-INGRESO-DETALLE-INFORMACION');
  }, []);

  return (
    <DepositosContratadoTemplate
      error={depositosContratadosError || reglasError}
      showMantencionErr={showMantencionErr}
      loading={isLoadingDepositosContratados || isLoadingLiquidarDAP}
      seleccionada={seleccionada}
      showModal={showModal}
      depositosContratados={depositosContratadosInput}
      handleSimulacion={handleSimulacion}
      handleGoToLiquidar={handleGoToLiquidar}
      handleModalActions={handleModalActions}
    />
  );
}

DepositosContratado.propTypes = {};
DepositosContratado.defaultProps = {};

export default DepositosContratado;
