import { spawn, call, takeLatest, put, all } from 'redux-saga/effects';

// Actions
import { migracionCuentaAhorroActions } from '../../../application/actions/migracionCuentaAhorro';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';
const apiCuentaAhorro = api(process.env.REACT_APP_CUENTA_AHORRO);

export function* setDeclaracionFatca(action) {
  try {
    const { tributaEnExtranjero, go } = action.payload;

    yield put(loadersActions.fetchLoadingMigracionCuentaAhorroFatca({ isLoading: true }));

    const { data, error } = yield call(apiCuentaAhorro.post, 'cuenta/persona/fatca/guardar ', {
      tributaEnExtranjero,
    });

    if (data) {
      yield put(migracionCuentaAhorroActions.setDeclaracionFatcaSuccess());
      go();
    } else {
      yield put(migracionCuentaAhorroActions.setDeclaracionFatcaFailed({ error }));
    }
    yield put(loadersActions.fetchLoadingMigracionCuentaAhorroFatca({ isLoading: false }));
  } catch (error) {
    yield all([
      put(migracionCuentaAhorroActions.setDeclaracionFatcaFailed({ error })),
      put(loadersActions.fetchLoadingMigracionCuentaAhorroFatca({ isLoading: false })),
    ]);
  }
}

export function* fetchContratosCuentaAhorro(action) {
  try {
    const { track } = action.payload;
    const headers = { track: track };

    yield put(loadersActions.fetchLoadingContratosCuentaAhorro({ isLoading: true }));

    const { data, error } = yield call(
      apiCuentaAhorro.get,
      'cuenta/pdf/borrador/listar', null, headers
    );

    if (data) {
      yield put(
        migracionCuentaAhorroActions.fetchContratosCuentaAhorroSuccess({
          contratosCuentaAhorro: data,
        })
      );
    } else {
      yield put(
        migracionCuentaAhorroActions.fetchContratosCuentaAhorroFailed({
          errorDataContratosCuentaAhorro: error,
        })
      );
    }
    yield put(loadersActions.fetchLoadingContratosCuentaAhorro({ isLoading: false }));
  } catch (err) {
    yield put(
      migracionCuentaAhorroActions.fetchContratosCuentaAhorroFailed({
        errorDataContratosCuentaAhorro: err,
      })
    );
    yield put(loadersActions.fetchLoadingContratosCuentaAhorro({ isLoading: false }));
  }
}

export function* fetchBorradorContratoCuentaAhorro(action) {
  try {
    const { track, nombreDocumento } = action.payload;
    const headers = { track: track };
    yield put(loadersActions.fetchLoadingBorradorContratoCuentaAhorro({ isLoading: true }));

    const { data, error } = yield call(
      apiCuentaAhorro.get,
      'cuenta/pdf/borrador/generar/' + nombreDocumento ,null, headers
    );

    if (data) {
      yield put(
        migracionCuentaAhorroActions.fetchBorradorContratoCuentaAhorroSuccess({
          borradorCuentaAhorro: data,
        })
      );
    } else {
      yield put(
        migracionCuentaAhorroActions.fetchBorradorContratoCuentaAhorroFailed({
          errorDataBorradorCuentaAhorro: error,
        })
      );
    }
    yield put(loadersActions.fetchLoadingBorradorContratoCuentaAhorro({ isLoading: false }));
  } catch (err) {
    yield put(
      migracionCuentaAhorroActions.fetchBorradorContratoCuentaAhorroFailed({
        errorDataBorradorCuentaAhorro: err,
      })
    );
    yield put(loadersActions.fetchLoadingBorradorContratoCuentaAhorro({ isLoading: false }));
  }
}

export function* migrarCuentaAhorro(action) {
  try {
    const params = action.payload;
    yield put(loadersActions.fetchLoadingMigrarCuentaAhorro({ isLoading: true }));
    const { data, error } = yield call(apiCuentaAhorro.post, 'cuenta/v1/migrar', params);
    if (data) {
      yield put(
        migracionCuentaAhorroActions.migrarCuentaAhorroSuccess({ migrarCuentaAhorro: data })
      );
    } else {
      yield put(
        migracionCuentaAhorroActions.migrarCuentaAhorroFailed({
          errorDataMigrarCuentaAhorro: error,
        })
      );
    }
    yield put(loadersActions.fetchLoadingMigrarCuentaAhorro({ isLoading: false }));
  } catch (err) {
    yield put(
      migracionCuentaAhorroActions.migrarCuentaAhorroFailed({
        errorDataMigrarCuentaAhorro: err,
      })
    );
    yield put(loadersActions.fetchLoadingMigrarCuentaAhorro({ isLoading: false }));
  }
}

export function* watchSetDeclaracionFatca() {
  yield takeLatest(migracionCuentaAhorroActions.setDeclaracionFatca.type, setDeclaracionFatca);
}

export function* watchFetchContratosCuentaAhorro() {
  yield takeLatest(
    migracionCuentaAhorroActions.fetchContratosCuentaAhorro.type,
    fetchContratosCuentaAhorro
  );
}

export function* watchFetchBorradorContratoCuentaAhorro() {
  yield takeLatest(
    migracionCuentaAhorroActions.fetchBorradorContratoCuentaAhorro.type,
    fetchBorradorContratoCuentaAhorro
  );
}

export function* watchMigrarCuentaAhorro() {
  yield takeLatest(migracionCuentaAhorroActions.migrarCuentaAhorro.type, migrarCuentaAhorro);
}

export default function* rootSaga() {
  yield spawn(watchSetDeclaracionFatca);
  yield spawn(watchFetchContratosCuentaAhorro);
  yield spawn(watchFetchBorradorContratoCuentaAhorro);
  yield spawn(watchMigrarCuentaAhorro);
}
