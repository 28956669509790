import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { creditoHipotecarioActions } from '../../../../../application/actions/creditoHipotecario';
import PaymentTemplate from '../../../../components/templates/CreditoHipotecario/Payment/PaymentTemplate';

import { useShallowStore } from '../../../../utils/hooks';

function PaymentContainer({ onError, onGoBack, onCompletePayment }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    loading: loadingPay,
    success: successPay,
    data: dataPay,
    error: errorPay,
    loanToPay: { feeNumber, expirationDate, feeUFAmount, feeAmount, referenceNumber },
  } = useShallowStore((store) => store.entities.creditoHipotecario.pay);

  const {
    success: successAvailableAccounts,
    data: availableAccountsList,
    error: errorAvailableAccounts,
  } = useShallowStore((store) => store.entities.creditoHipotecario.availableAccounts);

  const [enoughMoney, setEnoughMoney] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordInputValidation, setPasswordInputValidation] = useState('');

  useEffect(() => {
    if (successAvailableAccounts) {
      setSelectedAccount(availableAccountsList[0]);
    }
  }, [successAvailableAccounts]);

  useEffect(() => {
    if (selectedAccount) {
      setEnoughMoney(selectedAccount.balance.value >= feeAmount.value);
    }
  }, [selectedAccount, feeAmount]);

  useEffect(() => {
    if (successPay) {
      onCompletePayment();
    }

    return () => {
      dispatch(creditoHipotecarioActions.clearPay());
    };
  }, [dispatch, successPay, onCompletePayment]);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePassword = ({ target: { value } }) => {
    setPassword(value);
    setPasswordInputValidation('');
    if (value.length < 6) {
      setPasswordInputValidation('La clave debe tener entre 6 y 8 caracteres.');
    }
  };

  const onChangeAccount = ({ target: { value } }) => {
    // "account" puede ser "undefined"
    const account = availableAccountsList.find((a) => a.value === value);
    if (account) {
      setSelectedAccount(account);
    }
  };

  const onWrongPassword = () => {
    dispatch(creditoHipotecarioActions.clearPay());
    setShowPassword(false);
  };

  const onBlockedPassword = () => {
    history.push('/recuperar-clave');
  };

  const onPay = () => {
    if (enoughMoney && password) {
      dispatch(
        creditoHipotecarioActions.fetchPay({
          accountNumber: selectedAccount.value,
          referenceNumber,
          password,
        })
      );
    } else {
      onGoBack();
    }
  };

  let validationError = null;
  if (errorPay.wrongPassword || errorPay.blockedPassword) {
    validationError = dataPay;
  }

  let handleValidationError = onWrongPassword;
  if (errorPay.blockedPassword) {
    handleValidationError = onBlockedPassword;
  }

  const passwordIsValid = !passwordInputValidation && password;
  const nextActionButtonDisabled =
    (!passwordIsValid && enoughMoney) || !!validationError || loadingPay;

  // Virtualmente, en este punto no debiese ocurrir un error de "availableAccounts" porque
  // el componente padre ya valida que sólo se permita el pago si existen cuentas disponibles.
  // De todas formas se ha optado por considerarlo en este punto como garantía adicional.
  const error = errorPay.server || errorAvailableAccounts.server || errorAvailableAccounts.noData;

  if (error) {
    onError();
  }

  return (
    <PaymentTemplate
      selectedAccount={selectedAccount}
      availableAccountsList={availableAccountsList}
      onChangeAccount={onChangeAccount}
      enoughMoney={enoughMoney}
      feeNumber={feeNumber}
      expirationDate={expirationDate}
      feeUFAmount={feeUFAmount}
      feeAmount={feeAmount}
      password={password}
      handlePassword={handlePassword}
      passwordInputValidation={passwordInputValidation}
      handleValidationError={handleValidationError}
      validationError={validationError}
      showPassword={showPassword}
      togglePassword={togglePassword}
      loadingPay={loadingPay}
      onGoBack={onGoBack}
      onPay={onPay}
      nextActionButtonDisabled={nextActionButtonDisabled}
    />
  );
}

PaymentContainer.propTypes = {
  onError: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onCompletePayment: PropTypes.func.isRequired,
};

export default PaymentContainer;
