import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasKitActions } from '../../../../application/actions/activacionTarjetasKit';
import { personaActions } from '../../../../application/actions/persona';
// Template
import AutorizacionTemplate from '../../../components/templates/ActivacionTarjetasKit/Autorizacion';
// Utils
import { registerGA } from '../../../utils/metrics';

const Autorizacion = ({ steps, switchPassCoopeuchOrSms, setSwitchPassCoopeuchOrSms }) => {
  const dispatch = useDispatch();
  // Stores
  const persona = useSelector((store) => store.entities.persona);
  const kitData = useSelector((store) => store.entities.activacionTarjetasKit.kitDataKit);
  const smsCode = useSelector((store) => store.entities.activacionTarjetasKit.smsCodeKit);
  const validateSMS = useSelector((store) => store.entities.activacionTarjetasKit.validateSMSKit);
  const validateTC = useSelector((store) => store.entities.activacionTarjetasKit.validateTCKit);
  const availablePassCoopeuch = useSelector(
    (store) => store.entities.activacionTarjetasKit.availablePassCoopeuchKit
  );
  const solicitarPassCoopeuch = useSelector(
    (store) => store.entities.activacionTarjetasKit.solicitarPassCoopeuchKit
  );
  const IsValidatePassCoopeuch = useSelector(
    (store) => store.entities.activacionTarjetasKit.validarPassCoopeuchKit
  );
  const activateKit = useSelector((store) => store.entities.activacionTarjetasKit.activateKitKit);

  // States
  const [smsCodeOne, setSmsCodeOne] = useState('');
  const [smsCodeTwo, setSmsCodeTwo] = useState('');
  const [smsCodeThree, setSmsCodeThree] = useState('');
  const [smsCodeFour, setSmsCodeFour] = useState('');
  const [smsCodeFive, setSmsCodeFive] = useState('');
  const [smsCodeSix, setSmsCodeSix] = useState('');
  const [timerValue, setTimerValue] = useState(120);
  // Boolean
  const [counterActive, setCounterActive] = useState(false);
  const [errorTimeOut, setErrorTimeOut] = useState(false);
  const [factorErrorTimeOut, setFactorErrorTimeOut] = useState('');
  const [authorizeBtn, setAuthorizeBtn] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [isVisibleModalError, setIsVisibleModalError] = useState(false);
  const [IsAvailablePassCoopeuch, setIsAvailablePassCoopeuch] = useState(true);

  const handleClickSendSMS = () => {
    dispatch(activacionTarjetasKitActions.fetchSendSMSCodeKit());
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Enviar SMS - Clave Dinámica SMS',
    });
  };

  const handleClickValidateSMS = () => {
    const codigo = `${smsCodeOne}${smsCodeTwo}${smsCodeThree}${smsCodeFour}${smsCodeFive}${smsCodeSix}`;
    dispatch(activacionTarjetasKitActions.fetchValidateSMSCodeKit(codigo));
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar - Clave Dinámica SMS',
    });
  };

  const handleClickAuthorizePassCoopeuch = () => {
    dispatch(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchKit());
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Autorizar con PassCoopeuch - PassCoopeuch',
    });
  };

  const cleanInputsSMS = () => {
    setSmsCodeOne('');
    setSmsCodeTwo('');
    setSmsCodeThree('');
    setSmsCodeFour('');
    setSmsCodeFive('');
    setSmsCodeSix('');
  };

  useEffect(() => {
    let factorTimeout = '';
    switch (factorErrorTimeOut) {
      case 'passcoopeuch':
        factorTimeout = 'Passcoopeuch Tiempo Agotado';
        break;
      case 'sms':
        factorTimeout = 'Clave Dinámica SMS Tiempo Agotado';
        break;
      default:
        factorTimeout = factorErrorTimeOut;
    }
    if (factorErrorTimeOut !== '') {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': `${factorTimeout}`,
      });
    }
  }, [factorErrorTimeOut]);

  const retryError = (typeError) => {
    cleanInputsSMS();
    if (typeError === 'errorTime') {
      dispatch(activacionTarjetasKitActions.fetchSendSMSCodeResetKit());
      setCounterActive(false);
      setTimerValue(120);
      setErrorTimeOut(false);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - Clave Dinámica SMS Tiempo Agotado',
      });
    } else {
      setCounterActive(true);
      dispatch(activacionTarjetasKitActions.fetchValidateSMSCodeResetKit());
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Reintentar - Clave Dinámica SMS',
      });
    }
  };

  const retryTimeOutPass = () => {
    setCounterActive(false);
    setErrorTimeOut(false);
    dispatch(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchValidarPassCoopeuchResetKit());
  };

  const backToActivacion = (accion, factor) => {
    dispatch(activacionTarjetasKitActions.fetchValidarTarjetaCoordenadasResetKit());
    dispatch(activacionTarjetasKitActions.fetchSendSMSCodeResetKit());
    dispatch(activacionTarjetasKitActions.fetchValidateSMSCodeResetKit());
    dispatch(activacionTarjetasKitActions.fetchIsEnabledPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchResetKit());
    dispatch(activacionTarjetasKitActions.fetchValidarPassCoopeuchResetKit());
    setErrorTimeOut(false);
    let nombreFactor = '';
    switch (factor) {
      case 'passcoopeuch':
        nombreFactor = 'PassCoopeuch';
        break;
      case 'sms':
        nombreFactor = 'Clave Dinámica SMS';
        break;
      case 'tarjetacoord':
        nombreFactor = 'Tarjeta de Coordenadas';
        break;
      default:
        nombreFactor = factor;
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': `${accion} - ${nombreFactor}`,
    });
  };

  const handleClickDownloadApp = (plataforma) => {
    let nombreStore = '';
    switch (plataforma) {
      case 'AppStore':
        nombreStore = 'AppStore';
        break;
      case 'GooglePlay':
        nombreStore = 'GooglePlay';
        break;
      default:
        nombreStore = plataforma;
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': `${nombreStore} - PassCoopeuch`,
    });
  };

  const handleSetSmsCode = (id, value) => {
    id === 'cor1' && setSmsCodeOne(value);
    id === 'cor2' && setSmsCodeTwo(value);
    id === 'cor3' && setSmsCodeThree(value);
    id === 'cor4' && setSmsCodeFour(value);
    id === 'cor5' && setSmsCodeFive(value);
    id === 'cor6' && setSmsCodeSix(value);
  };

  const handleClickCloseModalError = () => {
    setIsVisibleModalError(false);
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
      'eve-cat': 'Click',
      'eve-lab': 'Entendido - Servicio no disponible',
    });
    backToActivacion('Volver al inicio','Servicio no disponible');
    window.location.replace('#/ecd');
  };

  const validatePassCoopeuch = () => {
    dispatch(activacionTarjetasKitActions.fetchValidarPassCoopeuchKit());
  };

  const handleSwitchMethods = (method) => {
    if (method === 'passcoopeuch') {
      setSwitchPassCoopeuchOrSms(!switchPassCoopeuchOrSms);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': IsAvailablePassCoopeuch
          ? 'Quiero autorizar con Clave Dinámica SMS - PassCoopeuch'
          : 'Quiero autorizar con Tarjeta de Coordenadas - PassCoopeuch',
      });
    }

    if (method === 'sms') {
      setSwitchPassCoopeuchOrSms(!switchPassCoopeuchOrSms);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Click',
        'eve-lab': 'Quiero autorizar con PassCoopeuch - Clave Dinámica SMS',
      });
    }
  };

  useEffect(() => {
    dispatch(personaActions.fetchDatosPersonales());
    registerGA({
      event: 'vpv-privado',
      page: '/tef/seguridad/activacionTarjetas/paso2',
    });
  }, []);

  // Validate response available passcoopeuch
  useEffect(() => {
    if (availablePassCoopeuch?.data === true) {
      dispatch(activacionTarjetasKitActions.fetchSolicitarPassCoopeuchKit());
      setIsVisibleModalError(false);
    }
    if (availablePassCoopeuch?.data === false) {
      setIsAvailablePassCoopeuch(false);
    }
    if (availablePassCoopeuch?.error !== null) {
      setIsVisibleModalError(true);
    } else {
      setIsVisibleModalError(false);
    }
  }, [availablePassCoopeuch]);

  // Validate response solicitar passcoopeuch
  useEffect(() => {
    if (solicitarPassCoopeuch?.data !== null) {
      setCounterActive(true);
    }
    if (solicitarPassCoopeuch?.error !== null) {
      setIsVisibleModalError(true);
      setCounterActive(false);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [solicitarPassCoopeuch]);

  useEffect(() => {
    if (IsValidatePassCoopeuch?.data?.codigo === 8) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setIsLoading(false);
    }
    if (IsValidatePassCoopeuch?.data?.codigo === 7) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setIsLoading(false);
    }
    if (IsValidatePassCoopeuch?.data?.codigo === 0) {
      setCounterActive(false);
      setErrorTimeOut(null);
      dispatch(activacionTarjetasKitActions.fetchActivateKitCVKit());
      setIsLoading(true);
    }
    if (IsValidatePassCoopeuch?.error !== null) {
      setCounterActive(false);
      setErrorTimeOut(null);
      setIsLoading(false);
      setIsVisibleModalError(true);
    }
    if (IsValidatePassCoopeuch?.error?.statusCode === 408){
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
  }, [IsValidatePassCoopeuch]);

  useEffect(() => {
    if (
      smsCodeOne !== '' &&
      smsCodeTwo !== '' &&
      smsCodeThree !== '' &&
      smsCodeFour !== '' &&
      smsCodeFive !== '' &&
      smsCodeSix !== ''
    ) {
      setAuthorizeBtn(true);
    } else {
      setAuthorizeBtn(false);
    }
  }, [smsCodeOne, smsCodeTwo, smsCodeThree, smsCodeFour, smsCodeFive, smsCodeSix]);

  useEffect(() => {
    if (validateTC.data === null || kitData.data === null) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas-kit');
    }
  }, []);

  useEffect(() => {
    smsCode.data === true && setCounterActive(true);
  }, [smsCode]);

  useEffect(() => {
    if (validateSMS.data?.bloqueado === true) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/seguridad/activacionTarjetas/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Reintentar - Clave dinámica SMS Bloqueada',
      });
    }
    validateSMS.data?.valido === true &&
      window.location.replace('#/ecd/seguridad/activacion-tarjetas-kit/comprobante');
  }, [validateSMS]);

  useEffect(() => {
    if (activateKit.data?.valido === true) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas-kit/comprobante');
      setIsLoading(false);
    }
  }, [activateKit]);

  return (
    <AutorizacionTemplate
      {...{
        steps,
        counterActive,
        setCounterActive,
        handleClickSendSMS,
        errorTimeOut,
        setErrorTimeOut,
        setFactorErrorTimeOut,
        retryError,
        handleSetSmsCode,
        authorizeBtn,
        handleClickValidateSMS,
        smsCode,
        validateSMS,
        smsCodeOne,
        smsCodeTwo,
        smsCodeThree,
        smsCodeFour,
        smsCodeFive,
        smsCodeSix,
        persona,
        timerValue,
        setTimerValue,
        backToActivacion,
        switchPassCoopeuchOrSms,
        handleClickAuthorizePassCoopeuch,
        retryTimeOutPass,
        validatePassCoopeuch,
        IsValidatePassCoopeuch,
        IsLoading,
        isVisibleModalError,
        handleClickCloseModalError,
        IsAvailablePassCoopeuch,
        handleSwitchMethods,
        handleClickDownloadApp,
      }}
    />
  );
};

export default Autorizacion;
