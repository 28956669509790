import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

  .confirmacion-title-card-head h5 {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 32px;
  }

  .confirmacion-simulacion-card-body {
    padding: 24px 32px 24px 32px;
  }

  .confirmacion-text-cuenta {
    color: #383638;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    font-family: 'Ubuntu-Medium';
  }

  .confirmacion-text-monto {
    font-family: 'Ubuntu-Medium';
    font-size: 28px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    color: #383638;
  }

  .confirmacion-select {
    width: 296px;
  }

  .confirmacion-text-email {
    color: #383638;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    font-family: 'Ubuntu-Medium';
  }

  .confirmacion-only-account {
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 12px;
    padding-left: 12px;
    width: max-content;
    min-width: 296px;
    min-height: 48px;
  }

  .confirmacion-clave-internet-text {
    color: #383638;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
    font-family: 'Ubuntu-Medium';
  }

  .confirmacion-terminos-text {
    color: #383838;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .confirmacion-terminos-redirect {
    color: #f42534;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    font-family: 'Ubuntu-Medium';
    text-decoration: underline;
    cursor: pointer;
  }

  .confirmacion-terminos-container {
    display: inline-flex;
  }

  .confirmacion-terminos-center {
    align-self: center;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  .button-return {
    font-weight: bold;
    width: 187px;
    text-align: left;
  }

  .button-continue {
    min-width: 160px;
    display: inline-flex;
  }

  .cuantavista-only-account {
    border: 1px solid #646569;
    border-radius: 4px;
    box-sizing: border-box;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 12px;
    padding-left: 12px;
    width: 296px;
  }
  .cuentavista-only-account-text {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .confirmacion-error-container {
    text-align: center;
  }

  .confirmacion-error-button {
    width: 296px;
  }

  @media screen and (max-width: 1024px) {
    padding: 1rem 1rem 1rem 1rem;

    .confirmacion-simulacion-card-body {
      padding: 16px;
    }

    .confirmacion-select {
      width: 100%;
    }

    .confirmacion-title-card-head h5 {
      color: #383638;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
    }

    .icon-alert svg {
      height: 18px;
      width: 18px;
      margin-top: 3px;
    }

    .icon-alert {
      padding: 12px 15px;
    }

    .confirmacion-contacto-text {
      color: #383638;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
    }

    .icon-alert > .right-content {
      align-items: start;
    }

    .confirmacion-terminos-center {
      margin-left: -8px;
    }

    .button-continue {
      width: 100%;
    }

    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }

    .button-return {
      margin-left: 29%;
    }

    .cuantavista-only-account {
      width: 100%;
    }

    .confirmacion-only-account {
      width: 100%;
      min-width: initial;
    }

    .confirmacion-error-button {
      width: 100%;
    }
  }
`;

export const AlertWrapper = styled.div`
  .confirmacion-terminos-modal {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .confirmacion-terminos-modal-title {
    color: #383638;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    font-family: 'Ubuntu-Medium';
  }
  .button-modal {
    width: 183px;
  }
  .modal-icon svg {
    width: 140px;
    height: 140px;
  }
  .confirmacion-modal-link {
    color: #e81e2b;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const AlertTasaWrapper = styled.div`
  .confirmacion-tasa-alert-title {
    color: #383838;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    font-family: 'Ubuntu-Medium';
    font-weight: bold;
    letter-spacing: 0.5px;
}
  }
  .confirmacion-tasa-alert-desc {
    font-size: 14px;
    letter-spacing: 0;
    line-height: 28px;
  }
  .modal-icon svg {
    width: 140px;
    height: 140px;
  }
`;

export const TitleWrapper = styled.div`
  .confirmacion-icon {
    display: flex;
    align-items: center;
  }

  .confirmacion-icon svg {
    height: 24px;
    width: 24px;
  }
`;

export const WrapperSkeleton = styled.div`
  .skeleton-center-vertical {
    align-self: center;
  }

  .display-desktop {
    display: initial;
  }

  .display-mobile {
    display: none;
  }

  @media screen and (max-width: 1024px) {
    .display-desktop {
      display: none;
    }

    .display-mobile {
      display: initial;
    }
  }
`;

export default Wrapper;
