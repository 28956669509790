const defaultRequest = {
  id: 500,
  title: '¡Lo sentimos!',
  message:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
};

export const errEditarDestinatario = {
  default: defaultRequest,
  1: {
    id: 1,
    title: 'Clave Bloqueada.',
    message: 'Clave Bloqueada. Por seguridad hemos bloqueado tu clave.',
    link: {
      name: 'Cambiar clave',
      url: '',
    },
  },
  2: {
    id: 2,
    title: 'Actualiza tu clave.',
    message: 'Actualiza tu clave. Te invitamos a crear una nueva clave.',
    link: {
      name: 'Actualizala aqui',
      url: '',
    },
  },
  3: {
    id: 3,
    title: 'Datos incorrectos',
    message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
  },
};

export default defaultRequest;
