import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRouter = ({ children, ...rest }) => (
  <Route
    {...rest}
    render={({ location }) =>
      sessionStorage.getItem('ecdtoken') ? (
        children
      ) : (
        <React.Fragment>{window.location.replace((!localStorage.getItem('pathLogin') || localStorage.getItem('pathLogin') == 'undefined' || localStorage.getItem('pathLogin') == 'login') ? '/' : localStorage.getItem('pathLogin'))}</React.Fragment>
      )
    }
  />
);

export default PrivateRouter;
