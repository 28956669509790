import React from 'react';

import Skeleton from 'react-loading-skeleton';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import BannerSimulaCredito from './BannerSimulaCredito';
import BannerSolicitalaAhora from './BannerSolicitalaAhora';
import BannerKitCV from './BannerKitCV';
import DashboardCard from '../../../organism/DashboardCard';
import {accesosRapidosTexts} from '../../../../containers/Dashboard/texts';
import Couple from './couple.svg';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const AccesosRapidosTemplate = ({
  handleClickEncuesta,
  handleClickTransferencia,
  handleClickDAP,
  pasoactual,
  handleLinkSolicitaCV,
  handleClickBannerKitCV,
  loadingContratacionCV,
  isContractableCV,
  isAvailableBannerCV,
}) => {
  let content = '';

  if (loadingContratacionCV) {
    content = (
      <div className="col-md-6 col-xs-12">
        <div className="bml-0 bml-md-1">
          <Skeleton height={10} width="80%" style={{ maxWidth: 254 }} />
        </div>
      </div>
    );
  }

  if (isAvailableBannerCV === true) {
    content = <BannerKitCV handleClickBannerKitCV={handleClickBannerKitCV} />;
  } else {
    if (isContractableCV === true && isAvailableBannerCV === false) {
      content = (
        <BannerSolicitalaAhora
          pasoactual={pasoactual}
          handleLinkSolicitaCV={handleLinkSolicitaCV}
          Couple={Couple}
        />
      );
    } else {
      content = (
        <BannerSimulaCredito
          pasoactual={pasoactual}
          iconPath={iconPath}
          handleClickEncuesta={handleClickEncuesta}
        />
      );
    }
  }

  return (
    <div className="bmb-3 bmb-md-4">
      <Wrapper>
        <div className="bmb-3 ">
          <label className="dashboard-title">{accesosRapidosTexts.TITLE}</label>
        </div>
        <div className="row">
          <div
            id="accesos"
            className={
              pasoactual == '4'
                ? 'col-md-6 col-xs-12 bmb-3 bmb-md-0 accesoss'
                : 'col-md-6 col-xs-12 bmb-3 bmb-md-0 '
            }
            style={{ display: 'flex', width: '99%' }}
          >
            <div className="col-md-6 col-xs-6 bmb-3 bmb-md-0">
              <div className="bmr-1 ">
                <div onClick={handleClickTransferencia}>
                  <DashboardCard classes="dashboard-card-shadow">
                    <DashboardCard.Body classes="dashboard-access-container">
                      <div className="text-center">
                        <div className="transferir-icon">
                          <LoadIcon
                            src={`${iconPath}monedero_new.svg`}
                            size={30}
                            color="#1E1E1E"
                            className="access-icon"
                          />
                        </div>
                        <div className="dashboard-center-2lines-text">
                          <label className="access-text">
                            {accesosRapidosTexts.TRANSFERIR_BUTTON}
                          </label>
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xs-6">
              <div className="bml-1 bmr-1">
                <div onClick={handleClickDAP}>
                  <DashboardCard classes="dashboard-card-shadow">
                    <DashboardCard.Body classes="dashboard-access-container">
                      <div className="text-center">
                        <div className="bloq-icon">
                          <LoadIcon
                            src={`${iconPath}calendar_new.svg`}
                            size={30}
                            color="#1E1E1E"
                            className="access-icon"
                          />
                        </div>
                        <div>
                          <label className="access-text">{accesosRapidosTexts.DAP_BUTTON}</label>
                        </div>
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>
              </div>
            </div>
          </div>
          {content}
        </div>
      </Wrapper>
    </div>
  );
};

export default AccesosRapidosTemplate;
