import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100%;
  box-sizing: border-box;

  img {
    pointer-events: none;
  }

  .dashboard--content-wrapper {
    p {
      font-size: 16px;
      margin-left: 8px;
      margin-right: 5px;
      float: left;
    }

    .text--amount {
      margin-bottom: 20px;
      align-items: center;
      padding: 0;

      & > div {
        flex-direction: row;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 330px) {
    .expand--title {
      width: 200px;
    }
  }
`;

export const CardWrapper = styled.div`
  .dashboard-card-header {
    height: unset;
  }
`;

export const SwiperContainer = styled.div`
  /* height: 200px; */
  border-radius: 5px;
  overflow: hidden;
  .swiper-slide {
    width: 100% !important;
  }
  .slider-action-column {
    /* display: flex;
    align-items: center; */
  }
  .slider-action-banner {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
`;

export const LeftColBannerRemanente = styled.div`
  /* min-width:100%; */
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  .button-ver-mas-remanente {
    align-items: center;
  }
  a {
    text-decoration: none;
  }
  svg {
    fill: white;
  }
  .banner-wrapper {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  /* line-height: 48px; */
`;
export const BannerWrapper = styled.div`
  height: 150px;
`;
export const BannerEstacional = styled.div`
  height: 150px;
  color: #383638;

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  svg path {
    fill: #e81e2b;
  }

  .dashboard--bannerestacional-image {
    background: no-repeat url(${({ webImage }) => webImage});

    background-size: cover;
    border-radius: 5px 0 0 5px;

    p {
      height: 64px;
      width: 194px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  @media screen and (max-width: 768px) {
    svg path {
      fill: white;
    }

    .dashboard--bannerestacional-image {
      background: no-repeat url(${({ mobileImage }) => mobileImage});
      background-size: cover;
      border-radius: 5px;
    }
  }
`;

export const CardMisProductos = styled.div`
  .dashboard--currency-wrapper {
    align-items: center;
    /* margin-bottom: 14px;
    margin-top: 14px; */
    display: flex;
    color: #383638;

    & .dashboard--currency-title {
      font-size: 14px;
      /* margin-top: 6px;
      margin-bottom: 12px; */
    }

    & .dashboard--currency {
      font-family: 'Ubuntu-Bold';
      /* margin-right: 14px; */
      font-size: 28px;
      color: #333;
      float: left;
    }
  }
`;

export const Caluga = styled.div`
  justify-content: center;
  display: flex;

  .dashboard--wrapper-caluga {
    border-radius: 5px;
    background: ${({ color }) => color};
    height: 168px;
    width: 431px;
    overflow: hidden;
    margin: 0;

    img {
      min-height: 100%;
    }
    div {
      padding: 0;
    }

    .dashboard--content-caluga {
      padding: 16px;
      color: white;
      height: 100%;
    }
  }

  .caluga {
    &--title {
      line-height: 24px;
    }
    &--subtitle {
      line-height: 32px;
    }
    &--descripcion {
      line-height: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

export const ExpandHeader = styled.div`
  padding-left: 12px;
  .expand--title {
    display: flex;
  }
  @media screen and (max-width: 766px) {
    padding-left: 0;
  }
`;

export const Surveys = styled.div`
  height: 80px;
  color: #383638;

  div {
    display: flex;
  }

  .opinion-poll-btn {
    width: 168px;
    font-size: initial;
  }

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 16px;

    .opinion-poll-btn {
      width: 100%;
    }
  }
`;

export const WrapperSkeleton = styled.div`
  .skeleton-separator {
    margin-top: 36px !important;
    margin-bottom: 24px !important;
  }

  .module-skeleton-card {
    padding: 24px 32px 36px 32px;
  }

  @media screen and (max-width: 768px) {
    .module-content {
      padding: 16px !important;
    }

    .skeleton-separator {
      margin-top: 24px !important;
    }

    .module-second {
      margin-top: 16px;
    }
  }
`;

export default Wrapper;
