import Styled from 'styled-components';

export const ActionButtons = Styled.div`
    color: #F42534;
    display: inline-flex;
    font-weight: bold;
    & > .comprobante-action {
      display: inline-flex;
      cursor: pointer;
    }
`;