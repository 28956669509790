import React, { Component } from 'react';
import { connect } from 'react-redux';
import { registerGA } from '../../../utils/metrics';
// Components
import DetalleCreditoTemplate from '../../../components/templates/CreditoHipotecario/DetalleCredito';

import { creditoHipotecarioActions } from '../../../../application/actions/creditoHipotecario';

const initState = {
  loadingCreditoSeleccionado: true,
  creditoSeleccionado: null,
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { creditos, resultadoCuota, changeSelectedLoanToPay } = this.props;
    const { creditoSeleccionado } = this.state;
    if (
      (prevProps.creditos !== creditos || prevProps.resultadoCuota !== resultadoCuota) &&
      creditos !== null &&
      resultadoCuota !== null
    ) {
      const creditoSeleccionado = this.setCreditoSeleccionado(creditos[0].numeroCredito);
      this.setState({
        creditoSeleccionado,
        loadingCreditoSeleccionado: false,
      });
      changeSelectedLoanToPay(creditoSeleccionado);
    }

    // Llamado a google analitycs en caso de tener dividendos pendientes
    const cuotaExiste = creditoSeleccionado?.cuota?.proximaCuota;
    if (cuotaExiste !== undefined) {
      //console.log('analitics cuota pendiente');
      registerGA({
        event: 'vpv-privado',
        page: '/tef/mis-productos/credito-hipotecario/informacion-credito',
      });
    } else {
      //console.log('sin cuota');
    }

    // Analitics Vencimiento 5 dias
    const vencimiento = creditoSeleccionado?.cuota?.proximaCuota?.diasVencimiento;
    // console.log(vencimiento);
    if (vencimiento !== undefined && vencimiento > 0 && vencimiento <= 5) {
      //console.log('analitics diasVencimiento');
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/mis-productos/credito-hipotecario/informacion-credito',
        'eve-cat': 'Advertencia',
        'eve-lab': 'Vence en 5 días',
      });
    } else {
      // console.log('sin Vencimiento');
    }
  };

  handleChangeSelect = (e) => {
    const { changeSelectedLoanToPay } = this.props;

    const numeroCreditoSeleccionado = parseInt(e.target.value, 10);
    const creditoSeleccionado = this.setCreditoSeleccionado(numeroCreditoSeleccionado);
    this.setState({
      creditoSeleccionado,
    });
    changeSelectedLoanToPay(creditoSeleccionado);
  };

  handleClickPagoOnline = () => {
    window.open('https://pago-online.coopeuch.cl/', '_blank');

    // Registro analitycs clic pago
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/credito-hipotecario/informacion-credito',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a pago online',
    });
  };

  setCreditoSeleccionado = (numeroCredito) => {
    const { resultadoCuota, creditos } = this.props;
    const cuotaSeleccionada = resultadoCuota.find(
      (cuota) => parseInt(cuota.nroCredito, 10) === numeroCredito
    );

    const creditoSeleccionado = creditos.find((credito) => credito.value === numeroCredito);

    return { ...creditoSeleccionado, cuota: { ...cuotaSeleccionada } };
  };

  render() {
    const { creditoSeleccionado, loadingCreditoSeleccionado } = this.state;
    const {
      skeleton,
      creditos,
      ventanaHoraria,
      goToPayment,
      loanToPay: { paymentAllowed, automaticPaymentMethod },
      availableAccounts: { error, loading: loadingAvailableAccounts },
    } = this.props;

    // Si el socio no posee cuentas vistas que cumplan con los criterios para pagar con ellas,
    // se debe redirigir al pago online. Se consideran los diversos escenarios para su validación.
    const referToOnlinePayment = error.server || error.noData;

    const loading = skeleton || loadingAvailableAccounts;

    return (
      <DetalleCreditoTemplate
        {...{
          skeleton: loading,
          creditos,
          ventanaHoraria,
          loadingCreditoSeleccionado,
          creditoSeleccionado,

          handleChangeSelect: this.handleChangeSelect,
          handleClickPago: goToPayment,
          handleClickPagoOnline: this.handleClickPagoOnline,
          referToOnlinePayment,
          paymentAllowed,
          automaticPaymentMethod,
        }}
      />
    );
  }
}
const mapStateToProps = (store) => ({
  resultadoCuota: store.entities.creditoHipotecario.cuota.data,
  availableAccounts: store.entities.creditoHipotecario.availableAccounts,
  loanToPay: store.entities.creditoHipotecario.pay.loanToPay,
  ventanaHoraria: store.entities.creditoHipotecario.ventanaHoraria.data,
});

const mapDispatchToProps = (dispatch) => ({
  changeSelectedLoanToPay: (loan) => {
    dispatch(creditoHipotecarioActions.changeSelectedLoanToPay(loan));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
