import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getYear } from 'date-fns';
import { remanenteActions } from '../../../../application/actions/remanente';
import BannerEstacional from '../../../components/templates/Dashboard/BannerEstacional';

class Index extends Component {
  componentDidMount = () => {
    const { fetchBannerEstacional, fetchRemanente } = this.props;
    fetchBannerEstacional();
    fetchRemanente(getYear(new Date()));
  };

  render() {
    const { bannerEstacional, saldoIndividual } = this.props;
    return <BannerEstacional {...{ bannerEstacional, saldoIndividual }} />;
  }
}
const mapStateToProps = ({ entities }) => ({
  bannerEstacional: entities.remanente.bannerEstacional,
  saldoIndividual: entities.remanente.saldoIndividual,
});
const mapDispatchToProps = (dispatch) => ({
  fetchRemanente(props) {
    dispatch(remanenteActions.fetchRemanente(props));
  },
  fetchBannerEstacional(props) {
    dispatch(remanenteActions.fetchBannerEstacional(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
