import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CajaInformacion = (props) => {
  const { title, items } = props;

  return (
    <div className="col-sm-12 col-md-12 col-lg-12">
      <div className="ml-32 mr-32 h-100 margin--mobile-informacion">
        <div className="info--wrapper--crear-clave h-100 pl-54 pr-54 pt-16">
          <Typography tag="p" fontSize={16} className="black text-bold">
            {title}
          </Typography>

          <div className="pl-12 pt-12 pb-12"> 
            <ul className="listaCajaInformacion">
              {items?.map((text, index) => (
                <li key={index} className="pb-8">
                  <img className='float-left cajaInformacionImagen'
                    src={`${iconPath}check_success.svg`}
                  />
                  <Typography tag="p" fontSize={14} className="black">
                    {text}
                  </Typography>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

CajaInformacion.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
};

CajaInformacion.defaultProps = {
  title: '',
  items: [],
};

export default CajaInformacion;
