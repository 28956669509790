import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Cuenta from '../Cuenta';
import Contratacion from '../Contratacion';
import Comprobante from '../Comprobante';

import WrapperTemplate from '../../../../components/templates/CuentaAhorro/SolicitudCAFlujo/Wrapper';

const components = {
  1: Cuenta,
  2: Contratacion,
  3: Comprobante,
};

const StepperRouter = (props) => {
  const { pathStep } = props;

  const Component = useMemo(() => components[pathStep]);
  return (
    <WrapperTemplate position={pathStep}>
      <Component {...props} />
    </WrapperTemplate>
  );
};

StepperRouter.propTypes = {
  pathStep: PropTypes.oneOf([1]).isRequired,
  ...Cuenta.propTypes,
};

StepperRouter.defaultProps = {
  ...Cuenta.defaultProps,
};

export default StepperRouter;
