const initState = {
  trackId: '',
  errorValidarRut: '',
  factoresSeguridad: [],
  errorFactoresSeguridad: false,
  reglasFactores: [],
  errorReglasFactores: false,
  envioDesafioOTP: '',
  datosPersonales: '',
  errorDatosPersonales: {},
  errorDatosSinTelefonoIBS: false,
  desafioOTPValido: false,
  smsErrors: {},
  reglaClaves: [],
  errorReglaClaves: '',
  successCrearClave: false,
  errorCrearClave: false,
  errorClaveMessage: '',
  successSelectMetodo: '',
  desafioTCOOValido: false,
  desafio1: '',
  desafio2: '',
  desafio3: '',
  tarjetaCoordenadasErrors: {},
  passCoopeuchErrors: {},
  obtencionFactorCPASS: false,
  desafioCPASSValido: false,
  redirectOrigin: { data: null, error: null },
};

export default initState;
