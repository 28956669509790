const initState = {
  destinatarios: [],
  errorDestinatarios: false,
  errorDestinatariosLog: null,
  errorBorrarDestinatarios: false,
  tiposCuentas: [],
  errorTipoCuentas: '',
  bancos: [],
  errorBancos: '',
  errorCrearOEditarDestinatario: '',
  destinatarioMessageError: {},
  origenSeleccionado: {},
  destinoSeleccionado: {},
  valueDestinoSeleccionado: '',
  reglasTerceros: [],
  cantidadTransferenciasTerceros: 0,
  montoAcumuladoTransferenciasTerceros: 0,
  isNuevo: false,
  permiteTransferir: false,
  errorReglasTerceros: false,
  errorReglasTercerosLog: null,
  montoATransferirTerceros: 0,
  comentarioATransferirTerceros: '',
  trackTransferencia: '',
  idTransferencia: '',
  errorValidarTransferencia: false,
  errorValidarTransferenciaLog: null,
  errorEjecutarTransferencia: false,
  errorEjecutarTransferenciaLog: null,
  detalleComprobanteTerceros: {},
  bancosBloqueados: [],
  updateDestinatario: { data: null, error: null },
  isFTUOpen: { data: null, error: null },
  descargaComprobanteTEF: { 
    success: false,
    error: false,
    errorCode: null,
    data: null,
    code: null,
  },
};

export default initState;
