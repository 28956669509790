import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DashboardCard from '../../../../components/organism/DashboardCard';
import { SkeletonCard } from '../Skeleton';
import { agregarformatoPesos } from '../../../../utils/formatters';
import { Swiper, SwiperSlide } from 'swiper/react';
import ErrorDashboard from '../ErrorDashboard';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

// import Swiper core and required modules
import SwiperCore, { Pagination, Navigation } from 'swiper';

import Wrapper from './styles';

import { cuentasTexts } from '../../../../containers/Dashboard/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const Index = ({
  cuentas,
  codigos,
  isLoading,
  errorCuentasVistas,
  handleClickTransferir,
  handleClickTarjeta,
  handleChangeSlide,
}) => {
  return (
    <Wrapper loading={isLoading}>
      {isLoading ? (
        <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
          <div className="cv-swiper">
            <DashboardCard classes="m-0 cv-card-container">
              <DashboardCard.Body classes="cv-container">
                <SkeletonCard short />
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        </div>
      ) : (
        <Fragment>
          {errorCuentasVistas ? (
            <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
              <ErrorDashboard descText={cuentasTexts.ERROR_DESC} />
            </div>
          ) : (
            <div>
              {cuentas.length >= 1 && (
                <div className="bmb-3 bmb-md-4 bml-md-1 bml-0 bmr-md-1 bmr-0">
                  <Swiper
                    pagination={cuentas.length > 1}
                    navigation={cuentas.length > 1}
                    className="cv-swiper"
                    onSlideChange={handleChangeSlide}
                  >
                    {cuentas.map((item, index) => (
                      <SwiperSlide key={index}>
                        <DashboardCard classes="m-0 cv-card-container">
                          <DashboardCard.Body classes="cv-container">
                            <div
                              className="row cursor-pointer"
                              onClick={() => handleClickTarjeta(item)}
                            >
                              <div className="col-md-1 col-xs-3 cv-icon-container bpl-0 bpl-md-2">
                                {item.codigoProducto === 'VI06' ? (
                                  <LoadIcon
                                    src={`${iconPath}Monedero_Digital.svg`}
                                    color="#000"
                                    className="float-left bmr-3 cv-icon-monedero"
                                  />
                                ) : (
                                  <LoadIcon
                                    src={`${iconPath}cv_b.svg`}
                                    color="#000"
                                    className="float-left bmr-3 cv-icon"
                                  />
                                )}
                              </div>
                              <div className="col-md-11 col-xs-9 bpl-md-5 bpl-0">
                                <div className="bml-0 bml-md-2 bmb-1">
                                  <label className="cv-title cursor-pointer">
                                    {codigos(item.codigoProducto).title}
                                  </label>
                                </div>
                                <div className="bml-0 bml-md-2">
                                  <label className="cursor-pointer">
                                    {cuentasTexts.NUMERICO} {item.numeroCuenta}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 bpl-0 bpl-md-2">
                                <div className="bmb-2">
                                  <label className="cv-text cursor-pointer">
                                    {cuentasTexts.SALDO_DISPONIBLE}
                                  </label>
                                </div>
                                <div>
                                  <label className="cv-value-fecha cursor-pointer">
                                    {agregarformatoPesos(item.saldoDisponible)}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 col-xs-12 bpt-md-3 bpt-3 bmb-3 bmb-md-0 bpl-0 bpl-md-2">
                                <div className="cv-margin-text cv-text-right">
                                  <label className="cv-text cursor-pointer">
                                    {cuentasTexts.SALDO_CONTABLE}{' '}
                                    <b>{agregarformatoPesos(item.saldoContable)}</b>
                                  </label>
                                </div>
                                <div className="cv-text-right">
                                  <label className="cv-text cursor-pointer">
                                    {cuentasTexts.RETENCIONES}{' '}
                                    <b>{agregarformatoPesos(item.retencion)}</b>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </DashboardCard.Body>
                        </DashboardCard>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    </Wrapper>
  );
};

Index.propTypes = {
  cuentas: PropTypes.array,
  codigos: PropTypes.func,
  handleClickTransferir: PropTypes.func,
};

Index.defaultProps = {
  cuentas: [],
  codigos: null,
  handleClickTransferir: null,
};

export default Index;
