import { put, takeLatest, call, spawn, all, delay } from 'redux-saga/effects';

import { factoresSeguridadActions } from '../../../application/actions/factoresSeguridad';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';
import { logReqError } from '../../../ui/utils/functions';
import { registerGA } from '../../../ui/utils/metrics';
import defaultRequest, {
  errPassCoopeuchFactor,
  errTarjetaCoordenadasFactor,
  errSMSFactor,
} from './errors';
import logger from '../../../ui/utils/logger';

const apiFactoresSeguridad = api(process.env.REACT_APP_FACTORES_SEGURIDAD);

export function* fetchFactoresSeguridad() {
  try {
    yield put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: true }));

    const { data, error } = yield call(apiFactoresSeguridad.post, 'factor/v1/persona/obtener', {});

    if (data) {
      yield put(
        factoresSeguridadActions.fetchFactoresSeguridadSuccess({
          factoresSeguridad: data,
        })
      );
    } else {
      yield put(
        factoresSeguridadActions.fetchFactoresSeguridadFailed({
          error: defaultRequest,
          errLog: error,
        })
      );
    }
    yield put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: false }));
  } catch (err) {
    yield all([
      put(
        factoresSeguridadActions.fetchFactoresSeguridadFailed({
          error: defaultRequest,
          errLog: err,
        })
      ),
      put(loadersActions.changeStatusLoadingFactoresSeguridad({ isLoading: false })),
    ]);
  }
}

export function* fetchDesafioFactorPassCoopeuch(action) {
  try {
    const { params, track } = action.payload;

    yield put(
      factoresSeguridadActions.fetchDesafioFactorPassCoopeuchResponse({
        obtencionFactorCoopeuchPass: false,
      })
    );

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const optionalsHeaders = { track: track };

    const { data, error } = yield call(
      apiFactoresSeguridad.post,
      'web/desafio/passcoopeuch/v1/obtener',
      params,
      optionalsHeaders
    );

    yield logReqError(error);

    if (data) {
      yield put(
        factoresSeguridadActions.fetchDesafioFactorPassCoopeuchResponse({
          obtencionFactorCoopeuchPass: data,
        })
      );
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    } else {
      yield put(
        factoresSeguridadActions.validarUsingFactorFailed({
          error: errPassCoopeuchFactor[Number(error.code)] || defaultRequest,
          type: 'passCoopeuchErrors',
          errorFactorLog: error,
        })
      );
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
  } catch (err) {
    console.log(err);
    yield put(
      factoresSeguridadActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'passCoopeuchErrors',
        errorFactorLog: err,
      })
    );
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  }
}

export function* fetchDesafioTarjetaCoordenadas(action) {
  try {
    const { track } = action.payload;
    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const { data, error } = yield call(
      apiFactoresSeguridad.get,
      'factor/desafio/v1/coordenada/obtener',
      null,
      optionalsHeaders
    );

    if (data) {
      yield put(factoresSeguridadActions.fetchDesafioTarjetaCoordenadasResponse({ ...data }));
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    } else {
      yield put(
        factoresSeguridadActions.validarUsingFactorFailed({
          error: errTarjetaCoordenadasFactor[Number(error.code)] || defaultRequest,
          type: 'tarjetaCoordenadasErrors',
          errorFactorLog: error,
        })
      );

      const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
      const errorSMS = {
        error: true,
        code: errTarjetaCoordenadasFactor[Number(error.code)] || defaultRequest,
        message: errTarjetaCoordenadasFactor[Number(error.code)].message
      }
      logger(errorSMS, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'TEF_TERCEROS_SMS_REINTENTO_TIEMPO',
        informacion_adicional: {},
      });
      yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
    }
  } catch (err) {
    yield all([
      put(
        factoresSeguridadActions.validarUsingFactorFailed({
          error: defaultRequest,
          type: 'tarjetaCoordenadasErrors',
          errorFactorLog: err,
        })
      ),
      put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false })),
    ]);
  }
}

export function* validarDesafioTarjetaCoordenadas(action) {
  try {
    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: true }));

    const { desafio, track, history, params } = action.payload;

    const optionalsHeaders = { track: track };

    const { data, error } = yield call(
      apiFactoresSeguridad.post,
      'factor/desafio/v1/coordenada/validar',
      desafio,
      optionalsHeaders
    );

    if (data) {
      yield put(
        factoresSeguridadActions.validarDesafioTarjetaCoordenadasResponse({
          validacionFactorDeSeguridad: data,
        })
      );

      if (history) {
        history.push(params);
      }
    } else {
      yield put(
        factoresSeguridadActions.validarUsingFactorFailed({
          type: 'tarjetaCoordenadasErrors',
          error: errTarjetaCoordenadasFactor[Number(error.code)] || defaultRequest,
          errorFactorLog: error,
        })
      );
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/transferencias/fondos/paso2',
        'eve-cat': 'Error',
        'eve-lab':
          errTarjetaCoordenadasFactor[Number(error.code)].message || defaultRequest.message,
      });
    }

    yield put(loadersActions.changeStatusLoadingFactorSeguridad({ isLoading: false }));
  } catch (err) {
    yield put(
      factoresSeguridadActions.validarUsingFactorFailed({
        type: 'tarjetaCoordenadasErrors',
        error: defaultRequest,
        errorFactorLog: err,
      })
    );
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/transferencias/fondos/paso2',
      'eve-cat': 'Error',
      'eve-lab': defaultRequest.message,
    });
  }
}

export function* validarDesafioCoopeuchPass(action) {
  try {
    const { params, track, tiempo } = action.payload;

    const optionalsHeaders = { track: track };

    const tiempoActivo = tiempo || 120;

    var tiempoLimite = tiempoActivo - (tiempoActivo * 22) / 100;
    var tiempoTranscurrido = 0;

    while (tiempoTranscurrido < tiempoLimite) {
      const { data, error } = yield call(
        apiFactoresSeguridad.post,
        'web/desafio/passcoopeuch/v1/validar',
        params,
        optionalsHeaders
      );

      if (data) {
        if (data.codigo !== 25) {
          if (data.codigo === 0) {
            yield put(
              factoresSeguridadActions.validarDesafioCoopeuchPassResponse({
                validacionFactorDeSeguridad: true,
              })
            );
            tiempoTranscurrido = tiempoLimite;
          } else {
            yield put(
              factoresSeguridadActions.validarUsingFactorFailed({
                type: 'passCoopeuchErrors',
                error: errPassCoopeuchFactor[Number(data.codigo)] || defaultRequest,
              })
            );
            registerGA({
              event: 'eve',
              'eve-acc': '/tef/transferencias/fondos/paso2',
              'eve-cat': 'Error',
              'eve-lab':
                errPassCoopeuchFactor[Number(data.codigo)].message || defaultRequest.message,
            });
            tiempoTranscurrido = tiempoLimite;
          }
        } else {
          tiempoTranscurrido = tiempoTranscurrido + 3;
          yield delay(3000);
        }
      } else {
        yield put(
          factoresSeguridadActions.validarUsingFactorFailed({
            type: 'passCoopeuchErrors',
            error: errPassCoopeuchFactor[Number(error.code)] || defaultRequest,
            errorFactorLog: error,
          })
        );
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/transferencias/fondos/paso2',
          'eve-cat': 'Error',
          'eve-lab': errPassCoopeuchFactor[Number(error.code)].message || defaultRequest.message,
        });
        tiempoTranscurrido = tiempoLimite;
      }
    }
  } catch (err) {
    console.log(err);
    yield put(
      factoresSeguridadActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'passCoopeuchErrors',
        errorFactorLog: err,
      })
    );
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/transferencias/fondos/paso2',
      'eve-cat': 'Error',
      'eve-lab': defaultRequest.message,
    });
  }
}

export function* fetchDesafioSMS(action) {
  try {
    yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: true }));

    const { track } = action.payload;

    const optionalsHeaders = { track: track };

    const { data } = yield call(
      apiFactoresSeguridad.get,
      'factor/v1/desafio/otp/obtener',
      null,
      optionalsHeaders
    );

    if (data) {
      if (data.enviado) {
        yield put(factoresSeguridadActions.fetchDesafioSMSResponse());
        yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
      } else {
        yield put(
          factoresSeguridadActions.validarUsingFactorFailed({
            error: errSMSFactor[2],
            type: 'smsErrors',
          })
        );
        yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
      }
    } else {
      yield put(
        factoresSeguridadActions.validarUsingFactorFailed({
          error: errSMSFactor[2],
          type: 'smsErrors',
        })
      );
      yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
    }
  } catch (err) {
    console.log(err);
    yield put(
      factoresSeguridadActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'smsErrors',
      })
    );
    yield put(loadersActions.changeStatusLoadingDesafioSMS({ isLoading: false }));
  }
}

export function* validarDesafioSMS(action) {
  try {
    const { params, track } = action.payload;

    const optionalsHeaders = { track: track };

    yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: true }));

    const { data } = yield call(
      apiFactoresSeguridad.post,
      'factor/v1/desafio/otp/validar',
      params,
      optionalsHeaders
    );

    const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
    if (data) {
      if (data.valido) {
        yield put(
          factoresSeguridadActions.validarDesafioSMSResponse({
            validacionFactorDeSeguridadSMS: data.valido,
          })
        );
        yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
      } else {
        if (data.bloqueado) {
          yield put(
            factoresSeguridadActions.validarUsingFactorFailed({
              error: errSMSFactor[2],
              type: 'smsErrors',
            })
          );
          registerGA({
            event: 'eve',
            'eve-acc': '/tef/transferencias/fondos/paso2-SMS',
            'eve-cat': 'Error',
            'eve-lab': errSMSFactor[2].message,
          });
          const errorSMS = {
            error: true,
            code: '504',
            message: errSMSFactor[2].message
          }
          logger(errorSMS, {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: 'TEF_TERCEROS_SMS_AUTORIZAR',
            informacion_adicional: {},
          });
          yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
        } else {
          if (data.expirado) {
            yield put(
              factoresSeguridadActions.validarUsingFactorFailed({
                error: errSMSFactor[3],
                type: 'smsErrors',
              })
            );
            registerGA({
              event: 'eve',
              'eve-acc': '/tef/transferencias/fondos/paso2-SMS',
              'eve-cat': 'Error',
              'eve-lab': errSMSFactor[3].message,
            });

            const errorSMS = {
              error: true,
              code: '504',
              message: errSMSFactor[3].message
            }
            logger(errorSMS, {
              rut: baseRut.slice(0, -1),
              digito_verificador: baseRut.slice(-1),
              accion: 'TEF_TERCEROS_SMS_AUTORIZAR',
              informacion_adicional: {},
            });
            yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
          } else {
            const errorSMS = {
              error: true,
              code: '504',
              message: errSMSFactor[1].message
            }
            yield put(
              factoresSeguridadActions.validarUsingFactorFailed({
                error: errSMSFactor[1],
                type: 'smsErrors',
              })
            );
            registerGA({
              event: 'eve',
              'eve-acc': '/tef/transferencias/fondos/paso2-SMS',
              'eve-cat': 'Error',
              'eve-lab': errSMSFactor[1].message,
            });
            logger(errorSMS, {
              rut: baseRut.slice(0, -1),
              digito_verificador: baseRut.slice(-1),
              accion: 'TEF_TERCEROS_SMS_AUTORIZAR',
              informacion_adicional: {},
            });
            yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
          }
        }
      }
    } else {
      yield put(
        factoresSeguridadActions.validarUsingFactorFailed({
          error: defaultRequest,
          type: 'smsErrors',
        })
      );
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/transferencias/fondos/paso2-SMS',
        'eve-cat': 'Error',
        'eve-lab': defaultRequest.message,
      });
      yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
    }
  } catch (err) {
    console.log(err);
    yield put(
      factoresSeguridadActions.validarUsingFactorFailed({
        error: defaultRequest,
        type: 'smsErrors',
      })
    );
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/transferencias/fondos/paso2-SMS',
      'eve-cat': 'Error',
      'eve-lab': defaultRequest.message,
    });
    yield put(loadersActions.changeStatusLoadingValidarSMS({ isLoading: false }));
  }
}

export function* fetchReglasFactoresSeguridad() {
  try {
    yield put(loadersActions.fetchLoadingReglasFactoresSeguridad({ isLoading: true }));
    const { data, error } = yield call(
      apiFactoresSeguridad.get,
      'factor/v1/desafio/configuracion/obtener'
    );
    if (data) {
      yield put(
        factoresSeguridadActions.fetchReglasFactoresSeguridadResponse({
          reglasFactoresSeguridad: data,
          errorLog: '',
        })
      );
    } else {
      yield put(
        factoresSeguridadActions.fetchReglasFactoresSeguridadResponse({
          reglasFactoresSeguridad: [],
          errorLog: error,
        })
      );
    }
    yield put(loadersActions.fetchLoadingReglasFactoresSeguridad({ isLoading: false }));
  } catch (err) {
    yield put(
      factoresSeguridadActions.fetchReglasFactoresSeguridadResponse({
        reglasFactoresSeguridad: [],
        errorLog: err,
      })
    );
    yield put(loadersActions.fetchLoadingReglasFactoresSeguridad({ isLoading: false }));
  }
}

export function* watchFetchFactoresSeguridad() {
  yield takeLatest(factoresSeguridadActions.fetchFactoresSeguridad.type, fetchFactoresSeguridad);
}

export function* watchFetchDesafioFactorPassCoopeuch() {
  yield takeLatest(
    factoresSeguridadActions.fetchDesafioFactorPassCoopeuch.type,
    fetchDesafioFactorPassCoopeuch
  );
}

export function* watchFetchDesafioTarjetaCoordenadas() {
  yield takeLatest(
    factoresSeguridadActions.fetchDesafioTarjetaCoordenadas.type,
    fetchDesafioTarjetaCoordenadas
  );
}

export function* watchValidarDesafioTarjetaCoordenadas() {
  yield takeLatest(
    factoresSeguridadActions.validarDesafioTarjetaCoordenadas.type,
    validarDesafioTarjetaCoordenadas
  );
}

export function* watchValidarDesafioCoopeuchPass() {
  yield takeLatest(
    factoresSeguridadActions.validarDesafioCoopeuchPass.type,
    validarDesafioCoopeuchPass
  );
}

export function* watchFetchDesafioSMS() {
  yield takeLatest(factoresSeguridadActions.fetchDesafioSMS.type, fetchDesafioSMS);
}

export function* watchValidarDesafioSMS() {
  yield takeLatest(factoresSeguridadActions.validarDesafioSMS.type, validarDesafioSMS);
}

export function* watchFetchReglasFactoresSeguridad() {
  yield takeLatest(
    factoresSeguridadActions.fetchReglasFactoresSeguridad.type,
    fetchReglasFactoresSeguridad
  );
}

export default function* rootSaga() {
  yield spawn(watchFetchFactoresSeguridad);
  yield spawn(watchFetchDesafioFactorPassCoopeuch);
  yield spawn(watchFetchDesafioTarjetaCoordenadas);
  yield spawn(watchValidarDesafioTarjetaCoordenadas);
  yield spawn(watchValidarDesafioCoopeuchPass);
  yield spawn(watchFetchDesafioSMS);
  yield spawn(watchValidarDesafioSMS);
  yield spawn(watchFetchReglasFactoresSeguridad);
}
