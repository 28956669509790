import React, { useState } from 'react';
import DashboardCard from '../../../components/organism/DashboardCard';
import FactorClaveInternet from './ClaveInternet';

const Index = ({thirdStep, setValidateAuth, setClave, clave}) => {
  const opacidad = thirdStep ? 1 : 0.5
  const [showClave, setShowClave] = useState(false)
  const handleChangeInput = (text) => {
    if(text === ""){
        setValidateAuth(true)
    }else{
        setValidateAuth(false)
    }
    setClave(text)
  }
  return (
    <>
      <DashboardCard className='widthSection'>
        <div style={{ opacity: opacidad }}>

          <DashboardCard.Head
            title={<span className='txtTitle-CP'>Autorización</span>}
            borderBottom="complete"
            classes="btext-xs-left bmt-xs-3 bmt-sm-4"
          />
          <DashboardCard.Body>
            <div className="row" style={{marginTop: 15}}>
              <div className="col-md-12 col-xs-12 bmt-3 bmt-md-0">
                <div className="" style={{textAlign: 'left', marginBottom: 30}}>
                  <p style={{fontSize: 14, marginTop: 4, color: '#383838'}}>Al ingresar tu clave internet, estarás firmando electrónicamente de acuerdo a la ley 19.799.</p>
                </div>
                <div className='col-sm-4'>
                  <label style={{ textAlign: 'left',fontSize: 14, marginBottom: '0.5rem', display: 'block', fontWeight: 700 }}>Clave de internet Coopeuch</label>
                  <FactorClaveInternet
                    onTogglePassword={() => setShowClave(!showClave)}
                    showClave={showClave}
                    value={clave}
                    disabled={!thirdStep}
                    onHandlePasswordInput={(text) => handleChangeInput(text.target.value)}
                    placeholder="Ingresar clave de internet"
                    error={false}
                  />
                </div>

              </div>
            </div>
      
          </DashboardCard.Body>
        </div>
      </DashboardCard>
    </>
  );
};

Index.displayName = 'Container_CDP_AUTORIZA';
export default Index;