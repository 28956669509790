import styled from 'styled-components';

export const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    padding-bottom: 36px;
  }
`;

export const CheckBoxText = styled.div`
  font-size: 14px;
  color: #383638;
  font-weight: bold;


  @media (max-width: 768px) {
    position: absolute;
    margin-top: 10px;
    left: 35px;
    top: 0;
    width: 90%;
    text-align: left; 
    line-height: 2;
    font-size: 12px;
  }

`;
