import { put, takeLatest, call, spawn } from 'redux-saga/effects';
// actions
import { ftuActions } from '../../../application/actions/ftu';
// api
import api from '../../api';

const apiFtu = api(process.env.REACT_APP_CONFIGURATION_API);
export function* fetchFtuEnviar(action) {
  try {
    const { params } = action.payload;
    const { data, error } = yield call(apiFtu.post, 'firsttimeuse/v1/crear', params);
    if (data) {
      yield put(ftuActions.fetchFtuObtenerSuccess(false));
    }
  } catch (err) {
    yield put(ftuActions.fetchFtuObtenerError({ error: '' }));
  }
}
export function* fetchFtuObtener() {
  try {
    const { data, error } = yield call(apiFtu.get, 'firsttimeuse/v1/consultar');

    if (error || data == null || data == undefined || data == '') {
      yield put(ftuActions.fetchFtuObtenerError());
    } else {
      yield put(ftuActions.fetchFtuObtenerSuccess(data));
    }
  } catch (err) {
    yield put(ftuActions.fetchFtuObtenerError());
  }
}
export function* watchFetchFtuEnviar() {
  yield takeLatest(ftuActions.fetchFtuEnviar.type, fetchFtuEnviar);
}
export function* watchFetchFtuObtener() {
  yield takeLatest(ftuActions.fetchFtuObtener.type, fetchFtuObtener);
}
export default function* rootSaga() {
  yield spawn(watchFetchFtuEnviar);
  yield spawn(watchFetchFtuObtener);
}
