import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { addClass } from '@coopeuch-components/web';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Input from '../../../atoms/Input';
import Countdown from '../Utils/Countdown';
import CountdownCard from '../Utils/CountdownCard';

import InputContainer, { ButtonContainer } from './styles';

const pasosFactor = {
  0: 'inicio',
  1: 'ingresar',
  2: 'validar',
};

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const FactorTarjetaDeCoordenadas = (props) => {
  const {
    onClickButton,
    onChangeInput,
    message,
    title,
    buttonText,
    withCountdownAnimation,
    stepFactor,
    timer,
    activeTimer,
    hasError,
    titleClass,
    showInputInError,
    valorDesafio1,
    valorDesafio2,
    valorDesafio3,
    valorDesafio4,
    valorDesafio5,
    valorDesafio6,
    hideButton,
    isButtonDisabled,
  } = props;

  let refSMS1 = useRef();
  let refSMS2 = useRef();
  let refSMS3 = useRef();
  let refSMS4 = useRef();
  let refSMS5 = useRef();
  let refSMS6 = useRef();

  function handleChange(event) {
    const { id, value } = event.target;

    if (id === 'sms1') {
      if (value.length === 1) {
        refSMS2.current.focus();
      }
    }

    if (id === 'sms2') {
      if (value.length === 1) {
        refSMS3.current.focus();
      }
    }

    if (id === 'sms3') {
      if (value.length === 1) {
        refSMS4.current.focus();
      }
    }

    if (id === 'sms4') {
      if (value.length === 1) {
        refSMS5.current.focus();
      }
    }

    if (id === 'sms5') {
      if (value.length === 1) {
        refSMS6.current.focus();
      }
    }

    onChangeInput(event);
  }

  return (
    <div className="row">
      <div className="col-md-2" />
      <div className="col-md-8 col-sm-8 col-xs-12">
        {hasError ? (
          <div className="row center-xs">
            <LoadIcon src={`${iconPath}ilustracion_error.svg`} size={175} />
          </div>
        ) : (
          <Countdown
            ilustration="sms"
            withCountdownAnimation={withCountdownAnimation}
            timer={timer}
          />
        )}

        {title && <p className={addClass([titleClass, 'text-size-l text-bold'])}>{title}</p>}
        <div
          className={addClass([!title && 'bmt-3', 'bmb-3 text-size-m'])}
          dangerouslySetInnerHTML={{ __html: `<div>${message}</div>` }}
        />

        {(pasosFactor[stepFactor] === 'inicio' ||
          pasosFactor[stepFactor] === 'validar' ||
          pasosFactor[stepFactor] === 'ingresar') &&
          showInputInError && (
            <InputContainer>
              <div className="row">
                <div className="col-md-2 col-xs-2 sms-input-padding">
                  <div>
                    <Input
                      id="sms1"
                      dataSet="1"
                      borderColor={
                        hasError ? 'red' : valorDesafio1?.length === 1 ? '#19a1d5 ' : '#646569'
                      }
                      maxLength={1}
                      className="factor-sms-input"
                      value={valorDesafio1}
                      onChange={handleChange}
                      ref={refSMS1}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-2 sms-input-padding">
                  <div>
                    <Input
                      id="sms2"
                      dataSet="2"
                      maxLength={1}
                      className="factor-sms-input"
                      borderColor={
                        hasError ? 'red' : valorDesafio2?.length === 1 ? '#19a1d5 ' : '#646569'
                      }
                      value={valorDesafio2}
                      onChange={handleChange}
                      ref={refSMS2}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-2 sms-input-padding">
                  <div>
                    <Input
                      id="sms3"
                      dataSet="3"
                      maxLength={1}
                      className="factor-sms-input"
                      borderColor={
                        hasError ? 'red' : valorDesafio3?.length === 1 ? '#19a1d5 ' : '#646569'
                      }
                      value={valorDesafio3}
                      onChange={handleChange}
                      ref={refSMS3}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-2 sms-input-padding">
                  <div>
                    <Input
                      id="sms4"
                      dataSet="4"
                      maxLength={1}
                      className="factor-sms-input"
                      borderColor={
                        hasError ? 'red' : valorDesafio3?.length === 1 ? '#19a1d5 ' : '#646569'
                      }
                      value={valorDesafio4}
                      onChange={handleChange}
                      ref={refSMS4}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-2 sms-input-padding">
                  <div>
                    <Input
                      id="sms5"
                      dataSet="5"
                      maxLength={1}
                      className="factor-sms-input"
                      borderColor={
                        hasError ? 'red' : valorDesafio3?.length === 1 ? '#19a1d5 ' : '#646569'
                      }
                      value={valorDesafio5}
                      onChange={handleChange}
                      ref={refSMS5}
                    />
                  </div>
                </div>
                <div className="col-md-2 col-xs-2 sms-input-padding">
                  <div>
                    <Input
                      id="sms6"
                      dataSet="6"
                      maxLength={1}
                      className="factor-sms-input"
                      borderColor={
                        hasError ? 'red' : valorDesafio3?.length === 1 ? '#19a1d5 ' : '#646569'
                      }
                      value={valorDesafio6}
                      onChange={handleChange}
                      ref={refSMS6}
                    />
                  </div>
                </div>
              </div>
            </InputContainer>
          )}
        {pasosFactor[stepFactor] === 'ingresar' && !hasError && (
          <CountdownCard timer={timer} active={activeTimer} />
        )}
        {(pasosFactor[stepFactor] === 'inicio' ||
          pasosFactor[stepFactor] === 'validar' ||
          hasError) &&
          !hideButton && (
            <ButtonContainer>
              <Button
                rounded={5}
                bgColor="#e81d2b"
                color="white"
                onClick={onClickButton}
                disabled={
                  isButtonDisabled || (pasosFactor[stepFactor] === 'inicio' && !hasError)
                    ? true
                    : false
                }
                width={296}
                shadow
                height={48}
                className="factor-button-style"
              >
                {buttonText}
              </Button>
            </ButtonContainer>
          )}
      </div>
      <div className="col-md-2" />
    </div>
  );
};

FactorTarjetaDeCoordenadas.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  onChangeInput: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  withCountdownAnimation: PropTypes.bool,
  stepFactor: PropTypes.number,
  inputs: PropTypes.object,
  timer: PropTypes.number,
  activeTimer: PropTypes.bool,
  hasError: PropTypes.bool,
  titleClass: PropTypes.string,
  title: PropTypes.string,
};

FactorTarjetaDeCoordenadas.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  onChangeInput: () => {},
  message: '',
  buttonText: '',
  withCountdownAnimation: false,
  stepFactor: 0,
  inputs: {},
  timer: 0,
  activeTimer: false,
  hasError: false,
  titleClass: '',
  title: '',
};

export default FactorTarjetaDeCoordenadas;
