import React from 'react';
import Web from './Web';
import Mobile from './Mobile';
import Typography from '@coopeuch-components/web/atoms/Typography';

const TableHistorial = ({ dataTable }) => {
  return (
    <div className="row ml-0 mr-0">
      <div className="col-xs-12 hide-on-mobile">
        <div className="mb-14 black">
          <div className="row between-xs">
            <div className="text-bold col-md-4">
              <Typography tag="p" fontWeight={700}>
                Fecha de pago
              </Typography>
            </div>
            <div className="text-bold col-md-4">
              <Typography tag="p" fontWeight={700}>
                Descripción
              </Typography>
            </div>
            <div className="text-bold col-md-2">
              <Typography tag="p" fontWeight={700}>
                Nº de cuotas
              </Typography>
            </div>
            <div className="text-bold pr-0 col-md-1">
              <Typography tag="p" fontWeight={700}>
                Valor
              </Typography>
            </div>
            <div className="text-bold pr-0 col-md-1">
              <Typography tag="p" fontWeight={700}>
                Monto
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100">
        <div className="col-xs-12 hide-on-mobile">
          <div className="pt-16 pb-16">
            <div className="black">
              <Web data={dataTable} />
            </div>
          </div>
        </div>
        <div className="col-xs-12 pb-16 hide-on-desktop pt-16">
          <Mobile data={dataTable} />
        </div>
      </div>
    </div>
  );
};

export default TableHistorial;
