// obtener cookie
function getCookie(nombreCookie) {
  const listaCookie = document.cookie.split(';');
  let micookie;
  for (let i in listaCookie) {
    let searchCookie = listaCookie[i].search(nombreCookie);
    if (searchCookie > -1) {
      micookie = listaCookie[i];
    }
  }
  let igual = micookie ? micookie.indexOf('=') : null;
  let valor = micookie
    ? micookie
        .substring(igual + 1)
        .split('.')
        .slice(2, 4)
    : null;
  let cookie = valor ? valor[0] + valor[1] : null;
  return cookie;
}

export default getCookie;
