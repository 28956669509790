import React from 'react';
import { IconWrapper } from './styles';
const CardHome = ({ icon, title, description, size, conPading }) => {
  return (
    <div
      className={`${conPading ? 'card-home-pading ' : ''} col-xs-12 col-sm-12 col-md-4 col-lg-4`}
    >
      <div>
        <div className=" card-home-ico">
          <IconWrapper icon={icon} size={size}>
            {icon}
          </IconWrapper>
        </div>
      </div>
      <div className="card-home-text">
        <strong>{title}</strong>
        <p>{description}</p>
      </div>
    </div>
  );
};
export default CardHome;
