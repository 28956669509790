import React from 'react';
import DashboardCard from '../../../organism/DashboardCard';
import FactoresDeSeguridad from '../../../organism/FactoresDeSeguridad';
import Button from '@coopeuch-components/web/atoms/Button';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import { ErrorMessage } from '../Wrapper/styles';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import { validacionTexts } from '../../../../containers/RecuperarOCrearClave/texts';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ValidacionTemplate = ({
  factorSelected,
  propiedadesFactor,
  telefono,
  tiempoSMS,
  intentosRetrySMS,
  handleRetrySMS,
  onChangeInputSMS,
  smsErrors,
  handleButtonFactorSMS,
  isLoadingValidarSMS,
  isLoadingDesafioSMS,
  numeroTarjetaCoord,
  tarjetaCoordenadasErrors,
  desafio1,
  desafio2,
  desafio3,
  tiempoTarjetaCoordenadas,
  handleButtonFactorTCOO,
  onChangeInputTCOO,
  isLoadingDesafioYValidarFactorSeguridad,
  passCoopeuchErrors,
  tiempoCPASS,
  handleButtonFactorCPASS,
}) => {
  return (
    <div>
      <div>
        {factorSelected === '2.1' && (
          <div>
            <DashboardCard classes="dashboard-card-shadow">
              <DashboardCard.Head
                title={validacionTexts.TITLE_TCOOR + numeroTarjetaCoord}
                borderBottom="complete"
              />
              <DashboardCard.Body>
                {isLoadingDesafioYValidarFactorSeguridad ? (
                  <div className="text-align-center spinner-container">
                    <SpinnerLoader></SpinnerLoader>
                  </div>
                ) : (
                  <div className="row center-xs mt-24 mb-24">
                    <FactoresDeSeguridad
                      tipo={propiedadesFactor?.tipo}
                      ilustration={propiedadesFactor?.imagen}
                      message={
                        tarjetaCoordenadasErrors?.id
                          ? tarjetaCoordenadasErrors?.message
                          : propiedadesFactor?.mensaje
                      }
                      stepFactor={propiedadesFactor?.step}
                      buttonText={
                        tarjetaCoordenadasErrors?.id ? 'Reintentar' : propiedadesFactor?.botonText
                      }
                      title={tarjetaCoordenadasErrors?.id ? tarjetaCoordenadasErrors.title : ''}
                      titleClass="pt-12 pb-24"
                      messageClass="pb-24 lh-1_5"
                      desafio1={desafio1}
                      desafio2={desafio2}
                      desafio3={desafio3}
                      valorDesafio1={propiedadesFactor?.valorDesafio1}
                      valorDesafio2={propiedadesFactor?.valorDesafio2}
                      valorDesafio3={propiedadesFactor?.valorDesafio3}
                      timer={tiempoTarjetaCoordenadas}
                      activeTimer={propiedadesFactor?.activeTimer}
                      hasError={tarjetaCoordenadasErrors?.id}
                      showInputInError={
                        tarjetaCoordenadasErrors?.id ? tarjetaCoordenadasErrors?.showInput : true
                      }
                      withCountdownAnimation={propiedadesFactor?.activeTimer}
                      onClickButton={handleButtonFactorTCOO}
                      onChangeInput={onChangeInputTCOO}
                    />
                  </div>
                )}
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        )}
      </div>
      <div>
        {factorSelected === '3.1' && (
          <div>
            <DashboardCard classes="dashboard-card-shadow">
              <DashboardCard.Head title={validacionTexts.TITLE_SMS} borderBottom="complete" />
              <DashboardCard.Body classes="validacion-sms-container">
                {isLoadingValidarSMS || isLoadingDesafioSMS ? (
                  <div className="text-align-center spinner-container">
                    <SpinnerLoader></SpinnerLoader>
                  </div>
                ) : (
                  <div>
                    {tiempoSMS === 0 && (
                      <div>
                        <ErrorMessage className="bmb-3 bmb-md-4">
                          <div className="row">
                            <div className="box">
                              <LoadIcon
                                src={`${iconPath}icono_error.svg`}
                                size={20}
                                className="validacion-icon-error"
                              />
                            </div>
                            <div className="col-xs">
                              <p className="bml-md-3 text-left">{validacionTexts.TIMEOUT_SMS}</p>
                            </div>
                          </div>
                        </ErrorMessage>
                      </div>
                    )}
                    {smsErrors.id !== 2 && (
                      <div>
                        <div>
                          <label className="label-desc text-size-l text-bold">
                            {validacionTexts.DESC_1}
                            <b className="validacion-sms-number text-bold">
                              {validacionTexts.DESC_2}
                              {telefono.substring(telefono?.length - 2, telefono?.length)}
                            </b>
                          </label>
                        </div>
                        <div className="mt-16">
                          <label className="label-desc text-size-l">
                            {validacionTexts.DESC_3}
                            <b className="validacion-sms-number">{validacionTexts.DESC_4}</b>
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="mt-24">
                      <FactoresDeSeguridad
                        tipo={propiedadesFactor?.tipo}
                        ilustration={propiedadesFactor?.imagen}
                        message={
                          smsErrors?.id
                            ? smsErrors.message
                            : '<div class="sms-text-class">' + propiedadesFactor?.mensaje + '</div>'
                        }
                        stepFactor={propiedadesFactor?.step}
                        buttonText={smsErrors?.id ? 'Reintentar' : propiedadesFactor?.botonText}
                        showInputInError={smsErrors?.id ? smsErrors?.showInput : true}
                        hasError={smsErrors?.id}
                        title={smsErrors?.title}
                        hideButton={smsErrors?.hideButton}
                        titleClass="pt-12 pb-24"
                        valorDesafio1={propiedadesFactor?.valorDesafio1}
                        valorDesafio2={propiedadesFactor?.valorDesafio2}
                        valorDesafio3={propiedadesFactor?.valorDesafio3}
                        valorDesafio4={propiedadesFactor?.valorDesafio4}
                        valorDesafio5={propiedadesFactor?.valorDesafio5}
                        valorDesafio6={propiedadesFactor?.valorDesafio6}
                        timer={tiempoSMS}
                        activeTimer={propiedadesFactor?.activeTimer}
                        onChangeInput={onChangeInputSMS}
                        withCountdownAnimation={propiedadesFactor?.activeTimer}
                        onClickButton={handleButtonFactorSMS}
                      />
                    </div>

                    {smsErrors.id !== 2 && (
                      <div className="bmt-md-0 bmt-3">
                        <Button
                          asTextLink
                          color="#E81E2B"
                          className="link-button"
                          onClick={handleRetrySMS}
                          disabled={!(tiempoSMS === 0 && Boolean(intentosRetrySMS > 0))}
                        >
                          <label className="link-text">{validacionTexts.RETRY_LINK_SMS}</label>
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        )}
      </div>
      <div>
        {factorSelected === '2.2' && (
          <div>
            <DashboardCard classes="dashboard-card-shadow">
              <DashboardCard.Head title={validacionTexts.TITLE_CPASS} borderBottom="complete" />
              <DashboardCard.Body>
                {isLoadingDesafioYValidarFactorSeguridad ? (
                  <div className="text-align-center spinner-container">
                    <SpinnerLoader></SpinnerLoader>
                  </div>
                ) : (
                  <div className="row center-xs mt-24 mb-24 validacion-cp-container">
                    <FactoresDeSeguridad
                      tipo={propiedadesFactor?.tipo}
                      ilustration={propiedadesFactor?.imagen}
                      message={
                        passCoopeuchErrors?.id
                          ? passCoopeuchErrors?.message
                          : propiedadesFactor?.mensaje
                      }
                      stepFactor={propiedadesFactor?.step}
                      buttonText={
                        passCoopeuchErrors?.id ? 'Reintentar' : propiedadesFactor?.botonText
                      }
                      title={passCoopeuchErrors?.id ? passCoopeuchErrors.title : ''}
                      titleClass="pt-12 pb-24"
                      messageClass="pb-24 lh-1_5"
                      hideButton={passCoopeuchErrors?.hideButton}
                      timer={tiempoCPASS}
                      activeTimer={propiedadesFactor?.activeTimer}
                      hasError={passCoopeuchErrors?.id}
                      withCountdownAnimation={propiedadesFactor?.activeTimer}
                      onClickButton={handleButtonFactorCPASS}
                    />
                  </div>
                )}
              </DashboardCard.Body>
            </DashboardCard>
          </div>
        )}
      </div>
    </div>
  );
};

export default ValidacionTemplate;
