import { confirmacionTexts } from '../../../ui/containers/TransferirFondos/texts';

const initState = {
  factoresSeguridad: [],
  errorFactoresSeguridad: false,
  errorFactoresMessage: {},
  errorFactoresMessageLog: null,
  passCoopeuchErrors: {},
  tarjetaCoordenadasErrors: {},
  smsErrors: {},
  validacionFactorDeSeguridad: false,
  factorActivo: 0,
  desafio1: '',
  desafio2: '',
  desafio3: '',
  propiedadesFactor: {
    2.1: {
      step: 0,
      imagen: 'tarjeta_coordenadas',
      mensaje: confirmacionTexts.LABEL_DESC_TARJETA_COORDENADAS,
      botonText: confirmacionTexts.LABEL_BUTTON_TARJETA_COORDENADAS,
      tipo: 2.1,
      valorDesafio1: '',
      valorDesafio2: '',
      valorDesafio3: '',
      activeTimer: false,
    },
    2.2: {
      step: 0,
      imagen: 'pass_coopeuch',
      mensaje: confirmacionTexts.LABEL_DESC_COOPEUCH_PASS,
      botonText: confirmacionTexts.LABEL_BUTTON_COOPEUCH_PASS,
      tipo: 2.2,
      activeTimer: false,
    },
    3.1: {
      step: 0,
      imagen: 'sms',
      mensaje: '',
      botonText: confirmacionTexts.LABEL_BUTTON_SMS,
      tipo: 3.1,
      activeTimer: false,
      valorDesafio1: '',
      valorDesafio2: '',
      valorDesafio3: '',
      valorDesafio4: '',
      valorDesafio5: '',
      valorDesafio6: '',
    },
  },
  obtencionFactorCoopeuchPass: false,
  validacionFactorDeSeguridadSMS: false,
  reglasFactoresSeguridad: [],
  primerFactorValidado: '',
  errorFactorLog: '',
  errorReglasFactoresSeguridad: '',
};

export default initState;
