import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { Button, Card } from '@design-system-coopeuch/web';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardBlockBonos = ({ title, message, handleClickRetry }) => {
  return (
    <Wrapper>
      <Card>
        <LoadIcon
          src={`${iconPath}ilustracion_error.svg`}
          className="containerBonos__image"
          size={180}
        />
        <div className="containerBonos__content">
          <p className="containerBonos__content_title">{title}</p>
          <p className="containerBonos__content_message">{message}</p>
        </div>
        <Button
          color="primary"
          variant="link"
          className="containerBonos__btnReset"
          onClick={() => handleClickRetry('Reintentar')}
        >
          Reintentar
        </Button>
      </Card>

      <Button
        className="btnReturn"
        variant="text"
        prefix="old-arrow-left"
        onClick={() => handleClickRetry('Ir al inicio')}
      >
        Ir al inicio
      </Button>
    </Wrapper>
  );
};

export default CardBlockBonos;
