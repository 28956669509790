import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Actions
import { remanenteActions } from '../../../../application/actions/remanente';
// utils
import { formatStandardDate } from '../../../utils/dates';

// component
import Remanente from '../../../components/templates/Remanente';


const tituloConstants = {
  default: '',
  P: `Monto pagado Remanente`,
  N: `Monto a pagar Remanente`,
};


class MiRemanente extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saldoIndividualLoading: true,
      remanenteCalculoLoading: true,
      remanenteMontoTotalLoading: true,
      composicionLoading: true,
    };
  }

  componentDidMount() {
    const { fetchRemanenteComposicion, fetchRemanenteContenido, fetchRemanente, fetchRemanenteSwitchOnOff } = this.props;
    fetchRemanenteComposicion();
    fetchRemanenteContenido();
    fetchRemanenteSwitchOnOff();
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      remanente: { saldoIndividual, remanenteCalculo, remanenteMontoTotal, composicion },
    } = this.props;

    const {
      saldoIndividualLoading,
      remanenteCalculoLoading,
      remanenteMontoTotalLoading,
      composicionLoading,
    } = this.state;

    if (prevProps.remanente.saldoIndividual !== saldoIndividual || !!saldoIndividual) {
      this.setState({ saldoIndividualLoading: false });
    }
    if (prevProps.remanente.remanenteCalculo !== remanenteCalculo) {
      this.setState({ remanenteCalculoLoading: false });
    }

    if (prevProps.remanente.remanenteMontoTotal !== remanenteMontoTotal) {
      this.setState({ remanenteMontoTotalLoading: false });
    }

    if (prevProps.remanente.composicion !== composicion) {
      this.setState({ composicionLoading: false });
    }
    if (remanenteMontoTotalLoading != prevState.remanenteMontoTotalLoading) {
      console.log(remanenteMontoTotalLoading);
      if (!remanenteMontoTotalLoading) {
        console.log('no carga');
      }
    }

    if (
      saldoIndividualLoading != prevState.saldoIndividualLoading ||
      remanenteCalculoLoading != prevState.remanenteCalculoLoading ||
      remanenteMontoTotalLoading != prevState.remanenteMontoTotalLoading ||
      composicionLoading != prevState.composicionLoading
    ) {
      this.setState({
        loading:
          saldoIndividualLoading ||
          remanenteCalculoLoading ||
          remanenteMontoTotalLoading ||
          composicionLoading,
      });
    }
  }

  

  render() {
    const {
      onClickCobrarButton,
      onClickCapitalizarButton,
      remanente,
      capitalizar,
      detalleCapitalizacion,
      cobreRemanenteLoading,
      capitalizarLoading,
      switchOnOff,
    } = this.props;

    const {
      saldoIndividual,
      remanenteCalculo,
      remanenteMontoTotal,
      composicion,
      cobreRemanenteWeb,
    } = remanente;

    const { medioPago, grupo, pago, sinRemanente, ano } = saldoIndividual;

    const {
      cobrar,
      errorListaNegra: errorListaNegraCobroRemanente,
      error: errorCobroRemanente,
    } = cobreRemanenteWeb;

    const titulo = tituloConstants[pago?.estado?.codigo] || tituloConstants.default;
    const anoPagadoCobrado = ano + 1;
    const tituloAno = `${titulo} ${anoPagadoCobrado}:`
    const { loading } = this.state;
    return (
      <Remanente
        {...{ detalleCapitalizacion }}
        onClickCobrarButton={onClickCobrarButton}
        onClickCapitalizarButton={onClickCapitalizarButton}
        remanenteMontoTotal={remanenteMontoTotal}
        isCobroRemanenteWeb={cobrar?.habilitado}
        habilitadoCapitalizar={capitalizar?.habilitado}
        errorListaNegraCobroRemanente={Boolean(
          errorCobroRemanente && errorListaNegraCobroRemanente
        )}
        errorListaNegraCapitalizacionRemanente={Boolean(
          capitalizar.errorServicio && capitalizar.errorListaNegra
        )}
        remanenteCalculo={remanenteCalculo}
        fechaDePago={formatStandardDate(pago?.fechaPagoDesde || pago?.fechaPago) || 'Por definir'}
        composicion={composicion}
        destino={medioPago?.destino}
        estado={pago?.estado?.codigo}
        tituloAno={tituloAno}
        monto={pago?.monto}
        grupo={grupo?.codigo}
        sinRemanente={sinRemanente}
        loading={loading}
        cobreRemanenteLoading={cobreRemanenteLoading}
        capitalizarLoading={capitalizarLoading}
        switchOnOff={switchOnOff}
      />
    );
  }
}

MiRemanente.propTypes = {
  fetchRemanenteComposicion: PropTypes.func.isRequired,
  fetchRemanente: PropTypes.func.isRequired,
};

MiRemanente.defaultProps = {};

const mapStateToProps = (store) => ({
  remanente: store.entities.remanente,
  capitalizar: store.entities.capitalizacion.habilitado,
  detalleCapitalizacion: store.entities.capitalizacion.detalleCapitalizacion,
  switchOnOff: store.entities.remanente.switchOnOff,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRemanenteComposicion(props) {
    dispatch(remanenteActions.fetchRemanenteComposicion(props));
  },
  fetchRemanenteContenido(props) {
    dispatch(remanenteActions.fetchRemanenteContenido(props));
  },
  fetchRemanenteSwitchOnOff(props) {
    dispatch(remanenteActions.fetchRemanenteSwitchOnOff(props));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MiRemanente);
