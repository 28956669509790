import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';
import Button from '@coopeuch-components/web/atoms/Button';
import { Link } from 'react-router-dom';
import { SliderPage } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const Index = () => {
  return (
    <SliderPage
      src={`${staticPath}dashboardbanner/bg-banner-privado-inversiones-dk.jpg`}
      srcMobile={`${staticPath}dashboardbanner/bg-banner-privado-inversiones-mb.jpg`}
    >
      <div className="inversiones-container row">
        <div className="col-xs-12 col-sm-7 col-md-7">
          <Typography className="w-100" color="#ffc900" tag="p" fontSize={18} fontWeight={700}>
            Invierte en el nuevo Fondo Mutuo <br />
            Coopeuch Compass Liquidez
          </Typography>
          <div className="bmb-2">
            <Typography className="w-100" color={'white'} tag="p" fontSize={12}>
              Si buscas generar rentabilidad con un bajo riesgo esperado, este fondo es para ti
            </Typography>
          </div>
          <div className="inversiones-list">
            <div className="col-xs-12 col-sm-5 col-md-4">
              <Link to="/ecd/inversiones">
                <Button bgColor="red" color="white" className="btn_red">
                  Conocer más
                </Button>
              </Link>
            </div>
            <div className="col-2 col-xs-12 col-sm-7 col-md-8 display-desktop align-items-center">
              <Typography className="w-100 text-bold" color={'white'} tag="p" fontSize={13}>
                Comienza a invertir desde $1.500
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-5 col-md-5">
          <div className="icon-inversiones-container display-desktop">
            <LoadIcon src={`${staticPath}dashboardbanner/logo-inversiones.svg`} className="icon-inversiones" />
          </div>
        </div>
      </div>
    </SliderPage>
  );
};

export default Index;
