import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import PageTitle from '../../../atoms/PageTitle';
import Select from '../../../atoms/Select';
import DashboardCard from '../../../organism/DashboardCard';
import FullBannerGoToSimulacion from '../../../organism/DepositoAPlazo/FullBannerGoToSimulacion';
import ModalWithoutCuentasVistas from '../../../organism/DepositoAPlazo/Liquidar/ModalWithoutCuentasVistas';
import ServiceError from '../../../organism/DepositoAPlazo/ServiceError';

import { capitalize, formatCurrency } from '../../../../utils/functions';
import { agregarFormatoDecimal } from '../../../../utils/formatters';
import { dateFormat } from '../../../../utils/dates';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const errCierreContable = {
  isWarning: true,
  message:
    'Esta función se encuentra deshabilitada en este momento. Vuelve a intentarlo más tarde.',
};

function DepositosContratadoTemplate(props) {
  const {
    error,
    seleccionada,
    showModal,
    depositosContratados,
    showMantencionErr,
    handleSimulacion,
    handleGoToLiquidar,
    handleModalActions,
  } = props;

  const {
    id,
    montoVencimiento,
    montoInvertir,
    montoGanancia,
    fechaInicio,
    fechaVencimiento,
    diasPlazo,
    origen,
    descripcionEstadoDeposito,
    descripcionTipoDeposito,
    numeroDeposito,
    tasaBase,
    tasaPeriodo,
    liquidar,
  } = seleccionada || {};

  return (
    <Wrapper className="w-100">
      <PageTitle
        id="dap-title-page"
        title="Depósito a Plazo"
        icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" />}
      />

      <div className="w-100 margin-after-title content-dap pt-24">
        {error || showMantencionErr ? (
          <div className="mt-54 pt-34">
            <ServiceError withCard {...(showMantencionErr ? errCierreContable : {})} noButton />
          </div>
        ) : (
          <Fragment>
            <div className="row dap--card">
              <div className="col-xs-12 col-sm-12 col-md-6 col-sm-6 start-xs">
                <DashboardCard classes="pl-32 pr-32 dap--card--content mb-0 mt-0">
                  {depositosContratados?.length >= 2 ? (
                    <Select
                      borderColor="#646569"
                      placeholder="Selecciona Cuenta"
                      onChange={handleSimulacion}
                      options={depositosContratados}
                      value={id}
                      size={48}
                      rounded={5}
                    />
                  ) : (
                    <div className="text-size-s black dap--resumen--seleccion-simulacion">
                      {seleccionada && (
                        <Fragment>
                          {descripcionTipoDeposito}: N° {numeroDeposito}
                        </Fragment>
                      )}
                    </div>
                  )}

                  <div className="black dap-resumen-monto-vencimiento">
                    <p>Monto al vencimiento</p>
                    <p className="text-bold text-size-xl pt-8">
                      {formatCurrency(montoVencimiento)}
                    </p>
                  </div>

                  <div className="row black">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <p className="pb-8 pt-4">Monto invertido</p>
                      <p className="text-bold">{formatCurrency(montoInvertir)}</p>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                      <p className="pb-8 pt-4 dap-resumen-texto">Ganancia</p>
                      <p className="text-bold">{formatCurrency(montoGanancia)}</p>
                    </div>
                  </div>
                </DashboardCard>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-sm-6 start-xs">
                <DashboardCard classes="pl-24 pr-24 mb-0 mt-0 dap--card--content dap-details--card">
                  <p className="black text-bold pb-16 text-size-xl">Detalle del depósito</p>

                  <div className="black pb-14">
                    Fecha de inicio:{' '}
                    <span className="text-bold">{capitalize(dateFormat(fechaInicio))}</span>
                  </div>

                  <div className="black pb-14">
                    Fecha de vencimiento:{' '}
                    <span className="text-bold">{capitalize(dateFormat(fechaVencimiento))}</span>
                  </div>

                  <div className="black pb-14">
                    Plazo: <span className="text-bold">{diasPlazo} días</span>
                  </div>

                  <div className="black pb-14">
                    Origen: <span className="text-bold">{capitalize(origen)}</span>
                  </div>

                  <div className="black">
                    Estado:{' '}
                    <span className="text-bold">{capitalize(descripcionEstadoDeposito)}</span>
                  </div>
                </DashboardCard>
              </div>
            </div>

            {liquidar && (
              <div className="row dap--card">
                <div className="col-xs-12 start-xs">
                  <Button
                    color="white"
                    bgColor="#E81E2B"
                    className="text-size-s text-bold"
                    width={180}
                    onClick={handleGoToLiquidar}
                  >
                    Liquidar
                  </Button>
                </div>
              </div>
            )}

            <div className="row dap--card">
              <div className="col-xs-12">
                <DashboardCard classes="mt-0 mb-0">
                  <DashboardCard.Head title="Tasas" borderBottom="complete" />
                  <DashboardCard.Body classes="pt-24 pl-32 pr-32">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs pb-24 black">
                        <p className="text-bold pb-4">Tasa base:</p>
                        <p className="text-bold text-size-xl pb-8">
                          {agregarFormatoDecimal(tasaBase)}%
                        </p>
                        <p className="text-size-s">
                          Tasa definida basada en un período de 30 días.
                        </p>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs pb-24 black">
                        <p className="text-bold pb-4">Tasa período:</p>
                        <p className="text-bold text-size-xl pb-8">
                          {agregarFormatoDecimal(tasaPeriodo)}%
                        </p>
                        <p className="text-size-s">
                          Tasa de interés total para todo el período estipulado en la captación.
                          Resulta de la división de la tasa base por 30 y multiplicado por el número
                          de días de la captación.
                        </p>
                      </div>
                    </div>
                  </DashboardCard.Body>
                </DashboardCard>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <div className="bmb-3 bmb-md-4">
                  <FullBannerGoToSimulacion />
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <ModalWithoutCuentasVistas
          show={showModal}
          closeAction={handleModalActions}
          backButton={handleModalActions}
          nextButton={() => handleModalActions('solicitar')}
        />
      </div>
    </Wrapper>
  );
}

DepositosContratadoTemplate.propTypes = {
  seleccionada: PropTypes.object,
  handleSimulacion: PropTypes.func,
};

DepositosContratadoTemplate.defaultProps = {
  seleccionada: {},
  handleSimulacion: null,
};

export default DepositosContratadoTemplate;
