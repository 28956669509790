import styled from 'styled-components';

const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    .factor-button-style {
      width: 100% !important;
      height: 100% !important;
      padding: 12px 16px !important;
    }
  }
`;

export default ButtonContainer;
