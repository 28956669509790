import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { tipoActions } from '../../../application/actions/tipo';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiTipo = api(process.env.REACT_APP_TIPO);

export function* fetchRegiones() {
  try {
    const { data, error } = yield call(apiTipo.get, 'region/v1/obtener');
    if (error) {
      yield put(tipoActions.fetchRegionesError());
    }
    if (data) {
      yield put(tipoActions.fetchRegionesSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchRegiones');
    yield put(tipoActions.fetchRegionesError());
  }
}

export function* fetchComunas(action) {
  const codigoComuna = action.payload;
  try {
    const { data, error } = yield call(apiTipo.get, `comuna/v1/obtener/${codigoComuna}`);
    if (error) {
      yield put(tipoActions.fetchComunasError());
    }
    if (data) {
      yield put(tipoActions.fetchComunasSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchComunas');
    yield put(tipoActions.fetchComunasError());
  }
}

export function* fetchSucursales(action) {
  const codigoRegion = action.payload;
  try {
    const { data, error } = yield call(apiTipo.get, `sucursal/v1/obtener/${codigoRegion}`);
    if (error) {
      yield put(tipoActions.fetchSucursalesError());
    }
    if (data) {
      yield put(tipoActions.fetchSucursalesSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchSucursales');
    yield put(tipoActions.fetchSucursalesError());
  }
}

export function* fetchCoberturaComuna() {
  try {
    const { data, error } = yield call(apiTipo.get, 'cobertura/proveedor/v1/obtener');
    if (error) {
      yield put(tipoActions.fetchCoberturaComunaError());
    }
    if (data) {
      yield put(tipoActions.fetchCoberturaComunaSuccess({ data: data.proveedores }));
    }
  } catch (err) {
    console.error(err.message, 'fetchCoberturaComuna');
    yield put(tipoActions.fetchCoberturaComunaError());
  }
}

export function* fetchMotivoTarjetaBloqueo() {
  try {
    yield put(loadersActions.fetchLoadingMotivoTarjetaBloqueo({ isLoading: true }));
    const { data } = yield call(apiTipo.get, 'tarjetabloqueo/obtener');
    if (data) {
      yield put(tipoActions.fetchMotivoTarjetaBloqueoSuccess({ data }));
      yield put(loadersActions.fetchLoadingMotivoTarjetaBloqueo({ isLoading: false }));
    } else {
      yield put(tipoActions.fetchMotivoTarjetaBloqueoFailed());
      yield put(loadersActions.fetchLoadingMotivoTarjetaBloqueo({ isLoading: false }));
    }
  } catch (err) {
    yield put(tipoActions.fetchMotivoTarjetaBloqueoFailed());
    yield put(loadersActions.fetchLoadingMotivoTarjetaBloqueo({ isLoading: false }));
  }
}

export function* fetchSucursalesComuna(action) {
  const codigoComuna = action.payload;
  try {
    yield put(loadersActions.fetchLoadingSucursalesComuna({ isLoading: true }));
    const { data, error } = yield call(apiTipo.get, `comuna/sucursal/v1/obtener/${codigoComuna}`);

    if (data) {
      yield put(tipoActions.fetchSucursalesComunaSuccess({ data }));
      yield put(loadersActions.fetchLoadingSucursalesComuna({ isLoading: false }));
    }

    if (error) {
      yield put(tipoActions.fetchSucursalesComunaFailed({ error }));
      yield put(loadersActions.fetchLoadingSucursalesComuna({ isLoading: false }));
    }
  } catch (err) {
    console.error(err.message, 'fetchSucursales');
    yield put(tipoActions.fetchSucursalesError({ err }));
    yield put(loadersActions.fetchLoadingSucursalesComuna({ isLoading: false }));
  }
}

export function* watchFetchRegiones() {
  yield takeLatest(tipoActions.fetchRegiones.type, fetchRegiones);
}

export function* watchFetchComunas() {
  yield takeLatest(tipoActions.fetchComunas.type, fetchComunas);
}

export function* watchFetchSucursales() {
  yield takeLatest(tipoActions.fetchSucursales.type, fetchSucursales);
}

export function* watchFetchCoberturaComuna() {
  yield takeLatest(tipoActions.fetchCoberturaComuna.type, fetchCoberturaComuna);
}

export function* watchFetchMotivoTarjetaBloqueo() {
  yield takeLatest(tipoActions.fetchMotivoTarjetaBloqueo.type, fetchMotivoTarjetaBloqueo);
}

export function* watchFetchSucursalesComuna() {
  yield takeLatest(tipoActions.fetchSucursalesComuna.type, fetchSucursalesComuna);
}

/**
 * This is a non-blocking rootSaga pattern. The good thing besides be non-blocking
 * is that we can cought any possible error if we want with try/catch.
 */
export default function* rootSaga() {
  yield spawn(watchFetchRegiones);
  yield spawn(watchFetchComunas);
  yield spawn(watchFetchSucursales);
  yield spawn(watchFetchCoberturaComuna);
  yield spawn(watchFetchMotivoTarjetaBloqueo);
  yield spawn(watchFetchSucursalesComuna);
}
