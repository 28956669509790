import React, { useEffect } from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SliderPage } from './styles';
import { registerGA } from '../../../../../utils/metrics';
import logger from '../../../../../utils/logger';

const Index = () => {
  const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
  const pathImage = process.env.REACT_APP_CLOUDFRONT_BANNER

   const handleClick = () => {
      registerGA({
        event: 'eve',
        'eve-acc' : '/tef/home',
        'eve-cat' : 'Banner',
        'eve-lab' : 'Modificar cuotas',
        'description': 'Aumenta tus cuotas de participacion'
      })
     logger(null, {
       rut: baseRut.slice(0, -1),
       digito_verificador: baseRut.slice(-1),
       accion: 'CP_VER_MODIFICACION',
       informacion_adicional: {
        origen: 'banner',
        tienecampania: true
       }
     })
   }

  return (
    <SliderPage 
    src={`${pathImage}web/banner_cdp.png`} 
    srcMobile={`${pathImage}responsive/banner_cdp_mob.png`}>
      <div className="cdp-1788-container">
        <div className="col-xs-12 col-sm-7 col-md-7">
          <div>
            <label className="cdp-1788-subtitle">¡Haz crecer tu capital!</label>
          </div>
          <div className="bmb-2">
            <label className="cdp-1788-title">Aumenta tus cuotas de participación</label>
          </div>
          <Link
            to={'ecd/productos/cuota-de-participacion/sugerencia'}
            onClick={() => handleClick()}
          >
            <Button bgColor="red" color="white" className="btn_red">
            Modificar cuotas
            </Button>
          </Link>
        </div>
      </div>
    </SliderPage>
  );
};

Index.propTypes = {
  //  eslint-disable-next-line react/forbid-prop-types
  banner: PropTypes.object,
};

Index.defaultProps = {
  banner: {},
};

export default Index;
