import styled from 'styled-components';

const Wrapper = styled.div`
.contentSMS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__text {
    width: 100%;
    max-width: 470px;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    font-family: 'Ubuntu-Regular';
    margin-top: 11px;
    margin-bottom: 15px;
  }
  &__btn {
    margin-top: 24px;
    width: 100%;
    max-width: 214px;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: 'Ubuntu-Medium';
  }
}
.titleActivacionKit {
  font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  .titleAlertKit {
    font-size: 18px;
    font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  .cardBlockBody {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .imageCenter {
      margin-left: auto;
      margin-right: auto;
      padding-top: 10px;
    }

    .descriptionKit {
      width: 100%;
      max-width: 426px;
      margin: 0 auto;
      line-height: 20px;
      padding-top: 10px;
      font-size: 14px;
    }
  }

`;

export default Wrapper;
