import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import ButtonBack from '../../ButtonBack';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
// Styles
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardBlock = ({ section, title, message, link }) => {
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} className="titleActivacionKit">
            {section}
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="cardBlockBody">
            <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="imageCenter" size={180} />
            <Typography align="center" tag="p" className="titleAlertKit">
              {title}
            </Typography>
            <Typography align="center" tag="p" className="descriptionKit">
              {message}
            </Typography>
          </div>
        </CardBox.Body>
      </CardBox>
      <ButtonBack link={link} text={activacionTexts.BUTTON_CANCEL} />
    </Wrapper>
  );
};

export default CardBlock;
