import styled from 'styled-components';

const Wrapper = styled.div`
  .title {
    font-size: 16px;
    font-family: 'Ubuntu-Bold';
    margin-top: 24px;
  }
  .alertDescription {
    font-size: 14px;
  }
  .boxSelects {
    display: flex;
    @media (max-width: 991px) {
      flex-direction: column;
    }
    .select {
      width: 100%;
      max-width: 300px;
      margin-right: 40px;
      label#aria-label-select-autocomplete {
        font-family: 'Ubuntu-Bold' !important;
        margin-top: 24px;
      }
      .skeleton-class {
        margin-top: 24px;
        height: 75px;
      }
    }
  }
`;

export const FormSon = styled.div`
  label {
    font-size: 14px !important;
    font-family: 'Ubuntu-Bold' !important;
    margin-top: 24px;
  }
  ._3MBFe ._2wTiJ {
    max-width: 100% !important;
  }
`;

export default Wrapper;
