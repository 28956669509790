import React, { useRef } from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import ButtonBack from '../../../ActivacionTarjetasKit/ButtonBack';
import { activacionTexts } from '../../../../../containers/ActivacionTarjetasKit/texts';

import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardCoordenadas = ({
  error,
  handleSetCardCoord,
  disabledBtn,
  handleClickContinue,
  retryButton,
  kitData,
  cardCoorOne,
  cardCoorTwo,
  cardCoorThree,
  cardCoorFour,
  cardCoorFive,
  cardCoorSix,
  cardCoorSeven,
  handleClickBack,
}) => {
  const refCoor1 = useRef();
  const refCoor2 = useRef();
  const refCoor3 = useRef();
  const refCoor4 = useRef();
  const refCoor5 = useRef();
  const refCoor6 = useRef();
  const refCoor7 = useRef();

  const handleChange = (e) => {
    const { id, value } = e.target;

    if (id === 'cor1') {
      handleSetCardCoord(id, value);
      if (value.length === 1) {
        refCoor2.current.focus();
      }
    }

    if (id === 'cor2') {
      handleSetCardCoord(id, value);
      if (value.length === 1) {
        refCoor3.current.focus();
      } else {
        refCoor1.current.focus();
      }
    }
    if (id === 'cor3') {
      handleSetCardCoord(id, value);
      if (value.length === 1) {
        refCoor4.current.focus();
      } else {
        refCoor2.current.focus();
      }
    }
    if (id === 'cor4') {
      handleSetCardCoord(id, value);
      if (value.length === 1) {
        refCoor5.current.focus();
      } else {
        refCoor3.current.focus();
      }
    }
    if (id === 'cor5') {
      handleSetCardCoord(id, value);
      if (value.length === 1) {
        refCoor6.current.focus();
      } else {
        refCoor4.current.focus();
      }
    }
    if (kitData.data?.numeroTarjetaCoordenadas.length === 6) {
      if (id === 'cor6') {
        handleSetCardCoord(id, value);
        if (value.length === 0) {
          refCoor5.current.focus();
        }
      }
    } else if (kitData.data?.numeroTarjetaCoordenadas.length === 7) {
      if (id === 'cor6') {
        handleSetCardCoord(id, value);
        if (value.length === 1) {
          refCoor7.current.focus();
        } else {
          refCoor5.current.focus();
        }
      }
      if (id === 'cor7') {
        handleSetCardCoord(id, value);
        if (value.length === 0) {
          refCoor6.current.focus();
        }
      }
    }
  };
  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Activa tu Tarjeta de Coordenadas
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="cardCoordenadas">
            {error === 'TC-01' || error === 'TC-04' ? (
              <LoadIcon
                src={`${iconPath}ilustracion_error.svg`}
                className="cardCoordenadas__image"
                size={180}
              />
            ) : (
              <LoadIcon
                src={`${iconPath}kit/tarjeta-coopeuch-mano.svg`}
                className="cardCoordenadas__image"
                size={180}
              />
            )}
            {error === 'TC-01' && (
              <Typography align="center" tag="p" fontSize={18} className="cardCoordenadas__bold">
                ¡Lo sentimos!
              </Typography>
            )}
            {error === 'TC-04' && (
              <Typography align="center" tag="p" fontSize={18} className="cardCoordenadas__bold">
                ¡Lo sentimos!
              </Typography>
            )}
            {error === 'TC-01' || error === 'TC-04' ? (
              <Typography align="center" tag="p" fontSize={14} className="cardCoordenadas__title">
                El número de serie ingresado es incorrecto. Por favor, intenta nuevamente.
              </Typography>
            ) : (
              <Typography align="center" tag="p" fontSize={14} className="cardCoordenadas__title">
                Ingresa el Nº de serie que se <br />
                encuentra al reverso de tu Tarjeta de
                <br />
                Coordenadas Nº {kitData.data?.numeroTarjetaCoordenadas}.
              </Typography>
            )}

            <div className="cardCoordenadas__box">
              <Typography
                tag="p"
                fontSize={14}
                fontWeight="bold"
                className="cardCoordenadas__box-description"
              >
                Nº de serie Tarjeta de Coordenadas
              </Typography>
              <div className="cardCoordenadas__form">
                <input
                  id="cor1"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'cardCoordenadas__form-input-error'
                  }`}
                  maxLength={1}
                  type="number"
                  onChange={handleChange}
                  ref={refCoor1}
                  value={cardCoorOne}
                />
                <input
                  id="cor2"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'cardCoordenadas__form-input-error'
                  }`}
                  maxLength={1}
                  type="number"
                  onChange={handleChange}
                  ref={refCoor2}
                  value={cardCoorTwo}
                />
                <input
                  id="cor3"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'cardCoordenadas__form-input-error'
                  }`}
                  maxLength={1}
                  type="number"
                  onChange={handleChange}
                  ref={refCoor3}
                  value={cardCoorThree}
                />
                <input
                  id="cor4"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'cardCoordenadas__form-input-error'
                  }`}
                  maxLength={1}
                  type="number"
                  onChange={handleChange}
                  ref={refCoor4}
                  value={cardCoorFour}
                />
                <input
                  id="cor5"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'cardCoordenadas__form-input-error'
                  }`}
                  maxLength={1}
                  type="number"
                  onChange={handleChange}
                  ref={refCoor5}
                  value={cardCoorFive}
                />
                <input
                  id="cor6"
                  className={`cardCoordenadas__form-input ${
                    error === 'TC-01' && 'cardCoordenadas__form-input-error'
                  }`}
                  maxLength={1}
                  type="number"
                  onChange={handleChange}
                  ref={refCoor6}
                  value={cardCoorSix}
                />
                {kitData.data?.numeroTarjetaCoordenadas.length === 7 && (
                  <input
                    id="cor7"
                    className={`cardCoordenadas__form-input ${
                      error === 'TC-01' && 'cardCoordenadas__form-input-error'
                    }`}
                    maxLength={1}
                    type="number"
                    onChange={handleChange}
                    ref={refCoor7}
                    value={cardCoorSeven}
                  />
                )}
              </div>
              {error === 'TC-01' || error === 'TC-04' ? (
                <Button
                  bgColor="#e81e2b"
                  color="white"
                  fontSize={14}
                  disabled={false}
                  onClick={retryButton}
                  className="cardCoordenadas__btn"
                >
                  {activacionTexts.BUTTON_RETRY}
                </Button>
              ) : (
                <Button
                  bgColor="#e81e2b"
                  color="white"
                  fontSize={14}
                  disabled={disabledBtn}
                  onClick={handleClickContinue}
                  className="cardCoordenadas__btn"
                >
                  {activacionTexts.BUTTON_CONTINUE}
                </Button>
              )}
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <div className="row">
        <div className="col-md-12">
          <ButtonBack
            link={error ? `/ecd/inicio` : `/ecd/seguridad/activacion-tarjetas-kit`}
            text={error ? activacionTexts.BUTTON_CANCEL : activacionTexts.BUTTON_BACK}
            onClick={() => handleClickBack(error ? activacionTexts.BUTTON_CANCEL : activacionTexts.BUTTON_BACK)}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CardCoordenadas;
