import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
// Styles
import Paragraph, { H1, H2, H3, H4, H5, H6 } from './styles';

const tags = {
  h1: <H1 />,
  h2: <H2 />,
  h3: <H3 />,
  h4: <H4 />,
  h5: <H5 />,
  h6: <H6 />,
  p: <Paragraph />,
};

const Typography = ({ fontFamily, lineHeight, fontWeight, fontSize, className, children, color, align, tag }) =>
  cloneElement(
    tags[tag],
    {
      lineHeight,
      fontFamily,
      fontWeight,
      className,
      fontSize,
      align,
      color,
    },
    children
  );

Typography.propTypes = {
  /**
    El font family a usar
   */
  fontFamily: PropTypes.string,
  /**
    El grosor de la fuente
   */
  fontWeight: PropTypes.oneOf([100, 300, 500, 600, 700]),
  /**
   Agregar o no interlineado
   */
  lineHeight: PropTypes.bool,
  /**
    Cualquier clase css
   */
  className: PropTypes.string,
  /**
    Contenido del componente
  */
  children: PropTypes.node.isRequired,
  /**
    Tamaño del texto a mostrar
  */
  fontSize: PropTypes.number,
  /**
    Alineación del texto
  */
  align: PropTypes.oneOf(['left', 'center', 'right']),
  /**
    Color del texto
   */
  color: PropTypes.string,
  /**
    El tag a reenderizar en caso de cambiar la relación tamaño texto / tag
   */
  tag: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p']),
  /**
  Identificador único
   */
  id: PropTypes.string,
};

Typography.defaultProps = {
  lineHeight: true,
  fontWeight: 100,
  fontFamily: '',
  className: '',
  fontSize: 0,
  align: 'left',
  color: '',
  tag: '',
  id: '',
};

export default Typography;
