import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { tarjetaCreditoActions } from '../../../../../application/actions/tarjetaCredito';
import { loginActions } from '../../../../../application/actions/ui_login';

import PagarTarjetaPagoTemplate from '../../../../components/templates/TarjetaDeCredito/PagarTarjetaPago';
import { useShallowStore } from '../../../../utils/hooks';
import { registerGA } from '../../../../utils/metrics';

const PagarTarjetaPago = (props) => {
  const {
    monto,
    email,
    datosTarjeta,
    history,
    handleEmail,
    handlePrevStep,
    handleNextStep,
    handleCodigoProductoPago,
  } = props;
  const { numeroTarjeta } = history?.location?.state || {};

  const dispatch = useDispatch();

  const [intentos, setIntentos] = useState(3);
  const [cuentaActual, setCuenta] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const cuentasVista = useShallowStore(({ entities }) => entities.products.cuentaVista.balance);
  const defaultEmail = useShallowStore(({ entities }) => entities.persona.correo);
  const loading = useShallowStore(({ ui }) => ui.loaders.isLoadingPagarTarjetaCredito);

  const pagoId = useShallowStore(({ entities }) => entities.tarjetaCredito.pagoConfirmado);
  const error = useShallowStore(({ entities }) => entities.tarjetaCredito.pagoError);

  const cuentasInputSelect = useMemo(() => {
    return cuentasVista?.map(({ codigoProducto, numeroCuenta, saldoDisponible }) => ({
      label: `${
        codigoProducto === 'VI06' ? 'Monedero Digital' : 'Cuenta Vista Coopeuch'
      }: N° ${numeroCuenta}`,
      value: String(numeroCuenta),
      saldoDisponible,
    }));
  }, [cuentasVista]);

  const handleManualEmail = useCallback(
    (event) => handleEmail(event?.target?.value),
    [handleEmail]
  );

  const handleCuenta = useCallback(
    (event) => {
      const nroCuenta = event?.target?.value;
      const { codigoProducto } =
        cuentasVista.find(({ numeroCuenta }) => numeroCuenta === Number(nroCuenta)) || {};

      setCuenta(nroCuenta);
      handleCodigoProductoPago(codigoProducto);
    },
    [setCuenta, handleCodigoProductoPago]
  );

  const handlePassword = useCallback((event) => setPassword(event?.target?.value), [setPassword]);

  const handlePasswordVisibility = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword, setShowPassword]
  );

  const handleSubmit = useCallback(
    (thereIsEnoughMoney) => {
      if (thereIsEnoughMoney) {
        dispatch(
          tarjetaCreditoActions.fetchPagarTarjetaCredito({
            clave: password,
            correo: email,
            cuentaOrigen: cuentaActual,
            monto: String(monto),
            tarjeta: numeroTarjeta,
            intentos: Number(intentos),
          })
        );
      } else {
        history.push('/ecd/productos/tarjeta-de-credito/');
      }
    },
    [monto, numeroTarjeta, cuentaActual, email, password, intentos, history]
  );

  const clearPasswordStates = useCallback(() => {
    setPassword('');
    setShowPassword(false);
    setIntentos(intentos - 1);

    dispatch(tarjetaCreditoActions.pagarTarjetaCreditoFailed({ error: {} }));

    if (String(error?.id) === '4') {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/tarjeta-de-credito/pagar/paso-2',
        'eve-cat': 'Click',
        'eve-lab': 'Cambiar clave',
      });

      history.push('/recuperar-clave');
      dispatch(loginActions.fetchLogoutUser());
    }
  }, [intentos, error, setPassword, setShowPassword, setIntentos, history]);

  useEffect(() => {
    if (defaultEmail) handleEmail(defaultEmail);
  }, [defaultEmail]);

  useEffect(() => {
    if (cuentasVista?.length >= 1) {
      setCuenta(String(cuentasVista?.[0]?.numeroCuenta));
      handleCodigoProductoPago(cuentasVista?.[0]?.codigoProducto);
    }
  }, [cuentasVista]);

  useEffect(() => {
    if (pagoId) handleNextStep();
  }, [pagoId]);

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/tarjeta-de-credito/pagar/paso-2',
    });
  }, []);

  useEffect(() => {
    if (error?.id === 500) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/tarjeta-de-credito/pagar/paso-2',
        'eve-cat': 'Error',
        'eve-lab': 'No se pudo realizar pago',
      });
    }
  }, [error?.id]);

  return (
    <PagarTarjetaPagoTemplate
      loading={loading}
      error={error}
      cuentas={cuentasInputSelect}
      monto={monto}
      numeroTarjeta={numeroTarjeta}
      datosTarjeta={datosTarjeta}
      cuentaActual={cuentaActual}
      email={email}
      password={password}
      showPassword={showPassword}
      handlePassword={handlePassword}
      handleCuenta={handleCuenta}
      handleManualEmail={handleManualEmail}
      handlePasswordVisibility={handlePasswordVisibility}
      handlePrevStep={handlePrevStep}
      handleSubmit={handleSubmit}
      clearPasswordStates={clearPasswordStates}
    />
  );
};

export default PagarTarjetaPago;
