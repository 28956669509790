import React from 'react';

import Controversia from '@coopeuch-project/controversia';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import { useSelector } from 'react-redux';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

export const AclaracionMovimientos = () => {
  const rut = useSelector((store) => store.entities.user.rut);
  return (
    <>
      <PageTitle
        title="Aclaración de Movimientos"
        icon={<LoadIcon src={`${iconPath}magnifying_glass.svg`} color="black" />}
      />
      <div className="w-100 text-left">
        <Controversia rut={rut} />
      </div>
    </>
  );
};
