import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { listaNegraActions } from '../../../application/actions/listaNegra';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiListaNegra = api(process.env.REACT_APP_LISTA_NEGRA);

export function* fetchListaNegra() {
  try {
    yield put(loadersActions.fetchLoadingListaNegra({ isLoading: true }));
    const { data, error } = yield call(apiListaNegra.get, 'v1/verificar');

    if (data) {
      yield put(listaNegraActions.fetchListaNegraSuccess(data.codigo));
      // console.log(data.codigo);
    }
    if (error) {
      yield put(listaNegraActions.fetchListaNegraError());
    }
    yield put(loadersActions.fetchLoadingListaNegra({ isLoading: false }));
  } catch (err) {
    console.error(err.message, 'fetchListaNegra');
    yield put(listaNegraActions.fetchListaNegraError());
    yield put(loadersActions.fetchLoadingListaNegra({ isLoading: false }));
  }
}

export function* fetchListaNegraConsultar() {
  try {
    yield put(loadersActions.fetchLoadingListaNegra({ isLoading: true }));
    const { data, error } = yield call(apiListaNegra.get, 'v1/consultar');

    if (data) {
      yield put(listaNegraActions.fetchListaNegraSuccess(parseInt(data.codigo, 10)));
      // console.log(data.codigo);
    }
    if (error) {
      yield put(listaNegraActions.fetchListaNegraError());
    }
    yield put(loadersActions.fetchLoadingListaNegra({ isLoading: false }));
  } catch (err) {
    console.error(err.message, 'fetchListaNegra');
    yield put(listaNegraActions.fetchListaNegraError());
    yield put(loadersActions.fetchLoadingListaNegra({ isLoading: false }));
  }
}

export function* watchFetchListaNegra() {
  yield takeLatest(listaNegraActions.fetchListaNegra.type, fetchListaNegra);
}

export function* watchFetchListaNegraConsultar() {
  yield takeLatest(listaNegraActions.fetchListaNegraConsultar.type, fetchListaNegraConsultar);
}

export default function* rootSaga() {
  yield spawn(watchFetchListaNegra);
  yield spawn(watchFetchListaNegraConsultar);
}
