
import React from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';

import Stepper from '../../../organism/Stepper';
import ContratacionTerminos from '../../../organism/ContratacionTerminos/v2';
import ContratacionEmail from '../../../organism/ContratacionEmail/v2';
import FirmaElectronica from '../../../organism/PoderEspecialAbonoAcreencias/Autorizacion/FirmaElectronica';
import StepsActions from '../../../organism/Stepper/StepsActions';
import PoderEspecial from '../../../organism/PoderEspecialAbonoAcreencias/Autorizacion/PoderEspecial';

import { MessageError, Wrapper } from './styles';

function AutorizacionTemplate(props) {
  const {
    loading,
    persona,
    banco,
    tipoCuenta,
    numeroCuenta,
    handleChangeEmail,
    checked,
    handleCheckBox,
    email,
    emailError,
    checkdRequiredError,
    password,
    handlePassword,
    passwordInputValidation,
    handleValidationError,
    validationError,
    showPassword,
    togglePassword,
    handleGoBack,
    handleSubmit,
    nextActionButtonDisabled
  } = props;

  const nombreCliente = `${persona.primerNombre} ${persona.apellidoPaterno} ${persona.apellidoMaterno}`

  const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const steps = ['Ingreso de datos', 'Autorización', 'Comprobante'];

  const emailErrorMessage = (
    <MessageError className="input-error-cuotas text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${ICON_PATH}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        {emailError}
      </div>
    </MessageError>
  );

  const texEnvioEmailAviso = (<p className='textosAutorizacion'> Si deseas que enviemos el comprobante a un email diferente, debes cambiarlo en la siguiente casilla. El cambio será solo para envío del documento y no reemplazarán el email que tienes registrado en Coopeuch</p>);
  const texEnvioEmail = (<p className='textosAutorizacion'> Al firmar electrónicamente, te enviaremos el comprobante a tu email:</p>);
  // eslint-disable-next-line react/jsx-one-expression-per-line
  const textPersonEmail = (<span className='text-bold'>{persona.correo}</span>);
  // eslint-disable-next-line react/jsx-one-expression-per-line
  const textOtroEmail = (<p className='textosAutorizacion'> Enviar a otro email</p>);
    // eslint-disable-next-line react/jsx-one-expression-per-line
  const textEmailNoTiene = (<p className='textosAutorizacion'> <b>No tienes un email registrado,</b> ingresa uno para continuar el proceso de Abono de Acreencias. Este email no reemplaza tus datos actuales. </p>);
  const textTerminosCondiciones = (<p className='textosAutorizacion'>Acepto los términos y condiciones establecidos en el poder especial y declaro haberlo leído.</p>);
  const cardDescription = (<p className='textAutorizo'>Al ingresar tu clave de internet, estarás firmando electrónicamente el poder especial de acuerdo a la ley 19.799.</p>);
  const documentHeader = 'Envío de comprobante';

  return (
    <>
      <Wrapper>
        <Stepper className="mb-24 start-xs" position={2} steps={steps} />
        <div className="row">
          <div className="col-xs-12 mt-8">
            <PoderEspecial
              nombreCliente={nombreCliente}
              persona={persona}
              banco={banco}
              tipoCuenta={tipoCuenta}
              numeroCuenta={numeroCuenta}
            />
            <ContratacionEmail
              persona={persona}
              email={email}
              emailError={emailError}
              emailErrorMessage={emailErrorMessage}
              handleChangeEmail={handleChangeEmail}
              texEnvioEmailAviso={texEnvioEmailAviso}
              texEnvioEmail={texEnvioEmail}
              textPersonEmail={textPersonEmail}
              textOtroEmail={textOtroEmail}
              textEmailNoTiene={textEmailNoTiene}
              documentHeader={documentHeader}
            />
            <ContratacionTerminos
              checked={checked}
              handleCheckBox={handleCheckBox}
              checkdRequiredError={checkdRequiredError}
              textTerminosCondiciones={textTerminosCondiciones}
            />
            <CardBox className="bmb-3 bmb-md-4">
              <FirmaElectronica
                id="firmaElectronica"
                checked={checked}
                password={password}
                handlePassword={handlePassword}
                passwordInputValidation={passwordInputValidation}
                handleValidationError={handleValidationError}
                validationError={validationError}
                showPassword={showPassword}
                onTogglePassword={togglePassword}
                loading={loading}
                cardDescription={cardDescription}
              />
            </CardBox>
            <StepsActions
              id="autorizacion"
              className="bmt-3 mt-24"
              backText="Volver"
              nextText="Continuar"
              backAction={handleGoBack}
              nextAction={handleSubmit}
              disableNextAction={nextActionButtonDisabled}
            />
          </div>
        </div>
      </Wrapper>
    </>
  );
}

AutorizacionTemplate.propTypes = {
  checked: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  showPassword: PropTypes.bool.isRequired,
  checkdRequiredError: PropTypes.bool.isRequired,
  banco: PropTypes.string.isRequired,
  tipoCuenta: PropTypes.isRequired,
  numeroCuenta: PropTypes.isRequired,
  email: PropTypes.string.isRequired,
  emailError: PropTypes.string.isRequired,
  persona: PropTypes.oneOfType([PropTypes.object]).isRequired,
  loading: PropTypes.bool.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  handlePassword: PropTypes.func.isRequired,
  handleChangeEmail: PropTypes.func.isRequired,
  passwordInputValidation: PropTypes.func.isRequired,
  handleValidationError: PropTypes.func.isRequired,
  nextActionButtonDisabled: PropTypes.bool.isRequired,
  validationError: PropTypes.bool.isRequired,
  togglePassword: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AutorizacionTemplate;
