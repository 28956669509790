import React from 'react';
import Typography from '@coopeuch-components/web/atoms/Typography';
import { formatStringToDate } from '../../../../utils/dates';
import { formatCurrency } from '../../../../utils/functions';

const Web = ({ data }) => {
  return (
    <>
      {data.map((item, index) => (
        <div className="row between-xs line-divider-top pt-16 pb-16" key={index}>
          <div className="col-md-4">
            <Typography tag="p">{`${formatStringToDate(item.fechaPago)} - ${item.horaPago.slice(
              0,
              5
            )} hrs.`}</Typography>
          </div>
          <div className="col-md-4">
            <Typography tag="p">{item.descripcion}</Typography>
          </div>
          <div className="col-md-2">
            <Typography tag="p" align="center">
              {item.cuotasPagadas}
            </Typography>
          </div>
          <div className="col-md-1">
            <Typography tag="p">{formatCurrency(item.valorCuota)}</Typography>
          </div>
          <div className="col-md-1">
            <Typography tag="p">{formatCurrency(item.montoTotalPagado)}</Typography>
          </div>
        </div>
      ))}
    </>
  );
};

export default Web;
