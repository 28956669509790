import styled from 'styled-components';

export const SwitchButton = styled.div`
  background-color: #fff;
  border-radius: 50%;
  transition: all 360ms ease-in-out;
  box-sizing: border-box;
  position: absolute;
  margin: 4px;
  cursor: pointer;
  height: 16px;
  width: 16px;
  top: 0;
`;

export const InputWrapper = styled.input`
  position: absolute;
  opacity: 0;
  height: inherit;
  margin: 0;
  cursor: pointer;
  width: inherit;
  left: 0;
  top: 0;

  &:checked + div {
    transform: translateX(24px);
  }
`;

const SwitchWrapper = styled.div`
  background-color: ${({ activeColor, disabled, checked }) => {
    let color = '';

    if (disabled) {
      color = '#9c9a9f';
    } else {
      color = checked ? activeColor : '#e1e1e1';
    }

    return color;
  }};
  border-radius: 48px;
  position: relative;
  margin: 0;
  height: 24px;
  width: 48px;
`;

export default SwitchWrapper;
