const defaultRequest = {
  id: 500,
  title: '¡Lo sentimos!',
  message:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
};

export const errClaveInternet = {
  1: {
    errorGeneral: true,
  },
  2: {
    id: 2,
    title: '¡Lo sentimos!',
    message: 'La contraseña ingresada es incorrecta.<br> Por favor vuelve a intentar',
    buttonTitle: 'Reintentar',
    showButton: true,
  },
  3: {
    id: 3,
    title: '¡Lo sentimos!',
    message:
      'La contraseña ingresada es incorrecta. Por favor vuelve a intentar.<br> Recuerda que <b>al tercer intento erróneo</b>, tu clave será bloqueada.',
    buttonTitle: 'Reintentar',
    showButton: true,
  },
  4: {
    id: 4,
    title: '¡Clave bloqueada!',
    message:
      'Por tu seguridad, hemos bloqueado tu clave. Si lo deseas<br> puedes volver a cambiar tu clave.',
    buttonTitle: 'Cambiar clave',
    showButton: true,
  },
};

export default defaultRequest;
