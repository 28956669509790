
const initState = {
  remanenteMontoTotal: null,
  bannerEstacional: {},
  remanenteCalculo: null,
  saldoIndividual: {},
  composicion: null,
  composicionError: false,
  productosDisponibles: {},
  error: false,
  contenidoError: false,
  cobreRemanenteWeb: { success: false, error: false, errorCode: '', data: null, date: null },
  switchOnOff: {
    data: null,
    error: null,
    success: null
  },
  cobrarRemanente: {
    error: false,
    success: false,
  },
  comprobanteCobro: null,
  errorLog: '',
  errorLogObtenerCuentas: '',
  errorLogObtenerTarjetas: '',
  errorCobrarRemanenteLog: '',
  errorComprobanteCobroLog: '',
  emailComprobante: false,
  emailComprobanteError: false,
};

export default initState;
