import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Alert from '@coopeuch-components/web/atoms/Alert';
import Input from '@coopeuch-components/web/atoms/Input';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Select from '../../../atoms/Select';
import { capitalize, capitalizeDescriptions } from '../../../../utils/functions';
import { formatRut } from '../../../../utils/formatters';

import DashboardCard from '../../DashboardCard';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const FormularioDatosDestinatario = ({
  onChangeHandle,
  texts,
  numeroCuentaRut,
  destinatario,
  tipoCuentas,
  bancos,
  errors,
  isLoading,
  isEditDestinatario,
  isTransferirFondos,
  origenSeleccionado,
  cuentasDelUsuario,
  currentUserRut,
}) => {
  const upperCase = (word) => word.toUpperCase();

  const codigosEspeciales = [{ value: 16, pattern: [capitalize, upperCase] }];

  const memoBancoOptions = useMemo(() => {
    let bancosTemp = bancos.map((item) => ({ label: item.descripcion, value: item.codigo }));
    return capitalizeDescriptions(bancosTemp, codigosEspeciales);
  }, [bancos]);

  const memoCuentaOptions = useMemo(
    () =>
      tipoCuentas.map((cuenta) => ({
        label: capitalize(cuenta.descripcion),
        value: cuenta.codigo,
      })),
    [tipoCuentas]
  );

  const memoHasOnlyMonederoDigital = useMemo(() => {
    return (
      (isTransferirFondos && origenSeleccionado?.codigoProducto === 'VI06') ||
      (cuentasDelUsuario.length ===
        cuentasDelUsuario?.filter((cuenta) => cuenta.codigoProducto === 'VI06')?.length &&
        cuentasDelUsuario.length >= 1)
    );
  }, [cuentasDelUsuario, origenSeleccionado, isTransferirFondos]);

  const AlertTefCtaVistaRut = () => {
    return (
      <div className="row">
        <div className="col-xs-12">
          <Alert className="alert-cdp-pendiente bp-3 " bgType="info" id="alert-cdp-pendiente" show>
            También puedes transferir a una Cuenta Vista Coopeuch, agregando el RUT del destinatario
            (sin digito verificador) como número de cuenta.
          </Alert>
        </div>
      </div>
    );
  };

  return (
    <DashboardCard className="ml-0 mr-0">
      <DashboardCard.Head
        title={isEditDestinatario ? texts.editTitle : texts.addTitle}
        borderBottom="complete"
        classes="btext-xs-left bmt-xs-3 bmt-sm-4"
      />
      <DashboardCard.Body classes="text-left-destinatarios black">
        <p className="pb-32 pt-24 pl-8">
          {isEditDestinatario ? texts.editDescription : texts.addDescription}
        </p>
        <div>
          <div className="row">
            <div className="col-md-4 mb-32 col-sm-12  w-100">
              <div className="ml-4 mr-4">
                <p className="text-bold mb-14 black">{texts.name}*</p>
                {isEditDestinatario ? (
                  <p>{destinatario?.nombreCompleto}</p>
                ) : (
                  <Input
                    borderColor="#646569"
                    size={48}
                    textSize={16}
                    onChange={onChangeHandle}
                    dataSet="nombre"
                    disabled={isLoading}
                    id="nombre-destinatario"
                    value={destinatario?.nombre}
                    type="text"
                    maxLength={30}
                    rounded={4}
                    placeholder="Ingresa Nombre completo"
                  />
                )}
              </div>
            </div>
            <div className="col-md-4 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                <p className="text-bold mb-14 black">{texts.rut}*</p>
                {isEditDestinatario ? (
                  <p>{formatRut(destinatario?.rut || '')}</p>
                ) : (
                  <>
                    <Input
                      borderColor={errors.rut ? '#CC284E' : '#646569'}
                      size={48}
                      placeholder="Ej: 12345678-9"
                      textSize={16}
                      onChange={onChangeHandle}
                      dataSet="rut"
                      id="rut-destinatario"
                      value={memoHasOnlyMonederoDigital ? currentUserRut : destinatario?.rut}
                      type="text"
                      disabled={isLoading || Boolean(currentUserRut && memoHasOnlyMonederoDigital)}
                      maxLength={12}
                      rounded={4}
                    />
                    {errors.rut && (
                      <div className="text-size-s error mt-8 error--input--message">
                        <div className="row">
                          <LoadIcon
                            src={`${iconPath}circle_close.svg`}
                            size={14}
                            color="#CC284E"
                            className="mr-8 error"
                          />
                          <p>{texts.rutError}</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4 with--help-text">
                <p className="text-bold mb-14 black">{texts.email}</p>
                <Input
                  size={48}
                  borderColor={errors.email && destinatario?.email ? '#CC284E' : '#646569'}
                  textSize={16}
                  onChange={onChangeHandle}
                  dataSet="email"
                  invalid={errors.email && destinatario?.email}
                  disabled={isLoading}
                  id="email-destinatario"
                  value={destinatario?.email}
                  type="text"
                  rounded={4}
                  placeholder="Ingresa email válido"
                />
                {errors.email && destinatario?.email && (
                  <div className="text-size-s error mt-8 error--input--message">
                    <div className="row">
                      <LoadIcon
                        src={`${iconPath}circle_close.svg`}
                        size={14}
                        color="#CC284E"
                        className="mr-8 error"
                      />
                      <p>{texts.emailError}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 mb-32 col-sm-12  w-100">
            <div className="ml-4 mr-4">
              <p className="text-bold mb-14 black">{texts.bank}*</p>
              {isEditDestinatario ? (
                <p>{capitalize(destinatario?.banco)}</p>
              ) : (
                <Select
                  borderColor="#333"
                  placeholder={texts.bankPlaceholder}
                  labelClass="mb-10 mt-8 mb-8"
                  onChange={onChangeHandle}
                  options={memoBancoOptions}
                  value={destinatario?.banco}
                  id="banco-destinatario"
                  size={48}
                  dataSet="banco"
                  rounded={4}
                />
              )}
            </div>
          </div>
          <div className="col-md-4 mb-32 col-sm-12 w-100">
            <div className="ml-4 mr-4">
              <p className="text-bold mb-14 black">{texts.typeAccount}*</p>
              {isEditDestinatario ? (
                <p>{capitalize(destinatario?.tipoCuenta)}</p>
              ) : (
                <Select
                  borderColor="#333"
                  placeholder={texts.typeAccountPlaceholder}
                  labelClass="mb-10 mt-8 mb-8"
                  onChange={onChangeHandle}
                  options={memoCuentaOptions}
                  dataSet="tipoCuenta"
                  id="tipo-destinatario"
                  value={destinatario?.tipoCuenta}
                  size={48}
                  rounded={4}
                />
              )}
            </div>
          </div>
          <div className="col-md-4 mb-32 col-sm-12  w-100">
            <div className="ml-4 mr-4">
              <p className="text-bold mb-14 black">{texts.numberAccount}*</p>
              {isEditDestinatario ? (
                <p>{destinatario?.numeroCuenta}</p>
              ) : (
                <Input
                  borderColor="#646569"
                  size={48}
                  textSize={16}
                  disabled={isLoading}
                  onChange={onChangeHandle}
                  id="numeroCuenta-destinatario"
                  dataSet="numeroCuenta"
                  value={destinatario.numeroCuenta}
                  type="text"
                  maxLength={19}
                  rounded={4}
                  placeholder="Ingresa número"
                />
              )}
            </div>
          </div>
        </div>

        {numeroCuentaRut.mostrarAlert && <AlertTefCtaVistaRut />}
      </DashboardCard.Body>
    </DashboardCard>
  );
};

FormularioDatosDestinatario.propTypes = {
  onChangeHandle: PropTypes.func,
  isLoading: PropTypes.bool,
  isTransferirFondos: PropTypes.bool,
  isEditDestinatario: PropTypes.bool,
  numeroCuentaRut: PropTypes.bool,
  destinatario: PropTypes.shape({
    rut: PropTypes.string,
    nombre: PropTypes.string,
    banco: PropTypes.string,
    tipoCuenta: PropTypes.string,
    numeroCuenta: PropTypes.string,
    email: PropTypes.string,
    alias: PropTypes.string,
    id: PropTypes.number,
  }),
  errors: PropTypes.shape({
    rut: PropTypes.bool,
    email: PropTypes.bool,
  }),
  tipoCuentas: PropTypes.array,
  bancos: PropTypes.array,
  texts: PropTypes.object,
  memoHasOnlyMonederoDigital: PropTypes.bool,
  currentUserRut: PropTypes.string,
};

FormularioDatosDestinatario.defaultProps = {
  onChangeHandle: null,
  isLoading: false,
  isTransferirFondos: false,
  isEditDestinatario: false,
  numeroCuentaRut: '',
  destinatario: {
    rut: '',
    nombre: '',
    banco: '',
    tipoCuenta: '',
    numeroCuenta: '',
    email: '',
    alias: '',
    id: null,
    esFavorito: null,
  },
  errors: {
    rut: false,
    email: false,
  },
  tipoCuentas: [],
  bancos: [],
  texts: {},
  memoHasOnlyMonederoDigital: false,
  currentUserRut: '',
};

export default FormularioDatosDestinatario;
