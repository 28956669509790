import React, { PureComponent } from 'react';
import { produce } from 'immer';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { gestionClaveActions } from '../../../application/actions/gestionClave';

import StepperRouter from './StepperRouter';

import { rutUnformat } from '../../utils/formatters';
import { registerGA } from '../../utils/metrics';

class RecuperarOCrearPassword extends PureComponent {
  state = {
    pathStep: 1,
    rut: '',
    factorSelected: '',
    type: '',
  };

  onChangeFactorSeleccionado = (factor) => {
    this.setState(
      produce((draft) => {
        draft.factorSelected = factor;
      })
    );
  };

  onStepsHandle = (step) => {
    this.setState(
      produce((draft) => {
        draft.pathStep = step;
      })
    );
  };

  onRutHandle = (rut) => {
    this.setState(
      produce((draft) => {
        draft.rut = rutUnformat(rut).toUpperCase();
      })
    );
  };

  componentDidMount() {
    const { history } = this.props;
    const { from } = history.location.state || {};

    if (from === 'login') {
      history.go(0);
    }

    if (from === undefined) {
      if (history.location.pathname === '/crear-clave') {
        registerGA({
          event: 'trigger_event',
          'event-config': { 'eve-acc': '/login', 'eve-cat': 'Click', 'eve-lab': 'Crea tu clave' },
        });
      } else {
        registerGA({
          event: 'trigger_event',
          'event-config': {
            'eve-acc': '/login',
            'eve-cat': 'Click',
            'eve-lab': 'Olvidaste tu clave',
          },
        });
      }
      this.setState(
        produce((draft) => {
          draft.type = history.location.pathname === '/crear-clave' ? 'crear' : 'recuperar';
        })
      );
    }
    // se crea bandera para el captcha
    if (localStorage.getItem('RefreshTokenCaptcha') === 'true') {
      window.location.reload();
      localStorage.setItem('RefreshTokenCaptcha', 'false');
      localStorage.setItem('pathLogin', from);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <script src="https://www.google.com/recaptcha/api.js"></script>
        </Helmet>
        <StepperRouter
          {...this.props}
          {...this.state}
          onChangeFactorSeleccionado={this.onChangeFactorSeleccionado}
          onStepsHandle={this.onStepsHandle}
          onRutHandle={this.onRutHandle}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  trackId: entities.gestionClave.trackId,
  factoresSeguridad: entities.gestionClave.factoresSeguridad,
  reglasFactores: entities.gestionClave.reglasFactores,
  envioDesafioOTP: entities.gestionClave.envioDesafioOTP,
  datosPersonales: entities.gestionClave.datosPersonales,
  errorReglasFactores: entities.gestionClave.errorReglasFactores,
  errorDatosPersonales: entities.gestionClave.errorDatosPersonales,
  errorDatosSinTelefonoIBS: entities.gestionClave.errorDatosSinTelefonoIBS,
  smsErrors: entities.gestionClave.smsErrors,
  isLoadingReglasTerceros: ui.loaders.isLoadingReglasTerceros,
  isLoadingDesafioSMS: ui.loaders.isLoadingDesafioSMS,
  isLoadingDatosPersonales: ui.loaders.isLoadingDatosPersonales,
  isLoadingValidarSMS: ui.loaders.isLoadingValidarSMS,
  reglasCrearClave: entities.gestionClave.reglaClaves,
  errorReglaClaves: entities.gestionClave.errorReglaClaves,
  errorValidarRut: entities.gestionClave.errorValidarRut,
  errorFactoresSeguridad: entities.gestionClave.errorFactoresSeguridad,
  successCrearClave: entities.gestionClave.successCrearClave,
  errorCrearClave: entities.gestionClave.errorCrearClave,
  errorClaveMessage: entities.gestionClave.errorClaveMessage,
  isLoadingValidarOCrear: ui.loaders.isLoadingValidarRutOCrearClave,
  isLoadingFactoresSeguridad: ui.loaders.isLoadingFactoresSeguridad,
  isLoadingReglasCrearClave: ui.loaders.isLoadingReglasCrearClave,
  desafioOTPValido: entities.gestionClave.desafioOTPValido,
  successSelectMetodo: entities.gestionClave.successSelectMetodo,
  isLoadingSelectMetodo: ui.loaders.isLoadingSelectMetodo,
  tarjetaCoordenadasErrors: entities.gestionClave.tarjetaCoordenadasErrors,
  desafio1: entities.gestionClave.desafio1,
  desafio2: entities.gestionClave.desafio2,
  desafio3: entities.gestionClave.desafio3,
  desafioTCOOValido: entities.gestionClave.desafioTCOOValido,
  isLoadingDesafioYValidarFactorSeguridad: ui.loaders.isLoadingDesafioYValidarFactorSeguridad,
  passCoopeuchErrors: entities.gestionClave.passCoopeuchErrors,
  obtencionFactorCPASS: entities.gestionClave.obtencionFactorCPASS,
  desafioCPASSValido: entities.gestionClave.desafioCPASSValido,
  redirectOrigin: entities.gestionClave.redirectOrigin,
});

const mapDispatchToProps = (dispatch) => ({
  fetchValidarRut(params) {
    dispatch(gestionClaveActions.validarRutToCrearClave(params));
  },
  redirectFromDiffOrigins(params) {
    dispatch(gestionClaveActions.redirectFromDiffOrigins(params));
  },
  resetErrorValidarRut(params) {
    dispatch(gestionClaveActions.resetErrorValidarRut(params));
  },
  fetchFactoresSeguridadCrearClave(params) {
    dispatch(gestionClaveActions.fetchFactoresSeguridadCrearClave(params));
  },
  fetchReglasFactores() {
    dispatch(gestionClaveActions.fetchReglasFactores());
  },
  fetchDesafioOTP(params) {
    dispatch(gestionClaveActions.fetchDesafioOTP(params));
  },
  validarDesafioOTP(params) {
    dispatch(gestionClaveActions.validarDesafioOTP(params));
  },
  fetchDatosPersonalesCrearClave(params) {
    dispatch(gestionClaveActions.fetchDatosPersonalesCrearClave(params));
  },
  validarUsingFactorFailed(params) {
    dispatch(gestionClaveActions.validarUsingFactorFailed(params));
  },
  fetchReglasCrearClave() {
    dispatch(gestionClaveActions.fetchReglasCrearClave());
  },
  crearClave(params) {
    dispatch(gestionClaveActions.crearClave(params));
  },
  recuperarClave(params) {
    dispatch(gestionClaveActions.recuperarClave(params));
  },
  selectMetodo(params) {
    dispatch(gestionClaveActions.selectMetodo(params));
  },
  fetchDesafioTCOO(params) {
    dispatch(gestionClaveActions.fetchDesafioTCOO(params));
  },
  validarDesafioTCOO(params) {
    dispatch(gestionClaveActions.validarDesafioTCOO(params));
  },
  fetchDesafioCPASS(params) {
    dispatch(gestionClaveActions.fetchDesafioCPASS(params));
  },
  validarDesafioCPASS(params) {
    dispatch(gestionClaveActions.validarDesafioCPASS(params));
  },
  returnMetodo() {
    dispatch(gestionClaveActions.returnMetodo());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RecuperarOCrearPassword));
