import styled from 'styled-components';

const Wrapper = styled.div`
  .dashboard-encuesta-wrapper {
    padding: 0px;
    min-height: 104px;
  }

  .dashboard-encuesta-button {
    font-weight: bold;
    width: 168px;
  }
  .accesoss{
    border: 3px solid #007DB7; 
    padding-right: -2px; 
    display: flex;
    width: 100%; 
    border-radius: 4px; 
    padding-right: 0px;
    padding-left: 4px;
    padding-top: 12px; 
    padding-bottom: 15px;
  }
  .simulacionn{
    border: 3px solid #007DB7;
    padding-right: -50px; 
    display: flex;
    padding-left: 0px;
    margin-left: auto;
    right: 0;
    border-radius: 4px;
  }

  .dashboard-encuesta-text-container {
    display: inline-flex;
  }

  .dashboard-encuesta-text {
    font-size: 20px;
    font-weight: bold;
    color: #383638;
    letter-spacing: 0;
    line-height: 24px;
    font-family: 'Ubuntu-Medium';
  }

  .dashboard-encuesta-button:hover {
    background: #e00000;
    color: #ebebeb;
    & path {
      fill: white;
    }
  }
  .fondsimula{
    background: white;
    margin-left: -2px;
  }

  .dashboard-title {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .access-text {
    color: #383638;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
    font-weight: bold;
  }

  .access-icon {
    display: inline-flex;
  }

  .bloq-icon {
    margin-bottom: 7px;
  }

  .transferir-icon {
    margin-bottom: 6px;
  }

  .dashboard-access-container {
    padding: 20px 0px 20px 0px;
    min-height: 104px;
    cursor: pointer;
  }

  .text-encuesta {
    color: #383638;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px;
  }

  .text-desc-encuesta {
    color: #383638;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .encuesta-link {
    color: #f42534;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .encuesta-icon svg {
    height: 108px;
    width: 228px;
  }

  .encuesta-icon-container {
    margin-bottom: -24px;
    width: 234px;
  }

  .encuesta-title-container {
    margin-top: 16px;
    margin-left: 32px;
  }

  .encuesta-title-text, .norma484-title-text {
    color: #383838;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    font-family: 'Ubuntu-Medium';
  }

  .encuesta-desc-container {
    margin-left: 32px;
    margin-top: 4px;
  }

  .encuesta-desc-text, .norma484-desc-text {
    color: #383638;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .encuesta-link-text {
    color: #f42534;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-decoration: underline;
    cursor: pointer;
  }

  .container_image{
    display: flex;
    align-self: end;
    justify-content: right;
  }
  .image {
    max-width: 184px;
    margin-bottom: -10px;
  }
  
  .pd-0 {
    padding: 0;
  }
  
  .norma-484-container {
    padding-right: 8px;
    align-items: end;
    
  }
  
  .norma484-text-container {
    height: 103px;
    width: 0;
    flex-grow: 1;
  }
  
  .norma484-image-container {
    width: 200px;
  }


  @media screen and (max-width: 1250px) and (min-width: 1023px), screen and (max-width: 470px) {
    
    .norma484-title-text {
      font-size: 15px;
    }
    
    .norma484-desc-text {
      font-size: 13px;
    }
    
    .norma484-image-container {
      width: 110px;
    }
    
  }


    @media screen and (max-width: 768px) {
    .image {
    margin-bottom: 0px;
  }
    .dashboard-encuesta-button {
      width: 100%;
    }
    .accesoss{
      border: 3px solid #007DB7; 
       
      display: flex;
      width: 100%; 
      border-radius: 5px; 
      padding-right: 0px;
      padding-left: 4px;
      padding-top: 25px; 
      padding-bottom: -10px;
    }
    .dashboard-encuesta-wrapper {
      padding: 16px 16px 16px 16px;
    }
    .encuesta-title-container {
      margin-top: 16px;
      margin-left: 16px;
    }
    .encuesta-desc-container {
      margin-left: 16px;
    }
    .dashboard-encuesta-wrapper {
      padding: 0px;
      min-height: 104px;
    }
    .dashboard-width {
      min-width: 99.66px;
    }
    .dashboard-center-2lines-text {
      max-width: 68px;
      text-align: center;
      margin: 0 auto;
    }
    .fondsimula{
      background: white;
      margin-left: 0px;
    }
    .encuesta-icon-container {
      margin-bottom: -24px;
      margin-left: 0px;
      left: 0;
      width: 115px;
      height: 100%;
      object-fit: contain;
    }

  }

  @media screen and (max-width: 340px) {

    .norma484-image-container {
      width: 80px;
    }
  }
`;

export const Image = styled.div`
  height: 104px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  color: #fff;
`;

export default Wrapper;
