import React from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import Skeleton from 'react-loading-skeleton';

function SkeletonCuenta() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="modulo">
        <div className="row start-xs modulo-content">
          <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
            <Skeleton height={10} width={152} className="hide-on-mobile" />
            <Skeleton height={10} width="100%" className="hide-on-desktop" />
          </div>
          <div className="col-xs-12 pt-54">
            <div className="row">
              {[1, 2]?.map((key) => (
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6" key={key}>
                  <Skeleton
                    height={10}
                    width={152}
                    className={`${key === 2 && 'hide-on-mobile'}`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="row middle-xs">
              <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 pt-12">
                <Skeleton height={48} width={296} className="hide-on-mobile" />

                <Skeleton height={48} width="100%" className="hide-on-desktop" />
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 pt-12">
                <Skeleton height={10} width={122} className="hide-on-mobile" />

                <div className="hide-on-desktop">
                  <div className="pt-16">
                    <Skeleton height={10} width={152} />
                  </div>
                  <div className="pt-24">
                    <Skeleton height={10} width={152} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonSummary() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="modulo">
        <div className="row start-xs modulo-content">
          <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
            <Skeleton height={10} width={152} className="hide-on-mobile" />
            <Skeleton height={10} width="100%" className="hide-on-desktop" />
          </div>
          <div className="col-xs-12 pt-36">
            <div className="row">
              {[1, 2]?.map((key) => (
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 mt-12" key={key}>
                  <Skeleton height={10} width={152} />
                </div>
              ))}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="row">
              {[1, 2]?.map((key) => (
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 mt-12" key={key}>
                  <Skeleton height={10} width={122} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonEmail() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="modulo">
        <div className="row start-xs modulo-content">
          <div className="col-xs-12 col-sm-5 col-md-3 col-lg-3">
            <Skeleton height={10} width={174} />
          </div>
          <div className="col-xs-12 pt-54">
            <Skeleton height={47} width="100%" />
          </div>
          <div className="col-xs-12 pt-24">
            <Skeleton height={10} width="70%" />
          </div>

          <div className="col-xs-12 pt-32">
            <Skeleton height={10} width={144} />
          </div>
          <div className="col-xs-12 pt-12">
            <div className="row middle-xs">
              <div className="col-xs-6">
                <Skeleton height={47} width={298} className="hide-on-mobile" />
                <Skeleton height={47} width={264} className="hide-on-desktop" />
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonPassword() {
  return (
    <div className="col-xs-12">
      <DashboardCard classes="modulo">
        <div className="row start-xs modulo-content">
          <div className="col-xs-12">
            <Skeleton height={10} width="60%" />
          </div>
          <div className="col-xs-12 pt-36">
            <Skeleton height={10} width={214} />
          </div>

          <div className="col-xs-5">
            <div className="row">
              <div className="col-xs-12 pt-12 pb-12">
                <Skeleton height={47} width={298} className="hide-on-mobile" />
                <Skeleton height={47} width={264} className="hide-on-desktop" />
              </div>
              <div className="col-xs-12">
                <div className="row">
                  <div className="col-xs-9">
                    <Skeleton height={10} width={174} />
                  </div>
                  <div className="col-xs">
                    <Skeleton height={10} width={24} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </div>
  );
}

function SkeletonPagarTarjetaPago({ children }) {
  return (
    <div className="row modulos-container">
      {children}

      <div className="col-xs-12">
        <div className="row middle-xs">
          <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 start-xs">
            <div className="d-flex middle-items">
              <Skeleton height={10} width={84} className="hide-on-mobile" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 end-xs">
            <Skeleton height={48} width={178} className="hide-on-mobile" />
            <Skeleton height={48} width="100%" className="hide-on-desktop" />

            <div className="w-100 pt-12 middle-items hide-on-desktop">
              <Skeleton height={10} width={84} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SkeletonPagarTarjetaPago.Cuenta = SkeletonCuenta;
SkeletonPagarTarjetaPago.Summary = SkeletonSummary;
SkeletonPagarTarjetaPago.Email = SkeletonEmail;
SkeletonPagarTarjetaPago.Password = SkeletonPassword;

export default SkeletonPagarTarjetaPago;
