const initState = {
  credito: { success: false, error: false, data: null, date: null },
  cuota: { success: false, error: false, data: null, date: null },
  historialPago: { success: false, error: false, data: null, date: null },
  historialPdfPago: { success: false, error: false, data: null, date: null },
  pay: {
    loading: false,
    success: false,
    error: { server: false, password: false },
    data: {},
    loanToPay: {
      paymentAllowed: false,
      automaticPaymentMethod: null,
    },
  },
  availableAccounts: {
    loading: false,
    success: false,
    error: { server: false, noData: false },
    data: [],
  },
  comprobantePago: { success: false, error: false, data: {} },
  comprobantePdfPago: { success: false, error: false, data: null },
  ventanaHoraria: {success: false, error: false, data: null},
};

export default initState;
