import React from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import ButtonWithSpinner from '../../../../atoms/ButtonWithSpinner';
import DashboardCard from '../../../../organism/DashboardCard';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import radioOff from '../../../../../assets/svg/rb1.svg';
import radioOn from '../../../../../assets/svg/rb2.svg';

import { confirmacionTexts } from '../../../../../containers/BloqueoTarjetas/texts';

import Wrapper from './styles';
import FactorClaveInternet from '../../../../organism/FactoresDeSeguridad/ClaveInternet';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ConfirmacionTemplate = ({
  motivoSeleccionado,
  handleMotivo,
  handleShowPassword,
  showClave,
  valuePassword,
  onHandleInput,
  handleBloqueo,
  mensajeErrorCambioEstado,
  motivoTarjetaBloqueo,
  handleClickReturn,
  loadingCambioEstado,
}) => {
  return (
    <Wrapper>
      <div className="bmb-md-4 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Head title={confirmacionTexts.TITLE_MOTIVO} borderBottom="complete" />
          <DashboardCard.Body classes="bloqueo-definitivo-title">
            <div className="bmb-3">
              <label className="bloqueo-confirmacion-motivo-desc">
                {confirmacionTexts.DESC_MOTIVO}
              </label>
            </div>
            {motivoTarjetaBloqueo?.values?.map((motivo, index) => (
              <div
                className="bmb-3 bloqueo-confirmacion-select"
                onClick={() => handleMotivo(motivo?.codigo)}
                key={index}
              >
                <img src={motivoSeleccionado === motivo?.codigo ? radioOn : radioOff} />
                <div className="bloqueo-confirmacion-container">
                  <label className="bloqueo-confirmacion-motivo-select">
                    {motivo?.descripcion}
                  </label>
                </div>
              </div>
            ))}
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmb-md-4 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Head title={confirmacionTexts.TITLE_FIRMA} borderBottom="complete" />
          <DashboardCard.Body classes="bloqueo-definitivo-title">
            <div className="bmb-4">
              <label className="bloqueo-firma-text">{confirmacionTexts.DESC_FIRMA}</label>
            </div>
            <div className="row">
              <div className="col-md-4 col-xs-12">
                <div className="bloqueo-definitivo-factor bmb-3">
                  <label>Ingresa tu clave de internet</label>
                </div>
                <FactorClaveInternet
                  onTogglePassword={handleShowPassword}
                  showClave={showClave}
                  value={valuePassword}
                  onHandlePasswordInput={onHandleInput}
                  placeholder="Ingresar Clave"
                  error={mensajeErrorCambioEstado}
                />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="row bmb-md-4 bmb-3">
        <div className="col-md-6 col-xs-12 return-bloqueo-center">
          <Button
            color="#EC1B2B"
            className="none-underline middle-items w-100"
            asTextLink
            onClick={() => handleClickReturn(1)}
          >
            <div className="row middle-xs">
              <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} className="mr-8 back--icon" />
              <span>{confirmacionTexts.RETURN_BUTTON}</span>
            </div>
          </Button>
        </div>
        <div className="col-md-6 col-xs-12 text-right">
          <div className="bloqueo-definitivo-button-container">
            <ButtonWithSpinner
              bgColor="#E81E2B"
              color="white"
              id="btn"
              className="button-style-bloqueo"
              disabled={!(motivoSeleccionado !== '' && valuePassword.length > 3)}
              onClick={handleBloqueo}
              loading={loadingCambioEstado}
            >
              {confirmacionTexts.CONTINUE_BUTTON}
            </ButtonWithSpinner>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default ConfirmacionTemplate;
