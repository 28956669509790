import styled, { keyframes, css } from 'styled-components';
import { darken } from 'polished';
import { colors } from '../../../assets/styles';
export const IconLeft = styled.div`
  float: left;
  margin-right: 8px;

  & > div,
  & > img,
  & > svg {
    height: 24px;
    width: 24px;
  }

  & path {
    fill: ${({ color }) => color};
  }
`;
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > svg {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    transform-origin: center;
    animation: ${spin} 2s linear infinite;
    path {
      color: white;
    }
  }
  svg {
    fill: ${() => colors.white}!important;

    path {
      fill: ${() => colors.white}!important;
    }
  }
`;

export const IconRight = styled.div`
  float: right;
  margin-left: 8px;

  & > div,
  & > svg {
    height: 24px;
    width: 24px;
  }

  & path {
    fill: ${({ color }) => color};
  }
`;

const BaseButton = styled.button`
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  height: 48px;
  border: 0;
  display: flex;
  justify-content: center;

  ${({ asTextLink }) =>
    !asTextLink &&
    `
    padding: 12px 24px;
    filter: drop-shadow(0 2px 2px rgba(187, 188, 188, 0.35));
  `}

  ${({ fullWidth, width }) =>
    fullWidth ? 'width: 100%;' : `width: ${width ? `${width}px;` : 'auto;'}`}

  ${({ bgColor, asTextLink, inverse, color }) => {
    return inverse
      ? `
        background-color: ${asTextLink ? 'transparent' : color};
        ${!asTextLink && `border: 1px solid ${bgColor};`}
        color: ${bgColor};
        path {
          fill: ${bgColor};
        }
        `
      : `
        background-color: ${asTextLink ? 'transparent' : bgColor};
        color: ${color};
      `;
  }}

  &:hover {
    ${({ bgColor, color, inverse, asTextLink }) => {
      return inverse
        ? `
        ${bgColor && !asTextLink && `background-color: ${darken(0.08, color)};`}
        ${color && `color: ${darken(0.06, bgColor)};`}
      `
        : `
        ${bgColor && !asTextLink && `background-color: ${darken(0.08, bgColor)};`}
        ${color && `color: ${darken(0.06, color)};`}
      `;
    }}
  }

  ${(props) =>
    props.disabled
      ? css`
          background-color: #d6d0d5 !important;
          cursor: not-allowed !important;
        `
      : ''}
`;

export default BaseButton;
