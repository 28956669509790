import styled from 'styled-components';

export const Message = styled.div`
  background-color: #f4f4f4;
  border: 1px dashed #979797;
  padding: 14px 18px;
  .icon {
    display: inline-block;
    background-color: #ffffff;
    height: 40px;
    width: 40px;
    padding: 7px 0px 7px 5px;
    border-radius: 30px;
    border: 1px dashed #b5b5b5;
  }
  .text {
    vertical-align: super;
    margin-left: 8px;
   
  }
`;
