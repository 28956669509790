import styled from 'styled-components';

const Wrapper = styled.div`
  .containerPass {
    display: flex;
    align-items: flex-start;
    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: center;
    }
    &__image {
      width: 100%;
      max-width: 178px;
      @media (max-width: 991px) {
        display: block;
        margin: 0 auto;
      }
    }
    &__info {
      display: flex;
      flex-direction: column;
      padding-left: 40px;
      padding-right: 40px;
      width: 100%;
      max-width: 430px;
      @media (max-width: 991px) {
        text-align: center;
        max-width: 100%;
        margin-top: 20px;
        margin-bottom: 20px;
      }
      @media (max-width: 390px) {
        text-align: initial;
      }
      &-title {
        font-family: 'Ubuntu-Bold';
        font-size: 16px;
        margin-bottom: 13px;
      }
      &-description {
        font-size: 14px;
      }
    }

    &__apps {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      &-image {
        width: 100%;
        max-width: 114px;
        margin-left: 20px;
      }
    }
  }

  .visible-desktop {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .visible-mobile {
    display: none;
    @media (max-width: 991px) {
      display: block;
    }
  }
`;

export default Wrapper;
