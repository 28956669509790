const initState = {
  obtenerTarjetas: { success: false, error: false, tarjetas: [] },
  tarjetasCredito: [],
  tarjetaCreditoError: false,
  facturacionTarjetaCredito: {},
  facturacionTarjetaCreditoError: false,
  cupoTarjetaCredito: {},
  cupoTarjetaCreditoError: false,
  patPass: '',
  patPassError: false,
  pagoConfirmado: false,
  pagoError: {},
  pagoComprobante: {},
  pagoComprobanteError: false,
  cuposTarjetasAdicionales: {},
  cuposTarjetasAdicionalesError: false,
  movimientos: {},
  movimientosError: false,
  pdfTarjetaCredito: '',
  pdfTarjetaCreditoError: false,
  errorNoEstadoDeCuenta: false,
};

export default initState;
