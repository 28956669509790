/* eslint-disable no-nested-ternary */
import React from 'react';

// Components
import GoogleMapReact from 'google-map-react';

// Ui
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Skeleton from 'react-loading-skeleton';
import Select from '../../../../../../atoms/Select';

// Styles
import Container, { MapWrapper, Marker, Icon, TimeText } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  fetchingData,
  region,
  regionOptions,
  comuna,
  comunaOptions,
  notSucursal,
  sucursal,
  isLoadingSucursalesComuna,
  sucursalOptions,
  handleOnChangeRegion,
  handleOnChangeSucursal,
  handleOnChangeComuna,
}) => {
  const zoom = 16;
  const center = {
    lat: sucursal ? parseFloat(sucursal.latitud || 0) : 0,
    lng: sucursal ? parseFloat(sucursal.longitud || 0) : 0,
  };
  const markerStyle = {
    position: 'absolute',
    fontSize: '12px',
    width: '250px',
    top: '-190px',
    left: '-125px',
  };

  return (
    <Container>
      <div className="row">
        <div className="col-xs-12">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Alert
              className="alert-info col-xs bpy-md-3 "
              bgType="warning"
              id="alert-factibilidad-ok"
              show
            >
              Recuerda que
              <b> sólo tú puedes recibir tus productos, </b>
              con tu cédula de identidad en mano.
            </Alert>
          )}
        </div>
      </div>

      <div className="row bmt-3">
        <div className="col-xs-12 col-md-4">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Select
              value={region ? region.value : ''}
              title="Región"
              placeholder="Todas"
              labelClass="bmy-3 text-bold"
              options={regionOptions}
              onChange={handleOnChangeRegion}
            />
          )}
        </div>
        <div className="col-xs-12 col-md-4">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Select
              value={comuna ? comuna.value : ''}
              title="Comuna"
              placeholder="Selecciona Comuna"
              labelClass="bmy-3 text-bold"
              options={comunaOptions}
              onChange={handleOnChangeComuna}
            />
          )}
        </div>
        <div className="col-xs-12 col-md-4">
          {isLoadingSucursalesComuna ? (
            <Skeleton />
          ) : !isLoadingSucursalesComuna && notSucursal ? (
            <Select
              value="nada"
              options={[{ label: null, value: null }]}
              labelClass="bmy-3 text-bold"
              title="Sucursal"
              placeholder="Todas"
              // onChange={handleOnChangeSucursal}
            />
          ) : (
            <Select
              value={sucursal ? sucursal.value : ''}
              options={sucursalOptions}
              labelClass="bmy-3 text-bold"
              title="Sucursal"
              placeholder="Todas"
              onChange={handleOnChangeSucursal}
            />
          )}
        </div>

        {!!sucursal && !notSucursal && (
          <MapWrapper className="col-xs-12 mt-24">
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_MAPS_API_KEY }}
              center={center}
              zoom={zoom}
              // defaultCenter={defaultProps.center}
              // defaultZoom={defaultProps.zoom}
            >
              <div {...center} style={markerStyle} text="My Marker">
                <Marker>
                  <div>
                    <b>{sucursal.descripcion}</b>
                    <p>{sucursal.direccion}</p>
                    <p>Horarios de Atención:</p>
                    <p>{sucursal.horarioAtencion}</p>
                  </div>
                </Marker>
                <Icon className="col-xs-12 text-center">
                  <LoadIcon
                    src={`${iconPath}gps.svg`}
                    size={14}
                    color="#CC284E"
                    className="display-content mx-auto red"
                  />
                </Icon>
              </div>
            </GoogleMapReact>
          </MapWrapper>
        )}
        <div className="col-xs-12 container_alert_Bottom">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Alert
              className="alert-info col-xs bpy-md-3 "
              bgType="info"
              id="alert-factibilidad-ok"
              show
            >
              Dada la emergencia sanitaria que estamos viviendo, es importante verificar que la
              sucursal donde retirarás tus tarjetas se encuentre abierta al momento de retirarlas,
              esto puedes validarlo a través de nuestro Contact Center o por nuestro chat IVIC.
            </Alert>
          )}
        </div>
      </div>
    </Container>
  );
};

Index.displayName = 'RetiroSucursal_Template';
export default Index;
