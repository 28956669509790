import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 72px;
  margin-bottom: 48px;

  .alert-content {
    padding: 16px;
    width: 100%;
    margin-bottom: 24px;
  }

  .modulo {
    margin-bottom: 24px;
  }

  .modulo-content {
    padding: 24px 32px;
  }

  @media screen and (max-width: 769px) {
    margin-top: 64px;

    .alert-content {
      margin-bottom: 16px;
    }

    .modulo {
      margin-bottom: 16px;
    }

    .modulo-content {
      padding: 16px;
    }
  }
`;

export default Wrapper;
