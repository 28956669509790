import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// actions
import { activacionTarjetasKitActions } from '../../../../application/actions/activacionTarjetasKit';
import ActivacionTemplate from '../../../components/templates/ActivacionTarjetasKit/Activacion';
// Utils
import { registerGA } from '../../../utils/metrics';

const Activacion = ({ steps }) => {
  const dispatch = useDispatch();

  const kitData = useSelector((store) => store.entities.activacionTarjetasKit.kitDataKit);
  const validateTC = useSelector((store) => store.entities.activacionTarjetasKit.validateTCKit);

  const [cardCoorOne, setCardCoorOne] = useState('');
  const [cardCoorTwo, setCardCoorTwo] = useState('');
  const [cardCoorThree, setCardCoorThree] = useState('');
  const [cardCoorFour, setCardCoorFour] = useState('');
  const [cardCoorFive, setCardCoorFive] = useState('');
  const [cardCoorSix, setCardCoorSix] = useState('');
  const [cardCoorSeven, setCardCoorSeven] = useState('');
  //Boolean
  const [error, setError] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const handleSetCardCoord = (id, value) => {
    id === 'cor1' && setCardCoorOne(value);
    id === 'cor2' && setCardCoorTwo(value);
    id === 'cor3' && setCardCoorThree(value);
    id === 'cor4' && setCardCoorFour(value);
    id === 'cor5' && setCardCoorFive(value);
    id === 'cor6' && setCardCoorSix(value);
    id === 'cor7' && setCardCoorSeven(value);
  };

  const handleClickContinue = () => {
    const numSerie = cardCoorSeven
      ? `${cardCoorOne}${cardCoorTwo}${cardCoorThree}${cardCoorFour}${cardCoorFive}${cardCoorSix}${cardCoorSeven}`
      : `${cardCoorOne}${cardCoorTwo}${cardCoorThree}${cardCoorFour}${cardCoorFive}${cardCoorSix}`;
    dispatch(
      activacionTarjetasKitActions.fetchValidarTarjetaCoordenadasKit({
        idTarjetaCoordenadas: kitData.data.idTarjetaCoordenadas,
        numeroSerie: parseInt(numSerie),
      })
    );
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
  };

  const retryButton = () => {
    setError(false);
    setCardCoorOne('');
    setCardCoorTwo('');
    setCardCoorThree('');
    setCardCoorFour('');
    setCardCoorFive('');
    setCardCoorSix('');
  };

  const handleClickBack = (accion) => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/seguridad/activacionTarjetas/paso1',
      'eve-cat': 'Click',
      'eve-lab': `${accion}`,
    });
  };

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/seguridad/activacionTarjetas/paso1',
    });
  }, []);

  // Validar para no entrar directamente a la URL activacion
  useEffect(() => {
    if (kitData.data?.activable === false || kitData.error !== null || kitData.data === null) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas-kit');
    }
  }, []);

  useEffect(() => {
    if (validateTC.data !== true) {
      setError(validateTC.error?.code);
    }
    if (validateTC.data === true) {
      window.location.replace('#/ecd/seguridad/activacion-tarjetas-kit/autorizacion');
    }
  }, [validateTC]);

  useEffect(() => {
    if (kitData.data?.numeroTarjetaCoordenadas.length === 6) {
      if (
        cardCoorOne !== '' &&
        cardCoorTwo !== '' &&
        cardCoorThree !== '' &&
        cardCoorFour !== '' &&
        cardCoorFive !== '' &&
        cardCoorSix !== ''
      ) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    } else if (kitData.data?.numeroTarjetaCoordenadas.length === 7) {
      if (
        cardCoorOne !== '' &&
        cardCoorTwo !== '' &&
        cardCoorThree !== '' &&
        cardCoorFour !== '' &&
        cardCoorFive !== '' &&
        cardCoorSix !== '' &&
        cardCoorSeven !== ''
      ) {
        setDisabledBtn(false);
      } else {
        setDisabledBtn(true);
      }
    }
  }, [
    cardCoorOne,
    cardCoorTwo,
    cardCoorThree,
    cardCoorFour,
    cardCoorFive,
    cardCoorSix,
    cardCoorSeven,
  ]);

  return (
    kitData.data?.activable === true &&
    kitData.error === null && (
      <ActivacionTemplate
        {...{
          steps,
          handleSetCardCoord,
          disabledBtn,
          handleClickContinue,
          error,
          retryButton,
          kitData,
          cardCoorOne,
          cardCoorTwo,
          cardCoorThree,
          cardCoorFour,
          cardCoorFive,
          cardCoorSix,
          cardCoorSeven,
          handleClickBack,
        }}
      />
    )
  );
};

export default Activacion;
