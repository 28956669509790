import React from 'react';
import PropTypes from 'prop-types';
// UI
import Card from '@coopeuch-components/web/atoms/Card';
// Components
import CardHead from './CardHead';
import CardBody from './CardBody';

const DashboardCard = ({ padding, margin, children, classes, className, id }) => (
  <Card className={classes || className} rounded padding={padding} margin={margin} id={id}>
    {children}
  </Card>
);

DashboardCard.propTypes = {
  classes: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DashboardCard.defaultProps = {
  classes: null,
  className: null,
  padding: 0,
  margin: '0',
};

DashboardCard.Head = CardHead;
DashboardCard.Body = CardBody;

export default DashboardCard;
