/* eslint-disable no-param-reassign */
import { createAction, createSlice } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/bonoEscolar';

// ------- SAGA ------ //

function fetGetInstitutesAndCareersSuccess(state, action) {
  const { data } = action.payload;
  state.dataInstitutes.institutes = data.instituciones;
  state.dataInstitutes.careers = data.carreras;
  state.dataInstitutes.error = null;
}

function fetGetInstitutesAndCareersFailed(state, action) {
  const { error } = action.payload;
  state.dataInstitutes.institutes = null;
  state.dataInstitutes.careers = null;
  state.dataInstitutes.error = true;
}

function setStatesBonoPostulacion(state, action) {
  const {
    typeBeneficiario,
    nombreSocio,
    nombreBeneficiario,
    rutBeneficiario,
    institucion,
    carrera,
  } = action.payload;
  state.dataBono.typeBeneficiario = typeBeneficiario && typeBeneficiario;
  state.dataBono.nombreSocio = nombreSocio && nombreSocio;
  state.dataBono.nombreBeneficiario = nombreBeneficiario && nombreBeneficiario;
  state.dataBono.rutBeneficiario = rutBeneficiario && rutBeneficiario;
  state.dataBono.institucion = institucion && institucion;
  state.dataBono.carrera = carrera && carrera;
}

function setStatesTypeBono(state, action) {
  const { typeBono } = action.payload;
  state.dataBono.typeBono = typeBono;
}

function setStatesConfirmacion(state, action) {
  const { phone, email } = action.payload;
  state.dataBono.phone = phone;
  state.dataBono.email = email;
}

function setResetValues(state) {
  state.dataBono.typeBono = '';
  state.dataBono.phone = '';
  state.dataBono.email = '';
  state.dataBono.typeBeneficiario = '';
  state.dataBono.nombreSocio = '';
  state.dataBono.rutBeneficiario = '';
  state.dataBono.nombreBeneficiario = '';
  state.dataBono.institucion = '';
  state.dataBono.carrera = '';
  state.dataBono.documentos.error = false;
  state.dataBono.documentos.CEDULA_IDENTIDAD_SOCIO = '';
  state.dataBono.documentos.CEDULA_IDENTIDAD_HIJO = '';
  state.dataBono.documentos.DOCUMENTO_ADICIONAL = '';
  state.dataBono.documentos.CERTIFICADO_TITULO = '';
  state.dataInstitutes.institutes = null;
  state.dataInstitutes.careers = null;
  state.dataInstitutes.error = null;
  state.saveBono.data = null;
  state.saveBono.error = null;
  state.userType.data = null;
  state.userType.error = false;
}

function setResetValuesHome(state) {
  state.userType.data = null;
  state.userType.error = false;
}

function fetchUploadFileToServerSuccess(state, action) {
  const { data, tipoDocumento } = action.payload;
  state.dataBono.documentos[tipoDocumento] = data;
}

function fetchUploadFileToServerFailed(state, action) {
  const { error } = action.payload;
  state.dataBono.documentos.error = true;
}

function fetchSaveBonoSuccess(state, action) {
  const { data } = action.payload;
  state.saveBono.data = data;
  state.saveBono.error = null;
}

function fetchSaveBonoFailed(state, action) {
  const { error } = action.payload;
  state.saveBono.data = null;
  state.saveBono.error = error;
}

function fetchTypeUserSuccess(state, action) {
  const { data } = action.payload;
  state.userType.data = data;
  state.userType.error = null;
}

function fetchTypeUserFailed(state, action) {
  const { error } = action.payload;
  state.userType.data = null;
  state.userType.error = true;
}

function fetchDownloadPdfBonoPending(state) {
  state.downloadPdfBono.data = null;
  state.downloadPdfBono.isFetching = true;
  state.downloadPdfBono.error = null;
}

function fetchDownloadPdfBonoSuccess(state, action) {
  const { data } = action.payload;
  state.downloadPdfBono.data = data;
  state.downloadPdfBono.isFetching = false;
  state.downloadPdfBono.error = null;
}

function fetchDownloadPdfBonoError(state, action) {
  const { error } = action.payload;
  state.downloadPdfBono.data = null;
  state.downloadPdfBono.isFetching = false;
  state.downloadPdfBono.error = true;
}

function listaSolicitudesSuccess(state, action) {
  const { lista } = action.payload;
  state.listaSolicitudes.error = false;
  state.listaSolicitudes.data = { lista };
}

function listaSolicitudesError(state, action) {
  state.listaSolicitudes.error = true;
  state.listaSolicitudes.data = [];
}

function setRejectedDocuments(state, { payload }) {
  state.dataBono = {
    ...state.dataBono,
    ...payload,
  };
  state.dataBono.documentos.isRejected = true;
}

function retryDownloadPdfBono(state) {
  state.downloadPdfBono.error = false;
}

function fetchDuplicidadBonoSuccess(state, action) {
  const { data } = action.payload;
  state.duplicidadBono.data = data;
  state.duplicidadBono.error = null;
}

function fetchDuplicidadBonoError(state, action) {
  const { error } = action.payload;
  state.duplicidadBono.data = null;
  state.duplicidadBono.error = true;
}

function setResetValuesDuplicidad(state) {
  state.duplicidadBono.data = null;
  state.duplicidadBono.error = false;
}

const fetchGetInstitutesAndCareers = createAction('fetchGetInstitutesAndCareers');
const fetchUploadFileToServer = createAction('fetchUploadFileToServer');
const fetchSaveBono = createAction('fetchSaveBono');
const fetchTypeUser = createAction('fetchTypeUser');
const fetchDownloadPdfBono = createAction('fetchDownloadPdfBono');
const fetchListaSolicitudes = createAction('fetchListaSolicitudes');
const fetchDuplicidadBono = createAction('fetchDuplicidadBono');

// ------------- connection ------------- //

const bonoEscolarReducer = createSlice({
  name: 'bonoEscolar',
  initialState,
  reducers: {
    fetGetInstitutesAndCareersSuccess,
    fetGetInstitutesAndCareersFailed,
    setStatesBonoPostulacion,
    setStatesTypeBono,
    setStatesConfirmacion,
    fetchUploadFileToServerSuccess,
    fetchUploadFileToServerFailed,
    setResetValues,
    setResetValuesHome,
    fetchSaveBonoSuccess,
    fetchSaveBonoFailed,
    fetchTypeUserSuccess,
    fetchTypeUserFailed,
    fetchDownloadPdfBonoPending,
    fetchDownloadPdfBonoSuccess,
    fetchDownloadPdfBonoError,
    listaSolicitudesSuccess,
    listaSolicitudesError,
    setRejectedDocuments,
    retryDownloadPdfBono,
    fetchDuplicidadBonoSuccess,
    fetchDuplicidadBonoError,
    setResetValuesDuplicidad,
  },
});

export const bonoEscolarActions = {
  ...bonoEscolarReducer.actions,
  fetchGetInstitutesAndCareers,
  fetchUploadFileToServer,
  fetchSaveBono,
  fetchTypeUser,
  fetchDownloadPdfBono,
  fetchListaSolicitudes,
  fetchDuplicidadBono,
};

export default bonoEscolarReducer.reducer;
