import styled from 'styled-components';

const BaseMenu = styled.div`
  justify-content: center;
  flex-direction: column;
  display: flex;
  height: ${({ height }) => `${height}px`};
`;

export default BaseMenu;
