import styled from 'styled-components';

export default styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

  .tabs {
    border-bottom: 1px solid lightgray;
  }

  .simulacion--container-content {
    padding: 32px;
  }

  .help--text {
    color: #6d6d6d;
  }

  .error--input--message {
    & div {
      margin: 0;
      align-items: center;
    }

    .align-baseline {
      align-items: baseline;
    }

    & img {
      filter: invert(24%) sepia(71%) saturate(4673%) hue-rotate(344deg) brightness(90%)
        contrast(102%);
    }
  }

  .simulacion-loading-daps {
    margin-top: -24px;
  }

  #simulacion-next-action {
    width: 178px;
  }

  @media screen and (max-width: 766px) {
    padding: 1rem 1rem 1rem 1rem;

    .simulacion--container-content {
      padding: 16px;
    }

    .step-action-button-wrapper {
      width: 100%;

      #simulacion-next-action {
        width: 100%;
      }
    }
  }
`;

export const TitleWrapper = styled.div`
  .simulador-icon {
    display: flex;
    align-items: center;
  }

  .simulador-icon svg {
    height: 24px;
    width: 24px;
  }

  @media screen and (max-width: 766px) {
    #dap-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }
  }
`;
