import React from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import { Text } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const BlockedPassword = ({ onClick }) => {
  return (
    <div className="center-xs">
      <div className="row center-xs">
        <img src={`${staticPath}login_error.png`} alt="login-error" />
      </div>
      <Text textSize={24} size={32} weight="bold">
        !Clave bloqueada!
      </Text>
      <div className="col-xs-12 col-md-8 col-md-offset-2">
        <Text textSize={18} size={28} weight="normal">
          Por tu seguridad, hemos bloqueado tu clave. Si lo deseas puedes volvera cambiar tu clave.
        </Text>
      </div>
      <div className="row bmt-3">
        <div className="col-md"></div>
        <div className="col-xs-12 col-md-4">
          <Button
            bgColor="#E81E2B"
            color="white"
            className="alert-modal-text"
            onClick={onClick}
            fullWidth
          >
            {'Cambiar clave'}
          </Button>
        </div>
        <div className="col-md"></div>
      </div>
    </div>
  );
};

export default BlockedPassword;
