import React, { Component } from 'react';
import { connect } from 'react-redux';
// UI Components
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../components/atoms/PageTitle';
import Maintenance from '../../../components/atoms/Maintenance';
import SolicitaCVLanding from '../../../components/templates/CuentaVista/Solicita/index';
import FlowContainer from './Flow';
import SolicitaMFlujoContainer from '../SolicitaMonederoFlujo';

import { PageTitleWrapper } from '../styles';
import logger from '../../../utils/logger';
import { registerGA } from '../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFlow: 'LANDING',
    };
  }

  componentDidMount() {
    const { persona } = this.props;

    const baseRut = String((persona?.rut || '').replace('-', ''));

    logger(null, {
      rut: baseRut.slice(0, -1),
      digito_verificador: baseRut.slice(-1),
      accion: 'CV-SIN PRODUCTO',
      informacion_adicional: {},
    });
  }

  startFlow = () => {
    let selectedFlow = '';
    const mantencion = this.checkMantencion();
    if (mantencion) {
      selectedFlow = 'MANTENCION';
    } else {
      selectedFlow = 'SOLICITA_CV';
    }
    this.setState({ selectedFlow });
  };

  startFlowMonedero = () => {
    let selectedFlow = '';
    const mantencion = this.checkMantencion();
    if (mantencion) {
      selectedFlow = 'MANTENCION';
    } else {
      selectedFlow = 'SOLICITA_MONEDERO';
    }
    this.setState({ selectedFlow });
  };

  checkMantencion = () => {
    const { mantencion } = this.props;
    const mantencionSolicitudCV = mantencion.data.find(
      (elem) => elem.producto.codigoProducto === 'SCV'
    );
    console.log({ mantencionSolicitudCV });
    return mantencionSolicitudCV ? mantencionSolicitudCV.mantencion.estado : false;
  };

  handlebackLanding = () => {
    this.setState({ selectedFlow: 'LANDING' })
    registerGA({
      'event': 'eve','eve-acc' : '/tef/solicitar/monederoDigital/paso1','eve-cat' : 'Click','eve-lab' : 'No por ahora'
    });
  };

  goToCuentaVista = () => {
    const { history } = this.props;
    history.push('/ecd/productos/cuenta-coopeuch');
    registerGA({
      'event': 'eve','eve-acc' : '/tef/solicitar/monederoDigital/comprobante','eve-cat' : 'Click','eve-lab' : 'Ir a Cuenta Vista'
    });
  };

  FlowRender = () => {
    const { selectedFlow } = this.state;
    switch (selectedFlow) {
      case 'LANDING':
        return (
          <SolicitaCVLanding
            solicitaCV={this.startFlow}
            solicitaMonedero={this.startFlowMonedero}
          />
        );
      case 'SOLICITA_CV':
        return <FlowContainer />;
      case 'SOLICITA_MONEDERO':
        return <SolicitaMFlujoContainer handlebackLanding={this.handlebackLanding} goToCuentaVista={this.goToCuentaVista}/>;
      case 'MANTENCION':
        return <Maintenance className="bmt-3 bmt-md-4" />;
      default:
        return <SolicitaCVLanding />;
    }
  };

  render() {
    const { selectedFlow } = this.state;
    return (
      <>
        <PageTitleWrapper>
          <PageTitle
            title={
              selectedFlow === 'SOLICITA_MONEDERO'
                ? 'Solicitud de Monedero Digital'
                : 'Solicitud de Cuenta Coopeuch'
            }
            icon={<LoadIcon src={`${iconPath}user.svg`} color="#333" />}
            id="header-cuenta-vista"
          />
        </PageTitleWrapper>

        <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
          <this.FlowRender />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ entities }) => ({
  mantencion: entities.configuracion.mantencion,
  persona: entities.persona,
});

Index.displayName = 'Flujo_Container';

export default connect(mapStateToProps, null)(Index);
