import React from 'react';
import Stepper, { RenderStep } from '../../../organism/Stepper';
import Autorizar from '../../../organism/Remanente/CobroRemanente/Autorizar';
import Comprobante from '../../../organism/Remanente/CobroRemanente/Comprobante';
import ModalCobrarDespues from '../../../organism/Remanente/CobroRemanente/ModalCobrarDespues';
import ModalEnviarComprobante from '../../../organism/Remanente/CobroRemanente/ModalEnviarComprobante';
import ModalComprobanteEnviado from '../../../organism/Remanente/CobroRemanente/ModalComprobanteEnviado';
import ModalEnviarComprobanteError from '../../../organism/Remanente/CobroRemanente/ModalEnviarComprobanteError';

const Index = ({
  selectedAccount,
  accountsAvailable,
  accountsType,
  onChangeAccount,
  checked,
  steps,
  currentStep,
  cobrarRemanenteData,
  showCobrarDespuesModal,
  showEnviarComprobanteModal,
  showComprobanteEnviadoModal,
  showEnviarComprobanteErrorModal,
  secondEmail,
  secondEmailError,
  onHandleChangeEmail,
  onHandleSendComprobante,
  handleSendComprobanteEmail,
  onHandleDownloadComprobante,
  closeModal,
  onHandleCheckBox,
  backStep1,
  nextStep1,
  backStep2,
  nextStep2,
  openSendEmailModal,
  loadingCobrar,
  errorCobroRemanente,
}) => {
  return (
    <React.Fragment>
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12">
          <Stepper className="" position={currentStep} {...{ steps }} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 ">
          <RenderStep step={currentStep - 1}>
            <Autorizar
              {...{
                checked,
                onHandleCheckBox,
                selectedAccount,
                accountsAvailable,
                accountsType,
                onChangeAccount,
              }}
              backAction={backStep1}
              nextAction={nextStep1}
              loading={loadingCobrar}
              error={errorCobroRemanente}
            />
            <Comprobante
              type={accountsType}
              data={cobrarRemanenteData}
              backAction={backStep2}
              nextAction={nextStep2}
              openSendEmailModal={openSendEmailModal}
              downloadComprobante={onHandleDownloadComprobante}
            />
          </RenderStep>
        </div>
      </div>
      <ModalCobrarDespues
        show={showCobrarDespuesModal}
        closeAction={closeModal}
        backButton={nextStep2}
        nextButton={closeModal}
      />
      <ModalEnviarComprobante
        show={showEnviarComprobanteModal}
        email={secondEmail}
        emailError={secondEmailError}
        onHandleChangeEmail={onHandleChangeEmail}
        closeAction={closeModal}
        nextButton={handleSendComprobanteEmail}
      />
      <ModalComprobanteEnviado
        show={showComprobanteEnviadoModal}
        closeAction={closeModal}
        backButton={closeModal}
        nextButton={closeModal}
      />
      <ModalEnviarComprobanteError
        show={showEnviarComprobanteErrorModal}
        closeAction={closeModal}
      />
    </React.Fragment>
  );
};

export default Index;
