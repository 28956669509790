import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import StepsActions from '../../../organism/Stepper/StepsActions';
import Error from '../../../atoms/Error';

import { CheckboxContainer, Wrapper } from './styles';

const Index = ({
  cuenta = '',
  fecha = '',
  email = '',
  checked = false,
  errorCobroRemanente,
  onHandleCheckBox,
  backButton,
  nextButton,
  loading,
}) => {
  return (
    <Wrapper>
      <Alert
        className="alert-deposito-contratacion-monedero bp-3 bmb-3 bmb-md-4 "
        bgType="success"
        id="alert-felicitaciones-contratacion-monedero"
        show
      >
        <p>
          <b>¡Felicitaciones!</b> has activado tu{' '}
          <b>
            Monedero Digital Coopeuch Nº {cuenta} con fecha {fecha}.{' '}
          </b>
          Enviaremos <b>copia de los contratos</b> a tu email: <b>{email}</b>
        </p>
      </Alert>
      {!errorCobroRemanente 
      ? (<React.Fragment>
        <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>¡Contratación exitosa!</CardBox.Header>
        <CardBox.Body>
          <p className="bmb-3">Has activado tu Monedero Digital Coopeuch.</p>
          <p className="bmb-3">
            <b>¿Deseas que el pago de tu Remanente se deposite en tu monedero?</b>
          </p>
          <CheckboxContainer>
            <CheckBox
              name="depositoChecked"
              className="checkbox-element"
              text="Sí, autorizo que el Remanente se pague en mi Monedero Digital Coopeuch"
              borderColor="#646569"
              id="input-deposito-contratacion-monedero"
              fontSize={16}
              isChecked={checked}
              onChange={onHandleCheckBox}
            />
          </CheckboxContainer>
        </CardBox.Body>
      </CardBox>
      </React.Fragment>
      )
      : (<Error />
      )

      }
      
      <StepsActions
        className="bmb-3 bmb-md-4"
        backAction={backButton}
        backText={'No por ahora'}
        nextAction={nextButton}
        disableNextAction={!(checked && !errorCobroRemanente)}
        id="contratacion-paso-1"
        loading={loading}
      />
    </Wrapper>
  );
};

export default Index;
