import React from 'react';
import { Component } from 'react';
import { produce } from 'immer';

import ValidacionTemplate from '../../../components/templates/RecuperarOCrearClave/Validacion';
import { registerGA } from '../../../utils/metrics';
import { metodoFactores } from '../../../utils/constants';

import {
  errTarjetaCoordenadasFactor,
  errPassCoopeuchFactor,
} from '../../../../infrastructure/services/gestionClave/errors';

import { validacionClaveTexts } from '../texts';

class Validacion extends Component {
  state = {
    tiempoTarjetaCoordenadas: 120,
    intervaloTarjetaCoordenadas: {},
    tiempoSMS: 120,
    intervaloSMS: {},
    tiempoCPASS: 120,
    intervaloCPASS: {},
    intentosRetrySMS: 3,
    propiedadesFactor: {
      2.1: {
        step: 0,
        imagen: 'tarjeta_coordenadas',
        mensaje: validacionClaveTexts.LABEL_DESC_TARJETA_COORDENADAS,
        botonText: validacionClaveTexts.LABEL_BUTTON_TARJETA_COORDENADAS,
        tipo: 2.1,
        valorDesafio1: '',
        valorDesafio2: '',
        valorDesafio3: '',
        activeTimer: false,
      },
      2.2: {
        step: 0,
        imagen: 'pass_coopeuch',
        mensaje: validacionClaveTexts.LABEL_DESC_COOPEUCH_PASS,
        botonText: validacionClaveTexts.LABEL_BUTTON_COOPEUCH_PASS,
        tipo: 2.2,
        activeTimer: false,
      },
      3.1: {
        step: 1,
        imagen: 'sms',
        mensaje: '<b>Código de seguridad</b>',
        botonText: validacionClaveTexts.LABEL_BUTTON_SMS,
        tipo: 3.1,
        activeTimer: true,
        valorDesafio1: '',
        valorDesafio2: '',
        valorDesafio3: '',
        valorDesafio4: '',
        valorDesafio5: '',
        valorDesafio6: '',
      },
    },
  };

  componentDidMount() {
    const { factorSelected, reglasFactores } = this.props;

    const tiempoReglaSMS = Number(
      reglasFactores.find((regla) => regla.codigo === 'OTP_TIME')?.valor
    );
    const tiempoReglaTCOO = Number(
      reglasFactores.find((regla) => regla.codigo === 'TCOO_TIME')?.valor
    );
    const tiempoReglaCPASS = Number(
      reglasFactores.find((regla) => regla.codigo === 'CPASS_TIME')?.valor
    );
    const cantIntSMS = Number(reglasFactores.find((regla) => regla.codigo === 'OTP_INT')?.valor);

    this.setState(
      produce((draft) => {
        draft.tiempoSMS = tiempoReglaSMS;
        draft.tiempoTarjetaCoordenadas = tiempoReglaTCOO;
        draft.intentosRetrySMS = cantIntSMS;
        draft.tiempoCPASS = tiempoReglaCPASS;
      })
    );

    if (factorSelected === '3.1') {
      this.activarTimerSMS();
    }

    registerGA({
      event: 'vpv',
      page: '/crea-rec-clave/paso3',
      producto: metodoFactores[factorSelected],
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { tiempoSMS, tiempoTarjetaCoordenadas, tiempoCPASS } = this.state;
    const {
      desafioOTPValido,
      onStepsHandle,
      validarUsingFactorFailed,
      desafioTCOOValido,
      obtencionFactorCPASS,
      reglasFactores,
      desafioCPASSValido,
      validarDesafioCPASS,
      trackId,
      tarjetaCoordenadasErrors,
      passCoopeuchErrors,
      smsErrors,
      rut,
      type,
    } = this.props;
    if (prevState.tiempoSMS !== tiempoSMS) {
      if (tiempoSMS === 0) {
        this.desactivarTimerSMS();
        registerGA({
          event: 'trigger_event',
          producto: metodoFactores['3.1'],
          'event-config': {
            'eve-acc': '/crea-rec-clave/paso3',
            'eve-cat': 'Error',
            'eve-lab': 'SMS - Tiempo agotado',
          },  
        });
      }
    }
    if (prevState.tiempoTarjetaCoordenadas !== tiempoTarjetaCoordenadas) {
      if (tiempoTarjetaCoordenadas === 0) {
        this.desactivarTimerTarjetaCoordenadas();
        validarUsingFactorFailed({
          error: errTarjetaCoordenadasFactor[Number(1)],
          type: 'tarjetaCoordenadasErrors',
        });
        registerGA({
          event: 'trigger_event_visto',
          producto: metodoFactores['2.1'],
          'event-config': {
            'eve-acc': '/crea-rec-clave/paso3',
            'eve-cat': 'Error',
            'eve-lab': 'Se agoto el tiempo',
          },
        });
      }
    }
    if (prevState.tiempoCPASS !== tiempoCPASS) {
      if (tiempoCPASS === 0) {
        this.desactivarTimerCPASS();
        validarUsingFactorFailed({
          error: errPassCoopeuchFactor[Number(14)],
          type: 'passCoopeuchErrors',
        });
        registerGA({
          event: 'trigger_event',
          'event-config': {
            'eve-acc': '/crea-rec-clave/paso3',
            'eve-cat': 'Error',
            'eve-lab': 'PassCoopeuch Se agoto el tiempo',
          },
        });
      }
    }

    if (prevProps.tarjetaCoordenadasErrors !== tarjetaCoordenadasErrors) {
      if (tarjetaCoordenadasErrors?.id) {
        this.desactivarTimerTarjetaCoordenadas();
      }
    }
    if (prevProps.passCoopeuchErrors !== passCoopeuchErrors) {
      if (passCoopeuchErrors?.id) {
        this.desactivarTimerCPASS();
      }
    }
    if (prevProps.smsErrors !== smsErrors) {
      if (smsErrors?.id) {
        this.desactivarTimerSMS();
      }
    }

    if (prevProps.desafioOTPValido !== desafioOTPValido) {
      if (desafioOTPValido) {
        onStepsHandle(4);
      }
    }
    if (prevProps.desafioTCOOValido !== desafioTCOOValido) {
      if (desafioTCOOValido) {
        onStepsHandle(4);
      }
    }
    if (prevProps.obtencionFactorCPASS !== obtencionFactorCPASS) {
      if (obtencionFactorCPASS) {
        this.setState(
          produce((draft) => {
            draft.propiedadesFactor[2.2].step = 1;
            draft.propiedadesFactor[2.2].mensaje =
              'Para autorizar la operación, ingresa la clave Coopeuch antes que termine el tiempo permitido.';
            draft.propiedadesFactor[2.2].activeTimer = true;
          })
        );

        const tiempoReglaCPASS = Number(
          reglasFactores.find((regla) => regla.codigo === 'CPASS_TIME')?.valor
        );

        validarDesafioCPASS({
          rut: rut,
          track: trackId + (type === 'crear' ? '-7' : '-17'),
          tiempo: tiempoReglaCPASS,
        });

        this.activarTimerCPASS();
      }
    }
    if (prevProps.desafioCPASSValido !== desafioCPASSValido) {
      if (desafioCPASSValido) {
        onStepsHandle(4);
      }
    }
  }

  activarTimerTarjetaCoordenadas = () => {
    let inter = setInterval(() => {
      this.setState(
        produce((draft) => {
          draft.tiempoTarjetaCoordenadas = draft.tiempoTarjetaCoordenadas - 1;
        })
      );
    }, 1000);

    this.setState(
      produce((draft) => {
        draft.intervaloTarjetaCoordenadas = inter;
      })
    );
  };

  desactivarTimerTarjetaCoordenadas = () => {
    const { intervaloTarjetaCoordenadas } = this.state;
    clearInterval(intervaloTarjetaCoordenadas);
  };

  resetearTimerTarjetaCoordenadas = () => {
    const { reglasFactores } = this.props;
    const tiempoReglaSMS = Number(
      reglasFactores.find((regla) => regla.codigo === 'TCOO_TIME')?.valor
    );
    this.setState(
      produce((draft) => {
        draft.tiempoTarjetaCoordenadas = tiempoReglaSMS;
      })
    );
  };

  activarTimerSMS = () => {
    let inter = setInterval(() => {
      this.setState(
        produce((draft) => {
          draft.tiempoSMS = draft.tiempoSMS - 1;
        })
      );
    }, 1000);

    this.setState(
      produce((draft) => {
        draft.intervaloSMS = inter;
      })
    );
  };

  desactivarTimerSMS = () => {
    const { intervaloSMS } = this.state;
    clearInterval(intervaloSMS);
  };

  resetearTimerSMS = () => {
    const { reglasFactores } = this.props;
    const tiempoReglaSMS = Number(
      reglasFactores.find((regla) => regla.codigo === 'OTP_TIME')?.valor
    );
    this.setState(
      produce((draft) => {
        draft.tiempoSMS = tiempoReglaSMS;
      })
    );
  };

  activarTimerCPASS = () => {
    let inter = setInterval(() => {
      this.setState(
        produce((draft) => {
          draft.tiempoCPASS = draft.tiempoCPASS - 1;
        })
      );
    }, 1000);

    this.setState(
      produce((draft) => {
        draft.intervaloCPASS = inter;
      })
    );
  };

  desactivarTimerCPASS = () => {
    const { intervaloCPASS } = this.state;
    clearInterval(intervaloCPASS);
  };

  resetearTimerCPASS = () => {
    const { reglasFactores } = this.props;
    const tiempoReglaCPASS = Number(
      reglasFactores.find((regla) => regla.codigo === 'CPASS_TIME')?.valor
    );
    this.setState(
      produce((draft) => {
        draft.tiempoCPASS = tiempoReglaCPASS;
      })
    );
  };

  handleRetrySMS = () => {
    const { trackId, rut, fetchDesafioOTP, validarUsingFactorFailed, type } = this.props;
    const params = { track: trackId + (type === 'crear' ? '-8' : '-18'), rut: rut };
    fetchDesafioOTP(params);
    this.resetearTimerSMS();
    validarUsingFactorFailed({
      error: {},
      type: 'smsErrors',
    });
    this.activarTimerSMS();
    this.setState(
      produce((draft) => {
        draft.intentosRetrySMS = draft.intentosRetrySMS - 1;
        draft.propiedadesFactor[3.1].valorDesafio1 = '';
        draft.propiedadesFactor[3.1].valorDesafio2 = '';
        draft.propiedadesFactor[3.1].valorDesafio3 = '';
        draft.propiedadesFactor[3.1].valorDesafio4 = '';
        draft.propiedadesFactor[3.1].valorDesafio5 = '';
        draft.propiedadesFactor[3.1].valorDesafio6 = '';
      })
    );
    registerGA({
      event: 'trigger_event',
      'event-config': {
        'eve-acc': '/crea-rec-clave/paso3',
        'eve-cat': 'Click',
        'eve-lab': 'Enviar codigo SMS nuevamente',
      },
    });
  };

  onChangeInputSMS = (event) => {
    const { id, value } = event.target;

    const { propiedadesFactor, tiempoSMS } = this.state;

    if (propiedadesFactor[3.1].step === 2) {
      if (value.length == 0) {
        this.setState(
          produce((draft) => {
            draft.propiedadesFactor[3.1].step = 1;
          })
        );
      }
    }

    if (id === 'sms1') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[3.1].valorDesafio1 = value;
        })
      );
      if (value.length === 1) {
        if (
          propiedadesFactor[3.1].valorDesafio2.length === 1 &&
          propiedadesFactor[3.1].valorDesafio3.length === 1 &&
          propiedadesFactor[3.1].valorDesafio4.length === 1 &&
          propiedadesFactor[3.1].valorDesafio5.length === 1 &&
          propiedadesFactor[3.1].valorDesafio6.length === 1 &&
          tiempoSMS !== 0
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[3.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'sms2') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[3.1].valorDesafio2 = value;
        })
      );
      if (value.length === 1) {
        if (
          propiedadesFactor[3.1].valorDesafio1.length === 1 &&
          propiedadesFactor[3.1].valorDesafio3.length === 1 &&
          propiedadesFactor[3.1].valorDesafio4.length === 1 &&
          propiedadesFactor[3.1].valorDesafio5.length === 1 &&
          propiedadesFactor[3.1].valorDesafio6.length === 1 &&
          tiempoSMS !== 0
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[3.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'sms3') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[3.1].valorDesafio3 = value;
        })
      );
      if (value.length === 1) {
        if (
          propiedadesFactor[3.1].valorDesafio1.length === 1 &&
          propiedadesFactor[3.1].valorDesafio2.length === 1 &&
          propiedadesFactor[3.1].valorDesafio4.length === 1 &&
          propiedadesFactor[3.1].valorDesafio5.length === 1 &&
          propiedadesFactor[3.1].valorDesafio6.length === 1 &&
          tiempoSMS !== 0
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[3.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'sms4') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[3.1].valorDesafio4 = value;
        })
      );
      if (value.length === 1) {
        if (
          propiedadesFactor[3.1].valorDesafio1.length === 1 &&
          propiedadesFactor[3.1].valorDesafio2.length === 1 &&
          propiedadesFactor[3.1].valorDesafio3.length === 1 &&
          propiedadesFactor[3.1].valorDesafio5.length === 1 &&
          propiedadesFactor[3.1].valorDesafio6.length === 1 &&
          tiempoSMS !== 0
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[3.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'sms5') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[3.1].valorDesafio5 = value;
        })
      );
      if (value.length === 1) {
        if (
          propiedadesFactor[3.1].valorDesafio1.length === 1 &&
          propiedadesFactor[3.1].valorDesafio2.length === 1 &&
          propiedadesFactor[3.1].valorDesafio3.length === 1 &&
          propiedadesFactor[3.1].valorDesafio4.length === 1 &&
          propiedadesFactor[3.1].valorDesafio6.length === 1 &&
          tiempoSMS !== 0
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[3.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'sms6') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[3.1].valorDesafio6 = value;
        })
      );
      if (value.length === 1) {
        if (
          propiedadesFactor[3.1].valorDesafio1.length === 1 &&
          propiedadesFactor[3.1].valorDesafio2.length === 1 &&
          propiedadesFactor[3.1].valorDesafio3.length === 1 &&
          propiedadesFactor[3.1].valorDesafio4.length === 1 &&
          propiedadesFactor[3.1].valorDesafio5.length === 1 &&
          tiempoSMS !== 0
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[3.1].step = 2;
            })
          );
        }
      }
    }
  };

  handleButtonFactorSMS = () => {
    const {
      smsErrors,
      validarDesafioOTP,
      trackId,
      rut,
      fetchDesafioOTP,
      validarUsingFactorFailed,
      type,
    } = this.props;
    const { propiedadesFactor } = this.state;

    if (propiedadesFactor[3.1].step === 2 && !smsErrors.id) {
      let params = {
        codigo:
          propiedadesFactor[3.1].valorDesafio1 +
          '' +
          propiedadesFactor[3.1].valorDesafio2 +
          '' +
          propiedadesFactor[3.1].valorDesafio3 +
          '' +
          propiedadesFactor[3.1].valorDesafio4 +
          '' +
          propiedadesFactor[3.1].valorDesafio5 +
          '' +
          propiedadesFactor[3.1].valorDesafio6,
        rut: rut,
      };

      registerGA({
        event: 'trigger_event',
        'event-config': {
          'eve-acc': '/crea-rec-clave/paso3',
          'eve-cat': 'Click',
          'eve-lab': 'Autorizar con SMS',
        },
      });

      validarDesafioOTP({ params, track: trackId + (type === 'crear' ? '-9' : '-19') });
    }

    //HANDLE ERRORS

    if (smsErrors.id) {
      if (smsErrors.id !== 1) {
        this.setState(
          produce((draft) => {
            draft.propiedadesFactor[3.1].step = 1;
            draft.propiedadesFactor[3.1].activeTimer = true;
            draft.intentosRetrySMS = draft.intentosRetrySMS - 1;
            draft.propiedadesFactor[3.1].valorDesafio1 = '';
            draft.propiedadesFactor[3.1].valorDesafio2 = '';
            draft.propiedadesFactor[3.1].valorDesafio3 = '';
            draft.propiedadesFactor[3.1].valorDesafio4 = '';
            draft.propiedadesFactor[3.1].valorDesafio5 = '';
            draft.propiedadesFactor[3.1].valorDesafio6 = '';
          })
        );
        validarUsingFactorFailed({
          error: {},
          type: 'smsErrors',
        });
        this.desactivarTimerSMS();
        this.resetearTimerSMS();
        const paramsDesafio = { track: trackId + (type === 'crear' ? '-8' : '-18'), rut: rut };
        fetchDesafioOTP(paramsDesafio);
        this.activarTimerSMS();
      } else {
        let params = {
          codigo:
            propiedadesFactor[3.1].valorDesafio1 +
            '' +
            propiedadesFactor[3.1].valorDesafio2 +
            '' +
            propiedadesFactor[3.1].valorDesafio3 +
            '' +
            propiedadesFactor[3.1].valorDesafio4 +
            '' +
            propiedadesFactor[3.1].valorDesafio5 +
            '' +
            propiedadesFactor[3.1].valorDesafio6,
          rut: rut,
        };
        validarDesafioOTP({ params, track: trackId + (type === 'crear' ? '-9' : '-19') });
      }
    }
  };

  handleButtonFactorTCOO = () => {
    const { propiedadesFactor } = this.state;
    const {
      factoresSeguridad,
      tarjetaCoordenadasErrors,
      validarUsingFactorFailed,
      fetchDesafioTCOO,
      trackId,
      rut,
      validarDesafioTCOO,
      type,
    } = this.props;

    if (propiedadesFactor[2.1].step === 0) {
      registerGA({
        event: 'trigger_event',
        'event-config': {
          'eve-acc': '/crea-rec-clave/paso3',
          'eve-cat': 'Click',
          'eve-lab': 'Autorizar tarjeta coordenadas',
        },
      });
      const numeroTarjeta = factoresSeguridad.find((factor) => factor.codigo === 2.1)?.payload;

      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[2.1].step = 1;
          draft.propiedadesFactor[2.1].mensaje =
            'Ingresa las coordenadas indicadas de tu Tarjeta de Coordenadas Nº ' + numeroTarjeta;
          draft.propiedadesFactor[2.1].activeTimer = true;
        })
      );
      this.activarTimerTarjetaCoordenadas();
      const params = { track: trackId + (type === 'crear' ? '-4' : '-14'), rut: rut };
      fetchDesafioTCOO(params);
    }
    if (propiedadesFactor[2.1].step === 2 && !tarjetaCoordenadasErrors?.id) {
      const params = {
        track: trackId + (type === 'crear' ? '-5' : '-15'),
        rut: rut,
        desafio: {
          valorDesafio1: propiedadesFactor[2.1].valorDesafio1,
          valorDesafio2: propiedadesFactor[2.1].valorDesafio2,
          valorDesafio3: propiedadesFactor[2.1].valorDesafio3,
        },
      };
      validarDesafioTCOO(params);
      this.desactivarTimerTarjetaCoordenadas();
    }

    if (tarjetaCoordenadasErrors.id) {
      validarUsingFactorFailed({
        error: {},
        type: 'tarjetaCoordenadasErrors',
      });

      // En estos cosos se debe colocar los reintentar por RegistrarGA

      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[2.1].step = 0;
          draft.propiedadesFactor[2.1].mensaje =
            'Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.';
          draft.propiedadesFactor[2.1].valorDesafio1 = '';
          draft.propiedadesFactor[2.1].valorDesafio2 = '';
          draft.propiedadesFactor[2.1].valorDesafio3 = '';
          draft.propiedadesFactor[2.1].activeTimer = false;
        })
      );
      this.resetearTimerTarjetaCoordenadas();
    }
  };

  onChangeInputTCOO = (event) => {
    const { id, value } = event.target;

    const { propiedadesFactor } = this.state;

    if (propiedadesFactor[2.1].step === 2) {
      if (value.length == 1) {
        this.setState(
          produce((draft) => {
            draft.propiedadesFactor[2.1].step = 1;
          })
        );
      }
    }

    if (id === 'cor1') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[2.1].valorDesafio1 = value;
        })
      );
      if (value.length === 2) {
        if (
          propiedadesFactor[2.1].valorDesafio2.length === 2 &&
          propiedadesFactor[2.1].valorDesafio3.length === 2
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[2.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'cor2') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[2.1].valorDesafio2 = value;
        })
      );
      if (value.length === 2) {
        if (
          propiedadesFactor[2.1].valorDesafio1.length === 2 &&
          propiedadesFactor[2.1].valorDesafio3.length === 2
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[2.1].step = 2;
            })
          );
        }
      }
    }
    if (id === 'cor3') {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[2.1].valorDesafio3 = value;
        })
      );
      if (value.length === 2) {
        if (
          propiedadesFactor[2.1].valorDesafio1.length === 2 &&
          propiedadesFactor[2.1].valorDesafio2.length === 2
        ) {
          this.setState(
            produce((draft) => {
              draft.propiedadesFactor[2.1].step = 2;
            })
          );
        }
      }
    }
  };

  handleButtonFactorCPASS = () => {
    const {
      passCoopeuchErrors,
      validarUsingFactorFailed,
      fetchDesafioCPASS,
      trackId,
      rut,
      type,
    } = this.props;
    const { propiedadesFactor } = this.state;

    if (propiedadesFactor[2.2].step === 0 && !passCoopeuchErrors.id) {
      fetchDesafioCPASS({
        rut: rut,
        track: trackId + (type === 'crear' ? '-6' : '-16'),
      });
      registerGA({
        event: 'trigger_event',
        'event-config': {
          'eve-acc': '/crea-rec-clave/paso3',
          'eve-cat': 'Click',
          'eve-lab': 'Autorizar con PassCoopeuch',
        },
      });
    }

    if (passCoopeuchErrors.id) {
      this.setState(
        produce((draft) => {
          draft.propiedadesFactor[2.2].step = 0;
          draft.propiedadesFactor[2.2].mensaje = validacionClaveTexts.LABEL_DESC_COOPEUCH_PASS;
          draft.propiedadesFactor[2.2].activeTimer = false;
        })
      );

      validarUsingFactorFailed({
        error: {},
        type: 'passCoopeuchErrors',
      });

      this.desactivarTimerCPASS();
      this.resetearTimerCPASS();
    }
  };

  render() {
    const {
      factorSelected,
      datosPersonales,
      smsErrors,
      isLoadingValidarSMS,
      isLoadingDesafioSMS,
      factoresSeguridad,
      tarjetaCoordenadasErrors,
      desafio1,
      desafio2,
      desafio3,
      isLoadingDesafioYValidarFactorSeguridad,
      passCoopeuchErrors,
    } = this.props;
    const {
      propiedadesFactor,
      tiempoSMS,
      intentosRetrySMS,
      tiempoTarjetaCoordenadas,
      tiempoCPASS,
    } = this.state;

    return (
      <ValidacionTemplate
        factorSelected={factorSelected}
        propiedadesFactor={propiedadesFactor[factorSelected]}
        telefono={String(datosPersonales)}
        tiempoSMS={tiempoSMS}
        intentosRetrySMS={intentosRetrySMS}
        smsErrors={smsErrors}
        isLoadingValidarSMS={isLoadingValidarSMS}
        isLoadingDesafioSMS={isLoadingDesafioSMS}
        tarjetaCoordenadasErrors={tarjetaCoordenadasErrors}
        desafio1={desafio1}
        desafio2={desafio2}
        desafio3={desafio3}
        tiempoTarjetaCoordenadas={tiempoTarjetaCoordenadas}
        isLoadingDesafioYValidarFactorSeguridad={isLoadingDesafioYValidarFactorSeguridad}
        passCoopeuchErrors={passCoopeuchErrors}
        tiempoCPASS={tiempoCPASS}
        handleRetrySMS={this.handleRetrySMS}
        onChangeInputSMS={this.onChangeInputSMS}
        handleButtonFactorSMS={this.handleButtonFactorSMS}
        numeroTarjetaCoord={factoresSeguridad.find((factor) => factor.codigo === 2.1)?.payload}
        onChangeInputTCOO={this.onChangeInputTCOO}
        handleButtonFactorTCOO={this.handleButtonFactorTCOO}
        handleButtonFactorCPASS={this.handleButtonFactorCPASS}
      />
    );
  }
}

export default Validacion;
