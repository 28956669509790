import React from 'react';
const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const Loading = () => {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs-12">
          <img src={`${staticPath}spinner.gif`} alt="loading" className="bmx-auto" />
        </div>
        <div className="col-xs-12 col-md-8 col-md-offset-2 text-center">
          <b>Estamos procesando tu solicitud.</b>
          <p>No cierres la página hasta finalizar el proceso, esto puede tomar algunos segundos</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Loading;
