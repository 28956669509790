import React from 'react';
import PropTypes from 'prop-types';
// styles
import BaseCard from './styles';

const Card = ({ borderColor, padding, margin, className, children, rounded, id }) => (
  <BaseCard
    borderColor={borderColor}
    className={className}
    padding={padding}
    margin={margin}
    rounded={rounded}
    id={id}
  >
    {children}
  </BaseCard>
);

Card.propTypes = {
  borderColor: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  padding: PropTypes.number,
  margin: PropTypes.string,
  rounded: PropTypes.bool,
  id: PropTypes.string,
};

Card.defaultProps = {
  borderColor: '',
  className: '',
  children: null,
  padding: 16,
  margin: '0px',
  rounded: false,
  id: '',
};

export default Card;
