import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 340px) {
    flex-direction: column;
    align-items: center;
  }
  .width-image {
    max-width: 140px;
  }
`;

export default Container;
