import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

// Actions
import { productActions } from '../../../application/actions/products';

// Templates
import BloqueoTemporalTemplate from '../../components/templates/BloqueoTarjetas/BloqueoTemporalTemplate';

const BloqueoTemporal = ({
  tarjetasDebitoBT,
  setReloadBloqueoTempCard,
  reloadBloqueoTempCard,
  storageTarjetasCVista,
  reloadStorage,
  setReloadStorage,
}) => {
  const [isloading, setIsLoading] = useState(true);
  const [cards, setCards] = useState([]);
  const [lockedCard, setLockedCard] = useState(null);
  const [resultado, setResultado] = useState();

  /* Metodos Redux */
  const dispatch = useDispatch();

  const storageBloqueoTempTarjetas = useSelector(
    (store) => store.entities.products.bloqueoTemporalTarjetas.tarjetasDisponibles,
    shallowEqual
  );
  
  const prepareCardData = (cardsToMap) =>
    cardsToMap.map((card, index) => ({
      id: card?.id ? card.id : index,
      tipo: 'Tarjeta de Débito',
      lvl: card.tipo,
      icon: card.descripcion === 'Débito Coopeuch' ? 'cv_a' : 'cv_b',
      numero: card.numeroTarjeta,
      bloqueo: card.bloqueo,
      disabled: false,
      temporaryBlock: card.bloqueo === 'G' ? false : true,
      loading: false,
      // success: false,
      // error: false,
    }));

  const editSpecificCard = (cardId, modCard) =>
    setCards(
      prepareCardData(tarjetasDebitoBT).map((card) =>
        card.id === cardId ? { ...card, ...modCard } : card
      )
    );

  const onHandleSwitch = (cardId) => {
    setReloadBloqueoTempCard(false);
    setLockedCard({ id: cardId });

    // Indico que tarjeta esta cargando
    // console.log({ cardId });
    editSpecificCard(cardId, { loading: true });
    dispatch(
      productActions.fetchBloqueoTemporalTarjeta({
        isDefinitivo: false,
        tarjetaId: cardId,
      })
    );
  };

  /**
   * Primera carga
   */
  useEffect(() => {
    if (tarjetasDebitoBT) {
      setCards(prepareCardData(tarjetasDebitoBT));
      editSpecificCard(lockedCard?.id, { success: true, loading: false, error: false });
      setLockedCard(null);
      console.log('Carga de tarjetas desde Container');
    }
  }, [tarjetasDebitoBT]);

  /*
   * Se Formatea la data de las tarjetas y se guarda en un State
   */
  useEffect(() => {
    if (storageBloqueoTempTarjetas.success) {
      setReloadBloqueoTempCard(true);
      setResultado(storageBloqueoTempTarjetas.data.resultado)
      console.log('llegue a carga exitosa');
    }
    if (storageBloqueoTempTarjetas.error) {
      console.log('llegue a error');
      editSpecificCard(lockedCard?.id, { success: false, loading: false, error: true });
      setReloadBloqueoTempCard(false);
    }

    setIsLoading(false);
    // console.log('primera carga');
  }, [storageBloqueoTempTarjetas]);

  /**
   * Se observa State de lockedCard
   */
  // useEffect(() => {
  //   if (reloadStorage) {
  //     console.log(lockedCard);
  //     // setLockedCard(null);
  //     console.log(lockedCard);
  //   }
  // }, [reloadStorage]);

  return (
    <>
      <BloqueoTemporalTemplate {...{ isloading, cards, onHandleSwitch, resultado }} />
    </>
  );
};

BloqueoTemporal.propTypes = {
  tarjetasDebitoBT: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      numeroTarjeta: PropTypes.string.isRequired,
      numeroCuenta: PropTypes.number.isRequired,
      descripcion: PropTypes.string.isRequired,
      rut: PropTypes.string.isRequired,
      nombre: PropTypes.string.isRequired,
      tipo: PropTypes.string.isRequired,
      estado: PropTypes.string.isRequired,
      codEstado: PropTypes.string.isRequired,
      bloqueo: PropTypes.string.isRequired,
      fechaApertura: PropTypes.string.isRequired,
      tieneChip: PropTypes.bool.isRequired,
    })
  ),
  reloadBloqueoTempCard: PropTypes.bool.isRequired,
  // setReloadBloqueoTempCard: PropTypes.func.isRequired,
};

BloqueoTemporal.defaultProps = {
  tarjetasDebitoBT: null,
};

BloqueoTemporal.displayName = 'BloqueoTemporal_BT_Container';

export default BloqueoTemporal;
