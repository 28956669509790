import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 50px;
  margin-top: 37px;
  @media (max-width: 1280px) {
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
  }

  .boxContainer {
    margin-top: 32px;
    @media (max-width: 991px) {
      margin-top: 16px;
    }
  }

  ._3sTov > div > h1 {
    font-family: 'Ubuntu-Bold';
  }

  ._1W4ig {
    padding: 0px;
  }

  .cardDatos {
    > div > h1 {
      font-weight: 900;
    }

    &__content {
      &__input {
        display: flex;
        width: 100%;

        &__phono {
          position: relative;
          width: 50%;
          max-width: 300px;
          &__box {
            height: 48px;
            border-radius: 4px;
            @media screen and (max-width: 500px) {
              margin-top: 12px;
            }
          }
          // Setear input para mostrar codigo area
          ._2lUSM {
            ._3kii5 {
              input {
                font-size: 14px;
              }
            }
          }
          &__code {
            position: absolute;
            top: 45px;
            left: 16px;
            font-size: 14px;
            color: black;
          }
          > div > label {
            font-family: 'Ubuntu-Bold';
            font-size: 14px;
          }

          @media screen and (max-width: 500px) {
            width: 100%;
            max-width: initial;
          }
        }

        &__phono.marginInput {
          margin-bottom: 22px;
        }

        &__email {
          margin-left: 32px;
          width: 50%;
          max-width: 300px;
          &__box {
            height: 48px;
            border-radius: 4px;
            @media screen and (max-width: 500px) {
              margin-top: 12px;
            }
          }

          ._2lUSM {
            ._3kii5 {
              input {
                font-size: 14px;
              }
            }
          }

          > div > label {
            font-family: 'Ubuntu-Bold';
            font-size: 14px;
          }

          @media screen and (max-width: 500px) {
            width: 100%;
            max-width: initial;
          }

          @media screen and (max-width: 500px) {
            margin-left: 0px;
            margin-top: 20px;
          }
        }

        @media screen and (max-width: 500px) {
          flex-direction: column;
        }
      }

      &__alert {
        margin-top: 24px;
        font-size: 'Ubuntu-Bold';
        /* margin-bottom: 24px; */

        > div > div > p > p {
          font-size: 14px;
          line-height: 22px;
          color: #007db7;
          @media screen and (max-width: 500px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        @media screen and (max-width: 500px) {
          margin-top: 28px;
        }
      }

      &__check {
        width: 100%;
        display: flex;

        ._36BXy {
          align-items: flex-start;
        }

        &__text {
          font-family: 'Ubuntu-Regular';
          font-size: 14px;

          > ._2p8qe {
            padding: 0px;
            text-align: left;
            align-items: flex-start;

            > span {
              font-weight: 900;
            }
          }
        }

        @media screen and (max-width: 500px) {
          width: 100%;
          line-height: 20px;
        }
      }

      @media screen and (max-width: 500px) {
        justify-content: space-evenly;
      }
    }

    /* @media screen and (max-width: 500px) {
      padding-bottom: 20px;
    } */
  }

  .divButtons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 22px;

    &__continue {
      ._2p8qe {
        width: 100%;
        max-width: 200px;

        @media screen and (max-width: 500px) {
          width: 100%;
          max-width: initial;
          margin-top: 24px;
        }
      }
    }

    &__return {
      ._2p8qe {
        width: 100%;
        max-width: 200px;

        @media screen and (max-width: 500px) {
          width: 100%;
          max-width: initial;
          margin-top: 24px;
        }
      }
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      margin-top: 0px;
    }
  }
`;
export default Wrapper;
