import React, { useMemo } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Stepper from '../../../../components/organism/Stepper';
import DashboardCard from '../../../organism/DashboardCard';
import Select from '../../../atoms/Select';
import PageTitle from '../../../atoms/PageTitle';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Button from '@coopeuch-components/web/atoms/Button';
import FactoresDeSeguridad from '../../../organism/FactoresDeSeguridad';
import CheckBox from '../../../atoms/CheckBox';
import AlertModal from '../../../atoms/AlertModal';
import InputLabel from '../../../atoms/InputLabel';
import ErrorCard from '../../../organism/ErrorCard';
import ButtonWithSpinner from '../../../atoms/ButtonWithSpinner';
import { agregarformatoPesos } from '../../../../utils/formatters';

import { confirmacionTexts, stepperTexts } from '../../../../containers/DepositoAPlazo/texts';

import Wrapper, { TitleWrapper, AlertWrapper, AlertTasaWrapper } from './styles';
import { SkeletonConfirmacion } from './skeleton';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ConfirmacionTemplate = ({
  showModal,
  handleClickTerminosModal,
  handleCloseModal,
  handleCuentaVistaOrigen,
  handleCuentaVistaDestino,
  handleReturnSimular,
  handleChangeMail,
  handleReturn,
  opcionCuentasVistaOrigen,
  opcionCuentasVistaDestino,
  cuentaOrigenSeleccionada,
  cuentaDestinoSeleccionada,
  descripcionCuentas,
  simulacion,
  mail,
  validMail,
  invalidMailMessage,
  showPassword,
  handleIconPassword,
  factorPassword,
  onHandlePasswordInput,
  errorInputPassword,
  handleChangeCheck,
  check,
  handleClickContinue,
  isLoadingCuentasVista,
  errorCuentasVistas,
  isLoadingDAPInvertir,
  errorInvertir,
  handleClickRetryError,
  handleClickAlertTasa,
  showAlertCambioTasa,
  handleLinkTerminosYCondiciones,
}) => {
  const steps = useMemo(() => {
    return [
      stepperTexts.STEPPER_PASO_1,
      stepperTexts.STEPPER_PASO_2,
      stepperTexts.STEPPER_PASO_3,
      stepperTexts.STEPPER_PASO_4,
      stepperTexts.STEPPER_PASO_5,
    ];
  }, []);


  return (
    <React.Fragment>
      <TitleWrapper>
        <PageTitle
          title={confirmacionTexts.PAGE_TITLE}
          icon={
            <LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" className="confirmacion-icon" />
          }
        />
      </TitleWrapper>
      <Wrapper className="margin-after-title">
        {errorCuentasVistas || errorInvertir?.errorGeneral ? (
          <ErrorCard />
        ) : (
          <div>
            <Stepper className="mb-24 start-xs" position={4} steps={steps} />
            {isLoadingCuentasVista ? (
              <SkeletonConfirmacion />
            ) : (
              <div>
                <div className="bmt-3 bmt-md-4">
                  <DashboardCard classes="dashboard-card-shadow">
                    <DashboardCard.Head
                      title={confirmacionTexts.CARD_CUENTA_ORIGEN}
                      classes="confirmacion-title-card-head"
                      borderBottom="complete"
                    />
                    <DashboardCard.Body classes="confirmacion-simulacion-card-body">
                      <div className="row m-0">
                        <div className="col-md-6 col-xs-12 p-0">
                          <div className="bmb-3 bmb-md-0">
                            <div className="mb-12">
                              <label className="confirmacion-text-cuenta">
                                {confirmacionTexts.NUMERO_CUENTA}
                              </label>
                            </div>
                            {opcionCuentasVistaOrigen.length > 1 ? (
                              <Select
                                containerClass="confirmacion-select"
                                options={opcionCuentasVistaOrigen}
                                value={cuentaOrigenSeleccionada?.numeroCuenta}
                                onChange={handleCuentaVistaOrigen}
                              />
                            ) : (
                              <div className="cuantavista-only-account">
                                <label className="cuantavista-only-account-text">
                                  {descripcionCuentas(cuentaOrigenSeleccionada)} Nº{' '}
                                  {cuentaOrigenSeleccionada?.numeroCuenta}
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6 col-xs-12 p-0">
                          <div className="bmb-md-3 bmb-2">
                            <label>{confirmacionTexts.SALDO_DISPONIBLE}</label>
                          </div>
                          <div className="pt-2">
                            <label className="confirmacion-text-monto">
                              {agregarformatoPesos(cuentaOrigenSeleccionada.saldoDisponible)}
                            </label>
                          </div>
                        </div>
                        {cuentaOrigenSeleccionada?.saldoDisponible < simulacion.monto && (
                          <div className="col-md-12 col-xs-12 p-0">
                            <div className="bmt-3">
                              <Alert show bgType="warning" className="icon-alert">
                                {confirmacionTexts.TOAST_SALDO_INSUFICIENTE}
                              </Alert>
                            </div>
                          </div>
                        )}
                      </div>
                    </DashboardCard.Body>
                  </DashboardCard>
                </div>
                {simulacion.monto <= cuentaOrigenSeleccionada?.saldoDisponible ? (
                  <div>
                    <div className="bmt-3 bmt-md-4">
                      <DashboardCard classes="dashboard-card-shadow">
                        <DashboardCard.Head
                          title={confirmacionTexts.CARD_CUENTA_DESTINO}
                          classes="confirmacion-title-card-head"
                          borderBottom="complete"
                        />
                        <DashboardCard.Body classes="confirmacion-simulacion-card-body">
                          <div className="row m-0">
                            <div className="col-md-6 col-xs-12 p-0">
                              <div className="bmb-3 bmb-md-0">
                                <div className="mb-12">
                                  <label className="confirmacion-text-cuenta">
                                    {confirmacionTexts.NUMERO_CUENTA}
                                  </label>
                                </div>
                                {opcionCuentasVistaDestino.length > 1 ? (
                                  <Select
                                    containerClass="confirmacion-select"
                                    options={opcionCuentasVistaDestino}
                                    value={cuentaDestinoSeleccionada?.numeroCuenta}
                                    onChange={handleCuentaVistaDestino}
                                  />
                                ) : (
                                  <div className="cuantavista-only-account">
                                    <label className="cuantavista-only-account-text">
                                      {descripcionCuentas(cuentaDestinoSeleccionada)} Nº{' '}
                                      {cuentaDestinoSeleccionada?.numeroCuenta}
                                    </label>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12 p-0">
                              <div className="bmb-md-3 bmb-2">
                                <label>{confirmacionTexts.SALDO_DISPONIBLE}</label>
                              </div>
                              <div className="pt-2">
                                <label className="confirmacion-text-monto">
                                  {agregarformatoPesos(cuentaDestinoSeleccionada.saldoDisponible)}
                                </label>
                              </div>
                            </div>
                          </div>
                        </DashboardCard.Body>
                      </DashboardCard>
                    </div>
                    <div className="bmt-3 bmt-md-4">
                      <DashboardCard classes="dashboard-card-shadow">
                        <DashboardCard.Head
                          title={confirmacionTexts.CARD_DATOS_CONTACTO}
                          classes="confirmacion-title-card-head"
                          borderBottom="complete"
                        />
                        <DashboardCard.Body classes="confirmacion-simulacion-card-body">
                          <div className="bmb-md-4 bmb-3">
                            <Alert show bgType="info" className="icon-alert">
                              {confirmacionTexts.CONTACTO_NO_REEMPLAZA}
                            </Alert>
                          </div>
                          <div className="bmb-md-4 bmb-3">
                            <label className="confirmacion-contacto-text">
                              {confirmacionTexts.CONTACTO_CONFIRMA}
                            </label>
                          </div>
                          <div className="row m-0">
                            <div className="col-md-12 col-xs-12 p-0">
                              <div className="bmb-2">
                                <label className="confirmacion-text-email">
                                  {confirmacionTexts.CONTACTO_LABEL_EMAIL}
                                </label>
                              </div>
                              <div className="bpt-1">
                                <InputLabel
                                  value={mail}
                                  inputClass="confirmacion-only-account"
                                  onChange={handleChangeMail}
                                  id="inputEmail"
                                  borderColor={!validMail ? '#E81E2B' : '#646569'}
                                />
                                {!validMail && (
                                  <div className="help-text dark-red text-size-s back--icon">
                                    <div className="row ml-0 pt-2">
                                      <LoadIcon
                                        src={`${iconPath}circle_close.svg`}
                                        size={14}
                                        className="mr-4 dark-red"
                                      />
                                      <p>{invalidMailMessage}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </DashboardCard.Body>
                      </DashboardCard>
                    </div>
                    <div className="bmt-3 bmt-md-4">
                      <DashboardCard classes="dashboard-card-shadow">
                        <DashboardCard.Head
                          title={confirmacionTexts.CARD_FIRMA_ELECTRONICA}
                          classes="confirmacion-title-card-head"
                          borderBottom="complete"
                        />
                        <DashboardCard.Body classes="confirmacion-simulacion-card-body">
                          {errorInvertir?.id ? (
                            <div className="confirmacion-error-container">
                              <ErrorCard
                                isCard={false}
                                buttonClass="confirmacion-error-button"
                                titleText={errorInvertir?.title}
                                descText={errorInvertir?.message}
                                buttonText={errorInvertir?.buttonTitle}
                                showButton={errorInvertir?.showButton}
                                onClickButton={handleClickRetryError}
                              />
                            </div>
                          ) : (
                            <div>
                              <div className="bmb-md-4 bmb-3">
                                <label>{confirmacionTexts.FIRMA_VALIDA}</label>
                              </div>
                              <div className="bmb-3">
                                <div className="bmb-2">
                                  <label className="confirmacion-clave-internet-text">
                                    {confirmacionTexts.FIRMA_INGRESA_CLAVE}
                                  </label>
                                </div>
                                <div className="row m-0">
                                  <div className="col-md-4 col-xs-12 p-0">
                                    <FactoresDeSeguridad
                                      tipo={1.1}
                                      showClave={showPassword}
                                      onTogglePassword={handleIconPassword}
                                      value={factorPassword}
                                      onHandlePasswordInput={onHandlePasswordInput}
                                      error={errorInputPassword}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="confirmacion-terminos-container">
                                <div>
                                  <CheckBox checked={check} onChange={handleChangeCheck} />
                                </div>
                                <div className="confirmacion-terminos-center">
                                  <label className="confirmacion-terminos-text">
                                    {confirmacionTexts.FIRMA_TERMINOS_1}
                                    <label
                                      className="confirmacion-terminos-redirect"
                                      onClick={handleClickTerminosModal}
                                    >
                                      {confirmacionTexts.FIRMA_TERMINOS_2}
                                    </label>
                                    {confirmacionTexts.FIRMA_TERMINOS_3}
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </DashboardCard.Body>
                      </DashboardCard>
                    </div>
                    <div className="bmt-4">
                      <div className="row">
                        <div className="col-md-6 display-desktop">
                          <Button
                            asTextLink
                            color="#E81E2B"
                            iconLeft={
                              <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />
                            }
                            onClick={handleReturn}
                            className="button-return p-0"
                          >
                            {confirmacionTexts.BOTON_RETURN}
                          </Button>
                        </div>
                        <div className="col-md-6 col-xs-12 btext-right">
                          <ButtonWithSpinner
                            bgColor="#E81E2B"
                            color="white"
                            onClick={handleClickContinue}
                            className="button-continue"
                            disabled={
                              !(
                                validMail &&
                                !errorInputPassword &&
                                factorPassword.length > 0 &&
                                check &&
                                errorInvertir.id === undefined &&
                                !isLoadingDAPInvertir
                              )
                            }
                            loading={isLoadingDAPInvertir}
                          >
                            {confirmacionTexts.BOTON_CONTINUE}
                          </ButtonWithSpinner>
                        </div>
                        <div className="col-md-6 col-xs-12 display-mobile">
                          <Button
                            asTextLink
                            color="#E81E2B"
                            iconLeft={
                              <LoadIcon src={`${iconPath}arrow_left.svg`} size={22} margin="0" />
                            }
                            onClick={handleReturn}
                            className="button-return p-0 bmt-3"
                          >
                            {confirmacionTexts.BOTON_RETURN}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="bmt-4">
                    <div className="row">
                      <div className="col-md-6" />
                      <div className="col-md-6 col-xs-12 btext-right">
                        <Button
                          bgColor="#E81E2B"
                          color="white"
                          onClick={handleReturnSimular}
                          className="button-continue"
                        >
                          {confirmacionTexts.BOTON_RETURN_SIMULACION}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </Wrapper>
      <AlertWrapper>
        <AlertModal showModal={showModal} width={650} onClose={handleCloseModal} closeOnOut={true}>
          <div className="bmb-3">
            <LoadIcon src={`${iconPath}ilustracion_info.svg`} className="modal-icon" />
          </div>
          <div className="bmb-3">
            <label className="confirmacion-terminos-modal-title">
              {confirmacionTexts.FIRMA_TERMINOS_MODAL_TITLE}
            </label>
          </div>
          <div className="text-left bmb-4">
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_1}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_2}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_3}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_4}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_5}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_6}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_7}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_8}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_9}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_10}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_11}
              </label>
            </div>
            <div>
              <label className="confirmacion-terminos-modal">
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_12}
              </label>
              <label
                className="confirmacion-terminos-modal confirmacion-modal-link"
                onClick={handleLinkTerminosYCondiciones}
              >
                {confirmacionTexts.FIRMA_TERMINOS_MODAL_13}
              </label>
            </div>
          </div>
          <div>
            <Button
              bgColor="#E81E2B"
              color="white"
              onClick={handleCloseModal}
              className="button-modal"
            >
              {confirmacionTexts.BOTON_TERMINOS_MODAL}
            </Button>
          </div>
        </AlertModal>
      </AlertWrapper>
      <AlertTasaWrapper>
        <AlertModal showModal={showAlertCambioTasa} width={546} onClose={handleClickAlertTasa}>
          <div className="bmb-3">
            <LoadIcon src={`${iconPath}ilustracion_info.svg`} className="modal-icon" />
          </div>
          <div className="bmb-2">
            <label className="confirmacion-tasa-alert-title">
              {confirmacionTexts.CAMBIO_TASA_MODAL_TITLE}
            </label>
          </div>
          <div className="bmb-3">
            <label className="confirmacion-tasa-alert-desc">
              {confirmacionTexts.CAMBIO_TASA_MODAL_1}
              <b>{confirmacionTexts.CAMBIO_TASA_MODAL_2}</b>
              {confirmacionTexts.CAMBIO_TASA_MODAL_3}
            </label>
          </div>
          <div>
            <Button
              bgColor="#E81E2B"
              color="white"
              onClick={handleClickAlertTasa}
              className="button-modal"
            >
              {confirmacionTexts.CAMBIO_TASA_MODAL_BUTTON}
            </Button>
          </div>
        </AlertModal>
      </AlertTasaWrapper>
    </React.Fragment>
  );
};

ConfirmacionTemplate.propTypes = {};
ConfirmacionTemplate.defaultProps = {};

export default ConfirmacionTemplate;
