import React from 'react';
import PropTypes from 'prop-types';

import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import DashboardCard from '../../../organism/DashboardCard';
import TableCRUD from '../../../organism/TableCRUD';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import AlertModal from '../../../atoms/AlertModal';
import { colors } from '../../../../assets/styles';
import { capitalize } from '../../../../utils/functions';

import { BodyCard, SuccessMessage, Container } from './styles';

import { listarDestinatariosTexts } from './texts';
import SearchBar from '../../../organism/SearchBar';
import FactoresDeSeguridad from '../../../organism/FactoresDeSeguridad';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ListarDestinatariosTemplate = ({
  destinatarios,
  destinatarioId,
  cargandoDestinatarios,
  cargarElementos,
  masElementos,
  numeroDestinatarioSeleccionado,
  configuracionColumnasDestinatarios,
  configuracionHeadersDestinatarios,
  onClickTableCRUD,
  errorDestinatarios,
  showAlert,
  showErrorModal,
  isLoaderDeleteDestinatarios,
  borrarDestinatariosSuccess,
  wasVI06,
  destinatarioParaBorrar,
  handleErrorDestinatariosButton,
  handleClickDelete,
  handleCloseModal,
  reqSuccess,
  reqWasEdition,
  onGoToDatosDestinatario,
  suggestions,
  onTypeQueryHandle,
  query,
  onRemoveHandle,
  onSearchHandle,
  onClickItemSearchBar,
}) => {
  return (
    <Container>
      {borrarDestinatariosSuccess && (
        <div className="row bmt-3">
          <div className="col-xs-12">
            <SuccessMessage className="mb-24" show>
              <div className="row">
                <div className="success-icon succes-message-icon-center">
                  <LoadIcon src={`${iconPath}success_circle_check.svg`} size={20} />
                </div>
                <div className="success-text">
                  <label>Destinatario eliminado con éxito</label>
                </div>
              </div>
            </SuccessMessage>
          </div>
        </div>
      )}
      {reqSuccess && (
        <div className="row bmt-xs-3 bmt-md-4">
          <div className="col-xs-12">
            <SuccessMessage className="" show>
              <div className="row">
                <div className="success-icon succes-message-icon-center">
                  <LoadIcon src={`${iconPath}success_circle_check.svg`} size={20} />
                </div>
                <div className="success-text">
                  <label>
                    {reqWasEdition
                      ? listarDestinatariosTexts.WARNING_EDITED
                      : listarDestinatariosTexts.WARNING_CREATED}
                  </label>
                </div>
              </div>
            </SuccessMessage>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-xs-12">
          <DashboardCard
            classes="dashboard-card-listado bmt-xs-3 bmt-sm-4 bmb-xs-3 bmb-sm-4 padding_destinatarios"
            margin="0px"
          >
            <DashboardCard.Head
              title={listarDestinatariosTexts.TITLE}
              borderBottom="complete"
              classes="bmt-xs-3 bmt-sm-4"
            />
            <DashboardCard.Body classes="destinatario-dashboard-body-responsive bmt-xs-0 bmy-sm-4 bmb-xs-3 padding_destinatarios">
              {cargandoDestinatarios ? (
                <div className="text-align-center">
                  <SpinnerLoader />
                </div>
              ) : (
                <BodyCard>
                  {errorDestinatarios ? (
                    <div className="row center-xs mt-14">
                      <div className="col-xs-12 center-xs">
                        <div className="row center-xs middle-xs mb-24">
                          <FactoresDeSeguridad
                            title={listarDestinatariosTexts.ERROR_DESTINATARIO_TITLE}
                            message={listarDestinatariosTexts.ERROR_DESTINATARIO_DESC}
                            tipo="error"
                            showButton
                            onClickButton={handleErrorDestinatariosButton}
                            buttonText={listarDestinatariosTexts.ERROR_BUTTON_TITLE}
                            titleClass="text-size-xxl pt-12 pb-24"
                            messageClass="pb-24 lh-1_5"
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="row">
                        <div className="col-xs-12 col-md-6">
                          <Button
                            inverse
                            bgColor={colors.red}
                            color="white"
                            className="destinatarios-body-button bmt-xs-3 bmt-sm-0"
                            onClick={onGoToDatosDestinatario}
                            iconLeft={
                              <LoadIcon
                                margin="0"
                                src={`${iconPath}icon_add1.svg`}
                                className="destinatarios-body-icon"
                              />
                            }
                            id="btn-list-dest"
                          >
                            {listarDestinatariosTexts.BUTTON_TEXT}
                          </Button>
                        </div>
                        <div className="col-xs-12 col-md-6 btext-right">
                          <SearchBar
                            id="destinatarios--inputsearch"
                            className="d-inline-block bmt-xs-3 bmt-sm-0"
                            suggestions={suggestions}
                            onChange={onTypeQueryHandle}
                            query={query}
                            onRemove={onRemoveHandle}
                            width={248}
                            onSubmit={onSearchHandle}
                            onClickItem={onClickItemSearchBar}
                            placeholder={listarDestinatariosTexts.BODY_INPUT_PLACEHOLDER}
                            rounded={4}
                          />
                        </div>
                      </div>
                      {reqSuccess && !reqWasEdition && (
                        <div className="pl-32 pr-32 pb-24 pt-24">
                          <div className="row">
                            <div className="box pl-12">
                              <LoadIcon src={`${iconPath}info.svg`} size={18} />
                            </div>
                            <div className="col-xs">
                              <p className="pr-24 warning-message">
                                {wasVI06
                                  ? listarDestinatariosTexts.NEW_DESTINATARIO_INFO_VI06
                                  : listarDestinatariosTexts.NEW_DESTINATARIO_INFO}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      {destinatarios.length === 0 ? (
                        <>
                          <div className="row bmt-3">
                            <div className="col-xs-12 text-center">
                              <LoadIcon
                                className="m-auto"
                                src={`${iconPath}ilustracion_info.svg`}
                              />
                            </div>
                          </div>
                          <div className="row bmt-3">
                            <div className="col-xs-12 text-center">
                              <label className="destinatarios-text-title">
                                {listarDestinatariosTexts.BODY_NO_DESTINATARIO_TITLE}
                              </label>
                            </div>
                            <div className="col-xs-8 col-xs-offset-2 col-md-6 col-md-offset-3">
                              <div className="text-align-center">
                                <label className="destinatarios-text-desc">
                                  {listarDestinatariosTexts.BODY_NO_DESTINATARIO_1}
                                  <b>{listarDestinatariosTexts.BODY_NO_DESTINATARIO_2}</b>
                                  {listarDestinatariosTexts.BODY_NO_DESTINATARIO_3}
                                </label>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="bmb-xs-3 bmb-lg-4 bmt-xs-3 bmt-sm-4" />
                          <TableCRUD
                            data={destinatarios}
                            highlightedRow={destinatarioId}
                            hasMore={masElementos}
                            value={numeroDestinatarioSeleccionado}
                            configColumns={configuracionColumnasDestinatarios}
                            configHeader={configuracionHeadersDestinatarios}
                            next={cargarElementos}
                            onClick={onClickTableCRUD}
                          />
                        </>
                      )}
                    </>
                  )}
                </BodyCard>
              )}
            </DashboardCard.Body>
          </DashboardCard>
        </div>
      </div>

      <AlertModal showModal={showErrorModal} width={616} onClose={handleCloseModal}>
        <div className="text-align-center">
          <div className="mb-16">
            <LoadIcon
              src={`${iconPath}ilustracion_error.svg`}
              className="destinatarios-imagen-error-eliminar"
            />
          </div>
          <div className="mb-16">
            <label className="destinatarios-error-eliminar-text">
              {listarDestinatariosTexts.ERROR_MODAL_TITLE}
            </label>
          </div>
          <div className="mb-50 destinatarios-error-eliminar-desc-container">
            <label className="destinatarios-error-eliminar-text-desc">
              {listarDestinatariosTexts.ERROR_MODAL_DESC}
            </label>
          </div>
        </div>
      </AlertModal>

      <AlertModal
        title={listarDestinatariosTexts.MODAL_ELIMINAR_TITLE}
        showModal={showAlert}
        width={616}
        onClick={handleClickDelete}
        onClose={handleCloseModal}
        labelLeftButton={listarDestinatariosTexts.MODAL_BUTTON_VOLVER}
        labelRightButton={listarDestinatariosTexts.MODAL_BUTTON_ELIMINAR}
        onLoader={isLoaderDeleteDestinatarios}
      >
        {isLoaderDeleteDestinatarios ? (
          <div className="text-align-center">
            <SpinnerLoader />
          </div>
        ) : (
          <div>
            <div className="mt-24">
              <label className="alert-modal-first-text text-bold black">
                {capitalize(destinatarioParaBorrar.nombreCompleto)}
              </label>
            </div>
            <div className="mt-8" />
            <div>
              <label className="black">
                {capitalize(destinatarioParaBorrar.tipoCuenta)} Nº{' '} ss
                {destinatarioParaBorrar.numeroCuenta}
              </label>
            </div>
            <div className="mt-8" />
            <div className="mb-24">
              <label className="black">{capitalize(destinatarioParaBorrar.banco)}</label>
            </div>
          </div>
        )}
      </AlertModal>
    </Container>
  );
};

ListarDestinatariosTemplate.propTypes = {
  createdDestinatario: PropTypes.bool,
  onGoToDatosDestinatario: PropTypes.func,
  reqSuccess: PropTypes.bool,
  reqWasEdition: PropTypes.bool,
  suggestions: PropTypes.array,
  onTypeQueryHandle: PropTypes.func,
  query: PropTypes.string,
  onRemoveHandle: PropTypes.func,
  onSearchHandle: PropTypes.func,
  onClickItemSearchBar: PropTypes.func,
};

ListarDestinatariosTemplate.defaultProps = {
  onGoToDatosDestinatario: null,
  reqSuccess: false,
  reqWasEdition: false,
  suggestions: [],
  onTypeQueryHandle: null,
  query: '',
  onRemoveHandle: null,
  onSearchHandle: null,
  onClickItemSearchBar: null,
};

export default ListarDestinatariosTemplate;
