import React from 'react';
import Alert from '@coopeuch-components/web/atoms/Alert';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Table from '@coopeuch-components/web/molecules/Table';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import StepsActions from '../../../../organism/Stepper/StepsActions';
import { agregarformatoPesos } from '../../../../../utils/formatters';
import Wrapper from '../../core/wrapper';

import { ActionButtons } from './styles';


const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const comprobanteImage = `${staticPath}sello-comprobante.png`;

function ReceiptTemplate({ receiptDetail, onGoBackHome, onPaymentSuccess, handleDownloadPdfReceipt }) {
  const payData = [
    {
      id: 0,
      name: 'Crédito Hipotecario',
      value: `N° ${receiptDetail.referenceNumber}`,
    },
    {
      id: 1,
      name: 'Método de pago',
      value: 'cuenta vista',
    },
    {
      id: 2,
      name: 'N° de cuenta',
      value: receiptDetail.accountNumber,
    },
    {
      id: 3,
      name: 'Fecha de pago',
      value: receiptDetail.paymentDate,
    },
    {
      id: 4,
      name: 'Valor de la cuota',
      value: agregarformatoPesos(receiptDetail.amount),
    },
    {
      id: 5,
      name: 'Dividendo pagado',
      value: receiptDetail.paidDividendNumber,
    },
  ];
  return (
    <Wrapper>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <Alert className="bp-3" bgType="success" id="alert-felicitaciones-pago-cdp" show>
            Pago exitoso.
          </Alert>
        </div>
      </div>
      <div className="row bmt-3 bmt-md-4">
        <div className="col-xs-12">
          <CardBox className="bmb-3 bmb-md-4">
            <CardBox.Header>
              <div className="row">
                <div className="col-xs-6">Comprobante</div>
                <div className="col-xs-6 text-right">
                  <ActionButtons>
                    <div className="comprobante-action bmr-3" onClick={handleDownloadPdfReceipt}>
                      <LoadIcon
                        className="mr-2"
                        src={`${iconPath}download_arrow.svg`}
                        color="#F42534"
                        size={22.5}
                      />
                      <div className="d-md-block d-none action-button-text">Descargar</div>
                    </div>
                  </ActionButtons>
                </div>
              </div>
            </CardBox.Header>
            <CardBox.Body>
              <div className="row">
                <div className="col-xs-12 col-md-8 bmb-md-3 comprobante-card-text d-flex middle-items">
                  <div>
                    <Typography className="mb-10" tag="p" fontWeight="bold" fontSize={14}>
                      Monto Pagado
                    </Typography>
                    <Typography className="mb-10" tag="p" fontWeight="bold" fontSize={24}>
                      <b>
                        {agregarformatoPesos(receiptDetail.amount)}
                      </b>
                    </Typography>
                    {!!receiptDetail.mail && (
                      <Typography tag="p" fontSize={14}>
                        Te enviamos copia de este comprobante a tu email:
                        {' '}
                        <b>{receiptDetail.mail}</b>.
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="col-xs-12 col-md-4">
                  <img className="bm-auto bmr-md-0" src={comprobanteImage} alt="comprobante" />
                </div>
              </div>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <CardBox>
            <CardBox.Header>Detalle del pago Hipotecario</CardBox.Header>
            <CardBox.Body>
              <Table
                borderColor="trasparent"
                fullWidth
                alignContent="left"
                stripeColor="#F6F3F5"
                stripeReverse
              >
                <Table.Body
                  data={payData}
                  renderItems={(item, TR, TD) => {
                    return (
                      <TR key={`detalle-contrato-monedero-${item.id}`}>
                        <TD className="td-table-element-capitalizacion pt-16 pb-16 pl-8 pr-8">
                          {item.name}
                        </TD>
                        <TD className="td-table-element-capitalizacion pt-16 pb-16 pl-8 pr-8">
                          {item.value}
                        </TD>
                      </TR>
                    );
                  }}
                />
              </Table>
            </CardBox.Body>
          </CardBox>
        </div>
      </div>
      <div className="row bmy-3 bmy-md-4">
        <div className="col-xs-12">
          <StepsActions
            backAction={onGoBackHome}
            backText='Ir a home'
            nextAction={onPaymentSuccess}
            nextText="Ir a Crédito Hipotecario"
          />
          {' '}
        </div>
      </div>
    </Wrapper>
  );
}

export default ReceiptTemplate;
