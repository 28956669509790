const initState = {
  errLogsConfiguracion: null,
  configuracion: { success: false, error: false, data: null, date: null },
  errLogResultado: null,
  resultado: { success: false, error: false, errorCode: '', data: null, date: null },
  errLogSolicitar: null,
  solicitar: { success: false, error: false, errorCode: '', data: null, date: null },
};

export default initState;
