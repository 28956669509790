import styled from 'styled-components';

export default styled.div`
  .new-header-padding {
    padding: 16px;
    @media screen and (min-width: 64em) {
      padding: 16px 32px;
    }
  }
`;
