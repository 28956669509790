const initState = {
  isLoaderDestinatarios: true,
  isLoaderDeleteDestinatarios: false,
  isLoadingDatosDestinatario: false,
  isLoadingReglasTerceros: true,
  isLoadingFactoresSeguridad: true,
  isLoadingDesafioYValidarFactorSeguridad: false,
  isLoadingSeleccionarDestinatario: false,
  isLoadingCuotaParticipacion: false,
  isLoadingActualizacionCuotaParticipacion: false,
  isLoadingBorradorDescargarCuotaParticipacion: false,
  isLoadingFactoresCuotaParticipacion: false,
  isLoadingSugerenciaCuotaParticipacion: false,
  isLoadingCapitalizarRemanente: false,
  isLoadingDetalleCapitalizacion: false,
  isLoadingCuentasAhorro: false,
  isLoadingMigracionCuentaAhorroFatca: false,
  isLoadingDesafioSMS: false,
  isLoadingValidarSMS: false,
  isLoadingDatosPersonales: false,
  isLoadingValidarTransferencia: false,
  isLoadingEjecutarTransferencia: false,
  isLoadingReglasEntreProductos: false,
  isLoadingSelectTefEntreProductos: false,
  isLoadingValidacionTefEntreProductos: false,
  isLoadingEjecucionTefEntreProductos: false,
  isLoadingHistorialTransferencias: false,
  isLoadingValidarRutOCrearClave: false,
  isLoadingReglasCrearClave: false,
  isLoadingSelectMetodo: false,
  isLoadingCuentasVista: false,
  isLoadingTarjetasCuentasVista: false,
  isLoadingUltMovimientosCuentasVista: false,
  isLoadingMovimientosCuentasAhorro: false,
  isLoadingDetalleCuentasAhorro: false,
  isLoadingTarjetasCredito: false,
  isLoadingFacturacionTarjetaCredito: false,
  isLoadingCupoTarjetaCredito: false,
  isLoadingPatPassTarjetaCredito: false,
  isLoadingPagarTarjetaCredito: false,
  isLoadingPagarTarjetaCreditoComprobante: false,
  isLoadingCupoTarjetaAdicionales: false,
  isLoadingMovimientosTarjetaCredito: false,
  isLoadingPDFTarjetaCredito: false,
  isLoadingDepositosContratados: false,
  isLoadingSimulacionMultiple: false,
  isLoadingDAPSimulacion: false,
  isLoadingLiquidarDAP: false,
  isLoadingComprobanteLiquidar: false,
  isLoadingSeleccionarSimulacionDAP: false,
  isLoadingDAPInvertir: false,
  isLoadingDAPComprobanteInvertir: false,
  isLoadingTarjetasCoordenadas: false,
  isLoadingTarjetasDebito: false,
  isLoadingIniciarFlujo: false,
  isLoadingDashboardProductos: false,
  isLoadingValidarSolicitudCV: false,
  isLoadingContratosCuentaAhorro: false,
  isLoadingBorradorContratoCuentaAhorro: false,
  isLoadingMigrarCuentaAhorro: false,
  isLoadingCertificadoSII: false,
  isLoadingTipoCuentas: false,
  isLoadingReglasFactoresSeguridad: false,
  isLoadingBancos: false,
  isLoadingCambioEstadoTjCoord: false,
  isLoadingCambioEstadoTjDebito: false,
  isLoadingMotivoTarjetaBloqueo: false,
  isLoadingRemanente: false,
  isLoadingContenidoRemanente: false,
  isLoadingComposicionRemanente: false,
  isLoadingCobrarRemanente: false,
  isLoadingComprobanteCobroRemanente: false,
  isLoadingListaNegra: false,
  isLoadingContratable: false,
  isLoadingContratado: false,
  isLoadingHabilitadoCell: false,
  isLoadingHabilitadoEmail: false,
  isLoadingDatosContacto: false,
  isLoadingEditDatosContacto: false,
  isLoadingDireccionObtener: false,
  isLoadingEditDireccionComercial: false,
  isLoadingBeneficios: false,
  isLoadingRubro: false,
  isLoadingRegion: false,
  isLoadingContratacionMonedero: false,
  isLoadingBannerContratacionCV: false,
  isLoadingSucursalesComuna: false,
  isLoadingValidarTarjetaCoordenadas: false,
  isLoadingValidarAptoAvance: false,
  isLoadingCampanaCrm: false,
  isLoadingValidarAlivioTc: false,
  isLoadingReprogramacionCuotas: false,
};

export default initState;
