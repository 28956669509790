import React from 'react';
import PropTypes from 'prop-types';

import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoader from '../../atoms/SpinnerLoader';

import BorrarIcon from './TableCRUDIcons/BorrarIcon';
import EditarIcon from './TableCRUDIcons/EditarIcon';

import Wrapper, { WrapperLoader } from './styles';

const TableCRUD = ({
  data,
  next,
  highlightedRow,
  hasMore,
  configColumns,
  configHeader,
  onClick,
}) => {
  return (
    <InfiniteScroll
      dataLength={data.length}
      next={next}
      hasMore={hasMore}
      loader={
        <WrapperLoader>
          <SpinnerLoader />
        </WrapperLoader>
      }
      endMessage={<div />}
    >
      {data.map((item, index) => (
        <Wrapper index={index} key={index} highlightedRow={item.value === highlightedRow}>
          <div className="row table-crud-row-container">
            {configColumns.map(
              (valueC, indexC) =>
                item[configHeader[indexC]] && (
                  <div
                    className={
                      'black table-crud-col table-crud-col-wordBreak col-xs-12 col-md-' + valueC
                    }
                    key={indexC}
                  >
                    {item[configHeader[indexC]]}
                  </div>
                )
            )}
          </div>
          <div className="table-crud-icons-container">
            <div className="icon-edit" onClick={() => onClick('edit', item.value)}>
              <EditarIcon />
            </div>
            <div className="icon-delete" onClick={() => onClick('delete', item.value)}>
              <BorrarIcon />
            </div>
          </div>
        </Wrapper>
      ))}
    </InfiniteScroll>
  );
};

TableCRUD.propTypes = {
  data: PropTypes.array.isRequired,
  hasMore: PropTypes.bool,
  configColumns: PropTypes.array.isRequired,
  configHeader: PropTypes.array.isRequired,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  next: PropTypes.func,
  highlightedRow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TableCRUD.defaultProps = {
  data: [],
  hasMore: false,
  configColumns: [],
  configHeader: [],
  onClickEdit: () => {},
  onClickDelete: () => {},
  next: () => {},
  highlightedRow: null,
};

export default TableCRUD;
