import styled from 'styled-components';

export const Wrapper = styled.div`
  .font-title-mobile {
    font-size: 0.9em;
  }
  @media screen and (max-width: 320px) {
    .font-title-mobile {
      font-size: 0.76em;
    }
  }
`;

export const Hr = styled.hr`
  height: 1px;
  background-color: #e9e9e9;
  border: none;
`;

export const ExpandContent = styled.div`
  align-items: center;

  h5 {
    font-size: 18px;
    margin: 0 10px;
    width: 75%;
  }

  ul {
    list-style: none;
    line-height: 26px;
    margin-right: 24px;

    li {
      padding-bottom: 10px;
      position: relative;
      &::before {
        @media screen and (min-width: 320px) {
          position: absolute;
          top: 0px;
        }
      }
    }

    div {
      padding-bottom: 8px;
      padding-left: 12px;
    }
  }
`;

export const RightCardWrapper = styled.div`
  .right-card-resumen-cdp {
    > section:first-child {
      height: 100%;
      > div:first-child {
        height: 100%;
      }
    }
  }
`;

export const AporteWrapper = styled.div`
  display: flex;
  flex-flow: row;
  .tt-content {
    width: 200px;
    height: auto;
    @media screen and (max-width: 400px) {
      width: 120px;
      top: 15px;
    }
  }
  .ammount-tooltip {
    top: 30px;
  }
  @media screen and (max-width: 768px) {
    .ammount-tooltip {
      top: 32px;
    }
  }
  @media screen and (max-width: 400px) {
    .ammount-tooltip {
      left: ${({ ammount }) => (ammount > 99999 ? '-50px' : '0')};
      top: 32px;
    }
  }
`;
