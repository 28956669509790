import React from 'react';

const ArrowIcon = () => (
  <svg width="20px" height="20px" viewBox="0 0 20 20">
    <title>ico-action (opcional)</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="ico-action-(opcional)">
        <polygon id="Fill-1" points="0 20 20 20 20 0 0 0"></polygon>
        <g id="00.Token/Icons/Directional/Arrow-Down/20x20" fill="#007DB7">
          <g id="00.Token/Icons/Directional/Arrow-Down/16x16">
            <path
              d="M5.95833333,6.55208333 L10,10.59375 L14.0416667,6.55208333 C14.4479167,6.14583333 15.1041667,6.14583333 15.5104167,6.55208333 C15.9166667,6.95833333 15.9166667,7.61458333 15.5104167,8.02083333 L10.7291667,12.8020833 C10.3229167,13.2083333 9.66666667,13.2083333 9.26041667,12.8020833 L4.47916667,8.02083333 C4.07291667,7.61458333 4.07291667,6.95833333 4.47916667,6.55208333 C4.88541667,6.15625 5.55208333,6.14583333 5.95833333,6.55208333 Z"
              id="arrow-color"
            ></path>
          </g>
        </g>
        <g id="Page-1"></g>
      </g>
    </g>
  </svg>
);

export default ArrowIcon;
