import styled from 'styled-components';

export const BorderLabel = styled.label`
${({ rounded }) => rounded && `border-radius: ${rounded}px`}
`
const Wrapper = styled.form`
  position: relative;

  ${({ width }) => width && `width: ${width}px;`}

  label {
    display: flex;
    border: 1px solid #646569;
    align-items: center;
    padding: 0 10px;
    ${({ rounded }) => rounded && `border-radius: ${rounded}px`}
  }

  input {
    background: transparent;
    height: 45px;
  }

  @media screen and (max-width: 766px) {
    width: 100%;
  }
`;

export const IconWrapper = styled.div`
  cursor: pointer;
`;

export const Suggestions = styled.div`
  margin: 1px;
  box-shadow: 0px 0px 6px -1px rgba(0, 0, 0, 0.75);
  background: white;
  position: absolute;
  left: 0;
  right: 0;
  text-align: left;
`;

export const SuggestionItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
`;

export default Wrapper;
