import styled from 'styled-components';

const Wrapper = styled.div`
   height: 100%;

  .cardContainer {
    box-shadow: 0 2px 4px 0 rgb(31 30 30 / 10%);
    background-color: #ffffff;
    padding: 1px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .bonoSolicitar {
    &__content {
      &__space {
        padding: 32px;
        height: 100%;
        @media screen and (max-width: 500px) {
          padding: 16px;
        }
      }

      &__info {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
        @media screen and (max-width: 500px) {
          max-width: 94%;
        }
      }

      &__monto {
        padding-top: 24px;
        &__montoText {
          font-family: 'Ubuntu-Bold';
          font-size: 16px;
        }
        &__montoNumber {
          padding-top: 8px;
          color: #e81e2b;
          font-size: 26px;
          font-family: 'Ubuntu-Bold';
          font-weight: 900;
          @media screen and (max-width: 500px) {
            font-size: 28px;
          }
        }
      }

      &__requisitos {
        padding-top: 24px;
        &__title {
          font-family: 'Ubuntu-Bold';
          font-size: 16px;
        }

        &__info {
          ul > li {
            padding-top: 8px;
            margin-left: 22px;

            ::marker {
              font-size: 22px;
            }
          }

          ul > li > p {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }

      &__documentos {
        padding-top: 24px;
        &__title {
          font-family: 'Ubuntu-Bold';
          font-size: 16px;
        }

        &__docsRequeridos {
          > div {
            display: flex;
            align-items: flex-end;
            padding-top: 8px;

            > div > svg {
              vertical-align: -webkit-baseline-middle;
            }

            @media screen and (max-width: 500px) {
              align-items: flex-start;
              padding-top: 16px;
            }
          }

          > div > div {
            width: 100%;
            max-width: 30px;
          }

          > div > p {
            width: 90%;
            padding-left: 5px;
            font-size: 14px;
            line-height: 22px;
          }
        }
      }
      &__alert {
        margin-top: 24px;
        p {
          font-size: 14px;
          font-family: 'Ubuntu-Regular';
        }
      }
    }
  }
  .btnSolicitar {
    display: flex;
    padding: 16px 32px;
  
    button {
      width: 100%;

      @media screen and (max-width: 500px) {
        width: 100%;
        max-width: initial;
      }
    }
    span {
      font-size: 14px;
      font-family: 'Ubuntu-Medium';
    }

    @media screen and (max-width: 500px) {
      padding: 16px;
      border-top: none;
    }
  }
`;
export default Wrapper;
