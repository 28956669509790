import styled, { keyframes } from 'styled-components';
import { colors } from '../../../assets/styles';
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  & > svg {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    transform-origin: center;
    animation: ${spin} 2s linear infinite;
  }
`;

export const Wrapper = styled.div`
  height: 100vh;
  @media (min-width: 64em) {
    min-height: 100vh;
  }
  .advices {
    position: relative;
  }
  .advices-title {
    font-size: 22px;
    font-weight: bold;
  }
  .advices:after,
  .advices:before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .advices:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: rgba(3, 125, 183, 0.25);
    border-width: 5px;
    margin-top: -5px;
  }
  .advices:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: rgba(3, 125, 183, 0.25);
    border-width: 6px;
    margin-top: -6px;
  }
  .submit-button-wrapper {
    position: relative;
  }

  .err-red {
    color: #cc284e !important;
  }

  .input--login {
    border-radius: 4px;
    border: 1px solid #646569;
    background-color: #ffffff;

    &::placeholder {
      color: #9c9a9f;
    }
  }

  .input--login--error {
    border: 1px solid #cc284e;
  }

  .login--warpper-input {
    span {
      color: #646569 !important;
      position: absolute;
      bottom: 5px;
    }

    small {
      color: #646569 !important;
    }
  }
  .newButton{
    display: inline-flex
    margin-left: 3rem;
    width: 60%;
  }
`;

export const LeftCol = styled.div`
  background-size: cover;
`;
export const RightCol = styled.div`
  min-height: 650px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
`;
export const TextCol = styled.div`
  border-radius: 10px;
  background-color: #005d8f65;
  color: white;
  table-layout: fixed;
  td {
    font-size: 14px;
    .icon-list-item {
      margin: 0 auto;
    }
  }
  .table-spacer {
    height: 16px;
  }
`;

export const Logo = styled.img`
  width: 190px;
  margin-top: 50px;
`;

export const Alert = styled.div`
  svg {
    fill: #cc284e;

    path {
      fill: #cc284e;
    }
  }

  .login--content-alert {
    background: #f9d4d5;
    padding: 10px;
    color: #cc284e;
    border-radius: 2px;
    display: flex;
    font-size: 16px;
    text-align: initial;
  }

  @media screen and (max-width: 766px) {
    margin-left: 10px !important;
    margin-right: -6px !important;
  }
`;

export const PasswordProblemWrapper = styled.div`
  button {
    span {
      color: #f42534;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      text-decoration: underline;
    }
  }
`;

export const MessageError = styled.div`
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;

export const LoginCard = styled.div`
  min-width: 227px;
  .public-site-link {
    color: ${() => colors.red};
    svg {
      path {
        fill: ${() => colors.red};
      }
    }
  }
  .login-title {
    img {
      margin: 0 auto;
    }
  }
  .login-input-fields {
    svg {
      fill: #cc284e;

      path {
        fill: #cc284e;
      }
    }
  }
  .login--warpper-input {
    width: 100%;
  }
  .checkbox-remember-data {
    margin-left: -8px;
  }
`;

export const ButtonScreen = styled.div`
  background-color: transparent;
  position: absolute;
  height: 100%;
  width: calc(100% - 1rem);
  z-index: 10;
  cursor: not-allowed !important;
`;
