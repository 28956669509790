import React, { useState, useEffect, useRef } from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PageTitle from '../../components/atoms/PageTitle';
import Error from '../../components/atoms/Error';
import BeneficiosTemplate from '../../components/templates/Beneficios';
import { registerGA } from '../../utils/metrics';
import { formatArrayForSelect } from '../../utils/formatters';
import { findByKey } from '../../utils/functions';
// actions
import { beneficiosActions } from '../../../application/actions/beneficios';
import logger from '../../utils/logger';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = () => {
  const [beneficios, setBeneficios] = useState(null);
  const [resultados, setResultados] = useState(null);

  // States handleChangeSelectRubro
  const [rubro, setRubro] = useState(null);
  const [todosRubro, setTodosRubro] = useState(false);
  const [selectStateRubro, setSelectStateRubro] = useState('');
  const [selectCodeRubro, setSelectCodeRubro] = useState(null);

  // States handleChangeSelectRegion
  const [region, setRegion] = useState(null);
  const [todosRegion, setTodosRegion] = useState(false);
  const [selectStateRegion, setSelectStateRegion] = useState('');
  const [selectCodeRegion, setSelectCodeRegion] = useState(null);

  // flags
  const [mounted, setMounted] = useState(false);
  const [errorGeneral, setErrorGeneral] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const beneficiosObtener = useSelector(
    (store) => store.entities.beneficios.beneficiosObtener,
    shallowEqual
  );
  // console.log({beneficiosObtener});
  const rubroObtener = useSelector((store) => store.entities.beneficios.rubroObtener, shallowEqual);
  const persona = useSelector((store) => store.entities.persona, shallowEqual);
  const isLoadingBeneficios = useSelector(
    (store) => store.ui.loaders.isLoadingBeneficios,
    shallowEqual
  );
  const isLoadingRubro = useSelector((store) => store.ui.loaders.isLoadingRubro, shallowEqual);
  const isLoadingRegion = useSelector((store) => store.ui.loaders.isLoadingRegion, shallowEqual);
  const errorBeneficios = useSelector(
    (store) => store.entities.beneficios.errorBeneficios,
    shallowEqual
  );
  const errorRubro = useSelector((store) => store.entities.beneficios.errorRubro, shallowEqual);
  const errorRegion = useSelector((store) => store.entities.beneficios.errorRegion, shallowEqual);

  const regionObtener = useSelector(
    (store) => store.entities.beneficios.regionObtener,
    shallowEqual
  );

  const setGlobalError = () => {
    setErrorGeneral(true);
  };

  const handleChangeSelectRubro = (e) => {
    const valueSelection = e.target.value;

    // Variable de evaluación si existe 'Todos'
    const nombreRegion = todosRegion
      ? 'Todos'
      : findByKey(region, 'value', selectCodeRegion)?.label || null;

    if (valueSelection === 'Todos') {
      setTodosRubro(true);
      // console.log('Todos Rubro');
      dispatch(
        beneficiosActions.fetchBeneficiosObtener({
          canal: 'WEB',
          idRubro: null,
          codRegion: selectCodeRegion,
        })
      );
      // console.log('categoria todos');

      // Analytics
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/conoce/beneficios-ofertas',
        'eve-cat': 'Click',
        'eve-lab': 'Filtro',
        producto: `Todos - ${nombreRegion}`,
      });
      // console.log(nombreRegion);
      setSelectCodeRubro(null);
    } else {
      setTodosRubro(false);
      dispatch(
        beneficiosActions.fetchBeneficiosObtener({
          canal: 'WEB',
          idRubro: valueSelection,
          codRegion: selectCodeRegion,
        })
      );
      // console.log(found(rubro, valueSelection).label);

      // Analytics
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/conoce/beneficios-ofertas',
        'eve-cat': 'Click',
        'eve-lab': 'Filtro',
        producto: `${findByKey(rubro, 'value', valueSelection).label} - ${nombreRegion}`,
      });

      setSelectCodeRubro(valueSelection);
    }
    setSelectStateRubro(valueSelection);
    setLoading(true);
  };

  const handleChangeSelectRegion = (e) => {
    const valueSelection = e.target.value;
    // console.log(valueSelection);

    // Variable de evaluación si existe 'Todos'
    const nombreRubro = todosRubro
      ? 'Todos'
      : findByKey(rubro, 'value', selectCodeRubro)?.label || null;

    if (e.target.value === 'Todos') {
      setTodosRegion(true);
      // console.log('Todos Region');
      dispatch(
        beneficiosActions.fetchBeneficiosObtener({
          canal: 'WEB',
          idRubro: selectCodeRubro,
          codRegion: null,
        })
      );

      // Analytics
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/conoce/beneficios-ofertas',
        'eve-cat': 'Click',
        'eve-lab': 'Filtro',
        producto: `${nombreRubro} - Todos`,
      });

      setSelectCodeRegion(null);
    } else {
      setTodosRegion(false);
      dispatch(
        beneficiosActions.fetchBeneficiosObtener({
          canal: 'WEB',
          idRubro: selectCodeRubro,
          codRegion: valueSelection,
        })
      );
      // console.log(found(region,valueSelection).label)

      // Analytics
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/conoce/beneficios-ofertas',
        'eve-cat': 'Click',
        'eve-lab': 'Filtro',
        producto: `${nombreRubro} - ${findByKey(region, 'value', valueSelection).label}`,
      });
      setSelectCodeRegion(valueSelection);
    }

    setSelectStateRegion(valueSelection);
    setLoading(true);
  };

  useEffect(() => {
    if (!mounted) {
      dispatch(
        beneficiosActions.fetchBeneficiosObtener({
          canal: 'WEB',
          idRubro: null,
          codRegion: null,
        })
      );
      dispatch(beneficiosActions.fetchRubroObtener());
      dispatch(beneficiosActions.fetchRegionObtener());
      setMounted(true);
      // console.log('mounted');
    }

    // Analytics de ingreso a la pagina
    registerGA({
      event: 'vpv-privado',
      page: '/tef/conoce/beneficios-ofertas',
    });
  }, []);

  const todos = { value: 'Todos', label: 'Todos' };

  useEffect(() => {
    if (mounted && beneficiosObtener.success && rubroObtener.success && regionObtener.success) {
      // console.log('entrando');
      setBeneficios([...beneficiosObtener.data].sort((a, b) => a.posicion - b.posicion));

      // Carga data componente select Rubros
      const rubroIterable = formatArrayForSelect(
        rubroObtener.data,
        'value',
        'label',
        'id',
        'descripcion'
      );
      setRubro([todos, ...rubroIterable]);

      // Carga data componente select Regiones
      const regionIterable = formatArrayForSelect(
        regionObtener.data,
        'value',
        'label',
        'codigo',
        'descripcion'
      );
      setRegion([todos, ...regionIterable]);
      setLoading(false);

      if (beneficiosObtener.data.length < 1) {
        // Analytics cuando no hay beneficios que coincidan con filtros
        registerGA({
          event: 'vpv-privado',
          page: '/tef/conoce/beneficios-ofertas/sin-resultados',
        });
      }
    }

    if (beneficiosObtener.error || rubroObtener.error || regionObtener.error) {
      setGlobalError();
      // Analytics sin servicios
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/conoce/beneficios-ofertas',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
    // console.log('[beneficiosObtener,regionObtener,rubroObtener]');
  }, [beneficiosObtener, regionObtener, rubroObtener, mounted]);

  useEffect(() => {
    if (beneficios != null) {
      setResultados([...beneficios]);
    }
  }, [beneficios]);

  useEffect(() => {
    if (resultados != null) {
      setLoading(false);
    }
  }, [resultados]);

  const prevLoadingBeneficios = useRef(false);
  const prevLoadingRubro = useRef(false);
  const prevLoadingRegion = useRef(false);

  useEffect(() => {
    if (
      prevLoadingBeneficios.current !== isLoadingBeneficios ||
      prevLoadingRubro.current !== isLoadingRubro ||
      prevLoadingRegion.current !== isLoadingRegion
    ) {
      if (!isLoadingBeneficios && !isLoadingRubro && !isLoadingRegion) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        console.log(errorBeneficios);
        console.log(errorRubro);
        console.log(errorRegion);
        logger(errorBeneficios || errorRubro || errorRegion, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'BENEFICIOS Y OFERTAS-INGRESO',
          informacion_adicional: {},
        });
      }
    }
    prevLoadingBeneficios.current = isLoadingBeneficios;
    prevLoadingRubro.current = isLoadingRubro;
    prevLoadingRegion.current = isLoadingRegion;
  }, [isLoadingBeneficios, isLoadingRubro, isLoadingRegion]);

  return (
    <>
      <PageTitle
        title="Beneficios y Ofertas"
        icon={<LoadIcon src={`${iconPath}gift.svg`} color="black" />}
      />
      <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
        {errorGeneral ? (
          <Error className="bmt-3 bmt-md-4" />
        ) : (
          <BeneficiosTemplate
            {...{
              resultados,
              loading,
              rubro,
              region,
              selectStateRegion,
              handleChangeSelectRegion,
              selectStateRubro,
              handleChangeSelectRubro,
            }}
          />
        )}
      </div>
    </>
  );
};

Index.displayName = 'Container_Beneficios';
export default Index;
