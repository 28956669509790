import React, { Fragment, useCallback, useEffect } from 'react';

import PageTitle from '../../../components/atoms/PageTitle';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import SolicitaTarjetaDeCreditoTemplate from '../../../components/templates/TarjetaDeCredito/SolicitarTarjetaLayout';
import { registerGA } from '../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const SolicitaTarjetaDeCredito = () => {
  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/tef/tarjeta-de-credito/sin-tarjeta/',
    });
  }, []);

  return (
    <Fragment>
      <PageTitle
        title="Tarjeta de Crédito"
        icon={
          <LoadIcon src={`${iconPath}mastercard.svg`} color={'black'} className="reverse-content" />
        }
      />
      <SolicitaTarjetaDeCreditoTemplate/>
    </Fragment>
  );
};

export default SolicitaTarjetaDeCredito;
