import React from 'react';
import PropTypes from 'prop-types';
// UI
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
// Styles
import Wrapper, { Title, Line } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardHead = ({
  classes,
  bgColor,
  bordered,
  icon,
  size,
  iconColor,
  title,
  titleSize,
  titleColor,
  titleClass,
  titleWeight,
  height,
  rightContent,
  leftContent,
  borderBottom,
  mSize,
  padding,
}) => (
  <>
    <Wrapper
      className={classes}
      height={height}
      bgColor={bgColor}
      bordered={bordered}
      padding={padding}
    >
      {leftContent ? (
        leftContent
      ) : (
        <div className="dashboard--header_left">
          {icon && (
            <LoadIcon
              src={`${iconPath}${icon}.svg`}
              size={size}
              color={iconColor}
              className="mr-8"
            />
          )}
          <Title
            mSize={mSize}
            size={titleSize}
            className={titleClass}
            titleWeight={titleWeight}
            color={titleColor}
          >
            {title}
          </Title>
        </div>
      )}

      <div>{rightContent}</div>
    </Wrapper>
    {borderBottom && (
      <div className="row">
        <div className="col-xs-12">
          <Line borderBottom={borderBottom} />
        </div>
      </div>
    )}
  </>
);

CardHead.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  size: PropTypes.number,
  bgColor: PropTypes.string,
  bordered: PropTypes.number,
  icon: PropTypes.string,
  titleSize: PropTypes.number,
  titleClass: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  mSize: PropTypes.number,
  titleColor: PropTypes.string,
  titleWeight: PropTypes.string,
  iconColor: PropTypes.string,
  rightContent: PropTypes.node,
  leftContent: PropTypes.node,
  borderBottom: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  padding: PropTypes.string,
};

CardHead.defaultProps = {
  size: 24,
  mSize: 18,
  bgColor: 'transparent',
  bordered: 5,
  titleSize: 20,
  titleColor: '#383638',
  iconColor: '#383638',
  titleWeight: '',
  height: 70,
  classes: 'dashboardcard-head',
  rightContent: null,
  leftContent: null,
  titleClass: '',
  borderBottom: '',
  padding: '24px 32px',
  title: '',
  icon: null,
};

export default CardHead;
