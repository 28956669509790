import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Skeleton from 'react-loading-skeleton';

const Index = () => {
  return (
    <>
      <div className="col-xs-12 col-md-4 bmy-3">
        <CardBox className="h-100 w-auto bmr-md-1">
          <CardBox.Header className="padding_header_card">
            <Skeleton width={25} height={15} />
            {''}
            <Skeleton width={'70%'} height={15} />
          </CardBox.Header>
          <CardBox.Body className="mb-30">
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
          </CardBox.Body>
        </CardBox>
      </div>

      <div className="col-xs-12 col-md-4 bmy-3">
        <CardBox className="h-100 w-auto bmr-md-1">
          <CardBox.Header className="padding_header_card">
            <Skeleton width={25} height={15} />
            {''}
            <Skeleton width={'70%'} height={15} />
          </CardBox.Header>
          <CardBox.Body className="mb-30">
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
          </CardBox.Body>
        </CardBox>
      </div>

      <div className="col-xs-12 col-md-4 bmy-3">
        <CardBox className="h-100 w-auto bmr-md-1">
          <CardBox.Header className="padding_header_card">
            <Skeleton width={25} height={15} />
            {''}
            <Skeleton width={'70%'} height={15} />
          </CardBox.Header>
          <CardBox.Body className="mb-30">
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
            <Skeleton width={'70%'} height={15} />
          </CardBox.Body>
        </CardBox>
      </div>
    </>
  );
};

export default Index;
