import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import DashboardCard from '../../../../organism/DashboardCard';
import Select from '../../../../atoms/Select';

import { formatCurrency } from '../../../../../utils/functions';

function ConfirmacionCuenta(props) {
  const { selected, data, handleChange } = props;

  const saldoDisponible = useMemo(() => {
    return data?.find((cuenta) => cuenta?.value === selected)?.saldoDisponible;
  }, [selected, data]);

  return (
    <DashboardCard classes="dap--card dap-top--card">
      <DashboardCard.Head title="Cuenta de destino" borderBottom="complete" />
      <DashboardCard.Body classes="pt-24 pl-32 pr-32 dap--card--content">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs pb-4 black">
            <p className="text-bold pb-8">Número de cuenta</p>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                {data?.length >= 2 ? (
                  <Select
                    borderColor="#646569"
                    placeholder="Selecciona Cuenta"
                    onChange={handleChange}
                    options={data}
                    value={selected}
                    size={48}
                  />
                ) : (
                  <div className="text-size-s black cv--resumen--seleccion-simulacion">
                    {selected && <Fragment>Cuenta Vista Coopeuch: N° {selected}</Fragment>}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs saldo--dap black">
            <p className="">Saldo disponible:</p>
            <p className="text-size-big text-bold">{formatCurrency(saldoDisponible)}</p>
          </div>
        </div>
      </DashboardCard.Body>
    </DashboardCard>
  );
}

ConfirmacionCuenta.propTypes = {
  selected: PropTypes.string,
  data: PropTypes.array,
  handleChange: PropTypes.func,
};

ConfirmacionCuenta.defaultProps = {
  selected: '',
  data: [],
  handleChange: null,
};

export default ConfirmacionCuenta;
