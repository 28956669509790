import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import addClass from '@coopeuch-components/web/functions/addClass';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const InputForForm = (props) => {
  const {
    label,
    showLockIcon,
    onChange,
    dataset,
    value,
    id,
    type,
    placeholder,
    onClickIconRight,
    iconRight,
    maxLength,
    error,
    helpText,
    disabled,
  } = props;

  return (
    <Fragment>
      {typeof label === 'string' ? (
        <p className={`text-bold mb-14 black ${disabled ? "disabled" : ""}`} >{label}</p>
      ) : (
        <div>{label}</div>
      )}
      <div className="wrapper--input--clave black">
        {showLockIcon && (
          <LoadIcon src={`${iconPath}lock.svg`} size={20} color="#646569" className="lock-icon" />
        )}
        <InputLabel
          textSize={16}
          size={48}
          borderColor={error ? '#cc284e' : '#646569'}
          onChange={onChange}
          dataSet={dataset}
          value={value}
          type={type}
          className="w-100"
          disabled={disabled}
          id={id}
          inputClass={addClass([showLockIcon && 'pl-28', 'input-for-form'])}
          placeholder={placeholder}
          iconFunc={onClickIconRight}
          iconRight={
            iconRight && (
              <LoadIcon
                src={`${iconPath}${iconRight}.svg`}
                size={20}
                color="#646569"
                className="eye-icon"
              />
            )
          }
          maxLength={maxLength}
        />
        {Boolean(error) ? (
          <div className="help-text dark-red text-size-s back--icon">
            <div className="row ml-0">
              <LoadIcon src={`${iconPath}circle_close.svg`} size={14} className="mr-4 dark-red icon-dark-red" />
              <p>{error}</p>
            </div>
          </div>
        ) : (
          <small className="help-text">{helpText}</small>
        )}
      </div>
    </Fragment>
  );
};

InputForForm.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  showLockIcon: PropTypes.bool,
  onChange: PropTypes.func,
  dataset: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onClickIconRight: PropTypes.func,
  iconRight: PropTypes.string,
  maxLength: PropTypes.number,
  error: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
};

InputForForm.defaultProps = {
  label: '',
  showLockIcon: false,
  onChange: null,
  dataset: '',
  value: '',
  id: '',
  type: 'text',
  placeholder: '',
  onClickIconRight: null,
  iconRight: '',
  maxLength: null,
  error: '',
  helpText: '',
  disabled: false,
};

export default InputForForm;
