import styled from 'styled-components';

export const TabsWrapper = styled.div`
  .tab--wrapper-shadows {
    width: 100%;
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    .wrapper-responsive {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }
`;
