import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { bonoEscolarActions } from '../../../../../application/actions/bonoEscolar';
import ComprobanteTemplate from '../../../../components/templates/BonoEscolar/Comprobante';
import { registerGA } from '../../../../utils/metrics';

const Comprobante = () => {
  const dataBono = useSelector((store) => store.entities.bonoEscolar.dataBono);
  const saveBono = useSelector((store) => store.entities.bonoEscolar.saveBono);
  const downloadPdfBono = useSelector((store) => store.entities.bonoEscolar.downloadPdfBono);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    registerGA({
      event: 'vpv-privado',
      page: '/beneficios/bono-estudios/solicitudes/carga-comprobante',
      producto: dataBono.typeBono === 'Bono de Ingreso' ? 'Bono Ingreso' : 'Bono Titulacion',
    });
  }, []);

  if (!dataBono.documentos.isRejected) {
    return <Redirect to="/ecd/bono-escolar" />;
  }
  return (
    <ComprobanteTemplate
      dataBonos={dataBono}
      saveBono={saveBono}
      handleClickBack={() => {
        history.push('/ecd/inicio');
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios/solicitudes/carga-comprobante',
          'eve-cat': 'Click',
          'eve-lab': 'Ir al inicio',
        });
      }}
      handleDownloadPdfBono={() => {
        dispatch(
          bonoEscolarActions.fetchDownloadPdfBono({
            idPostulacion: saveBono.data?.uuid,
            tipoComprobante: 'DOCUMENTOS_OBJETADOS',
          })
        );
        registerGA({
          event: 'eve',
          'eve-acc': '/beneficios/bono-estudios/solicitudes/carga-comprobante',
          'eve-cat': 'Click',
          'eve-lab': 'Descargar',
        });
      }}
      isFetchingDownloadPdfBono={downloadPdfBono.isFetching}
      isErrorDownloadPdfBono={downloadPdfBono.error}
      handleRetryDownloadPdfBono={() => {
        dispatch(bonoEscolarActions.retryDownloadPdfBono());
      }}
    />
  );
};

export default Comprobante;
