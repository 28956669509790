import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Typography from '@coopeuch-components/web/atoms/Typography';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import { Paragraph } from '../../../assets/styles/styled_components';
import { LabelWrapper, MessageError } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ContratacionTerminos = (props) => {
  const { 
    checked,
    handleCheckBox,
    checkdRequiredError = false,
    textTerminosCondiciones, // acepta los terminos y condiciones
    textAutorizoTerminos, // si, acepto
    texErrortAutorizo // debe  señalar si para continuar
  } = props;

  const checkRef = useRef();

  // Automáticamente scrollear hasta dar foco al componente de checkbox cuando hay un error
  // y así es siempre visible dónde ocurrió el error
  if (checkdRequiredError && checkRef.current) {
    checkRef.current.scrollIntoView();
  }

  return (
    <div ref={checkRef}>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header><Typography tag="p" fontSize={22} fontWeight="bold">Términos y condiciones</Typography></CardBox.Header>
        <CardBox.Body>
          <Paragraph className="mb-16">
            {textTerminosCondiciones}
          </Paragraph>
          <Paragraph className="flex-column">
            <CheckBox
              name="checked"
              className="checkbox-element"
              text={textAutorizoTerminos}
              borderColor={checkdRequiredError ? '#e81e2b' : '#646569'}
              id="input-auth-contratacion-monedero"
              fontSize={16}
              isChecked={checked}
              onChange={handleCheckBox}
            />

            {checkdRequiredError && (
              <LabelWrapper>
                <MessageError className="password-error text-size-s dark-red mt-8">
                  <div>
                    <LoadIcon
                      src={`${iconPath}circle_close.svg`}
                      size={14}
                      color="#CC284E"
                      className="mr-8 dark-red"
                    />
                    {texErrortAutorizo}
                  </div>
                </MessageError>
              </LabelWrapper>
            )}
          </Paragraph>
        </CardBox.Body>
      </CardBox>
    </div>
  );
};

ContratacionTerminos.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleCheckBox: PropTypes.func.isRequired,
  checkdRequiredError: PropTypes.bool,
  textTerminosCondiciones: PropTypes.string.isRequired,
  textAutorizoTerminos: PropTypes.string.isRequired,
  texErrortAutorizo: PropTypes.string.isRequired,
};

ContratacionTerminos.defaultProps = {
  checkdRequiredError: false,
};

export default ContratacionTerminos;
