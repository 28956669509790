import styled from 'styled-components';

export const SliderPage = styled.div`
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${({ src }) => src});
  color: #fff;

  .nueva-app-list {
    display: flex;
  }

  .nueva-app-container {
    padding: 24px;
  }

  .nueva-app-title {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
  }

  .nueva-app-subtitle {
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px;
  }

  .nueva-app-list-text {
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
  }

  .display-desktop {
    display: flex;
  }
  .display-mobile {
    display: none;
  }

  .icon-new-app svg {
    height: 152px;
    width: 110px;
  }

  .icon-new-app-container {
    text-align: right;
  }

  @media screen and (max-width: 766px) {
    background-image: url(${({ srcMobile }) => srcMobile});

    .display-mobile {
      display: flex;
    }
    .display-desktop {
      display: none;
    }
    .icon-new-app svg {
      height: 91px;
      width: 66px;
    }
    .icon-new-app-container {
      text-align: left;
    }
  }
`;
