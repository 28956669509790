import React, { useContext } from 'react';
import PropTypes from 'prop-types';
// utils
import Context from '../context';
// Assets
import ArrowRightIco from './ArrowRightIcon';
// styles
import BaseItem, { BaseBody, BaseHeader, BaseIcon } from './styles';

const ExpandItem = ({ position, children, bgHeaderColor, iconID, isExpand, isBordered }) => {
  const { items, onClickExpand, iconColor, rounded } = useContext(Context);
  const _pos = position - 1;

  const isExpanded = items[_pos];

  return (
    <BaseItem className="w-100">
      <BaseHeader
        className="row"
        bgColor={bgHeaderColor}
        rounded={rounded}
        isExpanded={isExpanded || isExpand}
        isBordered={isBordered}
      >
        <div className="expand-item-children col-xs">{children[0]}</div>
        <div
          className="item-arrow-wrapper bp-3 bmr-2 d-flex cursor-pointer"
          onClick={onClickExpand}
          data-position={_pos}
          id={iconID}
        >
          <BaseIcon isExpanded={isExpanded || isExpand} size={14}>
            <ArrowRightIco color={iconColor} />
          </BaseIcon>
        </div>
      </BaseHeader>
      <BaseBody className={isExpanded || isExpand ? 'anim-card--expand' : ''}>
        {children[1]}
      </BaseBody>
    </BaseItem>
  );
};

ExpandItem.propTypes = {
  bgHeaderColor: PropTypes.string,
  position: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

ExpandItem.defaultProps = {
  bgHeaderColor: '',
};

export default ExpandItem;
