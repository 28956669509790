import React from 'react';

import { Switch, Route, Redirect } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import DatosCuenta from '../DatosCuenta';
import Contratacion from '../Contratacion';
import Comprobante from '../Comprobante';

const StepperRouter = (props) => {
  const { url, path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${url}/datos-cuenta`} render={() => <DatosCuenta {...props} />} />
      <Route exact path={`${url}/contratacion`} render={() => <Contratacion {...props} />} />
      <Route exact path={`${url}/comprobante`} render={() => <Comprobante {...props} />} />
      <Redirect path={`${path}/`} to={`${path}/datos-cuenta`} />
    </Switch>
  );
};

export default StepperRouter;
