import styled from 'styled-components';

const Container = styled.div`
  .column {
    flex-direction: column;
  }
  .margin-bottom {
    margin-bottom: 106px;
  }
  .text {
    max-width: 470px;
    line-height: 32px !important;
  }
  .line {
    border-top: 1px solid #e2e1e3;
    /* padding-bottom: 32px; */
  }
  .button {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .under-line {
    position: relative;
  }
  .under-line:after {
    position: absolute;
    bottom: 25px;
    /* left: 0; */
    /* right: 0; */
    width: 100%;
    height: 1px;
    content: '';
    background: #f00;
    max-width: 284px;
  }
  .factor-button-style {
    width: 296px;
    @media screen and (max-width: 340px) {
      width: 258px;
    }
  }
  /* .align-right-desktop{

  }
  .align-left-desktop{} */
`;

export const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    .factor-button-style {
      width: 100% !important;
      height: 100% !important;
      padding: 12px 16px !important;
    }
  }
`;

export default Container;
