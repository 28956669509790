import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { cuotaParticipacionActions } from '../../../application/actions/cuotaParticipacion';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';
import { downloadPDFBase64OtherTab, downloadPDFBase64 } from '../../../ui/utils/functions';

const apiCuotas = api(process.env.REACT_APP_CUOTA_PARTICIPACION);

export function* fetchFactoresCuotaParticipacion() {
  try {
    yield put(loadersActions.fetchLoadingFactoresCuotaParticipacion({ isLoading: true }));
    const { data, error } = yield call(apiCuotas.get, 'factores/v1/obtener');
    if (error) {
      yield put(cuotaParticipacionActions.fetchFactoresCuotaParticipacionError({ error }));
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchFactoresCuotaParticipacionSuccess({ data }));
    }
    yield put(loadersActions.fetchLoadingFactoresCuotaParticipacion({ isLoading: false }));
  } catch (error) {
    yield put(cuotaParticipacionActions.fetchFactoresCuotaParticipacionError({ error }));
    yield put(loadersActions.fetchLoadingFactoresCuotaParticipacion({ isLoading: false }));
  }
}
export function* fetchModificacionCuotasParticipacion() {
  try {
    const { data, error } = yield call(apiCuotas.get, 'cuota/modificacion/v1/validar');
    if (error) {
      yield put(cuotaParticipacionActions.fetchModificarCuotaParticipacionError());
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchModificarCuotasParticipacionSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchModifyCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchModificarCuotaParticipacionError());
  }
}

export function* fetchBannerCuotasParticipacion() {
  try {
    const { data, error } = yield call(apiCuotas.get, 'banner/modificacion/cuota/v1/validar');
    if (error) {
      yield put(cuotaParticipacionActions.fetchBannerCuotaParticipacionError());
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchBannerCuotasParticipacionSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchBannerCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchBannerCuotaParticipacionError());
  }
}
export function* fetchSugerenciaCuotasParticipacion() {
  try {
    yield put(loadersActions.fetchLoadingSugerenciaCuotaParticipacion({ isLoading: true }));
    const { data, error } = yield call(apiCuotas.get, 'cuota/sugerencias/v1/obtener');
    if (error) {
      yield put(cuotaParticipacionActions.fetchSugerenciaCuotaParticipacionError());
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchSugerenciaCuotasParticipacionSuccess({ data }));
      yield put(loadersActions.fetchLoadingSugerenciaCuotaParticipacion({ isLoading: false }));
    }
  } catch (err) {
    console.error(err.message, 'fetchSugerenciaCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchSugerenciaCuotaParticipacionError());
    yield put(loadersActions.fetchLoadingSugerenciaCuotaParticipacion({ isLoading: false }));
  }
}

export function* fetchActualizacionCuotasParticipacion(action) {
  try {
    const { cuotasPactadas, clave, aceptoTerminos } = action.payload.payload.params;
    yield put(loadersActions.fetchLoadingActualizacionCuotaParticipacion({ isLoading: true }));
    const { data, error } = yield call(
      apiCuotas.put, 'cuota/v1/actualizar',
      {
        'cuotasPactadas': cuotasPactadas,
        'clave': clave,
        'aceptoTerminos': aceptoTerminos
      });
    if (error) {
      yield put(cuotaParticipacionActions.fetchActualizacionCuotaParticipacionError(error));
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchActualizacionCuotasParticipacionSuccess({ data }));
      yield put(loadersActions.fetchLoadingActualizacionCuotaParticipacion({ isLoading: false }));
    }
  } catch (err) {
    console.error(err.message, 'fetchActualizacionCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchActualizacionCuotaParticipacionError(err));
    yield put(loadersActions.fetchLoadingActualizacionCuotaParticipacion({ isLoading: false }));
  }
}


export function* fetchActualizaStatusErrorCuotaParticipacion() {
  yield put(cuotaParticipacionActions.fetchActualizaErrorCuotaParticipacion());
}

export function* fetchBorradorDescargarCuotasParticipacion(action) {
  const { cuotasPactadasPorMes } = action.payload;
  try {
    yield put(loadersActions.fetchLoadingBorradorDescargarCuotaParticipacion({ isLoading: true }));
    const { data, error } = yield call(apiCuotas.post, 'cuota/aporte/borrador/v1/descargar', {
      nombreDocumento: 'SOLICITUD_MODIFICACION_CP',
      atributos: [
        {
          atributo: "CUOTAS NUEVAS",
          valor: cuotasPactadasPorMes.toString(),
        }
      ]
    });
    if (error) {
      yield put(cuotaParticipacionActions.fetchBorradorDescargarCuotaParticipacionError());
    }
    if (data) {
      downloadPDFBase64(data, 'Solicitud de modificacion de cuotas');
    }
  } catch (err) {
    console.error(err.message, 'fetchBorradorDescargarCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchBorradorDescargarCuotaParticipacionError());
    yield put(loadersActions.fetchLoadingBorradorDescargarCuotaParticipacion({ isLoading: false }));
  }
}

export function* fetchDescargarComprobanteModificacionCuotasParticipacion() {
  try {
    yield put(loadersActions.fetchLoadingDescargarComprobanteModificacionCuotaParticipacion({ isLoading: true }));
    const optionalHeaders = {
      track: localStorage.getItem('token'),
    };
    const { data, error } = yield call(apiCuotas.get, 'cuota/modificacion/comprobante/v1/descargar', null, optionalHeaders);
    if (error) {
      yield put(cuotaParticipacionActions.fetchDescargarComprobanteModificacionCuotasParticipacionError(error));
    }
    if (data) {
      downloadPDFBase64(data, 'Solicitud de modificacion de cuotas');
    }
  } catch (err) {
    console.error(err.message, 'fetchDescargarComprobanteModificacionCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchDescargarComprobanteModificacionCuotasParticipacionError(err));
    yield put(loadersActions.fetchLoadingDescargarComprobanteModificacionCuotaParticipacion({ isLoading: false }));
  }
}
/// ///////////////////////////////////////

export function* fetchCuotasParticipacion() {
  try {
    yield put(loadersActions.fetchLoadingCuentaParticipacion({ isLoading: true }));

    const { data, error } = yield call(apiCuotas.get, 'cuota/v1/obtener');
    if (error) {
      yield put(cuotaParticipacionActions.fetchCuotasParticipacionError());
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchCuotasParticipacionSuccess({ data }));
    }

    yield put(loadersActions.fetchLoadingCuentaParticipacion({ isLoading: false }));
  } catch (err) {
    console.error(err.message, 'fetchCuotasParticipacion');
    yield put(loadersActions.fetchLoadingCuentaParticipacion({ isLoading: false }));
    yield put(cuotaParticipacionActions.fetchCuotasParticipacionError());
  }
}

export function* pagarCuotasParticipacion(action) {
  const { clave, cuotas, numeroCuenta } = action.payload;
  try {
    const { data, error } = yield call(apiCuotas.post, 'cuota/v1/pagar', {
      clave,
      cuotas,
      numeroCuenta,
    });
    if (error) {
      yield put(cuotaParticipacionActions.pagarCuotasParticipacionError({ error: error.code }));
    }
    if (data) {
      yield put(cuotaParticipacionActions.pagarCuotasParticipacionSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'pagarCuotasParticipacion');
    yield put(cuotaParticipacionActions.pagarCuotasParticipacionError({ error: '' }));
  }
}

export function* fetchDetallePagoCDP(action) {
  const { nroTransaccion } = action.payload;
  try {
    const { data, error } = yield call(
      apiCuotas.get,
      `cuota/pago/detalle/v1/obtener/${nroTransaccion}`
    );
    if (error) {
      yield put(cuotaParticipacionActions.fetchDetallePagoCDPError());
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchDetallePagoCDPSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchCuotasParticipacion');
    yield put(cuotaParticipacionActions.fetchDetallePagoCDPError());
  }
}

export function* fetchDataCuotasPagadas(action) {
  const { yearSelected } = action.payload;
  try {
    const { data, error } = yield call(apiCuotas.post, 'cuota/pagada/v1/obtener ', {
      fechaInicio: yearSelected,
      fechaTermino: yearSelected,
    });
    if (error) {
      yield put(cuotaParticipacionActions.fetchDataCuotasPagadasError({ error: error.code }));
    }
    if (data) {
      yield put(cuotaParticipacionActions.fetchDataCuotasPagadasSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchDataCuotasPagadas');
    yield put(cuotaParticipacionActions.fetchDataCuotasPagadasError({ error: '' }));
  }
}

export function* fetchDownloadPDF(action) {
  const { yearSelected } = action.payload;
  try {
    const { data } = yield call(apiCuotas.post, 'cuota/pagada/pdf/v1/obtener ', {
      fechaInicio: yearSelected,
      fechaTermino: yearSelected,
    });
    if (data) {
      downloadPDFBase64OtherTab(data);
    }
  } catch (err) {
    console.error(err.message, 'fetchDownloadPDF');
  }
}

export function* watchfetchFactoresCuotaParticipacion() {
  yield takeLatest(
    cuotaParticipacionActions.fetchFactoresCuotaParticipacion.type,
    fetchFactoresCuotaParticipacion
  );
}
export function* watchfetchCuotasParticipacion() {
  yield takeLatest(
    cuotaParticipacionActions.fetchCuotasParticipacion.type,
    fetchCuotasParticipacion
  );
}

export function* watchPagarCuotasParticipacion() {
  yield takeLatest(
    cuotaParticipacionActions.pagarCuotasParticipacion.type,
    pagarCuotasParticipacion
  );
}

export function* watchFetchDetallePagoCDP() {
  yield takeLatest(cuotaParticipacionActions.fetchDetallePagoCDP.type, fetchDetallePagoCDP);
}

export function* watchFetchDataCuotasPagadas() {
  yield takeLatest(cuotaParticipacionActions.fetchDataCuotasPagadas.type, fetchDataCuotasPagadas);
}

export function* watchFetchDescargarComprobanteModificacionCuotasParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchDescargarComprobanteModificacionCuotasParticipacion.type, fetchDescargarComprobanteModificacionCuotasParticipacion);
}

export function* watchFetchDownloadPDF() {
  yield takeLatest(cuotaParticipacionActions.fetchDownloadPDF.type, fetchDownloadPDF);
}
export function* watchFetchModificacionCuotasParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchModificacionCuotasParticipacion.type, fetchModificacionCuotasParticipacion);
}
export function* watchFetchSugerenciaCuotasParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchSugerenciaCuotasParticipacion.type, fetchSugerenciaCuotasParticipacion);
}
export function* watchFetchBorradorDescargarCuotasParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchBorradorDescargarCuotasParticipacion.type, fetchBorradorDescargarCuotasParticipacion);
}
export function* watchFetchActualizacionCuotasParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchActualizacionCuotasParticipacion.type, fetchActualizacionCuotasParticipacion);
}
export function* watchFetchActualizaStatusErrorCuotaParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchActualizaStatusErrorCuotaParticipacion.type, fetchActualizaStatusErrorCuotaParticipacion);
}
export function* watchFetchBannerCuotasParticipacion() {
  yield takeLatest(cuotaParticipacionActions.fetchBannerCuotasParticipacion.type, fetchBannerCuotasParticipacion);
}



export default function* rootSaga() {
  yield spawn(watchfetchFactoresCuotaParticipacion);
  yield spawn(watchfetchCuotasParticipacion);
  yield spawn(watchPagarCuotasParticipacion);
  yield spawn(watchFetchDetallePagoCDP);
  yield spawn(watchFetchDataCuotasPagadas);
  yield spawn(watchFetchDownloadPDF);
  yield spawn(watchFetchModificacionCuotasParticipacion);
  yield spawn(watchFetchSugerenciaCuotasParticipacion);
  yield spawn(watchFetchBorradorDescargarCuotasParticipacion);
  yield spawn(watchFetchDescargarComprobanteModificacionCuotasParticipacion);
  yield spawn(watchFetchActualizacionCuotasParticipacion);
  yield spawn(watchFetchActualizaStatusErrorCuotaParticipacion);
  yield spawn(watchFetchBannerCuotasParticipacion);
}
