import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CapitalizarTemplate from '../../../../components/templates/Remanente/Capitalizar';
import { cuotaParticipacionActions } from '../../../../../application/actions/cuotaParticipacion';
import { capitalizacionActions } from '../../../../../application/actions/capitalizacion';
import { personaActions } from '../../../../../application/actions/persona';
import { downloadPDFBase64 } from '../../../../utils/functions';
import { isValidEmail, agregarformatoPesos } from '../../../../utils/formatters';
import { registerGA } from '../../../../utils/metrics';
import logger from '../../../../utils/logger';
import { produce } from 'immer';

const initState = {
  steps: ['Características', 'Monto a Capitalizar', 'Comprobante'],
  currentStep: 1,
  cantidadCuotas: '',
  errorCuentasDisponibles: false,
  accountsAvailable: [],
  selectedAccount: null,
  checked: false,
  loadingCapitalizar: false,
  generalError: false,
  maximoCuotas: null,
  secondEmail: '',
  secondEmailError: '',
  showEnviarComprobanteModal: false,
  showComprobanteEnviadoModal: false,
  showEnviarComprobanteErrorModal: false,

  // Logs
  logEnviadoStep1: false,
  logEnviadoStep2: false,
  logEnviadoStep3: false,
};
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }
  componentDidMount = () => {
    const {
      fetchFactoresCuotaParticipacion,
      fetchProducts,
      fetchTarjetas,
      fetchDatosPersonales,
    } = this.props;
    fetchFactoresCuotaParticipacion();
    fetchProducts({
      codigoProducto: ['VI01', 'VI02', 'VI03', 'VI06'],
      estadoCuenta: ['A', 'I', 'D'],
    });
    fetchTarjetas({});
    fetchDatosPersonales();
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/capitalizar/paso1A',
    });
  };
  componentDidUpdate = (prevProps, prevState) => {
    const {
      productosDisponibles,
      remanentePagado,
      persona,
      cuotaParticipacion,
      capitalizarResponse,
      detalle,
      comprobanteCapitalizacion,
      emailComprobante,
    } = this.props;
    const { generalError } = this.state;
    if (prevState.generalError !== generalError && !!generalError) {
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/remanente/capitalizar/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    }
    if (prevProps.productosDisponibles !== productosDisponibles) {
      if (!!productosDisponibles.cuentas && !!productosDisponibles.tarjetas) {
        this.handleSetProductosDisponibles(productosDisponibles);
      } else if (productosDisponibles.error) {
        this.setState({
          errorCuentasDisponibles: true,
        });
      }
    } else if (
      comprobanteCapitalizacion !== prevProps.comprobanteCapitalizacion &&
      comprobanteCapitalizacion !== null
    ) {
      this.downloadComprobanteCapitalizacion();
    } else if (
      (productosDisponibles !== prevProps.productosDisponibles && productosDisponibles.error) ||
      (remanentePagado !== prevProps.remanentePagado && remanentePagado.error) ||
      (persona !== prevProps.persona && persona.errorDatosPersonales) ||
      (cuotaParticipacion !== prevProps.cuotaParticipacion && cuotaParticipacion.errorFactores)
    ) {
      this.setState({
        generalError: true,
      });
    } else if (
      (cuotaParticipacion !== prevProps.cuotaParticipacion ||
        remanentePagado !== prevProps.remanentePagado) &&
      !!cuotaParticipacion &&
      !!remanentePagado &&
      !!remanentePagado.pago
    ) {
      const maximoCuotas = Math.trunc(
        remanentePagado.pago.monto / cuotaParticipacion.factores.valorCuota
      );
      this.setState({
        maximoCuotas,
      });
    } else if (capitalizarResponse !== prevProps.capitalizarResponse) {
      if (capitalizarResponse.error) {
        this.setState({
          generalError: true,
        });
      } else {
        this.getDetalleCapitalizacion();
      }
    } else if (detalle !== prevProps.detalle) {
      if (detalle.error) {
        this.setState({ generalError: true });
      } else {
        registerGA({
          event: 'vpv-privado',
          page: '/tef/remanente/capitalizar/comprobanteA',
          monto: agregarformatoPesos(detalle.remanentePagado),
          producto:
            detalle.codigoProducto === 'VI06' ? 'Monedero Digital' : 'Cuenta Vista Coopeuch',
        });
        this.toNextStep();
      }
    } else if (emailComprobante !== prevProps.emailComprobante) {
      if (emailComprobante.error) {
        this.setState({
          showEnviarComprobanteModal: false,
          showComprobanteEnviadoModal: false,
          showEnviarComprobanteErrorModal: true,
        });
      } else if (emailComprobante.enviado) {
        this.setState({
          showEnviarComprobanteModal: false,
          showComprobanteEnviadoModal: true,
          showEnviarComprobanteErrorModal: false,
        });
      }
    }

    // -- Logs --
    const { currentStep, logEnviadoStep1, logEnviadoStep2, logEnviadoStep3, selectedAccount } =
      this.state;
    const {
      cuentasErrLog,
      tarjetasErrLog,
      isLoadingObtenerTarjetas,
      isLoadingObtenerCuentas,
      isLoadingDatosPersonales,
      isLoadingFactoresCuotaParticipacion,
      isLoadingCapitalizarRemanente,
      isLoadingDetalleCapitalizacion,
    } = this.props;

    const baseRut = String((persona?.rut || '').replace('-', ''));

    // -- START LOGS STEP 1 -- //
    if (
      prevProps.isLoadingObtenerTarjetas !== isLoadingObtenerTarjetas ||
      prevProps.isLoadingObtenerCuentas !== isLoadingObtenerCuentas ||
      prevProps.isLoadingDatosPersonales !== isLoadingDatosPersonales ||
      prevProps.isLoadingFactoresCuotaParticipacion !== isLoadingFactoresCuotaParticipacion ||
      cuentasErrLog ||
      tarjetasErrLog ||
      persona.errorLogPersona ||
      cuotaParticipacion.errorFactoresLogs
    ) {
      if (
        !isLoadingObtenerTarjetas &&
        !isLoadingObtenerCuentas &&
        !isLoadingDatosPersonales &&
        !isLoadingFactoresCuotaParticipacion
      ) {
        const errors =
          cuentasErrLog || tarjetasErrLog || persona.errorLogPersona || cuotaParticipacion.errorFactoresLogs;

        if (!logEnviadoStep1 && currentStep === 1) {
          logger(errors, {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: 'CAPITALIZACIÓN-INFORMACION CTA',
            informacion_adicional: {},
          });

          this.setState(
            produce((draft) => {
              draft.logEnviadoStep1 = true;
            })
          );
        }
      }
    }
    // -- END LOGS STEP 1 -- //

    // -- START LOGS STEP 2 -- //
    if (!logEnviadoStep2 && currentStep === 2) {
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CAPITALIZACIÓN-MONTO Y CUOTAS',
        informacion_adicional: {},
      });

      this.setState(
        produce((draft) => {
          draft.logEnviadoStep2 = true;
        })
      );
    }
    // -- END LOGS STEP 2 -- //

    // -- START LOGS STEP 3 -- //
    if (
      prevProps.isLoadingCapitalizarRemanente !== isLoadingCapitalizarRemanente ||
      prevProps.isLoadingDetalleCapitalizacion !== isLoadingDetalleCapitalizacion ||
      capitalizarResponse?.errorLog ||
      detalle.errorLog
    ) {
      if (!isLoadingCapitalizarRemanente && !isLoadingDetalleCapitalizacion) {
        if (!logEnviadoStep3 && currentStep === 2 || currentStep === 3) {
          logger(capitalizarResponse?.errorLog || detalle.errorLog, {
            rut: baseRut.slice(0, -1),
            digito_verificador: baseRut.slice(-1),
            accion: 'CAPITALIZACIÓN-COMPROBANTE',
            informacion_adicional: {
              monto: detalle?.montoCapitalizado,
            },
          });

          this.setState(
            produce((draft) => {
              draft.logEnviadoStep3 = true;
            })
          );
        }
      }
    }

    // -- END LOGS STEP 3 -- //
  };

  getDetalleCapitalizacion = () => {
    const { fetchDetalleCapitalizacion } = this.props;
    fetchDetalleCapitalizacion();
  };

  toNextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };
  toHome = () => {
    this.props.history.push('/ecd/inicio');
  };
  toRemanente = () => {
    this.props.onGoBackToRemanente();
  };
  handleSetProductosDisponibles = (productos) => {
    const cvActivas = productos.cuentas.filter(
      (cuenta) =>
        ['VI01', 'VI02', 'VI03'].includes(cuenta.codigoProducto) && cuenta.estadoCuenta === 'A'
    );
    const cvInactivasHabilitadas = productos.cuentas.filter(
      (cuenta) =>
        ['VI01', 'VI02', 'VI03'].includes(cuenta.codigoProducto) &&
        (cuenta.estadoCuenta === 'I' || cuenta.estadoCuenta === 'D') &&
        this.checkActiveCard(cuenta.numeroCuenta, productos.tarjetas)
    );
    const monedero = productos.cuentas.filter((cuenta) => cuenta.codigoProducto === 'VI06');
    const cvHabilitadas = [...cvActivas, ...cvInactivasHabilitadas, ...monedero].sort((a, b) =>
      new Date(a.fechaApertura) < new Date(b.fechaApertura) ? 1 : -1
    );
    if (cvHabilitadas.length) {
      const options = cvHabilitadas.map((cv) => ({
        label: `${cv.codigoProducto === 'VI06' ? 'Monedero Digital: N° ' : 'Cuenta Vista: N° '} ${
          cv.numeroCuenta
        }`,
        value: cv.numeroCuenta,
        saldo: cv.saldoDisponible,
        codigoProducto: cv.codigoProducto,
      }));
      this.setState({
        accountsAvailable: options,
        selectedAccount: options[0],
      });
    } else {
      this.setState({
        errorCuentasDisponibles: true,
      });
    }
  };
  checkActiveCard = (accountId, cards) => {
    const accountCards = cards.filter(
      (card) =>
        card.numeroCuenta === accountId &&
        card.codEstado === '01' &&
        ['', 'O', 'S', 'N'].includes(card.bloqueo)
    );
    return accountCards.length;
  };
  handleChangeSelect = (e) => {
    const { accountsAvailable } = this.state;
    const selectedAccount = accountsAvailable.filter(
      (account) => account.value === parseInt(e.target.value, 10)
    )[0];
    this.setState({
      selectedAccount,
    });
  };
  handleChangeInput = (e) => {
    const { value, name } = e.target;
    this.setState({
      [name]: value.replace(/[^z0-9\s]/g, ''),
    });
  };
  onHandleCheckBox = () => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  };
  onHandleChangeEmail = (e) => {
    const value = e.target.value;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      secondEmail: value,
      secondEmailError: isValidEmail(value) ? '' : errorMessage,
    });
  };

  downloadComprobanteCapitalizacion = () => {
    const { fetchComprobanteCapitalizacion, comprobanteCapitalizacion } = this.props;
    if (!!comprobanteCapitalizacion) {
      downloadPDFBase64(comprobanteCapitalizacion, 'Comprobante Capitalizacion');
    } else {
      fetchComprobanteCapitalizacion();
    }
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/comprobanteA',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
  };

  backStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso1A',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.toRemanente();
  };

  continueStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso1A',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
    this.toNextStep();
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/capitalizar/paso2A',
    });
  };

  backStep2 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/paso2A',
      'eve-cat': 'Click',
      'eve-lab': 'no por ahora',
    });
    this.toRemanente();
  };

  continueStep2 = () => {
    const { capitalizarRemanente } = this.props;
    const { cantidadCuotas, selectedAccount } = this.state;
    this.setState(
      {
        loadingCapitalizar: true,
      },
      () => {
        capitalizarRemanente({
          cantidadCuotas,
          numeroCuenta: selectedAccount.value,
        });
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/capitalizar/paso2A',
          'eve-cat': 'Click',
          'eve-lab': 'Continuar',
        });
      }
    );
  };
  continueStep3 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/capitalizar/comprobanteA',
      'eve-cat': 'Click',
      'eve-lab': 'ir a Remanente',
    });
    this.toRemanente();
  };
  handleSendComprobanteEmail = () => {
    const { enviarComprobanteEmail } = this.props;
    const correo = this.state.secondEmail;
    enviarComprobanteEmail({ correo });
  };
  closeAllModals = () => {
    this.setState({
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: false,
      showEnviarComprobanteErrorModal: false,
    });
  };
  openSendEmailModal = () => {
    this.setState(
      {
        secondEmail: '',
        secondEmailError: '',
        showEnviarComprobanteModal: true,
      },
      () => {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/remanente/capitalizar/comprobanteA',
          'eve-cat': 'Click',
          'eve-lab': 'Enviar',
        });
      }
    );
  };
  render() {
    const {
      steps,
      currentStep,
      errorCuentasDisponibles,
      accountsAvailable,
      selectedAccount,
      cantidadCuotas,
      checked,
      loadingCapitalizar,
      generalError,
      maximoCuotas,
      secondEmail,
      secondEmailError,
      showEnviarComprobanteModal,
      showComprobanteEnviadoModal,
      showEnviarComprobanteErrorModal,
    } = this.state;
    const {
      factores: { valorCuota },
      errorFactores,
    } = this.props.cuotaParticipacion;
    const { remanentePagado, persona, detalle } = this.props;
    return (
      <CapitalizarTemplate
        {...{
          steps,
          currentStep,
          errorCuentasDisponibles,
          accountsAvailable,
          selectedAccount,
          valorCuota,
          maximoCuotas,
          errorFactores,
          remanentePagado,
          correoPersona: persona.correo,
          cantidadCuotas,
          checked,
          loadingCapitalizar,
          generalError,
          detalle,
          secondEmail,
          secondEmailError,
          showEnviarComprobanteModal,
          showComprobanteEnviadoModal,
          showEnviarComprobanteErrorModal,
        }}
        handleChangeSelect={this.handleChangeSelect}
        handleChangeInput={this.handleChangeInput}
        handleChangeEmail={this.onHandleChangeEmail}
        handleSendComprobanteEmail={this.handleSendComprobanteEmail}
        openSendEmailModal={this.openSendEmailModal}
        onHandleCheckBox={this.onHandleCheckBox}
        onClickDownloadComprobante={this.downloadComprobanteCapitalizacion}
        backStep1={this.backStep1}
        continueStep1={this.continueStep1}
        backStep2={this.backStep2}
        continueStep2={this.continueStep2}
        backStep3={this.toHome}
        continueStep3={this.continueStep3}
        closeModal={this.closeAllModals}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  cuotaParticipacion: store.entities.cuotaParticipacion,
  productosDisponibles: store.entities.capitalizacion.productosDisponibles,
  remanentePagado: store.entities.remanente.saldoIndividual,
  persona: store.entities.persona,
  capitalizarResponse: store.entities.capitalizacion.capitalizar,
  detalle: store.entities.capitalizacion.detalleCapitalizacion,
  comprobanteCapitalizacion: store.entities.capitalizacion.comprobanteCapitalizacion,
  emailComprobante: store.entities.capitalizacion.emailComprobante,
  cuentasErrLog: store.entities.capitalizacion.cuentasErrLog,
  tarjetasErrLog: store.entities.capitalizacion.tarjetasErrLog,
  isLoadingObtenerTarjetas: store.ui.loaders.isLoadingObtenerTarjetasRemanente,
  isLoadingObtenerCuentas: store.ui.loaders.isLoadingObtenerCuentasRemanente,
  isLoadingDatosPersonales: store.ui.loaders.isLoadingDatosPersonales,
  isLoadingFactoresCuotaParticipacion: store.ui.loaders.isLoadingFactoresCuotaParticipacion,
  isLoadingCapitalizarRemanente: store.ui.loaders.isLoadingCapitalizarRemanente,
  isLoadingDetalleCapitalizacion: store.ui.loaders.isLoadingDetalleCapitalizacion,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFactoresCuotaParticipacion() {
    dispatch(cuotaParticipacionActions.fetchFactoresCuotaParticipacion());
  },
  fetchProducts(props) {
    dispatch(capitalizacionActions.fetchProductosDisponiblesCapitalizacion(props));
  },
  fetchTarjetas(props) {
    dispatch(capitalizacionActions.fetchTarjetasProductosDisponiblesCapitalizacion(props));
  },
  fetchDatosPersonales() {
    dispatch(personaActions.fetchDatosPersonales());
  },
  capitalizarRemanente(props) {
    dispatch(capitalizacionActions.capitalizarRemanente(props));
  },
  fetchDetalleCapitalizacion() {
    dispatch(capitalizacionActions.fetchDetalleCapitalizacion());
  },
  fetchComprobanteCapitalizacion() {
    dispatch(capitalizacionActions.fetchComprobanteCapitalizacion());
  },
  enviarComprobanteEmail(props) {
    dispatch(capitalizacionActions.enviarComprobanteEmail(props));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
