import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 110px;
  .encuestaBox {
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    @media (max-width: 991px) {
      z-index: 1;
    }
    &__title {
      font-size: 17px;
      font-family: 'Ubuntu-Regular';
    }
    &__description {
      margin-top: 7px;
      &-text {
        font-size: 14px;
        font-family: 'Ubuntu-Bold';
        &-break {
          display: none;
          @media (max-width: 991px) {
            display: block;
          }
        }
        &-link {
          text-decoration: underline;
          color: #f42534;
          font-size: 14px;
          font-family: 'Ubuntu-Bold';
          margin-left: 5px;
          @media (max-width: 991px) {
            display: block;
            margin-left: 0px;
          }
        }
      }
    }
  }
  .imageBannerCV {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 991px) {
      position: absolute;
      bottom: 0;
    }
  }
`;
export default Container;
