import styled, { keyframes } from 'styled-components';

export const BaseIconStep = styled.div`
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  height: 20px;
  width: 20px;
  background-color: ${({ activeColor, isAtived }) => (isAtived ? activeColor : '#fff')};
  border: 2px solid ${({ activeColor, isAtived }) => (isAtived ? activeColor : '#d8d8d8')};

  &::after {
    background-color: #fff;
    border-radius: inherit;
    position: absolute;
    content: '';
    margin: auto;
    height: 6px;
    bottom: 0;
    width: 6px;
    right: 0;
    left: 0;
    top: 0;
  }

  @media screen and (max-width: 576px) {
    margin-right: 0;
    height: 14px;
    width: 14px;

    &::after {
      height: 4px;
      width: 4px;
    }
  }
`;

const progressBgTransition = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const progressInitBgTransition = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 4%;
  }
`;

export const BaseLoader = styled.div`
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  overflow: hidden;

  & .anim-progress::after {
    background-color: ${({ activeColor }) => activeColor};
    animation: 860ms ${progressBgTransition} cubic-bezier(0.25, 1, 0.5, 1) forwards;
  }

  .init-progress::after {
    background-color: ${({ activeColor }) => activeColor};
    width: 4%;
    animation: 860ms ${progressInitBgTransition} cubic-bezier(0.25, 1, 0.5, 1) forwards;
  }
`;

export const BaseProgress = styled.div`
  background-color: #d8d8d8;
  flex-grow: 1;
  position: relative;
  height: ${({ barHeight }) => `${barHeight}px`};

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: inherit;
    top: 0;
  }
`;

export const BaseLastStep = styled.div`
  height: 12px;
  width: 3px;
  border-radius: 1.5px;
  background-color: ${({ activeColor, isActive }) => (isActive ? activeColor : '#d8d8d8')};
`;

export const BaseMoblieProgress = styled(BaseProgress)`
  &::after {
    background-color: ${({ activeColor }) => activeColor};
    width: ${({ position, step }) => `${(100 * position) / step}%`};
    margin-left: ${({ progressOutContext }) => progressOutContext && '-50%'};
  }
`;

export const BaseMobile = styled.div`
  @media screen and (min-width: 577px) {
    display: none;
  }
  .step-title-container-responsive {
    margin-left: 6px;
    color: #f42534;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: bold;
  }
  @media screen and (max-width: 768px) {
    .step-title-container-responsive {
      margin-left: 0px;
    }
  }
`;

export const BaseContentMobile = styled.div`
  display: flex;
  justify-content: space-between;
  .step-mobile-count {
    color: #646569;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

const BaseDesktop = styled.div`
  justify-content: space-between;
  display: flex;

  & > div {
    padding-left: 8px;
    flex: 1;
  }
  & > div:first-of-type {
    padding-left: 0px !important;
  }

  @media screen and (max-width: 576px) {
    display: none;
  }
  .step-title-container-responsive {
    margin-left: 6px;
    color: #f42534;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    font-weight: bold;
  }
  .step-title-container-responsive-inactive {
    color: #939496;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  @media screen and (max-width: 768px) {
    .step-title-container-responsive {
      margin-left: 0px;
    }
  }
`;

export default BaseDesktop;
