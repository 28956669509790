import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { empleadorActions } from '../../../application/actions/empleador';

// api
import api from '../../api';

// const apiEmpleador = api(process.env.REACT_APP_PERSONA);
const apiRegion = api(process.env.REACT_APP_REGION);
const apiTipo = api(process.env.REACT_APP_TIPO);

export function* fetchEmpleadorObtener() {
  try {
        // const { data, error } = yield call(apiEmpleador.post, 'datospersonales/v1/obtener', {});

    const data = {
      region: '01',
      comuna: '0301',
      calle: 'Av. Nueva Providencia',
      numero: '100',
      otros: '45',
    };
    // const error = 'error de prueba'

    if (data) {
      yield put(empleadorActions.fetchEmpleadorObtenerSuccess({ data }));
    } else {
      yield put(empleadorActions.fetchEmpleadorObtenerError());
    }
  } catch (error) {
    yield put(empleadorActions.fetchEmpleadorObtenerError());
  }
}

export function* fetchRegionObtener() {
  try {
    const { data } = yield call(apiRegion.get, 'v1/obtener');
    if (data) {
      yield put(empleadorActions.fetchRegionObtenerSuccess({ data }));
    } else {
      yield put(empleadorActions.fetchRegionObtenerError());
    }
  } catch (error) {
    yield put(empleadorActions.fetchRegionObtenerError());
  }
}

export function* fetchComunas(action) {
  const codigoComuna = action.payload;
  // const addZero = 0;
  // console.log(codigoComuna);
  try {
    const { data, error } = yield call(apiTipo.get, `comuna/v1/obtener/${codigoComuna}`);
    if (error) {
      yield put(empleadorActions.fetchComunasError());
    }
    if (data) {
      yield put(empleadorActions.fetchComunasSuccess({ data }));
    }
  } catch (err) {
    console.error(err.message, 'fetchComunas');
    yield put(empleadorActions.fetchComunasError());
  }
}

export function* watchFetchEmpleadorObtener() {
  yield takeLatest(empleadorActions.fetchEmpleadorObtener.type, fetchEmpleadorObtener);
}

export function* watchFetchRegionObtener() {
  yield takeLatest(empleadorActions.fetchRegionObtener.type, fetchRegionObtener);
}

export function* watchFetchComunas() {
  yield takeLatest(empleadorActions.fetchComunas.type, fetchComunas);
}

export default function* rootSaga() {
  yield spawn(watchFetchEmpleadorObtener);
  yield spawn(watchFetchRegionObtener);
  yield spawn(watchFetchComunas);
}
