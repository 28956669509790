import styled from 'styled-components';

export default styled.div`
  .texts {
    letter-spacing: 0;
    line-height: 24px;
  }

  .modulo-content {
    padding: 24px 32px;
  }

  .modulo-second-text {
    padding-top: 24px;
  }

  .second-module-pdf {
    margin-top: 32px;
  }

  .alert-content {
    padding: 12px 16px !important;
  }

  @media screen and (max-width: 766px) {
    padding: 12px !important;
    padding: 12px !important;

    .modulo-content {
      padding: 16px;
    }

    .alert-content {
      padding: 12px 8px !important;
    }

    .modulo-second-text {
      padding-top: 16px;
    }

    .second-module-pdf {
      margin-top: 24px;
    }
  }
`;

export const MainCertificate = styled.div`
  height: 109px;
  padding: 16px;
  background-color: #d9f1fb;
  border: 1px solid #e7e7e7;
  border-radius: 5px;

  @media screen and (max-width: 766px) {
    height: 60px;
    padding: 0px;
  }
`;

export const CertificateItem = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 35px 1fr 120px;
  align-items: center;

  .download-btn {
    display: flex;
  }

  @media screen and (max-width: 766px) {
    grid-template-columns: 32px 1fr 40px;
    gap: 2px;
  }
`;
