export const defaultRequest = {
    id: 500,
    title: '¡Lo sentimos!',
    message:
      'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
    link: {},
  };
  
  export const wrongRequest = {
    id: 10,
    message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
    link: {},
  };
  
  export const errMessages = {
    default: defaultRequest,
    0: defaultRequest,
    9: wrongRequest,
    10: wrongRequest,
    12: {
      id: 12,
      message: 'Clave Bloqueada. Por seguridad hemos bloqueado tu clave.',
      link: {
        name: 'Recupera tu Clave',
        url: '/recuperar-clave',
      },
    },
    11: {
      id: 11,
      message: 'Actualiza tu clave. Te invitamos a crear una nueva clave',
      link: {
        name: 'Actualízala aquí',
        url: '/recuperar-clave',
      },
    },
    101: {
      id: 101,
      message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
      link: {},
    },
    102: {
      id: 102,
      message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
      link: {},
    },
    103: {
      id: 103,
      message: 'Los datos ingresados son incorrectos. Favor intente nuevamente',
      link: {},
    },
  };

 export const ERROR_CODES_WITH_BUTTON = '5203004';
export const ERROR_MESSAGES = {
  '11': 'Actualizar clave',
  '12': 'Clave Bloqueada',
  '10': 'Datos incorrectos',
  '9': 'Datos incorrectos',
  '101': 'Credenciales Incorrectas',
  '102': 'Credenciales Incorrectas',
  '103': 'Credenciales Incorrectas',
};