import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../atoms/PageTitle';
import Stepper from '../../../../components/organism/Stepper';
import StepsActions from '../../../../components/organism/Stepper/StepsActions';

import Wrapper from './styles';
import ConfirmacionPassword from '../../../organism/DepositoAPlazo/Liquidar/ConfirmacionPassword';
import ConfirmacionCuenta from '../../../organism/DepositoAPlazo/Liquidar/ConfirmacionCuenta';
import ConfirmacionEmail from '../../../organism/DepositoAPlazo/Liquidar/ConfirmacionEmail';
import ConfirmacionFactor from '../../../organism/DepositoAPlazo/Liquidar/ConfirmacionFactor';

import SkeletonLiquidacionConfirmar from './Skeleton';
import { hasValuesOnObject, isEmailValid } from '../../../../utils/validators';
import ServiceError from '../../../organism/DepositoAPlazo/ServiceError';
import { descripcionCuentas } from '../../../../utils/constants';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

function LiquidacionConfirmarTemplate(props) {
  const {
    error,
    habilitadoOtpSmsError,
    loading,
    cuenta,
    isMontoHigher,
    cuentasVista,
    email,
    password,
    showPassword,
    stepFactor,
    desafios,
    timer,
    telefono,
    handleChangeDesafios,
    handleChangeCuenta,
    handlePassword,
    handlePasswordVisibility,
    handleChangeEmail,
    handleBack,
    handleSubmit,
    clearSmsStates,
    clearPasswordStates,
  } = props;

  const steps = useMemo(() => ['Pago', 'Comprobante'], []);

  const emailError = useMemo(() => !isEmailValid(email) || email?.length === 0, [email]);

  const isButtonDisabled = useMemo(() => {
    return !cuenta || password?.length < 6 || emailError;
  }, [cuenta, password, emailError]);

  const passwordCodes = ['2', '3', '4'];
  const smsCodes = ['1', '2', '3'];

  const hasAnError = useMemo(() => {
    return (
      hasValuesOnObject(error) &&
      !(isMontoHigher ? smsCodes : passwordCodes)?.includes(String(error?.id))
    );
  }, [error, isMontoHigher]);

  const cuentasInputSelect = useMemo(
    () =>
      cuentasVista?.map(({ numeroCuenta, saldoDisponible, codigoProducto }) => ({
        label:
          codigoProducto === 'VI06'
            ? `Monedero Digital: N° ${numeroCuenta}`
            : `${descripcionCuentas[codigoProducto]}: N° ${numeroCuenta}`,
        value: String(numeroCuenta),
        saldoDisponible,
      })),
    [cuentasVista]
  );

  return (
    <Wrapper className="w-100">
      <PageTitle
        id="dap-title-page"
        title="Depósito a Plazo"
        icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" />}
      />

      <div className="w-100 margin-after-title content-dap">
        {(hasAnError && !isMontoHigher) || habilitadoOtpSmsError?.id === 500 ? (
          <ServiceError
            withCard
            onClick={handleSubmit}
            {...(habilitadoOtpSmsError?.id === 500 ? { noButton: true } : {})}
          />
        ) : (
          <Fragment>
            <Stepper className="start-xs" position={1} steps={steps} />

            {loading ? (
              <SkeletonLiquidacionConfirmar>
                <SkeletonLiquidacionConfirmar.Cuenta />
                <SkeletonLiquidacionConfirmar.Email />
                <SkeletonLiquidacionConfirmar.Password />
              </SkeletonLiquidacionConfirmar>
            ) : (
              <Fragment>
                <div className="row">
                  <div className="col-xs-12">
                    <ConfirmacionCuenta
                      selected={cuenta}
                      data={cuentasInputSelect}
                      handleChange={handleChangeCuenta}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <ConfirmacionEmail handleChange={handleChangeEmail} email={email} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    {isMontoHigher ? (
                      <ConfirmacionFactor
                        timer={timer}
                        stepFactor={stepFactor}
                        desafios={desafios}
                        emailError={emailError}
                        smsErrors={hasValuesOnObject(error) ? error : habilitadoOtpSmsError}
                        handleChangeDesafios={handleChangeDesafios}
                        telefono={telefono}
                        handleSubmit={handleSubmit}
                        clearSmsStates={clearSmsStates}
                      />
                    ) : (
                      <ConfirmacionPassword
                        password={password}
                        showPassword={showPassword}
                        handlePassword={handlePassword}
                        handlePasswordVisibility={handlePasswordVisibility}
                        errorCode={error?.id}
                        clearPasswordStates={clearPasswordStates}
                      />
                    )}
                  </div>
                </div>
                <StepsActions
                  id="liquidacion-confirmar"
                  className="middle-xs mb-24"
                  backText="Volver"
                  nextText="Confirmar"
                  hideNextAction={hasValuesOnObject(error) || isMontoHigher}
                  backAction={handleBack}
                  nextAction={handleSubmit}
                  disableNextAction={isButtonDisabled}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
}

LiquidacionConfirmarTemplate.propTypes = {
  loading: PropTypes.bool,
  cuenta: PropTypes.string,
  reglas: PropTypes.array,
  cuentasInputSelect: PropTypes.array,
  password: PropTypes.string,
  showPassword: PropTypes.bool,
  handleChangeCuenta: PropTypes.func,
  handlePassword: PropTypes.func,
  handlePasswordVisibility: PropTypes.func,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
};

LiquidacionConfirmarTemplate.defaultProps = {
  loading: false,
  cuenta: '',
  reglas: [],
  cuentasInputSelect: [],
  password: '',
  showPassword: false,
  handleChangeCuenta: null,
  handlePassword: null,
  handlePasswordVisibility: null,
  handleBack: null,
  handleSubmit: null,
};

export default LiquidacionConfirmarTemplate;
