import { call, put, spawn, takeLatest } from 'redux-saga/effects';
import api from '../../api';
import {norma484Actions} from "../../../application/actions/norma484";

const apiRequest = api(process.env.REACT_APP_NORMA_URL_ENDPOINT);

export function* fetchValidarNorma484() {
  try {
    const endpoint = '/validar';
    const { data, code } = yield call(apiRequest.post, endpoint, {});
    if (code === 0) {
      yield put(norma484Actions.fetchValidarNorma484Success(data));
    } else {
      yield put(norma484Actions.fetchValidarNorma484Error());
    }
  } catch (err) {
    yield put(norma484Actions.fetchValidarNorma484Error());
  }
}

export function* watchfetchValidar() {
  yield takeLatest(norma484Actions.fetchValidarNorma484.type, fetchValidarNorma484);
}

export default function* rootSaga() {
  yield spawn(watchfetchValidar);
}

