import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 50px;
  margin-top: 37px;
  margin-bottom: 32px;
  @media (max-width: 1280px) {
    margin-right: 16px;
    margin-left: 16px;
    margin-top: 16px;
  }
  .containerCard {
    margin-bottom: 32px;
    @media (max-width: 991px) {
      margin-bottom: 24px;
    }
  }

  .messageInfoSuccess {
    margin: 32px 0px;

    > div > div > p {
      font-family: 'Ubuntu-Regular';
      font-size: 14px;
      line-height: 22px;

      @media screen {
        font-size: 14px;
        line-height: 20px;
      }
    }

    @media screen and (max-width: 500px) {
      margin: 16px 0px;
    }
  }

  .comprobanteContainer {
    padding-bottom: 32px;
    @media screen and (max-width: 500px) {
      padding-bottom: 16px;
    }
  }

  .divButtons__return {
    ._2p8qe {
      margin-top: 32px;
      padding-left: 16px;
    }
  }
`;
export default Wrapper;
