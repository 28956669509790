const initState = {
  kitData: { data: null, error: null },
  validateTC: { data: null, error: null },
  smsCode: { data: null, error: null },
  validateSMS: { data: null, error: null },
  downloadPDF: { error: null },
  kitDataBanner: { data: null, error: null },
  availablePassCoopeuch: { data: null, error: null },
  solicitarPassCoopeuch: { data: null, error: null },
  validarPassCoopeuch: { data: null, error: null },
  activateKit: { data: null, error: null },
  kitDataBanner: { data: null, error: null },
  tcCode:{data : null, error: null},
  solicitarTarjetaCoordenadas: { data: null, error: null },
  validarCoordenadas: {data: null, error: null},
  consultarFactor: {data: null, error: null},
  validateTD: {data: null, error: null},
};

export default initState;
