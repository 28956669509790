import styled from 'styled-components';

export const IconWrapper = styled.div`
  margin-right: 8px;
  /* height: 32px; */
  /* width: 32px; */
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  display: ${({ icon }) => (icon ? 'initial' : 'none')};

  & > img,
  & > svg & > div {
    height: 100%;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    margin-right: 8px;
    height: 24px;
    width: 24px;
  }
`;

const Wrapper = styled.div`
  background-color: #e4e5ed;
  height: 60px;
  width: 100vmax;
  border-bottom: 1px solid #c3c6dc;
  align-items: center;
  position: absolute;
  display: flex;
  z-index: 1;

  @media screen and (max-width: 63.99rem) {
    left: 0;
  }
`;

export default Wrapper;
