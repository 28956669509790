import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: 57px;
  margin-top: 37px;
  @media (max-width: 1280px) {
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 16px;
  }

  .boxContainer {
    margin-top: 32px;
    @media (max-width: 991px) {
      margin-top: 16px;
    }
    .boxHeader {
      padding: 20px 32px;
      @media (max-width: 768px) {
        padding: 16px;
      }
      &__title {
        font-size: 24px;
        font-family: 'Ubuntu-Bold';
        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
      &__description {
        font-size: 16px;
        font-family: 'Ubuntu-Regular';
        margin-top: 6px;
        @media (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
    .boxBody {
      display: flex;
      flex-direction: column;
    }
    ._1-ro1._3gQ4e {
      padding: 0px !important;
    }
  }
`;

export default Wrapper;
