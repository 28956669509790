const passCoopeuch = {
  descripIngresa:
    'Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.',
  descriptDesafio:
    'Ingresa clave PassCoopeuch antes que acabe el tiempo para autorizar la operación.',
  buttonIngresa: 'Autorizar con PassCoopeuch',
  buttonDesafio: 'para autorizar',
  buttonError: 'Reintentar',
};

export const errores = {
  0: {
    title: '¡Lo sentimos!',
    descrip:
      'En este momento nuestro servicio no se encuentra disponible. Agradecemos tu comprensión.',
  },
  1: {
    title: '¡Se agotó el tiempo!',
    descrip: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
  },
  8: {
    title: '¡Lo sentimos!',
    descrip:
      'La autorización desde tu celular ha sido rechazada, reintenta para volver a realizar el cambio de datos.',
  },
  4: {
    title: '¡Lo sentimos!',
    descrip: 'Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente',
  },
  5: {
    title: '¡Operación rechazada!',
    descrip: 'PassCoopeuch está bloqueada. Regístrate nuevamente en la app.',
  },

  7: {
    title: '¡Operación rechazada!',
    descrip: 'PassCoopeuch está bloqueada. Regístrate nuevamente en la app.',
  },
};

export default passCoopeuch;
