import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { addClass } from '@coopeuch-components/web';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import Input from '../../../../atoms/Input';
import Countdown from '../../../../organism/FactoresDeSeguridad/Utils/Countdown';
import CountdownCard from '../../../../organism/FactoresDeSeguridad/Utils/CountdownCard';

import InputContainer, { ButtonContainer } from './styles';

const pasosFactor = {
  0: 'inicio',
  1: 'ingresarCoordenadas',
  2: 'validarCoordenadas',
};

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const FactorTarjetaDeCoordenadas = (props) => {
  const {
    isButtonDisabled,
    onClickButton,
    onChangeInput,
    message,
    title,
    buttonText,
    withCountdownAnimation,
    stepFactor,
    desafio1,
    desafio2,
    desafio3,
    valorDesafio1,
    valorDesafio2,
    valorDesafio3,
    timer,
    activeTimer,
    duration,
    hasError,
    titleClass,
    showInputInError,
  } = props;

  let refCoor1 = useRef();
  let refCoor2 = useRef();
  let refCoor3 = useRef();

  function handleChange(event) {
    const { id, value } = event.target;

    if (id === 'cor1') {
      if (value.length === 2) {
        refCoor2.current.focus();
      }
    }

    if (id === 'cor2') {
      if (value.length === 2) {
        refCoor3.current.focus();
      }
    }

    onChangeInput(event);
  }

  return (
    <div className="col-md-6 col-sm-8 col-xs-12 center-xs">
      {hasError ? (
        <div className="row center-xs">
          <LoadIcon
            src={`${iconPath}tarjeta_coordenadas.svg`}
            size={180}
            className="factor-countdown-image"
          />
        </div>
      ) : (
        <Countdown
          ilustration="tarjeta_coordenadas"
          withCountdownAnimation={withCountdownAnimation}
          timer={timer}
        />
      )}

      <p className={addClass([titleClass, 'text-size-l text-bold'])}>{title}</p>
      <div
        className={addClass([!title && 'bmt-3', 'bmb-3 text-size-l'])}
        dangerouslySetInnerHTML={{ __html: `<div>${message}</div>` }}
      />
      <p className="homeKitBox__title">Tarjeta Débito Coopeuch</p>
          <p className="homeKitBox__content">
            Para comenzar, debes activar tu tarjeta de débito. Tenla a mano 
            para realizar la activación
          </p>

      {(pasosFactor[stepFactor] === 'ingresarCoordenadas' ||
        pasosFactor[stepFactor] === 'validarCoordenadas') &&
        showInputInError && (
          <InputContainer>
            <div className="row">
              <div className="col-md-4 col-xs-4 pl-12 pr-12">
                <div className="factor-coordenadas-label-container">
                  <label>{desafio1}</label>
                </div>
                <div>
                  <Input
                    id="cor1"
                    dataSet="1"
                    borderColor={
                      hasError ? 'red' : valorDesafio1?.length === 2 ? '#19a1d5 ' : '#646569'
                    }
                    maxLength={2}
                    type="password"
                    className="factor-coordenadas-input"
                    value={valorDesafio1}
                    onChange={handleChange}
                    ref={refCoor1}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-4 pl-12 pr-12">
                <div className="factor-coordenadas-label-container">
                  <label>{desafio2}</label>
                </div>
                <div>
                  <Input
                    id="cor2"
                    dataSet="2"
                    maxLength={2}
                    type="password"
                    className="factor-coordenadas-input"
                    borderColor={
                      hasError ? 'red' : valorDesafio2?.length === 2 ? '#19a1d5 ' : '#646569'
                    }
                    value={valorDesafio2}
                    onChange={handleChange}
                    ref={refCoor2}
                  />
                </div>
              </div>
              <div className="col-md-4 col-xs-4 pl-12 pr-12">
                <div className="factor-coordenadas-label-container">
                  <label>{desafio3}</label>
                </div>
                <div>
                  <Input
                    id="cor3"
                    dataSet="3"
                    maxLength={2}
                    type="password"
                    className="factor-coordenadas-input"
                    borderColor={
                      hasError ? 'red' : valorDesafio3?.length === 2 ? '#19a1d5 ' : '#646569'
                    }
                    value={valorDesafio3}
                    onChange={handleChange}
                    ref={refCoor3}
                  />
                </div>
              </div>
            </div>
          </InputContainer>
        )}
      {pasosFactor[stepFactor] === 'ingresarCoordenadas' && !hasError && (
        <CountdownCard timer={timer} active={activeTimer} />
      )}
      {(pasosFactor[stepFactor] === 'inicio' ||
        pasosFactor[stepFactor] === 'validarCoordenadas' ||
        hasError) && (
        <ButtonContainer>
          <Button
            rounded={5}
            bgColor="#e81d2b"
            color="white"
            onClick={onClickButton}
            dataset={{ id: 2.1 }}
            disabled={hasError ? false : isButtonDisabled}
            width={296}
            shadow
            height={48}
            className="factor-button-style"
          >
            Activar
          </Button>
        </ButtonContainer>
      )}
    </div>
  );
};

FactorTarjetaDeCoordenadas.propTypes = {
  isButtonDisabled: PropTypes.bool,
  onClickButton: PropTypes.func,
  onChangeInput: PropTypes.func,
  message: PropTypes.string,
  buttonText: PropTypes.string,
  withCountdownAnimation: PropTypes.bool,
  stepFactor: PropTypes.number,
  inputs: PropTypes.object,
  timer: PropTypes.number,
  activeTimer: PropTypes.bool,
  hasError: PropTypes.bool,
  titleClass: PropTypes.string,
  title: PropTypes.string,
};

FactorTarjetaDeCoordenadas.defaultProps = {
  isButtonDisabled: false,
  onClickButton: null,
  onChangeInput: () => {},
  message: '',
  buttonText: '',
  withCountdownAnimation: false,
  stepFactor: 0,
  inputs: {},
  timer: 0,
  activeTimer: false,
  hasError: false,
  titleClass: '',
  title: '',
};

export default FactorTarjetaDeCoordenadas;
