import styled from 'styled-components';

const Wrapper = styled.div`
  @media screen and (min-width: 414px) {
    margin-top: 16px;
  }
  @media screen and (min-width: 720px) {
    margin-top: 24px;
  }

  .editar-destinatarios--password-wrapper {
    position: relative;
    height: 68px;

    .lock-icon {
      position: absolute;
      z-index: 2;
      top: 13px;
      left: 5px;
    }

    .eye-icon {
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .help-text {
      position: absolute;
      left: 0;
      bottom: 0px;
    }

    .error-warning-password {
      display: flex;
      padding-top: 10px;
      width: 100%;
      & svg {
        fill: #bf0338;

        path {
          fill: #bf0338;
        }
      }
    }
  }
`;

export const ErrMessage = styled.div`
  line-height: 24px;
  font-size: 16px;
  display: flex;
  padding: 12px 24px;
  min-height: 48px;
  border-radius: 4px;
  justify-content: space-between;
  background-color: #f9d4d5;
  color: #bf0338;

  button {
    height: 25px;
  }

  & svg {
    fill: #bf0338;

    path {
      fill: #bf0338;
    }
  }
`;

export default Wrapper;
