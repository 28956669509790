/* eslint-disable no-nested-ternary */
import React, { PureComponent, Suspense, lazy } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import produce from 'immer';

// Ui
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Tabs from '@coopeuch-components/web/molecules/Tabs';
import Tab from '@coopeuch-components/web/molecules/Tabs/Tab';
import PageTitle from '../../components/atoms/PageTitle';
import ErrorCard from '../../components/organism/ErrorCard';

// Container
import SolicitaCuentaAhorro from './Solicita/index';
import SolicitudCAFlujo from './SolicitudCAFlujo';

// Actions
import { cuentaAhorroActions } from '../../../application/actions/cuentaAhorro';

// Utils
import { cuentaAhorroTexts, errorCardText } from './texts';
import { codigosModuloCuentaAhorro, estadosModuloCuentaAhorro } from '../../utils/constants';
import { registerDY, registerGA } from '../../utils/metrics';
import logger from '../../utils/logger';
import { hasValuesOnObject } from '../../utils/validators';

// Styles
import { TabsWrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const MisAhorros = lazy(() => import('./MisAhorros'));
const Movimientos = lazy(() => import('./Movimientos'));

const initState = {
  tieneCuentasAhorro: true,
  currentTab: 1,
  cuentaLog: false,
  cuentaAhorroSeleccionada: {},
  solicitudCAFlujo: false,
  isReloadCuentas: false,
};

class CuentaAhorro extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidMount() {
    const { fetchCuentasAhorro, cleanErrorStatusCuentaAhorro } = this.props;

    cleanErrorStatusCuentaAhorro();

    fetchCuentasAhorro({
      params: {
        codigoProducto: codigosModuloCuentaAhorro,
        estadoCuenta: estadosModuloCuentaAhorro,
      },
    });
    registerDY({ type: 'PRODUCT', data: ['cuenta_ahorro'] });
  }

  componentDidUpdate(prevProps) {
    const {
      isLoadingCuentasAhorro,
      isLoadingMovimientos,
      isLoadingDetalleCuentasAhorro,
      errDetallesLog,
      cuentasAhorro,
      errorCuentasAhorro,
      errCuentasLog,
      errMovimientosLog,
      persona,
      cuentaDetalle,
      fetchCuentasAhorro,
    } = this.props;
    const { cuentaLog, tieneCuentasAhorro, isReloadCuentas } = this.state;

    // Se recargan cuentas desde solicitud Cuenta (flujo)
    if (isReloadCuentas) {
      console.log('hice reload');
      fetchCuentasAhorro({
        params: {
          codigoProducto: codigosModuloCuentaAhorro,
          estadoCuenta: estadosModuloCuentaAhorro,
        },
      });
    }

    if (prevProps.isLoadingCuentasAhorro !== isLoadingCuentasAhorro) {
      if (!isLoadingCuentasAhorro) {
        // Se cancela recarga de fetchCuentasAhorro
        this.setState({ isReloadCuentas: false });
        if (cuentasAhorro.length > 0) {
          this.setState(
            produce((draft) => {
              draft.tieneCuentasAhorro = true;
            })
          );
        } else {
          registerGA({
            event: 'vpv-privado',
            page: '/tef/cuenta-ahorro/landing',
          });
          this.setState(
            produce((draft) => {
              draft.tieneCuentasAhorro = false;
            })
          );
        }
      }
    }

    if (prevProps.errorCuentasAhorro !== errorCuentasAhorro) {
      if (errorCuentasAhorro) {
        registerGA({
          event: 'eve',
          'eve-acc': '/tef/cuenta-ahorro/mis-ahorros/sin-servicio',
          'eve-cat': 'Aviso',
          'eve-lab': 'Sin Servicio',
        });
      }
    }

    if (tieneCuentasAhorro) {
      if (
        prevProps.isLoadingCuentasAhorro !== isLoadingCuentasAhorro ||
        prevProps.isLoadingDetalleCuentasAhorro !== isLoadingDetalleCuentasAhorro ||
        prevProps.isLoadingMovimientos !== isLoadingMovimientos
      ) {
        if (!isLoadingCuentasAhorro && !isLoadingDetalleCuentasAhorro && !isLoadingMovimientos) {
          const errors = errCuentasLog || errMovimientosLog || errDetallesLog;
          const baseRut = String((persona?.rut || '').replace('-', ''));

          const ifDetalles = hasValuesOnObject(cuentaDetalle) || !!errDetallesLog;

          if (errCuentasLog || errMovimientosLog || ifDetalles) {
            if (!cuentaLog) {
              logger(errors, {
                rut: baseRut.slice(0, -1),
                digito_verificador: baseRut.slice(-1),
                accion: 'CA-INGRESO',
                informacion_adicional: {},
              });
            }

            this.setState(
              produce((draft) => {
                draft.cuentaLog = true;
              })
            );
          }
        }
      }
    }
  }

  handleChangeCuentaAhorro = (account) => {
    this.setState(
      produce((draft) => {
        draft.cuentaAhorroSeleccionada = account;
      })
    );
  };

  onSetCurrentTabHandle = (event) => {
    const { dataset } = event.target || {};

    this.setState(
      produce((draft) => {
        draft.currentTab = Number(dataset?.id);
      })
    );
  };

  handleSolicitudCAFlujo = () => {
    const { persona } = this.props;
    const error = null;

    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/mis-ahorros',
      'eve-cat': 'Click',
      'eve-lab': 'Crear nueva cuenta ahorro',
    });

    if (persona && persona.rut) {
      const baseRut = String(persona.rut.replace('-', ''));

      this.setState({ solicitudCAFlujo: true });
      logger(error, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CA-SOLICITUD',
        informacion_adicional: {},
      });
    }
  };

  onHandleClickBanner = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/landing',
      'eve-cat': 'Click',
      'eve-lab': 'Solicita tu cuenta',
    });
    this.setState({ solicitudCAFlujo: true });
  };

  handleClickReturnSolicitudCA = () => {
    this.setState({ solicitudCAFlujo: false });
  };

  handleReloadCuentas = () => {
    this.setState({ isReloadCuentas: true });
  };

  render() {
    const { tieneCuentasAhorro, currentTab, cuentaAhorroSeleccionada, solicitudCAFlujo } =
      this.state;
    const { errorCuentasAhorro, isLoadingCuentasAhorro, cuentasAhorro, persona, history } =
      this.props;

    return (
      <>
        {solicitudCAFlujo === true ? (
          <SolicitudCAFlujo
            handleClickReturnSolicitudCA={this.handleClickReturnSolicitudCA}
            cuentasAhorro={cuentasAhorro}
            handleReloadCuentas={this.handleReloadCuentas}
          />
        ) : !tieneCuentasAhorro && !errorCuentasAhorro ? (
          <div>
            <SolicitaCuentaAhorro
              {...persona}
              handleSolicitudCAFlujo={this.handleSolicitudCAFlujo}
              onHandleClickBanner={this.onHandleClickBanner}
            />
          </div>
        ) : (
          <div className="w-100">
            <PageTitle
              title={cuentaAhorroTexts.TITLE_CUENTA_AHORRO}
              icon={<LoadIcon src={`${iconPath}user.svg`} color="#333" />}
            />
            <TabsWrapper className="w-100 margin-after-title">
              <Tabs
                bgColor="#fff"
                fontSize={15}
                fontFamily="Ubuntu-Medium"
                tabSize={20}
                activeColor="#e81e2b"
                className="pl-54 tab--wrapper-shadows tabs full-tab"
              >
                <Tabs.Header>
                  <Tab className="tab-style">
                    <Link
                      to="/ecd/productos/cuenta-ahorro"
                      onClick={this.onSetCurrentTabHandle}
                      data-id="1"
                    >
                      {cuentaAhorroTexts.TAB_CUENTA}
                    </Link>
                  </Tab>
                  <Tab className="tab-style w-150p">
                    <Link
                      to="/ecd/productos/cuenta-ahorro/movimientos"
                      onClick={this.onSetCurrentTabHandle}
                      data-id="2"
                    >
                      {cuentaAhorroTexts.TAB_MOVIMIENTOS}
                    </Link>
                  </Tab>
                </Tabs.Header>
                <div className="w-100 bpx-3 bpx-md-5 margin-after-tabs text-left">
                  <Tabs.Body>
                    <Suspense fallback={<div />}>
                      {Number(currentTab) === 1 && (
                        <div className="w-100 padding-title">
                          {errorCuentasAhorro && !isLoadingCuentasAhorro ? (
                            <div className="w-100 bmt-4">
                              <ErrorCard
                                titleText={errorCardText.ERROR_CARD_TITLE}
                                descText={errorCardText.ERROR_CARD_DESC}
                              />
                            </div>
                          ) : (
                            <MisAhorros
                              isLoadingCuentasAhorro={isLoadingCuentasAhorro}
                              cuentasAhorro={cuentasAhorro}
                              tieneCuentasAhorro={tieneCuentasAhorro}
                              cuentaAhorroSeleccionada={cuentaAhorroSeleccionada}
                              handleChangeCuentaAhorro={this.handleChangeCuentaAhorro}
                              history={history}
                              handleSolicitudCAFlujo={this.handleSolicitudCAFlujo}
                            />
                          )}
                        </div>
                      )}
                    </Suspense>
                    <Suspense fallback={<div />}>
                      {Number(currentTab) === 2 && (
                        <div className="w-100 padding-title">
                          <Movimientos
                            {...this.props}
                            cuentaAhorroSeleccionada={cuentaAhorroSeleccionada}
                            handleChangeCuentaAhorro={this.handleChangeCuentaAhorro}
                          />
                        </div>
                      )}
                    </Suspense>
                  </Tabs.Body>
                </div>
              </Tabs>
            </TabsWrapper>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  isLoadingCuentasAhorro: ui.loaders.isLoadingCuentasAhorro,
  cuentasAhorro: entities.cuentasAhorro.cuentas,
  errorCuentasAhorro: entities.cuentasAhorro.errorFetchCuentas,
  movimientos: entities.cuentasAhorro.movimientos,
  errorFetchMovimientos: entities.cuentasAhorro.errorFetchMovimientos,
  isLoadingMovimientos: ui.loaders.isLoadingMovimientosCuentasAhorro,
  isLoadingDetalleCuentasAhorro: ui.loaders.isLoadingDetalleCuentasAhorro,
  errCuentasLog: entities.cuentasAhorro.errCuentasLog,
  errMovimientosLog: entities.cuentasAhorro.errMovimientosLog,
  errDetallesLog: entities.cuentasAhorro.errDetallesLog,
  persona: entities.persona,
  cuentaDetalle: entities.cuentasAhorro.detalle,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasAhorro(props) {
    dispatch(cuentaAhorroActions.fetchCuentasAhorro(props));
  },
  fetchMovimientosCuentasAhorro(props) {
    dispatch(cuentaAhorroActions.fetchMovimientosCuentasAhorro(props));
  },
  cleanErrorStatusCuentaAhorro() {
    dispatch(cuentaAhorroActions.cleanErrorStatusCuentaAhorro());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CuentaAhorro));
