export const destinatariosTexts = {
  TITLE: 'Mis destinatarios',
};

export const datosDestinatarios = {
  addPageTitle: 'Agregar destinatatios',
  addTitle: '¿Quieres agregar un destinatario?',
  addDescription:
    'Para agregar un destinatario completa los siguientes campos que son obligatorios:',
  rut: 'RUT',
  name: 'Nombre y Apellido',
  bank: 'Institución Financiera',
  typeAccount: 'Tipo de cuenta',
  numberAccount: 'N° de Cuenta',
  email: 'Email',
  alias: 'Alias',
  goBack: 'Volver',
  next: 'Continuar',
  edit: 'Editar',
  save: 'Guardar',
  rutError: 'El RUT no es válido',
  emailError: 'El email ingresado no es válido.',
  bankPlaceholder: 'Seleccionar el banco',
  typeAccountPlaceholder: 'Seleccionar el tipo',
  warningMonedero: 'Al Monedero Digital, solo puedes transferir a Cuentas con tu mismo RUT',
  authorizeWithPassCoopeuchDescription:
    'Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.',
  buttonAuthorizeWithPassCoopeuch: 'Autorizar con PassCoopeuch',
  iWantToAuthorizeWithPassCoopeuch: 'Quiero autorizar con PassCoopeuch',
  iWantToAuthorizeWithTCOO: 'Quiero autorizar con Tarjeta de Coordenadas',
  authorizeWithTCOO:
    'Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.',
  buttonAuthorize: 'Autorizar',
  enterCoords: 'Ingresa las coordenadas indicadas de tu Tarjeta de Coordenadas N°',
  enterPass:
    'Para autorizar la operación, ingresa la clave Coopeuch antes que termine el tiempo permitido.',
  titlePassCoopeuch: 'PassCoopeuch',
  titleCoordsCard: 'Tarjeta de Coordenadas',
  editPageTitle: 'Editar destinatarios',
  pageTitle: 'Mis Destinatarios',
  editTitle: '¿Quieres modificar un destinatario?',
  editDescription: 'Recuerda que solo podrás modificar el email de destinatario',
  titlePassword: 'Firma electrónica',
  autorizeWithPasswordDescription:
    'Valida la modificación del destinatario con tu clave de internet Coopeuch',
  passwordLabel: 'Ingresa tu clave de Internet',
  helpTextPassword: 'Cantidad de caracteres',
  errorLengthPassword: 'La clave debe tener entre 6 y 8 caracteres.',
  tryAgain: 'Reintentar',
};
