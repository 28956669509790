import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { creditoHipotecarioActions } from '../../../application/actions/creditoHipotecario';

// api
import api from '../../api';

// Utils
import { downloadPDFBase64OtherTab } from '../../../ui/utils/functions';

const apiCreditoHipotecario = api(process.env.REACT_APP_CREDITO_HIPOTECARIO);
const apiCuentaVista = api(process.env.REACT_APP_CURRENT_ACCOUNT);

export function* fetchCredito() {
  try {
    const { data, error } = yield call(apiCreditoHipotecario.post, 'credito/v1/obtener', {});
    if (data) {
      yield put(creditoHipotecarioActions.fetchCreditoSuccess({ data }));
    } else {
      yield put(creditoHipotecarioActions.fetchCreditoError());
    }
  } catch (error) {
    yield put(creditoHipotecarioActions.fetchCreditoError());
  }
}

export function* fetchCuota() {
  try {
    const { data, error } = yield call(apiCreditoHipotecario.post, 'cuota/v1/obtener', {});
    if (data) {
      yield put(creditoHipotecarioActions.fetchCuotaSuccess({ data }));
    } else {
      yield put(creditoHipotecarioActions.fetchCuotaError());
    }
  } catch (error) {
    yield put(creditoHipotecarioActions.fetchCuotaError());
  }
}

export function* fetchHistorialPago(action) {
  const { accountNumber, yearSelected } = action.payload;
  try {
    const { data, error } = yield call(
      apiCreditoHipotecario.post,
      `credito/historial/v1/obtener/${accountNumber}`,
      {
        periodo: yearSelected,
      }
    );
    if (data) {
      yield put(creditoHipotecarioActions.fetchHistorialPagoSuccess({ data }));
    } else {
      yield put(creditoHipotecarioActions.fetchHistorialPagoError());
    }
  } catch (error) {
    yield put(creditoHipotecarioActions.fetchHistorialPagoError());
  }
}

export function* fetchHistorialPdfPago(action) {
  const { accountNumber, yearSelected } = action.payload;
  try {
    yield put(creditoHipotecarioActions.resetDataPDFMovimientos());
    const { data, error } = yield call(
      apiCreditoHipotecario.post,
      `credito/historial/pdf/v1/obtener/${accountNumber}`,
      {
        periodo: yearSelected,
      }
    );
    if (data) {
      downloadPDFBase64OtherTab(data);
      yield put(
        creditoHipotecarioActions.fetchHistorialPdfPagoSuccess({
          data,
        })
      );
    } else if (error) {
      yield put(creditoHipotecarioActions.fetchHistorialPdfPagoError());
    }
  } catch (err) {
    console.log(err.message, 'fetchHistorialPdfPago');
    yield put(creditoHipotecarioActions.fetchHistorialPdfPagoError());
  }
}

export function* fetchPay(action) {
  const { accountNumber, referenceNumber, password } = action.payload;
  yield put(creditoHipotecarioActions.fetchPayLoading());
  try {
    const { data, error } = yield call(apiCreditoHipotecario.post, 'cuota/v1/pagar', {
      numeroCuenta: accountNumber,
      numeroReferencia: referenceNumber,
      clave: password,
    });
    if (data) {
      yield put(creditoHipotecarioActions.fetchPaySuccess(data));
    }
    if (error) {
      yield put(creditoHipotecarioActions.fetchPayPasswordError(error));
    }
    if (!data && !error) {
      yield put(creditoHipotecarioActions.fetchPayServerError());
    }
  } catch (_) {
    yield put(creditoHipotecarioActions.fetchPayServerError());
  }
}
export function* fetchVentanaHoraria() {
  try {
    const { data, error } = yield call(apiCreditoHipotecario.get, 'ventanahoraria/v1/cierreasicom/obtener');
    if (data) {
      yield put(creditoHipotecarioActions.fetchVentanaHorariaSuccess( data ));
    } else {
      yield put(creditoHipotecarioActions.fetchVentanaHorariaError());
    }
  } catch (error) {
    yield put(creditoHipotecarioActions.fetchVentanaHorariaError());
  }
}

export function* fetchAvailableAccounts() {
  yield put(creditoHipotecarioActions.fetchAvailableAccountsLoading());
  try {
    const { data, error } = yield call(apiCuentaVista.post, 'cuenta/v1/obtener', {
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A'],
      tipoCliente: ['R'],
    });
    if (data.length) {
      yield put(creditoHipotecarioActions.fetchAvailableAccountsSuccess(data));
    }
    if (!data?.length) {
      yield put(creditoHipotecarioActions.fetchAvailableAccountsNoData());
    }
    if (error) {
      yield put(creditoHipotecarioActions.fetchAvailableAccountsError());
    }
    if (!data && !error) {
      yield put(creditoHipotecarioActions.fetchAvailableAccountsError());
    }
  } catch (_) {
    yield put(creditoHipotecarioActions.fetchAvailableAccountsError());
  }
}

export function* fetchComprobantePago(action) {
  const { referenceNumber, feeNumber } = action.payload;
  try {
    const { data, error } = yield call(
      apiCreditoHipotecario.post,
      'credito/detallepago/v1/obtener',
      {
        numeroCuotaPagada: feeNumber,
        numeroReferencia: referenceNumber,
      }
    );
    if (data) {
      yield put(creditoHipotecarioActions.fetchcomprobantePagoSuccess(data));
    } else {
      yield put(creditoHipotecarioActions.fetchcomprobantePagoError());
    }
  } catch (error) {
    yield put(creditoHipotecarioActions.fetchcomprobantePagoError());
  }
}

export function* fetchComprobantePdfPago(action) {
  const { feeNumber, referenceNumber } = action.payload;
  try {
    yield put(creditoHipotecarioActions.resetDataPDFMovimientos());
    const { data, error } = yield call(
      apiCreditoHipotecario.post,
      'credito/comprobante/pdf/v1/obtener',
      {
        numeroCuotaPagada: feeNumber,
        numeroReferencia: referenceNumber,
      }
    );
    if (data) {
      downloadPDFBase64OtherTab(data);
      yield put(
        creditoHipotecarioActions.fetchComprobantePdfPagoSuccess({
          data,
        })
      );
    } else if (error) {
      yield put(creditoHipotecarioActions.fetchComprobantePdfPagoError());
    }
  } catch (err) {
    console.log(err.message, 'fetchComprobantePdfPagoError');
    yield put(creditoHipotecarioActions.fetchComprobantePdfPagoError());
  }
}

export function* watchFetchCredito() {
  yield takeLatest(creditoHipotecarioActions.fetchCredito.type, fetchCredito);
}
export function* watchFetchCuota() {
  yield takeLatest(creditoHipotecarioActions.fetchCuota.type, fetchCuota);
}
export function* watchFetchHistorialPago() {
  yield takeLatest(creditoHipotecarioActions.fetchHistorialPago.type, fetchHistorialPago);
}
export function* watchFetchHistorialPdfPago() {
  yield takeLatest(creditoHipotecarioActions.fetchHistorialPdfPago.type, fetchHistorialPdfPago);
}
export function* watchFetchPagarCuota() {
  yield takeLatest(creditoHipotecarioActions.fetchPay.type, fetchPay);
}
export function* watchFetchAvailableAccounts() {
  yield takeLatest(creditoHipotecarioActions.fetchAvailableAccounts.type, fetchAvailableAccounts);
}
export function* watchFetchComprobantePago() {
  yield takeLatest(creditoHipotecarioActions.fetchComprobantePago.type, fetchComprobantePago);
}

export function* watchFetchComprobantePdfPago() {
  yield takeLatest(creditoHipotecarioActions.fetchComprobantePdfPago.type, fetchComprobantePdfPago);
}

export function* watchFetchVentanaHoraria() {
  yield takeLatest(creditoHipotecarioActions.fetchVentanaHoraria.type, fetchVentanaHoraria);
}

export default function* rootSaga() {
  yield spawn(watchFetchCredito);
  yield spawn(watchFetchCuota);
  yield spawn(watchFetchHistorialPago);
  yield spawn(watchFetchHistorialPdfPago);
  yield spawn(watchFetchPagarCuota);
  yield spawn(watchFetchAvailableAccounts);
  yield spawn(watchFetchComprobantePago);
  yield spawn(watchFetchComprobantePdfPago);
  yield spawn(watchFetchVentanaHoraria);

}
