import React from 'react';
import Skeleton from 'react-loading-skeleton';
// Styles
import { WrapperSkeleton } from './styles';

const LoadingSkeleton = () => {
  return (
    <WrapperSkeleton>
      <div className='row'>
        <div className="col-md-6 col-12 bodySkeleton">
          <Skeleton className="bmb-3 skeleton-description" />
          <div className="col-md-5 col-xs-12 d-flex end-xs">
            <Skeleton height={112} width={112} className="bmr-2" circle={true} />
          </div>
          <div className="boxMonto">
            <Skeleton className="bmt-3 skeleton-txt" />
            <Skeleton className="bmt-3 bmb-3 skeleton-txt" />
            <Skeleton className="bmt-3 skeleton-txt" />
          </div>
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 bmb-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-txt" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <div className="footerSkeleton">
            <Skeleton className="skeleton-btn" />
          </div>
        </div>
        <hr />
        <div className="col-md-6 col-12 bodySkeleton">
          <Skeleton className="bmb-3 skeleton-description" />
          <div className="col-md-5 col-xs-12 d-flex end-xs">
            <Skeleton height={112} width={112} className="center" circle={true} />
          </div>
          <div className="boxMonto">
            <Skeleton className="bmt-3 skeleton-txt" />
            <Skeleton className="bmt-3 bmb-3 skeleton-txt" />
            <Skeleton className="bmt-3 skeleton-txt" />
          </div>
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 bmb-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-txt" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <Skeleton className="bmt-3 skeleton-list" />
          <div className="footerSkeleton">
            <Skeleton className="skeleton-btn" />
          </div>
        </div>
        <hr />
      </div>
    </WrapperSkeleton>
  );
};

export default LoadingSkeleton;
