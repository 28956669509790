import React from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../atoms/PageTitle';
import SolicitaPassCoopeuch from '../../TransferirFondos/SolicitaPassCoopeuch';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DescargaPassCoopeuch = () => {
  return (
    <div>
      <PageTitle
        title="Descarga PassCoopeuch"
        icon={<LoadIcon src={`${iconPath}money_exchange.svg`} color="#333" />}
      />
      <div className="margin-after-title">
        <div className="mt-32 pl-32 pt-32 pb-32">
          <div className="row start-xs">
            <div className="col-xs-12">
              <SolicitaPassCoopeuch />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DescargaPassCoopeuch;
