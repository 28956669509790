import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import FullBannerGoToSimulacion from '../../../organism/DepositoAPlazo/FullBannerGoToSimulacion';
import DepositoContratado from '../../../organism/DepositoAPlazo/Home/DepositoContratado';

import PageTitle from '../../../atoms/PageTitle';
import ErrorCard from '../../../organism/ErrorCard';

import Wrapper from './styles';
import ExpandDetailed from '../../../organism/DepositoAPlazo/Home/ExpandDetailed';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

function HomeTemplate(props) {
  const { depositosContratados, error, isLoadingDepositosContratados } = props;

  return (
    <Wrapper>
      <PageTitle
        id="dap-title-page"
        title="Depósito a Plazo"
        icon={<LoadIcon src={`${iconPath}ico_dap.svg`} size={30} color="#333" />}
      />

      <div className="w-100 margin-after-title content-dap">
        {error ? (
          <div className="mt-24">
            <ErrorCard
              titleText="¡Lo sentimos!"
              descText="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
            />
          </div>
        ) : (
          <Fragment>
            {isLoadingDepositosContratados ? (
              <div className="row start-xs">
                <div className="col-xs-12">
                  <FullBannerGoToSimulacion.Skeleton />
                </div>

                <div className="col-xs-12">
                  <div className="row">
                    {[1, 2, 3, 4, 5, 6]?.map((i) => (
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" key={i}>
                        <DepositoContratado.Skeleton />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-xs-12">
                  <ExpandDetailed.Skeleton />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-xs-12">
                  <div className="bmt-3 bmt-md-4">
                    <FullBannerGoToSimulacion />
                  </div>
                </div>

                <div className="col-xs-12 bmt-3 bmt-md-4">
                  <div className="row">
                    {depositosContratados?.map((deposito, index) => (
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" key={deposito?.id}>
                        <DepositoContratado
                          itIsTheLast={depositosContratados?.length == index + 1}
                          {...deposito}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-xs-12 bmt-3 bmt-md-0 bmb-3 bmb-md-4 start-xs">
                  <ExpandDetailed />
                </div>
              </div>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
}

HomeTemplate.propTypes = {
  depositosContratados: PropTypes.array,
};

HomeTemplate.defaultProps = {
  depositosContratados: [],
};

export default HomeTemplate;
