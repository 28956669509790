import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import RutYSerie from '../RutYSerie';
import Metodo from '../Metodo';
import Validacion from '../Validacion';
import CreacionClave from '../CreacionClave';

import Wrapper from '../../../components/templates/RecuperarOCrearClave/Wrapper';

const components = {
  1: RutYSerie,
  2: Metodo,
  3: Validacion,
  4: CreacionClave,
};

const StepperRouter = (props) => {
  const { pathStep, resetAllState, onStepsHandle, returnMetodo, validarUsingFactorFailed } = props;

  const Component = useMemo(() => components[pathStep] || RutYSerie, [pathStep]);

  return (
    <Wrapper
      position={pathStep}
      resetAllState={resetAllState}
      onStepsHandle={onStepsHandle}
      returnMetodo={returnMetodo}
      validarUsingFactorFailed={validarUsingFactorFailed}
    >
      <Component {...props} />
    </Wrapper>
  );
};

StepperRouter.propTypes = {
  pathStep: PropTypes.oneOf([1, 2, 3, 4]).isRequired,
  ...RutYSerie.propTypes,
  ...Metodo.propTypes,
  ...Validacion.propTypes,
  ...CreacionClave.propTypes,
};

StepperRouter.defaultProps = {
  pathStep: 1,
  ...RutYSerie.defaultProps,
  ...Metodo.defaultProps,
  ...Validacion.defaultProps,
  ...CreacionClave.defaultProps,
};

export default StepperRouter;
