import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';
import { fetchMonederoValidarActions } from '../../../application/actions/upgradeMonedero';
import { loadersActions } from '../../../application/actions/ui_loaders';
import apiLegado from '../../../infrastructure/apiLegado';

const apiUpgradeMonedero = apiLegado(process.env.REACT_APP_UPGRADE_MONEDERO_API);

export function* fetchMonederoValidar() {
  try {
    yield put(loadersActions.fetchLoadingMonederoValidar({ isLoading: true }));

    const response = yield call(apiUpgradeMonedero.get, 'monedero/validar');
   
    if (!response.error && !response.data?.error  && !response.data.isAvailable ) {
      yield put(
        fetchMonederoValidarActions.fetchMonederoValidarSuccess({
          showBanner: !response.data.isAvailable,
          message: response.data.message,
        })
      );
    } else {
      yield put(fetchMonederoValidarActions.fetchMonederoValidarFailed());
    }
    yield put(loadersActions.fetchLoadingMonederoValidar({ isLoading: false }));
  } catch (error) {
    yield all([
      put(loadersActions.fetchLoadingMonederoValidar({ isLoading: false })),
      yield put(fetchMonederoValidarActions.fetchMonederoValidarFailed()),
    ]);
  }
}

export function* watchFetchMonederoValidar() {
  yield takeLatest(fetchMonederoValidarActions.fetchMonederoValidar.type, fetchMonederoValidar);
}

export default function* rootSaga() {
  yield spawn(watchFetchMonederoValidar);
}