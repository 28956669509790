import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  color: #383638;
  font-size: 16px;
  & label {
    display: flex;
    cursor: pointer;
  }
  & span {
    margin-left: 16px;
  }
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckedCheckbox = styled.div`
  display: inline-block;
  height: 24px;
  width: 24px;
  background: ${(props) => (props.checked ? '#007DB7' : '#ffffff')};
  border-radius: 3px;
  transition: all 150ms;
  border: ${({ checked }) => (checked ? '1px solid #007db7' : ' 2px solid #747474')};
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const BlockedCheckbox = styled.div`
  display: inline-block;
  height: 24px;
  width: 24px;
  background: #a5a5a5;
  border-radius: 3px;
  transition: all 150ms;
  border: 1px solid #a5a5a5;
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const CheckBoxComponent = ({ blocked, checked, id, name, onChange }) => {
  return (
    <CheckboxContainer>
      {blocked ? (
        <BlockedCheckbox {...{ blocked, checked }}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </BlockedCheckbox>
      ) : (
        <React.Fragment>
          <CheckedCheckbox {...{ blocked, checked }}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </CheckedCheckbox>
          <HiddenCheckbox {...{ id, name, onChange }} />
        </React.Fragment>
      )}
    </CheckboxContainer>
  );
};

const Index = ({ blocked, checked, id, name, onChange, label, labelClass }) => {
  return (
    <Wrapper>
      <label>
        <CheckBoxComponent {...{ blocked, checked, id, name, onChange }} />
        <span className={labelClass}>{label}</span>
      </label>
    </Wrapper>
  );
};

export default Index;
