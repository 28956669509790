import React, { PureComponent } from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import { productActions } from '../../../application/actions/products';
import { factoresSeguridadActions } from '../../../application/actions/factoresSeguridad';

import PageTitle from '../../components/atoms/PageTitle';
import ListarDestinatarios from './ListarDestinatarios';

import { destinatariosTexts } from './texts';
import { registerDY } from '../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

class Destinatarios extends PureComponent {
  componentDidMount() {
    const { fetchFactoresSeguridad, fetchCuentasVista } = this.props;
    fetchFactoresSeguridad();
    fetchCuentasVista({
      codigoProducto: ['VI01', 'VI03', 'VI06'],
      estadoCuenta: ['A'],
    });
    registerDY({ type: 'PRODUCT', data: ['transferencias'] });
  }

  componentDidUpdate(prevProps) {
    const { cuentas, sinProductos, history } = this.props;

    if (cuentas !== prevProps.cuentas && sinProductos) {
      history.push('/ecd/solicita/cuenta-coopeuch');
    }
  }

  render() {
    return (
      <>
        <PageTitle
          title={destinatariosTexts.TITLE}
          icon={<LoadIcon src={`${iconPath}money_exchange.svg`} color="#333" size={24} />}
        />
        <div className="w-100 bpx-3 bpx-md-5 margin-after-title text-left">
          <ListarDestinatarios />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ entities }) => ({
  cuentas: entities.products.cuentaVista.balance,
  sinProductos: entities.products.cuentaVista.sinProductos,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuentasVista(props) {
    dispatch(productActions.fetchCuentasVista(props));
  },
  fetchFactoresSeguridad() {
    dispatch(factoresSeguridadActions.fetchFactoresSeguridad());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Destinatarios));
