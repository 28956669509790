import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  100% {
    opacity: 1; 
    transform: none;
  }
`;

const BaseModal = styled.div`
  background-color: #fff;
  flex-direction: column;
  padding: 24px 32px;
  border-radius: 5px;
  padding-top: 21px;
  display: flex;
  margin: 48px auto;

  ${({ isOpen }) => !isOpen && 'opacity:0'};

  @media screen and (min-width: 767px) {
    width: ${({ width }) => `${width}px`};
  }
  @media screen and (max-width: 768px) {
    width: 94%;
    padding: 16px;
  }
`;

export const ModalFooder = styled.div`
  margin-top: 16px;
  float: left;
  width: 100%;
`;

export const BaseContainer = styled.div`
  background-color: rgba(51, 51, 51, 0.5);
  position: fixed;
  z-index: 4;
  width: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  overflow: auto;

  ${({ isOpen }) => !isOpen && 'display:none'};

  & .fadein-anim {
    animation: 860ms ${fadeIn} cubic-bezier(0.25, 1, 0.5, 1) forwards;
  }

  .alert-modal-icon-cerrar-container {
    text-align: right;
  }

  .alert-modal-icon-cerrar {
    cursor: pointer;
    display: inline-flex;
  }
`;

export const ModalTitle = styled.div`
  padding-bottom: 24px;
  color: #383638;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  font-weight: bold;
  border-bottom: 1px solid #e9e9e9;

  @media screen and (max-width: 768px) {
    font-weight: normal;
    margin-right: 0px;
  }
`;

export const ModalBody = styled.div`
  height: ${({ height }) => (height ? `${height - 92}px` : 'auto')};

  float: left;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: #d8d8d8;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #f42534;
  }

  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  @media screen and (min-width: 767px) {
    width: ${({ width }) => `${width - 64}px`};
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ModalFooter = styled.div`
  border-top: 1px solid #e9e9e9;
  padding-top: 48px;

  .alert-modal-text {
    font-weight: bold;
  }

  .alert-modal-text-responsive {
    font-weight: bold;
    padding-right: 30px;
  }

  .alert-modal-link {
    text-decoration: none;
  }

  .alert-modal-link-container {
    align-self: center;
    padding-left: 0px;
    /* padding-left: 30px; */
  }

  .alert-modal-button-container {
    text-align: right;
  }

  .alert-modal-link-container-mobile {
    display: none;
  }

  @media screen and (max-width: 768px) {
    padding-top: 24px;

    .alert-modal-link-container {
      display: none;
    }

    .alert-modal-link-container-mobile {
      margin-top: 16px;
      display: initial;
      text-align: center;
    }

    .alert-modal-text {
      width: 100%;
    }
  }
`;

export default BaseModal;
