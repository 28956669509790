import React from 'react';

import FactoresDeSeguridad from '../../../organism/FactoresDeSeguridad';
import DashboardCard from '../../../organism/DashboardCard';

const ErrorServices = ({ handleClick, ...props }) => (
  <DashboardCard className="mt-24 mb-24 ml-0 mr-0">
    <DashboardCard.Body classes="pl-0 pr-0">
      <div className="row center-xs mt-14">
        <div className="col-xs-12 center-xs">
          <div className="row center-xs middle-xs">
            <FactoresDeSeguridad
              tipo="error"
              title="¡Lo sentimos!"
              message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
              showButton
              onClickButton={handleClick}
              buttonText="Reintentar"
              titleClass="text-size-xxl pt-12 pb-24"
              messageClass="pb-24 lh-1_5"
              {...props}
            />
          </div>
        </div>
      </div>
    </DashboardCard.Body>
  </DashboardCard>
);

export default ErrorServices;
