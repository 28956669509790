import React from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import ButtonWithSpinner from '../../../atoms/ButtonWithSpinner';
import { colors } from '../../../../assets/styles';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { Wrapper } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const Index = ({
  backAction,
  className,
  disableBackAction = false,
  disableNextAction = false,
  nextAction,
  backText = 'Volver',
  nextText = 'Continuar',
  loading,
  id,
}) => {
  return (
    <Wrapper className={`row ${className}`}>
      <div className="step-action-button-wrapper bmt-3 bmt-md-0">
        <Button
          asTextLink
          disabled={disableBackAction}
          iconLeft={<LoadIcon src={`${iconPath}arrow_left.svg`} color={colors.red} />}
          onClick={backAction}
          color={colors.red}
          id={`${id}-prev-action`}
        >
          {backText}
        </Button>
      </div>
      <div className="step-action-button-wrapper">
        <ButtonWithSpinner
          className="mr-0"
          disabled={disableNextAction}
          onClick={nextAction}
          bgColor={colors.red}
          color={colors.white}
          id={`${id}-next-action`}
          loading={loading}
        >
          {nextText}
        </ButtonWithSpinner>
      </div>
    </Wrapper>
  );
};

export default Index;
