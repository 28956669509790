import React, { useEffect } from 'react';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Typography from '@coopeuch-components/web/atoms/Typography';

import StepsActions from '../../../../../organism/Stepper/StepsActions';
import ContratacionFirmaElectronica from '../../../../../organism/ContratacionFirmaElectronica';
import ContratacionEmail from '../../../../../organism/ContratacionEmail';
import Loading from '../../../../../atoms/Loading/Loading';
import DownloadLink from '../../../../../atoms/DownloadLink';
import { registerGA } from '../../../../../../utils/metrics';

import { CustomList, Paragraph, MessageError, CardBox } from './styles';
import './styles.css';
import ContratacionTerminos from '../../../../../organism/ContratacionTerminos';

const Index = ({
  checked,
  password,
  passwordError,
  serverPasswordError = '',
  onHandleCheckBox,
  onHandlePasswordInput,
  onTogglePassword,
  showPassword,
  volverACaracteristicas,
  contratar,
  serviceError = false,
  loadingContratacion,
  persona,
  email,
  onHandleEmailInputChange,
  emailError,
  handleWrongPassword,
  wrongPassword,
  blockedPassword,
  checkdRequiredError,
  onDownloadGenericContract,
}) => {

  useEffect(() => {
    registerGA({
      'event': 'vpv-privado', 'page': '/tef/solicitar/monederoDigital/paso2'
    });
  }, [])

  const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
  const documentsPath = process.env.REACT_APP_CLOUDFRONT_DOCUMENTS;
  const emailErrorMessage = (
    <MessageError className="input-error-cuotas text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${iconPath}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        {emailError}
      </div>
    </MessageError>
  );

  const texEnvioEmailAviso = (<p> Si deseas que enviemos tus contratos a un email diferente, debes cambiarlo en la siguiente casilla. El cambio será solo para envío de los documentos y no reemplazarán el email que tienes registrado en Coopeuch.</p>);
  const texEnvioEmail = (<p> Al firmar electrónicamente, te enviaremos tus contratos a tu email:</p>);
  const textEmailRegistrado = (<p> Email registrado ({persona.correo})</p>);
  const textOtroEmail = (<p> Enviar a otra cuenta de email</p>);
  const textEmailNoTiene = (<p><b>No tienes un email registrado</b>, ingresa uno para continuar el proceso de contratación. Este email no reemplaza tus datos actuales.</p>);
  const textTerminosCondiciones = (<p> Acepto los términos y condiciones establecidos en los contratos y declaro haberlos leido. Al ingresar la clave de Internet alfanumérica estaré firmando de manera electrónica.</p>);
  const textAutorizoTerminos = (<p>Sí, autorizo</p>);
  const texErrortAutorizo = (<p>Debes autorizar los términos y condiciones para continuar.</p>);
  const textFirmaElectronica = (<p>Firma tus contratos de forma digital con tu clave de internet Coopeuch: <br/><br/>  Al ingresar tu contraseña alfanumérica <b>estarás declarando que has leído los términos y condiciones</b> establecidos en los contratos. De lo contrario puedes cancelar la operación y realizar la contratación en una oficina.</p>);


  return (
    <>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Conoce tus contratos</CardBox.Header>
        <CardBox.Body>
          <div className="row bmb-3 bmb-md-4">
            <div className="col-xs-12">
              <Typography tag="p" fontSize={16}>
                Revisa y firma los siguientes documentos para formalizar tu contratación. Es
                importante que conozcas los detalles de cada uno de ellos, haciendo click en
                descargar contrato.
              </Typography>
            </div>
          </div>

          <div className="row middle-xs ml-0">
            <Paragraph size="18">
              <div className="w-100">
                <LoadIcon
                  className="float-left mr-16"
                  src={`${iconPath}note.svg`}
                  color="#1F1E1E"
                  size={22.5}
                />
                <div className="container_icon_title">
                  <p>
                    Contrato de apertura de “<b>Monedero Digital</b>” y{' '}
                    <b>&nbsp;Poder especial&nbsp;</b> para abono de acreencias
                  </p>
                </div>
              </div>
            </Paragraph>
            <Paragraph marginLeft="38.5" className="mt-16 mb-16">
              Acepta los términos y condiciones de uso establecidos en el contrato de producto
            </Paragraph>
            <Paragraph marginLeft="38.5" className="mb-16">
              <CustomList>
                <li className="mt-8 mb-8">
                  Autorizo a Coopeuch para aperturar en forma inmediata una Cuenta Vista.
                </li>
                <li className="mt-8 mb-8">
                  Aceptas el uso personal e intransferible de claves y dispositivos de seguridad que
                  Coopeuch disponga para autorizar transacciones de tu Cuenta Vista.
                </li>
                <li className="mt-8 mb-8">
                  Autorizas el envío de estados de cuentas y cualquier comunicación vía email.
                </li>
                <li className="mt-8 mb-8">
                  Autorizas a Coopeuch a depositar en tu Cuenta Vista cualquier acreencia que exista
                  a tu nombre
                </li>
              </CustomList>
            </Paragraph>
            <Paragraph marginLeft="38.5" className="mb-16">
              <DownloadLink
                onClick={onDownloadGenericContract}
                text="Descargar contrato"
                href={`${documentsPath}contratacion_monedero/contrato_mandato_monedero.pdf`}
              />
            </Paragraph>
          </div>
          <Paragraph size="18">
            <div className="w-100">
              <LoadIcon
                className="float-left mr-16"
                src={`${iconPath}note.svg`}
                color="#1F1E1E"
                size={22.5}
              />
              <p>Contrato de Prestación de Servicios mediante canales</p>
            </div>
          </Paragraph>
          <Paragraph marginLeft="38.5" className="mt-16 mb-16">
            Habilita la atención y contratación de productos a través de canales remotos como la
            página web, App, Contact center, Cajeros automáticos, etc.
          </Paragraph>
          <Paragraph marginLeft="38.5" className="mb-16">
            <DownloadLink
              onClick={onDownloadGenericContract}
              text="Descargar contrato"
              href={`${documentsPath}contratacion_monedero/contrato_prestacion_servicio_canales_monedero.pdf`}
            />
          </Paragraph>
        </CardBox.Body>
      </CardBox>
      <ContratacionEmail
        persona={persona}
        email={email}
        emailError={emailError}
        emailErrorMessage={emailErrorMessage}
        handleChangeEmail={onHandleEmailInputChange}
        texEnvioEmailAviso={texEnvioEmailAviso}
        texEnvioEmail={texEnvioEmail}
        textEmailRegistrado={textEmailRegistrado}
        textOtroEmail={textOtroEmail}
        textEmailNoTiene={textEmailNoTiene}
      />
      <ContratacionTerminos
        checked={checked}
        handleCheckBox={onHandleCheckBox}
        checkdRequiredError={checkdRequiredError}
        textTerminosCondiciones={textTerminosCondiciones}
        textAutorizoTerminos={textAutorizoTerminos}
        texErrortAutorizo={texErrortAutorizo}
      />
      <CardBox className="bmb-3 bmb-md-4">
        {loadingContratacion ? (
          <CardBox.Body>
            <Loading />
          </CardBox.Body>
        ) : (
          <ContratacionFirmaElectronica
            serviceError={serviceError}
            password={password}
            showPassword={showPassword}
            passwordError={passwordError}
            handleTogglePassword={onTogglePassword}
            handlePasswordInput={onHandlePasswordInput}
            handleWrongPassword={handleWrongPassword}
            serverPasswordError={serverPasswordError}
            wrongPassword={wrongPassword}
            textFirmaElectronica={textFirmaElectronica}
          />
        )}
      </CardBox>
      <StepsActions
        className="bmt-3 bmb-3"
        backAction={volverACaracteristicas}
        backText={'No por ahora'}
        nextAction={contratar}
        disableNextAction={
          !(
            password.length > 0 &&
            passwordError.length === 0 &&
            !blockedPassword &&
            !serviceError &&
            !!email.length &&
            emailError.length === 0
          )
        }
        loading={loadingContratacion}
        id="contratacion-paso-2"
      />
    </>
  );
};

export default Index;
