import React, { useState, useEffect } from 'react';
import Button from './styles.timerpass';
import { formatSecondToMinutes } from '../../../../../utils/formatters';

const TimerPass = ({ counterActive, setErrorTimeOut, setFactorErrorTimeOut, validatePassCoopeuch, setCounterActive }) => {
  let time = 120;
  const [value, setValue] = useState();

  const updateCountDown = () => {
    time--;
    validatePassCoopeuch();
    if (time === 0) {
      setValue('');
      setErrorTimeOut(true);
      setCounterActive(false);
      setFactorErrorTimeOut('passcoopeuch');
    }
    return formatSecondToMinutes(time);
  };

  useEffect(() => {
    // ACTUALIZACION: componentDidUpdate
    let temp;
    if (counterActive === true) {
      temp = setInterval(() => setValue(updateCountDown()), 1000);
    } else {
      clearInterval(temp);
    }
    return () => clearInterval(temp);
  }, [counterActive]);

  return (
    <Button>
      {value ? `${value} para autorizar` : `${formatSecondToMinutes(time)} para autorizar`}
    </Button>
  );
};

TimerPass.displayName = 'CountdownNumbers';
export default TimerPass;
