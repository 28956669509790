import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import Input from '@coopeuch-components/web/atoms/Input';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import DashboardCard from '../../../DashboardCard';

import { isEmailValid } from '../../../../../utils/validators';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

function ConfirmacionEmail(props) {
  const { email, handleChange } = props;

  const rulesError = useMemo(() => !isEmailValid(email) || email?.length <= 0, [email]);

  return (
    <Fragment>
      <DashboardCard classes="dap--card">
        <DashboardCard.Head title="Datos de Contacto" borderBottom="complete" />
        <DashboardCard.Body classes="pt-24 pl-32 pr-32 dap--card--content pb-8">
          <div className="row">
            <div className="col-xs-12">
              <Alert bgType="info" iconType="info" show className="pr-0 alert--wrapper">
                Estos datos no reemplazan tus datos actuales en nuestros registros.
              </Alert>
            </div>
            <div className="col-xs-12 start-xs">
              <p className="ml-4 black mb-24 mt-24">
                Para finalizar, confirma tu información de contacto. En caso contrario ingresa un un
                email.
              </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs black">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                  <div className="ml-4 mr-4 with--help-text">
                    <p className="text-bold black mb-14">Email</p>
                    <div className="editar-destinatarios--password-wrapper">
                      <Input
                        textSize={16}
                        size={48}
                        borderColor={rulesError ? 'red' : '#646569'}
                        onChange={handleChange}
                        value={email}
                        type="text"
                        inputClass="pl-28"
                      />

                      {rulesError && (
                        <div className="error-warning-password row pl-8 mt-2">
                          <LoadIcon src={`${iconPath}circle_close.svg`} size={14} className="red" />
                          <span className="text-size-s red pl-2">
                            {email?.length <= 0
                              ? 'Campo obligatorio'
                              : 'Debes ingresar un email válido'}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DashboardCard.Body>
      </DashboardCard>
    </Fragment>
  );
}

ConfirmacionEmail.propTypes = {
  email: PropTypes.string,
  handleChange: PropTypes.func,
};

ConfirmacionEmail.defaultProps = {
  email: '',
  handleChange: null,
};

export default ConfirmacionEmail;
