import React from 'react';

import DashboardCard from '../../../organism/DashboardCard';
import RadioGroup, { Radio } from '@coopeuch-components/web/atoms/RadioGroup';
import Button from '@coopeuch-components/web/atoms/Button';
import SpinnerLoader from '../../../atoms/SpinnerLoader';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';

import { metodoTexts, errorTexts } from '../../../../containers/RecuperarOCrearClave/texts';

import { WarningMessage } from '../Wrapper/styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const MetodoTemplate = ({
  optionSelected,
  handleChangeOpcions,
  handleClickContinue,
  handleClickRetry,
  existeFactor,
  isLoadingFactoresSeguridad,
  errorFactoresSeguridad,
  errorReglasFactores,
  isLoadingReglasTerceros,
  warningTelefono,
  warningBloqueo,
  isLoadingDesafioSMS,
  isLoadingDatosPersonales,
  isLoadingSelectMetodo,
  errorDatosPersonales,
  type,
}) => {
  return (
    <div>
      <DashboardCard classes="dashboard-card-shadow">
        <DashboardCard.Head
          title={type === 'crear' ? metodoTexts.TITLE_CREAR : metodoTexts.TITLE_RECUPERAR}
          borderBottom="complete"
        />
        <DashboardCard.Body>
          {isLoadingFactoresSeguridad ||
          isLoadingReglasTerceros ||
          isLoadingDesafioSMS ||
          isLoadingDatosPersonales ||
          isLoadingSelectMetodo ? (
            <div className="text-align-center spinner-container">
              <SpinnerLoader></SpinnerLoader>
            </div>
          ) : (
            <div>
              {errorFactoresSeguridad || errorReglasFactores || errorDatosPersonales ? (
                <div>
                  <div className="pl-32 pt-24 pr-32 pb-24">
                    <div className="text-align-center">
                      <LoadIcon src={`${iconPath}ilustracion_error.svg`} className="imagen-error" />
                    </div>
                    <div className="mb-20" />
                    <div className="text-align-center">
                      <label className="error-title">{errorTexts.ERROR_TITLE}</label>
                    </div>
                    <div className="mb-10" />
                    <div className="text-align-center row">
                      <div className="col-md-3" />
                      <div className="col-md-6 pl-20 pr-20">
                        <label className="error-desc">{errorTexts.ERROR_DESC}</label>
                      </div>
                      <div className="col-md-3" />
                    </div>
                    <div className="mb-24" />
                    <div className="text-align-center">
                      <Button bgColor="red" color="white" onClick={handleClickRetry} id="btn-error">
                        {errorTexts.ERROR_BUTTON}
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div>
                    {warningTelefono && optionSelected === '3.1' && (
                      <WarningMessage className="mb-24 mt-24 ml-10 mr-10">
                        <div className="row">
                          <div className="box">
                            <LoadIcon src={`${iconPath}warning_triangle.svg`} size={20} />
                          </div>
                          <div className="col-xs">
                            <p className="ml-16">
                              {metodoTexts.WARNING_TELEFONO} <b>{metodoTexts.PHONE_CALL_CENTER}</b>
                            </p>
                          </div>
                        </div>
                      </WarningMessage>
                    )}
                  </div>
                  <div>
                    {warningBloqueo && optionSelected === '3.1' && (
                      <WarningMessage className="mb-24 mt-24 ml-10 mr-10">
                        <div className="row">
                          <div className="box">
                            <LoadIcon src={`${iconPath}warning_triangle.svg`} size={20} />
                          </div>
                          <div className="col-xs">
                            <p className="ml-16">
                              {metodoTexts.WARNING_BLOQUEO} <b>{metodoTexts.PHONE_CALL_CENTER}</b>
                            </p>
                          </div>
                        </div>
                      </WarningMessage>
                    )}
                  </div>
                  <div className="metodo-card-container">
                    <div>
                      <label className="metodo-card-text">
                        <b>{metodoTexts.DESC_1}</b>{' '}
                        {type === 'crear' ? metodoTexts.DESC_CREAR : metodoTexts.DESC_RECUPERAR}
                      </label>
                    </div>
                    <div className="metodo-radio-group-container">
                      <RadioGroup
                        checkedValue={optionSelected}
                        activedColor="#007DB7"
                        onChange={handleChangeOpcions}
                      >
                        {existeFactor(2.2) && type === 'recuperar' && (
                          <Radio value="2.2" className="metodo-radio-button">
                            {metodoTexts.OPCION_CPASS}
                          </Radio>
                        )}
                        {existeFactor(2.1) && (
                          <Radio value="2.1" className="metodo-radio-button">
                            {metodoTexts.OPCION_TCOO}
                          </Radio>
                        )}
                        {existeFactor(3.0) && (
                        <Radio value="3.1" className="metodo-radio-button">
                          {metodoTexts.OPCION_SMS}
                        </Radio>
                        )}
                      </RadioGroup>
                    </div>
                    <div className="metodo-button-container">
                      <Button
                        bgColor="#E81E2B"
                        color="white"
                        id="btn"
                        width="276"
                        className="button-style-crear-clave"
                        disabled={
                          !optionSelected ||
                          (optionSelected === '3.1' && (warningTelefono || warningBloqueo))
                        }
                        onClick={handleClickContinue}
                      >
                        {metodoTexts.BUTTON_LABEL}
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </DashboardCard.Body>
      </DashboardCard>
    </div>
  );
};
export default MetodoTemplate;
