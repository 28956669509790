import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [windowSize, setwindowSize] = useState('');

  const handleResize = () => setwindowSize(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // initial State
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
};

export default useWindowSize;
