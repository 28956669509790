import React from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const ListaNegraError = () => {
  return (
    <div className="row bmt-3 bmt-md-4">
      <div className="col-xs-12">
        <CardBox className="bpy-md-4 bpx-mb-2rem bp-md-3  text-center">
          <CardBox.Body>
            <LoadIcon src={`${iconPath}info-desktop.svg`} />
            <div className="row">
              <div className="col-xs-12 col-md-6 col-md-offset-3">
                <Typography className="text-center bmy-3 " fontSize={24} fontWeight="bold" tag="p">
                  Necesitamos más información para solicitar tu Cuenta Vista
                </Typography>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 col-md-6 col-md-offset-3">
                <Typography className="text-center" fontSize={18} tag="p">
                  Contáctanos llamando al 600 200 1200.
                </Typography>
              </div>
            </div>
          </CardBox.Body>
        </CardBox>
      </div>
    </div>
  );
};

export default ListaNegraError;
