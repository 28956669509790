import Styled from "styled-components";

export const DownloadLink = Styled.div`
  & > a {
    color: #EC1B2B;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    font-weight: bold;
  }
`
