import styled from 'styled-components';

export const RegisteredEmail = styled.div`
    font-size: 14px;
    margin-top: 8px;
    margin-left: -20px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
     margin-bottom: 24px;
     margin-left: -12px;
  }

    .email {
        font-size: 14px;
        font-weight: 700;
        color: #383838;
        margin-left: 6px;

        @media (max-width: 768px) {
            margin-top: 6px;
            margin-left: 0;
        }
    }
    `;

export const AnotherEmailText = styled.div`
    font-size: 14px;
    margin-top: 2px;
    margin-left: -20px;

    @media (max-width: 768px) {
            margin-top: 6px;
            margin-left: -12px;
        }

    .email {
        font-size: 14px;
        font-weight: 700;
        color: #383838;
        margin-left: 6px;
    }
    `;