import styled from 'styled-components';

const Wrapper = styled.div`
  .icon-sello-container {
    align-self: center;
    text-align: right;
  }

  .icon-sello-container svg {
    width: 114px;
    height: 114px;
  }

  .comprobante-card-list {
    padding: 12px 16px 12px 16px;
  }

  .card-impar {
    background-color: #f6f3f5;
  }
  .card-par {
    background-color: #ffffff;
  }

  .comprobante-card-text {
    color: #383638;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }

  .comprobante-title-texts {
    color: #383638;
    font-family: 'Ubuntu-Medium';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
  }

  .comprobante-numero-texts {
    color: #383638;
    font-size: 24px;
    font-family: 'Ubuntu-Medium';
    font-weight: bold;
    letter-spacing: 0;
    line-height: 32px;
  }

  .comprobante-desc-texts {
    color: #383638;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }

  @media screen and (max-width: 1024px) {
    .icon-sello-container {
      align-self: center;
      text-align: left;
    }
    .comprobante-card-list {
      padding: 12px 8px 12px 8px;
    }
  }
`;

export default Wrapper;
