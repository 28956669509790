import CardBox from '@coopeuch-components/web/molecules/CardBox';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Wrapper } from './styles';

export const SkeletonComprobante = () => (
  <>
    <Wrapper className="display-desktop">
      <div className="bmt-3">
        <div className="row bmt-3 bmb-3 bmb-md-4">
          <div className="col-md-12">
            <Skeleton height={48} className="bmr-2" />
          </div>
        </div>
        <CardBox classes="dashboard-card-shadow">
          <CardBox.Header>
            <div className='row'>
              <div className='col-md-6'>
                <Skeleton width={269} height={10} />
              </div>
              <div className='col-md-6 d-flex end-xs'>
                <Skeleton width={24} height={10} className="bmr-2" />
                <Skeleton width={68} height={10} />
              </div>
            </div>
          </CardBox.Header>
          <CardBox.Body>
            <div className='row'>
              <div className='col-md-7 col-xs-12'>
                <div className="row m-0">
                  <div className="col-md-6 col-xs-12 bmb-3 bmb-md-3 bmy-md-3 bmb-md-1">
                    <Skeleton width={99} height={10} className="bmr-2" />
                    <Skeleton width={129} height={10} className="bmr-2" />
                  </div>
                  <div className="col-md-6  col-xs-12 bmb-3 bmb-md-3 bmy-md-3 bmb-md-1">
                    <Skeleton width={99} height={10} className="bmr-2" />
                    <Skeleton width={129} height={10} className="bmr-2" />
                  </div>
                  <div className="col-md-6 col-xs-12 bmb-3 bmb-md-3 bmy-md-3 bmb-md-1">
                    <Skeleton width={99} height={10} className="bmr-2" />
                    <Skeleton width={129} height={10} className="bmr-2" />
                  </div>
                  <div className="col-md-6 col-xs-12 bmb-3 bmb-md-3 bmy-md-3 bmb-md-1">
                    <Skeleton width={99} height={10} className="bmr-2" />
                    <Skeleton width={129} height={10} className="bmr-2" />
                  </div>
                </div>
              </div>
              <div className="col-md-5 col-xs-12 d-flex end-xs">
                <Skeleton height={112} width={112} className="bmr-2" circle />
              </div>
            </div>
          </CardBox.Body>
        </CardBox>
      </div>
      <div className="bmt-4">
        <div className="row">
          <div className="col-md-6 skeleton-center-vertical">
            <Skeleton width={19} height={10} className="bmr-2" />
            <Skeleton width={109} height={10} />
          </div>
        </div>
      </div>
    </Wrapper>
  </>
);

export default SkeletonComprobante;
