import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmailValid } from '../../../utils/validators';
import { downloadPDFBase64 } from '../../../utils/functions';
// componentes
import CobrarRemanenteTemplate from '../../../components/templates/Remanente/CobrarRemanente';
import { remanenteActions } from '../../../../application/actions/remanente';
import { registerGA } from '../../../utils/metrics';
import logger from '../../../utils/logger';

const initState = {
  accountsAvailable: null,
  accountsType: '',
  selectedAccount: null,
  steps: ['Autorizar pago', 'Comprobante'],
  currentStep: 1,
  checked: false,
  secondEmail: '',
  secondEmailError: '',
  showCobrarDespuesModal: false,
  showEnviarComprobanteModal: false,
  showEnviarComprobanteErrorModal: false,
  showComprobanteEnviadoModal: false,
  loadingCobrar: false,
  errorCobroRemanente: false,
};
class ContratoMonedero extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }
  componentDidMount = async () => {
    const { fetchProducts, fetchTarjetas } = this.props;
    registerGA({
      event: 'vpv-privado',
      page: '/tef/remanente/cobro/paso1A',
    });
    fetchProducts({
      codigoProducto: ['VI01', 'VI02', 'VI03', 'VI06'],
      estadoCuenta: ['A', 'I', 'D'],
    });
    fetchTarjetas({});
  };
  componentDidUpdate(prevProps) {
    const { productosDisponibles, cobrarRemanenteData, comprobanteCobro } = this.props;
    if (
      productosDisponibles !== prevProps.productosDisponibles &&
      !!productosDisponibles.cuentas &&
      productosDisponibles.tarjetas
    ) {
      this.handleSetProductosDisponibles(productosDisponibles);
    } else if (cobrarRemanenteData !== prevProps.cobrarRemanenteData) {
      this.handleCobroMonederoResponse(cobrarRemanenteData);
    } else if (comprobanteCobro !== prevProps.comprobanteCobro && comprobanteCobro !== null) {
      downloadPDFBase64(comprobanteCobro, 'Comprobante Cobro Remanente');
    }

    const {
      isLoadingObtenerCuentasRemanente,
      isLoadingObtenerTarjetasRemanente,
      persona,
      errorLogObtenerCuentas,
      errorLogObtenerTarjetas,
      isLoadingCobrarRemanente,
      errorCobrarRemanenteLog,
      isLoadingComprobanteCobroRemanente,
      errorComprobanteCobroLog,
      emailComprobante,
      emailComprobanteError,
      clearEnvio,
    } = this.props;

    if (
      prevProps.isLoadingObtenerCuentasRemanente !== isLoadingObtenerCuentasRemanente ||
      prevProps.isLoadingObtenerTarjetasRemanente !== isLoadingObtenerTarjetasRemanente
    ) {
      if (!isLoadingObtenerCuentasRemanente && !isLoadingObtenerTarjetasRemanente) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorLogObtenerCuentas || errorLogObtenerTarjetas, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'REMANENTE-COBRO P1',
          informacion_adicional: {},
        });
      }
    }

    if (prevProps.isLoadingCobrarRemanente !== isLoadingCobrarRemanente) {
      if (!isLoadingCobrarRemanente) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorCobrarRemanenteLog, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: errorCobrarRemanenteLog ? 'REMANENTE-COBRO P1' : 'REMANENTE-COBRO P2',
          informacion_adicional: errorCobrarRemanenteLog
            ? {}
            : { monto: cobrarRemanenteData?.monto },
        });
      }
    }

    if (prevProps.isLoadingComprobanteCobroRemanente !== isLoadingComprobanteCobroRemanente) {
      if (!isLoadingComprobanteCobroRemanente) {
        const baseRut = String((persona?.rut || '').replace('-', ''));
        logger(errorComprobanteCobroLog, {
          rut: baseRut.slice(0, -1),
          digito_verificador: baseRut.slice(-1),
          accion: 'REMANENTE-COBRO P2',
          informacion_adicional: { monto: cobrarRemanenteData?.monto },
        });
      }
    }

    if (prevProps.emailComprobante !== emailComprobante) {
      this.setState({
        showComprobanteEnviadoModal: true,
        showEnviarComprobanteErrorModal: false,
        showEnviarComprobanteModal: false,
      })
      clearEnvio();
    }
    if (prevProps.emailComprobanteError !== emailComprobanteError) {
      this.setState({
        showEnviarComprobanteErrorModal: true,
        showComprobanteEnviadoModal: false,
        showEnviarComprobanteModal: false,
      })
      clearEnvio();
    }

  }

  handleSetProductosDisponibles = (productos) => {
    const cvActivas = productos.cuentas.filter(
      (cuenta) =>
        ['VI01', 'VI02', 'VI03'].includes(cuenta.codigoProducto) && cuenta.estadoCuenta === 'A'
    );
    const cvInactivasHabilitadas = productos.cuentas.filter(
      (cuenta) =>
        ['VI01', 'VI02', 'VI03'].includes(cuenta.codigoProducto) &&
        (cuenta.estadoCuenta === 'I' || cuenta.estadoCuenta === 'D') &&
        this.checkActiveCard(cuenta.numeroCuenta, productos.tarjetas)
    );
    const monedero = productos.cuentas.filter((cuenta) => cuenta.codigoProducto === 'VI06');
    let aux = {};
    Object.assign(aux, cvInactivasHabilitadas[0]);
    aux.numeroCuenta = 1;
    aux.fechaApertura = '2019-02-20';
    const cvHabilitadas = [...cvActivas, ...cvInactivasHabilitadas].sort((a, b) =>
      new Date(a.fechaApertura) < new Date(b.fechaApertura) ? 1 : -1
    );
    if (cvHabilitadas.length) {
      const options = cvHabilitadas.map((cv) => ({
        label: `Vista: N° ${cv.numeroCuenta}`,
        value: cv.numeroCuenta,
      }));
      this.setState({
        accountsAvailable: options,
        selectedAccount: options[0],
        accountsType: 'CV',
      });
    } else if (monedero.length) {
      const options = monedero.map((m) => ({
        label: `Monedero: N° ${m.numeroCuenta}`,
        value: m.numeroCuenta,
      }));
      this.setState({
        accountsAvailable: options,
        selectedAccount: options[0],
        accountsType: 'MONEDERO',
      });
    } else {
      this.setState({
        errorCobroRemanente: true,
      });
    }
  };
  checkActiveCard = (accountId, cards) => {
    const accountCards = cards.filter(
      (card) =>
        card.numeroCuenta === accountId &&
        card.codEstado === '01' &&
        ['', 'O', 'S', 'N'].includes(card.bloqueo)
    );
    return accountCards.length;
  };
  onChangeAccount = (e) => {
    const { accountsAvailable } = this.state;
    const selectedAccount = accountsAvailable.filter(
      (account) => account.value === parseInt(e.target.value, 10)
    )[0];
    this.setState({
      selectedAccount,
    });
  };
  handleCobroMonederoResponse = (cobrarRemanente) => {
    if (cobrarRemanente.error) {
      this.setState({
        loadingCobrar: false,
        errorCobroRemanente: true,
      });
    } else if (cobrarRemanente.success) {
      this.setState(
        {
          loadingCobrar: false,
        },
        () => {
          const { accountsType: type } = this.state;
          registerGA({
            event: 'vpv-privado',
            page: '/tef/remanente/cobro/comprobanteA',
            monto: `${cobrarRemanente.monto}`,
            producto: type === 'MONEDERO' ? 'Monedero Digital' : 'Cuenta Vista Coopeuch',
          });
          this.toNextStep();
        }
      );
    }
  };

  onHandleCheckBox = () => {
    this.setState((prevState) => ({
      checked: !prevState.checked,
    }));
  };
  toHome = () => {
    this.props.history.push('/ecd/inicio');
  };
  toNextStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };
  toRemanente = () => {
    this.props.onGoBackToRemanente();
  };

  continueStep1 = () => {
    const { cobrarRemanente } = this.props;
    const { selectedAccount } = this.state;
    this.setState(
      {
        loadingCobrar: true,
      },
      () => cobrarRemanente(selectedAccount.value)
    );
  };
  openSendEmailModal = () => {
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: true,
    });
  };

  handleSendComprobanteEmail = () => {
    const { fetchEnvioEmail } = this.props;
    const correo = this.state.secondEmail;
    fetchEnvioEmail({ correo, flujoId: 1 })
  };

  onHandleChangeEmail = (e) => {
    const value = e.target.value;
    const errorMessage = 'Ingresa un email válido';
    this.setState({
      secondEmail: value,
      secondEmailError: isEmailValid(value) ? '' : errorMessage,
    });
  };

  onHandleSendComprobante = () => {
    const { secondEmail } = this.state;
    console.log(`enviando comprobante al email ${secondEmail}`);
    this.setState({
      secondEmail: '',
      secondEmailError: '',
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: true,
    });
  };
  onHandleDownloadComprobante = () => {
    const { fetchComprobante, comprobanteCobro } = this.props;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/comprobanteA',
      'eve-cat': 'Click',
      'eve-lab': 'Descargar',
    });
    if (comprobanteCobro !== null) {
      downloadPDFBase64(comprobanteCobro, 'Comprobante Cobro Remanente');
    } else {
      fetchComprobante({ flujoId: 1 });
    }
  };
  onHandleSalirSinCobrar = () => {
    this.setState({
      showCobrarDespuesModal: true,
    });
  };
  closeAllModals = () => {
    this.setState({
      showCobrarDespuesModal: false,
      showEnviarComprobanteModal: false,
      showComprobanteEnviadoModal: false,
      showEnviarComprobanteErrorModal: false,
    });
  };

  backStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso1A',
      'eve-cat': 'Click',
      'eve-lab': 'No por ahora',
    });
    this.onHandleSalirSinCobrar();
  };
  nextStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/paso1A',
      'eve-cat': 'Click',
      'eve-lab': 'Aceptar',
    });
    this.continueStep1();
  };
  backStep2 = () => {
    this.toHome();
  };
  nextStep2 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/remanente/cobro/comprobanteA',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a Remanente',
    });
    this.toRemanente();
  };

  render() {
    const {
      accountsAvailable,
      selectedAccount,
      accountsType,
      checked,
      steps,
      currentStep,
      secondEmail,
      secondEmailError,
      showCobrarDespuesModal,
      showEnviarComprobanteModal,
      showEnviarComprobanteErrorModal,
      showComprobanteEnviadoModal,
      loadingCobrar,
      errorCobroRemanente,
    } = this.state;
    const { cobrarRemanenteData } = this.props;
    return (
      <CobrarRemanenteTemplate
        {...{
          accountsAvailable,
          selectedAccount,
          accountsType,
          checked,
          steps,
          currentStep,
          secondEmail,
          secondEmailError,
          showCobrarDespuesModal,
          showEnviarComprobanteModal,
          showEnviarComprobanteErrorModal,
          showComprobanteEnviadoModal,
          cobrarRemanenteData,
          loadingCobrar,
          errorCobroRemanente,
        }}
        onHandleCheckBox={this.onHandleCheckBox}
        onChangeAccount={this.onChangeAccount}
        backStep1={this.backStep1}
        nextStep1={loadingCobrar ? null : this.nextStep1}
        backStep2={this.backStep2}
        nextStep2={this.nextStep2}
        onHandleChangeEmail={this.onHandleChangeEmail}
        openSendEmailModal={this.openSendEmailModal}
        onHandleSendComprobante={this.onHandleSendComprobante}
        onHandleDownloadComprobante={this.onHandleDownloadComprobante}
        closeModal={this.closeAllModals}
        handleSendComprobanteEmail={this.handleSendComprobanteEmail}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  cobrarRemanenteData: store.entities.remanente.cobrarRemanente,
  comprobanteCobro: store.entities.remanente.comprobanteCobro,
  productosDisponibles: store.entities.remanente.productosDisponibles,
  isLoadingObtenerCuentasRemanente: store.ui.loaders.isLoadingObtenerCuentasRemanente,
  isLoadingObtenerTarjetasRemanente: store.ui.loaders.isLoadingObtenerTarjetasRemanente,
  persona: store.entities.persona,
  errorLogObtenerCuentas: store.entities.remanente.errorLogObtenerCuentas,
  errorLogObtenerTarjetas: store.entities.remanente.errorLogObtenerTarjetas,
  isLoadingCobrarRemanente: store.ui.loaders.isLoadingCobrarRemanente,
  errorCobrarRemanenteLog: store.entities.remanente.errorCobrarRemanenteLog,
  errorComprobanteCobroLog: store.entities.remanente.errorComprobanteCobroLog,
  isLoadingComprobanteCobroRemanente: store.ui.loaders.isLoadingComprobanteCobroRemanente,
  emailComprobante: store.entities.remanente.emailComprobante,
  emailComprobanteError: store.entities.remanente.emailComprobanteError
});

const mapDispatchToProps = (dispatch) => ({
  fetchProducts(props) {
    dispatch(remanenteActions.fetchProductosDisponibles(props));
  },
  fetchTarjetas(props) {
    dispatch(remanenteActions.fetchTarjetasProductosDisponibles(props));
  },
  fetchComprobante(props) {
    dispatch(remanenteActions.fetchComprobanteCobro(props));
  },
  cobrarRemanente(props) {
    dispatch(remanenteActions.cobrarRemanente(props));
  },
  fetchEnvioEmail(props) {
    dispatch(remanenteActions.fetchEnvioEmail(props));
  },
  clearEnvio(props) {
    dispatch(remanenteActions.clearEnvio(props));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ContratoMonedero));
