const initState = {
  fatcaSuccess: false,
  fatcaError: null,
  contratosCuentaAhorro: [],
  errorContratosCuentaAhorro: false,
  errorDataContratosCuentaAhorro: {},
  borradorCuentaAhorro: '',
  errorBorradorCuentaAhorro: false,
  errorDataBorradorCuentaAhorro: {},
  migrarCuentaAhorro: false,
  errorMigrarCuentaAhorro: false,
  errorDataMigrarCuentaAhorro: {},
};

export default initState;
