import styled from 'styled-components';

export const BankAccount = styled.span`
  @media screen and (max-width: 769px) {
    float: left;
    width: 100%;
  }
`;

const InfoContent = styled.div`
  align-items: center;
  display: flex;

  & > div {
    float: inherit;
  }
`;
export const SinRemanenteWrapper = styled.div`
  svg {
    height:100px;
  }
`
export default InfoContent;
