export const validacionClaveTexts = {
  LABEL_BUTTON_SMS: 'Validar transacción',
  LABEL_DESC_TARJETA_COORDENADAS:
    'Haz click en el botón para validar con tu Tarjeta de Coordenadas Coopeuch y completar esta operación.',
  LABEL_BUTTON_TARJETA_COORDENADAS: 'Autorizar',
  ERROR_TITLE: '¡Lo sentimos!',
  ERROR_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  ERROR_BUTTON: 'Volver a intentar',
  LABEL_DESC_COOPEUCH_PASS:
    'Haz click en el botón para validar en la aplicación PassCoopeuch de tu celular y completar esta operación.',
  LABEL_BUTTON_COOPEUCH_PASS: 'Autorizar con PassCoopeuch',
};

export const errorTexts = {
  ERROR_TITLE: 'Lo sentimos',
  ERROR_DESC:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  ERROR_BUTTON: 'Reintentar',
};

export const metodoTexts = {
  TITLE_CREAR: 'Método para crear clave',
  TITLE_RECUPERAR: 'Método para recuperar clave',
  DESC_1: 'Selecciona un método',
  DESC_CREAR: 'de validación para crear tu clave:',
  DESC_RECUPERAR: 'de validación para recuperar tu clave:',
  OPCION_TCOO: 'Tarjeta de Coordenadas',
  OPCION_SMS: 'SMS',
  OPCION_CPASS: 'PassCoopeuch',
  BUTTON_LABEL: 'Continuar',
  WARNING_TELEFONO:
    'No tienes ningún teléfono asociado a tu Cuenta, por favor contáctate al Contact Center al',
  WARNING_BLOQUEO: 'Tu número está bloqueado, por favor contáctate al Contact Center al',
  PHONE_CALL_CENTER: '600 200 1200',
};

export const validacionTexts = {
  TITLE_SMS: 'Autorizar con SMS',
  TIMEOUT_SMS:
    'Código de seguridad ha cadudado. Has superado el límite de tiempo para la utilización.',
  DESC_1: 'Te enviamos un SMS con el código a tu celular',
  DESC_2: '+569 **** **',
  DESC_3: 'Si este no es tu número, por favor contáctate al Contact Center al ',
  DESC_4: '600 200 1200',
  RETRY_LINK_SMS: 'Aún no recibo el código, enviar nuevamente',
  TITLE_TCOOR: 'Validación con Tarjeta de Coordenadas ',
  TITLE_CPASS: 'Autorizar con PassCoopeuch',
};
