import React from 'react';
import PropTypes from 'prop-types';
//
import CardBox from '@coopeuch-components/web/molecules/CardBox'

const ExpandBody = ({ children }) => <CardBox.Body className="bpt-0">{children}</CardBox.Body>;

ExpandBody.propTypes = {
  children: PropTypes.node,
};

ExpandBody.defaultProps = {
  children: null,
};

export default ExpandBody;
