import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Badge from '@coopeuch-components/web/atoms/Badge';
import Skeleton from 'react-loading-skeleton';
import Button from '@coopeuch-components/web/atoms/Button';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import DashboardCard from '../../../DashboardCard';

import Wrapper from './styles';
import { registerGA } from '../../../../../utils/metrics';
import { capitalize, formatCurrency } from '../../../../../utils/functions';
import { dateFormat } from '../../../../../utils/dates';
import { addClass } from '@coopeuch-components/web';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const iconNames = {
  Renovable: 'ico_renovable',
  Fijo: 'ico_fijo',
};

function DepositoContratado(props) {
  const {
    montoVencimiento,
    numeroDeposito,
    fechaVencimiento,
    liquidar,
    descripcionTipoDeposito,
    itIsTheLast,
  } = props;

  const { push, location } = useHistory();

  const handleToGoResumen = useCallback(() => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo',
      'eve-cat': 'Click',
      'eve-lab': 'Ver detalle',
    });
    push({ pathname: `${location.pathname}/deposito-contratado`, state: { ...props } });
  }, [push, location, props]);

  return (
    <Wrapper className="w-100" itIsTheLast={itIsTheLast}>
      <DashboardCard classes="container hide-on-mobile">
        <div className="d-flex start-xs">
          <div className="mr-8">
            <LoadIcon
              src={`${iconPath}${iconNames[descripcionTipoDeposito]}.svg`}
              staticIcon
              size={30}
            />
          </div>

          <div className="w-100">
            <div className="d-flex between-xs">
              <p className="text-size-s">
                <span className="text-bold">{descripcionTipoDeposito} </span>
                vencimiento {fechaVencimiento ? capitalize(dateFormat(fechaVencimiento)) : ''}
              </p>
            </div>

            <div>
              {liquidar && (
                <Badge
                  color="#fff"
                  borderRadius={12}
                  bgColor="rgba(0,93,143,0.75)"
                  text="Disponible para retiro"
                  classes="pl-6 pr-6 pb-2 mt-8"
                />
              )}
            </div>

            <p className="text-size-s mb-2 mt-8">N° {numeroDeposito}</p>

            <div className="d-flex middle-xs between-xs">
              <div className="text-size-s">
                Monto al vencimiento: <b>{formatCurrency(montoVencimiento)}</b>
              </div>

              <Button asTextLink onClick={handleToGoResumen}>
                <div className="row middle-xs">
                  <span className="red-link text-size-s text-bold">Detalle</span>
                  <LoadIcon src={`${iconPath}arrow_right.svg`} size={22} className="red-link" />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </DashboardCard>

      <DashboardCard classes="container hide-on-desktop">
        <div className="d-flex start-xs">
          <div className="mr-4">
            <LoadIcon src={`${iconPath}${iconNames[descripcionTipoDeposito]}.svg`} size={24} />
          </div>

          <div className="w-100">
            <p className="text-size-s">
              <b>{descripcionTipoDeposito}</b> vencimiento{' '}
              {fechaVencimiento ? capitalize(dateFormat(fechaVencimiento)) : ''}
            </p>
            {liquidar && (
              <Badge
                color="#fff"
                borderRadius={12}
                bgColor="rgba(0,93,143,0.75)"
                text="Disponible para retiro"
                classes={addClass(['mb-8 mt-8', 'pl-6 pr-6 pb-2'])}
              />
            )}

            <p className={addClass(['text-size-s mb-2', !liquidar && 'mt-8'])}>
              N° {numeroDeposito}
            </p>

            <div className="d-flex middle-xs between-xs">
              <div className="text-size-s d-flex">
                Monto al vencimiento:{' '}
                <span className="text-bold pl-2">{formatCurrency(montoVencimiento)}</span>
              </div>

              <Button asTextLink onClick={handleToGoResumen} className="pl-0 pr-0">
                <LoadIcon src={`${iconPath}arrow_right.svg`} size={22} className="red-link" />
              </Button>
            </div>
          </div>
        </div>
      </DashboardCard>
    </Wrapper>
  );
}

function SkeletonLoading() {
  return (
    <Wrapper>
      <DashboardCard classes="pl-32 pr-32 pt-24 pb-24 bmb-3 bmb-md-4 container">
        <div className="d-flex">
          <div>
            <Skeleton circle height={35} width={35} />
          </div>
          <div className="ml-8 w-100">
            <div className="mb-8">
              <div>
                <Skeleton height={10} width="60%" className="hide-on-mobile" />
              </div>
              <Skeleton height={10} width="100%" className="hide-on-desktop" />
            </div>
            <div className="mb-8">
              <Skeleton height={10} width="40%" className="hide-on-mobile" />
              <Skeleton height={10} width="100%" className="hide-on-desktop" />
            </div>
            <div className="d-flex">
              <div className="w-100">
                <Skeleton height={10} width="65%" className="hide-on-mobile" />
                <Skeleton height={10} width="90%" className="hide-on-desktop" />
              </div>

              <div className="d-flex hide-on-mobile">
                <Skeleton height={10} width={60} />
                <div className="ml-8">
                  <Skeleton height={10} width={15} />
                </div>
              </div>

              <div className="hide-on-desktop">
                <Skeleton height={10} width={20} />
              </div>
            </div>
          </div>
        </div>
      </DashboardCard>
    </Wrapper>
  );
}

DepositoContratado.propTypes = {
  id: PropTypes.number,
  montoVencimiento: PropTypes.number,
  numeroDeposito: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fechaVencimiento: PropTypes.string,
  descripcionTipoDeposito: PropTypes.string,
  liquidar: PropTypes.bool,
};

DepositoContratado.defaultProps = {
  id: null,
  montoVencimiento: null,
  numeroDeposito: '',
  fechaVencimiento: null,
  descripcionTipoDeposito: '',
  liquidar: false,
};

DepositoContratado.Skeleton = SkeletonLoading;

export default DepositoContratado;
