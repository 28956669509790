import React from 'react';

import Button from '@coopeuch-components/web/atoms/Button';
// Atoms
import Typography from '@coopeuch-components/web/atoms/Typography';
// Styles
import AlertModalWrapper, { CustomList } from './styles';
import CheckBox from '@coopeuch-components/web/atoms/CheckBox';


const ModalConsentimiento = ({ visibleModal, handleClickCloseModalCerrar, handleClickModalContinuar, checked, handleCheckBox }) => {

    const textAutorizoTerminos = (<p className='textAutorizo'> Leí y estoy de acuerdo con el <b>Consentimiento para el Tratamiento de Datos Personales</b></p>);
    return (
        <AlertModalWrapper showModal={visibleModal} width={997} onClose={handleClickCloseModalCerrar}>
                <div className="bmt-3">
                    <Typography tag="p" fontSize={24} fontWeight="bold">
                        Consentimiento para el tratamiento de datos personales
                    </Typography>
                </div>
                <hr />
                <div className='scroll'>
                    <div>
                        <Typography tag="p" fontSize={14} className="textConsentimiento">
                            Te informamos que de acuerdo con la <b>Ley 19.628 “Sobre Protección de la Vida Privada”</b>, sus modificaciones, normas complementarias o que la reemplacen, la <b>“Cooperativa de Ahorro y Crédito Coopeuch”</b>, en adelante, <b>“Coopeuch”</b>, realizará el tratamiento de tus datos personales compartidos en la <b>“Solicitud de Bonos de Estudio”</b>, para las siguientes finalidades:
                        </Typography>
                    </div>
                    <div className="bmb-3">
                        <Typography tag="p" fontSize={14} fontWeight="bold">
                            <CustomList className="mt-20">
                                <li>Evaluar si cumples con los requisitos necesarios para postular al “Bono de Estudio”.</li>
                                <li>Enviarte notificaciones con respecto al estado del proceso de postulación, así como también, para contactarte en casos de dudas o consultas respecto de la documentación entregada.</li>
                                <li>Realizar consultas a fuentes externas de organismos públicos o privados para revisar vigencia de cédula de identidad y/o verificaciones necesarias para la evaluación del proceso de postulación.</li>
                                <li>Utilizarlos para fines estadísticos internos de Coopeuch relacionados con el proceso de postulación.</li>
                                <li>Enviarte encuestas de experiencia relacionadas con el proceso de postulación al “Bono de Estudio”.</li>
                                <li>Enviarte comunicaciones comerciales y/o de marketing relacionadas con productos, servicios, incentivos y/o beneficios que puedan ser de tu interés o se ajusten a tus necesidades.</li>
                                <li>Comunicar tus datos personales con empresas filiales o con las cuales Coopeuch tenga alianzas comerciales para enviarte descuentos en comercios, promociones, o cualquier beneficio que pueda ser de tu interés. </li>
                            </CustomList>
                        </Typography>
                    </div>
                    <div className="bmb-3">
                        <Typography tag="p" fontSize={14} className="textConsentimiento">
                            A su vez, te informamos que podrás disponer en todo momento de los derechos de acceso, rectificación, cancelación u oposición (en caso de ser aplicables) de tus datos personales por el tiempo que los mismos estén disponibles para ejercer dichos derechos y los demás que establece la Ley 19.628 “Sobre Protección de la Vida Privada”, normas complementarias o que la reemplace y/o modifique en el futuro.  <br /><br />
                            Estos derechos los podrás ejercer a través de la presentación de una solicitud o requerimiento por escrito en cualquiera de nuestras sucursales, o a través de los canales de contacto que habilitemos y/o comuniquemos en el futuro para los efectos de ejercer los derechos antes indicados. <br /><br />
                            Coopeuch se limitará a tratar tus datos personales solo para las finalidades indicadas en el presente consentimiento, y estarán disponibles durante el tiempo necesario para cumplir con los fines para los cuales fueron recabados, así como también, para determinar las posibles responsabilidades que se pudieran derivar de la finalidad; además de los períodos establecidos en las disposiciones regulatorias, legales y/o normativas. <br /><br />
                            En el evento que mi solicitud de postulación al “Bono de Estudio” fuese rechazada, este consentimiento quedará sin efecto.
                        </Typography>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-9 col-12'>
                        <CheckBox
                            labelClassName='checkboxTex'
                            name="checked"
                            className="checkbox-element"
                            text={textAutorizoTerminos}
                            id="input-auth-contratacion-monedero"
                            fontSize={16}
                            isChecked={checked}
                            onChange={handleCheckBox}
                        />
                    </div>
                    <div className='col-md-3 col-12 btnAceptar'>
                        <Button
                            bgColor="#E81E2B"
                            color="white"
                            disabled={!checked}
                            onClick={handleClickModalContinuar}
                            className="button-modal"
                        >
                            Aceptar
                        </Button>
                    </div>
                </div>
        </AlertModalWrapper >
    );
};

export default ModalConsentimiento;
