import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 24px 3rem 24px 3rem;

    @media (max-width: 768px) {
    padding: 24px 1.5rem
  }

  .textLegal{
  line-height: 20px;
  }

  .tituloDatos{
    margin: 0 0 16px 0;
    line-height: 20px;
  }

 .textosAutorizacion{
  font-size: 14px;
  line-height: 20px;
  }

  .textAutorizo{
    font-size: 14px;
  }

  @media (min-width: 768px) {
    #autorizacion-next-action{
      width: 160px;
    }
  }

`;


export const MessageError = styled.div`
  width: 100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;