import Styled from 'styled-components';

export const PasswordWrapper = Styled.div`

.login--icon-lock {
    position: absolute;
    top: 56px;
    z-index: 2;
    left: 12px;
  }

  .input--rut {
    small {
      display: none;
    }
  }

  .input--password {
    position: relative;
    margin: 0;

    input {
      padding-left: 35px;
    }

    div:last-child {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .input--error .password-error{
    div:last-child {
      top: 82px;
      left: 4px;
    }
  }

  .login--warpper-input {
    position: relative;
    span {
      color: #383638;
      position: absolute;
      bottom: 5px;
    }

    .login--wrapper-checkbox {
      width: 100%;
      display: flex;
      margin-left: -6px;
      margin-top: -12px;
      margin-bottom: 12px;
    }
  }

  button,
  input[type='text'],
  input[type='password'] {
    width: 100%;
    height: 48px;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

 

  @media screen and (max-width: 766px) {
   
  } 
`;
export const MessageError = Styled.div`
  width:100%;
  & div {
    margin: 0;
  }
  div {
    div {
      float: left
    }
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;
