import styled from 'styled-components';

export const TabsWrapper = styled.div`
  .tab--wrapper-shadows {
    width: 100%;
    position: absolute;
  }
  .padding-title {
    padding-top: 50px;
  }
  .tabs-item {
    padding: 0;
    font-family: Ubuntu-Regular;

    a {
      padding: 8px;
      height: 100%;
      text-decoration: unset;
      color: unset;
      display: block;
    }
  }
`;
