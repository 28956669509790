import { fork } from 'redux-saga/effects';

// Sagas
import { PoolActFinancieraSaga } from '@coopeuch-project/react-micro-actfinanciera';
import loginSaga from './login';
import layoutSaga from './layout';
import productsSaga from './products';
import remanenteSaga from './remanente';
import transferenciasSaga from './transferencias';
import transferenciaEntreProductos from './transferenciaEntreProductos';
import factoresSeguridadSaga from './factoresSeguridad';
import monederoSaga from './monedero';
import cuentaAhorroSaga from './cuentaAhorro';
import migracionCuentaAhorroSaga from './migracionCuentaAhorro';
import creditoHipotecarioSaga from './creditoHipotecario';
import abonoAcreenciasRemanenteSaga from './abonoAcreenciasRemamente';
import personaSaga from './persona';
import creditoSaga from './credito';
import creditoConsumoSimuladorSaga from './creditoConsumoSimulador';
import convenioSaga from './convenio';
import cuotaParticipacionSaga from './cuotaParticipacion';
import capitalizacionSaga from './capitalizacion';
import indicatorEconomico from './indicatorEconomico';
import tipo from './tipo';
import solicitudCuentaVista from './solicitudCuentaVista';
import historialTransferencias from './historialTransferencias';
import gestionClave from './gestionClave';
import tarjetaCredito from './tarjetaCredito';
import listaNegra from './listaNegra';
import configuracion from './configuracion';
import certificadosSII from './certificadosSII';
import seguros from './seguros';
import beneficios from './beneficios';
import depositoAPlazo from './depositoAPlazo';
import activacionTarjetas from './activacionTarjetas';
import activacionTarjetasKit from './activacionTarjetasKit';
import empleadorSaga from './empleador';
import tarjetaCoordenada from './tarjetaCoordenada';
import ate from './ate';
import campanacrm from './campanacrm';
import upgradeMonedero from './upgradeMonedero';
import ftu from './ftu';
import { PoolReliquidacionSaga } from '@coopeuch-project/react-micro-reliquidacion';
import puntos from './puntos';
import { PoolBarraEcommerceSaga } from '@coopeuch-project/barra-ecommerce';
import perfilComercial from './perfilComercial';
import { PoolFormalizacionSaga } from '@coopeuch-project/react-micro-formalizacion';
import avanceTarjetaCredito from './avanceTarjetaCredito';
import bonoEscolar from './bonoEscolar';
import norma484 from './norma484';
import aliviotc from './aliviotc';
import certProdVigentes from './certificadosProdVigentes';
import desbloqueoTd from './desbloqueoTd';

// General forking sagas.
export default function* rootSaga() {
  yield fork(loginSaga);
  yield fork(layoutSaga);
  yield fork(productsSaga);
  yield fork(remanenteSaga);
  yield fork(transferenciasSaga);
  yield fork(transferenciaEntreProductos);
  yield fork(factoresSeguridadSaga);
  yield fork(monederoSaga);
  yield fork(cuentaAhorroSaga);
  yield fork(migracionCuentaAhorroSaga);
  yield fork(creditoHipotecarioSaga);
  yield fork(abonoAcreenciasRemanenteSaga);
  yield fork(personaSaga);
  yield fork(creditoSaga);
  yield fork(empleadorSaga);
  yield fork(creditoConsumoSimuladorSaga);
  yield fork(convenioSaga);
  yield fork(cuotaParticipacionSaga);
  yield fork(capitalizacionSaga);
  yield fork(indicatorEconomico);
  yield fork(tipo);
  yield fork(solicitudCuentaVista);
  yield fork(historialTransferencias);
  yield fork(gestionClave);
  yield fork(tarjetaCredito);
  yield fork(listaNegra);
  yield fork(configuracion);
  yield fork(certificadosSII);
  yield fork(seguros);
  yield fork(beneficios);
  yield fork(depositoAPlazo);
  yield fork(activacionTarjetas);
  yield fork(activacionTarjetasKit);
  yield fork(PoolActFinancieraSaga);
  yield fork(tarjetaCoordenada);
  yield fork(ate);
  yield fork(campanacrm);
  yield fork(upgradeMonedero);
  yield fork(ftu);
  yield fork(PoolReliquidacionSaga);
  yield fork(puntos);
  yield fork(PoolBarraEcommerceSaga);
  yield fork(perfilComercial);
  yield fork(PoolFormalizacionSaga);
  yield fork(bonoEscolar);
  yield fork(avanceTarjetaCredito);
  yield fork(norma484);
  yield fork(aliviotc);
  yield fork(certProdVigentes);
  yield fork(desbloqueoTd);
  
}
