import React, { useRef } from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Button from '@coopeuch-components/web/atoms/Button';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import ButtonBack from '../../../../organism/ActivacionTarjetas/ButtonBack';
import Input from '../../../../atoms/Input';
import TimerTC from '../TimerButton/timerTC';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetas/texts';
import { regexOnlyNumber } from '../../../../../utils/formatters';
// Styles
import Wrapper, { CardFooter } from './styles';
import InputContainer, { ButtonContainer } from './styles';
import { useEffect } from 'react';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardSMS = ({
  handleClickSendSMS,
  handleClickSendTC,
  counterActive,
  setCounterActive,
  setErrorTimeOut,
  setFactorErrorTimeOut,
  errorTimeOut,
  persona,
  backToActivacion,
  handleSwitchMethods,
  stepSolicitartarjetaCoordenadas,
  valorDesafio1,
  valorDesafio2,
  valorDesafio3,
  hasError,
  solicitarTarjetaCoordenadas,
  handleSetTC,
  availablePassCoopeuch,
  onChangeInput,
  setHablitarBtn,
  hablitarBtn,
  handleClickRetry,
  handleClickValidateTC,
  validarCoordenadas,
  errorIngresoCoordenadas,
  disabledInput
}) => {
  let refCoor1 = useRef();
  let refCoor2 = useRef();
  let refCoor3 = useRef();

  function handleChange(e) {
    const { id, value } = e.target;

    if (id === 'cor1') {
      if (value.length === 1 && value.charCodeAt() > 47 && value.charCodeAt() < 58) {
        refCoor1.current.focus();
      } else if (value.length > 1 && value.charCodeAt() > 12 && value.charCodeAt() < 143) {
        console.log('cor1', id, value);
        handleSetTC(id, value);
        refCoor2.current.focus();
      } else {
        handleSetTC(id, '');
        e.target.value = '';
      }
    }

    if (id === 'cor2') {
      if (value.length === 1 && value.charCodeAt() > 47 && value.charCodeAt() < 58) {
        refCoor2.current.focus();
      } else if (value.length > 1 && value.charCodeAt() > 12 && value.charCodeAt() < 143) {
        console.log('cor2', id, value);
        handleSetTC(id, value);
        refCoor3.current.focus();
      } else {
        handleSetTC(id, '');
        e.target.value = '';
      }
    }

    if (id === 'cor3') {
      if (value.length === 1 && value.charCodeAt() > 47 && value.charCodeAt() < 58) {
        refCoor3.current.focus();
      } else if (value.length > 1 && value.charCodeAt() > 12 && value.charCodeAt() < 143) {
        console.log('cor3', id, value);
        handleSetTC(id, value);
      } else {
        handleSetTC(id, '');
        e.target.value = '';
      }
    }
  }

  const refresh = () => window.location.reload(true);

  return (
    <Wrapper>
      <CardBox className="bmt-md-4 bmt-3">
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Autorización con Tarjeta de Coordenadas {availablePassCoopeuch?.data[0].payload}
          </Typography>
        </CardBox.Header>
        <CardBox.Body>
          <div className="contentSMS">
            {errorTimeOut === true ||
            errorIngresoCoordenadas === true ||
            validarCoordenadas?.error?.code === 'TD-40'? (
              <LoadIcon
                src={`${iconPath}ilustracion_error.svg`}
                className="contentSMS__image"
                size={162}
              />
            ) : (
              <LoadIcon
                src={`${iconPath}tarjeta_coordenadas.svg`}
                className="contentSMS__image"
                size={162}
              />
            )}
            { validarCoordenadas?.error?.code === 'TD-40' ||
              errorIngresoCoordenadas === true  &&
              (
                <b className="contentSMS__title">¡Lo sentimos!</b>
              )}
            {errorTimeOut !== false && errorIngresoCoordenadas === false ? <b className="contentSMS__title">¡Se agotó el tiempo!</b> : null}
            {errorTimeOut === true && errorIngresoCoordenadas === false ? (
              <p className="contentSMS__text">
                Tienes un tiempo limitado para autorizar. Inténtalo nuevamente
              </p>
            ) : errorIngresoCoordenadas === true ? null : (
              <p className="contentSMS__text">
                Haz click en el botón para validar con tu tarjeta de Coordenadas Coopeuch y
                completar esta operación.
              </p>
            )}
            {validarCoordenadas?.error?.code === 'TD-40' && (
              <p className="contentSMS__text">
                Tú tarjeta de coordenadas está bloqueada, Solicítala nuevamente en App Coopeuch.
              </p>
            )}
            {errorIngresoCoordenadas === true  && (
              <p className="contentSMS__text">
                Las coordenadas no corresponden. Por favor, intenta nuevamente
              </p>
            )}
            {stepSolicitartarjetaCoordenadas !== false ? (
              <>
                <Wrapper>
                  <div className="row mb-10">
                    <div className="col-md-4 col-xs-4 pl-12 pr-12">
                      <div className="factor-coordenadas-label-container">
                        <label>{solicitarTarjetaCoordenadas?.data?.desafio1}</label>
                      </div>
                      <div>
                        <input
                          disabled={disabledInput}
                          id="cor1"
                          dataSet="1"
                          borderColor={
                            errorTimeOut
                              ? 'red'
                              : valorDesafio1?.length === 2
                              ? '#19a1d5 '
                              : '#646569'
                          }
                          maxLength={2}
                          type="password"
                          className="factor-coordenadas-input"
                          value={valorDesafio1}
                          onChange={handleChange}
                          ref={refCoor1}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-4 pl-12 pr-12">
                      <div className="factor-coordenadas-label-container">
                        <label>{solicitarTarjetaCoordenadas?.data?.desafio2}</label>
                      </div>
                      <div>
                        <input
                          disabled={disabledInput}
                          borderColor={
                            errorTimeOut
                              ? 'red'
                              : valorDesafio1?.length === 2
                              ? '#19a1d5 '
                              : '#646569'
                          }
                          id="cor2"
                          dataSet="2"
                          maxLength={2}
                          type="password"
                          className="factor-coordenadas-input"
                          value={valorDesafio2}
                          onChange={handleChange}
                          ref={refCoor2}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-xs-4 pl-12 pr-12">
                      <div className="factor-coordenadas-label-container">
                        <label>{solicitarTarjetaCoordenadas?.data?.desafio3}</label>
                      </div>
                      <div>
                        <input
                          disabled={disabledInput}
                          borderColor={
                            errorTimeOut
                              ? 'red'
                              : valorDesafio1?.length === 2
                              ? '#19a1d5 '
                              : '#646569'
                          }
                          id="cor3"
                          dataSet="3"
                          maxLength={2}
                          type="password"
                          className="factor-coordenadas-input"
                          value={valorDesafio3}
                          onChange={handleChange}
                          ref={refCoor3}
                        />
                      </div>
                    </div>
                  </div>
                </Wrapper>
              </>
            ) : null}
            {stepSolicitartarjetaCoordenadas === false ? (
              <Button
                bgColor="#E81E2B"
                color="white"
                fontSize={14}
                className="contentSMS__btn"
                onClick={handleClickSendTC}
              >
                Autorizar
              </Button>
            ) : errorTimeOut === true ||
            errorIngresoCoordenadas === true ? (
              <Button
                bgColor="#E81E2B"
                color="white"
                fontSize={14}
                className="contentSMS__btn"
                onClick={handleClickRetry}
              >
                Reintentar
              </Button>
            ) : hablitarBtn === true ? (
              <Button
                bgColor="#E81E2B"
                color="white"
                fontSize={14}
                className="contentSMS__btn"
                onClick={handleClickValidateTC}
              >
                Autorizar
              </Button>
            ) : (
              <TimerTC
                className="contentSMS__btn"
                {...{
                  stepSolicitartarjetaCoordenadas,
                  // counterActive,
                  // setCounterActive,
                  setErrorTimeOut,
                  setFactorErrorTimeOut,
                  errorTimeOut,
                  errorIngresoCoordenadas
                }}
              />
            )}
          </div>
        </CardBox.Body>
        <CardFooter>
          <button type="button" onClick={() => handleSwitchMethods('tarjetacoord')}>
            Quiero autorizar con PassCoopeuch
          </button>
        </CardFooter>
      </CardBox>
      <div className="row">
        <div className="col-md-12">
          <ButtonBack
            onClick={() => backToActivacion(activacionTexts.BUTTON_RETURN, 'tarjetacoord')}
            link="/ecd/seguridad/activacion-tarjetas"
            text={activacionTexts.BUTTON_RETURN}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default CardSMS;
