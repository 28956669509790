import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Alert from '@coopeuch-components/web/atoms/Alert';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Table from '@coopeuch-components/web/molecules/Table';
import StepsActions from '../../../../../organism/Stepper/StepsActions';
import { Paragraph, Wrapper, ActionButtons } from './styles';
import { agregarformatoPesos } from '../../../../../../utils/formatters';
import './styles.css';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const Index = ({
  remanenteCobrado = false,
  monto,
  fecha,
  email,
  cuenta,
  backButton,
  nextButton,
  openSendEmailModal,
  downloadComprobante,
  getContratoCanales,
  getContratoMonedero,
}) => {
  const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;
  const comprobante = `${staticPath}sello-comprobante.png`;

  const contractData = [
    {
      id: 0,
      name: 'Producto contratado',
      value: 'Monedero Digital',
    },
    {
      id: 1,
      name: 'N° Monedero',
      value: cuenta,
    },
    {
      id: 2,
      name: 'Fecha de contratación',
      value: fecha,
    },
    {
      id: 3,
      name: 'Estado',
      value: 'Activo',
    },
  ];
  return (
    <Wrapper>
      <Alert
        className="alert-comprobante-contratacion-monedero bp-3 bmb-3 bmb-md-4"
        bgType="success"
        id="alert-felicitaciones-contratacion-monedero"
        show
      >
        <React.Fragment>
          <b>¡Felicitaciones!</b> Haz contratado tu <b>Monedero Digital.</b> Ahora debes transferir
          saldo a tu cuenta y capitalizar tu Remanente.
        </React.Fragment>
      </Alert>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>
          <div className="row">
            <div className="col-xs-6">Comprobante</div>
            <div className="col-xs-6 text-right">
              <ActionButtons>
                <div className="comprobante-action bmr-3" onClick={downloadComprobante}>
                  <LoadIcon
                    className="mr-2"
                    src={`${iconPath}download_arrow.svg`}
                    color="#F42534"
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text">Descargar</div>
                </div>
                {/* <div className="comprobante-action" onClick={openSendEmailModal}>
                  <LoadIcon
                    className="mr-2"
                    src={`${iconPath}email.svg`}
                    color="#F42534"
                    size={22.5}
                  />
                  <div className="d-md-block d-none action-button-text">Enviar</div>
                </div> */}
              </ActionButtons>
            </div>
          </div>
        </CardBox.Header>
        <CardBox.Body>
          <div className="row">
            <div className="col-xs-12 col-md-8 bmb-md-3 comprobante-card-text">
              <div className="text-wrapper">
                <Paragraph className="mb-2" size={14}>
                  <b>{remanenteCobrado ? `Remanente pagado` : `Monedero Digital contratado`}</b>
                </Paragraph>
                <Paragraph className="mb-2" size={24}>
                  <b>{remanenteCobrado ? agregarformatoPesos(monto) : `N° ${cuenta}`}</b>
                </Paragraph>
                <Paragraph size={14}>
                  <React.Fragment>
                    Contrataste Monedero Digital con fecha {fecha}. 
                    {/* Enviaremos el comprobante y
                    contratos a tu mail: <b>{email}</b>. */}
                  </React.Fragment>
                </Paragraph>
              </div>
            </div>
            <div className="col-xs-12 col-md-4">
              <img className="bm-auto bmr-md-0" src={comprobante} alt="comprobante" />
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Detalle de Contratación de Monedero Digital</CardBox.Header>
        <CardBox.Body>
          <Table
            borderColor="trasparent"
            fullWidth
            alignContent="left"
            stripeColor="#F6F3F5"
            stripeReverse
          >
            <Table.Body
              data={contractData}
              renderItems={(item, TR, TD) => {
                return (
                  <TR key={`detalle-contrato-monedero-${item.id}`}>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.name}
                    </TD>
                    <TD className="td-table-element-contratacion-monedero pt-16 pb-16 pl-8 pr-8">
                      {item.value}
                    </TD>
                  </TR>
                );
              }}
            ></Table.Body>
          </Table>
        </CardBox.Body>
      </CardBox>
      <CardBox className="bmb-3 bmb-md-4">
        <CardBox.Header>Descarga tus contratos</CardBox.Header>
        <CardBox.Body>
          <div className="row bmt-3">
            <div className="col-xs-12 col-md-6 contract-action-button bmb-3 bmb-md-0" onClick={getContratoMonedero}>
              <LoadIcon
                className="mr-2"
                src={`${iconPath}download_arrow.svg`}
                color="#F42534"
                size={22.5}
              />
              Contrato de apertura de Monedero
            </div>
            <div className="col-xs-12 col-md-6 contract-action-button" onClick={getContratoCanales}>
              <LoadIcon
                className="mr-2"
                src={`${iconPath}download_arrow.svg`}
                color="#F42534"
                size={22.5}
              />
              Contrato de Canales
            </div>
          </div>
        </CardBox.Body>
      </CardBox>
      <StepsActions
        className="bmb-3 bmb-md-4"
        backAction={backButton}
        backText={'Ir a home'}
        nextText={'Ir a Remanente'}
        nextAction={nextButton}
        id="contratacion-monedero-comprobante"
      />
    </Wrapper>
  );
};

export default Index;
