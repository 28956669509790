import styled from 'styled-components';

export const CheckboxWrapper = styled.div`
  label {
    line-height: 20px;
  }
  .checkbox-label {
    margin-top: 10px;
    color: #383638;
    font-size: 14px;
  }
`;
export const CapPanel = styled.div`
  background-color: #22315c;
  color: white;
  border-radius: 5px;
  p {
    text-align: left;
  }
  @media (min-width: 64em) {
    height:100%;
    p {
      text-align: center;
    }
  }
  .col-element{
    display: flex;
    flex-flow: column;
  }
`;

export const AlertWrapper = styled.div`
  .alert-warning {
    height: auto;
  }
  svg {
    height:20px;
    width: 20px;
  }
`;

export const MessageError = styled.div`
  width:100%;
  & div {
    margin: 0;
  }

  & svg {
    fill: #e81e2b;

    path {
      fill: #e81e2b;
    }
  }
`;