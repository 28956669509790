import styled from 'styled-components';

const BaseCard = styled.div`
  background-color: #fff;
  width: 100%;

  padding: ${({ padding }) => `${padding}px`};
  margin: ${({ margin }) => margin};
  ${({ borderColor }) => borderColor && `border: 1px solid ${borderColor}`};
  ${({ rounded }) =>
    rounded &&
    `
      border-radius: 5px;
    `}

  box-shadow: 0 2px 4px 0 rgba(31, 30, 30, 0.1);
`;

export default BaseCard;
