import React from 'react';

const CalendarIcon = () => (
    <svg width="20px" height="20px" viewBox="0 0 32 32">
        <title>Calendar</title>
        <g id="Calendar" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="00.Token/Icons/Solid/16x16/eye-open" fill="#007DB7">
                <path d="M10.0510067,1.96266667 L10.0510067,5.97333333 C10.0510067,6.52709084 9.59908474,6.976 9.04161074,6.976 L7.02281879,6.976 C6.46534479,6.976 6.01342282,6.52709084 6.01342282,5.97333333 L6.01342282,1.96266667 C6.01342282,1.40890916 6.46534479,0.96 7.02281879,0.96 L9.04161074,0.96 C9.59908474,0.96 10.0510067,1.40890916 10.0510067,1.96266667 Z M0.966442953,28.032 C0.966442953,29.6932725 2.32220886,31.04 3.99463087,31.04 L28.2201342,31.04 C29.8925562,31.04 31.2483221,29.6932725 31.2483221,28.032 L31.2483221,12.992 L0.966442953,12.992 L0.966442953,28.032 Z M28.2201342,3.968 L28.2201342,5.97333333 C28.2201342,7.63460586 26.8643683,8.98133333 25.1919463,8.98133333 L23.1731544,8.98133333 C21.5007324,8.98133333 20.1449664,7.63460586 20.1449664,5.97333333 L20.1449664,3.968 L12.0697987,3.968 L12.0697987,5.97333333 C12.0697987,7.63460586 10.7140327,8.98133333 9.04161074,8.98133333 L7.02281879,8.98133333 C5.35039678,8.98133333 3.99463087,7.63460586 3.99463087,5.97333333 L3.99463087,3.968 C2.32220886,3.968 0.966442953,5.31472747 0.966442953,6.976 L0.966442953,10.9866667 L31.2483221,10.9866667 L31.2483221,6.976 C31.2483221,5.31472747 29.8925562,3.968 28.2201342,3.968 Z M25.1919463,0.96 L23.1731544,0.96 C22.6156804,0.96 22.1637584,1.40890916 22.1637584,1.96266667 L22.1637584,5.97333333 C22.1637584,6.52709084 22.6156804,6.976 23.1731544,6.976 L25.1919463,6.976 C25.7494203,6.976 26.2013423,6.52709084 26.2013423,5.97333333 L26.2013423,1.96266667 C26.2013423,1.40890916 25.7494203,0.96 25.1919463,0.96 Z" id="color-icono" />
            </g>
        </g>
    </svg>
);

export default CalendarIcon;
