import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import InputLabel from '@coopeuch-components/web/molecules/InputLabel';
import DashboardCard from '../../../DashboardCard';
import ServiceError from '../../ServiceError';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const errMessages = {
  4: {
    title: 'Clave Bloqueada.',
    htmlMessage: `<span class='black'>Por tu seguridad, hemos bloqueado tu clave. Si lo deseas <br /> puedes volver a cambiar tu clave.</span>`,
    gridClass: 'col-md-8',
    buttonText: 'Cambiar clave',
  },
  3: {
    title: 'Datos incorrectos',
    htmlMessage: `<span class='black'>La constraseña ingresada es incorrecta, por favor vuelve a intentar. Recuerda que <b>al tercer intento erróneo</b>, tu clave será bloqueada</span>`,
    gridClass: 'col-md-8',
  },
  2: {
    htmlMessage: `<span class='black'>La constraseña ingresada es incorrecta. <br /> Por favor vuelve a intentar.</span>`,
  },
};

function IconoOjo({ passwordInputIcon }) {
  return (
    <LoadIcon
      src={`${iconPath}${passwordInputIcon}.svg`}
      size={20}
      color="black"
      className="eye-icon"
    />
  );
}

function ConfirmacionPassword(props) {
  const {
    password,
    showPassword,
    handlePassword,
    handlePasswordVisibility,
    errorCode,
    clearPasswordStates,
  } = props;

  const passwordInputIcon = useMemo(() => `eye_${showPassword ? 'open' : 'close'}`, [showPassword]);
  const rulesError = useMemo(() => password?.length < 6 && password?.length >= 1, [password]);

  return (
    <Fragment>
      <DashboardCard classes="dap--card">
        <DashboardCard.Head title="Firma electrónica" borderBottom="complete" />
        <DashboardCard.Body classes="pt-24 pl-32 pr-32 dap--card--content">
          {!!errorCode ? (
            <ServiceError {...errMessages?.[errorCode]} onClick={clearPasswordStates} />
          ) : (
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 start-xs black">
                <div className="row">
                  <div className="col-xs-12">
                    <p className="ml-4 mb-24">
                      Valida la transacción con tu clave de internet Coopeuch
                    </p>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                    <div className="ml-4 mr-4 with--help-text ">
                      <p className="text-bold mb-14">Ingresa tu clave de Internet</p>
                      <div className="editar-destinatarios--password-wrapper">
                        <LoadIcon
                          src={`${iconPath}lock.svg`}
                          size={20}
                          color="#1F1E1E"
                          className="lock-icon"
                        />
                        <InputLabel
                          textSize={16}
                          size={48}
                          borderColor={rulesError ? 'red' : '#646569'}
                          onChange={handlePassword}
                          value={password}
                          type={showPassword ? 'text' : 'password'}
                          inputClass="pl-28"
                          placeholder="•••••••"
                          iconFunc={handlePasswordVisibility}
                          iconRight={<IconoOjo passwordInputIcon={passwordInputIcon} />}
                          maxLength={8}
                        />
                        {rulesError ? (
                          <div className="error-warning-password row pl-8">
                            <LoadIcon
                              src={`${iconPath}circle_close.svg`}
                              size={14}
                              className="red"
                            />
                            <span className="text-size-s red pl-2">
                              La clave debe tener entre 6 y 8 caract.
                            </span>
                          </div>
                        ) : (
                          <span className="text-size-s help-text pl-2">Cantidad de caracteres</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </DashboardCard.Body>
      </DashboardCard>
    </Fragment>
  );
}

ConfirmacionPassword.propTypes = {
  password: PropTypes.string,
  showPassword: PropTypes.bool,
  handlePassword: PropTypes.func,
  handlePasswordVisibility: PropTypes.func,
  errorCode: PropTypes.number,
};

ConfirmacionPassword.defaultProps = {
  password: '',
  showPassword: false,
  handlePassword: null,
  handlePasswordVisibility: null,
  errorCode: null,
};

export default ConfirmacionPassword;
