import React, { Component } from 'react';
import { connect } from 'react-redux';

import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';

import ComprobanteTemplate from '../../../components/templates/DepositoAPlazo/Comprobante';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';

class Comprobante extends Component {
  handleContinue = () => {
    const { history, match, fetchDepositosIsCompleted } = this.props;
    fetchDepositosIsCompleted({ isCompleted: false });
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/mis-productos/deposito-a-plazo/paso-5',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a Deposito a Plazo',
    });
    history.push(`${match?.url}`);
  };

  componentDidMount() {
    loguearRutUsuario('DAP_SIMULADOR-P5');
    const { simulacionSeleccionada, fetchComprobanteInvertir } = this.props;
    const params = {
      identificacionSimulacion: simulacionSeleccionada?.identificacionSimulacion,
    };
    fetchComprobanteInvertir({ params });
    registerGA({
      event: 'vpv-privado',
      page: '/tef/mis-productos/deposito-a-plazo/paso-5',
      monto: simulacionSeleccionada?.montoInversion,
      tasa_interes_mensual: simulacionSeleccionada?.tasaBase,
      producto: simulacionSeleccionada?.tipoDepositoPlazo,
    });
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
  }

  render() {
    return (
      <ComprobanteTemplate {...this.props} {...this.state} handleContinue={this.handleContinue} />
    );
  }
}

const mapStateToProps = ({ entities, ui }) => ({
  comprobanteInvertir: entities.depositoAPlazo.comprobanteInvertir,
  comprobanteInvertirError: entities.depositoAPlazo.comprobanteInvertirError,
  isLoadingDAPComprobanteInvertir: ui.loaders.isLoadingDAPComprobanteInvertir,
});

const mapDispatchToProps = (dispatch) => ({
  fetchComprobanteInvertir(params) {
    dispatch(depositoAPlazoActions.fetchComprobanteInvertir(params));
  },
  fetchDepositosIsCompleted(params) {
    dispatch(depositoAPlazoActions.fetchDepositosIsCompleted(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Comprobante);
