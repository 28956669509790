import { put, takeLatest, call, spawn } from 'redux-saga/effects';

import { depositoAPlazoActions } from '../../../application/actions/depositoAPlazo';
import { loadersActions } from '../../../application/actions/ui_loaders';

import api from '../../api';
import defaultRequest, { errClaveInternet, errSMSFactor } from './errors';
import { logReqError } from '../../../ui/utils/functions';
import { loguearErrorFlujo } from '../../../ui/utils/formateoParametriaLog';

const apiDepositoAPlazo = api(process.env.REACT_APP_DEPOSITO_PLAZO);
const apiDepositoAPlazoSimulador = api(process.env.REACT_APP_DEPOSITO_PLAZO_SIMULADOR);
const apiDepositoAPlazoOtp = api(process.env.REACT_APP_DEPOSITO_PLAZO_OTP);

export function* fetchDepositoConfiguracionSimular() {
  try {
    yield put(loadersActions.fetchLoadingDAPSimulacion({ isLoading: true }));

    const { data, error } = yield call(apiDepositoAPlazoSimulador.get, 'configuracion/obtener');

    if (data) {
      yield put(depositoAPlazoActions.fetchDepositoConfiguracionSimularSuccess({ rules: data }));
      yield put(loadersActions.fetchLoadingDAPSimulacion({ isLoading: false }));
    } else {
      loguearErrorFlujo(error,'DAP_SIMULADOR-P1');
      yield logReqError(error, 'GET');
      yield put(depositoAPlazoActions.fetchDepositoConfiguracionSimularFailed());
      yield put(loadersActions.fetchLoadingDAPSimulacion({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_SIMULADOR-P1');
    yield put(depositoAPlazoActions.fetchDepositoConfiguracionSimularFailed());
    yield put(loadersActions.fetchLoadingDAPSimulacion({ isLoading: false }));
  }
}

export function* fetchDepositosContratados(action) {
  try {
    const { estado } = action.payload || {};

    yield put(depositoAPlazoActions.fetchDepositosIsCompleted({ isCompleted: false }));
    yield put(loadersActions.fetchLoadingDepositosContratados({ isLoading: true }));

    const { data, error } = yield call(apiDepositoAPlazo.post, 'obtener', {
      estado,
    });

    if (data) {
      yield put(
        depositoAPlazoActions.fetchDepositosContratadosSuccess({
          depositos: data,
        })
      );
      yield put(loadersActions.fetchLoadingDepositosContratados({ isLoading: false }));
      yield put(depositoAPlazoActions.fetchDepositosIsCompleted({ isCompleted: true }));
    } else {
      loguearErrorFlujo(error,'DAP-INGRESO-INFORMACION');
      yield logReqError(error);
      yield put(depositoAPlazoActions.fetchDepositosContratadosFailed());
      yield put(loadersActions.fetchLoadingDepositosContratados({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP-INGRESO-INFORMACION');
    yield put(depositoAPlazoActions.fetchDepositosContratadosFailed());
    yield put(loadersActions.fetchLoadingDepositosContratados({ isLoading: false }));
  }
}

export function* fetchReglasLiquidacion() {
  try {
    yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: true }));

    const { data, error } = yield call(apiDepositoAPlazo.get, 'configuracion/obtener');

    if (data) {
      yield put(depositoAPlazoActions.fetchReglasLiquidacionSuccess({ reglas: data }));
      yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
    } else {
      loguearErrorFlujo(error,'DAP-INGRESO-DETALLE-INFORMACION')
      yield logReqError(error, 'GET');
      yield put(depositoAPlazoActions.fetchReglasLiquidacionFailed());
      yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP-INGRESO-DETALLE-INFORMACION')
    yield put(depositoAPlazoActions.fetchReglasLiquidacionFailed());
    yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
  }
}

export function* fetchOtpDesafio(action) {
  try {
    const { numeroDeposito } = action.payload || {};

    yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: true }));

    const { data, error } = yield call(
      apiDepositoAPlazoOtp.get,
      `desafio/obtener/${numeroDeposito}`
    );

    if (data) {
      yield put(depositoAPlazoActions.fetchOtpDesafioSuccess());
      yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
    } else {
      const objError =
        error?.code === '4' ? errSMSFactor[3] : errSMSFactor[error?.code] || defaultRequest;
        loguearErrorFlujo(error,'DAP_LIQUIDAR-P1')

      yield logReqError(objError, 'GET');
      yield put(depositoAPlazoActions.fetchOtpDesafioFailed({ error: objError }));
      yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_LIQUIDAR-P1')
    yield put(depositoAPlazoActions.fetchOtpDesafioFailed());
    yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
  }
}

export function* liquidarDepositoContratado(action) {
  try {
    const { intentos, ...body } = action.payload;

    yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: true }));

    const { data, error } = yield call(apiDepositoAPlazo.post, 'liquidar', body);

    if (data) {
      yield put(depositoAPlazoActions.liquidarDepositoContratadoSuccess());
      yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
    } else {
      const { clave } = body || {};

      const claveErrors =
        error?.code === '1'
          ? errClaveInternet[4]
          : error?.code === '3' && intentos === 3
          ? errClaveInternet[2]
          : errClaveInternet[error?.code];

      const smsErrors =
        error?.code === '4'
          ? errSMSFactor[3]
          : error?.code === '1' && Number(intentos) === 2
          ? errSMSFactor[4]
          : errSMSFactor[error?.code];
          loguearErrorFlujo(error,'DAP_LIQUIDAR-P1');
      yield logReqError(clave ? claveErrors : smsErrors || defaultRequest);
      yield put(
        depositoAPlazoActions.liquidarDepositoContratadoFailed({
          error: (clave ? claveErrors : smsErrors) || defaultRequest,
        })
      );

      yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_LIQUIDAR-P1');
    yield put(depositoAPlazoActions.liquidarDepositoContratadoFailed());
    yield put(loadersActions.fetchLoadingLiquidarDAP({ isLoading: false }));
  }
}

export function* fetchComprobanteLiquidar(action) {
  try {
    const { ...body } = action.payload;

    yield put(loadersActions.fetchLoadingComprobanteLiquidar({ isLoading: true }));

    const { data, error } = yield call(apiDepositoAPlazo.post, 'liquidar/detalle/obtener', body);

    if (data) {
      yield put(depositoAPlazoActions.fetchComprobanteLiquidarSuccess({ detalles: data }));

      yield put(loadersActions.fetchLoadingComprobanteLiquidar({ isLoading: false }));
    } else {
      loguearErrorFlujo(error,'DAP_LIQUIDAR-P2');
      yield logReqError(error);
      yield put(depositoAPlazoActions.fetchComprobanteLiquidarFailed());
      yield put(loadersActions.fetchLoadingComprobanteLiquidar({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_LIQUIDAR-P2');
    yield put(depositoAPlazoActions.fetchComprobanteLiquidarFailed());
    yield put(loadersActions.fetchLoadingComprobanteLiquidar({ isLoading: false }));
  }
}

export function* fetchSimulacionMultiple(action) {
  try {
    const { params } = action.payload;

    yield put(loadersActions.fetchLoadingSimulacionMultiple({ isLoading: true }));

    const { data } = yield call(apiDepositoAPlazoSimulador.post, 'multiple/simular', params);

    if (data) {
      yield put(
        depositoAPlazoActions.fetchSimulacionMultipleSuccess({
          simulaciones: data?.simulacionResponses,
        })
      );
      yield put(loadersActions.fetchLoadingSimulacionMultiple({ isLoading: false }));
    } else {
      yield put(depositoAPlazoActions.fetchSimulacionMultipleFailed());
      yield put(loadersActions.fetchLoadingSimulacionMultiple({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_SIMULADOR-P2');
    yield put(depositoAPlazoActions.fetchSimulacionMultipleFailed());
    yield put(loadersActions.fetchLoadingSimulacionMultiple({ isLoading: false }));
  }
}

export function* seleccionarSimulacion(action) {
  try {
    const { idSimulacion } = action.payload;

    yield put(loadersActions.seleccionarSimulacionDAPLoading({ isLoading: true }));

    const { data } = yield call(
      apiDepositoAPlazoSimulador.get,
      'opcion/seleccionar/' + idSimulacion
    );

    if (data) {
      yield put(
        depositoAPlazoActions.seleccionarSimulacionSuccess({ seleccionarSimulacion: data })
      );
      yield put(loadersActions.seleccionarSimulacionDAPLoading({ isLoading: false }));
    } else {
      yield put(depositoAPlazoActions.seleccionarSimulacionFailed());
      yield put(loadersActions.seleccionarSimulacionDAPLoading({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_SIMULADOR-P2');
    yield put(depositoAPlazoActions.seleccionarSimulacionFailed());
    yield put(loadersActions.seleccionarSimulacionDAPLoading({ isLoading: false }));
  }
}

export function* fetchDAPInvertir(action) {
  try {
    const { params } = action.payload;

    yield put(loadersActions.fetchLoadingDAPInvertir({ isLoading: true }));
    yield put(depositoAPlazoActions.fetchDAPInvertirReset());

    const { data, error } = yield call(apiDepositoAPlazo.post, 'invertir', params);

    if (data) {
      yield put(depositoAPlazoActions.fetchDAPInvertirSuccess({ invertirDAP: data }));
      yield put(loadersActions.fetchLoadingDAPInvertir({ isLoading: false }));
    } else {
      loguearErrorFlujo(error,'DAP_SIMULADOR-P4');
      yield put(depositoAPlazoActions.fetchDAPInvertirFailed({ code: error.code }));
      yield put(loadersActions.fetchLoadingDAPInvertir({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_SIMULADOR-P4');
    yield put(depositoAPlazoActions.fetchDAPInvertirFailed({ invertirDAPErrorCode: 0 }));
    yield put(loadersActions.fetchLoadingDAPInvertir({ isLoading: false }));
  }
}

export function* fetchComprobanteInvertir(action) {
  try {
    const { params } = action.payload;
    yield put(loadersActions.fetchLoadingDAPComprobanteInvertir({ isLoading: true }));

    const { data } = yield call(apiDepositoAPlazo.post, 'inversion/detalle/obtener', params);

    if (data) {
      yield put(depositoAPlazoActions.fetchComprobanteInvertirSuccess({ comprobante: data }));
      yield put(loadersActions.fetchLoadingDAPComprobanteInvertir({ isLoading: false }));
    } else {
      yield put(depositoAPlazoActions.fetchComprobanteInvertirFailed());
      yield put(loadersActions.fetchLoadingDAPComprobanteInvertir({ isLoading: false }));
    }
  } catch (err) {
    loguearErrorFlujo(err,'DAP_SIMULADOR-P5')
    yield put(depositoAPlazoActions.fetchComprobanteInvertirFailed());
    yield put(loadersActions.fetchLoadingDAPComprobanteInvertir({ isLoading: false }));
  }
}

export function* watchFetchDepositoConfiguracionSimular() {
  yield takeLatest(
    depositoAPlazoActions.fetchDepositoConfiguracionSimular.type,
    fetchDepositoConfiguracionSimular
  );
}

export function* watchFetchSimulacionMultiple() {
  yield takeLatest(depositoAPlazoActions.fetchSimulacionMultiple.type, fetchSimulacionMultiple);
}

export function* watchFetchDepositosContratados() {
  yield takeLatest(depositoAPlazoActions.fetchDepositosContratados.type, fetchDepositosContratados);
}

export function* watchFetchOtpDesafio() {
  yield takeLatest(depositoAPlazoActions.fetchOtpDesafio.type, fetchOtpDesafio);
}

export function* watchSeleccionarSimulacion() {
  yield takeLatest(depositoAPlazoActions.seleccionarSimulacion.type, seleccionarSimulacion);
}

export function* watchFetchDAPInvertir() {
  yield takeLatest(depositoAPlazoActions.fetchDAPInvertir.type, fetchDAPInvertir);
}

export function* watchFetchComprobanteInvertir() {
  yield takeLatest(depositoAPlazoActions.fetchComprobanteInvertir.type, fetchComprobanteInvertir);
}

export function* watchFetchReglasLiquidacion() {
  yield takeLatest(depositoAPlazoActions.fetchReglasLiquidacion.type, fetchReglasLiquidacion);
}

export function* watchLiquidarDepositoContratado() {
  yield takeLatest(
    depositoAPlazoActions.liquidarDepositoContratado.type,
    liquidarDepositoContratado
  );
}

export function* watchFetchComprobanteLiquidar() {
  yield takeLatest(depositoAPlazoActions.fetchComprobanteLiquidar.type, fetchComprobanteLiquidar);
}

export default function* rootSaga() {
  yield spawn(watchFetchDepositosContratados);
  yield spawn(watchFetchSimulacionMultiple);
  yield spawn(watchFetchOtpDesafio);
  yield spawn(watchFetchDepositoConfiguracionSimular);
  yield spawn(watchSeleccionarSimulacion);
  yield spawn(watchFetchDAPInvertir);
  yield spawn(watchFetchComprobanteInvertir);
  yield spawn(watchLiquidarDepositoContratado);
  yield spawn(watchFetchComprobanteLiquidar);
  yield spawn(watchFetchReglasLiquidacion);
}
