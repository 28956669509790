import React, { useEffect, useState, Children } from 'react';
import PropTypes from 'prop-types';
// utils
import Context from './context';
// components
import ExpandHeader from './ExpandHeader';
import ExpandItem from './ExpandItem';
import ExpandBody from './ExpandBody';
import CardBox from '@coopeuch-components/web/molecules/CardBox';

const { Provider } = Context;

/**
 * A very simple and high customizalbe `Expand` component.
 */
const Expand = ({ defaultSelectedItem = 0, ...props }) => {
  const [items, setItemsState] = useState([]);

  useEffect(() => {
    const size = Children.toArray(props.children).length;
    const _state = [];

    for (let iter = 0; iter < size; iter += 1) {
      _state.push(defaultSelectedItem - 1 === iter ? true : false);
    }

    setItemsState(_state);
  }, []);

  function _onClickExpand(event) {
    event.persist();

    const { callback } = props;
    const { position } = event.currentTarget.dataset;

    setItemsState((prevState) =>
      prevState.map((item, index) => (index === Number(position) ? !item : false))
    );
    callback(position);
  }

  const { boxClassName, borderColor, iconColor, children, rounded, id } = props;

  return (
    <CardBox borderColor={borderColor} className={boxClassName} rounded={rounded} id={id}>
      <Provider value={{ items, onClickExpand: _onClickExpand, iconColor, rounded }}>
        {children}
      </Provider>
    </CardBox>
  );
};

Expand.Item = ExpandItem;
Expand.Header = ExpandHeader;
Expand.Body = ExpandBody;

Expand.propTypes = {
  boxClassName: PropTypes.string,
  borderColor: PropTypes.string,
  iconColor: PropTypes.string,
  callback: PropTypes.func,
  children: PropTypes.node,
  rounded: PropTypes.bool,
  id: PropTypes.string,
};

Expand.defaultProps = {
  boxClassName: '',
  borderColor: '',
  iconColor: '#333',
  callback() {},
  children: null,
  rounded: false,
  id: '',
};

export default Expand;
