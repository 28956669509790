const defaultRequest = {
  id: 500,
  title: '¡Lo sentimos!',
  message:
    'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
  showInput: false,
};

export const errPassCoopeuchFactor = {
  default: defaultRequest,
  8: {
    id: 8,
    title: '¡Lo sentimos!',
    message:
      'La autorización desde tu celular ha sido rechazada, reintenta para volver a realizar la transacción',
  },
  1: {
    id: 1,
    title: '¡Lo sentimos!',
    message: 'La transferencia no ha podido ser realizada, por favor intenta nuevamente.',
  },
  12: {
    id: 12,
    title: '¡Lo sentimos!',
    message: 'Agregar destinatarios no ha podido ser realizado, por favor intenta nuevamente',
  },
  13: {
    id: 13,
    title: '¡Operación rechazada!',
    message: 'Haz rechazado agregar destinatario. Si quieres, puedes intentarlo nuevamente.',
  },
  14: {
    id: 14,
    title: '¡Se agotó el tiempo!',
    message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
  },
  15: {
    id: 15,
    title: '¡Operación rechazada!',
    message: 'Haz rechazado la transferencia. Si quieres, puedes intentarlo nuevamente',
  },
  7: {
    id: 7,
    title: '¡Operación rechazada!',
    message: 'PassCoopeuch está bloqueada. Regístrate nuevamente en la app.',
  },
};

export const errSMSFactor = {
  default: defaultRequest,
  1: {
    id: 1,
    title: '¡Lo sentimos!',
    message: 'El código SMS no corresponden. Por Favor, intenta nuevamente.',
    showInput: true,
  },
  2: {
    id: 2,
    title: '¡Lo sentimos!',
    message:
      'El código SMS ha sido bloqueado. Favor comunicarse con nuestro call center al <b>600 200 1200</b> e indicar el código <b>216908</b>.',
    hideButton: true,
  },
  3: {
    id: 3,
    title: '¡Se agotó el tiempo!',
    message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
  },
  4: {
    id: 4,
    title: '¡Se agotó el tiempo!',
    message: 'Has superado el límite de intentos de envio de sms por transacción.',
    hideButton: true,
  },
};

export const errTarjetaCoordenadasFactor = {
  1: {
    id: 1,
    title: '¡Se agotó el tiempo!',
    message: 'Tienes un tiempo limitado para autorizar, inténtalo nuevamente.',
    showInput: false,
  },
  2: {
    id: 2,
    title: '¡Lo sentimos!',
    message: 'Las coordenadas no corresponden. Por favor, intenta nuevamente.',
    showInput: false,
  },
  3: {
    id: 3,
    title: '¡Lo sentimos!',
    message:
      'En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde.',
    showInput: false,
  },
  4: {
    id: 4,
    title: '¡Operación rechazada!',
    message:
      'Por su seguridad, su tarjeta de coordenadas ha sido bloqueada, ha superado la cantidad de intentos',
    showInput: false,
  },
  5: {
    id: 5,
    title: '¡Lo sentimos!',
    message: 'Tu Tarjeta de Coordenadas está bloqueada. Solicítala nuevamente en App Coopeuch',
    showInput: false,
  },
};

export default defaultRequest;
