import Styled from 'styled-components';
import { colors } from '../../../../assets/styles';

export const Container = Styled.div`
width: 189px;
    /* #menu-1 {
      background-color: #f00;
    } */
    .inicio > div > div > svg >path{
      fill:${() => colors.red}!important;
    }
 
  /* .final{
    display:block;
      &:last-child{
        background-color: #f00;
      }
    } */

`;
export const Item = Styled.div`
display: flex;
align-items: center;
color: #383638;
height: 3em;
padding-top: 1em;
padding-bottom: 1em;
padding-left: ${({ paddingLeft }) => `${paddingLeft}px`};
${this}: hover  {
  background-color: #F6F3F5
}
 
 /* &:first-child div:nth-child(1):first-of-type + div {
  background-color: #f00 !important;
  } */
 
& svg path {
  fill: #646569 !important;
  
}
.menu-icon {
  float:left;
  margin-right: 8px;
}
.menu-item , .menu-item:root{
  text-decoration: none;
  color: #383638;
  cursor: pointer;
  font-size: 14px!important;
}
.active-menu-item p{
  color: ${() => colors.red};
  font-weight: bold!important;
}

.remove-padding-arrow-icon {
  margin-left: -5px;
  margin-right: 3px;
}
.first-level-menu-item {
  font-size: 16px;
}
.cursor-default {
  cursor: default
}

`;
export default Item;
