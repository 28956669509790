const initState = {
  factores: {},
  errorFactores: false,
  errorCuotaModify: false,
  errorCuotaBorradorDescargar: false,
  errorCuotaActualizacion: { message: null, error: false, code: null, statusCode: null, uri: null },
  errorFactoresLogs: null,
  cuotas: { success: false, error: false, data: null, date: null },
  actualizacionCuotas: { success: false, error: false, data: null, date: null },
  borradorDescargarciaCuotas: { success: false, error: false, data: null, date: null },
  cuentasDisponibles: { success: false, error: false, data: null, date: null },
  modify_cuotas: { success: false, error: false, data: null, date: null },
  banner_cuotas: { success: false },
  sugerenciaCuotas: { success: false, error: false, data: null, date: null },
  pagarCuotas: { success: false, error: false, data: null, date: null },
  detallePagoCuotas: { success: false, error: false, data: null, date: null },
  dataCuotasPagadas: { success: false, error: false, data: null },
  descargarComprobanteModificacionCuotasParticipacion: { success: false, error: false, data: null },
};

export default initState;
