import styled from 'styled-components';

export default styled.div`
  .content {
    width: 100%;
    text-align: left;
    padding: 24px 3rem 24px 3rem;
  }

  .modulos-container {
    margin-top: 24px;
  }

  .modulo {
    margin-top: 0px;
    margin-bottom: 24px;
  }

  .modulo-content {
    padding: 24px 32px;
  }

  .cv--resumen--seleccion {
    height: 48px;
    padding: 12px;
    border: 1px solid #646569;
    display: flex;
    align-items: center;
    border-radius: 5px;
  }

  .saldo-disponible {
    padding-top: 6px;
  }

  .err-color {
    color: #cc284e;
  }

  .err-component {
    .button-error-class {
      width: 296px;
      background-color: #e81e2b;
    }
  }

  .input-correo-or-password-wrapper {
    position: relative;
    height: 68px;

    .lock-icon {
      position: absolute;
      z-index: 2;
      top: 13px;
      left: 5px;
    }

    .eye-icon {
      position: absolute;
      right: 10px;
      top: 5px;
    }

    .help-text {
      position: absolute;
      left: 0;
      bottom: 0px;
    }

    .error-warning-password {
      position: absolute;
      left: 0;
      bottom: 0px;

      & svg {
        fill: #bf0338;

        path {
          fill: #bf0338;
        }
      }
    }
  }

  #pago-tarjeta-credito-next-action {
    width: 160px !important;
  }

  #pago-tarjeta-credito-prev-action {
    align-items: center;
  }

  @media screen and (max-width: 766px) {
    .content {
      padding-left: 12px;
      padding-right: 12px;
      padding-top: 16px !important;
    }

    .modulos-container {
      margin-top: 16px;
    }

    .modulo {
      margin-top: 0px;
      margin-bottom: 16px;
    }

    .modulo-content {
      padding: 16px;
    }

    .modulo-second-text {
      padding-top: 16px;
    }

    .saldo-disponible {
      padding-top: 0px;
    }

    .err-component {
      .button-error-class {
        width: auto;
      }
    }

    #dap-title-page {
      div:first-child {
        min-width: 30px;
        min-height: 30px;
      }
    }

    .alert--wrapper {
      padding-left: 14px !important;

      .right-content {
        align-items: start;

        & > div:first-child {
          width: 50px !important;
          height: 40px !important;
        }

        div,
        p {
          text-align: start;
        }

        svg {
          width: 20px;
          height: 30px;
        }
      }
    }

    #pago-tarjeta-credito-next-action,
    .step-action-button-wrapper {
      width: 100% !important;
    }
  }
`;
