import React, { Fragment, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import getYear from 'date-fns/getYear';
import { subYears } from 'date-fns';

import { certificadosSIIActions } from '../../../application/actions/certificadosSII';

import { useShallowStore } from '../../utils/hooks';
import { downloadPDFBase64 } from '../../utils/functions';

import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../components/atoms/PageTitle';
import CertificadosSIITemplate from '../../components/templates/CertificadosSII';
import { registerGA } from '../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CertificadosN70 = () => {
  const dispatch = useDispatch();

  const currentYear = useMemo(() => getYear(new Date()), []);
  const lastYear = useMemo(() => getYear(subYears(new Date(), 1)), [currentYear]);

  const certificados = useShallowStore(({ entities }) => entities.certificadosSII[70]);
  const isLoading = useShallowStore(({ ui }) => ui.loaders.isLoadingCertificadoSII);

  const thereIsACertificadoToCurrentYear = useMemo(() => {
    return certificados.currentYear?.data?.length >= 1;
  }, [certificados.currentYear]);

  const thereIsACertificadoToLastYear = useMemo(() => {
    return certificados.lastYear?.data?.length >= 1;
  }, [certificados.lastYear]);

  const thereIsAnError = useMemo(() => {
    return certificados.currentYear.error || certificados.lastYear.error;
  }, [certificados]);

  const handleDownloadPDF = useCallback(
    (key, year) => {
      downloadPDFBase64(certificados[key].data, `Certificado SII 70 - ${year}`);
      registerGA({
        'event': 'eve',
        'eve-acc' : '/tef/certificados/certificado70',
        'eve-cat' : 'Click',
        'eve-lab' : 'Descargar'
        });
    },
    [certificados]
  );
  
  useEffect(() => {
    registerGA({
      'event': 'vpv-privado',
      'page' : '/tef/certificados/certificado70'
      });
  }, []);

  useEffect(() => {
    if (currentYear && !certificados.currentYear?.data) {
      dispatch(
        certificadosSIIActions.fetchPDFCertificadoSII({
          codigo: 70,
          anio: currentYear,
          key: 'currentYear',
        })
      );
    }
  }, [currentYear]);

  useEffect(() => {
    if (
      !certificados.currentYear?.error &&
      lastYear &&
      typeof certificados.currentYear?.data === 'string' &&
      !certificados.lastYear?.data
    ) {
      dispatch(
        certificadosSIIActions.fetchPDFCertificadoSII({
          codigo: 70,
          anio: lastYear,
          key: 'lastYear',
        })
      );
    }
  }, [lastYear, certificados.currentYear]);

  return (
    <Fragment>
      <PageTitle
        title="Certificado N° 70"
        icon={<LoadIcon src={`${iconPath}deal.svg`} color="black" />}
      />
      <div className="w-100 margin-after-title text-left">
        <div className="bmt-3 bmt-md-4 bmb-3 bmb-md-4">
          <CertificadosSIITemplate
            error={thereIsAnError}
            loading={isLoading}
            thereIsACertificadoToLastYear={thereIsACertificadoToLastYear}
            thereIsACertificadoToCurrentYear={thereIsACertificadoToCurrentYear}
            numeroCertificado={70}
            currentYear={currentYear}
            lastYear={lastYear}
            handleDownloadPDF={handleDownloadPDF}
            title="Dividendos o Remanentes Distribuidos"
            message="De acuerdo a las instrucciones del Servicio de Impuestos Internos, a través del
            certificado N° 70 (dividendos o remanentes distribuidos) Coopeuch, por ser
            contribuyente del artículo 14 A de la ley de impuesto a la renta, debe informar el
            remanente pagado a sus socios en el año anterior. Este remanente queda afecto al
            impuesto global complementario por los conceptos de intereses pagados al capital y
            excedentes provenientes de operaciones con terceros (no socios), que son parte del
            total de remanente distribuido."
          />
        </div>
      </div>
    </Fragment>
  );
};

export default CertificadosN70;
