import React from 'react';
import Button from '@coopeuch-components/web/atoms/Button';
import { Text } from './styles';

const staticPath = process.env.REACT_APP_CLOUDFRONT_STATICS;

const ContratacionBloqueoClave = ({ onClick, serverPasswordError }) => {
  const { title, message, buttonTitle } = serverPasswordError;

  return (
    <div className="center-xs">
      <div className="row center-xs">
        <img src={`${staticPath}login_error.png`} alt="login-error" />
      </div>
      <Text id="title" textSize={16} size={32} weight="bold">
        {title}
      </Text>
      <div className="col-xs-12 col-md-8 col-md-offset-2">
        <Text id="message" textSize={14} size={28} weight="normal">
          {message}
        </Text>
      </div>
      <div className="row bmt-3">
        <div className="col-md"></div>
        <div className="col-xs-12 col-md-4">
          <Button
            id="button"
            bgColor="#E81E2B"
            color="white"
            className="alert-modal-text"
            onClick={onClick}
            fullWidth
          >
            {buttonTitle}
          </Button>
        </div>
        <div className="col-md"></div>
      </div>
    </div>
  );
};

export default ContratacionBloqueoClave;
