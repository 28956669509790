import React from 'react';
import Skeleton from 'react-loading-skeleton';
import DashboardCard from '../../../organism/DashboardCard';
import { WrapperSkeleton } from './styles';

export const SkeletonConfirmacion = () => (
  <React.Fragment>
    <WrapperSkeleton className="display-desktop">
      <div className="bmt-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-md-3 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-9 bmb-5" />
              <div className="col-md-3">
                <Skeleton />
              </div>
              <div className="col-md-3" />
              <div className="col-md-3">
                <Skeleton />
              </div>
              <div className="col-md-3" />
              <div className="col-md-4 bmt-3">
                <Skeleton height={51} />
              </div>
              <div className="col-md-2" />
              <div className="col-md-2 bmt-4 pt-6">
                <Skeleton />
              </div>
              <div className="col-md-4" />
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-4">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-md-3 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-9 bmb-5" />
              <div className="col-md-3">
                <Skeleton />
              </div>
              <div className="col-md-3" />
              <div className="col-md-3">
                <Skeleton />
              </div>
              <div className="col-md-3" />
              <div className="col-md-4 bmt-3">
                <Skeleton height={51} />
              </div>
              <div className="col-md-2" />
              <div className="col-md-2 bmt-4 pt-6">
                <Skeleton />
              </div>
              <div className="col-md-4" />
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-4">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-md-3 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-9 bmb-5" />
              <div className="col-md-12 bmb-4">
                <Skeleton height={51} />
              </div>
              <div className="col-md-9 bmb-4">
                <Skeleton />
              </div>
              <div className="col-md-3" />
              <div className="col-md-2 bmb-3">
                <Skeleton />
              </div>
              <div className="col-md-10" />
              <div className="col-md-4">
                <Skeleton height={51} />
              </div>
              <div className="col-md-8" />
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-4">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-md-3 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-9 bmb-5" />
              <div className="col-md-6 bmb-5">
                <Skeleton />
              </div>
              <div className="col-md-6" />
              <div className="col-md-4 bmb-3">
                <Skeleton />
              </div>
              <div className="col-md-8" />
              <div className="col-md-5 bmb-2">
                <Skeleton height={51} />
              </div>
              <div className="col-md-7" />
              <div className="col-md-2 bmb-3">
                <Skeleton />
              </div>
              <div className="col-md-10" />
              <div className="col-md-1">
                <Skeleton />
              </div>
              <div className="col-md-1" />
              <div className="col-md-7">
                <Skeleton />
              </div>
              <div className="col-md-3" />
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-4">
        <div className="row">
          <div className="col-md-6 skeleton-center-vertical">
            <Skeleton width={20} className="bmr-2" />
            <Skeleton width={50} />
          </div>
          <div className="col-md-6 btext-right">
            <Skeleton width={186} height={51} />
          </div>
        </div>
      </div>
    </WrapperSkeleton>
    <WrapperSkeleton className="display-mobile">
      <div className="bmt-3 bmt-md-0 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-xs-12 bmb-5">
                <Skeleton />
              </div>
              <div className="col-xs-7 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-5" />
              <div className="col-xs-12 bmb-3">
                <Skeleton height={47} />
              </div>
              <div className="col-xs-7 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-5" />
              <div className="col-xs-6">
                <Skeleton />
              </div>
              <div className="col-xs-6" />
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-3 bmt-md-0 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-xs-12 bmb-5">
                <Skeleton />
              </div>
              <div className="col-xs-12 bmb-4">
                <Skeleton height={96} />
              </div>
              <div className="col-xs-12 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-12 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-6 bmb-4">
                <Skeleton />
              </div>
              <div className="col-xs-6" />
              <div className="col-xs-3 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-9" />
              <div className="col-xs-12">
                <Skeleton height={49} />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-3 bmt-md-0 bmb-3">
        <DashboardCard classes="dashboard-card-shadow">
          <DashboardCard.Body classes="confirmacion-simulacion-card-body">
            <div className="row">
              <div className="col-xs-9 bmb-5">
                <Skeleton />
              </div>
              <div className="col-xs-3" />
              <div className="col-xs-12 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-6 bmb-4">
                <Skeleton />
              </div>
              <div className="col-xs-6" />
              <div className="col-xs-10 bmb-3">
                <Skeleton />
              </div>
              <div className="col-xs-2" />
              <div className="col-xs-12 bmb-2">
                <Skeleton height={47} />
              </div>
              <div className="col-xs-10 bmb-4">
                <Skeleton />
              </div>
              <div className="col-xs-2" />
              <div className="col-xs-1">
                <Skeleton height={24} width={24} />
              </div>
              <div className="col-xs-11 pt-6">
                <div className="col-xs-12 bmb-2">
                  <Skeleton />
                </div>
                <div className="col-xs-12 bmb-2">
                  <Skeleton />
                </div>
                <div className="col-xs-6">
                  <Skeleton />
                </div>
                <div className="col-xs-6" />
              </div>
            </div>
          </DashboardCard.Body>
        </DashboardCard>
      </div>
      <div className="bmt-3">
        <div className="row">
          <div className="col-xs-12">
            <Skeleton height={51} />
          </div>
          <div className="col-xs-3" />
          <div className="col-xs-6 bmt-4">
            <Skeleton />
          </div>
          <div className="col-xs-3" />
        </div>
      </div>
    </WrapperSkeleton>
  </React.Fragment>
);
