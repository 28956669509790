import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../components/atoms/PageTitle';
import PagoTemplate from '../../../components/templates/CuotaParticipacion/Pago';
import { cuotaParticipacionActions } from '../../../../application/actions/cuotaParticipacion';
import { registerGA } from '../../../utils/metrics';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const CODIGO_CUENTAS = ['VI01', 'VI03', 'VI06'];

const initState = {
  cuentasChecked: false,
  cuotasChecked: false,
  generalError: false,
  steps: ['Monto a pagar', 'Pago', 'Comprobante'],
  currentStep: 1,
  accountsAvailable: [],
  selectedAccount: null,
  cuotasPendiente: null,
  montoCuotasPendiente: null,
  valorCuota: null,
  cantidadCuotas: null,
  backButton: null,
  nextButton: null,
  montoAPagar: 0,
  saldoInsuficienteError: null,
  password: '',
  showPassword: false,
  passwordError: '',
  blockedPassword: false,
  serviceError: false,
  serverPasswordError: '',
  maxCuotasError: null,
  loadingDataStep1: true,
  waitingStep2: false,
  secondFailPasswordError: false,
  detallePago: {},
};

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }
  componentDidMount = () => {
    const { cuotas, cuentasDisponibles } = this.props;
    registerGA({
      event: 'vpv-privado',
      page: '/tef/pago-cuota-participacion/paso-1',
    });
    if (cuotas.success !== cuotas.error) this.checkCuotasResponse(cuotas);
    if (cuentasDisponibles.success !== cuentasDisponibles.error)
      this.checkCuentaVistaResponse(cuentasDisponibles);
  };
  componentDidUpdate = (prevProps) => {
    const { cuentasDisponibles, cuotas, pagarCuotasResponse, detallePagoCuotas } = this.props;
    const { cuentasChecked, cuotasChecked, loadingDataStep1 } = this.state;
    if (cuentasDisponibles !== prevProps.cuentasDisponibles) {
      this.checkCuentaVistaResponse(cuentasDisponibles);
    }
    if (cuotas !== prevProps.cuotas) {
      this.checkCuotasResponse(cuotas);
    }
    if (!!cuentasChecked && !!cuotasChecked && !!loadingDataStep1) {
      this.setState({ loadingDataStep1: false });
    }
    if (pagarCuotasResponse !== prevProps.pagarCuotasResponse) {
      this.handlePagoCuotasResponse(pagarCuotasResponse);
    }
    if (detallePagoCuotas !== prevProps.detallePagoCuotas) {
      this.handleDetallePagoResponse(detallePagoCuotas);
    }
  };

  checkCuentaVistaResponse = (response) => {
    if (response.error) {
      this.setGlobalError();
    } else {
      this.setCuentasDisponibles(response.data);
    }
  };

  checkCuotasResponse = (response) => {
    if (response.success) {
      this.setDataCuotas(response.data);
    } else {
      this.setGlobalError();
    }
  };
  setCuentasDisponibles = (cuentas) => {
    const options = cuentas.map((cv) => ({
      label: `${cv.codigoProducto === 'VI06' ? 'Monedero Digital: N° ' : 'Cuenta Vista: N° '} ${
        cv.numeroCuenta
      }`,
      value: cv.numeroCuenta,
      saldo: cv.saldoDisponible,
      codigoProducto: cv.codigoProducto,
    }));
    this.setState({
      accountsAvailable: options,
      selectedAccount: options[0],
      cuentasChecked: true,
    });
  };

  setDataCuotas = (cuotas) => {
    this.setState({
      cuotasPendiente: cuotas.pendientes.cuotasPendientes,
      montoCuotasPendiente: cuotas.pendientes.montoCuotasPendientes,
      valorCuota: cuotas.valorCuota,
      cuotasChecked: true,
    });
  };

  handleChangeInput = (e) => {
    const { value, name } = e.target;
    const { cuotasPendiente } = this.state;
    let maxCuotasError = false;
    if (parseInt(value, 10) > cuotasPendiente) {
      maxCuotasError = true;
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/pago-cuota-participacion/paso-1',
        'eve-cat': 'Error',
        'eve-lab': `Tu máximo de cuotas a pagar es de ${cuotasPendiente}, por favor ingresa una cantidad menor.`,
      });
    }
    const fixedValue = value.replace(/[^z0-9\s]/g, '');
    this.setState({
      [name]: !!fixedValue.length ? parseInt(fixedValue, 10) : 0,
      maxCuotasError,
    });
  };

  handleChangeSelect = (e) => {
    const { accountsAvailable, montoAPagar } = this.state;
    const selectedAccount = accountsAvailable.filter(
      (account) => account.value === parseInt(e.target.value, 10)
    )[0];
    this.setState(
      {
        selectedAccount,
      },
      () => {
        if (selectedAccount.saldo < montoAPagar) {
          registerGA({
            event: 'eve',
            'eve-acc': '/tef/pago-cuota-participacion/paso-2',
            'eve-cat': 'Error',
            'eve-lab': 'No tienes saldo suficiente',
          });
        }
      }
    );
  };
  handlePasswordInput = (e) => {
    const value = e.target.value;
    const errorMessage = 'La clave debe tener entre 6 y 8 caracteres.';
    this.setState({
      password: value,
      passwordError: value.length < 6 || value.length > 8 ? errorMessage : '',
    });
  };
  handleTogglePassword = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handlePagoCuotasResponse = (response) => {
    if (response.error) {
      this.setState(
        {
          serverPasswordError: response.errorCode,
          generalError: ['AUTH-01', 'AUTH-02', 'AUTH-03'].includes(response.errorCode)
            ? false
            : true,
          blockedPassword: ['AUTH-02', 'AUTH-03'].includes(response.errorCode) ? true : false,
          waitingStep2: false,
        },
        () => {
          if (['AUTH-02', 'AUTH-03'].includes(response.errorCode)) {
            registerGA({
              event: 'eve',
              'eve-acc': '/tef/pago-cuota-participacion/paso-2',
              'eve-cat': 'Error',
              'eve-lab': 'Contraseña bloqueada',
            });
          }
        }
      );
    } else if (response.success && !!response.data.pagado) {
      const { fetchDetallePagoCDP } = this.props;
      const { nroTransaccion } = response.data;
      fetchDetallePagoCDP({ nroTransaccion });
    }
  };
  handleDetallePagoResponse = (response) => {
    if (response.error) {
      this.setGlobalError();
    } else {
      const { selectedAccount } = this.state;
      this.setState(
        {
          detallePago: {
            ...response.data,
            tipoCuenta: `${
              selectedAccount.codigoProducto === 'VI06'
                ? 'Monedero Digital Coopeuch'
                : 'Cuenta Vista Coopeuch'
            }`,
          },
        },
        () => {
          registerGA({
            event: 'vpv-privado',
            page: '/tef/pago-cuota-participacion/paso-3',
            monto: response.data.montoPagado,
            cuotas: response.data.cuotasPagadas,
          });
          const { fetchCuotasParticipacion, fetchCuentasVista } = this.props;
          fetchCuotasParticipacion();
          fetchCuentasVista({
            codigoProducto: CODIGO_CUENTAS,
            estadoCuenta: ['A'],
            tipoCliente: ['R'],
          });
          this.toNextStep();
        }
      );
    }
  };
  handleRetryTryToPay = () => {
    this.setState({
      serverPasswordError: '',
      secondFailPasswordError: true,
    });
  };
  handleToCambiarClave = () => {
    const { history } = this.props;
    history.push('/recuperar-clave');
  };

  setGlobalError = () => {
    this.setState({ generalError: true }, () => {
      const { currentStep } = this.state;
      registerGA({
        event: 'eve',
        'eve-acc': `/tef/pago-cuota-participacion/paso-${currentStep}`,
        'eve-cat': 'Error',
        'eve-lab': 'Servicio no disponible',
      });
    });
  };
  toNextStep = () => {
    this.setState({ currentStep: this.state.currentStep + 1 });
  };
  toPrevStep = () => {
    this.setState({ currentStep: this.state.currentStep - 1 });
  };
  toCuotas = () => {
    const { history } = this.props;
    history.push('/ecd/productos/cuota-de-participacion');
  };
  toHome = () => {
    const { history } = this.props;
    history.push('/ecd/inicio');
  };

  backStep1 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/pago-cuota-participacion/paso-1',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    this.toCuotas();
  };
  continueStep1 = () => {
    const { cantidadCuotas, valorCuota } = this.state;
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/pago-cuota-participacion/paso-1',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });
    this.setState(
      {
        montoAPagar: cantidadCuotas * valorCuota,
      },
      () => {
        const { montoAPagar, selectedAccount } = this.state;
        registerGA({
          event: 'vpv-privado',
          page: '/tef/pago-cuota-participacion/paso-2',
          monto: montoAPagar,
        });
        if (selectedAccount.saldo < montoAPagar) {
          registerGA({
            event: 'eve',
            'eve-acc': '/tef/pago-cuota-participacion/paso-2',
            'eve-cat': 'Error',
            'eve-lab': 'No tienes saldo suficiente',
          });
        }
        this.toNextStep();
      }
    );
  };
  backStep2 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/pago-cuota-participacion/paso-2',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    this.toPrevStep();
  };
  continueStep2 = () => {
    const { pagarCuotas } = this.props;
    const { password, cantidadCuotas, selectedAccount, montoAPagar } = this.state;
    this.setState({ waitingStep2: true });
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/pago-cuota-participacion/paso-2',
      'eve-cat': 'Click',
      'eve-lab': 'Pagar',
      monto: montoAPagar,
      cuotas: cantidadCuotas,
    });
    pagarCuotas({
      clave: password,
      cuotas: cantidadCuotas,
      numeroCuenta: selectedAccount.value,
    });
  };
  continueStep2WithoutPago = () => {
    this.toCuotas();
  };
  backStep3 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/pago-cuota-participacion/paso-3',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    this.toHome();
  };
  continueStep3 = () => {
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/pago-cuota-participacion/paso-3',
      'eve-cat': 'Click',
      'eve-lab': 'Ir a Cuota de Partipación',
    });
    this.toCuotas();
  };

  render() {
    const {
      generalError,
      steps,
      currentStep,
      //Step1
      cuotasPendiente,
      montoCuotasPendiente,
      valorCuota,
      cantidadCuotas,
      montoAPagar,
      accountsAvailable,
      selectedAccount,
      saldoInsuficienteError,
      password,
      showPassword,
      passwordError,
      blockedPassword,
      serviceError,
      serverPasswordError,
      maxCuotasError,
      loadingDataStep1,
      waitingStep2,
      secondFailPasswordError,
      detallePago,
    } = this.state;
    return (
      <PagoTemplate
        {...{
          steps,
          currentStep,
          generalError,
          // Step1
          cuotasPendiente,
          montoCuotasPendiente,
          valorCuota,
          cantidadCuotas,
          montoAPagar,
          accountsAvailable,
          selectedAccount,
          saldoInsuficienteError,
          password,
          showPassword,
          passwordError,
          blockedPassword,
          serviceError,
          serverPasswordError,
          maxCuotasError,
          loadingDataStep1,
          waitingStep2,
          secondFailPasswordError,
          detallePago,
          handleChangeInput: this.handleChangeInput,
          handleChangeSelect: this.handleChangeSelect,
          handlePasswordInput: this.handlePasswordInput,
          handleTogglePassword: this.handleTogglePassword,
          handleRetryTryToPay: this.handleRetryTryToPay,
          handleToCambiarClave: this.handleToCambiarClave,
          backStep1: this.backStep1,
          nextStep1: this.continueStep1,
          backStep2: this.backStep2,
          nextStep2: this.continueStep2,
          continueStep2WithoutPago: this.continueStep2WithoutPago,
          backStep3: this.backStep3,
          nextStep3: this.continueStep3,
        }}
      />
    );
  }
}
const mapStateToProps = (store) => ({
  cuentasDisponibles: store.entities.cuotaParticipacion.cuentasDisponibles,
  cuotas: store.entities.cuotaParticipacion.cuotas,
  pagarCuotasResponse: store.entities.cuotaParticipacion.pagarCuotas,
  detallePagoCuotas: store.entities.cuotaParticipacion.detallePagoCuotas,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCuotasParticipacion: () => {
    dispatch(cuotaParticipacionActions.fetchCuotasParticipacion());
  },
  fetchCuentasVista(props) {
    dispatch(cuotaParticipacionActions.fetchCVCuotasParticipacion(props));
  },
  pagarCuotas(props) {
    dispatch(cuotaParticipacionActions.pagarCuotasParticipacion(props));
  },
  fetchDetallePagoCDP(props) {
    dispatch(cuotaParticipacionActions.fetchDetallePagoCDP(props));
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
