import React from 'react';

import CardKit from '../../../organism/ActivacionTarjetas/Activacion/CardKit';
import NoKit from '../../../../components/organism/ActivacionTarjetas/Activacion/NoKit';
import CardBlock from '../../../organism/ActivacionTarjetas/Error/CardBlock';
// Styled
import Wrapper from './styles';
import CardKitPass from '../../../organism/ActivacionTarjetas/Activacion/CardPassCoopeuch';


const HomeTemplate = ({ kitData, handleClickActivate, handleReturnInicio }) => {

  console.log('kit data' , kitData);

  return (
    <Wrapper>
      <div className="row">
        <div className="col-xs-12">

          {kitData.data !== null &&
            (kitData.data?.activable === true ? (
              <CardKitPass kitData={kitData} handleClickActivate={handleClickActivate} handleReturnInicio={handleReturnInicio} />
              //<CardKit kitData={kitData} handleClickActivate={handleClickActivate} />
            ) : (
              <NoKit
                icon="copywriting.svg"
                destacado="¡Nada por aquí!"
                text="Si ya activaste tus tarjetas, puedes revisarla en la sección Cuenta Vista Coopeuch"
              />
            ))}
          {kitData.error?.code === 'TC-03' && (
            <NoKit
              icon="block.svg"
              destacado="¡Tu producto está bloqueado!"
              text="Dirigite a la sucursal más cercana para continuar con la activación."
            />
          )}
          {kitData.error !== null && kitData.error?.code !== 'TC-03' && (
            <CardBlock
              section="Tus tarjetas están listas para ser activadas"
              title="¡Lo sentimos!"
              message="En este momento nuestro servicio no se encuentra disponible. Vuelve a intentarlo más tarde."
              link="/ecd/inicio"
            />
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default HomeTemplate;
