import React from 'react';
import PropTypes from 'prop-types';
// styles
import CardBox from '@coopeuch-components/web/molecules/CardBox';

const ExpandHeader = ({ children, height, className }) => (
  <CardBox.Header className={className} underline={false} height={height}>
    {children}
  </CardBox.Header>
);

ExpandHeader.propTypes = {
  children: PropTypes.node,
  height: PropTypes.number,
};

ExpandHeader.defaultProps = {
  children: null,
  height: 0,
};

export default ExpandHeader;
