import {createAction, createSlice} from "@reduxjs/toolkit";
import initialState from "../../../domain/entities/desbloqueoTd";

// ------- SAGA ------ //

function fetchValidarBloqueoTdSuccess(state, action) {
  const {data} = action.payload;
  console.log(">> log desde fetchValidarBloqueoTdSuccess", data);
  state.validarBloqueoTd.data = data;
  state.validarBloqueoTd.error = null;
}

function fetchValidarBloqueoTdError(state, action) {
  console.log(">> log desde fetchValidarBloqueoTdError");
  const {error} = action.payload;
  state.validarBloqueoTd.isValid = error;
  state.validarBloqueoTd.error = error;
}

function resetValidarBloqueoTd(state) {
  console.log(">> log desde resetValidarBloqueoTd");
  state.validarBloqueoTd = {...initialState.validarBloqueoTd};
}

const fetchValidarBloqueoTd = createAction('fetchValidarBloqueoTd');

// ------------- connection ------------- //
const desbloqueoTdReducer = createSlice({
  name: 'desbloqueoTd',
  initialState,
  reducers: {
    fetchValidarBloqueoTdSuccess,
    fetchValidarBloqueoTdError,
    resetValidarBloqueoTd,
  }
});

export const desbloqueoTdActions = {
  ...desbloqueoTdReducer.actions,
  fetchValidarBloqueoTd,
};

export default desbloqueoTdReducer.reducer;
