const headers = {
  'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
};

let emitter = null;

function _getTokenLegado() {
  const token = localStorage.getItem('tokenLegado');

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return token;
}

function _processResponse(result) {
  return result
    .then(async (res) => {
      const body = await res.json()
      const { status } = res

      let processed = Promise.resolve(body)

      if([401, 403, 500].includes(status)) {
        processed = Promise.resolve({data: {error:body}})
      }
      return processed
    })
    .then(async (res) => {
      let _res = res;
      if (!res.data) {
        _res = { error: false, data: res };
      } else if (res?.data?.error) {
        _res = { error: true };
      }
      return _res;
    })
    .catch((error) => {
      return { error };
    });
}

async function _processesRequest(request, method, params, optionalsHeaders) {
  _getTokenLegado();

  const response = fetch(request, {
    method,
    headers: optionalsHeaders ? { ...headers, ...optionalsHeaders } : headers,
    body: params
  });


  

  const result = await _processResponse(response);
  return result;
}

const apiLegado = (urlBase) => ({
  setEmitter(emit) {
    emitter = emit;
  },
  async get(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'GET',
      params,
      optionalsHeaders
    );
    return result;
  },
  async post(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'POST',
      params,
      optionalsHeaders
    );
    return result;
  },
  async put(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'PUT',
      params,
      optionalsHeaders
    );
    return result;
  },
  async delete(endpoint, params = null, optionalsHeaders = null) {
    const result = await _processesRequest(
      `${urlBase}${endpoint}`,
      'GET',
      params,
      optionalsHeaders
    );
    return result;
  },
});

export default apiLegado;
