import { put, takeLatest, call, spawn } from 'redux-saga/effects';

// actions
import { tCoordenadaActions } from '../../../application/actions/tarjetaCoordenada';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiTCoordenada = api(process.env.REACT_APP_MSTARJETA_COORDENADA);

export function* fetchTCoordenadaObtener() {
  try {
    const { data } = yield call(apiTCoordenada.get, 'v1/obtener');
    if (data) {
      yield put(tCoordenadaActions.fetchTCoordenadaObtenerSuccess({ data }));
    } else {
      yield put(tCoordenadaActions.fetchTCoordenadaObtenerError());
    }
  } catch (error) {
    yield put(tCoordenadaActions.fetchTCoordenadaObtenerError());
  }
}

export function* fetchCambioEstadoTarjetaCoordenadas(action) {
  try {
    const { params } = action.payload;
    yield put(loadersActions.fetchLoadingCambioEstadoTjCoord({ isLoading: true }));
    const { data, error } = yield call(apiTCoordenada.put, 'v1/estado/cambiar', params);
    if (data) {
      yield put(
        tCoordenadaActions.fetchCambioEstadoTarjetaCoordenadasSuccess({
          cambioEstadoTarjetaCoordenadas: data,
        })
      );
      yield put(loadersActions.fetchLoadingCambioEstadoTjCoord({ isLoading: false }));
    } else {
      yield put(
        tCoordenadaActions.fetchCambioEstadoTarjetaCoordenadasFailed({
          mensajeError: error.code ? error.message : 'Servicio no disponible',
        })
      );
      yield put(loadersActions.fetchLoadingCambioEstadoTjCoord({ isLoading: false }));
    }
  } catch (err) {
    yield put(
      tCoordenadaActions.fetchCambioEstadoTarjetaCoordenadasFailed({
        mensajeError: 'Servicio no disponible',
      })
    );
    yield put(loadersActions.fetchLoadingCambioEstadoTjCoord({ isLoading: false }));
  }
}

export function* watchFetchTCoordenadaObtener() {
  yield takeLatest(tCoordenadaActions.fetchTCoordenadaObtener.type, fetchTCoordenadaObtener);
}

export function* watchFetchCambioEstadoTarjetaCoordenadas() {
  yield takeLatest(
    tCoordenadaActions.fetchCambioEstadoTarjetaCoordenadas.type,
    fetchCambioEstadoTarjetaCoordenadas
  );
}

export default function* rootSaga() {
  yield spawn(watchFetchTCoordenadaObtener);
  yield spawn(watchFetchCambioEstadoTarjetaCoordenadas);
}
