import React, { useState, useEffect } from 'react';
import Button from './styles.timerpass';
import ButtonCoord from './styles.timertarcoord';
import { formatSecondToMinutes } from '../../../../utils/formatters';
import { registerGA } from '../../../../utils/metrics';
import logger from '../../../../utils/logger';

const TimerPass = ({ counterActive, validarPassCoopeuchCP, errorTimeOutCP, factor, IsValidatePassCoopeuch, coordenadas  }) => {
  let time = 120;
  let count = 0;
  const [value, setValue] = useState();

  const updateCountDown = () => {
    time--;
    if(count===2){
      if(factor==='passcoopeuch'){
        if(IsValidatePassCoopeuch.success === null || IsValidatePassCoopeuch.codigo === 25){
          validarPassCoopeuchCP();
          count = 0;
        }       
      }
    } else{
      count++;
    }
    if (time === 0) {
      const baseRut = String((localStorage.getItem('user-rut') || '').replace('-', ''));
      // marca tiempo se acabo 
      setValue('');
      errorTimeOutCP(true, false);
      registerGA({
        event: 'eve',
        'eve-acc': '/tef/solicitar/cuentaVista/paso2',
        'eve-cat': 'Error',
        'eve-lab': 'PassCoopeuch tiempo agotado',
      });
      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion: 'CV_SINPRODUCTO_CONTRATAR_CVISTA_AUTENTIFICACION_PASSCOOPEUCHTCOO_VALIDAR_TIEMPO_VENCIDO',
        informacion_adicional: {},
      });
    }
    return formatSecondToMinutes(time);
  };

  useEffect(() => {
    let intervalId;

    const startCountdown = () => {
      intervalId = setInterval(() => setValue(updateCountDown()), 1000);
    };

    const stopCountdown = () => {
      clearInterval(intervalId);
    };

    if (counterActive) {
      startCountdown();
    } else {
      stopCountdown();
    }

    return () => {
      stopCountdown();
    };
  }, [counterActive]);

  if(factor==='passcoopeuch'){
    return (
      <Button>
        {value ? `${value} para autorizar` : `${formatSecondToMinutes(time)} para autorizar`}
      </Button>
    );
  } else if(coordenadas===false){
    return (
      <ButtonCoord>
        {value ? `${value} para autorizar` : `${formatSecondToMinutes(time)} para autorizar`}
      </ButtonCoord>
    );
  } else{
    return (<div></div>);
  }
};

TimerPass.displayName = 'CountdownNumbers';
export default TimerPass;
