import React, { useState, useRef } from 'react';
import { Container } from './styles';
import AccordionItem from '../AccordionItem';

const Index = ({
  items,
  onClickMenuItem,
  onClickMenuWithChildren,

}) => {

  const contentEl = useRef();
  const [clicked, setClicked] = useState(1);

  const handleToggle = (i) => {
    if (clicked === i) {
      return setClicked('0');
    }
    setClicked(i);
  };
  // console.log(contentEl.current);
  return (
    <Container>
      {items.map((item, i) => {
        return (
          <AccordionItem
            onToggle={() => handleToggle(i)}
            active={Boolean(clicked === i)}
            key={item.id}
            item={item}
            contentEl={contentEl}
            onClickMenuItem={onClickMenuItem}
            onClickMenuWithChildren={onClickMenuWithChildren}
          />
        )
      }
      )}
    </Container>
  );
};
Index.displayName = 'ListToggle_Menu';
export default Index;
