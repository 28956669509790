/* eslint-disable import/no-named-as-default */
/* eslint-disable react/forbid-prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { capitalize } from '../../../../utils/functions';
import Stepper from '../../../organism/Stepper';
import StepsActions from '../../../organism/Stepper/StepsActions';
import DatosBancarios from '../../../organism/PoderEspecialAbonoAcreencias/DatosBancarios';
import { Wrapper } from './styles';
import SkeletonDatosBancarios from './skeleton';

function DatosBancariosTemplate({
  isLoading,  
  persona,
  bancos,
  tipoCuentas,
  onChangeHandle,
  handleGoBack,
  banco,
  tipoCuenta,
  numeroCuenta,
  errorCuenta,
  errorNumeroCuenta,
  handleSubmit,
}) {
  const steps = ['Ingreso de datos', 'Autorización', 'Comprobante'];

  const memoBancoOptions = useMemo(
    () => bancos.map((b) => ({ label: capitalize(b.descripcion), value: b })),
    [bancos]
  );

  const memoCuentaOptions = useMemo(
    () => tipoCuentas?.map((cuenta) => ({ label: capitalize(cuenta.descripcion), value: cuenta })),
    [tipoCuentas]
  );

  const isButtonDisabled = useMemo(() => {
    return !persona || !banco || !tipoCuenta || !numeroCuenta || errorNumeroCuenta;
  }, [banco, tipoCuenta, numeroCuenta, errorNumeroCuenta]);

  return (
    <Wrapper>
      <Stepper className="mb-24 start-xs" position={1} steps={steps} />
      {isLoading ? (
        <SkeletonDatosBancarios />
      ) : (
        <div className="row">
          <div className="col-xs-12 mt-8">
            <DatosBancarios
              persona={persona}
              onChangeHandle={onChangeHandle}
              memoBancoOptions={memoBancoOptions}
              banco={banco}
              memoCuentaOptions={memoCuentaOptions}
              tipoCuenta={tipoCuenta}
              errorNumeroCuenta={errorNumeroCuenta}
              errorCuenta={errorCuenta}
              numeroCuenta={numeroCuenta}
            />
            <StepsActions
              id="datosBancarios"
              className="bmt-3 mt-24"
              backText="Volver"
              nextText="Continuar"
              backAction={handleGoBack}
              nextAction={handleSubmit}
              disableNextAction={isButtonDisabled}
            />
          </div>
        </div>
      )}
    </Wrapper>
  );
}

DatosBancariosTemplate.propTypes = {
  onChangeHandle: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorNumeroCuenta: PropTypes.bool,
  isLoading: PropTypes.bool,
  bancos: PropTypes.arrayOf(PropTypes.object),
  tipoCuentas: PropTypes.arrayOf(PropTypes.object),
  persona: PropTypes.object,
  banco: PropTypes.object,
  tipoCuenta: PropTypes.object,
  numeroCuenta: PropTypes.string,
  errorCuenta: PropTypes.bool,
};

DatosBancariosTemplate.defaultProps = {
  errorNumeroCuenta: false,
  isLoading: false,
  bancos: [],
  tipoCuentas: [],
  numeroCuenta: '',
  errorCuenta: false,
  persona: null,
  banco: null,
  tipoCuenta: null,
};

export default DatosBancariosTemplate;
