import styled from 'styled-components';

export const DownloadLink = styled.div`
    color: #EC1B2B;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    font-weight: bold;
`

export const Hr = styled.div`
  // i want to use this component as a hr, but i can't use the hr tag because it's a reserved word
  width: 100%;
  height: 1px;
  background-color: #E5E5E5;
  margin-top: 20px;
  margin-bottom: 20px;
`;