import styled from 'styled-components';

const Wrapper = styled.div`
  /* Fonts */
  .carCoordenadas__box-description,
  .titleActivacionKit,
  .cardCoordenadas p label {
    font-family: 'Ubuntu-Bold';
  }

  .carCoordenadas__alert {
    font-family: 'Ubuntu-Regular';
  }

  .titleActivacionKit {
    font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .cardCoordenadas {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__image {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      @media (max-width: 500px) {
        margin-top: 0px;
      }
    }
    &__title {
      line-height: 20px;
    }
    &__btn {
      font-family: 'Ubuntu-Medium';
      width: 296px;
      margin: 0 auto;
      @media (max-width: 500px) {
        width: 100%;
      }
    }
    &__bold {
      font-family: 'Ubuntu-Bold';
      margin-bottom: 10px;
    }

    &__box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      max-width: 370px;
      margin-top: 24px;
      @media (max-width: 500px) {
        max-width: 256px;
      }
      &-description {
        text-align: center;
        font-family: 'Ubuntu-Bold';
        @media (max-width: 500px) {
          text-align: start;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 500px;
      margin-top: 8px;
      margin-bottom: 30px;
      @media (max-width: 500px) {
        margin-top: 0px;
      }
      &-input {
        height: 48px;
        width: 36px;
        border: 1px solid #646569;
        border-radius: 4px;
        background-color: #ffffff;
        text-align: center;
        margin-left: 8px;
        margin-right: 8px;
        font-size: 14px;
        @media (max-width: 500px) {
          margin-left: 4px;
          margin-right: 4px;
        }
        &:focus {
          border: 1px solid #007db7;
          transition: border ease-in-out 0.5s;
        }
        &::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        [type='number'] {
          -moz-appearance: textfield;
        }
      }
    }
  }

  .carCoordenadas__form-input-error {
    border: 1px solid #cc284e;
  }

  .body__container {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    color: #383838;
    &-img {
      display: flex;
      width: 144px;
      height: 144px;
    }
  }

  .body__title {
    height: 24px;
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 12px;
    margin-top: 24px;
    margin-bottom: 12px;
    font-family: 'Ubuntu-Bold';
  }

  .body__content {
    width: 100%;
    max-width: 400px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }

  .cardCoordenadas__btn {
    width: 100%;
    max-width: 296px;
    padding: 0px;
  }
`;

export default Wrapper;
