import styled from 'styled-components';

const InputContainer = styled.div`
  padding-left: 79px;
  padding-right: 79px;
  margin-bottom: 16px;

  & small {
    display: none;
  }

  .factor-coordenadas-input {
    font-size: 30px;
    text-align: center;
    border-radius: 2px;
    height: 48px;
  }

  .factor-coordenadas-input small {
    display: none;
  }

  .factor-coordenadas-label-container {
    margin-bottom: 8px;
    width: 100%;
    text-align: left;
  }

  @media screen and (max-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  .homeKitBox {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    color: #383838;
    &__containerImg {
    }
    &__title {
      width: 100%;
      max-width: 404px;
      font-size: 18px;
      line-height: 24px;
      font-weight: bold;
      text-align: center;
      margin-top: 24px;
      margin-bottom: 12px;
      font-family: 'Ubuntu-Bold';
      @media (max-width: 500px) {
        font-size: 16px;
      }
    }
    &__content {
      max-width: 370px;
      width: 100%;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      @media (max-width: 500px) {
        max-width: 256px;
      }
    }
    &__btn {
      width: 100%;
      max-width: 214px;
      border-radius: 4px;
      background-color: #e81e2b;
      color: white;
      font-size: 14px;
      padding: 14px 24px 14px 24px;
      margin-top: 32px;
      margin-bottom: 10px;
      text-align: center;
      font-weight: 500;
      font-family: 'Ubuntu-Medium';
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  @media screen and (max-width: 768px) {
    .factor-button-style {
      width: 246px ;
    }
  }
`;

export default InputContainer;
