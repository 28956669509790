import React from 'react';

import PropTypes from 'prop-types';

import Alert from '@coopeuch-components/web/atoms/Alert';

function InfoAlert({ children }) {
  return (
    <div className="row bmt-3 bmt-md-4" id="credito">
      <div className="col-xs-12">
        <Alert bgType="info" show>
          {children}
        </Alert>
      </div>
    </div>
  );
}

InfoAlert.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export default InfoAlert;
