const initState = {
  authenticated: false,
  error: {
    message: '',
    link: {},
    id: null,
  },
  logout: {
    message: ''
  }
};

export default initState;
