import React from 'react';
import PropTypes from 'prop-types';

import { formatSecondToMinutes } from '../../../../../utils/formatters';

import Wrapper, { Card } from './styles';

const CountdownCard = (props) => {
  const { active, timer } = props;

  return (
    <Wrapper>
      <Card active={active}>
        <label className="countdown-card-label">
          {formatSecondToMinutes(timer)} Min para autorizar
        </label>
      </Card>
    </Wrapper>
  );
};

CountdownCard.propTypes = {
  active: PropTypes.bool,
  timer: PropTypes.number,
};

CountdownCard.defaultProps = {
  active: false,
  timer: 0,
};

export default CountdownCard;
