import styled from "styled-components";

export const Text = styled.p`
color: #383638;
letter-spacing: 0;
text-align: center;
font-size: ${({ textSize }) => `${textSize}px`};
line-height: ${({ size }) => `${size}px`};
font-weight: ${({ weight }) => weight};

`