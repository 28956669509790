import React, { Component } from 'react';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import PageTitle from '../../../components/atoms/PageTitle';
import SimuladorCreditoDeConsumoTemplate from '../../../components/templates/CreditoDeConsumo/Simulador';

const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <PageTitle
          title="Crédito de Consumo"
          icon={<LoadIcon src={`${ICON_PATH}cash.svg`} color="black" />}
        />
        <div className="w-100 bpx-3 bpx-md-3 margin-after-title text-left">
          <SimuladorCreditoDeConsumoTemplate />
        </div>
      </>
    );
  }
}

Index.displayName = 'Container_Simulador';
export default Index;
