import produce from 'immer';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { cuentaAhorroActions } from '../../../../../application/actions/cuentaAhorro';
import CuentaTemplate from '../../../../components/templates/CuentaAhorro/SolicitudCAFlujo/Cuenta';

// logger

import logger from '../../../../utils/logger';
import { registerGA } from '../../../../utils/metrics';

class Cuenta extends Component {
  state = { warningCuentaAhorroVivienda: false, trackContratacion: null, showScreenError: false };

  componentDidMount() {
    const { cuentasAhorro, persona } = this.props;

    const viviendaAR03 = cuentasAhorro?.filter(
      (cuenta) => cuenta.codigoProducto === 'AR03' || cuenta.codigoProducto === 'AR08'
    );

    if (viviendaAR03.length > 0) {
      this.setState(
        produce((draft) => {
          draft.warningCuentaAhorroVivienda = true;
        })
      );
    }
    registerGA({
      event: 'vpv-privado',
      page: '/tef/cuenta-ahorro/solicitud/paso1',
    });
  }

  componentDidUpdate = (prevProps) => {
    const { handleStep, contratacionIniciar, fetchContratacionIniciarReset } = this.props;
    if (contratacionIniciar !== prevProps.contratacionIniciar) {
      if (contratacionIniciar.success) {
        handleStep(2);
      }
      if (contratacionIniciar.error) {
        this.setState(
          produce((draft) => {
            draft.showScreenError = true;
          })
        );
        fetchContratacionIniciarReset();
      }
    }
  };

  componentWillUnmount = () => {
    this.setState(
      produce((draft) => {
        draft.showScreenError = false;
      })
    );
  };

  handleClickReturn = () => {
    //marca volver paso 1
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Volver',
    });
    const { handleClickReturnSolicitudCA } = this.props;
    handleClickReturnSolicitudCA();
  };

  handleClickContinue = () => {
    const { persona, cuentaSeleccionada, fetchContratacionIniciar } = this.props;
    const baseRut = String(persona?.rut.replace('-', ''));
    fetchContratacionIniciar({ producto: cuentaSeleccionada?.code });

    //marca continuar a paso 2
    registerGA({
      event: 'eve',
      'eve-acc': '/tef/cuenta-ahorro/solicitud/paso1',
      'eve-cat': 'Click',
      'eve-lab': 'Continuar',
    });

    if (cuentaSeleccionada?.code) {
      let accion;
      if (cuentaSeleccionada.code === 'AN07') {
        accion = 'CA-SOLICITUDPRODUCTOS_NOMINAL';
      } else if (cuentaSeleccionada.code === 'AR08') {
        accion = 'CA-SOLICITUDPRODUCTOS_VIVIENDA';
      } else {
        accion = 'CA-SOLICITUDPRODUCTOS-REAJUSTABLE';
      }

      logger(null, {
        rut: baseRut.slice(0, -1),
        digito_verificador: baseRut.slice(-1),
        accion,
        informacion_adicional: {},
      });
    }
  };

  render() {
    return (
      <CuentaTemplate
        {...this.props}
        {...this.state}
        handleClickReturn={this.handleClickReturn}
        handleClickContinue={this.handleClickContinue}
      />
    );
  }
}

const mapStateToProps = (store) => ({
  contratacionIniciar: store.entities.cuentasAhorro.contratacionIniciar,
  persona: store.entities?.persona,
});

const mapDispatchToProps = (dispatch) => ({
  fetchContratacionIniciar(props) {
    dispatch(cuentaAhorroActions.fetchContratacionIniciar(props));
  },
  fetchContratacionIniciarReset() {
    dispatch(cuentaAhorroActions.fetchContratacionIniciarReset());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cuenta));
