import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { depositoAPlazoActions } from '../../../../application/actions/depositoAPlazo';

import LiquidacionComprobanteTemplate from '../../../components/templates/DepositoAPlazo/LiquidacionComprobante';
import { useShallowStore } from '../../../utils/hooks';
import { dateFormat } from '../../../utils/dates';
import { capitalize, formatCurrency } from '../../../utils/functions';
import { hasValuesOnObject } from '../../../utils/validators';
import { registerDY, registerGA } from '../../../utils/metrics';
import { loguearRutUsuario } from '../../../utils/formateoParametriaLog';
import { agregarFormatoDecimal } from '../../../utils/formatters';

function LiquidacionComprobante(props) {
  const { history } = props;

  const { idSimulacion } = history?.location?.state || {};

  const dispatch = useDispatch();

  const error = useShallowStore(({ entities }) => entities.depositoAPlazo.liquidarComprobanteError);
  const detalles = useShallowStore(({ entities }) => entities.depositoAPlazo.liquidarComprobante);
  const loading = useShallowStore(({ ui }) => ui.loaders.isLoadingComprobanteLiquidar);

  const {
    correoElectronico,
    fechaInicio,
    fechaLiquidacion,
    fechaVencimiento,
    montoGanancia,
    montoInvertido,
    montoVencimiento,
    numeroCuentaVistaDestino,
    numeroDeposito,
    tasaBase,
    tasaPeriodo,
    tipoDeposito,
  } = detalles || {};

  const detallesComprobante = useMemo(
    () => [
      {
        id: 1,
        name: 'Cuenta de destino',
        value: `Vista: Nº: ${numeroCuentaVistaDestino || ''}`,
      },
      {
        id: 2,
        name: 'Ganancia',
        value: `${formatCurrency(montoGanancia) || ''}`,
      },
      {
        id: 3,
        name: 'Fecha de inicio',
        value: `${fechaInicio ? capitalize(dateFormat(fechaInicio)) : ''}`,
      },
      {
        id: 4,
        name: 'Fecha de vencimiento',
        value: `${fechaVencimiento ? capitalize(dateFormat(fechaVencimiento)) : ''}`,
      },
      {
        id: 5,
        name: 'Tipo de depósito',
        value: `${tipoDeposito || ''}`,
      },
      {
        id: 6,
        name: 'Tasa base',
        value: `${tasaBase ? agregarFormatoDecimal(tasaBase) : ''}%`,
      },
      {
        id: 7,
        name: 'Tasa período',
        value: `${tasaPeriodo ? agregarFormatoDecimal(tasaPeriodo) : ''}%`,
      },
    ],
    [detalles]
  );

  const goHome = useCallback(() => {
    history.push('/ecd/productos/deposito-a-plazo');
  }, [history]);

  const fetchComprobante = useCallback(() => {
    dispatch(
      depositoAPlazoActions.fetchComprobanteLiquidar({
        numeroDeposito: idSimulacion,
      })
    );
  }, [history]);

  const clearLastLiquidacion = useCallback(() => {
    dispatch(depositoAPlazoActions.clearLastLiquidacion());
  }, []);

  useEffect(() => {
    if (hasValuesOnObject(detalles)) {
      registerGA({
        event: 'vpv-privado',
        page: '/tef/mis-productos/deposito-a-plazo/liquidar/paso-2',
        monto: montoVencimiento,
        tasa_interes_mensual: tasaBase,
        producto: tipoDeposito,
      });
    }
  }, [detalles]);

  useEffect(() => {
    fetchComprobante();
    clearLastLiquidacion();
    registerDY({ type: 'CART', data: ['deposito_plazo'] });
    loguearRutUsuario('DAP_LIQUIDAR-P2');
  }, []);

  return (
    <LiquidacionComprobanteTemplate
      error={error}
      loading={loading}
      detallesComprobante={detallesComprobante}
      montoInvertido={montoInvertido}
      montoVencimiento={montoVencimiento}
      fechaLiquidacion={fechaLiquidacion}
      correo={correoElectronico}
      numeroDeposito={numeroDeposito}
      goHome={goHome}
      fetchComprobante={fetchComprobante}
    />
  );
}

export default LiquidacionComprobante;
