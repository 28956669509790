import { put, takeLatest, call, spawn, all } from 'redux-saga/effects';
import { fetchCampanaCrmActions } from '../../../application/actions/campanaCrm';
import { loadersActions } from '../../../application/actions/ui_loaders';

// api
import api from '../../api';

const apiCampanaCrm = api(process.env.REACT_APP_CAMPANACRM_API);

export function* fetchCampanaCrm() {
  try {
    yield put(loadersActions.fetchLoadingCampanaCrm({ isLoading: true }));
    const { data, error } = yield call(apiCampanaCrm.get, 'campana/v1/obtener');
    if (data) {
      yield put(fetchCampanaCrmActions.fetchCampanaCrmSuccess({ data }));
    } else {
      yield put(fetchCampanaCrmActions.fetchCampanaCrmFailed({ error }));
    }
    yield put(loadersActions.fetchLoadingCampanaCrm({ isLoading: false }));
  } catch (error) {
    yield put(loadersActions.fetchLoadingCampanaCrm({ isLoading: false }));
    yield put(fetchCampanaCrmActions.fetchCampanaCrmFailed({ error }));
  }
}

export function* watchFetchCampanaCrm() {
  yield takeLatest(fetchCampanaCrmActions.fetchCampanaCrm.type, fetchCampanaCrm);
}

export default function* rootSaga() {
  yield spawn(watchFetchCampanaCrm);
}
