import styled from 'styled-components';

const Wrapper = styled.div`
  .titleDetalle {
    font-size: 24px;
    font-family: 'Ubuntu-Bold';
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  .subTitleDetalle {
    font-size: 19px;
    font-family: 'Ubuntu-Bold';
    padding: 15px 0px 15px 30px;
    @media (max-width: 500px) {
      font-size: 16px;
      padding: 16px;
    }
  }
  .body__container {
    display: flex;
    flex-flow: column wrap;
  }

  .card__content {
    width: 100%;
    height: 50px;
    font-size: 14px;
  }

  @media (max-width: 500px) {
    .card__content {
      width: 100%;
      height: auto;
    }
  }

  .color {
    background-color: #f6f6f6;
  }

  .header__container {
    display: flex;
    justify-content: space-between;
  }

  .header__descargar {
    width: 100%;
    max-width: 110px;
    display: flex;
    justify-content: space-between;
    line-height: 26px;
    border: 0px;
    background: transparent;
  }

  .header__descargar p {
    font-family: 'Ubuntu-Medium';
    font-size: 14px;
    color: #ec1b2b;
  }

  @media (max-width: 500px) {
    .header__descargar {
      width: auto;
      padding-right: 15px;
      justify-content: right;
    }

    .header__descargar p {
      display: none;
    }
  }

  .header__descargar p:hover {
    color: red;
  }

  .card__content-info {
    display: flex;
    padding-left: 30px;
    line-height: 50px;
  }

  @media (max-width: 500px) {
    .card__content-info {
      width: 100%;
      height: 50px;
      line-height: 22px;
      align-items: center;
      padding-left: 16px;
    }
  }

  .card__content-title {
    width: 100%;
    max-width: 280px;
    font-family: 'Ubuntu-Bold';
  }

  .card__content-name {
    width: 100%;
    max-width: 170px;
  }

  .bodyFooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 991px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    &__container {
      &-text {
        font-size: 14px;
        display: flex;
        align-items: center;
        @media (max-width: 991px) {
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 5px;
        }
        &--bold {
          font-family: 'Ubuntu-Bold';
          margin-left: 5px;
          @media (max-width: 991px) {
            margin-left: 0px;
            margin-bottom: 10px;
          }
        }
      }
    }
    &__legal {
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        margin-top: 20px;
        flex-direction: row-reverse;
      }
      &-text {
        font-family: 'Ubuntu-Regular';
        font-size: 12px;
        color: grey;
        margin-right: 15px;
        @media (max-width: 991px) {
          margin-right: 0px;
          margin-left: 15px;
        }
      }
      img {
        max-width: 90px;
        height: 90px;
      }
    }
  }
`;

export default Wrapper;
