import React from 'react';
import { Link } from 'react-router-dom';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import Typography from '@coopeuch-components/web/atoms/Typography';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import ButtonBack from '../../../../organism/ActivacionTarjetasKit/ButtonBack';
// Utils
import { activacionTexts } from '../../../../../containers/ActivacionTarjetasKit/texts';
// Styled
import Wrapper from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

const CardKit = ({ kitData, handleClickActivate, handleReturnInicio }) => {
  return (
    <Wrapper>
      <CardBox>
        <CardBox.Header>
          <Typography tag="p" fontSize={24} fontWeight="bold" className="titleActivacionKit">
            Tus tarjetas están listas para ser activadas
          </Typography>
        </CardBox.Header>
        <CardBox.Body className="homeKitBox">
          <div className="homeKitBox__containerImg">
            <LoadIcon src={`${iconPath}kit/kit-tarjetas.svg`} />
          </div>
          <p className="homeKitBox__title">Cuenta Vista Coopeuch</p>
          <p className="homeKitBox__content">
            Para comenzar, debes activar tus tarjetas de: <br />
            débito N° {kitData.data?.numeroTarjetaDebito} y coordenadas N°{' '}
            {kitData.data?.numeroTarjetaCoordenadas}.
          </p>
          <Link
            to="/ecd/seguridad/activacion-tarjetas-kit/activacion"
            className="homeKitBox__btn"
            onClick={() => handleClickActivate()}
          >
            Activar
          </Link>
        </CardBox.Body>
      </CardBox>
      <ButtonBack link="/ecd/inicio" text={activacionTexts.BUTTON_RETURN} onClick={() => handleReturnInicio()}/>
    </Wrapper>
  );
};

export default CardKit;
