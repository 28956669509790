import React from 'react';
import SimulacionTemplate from './Simulacion';
import Wrapper from './styles';

const Index = () => (
  <Wrapper>
    <div className="row bmy-3 bmy-md-4">
      <div className="col-xs-12">
        <SimulacionTemplate />
      </div>
    </div>
  </Wrapper>
);

Index.displayName = 'Template_Simulador';
export default Index;
