export const registerGA = (params) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push(params);
};

export const registerDY = (params) => {
  const DY = window.DY || {};
  if (typeof DY.API === 'function') {
    DY.API('spa', {
      context: params,
      url: window.location.href,
      countAsPageview: true,
    });
  }
};
