import styled from 'styled-components';

const Button = styled.div`
  
  width: 100%;
  heigth: 100%;
  max-width: 296px;
  max-heigth: 48px;
  border-radius: 4px;
  background-color: #d6d0d5;
  padding: 14px 24px;
  text-align: center;
  color: white;
  font-family: 'Ubuntu-Medium';
  font-size: 14px;
  margin-top: 24px
`;

export default Button;
