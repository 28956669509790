import styled from 'styled-components';

const Wrapper = styled.div`
  @media screen and (max-width: 766px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const ExpandContent = styled.div`
  align-items: start !important;

  h5 {
    font-size: 18px;
    margin: 0 10px;
    width: 75%;
  }

  ul {
    list-style: none;
    line-height: 26px;
    margin-right: 24px;

    li {
      padding-bottom: 10px;
    }

    div {
      padding-bottom: 8px;
      padding-left: 12px;
    }
  }

  .bullet-red::before {
    font-size: 24px;
    content: '•';
    color: #e81e2b;
    position: absolute;
    padding-top: 1px;
  }

  .bullet-red {
    span {
      padding-left: 12px;
    }
  }
`;

export default Wrapper;
