import React from 'react';

// Ui
import Skeleton from 'react-loading-skeleton';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Select from '../../../../../../atoms/Select';
import InputLabel from '../../../../../../atoms/InputLabel';

// Styles
import { AlertWrapper } from './styles';

const Index = ({
  fetchingData,
  region,
  comuna,
  regionOptions,
  comunaOptions,
  calle,
  numeroCasa,
  otrosCasa,
  poseeFactibilidad,
  handleOnChangeInput,
  handleOnChangeRegion,
  handleOnChangeComuna,
}) => {
  return (
    <>
      <div className="row">
        <div className="col-xs-12">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Alert
              className="alert-info col-xs bpy-md-3 "
              bgType="warning"
              id="alert-factibilidad-ok"
              show
            >
              Recuerda que
              <b> sólo tú puedes recibir tus productos, </b>
              con tu cédula de identidad en mano.
            </Alert>
          )}
        </div>
        {!!region && !!comuna && (
          <div className="col-xs-12">
            <AlertWrapper className="bmb-3 bmt-3">
              {poseeFactibilidad ? (
                <Alert
                  className="alert-info col-xs bpy-md-3 "
                  bgType="info"
                  id="alert-factibilidad-ok"
                  show
                >
                  Hemos detectado que posee factibilidad de despacho a domicilio, te contactaremos
                  para agendar fecha y hora de entrega de tus tarjetas
                </Alert>
              ) : (
                <Alert
                  className="alert-info col-xs bpy-md-3 "
                  bgType="warning"
                  id="alert-factibilidad-ok"
                  show
                >
                  <b>Despacho a Domicilio.</b> La dirección ingresada no tiene posibilidad de
                  despacho, retira tus tarjetas en la sucursal más cercana en la opción “Retiro en
                  Sucursal”
                </Alert>
              )}
            </AlertWrapper>
          </div>
        )}
      </div>
      <div className="row bmt-3">
        <div className="col-xs-12 col-md-6">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Select
              value={region ? region.value : ''}
              title="Región"
              placeholder="Selecciona Región"
              labelClass="bmy-3 text-bold"
              options={regionOptions}
              onChange={handleOnChangeRegion}
            />
          )}
        </div>
        <div className="col-xs-12 col-md-6">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <Select
              value={comuna ? comuna.value : ''}
              title="Comuna"
              placeholder="Selecciona Comuna"
              labelClass="bmy-3 text-bold"
              options={comunaOptions}
              onChange={handleOnChangeComuna}
            />
          )}
        </div>
      </div>
      <div className="row bmt-3">
        <div className="col-xs-12 col-md-6">
          {fetchingData ? (
            <Skeleton />
          ) : (
            <InputLabel
              id="calle"
              onChange={handleOnChangeInput}
              value={calle}
              name="calle"
              placeholder="Ingresa calle o avenida"
              labelClass="bmy-3 text-bold"
              size={48}
              title="Calle"
            />
          )}
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              {fetchingData ? (
                <Skeleton />
              ) : (
                <InputLabel
                  id="numeroCasa"
                  onChange={handleOnChangeInput}
                  value={numeroCasa}
                  name="numeroCasa"
                  placeholder="ej: 340 B"
                  labelClass="bmy-3 text-bold"
                  size={48}
                  title="Número"
                />
              )}
            </div>
            <div className="col-xs-12 col-md-6">
              {fetchingData ? (
                <Skeleton />
              ) : (
                <InputLabel
                  id="otrosCasa"
                  onChange={handleOnChangeInput}
                  value={otrosCasa}
                  name="otrosCasa"
                  placeholder="ej: Depto"
                  labelClass="bmy-3 text-bold"
                  size={48}
                  title="Depto/Villa/Otro"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
