/* eslint-disable no-nested-ternary */
import React from 'react';

// Ui
import Typography from '@coopeuch-components/web/atoms/Typography';
import Skeleton from 'react-loading-skeleton';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';

import DashboardCard from '../../../organism/DashboardCard';
import InputLabel from '../../../atoms/InputLabel';

// Utils
import { formatRut, capitalizeStrFormat, isValidEmail } from '../../../../utils/formatters';
// import { textsColors } from '../../../../assets/styles/index';

// Styles
import Message, { MessageError, FalseInput } from './styles';

const ICON_PATH = process.env.REACT_APP_CLOUDFRONT_ICONS;

const DatosDeContacto = ({
  data,
  datosContacto,
  loading,
  onChangePhones,
  onChangeEmail,
  userUpdate,
  loadingHabilitadoCell,
  habilitadoCell,
  loadingHabilitadoEmail,
  habilitadoEmail,
  loadingDatosContacto,
  evaluatePhone,
  loadingDirecciones,
  direccionPersona,
  datosContactoRef,
  factoresSeguridad,
}) => {
  const errorMessage = (message) => (
    <MessageError className="input-error-simulacion-cdc text-size-s red mt-6">
      <div className="row">
        <LoadIcon
          src={`${ICON_PATH}circle_close.svg`}
          size={14}
          color="#CC284E"
          className="mr-8 red"
        />
        <span id="input-error-message">{message}</span>
      </div>
    </MessageError>
  );
  return (
    <div ref={datosContactoRef}>
      {/* <DashboardCard className="ml-0 mr-0 bmb-xs-3 bmy-md-4">
        <DashboardCard.Head
          title="Datos de contacto"
          borderBottom="complete"
          classes="btext-xs-left bmt-xs-3 bmt-sm-4"
        />
        <DashboardCard.Body classes="text-left-destinatarios black"> */}
          {/*{userUpdate.dContacto && (
            <Message className="mt-24">
              <div className="align">
                <LoadIcon
                  src={`${ICON_PATH}success_circle_check.svg`}
                  size={18}
                  color="#CC284E"
                  className="ml-20 mr-8 green"
                />
                <Typography id="input-success-message" tag="p" fontSize={16} color="#006B14">
                  Los datos fueron editados correctamente.
                </Typography>
              </div>
            </Message>
          )}*/}

          {/* <p className="pb-32 pt-24 bml-1">
            
          </p> */}
          
          <div className="row">
            {/* <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loading ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <>
                    <p className=" text-bold">RUT</p>

                    <FalseInput className="disabled mt-14">
                      {data?.rut ? formatRut(data.rut) : ''}
                    </FalseInput>
                  </>
                )}
              </div>
            </div> */}

            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loading ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <>
                    <p>Nombre</p>
                    <br />
                    <span><strong>{`${capitalizeStrFormat(
                      data?.primerNombre || ''
                    )} ${capitalizeStrFormat(data?.apellidoPaterno || '')} ${capitalizeStrFormat(
                      data?.apellidoMaterno || ''
                    )}`}</strong></span>
                  </>
                )}
              </div>
            </div>

            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loadingDirecciones ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <>
                    <p>Dirección</p>
                    <br />
                    <span><strong>
                      {direccionPersona?.direccion}
                    </strong></span>
                  </>
                )}
              </div>
            </div>
            <div className="col-md-12 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                <p>Los campos señalados con (*) son obligatorios.</p>
              </div>
            </div>

            {/* <! –– Editable inputs ––> */}
            
            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loadingHabilitadoEmail ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : habilitadoEmail ? (
                  <>
                    <p className="bmb-3 text-bold">Email (*)</p>

                    <FalseInput className="disabled mt-18">
                      {datosContacto?.correo}
                    </FalseInput>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    value={datosContacto?.correo}
                    onChange={onChangeEmail}
                    name="correo"
                    id="correo"
                    size={48}
                    errorMessage={
                      !isValidEmail(datosContacto?.correo)
                        ? errorMessage('El email ingresado no es válido')
                        : null
                    }
                    title="Email(*)"
                  />
                )}
                {/* {loadingDatosContacto ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    value={datosContacto?.correo}
                    onChange={onChangeEmail}
                    name="correo"
                    id="correo"
                    size={48}
                    errorMessage={
                      !isValidEmail(datosContacto?.correo)
                        ? errorMessage('El email ingresado no es válido')
                        : null
                    }
                    title="Email(*)"
                  />
                )} */}
              </div>
            </div>
            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loadingHabilitadoCell ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : habilitadoCell ? (
                  <>
                    <p className="bmb-3 text-bold">Teléfono Celular (*)</p>

                    <FalseInput className="disabled mt-18">
                      {datosContacto?.telefonoCelular}
                    </FalseInput>
                  </>
                ) : (
                  
                  <InputLabel
                    // disabled={habilitadoCell}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    rounded={2}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    // inputClass={`${habilitadoCell ? 'disabled' : ''}`}
                    value={datosContacto?.telefonoCelular}
                    onChange={onChangePhones}
                    bottomMessage={<><br /><span style={{color: 'grey'}} className="text-size-s">
                      A este número serán enviados tus códigos de seguridad para autorizar transacciones.</span></>}
                    placeholder="Ej: 83232443"
                    name="telefonoCelular"
                    id="telefonoCelular"
                    size={48}
                    errorMessage={
                      evaluatePhone(datosContacto?.telefonoCelular, 13)
                        ? null
                        : factoresSeguridad?.length === 0 ? null : errorMessage('El teléfono Celular ingresado no es válido')
                    }
                    title="Teléfono celular (*)"
                  />
                )}
              </div>
            </div>

            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loadingDatosContacto ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    placeholder="Ej: +56 223232443"
                    value={datosContacto?.telefonoParticular}
                    onChange={onChangePhones}
                    name="telefonoParticular"
                    id="telefonoParticular"
                    size={48}
                    errorMessage={
                      evaluatePhone(datosContacto?.telefonoParticular, 13)
                        ? null
                        : errorMessage('El teléfono Particular ingresado no es válido')
                    }
                    title="Teléfono particular"
                  />
                )}
              </div>
            </div>

            <div className="col-md-6 mb-32 col-sm-12 w-100">
              <div className="ml-4 mr-4">
                {loadingDatosContacto ? (
                  <>
                    <div className="bmb-2 w-25">
                      <Skeleton height={10} />
                    </div>
                    <div className="mb-14 w-100">
                      <Skeleton height={50} />
                    </div>
                  </>
                ) : (
                  <InputLabel
                    rounded={2}
                    disabled={(factoresSeguridad?.length === 0 && true)}
                    borderColor="#646569"
                    labelClass="bmb-3 text-bold"
                    placeholder="Ej: +56 223232443"
                    value={datosContacto?.telefonoComercial}
                    onChange={onChangePhones}
                    name="telefonoComercial"
                    id="telefonoComercial"
                    size={48}
                    errorMessage={
                      evaluatePhone(datosContacto?.telefonoComercial, 13)
                        ? null
                        : errorMessage('El teléfono Comercial ingresado no es válido')
                    }
                    title="Teléfono comercial"
                  />
                )}
              </div>
            </div>

          </div>
        {/* </DashboardCard.Body>
      </DashboardCard> */}
    </div>
  );
};

DatosDeContacto.displayName = 'DatosDeContacto_Template';
export default DatosDeContacto;
