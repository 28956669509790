import React from 'react';
import CardBox from '@coopeuch-components/web/molecules/CardBox';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import { Message } from './styles';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;
const index = () => {
  return (
    <div className="col-xs-12 bmy-3">
      <CardBox>
        <CardBox.Header>Resultados de la Búsqueda</CardBox.Header>
        <CardBox.Body>
          <Message>
            <LoadIcon
              className="icon"
              src={`${iconPath}copywriting.svg`}
              color="#979797"
              size={20}
            />
            <span className="text">
              <b>¡Nada por aquí!</b> No encontramos resultados para tu búsqueda, intenta con otra
              selección.
            </span>
          </Message>
        </CardBox.Body>
      </CardBox>
    </div>
  );
};

export default index;
