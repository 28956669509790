import styled from 'styled-components';

const Composition = styled.div`
  .sin-remanente-chart {
    stroke: #e4e5ed;
    path {
      fill: #e4e5ed;
    }
  }
  .ct-chart-remanente {
    display: flex;
    justify-content: center;
  }

  .ct-series-disabled {
    fill: rgba(213, 208, 213, 0.75);
    border-left: 10px solid rgba(213, 208, 213, 0.75);
  }

  .ct-series-a {
    fill: ${({ enableChart }) => (enableChart ? '#276d92' : 'rgba(213, 208, 213, 0.75)')};
    border-left: ${({ enableChart }) =>
      `10px solid ${enableChart ? '#276d92' : 'rgba(213, 208, 213, 0.75)'}`};
  }

  .ct-series-b {
    fill: ${({ enableChart }) => (enableChart ? '#1caa90' : 'rgba(213, 208, 213, 0.75)')};
    border-left: ${({ enableChart }) =>
      `10px solid ${enableChart ? '#1caa90' : 'rgba(213, 208, 213, 0.75)'}`};
  }

  .ct-series-c {
    fill: ${({ enableChart }) => (enableChart ? '#04584c' : 'rgba(213, 208, 213, 0.75)')};
    border-left: ${({ enableChart }) =>
      `10px solid ${enableChart ? '#04584c' : 'rgba(213, 208, 213, 0.75)'}`};
  }

  .ct-series-d {
    fill: ${({ enableChart }) => (enableChart ? '#e3d076' : 'rgba(213, 208, 213, 0.75)')};
    border-left: ${({ enableChart }) =>
      `10px solid ${enableChart ? '#e3d076' : 'rgba(213, 208, 213, 0.75)'}`};
  }

  .ct-series-e {
    fill: ${({ enableChart }) => (enableChart ? '#cc284e' : 'rgba(213, 208, 213, 0.75)')};
    border-left: ${({ enableChart }) =>
      `10px solid ${enableChart ? '#cc284e' : 'rgba(213, 208, 213, 0.75)'}`};
  }

  .remanente--wrapper-table {
    & div {
      div {
        margin-bottom: 15px;
        align-items: center;
      }

      p {
        color: #383638;
      }
    }

    & span {
      height: 26px;
    }

    & div:first-child {
      padding: 0 0 16px 0;
      margin-bottom: 16px;
      margin-left: 0px;
    }

    & div:nth-last-child(-n + 5) {
      border-top: 1px solid #e9e9e9;
      margin-top: 12px;
      padding-top: 12px;
    }

    & div:last-child {
      margin: 0;
      margin-top: 12px;

      div {
        border: 1px solid transparent;
        padding: 0;
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 520px) {
    .remanente--wrapper-table {
      padding: 0 8px;
      & div,
      & span {
        display: flex;
        justify-content: space-between;
      }

      & div:first-child {
        padding: 0 8px 16px 0;
        margin-right: 0;
      }
    }
  }
`;

export default Composition;
