// eslint-disable no-param-reassign
import { createSlice, createAction } from '@reduxjs/toolkit';
import initialState from '../../../domain/entities/campanacrm';

// ------- SAGA ------ //
function fetchCampanaCrmSuccess(state, action) {
    const { data } = action.payload;
    state.campanaCrmData.data = data;
    state.campanaCrmData.error = null;
}

function fetchCampanaCrmFailed(state, action) {
    const { error } = action.payload;
    state.campanaCrmData.data = null;
    state.campanaCrmData.error = error;
}

const fetchCampanaCrm = createAction('fetchCampanaCrm');

const fetchCampanaCrmReducer = createSlice({
    name: 'fetchCampanaCrm',
    initialState,
    reducers: {
        fetchCampanaCrmSuccess,
        fetchCampanaCrmFailed,
    }
});

export const fetchCampanaCrmActions = {
    ...fetchCampanaCrmReducer.actions,
    fetchCampanaCrm,
};

export default fetchCampanaCrmReducer.reducer;
