import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';

import DashboardCard from '../../../organism/DashboardCard';
import PageTitle from '../../../atoms/PageTitle';
import Stepper from '../../../../components/organism/Stepper';
import ServiceError from '../../../organism/DepositoAPlazo/ServiceError';
import LoadIcon from '@coopeuch-components/web/atoms/LoadIcon';
import Alert from '@coopeuch-components/web/atoms/Alert';
import Table from '@coopeuch-components/web/molecules/Table';
import Button from '@coopeuch-components/web/atoms/Button';

import SkeletonComprobante from './Skeleton';
import Wrapper from './styles';
import { capitalize, formatCurrency } from '../../../../utils/functions';
import { dateFormat } from '../../../../utils/dates';

const iconPath = process.env.REACT_APP_CLOUDFRONT_ICONS;

function LiquidacionComprobante(props) {
  const {
    error,
    loading,
    detallesComprobante,
    fechaLiquidacion,
    numeroDeposito,
    montoInvertido,
    montoVencimiento,
    correo,
    goHome,
    fetchComprobante,
  } = props;

  const steps = useMemo(() => ['Pago', 'Comprobante'], []);

  return (
    <Wrapper className="w-100">
      <PageTitle
        id="dap-title-page"
        title="Liquidación Depósito a Plazo"
        icon={<LoadIcon src={`${iconPath}ico_dap.svg`} color="#333" />}
      />

      <div className="w-100 margin-after-title content-dap">
        {error ? (
          <div className="mt-24">
            <ServiceError withCard onClick={fetchComprobante} />
          </div>
        ) : (
          <Fragment>
            <Stepper className="start-xs" position={2} steps={steps} />

            {loading ? (
              <SkeletonComprobante />
            ) : (
              <Fragment>
                <Alert
                  bgType="success"
                  iconType="success"
                  show
                  className="pr-0 dap--card dap-top--card alert--wrapper"
                >
                  ¡Felicidades! Tu Depósito a Plazo fue liquidado con éxito.
                </Alert>
                <div className="row">
                  <div className="col-xs-12">
                    <DashboardCard classes="dap--card">
                      <DashboardCard.Head title="Comprobante" borderBottom="complete" />
                      <DashboardCard.Body classes="pt-24 pl-32 pr-32 pb-24 dap--card--content">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10 start-xs black">
                            <p className="text-bold text-size-s pb-10">
                              Depósito a Plazo liquidado el{' '}
                              {capitalize(dateFormat(fechaLiquidacion))}
                            </p>
                            <p className="text-bold text-size-xxl pb-10">Nº {numeroDeposito}</p>
                            <div>
                              <span>Te enviamos copia de este comprobante a tu mail: </span>
                              <span className="text-bold">{correo}</span>
                            </div>
                          </div>
                        </div>
                      </DashboardCard.Body>
                    </DashboardCard>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <DashboardCard classes="dap--card">
                      <DashboardCard.Head title="Detalle de Liquidación" borderBottom="complete" />
                      <DashboardCard.Body classes="pt-24 pl-32 pr-32 pb-24 dap--card--content">
                        <div className="row">
                          <div className="col-xs-12 start-xs black">
                            <Table
                              className="mb-4 hide-on-mobile"
                              borderColor="trasparent"
                              fullWidth
                              alignContent="left"
                              stripeColor="#F6F3F5"
                              stripeReverse
                            >
                              <Table.Header
                                outterClassName="dap--comprobante--header mb-24"
                                data={[
                                  <div className="pl-16">
                                    <p className="text-size-s white">Monto invertido</p>
                                    <p className="text-bold text-size-jumbo monto white">
                                      {formatCurrency(montoInvertido)}
                                    </p>
                                  </div>,
                                  <div className="pl-16">
                                    <p className="text-size-s white">Monto al vencimiento</p>
                                    <p className="text-bold text-size-jumbo monto white">
                                      {formatCurrency(montoVencimiento)}
                                    </p>
                                  </div>,
                                ]}
                              />
                              <div className="mt-24" />
                              <Table.Body
                                data={detallesComprobante}
                                renderItems={(item, TR, TD) => (
                                  <TR key={`detalle-dap-web-${item.id}`}>
                                    <TD className="pt-16 pb-16 pl-16 pr-16 black">{item.name}</TD>
                                    <TD className="pt-16 pb-16 pl-16 pr-16 black">{item.value}</TD>
                                  </TR>
                                )}
                              />
                            </Table>

                            <div className="hide-on-desktop">
                              <Table.Header
                                outterClassName="dap--comprobante--header mb-24"
                                data={[
                                  <div className="pl-16">
                                    <p className="text-size-s white">Monto invertido</p>
                                    <p className="text-bold text-size-jumbo monto white">
                                      {formatCurrency(montoInvertido)}
                                    </p>
                                  </div>,
                                  <div className="pl-16">
                                    <p className="text-size-s white">Monto al vencimiento</p>
                                    <p className="text-bold text-size-jumbo monto white">
                                      {formatCurrency(montoVencimiento)}
                                    </p>
                                  </div>,
                                ]}
                              />
                              <Table
                                className="mb-4 "
                                borderColor="trasparent"
                                fullWidth
                                alignContent="left"
                                stripeColor="#F6F3F5"
                                stripeReverse
                              >
                                <div className="mt-16" />
                                <Table.Body
                                  data={detallesComprobante}
                                  renderItems={(item, TR, TD) => (
                                    <TR key={`detalle-dap-mobile-${item.id}`}>
                                      <TD className="pt-16 pb-16 pl-16 pr-16 black">
                                        <p>{item.name}</p> <p className="pt-4">{item.value}</p>
                                      </TD>
                                    </TR>
                                  )}
                                />
                              </Table>
                            </div>
                          </div>
                        </div>
                      </DashboardCard.Body>
                    </DashboardCard>
                  </div>
                </div>
                <div className="row middle-xs center-xs reverse-sm">
                  <div className="col-xs-12 end-xs mb-48">
                    <Button
                      bgColor="#E81E2B"
                      className="text-size-m go-to-dap"
                      width={236}
                      onClick={goHome}
                    >
                      <span className="text-bold white">Ir a Depósito a Plazo</span>
                    </Button>
                  </div>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Wrapper>
  );
}

LiquidacionComprobante.propTypes = {
  error: PropTypes.bool,
  loading: PropTypes.bool,
  detallesComprobante: PropTypes.object,
  goHome: PropTypes.func,
  montoInvertido: PropTypes.number,
  montoLiquidar: PropTypes.number,
};

LiquidacionComprobante.defaultProps = {
  error: false,
  loading: false,
  detallesComprobante: {},
  goHome: null,
  montoInvertido: 0,
  montoLiquidar: 0,
};

export default LiquidacionComprobante;
